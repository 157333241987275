<div *ngIf="gynClinicalFlag == 'min'">
    <div class="block1_grid">
        <p class="tervys_heading"><b>General physical</b></p>
        <mat-label class="matlabel" [hidden]="skin_hidden">Jaundice<br>
            <select class="ipcss widthappt" [(ngModel)]="jaundice_txt" disableOptionCentering>
                <option value="Present">Present</option>
                <option value="No">No</option>
            </select>
        </mat-label>
        <mat-label class="matlabel" [hidden]="skin_hidden">Edema<br>
            <select class="ipcss widthappt" [(ngModel)]="edema_txt" disableOptionCentering>
                <option value="Present">Present</option>
                <option value="No">No</option>
            </select>
        </mat-label>
        <mat-label class="matlabel" [hidden]="skin_hidden">Sinusitis<br>
            <select class="ipcss widthappt" [(ngModel)]="sinus_txt" disableOptionCentering>
                <option value="Present">Present</option>
                <option value="No">No</option>
            </select>
        </mat-label>
        <!-- <mat-label  class="matlabel" [hidden]="skin_hidden">Pallor<br>
            <select class="ipcss widthappt" [(ngModel)]="pallor_txt" disableOptionCentering >
                <option value="Present">Present</option>
                <option value="No">No</option>
                </select>
         </mat-label> -->
    </div>
</div>

<div *ngIf="gynClinicalFlag == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="gen_phy-tab" data-bs-toggle="tab" data-bs-target="#gen_phy"
                            type="button" role="tab" aria-controls="gen_phy" aria-selected="true"
                            (click)="changetab('gen_phy')">General physical</button>
                    </li>
                    <li class="nav-item" role="presentation" [hidden]="obstric_hidden"> 
                        <button class="nav-link" id="antenatal-tab" data-bs-toggle="tab"
                            data-bs-target="#antenatal" type="button" role="tab" aria-controls="antenatal"
                            aria-selected="false" (click)="changetab('antenatal')">Antenatal</button>
                    </li>
                    <li class="nav-item" role="presentation" [hidden]="postnatal_hidden">
                        <button class="nav-link" id="postnatal-tab" data-bs-toggle="tab"
                            data-bs-target="#postnatal" type="button" role="tab" aria-controls="postnatal"
                            aria-selected="false" (click)="changetab('postnatal')">Postnatal</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane show active" id="gen_phy" role="tabpanel" aria-labelledby="gen_phy-tab">
                        <div class="row" *ngIf="gen_phy_tab">
                            <div class="cover_div">
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skin_hidden">Jaundice<br>
                                                <select class="ipcss " [(ngModel)]="jaundice_txt" disableOptionCentering
                                                    [disabled]="client" (change)="send_data()">
                                                    <option value="Present">Present</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skin_hidden">Edema<br> 
                                                <select class="ipcss " [(ngModel)]="edema_txt" [disabled]="client" (change)="send_data()"
                                                    disableOptionCentering>
                                                    <option value="Present">Present</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skin_hidden">Sinusitis<br>
                                                <select class="ipcss " [(ngModel)]="sinus_txt" [disabled]="client" (change)="send_data()"
                                                    disableOptionCentering>
                                                    <option value="Present">Present</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skin_hidden"
                                                (change)="pallor_change(pallor_txt); send_data();">
                                                Pallor<br>
                                                <select class="ipcss " [(ngModel)]="pallor_txt" [disabled]="client"
                                                    disableOptionCentering>
                                                    <option value="Present">Present</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="anemia_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="anemia_hidden">Pallor causes<br>
                                                <select class="ipcss " [disabled]="anemia_disabled || client"
                                                    [(ngModel)]="anemia" disableOptionCentering (change)="send_data()">
                                                    <option *ngFor="let anem of anemia_array" value="{{anem.description}}">
                                                        {{anem.description}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="heart_hidden" class="matlabel">Pulse<br>
                                                <input class="ipcss " matInput [disabled]="heart_disabled || client" (blur)="send_data()"
                                                    required [(ngModel)]="heart_rate">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="heart_hidden" class="matlabel">RS<br>
                                                <input class="ipcss " matInput [disabled]="heart_disabled || client" (blur)="send_data()"
                                                    required [(ngModel)]="rs_rate">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="heart_hidden" class="matlabel">CVS<br>
                                                <input class="ipcss " matInput [disabled]="heart_disabled || client" (blur)="send_data()"
                                                    required [(ngModel)]="cvs_rate">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="heart_hidden" class="matlabel">SPO2<br>
                                                <input class="ipcss " matInput required [(ngModel)]="spo2"  (blur)="send_data()"
                                                    [disabled]="client">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skin_hidden">Skin<br>
                                                <select class="ipcss " [disabled]="skin_disabled || client"
                                                    (change)="skin_change(skin_txt); send_data();" [(ngModel)]="skin_txt"
                                                    disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="skinabnormal_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="skinabnormal_hidden">Skin
                                                abnormalities<br>
                                                <select class="ipcss " required [disabled]="skin_abnormaldis || client" (change)="send_data()"
                                                    [(ngModel)]="skin_abnormal" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let skin of skin_abnormal_array"
                                                        value="{{skin.description}}">
                                                        {{skin.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="eye_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="eye_hidden">Eyes<br>
                                                <select class="ipcss " [disabled]="eye_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="eye_txt" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Jaundice">Jaundice</option>
                                                    <option value="Anaemia">Anaemia</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="mouth_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="mouth_hidden">Mouth<br> 
                                                <select class="ipcss " [disabled]="mouth_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="mouth" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Fetor">Fetor</option>
                                                    <option value="Ulcer">Ulcer</option>
                                                    <option value="Infections">Infections</option>
                                                    <option value="Hypertrophic gums/gingivitis">Hypertrophic
                                                        Gums/Gingivitis
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="nails_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="nails_hidden">Nails<br>
                                                <select class="ipcss " [disabled]="nail_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="nail" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Koilonychias">Koilonychias</option>
                                                    <option value="Leuconychia">Leuconychia</option>
                                                    <option value="Muehrcke's nails">Muehrcke's nails</option>
                                                    <option value="Finger clubbing">Finger clubbing</option>
                                                    <option value="Nicotine Strains">Nicotine Strains</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="lungs_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="lungs_hidden">Lungs<br>
                                                <select class="ipcss " [disabled]="lung_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="lungs" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Crepitations">Crepitations</option>
                                                    <option value="Bronchial sounds">Bronchial sounds</option>
                                                    <option value="Wheezing">Wheezing</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_rhythmhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="heart_rhythmhidden">Heart rhythm<br>
                                                <select class="ipcss " [disabled]="heartrythm_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="heart_rythm" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="legs_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="legs_hidden">Legs<br>
                                                <select class="ipcss " [disabled]="legs_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="legs" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Vericose">Vericose</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="foot_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="foot_hidden">Foot<br>
                                                <select class="ipcss " required [disabled]="foot_disbaled || client" (change)="send_data()"
                                                    [(ngModel)]="foot_details" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let foot of foot_details_array"
                                                        value="{{foot.description}}">
                                                        {{foot.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="thyroid_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="thyroid_hidden">Thyroid<br>
                                                <select class="ipcss " [disabled]="thyroid_disabled || client" 
                                                    (change)="thyroid_change(thyroid); send_data();" [(ngModel)]="thyroid"
                                                    disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Hyper">Hyper</option>
                                                    <option value="Hypo">Hypo</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="thyroid_medhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="thyroid_medhidden">Thyroid medication<br>
                                                <select class="ipcss " required [disabled]="thyroid_meddisabled || client" (change)="send_data()"
                                                    [(ngModel)]="thyroid_medication" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="breast_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="breast_hidden">Breasts<br>
                                                <select class="ipcss " [disabled]="breasts_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="breast_txt" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="moodswing_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="moodswing_hidden">Mood swing<br>
                                                <select class="ipcss " [disabled]="mood_swingdis || client" (change)="send_data()"
                                                    [(ngModel)]="mood_swing" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="diabglucose_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="diabglucose_hidden" class="matlabel">Blood glucose<br>
                                                <input class="ipcss " matInput [disabled]="bglucosedis || client" (blur)="send_data()"
                                                    [(ngModel)]="blood_glucose">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="generalapp_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="generalapp_hidden" class="matlabel">General appearance<br>
                                                <input class="ipcss " matInput [disabled]="genapp_disabled || client" (blur)="send_data()"
                                                    value="Good" [(ngModel)]="general_appearence">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="blood_preshidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="blood_preshidden" class="matlabel">Blood pressure<br>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <input [hidden]="blood_preshidden" class="ipcss" matInput
                                                            [disabled]="blood_disabled || client" placeholder="mm Hg"
                                                            maxlength="3" [(ngModel)]="BP_01" required (blur)="send_data()"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                    </div>
                                                    <div class="col-6">
                                                        <input [hidden]="blood_preshidden" class="ipcss" matInput
                                                            [disabled]="blood_disabled || client" required (blur)="send_data()"
                                                            placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_02"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                    </div>
                                                </div>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row" [hidden]="clinic_histhidden">
                                        <div class="col-12" [hidden]="clinic_histhidden || client" style="margin-top: 5px;">
                                            <a (click)="systemic_history()"><img
                                                    src="../../../assets/ui_icons/buttons/History_button.svg"
                                                    class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent" [hidden]="obstric_hidden">
                    <div class="tab-pane" id="antenatal" role="tabpanel" aria-labelledby="antenatal-tab">
                        <div class="row"  *ngIf="antenatal_tab">
                            <div class="cover_div">
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="striae_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="striae_hidden">Striae<br>
                                                <select class="ipcss " [disabled]="striae_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="striae" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="linea_nighidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="linea_nighidden">Linea nigra<br>
                                                <select class="ipcss " [disabled]="linea_nigdisabled || client" (change)="send_data()"
                                                    [(ngModel)]="linea_nigra" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="liquor_volhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="liquor_volhidden">Liquor volume<br>
                                                <select class="ipcss " [disabled]="liquor_voldisabled || client" (change)="send_data()"
                                                    [(ngModel)]="liquor_volume" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Increased">Increased</option>
                                                    <option value="Decreased">Decreased</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="fetal_movehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="fetal_movehidden">Fetal movements<br>
                                                <select class="ipcss " [disabled]="fetal_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="fetel_movement" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="fetus_grohidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="fetus_grohidden">Fetus growth<br>
                                                <select class="ipcss " [disabled]="fetus_grodisabled || client" (change)="send_data()"
                                                    [(ngModel)]="fetel_growth" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="fetus_liehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="fetus_liehidden">Fetus lie<br>
                                                <select class="ipcss " [disabled]="fetus_liedisabled || client" (change)="send_data()"
                                                    [(ngModel)]="fetes_lie" disableOptionCentering>
                                                    <option value="Longitude">Longitude</option>
                                                    <option value="Transverse">Transverse</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="fetus_presenthidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="fetus_presenthidden">Fetus
                                                presentation<br>
                                                <select class="ipcss " [disabled]="fetus_presdisabled || client" required (change)="send_data()"
                                                    [(ngModel)]="fetus_presentation" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let fetus of fetus_array" value="{{fetus.description}}">
                                                        {{fetus.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="anyemer_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="anyemer_hidden">Any emergencies<br>
                                                <select class="ipcss " [disabled]="anyemer_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="any_emergecny"
                                                    (change)="any_emergencychange(any_emergecny)" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="typeof_emergehidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="typeof_emergehidden">Type of emergency<br>
                                                <select class="ipcss " [disabled]="typ_emerdisabled || client" required (change)="send_data()"
                                                    [(ngModel)]="emergency_type"
                                                    (change)="typeofemergency_change(emergency_type)"
                                                    disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let emergency of type_emergency_array"
                                                        value="{{emergency.description}}">
                                                        {{emergency.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="emerother_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="emerother_hidden" class="matlabel">Others<br>
                                                <input class="ipcss " matInput [disabled]="treat_otherdisabled || client" (blur)="send_data()"
                                                    [required]="treatother_required" [(ngModel)]="emergency_others">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="onset_labhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="onset_labhidden">Onset of labour<br>
                                                <select class="ipcss " [disabled]="oneset_oflabdis || client" required (change)="send_data()"
                                                    [(ngModel)]="onset_labour" (change)="onsetlabour_change(onset_labour)"
                                                    disableOptionCentering>
                                                    <option value="None">None</option>
                                                    <option value="Spontaneous">Spontaneous</option>
                                                    <option value="Induced">Induced</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="baby_viahidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="baby_viahidden">Baby’s viability<br>
                                                <select class="ipcss " [disabled]="baby_viadisabled || client" (change)="send_data()"
                                                    [(ngModel)]="baby_viability" (change)="baby_viachange(baby_viability)"
                                                    disableOptionCentering>
                                                    <option value="Live birth">Live birth</option>
                                                    <option value="Still birth">Still birth</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="treat_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label [hidden]="treat_hidden" class="matlabel">Treatment<br>
                                                <input class="ipcss " matInput [disabled]="treatement_disabled || client" (blur)="send_data()"
                                                    [required]="treatment_required" [(ngModel)]="treatement">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="modeofdel_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="modeofdel_hidden">Mode of delivery<br>
                                                <select class="ipcss " [disabled]="baby_viadisabled || client" (change)="send_data()"
                                                    [(ngModel)]="Mode_delivery" (change)="modedel_change(Mode_delivery)"
                                                    disableOptionCentering>
                                                    <option *ngFor="let delivery of Mode_del_array"
                                                        value="{{delivery.description}}">
                                                        {{delivery.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="babygen_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="babygen_hidden">Baby’s gender<br>
                                                <select class="ipcss " [disabled]="baby_viadisabled || client" (change)="send_data()"
                                                    [(ngModel)]="baby_gender" required disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="babyabnormal_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="babyabnormal_hidden" class="matlabel">Baby’s
                                                abnormalities<br>
                                                <input class="ipcss " matInput [disabled]="baby_viadisabled || client" (blur)="send_data()"
                                                    [(ngModel)]="baby_abnormalities">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="delivery_termhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="delivery_termhidden">Delivery term<br>
                                                <select class="ipcss " [disabled]="baby_viadisabled || client" (change)="send_data()"
                                                    [(ngModel)]="delivery_term" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let term of gest_age_array"
                                                        value="{{term.description}}">
                                                        {{term.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="reasonc_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" [hidden]="reasonc_hidden">Reasons for c-section<br>
                                                <select class="ipcss " [disabled]="reason_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="reason_csection" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let reason of reason_array"
                                                        value="{{reason.description}}">
                                                        {{reason.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="counselling_needhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="counselling_needhidden">Counselling
                                                needed<br>
                                                <select class="ipcss " [disabled]="counsiling_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="counselling_need" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="head_circumhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="head_circumhidden" class="matlabel">Head circumfrence<br>
                                                <input class="ipcss " matInput [disabled]="head_cirdisabled || client" (blur)="send_data()"
                                                    [(ngModel)]="head_circum">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="birth_lochidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label [hidden]="birth_lochidden" class="matlabel">Birth location<br>
                                                <input class="ipcss " matInput [disabled]="birthloc_disabled || client" (blur)="send_data()"
                                                    [(ngModel)]="delivery_location">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="apgar_scoreonemin"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <div class="row">
                                                <div [hidden]="apgar_scoreonemin" class="col-9">
                                                    <mat-label [hidden]="apgar_scoreonemin" class="matlabel">APGAR SCORE AT 
                                                        1
                                                        MIN<br>
                                                        <input [ngClass]="!dynamic_border ? 'apgar' : 'broder_blue'" (blur)="send_data()"
                                                            class="ipcss gyno_clinicalwidth" matInput required readonly
                                                            [(ngModel)]="apgar_onemin" [disabled]="client">
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="apgar_scoreonemininfo || client" class="col-3">
                                                    <mat-label [hidden]="apgar_scoreonemininfo" class="matlabel">
                                                        <img src="../../../assets/img/Info_icon.png"
                                                            (click)="apgar_oneclick()" class="info_img" />
                                                    </mat-label>
                                                </div>
                                            </div>
    
                                        </div>
    
                                        <div [hidden]="apgar_scorefivemin"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <div class="row">
                                                <div [hidden]="apgar_scorefivemin" class="col-9">
                                                    <mat-label [hidden]="apgar_scorefivemin" class="matlabel">APGAR SCORE AT
                                                        5
                                                        MIN<br>
                                                        <input class="ipcss " matInput required [readonly]="isReadonly()" (blur)="send_data()"
                                                            [disabled]="client" [(ngModel)]="apgar_fivemin">
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="apgar_scorefivemininfo || client" class="col-3">
                                                    <mat-label [hidden]="apgar_scorefivemininfo" class="matlabel">
                                                        <img src="../../../assets/img/Info_icon.png"
                                                            (click)="apgar_fiveclick()" class="info_img" />
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div [hidden]="length_retushidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="length_retushidden" class="matlabel">Fundal height<br>
                                                <input class="ipcss " matInput [disabled]="length_uterusdisabled || client" (blur)="send_data()"
                                                    [(ngModel)]="fundal_height">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="fundal_matchhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="fundal_matchhidden">Fundal height
                                                match<br>
                                                <select class="ipcss " [disabled]="fundal_heightdisabled || client" (change)="send_data()"
                                                    [(ngModel)]="height_match" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="heart_fetushidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="heart_fetushidden" class="matlabel">Heart rate of fetus<br>
                                                <input class="ipcss " matInput [disabled]="heartrate_fetdisabled || client" (blur)="send_data()"
                                                    [(ngModel)]="fetus_heart">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="birth_lochidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="birth_lochidden" class="matlabel">Heart sound<br>
                                                <input class="ipcss " matInput [disabled]="birthloc_disabled || client" (blur)="send_data()"
                                                    [(ngModel)]="heart_sound">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="baby_weighthidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="baby_weighthidden" class="matlabel">Weight<br>
                                                <div class="row">
                                                    <div class="col-5">
                                                        <input class=" ipcss " matInput
                                                            [disabled]="babywei_disabled || client" required
                                                            [(ngModel)]="baby_weight" maxlength="2" (blur)="send_data()">
                                                    </div>
                                                    <div class="col-1">
                                                        <span style="padding: 0;position: relative;
                                                        left: 5px;font-size: 23px;">.</span>
                                                    </div>
                                                    <div class="col-5">
                                                        <input class="ipcss " matInput
                                                            [disabled]="babywei_disabled || client" required
                                                            [(ngModel)]="baby_weight1" maxlength="2" (blur)="send_data()">
                                                    </div>
                                                </div>
                                            </mat-label>
                                        </div>
    
                                        <div [hidden]="baby_weighthidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="baby_weighthidden" class="matlabel">Measure<br>
                                                <select class="ipcss " [disabled]="baby_viadisabled || client" (change)="send_data()"
                                                    [(ngModel)]="baby_clinic_measure" disableOptionCentering>
                                                    <option value="kgs">kgs</option>
                                                    <option value="lbs">lbs</option>
                                                </select>
                                            </mat-label>
                                        </div>
    
                                        <div [hidden]="birth_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-label [hidden]="birth_hidden" class="matlabel">Birth height<br>
                                                        <input class="ipcss " matInput [disabled]="birth_hedis || client"
                                                            [(ngModel)]="height_baby" maxlength="3" (blur)="send_data()"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                    </mat-label>
                                                </div>
                                                <div class="col-6">
                                                    <mat-label class="matlabel" [hidden]="birth_hidden">Measure<br>
                                                        <select class="ipcss"
                                                            [disabled]="birth_hedis || client" [(ngModel)]="height_measure" (blur)="send_data()"
                                                            disableOptionCentering>
                                                            <option value="cms">cms</option>
                                                            <option value="inch">inch</option>
                                                        </select>
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div [hidden]="pregnancy_medhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="pregnancy_medhidden">Pregnancy related medical conditions<br>
                                                <textarea class="ipcss obsttxtareaheight " [(ngModel)]="pregnacy_condition" (blur)="send_data()"
                                                    [disabled]="client" (click)="pregnancy_relatedmedpop()"></textarea>
                                            </mat-label>
                                        </div>
    
                                        <div [hidden]="pregnancy_relhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="pregnancy_relhidden">Pregnancy related
                                                issues<br>
                                                <textarea class="ipcss obsttxtareaheight " [(ngModel)]="pregnancy_issue"
                                                    [disabled]="client" (click)="pregnancy_issuepop()" (blur)="send_data()"></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row" [hidden]="obstric_hidden_history || client">
                                        <div class="col-12">
                                            <a (click)="antenatal_historyclick()"><img
                                                    src="../../../assets/ui_icons/buttons/History_button.svg"
                                                    class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent" [hidden]="postnatal_hidden">
                    <div class="tab-pane" id="postnatal" role="tabpanel" aria-labelledby="postnatal-tab">
                        <div class="row"  *ngIf="postnatal_tab">
                            <div class="cover_div">
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="postblood_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="postblood_hidden">Blood pressure<br>
                                                <select class="ipcss " [disabled]="post_blooddis || client" (change)="send_data()"
                                                    [(ngModel)]="blood_pressure" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="90mmHg">90mmHg</option>
                                                    <option value="Pre-eclampsia">Pre-eclampsia</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="pulse_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="pulse_hidden">Pulse<br>
                                                <select class="ipcss " [disabled]="pulse_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="pulse_txt" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="anaemic_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="anaemic_hidden">Anaemic<br>
                                                <select class="ipcss " [disabled]="anaemic_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="anemia_clinic" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let anem of anemia_array" value="{{anem.description}}">
                                                        {{anem.description}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="mood_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="mood_hidden">Mood<br>
                                                <select class="ipcss " [disabled]="moodpost_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="moodpost_disabled" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="painin_perhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="painin_perhidden">Pain in perineum<br>
                                                <select class="ipcss " [disabled]="painper_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="puerperium_pain" disableOptionCentering>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="typeof_contrahidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="typeof_contrahidden">Type of
                                                contraceptive<br>
                                                <select class="ipcss " [disabled]="typecon_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="type_contracept" disableOptionCentering>
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let contracept of contracept_array"
                                                        value="{{contracept.description}}">
                                                        {{contracept.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="chest_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="chest_hidden">Chest<br>
                                                <select class="ipcss " [disabled]="chest_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="postchest_txt" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="chestcond_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="chestcond_hidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [required]="chest_required" (blur)="send_data()"
                                                    [disabled]="chest_condisabled || client" [(ngModel)]="chest_condition">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="breast_feedhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="breast_feedhidden">Breast feeding<br>
                                                <select class="ipcss " [disabled]="breats_feeddis || client" (change)="send_data()"
                                                    [(ngModel)]="breast_feeding"
                                                    (change)="breastfeed_change(breast_feeding)" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="breast_feedcondhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="breast_feedcondhidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [disabled]="breats_feedcondids || client" (blur)="send_data()"
                                                    [required]="feed_required" [(ngModel)]="breastfeed_condition">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="breats_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="breats_hidden">Breasts<br>
                                                <select class="ipcss " [disabled]="breast_dis || client" (change)="send_data()"
                                                    [(ngModel)]="postobs_breasts" (change)="breast_change(postobs_breasts)"
                                                    disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="breatscond_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="breatscond_hidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [disabled]="breastcond_dis || client" (blur)="send_data()"
                                                    [required]="breast_required" [(ngModel)]="breast_condition">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="puerper_planhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="puerper_planhidden">Puerperium plan<br>
                                                <select class="ipcss " [disabled]="puerper_disabled || client" (blur)="send_data()"
                                                    [(ngModel)]="puerperium_paintxt"
                                                    (change)="purperium_change(puerperium_paintxt)" disableOptionCentering>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="puerper_conditionhidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="puerper_conditionhidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [required]="puerper_required" (blur)="send_data()"
                                                    [disabled]="puerpercond_disabled || client"
                                                    [(ngModel)]="puerperium_cond">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="uterine_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="uterine_hidden">Uterine involution<br>
                                                <select class="ipcss " [disabled]="uterine_disabled || client"  (change)="send_data()"
                                                    [(ngModel)]="uterine_involution"
                                                    (change)="uterine_change(uterine_involution)" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="uterineinvol_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="uterineinvol_hidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [disabled]="uterine_condisabled || client" (blur)="send_data()"
                                                    [required]="uterine_required" [(ngModel)]="uterine_condition">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="palpable_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="palpable_hidden">Palpable bladder<br>
                                                <select class="ipcss " [disabled]="palpable_disabled || client" (change)="send_data()"
                                                    [(ngModel)]="palpable_bladder"
                                                    (change)="palpable_change(palpable_bladder)" disableOptionCentering>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Abnormal">Abnormal</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="palpablecon_hidden"
                                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label [hidden]="palpablecon_hidden" class="matlabel"><br>
                                                <input class="ipcss " matInput [disabled]="palpable_condiiondis || client" (blur)="send_data()"
                                                    [required]="palpable_required" [(ngModel)]="palpable_bladdercondition">
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row" [hidden]="postnatal_hidden_history || client">
                                        <div class="col-12">
                                            <a (click)="postnatal_history()"><img
                                                    src="../../../assets/ui_icons/buttons/History_button.svg"
                                                    class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!--Future <div class="row" [hidden]="diabglucose_hidden">
        <div class="col-lg-12 col-xl-12 col-12 col-sm-12 col-md-12" [hidden]="diabglucose_hidden">
            <div class="appint_graph">
                <div id="Blood_Unavailable">
                    <p class="Paraclass">
                        <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                </div>
                <div id="Blood_Glucose_Chart">
                    <canvas #bloodGlucoseCanvas width="600" height="400" class="chart_div"></canvas>
                </div>
                <p class="text-center">Blood Glucose</p>
            </div>
        </div>    
    </div> -->
</div>