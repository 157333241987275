import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';

@Component({
  selector: 'app-diag-sample-list',
  templateUrl: './diag-sample-list.component.html',
  styleUrls: ['./diag-sample-list.component.scss']
})
export class DiagSampleListComponent implements OnInit {
  public save_btn: boolean;
  public sampledate: string;
  public filtduration: string;
  public currentDate: string;
  public sampleList: any = [];
  public selectedList: any = [];
  public sampletype_data: any = [];
  dtOptions: DataTables.Settings = {};

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.filtduration = "select";
    this.getdate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.getSampleTypes();
  }

  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.sampledate = this.currentDate;
        this.getSampleList(this.sampledate)
      }, error => { });
  }

  getSampleList(date) {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      country: ipaddress.country_code,
      diag_center_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
      duration: durval,
      date: date,
    }

    console.log("getSampleList--send_data", send_data)


    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log("response", response.sample_list);
        this.sampleList = [];
        if (response.sample_list != undefined) {
          for (var i = 0; i < response.sample_list.length; i++) {
            var cname;
            if (response.sample_list[i].middle_name != undefined) {
              cname = encrypt_decript.Decript(response.sample_list[i].first_name) + " " + encrypt_decript.Decript(response.sample_list[i].middle_name) + " " + encrypt_decript.Decript(response.sample_list[i].last_name);
            } else {
              cname = encrypt_decript.Decript(response.sample_list[i].first_name) + " " + encrypt_decript.Decript(response.sample_list[i].last_name);
            }
            var disableflag;
            if (response.sample_list[i].sample_collect_time != undefined) {
              disableflag = true;
            } else {
              disableflag = false;
            }

            var cdate;
            var dateval;
            if (response.sample_list[i].collected_date != undefined) {
              cdate = response.sample_list[i].collected_date.split("-");
              dateval = cdate[2] + "-" + cdate[1] + "-" + cdate[0];

            } else {
              dateval = "";
            }

            this.sampleList.push({
              patient_id: response.sample_list[i].patient_id,
              sampleid: response.sample_list[i].sample_id,
              prestestid: response.sample_list[i].prestestid,
              diagtestid: response.sample_list[i].diagtestid,
              test_type_name: response.sample_list[i].test_type_name,
              test_name: response.sample_list[i].test_name,
              collected_date: dateval,
              collect_time: response.sample_list[i].sample_collect_time,
              pat_name: cname,
              disableflag: disableflag,
              priority: response.sample_list[i].priority,
              sample_type: response.sample_list[i].sample_type,
              diag_dept_id: response.sample_list[i].diag_dept_id,
              department: response.sample_list[i].department,
              tube_type_id: response.sample_list[i].tube_type_id,
              tube_type: response.sample_list[i].tube_type,
            })
          }
          console.log("this.sampleList", this.sampleList);
        }

      }, error => { });
  }

  saveSamples() {
    this.selectedList = [];
    for (var i = 0; i < this.sampleList.length; i++) {
      if (this.sampleList[i].isSelected == true) {
        this.selectedList.push({
          pres_diag_id: this.sampleList[i].diagtestid,
          test_type: this.sampleList[i].test_type_name,
          pres_test_id: this.sampleList[i].prestestid,
          sample_type: this.sampleList[i].sample_type,
        })
      }
    }

    console.log("this.selectedList", this.selectedList)
    var send_data = {
      user_id: Helper_Class.getInfo().user_id,
      country: ipaddress.country_code,
      tests: this.selectedList,
      diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response)
        if (response.key == "1") {
          this.toastr.success("Successfully saved sample collections");
          this.getSampleList(this.sampledate);
        }

      }, error => { })
  }

  getSampleTypes() {
    this.sampletype_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdst ', { diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sampleTypesData = obj.sample_type_list;

          if (sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push(
                {
                  sample_type_id: sampleTypesData[i].sample_type_id,
                  sample_desc: sampleTypesData[i].sample_desc,
                  status: sampleTypesData[i].status,
                }
              )
            }
          }

          console.log("getSampleTypes", this.sampletype_data);

        }, error => { });

  }
}
