<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Discharge Summary</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                        <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                                class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" /></a>
                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="Details">

                        <div class="cover_div" style="padding-bottom: 13px;">
                            <div class="header_lable">Personal details</div>
                            <div class="content_cover">
                                <div class="row">                                                       
                                    <div class="col-3">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              MR No</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patient_identifier}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col-3">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              Patient name</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patient_name}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col-3">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              Age/Gender</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                              {{age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col-3" *ngIf="inpId != undefined && inpId != ''">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              Inpatient ID</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{inpId}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>      
                           
                                    <div class="col-3" *ngIf="admis_date != '' && admis_date != undefined">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              AOD</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{admis_date}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>   
                                    <div class="col-3" *ngIf="dis_date != '' && dis_date != undefined">
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                              DOD</td>
                                            <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{dis_date}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>                                                          
                                    <div class="col-6">
                                      <table style="width: 100%;" class="head-table">
                                         <tbody>
                                            <tr class="head-tr" style="vertical-align: top !important;">
                                               <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                                Consultant(s)</td>
                                               <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                               <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                              <div *ngFor="let name of consultarray">
                                                <span>{{name.name}}</span>
                                              </div></td>
                                            </tr>                 
                                         </tbody>
                                      </table>
                                    </div>
                                  </div>      
                            </div>
                        </div>
                        <div class="cover_div" style="padding-bottom: 13px;">
                            <div class="header_lable">General Examination</div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}
                                        </p>
                                    </div>

                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">BP:</span>
                                            {{blood_pressure}}</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">Temp:</span>
                                            {{temparature}}</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">Pulse:</span> {{pulse}}
                                            bpm</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">RR:</span> {{resp_rate}}
                                            bpm</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">CVS:</span> {{cvs}}</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">RS:</span> {{rstxt}}</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat"><span style="font-weight: 550;">CNS:</span> {{cns}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="surgery_date != undefined">
                            <div class="header_lable">Surgery details</div>
                            <div class="content_cover">
                                <div class="row " style="margin-top: 5px;margin-bottom: 5px;"
                                    *ngIf="surgery_date != undefined">
                                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-4 headernew"><span style="font-weight: 550;">Date of
                                                    surgery</span></div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">{{surgery_date}}</div>
                                            <div class="col-4 headernew"><span style="font-weight: 550;">Surgeon</span>
                                            </div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">
                                                <table>
                                                    <tr *ngFor="let surgeon of surgeon_list;">
                                                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="col-4 headernew"><span style="font-weight: 550;">Surgery
                                                    procedure</span></div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">{{surgery_proc}}</div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-4 headernew"><span
                                                    style="font-weight: 550;">Anaestetist</span></div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">
                                                <table>
                                                    <tr *ngFor="let anaest of anaestetist;">
                                                        <td style="text-align: left;" class="matlabel">{{anaest.name}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="col-4 headernew"><span
                                                    style="font-weight: 550;">Anaestesia</span></div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">{{anaestetype}}</div>
                                            <div class="col-4 headernew"><span style="font-weight: 550;">Anaestesia
                                                    procedure</span></div>
                                            <div class="col-1">:</div>
                                            <div class="col-7 optionnew">{{anaest_proc}}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 checkmar" *ngIf="dis_diag != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">

                                        <mat-label class="matlabel"><b class="label_bold">Diagnosis
                                            </b></mat-label>
                                    </div>
                                    <div class="col-12">
                                        <quill-editor id="editor1" [(ngModel)]="dis_diag" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentdiag($event)"></quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="dis_diag"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="complaints != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">Complaints
                                            </b></mat-label>
                                    </div>
                                    <div class="col-12">
                                        <quill-editor id="editor1" [(ngModel)]="complaints" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentcomp($event)"></quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="complaints"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="medical_hist != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">Previous Medical History</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <quill-editor id="editor1" [(ngModel)]="medical_hist" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentpreviousmedicalhist($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="medical_hist"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="physical_exam != ''">
                                <div class="row">
                                    <div class="col-12 margin_10 ">
                                        <mat-label class="matlabel"><b class="label_bold">Physical Examination</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <quill-editor id="editor1" [(ngModel)]="physical_exam" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentphysical($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="physical_exam"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="Treatmetnt_done != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">Treatment/Procedure
                                                done</b></mat-label>
                                    </div>
                                    <div class="col-12">
                                        <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="emergency_notes"></textarea> -->
                                        <quill-editor id="editor1" [(ngModel)]="Treatmetnt_done" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontenttreatment($event)"></quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="Treatmetnt_done"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="hosp_course != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">Summary Course in Hospital</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                                        <quill-editor id="editor1" [(ngModel)]="hosp_course" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontenthosp($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="hosp_course"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="ot_notes != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">OT Notes</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                                        <quill-editor id="editor1" [(ngModel)]="ot_notes" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentot($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="ot_notes"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="emergency_notes != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">In-case of Emergency</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                                        <quill-editor id="editor1" [(ngModel)]="emergency_notes" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentemer($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="emergency_notes"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 checkmar" *ngIf="hospital_followup != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel"><b class="label_bold">Discharge advice</b>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                                        <quill-editor id="editor1" [(ngModel)]="hospital_followup" [style]="editorStyle"
                                            [modules]="modules" placeholder="Enter Text"
                                            (onContentChanged)="oncontentdis($event)">
                                        </quill-editor>
                                        <div>
                                            <!-- Preview -->
                                            <quill-view-html hidden id="viewhtml1"
                                                [content]="hospital_followup"></quill-view-html>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 checkmar" >
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Next visit:<span>{{next_data}}</span></mat-label>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;"
                                [hidden]="druglist.length==0">
                                <div class="header_lable">Medicines to follow</div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="table-responsive dig_table_overflow">
                                                <table *ngIf="druglist.length"
                                                    class="table table-nowrap table-sm dataTable billlisttable">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 200px;"><b>Medicine</b></th>
                                                            <th style="width: 100px;"><b>Dosage</b></th>
                                                            <th style="width: 150px;"><b>Days</b></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let product of druglist">
                                                            <td>{{product.medicine_name}}</td>
                                                            <td style="text-align: center">{{product.medicine_dosage}}
                                                            </td>
                                                            <td style="text-align: center">
                                                                {{product.medicine_intakedays}} -
                                                                {{product.medicine_food}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Newly added -->

                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>

<!-- print section -->
<div id="print" hidden>
    <div #printlogowithname id="printlogowithname">
        <table style="border:'0';width:100%">
            <thead>
                <tr>
                    <th style="width:100%;height: 100px;">
                        <table style="width: 100%;margin-top: -100px;">
                            <tr>
                                <td style="display: flex;justify-content: center;">
                                    <div>
                                        <img alt="image" src={{hospital_logo}} style='{{dis_style}}'>
                                    </div>
                                    <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                        <span><b>{{hosp_name}}</b>,</span><br />
                                        <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                                        <span>{{hosp_csz}}</span><br />
                                        <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png"
                                                width="15px" height="15px" />{{hosp_tel}}.</span><br>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td width="100%">
                        <table style="border:'0';width:100%">
                            <tr>
                                <td style="width:100%;height: 100px;">&nbsp;</td>
                            </tr>
                        </table>
            </tfoot>
            <tbody>
                <tr>
                    <td width="100%">
                        <div class="bill_border">
                            <div>
                                <table style="width: 90%; margin: 0 auto;">
                                    <tr>
                                        <td style="width: 50%;">
                                            <div class="row">
                                                <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                                    <span><strong>Patient name : </strong>{{patient_name}} </span>
                                                    <br />
                                                    <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                                    <br />
                                                    <span><strong> UHID : </strong>{{patient_identifier}}</span>
                                                    <br />
                                                    <span><strong> Consultant(s) : </strong>
                                                        <table class="table1" style="margin-left: 50px;">
                                                            <tr *ngFor="let name of consultarray">
                                                                <td style="text-align: left;">{{name.name}}</td>
                                                            </tr>
                                                        </table>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>

                                        <td style="width: 50%;" valign="top">
                                            <div class="row" style="margin-left: 60px;">
                                                <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                                    <span><strong>Date of admission: </strong>{{admis_date}}
                                                        {{admis_time}}</span><br />
                                                    <span><strong>Date of discharge : </strong>{{dis_date}}
                                                        {{dis_time}}</span><br />
                                                    <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <div class="block1_grid">
                                    <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis
                                            :</mat-label>
                                        <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done :
                                        </mat-label>
                                        <div style="margin-top:-15px;text-align: justify;"
                                            [innerHTML]="Treatmetnt_done"></div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                                        <div style="text-align: justify;" [innerHTML]="complaints"></div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History :
                                        </mat-label>
                                        <div style="text-align: justify;" [innerHTML]="medical_hist">
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Physical Examination :
                                        </mat-label>
                                        <div style="text-align: justify;" [innerHTML]="physical_exam">
                                        </div>
                                    </div>
                                </div>

                                <div class="block1_grid">
                                    <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Investigation :
                                        </mat-label>
                                        <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                                        <mat-label class="matlabel" style="font-weight: 600;">Operative Notes :
                                        </mat-label>
                                        <div style="margin-bottom: 5px;">
                                            <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                            <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                                            <span><strong>Surgeon : </strong>
                                                <table>
                                                    <tr *ngFor="let surgeon of surgeon_list;">
                                                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </span><br />
                                            <span><strong>Anaestetist : </strong>
                                                <table>
                                                    <tr *ngFor="let anaest of anaestetist;">
                                                        <td style="text-align: left;" class="matlabel">{{anaest.name}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </span><br />
                                            <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                            <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                            <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in
                                            Hospital : </mat-label>
                                        <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                                        <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice :
                                        </mat-label>
                                        <div style="margin-top:-15px;text-align: justify;"
                                            [innerHTML]="hospital_followup">
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                                    <div class="col-12">
                                        <table id="tbl" cellpadding="5" cellspacing="0">
                                            <tr>
                                                <th>Medicine</th>
                                                <th>Dosage</th>
                                                <th>Days</th>
                                            </tr>
                                            <tr *ngFor="let medicine of druglist">
                                                <td>{{medicine.medicine_name}}</td>
                                                <td>{{medicine.medicine_dosage}}</td>
                                                <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <div style="width: 90%; margin: 0 auto;">
                                    <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                                    <br />
                                    <br />
                                    <mat-label class="matlabel"> <b>In case of emergency contact :</b>
                                        {{hosp_tel}}</mat-label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <table style="width: 100%; margin-top: 80px;">
                            <tr>
                                <td style="width: 50%;"></td>
                                <td style="width: 50%;">
                                    <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div #printbanner id="printbanner">
        <table style="border:'0';width:100%">
            <thead>
                <tr>
                    <th style="width:100%;height: 100px;">
                        <table style="margin-left:10px;margin-top:-100px;margin-right: 10px;">
                            <tr>
                                <td>
                                    <img alt="image" src={{hptl_dis_logo}} style='{{dis_style}}'>
                                </td>
                            </tr>
                        </table>
                    </th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td width="100%">
                        <table style="border:'0';width:100%">
                            <tr>
                                <td style="width:100%;height: 100px;">&nbsp;</td>
                            </tr>
                        </table>
            </tfoot>
            <tbody>
                <tr>
                    <td width="100%">
                        <div>

                            <table style="width: 90%; margin: 0 auto;">
                                <tr>
                                    <td colspan="2">
                                        <div [innerHTML]="headerstyle"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 50%;">
                                        <div class="row">
                                            <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                                <span><strong>Patient name : </strong>{{patient_name}} </span>
                                                <br />
                                                <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                                <br />
                                                <span><strong> UHID : </strong>{{patient_identifier}}</span>
                                                <br />
                                                <span><strong> Consultant(s) : </strong>
                                                    <table class="table1" style="margin-left: 50px;">
                                                        <tr *ngFor="let name of consultarray">
                                                            <td style="text-align: left;">{{name.name}}</td>
                                                        </tr>
                                                    </table>
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td style="width: 50%;" valign="top">
                                        <div class="row" style="margin-left: 60px;">
                                            <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                                <span><strong>Date of admission: </strong>{{admis_date}}
                                                    {{admis_time}}</span><br />
                                                <span><strong>Date of discharge : </strong>{{dis_date}}
                                                    {{dis_time}}</span><br />
                                                <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div class="block1_grid">
                                <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis
                                        :</mat-label>
                                    <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done :
                                    </mat-label>
                                    <div style="margin-top:-15px;text-align: justify;" [innerHTML]="Treatmetnt_done">
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                                    <div style="text-align: justify;" [innerHTML]="complaints"></div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History :
                                    </mat-label>
                                    <div style="text-align: justify;" [innerHTML]="medical_hist">
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Physical Examination :
                                    </mat-label>
                                    <div style="text-align: justify;" [innerHTML]="physical_exam">
                                    </div>
                                </div>
                            </div>

                            <div class="block1_grid">
                                <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                                    <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                                    <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                                    <div style="margin-bottom: 5px;">
                                        <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                        <span><strong>Surgery : </strong>{{surgery}} </span><br />
                                        <span><strong>Surgery procedure : </strong>{{surgery_proc}} </span><br />
                                        <span><strong>Surgeon : </strong>
                                            <table>
                                                <tr *ngFor="let surgeon of surgeon_list;">
                                                    <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                                </tr>
                                            </table>
                                        </span><br />
                                        <span><strong>Anaestetist : </strong>
                                            <table>
                                                <tr *ngFor="let anaest of anaestetist;">
                                                    <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                                </tr>
                                            </table>
                                        </span><br />
                                        <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                        <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                        <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital :
                                    </mat-label>
                                    <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                                    </div>
                                </div>

                                <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                                    <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice :
                                    </mat-label>
                                    <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                                    </div>
                                </div>
                            </div>

                            <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                                <div class="col-12">
                                    <table id="tbl" cellpadding="5" cellspacing="0">
                                        <tr>
                                            <th>Medicine</th>
                                            <th>Dosage</th>
                                            <th>Days</th>
                                        </tr>
                                        <tr *ngFor="let medicine of druglist">
                                            <td>{{medicine.medicine_name}}</td>
                                            <td>{{medicine.medicine_dosage}}</td>
                                            <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <br />
                            <div style="width: 90%; margin: 0 auto;">
                                <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                                <br />
                                <br />
                                <mat-label class="matlabel"> <b>In case of emergency contact :</b>
                                    {{hosp_tel}}</mat-label>
                            </div>
                        </div>
                        <br />
                        <table style="width: 100%; margin-top: 80px;">
                            <tr>
                                <td style="width: 50%;"></td>
                                <td style="width: 50%;">
                                    <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div #printnoheader id="printnoheader">
        <div>
            <table style="border:'0';width:100%">
                <thead>
                    <tr>
                        <th style="width:100%;height: 100px;"></th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td width="100%">
                            <table style="border:'0';width:100%">
                                <tr>
                                    <td style="width:100%;height: 100px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tfoot>
                <tbody>
                    <tr>
                        <td width="100%">
                            <table style="border:'0';width:100%;">
                                <thead>
                                    <tr>
                                        <th>
                                            <div style="margin-top: 30px !important;">&nbsp;</div>
                                            <div style={{printlogostyle}}>
                                                <div
                                                    style="display: flex; justify-content: space-between; margin-left: 25px !important;margin-right:25px !important;padding: 10px; margin-top: 10px; margin-bottom: 10px; border: 1px solid black; border-radius: 10px;">
                                                    <table style="width: 55%;vertical-align: top !important;">
                                                        <tr>
                                                            <td style="vertical-align: top !important;">
                                                                <strong>Name</strong></td>
                                                            <td style="vertical-align: top !important;">:
                                                                {{patient_name}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Age / Gender</strong></td>
                                                            <td>: {{age}} {{gender}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Inpatient ID</strong></td>
                                                            <td>: {{inpId}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>MR no</strong></td>
                                                            <td>: {{patient_identifier}}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style="vertical-align: top !important;">
                                                                <strong>Consultant(s)</strong></td>
                                                            <td
                                                                style="text-align: left;vertical-align: top !important;">
                                                                :
                                                                <div *ngFor="let name of consultarray">
                                                                    <p style="text-align: left;">{{name.name}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table
                                                        style="width: 45%;vertical-align: top !important;height: 0%;">
                                                        <tr>
                                                            <td style="vertical-align: top !important;width: 30px;">
                                                                <strong>DOA</strong></td>
                                                            <td style="vertical-align: top !important;"> :
                                                                {{admis_date}} {{admis_time}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="vertical-align: top !important;width: 30px;">
                                                                <strong>DOD</strong></td>
                                                            <td style="vertical-align: top !important;"> : {{dis_date}}
                                                                {{dis_time}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="vertical-align: top !important;column-span:2;">
                                                                <div style="margin-left: -10px;">
                                                                    <div
                                                                        class="d-flex align-items-center justify-content-center">
                                                                        <ngx-barcode class="barcode"
                                                                            [bc-value]="patient_identifier"
                                                                            [bc-display-value]="displayValue"
                                                                            [bc-line-color]="lineColor"
                                                                            [element-type]="elementType"
                                                                            [format]="format" [bc-width]="width"
                                                                            [bc-height]="barcodeheight"
                                                                            [bc-font-options]="fontOptions"
                                                                            [bc-font]="font" [bc-text-align]="textAlign"
                                                                            [bc-text-position]="textPosition"
                                                                            [bc-text-margin]="textMargin"
                                                                            [bc-font-size]="fontSize"
                                                                            [bc-background]="background"
                                                                            [bc-margin]="margin"
                                                                            [bc-margin-top]="marginTop"
                                                                            [bc-margin-bottom]="marginBottom"
                                                                            [bc-margin-left]="marginLeft"
                                                                            [bc-margin-right]="marginRight"></ngx-barcode>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td width="100%">
                                            <div class="block1_grid"
                                                style="margin-left: 25px !important;margin-right:25px !important">
                                                <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Discharge
                                                        Diagnosis :</mat-label>
                                                    <div style="margin-top:1px;text-align: justify;"
                                                        [innerHTML]="dis_diag">
                                                    </div>
                                                </div>

                                                <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                                                    <mat-label class="matlabel"
                                                        style="font-weight: 600;">Treatment/Procedure done :
                                                    </mat-label>
                                                    <div style="margin-top:1px;text-align: justify;"
                                                        [innerHTML]="Treatmetnt_done"></div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Complaints :
                                                    </mat-label>
                                                    <div style="text-align: justify;" [innerHTML]="complaints"></div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Previous
                                                        Medical History : </mat-label>
                                                    <div style="text-align: justify;" [innerHTML]="medical_hist">
                                                    </div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Physical
                                                        Examination : </mat-label>
                                                    <div style="text-align: justify;" [innerHTML]="physical_exam">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block1_grid"
                                                style=" margin-left: 25px !important;margin-right:25px !important;margin-top: 1px;">
                                                <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Investigation
                                                        : </mat-label>
                                                    <div style="margin-top:1px;text-align: justify;"
                                                        [innerHTML]="investigation">
                                                    </div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                                                    <mat-label class="matlabel" style="font-weight: 600;">OT Notes :
                                                    </mat-label>
                                                    <div style="margin-bottom: 5px;">
                                                        <div><strong>Surgery Date :
                                                            </strong><span>{{surgery_date}}</span> </div><br />
                                                        <div><strong>Surgery : </strong><span>{{surgery_proc}}</span>
                                                        </div><br />
                                                        <span><strong>Surgeon : </strong>
                                                            <table>
                                                                <tr *ngFor="let surgeon of surgeon_list;">
                                                                    <td style="text-align: left;" class="matlabel">
                                                                        {{surgeon.name}}</td>
                                                                </tr>
                                                            </table>
                                                        </span><br />
                                                        <span><strong>Anaestetist : </strong>
                                                            <table>
                                                                <tr *ngFor="let anaest of anaestetist;">
                                                                    <td style="text-align: left;" class="matlabel">
                                                                        {{anaest.name}}</td>
                                                                </tr>
                                                            </table>
                                                        </span><br />
                                                        <div><strong>Anaestesia : </strong><span>{{anaestetype}} </span>
                                                        </div><br />
                                                        <div><strong>Findings :
                                                            </strong><span>{{anaest_findings}}</span> </div><br />
                                                        <div><strong>Procedure done : </strong><span>{{anaest_proc}}
                                                            </span></div><br />
                                                    </div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;"> Summary
                                                        Course in Hospital : </mat-label>
                                                    <div style="margin-top:5px;text-align: justify;"
                                                        [innerHTML]="hosp_course">
                                                    </div>
                                                </div>
                                                <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                                                    <mat-label class="matlabel" style="font-weight: 600;">Discharge
                                                        Advice : </mat-label>
                                                    <div style="margin-top:5px;text-align: justify;"
                                                        [innerHTML]="hospital_followup">
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="margin-left: 25px !important;margin-right:25px !important">
                                                <div>
                                                    <p><b>General examination :</b></p>
                                                </div>
                                                <table style="margin-left: 10px;"
                                                    style="border: 1px solid #c5d7e6; width: 90%; border-collapse: collapse; margin-top: 10px; margin-left: auto; margin-right: auto;">
                                                    <tr>
                                                        <td *
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">Height:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                height }}
                                                            </mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">Weight:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                weight }} </mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">Blood prssure:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                blood_pressure }} </mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">Temp:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                temparature }}</mat-label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">Pulse:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                pulse }} bpm</mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">RR:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                resp_rate }} bpm</mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">CVS:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{ cvs
                                                                }}</mat-label>
                                                        </td>
                                                        <td
                                                            style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                                                            <mat-label class="matlabel">RS:</mat-label>
                                                            <mat-label class="matlabel" style="margin-left: 8px;">{{
                                                                rstxt }}</mat-label>
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>

                                            <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;margin-top: 25px !important" 
                                                *ngIf="druglist.length !=0">
                                                <div class="col-12">
                                                    <table *ngIf="druglist.length != 0"
                                                        style=" border: 1px solid black;border-collapse: collapse;">

                                                        <tr style="text-align: center;">
                                                            <th>
                                                                Medicine
                                                            </th>
                                                            <th>
                                                                Dosage
                                                            </th>

                                                            <th>
                                                                Days
                                                            </th>

                                                        </tr>
                                                        <tr *ngFor="let medicine of druglist">
                                                            <td
                                                                style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                                                {{medicine.medicine_name}}
                                                            </td>
                                                            <td
                                                                style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                                                {{medicine.medicine_dosage}}
                                                            </td>
                                                            <td
                                                                style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                                                {{medicine.medicine_intakedays+" -
                                                                "+medicine.medicine_food}}
                                                            </td>

                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <br />
                                            <div
                                                style="width: 90%; margin-left: 25px !important;margin-right:25px !important">
                                                <mat-label class="matlabel"> <b>Next Visit :</b>
                                                    {{next_data}}</mat-label>
                                                <br />
                                                <br />
                                                <mat-label class="matlabel"> <b>In case of emergency contact :</b>
                                                    {{hosp_tel}}</mat-label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td width="100%">
                                            <table style="border:'0';width:100%">
                                                <tr>
                                                    <td style="width:100%;height: 100px;">&nbsp;</td>
                                                </tr>
                                            </table>
                                </tfoot>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <br />
        <table style="width: 100%; margin-top: 80px;">
            <tr>
                <td style="width: 50%;"></td>
                <td style="width: 50%;">
                    <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                </td>
            </tr>
        </table>
    </div>
</div>
<!-- end of print section -->