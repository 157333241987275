import { Component, OnInit, HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import { getIp, Ip_with_img_address } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, Subscription } from 'rxjs';
import * as $ from 'jquery/dist/jquery.min.js';
import { DentalService, TreatmentService } from '../service';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-dental-gums-teeth',
  templateUrl: './dental-gums-teeth.component.html',
  styleUrls: ['./dental-gums-teeth.component.css']
})
export class DentalGumsTeethComponent implements OnInit {
  public Reason_Loss_Txt_div;
  public subscription: Subscription;
  public disabled: boolean;
  public gen_hist;
  public gum_sur_txt;
  public phy_tons_txt;
  public lym_node_txt;
  public gum_pos;
  public gum_size;
  public Supraaerupted_txt_div: boolean;
  public Opencontact_Pathmig_Div: boolean;
  public gum_teeth_open_cont_req: boolean;
  public Tot_Causes_Div: boolean;
  public gum_other;
  public gum_perio_pock;
  public gum_exud;
  public fact_aff_col;
  public gum_cons;
  public gum_cont;
  public teeth_open_cont;
  public teeth_path_mig;
  public teeth_prost;
  public teeth_fract_teeth;
  public text_area_style;
  public teeth_reason_loss;
  public occ_txt;
  public teeth_sup;
  public den_hist_togg;
  public dental_last_vit_req: boolean;
  public den_hist_bru_cng;
  public den_hist_ot_aid
  public den_hist_mod_clean;
  public den_hist_sup;
  public den_hist_last_vit;
  public den_hist_prev;
  public den_hist_ple;
  public den_hist_beh_last_vit;
  public dietsession_txt: string;
  public food_type: string;
  public quantity_txt: string;
  public med_prepara: string;
  public qty_sugar_data: string;
  public gum_teeth_prothsys_req: boolean;
  public gum_teeth_path_mig_req: boolean;
  public gum_teeth_sup_erupt_req: boolean;
  public get_spl_name;
  public Surface_Size_txt_Div: boolean;
  public gumsTab: boolean;
  public pulse_txt;
  public respi_txt;
  public Gums_Others_txt: boolean;
  public panelOpenState10;
  public occ_div: boolean;
  public Fractured_txt_div: boolean;
  public Prosthesis_txt_div: boolean;
  public diabled: boolean;
  public client:boolean = false;
  constructor(public http: Http, public toastr: ToastrService, public messageservice: TreatmentService, public dentalservice: DentalService) {

    //  this.Gums_Others_txt = true;
    //   this.Prosthesis_txt_div = true;
    //   this.Fractured_txt_div = true;
    //   this.Reason_Loss_Txt_div = true;
    //   this.Tot_Causes_Div =true;
    //   this.Opencontact_Pathmig_Div = true;
    //   this.Supraaerupted_txt_div = true;
    // this.Opencontact_Pathmig_Div=true;
    // this.occ_div=true;    
  }
  ngOnInit() {
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;

    }else{
      this.client = false;
    }

    Helper_Class.Set_den_spl_gums(null);
    this.Prosthesis_txt_div = true;
    this.Fractured_txt_div = true;
    this.Reason_Loss_Txt_div = true;
    this.Gums_Others_txt=true;
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
        // var data=message.spl
        //this.get_spl_name=message.spl;
        
        Doc_Helper.Set_dent_spl(message.spl);
        this.speciality_change(message.spl);
        //this.send_data(data);
      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    this.gum_teeth_change();
    this.RetrivalFunction();
    this.FilterFields();

  }
  FilterFields() {
    var getret = Helper_Class.getHopitalfieldsRet();
    this.disabled = false;
    var dental_spl = Doc_Helper.get_dental_spl_name()
    if (getret != undefined) {
      for (var i = 0; i < getret.length; i++) {
        if (getret[i].page_name == "Speciality") {
          if (getret[i].fields != undefined) {

            if (getret[i].fields != undefined) {
              for (var j = 0; j < getret[i].fields.length; j++) {
                if (getret[i].fields[j].field_name == "Gait") {

                  // this.gait_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Soft tissue examination") {
                  //  this.soft_tissue = false;
                }
                if (getret[i].fields[j].field_name == "Tongue size") {
                  //  this.Tot_Tongue_Div = false;
                }
                if (getret[i].fields[j].field_name == "Tongue posture") {
                  //  this.Tot_Tongue_Div = false;
                }
                if (getret[i].fields[j].field_name == "Tongue shape") {
                  // this.Tot_Tongue_Div = false;
                }
                if (getret[i].fields[j].field_name == "Tongue movement") {
                  // this.Tot_Tongue_Div = false;
                }
                if (getret[i].fields[j].field_name == "Cephalic index") {
                  //  this.extra_div = false;
                }
                if (getret[i].fields[j].field_name == "Facial index") {
                  //this.facial_index_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Facial divergence") {
                  //   this.facial_div_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Lip posture") {
                  //   this.lip_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Incompetent") {
                  //  this.incomp_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Clinical FMA") {
                  //  this.clinical_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Upper lip") {
                  //   this.upper_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Lower lip") {
                  // this.low_lip_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Chin position") {
                  //  this.chin_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Naso labial angle") {
                  //   this.naso_hidden = false;
                } if (getret[i].fields[j].field_name == "Mentolabial sclcus") {
                  // this.mento_hidden = false;

                }
                if (getret[i].fields[j].field_name == "Facial profile") {
                  // this.fac_data_div = false;
                }
                if (getret[i].fields[j].field_name == "Shape of head") {
                  //   this.fac_data_div = false;
                }
                if (getret[i].fields[j].field_name == "Facial form") {
                  //  this.fac_data_div = false;
                }
                if (getret[i].fields[j].field_name == "Skeletal relationship") {
                  //    this.skeletal = false;
                }
                if (getret[i].fields[j].field_name == "Axial inclination") {
                  //  this.axial_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Other individual regularities") {
                  //   this.other_ind_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Dental midline") {
                  //   this.dental_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Skeletal midline") {
                  //  this.ske_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Curve of spee") {
                  //  this.cure_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Antero") {
                  //  this.antero_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Incisor relation") {
                  //  this.incisor_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Respiration") {
                  //  this.resp_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Path of closure") {
                  //  this.path_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Deglutition") {
                  //  this.deg_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Perioral muscle activity") {
                  //   this.perio_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Speech") {
                  // this.speech_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Mastication") {
                  // this.mast_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Interocclusal clearance") {
                  //  this.inter_fun_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Amount of incisor exposure") {
                  //    this.amt_expo_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Jaw function") {
                  //this.jaw_hidden = false;
                }
                if (getret[i].fields[j].field_name == "HO pain") {
                  // this.pain_hidden = false;
                }
                if (getret[i].fields[j].field_name == "HO sounds") {
                  //  this.sound_hidden = false;
                }
                if (getret[i].fields[j].field_name == "TMJ tenderness") {
                  // this.tmj_trend_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Muscle tenderness") {
                  // this.muscle_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Mandibular motion") {
                  //  this.range_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Birth history" && (this.get_spl_name == "Orthodontics" || this.get_spl_name == "Pediatrics")) {
                  // this.Birth_Hist_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Narrator") {
                  //this.narrator_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Type of delivery") {
                  //  this.dey_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Infectious disease of mother") {
                  //  this.dis_mot_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Gestation period") {
                  // this.gst_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Health Of mother") {
                  //  this.health_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Drug taken by mother") {
                  //this.drug_mot_hidden = false;
                }

                if (getret[i].fields[j].field_name == "Birth weight") {
                  //this.birth_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Rh compatibility") {
                  // this.rh_hidden = false;
                }

                if (getret[i].fields[j].field_name == "Bruxism") {
                  //  this.tongue_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Pubertal status") {
                  //  this.injuries_div = false;
                }
                if (getret[i].fields[j].field_name == "History of tonsillectomy") {
                  //  this.tons_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Pain worse") {
                  // this.pain_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Frequency of painful episode") {
                  //this.freq_episode_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Location") {
                  // this.loc_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Relaving factor") {
                  // this.rel_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Social history") {
                  //this.soc_hist_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Intensity of pain") {
                  //   this.int_pain_hidden = false;
                }

                if (getret[i].fields[j].field_name == "Type of pain") {
                  //  this.type_pain_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Speciality specific disease") {
                  //  this.spl_dis_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Special treatment") {
                  // this.bleed_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Operations") {
                  // this.serious_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Patient regular attender") {
                  // this.pat_att_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Patient motivation") {
                  // this.pat_mot_hidden = false;
                }
                // if (getret[i].fields[j].field_name == "Grade scale") {
                //   this.mal_hist_hidden = false;
                // }
                if (getret[i].fields[j].field_name == "Rating") {
                  //   this.rating_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Rating type") {
                  //   this.rating_type_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Description") {
                  //   this.description_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Reason") {
                  // this.reason_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Prolonged use of bottle at bed") {
                  //   this.pro_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Tooth decay appearance") {
                  // this.tooth_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Relevant medical history") {
                  // this.rel_med_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Over jet" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.overjet_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Over bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.overbite_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Cross bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.cross_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Cross bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.open_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Supernumerary teeth" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  //  this.sup_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Ectopic eruption" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  //  this.ectopic_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Rotations" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.rotations_hidden = false;
                  // this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Crowding / Spacing" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  // this.crowding_hidden = false;
                  //  this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Midline" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {

                }
                if (getret[i].fields[j].field_name == "Arch length maxilla" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {

                }
                if (getret[i].fields[j].field_name == "Arch length mandible" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {

                }
                if (getret[i].fields[j].field_name == "Analysis required " && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {

                }
                if (getret[i].fields[j].field_name == "Molar relation primary" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {

                }
                if (getret[i].fields[j].field_name == "Molar relation permanent" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                  //   this.molar_permenent_hidden = false;
                  //  this.Orthodontic_Pane = false;
                }
                if (getret[i].fields[j].field_name == "Last visit") {
                  // this.last_vit_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Pleasant / Unpleasant experience?") {
                  //this.ple_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Previous treatment") {
                  //this.pre_treat_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Behaviour during last visit") {
                  //.beh_last_vit_hidden = false;
                }

                if (getret[i].fields[j].field_name == "Oral hygiene practices") {
                  // this.oral_txt_hyg = false;
                }

                if (getret[i].fields[j].field_name == "Oral habitual history") {
                  //  this.oral_hab_div = false;
                }
              }
            }
          }
        }
      }
    }


  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    this.send_data();
  }
  speciality_change(e) {
    this.get_spl_name = Doc_Helper.get_dent_spl();
    

    if (this.get_spl_name == "General") {
      this.gumsTab = true
      this.Gums_Others_txt = true;
    }
    else if (this.get_spl_name == "Periodontics") {
      this.Tot_Causes_Div = false;
      this.gumsTab = false; Headers
      this.Gums_Others_txt = true;
      this.Surface_Size_txt_Div = false;
      this.Opencontact_Pathmig_Div = false;
      this.Supraaerupted_txt_div = false;
      this.occ_div = false;
      this.Prosthesis_txt_div = true;
      this.Fractured_txt_div = true;
      this.Reason_Loss_Txt_div = true;

    }
    else if (this.get_spl_name == "Prosthodontics") {
      this.Tot_Causes_Div = true;
      this.Gums_Others_txt = false;
      this.Prosthesis_txt_div = false;
      this.Fractured_txt_div = false;

    }
    else if (this.get_spl_name == "Endodontics") {
      this.gumsTab=true;
    }
    if (this.get_spl_name == "Public health") {
      this.Surface_Size_txt_Div=true;
      this.Prosthesis_txt_div=false;
      this.Opencontact_Pathmig_Div=true;
      this.Fractured_txt_div=false;
      this.occ_div=false;
      this.Reason_Loss_Txt_div=false;
    }
    //this.send_data(this.get_spl_name);
  }

  getColor(v) {
    if (v == "Yes") {
      return "green";
    }
    if (v = "No") {
      return "red";
    }
  }

  RetrivalFunction() {
    // retival
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
       // var data = message.spl
        this.speciality_change( message.spl);
        Doc_Helper.Set_dent_spl(message.spl)
        

      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    var getret1 = Helper_Class.Get_den_ret1()
    var getret2 = Helper_Class.Get_den_ret2()


    if (getret2 != null) {
      // gums and teeth
      if (getret2.surface_texture != null) {
        this.gum_sur_txt = getret2.surface_texture;
      }
      if (getret2.ped_pocket != null) {
        this.gum_perio_pock = getret2.ped_pocket;
      }
      if (getret2.affecting_colour != null) {
        this.fact_aff_col = getret2.affecting_colour;
      }
      if (getret2.contour != null) {
        this.gum_cont = getret2.contour;
      }
      if (getret2.consistency != null) {
        this.gum_cons = getret2.consistency;
      }
      if (getret2.exudate != null) {
        this.gum_exud = getret2.exudate;
      }
      if (getret2.position != null) {
        this.gum_pos = getret2.position;
      }
      if (getret2.size != null) {
        this.gum_size = getret2.size;
      }
      if (getret2.ging_other != null) {
        this.gum_other = getret2.ging_other;
      }
      if (getret2.open_contacts != null) {
        this.teeth_open_cont = getret2.open_contacts;
      }
      if (getret2.supraaerupted != null) {
        this.teeth_sup = getret2.supraaerupted;
      }
      if (getret2.pathologic_migration != null) {
        this.teeth_path_mig = getret2.pathologic_migration;
      }
      if (getret2.occlusion != null) {
        this.occ_txt = getret2.occlusion;
      }
      if (getret2.prosthesis != null) {
        this.teeth_prost = getret2.prosthesis;
      }
      if (getret2.fracture_teeth != null) {
        this.teeth_fract_teeth = getret2.fracture_teeth;
      }
      if (getret2.reason_loss != null) {
        this.teeth_reason_loss = getret2.reason_loss;
      }


      if (getret2.pharynx_tonsils != null) {
        this.phy_tons_txt = getret2.pharynx_tonsils;
      }
      if (getret2.lip != null) {
        //this.lip_type = getret2.lip;
      }
      if (getret2.oralym != null) {
        this.lym_node_txt = getret2.oralym;
      }

      if (getret2.asymmetries != null) {
        //this.ext_asy = getret2.asymmetries;
      }

      if (getret2.speech != null) {
        //this.ext_speech = getret2.speech;
      }

      if (getret2.body_type_id != null) {
        //this.body_type = getret2.body_type_id;
      }

      if (getret2.ped_body_type != null) {
        // this.body_type = getret2.ped_body_type;
      }



    }
  }
  send_data() {
    this.get_spl_name = Doc_Helper.get_dent_spl();
    

    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";

    if (this.den_hist_togg == true) {
      child_visit = "yes"
    }
    // general history spl
    // var this.gen_hist=null;
    //  this.gum_teeth_change();

    if (this.get_spl_name == "General") {
      var intr_flg = "no"
      this.gen_hist = {
        int_oral_flag: intr_flg,
        //intra oral
        //extra oral

      }

    }
    else if (this.get_spl_name == "Periodontics") {
      var super_tx = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";

      if (this.occ_txt != undefined) {
        teet_exampubflg = "yes";
      }
      if (this.gum_size != undefined || this.gum_other != undefined || this.gum_pos != undefined || this.gum_exud != undefined) {
        ging_flg = "yes";
        
      }
      if (this.teeth_path_mig != undefined || this.teeth_sup != undefined || this.teeth_open_cont != undefined) {
        texam_flg = "yes";
      }
      if (this.fact_aff_col != undefined || this.gum_cons != undefined || this.gum_perio_pock != undefined || this.gum_sur_txt != undefined || this.gum_cont != undefined) {
        gin_exfig = "yes";
      }

      // if (this.gum_size != undefined || this.gum_other != undefined || this.gum_pos != undefined || this.gum_exud != undefined) {
      //   ging_flg = "yes";
      // }


      var intr_flg = "no"
      this.gen_hist = {
        //den_was_flag: denwa_flg,
       // int_oral_flag: intr_flg,
        //supra_flag: super_tx,
        tee_exam_pub_flag: teet_exampubflg,
        teeth_exam_flag: texam_flg,
        gingiva_flag: ging_flg,
        gin_exam_flag: gin_exfig,
        // overbite_flag: over_flg,

        oralym: this.lym_node_txt,
        //gums and teeth
        surface_texture: this.gum_sur_txt,
        size: this.gum_size,
        other: this.gum_other,
        position: this.gum_pos,
        ped_pocket: this.gum_perio_pock,
        exudate: this.gum_exud,
        affecting_colour: this.fact_aff_col,
        consistency: this.gum_cons,
        contour: this.gum_cont,
        ging_other: this.gum_other,
        open_contacts: this.teeth_open_cont,
        pathologic_migration: this.teeth_path_mig,
        occlusion: this.occ_txt,
        supraaerupted: this.teeth_sup,

        // oral_hygiene: this.oral_hyg_sta,
      }
      
      Helper_Class.Set_den_spl_gums(this.gen_hist);

    }
    else if (this.get_spl_name == "Orthodontics") {
      var post_ex = "no", pre_txt = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";

      this.gen_hist = {
        overbite_flag: over_biteflg,
        habit_exists: hab_txt,
        post_exists: post_ex,

        int_oral_flag: intr_flg,
        oral_hygeine_flag: oral_falg,
        //birth_exists: birth_flag,

        physical_status_flag: physical_status_flag,

        //physical status


        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,


      }
      Helper_Class.Set_den_spl_gums(this.gen_hist);

    }

    else if (this.get_spl_name == "Pediatrics") {
      var ped_flag = "no", diet_nalsis_flg = "no", ped_histflg = "no", gen_flag = "no", case_pedflg = "no", condition_txt = "", descrption_data = "", freq_data = "", inten_data = "", dure_dataoral = "", age_what = "", cond_suck = "";
      var over_biteflg = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", ped_histflg = "no", over_flg = "no", soft_flag = "no", oral_habit = "Yes", type_habit_id = 0, child_went_home = "no";


      if (this.den_hist_togg == true || this.den_hist_last_vit != undefined || this.den_hist_ple != undefined || this.den_hist_prev != undefined ||
        this.den_hist_beh_last_vit != undefined || this.den_hist_sup != undefined || this.den_hist_mod_clean != undefined || this.den_hist_ot_aid != undefined) {
        ped_histflg = "yes";
      }
      var intr_flg = "no"
      this.gen_hist = {
        int_oral_flag: intr_flg,
        overbite_flag: over_biteflg,
        ped_oth_flag: ped_flag,
        habit_exists: hab_txt,
        post_exists: post_ex,
        //pre_exists: pre_txt,
        //birth_exists: birth_flag,
        // ped_per_hist_flag: ped_histflg,
        ort_eval_exists: over_flg,
        //    gin_ped_flag: gen_flag,
        //  softisoth_flag: soft_flag,
        case_ped_flag: case_pedflg,
        oralym: this.lym_node_txt,
        analysis_required: any_req,
        child_health_cond: child_went_home,
        den_habit_type_id: type_habit_id,
        condition: condition_txt,
        problem: cond_suck,
        description: descrption_data,
        frequency: freq_data,
        duration: dure_dataoral,
        intensity: inten_data,
        age_habit_stop: age_what,
        //diet_details: this.diet_details_array,
        diet_flag: diet_flag_arry,
        // ped_per_hist_flag:ped_histflg,
        diet_analysis_flag: diet_nalsis_flg,
        oral_habit_flag: oral_habit,

      }

    }
    else if (this.get_spl_name == "Prosthodontics") {
      var oral_falg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no";

      var intr_flg = "no"


      this.gen_hist = {
        int_oral_flag: intr_flg,
        oral_hygeine_flag: oral_falg,
        // resp_flag: resp_flgtxt,
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,

      }
      Helper_Class.Set_den_spl_gums(this.gen_hist);

    }
    else if (this.get_spl_name == "Public health") {
      var hab_txt = "no", post_ex = "no", diet_nalsis_flg = "no", vital_sign_flag = "no", soft_flag = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";
      if (this.gum_size != undefined || this.gum_other != undefined || this.gum_pos != undefined || this.gum_exud != undefined) {
        ging_flg = "yes";
      }

      if (this.respi_txt != undefined || this.pulse_txt != undefined) {
        vital_sign_flag = "yes"
      }
      this.gen_hist = {
        // den_was_flag: denwa_flg,
        //  int_oral_flag: intr_flg,
        habit_exists: hab_txt,
        post_exists: post_ex,
        diet_analysis_flag: diet_nalsis_flg,
        tee_exam_pub_flag: teet_exampubflg,

        teeth_exam_flag: texam_flg,
        gingiva_flag: ging_flg,
        gin_exam_flag: gin_exfig,
        vital_sign_flag: vital_sign_flag,
        //  softisoth_flag: soft_flag,

        //vital sign
        resp_rate: this.respi_txt,
        pulse_rate: this.pulse_txt,
        //intra oral
        //extra oral

        oralym: this.lym_node_txt,
        pharynx_tonsils: this.phy_tons_txt,
        //gums and teeth

        occlusion: this.occ_txt,
        supraaerupted: this.teeth_sup,

        diet_flag: diet_flag_arry,



      }
    }

    

   Helper_Class.Set_den_spl_gums(this.gen_hist);
    

  }

  gum_teeth_change() {

    if (this.get_spl_name == "Public health") {
      if ((this.teeth_path_mig != undefined && this.teeth_path_mig.length != 0) || (this.teeth_sup != undefined && this.teeth_sup.length != 0) || (this.gum_sur_txt != undefined && this.gum_sur_txt.length != 0) || (this.gum_size != undefined && this.gum_size.length != 0) || (this.gum_pos != undefined && this.gum_pos.length != 0) || (this.gum_perio_pock != undefined && this.gum_perio_pock.length != 0) ||
        (this.gum_exud != undefined && this.gum_exud.length != 0) || (this.fact_aff_col != undefined && this.fact_aff_col.length != 0) || (this.gum_cons != undefined && this.gum_cons.length != 0) || (this.gum_cont != undefined && this.gum_cont.length != 0)
        || (this.gum_other != undefined && this.gum_other.length != 0) || (this.occ_txt != undefined && this.occ_txt.length != 0) || (this.teeth_reason_loss != undefined && this.teeth_reason_loss.length != 0)) {
        this.gum_teeth_prothsys_req = true;
      }
      else {
        this.gum_teeth_prothsys_req = false;
      }
    }
    if (this.get_spl_name == "Periodontics") {
      if ((this.teeth_path_mig != undefined && this.teeth_path_mig.length != 0) || (this.teeth_sup != undefined && this.teeth_sup.length != 0) || (this.gum_sur_txt != undefined && this.gum_sur_txt.length != 0) || (this.gum_size != undefined && this.gum_size.length != 0) || (this.gum_pos != undefined && this.gum_pos.length != 0) || (this.gum_perio_pock != undefined && this.gum_perio_pock.length != 0) ||
        (this.gum_exud != undefined && this.gum_exud.length != 0) || (this.fact_aff_col != undefined && this.fact_aff_col.length != 0) || (this.gum_cons != undefined && this.gum_cons.length != 0) || (this.gum_cont != undefined && this.gum_cont.length != 0)
        || (this.gum_other != undefined && this.gum_other.length != 0) || (this.occ_txt != undefined && this.occ_txt.length != 0) || (this.teeth_sup != undefined && this.teeth_sup.length != 0)) {
        this.gum_teeth_open_cont_req = true;
        this.gum_teeth_path_mig_req = true;
        this.gum_teeth_sup_erupt_req = true;
      }
      else {
        this.gum_teeth_open_cont_req = false;
        this.gum_teeth_path_mig_req = false;
        this.gum_teeth_sup_erupt_req = false;
      }
    }
    
  }
}
