import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-clienthome',
  templateUrl: './clienthome.component.html',
  styleUrls: ['./clienthome.component.css'],
})
export class ClienthomeComponent implements OnInit {
  
  public sendProfileData: any;
  public updateUrl: string;
  public profilePersonalData: any;
  public profileAddressData: any;
  public clntRetrvProfileAddressData: any;
  public clntPresentAddress: any = {};
  public clntPermenantAddress: any = {};
  public sendClntAddressData: any = {};
  public locationUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public presentLocationList = [];
  public presentCityList;
  public presentStateList;
  public presentCountryList;
  public permenantLocationList = [];
  public permenantCityList;
  public permenantStateList;
  public permenantCountryList;
  public retrivalFlag: boolean;
  public sameasFlag: boolean;
  public presentLocationId: string;
  public presentCityId: string;
  public presentStateId: string;
  public presentCountryId: string;
  public premLocationId: string;
  public premCityId: string;
  public premStateId: string;
  public premCountryId: string;
 //payment
  public packageModels;
  public mainPackage;
  public packIdList = [];
  public packageName;
  public packageValidity;
  public clientPackageData:any;

  //personal
  public bloodFlag:boolean=false;
  public downArrowFlag:boolean=false;
  public retEmailId: any;
  public retMobile: any;
  public retDob: boolean = false;
  public clientPersonalList: any = {};
  public clientAddressList: any = {};
  public clientPackageList: any = {};
  public dobFlag: boolean;

  public bloodGrpList: any;
  public languageList: any;
  public maritalStatusList: any = [];
  public referredDoctorList = [];
  public referredDoctor = [];
  public imageFileStr: any;
  public profileImg: string;
  public referTxt: string;

  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.clientPersonalList.citizenShip = "Indian";
    this.clientPersonalList.nationality = "Indian";
    this.maritalStatusList = ["Single", "Bachelor", "Spinster", "Married", "Divorced", "Seperated", "Widowed"];
    this.locationUrl = ipaddress.getIp + "gen/loc";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";

    this.clntPresentAddress.city = null;
    this.clntPresentAddress.state = null;
    this.clntPresentAddress.country = null;
    this.clntPermenantAddress.city = null;
    this.clntPermenantAddress.state = null;
    this.clntPermenantAddress.country = null;
  }


  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    if (this.retrivalFlag == true)
      this.dataLoad();
  }

  ngOnInit(): void {
    if (this.gservice.Client_login_detail_data != undefined) {
      this.retrivalFlag = true;
    }else{
      this.retrivalFlag = false;
      this.sameasFlag = false;
    }

    if (this.retrivalFlag == true){ // profile
      this.retrival();
    }else{ // registration
      this.retrivalFlag = false;
      this.dobFlag = false;
      this.getLanguageList('0');
      this.getBloodgroupList('0');
      this.imageFileStr = "assets/imgs/default.jpg";
    }
  }

  playText(txt) {
    // this.tts.speak({
    //   text: txt,
    //   rate: 1,
    //   locale: 'en-US'
    // })
    //   .then(() => 
    //   .catch((reason: any) => 
  }

  getLanguageList(retrivel_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/lng/", { headers: headers }).subscribe(
      data => {

        if (data.json().languages != null) {
          this.languageList = data.json().languages;
          if (retrivel_data != "0") {
            for (var s = 0; s < this.languageList.length; s++) {
              if (this.languageList[s].language_id == retrivel_data) {
                this.clientPersonalList.language = this.languageList[s];
                break;
              }
            }
          }
        }
      },error => {});
  }

  getBloodgroupList(retrivel_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "bd/bgroup/", { headers: headers }).subscribe(
      data => {
        if (data.json().blood_groups != null) {
          this.bloodGrpList = data.json().blood_groups;

          if (retrivel_data != "null" && retrivel_data != null) {
            for (var t = 0; t < this.bloodGrpList.length; t++) {
              if (this.bloodGrpList[t].blood_group_id == retrivel_data) {
                this.clientPersonalList.bloodGroup = this.bloodGrpList[t];
                break;
              }
            }
          }
        }
      },error => {});
  }

  fileChange(data) {// Image selection Web 
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);

      if (fileSize >= 50) {
        // $scope.Imagepath == "default"
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.clientPersonalList.profile_image = "default";
        this.imageFileStr = "../../../assets/img/default.jpg";
      } else {
        this.imageFileStr = myReader.result;
        this.clientPersonalList.profile_image = this.imageFileStr.split(',')[1];
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  retrival() {// Retrival data
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/clientdetails/", JSON.stringify({ user_id: this.gservice.Client_login_detail_data.Client_Id, country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        if (data.json().client_id != null) {
          this.clientPersonalList.client_id = data.json().client_id;
          this.imageFileStr = ipaddress.Ip_with_img_address + data.json().profile_image;
          this.profileImg = data.json().profile_image;

          if (data.json().profile_image == "default") {
            this.clientPersonalList.profile_image = "default";
            this.clientPersonalList.profile_image_flag = "default";
          } else {
            try {
              var img1 = document.getElementById("profile_img1") as HTMLImageElement;
              img1.src = ipaddress.Ip_with_img_address + data.json().profile_image;
            }
            catch (error) {}
            this.clientPersonalList.profile_image = this.profileImg;
            this.clientPersonalList.profile_image_flag = "default";
          }

          this.clientPersonalList.first_name = encrypt_decript.Decript(data.json().first_name.toString());
          if (data.json().middle_name != null) {
            this.clientPersonalList.middle_name = encrypt_decript.Decript(data.json().middle_name.toString());
          }

          this.clientPersonalList.last_name = encrypt_decript.Decript(data.json().last_name.toString());
          if (data.json().alias_name != null) {
            this.clientPersonalList.alias_name = encrypt_decript.Decript(data.json().alias_name.toString());
          }

          this.clientPersonalList.gender = encrypt_decript.Decript(data.json().gender.toString());
          if (data.json().dob != null && data.json().dob != "") {
            this.clientPersonalList.doB = data.json().dob;
            this.retDob = true;
          }

          if (data.json().Occupation != null) 
            this.clientPersonalList.Occupation = encrypt_decript.Decript(data.json().Occupation).toString();

          if (data.json().education != null) 
            this.clientPersonalList.education = encrypt_decript.Decript(data.json().education.toString());
          
          if (data.json().referred_by != null) 
            this.referTxt = data.json().referred_by.toString();
          
          if (data.json().email != null) {
            this.retEmailId = encrypt_decript.Decript(data.json().email.toString());
            this.clientPersonalList.email = encrypt_decript.Decript(data.json().email.toString());
            this.clientPersonalList.retrival_email = encrypt_decript.Decript(data.json().email.toString());
          } else {
            this.clientPersonalList.retrival_email = "0";
          }

          if (data.json().mobile != null) {
            this.retMobile = encrypt_decript.Decript(data.json().mobile.toString());
            this.clientPersonalList.mobile = encrypt_decript.Decript(data.json().mobile.toString());
            this.clientPersonalList.retrival_mobile = encrypt_decript.Decript(data.json().mobile.toString());
          }

          if (data.json().blood_group != null) {
            this.clientPersonalList.bloodGroup = data.json().blood_group;
            this.getBloodgroupList(data.json().blood_group);
          } else {
            this.getBloodgroupList('0');
          }

          if (data.json().language_id != null) 
            this.getLanguageList(data.json().language_id);
          else 
            this.getLanguageList('0');

          if (data.json().mother_maiden_name != null) 
            this.clientPersonalList.motherName = encrypt_decript.Decript(data.json().mother_maiden_name.toString());
          
          if (data.json().marital_status != null) {
            this.clientPersonalList.marital_status = encrypt_decript.Decript(data.json().marital_status.toString());

            if (data.json().spouse_name != null) {
              this.clientPersonalList.spouseName = encrypt_decript.Decript(data.json().spouse_name.toString());
            }
          }

          if (data.json().citizenship != null) 
            this.clientPersonalList.citizenShip = encrypt_decript.Decript(data.json().nationality.toString());

          if (data.json().nationality != null) 
            this.clientPersonalList.nationality = encrypt_decript.Decript(data.json().nationality.toString());

          if (data.json().blood_donation != null) {
            if (data.json().blood_donation.toString() == "1") {
              this.clientPersonalList.blood_donation = true;
            } else {
              this.clientPersonalList.blood_donation = false;
            }
          } else {
            this.clientPersonalList.blood_donation = false;
          }

          if (data.json().address != null) {
            for (var j = 0; j < data.json().address.length; j++) {
              if (data.json().address[j].type == "pres") {
                this.clientAddressList.presentAddress = {};
                this.clientAddressList.presentAddress.address1 = encrypt_decript.Decript(data.json().address[j].address1.toString());

                if (data.json().address[j].address2) {
                  this.clientAddressList.presentAddress.address2 = encrypt_decript.Decript(data.json().address[j].address2.toString());
                }

                this.clientAddressList.presentAddress.id_city = data.json().address[j].city;
                this.clientAddressList.presentAddress.id_state = data.json().address[j].state;
                this.clientAddressList.presentAddress.id_country = data.json().address[j].country;
                this.clientAddressList.presentAddress.location = data.json().address[j].location_desc;
                this.clientAddressList.presentAddress.zipcode = encrypt_decript.Decript(data.json().address[j].zipcode.toString());
                this.clientAddressList.presentAddress.city = {
                  "city_id": data.json().address[j].city,
                  "city_desc": data.json().address[j].city_desc,
                  "zipcode": this.clientAddressList.presentAddress.zipcode,
                  "location_id": data.json().address[j].location
                };
                this.clientAddressList.presentAddress.state = { "state_id": data.json().address[j].state, "state_desc": data.json().address[j].state_desc };
                this.clientAddressList.presentAddress.country = { "country_id": data.json().address[j].country, "country_desc": data.json().address[j].country_desc };

                if (data.json().address[j].tel_home != null)
                  this.clientAddressList.presentAddress.tel_home = encrypt_decript.Decript(data.json().address[j].tel_home.toString());
                
                if (data.json().address[j].tel_business != null) 
                  this.clientAddressList.presentAddress.tel_business = encrypt_decript.Decript(data.json().address[j].tel_business.toString());

                this.clientAddressList.presentAddress.permanent = data.json().address[j].permanent;
                this.clientAddressList.presentAddress.type = data.json().address[j].type;
                this.clientAddressList.presentAddress.permanent = "1";
              } else if (data.json().address[j].type == "perm") {
                this.clientAddressList.permenantAddress = {};
                this.clientAddressList.permenantAddress.address1 = encrypt_decript.Decript(data.json().address[j].address1.toString());

                if (data.json().address[j].address2) 
                  this.clientAddressList.permenantAddress.address2 = encrypt_decript.Decript(data.json().address[j].address2.toString());
               
                this.clientAddressList.permenantAddress.id_city = data.json().address[j].city;
                this.clientAddressList.permenantAddress.id_state = data.json().address[j].state;
                this.clientAddressList.permenantAddress.id_country = data.json().address[j].country;
                this.clientAddressList.permenantAddress.location = data.json().address[j].location_desc;
                this.clientAddressList.permenantAddress.zipcode = encrypt_decript.Decript(data.json().address[j].zipcode.toString());

                if (data.json().address[j].tel_home != null) 
                  this.clientAddressList.permenantAddress.tel_home = encrypt_decript.Decript(data.json().address[j].tel_home.toString());

                if (data.json().address[j].tel_business != null) 
                  this.clientAddressList.permenantAddress.tel_business = encrypt_decript.Decript(data.json().address[j].tel_business.toString());

                this.clientAddressList.permenantAddress.permanent = data.json().address[j].permanent;
                this.clientAddressList.permenantAddress.type = data.json().address[j].type;
              }
            }
          }

          if (data.json().pkg_desc != null) {
            this.clientPackageList.package = data.json().pkg_desc;
            this.clientPackageList.package_validity = data.json().sub_desc;
          }

          // Set data helper_class
          this.gservice.set_client_profile_personal_data(this.clientPersonalList);
          this.gservice.set_client_profile_address_data(this.clientAddressList);
          this.gservice.set_client_profile_package_data(this.clientPackageList);
          this.addressRetrival();
          
          this.clientPackageData = this.gservice.get_client_profile_package_data();

          if (this.gservice.get_client_profile_package_data()!=null && this.clientPackageData.package != null) {
            this.packageName = this.clientPackageData.package;
            this.packageValidity = this.clientPackageData.package_validity;
          }      
          this.package();      
        }
      },error => {});
  }

  sameAddress(e){
    this.sameasFlag=e;
  }

  selectBloodDonate() {// Blood donate
    this.bloodFlag =! this.bloodFlag;
    if (this.clientPersonalList.blood_donation == undefined || this.clientPersonalList.blood_donation == false) {
      this.clientPersonalList.blood_donation = true;

    } else if (this.clientPersonalList.blood_donation == true) {
      this.clientPersonalList.blood_donation = false;
    }
  }

    DOB_popup() {// DOB popup
    }
  
    numberOnlyCheck(ev) {
      var img1 = document.getElementById(ev) as HTMLInputElement;
      var getValue = "";
    }
  
    myFunction() {
      if ($("#myDIV").is(":visible")) {
        $("#myDIV").hide();
        $('#toggleImg').attr('src', 'assets/imgs/minussybl.png');
      } else {
        $("#myDIV").show();
        $('#toggleImg').attr('src', 'assets/imgs/minus.png');
      }
    }

    emailCheck1() {
      if (this.clientPersonalList.email != this.retEmailId) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
  
        this.http.post(ipaddress.getIp + "usercontroller/isclient/",
          JSON.stringify({
            input: encrypt_decript.Encript(this.clientPersonalList.email).toString(),
            flag: "65"
          }), { headers: headers }).subscribe(
            data => {
              
  
              if (data.json().result == true) {
                this.toastr.error(Message_data.existEmailID);
                this.clientPersonalList.Email_exits = true;
                this.gservice.set_client_profile_personal_data(this.clientPersonalList);
  
              } else {
               // this.mobileCheck();
              }
            },
            error => {
              
            })
  
      } else {
       // this.mobileCheck();
      }
    }
  
    mobileCheck() {
      if (this.clientPersonalList.mobile != this.retMobile) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
  
        this.http.post(ipaddress.getIp + "usercontroller/isclient/",
          JSON.stringify({
            input: encrypt_decript.Encript(this.clientPersonalList.mobile).toString(),
            flag: "1"
          }), { headers: headers }).subscribe(
            data => {
              
  
              if (data.json().result == true) {
                this.toastr.error(Message_data.existMobileNo);
                this.clientPersonalList.Mobile_exits = true;
                this.gservice.set_client_profile_personal_data(this.clientPersonalList);
  
              } else {
                this.setImageData();
              }
            },
            error => {
              
            })
  
      } else {
        this.setImageData();
        this.emailCheck1();
      }
    }

    @HostListener('window:click', ['$event'])
    onclick(event: any): void {
      if (this.retrivalFlag == true){
        this.setImageData();
        this.dataLoad();
     }
    }
  
    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
      if (this.retrivalFlag == true)
        this.setImageData();
    }
  
  setImageData() { // set dataa to helper class
    if (this.retrivalFlag == false){ // registration
      if (this.imageFileStr == "../../../assets/img/default.jpg") {
        this.clientPersonalList.profile_image = "default";
        this.clientPersonalList.profile_image_flag = "default";
      } else {
        if (this.imageFileStr != undefined)
          this.clientPersonalList.profile_image = this.imageFileStr.split(',')[1];
          this.clientPersonalList.profile_image_flag = "image";
      }
    } else {// Profile
      if (this.imageFileStr != ipaddress.Ip_with_img_address + this.profileImg) {// Compare retrivel image != selected image
        if (this.imageFileStr != "../../../assets/img/default.jpg") {
          if (this.imageFileStr != undefined)
            this.clientPersonalList.profile_image = this.imageFileStr.split(',')[1];
          this.clientPersonalList.profile_image_flag = "image";
        } else {
          this.clientPersonalList.profile_image = this.profileImg;
          this.clientPersonalList.profile_image_flag = "default";
        }
      } else {
        this.clientPersonalList.profile_image = this.profileImg;
        this.clientPersonalList.profile_image_flag = "default";
      }
    }
    this.gservice.set_client_profile_personal_data(this.clientPersonalList);
  }

  selectDOB(date) {
    if (this.gservice.Client_login_detail_data == null || this.retDob == false) {// profile
      this.clientPersonalList.doB = date;
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  clearDocRefferal() {
    this.referTxt = null;
    this.referredDoctorList = [];
  }

  fnameToUpper(){
    if(this.clientPersonalList.first_name != undefined)
      this.clientPersonalList.first_name = this.clientPersonalList.first_name.toLocaleUpperCase();
  }

  mnameToUpper(){
    if(this.clientPersonalList.middle_name != undefined)
      this.clientPersonalList.middle_name = this.clientPersonalList.middle_name.toLocaleUpperCase();
  }

  lnameToUpper(){
    if(this.clientPersonalList.last_name != undefined)
      this.clientPersonalList.last_name = this.clientPersonalList.last_name.toLocaleUpperCase();
   }

  validation() { // validation
    if (this.clientPersonalList.first_name == undefined || this.clientPersonalList.last_name == undefined 
      || this.clientPersonalList.gender == undefined || this.clientPersonalList.mobile == undefined) {
      this.toastr.error(Message_data.mandatory);
    } else if ((this.clientPersonalList.marital_status != undefined && this.clientPersonalList.marital_status == "Married" && this.clientPersonalList.spouseName == undefined) ||
      (this.clientPersonalList.marital_status != undefined && this.clientPersonalList.marital_status == "Widowed" && this.clientPersonalList.spouseName == undefined)) {
      this.toastr.error(Message_data.enterSpouseName);
    } else if (this.clientPersonalList.mobile.length != 10) {
      this.toastr.error(Message_data.validMobileNo);
    } else if (this.retrivalFlag == false && (this.clientPersonalList.password == undefined || this.clientPersonalList.con_password == undefined)) {
      this.toastr.error(Message_data.mandatory);
    } else if (this.clientPersonalList.password != this.clientPersonalList.con_password) {
      this.toastr.error(Message_data.pwdNotMatched);
    } else if (this.retrivalFlag == false && (this.dobFlag == false && (this.clientPersonalList.doB == undefined || this.clientPersonalList.doB == ""))) {
      this.DOB_popup();
    } else {
      this.gservice.reg_client_personal_flag = true;
      this.mobileCheck();
    }
  }

  dataLoad() {
   if (this.gservice.reg_client_personal_flag == true) {
      this.gservice.set_client_profile_address_data(null);
      if (this.clntPresentAddress.address1 == undefined || this.clntPresentAddress.location == undefined || this.clntPresentAddress.city == undefined ||
        this.clntPresentAddress.state == undefined || this.clntPresentAddress.country == undefined || this.clntPresentAddress.zipcode == undefined) {
        if (this.retrivalFlag != true) // profile
          this.toastr.error(Message_data.enterPermenantAddrMandatory);
      }else if ((this.sameasFlag == undefined || this.sameasFlag == false) && (this.clntPermenantAddress.address1 == undefined || this.clntPermenantAddress.location == undefined || this.clntPermenantAddress.city == undefined ||
        this.clntPermenantAddress.state == undefined || this.clntPermenantAddress.country == undefined || this.clntPermenantAddress.zipcode == undefined)) {
        if (this.retrivalFlag != true) // profile
          this.toastr.error(Message_data.enterPermenantAddrMandatory);
      }else {// validation Completed
        this.gservice.reg_client_address_flag = true;

        if (this.clntPresentAddress.address1 != null) {
          this.sendClntAddressData.presentAddress = this.clntPresentAddress;
          this.sendClntAddressData.presentAddress.type = "pres";

          if (this.sameasFlag != true) {
            this.sendClntAddressData.presentAddress.permanent = "0";
            this.sendClntAddressData.permenantAddress = this.clntPermenantAddress;
            this.sendClntAddressData.permenantAddress.type = "perm";
            this.sendClntAddressData.permenantAddress.permanent = "0";
          }else {
            this.sendClntAddressData.presentAddress.permanent = "1";
          }

          var presentadd2 = undefined, permadd2 = undefined;
          if (this.clntPresentAddress.address2 != undefined) 
            presentadd2 = encrypt_decript.Encript(this.clntPresentAddress.address2).toString();

          if (this.clntPermenantAddress.address2 != undefined) 
            permadd2 = encrypt_decript.Encript(this.clntPermenantAddress.address2).toString();

          var add1 = undefined, zipcode = undefined, telNumber = undefined, telBus = undefined;
          if (this.clntPresentAddress.address1 != undefined)
            add1 = encrypt_decript.Encript(this.clntPresentAddress.address1).toString()
          if (this.clntPresentAddress.zipcode != undefined)
            zipcode = encrypt_decript.Encript(this.clntPresentAddress.zipcode).toString()
          if (this.clntPresentAddress.tel_home != undefined)
            telNumber = encrypt_decript.Encript(this.clntPresentAddress.tel_home).toString()
          if (this.clntPresentAddress.tel_business != undefined)
            telBus = encrypt_decript.Encript(this.clntPresentAddress.tel_business).toString()

          if (this.sameasFlag != undefined && this.sameasFlag == true){ //Same as UnChechked
            var PreClient_address = [{
              address1: add1,
              address2: presentadd2,
              location: this.presentLocationId,
              city: this.presentCityId,
              state: this.presentStateId,
              country: this.presentCountryId,
              zipcode: zipcode,
              tel_home: telNumber,
              tel_business: telBus,
              is_permanent: "1",
              type: "pres",
            }];
          } else{ //Same as Chechked
            var permadd1 = undefined, permzipcode = undefined, permtelNumber = undefined, permtelBus = undefined;
            if (this.clntPermenantAddress.address1 != undefined)
              permadd1 = encrypt_decript.Encript(this.clntPermenantAddress.address1).toString()
            if (this.clntPermenantAddress.zipcode != undefined)
              permzipcode = encrypt_decript.Encript(this.clntPermenantAddress.zipcode).toString()
            if (this.clntPermenantAddress.tel_home != undefined)
              permtelNumber = encrypt_decript.Encript(this.clntPermenantAddress.tel_home).toString()
            if (this.clntPermenantAddress.tel_business != undefined)
              permtelBus = encrypt_decript.Encript(this.clntPermenantAddress.tel_business).toString()

            PreClient_address = [{
              address1: add1,
              address2: presentadd2,
              location: this.presentLocationId,
              city: this.presentCityId,
              state: this.presentStateId,
              country: this.presentCountryId,
              zipcode: zipcode,
              tel_home: telNumber,
              tel_business: telBus,
              is_permanent: "0",
              type: "pres",
            }, {
              address1: permadd1,
              address2: permadd2,
              location: this.premLocationId,
              city: this.premCityId,
              state: this.premStateId,
              country: this.premCountryId,
              zipcode: permzipcode,
              tel_home: permtelNumber,
              tel_business: permtelBus,
              is_permanent: "0",
              type: "perm",
            }];
          }
          
          var sendadata = {
            address: PreClient_address
          }
          Helper_Class.set_reg_work_array(sendadata);
          this.gservice.set_client_profile_address_data(this.sendClntAddressData);
        }
      }
    }
  }

  update() {
    this.validation();
    this.profilePersonalData = this.gservice.get_client_profile_personal_data();
    this.profileAddressData = this.gservice.get_client_profile_address_data();
    var validation_flag = false;

    if (this.profileAddressData == null) {
      this.toastr.error(Message_data.enterAddrMandatory);
      validation_flag = true;
    } else if (this.profilePersonalData.gender == null ||
      this.profilePersonalData.email == undefined || this.profilePersonalData.mobile == undefined) {
      validation_flag = true;
      this.toastr.error(Message_data.enterAddrMandatory);
    } else if ((this.profilePersonalData.marital_status != null && this.profilePersonalData.marital_status == "Married" && this.profilePersonalData.spouseName == undefined) ||
      (this.profilePersonalData.marital_status != null && this.profilePersonalData.marital_status == "Widowed" && this.profilePersonalData.spouseName == undefined)) {
      validation_flag = true;
      this.toastr.error(Message_data.enterSpouseName);
    } else if (this.profilePersonalData.mobile.length != 10) {
      validation_flag = true;
      this.toastr.error(Message_data.validMobileNo);
    } else if (this.profileAddressData.presentAddress.address1 == undefined || this.profileAddressData.presentAddress.location == undefined || this.profileAddressData.presentAddress.city == undefined ||
      this.profileAddressData.presentAddress == undefined || this.profileAddressData.presentAddress.country == undefined || this.profileAddressData.presentAddress.zipcode == undefined) {
      validation_flag = true;
      this.toastr.error(Message_data.mandatory);
    } else if (this.profileAddressData.presentAddress.address1 == "" || this.profileAddressData.presentAddress.location == "") {
      validation_flag = true;
      this.toastr.error(Message_data.enterPresentAddrMandatory);
    } else if (this.profileAddressData.presentAddress.permanent == "0")  {// Sameas unchecked
      if (this.profileAddressData.permenantAddress.address1 == undefined || this.profileAddressData.permenantAddress.location == undefined || this.profileAddressData.permenantAddress.city == undefined ||
        this.profileAddressData.permenantAddress.state == undefined || this.profileAddressData.permenantAddress.country == undefined || this.profileAddressData.permenantAddress.zipcode == undefined) {
        validation_flag = true;
        this.toastr.error(Message_data.mandatory);
      } else if (this.profileAddressData.permenantAddress.address1 == "" || this.profileAddressData.permenantAddress.location == "") {
        validation_flag = true;
        this.toastr.error(Message_data.enterPermenantAddrMandatory);
      }
    }
    if (validation_flag == false){ // Validation completed
      if (this.profilePersonalData.email != null){ // Email entered
        var valid_email = this.CheckValidEmail(); // Check whether email is valid or not

        if (valid_email == true) {
          if (this.profilePersonalData.retrival_email != null && this.profilePersonalData.retrival_email != 0){ // Email retrived
            if (this.profilePersonalData.email != this.profilePersonalData.retrival_email) // check email id changed
              this.emailCheck(this.profilePersonalData.email);
            else if (this.profilePersonalData.mobile != this.profilePersonalData.retrival_mobile) // mobile number changed
              this.mobileCheck1();
            else
              this.Update();
          }else{ // newly enterd email
            this.emailCheck(this.profilePersonalData.email);
          }
        }else {
          this.toastr.error(Message_data.validEmail);
        }
      } else {// email id not entered
        if (this.profilePersonalData.mobile != this.profilePersonalData.retrival_mobile) // mobile number changed
          this.mobileCheck1();
        else 
          this.Update();
      }
    }
  }

  CheckValidEmail(){ // Check whether email is valid or not
    return true;
  }

  emailCheck(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "usercontroller/isclient/",
      JSON.stringify({
        input: encrypt_decript.Encript(data).toString(),
        flag: "65"
      }), { headers: headers }).subscribe(
        data => {
          
          if (data.json().result == true) {
            this.toastr.error(Message_data.existEmailID);
          }
          else {
            if (this.profilePersonalData.mobile != this.profilePersonalData.retrival_mobile) {
              this.mobileCheck1();
            }
            else {
              this.Update();
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        })
  }

  mobileCheck1() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "usercontroller/isclient/",
      JSON.stringify({
        input: encrypt_decript.Encript(this.profilePersonalData.mobile).toString(),
        flag: "1"
      }), { headers: headers }).subscribe(
        data => {
          

          if (data.json().result == true) {
            this.toastr.error(Message_data.existMobileNo);
          }
          else {
            this.Update();
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        })
  }

  Update() {
    var valid_flag = false;
    if (this.profilePersonalData.first_name != null) {
      this.sendProfileData = {};

      this.sendProfileData.client_id = this.profilePersonalData.client_id;
      this.sendProfileData.first_name = encrypt_decript.Encript(this.profilePersonalData.first_name).toString();

      if (this.profilePersonalData.middle_name != null) {
        this.sendProfileData.middle_name = encrypt_decript.Encript(this.profilePersonalData.middle_name).toString();
     }

      this.sendProfileData.last_name = encrypt_decript.Encript(this.profilePersonalData.last_name).toString();

      if (this.profilePersonalData.alias_name != null) {
        this.sendProfileData.alias_name = encrypt_decript.Encript(this.profilePersonalData.alias_name).toString();
      }
      if (this.profilePersonalData.Occupation != null) {
        this.sendProfileData.Occupation = encrypt_decript.Encript(this.profilePersonalData.Occupation).toString();
      }
      if (this.profilePersonalData.gender != null) {
        this.sendProfileData.gender = encrypt_decript.Encript(this.profilePersonalData.gender).toString();
      }

      if (this.profilePersonalData.education != null) {
        this.sendProfileData.education = encrypt_decript.Encript(this.profilePersonalData.education).toString();
      }
      
      if (this.profilePersonalData.doB != null) {
        this.sendProfileData.dob = this.profilePersonalData.doB;
      }

      if (this.profilePersonalData.bloodGroup != null) {
        this.sendProfileData.blood_group = this.profilePersonalData.bloodGroup.blood_group_id;
      }

      if (this.profilePersonalData.language != null) {
        this.sendProfileData.language = this.profilePersonalData.language.language_id;
      }

      if (this.profilePersonalData.email != null) {
        this.sendProfileData.email = encrypt_decript.Encript(this.profilePersonalData.email).toString();
      }

      if (this.profilePersonalData.mobile != null) {
        this.sendProfileData.mobile = encrypt_decript.Encript(this.profilePersonalData.mobile).toString();
      }
      if (this.profilePersonalData.motherName != null) {
        this.sendProfileData.mother_maiden_name = encrypt_decript.Encript(this.profilePersonalData.motherName).toString();
      }
      if (this.profilePersonalData.marital_status != null) {
        this.sendProfileData.marital_status = encrypt_decript.Encript(this.profilePersonalData.marital_status).toString();
      }
      if (this.profilePersonalData.marital_status == "Married" || this.profilePersonalData.marital_status == "Widowed") {
        this.sendProfileData.spouse_name = encrypt_decript.Encript(this.profilePersonalData.spouseName).toString();
      }

      if (this.profilePersonalData.citizenShip != null) {
      this.sendProfileData.citizenShip = encrypt_decript.Encript(this.profilePersonalData.citizenShip).toString();
      }
      if (this.profilePersonalData.nationality != null) {
        this.sendProfileData.nationality = encrypt_decript.Encript(this.profilePersonalData.nationality).toString();
      }
      if (this.profilePersonalData.blood_donation != null && this.profilePersonalData.blood_donation == true) {
        this.sendProfileData.blood_donation = "1";

      } else {
        this.sendProfileData.blood_donation = "0";
      }

      // Address Data 

      if (this.profileAddressData.presentAddress.address2 != null) {
        var pres_address2 = encrypt_decript.Encript(this.profileAddressData.presentAddress.address2).toString();
      }
      
      if (this.profileAddressData.presentAddress.tel_home != null) {
        var pres_home_num = encrypt_decript.Encript(this.profileAddressData.presentAddress.tel_home).toString();
      }
      
      if (this.profileAddressData.presentAddress.tel_business != null) {
        var pres_buss_num = encrypt_decript.Encript(this.profileAddressData.presentAddress.tel_business).toString();
      }


      if (this.profileAddressData.permenantAddress != null) {
        if (this.profileAddressData.permenantAddress.address2 != null) {
          var perm_address2 = encrypt_decript.Encript(this.profileAddressData.permenantAddress.address2).toString();
        }
        if (this.profileAddressData.permenantAddress.tel_home != null) {
          var perm_home_num = encrypt_decript.Encript(this.profileAddressData.permenantAddress.tel_home).toString();
        }
        if (this.profileAddressData.permenantAddress.tel_business != null) {
          var perm_buss_num = encrypt_decript.Encript(this.profileAddressData.permenantAddress.tel_business).toString();
        }

        this.sendProfileData.address = [{
          address1: encrypt_decript.Encript(this.profileAddressData.presentAddress.address1).toString(),
          address2: pres_address2,
          location: this.profileAddressData.presentAddress.city.location_id,
          city: this.profileAddressData.presentAddress.city.city_id,
          state: this.profileAddressData.presentAddress.state.state_id,
          country: this.profileAddressData.presentAddress.country.country_id,
          zipcode: encrypt_decript.Encript(this.profileAddressData.presentAddress.city.zipcode).toString(),
          tel_business: pres_buss_num,
          tel_home: pres_home_num,
          type: this.profileAddressData.presentAddress.type,
          is_permanent: this.profileAddressData.presentAddress.permanent,
        },
        {
          address1: encrypt_decript.Encript(this.profileAddressData.permenantAddress.address1).toString(),
          address2: perm_address2,
          location: this.profileAddressData.permenantAddress.city.location_id,
          city: this.profileAddressData.permenantAddress.city.city_id,
          state: this.profileAddressData.permenantAddress.state.state_id,
          country: this.profileAddressData.permenantAddress.country.country_id,
          zipcode: encrypt_decript.Encript(this.profileAddressData.permenantAddress.city.zipcode).toString(),
          tel_business: perm_buss_num,
          tel_home: perm_home_num,
          type: this.profileAddressData.permenantAddress.type,
          is_permanent: this.profileAddressData.permenantAddress.permanent,
        }]
      }
      else {
        this.sendProfileData.address = [{
          address1: encrypt_decript.Encript(this.profileAddressData.presentAddress.address1).toString(),
          address2: pres_address2,
          location: this.profileAddressData.presentAddress.city.location_id,
          city: this.profileAddressData.presentAddress.city.city_id,
          state: this.profileAddressData.presentAddress.state.state_id,
          country: this.profileAddressData.presentAddress.country.country_id,
          zipcode: encrypt_decript.Encript(this.profileAddressData.presentAddress.city.zipcode).toString(),
          tel_business: pres_buss_num,
          tel_home: pres_home_num,
          type: this.profileAddressData.presentAddress.type,
          is_permanent: this.profileAddressData.presentAddress.permanent,
        }]
      }
    }

    if (valid_flag == false) {
     
      if (this.profilePersonalData.profile_image_flag == "default") {
        this.updateUrl = ipaddress.getIp + "usercontroller/updclnt/";
        this.sendProfileData.profile_image = this.profilePersonalData.profile_image;
      }
      else {
      
        this.updateUrl = ipaddress.getIp + "usercontroller/updclntweb/";
        this.sendProfileData.profile_image = this.profilePersonalData.profile_image;
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.updateUrl, JSON.stringify(this.sendProfileData), { headers: headers }).subscribe(
        data => {
          

          if (data.json().key != null && data.json().key == "1") {
            this.toastr.success(data.json().result)
            this.router.navigateByUrl("/loginpage")
            // var modalPage = this.modalCtrl.create("LoadingPage",{
            //   AppFlow:'home'
            // });
               
            // modalPage.present();
          }
          else {
            this.toastr.error(data.json().result)
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        })
    }

  }

  locationChange(data, type) {
    if (data.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null) {
            if (type == "0")
              this.presentLocationList = data.json().locations;
            else 
              this.permenantLocationList = data.json().locations;
          }
        },error => {});
    }
  }

  selectedLocation(data, type) {
    if (type == "0") {
      this.clntPresentAddress.location = data;
      this.presentLocationList = [];
      this.getCity(data, type, '0');
    }else {
      this.clntPermenantAddress.location = data;
      this.permenantLocationList = [];
      this.getCity(data, type, '0');
    }
  }

  getCity(data, type, flag) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          if (type == "0"){ //  Present address
            this.presentCityList = data.json().cities;
            if (flag != null && flag == "1") {
              for (var t = 0; t < this.presentCityList.length; t++) {
                if (this.presentCityList[t].city_id == this.clntPresentAddress.id_city) {
                  this.presentLocationId = this.presentCityList[t].location_id;
                  this.presentCityId = this.presentCityList[t].city_id;
                  this.clntPresentAddress.city = this.presentCityList[t];
                  this.clntPresentAddress.zipcode = this.presentCityList[t].zipcode;
                  this.cityChange(this.presentCityList[t].city_id, type, flag);
                  break;
                }
              }
            }else {
              this.presentLocationId = this.presentCityList[0].location_id;
              this.presentCityId = this.presentCityList[0].city_id;
              this.clntPresentAddress.city = this.presentCityList[0];
              this.clntPresentAddress.zipcode = this.presentCityList[0].zipcode;
              this.cityChange(this.presentCityList[0].city_id, type, '0');
            }
          }else {// Permenant address
            this.permenantCityList = data.json().cities;
            if (flag != null && flag == "1") {
              for (var t = 0; t < this.permenantCityList.length; t++) {
                if (this.permenantCityList[t].city_id == this.clntPermenantAddress.id_city) {
                  this.premLocationId = this.permenantCityList[t].location_id;
                  this.premCityId = this.permenantCityList[t].city_id;
                  this.clntPermenantAddress.city = this.permenantCityList[t];
                  this.clntPermenantAddress.zipcode = this.permenantCityList[t].zipcode;
                  this.cityChange(this.permenantCityList[t].city_id, type, flag);
                  break;
                }
              }
            }else {
              this.premLocationId = this.permenantCityList[0].location_id;
              this.premCityId = this.permenantCityList[0].city_id;
              this.clntPermenantAddress.city = this.permenantCityList[0];
              this.clntPermenantAddress.zipcode = this.permenantCityList[0].zipcode;
              this.cityChange(this.permenantCityList[0].city_id, type, '0');
            }
          }
        }
      },error => {});
  }

  cityChange(data, type, flag) {
    if (data != null && data != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            if (type == "0") { //  Present address
              this.presentStateList = data.json().states;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.presentStateList.length; t++) {
                  if (this.presentStateList[t].state_id == this.clntPresentAddress.id_state) {
                    this.presentStateId = this.presentStateList[t].state_id;
                    this.clntPresentAddress.state = this.presentStateList[t];
                    this.stateChange(this.presentStateList[t].state_id, type, flag);
                    break;
                  }
                }
              }else {
                this.presentStateId = this.presentStateList[0].state_id;
                this.clntPresentAddress.state = this.presentStateList[0];
                this.stateChange(this.presentStateList[0].state_id, type, '0');
              }
            }else{ // Permenant address
              this.permenantStateList = data.json().states;

              if (flag != null && flag == "1") {
                for (var t = 0; t < this.permenantStateList.length; t++) {
                  if (this.permenantStateList[t].state_id == this.clntPermenantAddress.id_state) {
                    this.premStateId = this.permenantStateList[t].state_id;
                    this.clntPermenantAddress.state = this.permenantStateList[t];
                    this.stateChange(this.permenantStateList[t].state_id, type, flag);
                    break;
                  }
                }
              } else {
                this.premStateId = this.permenantStateList[0].state_id;
                this.clntPermenantAddress.state = this.permenantStateList[0];
                this.stateChange(this.permenantStateList[0].state_id, type, '0');
              }
            }
          }
        },error => {});
    }
  }

  stateChange(data, type, flag) {
    if (data != null && data != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            if (type == "0"){ //  Present address
              this.presentCountryList = data.json().countries;
              this.presentCountryId = this.presentCountryList[0].country_id;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.presentCountryList.length; t++) {
                  if (this.presentCountryList[t].country_id == this.clntPresentAddress.id_country) {
                    this.clntPresentAddress.country = this.presentCountryList[t];
                  }
                }
              }else {
                this.clntPresentAddress.country = this.presentCountryList[0];
              }
            }else {
              this.permenantCountryList = data.json().countries;
              this.premCountryId = this.permenantCountryList[0].country_id;

              if (flag != null && flag == "1") {
                for (var t = 0; t < this.permenantCountryList.length; t++) {
                  if (this.permenantCountryList[t].country_id == this.clntPermenantAddress.id_country) {
                    this.clntPermenantAddress.country = this.permenantCountryList[t];
                  }
                }
              }else {
                this.clntPermenantAddress.country = this.permenantCountryList[0];
              }
            }
          }
        },error => {});
    }
  }

  countryChange(data, type) {

  }

  addressRetrival() {
    this.clntRetrvProfileAddressData = this.gservice.get_client_profile_address_data();
    if (this.gservice.get_client_profile_address_data() != null && this.clntRetrvProfileAddressData.presentAddress != null) {
      this.clntPresentAddress = this.clntRetrvProfileAddressData.presentAddress;
      this.getCity(this.clntPresentAddress.location, '0', '1');
      if (this.clntRetrvProfileAddressData.permenantAddress != null) {
        this.sameasFlag = false;
        this.clntPermenantAddress = this.clntRetrvProfileAddressData.permenantAddress;
        this.getCity(this.clntPermenantAddress.location, '1', '1');
      }else {
        this.sameasFlag = true;
      }
    }
  }

  buyNow(data) {
    // let RelationModal = this.modalCtrl.create('PackageSubscriptionPopupPage', {
    //   package_id: data, 
    //   retrival_flag: this.retrivalFlag
    // }, { cssClass: 'update-profile-modal' });
    // RelationModal.present();
    // RelationModal.onDidDismiss(data => {
    //   if(this.retrivalFlag == false)
    //   {
    //     if(data != null)
    //     {
        
    //       this.gservice.reg_client_package_flag = true;

    //       this.clientPackageData.package_id = data;

    //       var send_array = null;
    //       send_array = {
    //         grit_package_subscribe_id: data,
    //       }
         
    //       Helper_class.Set_package_data(send_array);
    //     }
    //     else
    //     {
    //       this.toastr.error("Please select package");
    //     }
    //   }
    // })
  }

  package() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpkgm',
      JSON.stringify({
        "user": "client"
      }),
      { headers: headers })
      .subscribe(
        response => {
          

          var obj = JSON.parse(response["_body"]);
          this.setPackage(obj);
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }
  
  setPackage(data) {
    for (var i = 0; i < data.package_details.length; i++) {
      var temp_array = [];

      for (var j = 0; j < data.package_details[i].package_modules.length; j++) {
        temp_array.push(data.package_details[i].package_modules[j]);
      }
      var Imagearray = [];
      for (var k = 0; k < data.modules.length; k++) {
        if (temp_array.indexOf(data.modules[k].module_id) > -1) {
          Imagearray.push("../../../assets/img/correct.png");
        }
        else {
          Imagearray.push("../../../assets/img/wrong.png");
        }
      }
      this.packIdList.push(Imagearray);
    }

    this.packageModels = data.modules;

    this.mainPackage = data.package_details;
  }

  openCloseArrow(){
    this.downArrowFlag = !this.downArrowFlag;
    if(this.downArrowFlag == true)
      document.getElementById("block").style.display="block";

    if(this.downArrowFlag == false)
      document.getElementById("block").style.display="none";
  }
}
