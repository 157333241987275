    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title ">
                    Ear
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row top" style="margin-top: 10px;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>PAS</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="pas_left" [disabled]="client">
                                                </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="pas_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>AOE</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="aoe_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="aoe_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>CDOE</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="cdoe_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="cdoe_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>AOM</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="aom_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="aom_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>COM</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="com_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="com_right">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="muc_type" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Mucous type<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="squa_type" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Squamous type<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>TM perforation</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="tm_cen" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Central<br> </mat-label>
                    
                                        </div>
                    
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="tm_mar" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Marginal<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="ps_type" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">PS<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()" [(ngModel)]="sub_type" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Subtotal<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>cholesteatoma</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="cho_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="cho_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="cho_at" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Attic<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="cho_pos" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Posterosuperior<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>OME</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ome_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                    
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ome_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>EC</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ec_left" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                    
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ec_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>BPPV</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="bp_left" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                    
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="bp_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Meniere's</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="men_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                    
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="men_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel"><b>Notes</b><br>
                                <textarea class="habitsta ipcss" (change)="send_data()" maxlength="250"
                                    [(ngModel)]="ear_diag_notes" [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion" [hidden]="nose_hidden">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title ">
                    Nose
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row top">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>polyps</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="poly_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="poly_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>AC</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ac_left" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                    
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ac_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Ethmoidal</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ethi_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>   
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ethi_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Rhinosporidiosis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="rhino_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="rhino_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Frontal sinusitis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="front_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="front_sin_right">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Maxillary sinusitis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="maxi_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="maxi_sin_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="maxi_sin_acu" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Acute<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="maxi_sin_chro" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Chronic<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Ethmoidal sinusitis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ethi_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="ethi_sin_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Sphenoidal sinusitis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="sphe_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="sphe_sin_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Septal deviation</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="septal_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="septal_sin_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Spur</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="spur_sin_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="spur_sin_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>#NB</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="nb_left" [disabled]="client"></mat-checkbox>
                                            <mat-label class="matlabel ">Left<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="nb_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Tenderness</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="tend_left" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Frontal<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="tend_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Antral Left<br> </mat-label>
                                        </div>
                                        <div class="col-4 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="antral_right" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Antral Right<br> </mat-label>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title ">
                    Neck
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row top">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Goitre</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="geit_left"  [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Left lobe<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="geit_right">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Right lobe<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="geit_diff" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Diffuse<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="geit_mng" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">MNG<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Neck Nodes(L)</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_single" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">single<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_mul" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Multiple<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_ten" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Tender<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_nonten" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Non Tender<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Neck Nodes(R)</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 paddingcheckbox" [hidden]="block_hidden">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_sin" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">single<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_multi" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Multiple<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_tender" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Tender<br> </mat-label>
                                        </div>
                                        <div class="col-6 paddingcheckbox">
                                            <mat-checkbox color="primary" (change)="send_data()" class="dentalmaterial" [(ngModel)]="neck_non" [disabled]="client">
                                            </mat-checkbox>
                                            <mat-label class="matlabel ">Non Tender<br> </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>




