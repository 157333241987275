<div *ngIf="gynPersonalFlag == 'min'">
    <div class="block1_grid">
        <mat-label [hidden]="age_hidden" class="matlabel">Name<br>
            <input class="ipcss" ngDefaultControl matInput [readonly]="agefield" [(ngModel)]="client_name"
                maxlength="3">
        </mat-label>
        <mat-label [hidden]="age_hidden" class="matlabel">Gender<br>
            <input class="ipcss" ngDefaultControl matInput [readonly]="agefield" [(ngModel)]="client_gender"
                maxlength="3">
        </mat-label>
            <!-- <mat-label [hidden]="age_hidden" class="matlabel">Gender<br>
                <input class="ipcss" ngDefaultControl matInput [readonly]="agefield" [(ngModel)]="client_gender"
                    maxlength="3">
            </mat-label> -->
        <mat-label [hidden]="current_occupationhidden" class="matlabel">Occupation<br>
            <input class="ipcss" ngDefaultControl matInput maxlength="25" required [(ngModel)]="occupation" [disabled]="client">
        </mat-label>

    </div><br>
</div>

<div class="Details" *ngIf="gynPersonalFlag == 'max'">
    <div class="row">

        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Concerns & Personal
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="maritial_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="maritial_hidden" class="matlabel">Marital Status<br>
                                <select class="ipcss" required [disabled]="maritial_disabled || client" (change)="send_data()"
                                    [(ngModel)]="maritial_status" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="Married">Married</option>
                                    <option value="Single">Single</option>
                                    <option value="Spinster">Spinster</option>
                                    <option value="Divorcy">Divorcy</option>
                                    <option value="Widowed">Widowed</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="current_occupationhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label [hidden]="current_occupationhidden" class="matlabel">Occupation<br>
                                <input class="ipcss" ngDefaultControl matInput maxlength="25" required [disabled]="client"
                                    [(ngModel)]="occupation" (blur)="send_data()">
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div [hidden]="stress_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="stress_hidden">Stress Factors<br>
                                <textarea class="ipcss obsttxtareaheight" rows="1"
                                    [disabled]="stress_disabled || client" required value="None" maxlength="150"
                                    [(ngModel)]="stress_factor" ngDefaultControl matInput (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <!-- <div [hidden]="main_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="main_hidden">Main Concerns<br>
                                <textarea class="ipcss obsttxtareaheight " rows="1"
                                    [disabled]="maincon_disabled || client" required maxlength="150"
                                    [(ngModel)]="Main_concern" ngDefaultControl matInput (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="past_hiddeen" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="past_hiddeen">Past Medical History<br>
                                <textarea class="ipcss obsttxtareaheight" [(ngModel)]="Medical_hist" (blur)="send_data()"
                                    (click)="medical_popup()" ngDefaultControl matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="present_hiddeen" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="present_hiddeen">Present Illness<br>
                                <textarea [disabled]="client" class="ipcss obsttxtareaheight" required
                                    [(ngModel)]="Present_illness" (click)="present_pop()" ngDefaultControl matInput (blur)="send_data()"></textarea>
                            </mat-label>
                        </div> -->

                        <div [hidden]="drug_hiddeen" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="drug_hiddeen">Drug Allergy<br>
                                <select class="ipcss" [(ngModel)]="drug_allergy"
                                    [disabled]="drugallergy_disabled || client" (change)="drug_change(drug_allergy);send_data() "
                                    disableOptionCentering ngDefaultControl>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="drug1_hiddeen" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="drug1_hiddeen">Drugs<br>
                                <textarea class="ipcss obsttxtareaheight" rows="1" maxlength="200" (blur)="send_data()"
                                    [disabled]="drug_disabled || client" [required]="drug_required" maxlength="150"
                                    [(ngModel)]="drugs" ngDefaultControl matInput></textarea>
                            </mat-label>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                            <div class="row">
                              <div class="col-12 margin_10">
                                <mat-label class="matlabel">
                                  <b class="label_bold">Complaints</b>
                                </mat-label>
                              </div>
                              <div class="col-12">
                                <quill-editor id="editor1" [(ngModel)]="complaints" [styles]="{height: 'calc(35vh - 100px)'}"
                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'main');send_data()">
                                </quill-editor>
                                <div>
                                  <quill-view-html hidden id="linemgmt" [content]="complaints"></quill-view-html>
                                </div>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                            <div class="row">
                              <div class="col-12 margin_10">
                                <mat-label class="matlabel">
                                  <b class="label_bold">Past medical history</b>
                                </mat-label>
                              </div>
                              <div class="col-12">
                                <quill-editor id="editor1" [(ngModel)]="past_medical" [styles]="{height: 'calc(35vh - 100px)'}"
                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'past');send_data()">
                                </quill-editor>
                                <div>
                                  <quill-view-html hidden id="linemgmt" [content]="past_medical"></quill-view-html>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                            <div class="row">
                              <div class="col-12 margin_10">
                                <mat-label class="matlabel">
                                  <b class="label_bold">Present illness</b>
                                </mat-label>
                              </div>
                              <div class="col-12">
                                <quill-editor id="editor1" [(ngModel)]="present_illvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'present_illvalue');send_data()">
                                </quill-editor>
                                <div>
                                  <quill-view-html hidden id="linemgmt" [content]="present_illvalue"></quill-view-html>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Menstrual
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="stage_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="stage_hidden">Stage<br>
                                <select class="ipcss" [(ngModel)]="stage" required [disabled]="client" (change)="send_data()"
                                    disableOptionCentering ngDefaultControl >
                                    <option disabled>Select</option>
                                    <option *ngFor="let stage of Gyna_Stage" value="{{stage.description}}">
                                        {{stage.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="stage_datehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="stage_datehidden" class="matlabel">Stage Date<br>
                                <input type="date" required class="ipcss_date" id="appt_date" [disabled]="client"
                                    (change)="OnDateChangestagedate(stage_date)" [(ngModel)]="stage_date"
                                    max="{{currentDate}}" ngDefaultControl matInput (blur)="send_data()">
                            </mat-label>
                        </div>
                        <div [hidden]="menstrual_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="menstrual_hidden">Menstrual periods<br>
                                <select class="ipcss" [(ngModel)]="Menstrual_Period" (change)="send_data()"
                                    [disabled]="menstrual_disabled || client" disableOptionCentering ngDefaultControl>
                                    <option value="Regular">Regular</option>
                                    <option value="Irregular">Irregular</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="lastmenstrual_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="lastmenstrual_hidden" class="matlabel">Last menstrual period<br>
                                <input type="date" required class="ipcss_date" id="appt_date" [disabled]="client"
                                    (change)="Menstrual_OnChange(last_mensperiod); send_data()" [(ngModel)]="last_mensperiod"
                                    max="{{currentDate}}" ngDefaultControl matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="cycle_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="cycle_hidden">Cycle length<br>
                                <select class="ipcss" [(ngModel)]="cycle_length" [disabled]="cycle_disabled || client" (change)="send_data()"
                                    disableOptionCentering ngDefaultControl>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="cycle_frequencyhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="cycle_frequencyhidden">Frequency<br>
                                <select class="ipcss" #gynafreq [(ngModel)]="Frequency_Length" (change)="send_data()"
                                    [disabled]="cycle_freqdisabled || client" disableOptionCentering>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="edd_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="edd_hidden" class="matlabel">EDD / REDD<br>
                                <input class="ipcss" #gynaedd [readonly]="isReadonly()" [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="Edd_redd" ngDefaultControl matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="trimester_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="trimester_hidden">Trimester<br>
                                <select class="ipcss" [disabled]="trimester_disabled || client" (change)="send_data()"
                                    [(ngModel)]="Trmester_txt" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="I">I</option>
                                    <option value="II">II</option>
                                    <option value="III">III</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="noof_weekhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="noof_weekhidden" class="matlabel">No of weeks<br>
                                <input class="ipcss" [disabled]="noof_weekdis || client" #noofweeks (blur)="send_data()"
                                    [(ngModel)]="noof_weeks" ngDefaultControl matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="menstrual_painhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="menstrual_painhidden">Menstrual pain<br>
                                <select class="ipcss" [(ngModel)]="mens_paintxt" (change)="send_data()"
                                    [disabled]="menstrual_paindisbaled || client"
                                    (change)="menstrualpain_change(mens_paintxt)" disableOptionCentering ngDefaultControl>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="timeofpain_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="timeofpain_hidden">Timing of pain<br>
                                <select class="ipcss" [disabled]="time_paindisabled || client" (change)="send_data()"
                                    [required]="time_painrequire" [(ngModel)]="pain_timing" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="Before menses">Before menses</option>
                                    <option value="During menses">During menses</option>
                                    <option value="Both">Both</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="character_fieldhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="character_fieldhidden">Character of pain<br>
                                <select class="ipcss" [disabled]="character_paindisabled || client" (change)="send_data()"
                                    [required]="character_painrequire" [(ngModel)]="character_pain"
                                    disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option *ngFor="let character of Characer_pain" value="{{character.description}}">
                                        {{character.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="menorrhagia_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="menorrhagia_hidden">Menstrual disorders<br>
                                <select class="ipcss" [disabled]="menorhagia_disabled || client" (change)="send_data()"
                                    (change)="menorrhagia_change(menorragiatxt)" [(ngModel)]="menorragiatxt"
                                    disableOptionCentering ngDefaultControl>
                                    <option value="No">No</option>
                                    <option value="Hypomenorrhea">Hypomenorrhea</option>
                                    <option value="Polymenorrhea">Polymenorrhea</option>
                                    <option value="Metrorrhagia">Metrorrhagia</option>
                                    <option value="Menorrhagia">Menorrhagia</option>
                                    <option value="Menometrorrhagia">Menometrorrhagia</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="noof_dayshidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="noof_dayshidden">No of days<br>
                                <select class="ipcss" [required]="noof_daysrequire" (change)="send_data()"
                                    [disabled]="noof_daysdisabled || client" [(ngModel)]="no_of_days"
                                    disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="nofrequency_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="nofrequency_hidden">Frequency<br>
                                <select class="ipcss" [disabled]="freuency_disabled || client" (change)="send_data()"
                                    [required]="frequency_required" [(ngModel)]="pain_frequency" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option *ngFor="let pain of pain_freqlist" value="{{pain.description}}">
                                        {{pain.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12" *ngIf="!client">
                            <a [hidden]="obestric_menstrualhidden" (click)="obestric_menstrualclick()"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                                    style="float: right;" /></a>
                            <a [hidden]="gyna_menstrualhidden" (click)="menstrual_gynahistoryclick()"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                                    style="float: right;" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="popsmear_hidden" class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Pap smear
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="pop_datehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label [hidden]="pop_datehidden" class="matlabel">Date<br>
                                <input type="date" class="ipcss_date" id="appt_date" [disabled]="client"
                                    (change)="OnDateChangesDate(pop_date); send_data()
                                    " [(ngModel)]="pop_date" max="{{currentDate}}"
                                    ngDefaultControl matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="pop_resulthidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="pop_resulthidden">Result<br>
                                <select class="ipcss" [required]="popre_require" (change)="send_data()"
                                    [disabled]="popresult_disabled || client" [(ngModel)]="pop_result"
                                    disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Abnormal">Abnormal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-md-8 col-lg-8 col-sm-12 col-xl-8">
                            <div *ngIf="chart_flag">
                                <div id="chart_container1" class="chart_containeralign"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="sexual_menshidden" class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Sexual
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="sexual_satushidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="sexual_satushidden">Sexual status<br>
                                <select class="ipcss" (change)="sexual_statuschange(sexual_status); send_data()"
                                    [disabled]="sexual_disabled || client" [(ngModel)]="sexual_status"
                                    disableOptionCentering ngDefaultControl>
                                    <option value="None">None</option>
                                    <option value="In Active">In Active</option>
                                    <option value="Active">Active</option>
                                    <option value="Very Active">Very Active</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="difficult_durehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="difficult_durehidden">Difficulties during
                                intercourse<br>
                                <select class="ipcss" (change)="diificult_duringchange(Sexual_Difficulties);send_data()"
                                    [disabled]="difficult_disabled || client" [(ngModel)]="Sexual_Difficulties"
                                    disableOptionCentering ngDefaultControl>
                                    <option value="None">None</option>
                                    <option value="Pain">Pain</option>
                                    <option value="Discomfort">Discomfort</option>
                                </select>
                            </mat-label>
                        </div>
                        <div *ngIf="this.gyna_fields == 'Gynaecology'" [hidden]="typeofpain_hidden"
                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="typeofpain_hidden">Type of pain<br>
                                <select class="ipcss" [disabled]="typeofpain_disabled || client" (change)="send_data()"
                                    [(ngModel)]="typeof_pain" disableOptionCentering ngDefaultControl>
                                    <option value="None">None</option>
                                    <option value="Superficial">Superficial</option>
                                    <option value="Sometimes">Sometimes</option>
                                    <option value="Always">Always</option>
                                    <option value="Deep">Deep</option>
                                </select>
                            </mat-label>
                        </div>
                        <div *ngIf="this.gyna_fields == 'Obstetrics'" [hidden]="typeofpain_hidden"
                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="typeofpain_hidden">Type of pain<br>
                                <select class="ipcss" [disabled]="typeofpain_disabled || client" (change)="send_data()"
                                    [(ngModel)]="typeof_pain" disableOptionCentering ngDefaultControl>
                                    <option value="None">None</option>
                                    <option value="Superficial">Superficial</option>
                                    <option value="Sometimes">Sometimes</option>
                                    <option value="Always">Always</option>
                                    <option value="Deep">Deep</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="sexual_satisfacthidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="sexual_satisfacthidden">Sexual satisfaction<br>
                                <select class="ipcss" [disabled]="sexaul_satdisabled || client" (change)="send_data()"
                                    [(ngModel)]="sexual_satisfaction" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="Satisfied">Satisfied</option>
                                    <option value="Not satisfied">Not satisfied</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="age_whensexualhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                            style="padding-top: 13px;">
                            <mat-label [hidden]="age_whensexualhidden" class="matlabel">Age when first sexually
                                active<br>
                                <input class="ipcss" maxlength="3" [(ngModel)]="sexual_active" (blur)="send_data()"
                                    [disabled]="age_whendisabled || client" ngDefaultControl matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="typeofsex_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                            style="padding-top: 13px;">
                            <mat-label class="matlabel" [hidden]="typeofsex_hidden">Type of sex<br>
                                <select class="ipcss" [(ngModel)]="type_ofsex"  (change)="send_data()"
                                    [disabled]="typeof_sexdisabled || client" disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option value="Oral">Oral</option>
                                    <option value="Anal">Anal</option>
                                    <option value="Vaginal">Vaginal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="contracept_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                            style="padding-top: 13px;">
                            <mat-label class="matlabel" [hidden]="contracept_hidden">Contraceptives<br>
                                <select class="ipcss" [(ngModel)]="contracept" (change)="contrapt_change(contracept); send_data();"
                                    [disabled]="contracept_disabled || client" disableOptionCentering ngDefaultControl>
                                    <option value="None">None</option>
                                    <option value="Regularly">Regularly</option>
                                    <option value="Often">Often</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="typeof_contracepthidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                            style="padding-top: 13px;">
                            <mat-label class="matlabel" [hidden]="typeof_contracepthidden">Type of contraceptive<br>
                                <select class="ipcss" [required]="typcontra_require" [(ngModel)]="type_contracept"
                                    [disabled]="typecontracept_disabled || client" disableOptionCentering ngDefaultControl (change)="send_data()">
                                    <option disabled>Select</option>
                                    <option *ngFor="let contracept of contracept_array"
                                        value="{{contracept.description}}">
                                        {{contracept.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="reason_dishidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="reason_dishidden">Reason for discontinue<br>
                                <textarea class="ipcss obsttxtareaheight" [required]="reason_require" (blur)="send_data()"
                                    [disabled]="reason_disabled || client" rows="1" maxlength="150"
                                    [(ngModel)]="reason_discontinue" ngDefaultControl matInput></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="compli_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="compli_hidden">Complications, if any<br>
                                <textarea class="ipcss obsttxtareaheight" rows="1" (blur)="send_data()"
                                    [disabled]="compli_disabled || client" maxlength="150" [(ngModel)]="compli_sex"
                                    ngDefaultControl matInput></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="infection_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="infection_hidden">Infections<br>
                                <select class="ipcss" [(ngModel)]="infection" [disabled]="infect_disabled || client" (change)="send_data()"
                                    disableOptionCentering ngDefaultControl>
                                    <option disabled>Select</option>
                                    <option *ngFor="let infect of infect_list" value="{{infect.description}}">
                                        {{infect.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="any_otherhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="any_otherhidden">Any other issues?<br>
                                <textarea class="ipcss obsttxtareaheight" rows="1" (blur)="send_data()"
                                    [disabled]="any_otherdisabled || client" maxlength="150"
                                    [(ngModel)]="other_issuesex" ngDefaultControl matInput></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12" *ngIf="!client">
                            <a [hidden]="obestric_menstrualhidden" (click)="obestric_sexualclick()"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                                    style="float: right;" /></a>
                            <a [hidden]="gyna_menstrualhidden" (click)="menstrual_sexualclick()"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                                    style="float: right;" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>