<app-header></app-header>
<div class="aboutus-section pt-5 mt-5" id="aboutusSection">
   <div class="container">
      <div class="row">
         <div class="col-12 col-md-7">
            <h1 class="aboutus-heading">About Tervys</h1>
            <p class="aboutus-para">Gritsys technology specializes in developing and implementing software products &
               platform (Tervys) for the healthcare industry . The company leverages cutting-edge technologies such
               as AI, big data, IoT, and cyber
               security to create innovative solutions that improve the quality
               of healthcare services using Tervys.<br> <br>
               Tervys utilizes AI algorithms to analyze vast amounts of medical data
               and provide insights to healthcare professionals.
               The platform collects data from various sources such as electronic medical records, wearable devices,
               and patient monitoring systems, and uses advanced analytics to identify patterns and trends. This
               allows healthcare providers to make data-driven
               decisions that improve patient outcomes. <br><br>
            </p>
         </div>
         <div class="col-12 col-md-5">
            <img src="../../../assets/images/aboutus_images/about-us.png" class="about-us-img">
         </div>
      </div>
   </div>
</div>
<div class="aboutus-section pb-5" id="aboutusSection">
   <div class="container">
      <div class="row">
         <div class="col-12 ">
            <p class="aboutus-para">
               To ensure the security and privacy of patient data, Tervys incorporates advanced cyber security
               measures. The platform uses encryption, access controls, and monitoring to protect patient data from
               unauthorized access and cyber-attacks. This allows healthcare providers to have confidence in the
               security and privacy of patient data.
               <br> <br>

               Tervys incorporates IoT technologies to enable remote monitoring of patient health. The platform
               utilizes connected devices such as wearables and remote monitoring systems to collect data on patient
               health, and provides real-time alerts to healthcare providers if any abnormalities are
               detected.<br><br>
               Overall, Tervys is an innovative solution that leverages AI, big data, IoT, and cyber security to
               improve the quality of healthcare services. By providing real-time insights into patient health,
               enabling data-driven decisions, and ensuring the security and privacy of patient data, Gritsys is
               helping to transform the healthcare industry.
            </p>
         </div>
      </div>
   </div>
</div>
<div class=" pb-5 text" id="aboutusSection">
   <div class="container">
      <div class="row justify-content-center">
         <div class="card-container col-10 p-3  m-3 col-md-5 ">
            <h1 class="aboutus-heading2">Be Empathetic</h1>
            <p class="aboutus-para2">We understand and honour every<br>
               individual’s personal journey.</p>
         </div>
         <div class="card-container col-10  p-3 m-3 col-md-5 ">
            <h1 class="aboutus-heading2">Be Innovative</h1>
            <p class="aboutus-para2">We are constantly innovating,<br>
               problem solving and evolving..</p>
         </div>
         <div class="card-container col-10  p-3 m-3 col-md-5 ">
            <h1 class="aboutus-heading2">Be Accessible</h1>
            <p class="aboutus-para2">We use technology to be<br>
               accessible far and wide.</p>
         </div>
         <div class="card-container col-10  p-3 m-3 col-md-5 ">
            <h1 class="aboutus-heading2">Be Reliable</h1>
            <p class="aboutus-para2">We are guided by strong values
               and honesty.</p>
         </div>
      </div>
   </div>
</div>
<div class=" pb-5 text" id="aboutusSection">
   <div class="container-fluid">
      <div class="row justify-content-center">
         <h1 class="text-center mb-5 aboutus-heading2">Meet Our Team</h1>
         <div class="card-container3 col-10 p-3  col-md-2 my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/michael.jpg " class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Michael</h1>
            <p class="aboutus-para4">Director Technology.</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2 my-3 mx-3 text-center">
            <img src="./../../assets/images/aboutus_images/aravind.jpg " class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Dr. Aravind </h1>
            <p class="aboutus-para4">Director Strategy.</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2 my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/nemi.jpg " class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Neminathan</h1>
            <p class="aboutus-para4">Director International Business .</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2  my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/daniel.png " class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Daniel </h1>
            <p class="aboutus-para4">VP Product development.</p>

         </div>
      </div>
   </div>
</div>
<div class=" pb-5 text" id="aboutusSection">
   <div class="container-fluid">
      <div class="row justify-content-center">
         <div class="card-container3 col-10 p-3  col-md-2 my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/naveen.png" class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Naveen</h1>
            <p class="aboutus-para4">VP Business development.</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2 my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/arumutam.jpg" class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Dr. Arumugam </h1>
            <p class="aboutus-para4">Chief Medical Officer.</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2 my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/user.png" class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Dr. Laxmaiah </h1>
            <p class="aboutus-para4">Business Advisor – Nutrition</p>

         </div>
         <div class="card-container3 col-10  p-3 col-md-2  my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/user.png" class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Dr. Arun Gnanaraj </h1>
            <p class="aboutus-para4">Advisor Wellness & Fitness.</p>
         </div>
         <div class="card-container3 col-10  p-3 col-md-2  my-3 mx-3 text-center">
            <img src="../../../assets/images/aboutus_images/murali.jpg" class="team-img2">
            <h1 class="aboutus-heading3 pt-3">Murali Krishnan </h1>
            <p class="aboutus-para4">Advisor Finance</p>
         </div>
      </div>
   </div>
</div>

<app-footer></app-footer>