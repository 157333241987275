import { Component, OnInit, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { seesion_nameid, day_nameid, ConvertTimeformat, Time_Formate_Session, formattedDate, Time_Formate } from '../../../assets/js/common';
import { session_based_time_fun } from '../../../assets/js/session_based_time';
import { Doc_Helper } from '../Doc_Helper';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { Observable, observable, Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-second-opinion-casesheet-apptdetails',
  templateUrl: './second-opinion-casesheet-apptdetails.component.html',
  styleUrls: ['./second-opinion-casesheet-apptdetails.component.css']
})
export class SecondOpinionCasesheetApptdetailsComponent implements OnInit {
  //Redaonly 
  isReadonly() { return true; }

  public apptdetails = 'min';
  public Current_time;
  public starts_from_array = [];
  public ends_at_array = [];
  public End_from_array = [];
  public end_to_array = [];
  public second_opinin_caselist;
  public session_txt;
  public cnt_reg_id;
  public f_name;
  public m_name;
  public l_name;
  public Age_txt;
  public client_blood_group;
  public gen_txt;
  public location_txt;
  public district_txt;
  public contact_num;
  public Treate_type;
  public specialization_txt;
  public hospital_name;
  public old_hosname: boolean;
  public new_hospital: boolean;
  public Appointment_type_txt;
  public AllotBtn: boolean;
  public se_date: boolean;
  public se_date1: boolean;
  public check_availabitydata: boolean;
  public from_dis: boolean;
  public to_dis: boolean;
  public Cur_timesplit;
  public Curre_time;
  public date_one;
  public date_txt1;
  public Cur_timesplit1;
  public Curre_time1;
  public sess_drop: boolean;
  public see_txt: boolean;
  public form_main: boolean;
  public to_main: boolean;
  public Appoint_Date;
  public date_txt_first;
  public appoint_div: boolean;
  public curr_date;
  public Reason_cancel;
  public reason_txt;
  public f_time;
  public to_time;

  public hosp_id: string;
  public sess_data: string

  // start time
  public Start_time;
  public end_time;
  public time_session;

  // end time
  public Ends_at_from;
  public Ends_at_to;
  public endtime_session;
  public session_drop;
  public hospital_nametxt: string;
  public secondhopistla_arry = [];
  public header_footer_flag: boolean;
  public loader: boolean;

  public currentDate;
  public userinfo;
  public user_id;
  subscription:Subscription;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService, public messageservice1:SecondOpinionService) { 
    this.getCurrentDate();
    this.AllotBtn = true;
    this.se_date = true;

    this.check_availabitydata = true;
    this.se_date1 = true;
    this.sess_drop = true;
    this.see_txt = true;
    this.appoint_div = true;
    this.reason_txt = true;
    this.from_dis = true;
    this.form_main = true;
    this.to_dis = true;
    this.to_main = true;
    //Starts time
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.starts_from_array.push("0" + i);
      } else {
        this.starts_from_array.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.ends_at_array.push("0" + i);
      } else {
        this.ends_at_array.push(i);
      }
    }

    //End time
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.End_from_array.push("0" + i);
      } else {
        this.End_from_array.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.end_to_array.push("0" + i);
      } else {
        this.end_to_array.push(i);
      }
    }

    this.Start_time = "06";
    this.end_time = "00";
    this.time_session = "AM";

    this.Ends_at_from = "08";
    this.Ends_at_to = "00";
    this.endtime_session = "AM";
  }

  ngOnInit(): void {
    this.subscription = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
      if (message) {
          this.apptdetails = message;
      }
    });
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.load_hosp_data();
    this.get_opn_data();
  }

  // get current work location hospital
  load_hosp_data() {
    this.secondhopistla_arry = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/doctorbyid/',
      JSON.stringify({
        doc_reg_id: this.user_id,
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          for (var i = 0; i < obj.consultation.length; i++) {
            if (this.secondhopistla_arry.length != 0) {
              var Check_Flag = false;
              for (var j = 0; j < this.secondhopistla_arry.length; j++) {
                if (obj.consultation[i].hospital_id == this.secondhopistla_arry[j].hosp_id) {
                  Check_Flag = true;
                  break;
                }
              }

              if (Check_Flag == false) {
                this.secondhopistla_arry.push({
                  hosp_name: obj.consultation[i].hospital,
                  hosp_id: obj.consultation[i].hospital_id
                });
              }
            }
            else {
              this.secondhopistla_arry.push({
                hosp_name: obj.consultation[i].hospital,
                hosp_id: obj.consultation[i].hospital_id
              });
            }
          }
        },
        error => {
          //  this.toastr.error(Message_data.defaultErr);
        }
      )
  }
  
  getCurrentDate(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.date_txt_first = this.currentDate;
            this.Current_time = obj.current_time;
            this.curr_date = obj.current_date;
            var Date = obj.current_date.split('-');
          }
        },
        error => {});
  }
  
  get_opn_data() {//Get opinion
    var obj = Helper_Class.getOpnRet();;
    this.second_opinin_caselist = obj;

    if (this.second_opinin_caselist.client_reg_id != null) {
      this.cnt_reg_id = this.second_opinin_caselist.client_reg_id;
    }

    if (this.second_opinin_caselist.session != null) {
      this.session_txt = encrypt_decript.Decript(this.second_opinin_caselist.session);
    }
    Doc_Helper.setOpn_doc_id(this.second_opinin_caselist.opn_doc_id);
    this.f_name = encrypt_decript.Decript(this.second_opinin_caselist.mem_first_name);
    if (this.second_opinin_caselist.mem_middle_name != null) {
      this.m_name = encrypt_decript.Decript(this.second_opinin_caselist.mem_middle_name);
    }
    this.l_name = encrypt_decript.Decript(this.second_opinin_caselist.mem_last_name);
    if(this.second_opinin_caselist.age ==0){
      this.Age_txt = "<1";
    }else {
      this.Age_txt = this.second_opinin_caselist.age;
    }
    if (this.second_opinin_caselist.blood_desc != null) {
      this.client_blood_group = this.second_opinin_caselist.blood_desc;
    }
    this.gen_txt = encrypt_decript.Decript(this.second_opinin_caselist.gender);
    this.location_txt = encrypt_decript.Decript(this.second_opinin_caselist.location);
    this.district_txt = encrypt_decript.Decript(this.second_opinin_caselist.city);
    this.contact_num = encrypt_decript.Decript(this.second_opinin_caselist.mem_mobile);
    this.Treate_type = this.second_opinin_caselist.medicare_name;
    if (this.second_opinin_caselist.specialization != null) {
      this.specialization_txt = this.second_opinin_caselist.specialization;
    }
    if (this.second_opinin_caselist.reason != null) {
      this.Reason_cancel = this.second_opinin_caselist.reason;
      this.reason_txt = false;
    }
    if (this.second_opinin_caselist.hptl_name != null) {
      this.hospital_name = this.second_opinin_caselist.hptl_name;
      this.old_hosname = true;
      this.new_hospital = false;
    }else {
      this.old_hosname = false;
      this.new_hospital = true;
    }

    this.Appointment_type_txt = this.second_opinin_caselist.opinion_desc;
    if (this.second_opinin_caselist.appointment_date != null && this.second_opinin_caselist.from_time != null) {
      this.AllotBtn = true;
      this.se_date = true;
      this.se_date1 = false;
      this.check_availabitydata = false;
      this.f_time = Time_Formate(this.second_opinin_caselist.from_time);
      this.to_time = Time_Formate(this.second_opinin_caselist.to_time);
      this.date_one = this.second_opinin_caselist.appointment_date.split('-');
      this.date_txt1 = this.date_one[2] + "-" + this.date_one[1] + "-" + this.date_one[0];
      this.sess_drop = true;
      this.see_txt = false;
      this.from_dis = false;
      this.to_dis = false;
      this.session_txt = encrypt_decript.Decript(this.second_opinin_caselist.session);
    }else {
      this.se_date = false;
      this.form_main = false;
      this.to_main = false;
      this.sess_drop = false;
      this.AllotBtn = false;
    }
    this.appoint_div = this.Appointment_type_txt != "Written" ? false : true;

    if (this.Appointment_type_txt == "Written") {
      this.AllotBtn = true;
    }

    if (this.second_opinin_caselist.is_cancelled != null) {
      this.AllotBtn = true;
    }

    if (this.second_opinin_caselist.status != null) {
      if (this.second_opinin_caselist.status == "0") {
        this.AllotBtn = true;
      }
    }
  }
  
  Allot_book() {
    var flag = true;
    if (this.hospital_nametxt == undefined) {
      this.toastr.error(Message_data.sltHosptl + "name");
      flag = false;
    }
    if (flag == true) {
      if (this.session_drop == undefined) {
        this.toastr.error(Message_data.sltSession);
      }else {
        var gsDayNames = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];

        var f_time = ConvertTimeformat("", this.Start_time + ":" + this.end_time + " " + this.time_session);
        var t_time = ConvertTimeformat("", this.Ends_at_from + ":" + this.Ends_at_to + " " + this.endtime_session);
        var ret_value = session_based_time_fun(f_time, t_time, this.session_drop);

        if (this.Start_time < (parseInt(this.Current_time) - 12)) {
          ret_value = false;
          this.toastr.error(Message_data.pastTimeAllot);
        }else {
          var values = this.date_txt_first.split('-');
          var result = new Date(this.date_txt_first);//new Date(values[2] + "-" + values[1] + "-" + values[0]);
          var dayName = gsDayNames[result.getDay()];
          var get_sel_day_num = day_nameid(dayName);

          if (ret_value == true) {
            this.sess_data = encrypt_decript.Encript(this.session_drop).toString();
            for (var i = 0; i < this.secondhopistla_arry.length; i++) {
              if (this.hospital_nametxt == this.secondhopistla_arry[i].hosp_name) {
                this.hosp_id = this.secondhopistla_arry[i].hosp_id;
                break;
              }
            }

            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'secopn/doctime',
              JSON.stringify({
                "doc_reg_id": this.user_id,
                "hptl_clinic_id": this.hosp_id,
                "date": this.date_txt_first,//values[2] + "-" + values[1] + "-" + values[0],
                "session_id": seesion_nameid(this.session_drop),
                "session": this.sess_data,
                "from_time": f_time,
                "to_time": t_time,
                "work_day": get_sel_day_num
              }),
              { headers: headers })
              .subscribe(
                response => {
                  var obj =response.json();
                  if (obj.result == false) {
                    this.toastr.error(Message_data.sltTimeNotAvail);
                  }else {
                    var headers = new Headers();
                    headers.append('Content-Type', 'application/json');
                    this.http.post(ipaddress.getIp.toString() + 'secopn/updsecopn/',
                      JSON.stringify({
                        doc_reg_id: this.user_id,
                        hptl_clinic_id: this.hosp_id,
                        appointment_date: this.date_txt_first,//values[2] + "-" + values[1] + "-" + values[0],
                        session: this.sess_data,
                        from_time: f_time,
                        to_time: t_time,
                        opinion_id: Doc_Helper.getOpnid(),
                        opn_doc_id: Doc_Helper.getOpn_doc_id
                      }),
                      { headers: headers })
                      .subscribe(
                        response => {
                          var obj = response.json();
                          if (obj.key == "1") {
                            this.toastr.success(Message_data.opnAllotSuccess);
                            this.messageservice1.sendSecondOpinionMessage("secondopinion");
                          }else {
                            this.toastr.error(Message_data.opnAllotNotSuccess);
                          }
                        },
                        error => {
                          this.toastr.error(Message_data.defaultErr);
                        });
                  }
                },
                error => {
                    this.toastr.error(Message_data.defaultErr);
                });
          }
          else {
            this.toastr.error(Message_data.sltValidTime);

          }
        }
      }
    }
  }
  
  Date_click(e){
    this.date_txt_first = e;
  }  
}
