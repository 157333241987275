import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  family: string = '../../../assets/images/family.png';
  deadline: string = '../../../assets/images/deadline.png';
  healthcheckup: string = '../../../assets/images/HealthCheckup-icon.png';
  //Carsoule images
  OrthopedicCarsoule: string = "../../../assets/images/CarsouleIcon/orthopedic.svg";
  PsychiatryCarsoule: string = "../../../assets/images/CarsouleIcon/psychology.svg";
  OphthalmologyCarsoule: string = "../../../assets/images/CarsouleIcon/ophthalmology.svg";
  DentalCarsoule: string = "../../../assets/images/CarsouleIcon/Dental.svg";
  NeurologyCarsoule: string = "../../../assets/images/CarsouleIcon/Neurology.svg";
  CardiologyCarsoule: string = "../../../assets/images/CarsouleIcon/Cardiology.svg";
  PulmonologyCarsoule: string = "../../../assets/images/CarsouleIcon/Pulmonology.svg";
  NephrologyCarsoule: string = "../../../assets/images/CarsouleIcon/Nephrology.svg";
  DiabetesCarsoule: string = "../../../assets/images/CarsouleIcon/Diabetes.svg";
  UrologyCarsoule: string = "../../../assets/images/CarsouleIcon/Urology.svg";
  ENTCarsoule: string = "../../../assets/images/CarsouleIcon/ent.svg";
  GynecologyCarsoule: string = "../../../assets/images/CarsouleIcon/Gynecology.svg";
  GastroenterologyCarsoule: string = "../../../assets/images/CarsouleIcon/Gastroenterology.svg";
  IVFCarsoule: string = "../../../assets/images/CarsouleIcon/In vitro fertilization.svg";
  GeneralsurgeryCarsoule: string = "../../../assets/images/CarsouleIcon/General surgery.svg";
  InfectiousdiseaseCarsoule: string = "../../../assets/images/CarsouleIcon/Infectious diseases.svg";
  //Logo
  myLogo: string = "../../../assets/images/tervys-logo.png";

  constructor(){

  }

  ngOnInit(): void {
  }
}
