<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock status</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" *ngIf="stockArray.length !=0">
            <div class="dig_table_overflow">
              <table id="card_tbl" class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Short name</th>
                    <th>Generic name</th>
                    <th>Medicare name</th>
                    <th>Product type</th>
                    <th>UOM</th>
                    <th>Manufacturer</th>
                    <th>Batch</th>
                    <th>Expiry date</th>
                    <th>Avl qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let stock of stockArray">
                    <td class="align_left">{{stock.name}}</td>
                    <td class="align_left"> {{stock.short_name}}</td>
                    <td class="align_left">{{stock.generic_name}}</td>
                    <td class="align_left">{{stock.medicare_name}}</td>
                    <td class="align_left">{{stock.product_type_desc}}</td>
                    <td class="align_left">{{stock.uom_desc}}</td>
                    <td class="align_left">{{stock.mfg_name}}</td>
                    <td class="align_left">{{stock.batch_no}}</td>
                    <td>{{stock.exp_date}}</td>
                    <td class="align_left">{{stock.qty_onhand}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>