<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                          <h5 style="color:black" class="m-0" class="mainHeadingStyle">Assignment details</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                      </div>
                </mdb-card-header>
                <mdb-card-body>

                    <div>
                        <app-loader></app-loader>
                    </div>
                    <!-- <p class="nodata" *ngIf="nodata_flag">No appointment(s) found</p> -->
                    <div class="row">
                        <div class="cover_div" style="margin-bottom:15px;">
                            <div class="header_lable">
                                Price details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Therapy
                                            <!-- [disabled]="disable_age" [(ngModel)]="rel_type"  -->
                                            <input type="text" [(ngModel)]="therapy" class="ipcss" required
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Price
                                            <!-- [disabled]="disable_age" [(ngModel)]="rel_type"  -->
                                            <input type="text" [(ngModel)]="price" class="ipcss" required
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Therapy
                                            <!-- [disabled]="disable_age" [(ngModel)]="rel_type"  -->
                                            <select [(ngModel)]="therapyType" class="ipcss" (change)="getTherapies()" >
                                                <option value="active">Active</option>
                                                <option value="deleted">Deleted</option>
                                              </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                                        <img src="../../../assets/ui_icons/buttons/add_button.svg" width="85px"  (click)="save()" class="add_btn history_btn btn-outline-success rounded pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive dig_table_overflow">
                        <table id="card_tbl" [hidden]="therapyList.length==0" class="table table-hover">
                            <thead>
                              <tr>
                                <th>Therapy</th>
                                <th>Price</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let therapy of therapyList" class="table table-hover">
                                <td class="align_left">{{therapy.therapy}}</td>
                                <td>{{therapy.price}}</td>
                                <td *ngIf="therapy.status == 1">
                                    <img src="../../../assets/ui_icons/pencil_icon_New.svg" (click)="editTherapy(therapy.therapy,therapy.price)" width="20px" height="20px" class="pointer1">
                                  
                                  <img src="../../../assets/ui_icons/trash_icon.svg" (click)="deleteTherapy(therapy.therapy_id)" width="20px" height="20px" style="margin-right: -30px; margin-left: 40px;" class="pointer1">
                                </td>
                
                                <td *ngIf="therapy.status == 0">
                                  <img src="../../../assets/ui_icons/select_mark.svg" (click)="activateTherapy(therapy.therapy_id)" width="20px" height="20px" >
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>                    
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>
