<div class="Details" id="Begin_content_others">
  <div class="header">
    <h2><strong>Book Appointment</strong></h2>
    <div style="float: right; margin-top: -27px;">
      <img class="saveimgbtn_doctreg" (click)="second_next_btn_Click()"
        src="../../../assets/ui_icons/buttons/Next_button.svg" />
    </div>
  </div>
  <div class="row">
    <div class="Details">
      <div class="appcreateheading_border">
        <p class="appcreatenew_heading"><b>Search by</b></p>
        <div class="block1">
          <mat-label class="matlabel">Location<br>
            <input type="text" class="ipcss widthappt" required maxlength="50" [(ngModel)]="location"
              (keyup)="Location_change($event)" matInput [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option (click)="location_selected(location)" *ngFor="let location of locationList"
                [value]="location">
                {{location}}
              </mat-option>
            </mat-autocomplete>
          </mat-label>
          <mat-label class="matlabel">District / City<br>
            <select required class="ipcss widthappt" [(ngModel)]="city" disableOptionCentering>
              <option (click)="City_change(city,city.city_desc)" *ngFor="let city of cityList" value="{{city.city_id}}">
                {{city.city_desc}}</option>
            </select>
          </mat-label>
          <mat-label class="matlabel">State<br>
            <select required class="ipcss widthappt" [(ngModel)]="state" disableOptionCentering>
              <option *ngFor="let state of stateList" value={{state.state_id}}>{{state.state_desc}}</option>
            </select>
          </mat-label>
          <mat-label class="matlabel">Country<br>
            <select required class="ipcss widthappt" [(ngModel)]="country" disableOptionCentering>
              <option *ngFor="let country of countryList" value={{country.country_desc}}
                (click)="Country_Change(country)">{{country.country_desc}}</option>
            </select>
          </mat-label>
        </div>
        <div class="details" *ngIf="filterFlag">
          <div class="block1">
            <mat-label class="matlabel" (click)="genderclick()">Gender</mat-label>&nbsp;
            <mat-radio-group class="dietradio" *ngIf="genderflag" labelPosition="before" aria-label="Select an option"
              color="primary" [(ngModel)]="gender">
              <mat-radio-button name="apptype" value="Male">Male</mat-radio-button>
              <mat-radio-button name="apptype" value="Female">Female</mat-radio-button>
              <mat-radio-button name="apptype" value="Transgender">Transgender</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="block1">
            <mat-label class="matlabel" (click)="Availabilityclick()">Availability</mat-label>&nbsp;
            <mat-radio-group class="dietradio" *ngIf="availabilityflag" labelPosition="before"
              aria-label="Select an option" color="primary" [(ngModel)]="filt_available_day">
              <mat-radio-button name="apptype" value="1">Sun</mat-radio-button>
              <mat-radio-button name="apptype" value="2">Mon</mat-radio-button>
              <mat-radio-button name="apptype" value="3">Tue</mat-radio-button>
              <mat-radio-button name="apptype" value="4">Wed</mat-radio-button>
              <mat-radio-button name="apptype" value="5">Thu</mat-radio-button>
              <mat-radio-button name="apptype" value="6">Fri</mat-radio-button>
              <mat-radio-button name="apptype" value="7">Sat</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="block1">
            <mat-label class="matlabel" (click)="session()">Session</mat-label>&nbsp;
            <mat-radio-group class="dietradio" *ngIf="sessionflag" labelPosition="before" aria-label="Select an option"
              color="primary" [(ngModel)]="filter_session">
              <mat-radio-button name="apptype" value="1">Morning</mat-radio-button>
              <mat-radio-button name="apptype" value="2">Afternoon</mat-radio-button>
              <mat-radio-button name="apptype" value="3">Evening</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="block1">
            <mat-label class="matlabel">
              <mat-slider dualKnobs="true" [(ngModel)]="knobValues" min="0" max="1500" step="100" value="1.5">
              </mat-slider>
            </mat-label>
            <mat-label class="matlabel">Filter by fee
              <select class="ipcss widthappt" [(ngModel)]="filtFee">
                <option value="Low to high">Low to high</option>
                <option value="High to low">High to low</option>
              </select>
            </mat-label>
          </div>
        </div>
        <input type="button" (click)="doctor_filter_click()" class="saveimgbtn_doctreg" value="Filter" />
      </div>
      <div class="appcreateheading_border">
        <a (click)="Doctor_View(doctor.Prov_ID, i)" *ngFor="let doctor of doctorList; let i = index">
          <mat-list class="list_style boxlist">
            <div class="pro_image"> <img src="{{doctor.Prof_image}}" class="profile_image"></div>
            <div class="appointment_name">
              <div>
                <p class="app_names">
                  <span><strong>{{"Name : "}}</strong></span>{{doctor.Doctor_Name}}
                </p>
              </div>
              <div class="hospital chn" *ngIf="!hospitalFlag">
                <p class="app_names"><strong>{{"Hospital : "}}</strong>
                  <span class="span_class" *ngFor="let hosp of doctor.Hospital">{{hosp.Hosp_name
                    + " - " + hosp.Hosp_Locat+" "}}</span>
                </p>
              </div>
              <div class="hospital chn" *ngIf="!specialFlag">
                <span class="icon_newColor"><i class="fa fa-heartbeat" aria-hidden="true"></i>
                </span>
                <p class="app_names"><span *ngIf="!screensizeBasedFlag"><strong>{{"Speciality
                      : "}}</strong></span>{{doctor.Speciality}}</p>
                <p class="app_names"><span *ngIf="!screensizeBasedFlag"><strong>{{"Gender :
                      "}}</strong></span>{{doctor.gender1}}</p>
                <p class="app_names"><span *ngIf="!screensizeBasedFlag"><strong>{{"Experience
                      : "}}</strong></span>{{doctor.Work_Year + " years"}}</p>
              </div>
              <img width="25" float-right height="25" src="../../../assets/img/correct.png" item-end
                *ngIf="doctor.check" />
            </div>
          </mat-list>
        </a>
      </div>
      <div class="block1" *ngIf="secondOpinionDocListFlag">
        <table id="patienttable" class="table table-hover table-dynamic">
          <tr>
            <th>Doctor name</th>
            <th>Location</th>
            <th>Action</th>
          </tr>
          <tr *ngFor="let Doctor of secondOpnDoctorList; let i=index">
            <td data-th="Doctor name">{{Doctor.Doc_Name}}</td>
            <td data-th="Location">{{Doctor.Doc_Location}}</td>
            <td data-th="Action"><a (click)="second_doctor_delete(Doctor.Doc_ID)">delete</a></td>
          </tr>
        </table>
      </div>
      <br />
    </div>
  </div>
</div>