<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Insurance Bill</h5>
                    </div>
                    <div class="headerButtons" *ngIf="insuranceBillFlag">
                        <a *ngIf="printbuttondie" (click)="print_area()"><img src="../../../assets/img/printer.svg" width="25px"
                                height="25px" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div [hidden]="showandhideFlag" class="cover_div">
                    <div class="header_lable">
                        Inpatient details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                                <!-- (click)="location_selected(location) -->
                                <mat-label class="matlabel">Client Name<br>
                                    <input type="text" class="ipcss" [(ngModel)]="appoint_txt" name="loc_name"
                                        (keyup)="getAppointments($event)" type="text" aria-label="Number" matInput
                                        [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option id="optionfont" *ngFor="let location of appointment_list"
                                            (click)="Appointmnet_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location)"
                                            [value]="location.docid_name">
                                            {{location.docid_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                                <mat-label class="matlabel">Filter by<br>
                                    <select required class="ipcss" [(ngModel)]="Filter_txt" required
                                        (change)="Filter_change()" disableOptionCentering>
                                        <option value="Date">Date</option>
                                        <option value="All">All</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="To_show">
                                <mat-label class="matlabel">From<br>
                                    <input type="date" class="ipcss widthbilllist datecss"
                                        (change)="OnDateChangedfrom(f_date)" [hidden]="From_show" [(ngModel)]="f_date"
                                        #matInput max="{{currentDate}}">
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" [hidden]="To_show">
                                <mat-label class="matlabel">To<br>
                                    <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                        (change)="OnDateChangedto(t_date)" [hidden]="To_show" [(ngModel)]="t_date"
                                        max="{{currentDate}}" matInput>
                                </mat-label>

                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center" style="margin-top: 20px !important;">
                                <mat-label class="matlabel">
                                    <a (click)="filter_click()"><img
                                            src="../../../assets/ui_icons/buttons/search_button.svg"
                                            class="saveimgbtn_inpatinfo topvalue" /></a>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="insuranceBillFlag" class="top-section">
                    <div class="container mt-4 ">
                        <div class="row ">
                            <div class="col-12 text-center justify-content-center">
                                <div>
                                    <P class="spans">INSURANCE BILL</P>
                                </div>
                            </div>
                        </div>
                        <div class="table-top1 col-12 d-flex  justify-content-between p-2">
                            <P><span class="spans">Service Tax Registration No:</span> {{serviceNo}}</P>
                            <P><span class="spans">Hospital Pan No:</span> {{panno}}</P>
                        </div>
                        <div class="table-top col-12 d-flex flex-wrap justify-content-between p-2">
                            <div class="col-6">
                                <div style="margin: 5px;"><span class="spans">Bill No:</span> {{billno}}</div>
                                    <div style="margin: 5px;"><span class="spans">IPNo:</span> {{inpid}}</div>
                                    <div style="margin: 5px;"><span class="spans">Patient Name:</span>&nbsp; {{patname}}</div>
                                    <div style="margin: 5px;"><span class="spans">Consultant:</span> 
                                        <table class="table1">
                                            <tr *ngFor="let name of consultarray">
                                                <td style="text-align: left;">{{name.name}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div style="margin: 5px;"><span class="spans">Admissiom Date:</span> {{admissionDate}}
                                        {{admissionTime}}</div>
                                    <div style="margin: 5px;"><span class="spans"> Discharge Date:</span>&nbsp; {{dischargeDate}}
                                        {{dischargeTime}}</div>
                            </div>
                            <div class="col-6">
                                <div style="margin: 5px;"><span class="spans">Date:</span> {{bill_date}}</div>
                                <div style="margin: 5px;"><span class="spans">ID no:</span> {{uhid}}</div>
                                <div style="margin: 5px;"><span class="spans">Insurance no:</span> {{insCardNo}}</div>
                                <div style="margin: 5px;"><span class="spans">Age/Sex:</span>&nbsp; {{age}}/{{gender}}</div>
                                <div style="margin: 5px;"><span class="spans">Insurance Name:</span> {{insurer}}</div>
                                <div style="margin: 5px;"><span class="spans">Policy No:</span> {{policyno}}</div>
                            </div>
                            <div class="col-12">
                                <P><span class="spans">Diagnosis:</span><span [innerHTML]="diagnosis"></span></P>
                                <P><span class="spans">Procedure:</span> <span [innerHTML]="treat_proc"></span></P>
                                <p><span class="spans">PreAuthorisation:</span> {{CCNno}}</p>
                                <!-- <p><span class="spans">CCN NO:</span> {{CCNno}}</p> -->
                            </div>
                        </div>
                        <div class="col-12 mt-4"> <!--class="col-12 mt-4" -->
                            <table class="table-top1 col-12" style="border-bottom: 1px solid black;">
                                <tr>
                                    <th style="width: 100px;">S.No</th>
                                    <th style="width: 700px;">Description</th>
                                    <th style="width: 250px;">Amount</th>
                                </tr>
                                <tr *ngFor="let bill of billDetails">
                                    <td>{{bill.index}}</td>
                                    <td style="width: 700px; text-align: left;">{{bill.name}}</td>
                                    <td style="width: 250px;text-align: right;">{{bill.fee}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" *ngIf="totalBill != '0.00' && totalBill != undefined">
                                        <!-- <input class="ipcss" [(ngModel)]="amountinword" matInput /> -->
                                        <span class="spans">In Words:</span>&nbsp;Rupees&nbsp;{{amountinword}}&nbsp;Only
                                    </td>
                                    <td style="text-align: right !important;"> 
                                        <span class="spans"><b>Total amount:</b></span>{{totalBill}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div [hidden]="true">
                    <div #printlogowithname id="printlogowithname">
                        <table style="margin-left:50px;margin-top:70px;">
                            <tr>
                                <td style="width: 450px; vertical-align: top;">
                                    <img alt="image" src={{hptlbillLogo}} [style]="billlogo_style">
                                    <p style="margin-top: -100px;margin-left: 110px;">{{hptlName}}</p>
                                    <p style="margin-left: 110px;">{{address}},{{doctorLocation}}</p>
                                    <p style="margin-left: 110px;">{{doctorCity}}</p>
                                    <p style="margin-left: 110px;">{{doctorState}},{{doctorCountry}}</p>
                                    <p style="margin-left: 110px;">{{doctorTelephone}}</p>
                                </td>
                                <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                                <td style="width: 300px;vertical-align: top;">
                                    <p><b>{{'Doctor'}} : {{docname}}</b></p>
                                    <p><b>{{'Date'}} : </b>{{bill_date}}</p>
                                </td>
                            </tr>
                        </table>
                        <table style="margin-left: 50px; margin-top:20px;margin-right: 50px;">
                            <tr>
                              <td colspan="2" style="width: 90%;text-align: center; ">INSURANCE BILL</td>
                            </tr>
                            <tr style="border: 1px solid #000;">
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;width: 85vw;">
                                        <tr>
                                            <td><P><span>Service Tax Registration No:</span> {{serviceNo}}</P></td>
                                            <td><P><span>Hospital Pan No:</span> {{panno}}</P></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;">
                                        <tr>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Bill No:</span> {{billno}}</div>
                                                <div style="margin: 5px;"><span class="spans">IPNo:</span> {{inpid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Patient Name:</span>&nbsp; {{patname}}</div>
                                                <div style="margin: 5px;"><span class="spans">Consultant:</span> 
                                                    <table style="margin-left: 65px;">
                                                        <tr *ngFor="let name of consultarray">
                                                            <td>{{name.name}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div style="margin: 5px;"><span class="spans">Admissiom Date:</span> {{admissionDate}}
                                                    {{admissionTime}}</div>
                                                <div style="margin: 5px;"><span class="spans"> Discharge Date:</span>&nbsp; {{dischargeDate}}
                                                    {{dischargeTime}}</div>
                                            </td>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Date:</span> {{bill_date}}</div>
                                                <div style="margin: 5px;"><span class="spans">ID no:</span> {{uhid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance no:</span> {{insCardNo}}</div>
                                                <div style="margin: 5px;"><span class="spans">Age/Sex:</span>&nbsp; {{age}}/{{gender}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance Name:</span> {{insurer}}</div>
                                                <div style="margin: 5px;"><span class="spans">Policy No:</span> {{policyno}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <P><span class="spans">Diagnosis:</span><span [innerHTML]="diagnosis"></span>
                                                </P>
                                                <P><span class="spans">procedure:</span> <span [innerHTML]="treat_proc"></span>
                                                </P>
                                                <p><span class="spans">PreAuthorisation:</span> {{CCNno}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <table style="margin-left: 50px; margin-right: 50px;border: 1px solid #504f4f;">
                            <tr>
                                <td colspan="2">
                                    <table class="table-top">
                                        <tr>
                                            <th style="width: 100px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">S.No</th>
                                            <th style="width: 600px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">Description</th>
                                            <th style="width: 250px;border-bottom: 1px solid #504f4f;">Amount</th>
                                        </tr>
                                        <tr *ngFor="let bill of billDetails">
                                            <td style="width: 100px;border-right: 1px solid #504f4f;">{{bill.index}}</td>
                                            <td style="width: 600px; text-align: left;border-right: 1px solid #504f4f;">{{bill.name}}</td>
                                            <td style="width: 250px;text-align: right;">{{bill.fee}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="border-top: 1px solid #504f4f;">
                                                {{amountinword}}
                                            </td>
                                            <td style="text-align: right;border-top: 1px solid #504f4f;"> <b>Total amount:</b> {{totalBill}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div #printbanner id="printbanner">
                        <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                            <tr>
                                <td>
                                    <!-- width="750px" height="200px" -->
                                    <img alt="image" src={{hptlbillLogo}} [style]="billlogo_style">
                                </td>
                            </tr>
                        </table>
                        <br />
                        <table style="margin-left: 50px; margin-top:20px;margin-right: 50px;">
                            <tr>
                                <td colspan="2" style="width: 90%;text-align: center; ">INSURANCE BILL</td>
                            </tr>
                            <tr style="border: 1px solid #000;">
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;width: 85vw;">
                                        <tr>
                                            <td>
                                                <P><span>Service Tax Registration No:</span> {{serviceNo}}</P>
                                            </td>
                                            <td>
                                                <P><span>Hospital Pan No:</span> {{panno}}</P>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;">
                                        <tr>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Bill No:</span> {{billno}}</div>
                                                <div style="margin: 5px;"><span class="spans">IPNo:</span> {{inpid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Patient Name:</span>&nbsp; {{patname}}</div>
                                                <div style="margin: 5px;"><span class="spans">Consultant:</span> 
                                                    <table style="margin-left: 65px;">
                                                        <tr *ngFor="let name of consultarray">
                                                            <td>{{name.name}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div style="margin: 5px;"><span class="spans">Admissiom Date:</span> {{admissionDate}}
                                                    {{admissionTime}}</div>
                                                <div style="margin: 5px;"><span class="spans"> Discharge Date:</span>&nbsp; {{dischargeDate}}
                                                    {{dischargeTime}}</div>
                                            </td>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Date:</span> {{bill_date}}</div>
                                                <div style="margin: 5px;"><span class="spans">ID no:</span> {{uhid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance no:</span> {{insCardNo}}</div>
                                                <div style="margin: 5px;"><span class="spans">Age/Sex:</span>&nbsp; {{age}}/{{gender}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance Name:</span> {{insurer}}</div>
                                                <div style="margin: 5px;"><span class="spans">Policy No:</span> {{policyno}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div style="margin-top: 10px;">Diagnosis:</div><div style="margin-top: -15px !important;" [innerHTML]="diagnosis"></div>
                                                <!-- <P><span class="spans">Diagnosis:</span><span style="margin-top: -20px !important;"
                                                        [innerHTML]="diagnosis"></span>
                                                </P> -->
                                                <div >Procedure:</div><div style="margin-top: -15px !important;" [innerHTML]="treat_proc"></div>
                                                <!-- <P><span class="spans">procedure:</span> <span style="margin-top: -8px !important;"
                                                        [innerHTML]="treat_proc"></span>
                                                </P> -->
                                                <p><span class="spans">Pre-authorisation:</span> {{CCNno}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <table style="margin-left: 50px; margin-right: 50px;border: 1px solid #504f4f;">
                            <tr>
                                <td colspan="2">
                                    <table class="table-top">
                                        <tr>
                                            <th
                                                style="width: 100px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">
                                                S.No</th>
                                            <th
                                                style="width: 600px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">
                                                Description</th>
                                            <th style="width: 250px;border-bottom: 1px solid #504f4f;">Amount</th>
                                        </tr>
                                        <tr *ngFor="let bill of billDetails">
                                            <td style="width: 100px;border-right: 1px solid #504f4f;">{{bill.index}}
                                            </td>
                                            <td style="width: 600px; text-align: left;border-right: 1px solid #504f4f;">
                                                {{bill.name}}</td>
                                            <td style="width: 250px;text-align: right;">{{bill.fee}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="border-top: 1px solid #504f4f;">
                                                {{amountinword}}
                                            </td>
                                            <td style="text-align: right;border-top: 1px solid #504f4f;"> <b>Total
                                                    amount:</b> {{totalBill}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div #printnoheader id="printnoheader">
                        <table style="margin-left: 50px; margin-top:200px;margin-right: 50px;">
                            <tr>
                              <td colspan="2" style="width: 90%;text-align: center; ">INSURANCE BILL</td>
                            </tr>
                            <tr style="border: 1px solid #000;">
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;width: 85vw;">
                                        <tr>
                                            <td><P><span>Service Tax Registration No:</span> {{serviceNo}}</P></td>
                                            <td><P><span>Hospital Pan No:</span> {{panno}}</P></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table style="border: 1px solid #504f4f;">
                                        <tr>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Bill No:</span> {{billno}}</div>
                                                <div style="margin: 5px;"><span class="spans">IPNo:</span> {{inpid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Patient Name:</span>&nbsp; {{patname}}</div>
                                                <div style="margin: 5px;"><span class="spans">Consultant:</span> 
                                                    <table style="margin-left: 65px;">
                                                        <tr *ngFor="let name of consultarray">
                                                            <td>{{name.name}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div style="margin: 5px;"><span class="spans">Admissiom Date:</span> {{admissionDate}}
                                                    {{admissionTime}}</div>
                                                <div style="margin: 5px;"><span class="spans"> Discharge Date:</span>&nbsp; {{dischargeDate}}
                                                    {{dischargeTime}}</div>
                                            </td>
                                            <td>
                                                <div style="margin: 5px;"><span class="spans">Date:</span> {{bill_date}}</div>
                                                <div style="margin: 5px;"><span class="spans">ID no:</span> {{uhid}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance no:</span> {{insCardNo}}</div>
                                                <div style="margin: 5px;"><span class="spans">Age/Sex:</span>&nbsp; {{age}}/{{gender}}</div>
                                                <div style="margin: 5px;"><span class="spans">Insurance Name:</span> {{insurer}}</div>
                                                <div style="margin: 5px;"><span class="spans">Policy No:</span> {{policyno}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <P><span class="spans">Diagnosis:</span><span [innerHTML]="diagnosis"></span>
                                                </P>
                                                <P><span class="spans">procedure:</span> <span [innerHTML]="treat_proc"></span>
                                                </P>
                                                <p><span class="spans">PreAuthorisation:</span> {{CCNno}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <table style="margin-left: 50px; margin-right: 50px;border: 1px solid #504f4f;">
                            <tr>
                                <td colspan="2">
                                    <table class="table-top">
                                        <tr>
                                            <th style="width: 100px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">S.No</th>
                                            <th style="width: 600px;border-bottom: 1px solid #504f4f;border-right: 1px solid #504f4f;">Description</th>
                                            <th style="width: 250px;border-bottom: 1px solid #504f4f;">Amount</th>
                                        </tr>
                                        <tr *ngFor="let bill of billDetails">
                                            <td style="width: 100px;border-right: 1px solid #504f4f;">{{bill.index}}</td>
                                            <td style="width: 600px; text-align: left;border-right: 1px solid #504f4f;">{{bill.name}}</td>
                                            <td style="width: 250px;text-align: right;">{{bill.fee}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="border-top: 1px solid #504f4f;">
                                                {{amountinword}}
                                            </td>
                                            <td style="text-align: right;border-top: 1px solid #504f4f;"> <b>Total amount:</b> {{totalBill}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>