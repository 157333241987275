import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';
declare var $: any; 

@Component({
  selector: 'app-physio-user-view',
  templateUrl: './physio-user-view.component.html',
  styleUrls: ['./physio-user-view.component.scss']
})
export class PhysioUserViewComponent implements OnInit {
  public userImage: boolean;
  public imageFlag: boolean;
  public imagePath;
  public retrvMobileNo;
  public retrvEmailId;
  public firstName;
  public middleName;
  public lastName;
  public gender;
  public userType;
  public mobileNo;
  public emailId;
  public hptlClinicId;
  public userRegId;
  public retrvLocation;
  public saveFlag: boolean;
  public updateFlag: boolean;
  public retrvLocationFlag: boolean;
  public saveLocationFlag: boolean;
  public retrvFNameFlag: boolean;
  public reportApprovelFlag;
  public userDetails;

  constructor(public http: Http, public toastr: ToastrService, public messageService: PhysioService) { }
  
  ngOnInit(): void {
    this.userDetails = Physio_Helper.getUserDetails();
    this.userImage = true;
    this.retrvMobileNo = "0";
    this.retrvEmailId = "0";
    this.hptlClinicId = this.userDetails.hptl_clinic_id;
    this.userRegId = this.userDetails.user_reg_id; 
    this.imagePath = "default";
    if (this.userRegId != null) {
      this.getRetrivalData(this.userRegId);
      this.saveFlag = true;
      this.updateFlag = false;
      this.retrvLocationFlag = false;
      this.saveLocationFlag = true;
    } else {
      this.saveFlag = false;
      this.updateFlag = true;
      this.retrvLocationFlag = true;
      this.saveLocationFlag = false;
    }
  }

  getRetrivalData(user_id) {
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  this.http.post(ipaddress.getIp.toString() + "physio/gdu/",
    JSON.stringify({
      users_id: user_id,
    }),
    { headers: headers })
    .subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        if (obj.first_name != null) {
          this.firstName = obj.first_name;
          this.retrvFNameFlag = true;
        }
        if (obj.middle_name != null) {
          this.middleName = obj.middle_name;
        }
        this.lastName = obj.last_name;
        this.gender = obj.gender;
        this.userType = obj.user_type;
        this.retrvMobileNo = obj.mobile;
        this.mobileNo = obj.mobile;
        if (obj.email != null) {
          this.retrvEmailId = obj.email;
          this.emailId = obj.email;
        }
        if (obj.report_approval != null && obj.report_approval == "1") {
          this.reportApprovelFlag = true;
        }
        if (obj.sign != null) {
          this.userImage = false;
          $('#profile_img').attr('src', 'data:image/jpeg;base64,' + obj.sign);
        }
        this.retrvLocation = obj.location;
        this.changeUserType();
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  changeUserType() {
    if (this.userType == "Pathologist" || this.userType == "Chief pathologist") {
      this.userImage = false;
      this.imageFlag = true;
    } else {
      this.userImage = true;
      this.imageFlag = false;
    }
  }
}
