<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">{{header_txt}}</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diag_content">
        <div class="row" style="margin-top: 10px;margin-left: 10px;">
            <div class="col-6 showlist" *ngFor="let data of mainListData">
                <div class="row">
                    <div class="col-1" *ngIf="data.checked == false" style="margin-left: 0px;">
                        <img (click)="selectData(data.label,data.value,true)" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="12px"/>
                    </div>
                    <div class="col-1" *ngIf="data.checked == true">
                        <img (click)="selectData(data.label,data.value,false)" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px"/>
                    </div>
                    <div class="col-10" style="padding-left: 8px;font-size: 13px;font-family: arial;">
                        {{data.label}}
                    </div>
                </div>
                <!-- <mat-checkbox color="primary" multiple [checked]="data.checked" class="dropdown"
                    (change)="selectData(data.label,data.value,$event.checked)">
                    {{data.label}}</mat-checkbox> -->
            </div>
        </div>
        
</div>
<!-- <hr class="hr"> -->
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
        <button id="add_btn" [mat-dialog-close]="listData" cdkFocusInitial mat-button>
            <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
        </button>
</div>