import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ClientCancelAppointmentComponent } from '../client-cancel-appointment/client-cancel-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
declare var Razorpay: any;
@Component({
  selector: 'app-physio-appointment-details',
  templateUrl: './physio-appointment-details.component.html',
  styleUrls: ['./physio-appointment-details.component.css']
})
export class PhysioAppointmentDetailsComponent implements OnInit {
  public curentDateURL: string;
  public nursePhysioUrl: string;
  public sendClientRegid;
  public currentDate;
  public currentTime;
  public cancelFlag;
  public location;
  public appDate;
  public appTime;
  public reason;
  public gender;
  public age;
  public bloodGroup;
  public relation;
  public mobile;
  public clntName;
  public hospAddress = [];
  public symptoms;
  public appDetails: string;
  public physioNurseName: string;
  public reasonFlag: boolean;
  public appNursePhysio;
  public homecare;
  public payTpe;
  public clntemail;
  public payid;
  public orderId;
  public signature;
  public doctorFee;
  public rpayorderUrl;
  public paySuccessUrl;
  constructor(public dialog:MatDialog,public messageservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    
  }

  ngOnInit(): void {
    this.curentDateURL = ipaddress.getIp + 'adm/curdate';
    this.rpayorderUrl = ipaddress.getIp + "rpay/oid";
    this.paySuccessUrl = ipaddress.getIp + "rpay/conforder";
    this.appNursePhysio = Client_Helper.getHomecare_nurse_physio();
    this.homecare = Client_Helper.getHomecare();
    this.reasonFlag = false;
    if(this.appNursePhysio == 'nurse'){
      this.nursePhysioUrl = ipaddress.getIp + 'appointment/npappsdet/';
      this.cancelFlag = true;
    }else if(this.appNursePhysio == 'dietician'){
      this.nursePhysioUrl = ipaddress.getIp + 'appointment/npappsdet/';
      this.cancelFlag = true;
    }else {
      this.nursePhysioUrl = ipaddress.getIp + 'appointment/pappdet/';
      this.cancelFlag = false;
    }
    this.getCurrentDate();
  }

  getCurrentDate() {
    this.sendClientRegid = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curentDateURL, JSON.stringify(this.sendClientRegid), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = data.json().result;
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.currentTime = data.json().current_time;
        }
        this.appointmentDetails();
      }
    )
  }

  appointmentDetails() {
    var sendData = {
      app_id: Client_Helper.getApp_id(),
      type: this.appNursePhysio
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.nursePhysioUrl, JSON.stringify(sendData), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = JSON.stringify(data);
        var obj = JSON.parse(JSON.stringify(this.curentDateURL))
        console.log("obj" + obj);
        var dateSplit = data.json().date.split("-");
        this.appDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        var get_f_time = this.gservice.get_time_form_session(data.json().f_time, this.gservice.get_seesion_ampm(data.json().session));
        this.appTime = get_f_time;
        this.payTpe =  data.json().payment_type;
        this.doctorFee =  data.json().fee != undefined ? data.json().fee : "0.00";
        if (data.json().reason != null) {
          this.reason = data.json().reason;
          this.reasonFlag = true;
        }
        if(data.json().gender != null){
          this.gender = encrypt_decript.Decript(data.json().gender).toString();
        }
        if(data.json().mobile != null){
          this.mobile = encrypt_decript.Decript(data.json().mobile).toString();
        }
        if(data.json().symptoms != null){
          this.symptoms = encrypt_decript.Decript(data.json().symptoms).toString();
        }
        this.age = data.json().age;
        this.bloodGroup = data.json().blood_group_desc;
        this.relation = data.json().relation;
        if(data.json().dr_middle_name != undefined && data.json().dr_middle_name != null){
          this.physioNurseName = data.json().dr_first_name+" "+data.json().dr_middle_name+" "+data.json().dr_last_name;
        } else {
          this.physioNurseName = data.json().dr_first_name+" "+data.json().dr_last_name;
        }
        function StringBuilder(this:any,value) {
          this.strings = new Array("");
          this.append(value);
        }
        StringBuilder.prototype.append = function (value) {
          if (value) {
            this.strings.push(value);
          }
        }
        StringBuilder.prototype.clear = function () {
          this.strings.length = 1;
        }
        StringBuilder.prototype.toString = function () {
          return this.strings.join("");
        }
        var hospadd = new StringBuilder("")
        if (data.json().address1 != null) {
          hospadd.append(data.json().hptl_name);
          hospadd.append("\n");
          hospadd.append(encrypt_decript.Decript(data.json().address1).toString());
          hospadd.append("\n");
          if (data.json().address2 != null && data.json().address2 != "") {
            hospadd.append(encrypt_decript.Decript(data.json().address2).toString());
            hospadd.append("\n");
          }
          var zipcode =  data.json().address2 != undefined ? encrypt_decript.Decript(data.json().address2).toString() : "";
          hospadd.append(data.json().location);
          hospadd.append("\n");
          hospadd.append(data.json().city);
          hospadd.append("\n");
          hospadd.append(data.json().state+" - "+ zipcode);
          hospadd.append("\n");
          hospadd.append(data.json().country);
          this.hospAddress = hospadd.toString();
        }
        var appdet = new StringBuilder("")
        if (data.json().date != null) {
          if (data.json().date != null) {
            appdet.append("Date :" + this.gservice.get_Date_Formate(data.json().date));
            appdet.append("\n");
          }
          if (data.json().token_count == "true") {
            appdet.append("Token no :" + data.json().token);
            appdet.append("\n");
          } else {
            appdet.append("Time :" + Time_Formate_Session(data.json().f_time, encrypt_decript.Decript(data.json().session).toString()));
            appdet.append("\n");
          }
          if (data.json().session != null) {
            appdet.append("Session :" + encrypt_decript.Decript(data.json().session).toString());
          }
          this.appDetails = appdet.toString();
        }
        var new_datalist = data.json();
        var middle = "";
        if (new_datalist.middle_name != null) {
          middle = encrypt_decript.Decript(new_datalist.first_name).toString() + ' ' + encrypt_decript.Decript(new_datalist.middle_name).toString() + ' ' + encrypt_decript.Decript(new_datalist.last_name).toString();
        } else {
          middle = encrypt_decript.Decript(new_datalist.first_name).toString() + ' ' + encrypt_decript.Decript(new_datalist.last_name).toString();
        }
        this.clntName = middle;
        if(this.appNursePhysio == 'nurse'){
          if (new_datalist.status == "1") {
            this.cancelFlag = true;
          } else if (new_datalist.status == "0") {
            this.cancelFlag = false;
          } else if (new_datalist.status == "2") {
          }
          if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1") {
            this.cancelFlag = false;
          }
        } 
      }
    )
  }

  cancel() {
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '21%',
      height: '180px',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.toString().trim() == "" && data.length == 0) {
              this.toastr.error(Message_data.appcancelreason);
            } else if (data.length > 150) {
              this.toastr.error(Message_data.maxlen150);
            } else {
              var send;
              if( this.appNursePhysio == "dietician" ){
                send = {
                  type: this.appNursePhysio,
                  diet_app_id: Client_Helper.getApp_id(),
                  reason: data,
                }
              }else{
                send = {
                  type: this.appNursePhysio,
                  np_app_id: Client_Helper.getApp_id(),
                  reason: data,
                }
              }
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
                JSON.stringify(send),{ headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    if (obj.key != null) {
                      if (obj.key == "1") {
                        this.toastr.success(Message_data.appcancel);
                        this.messageservice.sendMessage("nurse");
                        Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
                        Client_Helper.setHomecare(this.homecare);
                      }
                    }
                  },error => {
                    this.toastr.error(Message_data.network);
                  });
            }
    });
  }

  myCallbackFunction = function (_params) {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  back() {
    Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
    Client_Helper.setHomecare(this.homecare);
    if( this.appNursePhysio == "dietician" ){
      this.messageservice.sendMessage("dietician");
    }else{
      this.messageservice.sendMessage("physio");
    }
    
  }
  payWithRazor() {
    var billamount= this.doctorFee+"00";
   
    var orderoptions = {
      amount: billamount,  // amount in the smallest currency unit
      currency: "INR",
      receipt: "consult_rcptid"
    };
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.rpayorderUrl, JSON.stringify(orderoptions), { headers: headers }).subscribe(
      data => {
        var obj = data.json();

        if(obj.id != undefined){
          var options = {
            description: 'Consultation fee',
            image: 'https://i.imgur.com/3g7nmJC.png',
            currency: 'INR',
            key: 'rzp_live_2MzOMLIEZm8bbG',
            order_id: obj.id,
            amount: billamount,
            name: 'gritsys',
            theme: {
              color: '#51b0e7'
            },
            "prefill":{
              "contact": this.mobile,
              "email":this.clntemail,
            }
          };
          options['handler'] = this.razorPaySuccessHandler.bind(this);
          let razorpay = new Razorpay(options);
          razorpay.open();
          razorpay.on('payment.failed', function (response){
            console.log("ERROR in payments "+response.error.description);
          });
        }
      });
  }
  
  public razorPaySuccessHandler(success) {
    this.payid = success.razorpay_payment_id;
    this.orderId = success.razorpay_order_id;
    this.signature = success.razorpay_signature;
    this.updatePaymentDetails(this.orderId,this.payid,this.signature);
  }

  updatePaymentDetails(order,payment,signature){
    var send_data={
      doc_app_id: Client_Helper.getApp_id(),
      app_type:"Doctor appointment",
      order_id:order,
      payment_id:payment,
      signature:signature,
    }
    
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.paySuccessUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
          if (data.json().key == "1") {
            this.toastr.success(Message_data.paymentSuccess);
          }
        }, error => {});
  }
}
