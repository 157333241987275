import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from '../../../assets/js/Message_data';
import { Diagnosis_Helper } from '../../Diagnosis_module/Diagnosis_Helper';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { ClientViewService } from '../../client/clientview/client-view.service';
import { Client_Helper } from '../../client/Client_helper';
import { pharmacy_helper } from '../../pharma/Pharmcy_Helper';
import { Physio_Helper } from '../../Physio_module/Physio_Helper';
import { TermsConditionComponent } from '../../../app/Doctor_module/terms-condition/terms-condition.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../../app/Doctor_module/Master_Helper';
import { Diet_Helper } from '../../../app/Diet_module/Diet_Helper';
import { MasterHelperService } from '../../../app/Doctor_module/MasterHelper.service';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';

declare var $: any;
@Component({
  selector: 'app-loginpg',
  templateUrl: './loginpg.component.html',
  styleUrls: ['./loginpg.component.css']
})
export class LoginpgComponent implements OnInit {
  public senddata;
  public url_txt;
  public hospital_array:any = [];
  public ipaddress: string;
  public username;
  public pwd: string;
  public typeof: string;
  public Retrival_data:any = [];
  user_id: any;
  obj: any;
  hobj: any;
  consultation: any;
  hospitalId: any[];
  hptl_clinic_id: any;
  hospitalLocation;
  public urlt = ipaddress.getIp + "usercontroller/providerdetails";
  public hurlt = ipaddress.getIp + "usercontroller/hospdetails";

  //client login
  public module_data: any = [];
  public both_modul_list: any = [];
  public submenu_drawer_modul_list: any = [];
  public Login_detail_data: any = [];
  public Logindata: any = [];
  public response_middlename;
  public passvalidation: boolean = false;

  //pharma
  public pharma_hospital_list;
  public isemail: boolean;
  public LoginResponse: any = [];
  public usertype: any = [];
  public packageExpierflag: boolean;

  //payment
  public package_details:any = [];
  public PackageModels;
  public MainPackage;
  public Pack_id_list:any = [];
  public Package_Name;
  public Package_Validity;
  public client_package_data: any;
  public client_app_booking_flag: boolean = false;

  public CurrentDatetime;
  public tomorrow;
  public Appointment_Date;
  public currentDate;
  public hospilas_arry:any = [];
  public selectUser: boolean = false;
  private isMobile:boolean= false;
  private user_type: string;

  constructor(public gservice: CommonDataService, public router: Router, public route: ActivatedRoute,
    public http: HttpClient, public toastr: ToastrService, public clientservice: ClientViewService, public dialog: MatDialog,
    public masterData: MasterHelperService) {
    this.gservice.Client_ID = undefined;
    this.gservice.Client_login_detail_data = undefined;
    this.packageExpierflag = false;
    Doc_Helper.setHospFooter(undefined);
    Helper_Class.setConsultant(undefined);
    Helper_Class.setSpecializations(undefined);
    Helper_Class.setIsAdmin(undefined);
    Helper_Class.setPrintFlag(undefined);
    Master_Helper.setMasterCurrentDate(undefined);
    Client_Helper.setActivityActualDuration(undefined);
    Client_Helper.setDietProgress(undefined);
    Client_Helper.setClntHeightMsr(undefined);
    Diet_Helper.setAppFlow(undefined);
    this.getIP();
    this.masterData.clearmasterData();
  }

  ngOnInit(): void {
    this.getCurrentDate();
    if (Helper_Class.getTreatmentList() != null) {
      if (Helper_Class.getTreatmentList().app_flow != null) {
        if (Helper_Class.getTreatmentList().app_flow == "without_Normal") {
          this.client_app_booking_flag = true;
        }
      }
    }
  }

  login(type) {
    var url;

    this.toastr.clear();
    this.gservice.Client_login_detail_data = null;
    if (this.username == "") {
      this.toastr.error("Enter user name or email or mobile number")
    } else if (this.pwd == "") {
      this.toastr.error("Enter password")
    } else {
      this.isEmailId(this.username);
      var url: any = ipaddress.getIp.toString() + 'usercontroller/provlogn';
      if (type == "client") {
        url = ipaddress.getIp.toString() + 'usercontroller/clientlog';
      } else if (type == "front-desk" || type == "Admin" || type == "Billing" || type == "OT admin") {
        url = ipaddress.getIp.toString() + 'usercontroller/usrlogn';
      } else if (type == "consultant") {
        url = ipaddress.getIp.toString() + 'usercontroller/conlog';
      }

      var Send_data = {
        email: this.isemail,
        user: this.username.trim(),
        password: this.pwd.trim(),
        imei: this.ipaddress,
        country: ipaddress.country_code.toString(),
        type: type,
        flag: "web",
        user_type:this.user_type,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, (Send_data),
        { headers: headers })
        .subscribe(
          response => {
            this.obj = JSON.parse(JSON.stringify(response));
            console.log("LOGIN DETAILS "+JSON.stringify(this.obj))
            if (this.obj != undefined) {
              this.GetLogingDetails();
            } else {
              if (this.obj.key == "1") {
                this.toastr.error('Your account yet to be activated');
              } else if (this.obj.key == "2") {
                this.toastr.error('Verify Password');
              } else if (this.obj.key == "3") {
                this.toastr.error('Check login credentials');
              } else if (this.obj.key == "4") {
                this.toastr.error('Subscription expired, please renew');
                this.usertype.push(this.obj.login_details[0].type);
                if (this.usertype == "Client") {
                  this.packageExpierflag = true;
                  this.client_package();
                }
              } else {
                this.toastr.error('Invalid user');
              }
            }
            error => {
              this.toastr.error(Message_data.getNetworkMessage());
            }
          })
    }
  }

  focusUserType(e) {
    if (e != undefined && e != "") {
      var no = this.isNumber(e);
      this.isEmailId(this.username);
    }

    if (e != undefined && e != "") {
      // if (this.isemail == true) {
      //   this.getUserType(e);
      // } else {
      //   if (no == true) {
      //     if (e.length == 10) {
      //       this.getUserType(e);
      //     }
      //   }
      // }

      if (no == true) {
        if (e.length == 10) {
          this.getUserType(e);
        }

      } else {
        this.getUserType(e);
      }

    } else {
      this.selectUser = false;
    }
  }

  getUserType(e) {
    if(this.isemail == true){
      this.user_type= "email";
    } else if(this.isMobile == true) {
      this.user_type ="mobile"
    } else {
      this.user_type ="userid"
    }

    var send_data = ({
      user: e,
      email: this.isemail,
      country: ipaddress.country_code,
      useid_type:this.user_type
    })

    this.usertype = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'usercontroller/gut', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.user_types != undefined && obj.user_types != null) {
            this.usertype = [];
            for (var i = 0; i < obj.user_types.length; i++) {
              this.usertype.push(obj.user_types[i]);
            }
            if (this.usertype.length != 0) {
              this.selectUser = true;
            } else {
              this.selectUser = false;
            }
            
          } else {
            if (obj.key == "1") {
              this.toastr.error('Your account yet to be activated');
            } else if (obj.key == "2") {
              this.toastr.error('Verify Password');
            } else if (obj.key == "3") {
              this.toastr.error('Check login credentials');
            } else if (obj.key == "4") {
              this.toastr.error('Subscription expired, please renew');
              this.usertype.push(obj.login_details[0].type);
              if (this.usertype == "Client") {
                this.packageExpierflag = true;
                this.client_package();
              }
            } else {
              this.toastr.error('Invalid user');
            }
          }
        });
  }

  getCurrentDate() {
    var get_date;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      {
        country: ipaddress.country_code.toString()
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          Master_Helper.setMasterCurrentDate(obj);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.Appointment_Date = this.CurrentDatetime;

            Doc_Helper.setAppDate(this.Appointment_Date);
            Physio_Helper.setAppDate(this.Appointment_Date);
            Diet_Helper.setAppDate(this.Appointment_Date);
            FrontDesk_Helper.setAppDate(this.Appointment_Date);
          }
        },
        error => {

        }
      )
  }

  selectType(type) {
    this.login(type);
  }

  GetLogingDetails() {
    this.Retrival_data = this.obj;
    console.log("USER INFO "+JSON.stringify(this.Retrival_data))
    Helper_Class.setBillView("0");
    Helper_Class.setBillCreate("0");
    Helper_Class.setBillEdit("0");
    if (this.obj.provider == "doctor" || this.obj.provider == "consultant") {
      Helper_Class.setLoginType(this.obj.provider);
      Helper_Class.setBillingFlow(undefined)
      if (this.obj.client_base != undefined && this.obj.client_base != null && this.obj.client_base != "") {
        Helper_Class.setConsultant(this.obj.client_base);
        this.getSpecializations();
      } else {
        Helper_Class.setConsultant(undefined)
      }

      if (this.Retrival_data.user_id != null) {
        console.log("DOCTOR LOGIN "+JSON.stringify(this.Retrival_data))
        Helper_Class.setInfo(this.Retrival_data);
        localStorage.setItem("licence_code", this.Retrival_data.licence_code);
        localStorage.setItem("current_date", this.Retrival_data.current_date);
        Helper_Class.setSpecializations(this.Retrival_data.specializations);
        localStorage.setItem("Specilizations", JSON.stringify(this.Retrival_data.specializations))
        this.user_id = this.Retrival_data.user_id;

        if (this.Retrival_data.both_modules != null && this.Retrival_data.both_modules != undefined) {
          console.log("BOTH MODULES "+JSON.stringify(this.Retrival_data.both_modules))
          for (var i = 0; i < this.Retrival_data.both_modules.length; i++) {
            this.module_data = [];
            this.Retrival_data.both_modules[i].module_id;
            this.module_data.push(this.Retrival_data.both_modules[i].module_id);
            this.both_modul_list.push({
              module_id:this.Retrival_data.both_modules[i].module_id,
              create:this.Retrival_data.both_modules[i].create,
              edit:this.Retrival_data.both_modules[i].edit,
              view:this.Retrival_data.both_modules[i].view,
              delete:this.Retrival_data.both_modules[i].delete,
              print:this.Retrival_data.both_modules[i].print,
            });
          }
        }

        if (this.Retrival_data.home_modules != null) {
          for (var j = 0; j < this.Retrival_data.home_modules.length; j++) {
            this.module_data = [];
            this.module_data.push(this.Retrival_data.home_modules[j].module_id);
            this.both_modul_list.push({
              module_id:this.Retrival_data.home_modules[j].module_id,
              create:this.Retrival_data.home_modules[j].create,
              edit:this.Retrival_data.home_modules[j].edit,
              view:this.Retrival_data.home_modules[j].view,
              delete:this.Retrival_data.home_modules[j].delete,
              print:this.Retrival_data.home_modules[j].print,
            });
          }
        }

        if (this.Retrival_data.menudrawer_modules != null) {
          for (var k = 0; k < this.Retrival_data.menudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(this.Retrival_data.menudrawer_modules[k].module_id);
            this.both_modul_list.push({
              module_id:this.Retrival_data.menudrawer_modules[k].module_id,
              create:this.Retrival_data.menudrawer_modules[k].create,
              edit:this.Retrival_data.menudrawer_modules[k].edit,
              view:this.Retrival_data.menudrawer_modules[k].view,
              delete:this.Retrival_data.menudrawer_modules[k].delete,
              print:this.Retrival_data.menudrawer_modules[k].print,
            });
          }
        }

        if (this.Retrival_data.submenudrawer_modules != null) {
          for (var k = 0; k < this.Retrival_data.submenudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(this.Retrival_data.submenudrawer_modules[k].module_id);
            this.both_modul_list.push({
              module_id:this.Retrival_data.submenudrawer_modules[k].module_id,
              create:this.Retrival_data.submenudrawer_modules[k].create,
              edit:this.Retrival_data.submenudrawer_modules[k].edit,
              view:this.Retrival_data.submenudrawer_modules[k].view,
              delete:this.Retrival_data.submenudrawer_modules[k].delete,
              print:this.Retrival_data.submenudrawer_modules[k].print,
            });
          }
        }

        Helper_Class.setmodulelist(this.both_modul_list);
        Doc_Helper.setModuleList(this.both_modul_list);
        if (this.Retrival_data.hospitals.length != 0) {
          Helper_Class.setHospital(this.Retrival_data.hospitals);
          localStorage.setItem("hospital", JSON.stringify(this.Retrival_data.hospitals))
          for (var e = 0; e < this.Retrival_data.hospitals.length; e++) {
            this.hospitalId = this.Retrival_data.hospitals[e].hptl_clinic_id;
            Doc_Helper.setDisPrintTemp(this.Retrival_data.hospitals[e].dis_sum_print_template);
            Doc_Helper.setDisPrintHeaderText(this.Retrival_data.hospitals[e].dis_sum_header_text);
            Doc_Helper.setDisPrintFooterText(this.Retrival_data.hospitals[e].dis_sum_footer_text)
          }
          if (this.Retrival_data.hospitals[0].footer != undefined) {
            Doc_Helper.setHospFooter(ipaddress.Ip_with_img_address + this.Retrival_data.hospitals[0].footer);
          }
        }
        
        this.getHospitalLocation(this.hospitalId);
        Doc_Helper.setHospitalClinicalId(this.hospitalId);
        this.hptl_clinic_id = this.hospitalId;
        localStorage.setItem("hptl_clinic_id", this.hptl_clinic_id);
        localStorage.setItem("ipaddress", this.ipaddress);
        
      } else {
        this.toastr.error(Message_data.getGenralError());
      }

    } else if (this.obj.client != null) {
      localStorage.setItem("Client_Id", this.Retrival_data.client)
      Helper_Class.setInfo(this.Retrival_data);
      Helper_Class.setLoginType("client");
      var datachk = {
        flow: "client",
        weight_measure: this.Retrival_data.weight_measure,
        height_measure: this.Retrival_data.height_measure,
        height: this.Retrival_data.height,
        weight: this.Retrival_data.weight,
      }
      Client_Helper.setClntHeightMsr(datachk);

      if (this.Retrival_data.both_modules != null && this.Retrival_data.both_modules != undefined) {
        for (var i = 0; i < this.Retrival_data.both_modules.length; i++) {
          this.module_data = [];
          this.Retrival_data.both_modules[i].module_id;
          this.module_data.push(this.Retrival_data.both_modules[i].module_id);
          this.both_modul_list.push(this.module_data);
        }
      }
      if (this.Retrival_data.home_modules != null) {
        for (var j = 0; j < this.Retrival_data.home_modules.length; j++) {
          this.module_data = [];
          this.module_data.push(this.Retrival_data.home_modules[j].module_id);
          this.both_modul_list.push(this.module_data);
        }
      }
      if (this.Retrival_data.submenudrawer_modules != null) {
        for (var k = 0; k < this.Retrival_data.submenudrawer_modules.length; k++) {
          this.module_data = [];
          this.module_data.push(this.Retrival_data.submenudrawer_modules[k].module_id);
          this.submenu_drawer_modul_list.push(this.module_data);
          this.both_modul_list.push(this.Retrival_data.submenudrawer_modules[k].module_id);
        }
      }
      if (this.Retrival_data.key == "4") {
        this.toastr.error("Renew your subscription");
        this.Login_detail_data = {
          Client_Id: this.Retrival_data.client,
        }
        this.gservice.Client_login_detail_data = this.Login_detail_data;
        localStorage.setItem("Client_login_detail_data", this.Login_detail_data);
      } else if (this.Retrival_data.client != null) {
        this.Logindata.push(this.Retrival_data.client); // 0
        this.response_middlename = "";
        if (this.Retrival_data.middle_name != null) {
          this.response_middlename = encrypt_decript.Decript(this.Retrival_data.first_name) + " " + encrypt_decript.Decript(this.Retrival_data.middle_name) + " " + encrypt_decript.Decript(this.Retrival_data.last_name);
        } else {
          this.response_middlename = encrypt_decript.Decript(this.Retrival_data.first_name) + " " + encrypt_decript.Decript(this.Retrival_data.last_name);
        }
        this.Logindata.push(this.response_middlename);
        this.Logindata.push(ipaddress.Ip_with_img_address + this.Retrival_data.profile_image); // 2
        this.Logindata.push(this.Retrival_data.location); // 3
        this.Logindata.push(this.Retrival_data.city); // 4
        this.Logindata.push(this.Retrival_data.state); // 5
        this.Logindata.push(this.Retrival_data.country); // 6
        this.Logindata.push(this.Retrival_data.location_desc); // 7
        this.Logindata.push(this.Retrival_data.city_desc); // 8
        this.Logindata.push(this.Retrival_data.state_desc); // 9
        this.Logindata.push(this.Retrival_data.country_desc); // 10
        localStorage.setItem("user_id", this.Retrival_data.client);
        this.Login_detail_data = {
          Client_Id: this.Retrival_data.client,
          life_style_id: this.Retrival_data.life_style_id,
          Full_Name: this.response_middlename,
          Profile_Image: ipaddress.Ip_with_img_address + this.Retrival_data.profile_image,
          Location_ID: this.Retrival_data.location,
          City_ID: this.Retrival_data.city,
          State_ID: this.Retrival_data.state,
          Country_ID: this.Retrival_data.country,
          Client_Location: this.Retrival_data.location_desc,
          Client_City: this.Retrival_data.city_desc,
          Client_State: this.Retrival_data.state_desc,
          Client_Country: this.Retrival_data.country_desc,
          package_id: this.Retrival_data.package_id,
          gender: this.Retrival_data.gender,
          age: this.Retrival_data.age,
        }

        Helper_Class.setModules(this.both_modul_list);
        this.gservice.Client_login_detail_data = this.Login_detail_data;
        localStorage.setItem("Client_login_detail_data", this.Login_detail_data);
        if (Helper_Class.getTreatmentList() != null) {
          if (Helper_Class.getTreatmentList().app_flow != null) {
            if (Helper_Class.getTreatmentList().app_flow == "without_Normal") {
              this.client_app_booking_flag = true;
              if (Helper_Class.getTreatmentList().homecare != null) {
                Client_Helper.setHomecare(Helper_Class.getTreatmentList().homecare);
                Client_Helper.setHomecare_nurse_physio("");
                this.router.navigateByUrl("/clientview");
              } else {
                this.router.navigateByUrl("/clientview");
              }
            } else {
              this.router.navigateByUrl("/clientview");
            }
          } else {
            this.router.navigateByUrl("/clientview");
          }
        } else {
          this.router.navigateByUrl("/clientview");
        }

      }
    } else if (this.obj.provider == "pharmacy") {
      Helper_Class.setLoginType(this.obj.provider);
      Helper_Class.setInfo(this.obj);
      var both_modul_list = [];
      var submenu_drawer_modul_list:any=[];
      var module_data = [];
      if (this.obj.user_id != null) {

        if (this.obj.both_modules != null) {
          for (var k = 0; k < this.obj.both_modules.length; k++) {
            module_data.push(this.obj.both_modules[k].module_id);
            this.both_modul_list.push(module_data);
          }
          pharmacy_helper.setModuleList(module_data);
        }
        Helper_Class.setmodulelist(this.obj.both_modules);
        this.pharma_hospital_list = [];
        if (this.obj.hospitals != null) {
          for (var i = 0; i < this.obj.hospitals.length; i++) {
            this.pharma_hospital_list.push({
              hptl_clinic_id: this.obj.hospitals[i].hptl_clinic_id,
              hptl_nam: this.obj.hospitals[i].hptl_name,
            })
          }
        }

        if (this.obj.submenudrawer_modules != null) {
          for (var j = 0; j < this.obj.submenudrawer_modules.length; j++) {
            var module_data2 = [];
            module_data2.push(this.obj.submenudrawer_modules[j].module_id);
            submenu_drawer_modul_list.push(module_data2);
          }
        }
        Helper_Class.setHospitalInfo(this.obj.hospitals[0]);
        this.router.navigateByUrl("/menuView");
      }
    } else if (this.obj.provider == "diagnosis") {
      var obj = this.obj;
      Helper_Class.setLoginType(this.obj.provider);
      if (obj.user_id != null) {
        Helper_Class.setInfo(obj);
        if (obj.both_modules != null) {
          for (var k = 0; k < obj.both_modules.length; k++) {
            var module_data = [];
            module_data.push(obj.both_modules[k].module_id);
            this.both_modul_list.push(module_data);
          }
        }

        if (obj.home_modules != null) {
          for (var i = 0; i < obj.home_modules.length; i++) {
            var module_data1: any = [];
            module_data1.push(obj.home_modules[i].module_id);
            this.both_modul_list.push(module_data1);
          }
        }

        if (obj.submenudrawer_modules != null) {
          for (var j = 0; j < obj.submenudrawer_modules.length; j++) {
            var module_data2 = [];
            module_data2.push(obj.submenudrawer_modules[j].module_id);
            submenu_drawer_modul_list.push(module_data2);
          }
        }
        var type_user;
        if (obj.user_type == "diagnosis") {
          type_user = {
            user_id: obj.user_id,
            user_type: null,
            diag_centre_id: obj.hospitals[0].hptl_name,

          }
          Helper_Class.setmodulelist(obj.both_modules);
          Diagnosis_Helper.setmodulelistIds(this.both_modul_list);
          Diagnosis_Helper.setUserID_Type(type_user);

          localStorage.setItem("Diagnosis_Admin", "Diagnosis_Admin");
          localStorage.setItem("user_id", obj.user_id);
          localStorage.setItem("dr_first_name", obj.first_name);
          localStorage.setItem("dr_middle_name", obj.middle_name);
          localStorage.setItem("dr_last_name", obj.last_name);
          localStorage.setItem("profile_image", ipaddress.Ip_with_img_address + obj.profile_image);
          localStorage.removeItem("user_type");
          localStorage.setItem("diag_centre_id", obj.diag_centre_id);
          localStorage.setItem("package_id", obj.package_id);
          localStorage.setItem("ipaddress", this.ipaddress);
          Helper_Class.setHospitalInfo(obj.hospitals[0]);
          this.getHospitalLocation("");
          
        } else if (obj.user_type == "Chief pathologist" || obj.user_type == "Pathologist" || obj.user_type == "Chief lab technician" || obj.user_type == "Lab technician") {
          type_user = {
            user_id: obj.user_id,
            user_type: obj.user_type,
            diag_centre_id: obj.hospitals[0].hptl_name
          }
          Diagnosis_Helper.setDiag_user_reg_id(obj.user_id)
          Diagnosis_Helper.setUserID_Type(type_user);
          localStorage.setItem("user_type", obj.user_type);
          localStorage.setItem("user_id", obj.user_id);
          localStorage.setItem("dr_first_name", obj.first_name);
          localStorage.setItem("dr_middle_name", obj.middle_name);
          localStorage.setItem("dr_last_name", obj.last_name);
          localStorage.setItem("profile_image", ipaddress.Ip_with_img_address + obj.profile_image);
          localStorage.setItem("diag_centre_id", obj.diag_centre_id);
          localStorage.setItem("package_id", obj.package_id);
          localStorage.setItem("ipaddress", this.ipaddress);
          Helper_Class.setHospital(this.obj.hospitals);
          if (obj.address2 != null && obj.address2 != "") {
            localStorage.setItem("adress_details", obj.address1 + " " + obj.address2);
          }
          else {
            localStorage.setItem("adress_details", obj.address1);
          }
          localStorage.setItem("location", obj.location);
          localStorage.setItem("location_id", obj.location_id);
          localStorage.setItem("city", obj.city);
          localStorage.setItem("district_id", obj.district_id);
          localStorage.setItem("state", obj.state);
          localStorage.setItem("state_id", obj.state_id);
          localStorage.setItem("country", obj.country);
          localStorage.setItem("country_id", obj.country_id);
          localStorage.setItem("zipcode", obj.zipcode);
          localStorage.setItem("telephone", obj.telephone);
          localStorage.setItem("diag_centre_name", obj.diag_centre_name);
          localStorage.setItem("reportapproval", obj.report_approval);
          Helper_Class.setHospitalInfo(obj.hospitals[0]);
          this.getHospitalLocation("");
        }
      }
      
    } else if (this.obj.user_type == "front-desk") {
      Helper_Class.setLoginType(this.obj.user_type);
      var both_modul_list = [];
      if (this.obj.both_modules != null) {
        for (var k = 0; k < this.obj.both_modules.length; k++) {
          var module_data = [];
          module_data.push(this.obj.both_modules[k].module_id);
          both_modul_list.push(this.obj.both_modules[k].module_id);
        }
      }
      // Helper_Class.setmodulelist(this.obj.both_modules);

      FrontDesk_Helper.setmodules(this.obj.both_modules);
      Helper_Class.setmodulelist(this.obj.both_modules);
      Helper_Class.setspecalization_frontdesk(this.obj.specializations);
      Helper_Class.setInfo(this.obj);
      console.log("USER INFO "+JSON.stringify(Helper_Class.getInfo()));
      localStorage.setItem("user_id", this.obj.user_id);

      localStorage.setItem("Specilizations", JSON.stringify(this.obj.specializations))
      console.log("Specilizations", JSON.stringify(this.obj.specializations))
      Helper_Class.setHospitalId(this.obj.hptl_clinic_id);

      var print_flag = {
        admin_bill_generate: this.obj.admin_bill_generate,
        admin_bill_print: this.obj.admin_bill_print,
        admin_pres_print: this.obj.admin_pres_print,
      }
      type_user = {
        user_id: this.obj.user_id,
        user_type: this.obj.user_type,
        diag_centre_id: this.obj.hospitals[0].diag_centre_name,
      }

      Helper_Class.setHospital(this.obj.hospitals);
      Diagnosis_Helper.setUserID_Type(type_user);
      Helper_Class.setPrintFlag(print_flag);
      FrontDesk_Helper.setHospital_pres_logo(this.obj.print_logo);
      FrontDesk_Helper.setHospital_print_template(this.obj.print_template);
      FrontDesk_Helper.setDisPrintTemp(this.obj.dis_sum_print_template);
      FrontDesk_Helper.setDisPrintHeaderText(this.obj.dis_sum_heading);
      FrontDesk_Helper.setDisPrintFooterText(this.obj.dis_sum_footer);

      this.router.navigateByUrl("/menuView");

    } else if (this.obj.user_type == "Billing") {
      Helper_Class.setLoginType(this.obj.user_type);
      var both_modul_list = [];
      if (this.obj.both_modules != null) {
        for (var k = 0; k < this.obj.both_modules.length; k++) {
          var module_data = [];
          module_data.push(this.obj.both_modules[k].module_id);
          both_modul_list.push(this.obj.both_modules[k]);
        }
      }
      FrontDesk_Helper.setmodules(both_modul_list)
      Helper_Class.setmodulelist(both_modul_list);
      Helper_Class.setspecalization_frontdesk(this.obj.specializations);
      Helper_Class.setInfo(this.obj);
      console.log("USER INFO "+JSON.stringify(Helper_Class.getInfo()));
      localStorage.setItem("user_id", this.obj.user_id);

      localStorage.setItem("Specilizations", JSON.stringify(this.obj.specializations))
      console.log("Specilizations", JSON.stringify(this.obj.specializations))
      Helper_Class.setHospitalId(this.obj.hptl_clinic_id);

      var print_flag = {
        admin_bill_generate: this.obj.admin_bill_generate,
        admin_bill_print: this.obj.admin_bill_print,
        admin_pres_print: this.obj.admin_pres_print,
      }
      type_user = {
        user_id: this.obj.user_id,
        user_type: this.obj.user_type,
        diag_centre_id: this.obj.hospitals[0].diag_centre_name,
      }

      Helper_Class.setHospital(this.obj.hospitals);
      Diagnosis_Helper.setUserID_Type(type_user);
      Helper_Class.setPrintFlag(print_flag);
      FrontDesk_Helper.setHospital_pres_logo(this.obj.print_logo);
      FrontDesk_Helper.setHospital_print_template(this.obj.print_template);
      FrontDesk_Helper.setDisPrintTemp(this.obj.dis_sum_print_template);
      FrontDesk_Helper.setDisPrintHeaderText(this.obj.dis_sum_heading);
      FrontDesk_Helper.setDisPrintFooterText(this.obj.dis_sum_footer);

      this.router.navigateByUrl("/menuView");

    } else if (this.obj.provider == "nurse") {
      var both_modul_list = [];
      Helper_Class.setLoginType(this.obj.provider);
      if (this.obj.both_modules != null) {
        for (var k = 0; k < this.obj.both_modules.length; k++) {
          var module_data = [];
          module_data.push(this.obj.both_modules[k].module_id);
          both_modul_list.push(this.obj.both_modules[k].module_id);
        }
      }

      if (this.obj.home_modules != null) {
        for (var i = 0; i < this.obj.home_modules.length; i++) {
          var module_data1: any = [];
          module_data1.push(this.obj.home_modules[i].module_id);
          both_modul_list.push(module_data1);
        }
      }
      
      Helper_Class.setNurseWardList(this.obj.wards);
      FrontDesk_Helper.setmodules(this.obj.both_modules);
      Helper_Class.setInfo(this.obj);
      Helper_Class.setHospital(this.Retrival_data.hospitals);
      //  Helper_Class.setmodulelist(both_modul_list);
      Helper_Class.setmodulelist(this.obj.both_modules);
      Helper_Class.setsubmodulelist(submenu_drawer_modul_list);
      Helper_Class.setHospitalId(this.obj.hospitals[0].hptl_clinic_id);
      this.router.navigateByUrl("/menuView");

    } else if (this.obj.provider == "physio") {
      Helper_Class.setInfo(this.Retrival_data);
      Helper_Class.setHospital(this.Retrival_data.hospitals);
      Helper_Class.setLoginType(this.obj.provider);
      var both_modul_list = [];
      var submenu_drawer_modul_list: any = [];

      if (this.Retrival_data.user_id != null) {
        this.hospilas_arry = [];

        for (var i = 0; i < this.Retrival_data.hospitals.length; i++) {
          this.hospilas_arry.push({
            hptl_clinic_id: this.Retrival_data.hospitals[i].hptl_clinic_id,
            hptl_name: this.Retrival_data.hospitals[i].hptl_name,
          })
        }

        if (this.Retrival_data.both_modules != null) {
          for (var k = 0; k < this.Retrival_data.both_modules.length; k++) {
            var module_data = [];
            module_data.push(this.Retrival_data.both_modules[k].module_id);
            both_modul_list.push(this.Retrival_data.both_modules[k].module_id);
          }
        }

        if (this.Retrival_data.home_modules != null) {
          for (var i = 0; i < this.Retrival_data.home_modules.length; i++) {
            var module_data1: any = [];
            module_data1.push(this.Retrival_data.home_modules[i].module_id);
            both_modul_list.push(module_data1);
          }
        }

        Physio_Helper.setModuleList(both_modul_list);
        Helper_Class.setmodulelist(both_modul_list);
        if (this.Retrival_data.submenudrawer_modules != null) {
          for (var j = 0; j < this.Retrival_data.submenudrawer_modules.length; j++) {
            var module_data2 = [];
            module_data2.push(this.Retrival_data.submenudrawer_modules[j].module_id);
            submenu_drawer_modul_list.push(module_data2);
          }
        }

        var type_user;
        if (this.Retrival_data.type == "physio") {
          type_user = {
            user_id: this.Retrival_data.user_id,
            user_type: null,
            diag_centre_id: this.Retrival_data.diag_centre_id
          }

        } else {
          type_user = {
            user_id: this.Retrival_data.user_id,
            user_type: this.Retrival_data.type,
            diag_centre_id: this.Retrival_data.diag_centre_id
          }
        }

        Physio_Helper.setUserTypeId(module_data);
        if (this.Retrival_data.hospitals.length != 0) {
          Helper_Class.setHospital(this.Retrival_data.hospitals);
          localStorage.setItem("hospital", JSON.stringify(this.Retrival_data.hospitals))
          for (var e = 0; e < this.Retrival_data.hospitals.length; e++) {
            this.hospitalId = this.Retrival_data.hospitals[e].hptl_clinic_id;
          }
        }
        this.getHospitalLocation(this.hospitalId);
      }

    } else if (this.obj.user_type == "OT admin") {
      Helper_Class.setLoginType(this.obj.user_type);
      var both_modul_list = [];
      if (this.Retrival_data.both_modules != null) {
        for (var i = 0; i < this.Retrival_data.both_modules.length; i++) {
          this.both_modul_list.push({
            module_id:this.Retrival_data.both_modules[i].module_id,
            create:this.Retrival_data.both_modules[i].create,
            edit:this.Retrival_data.both_modules[i].edit,
            view:this.Retrival_data.both_modules[i].view,
            delete:this.Retrival_data.both_modules[i].delete,
            print:this.Retrival_data.both_modules[i].print,
          });
        }
      }
      
      Helper_Class.setmodulelist(this.both_modul_list);
      Helper_Class.setspecalization_frontdesk(this.obj.specializations);
      Helper_Class.setInfo(this.obj);
      console.log("USER INFO "+JSON.stringify(Helper_Class.getInfo()));
      localStorage.setItem("user_id", this.obj.user_id);

      localStorage.setItem("Specilizations", JSON.stringify(this.obj.specializations))
      console.log("Specilizations", JSON.stringify(this.obj.specializations))
      Helper_Class.setHospitalId(this.obj.hptl_clinic_id);

      var print_flag = {
        admin_bill_generate: this.obj.admin_bill_generate,
        admin_bill_print: this.obj.admin_bill_print,
        admin_pres_print: this.obj.admin_pres_print,
      }
      type_user = {
        user_id: this.obj.user_id,
        user_type: this.obj.user_type,
        diag_centre_id: this.obj.hospitals[0].diag_centre_name,
      }

      Helper_Class.setHospital(this.obj.hospitals);
      Diagnosis_Helper.setUserID_Type(type_user);
      Helper_Class.setPrintFlag(print_flag);
      FrontDesk_Helper.setHospital_pres_logo(this.obj.print_logo);
      FrontDesk_Helper.setHospital_print_template(this.obj.print_template);
      FrontDesk_Helper.setDisPrintTemp(this.obj.dis_sum_print_template);
      FrontDesk_Helper.setDisPrintHeaderText(this.obj.dis_sum_heading);
      FrontDesk_Helper.setDisPrintFooterText(this.obj.dis_sum_footer);

      this.router.navigateByUrl("/menuView");

    } else if (this.obj.user_type == "Admin") {
      Helper_Class.setIsAdmin(true);
      Helper_Class.setLoginType(this.obj.user_type);
      if (this.obj.both_modules != null) {
        var both_modul_list = [];
        for (var k = 0; k < this.obj.both_modules.length; k++) {
          var module_data = [];
          module_data.push(this.obj.both_modules[k].module_id);
          both_modul_list.push(this.obj.both_modules[k].module_id);
        }
      }

      if (this.obj.home_modules != null) {
        for (var i = 0; i < this.obj.home_modules.length; i++) {
          var module_data1: any = [];
          module_data1.push(this.obj.home_modules[i].module_id);
          both_modul_list.push(module_data1);
        }
      }

      if (this.obj.submenudrawer_modules != null) {
        for (var j = 0; j < this.obj.submenudrawer_modules.length; j++) {
          var module_data2 = [];
          module_data2.push(this.obj.submenudrawer_modules[j].module_id);
          submenu_drawer_modul_list.push(module_data2);
        }
      }
      var print_flag = {
        admin_bill_generate: this.obj.admin_bill_generate,
        admin_bill_print: this.obj.admin_bill_print,
        admin_pres_print: this.obj.admin_pres_print,
      }

      Helper_Class.setInfo(this.obj);
      console.log("this.obj" + JSON.stringify(this.obj));
      localStorage.setItem("user_id", this.obj.user_id);
      Helper_Class.setmodulelist(both_modul_list)
      Helper_Class.setsubmodulelist(submenu_drawer_modul_list)
      Helper_Class.setHospitalId(this.obj.hptl_clinic_id);
      Helper_Class.setmodulelist(this.obj.both_modules);
      Helper_Class.setHospital(this.obj.hospitals);
      Helper_Class.setPrintFlag(print_flag);
      this.getHospitalLocation(this.obj.hptl_clinic_id);
      Doc_Helper.setDisPrintTemp(this.hobj.dis_sum_print_template);
      Doc_Helper.setDisPrintHeaderText(this.hobj.dis_sum_header_text);
      Doc_Helper.setDisPrintFooterText(this.hobj.dis_sum_footer_text);

    } else if (this.obj.provider == "dietician") {
      Helper_Class.setInfo(this.Retrival_data);
      Helper_Class.setHospital(this.Retrival_data.hospitals);
      Diet_Helper.setAppFlow("Dietician");
      Helper_Class.setLoginType(this.obj.provider);
      var both_modul_list = [];
      var submenu_drawer_modul_list: any = [];

      if (this.Retrival_data.user_id != null) {
        this.hospilas_arry = [];

        for (var i = 0; i < this.Retrival_data.hospitals.length; i++) {
          this.hospilas_arry.push({
            hptl_clinic_id: this.Retrival_data.hospitals[i].hptl_clinic_id,
            hptl_name: this.Retrival_data.hospitals[i].hptl_name,
          })
        }

        if (this.Retrival_data.both_modules != null) {
          for (var k = 0; k < this.Retrival_data.both_modules.length; k++) {
            var module_data = [];
            module_data.push(this.Retrival_data.both_modules[k].module_id);
            both_modul_list.push(this.Retrival_data.both_modules[k].module_id);
          }
        }

        if (this.Retrival_data.home_modules != null) {
          for (var i = 0; i < this.Retrival_data.home_modules.length; i++) {
            var module_data1: any = [];
            module_data1.push(this.Retrival_data.home_modules[i].module_id);
            both_modul_list.push(module_data1);
          }
        }
        Diet_Helper.setModuleList(both_modul_list);
        Helper_Class.setmodulelist(both_modul_list);
        if (this.Retrival_data.submenudrawer_modules != null) {
          for (var j = 0; j < this.Retrival_data.submenudrawer_modules.length; j++) {
            var module_data2 = [];
            module_data2.push(this.Retrival_data.submenudrawer_modules[j].module_id);
            submenu_drawer_modul_list.push(module_data2);
          }
        }
        var type_user;
        if (this.Retrival_data.hospitals.length != 0) {
          Helper_Class.setHospital(this.Retrival_data.hospitals);
          localStorage.setItem("hospital", JSON.stringify(this.Retrival_data.hospitals))
          for (var e = 0; e < this.Retrival_data.hospitals.length; e++) {
            this.hospitalId = this.Retrival_data.hospitals[e].hptl_clinic_id;
          }
        }
        Helper_Class.setHospitalInfo(this.Retrival_data.hospitals[0]);
        this.router.navigateByUrl("/menuView");
      }
    } else {
      if (this.obj.key == "1") {
        this.toastr.error('Your account yet to be activated');
      } else if (this.obj.key == "2") {
        this.toastr.error('Verify Password');
      } else if (this.obj.key == "3") {
        this.toastr.error('Check login credentials');
      } else if (this.obj.key == "4") {
        this.toastr.error('Subscription expired, please renew');
        // this.usertype.push(this.obj.login_details[0].type);
        if (this.obj.client == "client") {
          this.packageExpierflag = true;
          this.client_package();
        }
      } else {
        this.toastr.error(this.obj.result);
      }
    }
  }

  getHospitalLocation(hospitalId) {
    this.hobj = this.Retrival_data.hospitals[0];

    if (this.hobj != null) {
      localStorage.setItem("Locatiion", this.hobj.location);
      if (this.hobj.hptl_logo != undefined) {
        Doc_Helper.setHospital_logo(ipaddress.Ip_with_img_address + this.hobj.hptl_logo);
      } else if (this.hobj.logo != undefined) {
        Doc_Helper.setHospital_logo(ipaddress.Ip_with_img_address + this.hobj.logo);
      }
      Doc_Helper.setHospital_name(this.hobj.hptl_name);
      if (this.hobj.hptl_pres_logo != undefined) {
        Doc_Helper.setHospital_pres_logo(ipaddress.Ip_with_img_address + this.hobj.hptl_pres_logo);
      }

      if (this.hobj.footer != undefined) {
        Doc_Helper.setHospFooter(ipaddress.Ip_with_img_address + this.hobj.footer);
      }

      if (this.hobj.bg_image != undefined) {
        Doc_Helper.setHospital_bg_image(ipaddress.Ip_with_img_address + this.hobj.bg_image);
      }

      Doc_Helper.setHospital_print_template(this.hobj.print_template);
      
    }
    Helper_Class.setHospitalInfo(this.hobj);
    if (this.Retrival_data.provider == "doctor") {
      if (this.Retrival_data.pam_accept == "1") {
        this.router.navigateByUrl("/menuView");
       // this.router.navigateByUrl('/Doctor');
      } else {
        const dialogRef = this.dialog.open(TermsConditionComponent, {
          width: '70%',
          height: '500px',

        });
        dialogRef.afterClosed().subscribe(result => {
          if (result["key"] == 1) {
            this.router.navigateByUrl("/menuView");
         //   this.router.navigateByUrl('/Doctor');
          } else {
            this.toastr.error("something went wrong, try later");
          }
        });
      }
    } else if (this.Retrival_data.provider == "consultant") {
      this.router.navigateByUrl("/menuView");
      // this.router.navigateByUrl("/Doctor");
    } else if (this.Retrival_data.provider == "physio") {
      this.router.navigateByUrl("/menuView");
      //this.router.navigateByUrl("/physio");
    } else if (this.Retrival_data.user_type == "diagnosis") {
      this.router.navigateByUrl("/menuView");
      // this.router.navigateByUrl("/Diagnosis");
    } else if (this.Retrival_data.user_type == "Chief pathologist" || this.Retrival_data.user_type == "Pathologist" || this.Retrival_data.user_type == "Chief lab technician" || this.Retrival_data.user_type == "Lab technician") {
      this.router.navigateByUrl("/menuView");
      //this.router.navigateByUrl("/Diagnosis");
    } else if (this.Retrival_data.user_type == "Admin") {
      this.router.navigateByUrl("/menuView");
      //this.router.navigateByUrl("/Admin");
    } else {
      this.router.navigateByUrl('/clientapplist');
    }

    //  })
  }

  getIP() {
    var headers = new HttpHeaders();
    this.http.get(ipaddress.getIp.toString() + 'gen/ip',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.ipaddress = obj.ipaddress;

          Helper_Class.setIPAddress(this.ipaddress);
        });
  }

  isEmailId(input) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(input.trim())) {
      // Invalid Email
      this.isemail = false;
    } else {
      this.isemail = true;
    }
  }

  isNumber(input) {
    var re = /^\d+$/;
    if (!re.test(input.trim())) {
      this.isMobile = false;
      return false;
    } else {
      this.isMobile = true;
      return true;
    }
  }

  client_package() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpkgm',
      ({
        "user": "client"
      }),
      { headers: headers })
      .subscribe(
        response => {


          var obj = JSON.parse(JSON.stringify(response));
          this.set_package(obj);
        },
        error => {
          this.toastr.error("Something Went Wrong, Please Try Again Later");
        }
      )
  }

  set_package(data) {
    for (var i = 0; i < data.package_details.length; i++) {
      var temp_array = [];

      for (var j = 0; j < data.package_details[i].package_modules.length; j++) {
        temp_array.push(data.package_details[i].package_modules[j]);
      }
      var Imagearray = [];
      for (var k = 0; k < data.modules.length; k++) {
        if (temp_array.indexOf(data.modules[k].module_id) > -1) {
          Imagearray.push("../../../assets/img/correct.png");
        }
        else {
          Imagearray.push("../../../assets/img/wrong.png");
        }
      }
      this.Pack_id_list.push(Imagearray);
    }

    this.PackageModels = data.modules;

    this.MainPackage = data.package_details;
  }

  BuyNow_Click(data) { }

  close_expier() {
    this.packageExpierflag = false;
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/gsplbyhptl",
      { hptl_clinic_id: this.obj.hospitals[0].hptl_clinic_id },
      { headers: headers }).subscribe(
        data => {
          var spl_array = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.specializations != null) {
            spl_array = obj.specializations;
            Helper_Class.setSpecializations(undefined);
            Helper_Class.setSpecializations(spl_array);
          }
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }
}
