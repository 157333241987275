import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-nephrologyreadingviewpage',
  templateUrl: './nephrologyreadingviewpage.component.html',
  styleUrls: ['./nephrologyreadingviewpage.component.css']
})
export class NephrologyreadingviewpageComponent implements OnInit {
  isReadonly() { return true; }
  public appID;
  public clientID;
  public relationID;
  public subRelationID;
  public firstName;
  public middleName;
  public lastName;
  public clientEthic;
  public gender;
  public age;
  public weight;
  public caseHistID;
  public serum;
  public serumValue;
  public total;
  public eGFR;
  public serumCreatinine;
  public readProtExrate;
  public readAlbmExrate;
  public getNephroReading;

  constructor(public toastr: ToastrService, public http: Http) { 

  }

  ngOnInit(): void {
    var getnephroreading = Diagnosis_Helper.getNephroreading();
    console.log("NEPHROLOGY READING "+JSON.stringify(getnephroreading))
    this.appID = getnephroreading.DiagNepro_App_id;
    this.clientID = getnephroreading.DiagNepro_Clnt_id;
    this.relationID = getnephroreading.DiagNepro_Clntrel_id;
    this.subRelationID = getnephroreading.DiagNepro_ClntSrel_id;
    this.firstName = getnephroreading.DiagNepro_Clnt_Fname;
    this.middleName = getnephroreading.DiagNepro_Clnt_Mname;
    this.lastName = getnephroreading.DiagNepro_Clnt_Lname;
    this.clientEthic = getnephroreading.DiagNepro_Clnt_Ethic;
    this.getNephrologyDetails();
  }
  getNephrologyDetails() {//Get nephrology details
    if (this.subRelationID == 0 || this.subRelationID == undefined) {
      this.subRelationID = undefined;
    }
    var nephrodetails = JSON.stringify({
      client_reg_id: this.clientID,
      relation_id: this.relationID,
      sub_rel_id: this.subRelationID
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "neph/nepdet/", nephrodetails,
      { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            this.gender = data.json().gender;
            if (data.json().age != null)
              this.age = data.json().age;
            this.weight = data.json().weight_measure == "lbs" ? parseFloat(data.json().weight) / 2.20462 : data.json().weight;
            this.caseHistID = data.json().case_hist_id;
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  
  serumCreatChange(SerumCreat) {//Serium criteria calculation for egrf
    if (SerumCreat != "") {
      if (this.age != undefined && this.age != null) // With age
        this.serum = ((140 - parseFloat(this.age)) * parseFloat(this.weight));
      else // Without age
        this.serum = (140 * parseFloat(this.weight));
      this.serumValue = 72 * parseFloat(SerumCreat);
      if (this.gender == "Male") {
        this.total = this.serum / this.serumValue;
        this.eGFR = this.total.toFixed([2]);
      }
      if (this.gender == "Female") {
        this.total = (this.serum / this.serumValue) * 0.85;
        this.eGFR = this.total.toFixed([2]);
      }
    }
    else {
      this.eGFR = undefined;
    }
  }

  readingSave() {//save readings
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "neph/sdread", JSON.stringify({
      case_hist_id: this.caseHistID,
      country: ipaddress.country_code,
      serum_creatinine: this.serumCreatinine,
      egfr: this.eGFR,
      albumin: this.readAlbmExrate,
      protein: this.readProtExrate,
      app_id: this.appID
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().key == "1")
          this.toastr.success(Message_data.readingsSavedSuccess);
        else
          this.toastr.error(Message_data.readingsNotSavedSuccess);
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }
}
