import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { Observable, ReplaySubject } from 'rxjs';
import { userInfo } from 'os';

@Component({
  selector: 'app-outsource-tests-upload',
  templateUrl: './outsource-tests-upload.component.html',
  styleUrls: ['./outsource-tests-upload.component.scss']
})
export class OutsourceTestsUploadComponent implements OnInit {
  public diag_centre_id: any;
  public outsourceTestsList: any = [];
  pendingData: any;
  public fileType: string;
  public imgStr: any;
  public fileName: string | null = null;
  selectedFile: any;
  imageFileStr: string;
  public pdf;
  public fileData: any;
  private reportname: string;
  public savebutton: boolean = false;
  userInfo: any;
  public diag_app_id;
  public userid;
  
  constructor(public messageservice: MenuViewService, public service: CommonDataService,
    public toastr: ToastrService, public http: Http, public router: Router,
    private cdr: ChangeDetectorRef, private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    
    this.userInfo = Helper_Class.getInfo();
    this.userid = this.userInfo.user_id;
    if (this.userInfo.user_type == "Admin") {
      this.diag_centre_id = Helper_Class.getInfo().diag_center_id;
    } else {
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    this.getOutsourceTests();
  }

  getOutsourceTests() {
    var patientName;
    var report_status;
    this.outsourceTestsList = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gotl",
      JSON.stringify({
        diag_centre_id: this.diag_centre_id,
      }), { headers: headers }).subscribe(
        data => {
          console.log("OutsourceTests", data.json().test_list)
          this.pendingData = data.json().test_list;

          for (var i = 0; i < this.pendingData.length; i++) {
            if (this.pendingData[i].first_name != undefined && this.pendingData[i].last_name != undefined) {
              patientName = encrypt_decript.Decript(this.pendingData[i].first_name) + " " + encrypt_decript.Decript(this.pendingData[i].last_name);
            } else {
              patientName = encrypt_decript.Decript(this.pendingData[i].first_name);
            }

            if (this.pendingData[i].status == "0") {
              report_status = "Completed";
            } else {
              report_status = "Upload";
            }

            var test_array = [];
            if (this.pendingData[i].tests != undefined) {
              for (var j = 0; j < this.pendingData[i].tests.length; j++) {
                var billDate = this.pendingData[i].tests[j].bill_time != undefined ? Date_Formate(this.pendingData[i].tests[j].bill_date) : "";
                var billTime = this.pendingData[i].tests[j].bill_time != undefined ? Time_Formate(this.pendingData[i].tests[j].bill_time) : "";
                var sample_collect_date = this.pendingData[i].tests[j].sample_collect_date != undefined ? Date_Formate(this.pendingData[i].tests[j].sample_collect_date) : "";
                var sample_collect_time = this.pendingData[i].tests[j].sample_collected_time != undefined ? Time_Formate(this.pendingData[i].tests[j].sample_collected_time) : "";
                var assert_date = this.pendingData[i].tests[j].assertion_date != undefined ? Date_Formate(this.pendingData[i].tests[j].assertion_date) : ""
                var assert_time = this.pendingData[i].tests[j].assertion_time != undefined ? Time_Formate(this.pendingData[i].tests[j].assertion_time) : "";
                var visited_date = this.pendingData[i].tests[j].visited_date != undefined ? Date_Formate(this.pendingData[i].tests[j].visited_date) : ""
                var visited_time = this.pendingData[i].tests[j].visited_time != undefined ? Time_Formate(this.pendingData[i].tests[j].visited_time) : "";

                test_array.push({
                  department: this.pendingData[i].tests[j].department,
                  diag_test_id: this.pendingData[i].tests[j].diag_test_id,
                  test_id: this.pendingData[i].tests[j].test_id,
                  test_cat: this.pendingData[i].tests[j].test_cat,
                  test_type_name: this.pendingData[i].tests[j].test_type_name,
                  test_name: this.pendingData[i].tests[j].test_name,
                  status: this.pendingData[i].tests[j].status,
                  bill_date: billDate,
                  bill_time: billTime,
                  sample_collect_d_t: sample_collect_date + ' ' + sample_collect_time,
                  assert_d_t: assert_date + ' ' + assert_time,
                  visited_d_t: visited_date + ' ' + visited_time,
                  template_id: this.pendingData[i].tests[j].template_id,
                  tempate_name: this.pendingData[i].tests[j].tempate_name,
                  template_desc: this.pendingData[i].tests[j].template_desc,
                  outsource_desc: this.pendingData[i].tests[j].outsource_desc,

                })
              }
            }

            this.outsourceTestsList.push({
              s_no: 1 + i,
              diag_appointment_id: this.pendingData[i].diag_appointment_id,
              approved_date: this.pendingData[i].approved_date,
              dmrno: this.pendingData[i].dmrno,
              patient_name: patientName,
              mrno: this.pendingData[i].mrno,
              sample_collected_time: this.pendingData[i].sample_collect_time,
              collected_date: this.pendingData[i].collected_date,
              status: report_status,
              test_cat: this.pendingData[i].test_cat,
              test_id: this.pendingData[i].test_id,
              test_name: this.pendingData[i].test_name,
              test_type_name: this.pendingData[i].test_type_name,
              diag_test_id: this.pendingData[i].diag_test_id,
              client_reg_id: this.pendingData[i].client_reg_id,
              relation_id: this.pendingData[i].relation_id,
              sub_rel_id: this.pendingData[i].sub_rel_id,
              test_no: this.pendingData[i].test_no,
              specimen_type: this.pendingData[i].specimen_type,
              template_id: this.pendingData[i].template_id,
              tempate_name: this.pendingData[i].tempate_name,
              template_desc: this.pendingData[i].template_desc,
              conduct_format: this.pendingData[i].conduct_format,
              tests: test_array,
              age: this.pendingData[i].age,
              gender: encrypt_decript.Decript(this.pendingData[i].gender),
              mobile: encrypt_decript.Decript(this.pendingData[i].mobile),
            })
          }
          console.log("this.pendingLists", this.outsourceTestsList)
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
  }

  //UPLOAD FILE 
  fileChange(data, reportdata, testdata) {
    this.readThis(data.target.files[0]).subscribe(base64 => {
      for (var i = 0; i < this.outsourceTestsList.length; i++) {
        if (this.outsourceTestsList[i].dmrno == reportdata.dmrno) {
          for (var j = 0; j < this.outsourceTestsList[i].tests.length; j++) {
            if (this.outsourceTestsList[i].tests[j].diag_test_id == testdata.diag_test_id) {
              this.outsourceTestsList[i].tests[j].report = base64;
              this.outsourceTestsList[i].tests[j].report_name = this.reportname;
              this.diag_app_id=this.outsourceTestsList[i].diag_appointment_id;
              this.savebutton = true;
            }
          }
        }
      }
      //this.fileData = base64;
      console.log("REPORT DATA " + JSON.stringify(this.outsourceTestsList));
    });
  }

  readThis(file: File): Observable<string> {
    this.reportname = file.name;
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  uploadReport() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/sostf",
      JSON.stringify({
        report_data: this.outsourceTestsList,
        country: ipaddress.country_code,
        approved_by: this.userid,
        diag_app_id:this.diag_app_id
      }), { headers: headers }).subscribe(
        data => {
          console.log("OutsourceTests", data.json().key)
          if (data.json().key == '1') {
            this.toastr.success("Successfully uploaded report");
          } else {
            this.toastr.error("Unable to upload report try again");
          }

        });
  }
}
