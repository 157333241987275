import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate,Time_Formate } from '../../../assets/js/common.js';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';

@Component({
  selector: 'app-pharmacy-completed',
  templateUrl: './pharmacy-completed.component.html',
  styleUrls: ['./pharmacy-completed.component.css']
})
export class PharmacyCompletedComponent implements OnInit {

  public pharmaURL: string;
  public pharmaClientId;
  public pharmaOrdCompleteDetails;
  public pharmaClientdata = [];
  public clientName: string;
  public currentOrderStatus;
  dtOptions: any = {};

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService, public  http:Http,public  router:Router,public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      bFilter: false,
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.pharmaURL = ipaddress.getIp + "pharmacontrol/viewordersbyclnt";
    if (this.gservice.Client_login_detail_data != undefined) {
      this.pharmaClientId = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        curr_flag: false,
        home_care: "0",
      }
    }
    this.viewOrdsByClient();
  }

  orderFlow() {
    this.clientservice.sendMessage("pharmacyprescriptionrelation");
  }

  viewClientDetails(orderid,pharma_id) {
    Client_Helper.setpharmacydetails(null);
    var data_value=null;
    data_value={
      orderid:orderid,
      pharma_id:pharma_id
    }
  Client_Helper.setpharmacydetails(data_value);
    this.clientservice.sendMessage('PharmacyorderComponent');
  }

  back() {
    //this.router.navigateByUrl('/pharmacycurrent')
  }

  viewOrdsByClient() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.pharmaURL, JSON.stringify(this.pharmaClientId), { headers: headers }).subscribe(
      data => {
        this.pharmaOrdCompleteDetails = data.json().order_details;
        if (data.json().order_details.length != 0 ) {
          for (var i = 0; i < data.json().order_details.length; i++) {
            if (data.json().order_details[i].middle_name != null && data.json().order_details[i].middle_name != "") {
              this.clientName = encrypt_decript.Decript(data.json().order_details[i].first_name) + ' ' + encrypt_decript.Decript(data.json().order_details[i].middle_name) + ' ' + encrypt_decript.Decript(data.json().order_details[i].last_name);
            } else {
              this.clientName = encrypt_decript.Decript(data.json().order_details[i].first_name) + ' ' + encrypt_decript.Decript(data.json().order_details[i].last_name);
            }
            if (data.json().order_details[i].is_cancelled == "1" ||
              data.json().order_details[i].is_reject == "1" ||
              data.json().order_details[i].delivered == "1") {
              if (data.json().order_details[i].is_cancelled == "1" &&
                data.json().order_details[i].is_reject != "1" &&
                data.json().order_details[i].delivered != "1") {
                this.currentOrderStatus = "Cancelled";
              } else if (data.json().order_details[i].is_cancelled != "1" &&
                data.json().order_details[i].is_reject == "1" &&
                data.json().order_details[i].delivered != "1") {
                this.currentOrderStatus = "Rejected";
              } else if (data.json().order_details[i].is_cancelled != "1" &&
                data.json().order_details[i].is_reject != "1" &&
                data.json().order_details[i].delivered == "1") {
                this.currentOrderStatus = "Delivered";
              }
            } else if (data.json().order_details[i].is_confirm == "1") {
              this.currentOrderStatus = "Order confirmed";
            } else {
              this.currentOrderStatus = "Waiting for confirmation";
            }
            this.pharmaClientdata.push({
              drug_purchase_id: data.json().order_details[i].drug_purchase_id,
              deliverType: data.json().order_details[i].description,
              clientName: this.clientName,
              orderDate: Date_Formate(data.json().order_details[i].order_date),
              orderTime: Time_Formate(data.json().order_details[i].order_time),
              orderStatus: this.currentOrderStatus,
              pharma:data.json().order_details[i].pharmacy_id,
            })
            localStorage.setItem("pharmacy_id" ,this.pharmaClientdata[i].pharma)
          }
        } 
      },
      error => {
      }
    )
  }
}
