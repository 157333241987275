import { Component, OnInit,HostListener} from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { TreatmentService } from '../service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { DentalService } from '../service/dental_service';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-casesheet-personal',
  templateUrl: './casesheet-personal.component.html',
  styleUrls: ['./casesheet-personal.component.css']
})
export class CasesheetPersonalComponent implements OnInit {
  public userinfo;
  public userlist;
  public personalFlag = 'min';
  public complaints_cardio;
  public past_medical_cardio;
  public present_illvalue_cardio
  public complaints;
  public present_illvalue;
  public Dental_max_flag:boolean=false;
  public concern_headiing:boolean;
  public heading_hidden:boolean;
  public medhist_flag: boolean = false;
  public mainconcernflag: boolean = false;
  public religiousflag: boolean = false;
  public Medical_Check = [];
  public Medical_history_array = [];
  public Main_concern_array = [];
  public Maincheck = [];
  public Religious_belief_array = [];
  public Religious_check = [];
  subscription:Subscription;
  public religious_belief;
  public medhistflag;
  public relbelflag: string;
  public stress_label;
  public stress_hidden: boolean;
  public past_medical;
  public Maincon_txt;
  public sub_rel_id;
  public flag1:any = "minimize";
  public stress_factortxt: string;
  public person_hist;
  public cardio_name: string;
  public cardio_age: any;
  public current_occupation: string;
  public cardio_gender: string;
  public height_txt: any;
  public height_measure: string;
  public weight_txt: any;
  public weight_measure: string;
  public txt_bmi;
  public den_case_hist_id;
  public txt_bmr;
  public exer_run: string;
  public cal_txt: any;
  public body_type: string;
  public body_nature: string;
  public anymed_txt: string;
  public med_txt: string;
  public name_hidden: boolean;
  public age_hidden: boolean;
  public curoccup_hidden: boolean;
  public Gender_hidden: boolean;
  public height_hidden: boolean;
  public weight_hidden: boolean;
  public bmi_hidden: boolean;
  public bmr_hidden: boolean;
  public dietary_hidden: boolean;
  public religious_belielabe:boolean;
  public name_label: string;
  public age_label: string;
  public current_label: string;
  public gender_label: string;
  public height_label: string;
  public height_measurelabel: string;
  public weight_label: string;
  public weight_measurelabel: string;
  public bmi_label: string;
  public bmr_label: string;
  public dietary_label: string;
  public bodyttype_array = [];
  public bodytype_label: string;
  public bnature_label: string;
  public anymed_label: string;
  public mainc: string;
  public Maincon_label: string;
  public Past_medicallabel: string;
  public Religious_label: string;
  public med_label: string;
  public bodytype_hidden: boolean;
  public bnature_hidden: boolean;
  public rweight_hidden: boolean;
  public maincon_hidden: boolean;
  public pastmeical_hidden: boolean;
  public religious_hidden: boolean;
  public anymed_hidden: boolean;
  public med_hidden: boolean;
  public casesheet_case_hist_id: string;
  public life_style_id: string;
  public occupation_txt: string;
  public diet_habits: string;
  public agefield: boolean;
  //dental fields
  public brush_details:boolean;
  public doc_name_array = [];

  public chief_complients = [];
  public chief_complients1 = [];
  public treat_spl_array:any = [];
  public chief_txt_other: boolean;
  public ref_dta: boolean;
  public all_data_hide: boolean;
  public trim_show: boolean;
  public note_show: boolean;
  public brush_freq_disable: boolean;
  public chief_complients_type;
  public Refercasesheet: boolean;
  public assign_doc_reg_id: boolean;
  public spl_text;
  public send_chief_complients = [];
  public chief_flag:boolean=false;
  public updflow: boolean;
  public treat_spl;
  public chief_hidden: boolean;
  public present_hidden: boolean;
  public duration_hidden: boolean;
  public ref_hidden: boolean;
  public past_med_hidden: boolean;
  public med_allergy_hidden: boolean;
  public surgical_hidden: boolean;
  public past_dent_hidden: boolean;
  public tak_drug_hidden: boolean;
  public other_med_hidden: boolean;
  public brush_data_hidden: boolean;
  public brush_material_hidden: boolean;
  public brush_change_hidden: boolean;
  public brush_head_hidden: boolean;
  public present_ill: string;
  public surgical_hist_txt;
  public duration_present_ill;
  public chief_comp_other;
  public drug_history;
  public other_med_hist;
  public med_allergy;
  public past_den_hist;
  public brush_typ;
  public brush_freq;
  public brush_mate;
  public brush_change;
  public ref_text;
  public doc_name;
  public ref_doc_reg_id;
  public ref_spl_id;
  public brush_type_array = [];
  public brush_mate_array = [];
  public concern_head:boolean;

  public cardio_flag:boolean=false;
  public clinical_exam_flag:boolean = false;
  public Dental_flag:boolean=false;
  public Diabetes_flag:boolean=false;
  public tcanymed;
  public C_age;
  public C_gender; public C_image;
  public med_flag: boolean = true;
  public getHopitalfieldsRet;
  public pastmedical:boolean;
  public speclization_arry;
  public personaldata;
  public client:boolean = false;
  public treatmentspl_desc;
  public tcanymedication;
  public user_id;
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };

  constructor(public bmiservice:BmiandbmrService,public dialog:MatDialog,public http: Http, public routes: ActivatedRoute, 
    public router: Router, public toastr: ToastrService, public messageservice:TreatmentService,public messageservice1:CasesheetService,
    public dentalservice:DentalService, public dropdown_dialog: MatDialog,public masterCSdata:MasterCSHelperService) {
    this.brush_data_hidden = false;
    this.stress_hidden = true;
    this.maincon_hidden = true;
    this.pastmeical_hidden = true;
    this.religious_hidden = true;
    this.agefield = true;
    this.pastmedical = true;
    //dental
    this.religious_belielabe=true;
    this.all_data_hide = false;
    this.chief_txt_other = true;
    this.note_show = true;
    this.trim_show = true;
    this.brush_freq_disable = true;
    this.updflow = false;
    this.brush_head_hidden = true;
    this.chief_hidden = true;
    this.present_hidden = true;
    this.duration_hidden = true;
    this.ref_hidden = true;
    this.past_med_hidden = true;
    this.med_allergy_hidden = true;
    this.surgical_hidden = true;
    this.past_dent_hidden = true;
    this.tak_drug_hidden = true;
    this.other_med_hidden = true;
    this.brush_details=false;
    this.ref_dta = true;
    this.stress_factortxt="None";  
    this.curoccup_hidden = true;

    this.curoccup_hidden = false;
    this.name_hidden = false;
    this.age_hidden = false;
    this.Gender_hidden = false;
    this.height_hidden = false;
    this.weight_hidden = false;
    this.bmi_hidden = false;
    this.bmr_hidden = false;
    this.dietary_hidden = false;
    this.bodytype_label = "Body type";
    this.bnature_label = "Body nature";

    this.anymed_label = "Taking any medication";
    this.med_label = "Medicines";
    this.bodytype_hidden = false;
    this.bnature_hidden = false;
    this.rweight_hidden = false;
    this.anymed_hidden = false;
    this.med_hidden = false;
   
    this.height_measure = "cms";
    this.weight_measure = "kgs";

    this.chief_txt_other = true;
    this.note_show = true;
    this.trim_show = true;
    this.brush_freq_disable = true;
    this.chief_hidden = true;
    this.present_hidden = true;
    this.duration_hidden = true;
    this.ref_hidden = true;
    this.past_med_hidden = true;
    this.med_allergy_hidden = true;
    this.surgical_hidden = true;
    this.past_dent_hidden = true;
    this.tak_drug_hidden = true;
    this.other_med_hidden = true;
  }

  async ngOnInit(){
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.personalFlag = message;
      }
    });

    this.subscription = this.bmiservice.getMessage().subscribe(message =>{
      if(message != undefined){
        if(message.flag == "vitals"){
          if(message.bodynatute != undefined){
            this.body_nature = message.bodynatute;
          }
        }
      }
    });

    this.subscription = this.messageservice.getMessage().subscribe(message => {
      var testflag = message;
      this.flag1 = message;   
    });
    
    if(Doc_Helper.getAppFlow() == "client" ){
      this.personaldata = Helper_Class.getInfo();
      this.client = true;
      this.userinfo = Helper_Class.getInfo();

    }else{
      this.personaldata = Doc_Helper.getClient_Info();
      this.client = false;
      this.userinfo = Helper_Class.getInfo();
      this.userlist = Doc_Helper.getClient_Info();
      console.log("check data 000"+JSON.stringify(this.userlist))
      this.life_style_id=this.userlist.life_style_id

    }

    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.user_id = this.personaldata.user_id;

    }else{
      this.user_id = this.userinfo.user_id;
    }

    this.speclization_arry = this.personaldata.spl;
    if(this.speclization_arry == "Dental"){  
      this.get_treat_spl_array();
    }
    
    if(this.speclization_arry == "Cardiology"){
      this.cardio_flag=true;
      this.brush_change_hidden=true;
      this.concern_headiing=true;
      this.brush_material_hidden=true;
      this.heading_hidden=true;
      this.getBodyTypes();

    } else if(this.speclization_arry == "Gastroenterology"){
      this.cardio_flag=true;
      this.brush_change_hidden=true;
      this.concern_headiing=true;
      this.chief_hidden=true;
      this.present_hidden=true;
      this.brush_material_hidden=true;
      this.heading_hidden=true;
      this.anymed_hidden=true;
      this.med_hidden=true;
      this.getBodyTypes();

    } else if(this.speclization_arry == "Dental"){
      this.Dental_flag = true;
      if(masterCSData_Helper.getMasterChiefComplients() == undefined){
        await this.masterCSdata.getChiefComplients();
        this.get_chief_complients();

      }else{
        this.get_chief_complients();
      }

    } else if(this.speclization_arry == "Diabetes"){
      this.religious_belielabe=false;
      this.pastmedical=false;
      this.maincon_hidden=false;
      this.Diabetes_flag = true;
      this.name_hidden=true;
      this.age_hidden=true;
      if(masterCSData_Helper.getMasterMainConcern() == undefined){
        await this.masterCSdata.getMainconcern();
        this.Get_main_concern();
        
      }else{
        this.Get_main_concern();
      }
    }

    this.C_age = this.personaldata.Age_data;
    this.C_gender = this.personaldata.Gender_data;
    this.C_image =this.personaldata.image;
    this.cardio_age =  this.personaldata.Age_data;
    this.cardio_gender = this.personaldata.Gender_data;
    // this.current_occupation = this.personaldata.occupation;
    if(this.personaldata.height != null){
      this.height_txt = this.personaldata.height;
      this.height_measure = this.personaldata.height_measure;
      this.weight_txt = this.personaldata.weight;
      this.weight_measure = this.personaldata.weight_measure;
      this.txt_bmi =this.personaldata.bmi
      this.txt_bmr = this.personaldata.bmr;
      
      //this.txt_bmi = undefined;
      this.calories_data();
    }

    this.getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
    if (this.getHopitalfieldsRet != null) {
      for (var i = 0; i < this.getHopitalfieldsRet.length; i++) {
        if (this.getHopitalfieldsRet[i].page_name == "CardioPersonal") {
          this.brush_change_hidden = true;
          this.concern_headiing = true;
          this.brush_data_hidden = true;
          this.brush_material_hidden = true;
          this.brush_data_hidden = true ;
          this.heading_hidden = true;
          if (this.getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < this.getHopitalfieldsRet[i].fields.length; j++) {
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                  this.current_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.curoccup_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Name") {
                  this.name_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.name_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                  this.age_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.age_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Gender") {
                  this.gender_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.Gender_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                  this.height_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.height_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height measure") {
                  this.height_measurelabel = "Measure";
                  this.height_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                  this.weight_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.weight_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight measure") {
                  this.weight_measurelabel = "Measure";
                  this.weight_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMI") {
                  this.bmi_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bmi_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMR") {
                  this.bmr_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bmr_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Dietary habits") {
                  this.dietary_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.dietary_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body type") {
                  this.bodytype_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bodytype_hidden = false;
                }

                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body nature") {
                  this.bnature_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bnature_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Taking any medication") {
                  this.anymed_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.anymed_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Medicines") {
                  this.med_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.med_hidden = false;
                }
              }
          }
        } else if (this.getHopitalfieldsRet[i].page_name == "History") {//diabetics
          this.religious_belielabe=false;
          this.pastmedical=false;
          this.maincon_hidden=false;
          this.brush_material_hidden=true;
          this.name_hidden = true;
          this.bnature_hidden = true;
          this.bodytype_hidden = true;
          this.med_hidden = true;
          this.anymed_hidden = true;
          this.chief_hidden=true;
          this.present_hidden=true;
          this.duration_hidden=true;
          this.ref_hidden=true;
          this.all_data_hide=true;
          this.concern_headiing=true;
          this.brush_data_hidden=true;
          this.brush_change_hidden=true;

          if (this.getHopitalfieldsRet[i].fields != undefined) {
            for (var j = 0; j < this.getHopitalfieldsRet[i].fields.length; j++) {
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                this.current_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.curoccup_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Name") {
                this.name_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.name_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                this.age_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.age_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Gender") {
                this.gender_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.Gender_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                this.height_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.height_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height measure") {
                this.height_measurelabel = "Measure";
                this.height_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                this.weight_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.weight_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight measure") {
                this.weight_measurelabel = "Measure";
                this.weight_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMI") {
                this.bmi_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.bmi_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMR") {
                this.bmr_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.bmr_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Dietary habits") {
                this.dietary_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.dietary_hidden = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body type") {
                this.bodytype_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.bodytype_hidden = false;
              }

              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body nature") {
                this.bnature_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.bnature_hidden = false;
              }

              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Main concenrns") {
                this.Maincon_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.maincon_hidden = false;
              }

              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Past medical history") {
                this.Past_medicallabel = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.pastmedical = false;
              }

              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Religious or other beliefs") {
                this.Religious_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.religious_belielabe = false;
              }
              if (this.getHopitalfieldsRet[i].fields[j].field_name == "Stress factors") {
                this.stress_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                this.stress_hidden = false;
              }
              if (this.maincon_hidden == false || this.pastmeical_hidden == false || this.religious_hidden == false) {
                // this.concern_head = false;
              }
            }
          }
        } else if (this.getHopitalfieldsRet[i].page_name == "DentalPersonal") {
          this.Dental_max_flag=true;
          this.name_hidden = true;
          this.bnature_hidden = true;
          this.bodytype_hidden = true;
          this.med_hidden = true;
          this.anymed_hidden = true;
          this.pastmedical=true;
          this.age_hidden=true;
          this.curoccup_hidden=true;
          this.Gender_hidden=true;
          this.height_hidden=true;
          this.weight_hidden=true;
          this.dietary_hidden=true;
          this.maincon_hidden=true;
          this.stress_hidden=true;
          this.religious_belielabe=true;
          this.bmi_hidden=true;
          this.bmr_hidden=true;
          if (this.getHopitalfieldsRet[i].fields != undefined) {
            if (this.getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < this.getHopitalfieldsRet[i].fields.length; j++) {
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Chief complaints") {
                  this.chief_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "History of present illness") {
                  this.present_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == " Duration of present illness") {
                  this.duration_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Referral/Assign") {
                  this.ref_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Past medical history") {
                  this.past_med_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Medical allergy") {
                  this.med_allergy_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Surgical history") {
                  this.surgical_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Past dental history") {
                  this.past_dent_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Taking any drugs") {
                  this.tak_drug_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Other medical history") {
                  this.other_med_hidden = false;
                }

                var chk_flag = false;
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Brushing & Flossing ") {
                  this.brush_data_hidden = false;
                  chk_flag = true;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Materials") {
                  this.brush_material_hidden = false;
                  chk_flag = true;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Changes") {
                  this.brush_change_hidden = false;
                  chk_flag = true;
                }
                if (chk_flag == true) {
                  this.brush_head_hidden = false;
                }
              }
            }
          }
        } else if (this.getHopitalfieldsRet[i].page_name == "Gastropersonal") {
          this.brush_change_hidden = true;
          this.concern_headiing = true;
          this.brush_data_hidden = true;
          this.brush_material_hidden = true;
          this.brush_data_hidden = true ;
          this.heading_hidden = true;
          if (this.getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < this.getHopitalfieldsRet[i].fields.length; j++) {
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                  this.current_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.curoccup_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Name") {
                  this.name_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.name_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                  this.age_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.age_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Gender") {
                  this.gender_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.Gender_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                  this.height_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.height_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Height measure") {
                  this.height_measurelabel = "Measure";
                  this.height_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                  this.weight_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.weight_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Weight measure") {
                  this.weight_measurelabel = "Measure";
                  this.weight_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMI") {
                  this.bmi_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bmi_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "BMR") {
                  this.bmr_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bmr_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Dietary habits") {
                  this.dietary_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.dietary_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body type") {
                  this.bodytype_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bodytype_hidden = false;
                }

                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Body nature") {
                  this.bnature_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.bnature_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Taking any medication") {
                  this.anymed_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.anymed_hidden = false;
                }
                if (this.getHopitalfieldsRet[i].fields[j].field_name == "Medicines") {
                  this.med_label = this.getHopitalfieldsRet[i].fields[j].field_name;
                  this.med_hidden = false;
                }
              }
          }
        }
      }
    }
    // this.med_cl();
    if(this.speclization_arry == "Cardiology"){
      this.getcasesheetDetails();
    } 
  }

  getMoreInformation(): string {
    return 'Example: \n  Morning:Two idly with sambar/chutney and a cup of coffee \n After noon:Cup of rice with dal curry and 1/2 cup of vegtables \n Evening:Cup of tea with 3 biscuits \n Night:3 Chapati with vegetables gravy'; // \n represent break line here
  }

  getMoreInformation1(): string {
    return 'Based on Harris-Benedict\n Calculation';
  }

  getMoreInformation2(): string {
    return 'If measure is “cms” then height should be less than “300cms”.\n If measure is “inches” then height should be less than “100 inches”.';
  }

  isReadonly() {
    if(Doc_Helper.getAppFlow() == "client" ){
      return true;
    }else{
      return false;
    }
  }

  med_change() {
    if (this.anymed_txt == "Yes") {
      this.med_flag = false;
    } else {
      this.med_flag = true;
      this.med_txt = "";
    }
  }

  getBodyTypes() {
    this.bodyttype_array = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',
      JSON.stringify({
        gender: this.personaldata.Gender_data,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.bodyttype_array = obj.body_types;
          this.body_type = this.bodyttype_array[0].bodytype_name;
          this.getcasesheetDetails();
        },
        error => {});
  }

  // //Get bmi and bmr and calories data
  calories_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.cardio_gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.cardio_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.cardio_gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.cardio_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        if (this.cardio_gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.cardio_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
         } else if (this.cardio_gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.cardio_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.cardio_gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.cardio_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.cardio_gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.cardio_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.cardio_gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.cardio_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.cardio_gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.cardio_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }
     
      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);
      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }

      if (this.txt_bmi < 18.5) {
        this.body_nature = "Under weight";
      } else if (this.txt_bmi >= 18.5 && this.txt_bmi <= 24.9) {
        this.body_nature = "Normal weight";
      } else if (this.txt_bmi >= 25 && this.txt_bmi <= 29.9) {
        this.body_nature = "Over weight";
      } else {
        this.body_nature = "Obase";
      }
    }
  }

  meddiab_click(selected) {
    this.past_medical = "";
    
    function StringBuilder(this: any,value){
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.Medical_Check = selected;
    }

    if (this.Medical_Check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Medical_Check.length; j++) {
        for (var i = 0; i < this.Medical_history_array.length; i++) {
          if (this.Medical_Check[j] == this.Medical_history_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Medical_history_array[i].label);
          }
        }
      }
      this.past_medical = selected_data.toString();
      
     
    }
  }

  relclick(selected) {
    this.religious_belief = "";
    
    function StringBuilder(this: any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Religious_check = selected;

    if (this.Religious_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Religious_check.length; j++) {
        for (var i = 0; i < this.Religious_belief_array.length; i++) {
          if (this.Religious_check[j] == this.Religious_belief_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Religious_belief_array[i].label);
          }
        }
      }
      this.religious_belief = selected_data.toString();
    }
  }

  Get_Religious_other_beliefs() {
    this.Religious_belief_array = [];

    var obj = masterCSData_Helper.getMasterReligiousOtherBeliefs();
    if(obj != undefined){
      if (obj.religious_beliefs != null) {
        for (var i = 0; i < obj.religious_beliefs.length; i++) {
          this.Religious_belief_array.push({
            type: 'checkbox',
            value: obj.religious_beliefs[i].religious_belief_id,
            label: obj.religious_beliefs[i].description,
            checked: false
          });
        }
        this.getcasesheetDetails();
      }
    }
  }

  //Get main concern data
  async Get_main_concern(){
    this.Main_concern_array = [];
    var obj = masterCSData_Helper.getMasterMainConcern();
    if (obj.main_concerns != undefined) {    
      for (var i = 0; i < obj.main_concerns.length; i++) {
        this.Main_concern_array.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.main_concerns[i].main_concerns_id,
          label: obj.main_concerns[i].description,
          checked: false
        });
      }
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.Get_medical_history();
    }else{
      this.Get_medical_history();
    }
  }

  main_click(selected) {
    this.Maincon_txt = "";
    
    function StringBuilder(this:any , value)   {
       this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Maincheck = selected;
    if (this.Maincheck.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Maincheck.length; j++) {
        for (var i = 0; i < this.Main_concern_array.length; i++) {
          if (this.Maincheck[j] == this.Main_concern_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Main_concern_array[i].label);
          }
        }
      }
      this.Maincon_txt = selected_data.toString();
    }
  }

  //Get past medical history
  async Get_medical_history() {
    this.Medical_history_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if(obj != undefined){
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.Medical_history_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if(obj.med_hist[i].med_hist_id == 32){
            this.past_medical = obj.med_hist[i].description;
          }
        }
      }
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getReligiousOtherBeliefs();
      this.Get_Religious_other_beliefs();
    }else{
      this.Get_Religious_other_beliefs();
    }
  }

  send_data() {
    var den_spl_id=null;
    for (var i = 0; i < this.treat_spl_array.length; i++) {
      if (this.treat_spl == this.treat_spl_array[i].den_spl_id) {
        den_spl_id = this.treat_spl_array[i].den_spl_id;
        Doc_Helper.setDen_spl_id(den_spl_id);
        break;
      }
    }
    var casesheet_personal = null, case_hist_id = null;
    this.medhistflag = this.Medical_Check.length != 0 ? "yes" : "no";
    this.relbelflag = this.Religious_check.length != 0 ? "yes" : "no";
    case_hist_id = this.casesheet_case_hist_id != null ? this.casesheet_case_hist_id : undefined;

    var lif_stl_id = this.life_style_id != null ? this.life_style_id : undefined;
    this.occupation_txt = this.current_occupation != undefined && this.current_occupation != null && this.current_occupation != "" ? encrypt_decript.Encript(this.current_occupation.trim()).toString() : null;

    var age;
    if (age != undefined ) {
      this.cardio_age = this.cardio_age;
    }
    var trimster = undefined, note = undefined, nursing_mother = "no", preg = "no", per_his_flag = "no";
    if (this.med_allergy != undefined || this.past_den_hist != undefined) {
        per_his_flag = "yes";
    }
    var ref_doc_id = undefined, ref_spl_id = undefined, gen_hist = null, med_hist_id = undefined, extra_oral_id = undefined, den_diag_id = undefined, personal_hist_id = undefined, diet_hist_id = undefined, soft_tissue_id = undefined, intra_oral_id = undefined, case_hist_id = undefined;
    var den_ret2 = Helper_Class.Get_den_ret2()
    if (den_ret2 != undefined) {
      if (den_ret2.ref_doc_reg_id != undefined) {
        ref_doc_id = den_ret2.ref_doc_reg_id;
      }

      if (den_ret2.ref_den_spl_id != undefined) {
        ref_spl_id = den_ret2.ref_den_spl_id;
      }
    }

    if (den_ret2!= undefined) {
      if (den_ret2.den_medical_hist_id != undefined) {
        med_hist_id = den_ret2.den_medical_hist_id;
      }
      if (den_ret2.den_extra_oral_id != undefined) {
        extra_oral_id = den_ret2.den_extra_oral_id;
      }
      if (den_ret2.den_diag_inv_id != undefined) {
        den_diag_id = den_ret2.den_diag_inv_id;
      }
      if (den_ret2.den_personal_hist_id != undefined) {
        personal_hist_id = den_ret2.den_personal_hist_id;
      }
      if (den_ret2.den_diet_hist_id != undefined) {
        diet_hist_id = den_ret2.den_diet_hist_id;
      }
      if (den_ret2.den_soft_tissues_id != undefined) {
        soft_tissue_id = den_ret2.den_soft_tissues_id;
      }
      if (den_ret2.den_intra_oral_id != undefined) {
        intra_oral_id = den_ret2.den_intra_oral_id;
      }
      if (den_ret2.den_case_hist_id != undefined) {
        case_hist_id = den_ret2.den_case_hist_id;
      }
    }
    var ReferFlow = undefined;
    if (this.updflow == false && this.Refercasesheet == true) {
      ReferFlow = true;
    }
    var sub_rel =this.personaldata.sub_id;
    
    // var ass = localStorage.getItem("sub_id");
    if(this.Dental_flag == true){
      if (sub_rel != undefined && sub_rel != "") {
        gen_hist = {
          case_hist_id: case_hist_id,
          sub_rel_id: sub_rel,
          ReferFlow: ReferFlow,
          ref_doc_reg_id: ref_doc_id,
          ref_den_spl_id: ref_spl_id,
          den_medical_hist_id: med_hist_id,
          den_extra_oral_id: extra_oral_id,
          den_diag_inv_id: den_diag_id,
          den_personal_hist_id: personal_hist_id,
          den_diet_hist_id: diet_hist_id,
          den_soft_tissues_id: soft_tissue_id,
          den_intra_oral_id: intra_oral_id,
    
          per_his_flag: per_his_flag,
          trimester: trimster,
          preg_notes: note,
          nursing_mother: nursing_mother,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          life_style_id: this.life_style_id,
          present_ill: this.present_ill,
          ill_duration: this.duration_present_ill,
          chief_comp_other: this.chief_comp_other,
          surgical_history: this.surgical_hist_txt,
          drug_history: this.drug_history,
          chief_complaints: this.send_chief_complients,
          den_spl_id: den_spl_id,  
          other_comp: this.other_med_hist,
          medication_allergy: this.med_allergy,
          den_his: this.past_den_hist,
          brushing_type: this.brush_typ,
          brush_frequency: this.brush_freq,
          brush_material: this.brush_mate,
          brush_change: this.brush_change,
          // mastigatory_muscle: $scope.masti_gatetxt,
      
          country: ipaddress.country_code
        }
      } else {
        gen_hist = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          
          ReferFlow: ReferFlow,
          ref_doc_reg_id: ref_doc_id,
          ref_den_spl_id: ref_spl_id,
          den_medical_hist_id: med_hist_id,
          den_extra_oral_id: extra_oral_id,
          den_diag_inv_id: den_diag_id,
          den_personal_hist_id: personal_hist_id,
          den_diet_hist_id: diet_hist_id,
          den_soft_tissues_id: soft_tissue_id,
          den_intra_oral_id: intra_oral_id,
          present_ill: this.present_ill,
          ill_duration: this.duration_present_ill,
          chief_comp_other: this.chief_comp_other,
          surgical_history: this.surgical_hist_txt,
          drug_history: this.drug_history,
          chief_complaints: this.send_chief_complients,
          den_spl_id: den_spl_id,
          //medical_history: this.send_past_med_hist,
          other_comp: this.other_med_hist,
          medication_allergy: this.med_allergy,
          den_his: this.past_den_hist,
          brushing_type: this.brush_typ,
          brush_frequency: this.brush_freq,
          brush_material: this.brush_mate,
          brush_change: this.brush_change,
          //dental    
      }
    }
    
    Doc_Helper.Set_den_per_hist(gen_hist); 
    }else if(this.Diabetes_flag == true){ //diabetes
      var casesheet_personal = null, case_hist_id = null;
      case_hist_id = this.casesheet_case_hist_id != null ? this.casesheet_case_hist_id : undefined;
      console.log("cardio -Diabetes_flag-"+JSON.stringify(this.Diabetes_flag))
      console.log("cardio -PERSONAL-"+JSON.stringify(Helper_Class.getCasesheetPersonal()))
   
    if(sub_rel.length == 0){
    //  Religious_check.
      if(this.Medical_Check.length != 0 && this.Religious_check.length != 0){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,   
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          // bmi: this.txt_bmi,
          // bmr: this.txt_bmr,
          // height: this.height_txt,
          // height_measure: this.height_measure,
          // weight: this.weight_txt,
          // weight_measure: this.weight_measure,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
          medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          rel_belief_flag: this.relbelflag,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
        }  
       
      

      }else if(this.Religious_check.length == 0 && this.Medical_Check.length != 0){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,   
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          // bmi: this.txt_bmi,
          // bmr: this.txt_bmr,
          // height: this.height_txt,
          // height_measure: this.height_measure,
          // weight: this.weight_txt,
          // weight_measure: this.weight_measure,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
        medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
          //rel_belief_flag: this.relbelflag,
        } 

      }else if(this.Religious_check.length !=0  && this.Medical_Check.length == 0){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,   
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          // bmi: this.txt_bmi,
          // bmr: this.txt_bmr,
          // height: this.height_txt,
          // height_measure: this.height_measure,
          // weight: this.weight_txt,
          // weight_measure: this.weight_measure,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
        //medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          rel_belief_flag: this.relbelflag,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
        } 

      } else {
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,    
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          // bmi: this.txt_bmi,
          // bmr: this.txt_bmr,
          // height: this.height_txt,
          // height_measure: this.height_measure,
          // weight: this.weight_txt,
          // weight_measure: this.weight_measure,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
        //  medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
        // rel_belief_flag: this.relbelflag,
        }    
      }
    }else{
      if(this.Medical_Check.length ! =0 && this.Religious_check.length != 0 ){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_rel_id: sub_rel,
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
          medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          rel_belief_flag: this.relbelflag,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
        }
      }else if(this.Religious_check.length == 0 && this.Medical_Check.length != 0){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_rel_id: sub_rel,
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
          medical_history_flag: this.medhistflag,
          rel_belief: this.Religious_check,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,     
        }
      }else if(this.Religious_check.length !=0  && this.Medical_Check.length == 0){
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_rel_id: sub_rel,
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
          rel_belief: this.Religious_check,
          rel_belief_flag: this.relbelflag,
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,
        }
      }else{
        casesheet_personal = {
          case_hist_id: case_hist_id,
          current_occupation: this.occupation_txt,
          life_style_id: lif_stl_id,
          app_id: this.personaldata.app_id,
          doc_reg_id:this.user_id,
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_rel_id: sub_rel,
        
          gender: this.cardio_gender,
          age: this.cardio_age,
          body_nature: this.body_nature,
          body_type: this.body_type,
          medication: this.anymed_txt,
          medicines: this.med_txt,
          diet_habits: this.diet_habits,
          stress_factor: this.stress_factortxt,
          main_concern: this.Maincheck,
          medical_history: this.Medical_Check,
          rel_belief: this.Religious_check,   
          complaints:this.complaints,
          present_illness:this.present_illvalue,
          past_illness:this.past_medical,  
        }
      }
    }
    Helper_Class.setCasesheetPersonal(casesheet_personal);
  }else if(this.cardio_flag == true){   //cardiology
    console.log("cardio --"+this.complaints_cardio)
    console.log("past_medical_cardio --"+this.past_medical_cardio)
    console.log("present_illvalue_cardio --"+this.complaints_cardio)
    Helper_Class.setCasesheetPersonal(null)
    var casesheet_personal = null, case_hist_id = null;
    case_hist_id = this.casesheet_case_hist_id != null ? this.casesheet_case_hist_id : undefined;
 
    if(sub_rel.length == 0){
      casesheet_personal = {
        case_hist_id: case_hist_id,
        current_occupation: this.occupation_txt,
        life_style_id: lif_stl_id,
        app_id: this.personaldata.app_id,
        doc_reg_id:this.user_id,
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id, 
        gender: this.cardio_gender,
        age: this.cardio_age,
        body_nature: this.body_nature,
        body_type: this.body_type,
        medication: this.anymed_txt,
        medicines: this.med_txt,
        diet_habits: this.diet_habits,
        stress_factor: this.stress_factortxt,
        complaints:this.complaints_cardio,
        present_illness:this.present_illvalue_cardio,
        past_illness:this.past_medical_cardio,
      }      
      Helper_Class.setCasesheetPersonal(casesheet_personal);
    }
    else{
      casesheet_personal = {
        case_hist_id: case_hist_id,
        current_occupation: this.occupation_txt,
        life_style_id: lif_stl_id,
        app_id: this.personaldata.app_id,
        doc_reg_id:this.user_id,
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        sub_rel_id: sub_rel,       
        gender: this.cardio_gender,
        age: this.cardio_age,
        body_nature: this.body_nature,
        body_type: this.body_type,
        medication: this.anymed_txt,
        medicines: this.med_txt,
        diet_habits: this.diet_habits,
        stress_factor: this.stress_factortxt,
        complaints:this.complaints_cardio,
        present_illness:this.present_illvalue_cardio,
        past_illness:this.past_medical_cardio,
      }
    }
    Helper_Class.setCasesheetPersonal(casesheet_personal);
    }
    console.log("cardio -PERSONAL-"+JSON.stringify(Helper_Class.getCasesheetPersonal()))
  }

  religious() {
    if (this.client == false) {
      for (var j = 0; j < this.Religious_belief_array.length; j++) {
        this.Religious_belief_array[j].checked = false;
      }

      if (this.Religious_check != undefined && this.Religious_check.length != 0) {
        for (var i = 0; i < this.Religious_check.length; i++) {
          for (var j = 0; j < this.Religious_belief_array.length; j++) {
            if (this.Religious_check[i] == this.Religious_belief_array[j].value) {
              this.Religious_belief_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        data: {
          title: "Religious or other beliefs",
          main_array: this.Religious_belief_array,
          selected_list: this.Religious_check,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.relclick(result);
        }
      });
    }
  }

  getcasesheetDetails() {
    Doc_Helper.setCaseHistId(null);
    Doc_Helper.setLifeStyleId(null);
    console.log("check array &&&&&&&&&&&&"+JSON.stringify(Helper_Class.getRet()))
    if(Helper_Class.getRet() != undefined){
      var obj = Helper_Class.getRet();
      if (obj != null && obj["key"] != 0) {   
        Helper_Class.setCaseHistId(obj.case_hist_id);
        Helper_Class.setLifeStyleId(obj.life_style_id);
  
        this.cardio_name = this.personaldata.client_name != undefined ? this.personaldata.client_name : obj.cardio_name;
        this.cardio_gender = obj.gender != null && obj.gender.length != 0 && obj.gender != undefined &&  obj.gender != "" ? obj.gender : this.personaldata.Gender_data;     
        this.complaints = obj.ascomplaints;
        this.present_illvalue =  obj.present_illness;
        this.past_medical= obj.past_illness;
        this.cardio_name =obj.client_name;
        if(this.Diabetes_flag == true){
          this.cardio_age = obj.age != null ? obj.age : this.personaldata.Age_data;
        }else{
          this.cardio_age = obj.cardio_age != null && obj.cardio_age != undefined && obj.cardio_age != "" ? obj.cardio_age : this.personaldata.Age_data;
        }
  
        //Current occupation
        if (obj.current_occupation != null && obj.current_occupation != "" && obj.current_occupation != undefined) {
          this.current_occupation = encrypt_decript.Decript(obj.current_occupation);
        }
        if (obj.case_hist_id != null) {
          this.casesheet_case_hist_id = obj.case_hist_id;
        }
  
        if (obj.life_style_id != null) {
          this.life_style_id = obj.life_style_id;
        }
        // Main Concerns
  
        //Height
        if (obj.height != null) {
          this.height_txt = obj.height;
          this.height_measure = obj.height_measure;
        }
        this.complaints = obj.complaints;
        this.present_illvalue =  obj.present_illness;
        this.past_medical= obj.past_illness;
       
        this.present_illvalue_cardio = obj.present_illness
        this.past_medical_cardio = obj.past_illness;
        this.complaints_cardio = obj.complaints;
        //Weight
        if (obj.weight != null) {
          this.weight_txt = obj.weight;
          this.weight_measure = obj.weight_measure;
        }
        //Bmi and mnr
        if (obj.bmi != null) {
          this.txt_bmi = obj.bmi;
          this.txt_bmr = obj.bmr;
          this.body_nature = obj.body_nature;
        }
  
        if (obj.body_type != "" || obj.body_type != null || obj.body_type != undefined) {
          this.body_type = obj.body_type;
        }
  
        if (obj.medication != null || obj.medication != undefined) {
          this.anymed_txt = obj.medication;
        }
  
        if (obj.medicines != null || obj.medicines != undefined) {
          this.med_txt = obj.medicines;
        }
  
        if (obj.diet_habits != null) {
          this.diet_habits = obj.diet_habits;
        }
  
        if (obj.stress_factor != null) {
          this.stress_factortxt = obj.stress_factor;
        }
       
        
        if (obj.main_concerns != null && obj.main_concerns != undefined && obj.main_concerns.length != 0) {
          for (var j = 0; j < obj.main_concerns.length; j++) {
            this.Maincheck.push(obj.main_concerns[j].main_concerns_id);
          }
          this.main_click(this.Maincheck)
        }
  
        if (obj.religious_beliefs != null && obj.religious_beliefs != undefined && obj.religious_beliefs.length != 0) {
          for (var j = 0; j < obj.religious_beliefs.length; j++) {
            this.Religious_check.push(obj.religious_beliefs[j].religious_belief_id);
          }
          this.relclick(this.Religious_check)
        }
  
        if (obj.medical_history != null && obj.medical_history != undefined && obj.medical_history.length != 0) {
          for (var j = 0; j < obj.medical_history.length; j++) {
            this.Medical_Check.push(obj.medical_history[j].diab_med_hist_id);
          }
          this.meddiab_click(this.Medical_Check);
        }  
        
      }else {
        if (this.personaldata.client_name != undefined) {
          this.cardio_name = this.personaldata.client_name;
        }
        this.agefield = false;
        if (this.personaldata.Gender_data != undefined || this.personaldata.Gender_data != null || this.personaldata.Gender_data.length != 0 || this.personaldata.Gender_data != "") {
          this.cardio_gender = this.personaldata.Gender_data;
        }
        if (this.personaldata.Age_data != undefined) {
          this.cardio_age = this.personaldata.Age_data ;
        }
        //Current occupation
        if (this.personaldata.current_occupation != undefined && this.personaldata.current_occupation != null && this.personaldata.current_occupation != "") {
          this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
        }
      }
    }else{
      if(this.userlist.ascomplaints != undefined){
        this.complaints = this.userlist.ascomplaints;
      }else{
        this.complaints = this.userlist.complaints;
      }
      if(this.userlist.ascomplaints != undefined){
        this.complaints_cardio = this.userlist.ascomplaints;
      }else{
        this.complaints_cardio = this.userlist.complaints;
      }
      
      this.present_illvalue =  this.userlist.present_illness;
      this.past_medical= this.userlist.past_illness;
     
      this.present_illvalue_cardio = this.userlist.present_illness
      this.past_medical_cardio = this.userlist.past_illness;
      //this.complaints_cardio = this.userlist.ascomplaints;
      this.cardio_name =this.userlist.client_name;
      if(this.userlist.Age_data != undefined){
        this.cardio_age=this.userlist.Age_data;
      }
      if(this.userlist.Age_data != undefined){
        this.cardio_gender=this.userlist.Gender_data;
      }   
      
    }
   
    this.send_data();
    // this.calories_data();
  }

  chief_popup() {
    if(this.client == false){
      for (var j = 0; j < this.chief_complients.length; j++) {
        this.chief_complients[j].checked = false;
      }
  
      if (this.send_chief_complients != undefined && this.send_chief_complients.length != 0) {
        for (var i = 0; i < this.send_chief_complients.length; i++) {
          for (var j = 0; j < this.chief_complients.length; j++) {
            if (this.send_chief_complients[i] == this.chief_complients[j].value) {
              this.chief_complients[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        panelClass: 'drop-down',
        data: {
          title: "Chief complaints",
          main_array: this.chief_complients,
          selected_list: this.send_chief_complients,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.chief_click(result);
        }
      });
    }
  }

  get_chief_complients(){
    var obj = masterCSData_Helper.getMasterChiefComplients();
    if(obj != undefined){
      for (var i = 0; i < obj.chief_comp.length; i++) {
        this.chief_complients.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.chief_comp[i].chief_comp_id,
          label: obj.chief_comp[i].description,
          checked: false
        });
      }
    }
    this.Dental_Retrieval_1();
  }

   //multi selection select event
  chief_click(selectedValue) {
    this.chief_complients_type = "";
    function StringBuilder(this: any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.send_chief_complients = selectedValue;
    var n = 0;
    for (var i = 0; i < this.send_chief_complients.length; i++) {
      for (var j = 0; j < this.chief_complients.length; j++) {
        if (this.send_chief_complients[i] == this.chief_complients[j].value) {
          n = n + 1;
          if (n > 1) {
            selected_data.append(",");
            selected_data.append("\n");
          }
          selected_data.append(this.chief_complients[j].label);
        }
      }

      if (this.send_chief_complients[i] == "28") {
        this.chief_txt_other = false;

      } else {
        this.chief_txt_other = true;
      }

      this.chief_complients_type = selected_data.toString();
    }
  }

  treat_spl_function(treat_spl){
  //treat_spl_function(e,id) {
    for(var i = 0; i < this.treat_spl_array.length; i++){
      if(this.treat_spl_array[i].den_spl_id == treat_spl){
        this.treatmentspl_desc = this.treat_spl_array[i].description;
      }
    }
    var data = null; 
    data = {
      spl:this.treatmentspl_desc,
      spl_id:treat_spl
    }
    console.log("message--"+JSON.stringify(data))
    this.dentalservice.sendMessage(data);
    Doc_Helper.set_dental_spl_name(this.treatmentspl_desc);
  }

  async get_treat_spl_array(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/docdenspl', JSON.stringify({
      doc_reg_id: this.user_id
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("treatment spl"+JSON.stringify(obj))
          var splarray = [];
          if (this.updflow == true) {
            for (var i = 0; i < obj.den_spl.length; i++) {
              if (obj.den_spl[i].description != "Public health") {
                splarray.push({
                  den_spl_id: obj.den_spl[i].den_spl_id,
                  description: obj.den_spl[i].description,
                })
              }
            }
            this.treat_spl_array = splarray;
          } else {
            this.treat_spl_array = obj.den_spl;
          }
          this.treat_spl = this.treat_spl_array[0].description;
        },
        error => {
        }
      )
      if(masterCSData_Helper.getMasterBrushType() == undefined){
        await this.masterCSdata.getBrushType();
        this.get_brush_type_array();
      }else{
        this.get_brush_type_array();
      }
      if(masterCSData_Helper.getMasterBrushMate() == undefined){
        await this.masterCSdata.getBrushMateArray();
        this.get_brush_mate_array();
      }else{
        this.get_brush_mate_array();
      }
  }

  Dental_Retrieval_1() {
    var obj = Helper_Class.Get_den_ret1();
    if (obj != undefined && obj["den_case_hist_id"] != undefined) {
      this.casesheet_case_hist_id = obj["den_case_hist_id"];
    }     

    if (obj != undefined && obj.spl_desc != null) {
      var treat_spl1 = obj.spl_desc;
      for(var i=0; i<this.treat_spl_array.length;i++){
        if(this.treat_spl_array[i].description == treat_spl1){
          this.treat_spl=this.treat_spl_array[i].den_spl_id;
        }
      }
  
      var data = null; 
      data = {
        spl:treat_spl1,
        spl_id:this.treat_spl
      }
      this.dentalservice.sendMessage(data);
      Doc_Helper.set_dental_spl_name(obj.spl_desc)
    }
        
    if (obj != undefined && obj.medication_allergy != null) {
        this.med_allergy = obj.medication_allergy;
    }
    if (obj != undefined && obj.surgical_history != null) {
          this.surgical_hist_txt = obj.surgical_history;
    }
    if (obj != undefined && obj.drug_history != null) {
          this.drug_history = obj.drug_history;
    }
    if (obj != undefined && obj.other_comp != null) {
          this.other_med_hist = obj.other_comp;
    }
    if (obj != undefined && obj.brush_material != null) {
          this.brush_mate = obj.brush_material;
    }

    if (obj != undefined && obj.den_his != null) {
          this.past_den_hist = obj.den_his;
    }

    if (obj != undefined && obj.brush_frequency != null) {
          this.brush_freq = obj.brush_frequency;
      }

    if (obj != undefined && obj.brush_change != null) {
          this.brush_change = obj.brush_change;
    }
    if (obj != undefined && obj.brushing_type != null) {
      this.brush_typ = obj.brushing_type;
    }

    if (obj != undefined && obj.den_his != null) {
      this.past_den_hist = obj.den_his;
    }

    if (obj != undefined && obj.chief_comp_other != null) {
      this.chief_comp_other = obj.chief_comp_other;
    }

    if(obj != undefined && obj.present_ill !=null){
      this.present_ill = obj.present_ill;
    }
    if(obj != undefined && obj.ill_duration !=null){
      this.duration_present_ill = obj.ill_duration;
    }
    if (obj != undefined && (obj.chief_comp != null || obj.chief_comp != undefined || obj.chief_comp != "")) {
      for (var j = 0; j < obj.chief_comp.length; j++) {
        this.send_chief_complients.push(obj.chief_comp[j]);
        this.chief_complients1 = this.send_chief_complients;
      }
      this.chief_click(this.send_chief_complients)
    }
  }

  //hide assign data
  ref_data_chage() {
    if(this.ref_text == "Assign" || this.ref_text == "Referral") {
      this.ref_dta = false;
      this.all_data_hide = true;
      this.brush_details=false;
      var data = undefined;
      this.ref_doc_reg_id = undefined;
      this.ref_spl_id = undefined;
      this.Refercasesheet = true;
      this.brush_data_hidden=true;
      this.brush_change_hidden=true;
      this.concern_headiing=true;
      this.brush_material_hidden=true;
      Doc_Helper.Set_send_dental_basic_data(data);
    }else {
      this.Refercasesheet = false;
      this.ref_dta = true;
      this.all_data_hide = false;
      this.brush_details=true;
      this.assign_doc_reg_id = false;
      this.concern_headiing = false;
      this.brush_data_hidden=false;
      this.brush_change_hidden=false;
      this.brush_material_hidden=false;
      this.doc_name = "";
      this.spl_text = undefined;
      this.doc_name = undefined;
    }
  }

  //brush type
  get_brush_type_array() {
    var obj = masterCSData_Helper.getMasterBrushType();
    if(obj != undefined){
      this.brush_type_array = obj.brush_types;
    }
  }

  //brush material
  get_brush_mate_array() {
    var obj = masterCSData_Helper.getMasterBrushMate();
    if(obj != undefined){
      this.brush_mate_array = obj.brush_materials;  
    }
  }

  brush_change_event() {
    if (this.brush_typ != "1") {
      this.brush_freq_disable = false;
      this.brush_freq = "0";
    } else {
      this.brush_freq_disable = true;
      this.brush_freq = "0";
    }
  }

  spl_search(e) {
    this.doc_name=e.target.value.toString();
    this.doc_name_array = [];
    var img = document.getElementById("refer_first") as HTMLImageElement;
    img.src = "assets/imgs/default.jpg";

    if (this.ref_text == "Assign") {
      if (this.doc_name.length > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'dental/docbyspl/', JSON.stringify({
          hptl_clinic_id: this.personaldata.hptl_clinic_id,
          spl_name: this.spl_text,
          doc_name: this.doc_name,
          doc_reg_id:this.user_id
        }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              
              this.doc_name_array = obj.doc_spl;
              this.assign_doc_reg_id = true;
            },
            error => {}
          )
      }
    } else {
      if (this.doc_name.length > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'dental/docbyspl/', JSON.stringify({
          spl_name: this.spl_text,
          doc_name: this.doc_name,
          doc_reg_id: this.user_id
        }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              
              this.doc_name_array = obj.doc_spl;
              this.assign_doc_reg_id = false;
            },
            error => {}
          )
      }
    }
  }

  doc_name_select(doc_id) {
    for (var i = 0; i < this.doc_name_array.length; i++) {
      if (this.doc_name_array[i].doc_reg_id == doc_id) {
        if(this.doc_name_array[i].middle_name != undefined ){
        this.doc_name = this.doc_name_array[i].first_name + " " + this.doc_name_array[i].middle_name + " " + this.doc_name_array[i].last_name;
        var img = document.getElementById("refer_first") as HTMLImageElement;
        img.src = ipaddress.Ip_with_img_address + this.doc_name_array[i].profile_image;
        this.doc_name_array = [];
        this.ref_doc_reg_id = doc_id;
        //public ref_spl_id;
        }
        else{
          this.doc_name = this.doc_name_array[i].first_name + " " + this.doc_name_array[i].last_name;
          var img = document.getElementById("refer_first") as HTMLImageElement;
          img.src = ipaddress.Ip_with_img_address + this.doc_name_array[i].profile_image;
          this.doc_name_array = [];
          this.ref_doc_reg_id = doc_id;
        }
        for (var j = 0; j < this.treat_spl_array.length; j++) {
          if (this.spl_text == this.treat_spl_array[j].description) {
            this.ref_spl_id = this.treat_spl_array[j].den_spl_id;
          }
        }

        var den_spl_id = null;
        var t_spl_desc=Doc_Helper.get_dental_spl_name();
        for (var i = 0; i < this.treat_spl_array.length; i++) {
          if (t_spl_desc == this.treat_spl_array[i].description) {
            den_spl_id = this.treat_spl_array[i].den_spl_id;
            break;
          }
        }
        var send_data = null;
        if (this.assign_doc_reg_id == true) {
          if (Helper_Class.Get_client_view_app_data()["age"] != "") {
            var age_txt = Helper_Class.Get_client_view_app_data()["age"];
          } else {
            age_txt = null;
          }

          if (Helper_Class.Get_client_view_app_data()!=undefined 
            && Helper_Class.Get_client_view_app_data()["guardian"] != undefined) {
            var gur_txt = Helper_Class.Get_client_view_app_data()["guardian"];
          } else {
            gur_txt = undefined;
          }

          if (Helper_Class.Get_client_view_app_data()!=undefined 
            && Helper_Class.Get_client_view_app_data()["occupation"] != undefined) {
            var occ_txt = Helper_Class.Get_client_view_app_data()["occupation"];
          } else {
            occ_txt = null;
          }

          if (Helper_Class.Get_client_view_app_data()!=undefined 
            && Helper_Class.Get_client_view_app_data()["income"] != undefined) {
            var income_txt = Helper_Class.Get_client_view_app_data()["income"];
          } else {
            income_txt = null;
          }

          var sub_rel =this.personaldata.sub_id;
          if (this.personaldata.sub_id.length != 0) {
            send_data = {
              case_hist_id: this.casesheet_case_hist_id,
              present_ill: this.present_ill,
              ill_duration: this.duration_present_ill,
              chief_comp_other: this.chief_comp_other,
              chief_complaints: this.send_chief_complients,
              den_spl_id: den_spl_id,
              app_id: this.personaldata.app_id,
              doc_reg_id:this.user_id,
              client_reg_id: this.personaldata.Client_id,
              relation_id: this.personaldata.rel_id,
              sub_rel_id: sub_rel,
  
              assn_doc_reg_id: doc_id,
              ref_type: "2",
              app_type: Helper_Class.Get_client_view_app_data()["app_type"],
              first_name: Helper_Class.Get_client_view_app_data()["first_name"],
              middle_name: Helper_Class.Get_client_view_app_data()["middle_name"],
              last_name: Helper_Class.Get_client_view_app_data()["last_name"],
              age: age_txt,
              gender: Helper_Class.Get_client_view_app_data()["gender"],
              mem_mobile: Helper_Class.Get_client_view_app_data()["mobile"],
              hptl_clinic_id: this.personaldata.hptl_clinic_id,
              guardian: gur_txt,
              occupation: occ_txt,
              income: income_txt,
              medicare_id: Helper_Class.Get_client_view_app_data()["medicare_id"],
              specialization_id: Helper_Class.Get_client_view_app_data()["specialization_id"],
              country: ipaddress.country_code
            } 
          } else {
              send_data = {
                case_hist_id: this.casesheet_case_hist_id,
                present_ill: this.present_ill,
                ill_duration: this.duration_present_ill,
                chief_comp_other: this.chief_comp_other,
                chief_complaints: this.send_chief_complients,
                den_spl_id: den_spl_id,
                app_id: this.personaldata.app_id,
                doc_reg_id:this.user_id,
                client_reg_id: this.personaldata.Client_id,
                relation_id: this.personaldata.rel_id,
                assn_doc_reg_id: doc_id,
                ref_type: "2",
                app_type: Helper_Class.Get_client_view_app_data()["app_type"],
                first_name: Helper_Class.Get_client_view_app_data()["first_name"],
                middle_name: Helper_Class.Get_client_view_app_data()["middle_name"],
                last_name: Helper_Class.Get_client_view_app_data()["last_name"],
                age: age_txt,
                gender: Helper_Class.Get_client_view_app_data()["gender"],
                mem_mobile: Helper_Class.Get_client_view_app_data()["mobile"],
                hptl_clinic_id: this.personaldata.hptl_clinic_id,
                guardian: gur_txt,
                occupation: occ_txt,
                income: income_txt,
                medicare_id: Helper_Class.Get_client_view_app_data()["medicare_id"],
                specialization_id: Helper_Class.Get_client_view_app_data()["specialization_id"],
                country: ipaddress.country_code
            }
          }
        } else {
          if (this.personaldata.sub_id.length != 0) {
          // send assign_ref_data
            send_data = {
              case_hist_id: this.casesheet_case_hist_id,
              ref_doc_reg_id: doc_id,
              ref_den_spl_id: this.ref_spl_id,
              present_ill: this.present_ill,
              ill_duration: this.duration_present_ill,
              chief_comp_other: this.chief_comp_other,
              chief_complaints: this.send_chief_complients,
              den_spl_id: den_spl_id,
              app_id: this.personaldata.app_id,
              doc_reg_id:this.user_id,
              client_reg_id: this.personaldata.Client_id,
              relation_id: this.personaldata.rel_id,
              sub_rel_id: sub_rel,
              country: ipaddress.country_code
            }
          }
          else{
            send_data = {
              case_hist_id: this.casesheet_case_hist_id,
              ref_doc_reg_id: doc_id,
              ref_den_spl_id: this.ref_spl_id,
              present_ill: this.present_ill,
              ill_duration: this.duration_present_ill,
              chief_comp_other: this.chief_comp_other,
              chief_complaints: this.send_chief_complients,
              den_spl_id: den_spl_id,
              app_id: this.personaldata.app_id,
              doc_reg_id:this.user_id,
              client_reg_id: this.personaldata.Client_id,
              relation_id: this.personaldata.rel_id,
              country: ipaddress.country_code
            }
          }
        }
        Doc_Helper.Set_send_dental_basic_data(send_data);
        break;
      }
    }
  }

  mainconcernpopup() {
    if (this.client == false) {
      for (var j = 0; j < this.Main_concern_array.length; j++) {
        this.Main_concern_array[j].checked = false;
      }

      if (this.Maincheck != undefined && this.Maincheck.length != 0) {
        for (var i = 0; i < this.Maincheck.length; i++) {
          for (var j = 0; j < this.Main_concern_array.length; j++) {
            if (this.Maincheck[i] == this.Main_concern_array[j].value) {
              this.Main_concern_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        panelClass: 'drop-down',
        data: {
          title: "Concerns",
          main_array: this.Main_concern_array,
          selected_list: this.Maincheck,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.main_click(result);
        }
      });
    }
  }

  medical_popup() {
    if (this.client == false) {
      for (var j = 0; j < this.Medical_history_array.length; j++) {
        this.Medical_history_array[j].checked = false;
      }

      if (this.Medical_Check != undefined && this.Medical_Check.length != 0) {
        for (var i = 0; i < this.Medical_Check.length; i++) {
          for (var j = 0; j < this.Medical_history_array.length; j++) {
            if (this.Medical_Check[i] == this.Medical_history_array[j].value) {
              this.Medical_history_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        data: {
          title: "Past Medical History",
          main_array: this.Medical_history_array,
          selected_list: this.Medical_Check,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.meddiab_click(result);
        }
      });
    }
  }

  history(){
  this.person_hist="concern_hist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
        Diab_case_hist_id: this.casesheet_case_hist_id,
    Life_style_id: this.life_style_id,
    History: this.person_hist,
    }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  changelinemgmt = (event,value) => {
    if(value == "main"){
      this.Maincon_txt = event.html;   
      this.complaints = event.html;   
    }else if(value == "past"){
      this.past_medical= event.html;   
    }else{
      this.present_illvalue=event.html
    }
    
  };

  changelinemgmtcardio = (event,value) => {
    if(value == "main"){
      this.Maincon_txt = event.html;   
      this.complaints_cardio = event.html;   
    }else if(value == "past"){
      this.past_medical_cardio= event.html;   
    }else{
      this.present_illvalue_cardio=event.html
    }
    console.log("cardio --"+this.complaints_cardio)
    console.log("past_medical_cardio --"+this.past_medical_cardio)
    console.log("present_illvalue_cardio --"+this.complaints_cardio)
  };
  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
}
