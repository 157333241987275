import { Component, OnInit,Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import {Helper_Class} from '../../helper_class';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formattedDate, Day_Return, Session_Return, Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-doctor-available-nonavailable-scedule-page',
  templateUrl: './doctor-available-nonavailable-scedule-page.component.html',
  styleUrls: ['./doctor-available-nonavailable-scedule-page.component.css']
})
export class DoctorAvailableNonavailableScedulePageComponent implements OnInit {
  @Input() max: any;

  public curr_date;
  public currentDate;
  public Get_Doctoradminlist;
  public get_arry_list :any = [];
  public doct_name;
  public consul_arry :any = [];
  public non_avail_arry  :any = [];
  public Get_consultation;
  public Get_nonavail;
  public fildatemonth1;
  public avail_pdiv: boolean;
  public avial_show: boolean;
  public nonavailcolor;
  public avail_txt;
  public nonf_date;
  public nonto_date;
  public day_sess;
  public full_nametxt;
  filter_date;
  public userinfo:any;
  public user_id:any;
  dtOptions: DataTables.Settings = {};
  public getipaddress = Helper_Class.getIPAddress();
  public tmppro;
  public hosp_id;
  hptl_clinic_id: any;
  user_ids: any;
  user_type: any;
  is_admin: boolean;
  doctorid: any;
  doctorList: any[];
  editbutton: boolean = false;
  newbutton: boolean = false;
  deletebutton: boolean = false;
  printbutton: boolean = false;
  viewbutton: boolean = false;
  private usertype:string;

  constructor(public  http:Http,public  router:Router, public  toastr:ToastrService) {   
  }

  ngOnInit(): void {  
    this.filter_date = Doc_Helper.getDocAvailabitiyDate();
    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      //"order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0,1,2,3,4] },
      ],
      "language": {
      //  / "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "10"){
          if(Helper_Class.getmodulelist()[i].edit == "1"){
            this.editbutton=true;
          }

          if(Helper_Class.getmodulelist()[i].create == "1"){
            this.newbutton=true;
          }

          if(Helper_Class.getmodulelist()[i].delete == "1"){
            this.deletebutton=true;
          }

          if(Helper_Class.getmodulelist()[i].print == "1"){
            this.printbutton=true;
          }

          if(Helper_Class.getmodulelist()[i].view == "1"){
            this.viewbutton=true;
          }
        }
      }
    }

    this.userinfo = Helper_Class.getInfo();
    this.doct_name = this.userinfo.middle_name != undefined ? this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name : this.userinfo.first_name + " " + this.userinfo.last_name;
    this.get_curr_date();
    
    if (Helper_Class.getInfo().user_type == 'doctor') {
      this.user_id = this.userinfo.user_id;
      this.is_admin=false;
      this.usertype="doctor";
      this.Doctor_availability_search();

    } else {
      this.is_admin=true;
      this.usertype="admin";
      this.changeProviderType();
    }
    this.doctorid ="All";
    // this.Get_doctor_list();
    // this.changeProviderType()
  }  

  changeProviderType() {
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.is_admin = true;
    this.getDoctors();
    

    // if (Helper_Class.getInfo().user_type == 'Admin') {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    //   this.user_ids = Helper_Class.getInfo().user_id;
    //   this.user_type = Helper_Class.getInfo().user_type;
    //   this.is_admin = true;
    //   this.getDoctors();
    // }
  }

  changedoc(){
    this.user_id = this.doctorid;
    this.Doctor_availability_search();
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.user_ids,
      type: this.usertype,
      hptl_clinic_id: this.hptl_clinic_id,
    };
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/docbyhosp', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' +  JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != '' && obj.doctors[i].middle_name != undefined) {
                doctorName =obj.doctors[i].first_name +' ' +
                  obj.doctors[i].middle_name +' ' +obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;

              } else {
                doctorName = obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            // this.doctorid = this.doctorList[0].Doc_ID;
            // this.user_id = this.doctorList[0].Doc_ID;
            this.Doctor_availability_search();
            // this.doctorid = this.userinfo.user_id;
            // this.user_id = this.userinfo.user_id;
            // for (var i = 0; i < this.doctorList.length; i++) {
            //   if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

            //   }
            // }
          }
        },
        (error) => {

        }
      );
  }

  DoctorChange(){
    document.getElementById("doctor")!.style.borderColor = '#2ca8fe';
  }

  //Get current date
  get_curr_date() {
    var obj1 = Master_Helper.getMasterCurrentDate();
    this.curr_date = obj1.current_date;
    this.currentDate = obj1.current_date
  }

  //Get Doctor list
  Get_doctor_list() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyhosp/',
      JSON.stringify({
        doc_reg_id:this.user_id,
        type: "doctor",
        flag: "doctor",
        imei: this.getipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
         
          this.Get_Doctoradminlist = obj.doctors;
          if (this.Get_Doctoradminlist != null && this.Get_Doctoradminlist != undefined) {
            for (var i = 0; i < this.Get_Doctoradminlist.length; i++) {
              if (this.Get_Doctoradminlist[i].middle_name != undefined && this.Get_Doctoradminlist[i].middle_name != "") {
                this.full_nametxt = this.Get_Doctoradminlist[i].first_name + " " + this.Get_Doctoradminlist[i].middle_name + " " + this.Get_Doctoradminlist[i].last_name;
              }
              else {
                this.full_nametxt = this.Get_Doctoradminlist[i].first_name + " " + this.Get_Doctoradminlist[i].last_name;
              }
              if (this.user_id != this.Get_Doctoradminlist[i].prov_id) {
                this.get_arry_list.push({
                  provider_id: this.Get_Doctoradminlist[i].prov_id,
                  name: this.full_nametxt,
                  hptl_clinic_id: this.Get_Doctoradminlist[i].hptl_clinic_id
                });
                }
            }
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  //Doctor availability search list
  Doctor_availability_search() {
      this.consul_arry = [];
      this.non_avail_arry = [];
      // for (var i = 0; i < this.get_arry_list.length; i++) {
      //   if (this.doct_name == this.get_arry_list[i].name) {
      //     this.tmppro = this.get_arry_list[i].provider_id;
      //     this.hosp_id = this.get_arry_list[i].hptl_clinic_id;
      //   }
      // }
      var get_f_date = new Date(this.filter_date.toString());
     
      var send_data=  JSON.stringify({
        doc_reg_id: this.doctorid,
        date: formattedDate(get_f_date),
        hosp_id: this.hptl_clinic_id
      })
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/docbytime/',
      send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            
            if (obj.doc_consultation != null) {
              this.Get_consultation = obj.doc_consultation;
              this.Get_nonavail = obj.non_avail;
              for (var i = 0; i < this.Get_consultation.length; i++) {
                // var fildatemonth = this.filter_date.split("-");
                // this.fildatemonth1 = fildatemonth[1] + "-" + fildatemonth[0] + "-" + fildatemonth[2];
                // var weekdays = new Array(7);
                // weekdays[0] = "Sunday";
                // weekdays[1] = "Monday";
                // weekdays[2] = "Tuesday";
                // weekdays[3] = "Wednesday";
                // weekdays[4] = "Thursday";
                // weekdays[5] = "Friday";
                // weekdays[6] = "Saturday";
                // var current_date = new Date(this.fildatemonth1);
                // var weekday_value = current_date.getDay();
                // var timeString = this.Get_consultation[i].available_from;
                // var H = +timeString.substr(0, 2);
                // var h = (H % 12) || 12;
                // var ampm = H < 12 ? "AM" : "PM";
                // timeString = h + timeString.substr(2, 3) + ampm;
                // var totimeString = this.Get_consultation[i].available_to;
                // var H = +totimeString.substr(0, 2);
                // var h = (H % 12) || 12;
                // var ampm = H < 12 ? "AM" : "PM";
                // totimeString = h + totimeString.substr(2, 3) + ampm;
                // if (weekdays[weekday_value] == Day_Return(this.Get_consultation[i].work_days) || Day_Return(this.Get_consultation[i].work_days) == "All Days") {
                //   this.avail_pdiv = true;
                //   this.avial_show = false;
                //   this.nonavailcolor = "boxavial";
                //   this.avail_txt = Session_Return(this.Get_consultation[i].day_session) + ", " + timeString + " - " + totimeString;
                // }else {
                //   this.avail_pdiv = true;
                //   this.avial_show = false;
                //   this.avail_txt = "Not available";
                //   this.nonavailcolor = "boxnonavial";
                // }
                var docname;
                if(this.Get_consultation[i].middle_name != undefined) {
                  docname=this.Get_consultation[i].first_name+" "+this.Get_consultation[i].middle_name+" "+this.Get_consultation[i].last_name;

                } else {
                  docname=this.Get_consultation[i].first_name+" "+this.Get_consultation[i].last_name;
                }

                this.consul_arry.push({
                  doc_reg_id:this.Get_consultation[i].doc_reg_id,
                  docname: docname,
                  morning:this.Get_consultation[i].morning,
                  afternoon:this.Get_consultation[i].afternoon,
                  evening:this.Get_consultation[i].evening,
                });
              }

              for (var i = 0; i < this.Get_nonavail.length; i++) {
                var daysession;

                var nonfrom = this.Get_nonavail[i].from_date.split("-");
                this.nonf_date = nonfrom[2] + "-" + nonfrom[1] + "-" + nonfrom[0];
                var nonto = this.Get_nonavail[i].to_date.split("-");
                this.nonto_date = nonto[2] + "-" + nonto[1] + "-" + nonto[0];
                if (this.Get_nonavail[i].full_day == "1") {
                  if ((this.filter_date >= this.nonf_date) && (this.filter_date <= this.nonto_date)) {
                    this.avial_show = true;
                  }

                }else {
                  this.avial_show = false;
                }

                if(this.Get_nonavail[i].day_session =="Morning") {
                  var timeval = this.Get_nonavail[i].Morning.split("-");
                  var ftime = timeval[0].split(":");
                  var ttime = timeval[1].split(":");
                  daysession = "not available from "+Time_Formate(ftime[0]+":"+ftime[1]+" to "+Time_Formate(ttime[0]+":"+ttime[1]));

                } else if(this.Get_nonavail[i].day_session =="Afternoon") {
                  var timeval = this.Get_nonavail[i].Afternoon.split("-");
                  var ftime = timeval[0].split(":");
                  var ttime = timeval[1].split(":");
                  daysession = "not available between "+Time_Formate(ftime[0]+":"+ftime[1]+" - "+Time_Formate(ttime[0]+":"+ttime[1]));

                } else if(this.Get_nonavail[i].day_session =="Evening") {
                  var timeval = this.Get_nonavail[i].Evening.split("-");
                  var ftime = timeval[0].split(":");
                  var ttime = timeval[1].split(":");
                  daysession = "not available between "+Time_Formate(ftime[0]+":"+ftime[1]+" - "+Time_Formate(ttime[0]+":"+ttime[1]));
                }
                for(var j=0;j<this.consul_arry.length;j++){
                  if(this.consul_arry[j].doc_reg_id == this.Get_nonavail[i].doc_reg_id) {
                    this.consul_arry[j].non_avail = daysession
                  }
                }
                // if (this.Get_nonavail[i].from_time != undefined) {
                //   var timeString1 = this.Get_nonavail[i].from_time;
                //   var H = +timeString1.substr(0, 2);
                //   var h = (H % 12) || 12;
                //   var ampm = H < 12 ? "AM" : "PM";
                //   timeString1 = h + timeString1.substr(2, 3) + ampm;

                //   var totimeString1 = this.Get_nonavail[i].to_time;
                //   var H = +totimeString1.substr(0, 2);
                //   var h = (H % 12) || 12;
                //   var ampm = H < 12 ? "AM" : "PM";
                //   totimeString1 = h + totimeString1.substr(2, 3) + ampm;
                //   this.day_sess = this.Get_nonavail[i].day_session + ", " + timeString1 + " - " + totimeString1 + " (Not Available)";

                // }else {
                //   this.day_sess = "Not available";
                // }

                this.non_avail_arry.push({
                  non_vail: daysession
                });
              }
              console.log("THE ARRAY VALUE IS "+JSON.stringify(this.consul_arry))
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          });
  }
  
  OnDateChangedfrom(e){
    Doc_Helper.setDocAvailabitiyDate(null);
    this.filter_date = e;
    Doc_Helper.setDocAvailabitiyDate(e);
  }
}
