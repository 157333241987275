import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import {TabsModule } from 'ngx-bootstrap/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { PharmaHomeComponent } from './pharma-home/pharma-home.component';
import { PharmacycurrentinformationComponent } from './pharmacycurrentinformation/pharmacycurrentinformation.component';
import { PharmacypastinformationComponent } from './pharmacypastinformation/pharmacypastinformation.component';
import { OrdercreateComponent } from './ordercreate/ordercreate.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterpageModule } from './filterpage/filterpage.module';
import { OrdercancelModule } from './ordercancel/ordercancel.module';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { ViewmedprespageModule } from './viewmedprespage/viewmedprespage.module';
import { PharmacymoduleOrderdetiledviewComponent } from './pharmacymodule-orderdetiledview/pharmacymodule-orderdetiledview.component';
import { PharmaSalesReportComponent } from './pharma-sales-report/pharma-sales-report.component';
import { PharmaReceivableReportComponent } from './pharma-receivable-report/pharma-receivable-report.component';
import { PharmaStockStatusComponent } from './pharma-stock-status/pharma-stock-status.component';
import { PharmaHelpComponent } from './pharma-help/pharma-help.component';
import { PharmaReferralComponent } from './pharma-referral/pharma-referral.component';
import { OrderConfirmPopupModule } from './order-confirm-popup/order-confirm-popup.module';
import { OverlayModule } from "@angular/cdk/overlay";
import { InpatientWriteRxComponent } from './inpatient-write-rx/inpatient-write-rx.component';
import { SalesReportModule } from '../Admin_module/sales-report-gst/sales-report-gst.module';
import { DoctorModule } from '../Doctor_module/DoctorModule.module';
import { InterdeptTransComponent } from './interdept-trans/interdept-trans.component';
import { InterdeptTransListComponent } from './interdept-trans-list/interdept-trans-list.component';
// import { PharmaSupplierPaymentComponent } from './pharma-supplier-payment/pharma-supplier-payment.component';
// import { PharmaRackbinComponent } from './pharma-rackbin/pharma-rackbin.component';
// import { PharmaRacksBinsComponent } from './pharma-racks-bins/pharma-racks-bins.component'; 
import { InwardComponent } from './inward/inward.component';
import { BillEstimateComponent } from './bill-estimate/bill-estimate.component';


import { InventoryModuleModule } from '../inventory/inventory-module/inventory-module.module';


@NgModule({
  declarations: [
    PharmaHomeComponent,
    PharmacyComponent,
    PharmacycurrentinformationComponent,
    PharmacypastinformationComponent,
    OrdercreateComponent,
   PharmacymoduleOrderdetiledviewComponent,
   OrdercreateComponent,
    PharmaSalesReportComponent,
    PharmaReceivableReportComponent,
    PharmaStockStatusComponent,
  
    PharmaHelpComponent,
    
    PharmaReferralComponent,
    InwardComponent,
    
    InpatientWriteRxComponent,
   
    InterdeptTransComponent,
    InterdeptTransListComponent,
  
    BillEstimateComponent,
   
   
     
 

  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    FilterpageModule,
    OrdercancelModule,
    ViewmedprespageModule,
    OrderConfirmPopupModule,
    OverlayModule,
    TabsModule,
    SalesReportModule,
     InventoryModuleModule,
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  exports:[
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    PharmaHomeComponent,
    PharmacycurrentinformationComponent,
    PharmacypastinformationComponent,
    OrdercreateComponent,
    PharmacymoduleOrderdetiledviewComponent,
    OrdercreateComponent,
    PharmaSalesReportComponent,
    PharmaReceivableReportComponent,
    PharmaStockStatusComponent,
    PharmaHelpComponent,
    PharmaReferralComponent,
    InwardComponent,
    InpatientWriteRxComponent,
    InterdeptTransComponent,
    InterdeptTransListComponent,
   
    BillEstimateComponent,
],
  providers: [DatePipe],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class PharmaModule { }

