<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Personal information</h5>
          </div>
          <div class="headerButtons" *ngIf="!billinguser">
            <div *ngIf="frontdeskuser">
              <a style="margin-right: 5px;" (click)="goToPatientlist()"><img
                src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="update_btn && casesheetbutton || casesheet" (click)="Casesheet()">
              <img src="../../../assets/ui_icons/buttons/case sheet.svg" class="writerximgbtn_inpatinfo" /></a>            
            <!-- <a [hidden]="nurse_flow && !medicalprescription && createpage_flag" style="margin-right: 5px;" (click)="Write_prescription()"><img
                src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="viewrximgbtn_inpatinfo" /></a>
            <a [hidden]="nurse_flow && !viewpres && createpage_flag" style="margin-right: 5px;" (click)="view_prescription()"><img
                src="../../../assets/ui_icons/buttons/view_RX_button.svg" class="viewrximgbtn_inpatinfo" /></a> -->
            <a (click)="dischargeSummary()" *ngIf="!createpage_flag && dichargebutton"><img
                src="../../../assets/ui_icons/buttons/Discharge_icon.svg" class="disimgbtn_inpatinfo"
                [hidden]="update_btn && createpage_flag" /></a>

            <!-- <a style="margin-right: 5px;" (click)="getOtBooking()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Booking.svg" class="saveimgbtn_inpatinfo" /></a> -->

            <!-- <a style="margin-right: 5px;" (click)="diet_tracking()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Tracker.svg" class="saveimgbtn_inpatinfo" /></a> -->
            <!-- <a style="margin-right: 5px;" (click)="view_Medtracker()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Tracker.svg" class="saveimgbtn_inpatinfo" /></a> -->
            <a style="margin-right: 5px;" [hidden]="update_btn && printbutton || nurse_flow" (click)="print_area()"
              *ngIf="!createpage_flag"><img src="../../../assets/img/printer.svg" style="height:25px;" /></a>
            <a style="margin-right: 5px;" (click)="update_admission()" [hidden]="update_btn"><img
                src="../../../assets/ui_icons/buttons/update_button.svg" class="updateimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="Confirm_btn" (click)="create_admission()"><img
                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
            </div>
            <div *ngIf="!frontdeskuser">
              <a style="margin-right: 5px;" (click)="goToPatientlist()"><img
                src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="update_btn && casesheetbutton || casesheet" (click)="Casesheet()">
              <img src="../../../assets/ui_icons/buttons/case sheet.svg" class="writerximgbtn_inpatinfo" /></a>            
            <a [hidden]="nurse_flow && !medicalprescription && createpage_flag" style="margin-right: 5px;" (click)="Write_prescription()"><img
                src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="viewrximgbtn_inpatinfo" /></a>
            <a [hidden]="nurse_flow && !viewpres && createpage_flag" style="margin-right: 5px;" (click)="view_prescription()"><img
                src="../../../assets/ui_icons/buttons/view_RX_button.svg" class="viewrximgbtn_inpatinfo" /></a>
            <a (click)="dischargeSummary()" *ngIf="!createpage_flag && dichargebutton"><img
                src="../../../assets/ui_icons/buttons/Discharge_icon.svg" class="disimgbtn_inpatinfo"
                [hidden]="update_btn && createpage_flag" /></a>

            <a style="margin-right: 5px;" (click)="getOtBooking()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Booking.svg" class="saveimgbtn_inpatinfo" /></a>

            <!-- <a style="margin-right: 5px;" (click)="diet_tracking()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Tracker.svg" class="saveimgbtn_inpatinfo" /></a> -->
            <a style="margin-right: 5px;" (click)="view_Medtracker()" *ngIf="doctorviewflag && !createpage_flag "><img
                src="../../../assets/ui_icons/buttons/Tracker.svg" class="saveimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="update_btn && printbutton || nurse_flow" (click)="print_area()"
              *ngIf="!createpage_flag"><img src="../../../assets/img/printer.svg" style="height:25px;" /></a>
            <a style="margin-right: 5px;" (click)="update_admission()" [hidden]="update_btn"><img
                src="../../../assets/ui_icons/buttons/update_button.svg" class="updateimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="Confirm_btn" (click)="create_admission()"><img
                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
            </div>
            
          </div>
          <div class="headerButtons" *ngIf="billinguser">

            <a style="margin-right: 5px;" (click)="goToPatientlist()"><img
                src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>         
            <a style="margin-right: 5px;" [hidden]="update_btn && printbutton || nurse_flow" (click)="print_area()"
              *ngIf="!createpage_flag"><img src="../../../assets/img/printer.svg" style="height:25px;" /></a>
            <a style="margin-right: 5px;" (click)="update_admission()" [hidden]="update_btn"><img
                src="../../../assets/ui_icons/buttons/update_button.svg" class="updateimgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" [hidden]="Confirm_btn" (click)="create_admission()"><img
                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details" style="margin-top: -10px;">
            <div class="col-12">
              <div class="cover_div">
                <div class="header_lable">Personal Details</div>
                <div class="content_cover">
                  <div class="row" *ngIf="createpage_flag">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="profileimage">

                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                          <mat-label class="matlabel">MR no<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="mr_no"
                              (keyup)="validateMRNNumber()" required />
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                          <mat-label class="matlabel">Salutation<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="clnt_sal"
                              (ngModelChange)="changeSalutation($event)" [readonly]="isReadonly()">
                              <!-- <option value="select" Selected>Select</option> -->
                              <option *ngFor="let sal of clnt_sal_list" value="{{sal.sal_id}}">
                                {{sal.sal_desc}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">First name<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_fname"
                              (blur)="fname_toUpper()" required />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Middle name<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_mname"
                              (blur)="mname_toUpper()" matInput />
                          </mat-label>
                        </div> 
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Last name<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_lname"
                              (blur)="lname_toUpper()" required matInput />
                          </mat-label>
                        </div>
                        <div *ngIf="mobile_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <div class="row">
                            <div class="col-12" [hidden]="update_btn">
                              <mat-label class="matlabel" *ngIf="mobile_type">Mobile no<br>
                                <input type="text" class="ipcss walkin_mobilewidth" [(ngModel)]="clnt_cont_number"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10"
                                  matInput style="width:100%;" />
                              </mat-label>
                            </div>
                            <div class="col-8" [hidden]="!update_btn">
                              <mat-label class="matlabel" *ngIf="mobile_type">Mobile no<br>
                                <input type="text" class="ipcss walkin_mobilewidth" [(ngModel)]="clnt_cont_number"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10"
                                  matInput style="width:100%;" />
                              </mat-label>
                            </div>
                            <div class="col-2 p-0" [hidden]="!update_btn">
                              <mat-label class="matlabel" *ngIf="mobile_type"><br>
                                <a (click)="getUserDetails()"><img src="../../assets/img/search.png" class="seacrhicon"
                                    width="25" height="auto" /></a>
                              </mat-label>
                            </div>
                            <!-- <div class="col-2">
                          <mat-label class="matlabel" *ngIf="mobile_type"><br>
                            <a (click)="datalist()"><img src="../../../assets/ui_icons/Organization.svg"
                                class="seacrhicon" /></a>
                          </mat-label>

                        </div> -->
                          </div>
                        </div>
                        <div *ngIf="!mobile_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel" *ngIf="!mobile_type">Mobile no<br>
                            <input type="text" class="ipcss inpat_width" (blur)="validateMobile()"
                              [(ngModel)]="clnt_cont_number"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" required
                              matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">DOB
                            <input type="date" class="ipcss_date noappt_width" [disabled]="disable_dob"
                              (change)="OnDateChangeDOB(clnt_dob)" [readonly]="isReadonly()" [(ngModel)]="clnt_dob"
                              max="{{currentDate}}" required #matInput>

                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Age
                            <input type="text" class="ipcss noappt_width" [disabled]="disable_age" (keyup)="ChangeAge()"
                              [(ngModel)]="clnt_age" [readonly]="isReadonly()" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel">Blood group<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_blood"
                                  [disabled]="!user_type" matInput [readonly]="isReadonly()" />
                              </mat-label>
                            </div>
                            <div [hidden]="user_type" class="col-6">
                              <mat-label class="matlabel">Gender<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_gender" matInput
                                  [readonly]="isReadonly()" />
                              </mat-label>
                            </div>
                            <div [hidden]="!user_type" class="col-6">
                              <mat-label class="matlabel" [hidden]="!user_type">Gender<br>
                                <select class="ipcss inpat_width" [(ngModel)]="clnt_gender" [readonly]="isReadonly()"
                                  (ngModelChange)="changeGender($event)">
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Transgender">Transgender</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Marital status
                            <mat-select disableOptionCentering class="ipcss" [(ngModel)]="clnt_marital">
                              <mat-option *ngFor="let marstatus of maritalStatusArray" [value]="marstatus">
                                {{marstatus}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Care taker<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_fg_name" maxlength="50"
                              matInput [readonly]="readonlyFlag" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Emergency contact<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="emer_contact"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" matInput
                              [readonly]="readonlyFlag" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Occupation<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_occupation" maxlength="25"
                              matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Id proof type<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="idProofType"
                              (ngModelChange)="changeIdProofType($event)" [compareWith]="compareById"
                              [readonly]="readonlyFlag">
                              <option [ngValue]=null disabled>Select</option>
                              <option *ngFor="let idProof of idProofTypeList" [selected]="idProof === idProofType"
                                [ngValue]="idProof">
                                {{idProof.description}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="idProofNoFlag">
                          <mat-label class="matlabel">{{idProofLabel}} no<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="idProofNo"
                              maxlength={{prooflength}} matInput required />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Income<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_income" maxlength="25"
                              matInput [readonly]="readonlyFlag" />
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Complaints
                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_symptom"
                              (keyup)="getSymptoms()" matInput [readonly]="readonlyFlag" />
                            <div class="auto_complete_text" style="margin-top: 3px !important;">
                              <ul *ngFor="let symptom of symptomlist">
                                <li>
                                  <a (click)="select_symptom(symptom)">{{symptom}}</a>
                                </li>
                              </ul>
                            </div>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                          <mat-label class="matlabel">Referred by<br>
                            <input type="text" class="ipcss inpat_width" maxlength="50" [(ngModel)]="refer_txt"
                              (keyup)="getReferralData($event)" aria-label="Number" matInput [matAutocomplete]="auto2"
                              [readonly]="readonlyFlag" />
                            <mat-autocomplete #auto2="matAutocomplete">
                              <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                                (click)="Select_refer_doctor(item)" value="{{item}}">
                                {{item}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row" *ngIf="!createpage_flag">
                    <div class="col-1" style="text-align: center;">
                      <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="profileimage">
                    </div>
                    <div class="col-11">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">MR no:</span> {{mrno}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Name:</span>
                            {{clnt_saldesc}}.{{patient_name}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Age/Gender:</span> {{clnt_age}}/
                            {{gender}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{mobile_no}}</p>
                        </div>

                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{doctor_name}}
                          </p>
                        </div>
                        <div class="col-3" *ngIf="!adm_datetime_flag">
                          <p class="textformat"><span style="font-weight: 550;">DOA:</span> {{adm_date}}
                            {{admission_time}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Emergency contact:</span>
                            {{emer_contact}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Care taker:</span> {{clnt_fg_name}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Occupation:</span> {{clnt_occupation}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Complaints:</span><span [innerHtml]="clnt_symptom"></span></p>
                        </div>

                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Admission reason:</span>
                            {{admDisReason}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">MLC case:</span> {{mlcCase}}</p>
                        </div>
                        <div class="col-3" *ngIf="mlcCaseNoFlag">
                          <p class="textformat"><span style="font-weight: 550;">MLC case #:</span> {{mlcCaseNo}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Speciality:</span> {{spl}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Mode of payment:</span>
                            {{modeOfPaymentvalue}}</p>
                        </div>
                        <div class="col-3 " [hidden]="insure_flagheader">
                          <p class="textformat"><span style="font-weight: 550;" [hidden]="insure_flag">TPA:</span>
                            {{tpanamevalue}}</p>
                        </div>
                        <div class="col-3 " [hidden]="insure_flagheader">
                          <p class="textformat"><span style="font-weight: 550;" [hidden]="insure_flag">Insurance
                              name:</span> {{insuranceNamevalue}}</p>
                        </div>
                        <div class="col-3 " [hidden]="insure_flagheader">
                          <p class="textformat"><span style="font-weight: 550;" [hidden]="insure_flag">Card
                              number:</span> {{insCardNovalue}}</p>
                        </div>
                        <div class="col-3 " [hidden]="insure_flagheader">
                          <p class="textformat"><span style="font-weight: 550;" [hidden]="insure_flag">Policy
                              number:</span> {{policyNumbervalue}}</p>
                        </div>
                        <div class="col-3 " [hidden]="insure_flagheader">
                          <p class="textformat"><span style="font-weight: 550;" [hidden]="insure_flag">Approval
                              amount:</span> {{approved_amountvalue}}</p>
                        </div>

                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Department:</span> {{dept_desc}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Ward:</span> {{wardName}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Bed:</span> {{bed_novalue}}</p>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">

                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 "  *ngIf="bill_noconsultant"> -->
                          <mat-label class="matlabel" >Consultant-Opinion<br>
                            <mat-select class="ipcss widthappt" [(ngModel)]="consult_doc" multiple>
                              <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                {{doctor.docname}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="cover_div" *ngIf="createpage_flag">
                <div class="header_lable">Admission details</div>
                <div class="content_cover">
                  <div class="row" *ngIf="!front_showinsuranceflag">

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="adm_datetime_flag && createpage_flag">
                      <mat-label class="matlabel">Admission date<br>
                        <input type="date" class="ipcss" (change)="OnDateChangeddate(admission_date)" [(ngModel)]="admission_date"
                          matInput   min="{{currentDate}}" >
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="adm_datetime_flag && createpage_flag">
                      <mat-label class="matlabel">Admission time<br></mat-label>
                      <div class="row">
                        <div class="col-3" style="padding-right:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time1">
                            <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                            </option>
                          </select>
                        </div>
                        <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;"> : </div>
                        <div class="col-3" style="padding-left:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time2">
                            <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                            </option>
                          </select>
                        </div>
                        <div class="col-5">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time3">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">Admission reason
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="admDisReason">
                          <option value="Select" disabled>Select</option>
                          <option value="Admission">Admission</option>
                          <option value="Referred">Referred</option>
                          <option value="Referred">MLC</option>
                          <!-- <option value="Expired">Expired</option> -->
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">MLC case<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="mlcCase"
                          (change)="mlcCaseNoFlag = mlcCase == 'Yes' ? true : false;">
                          <option value="select" disabled>select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="mlcCaseNoFlag && createpage_flag">
                      <mat-label class="matlabel">MLC case no<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="mlcCaseNo" maxlength="25" matInput
                          required />
                      </mat-label>
                    </div>

                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="createpage_flag && !doc_flag">
                      <mat-label class="matlabel">Specialization<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="spl_id"
                          (change)="getDoctorList(spl_id)" required>
                          <option Selected>Select</option>
                          <option *ngFor="let spl of spl_array" value="{{spl.spl_id}}">
                            {{spl.spl_name}}</option>
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="createpage_flag && !doctorflag">
                      <mat-label class="matlabel">Admission Doctor<br>
                        <select class="ipcss widthappt" [(ngModel)]="doc_id" required (change)="changedoctor(doc_id)">
                          <option *ngFor="let doctor of docname_list" value="{{doctor.prov_id}}">{{doctor.docname}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 "  *ngIf="createpage_flag">
                      <mat-label class="matlabel">Consultant-Opinion<br>
                        <mat-select class="ipcss widthappt" [(ngModel)]="consult_doc" multiple>
                          <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                            {{doctor.docname}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                  </div>
                  <div class="row" *ngIf="front_showinsuranceflag">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="adm_datetime_flag && createpage_flag">
                      <mat-label class="matlabel">Admission date<br>
                        <input type="date" class="ipcss_date" id="appt_date" [(ngModel)]="admission_date" #matInput
                          style="">
                        <!-- <input type="date" class="ipcss inpat_width" [(ngModel)]="admission_date" matInput /> -->
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="adm_datetime_flag && createpage_flag">
                      <mat-label class="matlabel">Admission time<br></mat-label>
                      <div class="row">
                        <div class="col-3" style="padding-right:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time1">
                            <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                            </option>
                          </select>
                        </div>
                        <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;"> : </div>
                        <div class="col-3" style="padding-left:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time2">
                            <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                            </option>
                          </select>
                        </div>
                        <div class="col-5">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="admission_time3">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">Admission reason
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="admDisReason">
                          <option value="Select" disabled>Select</option>
                          <option value="Admission">Admission</option>
                          <option value="Referred">Referred</option>
                          <option value="Referred">MLC</option>
                          <!-- <option value="Expired">Expired</option> -->
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">MLC case<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="mlcCase"
                          (change)="mlcCaseNoFlag = mlcCase == 'Yes' ? true : false;">
                          <option value="select" disabled>select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="mlcCaseNoFlag && createpage_flag">
                      <mat-label class="matlabel">MLC case no<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="mlcCaseNo" maxlength="25" matInput
                          required />
                      </mat-label>
                    </div>

                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                      *ngIf="createpage_flag && !doc_flag">
                      <mat-label class="matlabel">Specialization<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="spl_id"
                          (change)="getDoctorList(spl_id)" required>
                          <option Selected>Select</option>
                          <option *ngFor="let spl of spl_array" value="{{spl.spl_id}}">
                            {{spl.spl_name}}</option>
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="createpage_flag && !doctorflag">
                      <mat-label class="matlabel">Admission Doctor<br>
                        <select class="ipcss widthappt" [(ngModel)]="doc_id" required (change)="changedoctor(doc_id)">
                          <option *ngFor="let doctor of docname_list" value="{{doctor.prov_id}}">{{doctor.docname}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="createpage_flag">
                      <mat-label class="matlabel">Consultant-Opinion<br>
                        <mat-select class="ipcss widthappt" [(ngModel)]="consult_doc" multiple>
                          <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                            {{doctor.docname}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">Mode of payment<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="modeOfPayment"
                          (change)="paymentTypeChange(modeOfPayment)">
                          <option value="Select" disabled>Select</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Non-Insurance">Non-Insurance</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="pay_flag">
                      <mat-label class="matlabel">Bill type<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="billPayment" >
                          <option value="Select" disabled>Select</option>
                          <option value="Including pharma">Including pharma</option>
                          <option value="Excluding pharma">Excluding pharma</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">TPA<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="tpa_name" maxlength="50" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">Insurance name<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="insuranceName" maxlength="25" required
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">Card number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="insCardNo" maxlength="25" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">Policy number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="policyNumber" maxlength="25" required
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">CCN number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="ccnNumber" maxlength="25" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">Approval amount<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="approved_amount" maxlength="25"
                          matInput />
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">Department<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="department"
                          [readonly]="readonlyFlag">
                          <option *ngFor="let departments of departmentList" value="{{departments.department_id}}">
                            {{departments.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="createpage_flag">
                      <mat-label class="matlabel">Ward<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                          [readonly]="readonlyFlag" (ngModelChange)="getBedList($event)">
                          <!-- <option Selected>Select</option> -->
                          <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                            {{ward.ward_name}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;"
                      *ngIf="createpage_flag">
                      <mat-label class="matlabel">Bed number<br></mat-label>
                      <div class="btn-group">
                        <input [disabled]="!show_bed_details" type="button"
                          style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                          class="btn-secondary" [(ngModel)]="bed_no" />
                        <button [disabled]="!show_bed_details" type="button"
                          style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                          class="btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
                          data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                          <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                          <li *ngIf="this.bedList.length !=0" style="border: 1px solid #b5afaf;">
                            <div class="container">
                              <div class="row" style=" width: 550px; height: auto;" *ngIf="bedList.length >= 3">
                                <div class="col-3 p-0" *ngFor="let bed of bedList">
                                  <div (click)="changeBed(bed)" style="text-align: center;">
                                    <div class="bed_bed_no">{{bed.bed_no}}</div>
                                    <div><img src="{{bed.bed_img}}" class="bed_img">
                                    </div>
                                    <div class="bed_bed_name d-flex justify-content-center"
                                      [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                      {{bed.pat_name}}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row" style="width: 300px; height: auto;" *ngIf="bedList.length <= 2">
                                <div class="col-6 p-0" *ngFor="let bed of bedList">
                                  <div (click)="changeBed(bed)" style="text-align: center;">
                                    <div class="bed_bed_no">{{bed.bed_no}}</div>
                                    <div><img src="{{bed.bed_img}}" class="bed_img">
                                    </div>
                                    <div class="bed_bed_name d-flex justify-content-center"
                                      [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                      {{bed.pat_name}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="btn-group" *ngIf="createpage_flag">
                        <button [hidden]="!show_bednos" type="button"
                          style="width: 30px !important;border:none !important;" id="dropdownMenuReference"
                          data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                          <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                          <li *ngIf="this.bedOccList.length !=0" style="border: 1px solid #b5afaf;">
                            <div class="container">
                              <div class="row" style=" width: 550px; height: auto;">
                                <table class="table table-hover table-dynamic" id="tbl">
                                  <thead>
                                    <tr>
                                      <th>Ward</th>
                                      <th>Bed no</th>
                                      <th>Allotted on</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let bed of bedOccList">
                                      <td class="test">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                      </td>
                                      <td style="font-size: 12px;">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                      </td>
                                      <td style="font-size: 12px;">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" *ngIf="billflag">
                <div class="header_lable">Admission details</div>
                <div class="content_cover">
                  <div class="row" *ngIf="bill_inp_show_insflag">

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Mode of payment<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="modeOfPayment"
                          (change)="paymentTypeChange(modeOfPayment)">
                          <option value="Select" disabled>Select</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Non-Insurance">Non-Insurance</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="pay_flag">
                      <mat-label class="matlabel">Bill type<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="billPayment" >
                          <option value="Select" disabled>Select</option>
                          <option value="Including pharma">Including pharma</option>
                          <option value="Excluding pharma">Excluding pharma</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">TPA<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="tpa_name" maxlength="50" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">Insurance name<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="insuranceName" maxlength="25" required
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">Card number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="insCardNo" maxlength="25" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">Policy number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="policyNumber" maxlength="25" required
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">CCN number<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="ccnNumber" maxlength="25" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                      <mat-label class="matlabel">Approval amount<br>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="approved_amount" maxlength="25"
                          matInput />
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Department<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="department"
                          [readonly]="readonlyFlag">
                          <option *ngFor="let departments of departmentList" value="{{departments.department_id}}">
                            {{departments.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Ward<br>
                        <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                          [readonly]="readonlyFlag" (ngModelChange)="getBedList($event)">
                          <!-- <option Selected>Select</option> -->
                          <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                            {{ward.ward_name}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;">
                      <mat-label class="matlabel">Bed number<br></mat-label>
                      <div class="btn-group">
                        <input [disabled]="!show_bed_details" type="button"
                          style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                          class="btn-secondary" [(ngModel)]="bed_no" />
                        <button [disabled]="!show_bed_details" type="button"
                          style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                          class="btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
                          data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                          <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                          <li *ngIf="this.bedList.length !=0" style="border: 1px solid #b5afaf;">
                            <div class="container">
                              <div class="row" style=" width: 550px; height: auto;" *ngIf="bedList.length >= 3">
                                <div class="col-3 p-0" *ngFor="let bed of bedList">
                                  <div (click)="changeBed(bed)" style="text-align: center;">
                                    <div class="bed_bed_no">{{bed.bed_no}}</div>
                                    <div><img src="{{bed.bed_img}}" class="bed_img">
                                    </div>
                                    <div class="bed_bed_name d-flex justify-content-center"
                                      [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                      {{bed.pat_name}}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row" style="width: 300px; height: auto;" *ngIf="bedList.length <= 2">
                                <div class="col-6 p-0" *ngFor="let bed of bedList">
                                  <div (click)="changeBed(bed)" style="text-align: center;">
                                    <div class="bed_bed_no">{{bed.bed_no}}</div>
                                    <div><img src="{{bed.bed_img}}" class="bed_img">
                                    </div>
                                    <div class="bed_bed_name d-flex justify-content-center"
                                      [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                      {{bed.pat_name}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="btn-group" *ngIf="createpage_flag">
                        <button [hidden]="!show_bednos" type="button"
                          style="width: 30px !important;border:none !important;" id="dropdownMenuReference"
                          data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                          <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                          <li *ngIf="this.bedOccList.length !=0" style="border: 1px solid #b5afaf;">
                            <div class="container">
                              <div class="row" style=" width: 550px; height: auto;">
                                <table class="table table-hover table-dynamic" id="tbl">
                                  <thead>
                                    <tr>
                                      <th>Ward</th>
                                      <th>Bed no</th>
                                      <th>Allotted on</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let bed of bedOccList">
                                      <td class="test">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                      </td>
                                      <td style="font-size: 12px;">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                      </td>
                                      <td style="font-size: 12px;">
                                        <span
                                          [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!bill_inp_show_insflag">
              <div class="col-12" *ngIf="front_showinsuranceflag">
                <div class="cover_div" *ngIf="!createpage_flag && allocatedbedflag">
                  <div class="header_lable">Admission details</div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="row" *ngIf="allocatedbedflag">

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Department<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="department"
                              [readonly]="readonlyFlag">
                              <option *ngFor="let departments of departmentList" value="{{departments.department_id}}">
                                {{departments.description}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Ward<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                              [readonly]="readonlyFlag" (ngModelChange)="getBedList($event)">
                              <!-- <option Selected>Select</option> -->
                              <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                                {{ward.ward_name}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;"
                          *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Bed number<br></mat-label>
                          <div class="btn-group">
                            <input [disabled]="!show_bed_details" type="button"
                              style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                              class="btn-secondary" [(ngModel)]="bed_no" />
                            <button [disabled]="!show_bed_details" type="button"
                              style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                              class="btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
                              data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                              <span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                              <li *ngIf="this.bedList.length !=0" style="border: 1px solid #b5afaf;">
                                <div class="container">
                                  <div class="row" style=" width: 550px; height: auto;" *ngIf="bedList.length >= 3">
                                    <div class="col-3 p-0" *ngFor="let bed of bedList">
                                      <div (click)="changeBed(bed)" style="text-align: center;">
                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                        </div>
                                        <div class="bed_bed_name d-flex justify-content-center"
                                          [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                          {{bed.pat_name}}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row" style="width: 300px; height: auto;" *ngIf="bedList.length <= 2">
                                    <div class="col-6 p-0" *ngFor="let bed of bedList">
                                      <div (click)="changeBed(bed)" style="text-align: center;">
                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                        </div>
                                        <div class="bed_bed_name d-flex justify-content-center"
                                          [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                          {{bed.pat_name}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="btn-group">
                            <button [hidden]="!show_bednos" type="button"
                              style="width: 30px !important;border:none !important;" id="dropdownMenuReference"
                              data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                              <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                              <li *ngIf="this.bedOccList.length !=0" style="border: 1px solid #b5afaf;">
                                <div class="container">
                                  <div class="row" style=" width: 550px; height: auto;">
                                    <table class="table table-hover table-dynamic" id="tbl">
                                      <thead>
                                        <tr>
                                          <th>Ward</th>
                                          <th>Bed no</th>
                                          <th>Allotted on</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let bed of bedOccList">
                                          <td class="test">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                          </td>
                                          <td style="font-size: 12px;">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                          </td>
                                          <td style="font-size: 12px;">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!front_showinsuranceflag">
                <div class="cover_div" *ngIf="!createpage_flag && allocatedbedflag">
                  <div class="header_lable">Admission details</div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="row" *ngIf="allocatedbedflag">

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Department<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="department"
                              [readonly]="readonlyFlag">
                              <option *ngFor="let departments of departmentList" value="{{departments.department_id}}">
                                {{departments.description}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Ward<br>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                              [readonly]="readonlyFlag" (ngModelChange)="getBedList($event)">
                              <!-- <option Selected>Select</option> -->
                              <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                                {{ward.ward_name}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;"
                          *ngIf="allocatedbedflag">
                          <mat-label class="matlabel">Bed number<br></mat-label>
                          <div class="btn-group">
                            <input [disabled]="!show_bed_details" type="button"
                              style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                              class="btn-secondary" [(ngModel)]="bed_no" />
                            <button [disabled]="!show_bed_details" type="button"
                              style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                              class="btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
                              data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                              <span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                              <li *ngIf="this.bedList.length !=0" style="border: 1px solid #b5afaf;">
                                <div class="container">
                                  <div class="row" style=" width: 550px; height: auto;" *ngIf="bedList.length >= 3">
                                    <div class="col-3 p-0" *ngFor="let bed of bedList">
                                      <div (click)="changeBed(bed)" style="text-align: center;">
                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                        </div>
                                        <div class="bed_bed_name d-flex justify-content-center"
                                          [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                          {{bed.pat_name}}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row" style="width: 300px; height: auto;" *ngIf="bedList.length <= 2">
                                    <div class="col-6 p-0" *ngFor="let bed of bedList">
                                      <div (click)="changeBed(bed)" style="text-align: center;">
                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                        </div>
                                        <div class="bed_bed_name d-flex justify-content-center"
                                          [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                          {{bed.pat_name}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="btn-group">
                            <button [hidden]="!show_bednos" type="button"
                              style="width: 30px !important;border:none !important;" id="dropdownMenuReference"
                              data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                              <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference" style="padding: 0;">
                              <li *ngIf="this.bedOccList.length !=0" style="border: 1px solid #b5afaf;">
                                <div class="container">
                                  <div class="row" style=" width: 550px; height: auto;">
                                    <table class="table table-hover table-dynamic" id="tbl">
                                      <thead>
                                        <tr>
                                          <th>Ward</th>
                                          <th>Bed no</th>
                                          <th>Allotted on</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let bed of bedOccList">
                                          <td class="test">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                          </td>
                                          <td style="font-size: 12px;">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                          </td>
                                          <td style="font-size: 12px;">
                                            <span
                                              [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12" *ngIf="front_inp_show_vitalsflag">
              <div class="cover_div">
                <div class="header_lable">Surgery details</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <mat-label class="matlabel">Required surgery<br>
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="req_surgery"
                          (change)="surgeryRequired()">
                          <option value="select" disabled>select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="req_sur_flag">
                      <mat-label class="matlabel">Surgery
                        <input type="text" class="ipcss noappt_width" 
                           [(ngModel)]="surgery_name" (keyup)="surgeryChange(surgery_name)" (focus)="clearFields()" matInput
                           [matAutocomplete]="sauto" required />
                        <mat-autocomplete #sauto="matAutocomplete" >
                           <mat-option (click)="surgerySelect(surgery)"
                              *ngFor="let surgery of surgery_list" value="{{surgery.surgery_name}}">
                              {{surgery.surgery_name}}
                           </mat-option>
                        </mat-autocomplete>
                     </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 " [hidden]="req_sur_flag">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                          <mat-label class="matlabel">Surgery date<br>
                            <input type="date" class="ipcss inpat_width" [(ngModel)]="surgery_date"
                              min="{{currentDate}}" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8" [hidden]="req_sur_flag">
                          <mat-label class="matlabel">Surgery time<br></mat-label>
                          <div class="row">
                            <div class="col-3" style="padding-right:0;">
                              <select type="date" class="ipcss inpat_width" [(ngModel)]="surgery_time1">
                                <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                                </option>
                              </select>
                            </div>
                            <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                              : </div>
                            <div class="col-3" style="padding-left:0;">
                              <select type="date" class="ipcss inpat_width" [(ngModel)]="surgery_time2">
                                <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                                </option>
                              </select>
                            </div>
                            <div class="col-4">
                              <select type="date" class="ipcss inpat_width" [(ngModel)]="surgery_time3">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="req_sur_flag">
                      <mat-label class="matlabel">Surgeon<br>
                        <mat-select class="ipcss widthappt" [(ngModel)]="surg_doc" multiple>
                          <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                            {{doctor.docname}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-9 " [hidden]="req_sur_flag">
                      <mat-label class="matlabel">Surgery procedure
                        <textarea class="ipcss inpat_width1" [(ngModel)]="surgery_proc" matInput></textarea>
                        
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="front_inp_show_vitalsflag">
              <div class="cover_div" [hidden]="req_sur_flag">
                <div class="header_lable">Anesthesia</div>
                <div class="content_cover">
                  <div class="row">
                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Anaestetist
                        <mat-select class="ipcss widthappt" [(ngModel)]="anaest_doc" multiple>
                          <mat-option *ngFor="let doctor of anaest_doctors" value="{{doctor.doc_reg_id}}">
                            {{doctor.name}}</mat-option>
                        </mat-select>
                        <!-- <select class="ipcss inpat_width" [(ngModel)]="anaest_doc">
                                                <option *ngFor="let doctor of anaest_doctors"
                                                    value="{{doctor.doc_reg_id}}">
                                                    {{doctor.name}}</option>
                                            </select> -->
                      </mat-label>
                    </div>
                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Anaestesia type
                        <select class="ipcss inpat_width" [(ngModel)]="anaesttype">
                          <option *ngFor="let type of anaest_types" value="{{type.type_id}}">
                            {{type.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                      <mat-label class="matlabel">Procedure
                        <textarea class="ipcss inpat_width1" [(ngModel)]="anaest_procedure" matInput></textarea>
                      </mat-label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                      <mat-label class="matlabel">Other findings
                        <textarea class="ipcss inpat_width1" [(ngModel)]="anaest_findings" matInput></textarea>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Side effects<br>
                        <select class="ipcss inpat_width" [(ngModel)]="anaest_effect">
                          <option *ngFor="let effect of anaest_effects" value="{{effect.side_effect_id}}">
                            {{effect.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="front_inp_show_vitalsflag">
              <div class="cover_div">
                <div class="header_lable">Vitals</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-3">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">Height<br>
                            <input matInput type="text" class="ipcss inpat_width"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              (change)="calories_data()" maxlength="3" [(ngModel)]="height_txt">
                          </mat-label>
                        </div>
                        <div class="col-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss inpat_width" (change)="calories_data()" [(ngModel)]="hmeasure"
                              disableOptionCentering>
                              <option Selected value="cms">cms</option>
                              <option value="inch">inch</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">Weight<br>
                            <input type="text" matInput class="ipcss inpat_width" (change)="calories_data()"
                              maxlength="3" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              [(ngModel)]="weight_txt">
                          </mat-label>
                        </div>
                        <div class="col-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss inpat_width" (change)="calories_data()" [(ngModel)]="wmeasure"
                              disableOptionCentering>
                              <option Selected value="kgs">kgs</option>
                              <option value="lbs">lbs</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">BMI<br>
                            <input matInput class="ipcss inpat_width" [(ngModel)]="bmi_txt" disabled>
                          </mat-label>
                        </div>
                        <div class="col-6">
                          <mat-label class="matlabel">Temperature<br>
                            <input class="ipcss inpat_width" [ngStyle]="{'color': colorstyle ? 'red' : 'black'}"
                              [(ngModel)]="temp_txt" maxlength="5" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                             
                              (input)="tempcolor(temp_txt)">
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Blood pressure</mat-label>
                      <div class="row">
                        <div class="col-6">
                          <input class="ipcss inpat_width" [disabled]="blood_disabled" placeholder="mm Hg" maxlength="3"
                            (input)="sistol(BP_01)" [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                            [(ngModel)]="BP_01" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        </div>
                        <div class="col-6" style="position: relative;">
                          <span class="slash_icon">/</span>
                          <input class="ipcss inpat_width" [disabled]="blood_disabled" placeholder="mm Hg" maxlength="3"
                            [(ngModel)]="BP_02" [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                            (input)="diostol(BP_02)" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="row">

                        <div class="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-3">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel">SPO2</mat-label>
                              <input matInput class="ipcss inpat_width" [(ngModel)]="spo_txt" (input)="spo2(spo_txt)"
                                [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                            </div>
                            <div class="col-6">
                              <mat-label class="matlabel">Pulse</mat-label>
                              <input matInput class="ipcss inpat_width" [(ngModel)]="pulse_txt">
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel">CVS<br>
                                <input matInput class="ipcss " placeholder="cvs" maxlength="25" [(ngModel)]="cvs_txt">
                              </mat-label>
                            </div>
                            <div class="col-6">
                              <mat-label class="matlabel">RS<br>
                                <input matInput class="ipcss " placeholder="rs" maxlength="25" [(ngModel)]="rstxt">
                              </mat-label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel">CNS<br>
                                <input matInput class="ipcss " placeholder="cns" maxlength="25" [(ngModel)]="cns_txt">
                              </mat-label>
                            </div>
                            <div class="col-6">
                              <mat-label class="matlabel">Abdomen<br>
                                <input matInput class="ipcss " placeh older="ABD" maxlength="25" [(ngModel)]="abd_txt">
                              </mat-label>
                            </div>
                          </div>
                        </div>

                        <div class="col-2">
                          <mat-label class="matlabel">&nbsp;<br>
                            <img src="../../../assets/ui_icons/buttons/add_button.svg" class="add_button"
                              (click)="addVitals()" />
                          </mat-label>
                        </div>

                      </div>
                    </div>


                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                      *ngIf="vitals_List.length != 0">
                      <table>
                        <thead>
                          <tr>
                            <th style="text-align: left;">S.No</th>
                            <th style="text-align: left;">Height</th>
                            <th style="text-align: left;">Weight</th>
                            <th style="text-align: left;">BMI</th>
                            <th style="text-align: left;">Temp</th>
                            <th style="text-align: left;">BP</th>
                            <th style="text-align: left;">SPO2</th>
                            <th style="text-align: left;">Pulse</th>
                            <th style="text-align: left;">CVS</th>
                            <th style="text-align: left;">RS</th>
                            <th style="text-align: left;">CNS</th>
                            <th style="text-align: left;">ABD</th>
                            <th style="text-align: left;">Date</th>
                            <!-- <th style="text-align: left;">Time</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vital of vitals_List">
                            <td style="text-align: left;">{{vital.sNo}}</td>
                            <td style="text-align: left;">{{vital.height}}</td>
                            <td style="text-align: left;">{{vital.weight}}</td>
                            <td style="text-align: left;">{{vital.bmi}}</td>
                            <td style="text-align: left;">
                              <span [style.color]="vital.temperature > 100 ? 'red' : 'black'">{{vital.temperature}}</span>
                             </td>
                             <td style="text-align: left;">
                              <span [style.color]="
                                vital.bloodPressurekey 
                                && (vital.bloodPressurekey.split('/')[0] > 160 
                                || vital.bloodPressurekey.split('/')[0] < 100 
                                || vital.bloodPressurekey.split('/')[1] > 100 
                                || vital.bloodPressurekey.split('/')[1] < 60) 
                                  ? 'red' 
                                  : 'black'
                              ">
                                {{vital.bloodPressurekey}}
                              </span>
                            </td>
                            <td style="text-align: left;">
                              <span [style.color]="vital.SPO2 < 75 ? 'red' : 'black'">{{vital.SPO2}}</span>
                            </td>
                            <td style="text-align: left;">
                              {{vital.pulse}}</td>
                            <td style="text-align: left;">{{vital.Cvs}}</td>
                            <td style="text-align: left;">{{vital.Rs}}</td>
                            <td style="text-align: left;">{{vital.Cns}}</td>
                            <td style="text-align: left;">{{vital.ABD}}</td>
                            <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                            <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                          </tr>
                        </tbody>
                      </table>
                      
                      
                      <div>
                        <div class="row" *ngIf="vitals_List.length != 0">
                          <div class="col-6" *ngIf="spo2graphflag">
                            <canvas id="spo2graph" width="361" height="200"
                            style="display: block;"></canvas>          
                          </div>
                          <div class="col-6" *ngIf="pulseflag">
                            <canvas id="pulsegrpah" width="361" height="200"
                            style="display: block;"></canvas>          
                          </div>
                          <div class="col-6" *ngIf="bpgraphflag">
                            <canvas id="bpgraph" width="361" height="200"
                            style="display: block;"></canvas>          
                          </div>
        
                        </div>
                      </div>
                    </div>
         
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="front_inp_show_vitalsflag">
              <div class="cover_div">
                <div class="header_lable">
                  Observation
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel"><b class="label_bold">Investigation</b></mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="inp_inv" [maxLength]="10"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                            placeholder="Enter investigation" (onContentChanged)="changeInvest($event)">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="viewhtml1" [content]="inp_inv" [maxLength]="10">
                            </quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Chief complaints</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="chief_comp" [styles]="{height: 'calc(35vh - 100px)'}"
                            [modules]="modules" placeholder="Enter Text" (onContentChanged)="changeChiefComp($event)">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="chiefcomp1" [content]="chief_comp"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Present illness</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}"
                            [modules]="modules" placeholder="Enter Text" (onContentChanged)="changePresIllness($event)">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="presill1" [content]="pres_ill"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Past medical history</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="med_hist" [styles]="{height: 'calc(35vh - 100px)'}"
                            [modules]="modules" placeholder="Enter past medical history"
                            (onContentChanged)="changeMedicalHistory($event)"></quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="medhist1" [content]="med_hist"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Family history</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="family_hist" [styles]="{height: 'calc(35vh - 100px)'}"
                            [modules]="modules" placeholder="Enter family history"
                            (onContentChanged)="changeFamilyHistory($event)"></quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="famhist1" [content]="family_hist"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Physical examination</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="physical_exam"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                            placeholder="Enter physical examination" (onContentChanged)="changePhysicalExam($event)">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="medhist1" [content]="physical_exam"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <mat-label class="matlabel">Pre-hospitalization</mat-label>
                          <select class="ipcss" [(ngModel)]="pre_hosp" (change)="preHosp_change(pre_hosp)">
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8" *ngIf="pre_hospflag">
                          <mat-label class="matlabel">Hospital details
                            <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="pre_hospital_details"
                              matInput></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Smoking
                        <select class="ipcss inpat_width" [(ngModel)]="inp_smoke">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Drinking alcohol
                        <select class="ipcss inpat_width" [(ngModel)]="inp_alcohol">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                      <div class="row">
                        <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 ">
                          <mat-label class="matlabel">Taking any medication
                            <select class="ipcss inpat_width" [(ngModel)]="medication" (change)="medicationChange()">
                              <option value="Select" disabled>Select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-8 col-xl-8" [hidden]="medication_flag">
                          <mat-label class="matlabel">Medication details<br>
                            <input type="text" class="ipcss inpat_width" [(ngModel)]="med_details"
                              [required]="med_requed" maxlength="150" matInput />
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12" style="margin-top: 5px !important;" style="margin-top: 10px;">
                      <div class="row">
                        <div class="col-2">
                          <mat-label class="matlabel" style="font-weight: 600;">Allergies :
                          </mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field" *ngIf="ynopt2 != undefined">
                            <input type="radio" id="radio-one" (click)="changeAllery('main',1,'yes')" />
                            <label for="radio-one" [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-two" (click)="changeAllery('main',1,'no')" />
                            <label for="radio-two" [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" style="margin-top: 5px !important;">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                          *ngIf="allergyFlag">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel" style="font-weight: 600;">Drug
                                allergy : </mat-label>
                            </div>
                            <div class="col-6">
                              <div class="switch-field" *ngIf="ynopt3 != undefined">
                                <input type="radio" id="radio-five" (click)="changeAllery('drug',1,'yes')" />
                                <label for="radio-five" [ngClass]="ynopt3 ? 'radio_active':'radio_inactive'">Yes</label>
                                <input type="radio" id="radio-six" (click)="changeAllery('drug',1,'no')" />
                                <label for="radio-six" [ngClass]="!ynopt3 ? 'radio_active':'radio_inactive'">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                          *ngIf="drugDetailsFlag">
                          <mat-label class="matlabel">Details
                            <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="drug_details"
                              matInput></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                          *ngIf="allergyFlag">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel" style="font-weight: 600;">
                                Environmental allergy : </mat-label>
                            </div>
                            <div class="col-6">
                              <div class="switch-field" *ngIf="ynopt4 != undefined">
                                <input type="radio" id="radio-seven" (click)="changeAllery('evn',1,'yes')" />
                                <label for="radio-seven"
                                  [ngClass]="ynopt4 ? 'radio_active':'radio_inactive'">Yes</label>
                                <input type="radio" id="radio-eight" (click)="changeAllery('evn',1,'no')" />
                                <label for="radio-eight"
                                  [ngClass]="!ynopt4 ? 'radio_active':'radio_inactive'">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                          *ngIf="engDetailsFlag && allergyFlag">
                          <mat-label class="matlabel">Details
                            <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="env_details"
                              matInput></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                          *ngIf="allergyFlag">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel" style="font-weight: 600;">Food
                                allergy : </mat-label>
                            </div>
                            <div class="col-6">
                              <div class="switch-field" *ngIf="ynopt5 != undefined">
                                <input type="radio" id="radio-nine" (click)="changeAllery('food',1,'yes')" />
                                <label for="radio-nine" [ngClass]="ynopt5 ? 'radio_active':'radio_inactive'">Yes</label>
                                <input type="radio" id="radio-ten" (click)="changeAllery('food',1,'no')" />
                                <label for="radio-ten" [ngClass]="!ynopt5 ? 'radio_active':'radio_inactive'">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                          *ngIf="foodDetailsFlag && allergyFlag">
                          <mat-label class="matlabel">Details
                            <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="food_details"
                              matInput></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="!update_btn && front_inp_show_vitalsflag">
              <div class="cover_div">
                <div class="header_lable">Discharge details</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Discharge date<br>
                        <input type="date" class="ipcss inpat_width" [(ngModel)]="discharge_date" 
                          matInput (change)="dischargedetials()" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Discharge time<br></mat-label>
                      <div class="row">
                        <div class="col-3" style="padding-right:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="discharge_time1">
                            <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                            </option>
                          </select>
                        </div>
                        <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                          : </div>
                        <div class="col-3" style="padding-left:0;">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="discharge_time2">
                            <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                            </option>
                          </select>
                        </div>
                        <div class="col-5">
                          <select type="date" class="ipcss inpat_width" [(ngModel)]="discharge_time3">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Discharge reason
                        <select type="date" class="ipcss inpat_width" [(ngModel)]="disReason">
                          <option value="Select" disabled>Select</option>
                          <option value="Cured">Cured</option>
                          <option value="Improved">Improved</option>
                          <option value="Unchanged">Unchanged</option>
                          <option value="AMA">AMA</option>
                          <option value="Expired">Expired</option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12" [hidden]="createpage_flag">
              <div class="row" style="margin-top: 18px ;margin-left: 1px;" [hidden]="bill_inp_show_insflag">
                <div class="col-12 tervys_heading" style="font-size: 12px !important;">Other services</div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-5">
                  <div class="row" style="border: 1px solid #66b9eb;border-radius: 15px;padding: 13px 2px;">
                    <div class="col-6">
                      <mat-label class="matlabel">Chargetypes<br>
                        <input type="text" class="ipcss inpat_width" maxlength="50" [(ngModel)]="chargetypevalue"
                          (keyup)="getChargeTypes($event)" aria-label="Number" matInput [matAutocomplete]="auto2"
                          [readonly]="readonlyFlag" />
                        <mat-autocomplete #auto2="matAutocomplete">
                          <mat-option id="optionfont" *ngFor="let charge of chargeList" value="{{charge.charge_desc}}"
                            (click)="selectitem(charge)">
                            {{charge.charge_desc}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-label>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">From</mat-label>
                        <input type="date" class="ipcss" (change)="OnDateChangedfrom(f_date)" [(ngModel)]="f_date"
                          #matInput max="{{currentDate}}" >
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">To</mat-label>
                        <input type="date" id="appt_date" (change)="OnDateChangedto(t_date)" required
                          [(ngModel)]="t_date" max="{{currentDate}}" class="ipcss" matInput>
                      </div>
                      <div class="col-6">
                        <mat-label class="matlabel">Start time</mat-label>
                        <div class="row">
                          <div class="col-3" style="padding-right:0;">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="f_time1"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option *ngFor="let hrs of start_from_array" value="{{hrs}}">{{hrs}}</option>
                            </select>
                          </div>
                          <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;"> : </div>
                          <div class="col-3" style="padding-left:0;">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="to_time1"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option *ngFor="let min of start_at_array" value="{{min}}">{{min}}</option>
                            </select>
                          </div>
                          <div class="col-5">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="sesionselction1"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <mat-label class="matlabel">End time</mat-label>
                        <div class="row">
                          <div class="col-3" style="padding-right:0;">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="f_time"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option *ngFor="let hrs of End_from_array" value="{{hrs}}">{{hrs}}</option>
                            </select>
                          </div>
                          <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;"> : </div>
                          <div class="col-3" style="padding-left:0;">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="to_time"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option *ngFor="let min of ends_at_array" value="{{min}}">{{min}}</option>
                            </select>
                          </div>
                          <div class="col-5">
                            <select type="date" class="ipcss inpat_width" [(ngModel)]="sesionselction"
                              (ngModelChange)="calculateTotalHours()" [required]="timerequired">
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Quantity<br>
                          <input type="number" class="ipcss inpat_width" [(ngModel)]="quantity"
                            [readonly]="isReadonly()" matInput />
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 my-auto"
                  style="margin-top: 56px !important;text-align: center;">
                  <mat-label class="matlabel"></mat-label>
                  <img (click)="addothercharges()" src="../../../assets/ui_icons/arrow_green.svg"
                    style="cursor: pointer;" class="arrow_imageprescript" />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-6">
                  <div class="row" >
                    <div class="col-12">
                      <div class="table-responsive dig_table_overflow">
                        <table class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th>Charge name</th>
                              <th>Start time</th>
                              <th>End time</th>
                              <th>Quantity</th>
                              <th class="delete">&nbsp;</th>
                              <th class="delete">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody *ngIf="selectedchargelistvalue.length != 0">
                            <tr *ngFor="let charge of selectedchargelistvalue ;let i=index">
                              <td style="font-size: 12px; text-align: left !important;">{{charge.chargename}}</td>
                              <td style="font-size: 12px; text-align: left !important;">{{charge.starttime}}</td>
                              <td style="font-size: 12px; text-align: left !important;">{{charge.endtime}}</td>
                              <td style="font-size: 12px; text-align: left !important;">{{charge.quantity}}</td>
                              
                              <td style="font-size: 12px;text-align: center;" (click)="deletecharge(charge)"><img
                                  src="../../../assets/ui_icons/trash_icon.svg" class="billing_edit" alt="">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="!createpage_flag">
              <div class="cover_div">
                <div class="header_lable">
                  Blood glucose
                </div>
                <div class="content_cover">
                  <div class="row" >              
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <div class="row" style="border: 1px solid #66b9eb;border-radius: 15px;padding: 13px 2px;align-items: center;">
                      <div class="col-6">
                        <mat-label class="matlabel">Glucose<br>
                          <select class="ipcss inpat_width" [(ngModel)]="gluselect" 
                            (ngModelChange)="changeglucoselevel($event)">
                            <option value="beforefood">Before food</option>
                            <option value="afterfood">2 hrs after food</option>
                            <option value="Random">Random</option>
                          </select>
                        </mat-label>
                      </div>
                        <div *ngIf="!randomflag">
                        <div class="col-6" *ngIf="beforefood_flag">
                          <mat-label class="matlabel">Fasting<br>
                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3"
                              [(ngModel)]="Fasting_Halfhour" required
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                          </mat-label>

                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-6" *ngIf="!beforefood_flag">
                          <mat-label class="matlabel">Postprandial<br>
                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3"
                              [(ngModel)]="Postprandial" required
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                          </mat-label>
                        </div>
                      </div>
                      <div *ngIf="randomflag">
                        <div class="col-12 col-md-3 col-lg-4 col-xl-6">
                          <mat-label class="matlabel">Random<br>
                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3"
                              [(ngModel)]="random" required
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                          </mat-label>
                        </div>
                      </div>

                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 my-auto"
                      style="text-align: center;margin-top: 29px !important;">
                      <mat-label class="matlabel"></mat-label>
                      <img (click)="save_reading()" src="../../../assets/ui_icons/arrow_green.svg"
                        style="cursor: pointer;" class="arrow_imageprescript" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4">
                      <div class="row" >
                        <div class="col-12">
                          <canvas id="energyGraph" width="361" height="200"
                          style="display: block;"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12" [hidden]="nurseflag && createpage_flag">
              <div class="row" style="margin-top: 18px ;margin-left: 1px;">
                <div class="col-12 tervys_heading" style="font-size: 12px !important;">Nursing notes</div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-5">
                  <div class="row" style="border: 1px solid #66b9eb;border-radius: 15px;padding: 13px 2px;">
                    <div class="col-6" style="padding: 12px;">
                      <textarea style="height: 150px !important;" class="ipcss" required maxlength="750"
                          [(ngModel)]="nursingnotes" ></textarea>
                  </div>
               
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 my-auto"
                  style="margin-top: 56px !important;text-align: center;">
                  <mat-label class="matlabel"></mat-label>
                  <img (click)="addnursingnotes()" src="../../../assets/ui_icons/arrow_green.svg"
                    style="cursor: pointer;" class="arrow_imageprescript" />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-6">
                  <div class="row" >
                    <div class="col-12">
                      <div class="table-responsive dig_table_overflow">
                        <table class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>notes</th>
                             
                            </tr>
                          </thead>
                          <tbody *ngIf="nursenotesarray.length != 0">
                            <tr *ngFor="let charge of nursenotesarray ;let i=index">
                              <td style="font-size: 12px; text-align: left !important;">{{charge.name}}</td>
                              <td style="font-size: 12px; text-align: left !important;">{{charge.notes}}</td>
                           
                              
                              <td style="font-size: 12px;text-align: center;" (click)="deletecharge(charge)"><img
                                  src="../../../assets/ui_icons/trash_icon.svg" class="billing_edit" alt="">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div id="inpatientInfoPrint">
            <div>
              <table style="width: 100%;">
                <tr>
                  <td style="text-align: center;">
                    <p style="font-size: 24px;"><strong>{{hospital_name}}</strong></p>
                    <p>{{hospitalAddress}} {{Hospital_location}}</p>
                    <p>Tel: {{hosp_tel}}</p>
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-left: 50px;">
              <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Personal information</strong></p>
            </div>
            <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
              <table style="margin-top: 5px; text-align: left !important;">
                <tr>
                  <td class="align_left"><strong>MR No : </strong>{{mrno}}</td>
                  <td class="align_left"><strong>Visit id : </strong>{{inpatId}}</td>
                </tr>
                <tr style="background-color: #fff;">
                  <td class="align_left"><strong>Name : </strong>{{clnt_saldesc}}
                    {{patient_name}}</td>

                  <td class="align_left"><strong>Mobile : </strong>{{mobile_no}}
                  </td> &nbsp;&nbsp;


                </tr>
                <tr>
                  <td class="align_left"><strong>Doctor :</strong> {{doctor_name}}</td>
                  <td class="align_left"><strong>DOA :</strong> {{admission_date | date:
                    'dd-MM-yyyy'}} {{admission_time}}
                    &nbsp;&nbsp;</td>

                </tr>
                <tr>
                  <td class="align_left"><strong>Id proof type :</strong> {{idProofNo}} /
                    {{idProofLabel}}</td>
                  <td class="align_left" *ngIf="print_wardbed"><strong>Ward / Bed No : </strong>
                    {{wardName}} / {{bed_no}}</td>
                </tr>
                <tr>
                  <td class="align_left"><strong>DOD :</strong> <span *ngIf="disPrint != undefined">{{disPrint}}
                      {{timePrint}}</span> </td>
                  <td class="align_left"><strong>MLC case : </strong>{{mlcCase}}
                    <span *ngIf="mlcCaseNoFlag"><strong>MLC case No: </strong> {{mlcCaseNo}} </span>
                  </td>
                </tr>
                <tr>
                  <td class="align_left"><strong>Mode of Payment : </strong>{{modeOfPayment}}</td>
                  <td class="align_left"><strong>Admission / Discharge reason :</strong>
                    {{admDisReason}}</td>
                </tr>
                <tr>
                  <td class="align_left"><strong>Age/Gender : </strong>{{clnt_age}}/{{clnt_gender}}</td>
                  <td class="align_left"><strong>Marital status :</strong> {{clnt_maritalvalue}}</td>
                </tr>
                <tr style="background-color: #fff;">
                  <!-- <td class="align_left"><strong>: </strong></td> -->
                  <td class="align_left"><strong>Blood group :</strong> {{clnt_blood}}</td>
                  <td class="align_left"><strong>Symptoms : </strong>{{clnt_symptom}}</td>

                </tr>
                <tr>
                  <td class="align_left"><strong>Occupation : </strong>{{clnt_occupation}}</td>
                  <td class="align_left"><strong>Income : </strong>{{clnt_income}}</td>
                </tr>
                <tr>
                  <td class="align_left"><strong>Emergency contact:</strong> {{emer_contact}}</td>
                  <td class="align_left"><strong>Referred by : </strong>{{refer_txt}}</td>
                </tr>
                <tr>
                  <td class="align_left"><strong>Care taker:</strong> {{clnt_fg_name}}</td>
                  <td class="align_left"><strong>Address :</strong> {{hosp_addr}}, {{hosp_csz}}</td>
                </tr>
                <tr>
                </tr>
              </table>
            </div>

            <div style="margin-left: 50px;" *ngIf="vitalsFlag">
              <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Vitals </strong></p>
            </div>
            <div *ngIf="vitalsFlag" style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">

              <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                <table *ngIf="vitals_List.length != 0" id="" class="table table-hover table-dynamic" cellpadding="5"
                  cellspacing="0" style="border: 1px solid #000; width: 95%; margin: 10px auto;">

                  <thead>
                    <tr style="text-align: center;">
                      <th style="text-align: left;">S.No</th>
                      <th style="text-align: left;">Height</th>
                      <th style="text-align: left;">Weight</th>
                      <th style="text-align: left;">BMI</th>
                      <th style="text-align: left;">Temp</th>
                      <th style="text-align: left;">BP</th>

                      <th style="text-align: left;">Date</th>
                      <!-- <th style="text-align: left;">Time</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vital of vitals_List">
                      <td style="text-align: left;">{{vital.sNo}}</td>
                      <td style="text-align: left;">{{vital.height}}</td>
                      <td style="text-align: left;">{{vital.weight}}</td>
                      <td style="text-align: left;">{{vital.bmi}}</td>
                      <td style="text-align: left;">{{vital.temperature}}</td>
                      <td style="text-align: left;">{{vital.bloodPressure}}</td>

                      <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                      <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                    </tr>
                  </tbody>

                </table>
              </div>
         
              <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                  <strong>COVID-19 symptoms : </strong> {{covid_type}}
                </div>
              </div>
              <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                  <strong>Duration : </strong> {{covid_duration}}
                </div>
              </div>
              <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                  <strong>Symptoms : </strong> {{coviedSytemmsText}}
                </div>
              </div>
              <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                  <strong>Comorbities : </strong> {{comorb_desc}}
                </div>
              </div>
              <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                  <table *ngIf="observList.length != 0" id="" class="table table-hover table-dynamic" cellpadding="5"
                    cellspacing="0" style="border: 1px solid #000; width: 95%; margin: 10px auto;">
                    <tr style="text-align: center;">
                      <th>Temperature</th>
                      <th>Blood pressure</th>
                      <th>Saturation</th>
                      <th>pulse</th>
                      <th>Respiratory rate</th>
                      <th>RT-PCR result</th>
                    </tr>
                    <tr *ngFor="let observ of observList">
                      <td>{{observ.temp}}</td>
                      <td>{{observ.bp}}</td>
                      <td>{{observ.sop2}}</td>
                      <td>{{observ.pulse}}</td>
                      <td>{{observ.resprate}}</td>
                      <td>{{observ.rtpcr}}</td>
                    </tr>
                  </table>
                </div>
              </div>

            </div>
            <div *ngIf="observationFlag" style="margin-left: 50px;">
              <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Observation</strong></p>
            </div>
            <div *ngIf="observationFlag" style="border: 1px solid #b5b5b6; width: 85%; margin-left: 50px;" [hidden]="covid_flag">
              <div class="rowCover" style="display: inline-flex !important; flex-direction: column;">
                <div *ngIf="this.chief_comp != undefined && this.chief_comp != ''" class="vitalLable">
                  <strong>Chief complaints:</strong> <span [innerHtml]="chief_comp"></span>
                </div>
                <div *ngIf="this.pres_ill != undefined && this.pres_ill != ''" class="vitalLable">
                  <strong>Present illness:</strong> <span [innerHtml]="pres_ill"></span>
                </div>
                <div *ngIf="this.med_hist != undefined && this.med_hist != ''" class="vitalLable">
                  <strong>Past medical history:</strong> <span [innerHtml]="med_hist"></span>
                </div>
                <div *ngIf="this.family_hist != undefined && this.family_hist != ''" class="vitalLable">
                  <strong>Family history:</strong> <span [innerHtml]="family_hist"></span>
                </div>
                <div *ngIf="this.physical_exam != undefined && this.physical_exam != ''" class="vitalLable">
                  <strong>Physical examination:</strong> <span [innerHtml]="physical_exam"></span>
                </div>
                <div *ngIf="preHospFlag" class="vitalLable">
                  <strong>Hospital details:</strong> <span [innerHtml]="pre_hospital_details"></span>
                </div>
                <div *ngIf="allergyFlag" class="vitalLable">
                  <strong>Allergy details:</strong>
                </div>
                <div *ngIf="allergyFlag && drugDetailsFlag" class="vitalLable">
                  <strong>Drug Details:</strong> {{drug_details}}
                </div>
                <div *ngIf="allergyFlag && engDetailsFlag" class="vitalLable">
                  <strong>Environmental Details:</strong> {{env_details}}
                </div>
                <div *ngIf="allergyFlag && foodDetailsFlag" class="vitalLable">
                  <strong>Food Details:</strong> {{food_details}}
                </div>
              </div>
            </div>
            
            
          </div>
      

          <div #idprint id="idprint">
            <div class="container-fluid m-5">
              <div class="row">
                <div class="col-6" style="width: 300px;border-style: solid;border-width: thin;
                    border-radius: 10px;border-color: rgb(139, 139, 251);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                  <div class="justify-content-center mt-2" style="text-align: center">
                    <h1 style="font-size: 16px;letter-spacing: 4px;color: rgb(139, 139, 251);">
                      {{ patient_name }}
                    </h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center mt-2" style="letter-spacing: 1px">
                    <!--align-items-center justify-content-center-->
                    <div>
                      <table>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Inpatient Id
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ inpatId }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            UHID
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ inpatientId }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Age/Gender
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ clnt_age }}/{{ clnt_gender }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Mobile No
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ clnt_cont_number }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            D.O.A & Time
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ admission_date | date : "dd-MM-yyyy" }}
                            {{ admission_time_full }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;
                              font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            D.O.S & Time
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ surgery_date | date : "dd-MM-yyyy" }}
                            {{ surgery_time_full }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div style="margin-top: 15px;margin-bottom: 20px !important;magin-left:15px;margin-right:15px"
                    class="d-flex align-items-center justify-content-center">
                    <ngx-barcode class="barcode" [bc-value]="inpatId" [bc-display-value]="displayValue"
                      [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                      [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                      [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                      [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                      [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                      [bc-margin-right]="marginRight">
                    </ngx-barcode>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #printbanner id="printbanner">
            <div class="container-fluid m-2">
              <div class="row">
                <div class="col-6" style="width: 400px;border-style: solid;border-width: thin;
                    border-radius: 10px;border-color: rgb(139, 139, 251);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                  <div class="justify-content-center mt-2" style="text-align: center">
                    <h1 style="font-size: 16px;letter-spacing: 4px;color: rgb(139, 139, 251);">
                      {{ hospital_name }}
                    </h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center mt-2" style="letter-spacing: 1px">
                    <!--align-items-center justify-content-center-->
                    <div class="row">
                      <!-- <div class="col-6"> {{ inpatId }}</div> -->
                      <div class="col-7">
                        <div class="row">
                          <div class="col-12"> {{clnt_saldesc}}.{{ patient_name }}</div>
                          <div class="col-12"> {{ clnt_age }}/{{ clnt_gender }}</div>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="d-flex" style="margin-bottom: 5px;">
                          <ngx-barcode class="barcode" [bc-value]="inpatientId" [bc-display-value]="displayValue"
                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                            [bc-width]="width1" [bc-height]="barcodeheight1" [bc-font-options]="fontOptions"
                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight">
                          </ngx-barcode>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div #printgraph id="printgraph">
            <div class="container-fluid m-2">
              <div class="row">
                <div class="col-6" style="width: 400px;border-style: solid;border-width: thin;
                    border-radius: 10px;border-color: rgb(139, 139, 251);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                  <div class="justify-content-center mt-2" style="text-align: center">
                    <h1 style="font-size: 16px;letter-spacing: 4px;color: rgb(139, 139, 251);">
                      {{ hospital_name }}
                    </h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center mt-2" style="letter-spacing: 1px">
                    <!--align-items-center justify-content-center-->
                    <div class="row">
                      <!-- <div class="col-6"> {{ inpatId }}</div> -->
                      <div class="col-7">
                        <div class="row">
                          <div class="col-12"> {{clnt_saldesc}}.{{ patient_name }}</div>
                          <div class="col-12"> {{ clnt_age }}/{{ clnt_gender }}</div>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="d-flex" style="margin-bottom: 5px;">
                          <ngx-barcode class="barcode" [bc-value]="inpatientId" [bc-display-value]="displayValue"
                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                            [bc-width]="width1" [bc-height]="barcodeheight1" [bc-font-options]="fontOptions"
                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight">
                          </ngx-barcode>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row">
                <canvas id="energyGraph1" width="361" height="200"
                style="display: block;"></canvas>
              </div>
            </div>
          </div>
       
 
        </div>
      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>
