import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat,seesion_nameid, session_based_time_fun,Date_Formate, day_nameid } from '../../../assets/js/common.js';
import { MatDialog } from '@angular/material/dialog';
import { DropdownModelpageComponent } from '../../Doctor_module/dropdown-modelpage/dropdown-modelpage.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
declare var $:any;

@Component({
  selector: 'app-docter-registration',
  templateUrl: './docter-registration.component.html',
  styleUrls: ['./docter-registration.component.css']
})
export class DocterRegistrationComponent implements OnInit {
  public treatFlag:boolean=true;
  public splFlag:boolean=true;
  public subSplFlag:boolean=true;

  public splArray = [];
  public subSplArray = [];

  public imgSTR = null;
  public doctorDOB;
  public treatType = null;
  public splType;
  public subSplType;
  // variable declaration

  public startTime :string;
  public endTime : String;
  public timeSession: string;
  public endsAtFrom: string;
  public endsAtTo:string;
  public endTimeSession:string;
  public currentDatetime;
  public password;
  public createPassword;

  public treatementArray = [];
  public specilizationArray = [];
  public subspecilitiesArray = [];
  public startsFromArray = [];
  public endsAtArray = [];
  public endFromArray = [];
  public endToArray = [];
  public homecareConsultArray = [];

  public homecareShow;
  public toggHome: boolean;
  public homecareSession;

  public homecareFee;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public profileIMG: string;

  public dob: string;
  public doctorMobile: string;
  public doctorEmail: string;
  public genderText: string;
  public updateIMG;
  public imagePath;

  public registerationCode;
  public registerationYear: string;
  public registerationState: string;
  public clientCall: boolean;
  public secondOpinion: boolean;
  public specializationID: string;
  public presAddress1: string;
  public presAddress2: string;
  public presLocation: string;
  public presState: string;
  public presCountry: string;
  public presZipcode: string;
  public presTelephone: string;
  public permAddress1: string;
  public permAddress2: string;
  public permLocation: string;
  public permCity: string;
  public permState: string;
  public permCountry: string;
  public permZipcode: string;
  public permTelephone: string;
  public payCash: boolean;
  public payOnline: boolean;
  public payIns: boolean;
  public presCityArray;
  public presStateArray;
  public presCountryArray;
  public cityURL;
  public stateURL;
  public countryURL;
  public presLocationFilterArray = [];
  public sendSplArray = [];
  public sendTreat = [];
  public sendSubsplArray = [];
 
  public presLocationArray = [];
  public filtCityDesc;
  public presLocID: string;
  public permLocID: string;
  public mobileSTR: string;
  public emailSTR: string;

  //perm address
  public permLocationFilterArray = [];
  public premLocation: string;
  public permLocationFilterList = [];
  public permStateArray;
  public permCountryArray;
  public permCityArray;
  public confirmPassword;

  public filtStateDesc;
  public countryID: string;

  public hospName: string;
  public hospLocation: string;
  public hospID;
  public hospAddress1: string;
  public hospAddress2: string;
  public hopWebsite: string;
  public hospContactNum: string;
  public presCitySelect;
  public splCol;
  public subSplCol;
  public permDiv: boolean;
  public sameasAboveCheck: boolean;
  public preClientAddress = [];
  public getDate;
  public proImg;
  public homecareMorningFlag: boolean;
  public homecareAfternoonFlag: boolean;
  public homecareEveningFlag: boolean;
  public homecareTable: boolean;

  //worklocation
  public workArray = [];
  public hospDist;
  public hospState;
  public hospCountry;
  public hospZip: string;
  public hospFee: string;
  public hospDays: string;
  public hospSession: string;
  public startFrom1: string;
  public startFrom2: string;
  public startFrom3: string;
  public endAt1: string;
  public endAt2: string;
  public endAt3: string;
  public hopsConsult;
  public appToken;
  public appTime: boolean;
  public count: string;
  public hospital = [];
  public location = []
  public locID: string;
  public countryDesc: string;

  public locationFilterArray = [];
  public filteredLocationArray = [];
  public filteredHospArray = [];
  public hospitalFilterArray = [];

  public cityList;
  public locationListArray = [];
  public stateListArray;
  public countryListArray;

  public appTypeLbl: string;

  //Qualification
  public qualificationArray = [];
  public qualificationDegree: string;
  public qualificationClg: string;
  public qualificationUni: string;
  public qualificationYear: string;

  //terms and condition
  public regProfileTab;
  public regWorkTab;
  public addRegistrationWork;
  public registrationQual;
  public getPackData;
  public agree:boolean;
  public dobYear;
  public saveFlag:boolean = false;
  public signatureFlag:boolean;
  public treateLabel:any;
  public docAge:string;
  public ageRead: boolean=false;
  public ageDisabledFlag: boolean=false;
  public ageUrl: string;
  //Read only
  isReadonly() { return true; }
  public signatureFile = null;
  
  constructor(public dropdown_dialog: MatDialog,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    this.homecareTable = true;
    this.sameasAboveCheck = true;
    this.permDiv = true;
    this.payCash=true;
    this.signatureFlag = false;
    //qualification
    this.qualificationDegree = "";
    this.qualificationUni = "";
    this.qualificationYear = "";
    //work location
    this.hospName = "";
    this.hospLocation = "";
    this.hospDist = "";
    this.hospState = "";
    this.hospCountry = "";
    this.hospZip = "";
    this.hospAddress1 = "",
    this.hospAddress2 = "";
    this.hospContactNum = "";
    this.hopWebsite = '';
    this.hospFee = "";
    this.hospDays = undefined;
    this.hospSession = undefined;
    this.hopsConsult = true;
    this.count = "";
    this.splCol = true;
    this.appTypeLbl = "Count";
    this.appToken = "Token";
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";
    this.endAt1 = "08";
    this.endAt2 = "00";
    this.endAt3 = "AM";
    this.hospID = "0";
  }

  ngOnInit(): void {
    this.imagePath = "default";
    this.homecareShow = true;
    this.subSplCol = true;
    this.homecareMorningFlag = true;
    this.homecareAfternoonFlag = true;
    this.homecareEveningFlag = true;

    this.Get_treatement();
    this.Get_specialization();
    this.Get_subspecilities();
    this.timeSession = "AM";
    this.endTimeSession = "AM";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    if(this.currentDatetime == undefined){
      this.getCurrentDate()
    }else{
      this.getDate = this.currentDatetime.split('-');
    }

    //Starts time
    for (var i = 1; i <= 12; i++) {
      if (i < 10) 
        this.startsFromArray.push("0" + i);
      else 
        this.startsFromArray.push(i);
    }

     this.startTime = "06";
     this.endTime = "00";
     this.timeSession = "AM";
     this.endsAtFrom = "08";
     this.endsAtTo = "00";
     this.endTimeSession = "AM";

    for (var i = 0; i <= 55; i += 5){
      if (i < 10)
        this.endsAtArray.push("0" + i);
      else 
        this.endsAtArray.push(i);
    }

    //End time

    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.endFromArray.push("0" + i);
      else 
        this.endFromArray.push(i);
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10)
        this.endToArray.push("0" + i);
      else 
        this.endToArray.push(i);
    }
  }

  getCurrentDate(){
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            get_date = obj.current_date.split('-');
            this.getDate = this.currentDatetime.split('-');
          }
        },error => {});
  }

  changeFile($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        // $scope.Imagepath == "default"
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgSTR = null;
      } else {
        this.imgSTR = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  onFileChange($event): void {
    this.signatureFlag = true;
    this.readThis1($event.target);
  }

  readThis1(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        // $scope.Imagepath == "default"
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.signatureFile = null;
      } else {
        var signatureFile = myReader.result;
        this.signatureFile = myReader.result;
        $('#signature_img').attr('src', myReader.result);
        this.signatureFile = (signatureFile as string).split(',')[1];
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);   
  }  

  Sameas_Change(e) { //sameas Event
    this.sameasAboveCheck = e;
    this.permDiv = this.sameasAboveCheck == true ? true : false;
  }

  validateRegYear(value){

  }

  home_click() {//Based on home care hide and show
    this.homecareShow = this.toggHome == true ? false : true;
  }

  session_change1() {
    if (this.hospSession == "Morning") {
      this.homecareMorningFlag = false;
      this.homecareAfternoonFlag = true;
      this.homecareEveningFlag = true;
      this.startFrom3 = "AM";
      this.endAt3="AM";
      this.startFrom1 = "06";
      this.startFrom2 = "00";
      this.endAt1 = "08";
      this.endAt2 = "00";
    } else if (this.hospSession == "Afternoon") {
      this.homecareAfternoonFlag = false;
      this.homecareMorningFlag = true;
      this.homecareEveningFlag = true;
      this.startFrom3 = "PM";
      this.endAt3="PM";
      this.startFrom1 = "12";
      this.startFrom2 = "00";
      this.endAt1 = "02";
      this.endAt2 = "00";
    } else if (this.hospSession == "Evening") {
      this.homecareEveningFlag = false;
      this.homecareAfternoonFlag = true;
      this.homecareMorningFlag = true;
      this.startFrom3 = "PM";
      this.endAt3="PM";
      this.startFrom1 = "06";
      this.startFrom2 = "00";
      this.endAt1 = "08";
      this.endAt2 = "00";
    } else {
      this.homecareMorningFlag = true;
      this.homecareAfternoonFlag = true;
      this.homecareEveningFlag = true;
    }
  }  

  session_change() {
    if (this.homecareSession == "Morning") {
      this.homecareMorningFlag = false;
      this.homecareAfternoonFlag = true;
      this.homecareEveningFlag = true;
      this.timeSession = "AM";
      this.endTimeSession="AM";
      this.startTime = "06";
      this.endTime = "00";
      this.endsAtFrom = "08";
      this.endsAtTo = "00";
    } else if (this.homecareSession == "Afternoon") {
      this.homecareAfternoonFlag = false;
      this.homecareMorningFlag = true;
      this.homecareEveningFlag = true;
      this.timeSession = "PM";
      this.endTimeSession="PM";
      this.startTime = "12";
      this.endTime = "00";
      this.endsAtFrom = "02";
      this.endsAtTo = "00";
    } else if (this.homecareSession == "Evening") {
      this.homecareEveningFlag = false;
      this.homecareAfternoonFlag = true;
      this.homecareMorningFlag = true;
      this.timeSession = "PM";
      this.endTimeSession="PM";
      this.startTime = "06";
      this.endTime = "00";
      this.endsAtFrom = "08";
      this.endsAtTo = "00";
    } else {
      this.homecareMorningFlag = true;
      this.homecareAfternoonFlag = true;
      this.homecareEveningFlag = true;
    }
  }
    
  Get_treatement() {//Get treatmentplan data
    this.treatementArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getmedicare',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.medicare != null) {
            for (var i = 0; i < obj.medicare.length; i++) {
              this.treatementArray.push({
                type: 'checkbox',
                value: obj.medicare[i].medicare_id,
                label: obj.medicare[i].medicare_name,
                checked: false
              });
            }
            this.treatType = obj.medicare[0].medicare_id;
            this.treateLabel = obj.medicare[0].medicare_name;
            this.medicare_click(obj.medicare[0].medicare_name,obj.medicare[0].medicare_id)
            this.splCol = false;
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        }
      )
  }

 
  Get_specialization() {  //Get specialization data
    this.specilizationArray = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          for (var i = 0; i < obj.specialization.length; i++) {
            this.specilizationArray.push({
              type: 'checkbox',
              // multiple:true,
              value: obj.specialization[i].spl_id,
              label: obj.specialization[i].spl_name,
              checked: false
            });
          }
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  //Get subspecilities data
  Get_subspecilities() {
    this.subspecilitiesArray = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/denspl',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.den_spl.length; i++) {
            this.subspecilitiesArray.push({
              type: 'checkbox',
              value: obj.den_spl[i].den_spl_id,
              label: obj.den_spl[i].description,
              checked: false
            });
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  medicare_popup(){//treatement type
    this.treatFlag = !this.treatFlag;
    for (var j = 0; j < this.treatementArray.length; j++) {
      this.treatementArray[j].checked = false;
    }

    if (this.sendTreat != undefined && this.sendTreat.length != 0) {
      for (var i = 0; i < this.sendTreat.length; i++) {
        for (var j = 0; j < this.treatementArray.length; j++) {
          if (this.sendTreat[i] == this.treatementArray[j].value) {
            this.treatementArray[j].checked = true;
          }
        }
      }
    }
  }

  medicare_click(data,id){
    this.sendTreat = [];
    this.sendTreat.push(id);
    if(id== 1){
      this.splCol = false;
    }
    else{
      this.splCol = true;
    }
  }

  closetreatment(){
    this.treatFlag =!this.treatFlag;
  }

  medicare_change(selectedValue) {
    this.splCol = true;
    this.treatType = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.sendTreat = selectedValue;

    if (this.sendTreat.length != 0) {
      this.splCol = this.sendTreat.indexOf('1') == 0 ? false : true;
      
      var n = 0;
      for (var i = 0; i < this.sendTreat.length; i++) {
        for (var j = 0; j < this.treatementArray.length; j++) {
          if (this.treatementArray[j].value == this.sendTreat[i]) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.treatementArray[j].label);
          }
        }
      }
      this.treatType = selected_data.toString();
    }
  }

  // speciality
  spl_popup() {
    this.splFlag = !this.splFlag;
    for (var j = 0; j < this.specilizationArray.length; j++) {
      this.specilizationArray[j].checked = false;
    }

    if (this.sendSplArray != undefined && this.sendSplArray.length != 0) {
      for (var i = 0; i < this.sendSplArray.length; i++) {
        for (var j = 0; j < this.specilizationArray.length; j++) {
          if (this.sendSplArray[i] == this.specilizationArray[j].value) {
            this.specilizationArray[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
      width: '600px',
      height: '400px',
      data: {
        title: "Speciality",
        main_array: this.specilizationArray,
        selected_list: this.sendSplArray,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.spl_change(result);
      }
    });

    // let alert_data = this.alert_ctrl.create({
    //   title: 'Speciality',
    //   cssClass: 'alertCustomCss',
    //   inputs: this.specilization_array,
    //   buttons: [
    //     {
    //       // text: 'ok',
    //       cssClass: 'alert_done_btn',
    //       handler: data => {
    //         this.spl_change(data);
    //       }
    //     },
    //     {
    //       //text: 'cancel',
    //       cssClass: 'alert_cancel_btn',
    //       role: 'cancel',
    //       handler: data => {
    //         
    //       }
    //     }
    //   ],
    // });
    // alert_data.present();
  }

  splarray_click(data,id,e){
    if(e == true){
        this.splArray.push(id)
    }else{
        if(this.splArray.length != 0){
          for( var k=0; k<this.splArray.length; k++){
            if(id == this.splArray[k])
            {
              this.splArray.splice(k);
            }
          }
        }
      }
    this.spl_change(this.splArray)
  }

  closespl(){
    this.splFlag = !this.splFlag;
  }

  spl_change(selectedValue) {//specialization
    this.subSplCol = true;
    this.splType = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.sendSplArray = selectedValue;

    if (this.sendSplArray.length != 0) {
      if (this.sendSplArray.indexOf('2') == 0) {
        this.subSplCol = false;

      } else {
        this.subSplCol = true;
      }
      var n = 0;
      for (var i = 0; i < this.sendSplArray.length; i++) {
        for (var j = 0; j < this.specilizationArray.length; j++) {
          if (this.sendSplArray[i] == this.specilizationArray[j].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.specilizationArray[j].label);
          }
        }
      }
      this.splType = selected_data.toString();
    }
  }

  sub_spl_popup() {
    this.subSplFlag = !this.subSplFlag;
    for (var j = 0; j < this.subspecilitiesArray.length; j++) {
      this.subspecilitiesArray[j].checked = false;
    }

    if (this.sendSubsplArray != undefined && this.sendSubsplArray.length != 0) {
      for (var i = 0; i < this.sendSubsplArray.length; i++) {
        for (var j = 0; j < this.subspecilitiesArray.length; j++) {
          if (this.sendSubsplArray[i] == this.subspecilitiesArray[j].value) {
            this.subspecilitiesArray[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
      width: '600px',
      height: '400px',
      data: {
        title: "Speciality",
        main_array: this.subspecilitiesArray,
        selected_list: this.sendSubsplArray,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.sub_spl_change(result);
      }
    });
  }

  closesubspl(){
    this.subSplFlag = !this.subSplFlag;
  }

  ssplarray_click(data,id,e){
    if(e == true){
        this.subSplArray.push(id)
    }else{
        if(this.subSplArray.length != 0){
          for( var k=0; k<this.subSplArray.length; k++){
            if(id == this.subSplArray[k])
            {
              this.subSplArray.splice(k);
            }
          }
        }
      }
      this.sub_spl_change(this.subSplArray)
  }
  
  sub_spl_change(selectedValue) {//subspl
    this.subSplType = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.sendSubsplArray = selectedValue;

    if (this.sendSubsplArray.length != 0) {
      var n = 0;

      for (var j = 0; j < this.sendSubsplArray.length; j++) {
        for (var i = 0; i < this.subspecilitiesArray.length; i++) {
          if (this.sendSubsplArray[j] == this.subspecilitiesArray[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.subspecilitiesArray[i].label);
          }
        }
      }
      this.subSplType = selected_data.toString();
    }
  }

  
  pres_location_fun(e) {// present address - location
    this.presCountryArray = [];
    this.presStateArray = [];
    this.presCityArray = [];
    this.presLocationArray = [];

    this.presCitySelect = '';
    this.presState = '';
    this.presCountry = '';
    this.presZipcode = '';

   
    this.presLocationFilterArray = [];
    this.presLocation = e.target.value.toString();
    if (this.presLocation !== "" && this.presLocation.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.presLocation
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              // this.presLocationFilterArray = obj.locations;
              // this.presLocationArray = this.presLocationFilterArray.filter(function (this:any,el) {
              //   return el.toLowerCase().indexOf(this.pres_location_txt.toLowerCase()) > -1;
              // }.bind(this));
              this.presLocationArray = obj.locations;
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )

    } else {
      this.presLocationArray = [];
    }
  }

  select_pres_location(data) {
    this.presLocation = data;
    this.presLocationArray = [];

    this.Get_Present_City(data);
    this.presStateArray = [];
    this.presCityArray = [];
    this.presCountryArray = [];
  }
  
 
  Get_Present_City(data) { // present address - city
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.presCityArray = data.json().cities;
          this.presCitySelect = this.presCityArray[0].city_id;
          this.filtCityDesc = this.presCityArray[0].city_desc;
          this.presLocID = this.presCityArray[0].location_id;
          this.presZipcode = this.presCityArray[0].zipcode;
          this.Pres_City_change(this.presCityArray[0].city_id);
        }
      },error => {
          this.toastr.error(Message_data.defaultErr);
      });
  }

  Pres_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {

          if (data.json().states != null && data.json().states.length != 0) {
            this.presStateArray = data.json().states;
            this.presState = this.presStateArray[0].state_id;
            this.filtStateDesc = this.presStateArray[0].state_desc;
            this.Present_state_change(this.presStateArray[0].state_id);
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        })

    } else {
      this.presStateArray = [];
      this.presCityArray = [];
      this.presCountryArray = [];
    }
  }

  Present_state_change(data) {// present address - state
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.presCountryArray = data.json().countries;
            this.countryID = this.presCountryArray[0].country_id;
            this.presCountry = this.presCountryArray[0].country_id;
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        })
    } else {
      this.presStateArray = [];
      this.presCountryArray = [];
    }
  }

  //permanent address- location
  perm_location_fun(e) {
    this.permCountryArray = [];
    this.permStateArray = [];
    this.permCityArray = [];
    this.permLocationFilterList = [];

    this.permCity = '';
    this.permCountry = '';
    this.permState = '';
    this.permZipcode = '';

   
    this.permLocationFilterArray = [];
    if (this.permLocation !== "" && this.permLocation.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.permLocation
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              // this.permLocationFilterArray = obj.locations;
              // this.permLocationFilterList = this.permLocationFilterArray.filter(function (this:any,el) {
              //   return el.toLowerCase().indexOf(this.perm_location_txt.toLowerCase()) > -1;
              // }.bind(this));
              this.permLocationFilterList = obj.locations;
            }
          },error => {
              this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.permLocationFilterList = [];
    }
  }

  select_perm_location(data) {
    this.permLocation = data;
    this.permLocationFilterList = [];

    this.Get_Permanent_City(data);
    this.permStateArray = [];
    this.permCityArray = [];
    this.permCountryArray = [];
  }
    
  Get_Permanent_City(data) {//permanent address- city
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.permCityArray = data.json().cities;
          this.permCity = this.permCityArray[0].city_id;
          this.filtCityDesc = this.permCityArray[0].city_desc;
          this.permLocID = this.permCityArray[0].location_id;
          this.permZipcode = this.permCityArray[0].zipcode;
          this.Perm_City_change(this.permCityArray[0].city_id);
        }
      },error => {
          this.toastr.error(Message_data.defaultErr);
      });
  }

  Perm_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.permStateArray = data.json().states;
            this.permState = this.permStateArray[0].state_id;
            this.filtStateDesc = this.permStateArray[0].state_desc;
            this.Permanent_state_change(this.permStateArray[0].state_id);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
    } else {
      this.permStateArray = [];
      this.permCityArray = [];
      this.permCountryArray = [];
    }
  }
  
  Permanent_state_change(data) {//permanent address- state
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.permCountryArray = data.json().countries;
            this.countryID = this.permCountryArray[0].country_id;
            this.permCountry = this.permCountryArray[0].country_id;
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
    } else {
      this.permStateArray = [];
      this.permCountryArray = [];
    }
  }
  
  homecare_btn_click() {// Homecare consulation in table
    //this.homecare_consult_array = [];
    var ret_value = true
    if (this.homecareSession == undefined || (this.homecareFee == undefined || this.homecareFee == null)) {
      this.toastr.error(Message_data.mandatory );
    } else {
      if(this.startTime == this.endsAtFrom){
        this.toastr.error(Message_data.enterValidTime);
      }else{
        var f_time = ConvertTimeformat("", this.startTime + ":" + this.endTime + " " + this.timeSession);
        var t_time = ConvertTimeformat("", this.endsAtFrom + ":" + this.endsAtTo + " " + this.endTimeSession);
        var ret_value = session_based_time_fun(f_time, t_time, this.homecareSession);
  
        if (ret_value == true) {
          this.homecareConsultArray.push({
            day_session: seesion_nameid(this.homecareSession),
            session: this.homecareSession,
            available_from: ConvertTimeformat("", this.startTime + ":" + this.endTime + " " + this.timeSession),
            available_to: ConvertTimeformat("", this.endsAtFrom + ":" + this.endsAtTo + " " + this.endTimeSession),
            available_from1: this.startTime + ":" + this.endTime + " " + this.timeSession,
            available_to1: this.endsAtFrom + ":" + this.endsAtTo + " " + this.endTimeSession,
            home_care_fee: this.homecareFee,
            homecare_consult_id: "0"
          });
  
          this.homecareTable = this.homecareConsultArray.length != 0 ? false : true;
          this.homecareSession = "Select";
          this.startTime = "06";
          this.endTime = "00";
          this.timeSession = "AM";
          this.endsAtFrom = "08";
          this.endsAtTo = "00";
          this.endTimeSession = "AM";
          this.homecareFee = null;
        } 
      }
    }
  }
  
  home_consult_delete(session_data, avail_from, avail_to) {//Home care delete
    for (var i = 0; i < this.homecareConsultArray.length; i++){
      if (session_data == this.homecareConsultArray[i].session && avail_from == this.homecareConsultArray[i].available_from1 && avail_to == this.homecareConsultArray[i].available_to1)
        this.homecareConsultArray.splice(i, 1);
    }
    this.homecareTable = this.homecareConsultArray.length != 0 ? false : true;
  }
  
  treat_click(treat_id, flag) {//medicare
    if (flag == true) {
      this.sendTreat.push(treat_id);
    }else {
      for (var i = 0; this.sendTreat.length; i++) {
        if (this.sendTreat[i] == treat_id) {
          this.sendTreat.splice(i, 1);
          break;
        }
      }
    }

    if (treat_id == 8 && flag == true) {
      this.splCol = false;
    }else {
      this.splCol = true;
      this.subSplCol = true;
    }
  }
    
  spl_click(spl_id, flag) {//specialization
    if (flag == true) {
      this.sendSplArray.push(spl_id)
    }else {
      for (var i = 0; this.sendSplArray.length; i++) {
        if (this.sendSplArray[i] == spl_id) {
          this.sendSplArray.splice(i, 1);
          break;
        }
      }
    }

    this.subSplCol = spl_id == 2 && flag == true ? false : true;
  }

  Profile_next_click() {//Profile next click
    var chec_valid = true;
    if (this.firstName == undefined || this.firstName == "" || this.lastName == undefined || this.lastName == "" 
      || this.genderText == undefined || this.doctorMobile == undefined || this.doctorMobile == "" 
      || this.registerationCode == undefined || this.registerationCode == "" 
      || this.registerationYear == undefined || this.registerationYear == "" || this.registerationState == undefined 
      || this.registerationState == "" || this.createPassword == undefined || this.createPassword == "" 
      || this.confirmPassword == undefined || this.confirmPassword == "" ) {
      this.toastr.error(Message_data.mandatory );
      chec_valid = false;
    } else if (this.treatType == null) {
      this.toastr.error(Message_data.sltTreatmentType);
      chec_valid = false;
    } else if (this.sendTreat.some(x => x === "1") && this.sendSplArray.length == 0) {
      this.toastr.error(Message_data.sltSplType);
      chec_valid = false;
    } else if (this.sendSplArray.some(x => x === "2") && this.sendSubsplArray.length == 0) {
      this.toastr.error(Message_data.sltSubSplType);
      chec_valid = false;
    } else if (this.toggHome == true && (this.presAddress1 == undefined || this.presAddress1 == "" || this.presLocation == undefined || this.presLocation == "" ||
      this.presCitySelect == undefined || this.presState == undefined || this.presCountry == undefined || this.presTelephone == undefined || this.presTelephone == "")) {
      this.toastr.error(Message_data.enterPresentAddrMandatory);
      chec_valid = false;
    } else if (this.toggHome == true && (this.sameasAboveCheck == undefined || this.sameasAboveCheck == false) && (this.permAddress1 == undefined || this.permAddress1 == "" || this.permLocation == undefined || this.permLocation == "" ||
      this.permCity == undefined || this.permState == undefined || this.permCountry == undefined || this.permTelephone == undefined || this.permTelephone == "")) {
      this.toastr.error(Message_data.enterPermenantAddrMandatory);
      chec_valid = false;
    } else if (this.doctorMobile.length < 10) {
      this.toastr.error(Message_data.validMobileNo);
      chec_valid = false;
    } else if (this.doctorEmail != undefined) {
      var x = this.doctorEmail.trim();
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        chec_valid = false;
        this.toastr.error(Message_data.validEmail);
      }
    }
    
    if (this.createPassword != this.confirmPassword) {
      this.toastr.error(Message_data.pwdNotMatched);
      chec_valid = false;
    }

    if (this.doctorDOB != undefined) {
      var get_t_date = this.doctorDOB;
      var Dobsplit = get_t_date.toString().split('-');
    }

    if (this.doctorDOB != "" && (this.registerationYear != undefined || this.registerationYear != "")) {
      if ((this.registerationYear.length != 4) || (parseInt(this.registerationYear) > parseInt(this.getDate[0]))) {
        this.toastr.error(Message_data.validRegtrYear);
        chec_valid = false;
      } else if (parseInt(this.docAge) <22) {
        this.toastr.error(Message_data.invalidRegtrOrDOB);
        chec_valid = false;
      }
    }

    if (chec_valid == true) {
      this.check_mobile_exits();
    }
  }

  final_update() {
    var cli_call = null;
    cli_call = this.clientCall == true ? "1" : "0";

    var sec_opn = null;
    sec_opn = this.secondOpinion == true ? "1" : "0";

    var home_value = null;
    home_value = this.toggHome == true ? "1" : "0";

    var pcash;
    pcash = this.payCash == true ? "1" : "0";

    var ponline;
    ponline = this.payOnline == true ? "1" : "0";

    var pins;
    pins = this.payIns == true ? "1" : "0";

    var present_address2 = "";
    if (this.presAddress2 != undefined) 
      present_address2 = this.presAddress2;
    
    var permanent_address2 = "";
    if (this.permAddress2 != undefined)
      permanent_address2 = this.permAddress2;

    if(this.doctorDOB != undefined){
      var get_tmp = this.doctorDOB.split('-');
      var get_f_date = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0]; 
    }
    
    this.preClientAddress = [];
    if (this.sameasAboveCheck == true && this.toggHome != undefined && this.toggHome == true) {
      this.preClientAddress.push({
        address1: this.presAddress1,
        address2: present_address2,
        location: this.presLocID,
        city: this.presCitySelect,
        state: this.presState,
        country: this.presCountry,
        zipcode: this.presZipcode,
        telephone: this.presTelephone,
        sameascurrent: "1",
        type: "pres",
      });
    } else {
      this.preClientAddress.push({
        address1: this.presAddress1,
        address2: present_address2,
        location: this.presLocID,
        city: this.presCitySelect,
        state: this.presState,
        country: this.presCountry,
        zipcode: this.presZipcode,
        telephone: this.presTelephone,
        sameascurrent: "0",
        type: "pres",
      }, {
          address1: this.permAddress1,
          address2: permanent_address2,
          location: this.permLocID,
          city: this.permCity,
          state: this.permState,
          country: this.permCountry,
          zipcode: this.permZipcode,
          telephone: this.permTelephone,
          sameascurrent: "0",
          type: "perm",
        });
    }

    this.updateIMG = this.imagePath == "default" ? "default" : this.imagePath.split(',')[1];
    if(this.doctorEmail != undefined)
      var emailstr = this.doctorEmail.trim();
    
    this.mobileSTR = encrypt_decript.Encript(this.doctorMobile).toString();
    this.emailSTR = encrypt_decript.Encript(emailstr).toString();
    this.password = encrypt_decript.Encript(this.createPassword).toString();

    var sub_spl = undefined;
    if (this.sendSubsplArray.length != 0) 
      sub_spl = this.sendSubsplArray;

    var img_path = this.proImg, upd_url = undefined;
    if (this.profileIMG != null) {
      img_path = this.profileIMG;
      upd_url = "1";
    } else {
      img_path = "default";
    }
    
    var sig_path;
    if(this.signatureFile != null){
      sig_path = this.signatureFile;
      console.log(sig_path);
    }

    if (this.preClientAddress != undefined && this.preClientAddress.length == 0) {
      this.preClientAddress = undefined;
    }

    if (this.homecareConsultArray != undefined && this.homecareConsultArray.length == 0) {
      this.homecareConsultArray = undefined;
    }

    if (this.toggHome == undefined || this.toggHome == false) {
      this.homecareConsultArray = undefined;
      this.preClientAddress = undefined;
    }

    var dobval ;
    if(get_f_date != undefined){
      dobval = Date_Formate(get_f_date);
    }
    var send_array = null;
    send_array = {
      serv_provider_id: "1",
      upd_url: upd_url,
      profile_image: img_path,
      signature_image: sig_path,
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      gender: this.genderText,
      dob: dobval,
      age: this.docAge,
      email: this.emailSTR,
      mobile: this.mobileSTR,
      registration_code: this.registerationCode,
      registration_state: this.registerationState,
      registration_year: this.registerationYear,
      language: "English",
      created_date: this.currentDatetime,
      password: this.password,
      verification_call: "0",
      client_call: cli_call,
      home_care: home_value,
      second_opinion: sec_opn,
      medicare_name: this.sendTreat,
      specialization_id: this.sendSplArray,
      den_spl_id: sub_spl,
      home_address: this.preClientAddress,
      homecare_consult: this.homecareConsultArray,
      pay_cash: pcash,
      pay_online: ponline,
      pay_ins: pins,
    }
    console.log(send_array);
    Helper_Class.set_regsitration_profile_array(send_array);
    this.next_click_wloc();
  }

  emailCheck() {
    if(this.doctorEmail != undefined || this.doctorEmail != ""){
      var email_di = encrypt_decript.Encript(this.doctorEmail);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: email_di.toString(),
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true)
              this.toastr.error(Message_data.existEmailID);
            else
              this.final_update();
          },error => {});
    }
  }

  check_mobile_exits() {
    var email_di = encrypt_decript.Encript(this.doctorMobile);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
      JSON.stringify({
        mobile: email_di.toString(),
        flag: "1"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true) {
            this.toastr.error(Message_data.existMobileNo);
          } else {
            if(this.doctorEmail !=undefined && this.doctorEmail.trim() != undefined){
              this.emailCheck();
            } else {
              this.final_update();
            }
          }
        },error => {});
  }

  subspl_click(subspl_id, flag) {//subspl
    if (flag == true) {
      this.sendSubsplArray.push(subspl_id);
    }
    else {
      for (var i = 0; this.sendSubsplArray.length; i++) {
        if (this.sendSubsplArray[i] == subspl_id) {
          this.sendSubsplArray.splice(i, 1);
          break;
        }
      }
    }
  }

  work_btn_click() {// add table
    var flag = false;

    if (this.hospName == "") {
      this.toastr.error(Message_data.mandatory )
      flag = true;
    }
    
    if(this.startFrom1 == this.endAt1){
      this.toastr.error(Message_data.enterValidTime)
      flag = true;
    }

    if (flag == false) {
      if (this.hospital.length != 0) {
        var cosult_id = null;
        cosult_id = this.hopsConsult == true ? 1 : 0;

        if (this.hospName == "" || this.hospLocation == "" ||
          this.hospDist == undefined || this.hospState == "" || this.hospCountry == "" ||
          this.hospZip == "" || this.hospAddress1 == "" || this.hospContactNum == "" ||
          this.hospDays == "" || this.hospSession == "" || this.count == "") {
          this.toastr.error(Message_data.mandatory )
        }else {
          var token_name = undefined, time_dure = undefined;
          if (this.appToken == "Token") {
            token_name = this.count;
            time_dure = "0";
          }else {
            token_name = "0";
            time_dure = this.count;
          }
          var f_time = ConvertTimeformat("", this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3);
          var t_time = ConvertTimeformat("", this.endAt1 + ":" + this.endAt2 + " " + this.endAt3);
          var ret_value = session_based_time_fun(f_time, t_time, this.hospSession);

          if (ret_value == true) {
            this.workArray.push({
              hospital_id: this.hospID,
              name: this.hospName,
              location: this.hospLocation,
              city: this.hospDist,
              state: this.hospState,
              country: this.hospCountry,
              zipcode: this.hospZip,
              address1: this.hospAddress1,
              address2: this.hospAddress2,
              mobilenum: this.hospContactNum,
              website: this.hopWebsite,
              fee: this.hospFee,
              day_desc: this.hospDays,
              day: day_nameid(this.hospDays),
              session_desc: this.hospSession,
              day_session: seesion_nameid(this.hospSession),
              available_from: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3,
              available_to: this.endAt1 + ":" + this.endAt2 + " " + this.endAt3,
              consultant: cosult_id,
              appointment: this.appToken + "" + this.appTime,
              token: token_name,
              app_duration: time_dure,
              consult_time_id: "0"
            });

            this.hospDays = "";
            this.hospSession = "";
            this.hopsConsult = true;
            this.count = "";
            this.startFrom1 = "06";
            this.startFrom2 = "00";
            this.startFrom3 = "AM";
            this.endAt1 = "08";
            this.endAt2 = "00";
            this.endAt3 = "AM";
          }else {
            this.toastr.error(Message_data.invalidTime);
          }
        }
      }else {
        this.toastr.error(Message_data.validHosptl);
      }
    }
  }
  
  work_delete(name_data, day, session, from_time) {//table delecte action
    for (var i = 0; i < this.workArray.length; i++) {
      if (name_data == this.workArray[i].name && day == this.workArray[i].day_desc 
        && session == this.workArray[i].session_desc && from_time == this.workArray[i].available_from) {
        this.workArray.splice(i, 1);
      }
    }
  }

  clearHospital(){
    this.hospName=null;
    this.hospID=undefined;
    this.hospitalFilterArray=[];
  }
  
  hospital_filter(e) {
    this.cityList = [];
    this.stateListArray = [];
    this.countryListArray = [];
    this.filteredLocationArray = [];
    this.locationListArray = [];

    this.hospLocation = '';
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContactNum = '';
    this.hopWebsite = '';
    this.hospName =e.target.value;
    
    var tmp_arr = [];
    if (this.hospName !== "" && this.hospName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hospName
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital = [];
            this.hospitalFilterArray = [];
            this.filteredHospArray=[];
            var obj = response.json();
            
            if (obj.hospitals != null) {
              this.hospitalFilterArray = obj.hospitals;
              for (var i = 0; i < this.hospitalFilterArray.length; i++) {
                this.hospital.push(this.hospitalFilterArray[i].hosp_name);
              }
              this.filteredHospArray = obj.hospitals;
              // this.filteredHospArray = this.hospital.filter(function (this:any,el) {
              //   return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              // }.bind(this));
              if (this.hospital.length == 0) {
                this.toastr.error(Message_data.noHosptlFound);
              }
            }else {
              this.toastr.error(Message_data.noHosptlFound);
            }
          },error => {});
    } else {
      this.filteredHospArray = [];
    }
  }

  getLocationBasedonhospital() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/hloc/',
      JSON.stringify({
        hosp_name: this.hospName
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_locations != null){
            this.locationListArray = obj.hosp_locations;
            this.hospLocation =  obj.hosp_locations.length == 1 ? obj.hosp_locations[0] : "Select";
            this.Loc_change();
          }
        },error => {});
  }

  select_hospital(data) {
    this.hospName = data.hosp_name;
    this.filteredHospArray = [];
    if (this.hospName != undefined)
      this.getLocationBasedonhospital();
  }

  Loc_change() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/haddr/',
      JSON.stringify({
        hosp_name: this.hospName,
        location: this.hospLocation
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hptl_clinic_id != null) {
            this.hospID = obj.hptl_clinic_id;
            this.hospAddress1 = obj.address1;
            this.hospAddress2 = obj.address2;
            this.hospDist = obj.city;
            this.hospState = obj.state;
            this.hospCountry = obj.country;
            this.hospContactNum = obj.telephone;
            this.hopWebsite = obj.website;
            this.hospZip = obj.zipcode;
          }
        },error => {});
  }
  
  locfilter() {//location autopopulate
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContactNum = '';
    this.hopWebsite = '';
    this.hospID = "0";
    this.location = [];
    this.locationFilterArray = [];
    var tmp_arr = [];
    if (this.hospLocation !== "" && this.hospLocation.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hospLocation
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.locationFilterArray = obj.locations;
              this.filteredLocationArray = this.locationFilterArray.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {});
    } else {
      this.filteredLocationArray = [];
    }
  }

  select_location(data) {
    this.hospLocation = data;
    this.filteredLocationArray = [];

    this.Get_City(data);
    this.stateListArray = [];
    this.cityList = [];
    this.countryListArray = [];
  }

  Get_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.cityList = data.json().cities;
          this.hospDist = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.locID = this.cityList[0].location_id;
          this.hospZip = this.cityList[0].zipcode;
          this.City_change(this.cityList[0].city_id);
        }
      },error => {});
  }

  City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateListArray = data.json().states;
            this.hospState = this.stateListArray[0].state_id;
            this.filtStateDesc = this.stateListArray[0].state_desc;
            this.State_change(this.stateListArray[0].state_id);
          }
        },error => {});
    } else {
      this.stateListArray = [];
      this.cityList = [];
      this.countryListArray = [];
    }
  }

  State_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.countryListArray = data.json().countries;
            this.countryID = this.countryListArray[0].country_id;
            this.hospCountry = this.countryListArray[0].country_desc;
            this.SerCountry_Change();
          }
        },error => {});
    } else {
      this.stateListArray = [];
      this.countryListArray = [];
    }
  }

  SerCountry_Change() {
    this.hospID = "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      JSON.stringify({
        hosp_name: this.hospName,
        location: this.hospLocation,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_id != null) {
            this.hospID = obj.hosp_id;
            this.hospAddress1 = obj.address1;
            this.hospAddress2 = obj.address2;
            if (obj.telephone != null) {
              this.hospContactNum = obj.telephone;
            }
            if (obj.website != null) {
              this.hopWebsite = obj.website;
            }
          }
        },error => {});
  }

  app_token_fun() {
    this.count = '';
    this.appTypeLbl = this.appToken == "Time" ? "Time in mins" : "Count";
  }

  qualification_btn_click() { //qualification
    if (this.qualificationDegree == "" || this.qualificationUni == "" ) {
      this.toastr.error(Message_data.mandatory )
    } else {
      var flag = true;
      var currentTime = new Date()
      var cuurrent_year = currentTime.getFullYear();
      // var reg_year_editText = this.qualificationYear;
      // var reg = parseInt(reg_year_editText);

      if (parseInt(this.docAge) < 22) {
        this.toastr.error(Message_data.validYrOfCompletion)
        flag = false;
      } else if (parseInt(this.qualificationYear) > cuurrent_year) {
        this.toastr.error(Message_data.validYrOfCompletion)
        flag = false;
      }

      if (flag == true) {
        this.qualificationArray.push({
          degree: this.qualificationDegree,
          institute: this.qualificationClg,
          university: this.qualificationUni,
          year: this.qualificationYear,
        });
        //Resets the form 
        this.qualificationDegree = "";
        this.qualificationUni = "";
        this.qualificationYear = "";
        this.qualificationClg = "";
      }
    }
  }

  qua_delete(degree_da, year_da) {//delete action
    for (var i = 0; i < this.qualificationArray.length; i++) {
      if (degree_da == this.qualificationArray[i].degree) {
        this.qualificationArray.splice(i, 1);
      }
    }
  }

  Qualification_next_click() {
    if (this.qualificationArray.length == 0) {
      this.toastr.error(Message_data.addQualf);
    } else {
      var qual_arr = null;
      qual_arr = {
        qualification: this.qualificationArray
      }
      Helper_Class.setregistration_qual_array(qual_arr);
    }
    this.intitateToRegister();
  }

  intitateToRegister(){
    if(this.agree==true) {
      var headers = new Headers();
      this.regProfileTab = Helper_Class.get_regsitration_profile_array();
      var mob = encrypt_decript.Encript(this.doctorMobile).toString();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
        JSON.stringify({
          mobile: mob,
          country: ipaddress.country_code,
          flag:"1",
          registration:true
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.otp != null) {
              this.otp_open(obj.otp);
            }
          },error => {
              this.toastr.error(Message_data.defaultErr);
          });
      } else{
        this.toastr.error(Message_data.termsNconditions);
      }
  }

  gender(){
    document.getElementById("genderid").style.border="1px solid #2ca8fe";
  }

  register_next_click() {
    this.Profile_next_click();
  }

  next_click_wloc() {
   //this.work_arry
    if (this.workArray.length == 0) {
      this.toastr.error(Message_data.addWrkLocation);
    }else {
      var work_arry1 = [];
      var work_address_array = [];
      for (var i = 0; i < this.workArray.length; i++) {
        if (this.workArray[i].hospital_id == "0") {
          var address2_txt = undefined;
          address2_txt = this.workArray[i].address2 != undefined ? this.workArray[i].address2 : "";

          var website_txt = undefined;
          website_txt = this.workArray[i].hospital_website != undefined ? this.workArray[i].hospital_website : "";

          work_address_array.push({
            name: this.workArray[i].name,
            address1: this.workArray[i].address1,
            address2: address2_txt,
            city: this.workArray[i].city,
            state: this.workArray[i].state,
            zipcode: this.workArray[i].zipcode,
            country: this.workArray[i].country,
            telephone: this.workArray[i].mobilenum,
            website: this.workArray[i].hospital_website,
            location: this.workArray[i].location,
          });

          var hosp_nursename = this.workArray[i].name;
          var hosp_nurseloc = this.workArray[i].location;
        }

        var consult_time_id = this.workArray[i].consul_id != "0" ? this.workArray[i].consult_time_id : "0";

        work_arry1.push({
          hospital_id: this.workArray[i].hospital_id,
          available_from: ConvertTimeformat("", this.workArray[i].available_from),
          available_to: ConvertTimeformat("", this.workArray[i].available_to),
          fee: this.workArray[i].fee,
          day: this.workArray[i].day,
          day_session: this.workArray[i].day_session,
          token: this.workArray[i].token,
          app_duration: this.workArray[i].app_duration,
          consultant: this.workArray[i].consultant,
          consultation_time_id: consult_time_id,
          hosp_name: hosp_nursename,
          location: hosp_nurseloc,
        });
      }
      Helper_Class.set_reg_work_array(work_arry1);
      Helper_Class.set_reg_work_add_array(work_address_array);
    }
    this.Qualification_next_click();
  }

  set_image_data() {
    if (this.imgSTR == "../../assets/img/default.jpg")
      this.profileIMG = "default";
    else 
      this.profileIMG = this.imgSTR != undefined ? this.imgSTR.split(',')[1] : "default";
    this.register();
  }

  otp_open(otp_data) {
    this.set_image_data();
  }

  register(){
    this.regProfileTab = Helper_Class.get_regsitration_profile_array();
    this.regWorkTab = Helper_Class.get_reg_work_array();
    this.addRegistrationWork = Helper_Class.get_reg_work_add_array();
    this.registrationQual = Helper_Class.getregistration_qual_array();

    var send_work = null;
    send_work = {
      consultation: this.regWorkTab
    }

    var send_url = "usercontroller/regprov/";
    for (var key in this.regProfileTab) {
      if (key == "upd_url" && this.regProfileTab[key] != undefined) {
        send_url = "usercontroller/regprovweb/";
      }
    }
    
    var subscribe_array = {
      grit_package_subscribe_id: "12",
      country: ipaddress.country_code
    }

    var finalObj = $.extend(true, this.regProfileTab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.registrationQual);
    var finalObj2 = $.extend(true, finalObj1, subscribe_array);
    var output = null;
    output = finalObj2;

    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_reg_work_add_array(); 

    if (this.addRegistrationWork.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output =  $.extend (true,finalObj2,send_foot);
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if(obj.key=="1"){
            this.saveFlag = true;
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl("/loginpage");
          } else{
            this.toastr.error(Message_data.unableToRegtr);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  Date_DOB(e){
    this.doctorDOB = e;

    this.ageRead = true;
    if(e != null)
    this.ageDisabledFlag = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.doctorDOB),
      curdate: Date_Formate(this.currentDatetime),

    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined)
          this.docAge = obj.value;
      });
  }

  Date_qulification(e){
    this.qualificationYear = e;
  }
}
