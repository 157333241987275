import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-others-clinical-exam',
  templateUrl: './others-clinical-exam.component.html',
  styleUrls: ['./others-clinical-exam.component.css']
})

export class OthersClinicalExamComponent implements OnInit {
  public othersClinicalFlag ="min";
  public others_clinical;

  subscription:Subscription;
  public minflag:boolean=true;
  public medical_Check = [];
  public medical_history_array = [];
  public Main_concern_array = [];
  public Medical_history_array = [];
  public Religious_belief_array = [];
  public Maincheck = [];
  public Maincon_txt;
  public Medical_Check = [];
  public past_medical;
  public Religious_check = [];
  public relbelflag;
  public religious_belief;
  public medhist_flag1:boolean=false;
  public neuro_flag:boolean=false;
  public neph_flag:boolean=false;
  public footflag :boolean=false;
  public skinflag :boolean=false;
  public ret_flag :boolean=false;
  public hearflag :boolean=false;
  public mobi_flag :boolean=false;
  public cadio_array1=[];
  public Nephropathy_array1=[];
  public Neuropathy_array1=[];
  public skin_array1=[];
  public Foot_arry1=[];
  public mobility_array1=[];
  public Retinopathy_arry1 = [];
  public hearing_array1=[];
  public case_hist_id: string;
  public life_style_id: string;
  public medhistflag: string;
  public feelstress_label: string;
  public stress_label: string;
  public medhist_label: string;
  public symptom_label: string;
  public hdisorder_label: string;
  public treat_label: string;
  public hosp_label: string;
  public hospdet_label: string;
  public rightarm_label: string;
  public leftarm_label: string;
  public murmurs_label: string;
  public murmursgrade_label: string;
  public hsound_label:string;
  public fasting_label: string;
  public prandial_label: string;
  public eyes_label: string;
  public nails_label: string;
  public liptongue_label: string;
  public neck_label: string;
  public face_label: string;
  public precordium_label: string;
  public hand_arm_label: string;
  public abdomen_label: string;
  public lowerlimb_label: string;
  public caprefill_label: string;

  public feelstress: string;
  public hdisorder: string;
  public hosp: string;
  public hospdet: string;
  public treat: string;
  public rightarm:string;
  public rightarm1: string;
  public leftarm:string;
  public leftarm1:string;
  public fasting:string;
  public prandial: string;
  public cardio_label:string;
  public neuropathy_label: string;
  public nephropathy_label: string;
  public retinopathy_label: string;
  public footdamage_label: string;
  public skincon_label: string;
  public hearing_label: string;
  public mobility_label: string;
  public Maincon_label:string;
  public Past_medicallabel:string;
  public Religious_label: string;
  public stress: string;
  public symptom: string;

  public header_footer_flag:boolean;
  public history_flag: boolean;
  public feelstress_hidden: boolean;
  public stress_hidden: boolean;
  public medhist_hidden: boolean;
  public symptom_hidden: boolean;
  public hdisorder_hidden: boolean;
  public treat_hidden: boolean;
  public hosp_hidden: boolean;
  public hospdet_hidden: boolean;
  public rightarm_hidden:boolean;
  public leftarm_hidden:boolean
  public fasting_hidden: boolean;
  public prandial_hidden:boolean;
  public cardio_hidden:boolean;
  public neuropathy_hidden: boolean;
  public nephropathy_hidden: boolean;
  public retinopathy_hidden: boolean;
  public footdamage_hidden: boolean;
  public skincon_hidden: boolean;
  public hearing_hidden: boolean;
  public mobility_hidden:boolean;
  public maincon_hidden:boolean;
  public pastmeical_hidden: boolean;
  public religious_hidden: boolean;
  public prevhosp_flag: boolean;
  public stress_flag:boolean;


  public Cardiovascular_array = [];
  public Neuropathy_array = [];
  public Nephropathy_array = [];
  public Retinopathy_arry = [];
  public Foot_arry = [];
  public Skin_arry = [];
  public Hearing_arry = [];
  public Mobile_arry = [];
  public complicate_cardio: string;
  public complicate_neuropathy: string;
  public complicate_nephro: string;
  public complicate_retino: string;
  public complicate_footdamage: string;
  public complicate_skin: string;
  public complicate_hearing: string;
  public complicate_mobility: string;
  public video: string;
  public Cardio_check = [];
  public Neuro_check = [];
  public Retino_check = [];
  public Foot_check = [];
  public skin_Check_nephlist = [];
  public hear_Check_nephlist = [];
  public Mobility_check = [];
  public Medical_Check_nephlist = [];
  public Neuropathy;

  public Complicate_hist: string;
  public footheader: boolean;
  public cardio_txt;
  public nephropathy;
  public retino_pathy;
  public foot_damage;
  public skin_txt;
  public hearing_txt;
  public mobility_txt;
  public hosp_flag;
  public feelstress_flag;
  
  public mainconcern_flag:boolean=false;
  public mainconcern_value_array = [];
  public medhist_flag:boolean=false;
  public medhist_value_array = [];
  public religious_flag:boolean=false;
  public religious_value_array = [];
  public clinic_histhidden;
  public getRet1;
  public client:boolean;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,
    private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
    this.feelstress="No";
    this.hosp="No";
    this.prevhosp_flag=true;
    this.stress_flag=true;
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.othersClinicalFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    this.getRet1= Helper_Class.getRet();
    var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
    for (var i = 0; i < getHopitalfieldsRet.length; i++) {
      if (getHopitalfieldsRet[i].page_name == "Other Clinical") {
        if (getHopitalfieldsRet[i].fields != undefined) {
          if (getHopitalfieldsRet[i].fields != undefined) {
            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
              if (getHopitalfieldsRet[i].fields[j].field_name == "Main concenrns") {
                this.Maincon_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.maincon_hidden = false;
              }
              
              if (getHopitalfieldsRet[i].fields[j].field_name == "Past medical history") {
                this.medhist_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.medhist_hidden = false;
              }
              
              if (getHopitalfieldsRet[i].fields[j].field_name == "Religious or other beliefs") {
                this.Religious_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.religious_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Do you feel stressed") {
                this.feelstress_label = getHopitalfieldsRet[i].fields[j].field_name + "?";
                this.feelstress_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Stress factors") {
                this.stress_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.stress_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Symptoms") {
                this.symptom_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.symptom_hidden = false;
              }

              
              if (getHopitalfieldsRet[i].fields[j].field_name == "Treatment") {
                this.treat_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.treat_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Previous hospitalization") {
                this.hosp_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.hosp_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Hospitalization details") {
                this.hospdet_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.hospdet_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Right arm") {
                this.rightarm_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.rightarm_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Left arm") {
                this.leftarm_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.leftarm_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Fasting") {
                this.fasting_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.fasting_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Postprandial") {
                this.prandial_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.prandial_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Cardiovascular") {
                this.cardio_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.cardio_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Neuropathy") {
                this.neuropathy_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.neuropathy_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Nephropathy") {
                this.nephropathy_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.nephropathy_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Retinopathy") {
                this.retinopathy_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.retinopathy_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Foot damage") {
                this.footdamage_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.footdamage_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Skin conditions") {
                this.skincon_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.skincon_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Hearing") {
                this.hearing_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.hearing_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Mobility concerns") {
                this.mobility_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.mobility_hidden = false;
              }
            }
          }
        }
      }
    }
    if(masterCSData_Helper.getMasterMainConcern() == undefined){
      await this.masterCSdata.getMainconcern();
      this.getMainConcern();
    }else{
      this.getMainConcern();
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.getMedicalHistory();
    }else{
      this.getMedicalHistory();
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getReligiousOtherBeliefs();
      this.getReligiousBeliefs();
    }else{
      this.getReligiousBeliefs();
    }

    if(masterCSData_Helper.getMasterCardiovasular() == undefined){
      await this.masterCSdata.getCardiovascular();
      this.get_Cardiovascular();
    }else{
      this.get_Cardiovascular();
    }
  }

  stress_change(){
    if(this.feelstress =="Yes" && this.client == false){
      this.stress_flag = false;
    }else{
      this.stress_flag=true;
      this.stress="";
      this.symptom="";
    }
  }

  //main concern
  Mainconcern_pop() {
    if(this.client == false){
      this.mainconcern_flag = true;
      for (var j = 0; j < this.Main_concern_array.length; j++) {
        this.Main_concern_array[j].checked = false;
      }
  
      if (this.Maincheck != undefined && this.Maincheck.length != 0) {
        for (var i = 0; i < this.Maincheck.length; i++) {
          for (var j = 0; j < this.Main_concern_array.length; j++) {
            if (this.Maincheck[i] == this.Main_concern_array[j].value) {
              this.Main_concern_array[j].checked = true;
            }
          }
        }
      }
    }

    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Main Concerns",
        main_array: this.Main_concern_array,
        selected_list:this.Maincheck,
      } 
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.main_click(result);
        }
    });
  }

  main_click(selected) {
    this.Maincon_txt = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.Maincheck = selected;
    if (this.Maincheck.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Maincheck.length; j++) {
        for (var i = 0; i < this.Main_concern_array.length; i++) {
          if (this.Maincheck[j] == this.Main_concern_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Main_concern_array[i].label);
          }
        }
      }
      this.Maincon_txt = selected_data.toString();
    } else {
      this.Maincon_txt = "";
    }
  }
  
  //medical popup
  medical_popup() {
    if(this.client == false){
        this.medhist_flag = true;
        for (var j = 0; j < this.Medical_history_array.length; j++) {
          this.Medical_history_array[j].checked = false;
        }

        if (this.Medical_Check != null && this.Medical_Check != undefined && this.Medical_Check.length != 0) {
          for (var i = 0; i < this.Medical_Check.length; i++) {
            for (var j = 0; j < this.Medical_history_array.length; j++) {
              if (this.Medical_Check[i] == this.Medical_history_array[j].value) {
                this.Medical_history_array[j].checked = true;
              }
            }
          }
        }

        const dialogRef = this.dialog.open(DropdownModelpageComponent, {
          width: '500px',
          height: '400px',
          data:{
            title: "Past Medical History",
            main_array: this.Medical_history_array,
            selected_list:this.Medical_Check,
          } 
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
            this.meddiab_click(result);
            }
        });
    }
  }

  meddiab_click(selected) {
    this.past_medical = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.Medical_Check = selected;
    }
    
    if (this.Medical_Check.length != 0) {
      var n = 0;
      
      for (var j = 0; j < this.Medical_Check.length; j++) {
        for (var i = 0; i < this.Medical_history_array.length; i++) {
          if (this.Medical_Check[j] == this.Medical_history_array[i].value) {
            
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Medical_history_array[i].label);
          }
        }
      }
      this.past_medical = selected_data.toString();
    }
    
  }

  //religious beliefs
  religious_popup() {
    if(this.client == false){
        this.religious_flag = true;
        for (var j = 0; j < this.Religious_belief_array.length; j++) {
          this.Religious_belief_array[j].checked = false;
        }

        if (this.Religious_check != undefined && this.Religious_check.length != 0) {
          for (var i = 0; i < this.Religious_check.length; i++) {
            for (var j = 0; j < this.Religious_belief_array.length; j++) {
              if (this.Religious_check[i] == this.Religious_belief_array[j].value) {
                this.Religious_belief_array[j].checked = true;
              }
            }
          }
        }
      
        const dialogRef = this.dialog.open(DropdownModelpageComponent, {
          width: '500px',
          height: '400px',
          data:{
            title: "Religious or Other Beliefs",
            main_array: this.Religious_belief_array,
            selected_list:this.Religious_check,
          } 
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
            this.relclick(result);
            }
        });
    }
  }

  relclick(selected) {
    this.religious_belief = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Religious_check = selected;

    if (this.Religious_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Religious_check.length; j++) {
        for (var i = 0; i < this.Religious_belief_array.length; i++) {
          if (this.Religious_check[j] == this.Religious_belief_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Religious_belief_array[i].label);
          }
        }
      }
      this.religious_belief = selected_data.toString();
    }
  }

  enableHosp(){
    if(this.hosp =="Yes"){
      this.prevhosp_flag = false;
    } else{
      this.prevhosp_flag = true;
    }
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }
  
  send_data() {
    Helper_Class.setClinicalHist(null);

    var compli_data = null;
    var card_flg;
    var neuro_flg;
    var neph_flg;
    var retino_flg;
    var foot_flg;
    var skin_flag;
    var hear_flag;
    var mobility_flg;

    var main_flag, medhistory_flag, rel_flag;

    if (this.Cardio_check.length != 0) {
      card_flg = "yes"

    } else {
      card_flg = "no"
    }

    if (this.Neuro_check.length != 0) {
      neuro_flg = "yes"

    } else {
      neuro_flg = "no"
    }

    if (this.Medical_Check_nephlist.length != 0) {
      neph_flg = "yes"

    } else {
      neph_flg = "no"
    }

    if (this.Retino_check.length != 0) {
      retino_flg = "yes"

    } else {
      retino_flg = "no"
    }

    if (this.Retino_check.length != 0) {
      retino_flg = "yes"

    } else {
      retino_flg = "no"
    }

    if (this.Foot_check.length != 0) {
      foot_flg = "yes"

    } else {
      foot_flg = "no"
    }

    if (this.skin_Check_nephlist.length != 0) {
      skin_flag = "yes"

    } else {
      skin_flag = "no"
    }

    if (this.hear_Check_nephlist.length != 0) {
      hear_flag = "yes"

    } else {
      hear_flag = "no"
    }

    if (this.Mobility_check.length != 0) {
      mobility_flg = "yes"

    }  else {
      mobility_flg = "no"
    }
    var medicaldata =[];
    if (this.Medical_Check != null) {
      medicaldata = this.Medical_Check;
    } else{
      medicaldata=null;
    }

    if(this.Medical_Check.length != 0){
      this.medhistflag = "yes";
    }
    if(this.Religious_check.length != 0){
      this.relbelflag = "yes";
    }
    if(this.Maincheck.length != 0){
      main_flag = "yes";
    }


    var clinical_data ={
      stressed: this.feelstress,
      stress_factor: this.stress,
      symptoms: this.symptom,
      main_concern: this.Maincheck,
      medical_history: medicaldata,
      medical_history_flag:this.medhistflag,
      rel_belief: this.Religious_check,
      rel_belief_flag: this.relbelflag,
      prev_hosp_visit: this.hosp,
      hosp_visit_det: this.hospdet,
      treatment:this.treat,
      
      bp_right_arm_dia: this.rightarm,
      bp_right_arm_sys: this.rightarm1,
      bp_left_arm_dia: this.leftarm,
      bp_left_arm_sys: this.leftarm1,
      glucose_fast: this.fasting,
      glucose_postprand: this.prandial,

      mobility_comp: this.Mobility_check,
      hear_comp: this.hear_Check_nephlist,
      skin_comp: this.skin_Check_nephlist,
      foot_comp: this.Foot_check,
      retino_comp: this.Retino_check,
      neprho_comp: this.Medical_Check_nephlist,
      neuro_comp: this.Neuro_check,
      cardio_comp: this.Cardio_check,
      cardio_flag: card_flg,
      neuro_flag: neuro_flg,
      neprho_flag: neph_flg,
      retino_flag: retino_flg,
      foot_flag: foot_flg,
      skin_flag: skin_flag,
      hear_flag: hear_flag,
      mobility_flag: mobility_flg
      
    }
    Helper_Class.setClinicalHist(clinical_data);
    
  }

  //Get main concern data
  getMainConcern() {
    this.Main_concern_array = [];
    var obj = masterCSData_Helper.getMasterMainConcern();
    if (obj != undefined) {
      if (obj.main_concerns != null) {
        for (var i = 0; i < obj.main_concerns.length; i++) {
          this.Main_concern_array.push({
            type: 'checkbox',
            value: obj.main_concerns[i].main_concerns_id,
            label: obj.main_concerns[i].description,
            checked: false
          });
        }
      }
    }
  }
  
  //Get past medical history
  getMedicalHistory() {
    this.Medical_history_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if (obj != undefined) {
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.Medical_history_array.push({
            type: 'checkbox',
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if(obj.med_hist[i].med_hist_id == 32){
            this.past_medical = obj.med_hist[i].description;
          }
        }
      }
    }
  }
  
  //Get Religious or other beliefs
  getReligiousBeliefs() {
    this.Religious_belief_array = [];
    var obj = masterCSData_Helper.getMasterReligiousOtherBeliefs();
    if (obj != undefined) {
      if (obj.religious_beliefs != null) {
        for (var i = 0; i < obj.religious_beliefs.length; i++) {
          this.Religious_belief_array.push({
            type: 'checkbox',
            value: obj.religious_beliefs[i].religious_belief_id,
            label: obj.religious_beliefs[i].description,
            checked: false
          });
        }
      }
    }
  }
  
  // mobile select
  mob_click(selected) {
      this.mobility_txt = "";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Mobility_check = selected;

    if (this.Mobility_check.length != 0) {
      var n = 0;

      for (var j = 0; j < this.Mobility_check.length; j++) {
        for (var i = 0; i < this.Mobile_arry.length; i++) {
          if (this.Mobility_check[j] == this.Mobile_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Mobile_arry[i].label);
          }
        }
      }

      this.mobility_txt = selected_data.toString();
    }
  }

  mobility_pop() {
    for (var j = 0; j < this.Mobile_arry.length; j++) {
      this.Mobile_arry[j].checked = false;
    }

    if (this.Mobility_check != undefined && this.Mobility_check.length != 0) {
      for (var i = 0; i < this.Mobility_check.length; i++) {
        for (var j = 0; j < this.Mobile_arry.length; j++) {
          if (this.Mobility_check[i] == this.Mobile_arry[j].value) {
            this.Mobile_arry[j].checked = true;
          }
        }
      }
    }
  }
  
  
  foot_dmagepop() {
    for (var j = 0; j < this.Foot_arry.length; j++) {
      this.Foot_arry[j].checked = false;
    }

    if (this.Foot_check != undefined && this.Foot_check.length != 0) {
      for (var i = 0; i < this.Foot_check.length; i++) {
        for (var j = 0; j < this.Foot_arry.length; j++) {
          if (this.Foot_check[i] == this.Foot_arry[j].value) {
            this.Foot_arry[j].checked = true;
          }
        }
      }
    }
  }

  retino_Pop() {
    for (var j = 0; j < this.Retinopathy_arry.length; j++) {
      this.Retinopathy_arry[j].checked = false;
    }

    if (this.Retino_check != undefined && this.Retino_check.length != 0) {
      for (var i = 0; i < this.Retino_check.length; i++) {
        for (var j = 0; j < this.Retinopathy_arry.length; j++) {
          if (this.Retino_check[i] == this.Retinopathy_arry[j].value) {
            this.Retinopathy_arry[j].checked = true;
          }
        }
      }
    }
  }
      
  medneph_pop() {
    for (var j = 0; j < this.Nephropathy_array.length; j++) {
      this.Nephropathy_array[j].checked = false;
    }

    if (this.Medical_Check_nephlist != undefined && this.Medical_Check_nephlist.length != 0) {
      for (var i = 0; i < this.Medical_Check_nephlist.length; i++) {
        for (var j = 0; j < this.Nephropathy_array.length; j++) {
          if (this.Medical_Check_nephlist[i] == this.Nephropathy_array[j].value) {
            this.Nephropathy_array[j].checked = true;
          }
        }
      }
    }
  }
  
  // Get Mobility concerns
  get_Mobility() {
    var obj = masterCSData_Helper.getMasterMobility();
    if (obj != undefined) {
      if (obj.mobility_diseases != null) {
        for (var i = 0; i < obj.mobility_diseases.length; i++) {
          this.Mobile_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.mobility_diseases[i].mobility_dis_id,
            label: obj.mobility_diseases[i].description,
            checked: false
          });
        }
      }
    }
    this.GetClinicalData();
  }
  
  // Get Hearing  data
  async get_Hearing() {
    var obj = masterCSData_Helper.getMasterHearing();
    if (obj != undefined) {
      if (obj.hearing_diseases != null) {
        for (var i = 0; i < obj.hearing_diseases.length; i++) {
          this.Hearing_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.hearing_diseases[i].diab_hearing_id,
            label: obj.hearing_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterMobility() == undefined){
      await this.masterCSdata.getMobility();
      this.get_Mobility();
    }else{
      this.get_Mobility();
    }
  }
  
  // Get Skin  data
  async get_Skin() {
    var obj = masterCSData_Helper.getMasterSkin();
    if (obj != undefined) {
      if (obj.skin_diseases != null) {
        for (var i = 0; i < obj.skin_diseases.length; i++) {
          this.Skin_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.skin_diseases[i].skin_dis_id,
            label: obj.skin_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterHearing() == undefined){
      await this.masterCSdata.getHearing();
      this.get_Hearing();
    }else{
      this.get_Hearing();
    }
  }
  
  // Get Foot data
  async get_Foot() {
    var obj = masterCSData_Helper.getMasterFoot();
    if (obj != undefined) {
      if (obj.foot_diseases != null) {
        for (var i = 0; i < obj.foot_diseases.length; i++) {
          this.Foot_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.foot_diseases[i].foot_dis_id,
            label: obj.foot_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterSkin() == undefined){
      await this.masterCSdata.getSkin();
      this.get_Skin();
    }else{
      this.get_Skin();
    }
  }
   
  // Get Retinopathy data
  async get_Retinopathy() {
    var obj = masterCSData_Helper.getMasterRetino();
    if (obj != undefined) {
      if (obj.eye_diseases != null) {
        for (var i = 0; i < obj.eye_diseases.length; i++) {
          this.Retinopathy_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.eye_diseases[i].eye_dis_id,
            label: obj.eye_diseases[i].description,
            checked: false
          });
        }
        if(masterCSData_Helper.getMasterFoot() == undefined){
          await this.masterCSdata.getFoot();
          this.get_Foot();
        }else{
          this.get_Foot();
        }
      }
    }
  }
  
  //Get Neuropathy data
  async get_Nephropathy() {
    var obj = masterCSData_Helper.getMasterNephro();
    if (obj != undefined) {
      if (obj.nephro_diseases != null) {
        for (var i = 0; i < obj.nephro_diseases.length; i++) {
          this.Nephropathy_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.nephro_diseases[i].nephro_id,
            label: obj.nephro_diseases[i].description,
            checked: false
          });
        }
        if(masterCSData_Helper.getMasterRetino() == undefined){
          await this.masterCSdata.getRetinopathy();
          this.get_Retinopathy();
        }else{
          this.get_Retinopathy();
        }
      }
    }
  }
  
  //Get Neuropathy data
  async get_Neuropathy() {
    this.Neuropathy_array = [];
    var obj = masterCSData_Helper.getMasterNeuro();
    if (obj != undefined) {
      if (obj.neuro_diseases != null) {
        for (var i = 0; i < obj.neuro_diseases.length; i++) {
          this.Neuropathy_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.neuro_diseases[i].neuro_id,
            label: obj.neuro_diseases[i].description,
            checked: false
          });
        }

        if(masterCSData_Helper.getMasterNephro() == undefined){
          await this.masterCSdata.getNephropathy();
          this.get_Nephropathy();
        }else{
          this.get_Nephropathy();
        }    
      }
    }
  }
  
  //Get Cardiovascular data 
  async get_Cardiovascular() {
    var obj = masterCSData_Helper.getMasterCardiovasular();
    if (obj != undefined) {
      if (obj.cardio_diseases != null) {
        for (var i = 0; i < obj.cardio_diseases.length; i++) {
          this.Cardiovascular_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.cardio_diseases[i].cardio_id,
            label: obj.cardio_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterNeuro() == undefined){
      await this.masterCSdata.getNeuropathy();
      this.get_Neuropathy();
    }else{
      this.get_Neuropathy();
    }
  }
   
  GetClinicalData(){
      var getRet = Helper_Class.getRet();
      if (getRet != undefined) {
        if (getRet.case_hist_id != null) {
          this.case_hist_id = getRet.case_hist_id;
        }
  
        if (getRet.life_style_id != null) {
          this.life_style_id = getRet.life_style_id;
        }
        
        this.feelstress = getRet.stressed;
        this.stress = getRet.stress_factor;
        if(getRet.stressed =="Yes"){
          this.stress_flag = false;
        } else{
          this.stress_flag = true;
        }
  
        if(getRet.prev_hosp_visit =="Yes" && this.client == false){
          this.prevhosp_flag=false;
          
        } else{
          this.prevhosp_flag = true;
        }
        this.symptom = getRet.symptoms;
        this.treat = getRet.treatment;
        this.hosp = getRet.prev_hosp_visit;
        this.hospdet = getRet.hosp_visit_det;
        this.rightarm = getRet.bp_right_arm_dia;
        this.rightarm1 = getRet.bp_right_arm_sys;
        this.leftarm = getRet.bp_left_arm_dia;
        this.leftarm1 = getRet.bp_left_arm_sys;
        this.fasting = getRet.glucose_fasting;
        this.prandial = getRet.glucose_postprandial;
        
        // Main Concerns
        if (getRet.main_concerns != null) {
          for (var j = 0; j < getRet.main_concerns.length; j++) {
            this.Maincheck.push(getRet.main_concerns[j].main_concerns_id);
            this.mainconcern_value_array = this.Maincheck;
          }
          this.main_click(this.Maincheck)
        }
  
        // Religious or other beliefs
        if (getRet.religious_beliefs != null) {
          for (var j = 0; j < getRet.religious_beliefs.length; j++) {
            this.Religious_check.push(getRet.religious_beliefs[j].religious_belief_id);
            this.religious_value_array = this.Religious_check;
          }
          this.relclick(this.Religious_check)
        }
  
        if (getRet.medical_history != null 
          && getRet.medical_history != undefined 
          && getRet.medical_history.length != 0) {
            
          for (var i = 0; i < getRet.medical_history.length; i++) {
            this.medical_Check.push(getRet.medical_history[i].med_hist_id);
            this.medhist_value_array = this.medical_Check;
          }
          
          this.meddiab_click(this.medical_Check);
        }
  
        // Cardiovascular
        this.Cardio_check = [];
  
        if (getRet.cardio_dis != null) {
          for (var j = 0; j < getRet.cardio_dis.length; j++) {
            this.Cardio_check.push(getRet.cardio_dis[j].diab_cardio_dis_id);
          }
          this.cardio_click(this.Cardio_check)
        }
  
        // Neuropathy
        this.Neuro_check = [];
        if (getRet.neuro_dis != null) {
          for (var j = 0; j < getRet.neuro_dis.length; j++) {
            this.Neuro_check.push(getRet.neuro_dis[j].diab_neuro_dis_id);
          }
  
          this.neuro_click(this.Neuro_check)
        }
  
        // Nephropathy
        this.Medical_Check_nephlist = [];
        if (getRet.neprho_dis != null) {
          for (var j = 0; j < getRet.neprho_dis.length; j++) {
            this.Medical_Check_nephlist.push(getRet.neprho_dis[j].diab_nephro_dis_id);
          }
         
          this.medneph_click(this.Medical_Check_nephlist)
        }
  
        // REtinopathy
        this.Retino_check = [];
        if (getRet.retino_dis != null) {
          for (var j = 0; j < getRet.retino_dis.length; j++) {
            this.Retino_check.push(getRet.retino_dis[j].diab_retino_dis_id);
          }
          
          this.ret_click(this.Retino_check)
        }
  
        // Foot damage
        this.Foot_check = [];
        if (getRet.foot_dis != null) {
          for (var j = 0; j < getRet.foot_dis.length; j++) {
            this.Foot_check.push(getRet.foot_dis[j].diab_foot_dis_id);
          }
          this.foot_click(this.Foot_check)
        }
  
        // skin condition
        this.skin_Check_nephlist = [];
        if (getRet.skin_dis != null) {
          for (var j = 0; j < getRet.skin_dis.length; j++) {
            this.skin_Check_nephlist.push(getRet.skin_dis[j].diab_skin_dis_id);
          }
          this.skin_click(this.skin_Check_nephlist)
        }
  
        // Hearing
        this.hear_Check_nephlist = [];
        if (getRet.hearing_dis != null) {
          for (var j = 0; j < getRet.hearing_dis.length; j++) {
            this.hear_Check_nephlist.push(getRet.hearing_dis[j].diab_hearing_id);
          }
          this.hear_click(this.hear_Check_nephlist)
        }
  
        // Hearing
        this.Mobility_check = [];
  
        if (getRet.mobility != null) {
          for (var j = 0; j < getRet.mobility.length; j++) {
            this.Mobility_check.push(getRet.mobility[j].diab_mobility_dis_id);
          }
          this.mob_click(this.Mobility_check)
        }
      }
  
  }
  
  //clinical exam others
  medical_popup1(){   
    if(this.client == false){
      this.medhist_flag1 = true;
      for (var j = 0; j < this.Cardiovascular_array.length; j++) {
        this.Cardiovascular_array[j].checked = false;
      }
  
      if (this.Cardio_check != undefined && this.Cardio_check.length != 0) {
        for (var i = 0; i < this.Cardio_check.length; i++) {
          for (var j = 0; j < this.Cardiovascular_array.length; j++) {
            if (this.Cardio_check[i] == this.Cardiovascular_array[j].value) {
              this.Cardiovascular_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Cardiovascular",
          main_array: this.Cardiovascular_array,
          selected_list:this.Cardio_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.cardio_click(result);
          }
      });
    }
  }
   
  neuro(){   
    if(this.client == false){
      this.neuro_flag = true;
      for (var j = 0; j < this.Neuropathy_array.length; j++) {
        this.Neuropathy_array[j].checked = false;
      }
   
      if (this.Neuro_check != undefined && this.Neuro_check.length != 0) {
        for (var i = 0; i < this.Neuro_check.length; i++) {
          for (var j = 0; j < this.Neuropathy_array.length; j++) {
            if (this.Neuro_check[i] == this.Neuropathy_array[j].value) {
              this.Neuropathy_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Neuropathy",
          main_array: this.Neuropathy_array,
          selected_list:this.Neuro_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.neuro_click(result);
          }
      });
    }
  }

  neph(){
    if(this.client == false){
      this.neph_flag = true;
      for (var j = 0; j < this.Nephropathy_array.length; j++) {
        this.Nephropathy_array[j].checked = false;
      }
  
      if (this.Medical_Check_nephlist != undefined && this.Medical_Check_nephlist.length != 0) {
        for (var i = 0; i < this.Medical_Check_nephlist.length; i++) {
          for (var j = 0; j < this.Nephropathy_array.length; j++) {
            if (this.Medical_Check_nephlist[i] == this.Nephropathy_array[j].value) {
              this.Nephropathy_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Nephropathy",
          main_array: this.Nephropathy_array,
          selected_list:this.Medical_Check_nephlist,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.medneph_click(result);
          }
      });
    }
   }

  skin(){
    if(this.client == false){      
        this.skinflag = true;
        for (var j = 0; j < this.Skin_arry.length; j++) {
          this.Skin_arry[j].checked = false;
        }
    
        if (this.skin_Check_nephlist != undefined && this.skin_Check_nephlist.length != 0) {
          for (var i = 0; i < this.skin_Check_nephlist.length; i++) {
            for (var j = 0; j < this.Skin_arry.length; j++) {
              if (this.skin_Check_nephlist[i] == this.Skin_arry[j].value) {
                this.Skin_arry[j].checked = true;
              }
            }
          }
        }

        const dialogRef = this.dialog.open(DropdownModelpageComponent, {
          width: '500px',
          height: '400px',
          data:{
            title: "Skin Conditions",
            main_array: this.Skin_arry,
            selected_list:this.skin_Check_nephlist,
          } 
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
            this.skin_click(result);
            }
        });
    }
  }

  retino_pathy1(){
    if(this.client == false){
      this.ret_flag = true;
      for (var j = 0; j < this.Retinopathy_arry.length; j++) {
        this.Retinopathy_arry[j].checked = false;
      }

      if (this.Retino_check != undefined && this.Retino_check.length != 0) {
        for (var i = 0; i < this.Retino_check.length; i++) {
          for (var j = 0; j < this.Retinopathy_arry.length; j++) {
            if (this.Retino_check[i] == this.Retinopathy_arry[j].value) {
              this.Retinopathy_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Retinopathy",
          main_array: this.Retinopathy_arry,
          selected_list:this.Retino_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.ret_click(result);
          }
      });
    }
  }

  hear(){
    if(this.client == false){
      for (var j = 0; j < this.Hearing_arry.length; j++) {
        this.Hearing_arry[j].checked = false;
      }

      if (this.hear_Check_nephlist != undefined && this.hear_Check_nephlist.length != 0) {
        for (var i = 0; i < this.hear_Check_nephlist.length; i++) {
          for (var j = 0; j < this.Hearing_arry.length; j++) {
            if (this.hear_Check_nephlist[i] == this.Hearing_arry[j].value) {
              this.Hearing_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Hearing",
          main_array: this.Hearing_arry,
          selected_list:this.hear_Check_nephlist,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.hear_click(result);
          }
      });
    }
  }
  
  mobi_flag1(){
    if(this.client == false){
      this.mobi_flag = true;
      for (var j = 0; j < this.Mobile_arry.length; j++) {
        this.Mobile_arry[j].checked = false;
      }

      if (this.Mobility_check != undefined && this.Mobility_check.length != 0) {
        for (var i = 0; i < this.Mobility_check.length; i++) {
          for (var j = 0; j < this.Mobile_arry.length; j++) {
            if (this.Mobility_check[i] == this.Mobile_arry[j].value) {
              this.Mobile_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Mobile Concerns",
          main_array: this.Mobile_arry,
          selected_list:this.Mobility_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.mob_click(result);
          }
      });
    }
  }

  neuro_click(selected) {
    this.Neuropathy="";
    
    function StringBuilder(this: any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.Neuro_check = selected;

    
    if (this.Neuro_check.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.Neuro_check.length; j++) {
        for (var i = 0; i < this.Neuropathy_array.length; i++) {

          if (this.Neuro_check[j] == this.Neuropathy_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
          
            selected_data.append(this.Neuropathy_array[i].label);

          }

        }
      }
      this.Neuropathy = selected_data.toString();
  }
  }

  cardio_click(selected) {
    // this.Cardio_check = selectedValue; 
    this.cardio_txt="";
    
    function StringBuilder(this:any , value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.Cardio_check = selected;

    
    if (this.Cardio_check.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.Cardio_check.length; j++) {
        for (var i = 0; i < this.Cardiovascular_array.length; i++) {

          if (this.Cardio_check[j] == this.Cardiovascular_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Cardiovascular_array[i].label);

          }

        }
      }
      this.cardio_txt = selected_data.toString();
      


  }
  }

  medneph_click(selected) {
    this.nephropathy="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.Medical_Check_nephlist = selected;

    
    if (this.Medical_Check_nephlist.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.Medical_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Nephropathy_array.length; i++) {

          if (this.Medical_Check_nephlist[j] == this.Nephropathy_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
         
            selected_data.append(this.Nephropathy_array[i].label);

          }

        }
      }
      this.nephropathy = selected_data.toString();


  }

  }

  // Retino select 
  ret_click(selected) {
    // this.Retino_check = selectedValue;
    this.retino_pathy="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.Retino_check = selected;

    
    if (this.Retino_check.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.Retino_check.length; j++) {
        for (var i = 0; i < this.Retinopathy_arry.length; i++) {

          if (this.Retino_check[j] == this.Retinopathy_arry[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Retinopathy_arry[i].label);

          }

        }
      }
      this.retino_pathy = selected_data.toString();


  }

  }

  foot_click(selected) {
  // this.Foot_check = selectedValue;
    this.foot_damage="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.Foot_check = selected;

    
    if (this.Foot_arry.length != 0) {


      var n = 0;

      for (var j = 0; j < this.Foot_check.length; j++) {
        for (var i = 0; i < this.Foot_arry.length; i++) {
         
          if (this.Foot_check[j] == this.Foot_arry[i].value) {

          
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
          
            selected_data.append(this.Foot_arry[i].label);

          }

        }
      }
      this.foot_damage = selected_data.toString();


  }

  }

   // skin select
  skin_click(selected) {
    // this.skin_Check_nephlist = selectedValue;
    this.skin_txt="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.skin_Check_nephlist = selected;

    
    if (this.skin_Check_nephlist.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.skin_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Skin_arry.length; i++) {

          if (this.skin_Check_nephlist[j] == this.Skin_arry[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Skin_arry[i].label);

          }

        }
      }
      this.skin_txt = selected_data.toString();


  }

  }

  // hearing select
  hear_click(selected) {
    // this.hear_Check_nephlist = selectedValue;
    this.hearing_txt="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.hear_Check_nephlist = selected;

    
    if (this.hear_Check_nephlist.length != 0) {
   

      var n = 0;

      for (var j = 0; j < this.hear_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Hearing_arry.length; i++) {

          if (this.hear_Check_nephlist[j] == this.Hearing_arry[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Hearing_arry[i].label);

          }

        }
      }
      this.hearing_txt = selected_data.toString();


  }
  }

  footdamage(){
    if(this.client == false){
      this.footflag = true;
      for (var j = 0; j < this.Foot_arry.length; j++) {
        this.Foot_arry[j].checked = false;
      }
  
      if (this.Foot_check != undefined && this.Foot_check.length != 0) {
        for (var i = 0; i < this.Foot_check.length; i++) {
          for (var j = 0; j < this.Foot_arry.length; j++) {
            if (this.Foot_check[i] == this.Foot_arry[j].value) {
              this.Foot_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Foot Damage",
          main_array: this.Foot_arry,
          selected_list:this.Foot_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.foot_click(result);
          }
      });
    }
  }

  //comlication history
  Complication_click() { }
  playvideo(data){}

  history(){
    this.others_clinical="Complcate_hist"
    const dialogRef1 = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      // data:"Complcate_hist"
      data:{
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.others_clinical,
      }
   });
 
   dialogRef1.afterClosed().subscribe(result => {
     
     //this.dis_txt = result;
   });
  }

  history1(){
   this.others_clinical="Clinical_hist"
  const dialogRef1 = this.dialog.open(DiabeticpopuphistoryComponent, {
    width: '700px',
    height: '300px',
   // data:"Complcate_hist"
   data:{
    Diab_case_hist_id: this.case_hist_id,
Life_style_id: this.life_style_id,
History: this.others_clinical,
}
  });
 
  
 

 
  }
}