<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">User List</h5>
                    </div>
                    <div class="headerButtons">
                        
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="nodata" *ngIf="packageListArray.length == 0">No bill(s) found</p>
                        <table *ngIf="packageListArray.length != 0" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Package name</th>
                                    <th>Package type</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let package of packageListArray; let i = index"  (click)="viewBill(package)">
                                    <td style="font-size: 12px;text-align: center;">
                                       <img [src]="package.image" style="width: 30px; height:30px" alt="">
                                    </td>
                                    <td style="font-size: 12px;text-align: center;">
                                        {{package.first_name}} {{package.middle_name}} {{package.last_name}}
                                    </td>
                                    <td style="font-size: 12px;text-align: center;">
                                        {{package.package_name}}
                                    </td>
                                    <td style="font-size: 12px;text-align: center;">
                                        {{package.pkg_type_desc}}
                                    </td>
                                    <td style="font-size: 12px;text-align: center;">
                                        {{package.price}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>