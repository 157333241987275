<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient registration</h5>
          </div>

          <div class="headerButtons">

            <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="backimgbtn_inpatinfo" /></a>
            <a style="margin:0 5px;" *ngIf="saveFlag"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                class="saveimgbtn_inpatinfo" (click)="registerPatient()" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="information">
          <div class="row">
            <mat-label class="matlabel" style="margin-left: 27px; margin-bottom: 6px;">Profile picture</mat-label>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-2" style="text-align: center;position: relative;">
              <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 mx-3">
                <div class="small-12 medium-2 large-2 columns" *ngIf="!webcamImage">
                  <div class="circle">
                    <img *ngIf="thisshow && !fileRemoved" class="profile-pic" id="profile_img" [src]="imgUrl ? imgUrl : '../../assets/img/default.jpg'">
                    <img *ngIf="thisnot && !fileRemoved" class="profile-pic" id="profile_img" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                    <img *ngIf="!thisshow" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                    <img *ngIf="!thisnot" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">

                  </div>
                  <div [ngClass]="styleOne ? 'p-image' : 'p-image-new'">
                    <button type="button" style="border: none; background: none;" data-bs-toggle="modal" data-bs-target="#profilePicModal">
                      <i class="fa fa-camera upload-button"></i>
                    </button>
                  </div>
                </div>
            
                <!-- <input type="file" (change)="changeFile($event)" name="profile_pic" id="profile_pic"> -->
                <div class="snapshot" *ngIf="webcamImage">
                  <div class="circle">
                    <img *ngIf="thisshow" class="profile-pic2" [src]="webcamImage.imageAsDataUrl" />
                    <img *ngIf="thisnot" class="profile-pic2" [src]="imgUrll" />
                    <img *ngIf="!thisshow" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                    <img *ngIf="!thisnot" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                    <!-- <img [src]="webcamImage.imageAsDataUrl" style="width: 160px;" /> -->
                  </div>
                  <div [ngClass]="styleOne ? 'p-image' : 'p-image-new'">
                    <button type="button" style="border: none; background: none;" data-bs-toggle="modal" data-bs-target="#profilePicModal">
                      <i class="fa fa-camera upload-button"></i>
                    </button>
                  </div>
                </div>
              </div>
            
              <div class="modal fade" id="profilePicModal" tabindex="-1" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Profile picture</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body ">
                      <div class="col-12 text-center">
                        <webcam [height]="200" [width]="200" [trigger]="triggerObservable" (imageCapture)="handleImage($any($event))"></webcam>
                        <div>
                          <button class="button-34 my-2" data-bs-dismiss="modal" (click)="triggerSnapshot();">Click Here and take the Shot</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              <input style="width: 90px;" class="form-control form-control-sm" id="formFileSm" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" [ngStyle]="getImageStyle()" />
            
              
                <!-- <img src="../../../assets/ui_icons/buttons/Back_button1.svg" class="backimgbtn_inpatinfo" (click)="removeFile('image')" /> -->
              </div>
            
              <div class="modal fade" id="idproofPicModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">ID Card</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body ">
                      <div class="col-12 text-center">
                        <webcam [height]="200" [width]="200" [trigger]="triggerObservable2" (imageCapture)="handleImage2($any($event))"></webcam>
                        <div>
                          <button class="button-34 my-2" data-bs-dismiss="modal" (click)="triggerSnapshot2();">Click Here and take the Shot</button>
                        </div>
                        <div class="snapshot" *ngIf="webcamImage2">
                          <!-- <h2>Here is your image!</h2> -->
                          <!-- <img [src]="webcamImage2.imageAsDataUrl" style="width: 160px;" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Salutation
                    <br>
                    <select class="ipcss widthappt" [(ngModel)]="clntSal" (ngModelChange)="changeSalutation($event)">
                      <option value="select" Selected>Select</option>
                      <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">First name
                    <input class="ipcss widthappt" [(ngModel)]="clntFirstName" (blur)="fnameToUpper()" required
                      matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Middle name
                    <input class="ipcss widthappt" [(ngModel)]="clntMiddleName" (blur)="mnameToUpper()" matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Last name
                    <input class="ipcss widthappt" [(ngModel)]="clntLastName" (blur)="lnameToUpper()" required
                      matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">DOB
                    <input type="date" class="ipcss_date noappt_width heightdob" (change)="selectDob(clntDOB)"
                      [(ngModel)]="clntDOB" max="{{currentDatetime}}" required #matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="ageflag">
                  <mat-label class="matlabel">Age
                    <input (keyup)="ChangeAge()" [(ngModel)]="clntAge" [readonly]="ageRead" [required]="!ageRead"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="ipcss widthappt" required
                      matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="!ageflag">
                  <mat-label class="matlabel">Age
                    <input (keyup)="ChangeAge()" [(ngModel)]="clntAge" [readonly]="isReadonly()" [required]="!ageRead"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="ipcss widthappt" required
                      matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Gender<br>
                    <select [(ngModel)]="clntGender" class="ipcss widthappt" [disabled]="retrvDisabledFlag"
                      (ngModelChange)="changeGender($event)">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Mobile
                    <input [(ngModel)]="clntContNumber" maxlength="10" required (blur)="validateMobile()"
                      class="ipcss widthappt" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Emergency contact 1
                    <input [(ngModel)]="clntEmergNumber" maxlength="15" class="ipcss widthappt"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Emergency contact 2
                    <input [(ngModel)]="clntEmergNumber1" maxlength="15" class="ipcss widthappt"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                  </mat-label>
                </div>

                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Aadhaar Number
                    <input [(ngModel)]="aadharNumber" maxlength="12" (blur)="validateAadhar()" class="ipcss widthappt"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                  </mat-label>
                </div>

                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Whatsapp Number
                    <input [(ngModel)]="whatsappNumber" maxlength="10" (blur)="validatewhatsapp()"
                      class="ipcss widthappt" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                  </mat-label>
                </div>


                <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Father / Guardian name
                    <input class="ipcss widthappt" [(ngModel)]="clntFatherOrGuardName" matInput />
                  </mat-label>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Blood Group<br>
                    <mat-select disableOptionCentering class="ipcss" [(ngModel)]="bloodGroup">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let blood of bloodGroupTestArray" [value]="blood.description">
                        {{blood.description}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Primary Language<br>
                    <mat-select class="ipcss" [(ngModel)]="primlanguage">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let language of languageDataArray" [value]="language.language_id">
                        {{language.description}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-6 my-auto">
                  <div class="row">
                    
                    <div class="col-5 margin-bottomvalue">
                      <mat-label class="matlabel ">Willing To Donate Blood</mat-label>
                   </div>
                   <div class="col-2 margin-bottomvalue" style="padding: 0px !important;">
                      <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="blood_donation" ngDefaultControl
                         [checked]="blood_donation" (change)="donateblood($event.source.checked)">
                         <span class="familytab" *ngIf="!blood"><img class="family_toggle_img"
                               src="../../../assets/ui_icons/cross_mark.svg"></span>
                         <span class="familytab" *ngIf="blood"><img class="family_toggle_img"
                               src="../../../assets/ui_icons/tick_mark.svg"></span>
                      </mat-button-toggle>
                   </div>
                  </div>
                  <!-- <mat-label class="matlabel paddingtop">Willing To Donate Blood
                    <button mat-button class="matbutton" (click)="donateblood()" style="border: none;">
                      <label *ngIf="blood"
                        style="color: green;border: 1px solid green; border-radius: 7px;padding: 0 10px;"
                        [(ngModel)]="blood_donation">Yes</label>
                      <label *ngIf="!blood"
                        style="color: red; border: 1px solid red; border-radius: 7px;padding: 0 10px;"
                        [(ngModel)]="blood_donation">No</label>
                    </button>
                  </mat-label> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Address 1
                <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress1" required matInput />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Address 2
                <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress2" matInput />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Zipcode
                <input type="text" class="ipcss widthappt" [(ngModel)]="clntZipcode" (keyup)=zipcode_change()
                  matInput />
              </mat-label>
            </div>



            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Location<br>
                <input type="text" class="ipcss widthappt" required maxlength="50" [(ngModel)]="clntLocation"
                  (keyup)="changeLocation(clntLocation)" matInput />
                <div class="auto_complete_text" *ngIf="clntLocationList.length != 0">
                  <ul *ngFor="let location of clntLocationList">
                    <li>
                      <a (click)="selectLocation(location.location_id)">{{location.description}}</a>
                    </li>
                  </ul>
                </div>
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">District / City<br>
                <input matInput class="ipcss widthappt" [(ngModel)]="clntCity" [disabled]="userTypeFlag"
                  [hidden]="!existUserFlag">
                <mat-select required class="ipcss widthappt" [(ngModel)]="clntCity" [hidden]="existUserFlag"
                  disableOptionCentering (ngModelChange)="changeCity(clntCity, '0')">
                  <mat-option *ngFor="let city of clntCityList" value="{{city.city_desc}}">{{city.city_desc}}
                  </mat-option>
                </mat-select>
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">State<br>
                <input type="text" matInput class="ipcss widthappt" [(ngModel)]="clntState" [disabled]="userTypeFlag"
                  [hidden]="!existUserFlag">
                <mat-select required class="ipcss widthappt" [(ngModel)]="clntState" disableOptionCentering
                  [hidden]="existUserFlag" (ngModelChange)="changeState(clntState, '0')">
                  <mat-option *ngFor="let state of clntStateList" value={{state.state_desc}}>{{state.state_desc}}
                  </mat-option>
                </mat-select>
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Country<br>
                <input type="text" class="ipcss widthappt" [(ngModel)]="clntCountry" [disabled]="userTypeFlag"
                  [hidden]="!existUserFlag">
                <mat-select required [(ngModel)]="clntCountry" class="ipcss widthappt" [hidden]="existUserFlag"
                  disableOptionCentering>
                  <mat-option *ngFor="let country of clntCountryList" value={{country.country_desc}}>
                    {{country.country_desc}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel">Channel<br>
                <select class="ipcss widthappt" [(ngModel)]="ref_source" (change)="changeref(ref_source)">
                  <option *ngFor="let doctor of ref_sourcearray" value="{{doctor.lead_type_id}}">{{doctor.lead_desc}}
                  </option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
              <mat-label class="matlabel"> Referred by<br>
                <input type="text" class="ipcss widthappt" maxlength="50" (keyup)="getReferral($event)"
                  [(ngModel)]="referredDoctor" matInput [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option (click)="selectReferredDoctor(item)" *ngFor="let item of referredDoctorList"
                    value={{item}}>
                    {{item}}
                  </mat-option>
                </mat-autocomplete>
              </mat-label>
            </div>


            <div class="col-8" style="margin-top: 10px;">
              <mat-label class="matlabel">Id proof<br>
                <div class="rectangle">
                  <img *ngIf="thisnota" class="profile-pic2id" [src]="webcamImage2.imageAsDataUrl" />
                  <img *ngIf="thisshowa" class="profile-pic2id" [src]="imgUrl2" />
                  <img *ngIf="!thisshowa" class="profile-pic2id" [src]="imgUrl2 ? imgUrl2 : '../../assets/img/default.jpg'">
                  <img *ngIf="!thisnota" class="profile-pic2id" [src]="imgUrl2 ? imgUrl2 : '../../assets/img/default.jpg'">
                </div>
                <div class="chooseFile-container">
                  <div>
                    <input style="width: 90px;" class="form-control form-control-sm" id="formFileSma"
                    name="imageUrl2" type="file" accept="image/*" (change)="handleInputChange1($event)" />
                    <!-- <img src="../../../assets/ui_icons/buttons/Back_button1.svg" class="backimgbtn_inpatinfo" (click)="removeFile('id')" /> -->

                  </div>
                 
                  <img src="../../../assets/img/camera.svg" data-bs-toggle="modal" data-bs-target="#idproofPicModal"
                    class="camerasimg" (click)="changeidproofimage()">
                    
                </div>
              </mat-label>
            </div>
          </div>
        </div>


        <div [hidden]="true">
          <div #idprint id="idprint">
            <div class="container-fluid m-3">
              <div class="row" style="margin: 0;
              position: absolute;
              top: 50%;
              left: 50%;width: 240px;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              border-style: solid; 
                  border-width: thin; 
                  border-radius: 10px; 
                  border-color: rgb(139, 139, 251); 
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">

                <div class="justify-content-center" style="text-align: center;">
                  <img style="width: 100px; height: 100px; border-radius: 50px; margin: 5px;" [src]='imgUrl'>
                  <h1 style="font-size: 10px; letter-spacing: 4px;  color: rgb(139, 139, 251);">
                    {{patientname}}</h1>
                </div>
                <!-- mt-2 -->
                <div class="d-flex align-items-center justify-content-center " style="letter-spacing: 1px;">
                  <table style="width:100%">
                    <tr>
                      <td
                        style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px;width:50%">
                        DOB</td>
                      <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px">
                        {{dateformate}}</td>
                    </tr>

                    <tr>
                      <td
                        style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px;width:50%">
                        Age</td>
                      <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px">
                        {{clntAge}}</td>
                    </tr>

                    <tr>
                      <td
                        style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px;width:50%">
                        Gender</td>
                      <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px">
                        {{clntGender}}</td>
                    </tr>

                    <tr>
                      <td
                        style="font-size: 10px; display:block;font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px;width:50%">
                        Address</td>
                      <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px">

                        {{fulladdress}} {{clntLocation}}, {{clntCity}}, {{clntState}},
                        {{clntZipcode}}

                      </td>
                    </tr>

                    <tr>
                      <td
                        style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px;width:50%">
                        Mobile No</td>
                      <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:5px">
                        {{clntContNumber}}</td>
                    </tr>
                  </table>

                </div>
                <!-- style="margin: 10px;" -->
                <div class="d-flex align-items-center justify-content-center">
                  <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                    [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                    [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                    [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                    [bc-margin-right]="marginRight"></ngx-barcode>
                </div>
              </div>
            </div>
          </div>
        </div>




      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>