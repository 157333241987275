<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">View prescription</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="inpat_content">
    <div class="container">
        <div class="row" style="max-height: 250px;">
            <div *ngIf="viewpreslist_array.length ==0">
                <p class="nodata">No prescription data found</p>
            </div>
            <div *ngIf="viewpreslist_array.length !=0">
                <div *ngFor="let viewlist of viewpreslist_array">
                    <div class="cover_div">
                        <div class="row">
                            <div class="col-6">
                                <mat-label class="matlabel">{{viewlist.tot_doct_name}}</mat-label>
                            </div>
                            <div class="col-6">
                                <mat-label class="matlabel" style="float:right;">{{viewlist.view_date}}</mat-label>
                            </div>
                            <div class="col-12">
                                <table style="border: 1px solid #06438a;width: 100%;height: 41px;border: 1px solid #06438a;margin: 5px auto;">
                                    <tr *ngFor="let viewmed of viewlist.medicines">
                                        <td style="text-align: left;padding-left: 5px;">{{viewmed.drug}}</td>
                                        <td style="text-align: left;padding-left: 5px;">{{viewmed.catagory}}</td>
                                    </tr>
                                    <tr *ngFor="let rad of data.Radiology">
                                        <td style="text-align: left;padding-left: 5px;">{{rad.drug}}</td>
                                        <td style="text-align: left;padding-left: 5px;">{{rad.catagory}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div style="margin-top: 10px;"
                            *ngIf="labarray.length!=0 || radioarray.length!=0">
                            <p><strong>DX: </strong></p>
                            <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                            <div *ngIf="labarray.length !=0"  style="width: 89%;margin-bottom: 10px;" >
                             <div class="heading"><strong>Lab Tests:</strong></div>
                             <div class="test-names-container">
                                <!-- <p></p> -->
                                <ng-container *ngFor="let test of labarray; let last = last">
                                  <span class="test-name">{{ test.testname }}</span>{{ !last ? ',' : '' }}
                                </ng-container>
                          
                              </div>
                              
                            </div>
                            <div *ngIf="radioarray.length !=0"  style="width: 89%;margin-bottom: 10px;" >
                             <div class="heading"><strong>Radiology Tests:</strong></div>
                             <div class="test-names-container">
                                <ng-container *ngFor="let test of radioarray; let last = last">
                                  <span class="test-name">{{ test.testname }}</span>{{ !last ? ',' : '' }}
                                </ng-container>
                              </div>
                            </div>
                    
                          </div>
                         
                          </div>
                          <div style="margin-top: 10px;"
                            *ngIf="labarraynew.length!=0 ||  radioarraynew.length!=0 ">
                            <p *ngIf="labarraynew.length!=0 || radioarraynew.length!=0 "><strong>Test for next visit:</strong></p>
                            
                            <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                            <div *ngIf="labarraynew.length !=0"  style="width: 89%;margin-bottom: 10px;" >
                             <div class="heading"><strong>Lab Tests:</strong></div>
                             <div class="test-names-container">
                                <!-- <p></p> -->
                                <ng-container *ngFor="let test of labarraynew; let last = last">
                                  <span class="test-name">{{ test.testname }}</span>{{ !last ? ',' : '' }}
                                </ng-container>
                                <!-- <ng-container *ngFor="let profile of selected_profilesnew let last = last">
                                   <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                                 </ng-container> -->
                              </div>
                              
                            </div>
                            <div *ngIf="radioarraynew.length !=0"  style="width: 89%;margin-bottom: 10px;" >
                             <div class="heading"><strong>Radiology Tests:</strong></div>
                             <div class="test-names-container">
                                <ng-container *ngFor="let test of radioarraynew; let last = last">
                                  <span class="test-name">{{ test.testname }}</span>{{ !last ? ',' : '' }}
                                </ng-container>
                              </div>
                            </div>
                            </div>
                        
            
                          </div>   
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>