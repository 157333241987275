<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Supplier payments</h5>
            </div>
            <div class="headerButtons">
              <img *ngIf="editbutton" src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()" [disabled]="isDisabled"
                class="saveimgbtn_inpatinfo" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <mat-label class="matlabel" style="margin-top: 7px;">Location<br>
                <select disableOptionCentering class="ipcss " [(ngModel)]="location">
                  <option disabled>Select</option>
                  <option *ngFor="let pro_type of loadLocationList"
                    value={{pro_type.location_id}}>{{pro_type.location_id}}</option>
                </select>
              </mat-label>
            </div> -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Supplier / distributor name<br>
                    <select disableOptionCentering class="ipcss " [(ngModel)]="suppId" required>
                        <option disabled>Select</option>
                        <option *ngFor="let supplier of supplierList" value={{supplier.supp_id}}>{{supplier.name}}</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <mat-label class="matlabel" style="margin-top: 7px;">From
                <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                   #matInput style="width: 140px;">
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <mat-label class="matlabel" style="margin-top: 7px;">To
                <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                  #matInput style="width: 140px;">
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1">
              <mat-label class="matlabel"><br>
                <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                  (click)="getInvoiceDetails()" />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1" *ngIf="invoicedetails.length != 0">
              <mat-label class="matlabel"><br>
                <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                  (click)="getExcelData('excel')" />
              </mat-label>
            </div>
            <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 my-auto" style="margin-top: 25px !important;margin-bottom: 10px !important;">
              <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png"
                      width="20px" height="20px" (click)="showPaumentHistory(mymodal)" *ngIf="showimage"/>
            </div>
            

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" style="margin-top: 15px !important;margin-bottom: 10px !important;">
              <mat-label class="matlabel" style="margin-top: 7px;"><span style="font-weight: 600;">Invoice amount : </span><b>{{totalAmount}}</b> 
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" style="margin-top: 15px !important;margin-bottom: 10px !important;">
              <mat-label class="matlabel" style="margin-top: 7px;"><span style="font-weight: 600;">Pending amount : </span><b>{{pendingAmount}}</b> 
              </mat-label>
            </div>
          

            <div class="row" [hidden]="showpayflag" >
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
                <mat-label class="matlabel">Amount to pay<br>
                  <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="paid_amount" style="text-align:right;" disabled />
                </mat-label>
              </div> 
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
                <mat-label class="matlabel">Payment type<br>
                  <select class="ipcss " [(ngModel)]="payType" (change)="pay_change()" disableOptionCentering>
                    <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="insurFlag">
                <mat-label class="matlabel">Insurer<br>
                  <select class="ipcss " [(ngModel)]="insurerId" disableOptionCentering>
                    <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="cardFlag">
                <mat-label class="matlabel">Card No <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="cardFlag">
                <mat-label class="matlabel">Holder name<br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="transactionFlag">
                <mat-label class="matlabel">Transaction ID<br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" />
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="chequeFlag">
                <mat-label class="matlabel">Cheque No <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="chequeFlag">
                <mat-label class="matlabel">Bank Name <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                </mat-label>
              </div>
            </div>

            <div class="col-12" *ngIf="invoicedetails.length != 0" style="margin-top: 10px !important;">
              <table *ngIf="invoicedetails.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th style="width: 20%;">Supplier</th>
                    <th style="width: 10%;">Invoice #</th>
                    <th style="width: 10%;">Invoice date</th>
                    <th style="width: 10%;">Due date</th>
                    <th style="width:10%">Lapsed days</th>
                    <th style="width: 10%;">Amount</th>
                    
                    <th style="width: 10%;">Paid</th>
                    <th style="width:10%">Payment</th>
                    <th style="width: 9%;">Paid on</th>
                    
                    <th style="width: 10%;">Balance</th>
                    <th style="width: 10%;">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of invoicedetails">
                    <td class="align_left">{{invoice.name}}</td>
                    <td style="text-align: center !important;">{{invoice.invoice_no}}</td>
                    <td style="text-align: center !important;">{{invoice.invoice_date}}</td>
                    <!-- <td style="text-align: center !important;" [ngStyle]="invoice.dueflag == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{invoice.inv_due_date}}</td> -->

                    <td style="text-align: center !important;" [ngClass]="{'green': invoice.invdiffdays >= 3,'orange': invoice.invdiffdays >= 2 && invoice.invdiffdays < 3,'red': invoice.invdiffdays < 1}">
                      {{ invoice.inv_due_date}} 
                      </td>
                    <td style="text-align: center !important;" [ngClass]="{'green': invoice.invdiffdays >= 3,'orange': invoice.invdiffdays >= 2 && invoice.invdiffdays < 3,'red': invoice.invdiffdays < 1}">{{invoice.lag}}</td>
                    <td style="text-align: right !important;">{{invoice.invoice_amount}}</td>
                    
                    <td style="text-align: right !important;">{{invoice.payment}}</td>
                    <td style="text-align: right !important;" *ngIf="invoice.disableflag">{{invoice.paid_amount}}</td>
                     <td class="align_left" *ngIf="!invoice.disableflag">
                      <input class="ipcss" matInput text-right maxlength="10" style="margin:0;width: 70%;text-align:right;"
                        [(ngModel)]="invoice.paid_amount" (blur)="calculatePaidAmount(invoice)"  />
                    </td>
                    <td style="text-align: right !important;">{{invoice.payment_date}}</td>
                    <td style="text-align: right !important;">{{invoice.balance}}</td>
                    <td style="text-align: center !important;">{{invoice.paid_flag}}</td>
                    <td>
                      <input type="checkbox" name="" id="" [(ngModel)]="invoice.paymentcheckbox" [disabled]="invoice.disableflag" [checked]="accepted" (change)="check_amt_to_payment(invoice)">
                    </td>
                    <!-- <td class="align_left" *ngIf="!invoice.disableflag">
                      <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                        [(ngModel)]="invoice.paid_amount" (blur)="calculatePaidAmount(invoice)" style="text-align:right;" />
                    </td> -->
                    
                    <!-- <td class="align_left">
                      <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                        [(ngModel)]="invoice.balance" style="text-align:right;" /> 
                    </td>-->
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12" *ngIf="invoicedetails.length == 0">
              <p class="nodata">No data found</p>
            </div> 
          </div>

          <ng-template #mymodal let-modal>
            <div class="modal-content" style="width: 150%">
            <div [hidden]="payHistList.length == 0" class="dig_table_overflow">
                <div class="table-responsive">
                    <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th style="width: 125px;">Paid on</th>
                                <th>Payment mode</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pay of payHistList">
                                <td>{{pay.amount}}</td>
                                <td style="width: 125px;">{{pay.payment_date}}</td>
                                <td>{{pay.payment_desc}}</td>
                                <td>{{pay.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </ng-template>

        </mdb-card-body>
      </mdb-card>
    </div>
  </div>