<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Edit bill</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" /></a> &nbsp;
            <a (click)="print_area('print')" *ngIf="printbutton"><img src="../../../assets/img/printer.svg" width="25px" height="25px" style="cursor: pointer;" /></a> &nbsp;
            <a *ngIf="updateFlag" (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin-bottom: 15px;">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Bill No<br>
              <input class="ipcss  widthappt" matInput disabled="true" [(ngModel)]="billId" />
            </mat-label>
          </div>
          
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" >
            <mat-label class="matlabel" >Ratecard<br>
              <select required class="ipcss " [(ngModel)]="rate_card" disableOptionCentering (change)="getConcession()"> 
                <option *ngFor="let RateCard of RateCardArray" value={{RateCard.rate_card_id}}> {{RateCard.rate_card_desc}} </option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  widthappt" matInput [(ngModel)]="patientName" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"><br/>
            <mat-label class="matlabel" style="margin-top: 12px !important;">Settled
                <mat-checkbox color="primary" [(ngModel)]="settled" (change)="handleCheckboxChange('settled')"></mat-checkbox>
            </mat-label>
            &nbsp;&nbsp;
            <mat-label class="matlabel" style="margin-top: 12px !important;">Finalized
                <mat-checkbox color="primary" [(ngModel)]="finalized" (change)="handleCheckboxChange('finalized')"></mat-checkbox>
            </mat-label>
          </div> 

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <select class="ipcss " [(ngModel)]="payType" (change)="pay_change()" disableOptionCentering>
                <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering>
                <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="transactionFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
        </div>
       
        <div class="col-12" *ngIf="createpage_flag">
          <div class="cover_div">
            <div class="header_lable">Patient details</div>
              <div class="content_cover">             
                <div class="row">
                  <div class="col-2" style="text-align: center;">
                    <img src="{{this.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="profileimage">
                  </div>
                  <div class="col-10">
                    <div class="row">                    
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                MR No</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mrno}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Patient name</td>
                              <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patientName}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Age/Gender</td>
                              <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3" *ngIf="mobile_no != undefined && mobile_no != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Mobile</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>      
                      <div class="col-3" *ngIf="dr_name != '' && dr_name != undefined">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Doctor</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{dr_name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-3" *ngIf="admitted_date != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                DOA</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>   
                      <div class="col-3" *ngIf="e_contact != '' && e_contact != undefined">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Emerg contact</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{e_contact}} 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>  
                      <div class="col-3" *ngIf="dept_desc != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Dept</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{dept_desc}} 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>              
                      <div class="col-3" *ngIf="wardName != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                Ward/Bed</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{wardName}} <span *ngIf="bed_no != ''">/</span> {{bed_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                                                                            
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Address</td>
                                  <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{address}} {{location}} {{city}} {{state}}
                                </td>
                              </tr>                 
                            </tbody>
                        </table>
                      </div>        
                    </div>      
                  </div>
                </div>               
              </div>
          </div>
        </div>
       
        <div class="headerButtons" style="border: none;">        
          <img src="../../../assets/ui_icons/Add_icon.svg"  class="saveimgbtn_inpatinfo_add add_align" style="margin-top: 10px !important;" (click)="addNewBillDetails()"/> 
        </div>          
        
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <div class="table-responsive dig_table_overflow" *ngIf="billAddList.length" style="margin-top: 10px;">
              <table  class="table table-hover table-dynamic table-nowrap table-sm billcreae_table bill_edit_table">
                <thead class="tableheading"> 
                  <tr style="height: 25px;">
                    <th style="width: 10%;">Sno</th>
                    <th style="width: 20%">Service</th>
                    <th style="width: 5%" [hidden]="paytypreq">Payment type</th>
                    <th style="width: 6%">Fee</th>
                    <th [hidden]="discount_flag" style="width: 5%">Discount(%)</th>
                    <th [hidden]="bill_qty_flag  && !inpatient_flag" style="width: 6%">Quantity</th>
                    <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 6%">Amount</th>      
                    <th style="width: 10%" class="delete">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of billAddList" class="tdvalues">
                    <td style="font-family: Arial, Helvetica, sans-serif !important;font-size: 13px !important;"> {{person.sno}} </td>
                    <td style="text-align: left;"> 
                      <mat-label>
                        <input type="text" class="ipcss order_width getChargeDesc" style="font-size: 12px !important;" maxlength="50"  
                          (keyup)="getServiceType(person.biill_type)"
                            [(ngModel)]="person.biill_type" required matInput [matAutocomplete]="auto1" [disabled]="person.edit_flag" /> <!--(keyup)="person.biill_type && person.biill_type.length >=2 && handleKeyUp($event,person.biill_type,person)"-->
                        <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                            <mat-option  *ngFor="let charge of serviceType" (click)="changeChargeType1(charge,person)"> 
                                {{charge.description}}
                            </mat-option>
                        </mat-autocomplete>
                      </mat-label>                        
                    </td>
                    <td [hidden]="paytypreq"> {{person.pay_type}} </td>
                    <td [hidden]="!discount_flag" style="font-family: Arial, Helvetica, sans-serif !important;font-size: 13px !important;text-align: right;">  
                     {{person.amount_txt}}
                    </td>
                    <td [hidden]="discount_flag" style="font-family: Arial, Helvetica, sans-serif !important;font-size: 13px !important;text-align: center;">  
                      <input type="text" class="bill_edit_td" style="width: 75%;" 
                      [(ngModel)]="person.amount_txt"
                      #matInput (keyup)="changeamount1(person,0)" />
                     </td>
                    <td [hidden]="discount_flag">                       
                      <input type="text" class="bill_edit_td" style="width: 75%;" 
                      [(ngModel)]="person.concession"
                      #matInput (keyup)="changeamount1(person,0)" /> 
                    </td> 
                    <td [hidden]="bill_qty_flag && !inpatient_flag">  
                      <input type="text" class="bill_edit_td" style="width: 75%;font-size: 13px !important;" [(ngModel)]="person.quantity" (keyup)="changeamount1(person,0)" #matInput />
                    </td>
                    <td [hidden]="multiply_flag" style="font-family: Arial, Helvetica, sans-serif !important;font-size: 13px !important;text-align: right;">
                      {{person.total}}
                    </td>  
                    <td [hidden]="discount_flag" style="font-family: Arial, Helvetica, sans-serif !important;font-size: 13px !important;text-align: right;">
                      <input type="text" class="bill_edit_td" style="width: 75%;font-size: 13px !important;" [(ngModel)]="person.fee" (keyup)="changeamount1(person,2)" #matInput />
                    </td>              
                    <td class="delete">
                      <a (click)="Delete_bill(person)" *ngIf="deletebutton"> 
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" style="cursor: pointer;">
                      </a>
                    </td>                 
                  </tr>
                </tbody>
              </table> 
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 10px;">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="!paytypreq">
              <div class="row">
                <div class="col-2">
                  <mat-label class="matlabel">Credit bill
                      <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                      </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-5" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-6">
                      <select class="ipcss" [(ngModel)]="credit_type" disableOptionCentering>
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}} </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4" [hidden]="credit_flag">
                  <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput maxlength="50" />
                </div>
              </div>
              <div class="row" [hidden]="!credit_flag">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom" [hidden]="!paytypreq">
                  <mat-label class="matlabel">Payment mode<br>
                    <mat-select class="ipcss " [(ngModel)]="payType1" 
                      disableOptionCentering>
                      <mat-option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="!paytypreq">
                  <mat-label class="matlabel">Amount<br>
                    <input class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="12" style="text-align: right;"/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2" [hidden]="!paytypreq">
                  <mat-label class="matlabel ">                  
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align btn-outline-success rounded"
                    (click)="addPaymentDetails()" style="float: right;margin-top: 20px;cursor: pointer;"/>
                  </mat-label>
                </div>
              </div>

              <div class="row rowchanges" [hidden]="!paytypreq">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;" class="payment_array_style">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;text-align: right;" class="payment_array_style">{{pay.amount}}</td>&nbsp;
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-1"></div>
          <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-4">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="include_gst_click(inGST)">
                </mat-checkbox>
              </div>
              <div *ngFor="let gsttax of gstDataList">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                  </div>
                  <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="prevbalanceFlag">
                <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="prevbalanceFlag">
                <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="prevbalance" maxlength="10"
                  disabled />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr"> Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true" [(ngModel)]="totalBillAmount" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr"> Discount <span [hidden]="!discount_flag">({{concession}} %)</span></mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right [(ngModel)]="discount_amount" (keyup)="changeDiscount()"/>
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Bill amount </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="finalAmount" />
              </div>
            
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="advanceFlag">
                <mat-label class="matlabel heightmsr">Advance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="advanceFlag">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="advanceBill" maxlength="10" readonly />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt" matInput text-right (keyup)="paid_change()"
                  [(ngModel)]="paidBill" maxlength="10" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr" >Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right
                  [(ngModel)]="remainingBalance" maxlength="10" />
              </div>
            </div>
          </div>

          <div [hidden]="true" #printbanner id="printbanner">
            <table style="border:'0';width:100%">              
             <thead>
              <tr>
                <th style="width:100%;">                                 
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div style="width: 100%; height: 205px !important;"> 
                      <div style="width: 100%;">
                        <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                      </div>                                                             
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 0px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{patientName}} </td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{address}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{location}} {{city}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td>: {{dr_name}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{state}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{created_date}}</td>
                          </tr>
                          <tr *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                                                   
                          </tr>                         
                          <tr *ngIf="credit_type != undefined && credit_type != ''">
                            <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                            <td>: {{credit_person}}</td>
                          </tr>                        
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>
                            </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td> 
                          </tr>                         
                        </tbody>
                      </table>
                    </div>
                  </div>               
                </th>
              </tr>
             </thead>
             <tbody >  
               <tr>  
                 <td width="100%">  
                  <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                  <div class="bill_border">
                    <div>
                      <div *ngIf="billAddList.length != 0" style="margin-left: 20px;margin-right: 10px;">
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                            <thead>
                              <tr>
                                <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                                <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let billprinttable of billAddList">
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="sno">{{billprinttable.sno}}</td>
                                <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type">{{billprinttable.biill_type}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                <td [style]="amount_td_style"
                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td><!--style="text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px"-->
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td> 
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                              </tr>
                            </tbody>                                                     
                          </table>
                        </div>
                      </div>            
                      <div style="width: 100%;">
                        <table  style="margin-bottom: -20px;">
                          <tr>
                            <td style="vertical-align: top; width: 26%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                </tr>
                              </table>            
                            </td>                           
                            <td style="vertical-align: top;width: 31%;">
                              <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                <tr [hidden]="gstDataList.length==0" *ngFor="let gsttax of gstDataList">
                                  <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">{{gsttax.Gst_amount}}
                                    </td>
                                </tr>        
                                <tr [hidden]="prevbalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevbalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == finalAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="discount_amount === '0.00'">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{finalAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag || advanceBill == '0.00'">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advanceBill}}</td>
                                </tr>
                                <tr [hidden]="paidBill == finalAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paidBill}}</td>
                                </tr>
                                <tr [hidden]="paidBill == finalAmount || remainingBalance == '0.00'">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{remainingBalance}}</td>
                                </tr> 
                              </table>
                              <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                <tr [hidden]="gstDataList.length==0" *ngFor="let gsttax of gstDataList">
                                  <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">{{gsttax.Gst_amount}}
                                    </td>
                                </tr>     
                                <tr [hidden]="prevbalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevbalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == finalAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr [hidden]="discount_amount === '0.00'">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{finalAmount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag || advanceBill == '0.00'">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advanceBill}}</td>
                                </tr>
                                <tr [hidden]="paidBill == finalAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paidBill}}</td>
                                </tr>
                                <tr [hidden]="paidBill == finalAmount || remainingBalance == '0.00'">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{remainingBalance}}</td>
                                </tr> 
                              </table>
                            </td>                        
                          </tr>
                        </table>
                      </div>
                      <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                        <div class="row">
                          <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paidBill != '0.00'">
                            <tr>
                              <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>                           
                 </td>
               </tr>
             </tbody>
             <tfoot style="margin-bottom: 10px;"> 
              <tr>  
                <td width="100%">  
                  <table style="width: 100%;">  
                    <tr>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: left !important;margin-left: 15px;">
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        </table> 
                      </td>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <tr>
                            <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                              <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                            </div>                       
                          </tr>
                        </table>                      
                      </td>
                    </tr>  
                  </table> 
                </td>
              </tr> 
             </tfoot>    
            </table>
          </div>

          <div [hidden]="true" #printnoheader id="printnoheader">
            <table style="border:'0';width:100%;">  
              <thead>
                <tr>
                  <th style="width:100%;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div [style]="nobanner_style">
                        <div style="height: 5px !important;"></div>
                        <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                          <tbody>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                              <td>: {{patientName}} </td>
                              <td style="padding-left: 5px;"><strong>Address</strong></td>
                              <td>: {{address}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                              <td>: {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{location}} {{city}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                              <td>: {{dr_name}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{state}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                              <td>: {{billId}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                              <td>: {{created_date}}</td>
                            </tr>
                            <tr *ngIf="surgery != undefined && surgery != ''">
                              <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                              <td>: {{surgery}}</td>                                                   
                            </tr>                         
                            <tr *ngIf="credit_type != undefined && credit_type != ''">
                              <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                              <td>: {{credit_person}}</td>
                            </tr>                        
                            <tr>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td>
                              </td> 
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td *ngIf="barcode_flag">
                                <img [style]="barcode_style"
                                  src={{barcode_url}} /></td> 
                            </tr>                         
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%;" >  
                <tr>  
                  <td width="100%">  
                    <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                    <div class="bill_border">
                      <div>
                        <div *ngIf="billAddList.length != 0" style="margin-left: 20px;margin-right: 10px;">
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                              <thead>
                                <tr>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                  <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                                  <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                  <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                  <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let billprinttable of billAddList">
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="sno">{{billprinttable.sno}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type">{{billprinttable.biill_type}}</td>
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                  <td [style]="amount_td_style"
                                    data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td><!--style="text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px"-->
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                  <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                    data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td> 
                                  <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                    data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                </tr>
                              </tbody>                                                     
                            </table>
                          </div>
                        </div>            
                        <div style="width: 100%;">
                          <table  style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;">
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>            
                              </td>                           
                              <td style="vertical-align: top;width: 31%;">
                                <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                  <tr [hidden]="gstDataList.length==0" *ngFor="let gsttax of gstDataList">
                                    <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">{{gsttax.Gst_amount}}
                                      </td>
                                  </tr>        
                                  <tr [hidden]="prevbalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevbalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == finalAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount === '0.00'">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{finalAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advanceBill == '0.00'">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advanceBill}}</td>
                                  </tr>
                                  <tr [hidden]="paidBill == finalAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paidBill}}</td>
                                  </tr>
                                  <tr [hidden]="paidBill == finalAmount || remainingBalance == '0.00'">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{remainingBalance}}</td>
                                  </tr> 
                                </table>
                                <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                  <tr [hidden]="gstDataList.length==0" *ngFor="let gsttax of gstDataList">
                                    <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                    <td style="text-align: right; width: 100px;" data-th="Central GST"
                                      class="fee_txt">{{gsttax.Gst_amount}}
                                      </td>
                                  </tr>     
                                  <tr [hidden]="prevbalanceFlag" > 
                                    <th style="text-align: left;">Previous balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">{{prevbalance}}</td>
                                  </tr>  
                                  <tr [hidden]="totalBillAmount == finalAmount">
                                    <th style="text-align: left;">Total (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{totalBillAmount}}</td>
                                  </tr>
                                  <tr [hidden]="discount_amount === '0.00'">
                                    <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{discount_amount}}</td>
                                  </tr>
                                  <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                      class="fee_txt">
                                      {{finalAmount}}</td>
                                  </tr>
                                  <tr [hidden]="advanceFlag || advanceBill == '0.00'">
                                    <th style="text-align: left;">Advance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{advanceBill}}</td>
                                  </tr>
                                  <tr [hidden]="paidBill == finalAmount">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{paidBill}}</td>
                                  </tr>
                                  <tr [hidden]="paidBill == finalAmount || remainingBalance == '0.00'">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                      {{remainingBalance}}</td>
                                  </tr> 
                                </table>
                              </td>                        
                            </tr>
                          </table>
                        </div>
                        <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paidBill != '0.00'">
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>                           
                  </td>
                </tr>
              </tbody>
              <tfoot style="margin-bottom: 10px;"> 
                <tr>  
                  <td width="100%">  
                    <table style="width: 100%;">  
                      <tr>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: left !important;margin-left: 15px;">
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </table> 
                        </td>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <tr>
                              <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                              </div>                       
                            </tr>
                          </table>                      
                        </td>
                      </tr>  
                    </table> 
                  </td>
                </tr> 
              </tfoot>  
            </table>
          </div> 
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>