<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Upload</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" class="saveimgbtn_inpatinfo" (click)="createPdf()" src="../../../assets/ui_icons/buttons/save_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="back()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label style="margin-top: 7px;" class="matlabel">Relation<br>
              <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="relationId">
                <mat-option disabled>Select</mat-option>
                <mat-option *ngFor="let rel of relationList" (click)="changeRelation(rel.relation_id)"
                  value={{rel.relation_id}}>{{rel.relationship_name}}
                </mat-option>
              </mat-select>
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label style="margin-top: 7px;" class="matlabel">First Name<br>
              <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="fName">
                <mat-option disabled>Select</mat-option>
                <mat-option *ngFor="let rel of relationData" (click)="changeFName(fName)" value={{rel.name}}>
                  {{rel.name}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label style="margin-top: 7px;" class="matlabel">Middle Name
              <input type="text" class="ipcss widthappt" [(ngModel)]="mName" readonly=true matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label style="margin-top: 7px;" class="matlabel">Last Name
              <input type="text" class="ipcss widthappt" [(ngModel)]="lName" readonly=true matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label style="margin-top: 7px;" class="matlabel">Report Type<br>
              <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="docType"
                (ngModelChange)="changeDocumentType(docType)">
                <mat-option [ngValue]="Select">Select</mat-option>
                <mat-option *ngFor="let rep of reportList" value={{rep.document_type_id}}>{{rep.description}}
                </mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Report Name
              <input type="text" class="ipcss widthappt" [(ngModel)]="reportName" required matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Disease
              <input type="text" class="ipcss widthappt" [(ngModel)]="disease" matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Document Date
              <input type="date" class="ipcss" id="appt_date" (change)="changeDocumentDate($event,documentDate)"
                [(ngModel)]="documentDate" max="{{currentDate}}" #matInput>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Specialization<br>
              <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="splId">
                <mat-option disabled>Select</mat-option>
                <mat-option *ngFor="let spl of splList" value={{spl.spl_id}}>{{spl.spl_name}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="position: relative;">
            <mat-label class="matlabel">
              <input type="file" class="ipcss" (change)="fileChange($event)" name="filUpload" id="file" />
              <label class="closeoption" (change)="fileChange($event)" for="file"
                style="position: absolute; top: 27px;width: 100%;"><span class="excr_confirm matlabel"><i
                    class="fa fa-upload" aria-hidden="true"></i> Upload Your Document</span> </label>
            </mat-label>
          </div>
        </div>
        <div class="row" style="margin-top: 20px;
    ">
          <div class="col-12">
            <div *ngFor="let photo of photos">
              <img id="selected_img" src="{{photo}}" />
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
          aria-labelledby="loginmodel_header" aria-hidden="true">
          <div class="modal-dialog" style="width: 25%;top: 20%;">
            <div class="modal-content">
              <div class="modal-header" style="padding:0px;background: #51b0e7;">
                <div style="width: 100%;color: #fff;">
                  <h3 class="modal-title" id="loginmodel_header">Confirm</h3>
                </div>
                <button type="button" class="model_close" data-bs-dismiss="modal" aria-label="Close"><img
                    src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:20px"></button>
              </div>
              <div class="modal-body">
                 Would you like to enter Medical prescription
              </div>
              <div class="modal-footer">
                <div class="closeoption">
                  <span (click)=showWriterxModel() class="excr_confirm1">YES</span> 
                  <span  class="conform_no" data-bs-dismiss="modal" aria-label="Close">NO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>