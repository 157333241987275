import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Http, Headers } from '@angular/http';
import { CommonDataService } from '../../providers/common-data.service';
import { Nurse_Helper } from '../../Nurse_module/Nurse_Helper';
import { Date_Formate } from '../../../assets/js/common';
import { InpatMedicineTrackerComponent } from '../../Nurse_module/inpat-medicine-tracker/inpat-medicine-tracker.component';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-medicine-track-history',
  templateUrl: './medicine-track-history.component.html',
  styleUrls: ['./medicine-track-history.component.scss']
})
export class MedicineTrackHistoryComponent implements OnInit {
  public subRelId;
  public relationId;
  public medicineHistoryList=[];
  public clientRegId;
  public isNurse;

  constructor(public dialog:MatDialog,public http:Http,public comm_Data: CommonDataService,public dialogRef: MatDialogRef<MedicineTrackHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      dialogRef.disableClose = true;
      if(data.sub_rel_id != null && data.sub_rel_id !="" 
      && data.sub_rel_id != undefined && data.sub_rel_id != "0"){
        this.subRelId = data.sub_rel_id;
      }
      if(data.relation_id != null){
        this.relationId = data.relation_id;
      }
      if(data.flow == "client"){
        this.isNurse = undefined;
        this.clientRegId = this.comm_Data.Client_login_detail_data.Client_Id;
      }else{
        if(data.flow == "nurse"){
          this.isNurse = "nurse";
          this.clientRegId = Nurse_Helper.getINPatient().client_reg_id;
        }
      } 
      this.medicineTrackerHistory();
  }

  ngOnInit(): void {
  }

  medicineTrackerHistory(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/mthist',
      JSON.stringify({
        client_reg_id:this.clientRegId,
        relation_id:this.relationId,
        sub_rel_id:this.subRelId,      
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.medicine_intakes != null) {
            for (var i = 0; i < obj.medicine_intakes.length; i++) {
               this.medicineHistoryList.push({
                    drug_id: obj.medicine_intakes[i].drug_id,
                    drug_name:obj.medicine_intakes[i].drug_name,
                    quantity: obj.medicine_intakes[i].quantity,
                    intake: obj.medicine_intakes[i].intake,
                    created_date:Date_Formate(obj.medicine_intakes[i].created_date),
                    created_time:(obj.medicine_intakes[i].created_time)
                });
            }
        }
        },error => {})
  }

  close(){
    this.dialogRef.close();
    if(this.isNurse == "nurse"){
      const dialogRef5 = this.dialog.open(InpatMedicineTrackerComponent, {
        width: '700px',
        height: 'auto',
        data:{
          relation_id: "1",
          trackerFlag: "medicine"
        }
      });
      dialogRef5.afterClosed().subscribe(result => {
      });
    }
  }
}
