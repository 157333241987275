import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ipaddress } from 'src/app/ipaddress';
import { masterCSData_Helper } from './masterCSData_Helper';

@Injectable({ providedIn: 'root' })
export class MasterCSHelperService {

  constructor(public http:HttpClient){}

  clearValue(){
    masterCSData_Helper.setMasterMainConcern(undefined);
    masterCSData_Helper.setMasterMedicalHistory(undefined);
    masterCSData_Helper.setMasterReligiousOtherBeliefs(undefined);
    masterCSData_Helper.setMasterChiefComplients(undefined);
    masterCSData_Helper.setMasterBrushType(undefined);
    masterCSData_Helper.setMasterBrushMate(undefined);
    masterCSData_Helper.setMasterIntakeSession(undefined);
    masterCSData_Helper.setMasterExercise(undefined);
    masterCSData_Helper.setMasterExerDuration(undefined);
    masterCSData_Helper.setMasterExerRoutine(undefined);
    masterCSData_Helper.setMasterQunatity(undefined);
    masterCSData_Helper.setMasterMixingtype(undefined);
    masterCSData_Helper.setMasterSpecilizationType(undefined);
    masterCSData_Helper.setMasterYogaType(undefined);
    masterCSData_Helper.setMasterMobility(undefined);
    masterCSData_Helper.setMasterHearing(undefined);
    masterCSData_Helper.setMasterSkin(undefined);
    masterCSData_Helper.setMasterFoot(undefined);
    masterCSData_Helper.setMasterRetino(undefined);
    masterCSData_Helper.setMasterNephro(undefined);
    masterCSData_Helper.setMasterNeuro(undefined);
    masterCSData_Helper.setMasterCardiovasular(undefined);
    masterCSData_Helper.setMasterDiabTreatmentType(undefined);
    masterCSData_Helper.setMasterGynoDisease(undefined);
    masterCSData_Helper.setMasterFoodType(undefined);
    masterCSData_Helper.setMasterICDChapter(undefined);
    masterCSData_Helper.setMasterGestationstage(undefined);
    masterCSData_Helper.setMasterModeofdelivery(undefined);
    masterCSData_Helper.setMasterReasonCsection(undefined);
    masterCSData_Helper.setMasterAgeGroup(undefined);
    masterCSData_Helper.setMasterCognitiveDevelopment(undefined);
    masterCSData_Helper.setMasterLanguageDevelopment(undefined);
    masterCSData_Helper.setMasterImmunizationData(undefined);
    masterCSData_Helper.setMasterPregnancyRelatedCondition(undefined);
    masterCSData_Helper.setMasterPregnancyMedicalCondition(undefined);
    masterCSData_Helper.setMasterTypeContraceptive(undefined);
    masterCSData_Helper.setMasterChestpainRadiations(undefined);
    masterCSData_Helper.setMasterChestpainAgrelFactors(undefined);
    masterCSData_Helper.setMasterChestpainAssociatedSymptoms(undefined);
    masterCSData_Helper.setMasterChestpainCauses(undefined);
    masterCSData_Helper.setMasterBreathingTriggers(undefined);
    masterCSData_Helper.setMasterBreathingOnsets(undefined);
    masterCSData_Helper.setMasterPalpitationTypes(undefined);
    masterCSData_Helper.setMasterMucusTypes(undefined);
    masterCSData_Helper.setMasterChestpainLocationDetails(undefined);
    masterCSData_Helper.setMasterChestpainCharacters(undefined);

    masterCSData_Helper.setMasterSkinAbnormalities(undefined);
    masterCSData_Helper.setMasterGynoFoot(undefined);
    masterCSData_Helper.setMasterAnemia(undefined);
    masterCSData_Helper.setMasterFetuspresentation(undefined);
    masterCSData_Helper.setMasterTypeEmergency(undefined);
    masterCSData_Helper.setMasterComplicationDelivery(undefined);
    masterCSData_Helper.setMasterComplicationPostpartum(undefined);
    masterCSData_Helper.setMasterPediaConcerns(undefined);
    masterCSData_Helper.setMasterChildhabits(undefined);

    masterCSData_Helper.setMasterMaternalIllness(undefined);
    masterCSData_Helper.setMasterFoodMenu(undefined);
    masterCSData_Helper.setMasterNephroDisease(undefined);
    masterCSData_Helper.setMasterNephroTreatment(undefined);
    masterCSData_Helper.setMasterAbdomenType(undefined);
    masterCSData_Helper.setMasterRespiratoryType(undefined);
    masterCSData_Helper.setMasterCausesType(undefined);
    masterCSData_Helper.setMasterComplicationsType(undefined);
    masterCSData_Helper.setMasterDurationSymptom(undefined);
    masterCSData_Helper.setMasterGynoPresentIllness(undefined);
    masterCSData_Helper.setMasterGynoStage(undefined);
    masterCSData_Helper.setMasterCharacterofpain(undefined);
    masterCSData_Helper.setMasterFrequency(undefined);
    masterCSData_Helper.setMasterInfection(undefined);
    masterCSData_Helper.setMastergetReportTypes(undefined);
    masterCSData_Helper.setMasterReliFactor(undefined);
    masterCSData_Helper.setMasterSplSpeciDisease(undefined);
    masterCSData_Helper.setMasterAreaSpecificDisease(undefined);
    masterCSData_Helper.setMasterAgeSpecificDisease(undefined);
    masterCSData_Helper.setMasterGenderSpecificDisease(undefined);
    masterCSData_Helper.setMastergetOccupationalhazards(undefined);
    masterCSData_Helper.setMastergetRatingType(undefined);
    masterCSData_Helper.setMasterUneruptedTeeth(undefined);
    masterCSData_Helper.setMasterGingivalColor(undefined);
    masterCSData_Helper.setMasterCircumOralMusTone(undefined);
    
    masterCSData_Helper.setMasterLipPosture(undefined);
    masterCSData_Helper.setMasterChinPosition(undefined);
    masterCSData_Helper.setMasterNasoLabelAngle(undefined);
    masterCSData_Helper.setMasterMentolabialSclcus(undefined);
    masterCSData_Helper.setMasterFacialProfile(undefined);
    masterCSData_Helper.setMasterShapeofHead(undefined);
    masterCSData_Helper.setMasterFacialForm(undefined);
    masterCSData_Helper.setMasterFacialDivergence(undefined);

    masterCSData_Helper.setMasterFacialSymmetry(undefined);
    masterCSData_Helper.setMasterLip(undefined);
    masterCSData_Helper.setMasterMolarRight(undefined);
    masterCSData_Helper.setMasterTypeofHabit(undefined);
    masterCSData_Helper.setMasterChildhoodDisease(undefined);
    masterCSData_Helper.setMasterUpperType(undefined);
    masterCSData_Helper.setMasterExtraOralTmj(undefined);
    masterCSData_Helper.setMasterExtraOralCondition(undefined);
    masterCSData_Helper.setMasterShapeofTeeth(undefined);
    masterCSData_Helper.setMasterColorofTeeth(undefined);

    masterCSData_Helper.setMasterConsistencyofTeeth(undefined);
    masterCSData_Helper.setMasterConditionofTeeth(undefined);
    masterCSData_Helper.setMasterMedicationDeptReferral(undefined);
    masterCSData_Helper.setMasterMaterialData(undefined);
  }

  public getData(url){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json;');
    return this.http.get( ipaddress.getIp.toString() + url, { headers: headers });
  }
  
  public PostData(url, data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(ipaddress.getIp.toString() + url, data, { headers: headers });
  }

  async getMainconcern(){
    await this.getData('gen/mcorn').toPromise().then(
      (data) => masterCSData_Helper.setMasterMainConcern(JSON.parse(JSON.stringify(data))));
  }

  async getMedicalHistory() {
    await this.getData('gen/pmedhis').toPromise().then(
      (data) => masterCSData_Helper.setMasterMedicalHistory(JSON.parse(JSON.stringify(data))));
  }

  async getReligiousOtherBeliefs() {
    await this.getData('gen/relblf').toPromise().then(
      (data) => masterCSData_Helper.setMasterReligiousOtherBeliefs(JSON.parse(JSON.stringify(data))));
  }

  async getChiefComplients(){
    await this.getData('dental/chcomp').toPromise().then(
      (data) => masterCSData_Helper.setMasterChiefComplients(JSON.parse(JSON.stringify(data))));
  }

  async getBrushType() {
    await this.getData('dental/brush').toPromise().then(
      (data) => masterCSData_Helper.setMasterBrushType(JSON.parse(JSON.stringify(data))));
  }

  async getBrushMateArray() {
    await this.getData('dental/dbmat').toPromise().then(
      (data) => masterCSData_Helper.setMasterBrushMate(JSON.parse(JSON.stringify(data))));
  }

  async getIntakeSession() {
    await this.getData('prescription/dintypes/').toPromise().then(
      (data) => masterCSData_Helper.setMasterIntakeSession(JSON.parse(JSON.stringify(data))));
  }

  async getExercise() {
    await this.getData('gen/exes').toPromise().then(
      (data) => masterCSData_Helper.setMasterExercise(JSON.parse(JSON.stringify(data))));
  }

  async getDuration() {
    await this.getData('gen/exes').toPromise().then(
      (data) => masterCSData_Helper.setMasterExerDuration(JSON.parse(JSON.stringify(data))));
  }

  async getExerciseRoutine() {
    await this.getData('gen/exesrot/').toPromise().then(
      (data) => masterCSData_Helper.setMasterExerRoutine(JSON.parse(JSON.stringify(data))));
  }

  async getQuantity() {
    await this.getData('dental/aqty').toPromise().then(
      (data) => masterCSData_Helper.setMasterQunatity(JSON.parse(JSON.stringify(data))));
  }

  async getMixingtype() {
    await this.getData('dental/amix').toPromise().then(
      (data) => masterCSData_Helper.setMasterMixingtype(JSON.parse(JSON.stringify(data))));
  }

  async getSpecilizationType() {
    await this.getData('usercontroller/getspecialization/').toPromise().then(
      (data) => masterCSData_Helper.setMasterSpecilizationType(JSON.parse(JSON.stringify(data))));
  }

  async getYogaType() {
    await this.getData('gen/ysna').toPromise().then(
      (data) => masterCSData_Helper.setMasterYogaType(JSON.parse(JSON.stringify(data))));
  }

  async getMobility() {
    await this.getData('gen/mdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterMobility(JSON.parse(JSON.stringify(data))));
  }

  async getHearing() {
    await this.getData('gen/hdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterHearing(JSON.parse(JSON.stringify(data))));
  }

  async getSkin() {
    await this.getData('gen/sdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterSkin(JSON.parse(JSON.stringify(data))));
  }

  async getFoot() {
    await this.getData('gen/fdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterFoot(JSON.parse(JSON.stringify(data))));
  }

  async getRetinopathy() {
    await this.getData('gen/retino').toPromise().then(
      (data) => masterCSData_Helper.setMasterRetino(JSON.parse(JSON.stringify(data))));
  }
  
  async getNephropathy() {
    await this.getData('gen/nephro').toPromise().then(
      (data) => masterCSData_Helper.setMasterNephro(JSON.parse(JSON.stringify(data))));
  }

  async getNeuropathy() {
    await this.getData('gen/neuro').toPromise().then(
      (data) => masterCSData_Helper.setMasterNeuro(JSON.parse(JSON.stringify(data))));
  }

  async getCardiovascular() {
    await this.getData('gen/cardio').toPromise().then(
      (data) => masterCSData_Helper.setMasterCardiovasular(JSON.parse(JSON.stringify(data))));
  }

  async getDiab_tratment_type() {
    await this.getData('diab/dtypes').toPromise().then(
      (data) => masterCSData_Helper.setMasterDiabTreatmentType(JSON.parse(JSON.stringify(data))));
  }

  async getDiseases() {
    await this.getData('goi/gdis/').toPromise().then(
      (data) => masterCSData_Helper.setMasterGynoDisease(JSON.parse(JSON.stringify(data))));
  }

  async getFoodType() {
    await this.getData('lifestyle/fcat').toPromise().then(
      (data) => masterCSData_Helper.setMasterFoodType(JSON.parse(JSON.stringify(data))));
  }

  async getChapter() {
    await this.getData('icd/chapter/').toPromise().then(
      (data) => masterCSData_Helper.setMasterICDChapter(JSON.parse(JSON.stringify(data))));
  }

  async getGestationage() {
    await this.getData('goi/gstage/').toPromise().then(
      (data) => masterCSData_Helper.setMasterGestationstage(JSON.parse(JSON.stringify(data))));
  }

  async getModeofdelivery() {
    await this.getData('goi/lbr/').toPromise().then(
      (data) => masterCSData_Helper.setMasterModeofdelivery(JSON.parse(JSON.stringify(data))));
  }

  async getReasonCsection() {
    await this.getData('goi/csr/').toPromise().then(
      (data) => masterCSData_Helper.setMasterReasonCsection(JSON.parse(JSON.stringify(data))));
  }

  async getAgeGroup() {
    await this.getData('pedia/pamst').toPromise().then(
      (data) => masterCSData_Helper.setMasterAgeGroup(JSON.parse(JSON.stringify(data))));
  }

  async getCognitiveDevelopment(){
    await this.getData('pedia/cdev').toPromise().then(
      (data) => masterCSData_Helper.setMasterCognitiveDevelopment(JSON.parse(JSON.stringify(data))));
  }

  async getLanguageDevelopment(){
    await this.getData('pedia/ldev').toPromise().then(
      (data) => masterCSData_Helper.setMasterLanguageDevelopment(JSON.parse(JSON.stringify(data))));
  }

  async getImmunizationData() {
    await this.getData('pedia/vaclist').toPromise().then(
      (data) => masterCSData_Helper.setMasterImmunizationData(JSON.parse(JSON.stringify(data))));
  }

  async getPregnancyRelatedCondition() {
    await this.getData('goi/pmcond').toPromise().then(
      (data) => masterCSData_Helper.setMasterPregnancyRelatedCondition(JSON.parse(JSON.stringify(data))));
  }

  async getPregnancyMedicalCondition() {
    await this.getData('goi/gpissue').toPromise().then(
      (data) => masterCSData_Helper.setMasterPregnancyMedicalCondition(JSON.parse(JSON.stringify(data))));
  }

  async getTypeContraceptive() {
    await this.getData('goi/cceptive/').toPromise().then(
      (data) => masterCSData_Helper.setMasterTypeContraceptive(JSON.parse(JSON.stringify(data))));
  }

  async getChestpainLocationDetails() {
    await this.getData('cardio/painloc').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainLocationDetails(JSON.parse(JSON.stringify(data))));
  }

  async getChestpainCharacters() {
    await this.getData('cardio/paincar').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainCharacters(JSON.parse(JSON.stringify(data))));
  }

  async getChestpainRadiations() {
    await this.getData('cardio/painrad').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainRadiations(JSON.parse(JSON.stringify(data))));
  }
  
  async getChestpainAgrelFactors() {
    await this.getData('cardio/painagrel').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainAgrelFactors(JSON.parse(JSON.stringify(data))));
  }

  async getChestpainAssociatedSymptoms() {
    await this.getData('cardio/painasympt').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainAssociatedSymptoms(JSON.parse(JSON.stringify(data))));
  }
  
  async getChestpainCauses() {
    await this.getData('cardio/paincause').toPromise().then(
      (data) => masterCSData_Helper.setMasterChestpainCauses(JSON.parse(JSON.stringify(data))));
  }

  async getBreathingTriggers() {
    await this.getData('cardio/btrig').toPromise().then(
      (data) => masterCSData_Helper.setMasterBreathingTriggers(JSON.parse(JSON.stringify(data))));
  }

  async getBreathingOnsets() {
    await this.getData('cardio/bonset').toPromise().then(
      (data) => masterCSData_Helper.setMasterBreathingOnsets(JSON.parse(JSON.stringify(data))));
  }

  async getPalpitationTypes() {
    await this.getData('cardio/palps').toPromise().then(
      (data) => masterCSData_Helper.setMasterPalpitationTypes(JSON.parse(JSON.stringify(data))));
  }

  async getMucusTypes() {
    await this.getData('cardio/mucs').toPromise().then(
      (data) => masterCSData_Helper.setMasterMucusTypes(JSON.parse(JSON.stringify(data))));  
  }

  async getSkinAbnormalities() {
    await this.getData('goi/sabnorm/').toPromise().then(
      (data) => masterCSData_Helper.setMasterSkinAbnormalities(JSON.parse(JSON.stringify(data))));  
  }

  async getGynoFoot() {
    await this.getData('goi/gfoot/').toPromise().then(
      (data) => masterCSData_Helper.setMasterGynoFoot(JSON.parse(JSON.stringify(data))));  
  }

  async getAnemia() {
    await this.getData('goi/ganae/').toPromise().then(
      (data) => masterCSData_Helper.setMasterAnemia(JSON.parse(JSON.stringify(data))));  
  }

  async getFetuspresentation() {
    await this.getData('goi/fpres/').toPromise().then(
      (data) => masterCSData_Helper.setMasterFetuspresentation(JSON.parse(JSON.stringify(data))));  
  }

  async getTypeEmergency() {
    await this.getData('goi/gpemer/').toPromise().then(
      (data) => masterCSData_Helper.setMasterTypeEmergency(JSON.parse(JSON.stringify(data))));  
  }

  async getComplicationDelivery() {
    await this.getData('goi/dcomp/').toPromise().then(
      (data) => masterCSData_Helper.setMasterComplicationDelivery(JSON.parse(JSON.stringify(data))));  
  }

  async getComplicationPostpartum() {
    await this.getData('goi/ppcomp/').toPromise().then(
      (data) => masterCSData_Helper.setMasterComplicationPostpartum(JSON.parse(JSON.stringify(data))));  
  }

  async getPediaConcerns() {
    await this.getData('pedia/conc').toPromise().then(
      (data) => masterCSData_Helper.setMasterPediaConcerns(JSON.parse(JSON.stringify(data))));  
  }

  async getChildhabits() {
    await this.getData('pedia/chabit').toPromise().then(
      (data) => masterCSData_Helper.setMasterChildhabits(JSON.parse(JSON.stringify(data))));  
  }

  async getMaternalIllness() {
    await this.getData('pedia/mill').toPromise().then(
      (data) => masterCSData_Helper.setMasterMaternalIllness(JSON.parse(JSON.stringify(data))));  
  }

  async getFoodMenu(){
    await this.getData('lifestyle/gmn').toPromise().then(
      (data) => masterCSData_Helper.setMasterFoodMenu(JSON.parse(JSON.stringify(data))));  
  }

  async getNephroDisease() {
    await this.getData('neph/ndis').toPromise().then(
      (data) => masterCSData_Helper.setMasterNephroDisease(JSON.parse(JSON.stringify(data))));  
  }

  async getNephroTreatment() {
    await this.getData('neph/ntreat').toPromise().then(
      (data) => masterCSData_Helper.setMasterNephroTreatment(JSON.parse(JSON.stringify(data))));  
  }

  async getAbdomenType() {
    await this.getData('neph/abdmn').toPromise().then(
      (data) => masterCSData_Helper.setMasterAbdomenType(JSON.parse(JSON.stringify(data))));  
  }

  async getRespiratoryType() {
    await this.getData('gen/pulm').toPromise().then(
      (data) => masterCSData_Helper.setMasterRespiratoryType(JSON.parse(JSON.stringify(data))));  
  }

  async getCausesType() {
    await this.getData('neph/cause').toPromise().then(
      (data) => masterCSData_Helper.setMasterCausesType(JSON.parse(JSON.stringify(data))));  
  }

  async getComplicationsType() {
    await this.getData('neph/compl').toPromise().then(
      (data) => masterCSData_Helper.setMasterComplicationsType(JSON.parse(JSON.stringify(data)))); 
  }

  async getDurationSymptom() {
    await this.getData('neph/symdur').toPromise().then(
      (data) => masterCSData_Helper.setMasterDurationSymptom(JSON.parse(JSON.stringify(data)))); 
  }

  async getGynoPresentIllness() {
    await this.getData('goi/pidet').toPromise().then(
      (data) => masterCSData_Helper.setMasterGynoPresentIllness(JSON.parse(JSON.stringify(data)))); 
  }

  async getGynoStage() {
    await this.getData('goi/msdet').toPromise().then(
      (data) => masterCSData_Helper.setMasterGynoStage(JSON.parse(JSON.stringify(data)))); 
  }
  
  async getCharacterofpain() {
    await this.getData('goi/mpdet').toPromise().then(
      (data) => masterCSData_Helper.setMasterCharacterofpain(JSON.parse(JSON.stringify(data)))); 
  }

  async getFrequency() {
    await this.getData('goi/pfreq').toPromise().then(
      (data) => masterCSData_Helper.setMasterFrequency(JSON.parse(JSON.stringify(data)))); 
  }

  async getInfection() {
    await this.getData('goi/sinf').toPromise().then(
      (data) => masterCSData_Helper.setMasterInfection(JSON.parse(JSON.stringify(data)))); 
  }

  async getReportTypes() {
    await this.getData('gen/dtypes').toPromise().then(
      (data) => masterCSData_Helper.setMastergetReportTypes(JSON.parse(JSON.stringify(data)))); 
  }

  async getReliFactor() {
    await this.getData('dental/denrlf').toPromise().then(
      (data) => masterCSData_Helper.setMasterReliFactor(JSON.parse(JSON.stringify(data)))); 
  }

  async getSplSpeciDisease() {
    await this.getData('dental/dssdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterSplSpeciDisease(JSON.parse(JSON.stringify(data)))); 
  }

  async getAreaSpecificDisease() {
    await this.getData('dental/areadis').toPromise().then(
      (data) => masterCSData_Helper.setMasterAreaSpecificDisease(JSON.parse(JSON.stringify(data)))); 
  }

  async getAgeSpecificDisease() {
    await this.getData('dental/agedis').toPromise().then(
      (data) => masterCSData_Helper.setMasterAgeSpecificDisease(JSON.parse(JSON.stringify(data)))); 
  }

  async getGenderSpecificDisease() {
    await this.getData('dental/gendis').toPromise().then(
      (data) => masterCSData_Helper.setMasterGenderSpecificDisease(JSON.parse(JSON.stringify(data)))); 
  }

  async getOccupationalhazards() {
    await this.getData('dental/occhaz').toPromise().then(
      (data) => masterCSData_Helper.setMastergetOccupationalhazards(JSON.parse(JSON.stringify(data)))); 
  }

  async getRatingType() {
    await this.getData('dental/denpr').toPromise().then(
      (data) => masterCSData_Helper.setMastergetRatingType(JSON.parse(JSON.stringify(data)))); 
  }

  async getUneruptedTeeth() {
    await this.getData('dental/tname').toPromise().then(
      (data) => masterCSData_Helper.setMasterUneruptedTeeth(JSON.parse(JSON.stringify(data)))); 
  }

  async getGingivalColor() {
    await this.getData('dental/gincolor').toPromise().then(
      (data) => masterCSData_Helper.setMasterGingivalColor(JSON.parse(JSON.stringify(data)))); 
  }

  async getCircumOralMusTone() {
    await this.getData('dental/cirmoltone').toPromise().then(
      (data) => masterCSData_Helper.setMasterCircumOralMusTone(JSON.parse(JSON.stringify(data)))); 
  }

  async getLipPosture() {
    await this.getData('dental/lppost').toPromise().then(
      (data) => masterCSData_Helper.setMasterLipPosture(JSON.parse(JSON.stringify(data)))); 
  }

  async getChinPosition() {
    await this.getData('dental/chnpos').toPromise().then(
      (data) => masterCSData_Helper.setMasterChinPosition(JSON.parse(JSON.stringify(data)))); 
  }

  async getNasoLabelAngle() {
    await this.getData('dental/nlobangle').toPromise().then(
      (data) => masterCSData_Helper.setMasterNasoLabelAngle(JSON.parse(JSON.stringify(data)))); 
  }

  async getMentolabialSclcus() {
    await this.getData('dental/mentsul').toPromise().then(
      (data) => masterCSData_Helper.setMasterMentolabialSclcus(JSON.parse(JSON.stringify(data)))); 
  }

  async getFacialProfile() {
    await this.getData('dental/fprofile').toPromise().then(
      (data) => masterCSData_Helper.setMasterFacialProfile(JSON.parse(JSON.stringify(data)))); 
  }

  async getShapeofHead() {
    await this.getData('dental/hdshape').toPromise().then(
      (data) => masterCSData_Helper.setMasterShapeofHead(JSON.parse(JSON.stringify(data)))); 
  }

  async getFacialForm() {
    await this.getData('dental/fform').toPromise().then(
      (data) => masterCSData_Helper.setMasterFacialForm(JSON.parse(JSON.stringify(data)))); 
  }

  async getFacialDivergence() {
    await this.getData('dental/fdiv').toPromise().then(
      (data) => masterCSData_Helper.setMasterFacialDivergence(JSON.parse(JSON.stringify(data)))); 
  }

  async getFacialSymmetry() {
    await this.getData('dental/fsym').toPromise().then(
      (data) => masterCSData_Helper.setMasterFacialSymmetry(JSON.parse(JSON.stringify(data)))); 
  }

  async getLip() {
    await this.getData('dental/lptypes').toPromise().then(
      (data) => masterCSData_Helper.setMasterLip(JSON.parse(JSON.stringify(data)))); 
  }

  async getMolarRight() {
    await this.getData('dental/denmol').toPromise().then(
      (data) => masterCSData_Helper.setMasterMolarRight(JSON.parse(JSON.stringify(data)))); 
  }

  async getTypeofHabit() {
    await this.getData('dental/denhtm').toPromise().then(
      (data) => masterCSData_Helper.setMasterTypeofHabit(JSON.parse(JSON.stringify(data)))); 
  }

  async getChildhoodDisease() {
    await this.getData('dental/chdis').toPromise().then(
      (data) => masterCSData_Helper.setMasterChildhoodDisease(JSON.parse(JSON.stringify(data)))); 
  }

  async getUpperType() {
    await this.getData('dental/fratttypes').toPromise().then(
      (data) => masterCSData_Helper.setMasterUpperType(JSON.parse(JSON.stringify(data)))); 
  }

  async getExtraOralTmj() {
    await this.getData('dental/jtcon').toPromise().then(
      (data) => masterCSData_Helper.setMasterExtraOralTmj(JSON.parse(JSON.stringify(data)))); 
  }

  async getExtraOralCondition() {
    await this.getData('dental/econ').toPromise().then(
      (data) => masterCSData_Helper.setMasterExtraOralCondition(JSON.parse(JSON.stringify(data)))); 
  }

  async getShapeofTeeth() {
    await this.getData('dental/tetstr').toPromise().then(
      (data) => masterCSData_Helper.setMasterShapeofTeeth(JSON.parse(JSON.stringify(data)))); 
  }

  async getColorofTeeth() {
    await this.getData('dental/tetcolor').toPromise().then(
      (data) => masterCSData_Helper.setMasterColorofTeeth(JSON.parse(JSON.stringify(data)))); 
  }

  async getConsistencyofTeeth() {
    await this.getData('dental/tetconst').toPromise().then(
      (data) => masterCSData_Helper.setMasterConsistencyofTeeth(JSON.parse(JSON.stringify(data)))); 
  }

  async getConditionofTeeth() {
    await this.getData('dental/deniss').toPromise().then(
      (data) => masterCSData_Helper.setMasterConditionofTeeth(JSON.parse(JSON.stringify(data)))); 
  }

  async getMedicationDeptReferral() {
    await this.getData('dental/medref').toPromise().then(
      (data) => masterCSData_Helper.setMasterMedicationDeptReferral(JSON.parse(JSON.stringify(data)))); 
  }

  async getMaterialData() {
    await this.getData('dental/dmat').toPromise().then(
      (data) => masterCSData_Helper.setMasterMaterialData(JSON.parse(JSON.stringify(data)))); 
  }

}