<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{typeLableName}} Appointments</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="cancel()" *ngIf="appointmentList.length != 0" style=" margin: 5px 8px 0 0px; width: 85px;" src="../../../assets/ui_icons/buttons/cancel_button.svg" />
            <a (click)="createPhysioNurseAppt()" class="create"><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
              <div >
                <app-loader></app-loader>
          </div>
        <p class="nodata" *ngIf="appointmentList.length == 0 && nodata">No Appointment(s) Found</p>
        <table *ngIf="appointmentList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
          <thead>
            <tr>
              <th>Name</th>
              <th *ngIf="appNursePhysio=='physio' || appNursePhysio == 'dietician'">{{typeLableName}} Name</th>
              <th *ngIf="appNursePhysio=='nurse'">Nurse Name</th>
              <th>Appointment Date</th>
              <th>Session </th>
              <th>Token No</th>
              <th style="text-align: center !important;">Status </th>
              <th style="width: 5%;"><mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"></mdb-checkbox></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let person of appointmentList; let i = index" (click)="viewAppt(person.app_id)">
              <td style="font-size: 12px;text-align: left;padding-left: 20px;">{{ person.Client_Name }}</td>
              <td style="font-size: 12px;text-align: left;padding-left: 43px;">{{ person.Doctor_Name }}</td>
              <td style="font-size: 12px;">{{ person.Date | date:'dd-MM-yyyy' }}</td>
              <td style="font-size: 12px;text-align: center;">{{ person.Session }}</td>
              <td style="font-size: 12px;">{{person.Time_Token}}</td>
              <td style="font-size: 12px;"> 
                <div style="position: relative;top: 9px;">
                  <img *ngIf="person.statustxt == 'Completed'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                  <img *ngIf="person.statustxt == 'Cancelled'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" />
                  <img *ngIf="person.statustxt == 'Not visited'"  width="30px" height="auto" src="../../../assets/ui_icons/not_visited_icon.svg" />
                  <img *ngIf="person.statustxt == 'Confirmed'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                  <img *ngIf="person.statustxt == 'Open'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                  <p>{{person.statustxt}}</p>
                </div>
              </td>
              <td style="font-size: 12px;width: 5%;"><mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name" value="{{person.id}}" (change)="isAllSelected(person.app_id)"  [disabled]="person.statustxt != 'Open' && person.statustxt != 'Confirmed'"></mdb-checkbox></td>
            </tr>
          </tbody>
        </table>
        <br><br><br>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
