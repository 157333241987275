import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';
declare var $: any;

@Component({
  selector: 'app-physio-assign-view',
  templateUrl: './physio-assign-view.component.html',
  styleUrls: ['./physio-assign-view.component.scss']
})
export class PhysioAssignViewComponent implements OnInit {
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public relationType: string;
  public age: string;
  public gender: string;
  public bloodGroup: string;
  public fatherOrGuardian: string;
  public income: string;
  public occupation: string;
  public contactNo: string;
  public symptoms: string;
  public clientAddress: string;
  public currentDate: string;
  public homecare: string;
  public confirmFlag: boolean;
  public getAppId :any;
  public doctorName: string;
  public startDate : string;
  public endDate : string;
  public noOfSitting: string;
  public fees: string;
  public prescriptionDetails: string;
  public comments: string;
  public curentDateURL;
  public reasonForCancelAppt;

  constructor(public http: Http, public toastr: ToastrService, public messageService: PhysioService) { }

  ngOnInit(): void {
    this.confirmFlag = true;
    this.curentDateURL = ipaddress.getIp + 'adm/curdate';
    this.getAppId = Physio_Helper.getAssignDetailsView().app_id;
    this.homecare = Physio_Helper.getAssignDetailsView().home_care;
    this.getCurrendDate();
  }

  getCurrendDate() {
    var sendData = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curentDateURL, JSON.stringify(sendData), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = data.json().result;
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.getAppointmentDetailedView();
        }
      });
  }

  getAppointmentDetailedView() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'hcare/getasgdet/',
      JSON.stringify({
        assign_id: this.getAppId,
        type: "physio",
        home_care: this.homecare,
        country: ipaddress.country_code,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.client_first_name != null) {
            this.firstName = encrypt_decript.Decript(obj.client_first_name);
            if (obj.client_middle_name != undefined || obj.client_middle_name != null) {
              this.middleName = encrypt_decript.Decript(obj.client_middle_name);
            }
            this.lastName = encrypt_decript.Decript(obj.client_last_name);
            if (obj.dob != null) {
              localStorage.setItem("Case_Clnt_dob", obj.dob);
            }
            var stdate = obj.start_date.split('-');
            this.startDate = stdate[2]+"-"+stdate[1]+"-"+stdate[0];
            var eddate = obj.end_date.split('-');
            this.endDate = eddate[2]+"-"+eddate[1]+"-"+eddate[0];
            this.noOfSitting = obj.no_of_sittings;
            this.fees = obj.fee_per_sitting;
            this.comments = obj.comments;
            this.relationType = obj.relation;
            this.age = obj.age;
            this.gender = encrypt_decript.Decript(obj.gender);
            localStorage.setItem("Age_data", this.age);
            if (obj.blood_desc != null) {
              this.bloodGroup = obj.blood_desc;
            }
            if (obj.guardian != null) {
              this.fatherOrGuardian = encrypt_decript.Decript(obj.guardian);
              this.income = encrypt_decript.Decript(obj.income);
            }
            if (obj.guardian != null) {
              this.income = encrypt_decript.Decript(obj.income);
            }
            if (obj.occupation != null) {
              this.occupation = encrypt_decript.Decript(obj.occupation);
            }
            if(obj.mobile != undefined || obj.mobile != null){
              this.contactNo = encrypt_decript.Decript(obj.mobile);
            }
            if (obj.symptoms != null) {
              this.symptoms = encrypt_decript.Decript(obj.symptoms);
            }
            Helper_Class.Set_client_view_app_data(obj);
            if (obj.doc_middle_name != null && obj.doc_middle_name != undefined) {
              this.doctorName =obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
            } else {
              this.doctorName =obj.doc_first_name + " " + obj.doc_last_name;
            }
            var address1, location, city_zip, stacount;
            location=obj.location_desc;
            if (obj.address2 != null && obj.address2 != "") {
              address1 = encrypt_decript.Decript(obj.address1) + " " + encrypt_decript.Decript(obj.address2);
            } else {
              address1 = encrypt_decript.Decript(obj.address1);
            }
            city_zip = obj.city_desc + " - " + encrypt_decript.Decript(obj.zipcode);
            stacount=obj.state_desc + " , " + obj.country_desc;
            this.clientAddress =address1+"\n"+location+"\n"+city_zip+"\n"+stacount;
            for(var j=0;j<obj.drug_list.length;j++){
              this.prescriptionDetails=obj.drug_list[j].drug_name+"  "+obj.drug_list[j].morning+"-"+obj.drug_list[j].afternoon+"-"+obj.drug_list[j].evening+"   "+obj.drug_list[j].time_duration+" "+obj.drug_list[j].drug_intake+"   "+obj.drug_list[j].days+" "+"days"+"\n" ;
            }
          }else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  cancelModel(){
    $("#staticBackdrop").modal('hide');
  }

  cancel() {
    $("#staticBackdrop").modal('show');
  }

  submitCancel(){
    if (this.reasonForCancelAppt.toString().trim() == "" && this.reasonForCancelAppt.length == 0) {
      this.toastr.error(Message_data.appcancelreason);
    }
    else if (this.reasonForCancelAppt.length > 150) {
      this.toastr.error(Message_data.maxlen150);
    }
    else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          type: "nurse",
          np_app_id: this.getAppId,
          reason: this.reasonForCancelAppt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                $("#staticBackdrop").modal('hide');
                this.toastr.success(Message_data.appcancel);
                this.messageService.sendMessage("assignmentList");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
            $("#staticBackdrop").modal('hide');
          }
        )
    }
  }

  confirmAppointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/confapp',
      JSON.stringify({
        type: "doctor",
        app_id: this.getAppId,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success(Message_data.appconf);
              this.messageService.sendMessage("assignList");
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
