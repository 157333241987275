<div mat-dialog-title class="popup_border_cancel">
  <h5 class="header_txt">Please provide reason</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="onNoClick()" width="20px" height="20px" id="close_cancel" />
  </div>
</div>
<div mat-dialog-content class="diag_content">
  <div class="row" style="margin: 5px;">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-label class="matlabel"><br>
        <textarea #matInput class="ipcss obsttxtareaheight widthappt" maxlength="50"
          [(ngModel)]="cancel"></textarea>
      </mat-label>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="submit()" id="add_btn" [mat-dialog-close]="cancel" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/submit_button.svg" class="addimgbtn_icd" />
  </button>
  <button (click)="onNoClick()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="addimgbtn_icd" />
  </button>
</div>