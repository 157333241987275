import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-billlist',
  templateUrl: './physio-billlist.component.html',
  styleUrls: ['./physio-billlist.component.scss']
})
export class PhysioBilllistComponent implements OnInit {
  public userInfo;
  public userId;
  public currentDate = null;
  public currentMaxDate = null;
  public billingList = [];
  public hospitalList = [];
  public hptlClinicId: string;
  public hospitalFlag: boolean;
  public fromDate;
  public toDate;
  public totalAmount;
  public totalBalance;
  dtOptions: DataTables.Settings = {};

  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: PhysioService) {
    this.hospitalFlag = true;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.hospitalList = this.userInfo.hospitals;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 4, 5, 7,9] },
        { "width": "100px", "targets": 8 },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search  by bill no, date, or name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (this.hospitalList.length != 0) {
      this.hospitalFlag = true;
      this.hptlClinicId = this.hospitalList[0].hptl_clinic_id;
    } else {
      this.hospitalFlag = false;
    }
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.currentMaxDate = obj.current_date;
            this.fromDate = obj.current_date;
            this.toDate = obj.current_date;
            this.getBillList();
          }
        }, error => {});
  }

  selectFromDate(e) {
    this.fromDate = e;   
  }

  selectToDate(e) {
    this.toDate = e;
  }

  getBillList() {
    this.billingList = [];
    var fieldvalue = {
        center_id: this.hptlClinicId,
        provider_id: this.userId,
        from_date: this.currentDate,
        to_date: this.currentDate,
        imei: ipaddress.getIp,
        app_type: "physio"
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'bill/getbills',
    JSON.stringify({
      fieldvalue
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
            this.totalAmount = obj.totalamount;
            this.totalBalance = obj.totalbalance;
            if (obj.bills != null) {
              this.setBillList(obj);
            } 
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  filter() {
    var get_f_date = this.fromDate;
    var get_t_date = this.toDate;
    this.billingList = [];
    var fieldvalue = {
      center_id: this.hptlClinicId,
      provider_id: this.userId,
      from_date: get_f_date,
      to_date: get_t_date,
      imei: ipaddress.getIp,
      app_type: "physio"
  }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'bill/getbills/',
    JSON.stringify({
      fieldvalue
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
            if (obj.bills != null) {
              this.setBillList(obj);
            } 
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  setBillList(obj) {
    if (obj.bills != null) {
      for (var i = 0; i < obj.bills.length; i++) {
        var clnt_nmae = null;
        if (obj.bills[i].middle_name != undefined && obj.bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].middle_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
        } else {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
        }
        var get_date = obj.bills[i].date.split('-');
        this.billingList.push({
          bill_id: obj.bills[i].bill_id,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name: clnt_nmae,
          amount: encrypt_decript.Decript(obj.bills[i].amount),
          amount_only: encrypt_decript.Decript(obj.bills[i].amount),
        });
      }
      var tot = 0;
      for (var i = 0; i < this.billingList.length; i++) {
        tot = tot + parseFloat(this.billingList[i].amount_only);
      }
    } 
  }

  viewBill(bill_id) {
    var bill = {
      bill_view_id : bill_id
    }
  Physio_Helper.setBillId(bill)
    this.messageService.sendMessage("billdetails");
  }

  create(){
    this.messageService.sendMessage("billCreate");
  }
}
