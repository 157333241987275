import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, diag_dayid, Date_Formate } from '../../../assets/js/common.js';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-diagnosis-registration',
  templateUrl: './diagnosis-registration.component.html',
  styleUrls: ['./diagnosis-registration.component.css']
})
export class DiagnosisRegistrationComponent implements OnInit {
  //PROFILE 
  public imgStr = null;
  public doctorDOB;
  // variable declaration
  public currentDatetime;
  public password;
  public createPassword;
  public toggHome: boolean;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public profileIMG: string;
  public docMobile: string;
  public docEmail: string;
  public genderTxt: string;
  public updateIMG;
  public imagePath;
  public registerationCode: string;
  public registerationYear: string;
  public registerationState: string;
  public mobileSTR: string;
  public emailSTR: string;
  public confirmPassword;
  public getDate;
  public proIMG;
  //work
  public workArray = [];
  public startsFromArray = [];
  public endsAtArray = [];
  public hospNameTxt: string;
  public hospDist;
  public hospLoctionTxt: string;
  public hospState;
  public hospCountry;
  public hospZip: string;
  public hospAddress1: string;
  public hospAddress2: string;
  public hospContact: string;
  public hopWebsite: string;
  public hospDays: string;
  public startFrom1: string;
  public startFrom2: string;
  public startFrom3: string;
  public endAt1: string;
  public endAt2: string;
  public endAt3: string;
  public hopsConsult;
  public appToken;
  public appTime: boolean;
  public countTxt: string;
  public hospitalArray = [];
  public filtCityDesc;
  public filtStateDesc;
  public locID: string;
  public countryID: string;
  public countryDesc: string;
  public locationFilterArray = [];
  public filteredLocList = [];
  public filteredHospArray = [];
  public hospitalFilterArray = [];
  public locationList;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public cityListArray;
  public stateListArray;
  public countryListArray;
  public hospID = 0;
  public IsExists: boolean;
  public citySendid;
  public appTypeLBL: string;
  public homecareMorningTool: boolean;
  public homecareAfternoonTool: boolean;
  public homecareEveningTool: boolean;
  public sendCityDesc;
  public sendStateDesc;
  //terms and condition
  public regProfileTab;
  public regWorkTab;
  public registrationWorkAdd;
  public getPackData;
  public agree;
  tmr = new Date();
  public saveFlag: boolean = false;
  public docAge:string;
  public ageRead: boolean=false;
  public ageDisabledFlag: boolean=false;
  public ageUrl: string;
  public signatureFlag:boolean;
  public signatureFile = null;
  constructor(public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.signatureFlag = false;
    //profile
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    //work
    this.hospNameTxt = "";
    this.hospLoctionTxt = "";
    this.hospDist = "";
    this.hospState = "";
    this.hospCountry = "";
    this.hospZip = "";
    this.hospAddress1 = "",
      this.hospAddress2 = "";
    this.hospContact = "";
    this.hopWebsite = '';
    this.hospDays = undefined;
    this.hopsConsult = '';
    this.countTxt = "";
    this.appTypeLBL = "Count";
    this.appToken = true;
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";
    this.endAt1 = "08";
    this.endAt2 = "00";
    this.endAt3 = "AM";
    this.hospID = 0;
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    // time format
    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.startsFromArray.push("0" + i);
      else
        this.startsFromArray.push(i);
    }
    for (var i = 0; i <= 55; i += 5) {
      if (i < 10)
        this.endsAtArray.push("0" + i);
      else
        this.endsAtArray.push(i);
    }
  }
  ngOnInit(): void {
    //profile
    this.imagePath = "default";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    // Date Picker
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            this.getDate = obj.current_date.split('-');
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    //work
    this.homecareMorningTool = true;
    this.homecareAfternoonTool = true;
    this.homecareEveningTool = true;
  }

  changeDate(data) {//calendar
    this.doctorDOB = data;
    var get_t_date = (this.doctorDOB);
    var Dobsplit = get_t_date.toString().split('-');

    this.ageRead = true;
    if(data != null)
    this.ageDisabledFlag = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.doctorDOB),
      curdate: Date_Formate(this.currentDatetime),

    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined)
          this.docAge = obj.value;
      });
  }

  public createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + ".jpg";
    return newFileName;
  }
  
  convertToBase64(url, outputFormat) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'),
          dataURL;
        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        dataURL = canvas.toDataURL(outputFormat);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }
  changeFile($event): void {
    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgStr = null;
      } else {
        this.imgStr = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  onFileChange($event): void {
    this.signatureFlag = true;
    this.readThis1($event.target);
  }

  readThis1(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        // $scope.Imagepath == "default"
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.signatureFile = null;
      } else {
        var signatureFile = myReader.result;
        this.signatureFile = myReader.result;
        $('#signature_img').attr('src', myReader.result);
        this.signatureFile = (signatureFile as string).split(',')[1];
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
    
  }

  clickProfileNext() {//Profile next click
    var chec_valid = true;
    if (this.firstName == undefined || this.firstName == "" || this.lastName == undefined || this.lastName == "" 
    || this.genderTxt == undefined || this.doctorDOB == "" || this.docMobile == undefined || this.docMobile == "" 
    || this.registerationCode == undefined || this.registerationCode == "" || this.registerationYear == undefined 
    || this.registerationYear == "" || this.registerationState == undefined || this.registerationState == "" 
    || this.createPassword == undefined || this.createPassword == "" || this.confirmPassword == undefined 
      || this.confirmPassword == "") {
      this.toastr.error(Message_data.mandatory);
      chec_valid = false;
    } else if (this.docMobile.length != 10) {
      this.toastr.error(Message_data.validMobileNo);
      chec_valid = false;
    } else if (this.docEmail != undefined) {
      var x = this.docEmail;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        chec_valid = false;
        this.toastr.error(Message_data.validEmail);
      }
    }
    if (this.createPassword != this.confirmPassword) {
      this.toastr.error(Message_data.pwdNotMatched);
      chec_valid = false;
    }
    if (this.doctorDOB != undefined) {
      var get_t_date = (this.doctorDOB);
      var Dobsplit = get_t_date.toString().split('-');
      Helper_Class.set_Doc_reg_dob(Dobsplit[0]);
    }
    if (this.doctorDOB != "" && this.registerationYear != undefined && this.registerationYear != "") {
      if ((this.registerationYear.length != 4) || (parseInt(this.registerationYear) > parseInt(this.getDate[0]))) {
        this.toastr.error(Message_data.validRegtrYear);
        chec_valid = false;
        
      } else if ((parseInt(this.registerationYear) - parseInt(Dobsplit[0])) < 20) {
        this.toastr.error(Message_data.invalidRegtrOrDOB);
        chec_valid = false;
      }
    }
    if (chec_valid == true) {
      this.checkMobileExits();
    }
  }
  updateFinal() {
    var home_value = null;
    home_value = this.toggHome == true ? "1" : "0";
    var get_f_date = (this.doctorDOB);
    this.updateIMG = this.imagePath == "default" ? "default" : this.imagePath.split(',')[1];
    var emailstr = this.docEmail.trim();
    this.mobileSTR = encrypt_decript.Encript(this.docMobile).toString();
    this.emailSTR = encrypt_decript.Encript(emailstr).toString();
    this.password = encrypt_decript.Encript(this.createPassword).toString();
    var img_path = this.proIMG, upd_url = undefined;
    var sig_path;
    if(this.signatureFile != null){
      sig_path = this.signatureFile;
      console.log(sig_path);
    }
    if (this.imgStr != null) {
      img_path = this.imgStr.split(',')[1];
      upd_url = "1";
    } else {
      img_path = "default";
    }
    var send_array = null;
    send_array = {
      serv_provider_id: "3",
      upd_url: upd_url,
      profile_image: img_path,
      signature_image: sig_path,
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      gender: this.genderTxt,
      dob: get_f_date,
      age: this.docAge,
      email: this.emailSTR,
      mobile: this.mobileSTR,
      registration_code: this.registerationCode,
      registration_state: this.registerationState,
      registration_year: this.registerationYear,
      language: "English",
      created_date: this.currentDatetime,
      password: this.password,
      verification_call: "1",
      client_call: "1",
      home_sample: home_value
    }
    Helper_Class.set_regsitration_profile_array(send_array);
    this.nextWorklocation();
  }
  emailCheck() {
    var email_di = encrypt_decript.Encript(this.docEmail);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
      JSON.stringify({
        email: email_di.toString(),
        flag: "3"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true)
            this.toastr.error(Message_data.existEmailID);
          else
            this.licenceExists();
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  licenceExists() { //licence exists
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/islicenceexist',
      JSON.stringify({
        licence_code: this.registerationCode,
        flag: "3"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true)
            this.toastr.error(Message_data.existRegstrCode);
          else
            this.updateFinal();
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  checkMobileExits() {
    var email_di = encrypt_decript.Encript(this.docMobile);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
      JSON.stringify({
        mobile: email_di.toString(),
        flag: "3"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true) {
            this.toastr.error(Message_data.existMobileNo);
          } else {
            if (this.docEmail != undefined && this.docEmail.trim() != undefined) {
              this.emailCheck();
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  clickWork() {//work // add table
    var cosult_id = null;
    cosult_id = this.hopsConsult == true ? 1 : 0;
    for (var i = 0; i < this.cityListArray.length; i++) {
      if (this.cityListArray[i].city_id == this.hospDist) {
        this.sendCityDesc = this.cityListArray[i].city_desc;
      }
    }

    for (var i = 0; i < this.stateListArray.length; i++) {
      if (this.stateListArray[i].state_id == this.hospState) {
        this.sendStateDesc = this.stateListArray[i].state_desc;
      }
    }

    if (this.hospNameTxt == "" || this.hospLoctionTxt == "" ||
      this.hospDist == undefined || this.hospState == "" || this.hospCountry == "" ||
      this.hospZip == "" || this.hospAddress1 == "" || this.hospContact == "" ||
      this.hospDays == "") {
      this.toastr.error(Message_data.mandatory)
    } else {
      this.workArray.push({
        hospital_id: this.hospID,
        name: this.hospNameTxt,
        location: this.hospLoctionTxt,
        city: this.sendCityDesc,
        state: this.sendStateDesc,
        country: this.hospCountry,
        zipcode: this.hospZip,
        address1: this.hospAddress1,
        address2: this.hospAddress2,
        mobilenum: this.hospContact,
        website: this.hopWebsite,
        day_desc: this.hospDays,
        day: diag_dayid(this.hospDays),
        available_from: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3,
        available_to: this.endAt1 + ":" + this.endAt2 + " " + this.endAt3,
        consultant: cosult_id,
        appointment: this.appToken + "" + this.appTime,
        consult_time_id: "0"
      });
      this.hospNameTxt = "";
      this.hospLoctionTxt = "";
      this.hospDist = "";
      this.hospState = "";
      this.hospCountry = "";
      this.hospZip = "";
      this.hospAddress1 = "",
        this.hospAddress2 = "";
      this.hospContact = "";
      this.hopWebsite = '';
      this.hospDays = "";
      this.hopsConsult = '';
      this.countTxt = "";
      this.startFrom1 = "06";
      this.startFrom2 = "00";
      this.startFrom3 = "AM";
      this.endAt1 = "08";
      this.endAt2 = "00";
      this.endAt3 = "AM";
    }
  }
  deleteWork(name_data, end_time) { //table delecte action
    for (var i = 0; i < this.workArray.length; i++) {
      if (name_data == this.workArray[i].name) {
        this.workArray.splice(i, 1);
      }
    }
  }
  filterHospital(e) {
    this.cityListArray = [];
    this.stateListArray = [];
    this.countryListArray = [];
    this.filteredLocList = [];
    this.hospLoctionTxt = '';
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContact = '';
    this.hopWebsite = '';
    this.hospitalArray = [];
    this.hospitalFilterArray = [];
    var tmp_arr = [];
    this.hospNameTxt = e.target.value.toString();
    if (this.hospNameTxt !== "" && this.hospNameTxt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagnosis/',
        JSON.stringify({
          diag_name: this.hospNameTxt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.diagnosis != null) {
              this.hospitalFilterArray = obj.diagnosis;
              for (var i = 0; i < this.hospitalFilterArray.length; i++) {
                this.hospitalArray.push(this.hospitalFilterArray[i].diag_name);
              }
              this.filteredHospArray = this.hospitalArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospNameTxt.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.filteredHospArray = [];
    }
  }
  selectHospital(data) {
    this.hospNameTxt = data;
    this.filteredHospArray = [];
  }
  locFilter(e) {//location autopopulate
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContact = '';
    this.hopWebsite = '';
    this.hospID = 0;
    this.locationFilterArray = [];
    this.hospLoctionTxt = e.target.value.toString();
    if (this.hospLoctionTxt !== "" && this.hospLoctionTxt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hospLoctionTxt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.filteredLocList = [];
              this.locationFilterArray = obj.locations;
              this.filteredLocList = this.locationFilterArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospLoctionTxt.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.filteredLocList = [];
    }
  }
  selectLocation(data) {
    this.hospLoctionTxt = data;
    this.filteredLocList = [];
    this.getCity(data);
    this.stateListArray = [];
    this.cityListArray = [];
    this.countryListArray = [];
  }
  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.cityListArray = data.json().cities;
          this.hospDist = this.cityListArray[0].city_id;
          this.filtCityDesc = this.cityListArray[0].city_desc;
          this.locID = this.cityListArray[0].location_id;
          this.hospZip = this.cityListArray[0].zipcode;
          this.changeCity(this.cityListArray[0].city_id);
        }
      }, error => { });
  }
  changeCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateListArray = [];
            this.stateListArray = data.json().states;
            this.hospState = this.stateListArray[0].state_id;
            this.filtStateDesc = this.stateListArray[0].state_desc;
            this.changeState(this.stateListArray[0].state_id);
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.stateListArray = [];
      this.cityListArray = [];
      this.countryListArray = [];
    }
  }
  changeState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.countryListArray = [];
            this.countryListArray = data.json().countries;
            this.countryID = this.countryListArray[0].country_id;
            this.hospCountry = this.countryListArray[0].country_desc;
            this.changeSerCountry();
          }
        }, error => { });
    } else {
      this.stateListArray = [];
      this.countryListArray = [];
    }
  }
  changeSerCountry() {
    for (var i = 0; i < this.cityListArray.length; i++) {
      if (this.cityListArray[i].city_desc == this.hospDist) {
        this.citySendid = this.cityListArray[i].city_id;
      }
    }
    if (this.hospNameTxt != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/isdiagexist/',
        JSON.stringify({
          diag_name: this.hospNameTxt,
          location: this.hospLoctionTxt,
          city: this.citySendid
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.is_exists != null) {
              if (obj.is_exists == true) {
                this.IsExists = true;
                this.toastr.error(Message_data.diagCenterAlrdyRegInLoc);
              } else {
                this.IsExists = false;
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetailsbyname/',
                  JSON.stringify({
                    diag_name: this.hospNameTxt,
                    location: this.hospLoctionTxt,
                  }),
                  { headers: headers })
                  .subscribe(
                    response => {
                      var obj = JSON.parse(response["_body"]);
                      console.log("GET DIAG DATA ((())) "+JSON.stringify(obj))
                      if (obj.diag_id != null) {
                        this.hospID = obj.diag_id;
                        this.hospAddress1 = obj.address1;
                        this.hospAddress2 = obj.address2;
                        if (obj.telephone != null)
                          this.hospContact = obj.telephone;
                        if (obj.website != null)
                          this.hopWebsite = obj.website;
                      }
                    }, error => {
                      this.toastr.error(Message_data.getNetworkMessage());
                    });
              }
            } else {
              this.IsExists = false;
              this.hospID = 0;
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }
  nextWorklocation() {
    if (this.workArray.length == 0) {
      this.toastr.error(Message_data.addWrkLocation);
    } else {
      var work_arry1 = [];
      var work_address_array = [];
      for (var i = 0; i < this.workArray.length; i++) {
        if (this.workArray[i].hospital_id == "0") {
          var address2_txt = undefined
          address2_txt = this.workArray[i].address2 != undefined ? this.workArray[i].address2 : "";
          var website_txt = undefined;
          website_txt = this.workArray[i].hospital_website != undefined ? this.workArray[i].hospital_website : "";
          work_address_array.push({
            name: this.workArray[i].name,
            address1: this.workArray[i].address1,
            address2: address2_txt,
            city: this.workArray[i].city,
            state: this.workArray[i].state,
            zipcode: this.workArray[i].zipcode,
            country: this.workArray[i].country,
            telephone: this.workArray[i].mobilenum,
            website: this.workArray[i].hospital_website,
            location: this.workArray[i].location,
          });
          var hosp_nursename = this.workArray[i].name;
          var hosp_nurseloc = this.workArray[i].location;
        }
        var consult_time_id = this.workArray[i].consul_id != "0" ? this.workArray[i].consult_time_id : "0";
        work_arry1.push({
          hospital_id: this.workArray[i].hospital_id,
          available_from: ConvertTimeformat("", this.workArray[i].available_from),
          available_to: ConvertTimeformat("", this.workArray[i].available_to),
          fee: this.workArray[i].fee,
          day: this.workArray[i].day,
          day_session: this.workArray[i].day_session,
          token: this.workArray[i].token,
          app_duration: this.workArray[i].app_duration,
          consultant: this.workArray[i].consultant,
          consultation_time_id: consult_time_id,
          hosp_name: hosp_nursename,
          location: hosp_nurseloc,
        });
      }
      Helper_Class.set_reg_work_array(work_arry1);
      Helper_Class.set_reg_work_add_array(work_address_array);
      this.intitateToRegister();
    }
  }
  nextRegister() {//terms and condition
    this.clickProfileNext();
  }
  intitateToRegister() {
    if (this.agree == true) {
      var headers = new Headers();
      this.regProfileTab = Helper_Class.get_regsitration_profile_array();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
        JSON.stringify({
          mobile: encrypt_decript.Encript("").toString(),
          country: ipaddress.country_code,
          flag:"3",
          registration:true
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.otp != null) {
              this.otpOpen(obj.otp);
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.toastr.error(Message_data.termsNconditions);
    }
  }
  otpOpen(otp_data) {
    this.register();
  }
  register() {
    this.regProfileTab = Helper_Class.get_regsitration_profile_array();
    this.regWorkTab = Helper_Class.get_reg_work_array();
    this.registrationWorkAdd = Helper_Class.get_reg_work_add_array();
    this.getPackData = Helper_Class.get_package_data();
    var send_work = null;
    send_work = {
      consultation: this.regWorkTab
    }
    var send_url = "usercontroller/regprov/";
    for (var key in this.regProfileTab) {
      if (key == "upd_url" && this.regProfileTab[key] != undefined) {
        send_url = "usercontroller/regprovweb/";
      }
    }
    var subscribe_array = {
      grit_package_subscribe_id: "18",
      country: ipaddress.country_code
    }
    var finalObj = $.extend(true, this.regProfileTab, send_work);
    var finalObj2 = $.extend(true, finalObj, subscribe_array);
    var output = null;
    output = finalObj2;
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_reg_work_add_array();
    // send foot ass
    if (this.registrationWorkAdd != null || this.registrationWorkAdd != undefined) {
      send_foot = {
        address: getfoot_ass,
      }
    }
    output = $.extend(true, finalObj2, send_foot);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key == "1") {
            this.saveFlag = true;
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl("/loginpage");
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  termsConditionBack() {
    // this.app.getActiveNavs()[0].parent.select(2);
  }
  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }
  gender() {
    document.getElementById("genderid").style.border = "1px solid #2ca8fe";
  }
}
