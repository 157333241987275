import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Date_Formate } from '../../../assets/js/common';
// import { Doc_Helper } from '../Doc_Helper'
// import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ipaddress } from '../../ipaddress' ;
// import { Master_Helper } from '../Master_Helper';
// import { MasterHelperService } from '../MasterHelper.service';
import { DietService } from '../../../app/Diet_module/diet-view/diet-view.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { Admin_Helper } from '../Admin_Helper';
@Component({
  selector: 'app-insuranceprovider-list',
  templateUrl: './insuranceprovider-list.component.html',
  styleUrls: ['./insuranceprovider-list.component.scss']
})
export class InsuranceproviderListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public clnt_location;
  public clnt_city;
  public clnt_state;
  public clnt_country;
  public clnt_location_id;
  public clnt_city_id;
  public clnt_state_id;
  public clnt_country_id;
  public hospital_details;
  public userinfo;
  public hospital_id;
  public clnt_zipcode;
  public insurancelist:any;
  constructor(public toastr: ToastrService,public http: Http, public router: Router,
    public messageservice:MenuViewService, public masterData:MasterHelperService
    ,public dietService:MenuViewService,) {
    }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [ 4, 5,] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by bill no or patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userinfo = Helper_Class.getInfo();

    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    console.log("check countr"+JSON.stringify(this.hospital_details))  
    this.insurancelist = [];
    this.getinsurence()
  }
  getinsurence() {
    console.log('this.hospital_id' + JSON.stringify(this.hospital_id));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'gen/gibh',
        JSON.stringify({ hptl_clinic_id: this.hospital_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.insurancelist = [];
          var obj1 = data.json();
          //this.insurancelist = JSON.parse(data['_body']).anaesthesia_doctors;
          var obj=obj1.insurer_details
          console.log('Insurer' + JSON.stringify(obj));

          Admin_Helper.setinsuranceprovidelist(null);
          if(obj.length != 0){
            for(var i= 0;i<obj.length;i++){
              this.insurancelist.push({
                Provider:obj[i].insurer_desc,
                address1:obj[i].address1,
                address2:obj[i].address2,
                location:obj[i].location,
                city:obj[i].city,
                state:obj[i].state,
                country:obj[i].country,
                zipcode:obj[i].zipcode,
                mobile:obj[i].mobile,
                email:obj[i].email,
                rohini_id:obj[i].rohini_id,
                hptl_ins_id:obj[i].hptl_ins_id,
                locationdesc:obj[i].location_desc,
                city_desc:obj[i].city_desc,
                state_desc:obj[i].state_desc,
                country_desc:obj[i].country_desc,
                status:obj[i].status
              })
            }
           
          }
          //if(this.hptl_clinic_id)
         
        },
        (error) => {
          this.toastr.error('Unable to fetch country details, try again later');
        }
      );
  }
  create(){
    this.messageservice.sendMessage("insurer_list");
  }
  edit(data){
    Admin_Helper.setinsuranceprovidelist(data);

    this.messageservice.sendMessage("insurer_edit");
  }
  view_bill(data){
    Admin_Helper.setinsuranceprovidelist(data);
    this.messageservice.sendMessage("insurer_detail");
    //insurer_detail
  }
  deleteBill(person){
    var save_data = ({
      hptl_ins_id:person.hptl_ins_id,
      hptl_clinic_id: this.hospital_id,
      insurer_desc:person.Provider,
      name:person.insurer_name,
      address1:person.address1,
      address2:person.address2,
      location :person.location,
      city:person.city,
      state:person.state,
      country:person.country,zipcode:person.zipcode,mobile:person.mobile,email:person.email,rohini_id:person.rohini_id,
      status:"0"
   
      // mobileno:this.mobileno,
      // email_id:this.email_id,rohini_id:this.rohini_id
      
      // prov_id: ,
      // client_id: this.client_reg_id,
     
     
    });
    console.log("check list**8"+JSON.stringify(save_data))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/sibh', save_data, {
      headers: headers,
    })
      .subscribe((response) => {
        var obj = response.json();
        this.messageservice.sendMessage("insurerlist");
        this.toastr.success(Message_data.saveSuccess)
        console.log("check response"+JSON.stringify(obj))
        //this.ngOnInit()
        //this.toastr.success(Message_data.addmissionSavedSuccess);
      });
  }
}
