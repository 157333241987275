<div class="row" style="width:100%;margin:0 auto;">
    <div class="">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header" >
              <h5 class="m-0" class="mainHeadingStyle">List</h5>
            </div>
            <div class="headerButtons">
        <!--*ngIf="createFlag && newbutton"--><a (click)="create()"><img src="../../../assets/ui_icons/buttons/new_button.svg" 
                class="saveimgbtn_inpatinfo"></a>
            </div>
          </div> 
          
        </mdb-card-header>
        <mdb-card-body>       
  
         
          <div class="row" style="margin-top: 15px;">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p class="nodata" *ngIf="insurancelist.length == 0">No Insurer(s) found</p>
  
              <table *ngIf="insurancelist.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable billlisttable">
                <thead>
                  <tr>
                    <th class="delete">Insurance Provider</th>
                    <th>location</th>
                    <th>mail id</th>
                    <th>Rohini id</th>
                    <th>Contact number</th>
                    <th>Status</th>
                    <th class="delete" >&nbsp;</th>
                    <th class="delete">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                   
                  <tr *ngFor="let person of insurancelist; let i = index">
                    <td style="font-size: 12px;text-align: center;" (click)="view_bill(person)">{{person.Provider}}
                    </td>
                    <td style="font-size: 12px;text-align: center;" (click)="view_bill(person)">{{person.locationdesc}}
                    </td>
                    <td style="font-size: 12px;text-align: left !important;" (click)="view_bill(person)">
                      {{person.email}} 
                    </td>
                    <td style="font-size: 12px;text-align: right;" (click)="view_bill(person)">{{person.rohini_id}}
                    </td>
                    <td style="font-size: 12px;text-align: right;" (click)="view_bill(person)">{{person.mobile}}
                    </td>
                    <td style="font-size: 12px;text-align: right;" (click)="view_bill(person)">{{person.status}}
                    </td>
                     <td  style="font-size: 12px;text-align: center;" (click)="edit(person)"><img
                        src="../../../assets/img/edit.png" class="billing_edit" alt="" style="width: 20px;height: 20px;">
                    </td>
                      <td  style="font-size: 12px;text-align: center;" (click)="deleteBill(person)"><img
                        src="../../../assets/ui_icons/trash_icon.svg" class="billing_edit" alt="" style="width: 20px;height: 20px;">
                    </td>
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>