import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service'
import { Nurse_Helper } from '../Nurse_Helper';
import { Diet_Helper } from 'src/app/Diet_module/Diet_Helper';
@Component({
  selector: 'app-nurse-inpatientlist-diet',
  templateUrl: './nurse-inpatientlist-diet.component.html',
  styleUrls: ['./nurse-inpatientlist-diet.component.scss']
})
export class NurseInpatientlistDietComponent implements OnInit {

  public CurrentDatetime;
  public selectvalue;
  public mobileflag;
  public mobile;
  public currentTime;
  public currentDate;
  public currentMaxDate;
  public currentYear;
  public get_date;
  public docRegId;
  public mrnoflag;
  public nameflag;
  public locationflag;
  public fdateflag;
  public todateflag;
  public mrno;
  public name;
  public location;
  public fromDate;
  public toDate;
  public filterby;
  public dischargebutton: boolean;
  public floorarray: any;
  public floorvalue;
  public tabular_format: boolean = true;
  public view_type;
  public graphicalformat: boolean = false;
  public graph_defaultimg: boolean = false
  public hospitalId;
  public splName: string;
  public patientList: any[] = [];
  public userInfo: any;
  public userid: any;
  public firstName: string;
  public lastName: string;
  dtOptions: any = {};
  public patientType;
  public display;
  public doctorList = [];
  public doctor;
  public doctorId;
  public doctorName;
  public ipaddress;
  public splUrl;
  public filterbasedurl;
  public splList = [];
  public masterSelected: boolean = false;
  public checklistid: any = [];
  public checklist: any = [];
  public patientCount: any;
  public dislist: any = [];
  public sur_flag: boolean = true;
  public create_flag: boolean = true;
  editbuttondie: boolean = false;
  newbuttondie: boolean = false;
  printbuttondie: boolean = false;
  viewbuttondie: boolean = false;
  deletebuttondie: boolean = false;
  public wardTranfer;
  public wardTransfer_flag: boolean = false;
  public departmentList = [];
  public ward_list = [];
  public bedList = [];
  public department;
  public ward_id;
  public bed_no;
  public admission_id;
  public show_bed_details: boolean = false;
  public readonlyFlag: boolean;
  public send_bed_no;
  public show_bednos: boolean = false;
  public bedOccList: any[];
  public updateFlag: boolean;
  public count;
  public pagename;
  public disTimeLineList = [];

  constructor(public doctormessageservice: MenuViewService, private http: HttpClient, public toastr: ToastrService, public messageservice: MenuViewService, public masterData: MasterHelperService, private cdr: ChangeDetectorRef,public nurseservice: MenuViewService) {
    Helper_Class.setInpatientFlow(undefined);
    Helper_Class.setSpecializations(undefined);
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("New");
    Doc_Helper.setMobile_no(null);
    this.readonlyFlag = true;
    this.updateFlag = true;
  }

  async ngOnInit(): Promise<void> {
    this.floorvalue="All";
    this.selectvalue = "mrno";
    this.wardTransfer_flag=false
    if (Helper_Class.getdisplaypagename() == "wardTransfer") {
      this.pagename = "Ward Transfer";
      this.wardTransfer_flag=true;
    } else {
      this.pagename = "Patient list";
      this.wardTransfer_flag=false;
    }
    this.floorvalue = "All";
    this.view_type = "graph";
    this.wardTranfer = Helper_Class.getReportType();
  
    if (Master_Helper.getMasterWardData() != undefined) {
      this.ward_list = Master_Helper.getMasterWardData().wards;
    } else {
      await this.masterData.getWardData();
      this.ward_list = Master_Helper.getMasterWardData().wards;
    }

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "type": "date", "orderable": false, "targets": [0] },
        { "targets": 4, "type": "date-eu" },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";
    this.filterbasedurl = ipaddress.getIp + "inpat/gibf ";

    this.userInfo = Helper_Class.getInfo();
    this.userid = this.userInfo.user_id;
    this.docRegId = this.userid;
    this.hospitalId = Helper_Class.getHospitalId();
    this.patientType = "self";
    this.ipaddress = Helper_Class.getIPAddress();
    this.getDoctors();
    this.getSpecializations();
    this.getCurrentDate()
    if (Helper_Class.getInfo().dis_summ_create == "1") {
      this.create_flag = false;
    }
    if (Nurse_Helper.getmodules() != null) {
      if (this.wardTranfer == 'wardTransfer') {
        for (var i = 0; i < Nurse_Helper.getmodules().length; i++) {
          if (Nurse_Helper.getmodules()[i].module_id == "151") {
            if (Nurse_Helper.getmodules()[i].edit == "1") {
              this.editbuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].create == "1") {
              this.newbuttondie = true;
              this.create_flag = false;
            }
            if (Nurse_Helper.getmodules()[i].delete == "1") {
              this.deletebuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].print == "1") {
              this.printbuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].view == "1") {
              this.viewbuttondie = true;
            }
          }        
        }
      } else {
        for (var i = 0; i < Nurse_Helper.getmodules().length; i++) {
          if (Nurse_Helper.getmodules()[i].module_id == "1") {
            if (Nurse_Helper.getmodules()[i].edit == "1") {
              this.editbuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].create == "1") {
              this.newbuttondie = true;
              this.create_flag = false;
            }
            if (Nurse_Helper.getmodules()[i].delete == "1") {
              this.deletebuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].print == "1") {
              this.printbuttondie = true;
            }
            if (Nurse_Helper.getmodules()[i].view == "1") {
              this.viewbuttondie = true;
            }
          }
          if (Nurse_Helper.getmodules()[i].module_id == "3") {
            this.dischargebutton = true;
          }
        }
      }      
    }
    this.viewChange(this.view_type);
    this.Filterbydata(this.selectvalue)
  }

  getCurrentDate() {
    this.CurrentDatetime = Master_Helper.getMasterCurrentDate().current_date;
    this.currentDate = Master_Helper.getMasterCurrentDate().current_date;
    this.currentTime = Master_Helper.getMasterCurrentDate().current_time;
    this.get_date =
      Master_Helper.getMasterCurrentDate().current_date.split('-');
    this.currentYear = this.get_date[0];
    Helper_Class.setKey(
      Master_Helper.getMasterCurrentDate().provider +
      Master_Helper.getMasterCurrentDate().clientid +
      Master_Helper.getMasterCurrentDate().messageid
    );
    Helper_Class.setIV(Master_Helper.getMasterCurrentDate().random);
    //  this.admission_date =  Date_Formate(this.currentDate);
    var check1 = Time_Formate(this.currentTime);
    this.currentMaxDate = this.currentDate;
    this.fromDate=this.currentDate;
    this.toDate = this.currentDate;
    //this.getSpecializations();
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, { hptl_clinic_id: this.userInfo.hptl_clinic_id },
      { headers: headers }).subscribe(
        data => {
          this.splList = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.specializations != null)
            this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }
  getPatients() {
    this.patientList = [];
    var data = null;
    var send_data = {
      doc_reg_id: this.doctor,
      hptl_clinic_id: this.hospitalId,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "doctor",
      floor: this.floorvalue
    }
    console.log("INPATIENT send_data " + JSON.stringify(send_data))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      (send_data),
      { headers: headers })
      
      .subscribe(
        response => {
          this.floorarray = [];
          this.patientList = [];
          var data = JSON.parse(JSON.stringify(response));
          console.log("check datta " + JSON.stringify(data));
          if (data.floors != undefined) {
            this.floorarray.push("All")
            for (var k = 0; k < data.floors.length; k++) {
              this.floorarray.push(data.floors[k])
            }

          }
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }

              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc + ".";
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }
              // var disflag;
              // if(values.discharge_date !=undefined){
              //   disflag = true;
              // } else {
              //   disflag = false;
              // }
              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;


                }
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var height,height_measure,weight,weight_measure,bmi,bmr;
              if (values.height != undefined) {
                height =  values.height
              } else {
                height = ""
              }
              if (values.height_measure != undefined) {
                height_measure =  values.height_measure
              } else {
                height_measure = ""
              }
              if (values.weight != undefined) {
                weight =  values.weight
              } else {
                weight = ""
              }
              if (values.weight_measure != undefined) {
                weight_measure =  values.weight_measure
              } else {
                weight_measure = ""
              }
              if (values.bmi != undefined) {
                bmi =  values.bmi
              } else {
                bmi = ""
              }
              if (values.bmi != undefined) {
                bmi =  values.bmi
              } else {
                bmi = ""
              }
              if (values.bmr != undefined) {
                bmr =  values.bmr
              } else {
                bmr = ""
              }
              var gender, age;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender)
              }
              var gender_age,address1,address2,location,district,zipcode,state,country,locationcheck,districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + " / " + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }

              if (values.address2 != null && values.address2 != undefined && values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1)+ ",";
                if (values.address2 != " " && values.address2 != "null") {
                  if (encrypt_decript.Decript(values.address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(values.address2)+",";
                  }
                }
              } else if (values.address1 != undefined) {
                  address1 = encrypt_decript.Decript(values.address1)+",";
              } else if (values.address2 != undefined) {
                  address2 = encrypt_decript.Decript(values.address2)+",";
              }
    
              if(values.location != undefined){
                locationcheck = values.location
              }
              if(values.district != undefined){
                districtcheck = values.district
              }
    
              if(values.zipcode != undefined) {
                zipcode = encrypt_decript.Decript(values.zipcode)+",";
              }
    
              if (values.state != undefined) {
                state = values.state+"-";
              }   
              
              if (values.country != undefined) {
                country = values.country+".";
              }
           
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              if (this.wardTranfer == 'wardTransfer') {
                this.wardTransfer_flag = true;
              } else if (this.wardTranfer == 'Patients') {
                this.wardTransfer_flag = false;
              }

              var admission_date,relation_id;
              if (values.admission_date != undefined) {
                admission_date = values.admission_date;
              }

              if (values.relation_id != undefined) {
                relation_id = values.relation_id;
              }
          
              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + pname,
                patient_namevalue: pname,
                fullname: fullname,
                height:height,
                weight:weight,
                age:age,gender:gender,
                bmi:bmi,bmr:bmr,height_measure:height_measure,weight_measure:weight_measure,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                admission_date: admission_date,
                relation_id: relation_id,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,               
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,
                speciality: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                doc_reg_id: values.doc_reg_id,
                gender_age: gender_age,
                address1: address1,
                address2: address2,                 
                locationcheck: locationcheck, 
                districtcheck: districtcheck,
                zipcode: zipcode,
                state: state,
                country: country,
                hosp_inp_idvalue: hosp_inp_id2, 
                mobilenumber: encrypt_decript.Decript(values.mobile),
                patient_id:values.patient_id,
                mlc:values.mlc_type
              });
              Helper_Class.setinpatientfilterdata(this.patientList);
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList));
            }
          } else {  
           // this.count = "No patient(s)";
            this.count = "0"
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getWardDetails(person) {
    for (var i = 0; i < this.patientList.length; i++) {
      if (this.patientList[i].inpatient_id == person.inpatient_id) {
        this.admission_id = this.patientList[i].inpatient_id;
        this.department = this.patientList[i].speciality;
        this.ward_id = this.patientList[i].ward_id;
        this.getBedDetails(this.admission_id);
        this.getBedList(this.ward_id);
        this.bed_no = this.patientList[i].bed_no;
      }
    }
  }

  changePatientType() {
    this.getPatients();
  }

  getDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep',
      {
        country: "IN",
        reg_id: this.userInfo.user_id,
        imei: this.ipaddress,
        type: "front-desk",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != null) {
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
            }
            this.doctor = this.doctorList[0].Doc_ID;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }



  viewPatient(patient_id,inpatient_id, mobile, regid, fname, mname, lname, speciality, list) {
    console.log("selected list" + JSON.stringify(list.inpatient_id))
    console.log("selected inpatient_id" + JSON.stringify(list))
  
      Doc_Helper.setMrno(patient_id);
      Doc_Helper.setFirstname(fname);
      Doc_Helper.setMiddlename(mname);
      Doc_Helper.setLastname(lname);
      Doc_Helper.setClient_id(regid);
      Doc_Helper.setSearchFlag("true");
      Doc_Helper.setClient_type("Existing");
      Doc_Helper.setMobile_no(mobile);
      Doc_Helper.setInpatientId(null);
      Doc_Helper.setInpatientId(list.inpatient_id);
      Doc_Helper.setInpatientFlow("list");
      Doc_Helper.setFlowName("patientList");
      var spa_id
      for (var i = 0; i < this.splList.length; i++) {
        if (this.splList[i].spl_name == speciality) {
          spa_id = this.splList[i].spl_id;
        }
      }
      var specializations = [{
        spl_id: spa_id,
        spl_name: speciality
      }]
      if (Helper_Class.getInfo().user_type == 'nurse') {
        Helper_Class.setInpatientFlow("nurse");
      } else {
        Helper_Class.setInpatientFlow("front-desk");
      }
      var data={
      prov_id: list.user_id,
      client_id: list.client_reg_id,
      Client_id: list.client_reg_id,
      relation_id: '1',
      salutation: list.clnt_sal,
      Gender_data: list.gender,
      client_name: list.patient_name,
      Age_data: list.age,
      app_id: list.app_id,  
      patient_id: list.patient_id,
      height:list.height,
      weight:list.weight,
      height_measure:list.height_measure,
      weight_measure: list.weight_measure,
      bmi: list.bmi,
      bmr:list.bmr,
       rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: list.clnt_dob,
      spl_id: list.spl_id,
      hptl_clinic_id: list.hospital_id, 
      mobile: list.mobile_no,
      spl: list.spl,
      temparature: list.temp_txt,
      // height: list.height_txt,
      // height_measure: list.hmeasure,
      // weight: list.weight_txt,
      // weight_measure: list.wmeasure,
      // bmi: list.bmi_txt,
      patientname:list.patient_name,
      symptoms: list.clnt_symptom,
      medicare_name: list.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'Inpatinet',     
      flag: 'inpat', pat_id: list.patient_id, inpatient_id: list.inpatient_id, doc_reg_id: list.doc_reg_id,
      }
      Helper_Class.setClientInfo(data)
      Diet_Helper.setClient_Info(data);
      console.log("log info --"+JSON.stringify(Diet_Helper.getClient_Info()))
       Helper_Class.setSpecializations(specializations);
      this.messageservice.sendMessage("dietaccess");
      //this.nurseservice.sendMessage("dietaccess");
    
  }

  changeDoctor(e) {
    Nurse_Helper.setdoctor(undefined);
    Nurse_Helper.setdoctor(e);
    this.doctor = e;
    this.getPatients();
  }

  checkUncheckAll(data) {
    this.masterSelected = data.checked;
    for (var i = 0; i < this.patientList.length; i++) {
      this.patientList[i].selected = this.masterSelected;
    }
  }

  isInpatSelected(list) {
    if (list.selected == true) {
      for (var i = 0; i < this.patientList.length; i++) {
        if (list.inpatient_id == this.patientList[i].inpatient_id) {
          this.patientList[i].selected = false;
        }
      }
    } else {
      for (var i = 0; i < this.patientList.length; i++) {
        if (list.inpatient_id == this.patientList[i].inpatient_id) {
          this.patientList[i].selected = true;
        }
      }
    }
    
  }

  closeInpatient() {
    if (this.patientList.length != 0) {
      this.dislist = [];
      for (var i = 0; i < this.patientList.length; i++) {
        if (this.patientList[i].selected == true) {
          this.dislist.push(this.patientList[i].inpatient_id)
        }
      }
      if (this.dislist.length != 0) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'inpat/cip',
          ({ inpat_ids: this.dislist }),
          { headers: headers })
          .subscribe(
            response => {
              var data = JSON.parse(JSON.stringify(response));
              if (data.key == "1") {
                this.toastr.success("Successfully closed inpatient record(s) ");
                this.getPatients();
              } else {
                this.toastr.error("Unable to closed inpatient record(s), try later");
              }

            });
      } else {
        this.toastr.error("Select patient");
      }
    }
  }



  getBedDetails(inpatient_id) {
    console.log("inpatient_id",inpatient_id);
    if (this.admission_id != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/gbdinp',
        ({
          hptl_clinic_id: this.hospitalId,
          inpatient_id: inpatient_id,
        }),
        { headers: headers }
      )
        .subscribe((data) => {
          this.bedOccList = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.bed_details != undefined && obj.bed_details.length != 0) {
            for (var i = 0; i < obj.bed_details.length; i++) {
              var bdate = obj.bed_details[i].occup_date.split('-');
              this.bedOccList.push({
                ward: obj.bed_details[i].ward_name,
                bedno: obj.bed_details[i].bed_no,
                date: bdate[2] + '-' + bdate[1] + '-' + bdate[0],
                status: obj.bed_details[i].status,
              });
            }
          }
          this.show_bednos = this.bedOccList.length != 0 ? true : false;
        });
    }
  }

  getBedList(ward_id) {
    this.bedList = []; // Initialize bedList array
    this.show_bed_details = this.bedList.length !== 0; // Update show_bed_details condition
    this.bed_no = undefined;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp.toString() + 'inpat/gbw',
      ({
        hptl_clinic_id: this.hospitalId,
        ward_id: ward_id,
      }),
      { headers: headers }
    ).subscribe((data) => {
      var obj = JSON.parse(JSON.stringify(data));
      var flag = this.isEmptyObject(obj);
      if (obj != undefined && obj != null && obj != '' && !flag) {
        if (obj.bed_details.length != 0) {
          this.show_bed_details = obj.bed_details.length !== 0; // Update show_bed_details condition
          for (var i = 0; i < obj.bed_details.length; i++) {
            var pat_name,
              fontWeight = '',
              flag: boolean;
            pat_name =
              obj.bed_details[i].name == 'Vacant' ? '#000' : 'darkgreen';
            fontWeight = obj.bed_details[i].name == 'Vacant' ? '400' : '600';
            flag = obj.bed_details[i].name == 'Vacant';
            this.bedList.push({
              bed_no:
                obj.bed_details[i].bed_no != undefined
                  ? obj.bed_details[i].bed_no
                  : '--',
              pat_name: obj.bed_details[i].name,
              bed:
                obj.bed_details[i].bed_no + ' - ' + obj.bed_details[i].name,
              bed_img: '../../../assets/ui_icons/bed_icon.svg',
              color: pat_name,
              fontWeight: fontWeight,
              flag: flag,
            });
          }
        }
      } else {
        this.show_bed_details = this.bedList.length !== 0; // Update show_bed_details condition
      }
      // console.log('this.bedList ==> ' + JSON.stringify(this.bedList));
    });
  }


  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  changeBed(e) {
    this.updateFlag = false;
    if (e.flag == true) {
      this.bed_no = e.bed_no;
      this.send_bed_no = e.bed_no;
    } else {
      this.toastr.error('Please select vacant bed');
    }
  }

  updateWardDetails() {
    var flag = false;
    if (this.admission_id == "" || this.admission_id == undefined) {
      this.toastr.error('Admission ID Not Found');
      flag = true;
    }
    if (this.ward_id == "" || this.ward_id == undefined) {
      this.toastr.error('Ward ID Not Found');
      flag = true;
    }

    if (this.bed_no == "" || this.bed_no == undefined) {
      this.toastr.error('Bed ID Not Found');
      flag = true;
    }
    if (flag == false && this.updateFlag == false) {
      if (
        this.admission_id != '' && this.admission_id != undefined &&
        this.ward_id != '' && this.ward_id != undefined &&
        this.bed_no != '' && this.bed_no != undefined
      ) {
        var sendData = {
          country: "IN",
          admission_id: this.admission_id,
          ward_id: this.ward_id,
          bed_no: this.bed_no
        }

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + 'inpat/swtd', sendData,
          { headers: headers })
          .subscribe(
            data => {
              var obj = JSON.parse(JSON.stringify(data));
              console.log(obj);
              if (obj != undefined && obj.key == '1') {
                this.toastr.success("Ward and bed details updated successfully");
                this.getPatients();
                this.updateFlag = true;
              } else {
                this.toastr.error("Unable to update Ward and bed details");
              }
            });
      }
    } else {
      this.toastr.error("Change Ward or Bed Details");
    }
  }

  viewChange(value) {
    if (value == "graph1") {
      this.graphicalformat = true;
      this.graph_defaultimg = false;
      this.tabular_format = false;

    } else if (value == "tabular") {
      this.graphicalformat = false;
      this.graph_defaultimg = false;
      this.tabular_format = true;  
      this.dischargebutton=true;
      this.wardTransfer_flag=false;   
    } else {
      this.tabular_format = false;
      this.graphicalformat = false;
      this.graph_defaultimg = true;
      this.dischargebutton=false;
      this.wardTransfer_flag=false;
     
    }
  }
  changefloorvalue(value) {
    this.floorvalue = value;
    this.getPatients();
  }
  //  this.doctormessageservice.sendMessage("docpresnocase");
  // Doc_Helper.setAppFlow('apptList')
  write_prescription() {
    this.doctormessageservice.sendMessage("docpresnocase");
    Doc_Helper.setAppFlow('apptList')
  }
  Filterbydata(data) {
    this.mrno="";
    this.name="";
    this.location="";
    this.mobile= "";

    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "location") {
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = true;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "date") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = false;
      this.filtercheck();
    }
    if (data == "mobile") {
      this.fdateflag = false;
      this.todateflag = false;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
    }
    this.patientList = Helper_Class.getinpatientfilterdata();
    // if(data == "mrno"){
    //   this.mrnoflag =true;
    // }
  }

  selectFromDate(e) {
    this.fromDate = e;
  }

  selectToDate(e) {
    this.toDate = e;
  }
  fnameToUpper() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }
  fnameToUpperlocation() {
    if (this.location != null)
      this.location = this.location.toLocaleUpperCase();
  }
  mrnobased() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }

  filtercheck() {
    console.log("chekc value  --" + JSON.stringify(this.patientList))
    if (this.nameflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.patient_namevalue.toLowerCase().includes(this.name.toLowerCase()));
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      // this.patientList=[];
      // this.patientList=filteredArray;
      // this.mrno
      this.filtermethod("name", this.name, '')
    }
    if (this.mobileflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.mobilenumber == this.mobile);
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      // this.patientList=[];
      // this.patientList=filteredArray;
      this.filtermethod("mobile", this.mobile, '')
      // this.mrno
    }


    if (this.locationflag == true) {
      let filteredArray1 = this.patientList.filter(obj => obj.locationcheck.trim().toLowerCase() === this.location.trim().toLowerCase());
      console.log("filteredArray loctaion  --" + JSON.stringify(filteredArray1))
      // this.patientList=[];
      // this.patientList=filteredArray1;
      this.filtermethod("location", this.location, '')
      // this.mrno
    }

    if (this.mrnoflag == true) {

      let filteredArray = this.patientList.filter(obj => obj.hosp_inp_idvalue.toLowerCase() === this.mrno.toLowerCase());

      console.log("filteredArray mrno  --" + JSON.stringify(filteredArray))
      this.patientList = [];
      this.patientList = filteredArray;
      this.filtermethod("mr_no", this.mrno, '');
      // this.mrno
    }
    if (this.fdateflag == true && this.todateflag == true) {
      let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
      let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));

      let filteredArraycheck = this.patientList.filter(obj => {
        // Convert admit_date to a Date object
        let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));

        // Check if admit_date is between fromDate and toDate
        return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
      });
      console.log("filteredArray date   --" + JSON.stringify(filteredArraycheck));
      // this.patientList=[];
      // this.patientList=filteredArraycheck;
      this.filtermethod("date", '', '');
      // this.mrno
    }

  }

  getdatavalue(value) {
   
    if (!this.name || this.name.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();

      // Handle the empty value case
    } else {
      console.log('Input value:', this.name);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }
  getdatavaluemrno(value) {
  
    if (!this.mrno || this.mrno.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();

      // Handle the empty value case
    } else {
      console.log('Input value:', this.mrno);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }

  }
  getdatavaluelocation(value) {
   
    if (!this.location || this.location.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();

      // Handle the empty value case
    } else {
      console.log('Input value:', this.location);
      if (this.location.length >= 3) {
        this.filtermethod("location", this.location, '');
      }   
    }
  }
  getdatamobilenumber(vlaue) {
   
    if (!this.mobile || this.mobile.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();

      // Handle the empty value case
    } else {
      console.log('Input value:', this.mobile);
      this.filtermethod("mobile", this.mobile, '');
      // Your logic for non-empty value
    }
  }
  filtermethod(type, name, name1) {
    var senddata;
    if (type == "date") {
      senddata = {
        filter: type,
        fdate: (this.fromDate),
        tdate: (this.toDate),hptl_clinic_id: this.hospitalId
      }
    }
    
    if (type == "name") {
      senddata = {
        filter: type,
        fname: encrypt_decript.Encript(this.name).toString(),hptl_clinic_id: this.hospitalId
      }
    }
    if (type == "mobile") {
      senddata = {
        filter: type,
        mobile:  encrypt_decript.Encript(this.mobile).toString(),
        hptl_clinic_id: this.hospitalId
        // tdate:Date_Formate(this.toDate)
      }
    }
    if (type == "mr_no") {
      senddata = {
        filter: type,
        mr_no: this.mrno, hptl_clinic_id: this.hospitalId
        // tdate:Date_Formate(this.toDate)
      }
    }
    if (type == "location") {
      senddata = {
        filter: type,
        location: this.location, hptl_clinic_id: this.hospitalId
        // tdate:Date_Formate(this.toDate)
      }
    }
    console.log("filter basedheck ---" + JSON.stringify(senddata))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.filterbasedurl, senddata,
      { headers: headers }).subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(data))
          this.patientList = [];
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc;
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }
              // var disflag;
              // if(values.discharge_date !=undefined){
              //   disflag = true;
              // } else {
              //   disflag = false;
              // }
              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;


                }
              }
              var height,height_measure,weight,weight_measure,bmi,bmr;
              if (values.height != undefined) {
                height =  values.height
              } else {
                height = ""
              }
              if (values.height_measure != undefined) {
                height_measure =  values.height_measure
              } else {
                height_measure = ""
              }
              if (values.weight != undefined) {
                weight =  values.weight
              } else {
                weight = ""
              }
              if (values.weight_measure != undefined) {
                weight_measure =  values.weight_measure
              } else {
                weight_measure = ""
              }
              if (values.bmi != undefined) {
                bmi =  values.bmi
              } else {
                bmi = ""
              }
              if (values.bmi != undefined) {
                bmi =  values.bmi
              } else {
                bmi = ""
              }
              if (values.bmr != undefined) {
                bmr =  values.bmr
              } else {
                bmr = ""
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var gender, age, location, district;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender)
              }
              var gender_age, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + "/" + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }
              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              if (this.wardTranfer == 'wardTransfer') {
                this.wardTransfer_flag = true;
              } else if (this.wardTranfer == 'Patients') {
                this.wardTransfer_flag = false;
              }
          
              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bmi:bmi,bmr:bmr,height_measure:height_measure,weight_measure:weight_measure,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + " " + pname,
                patient_namevalue: pname,
                fullname: fullname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                ward_name: wardname,
                bed_no: bedno,
                age:age,
                gender:gender,
                mobile: values.mobile,
                patient_id: values.patient_id,
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.inp_hosp_id,
                speciality: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                gender_age: gender_age,           
                // districtcheck: districtcheck, 
                // locationcheck: locationcheck, 
                // hosp_inp_idvalue: hosp_inp_id2, 
                // mobilenumber: encrypt_decript.Decript(values.mobile),
                // mlc:values.mlc_type
              });
             // Helper_Class.setinpatientfilterdata(this.patientList)
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList))
            }
          } else {
           // this.count = "No patient(s)"
           this.count = "0"
          }
          // var obj = JSON.parse(JSON.stringify(data));
          // if (obj.specializations != null) 
          //   this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }
}


