<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Concent Form</h5>
          </div>
          <div class="headerButtons">
            <a style="margin-right: 5px"  (click)="print_area()"><img
              src="../../../assets/img/printer.svg" style="height: 25px"/></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div id="inpatientConcentForm">
          <div [hidden]="true">            
            <table style="width: 100%">
              <tr>
                <td style="text-align: center">
                  <p style="font-size: 24px">
                    <strong>{{ hospital_name }}</strong>
                  </p>
                  <p>{{ hospitalAddress }}</p>
                  <p>Tel: {{ hosp_tel }} </p>
                  <strong>PRIMARY CASE RECORD</strong><span>(To be filled in Capital Letters only)</span>
                </td>
              </tr>
            </table>
          </div>
          <div style="border: 1px solid #b5b5b6; margin-left: 5px; font-family: Arial !important;font-size:12px;letter-spacing: 1px;" class="user_info">
            <div>             
              <table style="margin-top: 20px;width:100%; text-align: left;">
                <tr>
                  <td class="align_left" >
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Hospital No :
                    </strong><span style="font-size: 11px;">{{ hospital_id }}</span>
                  </td>                 
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">D.O.A : 
                    </strong><span style="font-size: 11px;">{{ admission_date }}</span> 
                  </td>                 
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Time : 
                    </strong><span style="font-size: 11px;">{{ admission_time }}</span>
                  </td>
                </tr>                       
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>              
                <tr>
                  <td>
                     &nbsp; 
                  </td>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">D.O.D : 
                    </strong><span style="font-size: 11px;">{{ discharge_date }}</span>
                  </td>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Time : 
                    </strong><span style="font-size: 11px;">{{ discharge_time }}</span>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>      
                <tr>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Name : 
                    </strong><span style="font-size: 11px;">{{ patient_name }}</span>
                  </td>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Age/Gender :
                    </strong><span style="font-size: 11px;">{{ age }} / {{gender}}</span>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>      
                <tr>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Father/Husbend Name : 
                    </strong><span style="font-size: 11px;">{{ guardian }}</span>
                  </td>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Occupation : 
                    </strong><span style="font-size: 11px;">{{ occupation }}</span>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>      
                <tr>
                  <td class="align_left">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Address : 
                    </strong><span style="font-size: 11px;">{{ address }}</span>
                  </td>
                </tr> 
                <tr>
                  <td>
                    &nbsp;
                  </td>
                </tr>                    
              </table>
            </div>
            <div class="para">
              <table>
                <tr>
                  <td>
                    <p style="font-size: 13px;text-indent: 50px;">I do hereby give my concent for any investigation,operation,anesthesia and treatment consider necessary in the patient</p> 
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;
                  </td>
                </tr>               
              </table>                              
            </div>
            <div class="details">
              <table style="width:100%;">               
                <tr>                  
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Consultant :
                    </strong> <span style="font-size: 11px;">{{ consultant }}</span>
                  </td>
                  <td style="width:33%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">1. OPINION : 
                    </strong>
                  </td>
                  <td style="width:33%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">7. RESPIRATOR : 
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>               
                <tr>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Room No :
                    </strong><span style="font-size: 11px;">{{ room_no }}</span>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">2. CUTDOWN : 
                    </strong>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">8. L.P : 
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>           
                <tr>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Allergic to : 
                    </strong> <span style="font-size: 11px;">{{ allergy }}</span>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">3. o2 : 
                    </strong>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">9. BANDAGE :
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>           
                <tr>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">Blood Group :
                    </strong> <!-- {{ blood_group }} -->
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">4. SUCTION : 
                    </strong>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">10. E.C.G :
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>           
                <tr>                 
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">RH Factor : 
                    </strong> 
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">5. FOLYE'S RYLES : 
                    </strong>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">11. BIOSPSY : 
                    </strong>
                  </td>               
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>           
                <tr>
                  <td> &nbsp; </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">6. MONITORING :
                    </strong>
                  </td>
                  <td style="width: 34%; text-align:left;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial">12. ANESTHESIA ETC :
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>           
              </table>
            </div>                       
            <div>
              <table class="table" style="margin-bottom: 30px;">
                <tr>
                  <td class="align_left">
                    <br>
                    <div> 
                      <p style="font-size: 11px;">1.{{ hospital_name }} சட்ட திட்டங்களுக்கும், விதிகளுக்கும் ஒப்புக்கொண்டு டாக்டர் ஆலோசனைப்படி நடக்கவும் சம்மதிக்கிறேன்.</p>
                      <p style="font-size: 11px;">2.மேற்படி ஆஸ்பத்திரியில் பரிசோதனை செய்வதற்கும். சிகிச்சை செய்வதற்கும், மயக்க மருந்து கொடுப்பதற்கும், ஆபரேஷன்' செய்வதற்கும் நோயாளியின் நலனுக்காக என்பதை அறிந்து நான் முழுமனதுடன் ஒப்புதல் அளிக்கிறேன்.</p> 
                      <p style="font-size: 11px;">3.எங்களுடைய உடைமைகள் மற்றும் பொருட்களை நாங்கள் எங்கள் பொறுப்பில் பார்த்துக் கொள்ள சம்மதிக்கிறேன்.</p>
                      <p style="font-size: 11px;">4.எங்களுடைய உடைமைகள் மற்றும் பொருட்களுக்கு நிர்வாகஸ்தாரோ, உரிமையாளரோ பொறுப்பல்ல என்பதை இதன் மூலம் அறிகிறேன்.</p>
                      <p style="font-size: 11px;">5.எங்களுக்கு ஏற்படும் நஷ்டத்திற்கு நாங்களே பொறுப்பு என்பதை அறிந்து இங்கு அட்மிஷன் செய்ய சம்மதிக்கிறேன்.</p>
                      <p style="font-size: 11px;">6.போலீஸ் /கோர்ட் சம்பந்தப்பட்ட (M.L.C.) கேஸ் அல்ல என்று உறுதியளித்து அட்மிஷன் செய்கிறேன்.சகல நஷ்டத்திற்கு நானே பொறுப்பு என்பதை இதன் மூலம் உறுதியளிக்கிறேன்.</p>
                    </div>                    
                  </td>
                </tr>
              </table>
            </div>
            <div class="footer">
              <table class="container">
                <tr>
                  <td style="width: 30%;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial;
                                    text-align: center;">
                                    <span style="text-align: center;">Me</span>
                    </strong>
                  </td>
                  <td style="width: 35%;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial;
                                    text-align: center;">
                                    <span style="text-align: center;">Medical Officer Signature and date</span>                                    
                    </strong>
                  </td>
                  <td style="width: 35%;">
                    <strong style=" letter-spacing: 0.0.8rem;
                                    font-size: 0.8rem;
                                    font-weight: 545;
                                    font-family: Arial;
                                    text-align: center;" >
                                    <span style="text-align: center;">Parent or Guardian Signature and date</span>
                    </strong>
                  </td>
                </tr>
                <br>
              </table>
            </div>
          </div> 
        </div>   
      </mdb-card-body>
    </mdb-card>
  </div>
</div>




