import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, day_nameid, Day_Return, seesion_nameid, Session_Return, Time_Formate } from '../../../assets/js/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
declare var $ : any; 
@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit {

  public userInfo;
  public hospitalDetails;
  public consultantFName;
  public consultantMName;
  public consultantLName;
  public consultantLicense;
  public consultantSpeciality;
  public consultantDays;
  public consultantSession;
  public consultantLoginmap;
  public consultantList;
  public consultantFee;
  public consultantMobile;
  public consultantPwd;
  public consultantGender;
  public consultantQualification;
  public consultantExp; 
  public timeStarts1: string;
  public timeStarts2: string;
  public timeStarts3: string;
  public timeEnds1: string;
  public timeEnds2: string;
  public timeEnds3: string;
  public timeStartsFrom = [];
  public timeEndsAt = [];
  public splList;
  public createdBy;
  public editFlag:boolean = false;
  public editList;
  public sessionList = [];
  public showCreateEditFlag:boolean = false;
  public ynopt1:boolean;
  public patientList = [];
  
  masterSelected: boolean = false;
  dtOptions: DataTables.Settings = {};

  constructor(public http:HttpClient,public toastr:ToastrService) { 
    for (var i = 1; i <= 12; i++) {//Starts time
      if (i < 10) 
        this.timeStartsFrom.push("0" + i);
      else 
        this.timeStartsFrom.push(i);
    }

    for (var i = 0; i <= 55; i += 5) {//end time
      if (i < 10)
        this.timeEndsAt.push("0" + i);
      else 
        this.timeEndsAt.push(i);
    }

    this.timeStarts1 = "06";
    this.timeStarts2 = "00";
    this.timeStarts3 = "AM";
    this.timeEnds1 = "08";
    this.timeEnds2 = "00";
    this.timeEnds3 = "AM";
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 4] },
        { "width": "100px", "targets": 8 },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    // $('#consulPassword').attr("autocomplete", "off"); 
    this.userInfo = Helper_Class.getInfo();
    this.createdBy = this.userInfo.middle_name != undefined ? this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name : this.userInfo.first_name + " " + this.userInfo.last_name;
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.getSpecializations();
    this.getConsultants();
    this.getPatient();
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/getspecialization/", 
      { headers: headers }).subscribe(
        response => {
          this.splList = [];
          var data = JSON.parse(JSON.stringify(response));
          if (data.specialization != null)
            this.splList = data.specialization;
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getConsultants(){
    var send_data ={
      hptl_clinic_id:this.hospitalDetails
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gcd',
    (send_data), { headers: headers }).subscribe(
      response => {  
        var data = JSON.parse(JSON.stringify(response));
        if(data != undefined){
          if(data.doctors != undefined){
            var obj = data.doctors;
            this.consultantList = [];
  
            for(var j = 0; j < obj.length; j++){
              var name;
              name = obj[j].middle_name != undefined ? obj[j].first_name + " " + obj[j].middle_name + " " + obj[j].last_name : obj[j].first_name + " " + obj[j].last_name; 

              this.consultantList.push({
                doc_reg_id:obj[j].doc_reg_id,
                first_name:obj[j].first_name,
                middle_name:obj[j].middle_name,
                last_name:obj[j].last_name,
                doc_name:name,
                license_code:obj[j].license_code,
                mobile:obj[j].mobile,
                gender:obj[j].gender,
                password:obj[j].password,
                spl_id:obj[j].spl_id,
                spl_name:obj[j].spl_name,
                consultation:obj[j].consultation,
                qualification:obj[j].qualification,
                experience:obj[j].experience,
                clients:obj[j].clients
              });
            }
          }
        }
      });
  }

  getPatient(){
    var send_data = {
      filter:"In-patient",
      hptl_clinic_id:this.hospitalDetails,
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/clndet',
    (send_data), { headers: headers }).subscribe(
      response => {  
        var obj = JSON.parse(JSON.stringify(response));
        if(obj != undefined){
          this.patientList = [];
          for(var i = 0; i < obj.patient_list.length; i++){
            var mobile,name,rel_id,sub_id;

            if(obj.patient_list[i].middle_name != undefined && encrypt_decript.Decript(obj.patient_list[i].middle_name) != ""){
              name = encrypt_decript.Decript(obj.patient_list[i].first_name) + " " + encrypt_decript.Decript(obj.patient_list[i].middle_name) + " " + encrypt_decript.Decript(obj.patient_list[i].last_name);
            }else{
              name = encrypt_decript.Decript(obj.patient_list[i].first_name) + " " + encrypt_decript.Decript(obj.patient_list[i].last_name);
            }

            if(obj.patient_list[i].mobile != undefined && encrypt_decript.Decript(obj.patient_list[i].mobile) != ""){
              mobile = encrypt_decript.Decript(obj.patient_list[i].mobile);
            }else{
              mobile = "";
            }

            if(obj.patient_list[i].relation_id != undefined && obj.patient_list[i].relation_id != "" && obj.patient_list[i].relation_id != null){
              rel_id = obj.patient_list[i].relation_id;
            }else{
              rel_id = "1";
            }

            if(obj.patient_list[i].sub_rel_id != undefined && obj.patient_list[i].sub_rel_id != "" && obj.patient_list[i].sub_rel_id != null){
              sub_id = obj.patient_list[i].sub_rel_id;
            }else{
              sub_id = undefined;
            }

            this.patientList.push({
              client_reg_id: obj.patient_list[i].client_reg_id,
              profile_image: ipaddress.Ip_with_img_address + obj.patient_list[i].profile_image,
              patient_name : name,
              relation_id:rel_id,
              sub_rel_id:sub_id,
              location : obj.patient_list[i].location_desc,
              city : obj.patient_list[i].city_desc,
              contact:mobile,
              isSelected:false,
            });
          }
        }
      });
  }

  changeType(type, value) {
    if (value == "yes"){
      this.masterSelected = false;
      this.ynopt1 = true;
      for(var i = 0 ; i < this.patientList.length; i++){
        this.patientList[i].isSelected = false;
      }
    }else{
      this.masterSelected = true;
      for(var i = 0 ; i < this.patientList.length; i++){
        this.patientList[i].isSelected = false;
      }
      this.ynopt1 = false;
    }
  }

  changeSession() {
    if (this.consultantSession == "Morning") {
      this.timeStarts3 = "AM";
      this.timeEnds3="AM";
      this.timeStarts1 = "06";
      this.timeStarts2 = "00";
      this.timeEnds1 = "08";
      this.timeEnds2 = "00";
    } else if (this.consultantSession == "Afternoon") {
      this.timeStarts3 = "PM";
      this.timeEnds3="PM";
      this.timeStarts1 = "12";
      this.timeStarts2 = "00";
      this.timeEnds1 = "02";
      this.timeEnds2 = "00";
    } else if (this.consultantSession == "Evening") {
      this.timeStarts3 = "PM";
      this.timeEnds3="PM";
      this.timeStarts1 = "06";
      this.timeStarts2 = "00";
      this.timeEnds1 = "08";
      this.timeEnds2 = "00";
    } 
  }  

  addSession(){
    var check_flag:boolean = true;
    check_flag = this.consultantFee == undefined && this.consultantFee == "" ? false : true;
    if(check_flag == true){
      this.sessionList.push({
        work_days:this.consultantDays,
        day_session:this.consultantSession,
        available_from:this.timeStarts1 + ":" + this.timeStarts2 + " " + this.timeStarts3,
        available_to: this.timeEnds1 + ":" + this.timeEnds2 + " " + this.timeEnds3,
        fee:this.consultantFee,
      });
      this.consultantDays = undefined;
      this.consultantFee = undefined;
      this.consultantSession = undefined;
      this.consultantDays = undefined;
      this.timeStarts1 = "06";
      this.timeStarts2 = "00";
      this.timeStarts3 = "AM";
      this.timeEnds1 = "08";
      this.timeEnds2 = "00";
      this.timeEnds3 = "AM";
    }else{
      this.toastr.error("please fill mandatory fields");
    }
  }

  addConsultant(e){
    var check_flag:boolean = true;
    var selectedPatient = [];
    var send_data;

    if(this.consultantFName == undefined || this.consultantLName == undefined || this.consultantMobile == undefined || this.consultantSpeciality == undefined || this.consultantLicense == undefined || this.consultantPwd == undefined || this.consultantGender == undefined || this.sessionList.length == 0){
        check_flag = false;
      }

    if(this.patientList.length != 0){
      for(var i = 0; i < this.patientList.length; i++){
        if(this.patientList[i].isSelected == true){
          if(this.patientList[i].sub_rel_id != undefined){
            selectedPatient.push({
              client_reg_id:this.patientList[i].client_reg_id,
              relation_id:this.patientList[i].relation_id,
              sub_rel_id:this.patientList[i].sub_rel_id
            });
          }else{
            selectedPatient.push({
              client_reg_id:this.patientList[i].client_reg_id,
              relation_id:this.patientList[i].relation_id,
            });
          }
        }
      }
    }

    if(check_flag == true){
      if(e == 'add'){
        var consultation = [];
        if(this.sessionList != undefined){
          for(var i = 0; i < this.sessionList.length; i++){
            consultation.push({
              work_days:day_nameid(this.sessionList[i].work_days),
              day_session:seesion_nameid(this.sessionList[i].day_session),
              available_from:ConvertTimeformat("", this.sessionList[i].available_from),
              available_to: ConvertTimeformat("", this.sessionList[i].available_to),
              fee:this.sessionList[i].fee,

            });
          }
        }

        send_data = {
          hptl_clinic_id: this.hospitalDetails,
          first_name:this.consultantFName,
          middle_name:this.consultantMName,
          last_name:this.consultantLName,
          license_code:this.consultantLicense,
          spl_id:this.consultantSpeciality,
          gender:this.consultantGender,
          mobile:this.consultantMobile,
          qualification:this.consultantQualification,
          experience:this.consultantExp,
          password:this.consultantPwd,
          created_by:this.createdBy,
          consultations: consultation,
          clnt_consult:selectedPatient
        }
        console.log("update the data---"+JSON.stringify(send_data))
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "adm/scd", send_data,
          { headers: headers }).subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              if(obj.key != '0'){
                this.toastr.success(Message_data.consultantAddedSuccess);
                this.showCreateEditFlag = false;
                this.getConsultants();
                this.clearFields();
              }else{
                this.toastr.error(obj.result);
              }
            });
      }else{//edit
        var consultation = [];
        if(this.sessionList != undefined){
          for(var i = 0; i < this.sessionList.length; i++){
            consultation.push({

              work_days:day_nameid(this.sessionList[i].work_days),
              day_session:seesion_nameid(this.sessionList[i].day_session),
              available_from:ConvertTimeformat("", this.sessionList[i].available_from),
              available_to: ConvertTimeformat("", this.sessionList[i].available_to),
              fee:this.sessionList[i].fee,
              schedule_id:this.sessionList[i].schedule_id,
            });
          }
        }

        send_data = {
          hptl_clinic_id: this.hospitalDetails,
          doc_reg_id:this.editList.doc_reg_id,
          first_name:this.consultantFName,
          middle_name:this.consultantMName,
          last_name:this.consultantLName,
          license_code:this.consultantLicense,
          spl_id:this.consultantSpeciality,
          gender:this.consultantGender,
          mobile:this.consultantMobile,
          qualification:this.consultantQualification,
          experience:this.consultantExp,
          password:this.consultantPwd,
          modified_by:this.createdBy,
          consultations: consultation,
          clnt_consult:selectedPatient
        }
        console.log("update the data---"+JSON.stringify(send_data))

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'adm/ucd',
        (send_data), { headers: headers }).subscribe(
          response => {  
            var obj = JSON.parse(JSON.stringify(response));
            if(obj.key != '0'){
              this.toastr.success(Message_data.consultantUpdSuccess);
              this.showCreateEditFlag = false;
              this.editFlag = false;
              this.back();
              this.getConsultants();
              this.clearFields();
            }else{
              this.toastr.error(obj.result);
            }
          });
      }
    }else{
      this.toastr.error(Message_data.mandatory);
    }
  }

  editConsultant(consultant){
    this.showCreateEditFlag = true;
    this.masterSelected = false;
    if(this.consultantList != undefined){
      this.editList = consultant;
      this.editFlag = true;
      this.consultantFName = consultant.first_name;
      this.consultantMName = consultant.middle_name;
      this.consultantLName = consultant.last_name;
      this.consultantLicense = consultant.license_code;
      this.consultantMobile = consultant.mobile;
      this.consultantPwd = consultant.password;
      this.consultantGender = consultant.gender;
      this.consultantDays = consultant.day;
      this.consultantSession = consultant.session;
      this.consultantSpeciality = consultant.spl_id;
      this.consultantQualification = consultant.qualification;
      this.consultantExp = consultant.experience;
      this.sessionList = [];
      for(var i = 0;i < consultant.consultation.length; i++){
        this.sessionList.push({
          work_days:Day_Return(consultant.consultation[i].work_days),
          day_session:Session_Return(consultant.consultation[i].day_session),
          available_from:Time_Formate(consultant.consultation[i].available_from),
          available_to:Time_Formate(consultant.consultation[i].available_to),
          fee:consultant.consultation[i].fee,
          schedule_id:consultant.consultation[i].schedule_id,
          doc_reg_id:consultant.doc_reg_id,
        });
      }
      for(var j = 0; j < this.patientList.length; j++){
        this.patientList[j].isSelected = false;
        for(var i = 0;i < consultant.clients.length; i++){
          if(this.patientList[j].client_reg_id == consultant.clients[i].client_reg_id){
            this.patientList[j].isSelected = true;
          }
        }
      }

    }
  }

  deleteConsultant(consultant){
    var send_data = {
      doc_reg_id:consultant.doc_reg_id,
      schedule_id:consultant.schedule_id,
      modified_by:this.createdBy,
      type:"consultation",
    }
    console.log("consultant" + JSON.stringify(consultant));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/dcd',
    (send_data), { headers: headers }).subscribe(
      response => {  
        var obj = JSON.parse(JSON.stringify(response));
        if(obj.key != '0'){
          this.toastr.success(Message_data.consultantAddedSuccess);
          for(var i = 0; i < this.sessionList.length; i++){
            if( consultant.schedule_id != undefined){
              if(this.sessionList[i].schedule_id == consultant.schedule_id){
                this.sessionList.splice(i,1);
              }
            }else{
              if(this.sessionList[i].work_days == consultant.work_days && this.sessionList[i].day_session == consultant.day_session && this.sessionList[i].available_from  == consultant.available_from){
                this.sessionList.splice(i,1);
              }
            }
          }
        }else{
          this.toastr.error(obj.result);
        }
      });
  }

  clearFields(){
    this.consultantFName = undefined;
    this.consultantMName = undefined;
    this.consultantLName = undefined;
    this.consultantMobile = undefined;
    this.consultantPwd = undefined;
    this.consultantLicense = undefined;
    this.consultantGender = undefined;
    this.consultantSpeciality = undefined;
    this.consultantQualification = undefined;
    this.consultantExp = undefined;
  }

  createConsultant(){
    this.showCreateEditFlag = true;
    this.editFlag = false;
    this.sessionList = [];
    this.clearFields();
    this.sessionList=[];
  }

  back(){
    this.showCreateEditFlag = false;
  }

  checkUncheckAll() {
    if(this.masterSelected == true){
      for(var i = 0 ; i < this.patientList.length; i++){
        this.patientList[i].isSelected = true;
      }
    }else{
      for(var i = 0 ; i < this.patientList.length; i++){
        this.patientList[i].isSelected = false;
      }
    }
  }

  isAllSelected(list) {
    for(var i = 0 ; i < this.patientList.length; i++){
      if(this.patientList[i].client_reg_id == list.client_reg_id){
        this.patientList[i].isSelected = list.isSelected;
      }
    }
  }
}
