import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { TreatmentService } from '../service';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress'; import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-others-personal',
  templateUrl: './others-personal.component.html',
  styleUrls: ['./others-personal.component.css']
})
export class OthersPersonalComponent implements OnInit {

  public othersPersonalFlag = "min";
  subscription: Subscription;
  public minflag: boolean = true;
  public complaints;
  public present_illvalue;
  public past_medical;
  public Mixing_array = [];
  public exercise_routine_arry = [];
  public case_hist_id: string;
  public life_style_id: string;
  //public maincheck = [];
  public main_concern_array = [];
  public Quantity_array = [];
  public bodyttype_array = [];
  public person_hist;
  public obj1;
  //public maincon_txt: string;
  public diet_habits: string;
  //public stress_factortxt: string;
  public name: string;
  public age: any;
  public current_occupation: string;
  public gender: string;
  public height_txt;
  public height_measure: string;
  public weight_txt;
  public weight_measure: string;
  public txt_bmi: any;
  public txt_bmr: any;
  public exer_run: string;
  public cal_txt: any;
  public body_type: string;
  public body_nature: string;
  public rweight_txt: string;
  public anymed_txt: string;
  public med_txt: string;
  public togg_alchol: boolean;
  public alchol_show: boolean;
  public togg_smoking: boolean;
  public smoke_show: boolean;
  public togg_gutka: boolean;
  public gutka_show: boolean;
  public togg_exercise: boolean;
  public exercise_show: boolean;
  public togg_family: boolean;
  public gutka_duration: string;
  public gutka_frequency: string;
  public alchol_duration: string;
  public alcohol_frequency: string;
  public Alc_Quant: string;
  public Alc_Mix: string;
  public smoke_duration: string;
  public smoke_frequency: string;
  public exercise_dur: string;
  public name_label: string;
  public age_label: string;
  public current_label: string;
  public gender_label: string;
  public height_label: string;
  public height_measurelabel: string;
  public weight_label: string;
  public weight_measurelabel: string;
  public bmi_label: string;
  public bmr_label: string;
  public dietary_label: string;
  public alchol_label: string;
  public smoke_label: string;
  public gutka_label: string;
  public exercise_label: string;
  public bodytype_label: string;
  public bnature_label: string;
  public rweight_label: string;
  public anymed_label: string;
  public med_label: string;
  public brancial_label: string;
  public radial_label: string;
  public carotid_label: string;
  public femoral_label: string;
  public popliteal_label: string;
  public abdom_aorta: string;
  public branch_rhythm: string;
  public radial_rhythm: string;
  public carotid_rhythm: string;
  public femoral_rhythm: string;
  public popliteal_rhythm: string;
  public posterior_rhythm: string;
  public dorsalis_rhythm: string;
  public occupation_txt: string;
  public alcholhist: string;
  public family_hidden: boolean;
  public family_label: string;

  public name_hidden: boolean;
  public age_hidden: boolean;
  public curoccup_hidden: boolean;
  public Gender_hidden: boolean;
  public height_hidden: boolean;
  public weight_hidden: boolean;
  public bmi_hidden: boolean;
  public bmr_hidden: boolean;
  public dietary_hidden: boolean;
  public alchol_hidden: boolean;
  public smoke_hidden: boolean;
  public gutka_hidden: boolean;
  public exercise_hidden: boolean;
  public bodytype_hidden: boolean;
  public bnature_hidden: boolean;
  public rweight_hidden: boolean;
  public anymed_hidden: boolean;
  public med_hidden: boolean;
  public brancial_hidden: boolean;
  public radial_hidden: boolean;
  public carotid_hidden: boolean;
  public femoral_hidden: boolean;
  public popliteal_hidden: boolean;
  public abdom_aorta_hidden: boolean;
  public branch_rhythm_hidden: boolean;
  public radial_rhythm_hidden: boolean;
  public carotid_rhythm_hidden: boolean;
  public femoral_rhythm_hidden: boolean;
  public popliteal_rhythm_hidden: boolean;
  public posterior_rhythm_hidden: boolean;
  public dorsalis_rhythm_hidden: boolean;
  public loader: boolean;
  public med_flag: boolean;
  public historytab: boolean;
  public header_footer_flag: boolean;
  public agefield: boolean;
  public wtmeasure_hidden;
  public currnetoccupation_hidden;
  public obervation_hidden;
  public personaldata;
  public userinfo;

  public client: boolean;
  public user_id;

  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };

  constructor(public bmiservice: BmiandbmrService, public dialog: MatDialog, public messagesevice: CasesheetService, private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService) {
    this.togg_family = false;
    this.togg_alchol = false;
    this.togg_smoking = false;
    this.togg_gutka = false;
    this.togg_exercise = false;
    this.exercise_show = true;
    this.alchol_show = true;
    this.smoke_show = true;
    this.gutka_show = true;

    this.name_hidden = true;
    this.age_hidden = true;
    this.curoccup_hidden = true;
    this.Gender_hidden = true;
    this.height_hidden = true;
    this.weight_hidden = true;
    this.bmi_hidden = true;
    this.bmr_hidden = true;
    this.dietary_hidden = true;
    this.alchol_hidden = true;
    this.smoke_hidden = true;
    this.gutka_hidden = true;
    this.exercise_hidden = true;
    this.bodytype_hidden = true;
    this.bnature_hidden = true;
    this.rweight_hidden = true;
    this.anymed_hidden = true;
    this.med_hidden = true;
    this.brancial_hidden = true;
    this.radial_hidden = true;
    this.carotid_hidden = true;
    this.femoral_hidden = true;
    this.popliteal_hidden = true;
    this.abdom_aorta_hidden = true;
    this.branch_rhythm_hidden = true;
    this.radial_rhythm_hidden = true;
    this.carotid_rhythm_hidden = true;
    this.femoral_rhythm_hidden = true;
    this.popliteal_rhythm_hidden = true;
    this.posterior_rhythm_hidden = true;
    this.dorsalis_rhythm_hidden = true;
    this.med_flag = true;
    this.historytab = true;
    this.agefield = true;

    this.txt_bmr = undefined;
    this.txt_bmi = undefined;
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  med_change() {
    if (this.anymed_txt == "Yes") {
      this.med_flag = false;
    } else {
      this.med_flag = true;
      this.med_txt = "";
    }
  }

  send_data() {
    var gen_hist = null, case_hist_id = null;

    if (this.case_hist_id != null) {
      case_hist_id = this.case_hist_id;

    } else {
      case_hist_id = undefined;
    }

    var lif_stl_id = undefined;
    if (this.life_style_id != null) {
      lif_stl_id = this.life_style_id;
    }

    if (this.current_occupation != undefined) {
      this.occupation_txt = encrypt_decript.Encript(this.current_occupation).toString();

    } else {
      this.occupation_txt = null;
    }

    if (this.personaldata.sub_id.length == 0) {
      gen_hist = {
        country: ipaddress.country_code,
        case_hist_id: case_hist_id,
        current_occupation: this.occupation_txt,
        life_style_id: lif_stl_id,
        app_id: this.personaldata.app_id,
        doc_reg_id: this.user_id,
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        // height: this.height_txt,
        // height_measure: this.height_measure,
        // weight: this.weight_txt,
        // weight_measure: this.weight_measure,
        // bmi: this.txt_bmi,
        // bmr: this.txt_bmr,
        gender: this.gender,
        age: this.age,
        // body_nature:this.body_nature,
        body_type: this.body_type,
        medication: this.anymed_txt,
        medicines: this.med_txt,
        diet_habits: this.diet_habits,
        complaints:this.complaints,
        present_illness:this.present_illvalue,
        past_illness:this.past_medical,
      }
    } else {
      gen_hist = {
        country: ipaddress.country_code,
        case_hist_id: case_hist_id,
        current_occupation: this.occupation_txt,
        life_style_id: lif_stl_id,
        app_id: this.personaldata.app_id,
        doc_reg_id: this.user_id,
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        sub_rel_id: this.personaldata.sub_id,
        height: this.height_txt,
        height_measure: this.height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        bmi: this.txt_bmi,
        bmr: this.txt_bmr,
        gender: this.gender,
        age: this.age,
        body_nature: this.body_nature,
        body_type: this.body_type,
        medication: this.anymed_txt,
        medicines: this.med_txt,
        diet_habits: this.diet_habits,
        complaints:this.complaints,
        present_illness:this.present_illvalue,
        past_illness:this.past_medical,
      }
    }

    Helper_Class.setPersonalHist(gen_hist);
    Helper_Class.setClntAge(this.age);
    Helper_Class.setClntGender(this.gender);

    // Helper_class.setClntHeight(this.height_txt);
    // Helper_class.setClntHeightMsr(this.height_measure);
    // Helper_class.setClntWeight(this.weight_txt);
    // Helper_class.setClntWeightMsr(this.weight_measure);
  }

  ngOnInit() {
    this.subscription = this.messagesevice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.othersPersonalFlag = message;
      }
    });

    this.subscription = this.bmiservice.getMessage().subscribe(message => {
      if (message != undefined) {
        if (message.flag == "vitals") {
          if (message.bodynatute != undefined) {
            this.body_nature = message.bodynatute;
          }
        }
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {
      this.personaldata = Helper_Class.getInfo();
      this.client = true;
      this.userinfo = Helper_Class.getclient_hospitalDetails();
    } else {
      this.personaldata = Doc_Helper.getClient_Info();
      this.userinfo = Helper_Class.getInfo();
      this.client = false;
      
    }

    if (Helper_Class.getInpatientFlow() == "nurse") {
      this.user_id = this.personaldata.user_id;
    } else {
      this.user_id = this.userinfo.user_id;
    }

    this.obj1 = Helper_Class.getRet();


    if (this.personaldata.Age_data != undefined && this.personaldata.Age_data != null && this.personaldata.Age_data != "") {
      this.age = this.personaldata.Age_data;
    } else {
      this.age = "";
      this.agefield = false;
    }
    this.gender = this.personaldata.Gender_data;

    if (this.personaldata.height != null) {
      this.height_txt = this.personaldata.height;
      this.height_measure = this.personaldata.height_measure;
      this.weight_txt = this.personaldata.weight;
      this.weight_measure = this.personaldata.weight_measure;
      this.txt_bmi = this.personaldata.bmi;
      this.txt_bmr = this.personaldata.bmr;
      this.calories_data();

      if (Helper_Class.Get_client_view_app_data() != undefined) {
        if (Helper_Class.Get_client_view_app_data().current_occupation != null &&
          Helper_Class.Get_client_view_app_data().current_occupation != "" && Helper_Class.Get_client_view_app_data().current_occupation != undefined) {
          this.current_occupation = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().current_occupation);
        }
      }

      //this.txt_bmi = undefined;
    }

    this.getBodyTypes();

    this.alchol_duration = "<1 Year";
    this.alcohol_frequency = "<5";
    this.smoke_duration = "<1 Year";
    this.smoke_frequency = "<5";
    this.gutka_duration = "<1 Year";
    this.gutka_frequency = "<5";
    this.exercise_dur = "15 Mins";
    this.height_measure = "cms";
    this.weight_measure = "kgs";

    var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();

    for (var i = 0; i < getHopitalfieldsRet.length; i++) {
      if (getHopitalfieldsRet[i].page_name == "Other Personal") {
        if (getHopitalfieldsRet[i].fields != undefined) {

          if (getHopitalfieldsRet[i].fields != undefined) {
            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
              if (getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                this.current_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.curoccup_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Name") {
                this.name_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.name_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                this.age_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.age_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Gender") {
                this.gender_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.Gender_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                this.height_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.height_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Height measure") {
                this.height_measurelabel = "Measure";
                this.height_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                this.weight_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.weight_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Weight measure") {
                this.weight_measurelabel = "Measure";
                this.weight_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "BMI") {
                this.bmi_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.bmi_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "BMR") {
                this.bmr_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.bmr_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Dietary habits") {
                this.dietary_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.dietary_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Alcohol") {
                this.alchol_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.alchol_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Smoke") {
                this.smoke_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.smoke_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Gutka") {
                this.gutka_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.gutka_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Exercises") {
                this.exercise_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.exercise_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Family Support") {
                this.family_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.family_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Body type") {
                this.bodytype_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.bodytype_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Body nature") {
                this.bnature_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.bnature_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Recent weight gain") {
                this.rweight_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.rweight_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Taking any medication") {
                this.anymed_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.anymed_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Medicines") {
                this.med_label = getHopitalfieldsRet[i].fields[j].field_name;
                this.med_hidden = false;
              }
            }
          }
        }
      }
    }

  }

  getBodyTypes() {
    this.bodyttype_array = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',
      JSON.stringify({
        gender: this.gender,
        // gender: "Male",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null) {
            this.bodyttype_array = obj.body_types;
            this.body_type = this.bodyttype_array[0].bodytype_name;
          }
          this.getCasesheetDetails();
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  isReadonly() {
    return true;
  }

  //Get bmi and bmr and calories data
  calories_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');

          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.toastr.error(Message_data.heightMSValue)
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);
        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }

      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);

      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }

      if (this.txt_bmi < 18.5) {
        this.body_nature = "Under weight";

      } else if (this.txt_bmi >= 18.5 && this.txt_bmi <= 24.9) {
        this.body_nature = "Normal weight";

      } else if (this.txt_bmi >= 25 && this.txt_bmi <= 29.9) {
        this.body_nature = "Over weight";

      } else {
        this.body_nature = "Obase";
      }
    }
    // var datavalue=null;
    // datavalue={
    //   bodynatute:this.body_nature,
    //   txt_bmr:this.txt_bmr,
    //   txt_bmi:this.txt_bmi
    // }
    // this.bmiservice.sendMessage(datavalue);

  }

  getCasesheetDetails() {
    //this.loader = false;
    this.case_hist_id = null;
    var obj = Helper_Class.getRet();
    console.log("retr --"+JSON.stringify(Helper_Class.getRet()))

    if (obj != undefined) {
      if (obj != null && obj.case_hist_id != null) {


        Helper_Class.setCaseHistId(obj.case_hist_id);
        Helper_Class.setLifeStyleId(obj.life_style_id);

        //fetch data from local storage
        if (this.personaldata.client_name != undefined) {
          this.name = this.personaldata.client_name;
        } else {
          this.name = obj.cardio_name;
        }
        if(obj.complaints != undefined){
          this.complaints = obj.complaints;
        }
        if(obj.present_illness){
          this.present_illvalue =  obj.present_illness;
        }
        if(obj.past_illness){
          this.past_medical= obj.past_illness;
        }
        if (obj.age) {
          this.age = obj.age
        } else {
          localStorage.getItem("Age_data") != undefined
          this.age = obj.age;
        }

        if (this.age == undefined) {
          this.agefield = false;
        }

        if (this.personaldata.Gender_data != undefined) {
          this.gender = this.personaldata.Gender_data;
        } else {
          this.gender = obj.gender;
        }

        //Current occupation
        if (obj.current_occupation != null || obj.current_occupation != "") {
          this.current_occupation = encrypt_decript.Decript(obj.current_occupation);

        } else {
          this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
        }

        if (obj.case_hist_id != null) {
          this.case_hist_id = obj.case_hist_id;
        }

        if (obj.life_style_id != null) {
          this.life_style_id = obj.life_style_id;
        }

        //Height
        if (obj.height != null) {
          this.height_txt = obj.height;
          this.height_measure = obj.height_measure;
        }

        //Weight
        if (obj.weight != null) {
          this.weight_txt = obj.weight;
          this.weight_measure = obj.weight_measure;
        }

        //Bmi and mnr
        if (obj.bmi != null) {
          this.txt_bmi = obj.bmi;
          this.txt_bmr = obj.bmr;
          this.body_nature = obj.body_nature;
          // var datavalue=null;
          // datavalue={
          //   bodynatute:this.body_nature,
          //   txt_bmr:this.txt_bmr,
          //   txt_bmi:this.txt_bmi
          // }
          // this.bmiservice.sendMessage(datavalue);
        }

        if (obj.body_type != "" || obj.body_type != null) {
          this.body_type = obj.body_type;
        }
        if (obj.medication != null || obj.medication != undefined) {
          this.anymed_txt = obj.medication;
        }
        if (obj.medicines != null || obj.medicines != undefined) {
          this.med_txt = obj.medicines;
        }

        //Diet habits
        if (obj.diet_habits != null) {
          this.diet_habits = obj.diet_habits;
        }

        if (obj.spl_name != undefined) {
          Helper_Class.setOtherSpl(obj.spl_name);
        }

      } else {
        //this.loader = true;
        if (this.personaldata.client_name != undefined) {
          this.name = this.personaldata.client_name;
        }

        if (this.personaldata.Gender_data != undefined) {
          this.gender = this.personaldata.Gender_data;
        }

        //Current occupation
        if (this.personaldata.current_occupation != undefined && this.personaldata.current_occupation != 'undefined') {
          this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
        }
        this.complaints = this.personaldata.ascomplaints;
        this.present_illvalue =  this.personaldata.present_illness;
        this.past_medical= this.personaldata.past_illness;
      }
    } else {
      this.complaints = this.personaldata.ascomplaints;
      this.present_illvalue = this.personaldata.present_illness;
      this.past_medical = this.personaldata.past_illness;
    }
  }

  history() {

    this.person_hist = "concern_hist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.person_hist,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.dis_txt = result;
    });
  }
  changelinemgmt = (event,value) => {
    if(value == "main"){
     // this.Maincon_txt = event.html;   
      this.complaints = event.html;   
    }else if(value == "past"){
      this.past_medical= event.html;   
    }else{
      this.present_illvalue=event.html
    }
    
  };
}
