import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontDeskComponent } from './front-desk/front-desk.component';
import { FrontdeskHomeComponent } from './frontdesk-home/frontdesk-home.component';
import { ReceptAppCreatePageComponent } from './recept-app-create-page/recept-app-create-page.component';
import { ReceptBillCreateComponent } from './recept-bill-create/recept-bill-create.component';
import { ReceptBillDetailviewComponent } from './recept-bill-detailview/recept-bill-detailview.component';
import { ReceptBillEditComponent } from './recept-bill-edit/recept-bill-edit.component';
import { ReceptBillListComponent } from './recept-bill-list/recept-bill-list.component';
import { ReceptPatientListComponent } from './recept-patient-list/recept-patient-list.component';
import { ReceptPrescriptionListComponent } from './recept-prescription-list/recept-prescription-list.component';
import { ReceptionistMgmtapplistComponent } from './receptionist-mgmtapplist/receptionist-mgmtapplist.component';
import { ReceptionistdoctordetailviewComponent } from './receptionistdoctordetailview/receptionistdoctordetailview.component';
import { ReceptviewprescriptionComponent } from './receptviewprescription/receptviewprescription.component';
import { DocRefListComponent } from './doc-ref-list/doc-ref-list.component';
import { DocRefCreateComponent } from './doc-ref-create/doc-ref-create.component';
import { LeadMgmtComponent} from './lead-mgmt/lead-mgmt.component';
import { PartialPaymentComponent } from './partial-payment/partial-payment.component';

const routes: Routes = [
  {path: 'FrontDesk', component: FrontDeskComponent},
  {path: 'frontdeskhome', component: FrontdeskHomeComponent},
  {path:'frontdeskappointments',component:ReceptionistMgmtapplistComponent},
  {path:'receptionistdoctordetailview',component:ReceptionistdoctordetailviewComponent},
  {path:'receptbillcreate',component:ReceptBillCreateComponent},
  {path:'receptbilldeatilview',component:ReceptBillDetailviewComponent},
  {path:'receptbilledit',component:ReceptBillEditComponent},
  {path:'receptbilllist',component:ReceptBillListComponent},
  {path:'receptprescriptionlist',component:ReceptPrescriptionListComponent},
  {path:'receptpatientlist',component:ReceptPatientListComponent},
  {path:'receptionistappcreatepage',component:ReceptAppCreatePageComponent},
  {path: 'docref', component: DocRefListComponent },
  {path: 'docrefcreate', component: DocRefCreateComponent },
  {path: 'leadmgmt', component: LeadMgmtComponent},
  {path: 'parpay',component: PartialPaymentComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontDeskRoutingModule { }
