import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress' ;import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';

@Component({
  selector: 'app-pediatrics-add-vaccine',
  templateUrl: './pediatrics-add-vaccine.component.html',
  styleUrls: ['./pediatrics-add-vaccine.component.scss']
})
export class PediatricsAddVaccineComponent implements OnInit {

  public Age_grouparray;
  public Vaccine_name;
  public Age_group;
  public Age_id;

  constructor(public http:Http, public toastr:ToastrService,public el: ElementRef,public dialogRef: MatDialogRef<PediatricsAddVaccineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public masterCSdata:MasterCSHelperService) {      
      dialogRef.disableClose = true; 
   }

  async ngOnInit(){
    if(masterCSData_Helper.getMasterAgeGroup() == undefined){
      await this.masterCSdata.getAgeGroup();
      this.Get_age_group();
    }else{
      this.Get_age_group();
    }
  }

  Get_age_group() {
    this.Age_grouparray;
    var obj = masterCSData_Helper.getMasterAgeGroup();
    if (obj != undefined) 
      this.Age_grouparray = obj.age_groups;
  }

  //save vaccine
  Vaccine_save() {
    for (var i = 0; i < this.Age_grouparray.length; i++) {
      if (this.Age_grouparray[i].description == this.Age_group) {

        this.Age_id = this.Age_grouparray[i].age_id;
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/svac/',
      JSON.stringify({
        vaccine_name: this.Vaccine_name,
        age_group_id: this.Age_id

      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(response["_body"]);
          
          if (obj["key"] == "1") {
            this.toastr.success(Message_data.vaccineSavedSuccess);
            this.ClosePopup();
          }

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
