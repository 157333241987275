<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()"
              src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="book()"
              src="../../../assets/ui_icons/buttons/Book_now.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment For &nbsp;&nbsp;
                  <input class="ipcss widthappt" matInput type="text" [(ngModel)]="relationName" disabled />
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">First name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_fname"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Middle name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_mname"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Last name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_lname"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel"> Age (yrs)
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_age" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Gender
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_gender"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Blood Group
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_blood"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Contact Number
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_cont_number"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Symptom
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_symptom"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Father / Guardian Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_fg_name"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Income
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_income"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Occupation
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clientDetailData.clnt_occupation"
                disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="!nursePhysioAppFlag">
            <mat-label class="matlabel">Treatment type
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="treatType" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="!nursePhysioAppFlag">
            <mat-label class="matlabel">Speciality
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="speciality" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Doctor Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="hospDoctDetailData.doc_name"
                disabled />
            </mat-label>
          </div>
          <div class="col-12" *ngIf="!homecareHospFlag">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Hospital name</mat-label>
                <input matInput class="ipcss" [(ngModel)]="hospitalName" disabled>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Hospital location</mat-label>
                <input matInput class="ipcss" [(ngModel)]="hospitalLocation" disabled>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Hospital city</mat-label>
                <input matInput class="ipcss" [(ngModel)]="hospitalCity" disabled>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Appointment date</mat-label>
                <input matInput class="ipcss" [(ngModel)]="appointmentDate" disabled>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Session</mat-label>
                <input matInput class="ipcss" [(ngModel)]="appointmentSession" disabled>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="!nursePhysioAppFlag">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Appointment type</mat-label>
                <input matInput class="ipcss widthappt" [(ngModel)]="appType" disabled>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" *ngIf="!homecareFlag">
                <mat-label class="matlabel">Appointment {{appTypeName}}</mat-label>
                <input matInput class="ipcss widthappt" [(ngModel)]="hospDoctDetailData.tokenortime" disabled>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="homecareFlag">
            <div class="table-responsive">
              <table id="card_tbl" class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Session</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of homecareData">
                    <td data-th="Date">{{data.date}}</td>
                    <td data-th="Session">{{data.session}}</td>
                    <td data-th="Time">{{data.visit_Time}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>