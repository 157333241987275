<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" [hidden]="app_button"
              class="saveimgbtn_inpatinfo" (click)="cancel_click()" />
            <!-- <img src="../../../assets/ui_icons/buttons/reassign_button.svg" [hidden]="Reassign_Btn"
                class="saveimgbtn_inpatinfo" (click)="reassignClick()" /> -->
            <img src="../../../assets/ui_icons/buttons/Book_now.svg" [hidden]="Book_Now_View"
              class="saveimgbtn_inpatinfo" (click)="bookNow()" />
            <!-- <img width="30px" src="assets/img/video.png" [hidden]="video_button" class="editDelicon"
                (click)="video_call()" style="" /> -->
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="backimgbtn_inpatinfo" /></a>
            <!--                   
                  <img class="saveimgbtn_inpatinfo" *ngIf="reschedule_Div"  (click)="reschedule_App()"
                src="../../../assets/ui_icons/buttons/reschedule_button.svg" /> -->
            <img class="saveimgbtn_inpatinfo" *ngIf="Available_Div" (click)="Reschedule_Book()"
              src="../../../assets/ui_icons/buttons/Book_now.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="tempUser" (click)="updateUser()"
              src="../../../assets/ui_icons/buttons/update_button.svg" />
            <a style="margin:0 5px;" *ngIf="!tempUser" (click)="print_app()"><img src="../../../assets/img/printer.svg"
                class="backimgbtn_inpatinfo" style="width: 25px;" height="25px" /></a>

          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Personal details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Relationship<br>
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="rel_type" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">Salutation
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="clntSal" matInput required />

                  <!-- <select disableOptionCentering class="ipcss noappt_width" required [(ngModel)]="clntSal"
                    (ngModelChange)="changeSalutation($event)">
                    <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                  </select> -->
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">First name
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="f_name" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Middle name
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="m_name" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Last name
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="l_name" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                <mat-label class="matlabel">DOB
                  <input type="date" class="ipcss_date noappt_width" [disabled]="dobDisabledFlag"
                    (change)="selectDob(clntDob)" [(ngModel)]="clntDob" max="{{currentDate}}" #matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Age
                  <input class="ipcss " [disabled]="ageDisabledFlag" [readonly]="isReadonly()" (keyup)="changeAge()"
                    [(ngModel)]="age_txt" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Gender
                  <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="gen_txt" required>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                  <!-- <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="gen_txt" matInput /> -->
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Occupation
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="occ_txt" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Contact number
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="cont_txt" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Purpose of visit
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="dietType" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Referred by
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="refer_txt" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Speciality
                  <mat-select class="ipcss" [(ngModel)]="speclization_txt" required>
                    <mat-option *ngFor="let spl of specializationList" value={{spl.spl_id}}>
                      {{spl.spl_name}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Speciality
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="speclization_txt" matInput />
                </mat-label>
              </div> -->
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Appointment details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Symptoms
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="sym_txt" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngIf="!this.tempUser">
                <mat-label class="matlabel">Patient address
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="pat_info" matInput></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Appointment details
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="app_info" matInput></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Hospital details
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="hosp_info" matInput></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngIf="!this.tempUser">
                <mat-label class="matlabel">Package
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="healthPackageID" matInput />
                  
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngIf="this.tempUser">
                <mat-label class="matlabel">Package
                  <mat-select class="ipcss" [(ngModel)]="healthPackageID" required>
                    <mat-option *ngFor="let package of healthPackage" value={{package.health_pkg_id}}>
                      {{package.package_type_desc}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 my-auto" *ngIf="this.tempUser">
                <div style="font-weight: 600;font-family: arial;"> Subscribe to Tervys app
                  <img *ngIf="subscribeFlag == true" (click)="selectSubscribe('no')"
                    src="../../../assets/ui_icons/tick_mark.svg" width="12px"
                    style="cursor: pointer;width: 25px;margin: 0 0px 0 9px;">
                  <img *ngIf="subscribeFlag == false" (click)="selectSubscribe('yes')"
                    src="../../../assets/ui_icons/cross_mark.svg" width="12px"
                    style="cursor: pointer;width: 25px; margin: 0 0px 0 9px;">
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 my-auto" *ngIf="!this.tempUser">
                <div style="font-weight: 600;font-family: arial;"> Subscribe to Tervys app
                  <img *ngIf="subscribeFlag == true" 
                    src="../../../assets/ui_icons/tick_mark.svg" width="12px"
                    style="cursor: pointer;width: 25px;margin: 0 0px 0 9px;">
                  <img *ngIf="subscribeFlag == false" 
                    src="../../../assets/ui_icons/cross_mark.svg" width="12px"
                    style="cursor: pointer;width: 25px; margin: 0 0px 0 9px;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" [hidden]="Reassign_Div">
          <div class="header_lable">
            Resasign
          </div>
          <div class="content_cover">
            <div [hidden]="Reassign_Div">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel" [hidden]="Reassign_Div">Doctor
                    <select class="ipcss " (change)="assign_DoctorChange(doctor)" [(ngModel)]="doctor">
                      <option class="ipcss " *ngFor="let doctors of Doctor_List_Data" value={{doctors.Doc_Name}}>
                        {{doctors.Doc_Name}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Date
                    <input type="date" class="ipcss_date widthbillcreate" id="appt_date" required
                      [(ngModel)]="Appointment_Date" min="{{currentDate}}" #matInput style="background: #fff;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Session<br>
                    <select class="ipcss " [(ngModel)]="session" required (change)="sessionChange()">
                      <option *ngFor="let ses of Visiting_Session_Data" value={{ses.description}}>{{ses.description}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="App_Type_Token">
                  <mat-label class="matlabel" [hidden]="App_Type_Token">Appointment type :</mat-label>
                  <mat-label class="matlabel" [hidden]="App_Type_Token">Token</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="App_Type_Time">
                  <mat-label class="matlabel" [hidden]="App_Type_Time">
                    <select class="ipcss " [(ngModel)]="appointmenttime" required (change)="sessionChange()">
                      <option *ngFor="let time of Time_Data" value={{time}}>{{time}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-auto">
                  <mat-label class="matlabel">
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="Searchbtn_Click()" />
                  </mat-label>
                </div>
                <div class="col-12">
                  <mat-label class="matlabel" [hidden]="Unavailable">Sorry already booked</mat-label>
                </div>
              </div>
            </div>
            <div [hidden]="Doctor_Consult_Data.length==0">
              <div class="table-responsive dig_table_overflow">
                <table id="patienttable" class="table table-hover table-dynamic reassing_table"
                  [hidden]="Doctor_Consult_Data.length==0">
                  <thead>
                    <tr>
                      <th>Work days</th>
                      <th>Session</th>
                      <th>Starts from</th>
                      <th>Ends at </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let work of  Doctor_Consult_Data">
                      <td>{{work.Workday}}</td>
                      <td>{{work.Session}}</td>
                      <td>{{work.Avail_from}}</td>
                      <td>{{work.Avail_to}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="Normal_reschedule_div">
          <div class="header_lable">
            Reschedule
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive dig_table_overflow">
                  <table id="tbl" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Hospital name</th>
                        <th>Day</th>
                        <th>Session</th>
                        <th>Starts from</th>
                        <th>Ends at</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let doctor of normal_doctor_data">
                        <td data-th="Hospital Name">{{doctor.hospital}}</td>
                        <td data-th="Day">{{doctor.workdays}}</td>
                        <td data-th="Session">{{doctor.day_session}}</td>
                        <td data-th="Starts from">{{doctor.available_from}}</td>
                        <td data-th="Ends at">{{doctor.available_to}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Date
                  <input type="date" class="ipcss " [(ngModel)]="Normal_App_Date"
                    (change)="OnDateMatePicker(Normal_App_Date)" min={{currentDate}} matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="Available_Div" class="matlabel">Session<br>
                  <mat-select [(ngModel)]="visit_Session" disableOptionCentering class="ipcss "
                    (ngModelChange)="visit_Session_Change(visit_Session, Normal_App_Date)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let session of visiting_Session_Data" value={{session.description}}>
                      {{session.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div *ngIf="TimeorToken_Div" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment {{Resched_AppType}}</mat-label>
                <div class="row" *ngIf="app_Type_Token">
                  <div class="col-12">
                    <mat-label class="matlabel">Token<br>
                    </mat-label>
                  </div>
                </div>
                <div class="row" *ngIf="!app_Type_Token">
                  <div class="col-12">
                    <mat-label *ngIf="!app_Type_Token" class="matlabel">
                      <mat-select [(ngModel)]="Appointment_Time" *ngIf="!app_Type_Token" disableOptionCentering
                        class="ipcss ">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let time of Time_Data" value={{time}}>{{time}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Reason for reschedule
                  <textarea class="ipcss " [(ngModel)]="reschedule_reason"></textarea>
                </mat-label>
              </div>
              <div class="col-12" *ngIf="Available_Div">
                <mat-label class="matlabel" *ngIf="Unavailable">Sorry already booked</mat-label>
              </div>
              <div class="col-12" *ngIf="NonAvailable_Div">
                <mat-label class="matlabel" *ngIf="Unavailable">Please check doctor's availability</mat-label>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <!-- style="position: fixed; bottom: 16%; right: 6%; margin: 0px; width: 33%;" -->
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="titleStyle">
                <h5 class="modal-title" id="staticBackdropLabel">Reasone for cancellation</h5>
                <span class="closeicon" data-bs-dismiss="modal" aria-label="Close" style="float: right;"><img
                    src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:19px"> </span>
              </div>
              <div class="modal-body">
                <textarea class="ipcss noappt_width" style="height:108px !important;" [(ngModel)]="cancelResone"
                  maxlength="500"></textarea>
              </div>
              <div class="modal-footer">
                <img src="../../../assets/ui_icons/diet_plan/send_button.svg" (click)="sendCancel()"
                  alt="Card image cap" style="width:85px; height: 25px;">
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="this.tempUser">
          <div class="header_lable">
            Address details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                <mat-label class="matlabel">Address1
                  <input type="text" class="ipcss noappt_width" [(ngModel)]="clntAddress1" matInput required />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                <mat-label class="matlabel">Address2
                  <input type="text" class="ipcss noappt_width" [(ngModel)]="clntAddress2" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">Country

                  <mat-select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clntCountry"
                    (ngModelChange)="changeCountry(clntCountry, '0')">
                    <mat-option Selected>Select</mat-option>
                    <mat-option *ngFor="let country of clntCountryList" value="{{country.description}}">
                      {{country.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">State

                  <mat-select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clntState"
                    (ngModelChange)="changeState(clntState, '0')">
                    <mat-option Selected>Select</mat-option>
                    <mat-option *ngFor="let state of clntStateList" value="{{state.description}}">
                      {{state.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">District / City

                  <mat-select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clntCity"
                    (ngModelChange)="changeCity(clntCity, '0')">
                    <mat-option Selected>Select</mat-option>
                    <mat-option *ngFor="let city of clntCityList" value="{{city.description}}">
                      {{city.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Location
                  <input type="text" class="ipcss noappt_width" [(ngModel)]="clntLocation"
                    (keyup)="changeLocation(clntLocation)" matInput [matAutocomplete]="auto" required />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectLocation(location)" *ngFor="let location of clntLocationList"
                      value="{{location.description}}">
                      {{location.description}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                <mat-label class="matlabel">Zipcode
                  <input type="text" class="ipcss noappt_width" [(ngModel)]="clntZipcode" matInput />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div #printlogowithname id="printlogowithname">
            <table style="margin-left:50px;width: 100%;">
              <tr>
                <td style="display: flex;justify-content: center;">
                  <div>
                    <img alt="image" src={{hosp_logo}} width="100px" height="100px" style="vertical-align: middle;">
                  </div>
                  <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                    <p><b>{{hosp_name}}</b>,</p>
                    <span *ngIf="hosp_address != undefined">{{hosp_address}}</span><br />
                    <span>{{hosp_location}},{{hosp_city}}</span><br />
                    <span>{{hosp_state}}</span><br />
                  </div>
                </td>
              </tr>
            </table>
            <table style="margin-left: 50px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>UHID:</b> {{clnt_id}}</p>
                  <p><b>Name:</b> {{clnt_name}}</p>
                  <p><b>Age:</b> {{age_txt}} &nbsp;&nbsp;&nbsp;&nbsp;<b>Gender:</b>{{gen_txt}} </p>
                  <p><b>Contact no:</b>{{cont_txt}}</p>
                  <p><b>Address:</b>{{pat_info}}</p>
                  <p><b>Package:</b>{{healthPackageID}}</p>
                </td>
                <td style="width: 50px;margin-top: 65px;">&nbsp;</td>
                <td style="vertical-align: top;">
                  <p><b>Doctor: {{"Dr."+doc_name}}</b></p>
                  <p><b>Date: </b>{{app_date}}&nbsp;&nbsp;&nbsp;&nbsp;<b>Session: </b>{{app_session}}</p>
                  <p><b>Appointment Type:</b> {{app_type}}</p>
                  <p *ngIf="refer_txt!= undefined"><b>Referred by: Dr.</b>{{refer_txt}}</p>
                  <p><b>App subscribed:</b>{{subscribeApp}}</p>
                </td>
              </tr>
            </table>
          </div>
          <div #printbanner id="printbanner">
            <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
              <tr>
                <td>
                  <img alt="image" src={{hptl_pres_logo}} width="750px" height="250px">
                </td>
              </tr>
            </table>
            <table style="margin-left: 50px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>Identifier:</b> {{clnt_id}}</p>
                  <p><b>Name:</b> {{clnt_name}}</p>
                  <p><b>Age:</b> {{age_txt}} &nbsp;&nbsp;&nbsp;&nbsp;<b>Gender:</b>{{gen_txt}} </p>
                  <p><b>Contact no:</b>{{cont_txt}}</p>
                  <p><b>Address:</b>{{pat_info}}</p>
                  <p><b>Package:</b>{{healthPackageID}}</p>
                </td>
                <td style="vertical-align: top;">
                  <p><b>Date: </b>{{Appointment_Date}}</p>
                  <p><b>Session: </b>{{app_session}}</p>
                  <p><b>Appointment Type:</b> {{app_type}}</p>
                  <p><b>Referred by: Dr.</b>{{refer_txt}}</p>
                  <p><b>App subscribed:</b>{{subscribeApp}}</p>
                </td>
              </tr>
            </table>
          </div>
          <div #printnoheader id="printnoheader">
            <table style="margin-left: 50px; margin-top:220px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>Identifier:</b> {{clnt_id}}</p>
                  <p><b>Name:</b> {{clnt_name}}</p>
                  <p><b>Age:</b> {{age_txt}} &nbsp;&nbsp;&nbsp;&nbsp;<b>Gender:</b>{{gen_txt}} </p>
                  <p><b>Contact no:</b>{{cont_txt}}</p>
                  <p><b>Address:</b>{{pat_info}}</p>
                  <p><b>Package:</b>{{healthPackageID}}</p>
                </td>
                <td style="vertical-align: top;">
                  <p><b>Date: </b>{{Appointment_Date}}</p>
                  <p><b>Session: </b>{{app_session}}</p>
                  <p><b>Appointment Type:</b> {{app_type}}</p>
                  <p><b>Referred by: Dr.</b>{{refer_txt}}</p>
                  <p><b>App subscribed:</b>{{subscribeApp}}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>