import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-pediatrics-development',
  templateUrl: './pediatrics-development.component.html',
  styleUrls: ['./pediatrics-development.component.css']
})
export class PediatricsDevelopmentComponent implements OnInit {
    public pediaDevelopFlag ="min";
  public header_footer_flag: boolean;
  public ismobile : boolean;
  public Developement_Data;
  public Devel_Cog_1_2_month_Data = [];
  public Devel_Cog_3_month_Data = [];
  public Devel_Cog_4_5_month_Data = [];
  public Devel_Cog_6_7_month_Data = [];
  public Devel_Cog_8_9_month_Data = [];
  public Devel_Cog_10_12_month_Data = [];
  public Devel_Cog_15_month_Data = [];
  public Devel_Cog_18_month_Data = [];
  public Devel_Cog_24_month_Data = [];
  public Devel_Cog_3_year_Data = [];
  public Devel_Cog_4_year_Data = [];
  public Devel_Cog_5_year_Data = [];

  public Devel_Lang_1_2_month_Data = [];
  public Devel_Lang_3_month_Data = [];
  public Devel_Lang_4_5_month_Data = [];
  public Devel_Lang_6_7_month_Data = [];
  public Devel_Lang_8_9_month_Data = [];
  public Devel_Lang_10_12_month_Data = [];
  public Devel_Lang_15_month_Data = [];
  public Devel_Lang_18_month_Data = [];
  public Devel_Lang_24_month_Data = [];
  public Devel_Lang_3_year_Data = [];
  public Devel_Lang_4_year_Data = [];
  public Devel_Lang_5_year_Data = [];
  public main_infant = [];

  public oneyearDecide_teethremarks;
  public oneyearDecide_teeth;
  public cond_txt;
  public oneyearDental_caries;
  public dental_caries;
  public oneyeardental_cariesremarks;
  public oneyearobject_permonce;
  public object_perfom;
  public oneyearobject_remarks;
  public oneyearsleep_pattern;
  public sleep_pattren;
  public oneyear_sleepremarks;
  public twoyearDention_teeth;
  public twoyear_dent;
  public twoyearDention_remarks;
  public twoyearmoral_dev;
  public twoyear_moral;
  public twoyearmoral_remarks;
  public twoyearphyscho_social;
  public twoyear_physcio;
  public twoyearphysco_remarks;

  public twoyeartoilet_training;
  public twoyeartoilet_remarks;
  public twoyear_toilet;
  public two_yearsleep;
  public twoyear_sleep;
  public two_yearsleepremarks;

  public two_yearplayremarks;
  public twoyearplaying_pattren;
  public twoyear_play;

  public threeyearDention_remarks;
  public threeyearDention_teeth;
  public three_dental;

  public threeyearphyscho_remarks;
  public threeyearphyscho_social;
  public three_physco;

  public threeyearmoral_remarks;
  public threeyearmoral_dev;
  public three_moral;

  public threeyeartoilet_remarks;
  public threeyeartoilet_training
  public three_toilet;

  public threeyear_sleep_remarks;
  public threeyear_sleep;
  public three_sleep;

  public threeyearplaying_remarks;
  public threeyearplaying_pattren;
  public three_playing;

  public remark_txt;
  public cog_mianarry = [];
  public dev_cogtxt;

  public dev_threemon;
  public dev_fivemon;
  public dev_six;
  public dev_ten;
  public lan_one;
  public lan_three;
  public language_mainarry = [];
  public lan_five;
  public lan_six;
  public lan_eight;
  public lan_ten;
  public dev_fifteen;
  public dev_eighteen;
  public dev_twenfour;
  public lan_fifteen;
  public lan_eightteen;
  public lan_twentf;
  public cog_thyear;
  public cog_fouryear;
  public cog_fiveyear;
  public lan_threeyear;
  public lan_fouryear;
  public lan_fiveyear;
  public dev_eight;

  public cogone_disabled: boolean;
  public cogthree_disabled: boolean;
  public cogfive_disabled: boolean;
  public cogsix_disabled: boolean;
  public cogeight_disabled: boolean;
  public cogten_disabled: boolean;
  public devfif_disabled: boolean;
  public deveighteen_disabled: boolean;
  public devtwen_disabled: boolean;
  public devthyear_disabled: boolean;
  public devfouryear_disabled: boolean;
  public devfiveyear_disabled: boolean;
  public lagone_disabled: boolean;
  public lagthree_disabled: boolean;
  public lagfour_disabled: boolean;
  public lagsix_disabled: boolean;
  public lageight_disabled: boolean;
  public lagten_disabled: boolean;
  public lagfifteen_disabled: boolean;
  public lageighteen_disabled: boolean;
  public lagtwnfour_disabled: boolean;
  public lanthyear_disabled: boolean;
  public lanfouryear_disabled: boolean;
  public lanfiveyear_disabled: boolean;

  public decide_disabled: boolean;
  public den_disabled: boolean;
  public object_disabled: boolean;
  public sleep_disabled: boolean;
  public tedden_disabled: boolean;
  public tedmore_disabled: boolean;
  public tedsycho_disabled: boolean;
  public tedtoilet_disabled: boolean;
  public tedsleep_disabled: boolean;
  public tedplay_disabled: boolean;
  public preden_disabled: boolean;
  public premore_disabled: boolean;
  public prepyscho_disable: boolean;
  public pretoilet_disable: boolean;
  public presleep_disable: boolean;
  public preplay_disable: boolean;
  public Development_tab;

  public onedecide;
  public oneyear_carremarks;
  public object_remark;
  public one_sleep;
  public two_dention;
  public two_moral;
  public two_physco;
  public two_toilet;
  public two_sleep;
  public two_play;
  public three_dent;
  public three_moral_obj;
  public three_sleep_obj;
  public three_playing_obj;
  public three_toilet_obj;
  public three_physcho_obj;
  public oneyearaccordian: boolean;
  public twoyear: boolean;
  public threeyear: boolean;
  public cognitiveonemonth: boolean;
  public cognitivethreemonth: boolean;
  public cognitivefourmonth: boolean;
  public cognitivesixmonth: boolean;
  public cognitiveeightmonth: boolean;
  public cognitivetenmonth: boolean;

  public lansix: boolean;
  public lanone: boolean;
  public lanfour: boolean;
  public lanthree: boolean;
  public laneight: boolean;
  public lanten: boolean;
  public cogfif: boolean;
  public cogeight: boolean;
  public cogtwnty: boolean;
  public langfifmonth: boolean;
  public langeightmonth: boolean;
  public langtwntymonth: boolean;
  public cogthree: boolean;
  public cogfour: boolean;
  public cogfive: boolean;

  public lanthreeyear: boolean;
  public lanfyear: boolean;
  public lanfiveyear: boolean;
  subscription:Subscription;
  public client:boolean;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,
    public messageservice:CasesheetService,public masterCSdata:MasterCSHelperService) { 
    
    this.oneyearDecide_teeth = "yes";
    this.oneyearDental_caries = "no";
    this.oneyearobject_permonce = "yes";
    this.oneyearsleep_pattern = "yes";
    this.twoyearDention_teeth = "yes";
    this.twoyearmoral_dev = "no";
    this.twoyearphyscho_social = "yes";
    this.twoyeartoilet_training = "yes";
    this.two_yearsleep = "yes";
    this.twoyearplaying_pattren = "yes";
    this.twoyearplaying_pattren = "yes";
    this.threeyearDention_teeth = "yes";
    this.threeyearmoral_dev = "no";
    this.threeyearphyscho_social = "yes";
    this.threeyeartoilet_training = "yes";
    this.threeyear_sleep = "yes";
    this.threeyearplaying_pattren = "yes";

  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
        if (message) {
            this.pediaDevelopFlag = message;
        }
      });

    if(Doc_Helper.getAppFlow() == "client" ){
        this.client = true;
        this.cogone_disabled = true;
        this.cogthree_disabled = true;
        this.cogfive_disabled = true;
        this.cogsix_disabled = true;
        this.cogeight_disabled = true;
        this.cogten_disabled = true;
        this.lagone_disabled = true;
        this.lagthree_disabled = true;
        this.lagfour_disabled = true;
        this.lagsix_disabled = true;
        this.lageight_disabled = true;
        this.lagten_disabled = true;
        this.tedden_disabled = true;

        this.tedmore_disabled = true;
        this.tedsycho_disabled = true;
        this.tedtoilet_disabled = true;
        this.tedsleep_disabled = true;
        this.tedplay_disabled = true;
        this.devfif_disabled = true;
        this.deveighteen_disabled = true;
        this.devtwen_disabled = true;
        this.lagfifteen_disabled = true;
        this.lageighteen_disabled = true;
        this.lagtwnfour_disabled = true;
        this.preden_disabled = true;
        this.premore_disabled = true;
        this.prepyscho_disable = true;

        this.pretoilet_disable = true;
        this.presleep_disable = true;
        this.preplay_disable = true;
        this.devthyear_disabled = true;
        this.devfouryear_disabled = true;
        this.devfiveyear_disabled = true;
        this.lanfouryear_disabled = true;
        this.lanfiveyear_disabled = true;
        this.lanthyear_disabled = true;

    }else{
        this.client = false;
    }

    if(masterCSData_Helper.getMasterCognitiveDevelopment() == undefined){
        await this.masterCSdata.getCognitiveDevelopment();
        this.Get_Cognitive_development();
    }else{
        this.Get_Cognitive_development();
    }
  }

  async Get_Cognitive_development() {
    this.Developement_Data;

    this.Devel_Cog_1_2_month_Data = [];
    this.Devel_Cog_3_month_Data = [];
    this.Devel_Cog_4_5_month_Data = [];
    this.Devel_Cog_6_7_month_Data = [];
    this.Devel_Cog_8_9_month_Data = [];
    this.Devel_Cog_10_12_month_Data = [];
    this.Devel_Cog_15_month_Data = [];
    this.Devel_Cog_18_month_Data = [];
    this.Devel_Cog_24_month_Data = [];
    this.Devel_Cog_3_year_Data = [];
    this.Devel_Cog_4_year_Data = [];
    this.Devel_Cog_5_year_Data = [];

    var obj = masterCSData_Helper.getMasterCognitiveDevelopment();

    // 1-2 months
    if (obj["1-2 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["1-2 months"].length; i++) {
            this.Devel_Cog_1_2_month_Data.push({
                Cognitive_ID: obj["1-2 months"][i].cognitive_id,
                Description: obj["1-2 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt,
            });
        }
    }

    // 3 months
    if (obj["3 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["3 months"].length; i++) {
            this.Devel_Cog_3_month_Data.push({
                Cognitive_ID: obj["3 months"][i].cognitive_id,
                Description: obj["3 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 4-5 months
    if (obj["4-5 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["4-5 months"].length; i++) {
            this.Devel_Cog_4_5_month_Data.push({
                Cognitive_ID: obj["4-5 months"][i].cognitive_id,
                Description: obj["4-5 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 6-7 months
    if (obj["6-7 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["6-7 months"].length; i++) {
            this.Devel_Cog_6_7_month_Data.push({
                Cognitive_ID: obj["6-7 months"][i].cognitive_id,
                Description: obj["6-7 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 8-9 months
    if (obj["8-9 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["8-9 months"].length; i++) {
            this.Devel_Cog_8_9_month_Data.push({
                Cognitive_ID: obj["8-9 months"][i].cognitive_id,
                Description: obj["8-9 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 10-12 months
    if (obj["10-12 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["10-12 months"].length; i++) {
            this.Devel_Cog_10_12_month_Data.push({
                Cognitive_ID: obj["10-12 months"][i].cognitive_id,
                Description: obj["10-12 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 15 months
    if (obj["15 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["15 months"].length; i++) {
            this.Devel_Cog_15_month_Data.push({
                Cognitive_ID: obj["15 months"][i].cognitive_id,
                Description: obj["15 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 18 months
    if (obj["18 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["18 months"].length; i++) {
            this.Devel_Cog_18_month_Data.push({
                Cognitive_ID: obj["18 months"][i].cognitive_id,
                Description: obj["18 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 24 months
    if (obj["24 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["24 months"].length; i++) {
            this.Devel_Cog_24_month_Data.push({
                Cognitive_ID: obj["24 months"][i].cognitive_id,
                Description: obj["24 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 3 years
    if (obj["3 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["3 years"].length; i++) {
            this.Devel_Cog_3_year_Data.push({
                Cognitive_ID: obj["3 years"][i].cognitive_id,
                Description: obj["3 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 4 years
    if (obj["4 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["4 years"].length; i++) {
            this.Devel_Cog_4_year_Data.push({
                Cognitive_ID: obj["4 years"][i].cognitive_id,
                Description: obj["4 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 5 years
    if (obj["5 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["5 years"].length; i++) {
            this.Devel_Cog_5_year_Data.push({
                Cognitive_ID: obj["5 years"][i].cognitive_id,
                Description: obj["5 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    if(masterCSData_Helper.getMasterLanguageDevelopment() == undefined){
        await this.masterCSdata.getLanguageDevelopment();
        this.Get_Language_development();
    }else{
        this.Get_Language_development();
    }
  }

  Get_Language_development() {
    this.Developement_Data;

    this.Devel_Lang_1_2_month_Data = [];
    this.Devel_Lang_3_month_Data = [];
    this.Devel_Lang_4_5_month_Data = [];
    this.Devel_Lang_6_7_month_Data = [];
    this.Devel_Lang_8_9_month_Data = [];
    this.Devel_Lang_10_12_month_Data = [];
    this.Devel_Lang_15_month_Data = [];
    this.Devel_Lang_18_month_Data = [];
    this.Devel_Lang_24_month_Data = [];
    this.Devel_Lang_3_year_Data = [];
    this.Devel_Lang_4_year_Data = [];
    this.Devel_Lang_5_year_Data = [];

    var obj = masterCSData_Helper.getMasterLanguageDevelopment();
    
    // 1-2 months
    if (obj["1-2 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["1-2 months"].length; i++) {
            this.Devel_Lang_1_2_month_Data.push({
                Cognitive_ID: obj["1-2 months"][i].language_id,
                Description: obj["1-2 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 3 months
    if (obj["3 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["3 months"].length; i++) {

            this.Devel_Lang_3_month_Data.push({
                Cognitive_ID: obj["3 months"][i].language_id,
                Description: obj["3 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 4-5 months
    if (obj["4-5 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["4-5 months"].length; i++) {

            this.Devel_Lang_4_5_month_Data.push({
                Cognitive_ID: obj["4-5 months"][i].language_id,
                Description: obj["4-5 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }


    // 6-7 months
    if (obj["6-7 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["6-7 months"].length; i++) {

            this.Devel_Lang_6_7_month_Data.push({
                Cognitive_ID: obj["6-7 months"][i].language_id,
                Description: obj["6-7 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 8-9 months
    if (obj["8-9 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["8-9 months"].length; i++) {

            this.Devel_Lang_8_9_month_Data.push({
                Cognitive_ID: obj["8-9 months"][i].language_id,
                Description: obj["8-9 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 10-12 months
    if (obj["10-12 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["10-12 months"].length; i++) {

            this.Devel_Lang_10_12_month_Data.push({
                Cognitive_ID: obj["10-12 months"][i].language_id,
                Description: obj["10-12 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 15 months
    if (obj["15 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["15 months"].length; i++) {

            this.Devel_Lang_15_month_Data.push({
                Cognitive_ID: obj["15 months"][i].language_id,
                Description: obj["15 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 18 months
    if (obj["18 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["18 months"].length; i++) {

            this.Devel_Lang_18_month_Data.push({
                Cognitive_ID: obj["18 months"][i].language_id,
                Description: obj["18 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }

    // 24 months
    if (obj["24 months"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["24 months"].length; i++) {

            this.Devel_Lang_24_month_Data.push({
                Cognitive_ID: obj["24 months"][i].language_id,
                Description: obj["24 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 3 years
    if (obj["3 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["3 years"].length; i++) {

            this.Devel_Lang_3_year_Data.push({
                Cognitive_ID: obj["3 years"][i].language_id,
                Description: obj["3 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 4 years
    if (obj["4 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["4 years"].length; i++) {

            this.Devel_Lang_4_year_Data.push({
                Cognitive_ID: obj["4 years"][i].language_id,
                Description: obj["4 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    // 5 years
    if (obj["5 years"] != null) {
        this.remark_txt = "";
        for (var i = 0; i < obj["5 years"].length; i++) {

            this.Devel_Lang_5_year_Data.push({
                Cognitive_ID: obj["5 years"][i].language_id,
                Description: obj["5 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark_txt
            });
        }
    }
    this.GetDevelopmentData();
  }


  //send development data
  @HostListener('window:click', ['$event'])
    onclick(event: any): void {
        if(this.client == false){
            this.send_data();
        }
  }
  @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        if(this.client == false){
            this.send_data();
        }  }

  send_data() {
    this.main_infant = [];
    this.cog_mianarry=[];
    this.language_mainarry=[];
    
    //one year decide teeth
    if (this.oneyearDecide_teeth == "yes") {
        this.cond_txt = "Normal";

    } else {
        this.cond_txt = "Abnormal";
    }

    if (this.oneyearDecide_teethremarks != undefined) {
        this.onedecide = this.oneyearDecide_teethremarks;

    } else {
        this.onedecide = "";
    }
    var child_decided = {
        age_group: "Infant",
        develop_type: "Deciduous teeth",
        remarks: this.onedecide,
        condition: this.cond_txt,
    };

    this.main_infant.push(child_decided);

    // one year dental caries
    if (this.oneyearDental_caries == "yes") {
        this.dental_caries = "Yes";

    } else {
        this.dental_caries = "No";
    }

    if (this.oneyeardental_cariesremarks != undefined) {
        this.oneyear_carremarks = this.oneyeardental_cariesremarks;

    } else {
        this.oneyear_carremarks = "";
    }
    var dental_caries_data = {
        age_group: "Infant",
        develop_type: "Dental caries",
        remarks: this.oneyear_carremarks,
        condition: this.dental_caries,
    };

    this.main_infant.push(dental_caries_data);

    // one year object perfomance
    if (this.oneyearobject_permonce == "yes") {
        this.object_perfom = "Normal";

    } else {
        this.object_perfom = "Abnormal";
    }

    if (this.oneyearobject_remarks != undefined) {
        this.object_remark = this.oneyearobject_remarks;

    } else {
        this.object_remark = "";
    }
    var object_perfomance_array = {
        age_group: "Infant",
        develop_type: "Object permanence",
        remarks: this.object_remark,
        condition: this.object_perfom,
    };
    this.main_infant.push(object_perfomance_array);

    //one year sleep pattren
    if (this.oneyearsleep_pattern == "yes") {
        this.sleep_pattren = "Normal";

    } else {
        this.sleep_pattren = "Abnormal";
    }
    if (this.oneyear_sleepremarks != undefined) {
        this.one_sleep = this.oneyear_sleepremarks;

    } else {
        this.one_sleep = "";
    }
    var sleep_pattren_array = {
        age_group: "Infant",
        develop_type: "Sleep pattern",
        remarks: this.one_sleep,
        condition: this.sleep_pattren,
    };
    this.main_infant.push(sleep_pattren_array);

    //toodler
    //two year dention
    if (this.twoyearDention_teeth == "yes") {
        this.twoyear_dent = "Normal";

    } else {
        this.twoyear_dent = "Abnormal";
    }

    if (this.twoyearDention_remarks != undefined) {
        this.two_dention = this.twoyearDention_remarks;

    } else {
        this.two_dention = "";
    }
    var twoyear_dental_array = {
        age_group: "Toddler",
        develop_type: "Dentition",
        remarks: this.two_dention,
        condition: this.twoyear_dent,
    };
    this.main_infant.push(twoyear_dental_array);

    //two year Moral development
    if (this.twoyearmoral_dev == "yes") {
        this.twoyear_moral = "Yes";

    } else {
        this.twoyear_moral = "No";
    }
    if (this.twoyearmoral_remarks != undefined) {
        this.two_moral = this.twoyearmoral_remarks;

    } else {
        this.two_moral = "";
    }
    var twoyear_moral_array = {
        age_group: "Toddler",
        develop_type: "Moral development",
        remarks: this.two_moral,
        condition: this.twoyear_moral,
    };
    this.main_infant.push(twoyear_moral_array);

    //two year physco development
    if (this.twoyearphyscho_social == "yes") {
        this.twoyear_physcio = "Normal";

    } else {
        this.twoyear_physcio = "Abnormal";
    }
    if (this.twoyearphysco_remarks != undefined) {
        this.two_physco = this.twoyearmoral_remarks;

    } else {
        this.two_physco = "";
    }
    var twoyear_physco_array = {
        age_group: "Toddler",
        develop_type: "Psycho-social development",
        remarks: this.two_physco,
        condition: this.twoyear_physcio,
    };
    this.main_infant.push(twoyear_physco_array);

    //two year toilet training
    if (this.twoyeartoilet_training == "yes") {
        this.twoyear_toilet = "Normal";

    } else {
        this.twoyear_toilet = "Abnormal";
    }

    if (this.twoyeartoilet_remarks != undefined) {
        this.two_toilet = this.twoyeartoilet_remarks;

    } else {
        this.two_toilet = "";
    }
    var twoyear_toilet_array = {
        age_group: "Toddler",
        develop_type: "Toilet training",
        remarks: this.two_toilet,
        condition: this.twoyear_toilet,
    };
    this.main_infant.push(twoyear_toilet_array);

    //two year sleep training
    if (this.two_yearsleep == "yes") {
        this.twoyear_sleep = "Normal";

    } else {
        this.twoyear_sleep = "Abnormal";
    }

    if (this.two_yearsleepremarks != undefined) {
        this.two_sleep = this.two_yearsleepremarks;

    } else {
        this.two_sleep = "";
    }
    var twoyear_sleep_array = {
        age_group: "Toddler",
        develop_type: "Sleep pattern",
        remarks: this.two_sleep,
        condition: this.twoyear_sleep,
    };
    this.main_infant.push(twoyear_sleep_array);

    //two year playing training
    if (this.twoyearplaying_pattren == "yes") {
        this.twoyear_play = "Normal";

    } else {
        this.twoyear_play = "Abnormal";
    }
    if (this.two_yearplayremarks != undefined) {
        this.two_play = this.two_yearplayremarks;

    } else {
        this.two_play = "";
    }
    var twoyear_playing_array = {
        age_group: "Toddler",
        develop_type: "Playing pattern",
        remarks: this.two_play,
        condition: this.twoyear_play,
    };
    this.main_infant.push(twoyear_playing_array);

    //pre schooler
    if (this.threeyearDention_teeth == "yes") {
        this.three_dental = "Normal";

    } else {
        this.three_dental = "Abnormal";
    }

    if (this.threeyearDention_remarks != undefined) {
        this.three_dent = this.threeyearDention_remarks;

    } else {
        this.three_dent = "";
    }
    var three_dental_array = {
        age_group: "Preschool",
        develop_type: "Dentition",
        remarks: this.three_dent,
        condition: this.three_dental,
    };
    this.main_infant.push(three_dental_array);

    //moral development
    if (this.threeyearmoral_dev == "yes") {
        this.three_moral = "Yes";

    } else {
        this.three_moral = "No";
    }

    if (this.threeyearmoral_remarks != undefined) {
        this.three_moral_obj = this.threeyearmoral_remarks;

    } else {
        this.three_moral_obj = "";
    }
    var three_moral_array = {
        age_group: "Preschool",
        develop_type: "Moral development",
        remarks: this.three_moral_obj,
        condition: this.three_moral,
    };
    this.main_infant.push(three_moral_array);

    //physio development
    if (this.threeyearphyscho_social == "yes") {
        this.three_physco = "Normal";

    } else {
        this.three_physco = "Abnormal";
    }

    if (this.threeyearphyscho_remarks != undefined) {
        this.three_physcho_obj = this.threeyearphyscho_remarks;

    } else {
        this.three_physcho_obj = "";
    }
    var three_physio_array = {
        age_group: "Preschool",
        develop_type: "Psycho-social development",
        remarks: this.three_physcho_obj,
        condition: this.three_physco,
    };
    this.main_infant.push(three_physio_array);

    //toilet trainig
    if (this.threeyeartoilet_training == "yes") {
        this.three_toilet = "Normal";

    } else {
        this.three_toilet = "Abnormal";
    }
    if (this.threeyeartoilet_remarks != undefined) {
        this.three_toilet_obj = this.threeyeartoilet_remarks;

    } else {
        this.three_toilet_obj = "";
    }
    var three_toilet_array = {
        age_group: "Preschool",
        develop_type: "Toilet training",
        remarks: this.three_toilet_obj,
        condition: this.three_toilet,
    };
    this.main_infant.push(three_toilet_array);

    //sleep pattren
    if (this.threeyear_sleep == "yes") {
        this.three_sleep = "Normal";

    } else {
        this.three_sleep = "Abnormal";
    }

    if (this.threeyear_sleep_remarks != undefined) {
        this.three_sleep_obj = this.threeyear_sleep_remarks;

    } else {
        this.three_sleep_obj = "";
    }
    var three_sleep_array = {
        age_group: "Preschool",
        develop_type: "Sleep pattern",
        remarks: this.three_sleep_obj,
        condition: this.three_sleep,
    };
    this.main_infant.push(three_sleep_array);

    //playing pattren
    if (this.threeyearplaying_pattren == "yes") {
        this.three_playing = "Normal";

    } else {
        this.three_playing = "Abnormal";
    }
    if (this.threeyearplaying_remarks != undefined) {
        this.three_playing_obj = this.threeyearplaying_remarks;

    } else {
        this.three_playing_obj = "";
    }
    var three_playing_array = {
        age_group: "Preschool",
        develop_type: "Playing pattern",
        remarks: this.three_playing_obj,
        condition: this.three_playing,
    };
    this.main_infant.push(three_playing_array);

    //Cognitive development
    for (var i = 0; i < this.Devel_Cog_1_2_month_Data.length; i++) {
        if (this.Devel_Cog_1_2_month_Data[i].No == true) {
            this.dev_cogtxt = "Abnormal";

        } else {
            this.dev_cogtxt = "Normal";
        }

        var cognitive_onetwo = {
            condition: this.dev_cogtxt,
            cognitive_id: this.Devel_Cog_1_2_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_1_2_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_onetwo);
    }

    for (var i = 0; i < this.Devel_Cog_3_month_Data.length; i++) {
        if (this.Devel_Cog_3_month_Data[i].No == true) {
            this.dev_threemon = "No";

        } else {
            this.dev_threemon = "Yes";
        }

        var cognitive_lan = {
            condition: this.dev_threemon,
            cognitive_id: this.Devel_Cog_3_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_3_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_lan);
    }

    for (var i = 0; i < this.Devel_Cog_4_5_month_Data.length; i++) {
        if (this.Devel_Cog_4_5_month_Data[i].No == true) {
            this.dev_fivemon = "No";

        } else {
            this.dev_fivemon = "Yes";
        }

        var cognitive_four = {
            condition: this.dev_fivemon,
            cognitive_id: this.Devel_Cog_4_5_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_4_5_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_four);
    }

    for (var i = 0; i < this.Devel_Cog_6_7_month_Data.length; i++) {
        if (this.Devel_Cog_6_7_month_Data[i].No == true) {
            this.dev_six = "No";

        } else {
            this.dev_six = "Yes";
        }

        var cognitive_six = {
            condition: this.dev_six,
            cognitive_id: this.Devel_Cog_6_7_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_6_7_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_six);
    }

    for (var i = 0; i < this.Devel_Cog_8_9_month_Data.length; i++) {
        if (this.Devel_Cog_8_9_month_Data[i].No == true) {
            this.dev_eight = "No";

        } else {
            this.dev_eight = "Yes";
        }

        var cognitive_eight = {
            condition: this.dev_eight,
            cognitive_id: this.Devel_Cog_8_9_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_8_9_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_eight);
    }

    for (var i = 0; i < this.Devel_Cog_10_12_month_Data.length; i++) {
        if (this.Devel_Cog_10_12_month_Data[i].No == true) {
            this.dev_ten = "No";

        } else {
            this.dev_ten = "Yes";
        }

        var cognitive_ten = {
            condition: this.dev_ten,
            cognitive_id: this.Devel_Cog_10_12_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_10_12_month_Data[i].remarks,
        };
        this.cog_mianarry.push(cognitive_ten);
    }

    for (var i = 0; i < this.Devel_Lang_1_2_month_Data.length; i++) {
        if (this.Devel_Lang_1_2_month_Data[i].No == true) {
            this.lan_one = "No";

        } else {
            this.lan_one = "Yes";
        }

        var lan_onelist = {
            condition: this.lan_one,
            language_id: this.Devel_Lang_1_2_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_1_2_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_onelist);
    }

    for (var i = 0; i < this.Devel_Lang_3_month_Data.length; i++) {
        if (this.Devel_Lang_3_month_Data[i].No == true) {
            this.lan_three = "No";

        } else {
            this.lan_three = "Yes";
        }

        var lan_threelist = {
            condition: this.lan_three,
            language_id: this.Devel_Lang_3_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_3_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_threelist);
    }

    for (var i = 0; i < this.Devel_Lang_4_5_month_Data.length; i++) {
        if (this.Devel_Lang_4_5_month_Data[i].No == true) {
            this.lan_five = "No";

        } else {
            this.lan_five = "Yes";
        }

        var lan_fivelist = {
            condition: this.lan_five,
            language_id: this.Devel_Lang_4_5_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_4_5_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_fivelist);
    }

    for (var i = 0; i < this.Devel_Lang_6_7_month_Data.length; i++) {
        if (this.Devel_Lang_6_7_month_Data[i].No == true) {
            this.lan_six = "No";

        } else {
            this.lan_six = "Yes";
        }

        var lan_sixlist = {
            condition: this.lan_six,
            language_id: this.Devel_Lang_6_7_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_6_7_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_sixlist);
    }

    for (var i = 0; i < this.Devel_Lang_8_9_month_Data.length; i++) {
        if (this.Devel_Lang_8_9_month_Data[i].No == true) {
            this.lan_eight = "No";

        } else {
            this.lan_eight = "Yes";
        }

        var lan_eightlist = {
            condition: this.lan_eight,
            language_id: this.Devel_Lang_8_9_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_8_9_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_eightlist);
    }

    for (var i = 0; i < this.Devel_Lang_10_12_month_Data.length; i++) {
        if (this.Devel_Lang_10_12_month_Data[i].No == true) {
            this.lan_ten = "No";

        } else {
            this.lan_ten = "Yes";
        }

        var lan_tenlist = {
            condition: this.lan_ten,
            language_id: this.Devel_Lang_10_12_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_10_12_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_tenlist);
    }

    for (var i = 0; i < this.Devel_Cog_15_month_Data.length; i++) {
        if (this.Devel_Cog_15_month_Data[i].No == true) {
            this.dev_fifteen = "No";

        } else {
            this.dev_fifteen = "Yes";
        }

        var dev_fiflist = {
            condition: this.dev_fifteen,
            cognitive_id: this.Devel_Cog_15_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_15_month_Data[i].remarks,
        };
        this.cog_mianarry.push(dev_fiflist);
    }

    for (var i = 0; i < this.Devel_Cog_18_month_Data.length; i++) {
        if (this.Devel_Cog_18_month_Data[i].No == true) {
            this.dev_eighteen = "No";

        } else {
            this.dev_eighteen = "Yes";
        }

        var dev_eighteenlist = {
            condition: this.dev_eighteen,
            cognitive_id: this.Devel_Cog_18_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_18_month_Data[i].remarks,
        };
        this.cog_mianarry.push(dev_eighteenlist);
    }

    for (var i = 0; i < this.Devel_Cog_24_month_Data.length; i++) {
        if (this.Devel_Cog_24_month_Data[i].No == true) {
            this.dev_twenfour = "No";

        } else {
            this.dev_twenfour = "Yes";
        }

        var dev_twenflist = {
            condition: this.dev_twenfour,
            cognitive_id: this.Devel_Cog_24_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_24_month_Data[i].remarks,
        };
        this.cog_mianarry.push(dev_twenflist);
    }

    for (var i = 0; i < this.Devel_Lang_15_month_Data.length; i++) {
        if (this.Devel_Lang_15_month_Data[i].No == true) {
            this.lan_fifteen = "No";

        } else {
            this.lan_fifteen = "Yes";
        }

        var lan_fiflist = {
            condition: this.lan_fifteen,
            language_id: this.Devel_Lang_15_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_15_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_fiflist);
    }

    for (var i = 0; i < this.Devel_Lang_18_month_Data.length; i++) {
        if (this.Devel_Lang_18_month_Data[i].No == true) {
            this.lan_eightteen = "No";

        } else {
            this.lan_eightteen = "Yes";
        }

        var lan_eighteenlist = {
            condition: this.lan_eightteen,
            language_id: this.Devel_Lang_18_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_18_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_eighteenlist);
    }

    for (var i = 0; i < this.Devel_Lang_24_month_Data.length; i++) {
        if (this.Devel_Lang_24_month_Data[i].No == true) {
            this.lan_twentf = "No";

        } else {
            this.lan_twentf = "Yes";
        }

        var lan_twenflist = {
            condition: this.lan_twentf,
            language_id: this.Devel_Lang_24_month_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_24_month_Data[i].remarks,
        };
        this.language_mainarry.push(lan_twenflist);
    }

    for (var i = 0; i < this.Devel_Cog_3_year_Data.length; i++) {
        if (this.Devel_Cog_3_year_Data[i].No == true) {
            this.cog_thyear = "No";

        } else {
            this.cog_thyear = "Yes";
        }

        var cog_threeylist = {
            condition: this.cog_thyear,
            cognitive_id: this.Devel_Cog_3_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_3_year_Data[i].remarks,
        };
        this.cog_mianarry.push(cog_threeylist);
    }

    for (var i = 0; i < this.Devel_Cog_4_year_Data.length; i++) {
        if (this.Devel_Cog_4_year_Data[i].No == true) {
            this.cog_fouryear = "No";

        } else {
            this.cog_fouryear = "Yes";
        }

        var cog_fouryearlist = {
            condition: this.cog_fouryear,
            cognitive_id: this.Devel_Cog_4_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_4_year_Data[i].remarks,
        };
        this.cog_mianarry.push(cog_fouryearlist);
    }

    for (var i = 0; i < this.Devel_Cog_5_year_Data.length; i++) {
        if (this.Devel_Cog_5_year_Data[i].No == true) {
            this.cog_fiveyear = "No";

        } else {
            this.cog_fiveyear = "Yes";
        }

        var cog_fiveyearlist = {
            condition: this.cog_fiveyear,
            cognitive_id: this.Devel_Cog_5_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Cog_5_year_Data[i].remarks,
        };
        this.cog_mianarry.push(cog_fiveyearlist);
    }

    for (var i = 0; i < this.Devel_Lang_3_year_Data.length; i++) {
        if (this.Devel_Lang_3_year_Data[i].No == true) {
            this.lan_threeyear = "No";

        } else {
            this.lan_threeyear = "Yes";
        }

        var lan_threeyearlist = {
            condition: this.lan_threeyear,
            language_id: this.Devel_Lang_3_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_3_year_Data[i].remarks,
        };
        this.language_mainarry.push(lan_threeyearlist);
    }

    for (var i = 0; i < this.Devel_Lang_4_year_Data.length; i++) {
        if (this.Devel_Lang_4_year_Data[i].No == true) {
            this.lan_fouryear = "No";

        } else {
            this.lan_fouryear = "Yes";
        }

        var lan_fouryearlist = {
            condition: this.lan_fouryear,
            language_id: this.Devel_Lang_4_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_4_year_Data[i].remarks,
        };
        this.language_mainarry.push(lan_fouryearlist);
    }

    for (var i = 0; i < this.Devel_Lang_5_year_Data.length; i++) {
        if (this.Devel_Lang_5_year_Data[i].No == true) {
            this.lan_fiveyear = "No";

        } else {
            this.lan_fiveyear = "Yes";
        }

        var lan_fiveyearlist = {
            condition: this.lan_fiveyear,
            language_id: this.Devel_Lang_5_year_Data[i].Cognitive_ID,
            remarks: this.Devel_Lang_5_year_Data[i].remarks,
        };
        this.language_mainarry.push(lan_fiveyearlist);
    }

    var pedia_development = null;

    if(this.main_infant.length ==0){
        this.main_infant=undefined;
    }
    
    pedia_development = {
        child_develop: this.main_infant,
        cognitive: this.cog_mianarry,
        language: this.language_mainarry,
    }
    Helper_Class.setpediadevelop(pedia_development);
  }

  GetDevelopmentData() {
  var getpediaRet = Helper_Class.getpediaRet();
  if (getpediaRet != undefined) {
      if (getpediaRet.cognitive != null) {
         // 1-2 months
          if (getpediaRet.cognitive[0]["1-2 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["1-2 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_1_2_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["1-2 months"][i].cognitive_id == this.Devel_Cog_1_2_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["1-2 months"][i].cog_condition == "Abnormal") {
                              this.Devel_Cog_1_2_month_Data[j].Yes = false;
                              this.Devel_Cog_1_2_month_Data[j].No = true;
                              this.Devel_Cog_1_2_month_Data[j].remarks = getpediaRet.cognitive[0]["1-2 months"][i].remarks;
                              this.cogone_disabled = true;

                          } else {
                              this.Devel_Cog_1_2_month_Data[j].Yes = true;
                              this.Devel_Cog_1_2_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogone_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 3 months
          if (getpediaRet.cognitive[0]["3 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["3 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_3_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["3 months"][i].cognitive_id == this.Devel_Cog_3_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["3 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_3_month_Data[j].Yes = false;
                              this.Devel_Cog_3_month_Data[j].No = true;
                              this.Devel_Cog_3_month_Data[j].remarks = getpediaRet.cognitive[0]["3 months"][i].remarks;
                              this.cogthree_disabled = true;

                          } else {
                              this.Devel_Cog_3_month_Data[j].Yes = true;
                              this.Devel_Cog_3_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogthree_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 4-5 months
          if (getpediaRet.cognitive[0]["4-5 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["4-5 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_4_5_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["4-5 months"][i].cognitive_id == this.Devel_Cog_4_5_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["4-5 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_4_5_month_Data[j].Yes = false;
                              this.Devel_Cog_4_5_month_Data[j].No = true;
                              this.Devel_Cog_4_5_month_Data[j].remarks = getpediaRet.cognitive[0]["4-5 months"][i].remarks;
                              this.cogfive_disabled = true;

                          } else {
                              this.Devel_Cog_4_5_month_Data[j].Yes = true;
                              this.Devel_Cog_4_5_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogfive_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 6-7 months
          if (getpediaRet.cognitive[0]["6-7 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["6-7 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_6_7_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["6-7 months"][i].cognitive_id == this.Devel_Cog_6_7_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["6-7 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_6_7_month_Data[j].Yes = false;
                              this.Devel_Cog_6_7_month_Data[j].No = true;
                              this.Devel_Cog_6_7_month_Data[j].remarks = getpediaRet.cognitive[0]["6-7 months"][i].remarks;

                              this.cogsix_disabled = true;

                          } else {
                              this.Devel_Cog_6_7_month_Data[j].Yes = true;
                              this.Devel_Cog_6_7_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogsix_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 8-9 months
          if (getpediaRet.cognitive[0]["8-9 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["8-9 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_8_9_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["8-9 months"][i].cognitive_id == this.Devel_Cog_8_9_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["8-9 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_8_9_month_Data[j].Yes = false;
                              this.Devel_Cog_8_9_month_Data[j].No = true;
                              this.Devel_Cog_8_9_month_Data[j].remarks = getpediaRet.cognitive[0]["8-9 months"][i].remarks;
                              this.cogeight_disabled = true;

                          } else {
                              this.Devel_Cog_8_9_month_Data[j].Yes = true;
                              this.Devel_Cog_8_9_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogeight_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 10-12 months
          if (getpediaRet.cognitive[0]["10-12 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["10-12 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_10_12_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["10-12 months"][i].cognitive_id == this.Devel_Cog_10_12_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["10-12 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_10_12_month_Data[j].Yes = false;
                              this.Devel_Cog_10_12_month_Data[j].No = true;
                              this.Devel_Cog_10_12_month_Data[j].remarks = getpediaRet.cognitive[0]["10-12 months"][i].remarks;
                              this.cogten_disabled = true;

                          } else {
                              this.Devel_Cog_10_12_month_Data[j].Yes = true;
                              this.Devel_Cog_10_12_month_Data[j].No = false;
                              if(this.client == false){
                                this.cogten_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 15 months
          if (getpediaRet.cognitive[0]["15 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["15 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_15_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["15 months"][i].cognitive_id == this.Devel_Cog_15_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["15 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_15_month_Data[j].Yes = false;
                              this.Devel_Cog_15_month_Data[j].No = true;
                              this.Devel_Cog_15_month_Data[j].remarks = getpediaRet.cognitive[0]["15 months"][i].remarks;
                              this.devfif_disabled = true;

                          } else {
                              this.Devel_Cog_15_month_Data[j].Yes = true;
                              this.Devel_Cog_15_month_Data[j].No = false;
                              if(this.client == false){
                                this.devfif_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 18 months
          if (getpediaRet.cognitive[0]["18 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["18 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_18_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["18 months"][i].cognitive_id == this.Devel_Cog_18_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["18 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_18_month_Data[j].Yes = false;
                              this.Devel_Cog_18_month_Data[j].No = true;
                              this.Devel_Cog_18_month_Data[j].remarks = getpediaRet.cognitive[0]["18 months"][i].remarks;
                              this.deveighteen_disabled = true;

                          } else {
                              this.Devel_Cog_18_month_Data[j].Yes = true;
                              this.Devel_Cog_18_month_Data[j].No = false;
                              if(this.client == false){
                                this.deveighteen_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 24 months
          if (getpediaRet.cognitive[0]["24 months"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["24 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_24_month_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["24 months"][i].cognitive_id == this.Devel_Cog_24_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["24 months"][i].cog_condition != "Yes") {
                              this.Devel_Cog_24_month_Data[j].Yes = false;
                              this.Devel_Cog_24_month_Data[j].No = true;
                              this.Devel_Cog_24_month_Data[j].remarks = getpediaRet.cognitive[0]["24 months"][i].remarks;
                              this.devtwen_disabled = true;

                          } else {
                              this.Devel_Cog_24_month_Data[j].Yes = true;
                              this.Devel_Cog_24_month_Data[j].No = false;
                              if(this.client == false){
                                 this.devtwen_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 3 years
          if (getpediaRet.cognitive[0]["3 years"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["3 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_3_year_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["3 years"][i].cognitive_id == this.Devel_Cog_3_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["3 years"][i].cog_condition != "Yes") {
                              this.Devel_Cog_3_year_Data[j].Yes = false;
                              this.Devel_Cog_3_year_Data[j].No = true;
                              this.Devel_Cog_3_year_Data[j].remarks = getpediaRet.cognitive[0]["3 years"][i].remarks;
                              this.devthyear_disabled = true;

                          } else {
                              this.Devel_Cog_3_year_Data[j].Yes = true;
                              this.Devel_Cog_3_year_Data[j].No = false;
                              if(this.client == false){
                              this.devthyear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 4 years
          if (getpediaRet.cognitive[0]["4 years"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["4 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_4_year_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["4 years"][i].cognitive_id == this.Devel_Cog_4_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["4 years"][i].cog_condition != "Yes") {
                              this.Devel_Cog_4_year_Data[j].Yes = false;
                              this.Devel_Cog_4_year_Data[j].No = true;
                              this.Devel_Cog_4_year_Data[j].remarks = getpediaRet.cognitive[0]["4 years"][i].remarks;
                              this.devfouryear_disabled = true;

                          } else {
                              this.Devel_Cog_4_year_Data[j].Yes = true;
                              this.Devel_Cog_4_year_Data[j].No = false;
                              if(this.client == false){
                              this.devfouryear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 5 years
          if (getpediaRet.cognitive[0]["5 years"] != null) {
              for (var i = 0; i < getpediaRet.cognitive[0]["5 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Cog_5_year_Data.length; j++) {
                      if (getpediaRet.cognitive[0]["5 years"][i].cognitive_id == this.Devel_Cog_5_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.cognitive[0]["5 years"][i].cog_condition != "Yes") {
                              this.Devel_Cog_5_year_Data[j].Yes = false;
                              this.Devel_Cog_5_year_Data[j].No = true;
                              this.Devel_Cog_5_year_Data[j].remarks = getpediaRet.cognitive[0]["5 years"][i].remarks;
                              this.devfiveyear_disabled = true;

                          } else {
                              this.Devel_Cog_5_year_Data[j].Yes = true;
                              this.Devel_Cog_5_year_Data[j].No = false;
                              if(this.client == false){
                              this.devfiveyear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }
      }

      if (getpediaRet.language != null) {
          // 1-2 months
          if (getpediaRet.language[0]["1-2 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["1-2 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_1_2_month_Data.length; j++) {
                      if (getpediaRet.language[0]["1-2 months"][i].language_id == this.Devel_Lang_1_2_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["1-2 months"][i].condition != "Yes") {
                              this.Devel_Lang_1_2_month_Data[j].Yes = false;
                              this.Devel_Lang_1_2_month_Data[j].No = true;
                              this.Devel_Lang_1_2_month_Data[j].remarks = getpediaRet.language[0]["1-2 months"][i].remarks;

                              this.lagone_disabled = true;

                          } else {
                              this.Devel_Lang_1_2_month_Data[j].Yes = true;
                              this.Devel_Lang_1_2_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagone_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 3 months
          if (getpediaRet.language[0]["3 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["3 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_3_month_Data.length; j++) {
                      if (getpediaRet.language[0]["3 months"][i].language_id == this.Devel_Lang_3_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["3 months"][i].condition != "Yes") {
                              this.Devel_Lang_3_month_Data[j].Yes = false;
                              this.Devel_Lang_3_month_Data[j].No = true;
                              this.Devel_Lang_3_month_Data[j].remarks = getpediaRet.language[0]["3 months"][i].remarks;
                              this.lagthree_disabled = true;

                          } else {
                              this.Devel_Lang_3_month_Data[j].Yes = true;
                              this.Devel_Lang_3_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagthree_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 4-5 months
          if (getpediaRet.language[0]["4-5 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["4-5 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_4_5_month_Data.length; j++) {
                      if (getpediaRet.language[0]["4-5 months"][i].language_id == this.Devel_Lang_4_5_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["4-5 months"][i].condition != "Yes") {
                              this.Devel_Lang_4_5_month_Data[j].Yes = false;
                              this.Devel_Lang_4_5_month_Data[j].No = true;
                              this.Devel_Lang_4_5_month_Data[j].remarks = getpediaRet.language[0]["4-5 months"][i].remarks;
                              this.lagfour_disabled = true;

                          } else {
                              this.Devel_Lang_4_5_month_Data[j].Yes = true;
                              this.Devel_Lang_4_5_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagfour_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 6-7 months
          if (getpediaRet.language[0]["6-7 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["6-7 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_6_7_month_Data.length; j++) {
                      if (getpediaRet.language[0]["6-7 months"][i].language_id == this.Devel_Lang_6_7_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["6-7 months"][i].condition != "Yes") {
                              this.Devel_Lang_6_7_month_Data[j].Yes = false;
                              this.Devel_Lang_6_7_month_Data[j].No = true;
                              this.Devel_Lang_6_7_month_Data[j].remarks = getpediaRet.language[0]["6-7 months"][i].remarks;
                              this.lagsix_disabled = true;

                          } else {
                              this.Devel_Lang_6_7_month_Data[j].Yes = true;
                              this.Devel_Lang_6_7_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagsix_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 8-9 months
          if (getpediaRet.language[0]["8-9 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["8-9 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_8_9_month_Data.length; j++) {
                      if (getpediaRet.language[0]["8-9 months"][i].language_id == this.Devel_Lang_8_9_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["8-9 months"][i].condition != "Yes") {
                              this.Devel_Lang_8_9_month_Data[j].Yes = false;
                              this.Devel_Lang_8_9_month_Data[j].No = true;
                              this.Devel_Lang_8_9_month_Data[j].remarks = getpediaRet.language[0]["8-9 months"][i].remarks;

                              this.lageight_disabled = true;

                          } else {
                              this.Devel_Lang_8_9_month_Data[j].Yes = true;
                              this.Devel_Lang_8_9_month_Data[j].No = false;
                              if(this.client == false){
                              this.lageight_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 10-12 months
          if (getpediaRet.language[0]["10-12 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["10-12 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_10_12_month_Data.length; j++) {
                      if (getpediaRet.language[0]["10-12 months"][i].language_id == this.Devel_Lang_10_12_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["10-12 months"][i].condition != "Yes") {
                              this.Devel_Lang_10_12_month_Data[j].Yes = false;
                              this.Devel_Lang_10_12_month_Data[j].No = true;
                              this.Devel_Lang_10_12_month_Data[j].remarks = getpediaRet.language[0]["10-12 months"][i].remarks;
                              this.lagten_disabled = true;

                          } else {
                              this.Devel_Lang_10_12_month_Data[j].Yes = true;
                              this.Devel_Lang_10_12_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagten_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 15 months
          if (getpediaRet.language[0]["15 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["15 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_15_month_Data.length; j++) {
                      if (getpediaRet.language[0]["15 months"][i].language_id == this.Devel_Lang_15_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["15 months"][i].condition != "Yes") {
                              this.Devel_Lang_15_month_Data[j].Yes = false;
                              this.Devel_Lang_15_month_Data[j].No = true;
                              this.Devel_Lang_15_month_Data[j].remarks = getpediaRet.language[0]["15 months"][i].remarks;
                              this.lagfifteen_disabled = true;

                          } else {
                              this.Devel_Lang_15_month_Data[j].Yes = true;
                              this.Devel_Lang_15_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagfifteen_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 18 months
          if (getpediaRet.language[0]["18 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["18 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_18_month_Data.length; j++) {
                      if (getpediaRet.language[0]["18 months"][i].language_id == this.Devel_Lang_18_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["18 months"][i].condition != "Yes") {
                              this.Devel_Lang_18_month_Data[j].Yes = false;
                              this.Devel_Lang_18_month_Data[j].No = true;
                              this.Devel_Lang_18_month_Data[j].remarks = getpediaRet.language[0]["18 months"][i].remarks;
                              this.lageighteen_disabled = true;

                          } else {
                              this.Devel_Lang_18_month_Data[j].Yes = true;
                              this.Devel_Lang_18_month_Data[j].No = false;
                              if(this.client == false){
                              this.lageighteen_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 24 months
          if (getpediaRet.language[0]["24 months"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["24 months"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_24_month_Data.length; j++) {
                      if (getpediaRet.language[0]["24 months"][i].language_id == this.Devel_Lang_24_month_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["24 months"][i].condition != "Yes") {
                              this.Devel_Lang_24_month_Data[j].Yes = false;
                              this.Devel_Lang_24_month_Data[j].No = true;
                              this.Devel_Lang_24_month_Data[j].remarks = getpediaRet.language[0]["24 months"][i].remarks;
                              this.lagtwnfour_disabled = true;

                          } else {
                              this.Devel_Lang_24_month_Data[j].Yes = true;
                              this.Devel_Lang_24_month_Data[j].No = false;
                              if(this.client == false){
                              this.lagtwnfour_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 3 years
          if (getpediaRet.language[0]["3 years"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["3 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_3_year_Data.length; j++) {
                      if (getpediaRet.language[0]["3 years"][i].language_id == this.Devel_Lang_3_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["3 years"][i].condition != "Yes") {
                              this.Devel_Lang_3_year_Data[j].Yes = false;
                              this.Devel_Lang_3_year_Data[j].No = true;
                              this.Devel_Lang_3_year_Data[j].remarks = getpediaRet.language[0]["3 years"][i].remarks;
                              this.lanthyear_disabled = true;

                          } else {
                              this.Devel_Lang_3_year_Data[j].Yes = true;
                              this.Devel_Lang_3_year_Data[j].No = false;
                              if(this.client == false){
                              this.lanthyear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 4 years
          if (getpediaRet.language[0]["4 years"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["4 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_4_year_Data.length; j++) {
                      if (getpediaRet.language[0]["4 years"][i].language_id == this.Devel_Lang_4_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["4 years"][i].condition != "Yes") {
                              this.Devel_Lang_4_year_Data[j].Yes = false;
                              this.Devel_Lang_4_year_Data[j].No = true;
                              this.Devel_Lang_4_year_Data[j].remarks = getpediaRet.language[0]["4 years"][i].remarks;
                              this.lanfouryear_disabled = true;

                          } else {
                              this.Devel_Lang_4_year_Data[j].Yes = true;
                              this.Devel_Lang_4_year_Data[j].No = false;
                              if(this.client == false){
                              this.lanfouryear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }

          // 5 years
          if (getpediaRet.language[0]["5 years"] != null) {
              for (var i = 0; i < getpediaRet.language[0]["5 years"].length; i++) {
                  for (var j = 0; j < this.Devel_Lang_5_year_Data.length; j++) {
                      if (getpediaRet.language[0]["5 years"][i].language_id == this.Devel_Lang_5_year_Data[j].Cognitive_ID) {
                          if (getpediaRet.language[0]["5 years"][i].condition != "Yes") {
                              this.Devel_Lang_5_year_Data[j].Yes = false;
                              this.Devel_Lang_5_year_Data[j].No = true;
                              this.Devel_Lang_5_year_Data[j].remarks = getpediaRet.language[0]["5 years"][i].remarks;
                              this.lanfiveyear_disabled = true;

                          } else {
                              this.Devel_Lang_5_year_Data[j].Yes = true;
                              this.Devel_Lang_5_year_Data[j].No = false;
                              if(this.client == false){
                              this.lanfiveyear_disabled = false;
                              }
                          }
                      }
                  }
              }
          }
      }

      if (getpediaRet.child_develop != null) {
          for (var i = 0; i < getpediaRet.child_develop.length; i++) {
              if (getpediaRet.child_develop[i].age_group == "Infant") {
                  if (getpediaRet.child_develop[i].develop_type == "Deciduous teeth") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.oneyearDecide_teeth = "no"
                          this.decide_disabled = true;
                      }

                      this.cond_txt = getpediaRet.child_develop[i].ped_condition;
                      this.oneyearDecide_teethremarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Dental caries") {
                      if (getpediaRet.child_develop[i].ped_condition == "Yes") {
                          this.oneyearDental_caries = "yes";
                          this.den_disabled = true;
                      }

                      this.dental_caries = getpediaRet.child_develop[i].ped_condition;
                      this.oneyeardental_cariesremarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Object permanence") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.oneyearobject_permonce = "no";
                          this.object_disabled = true;
                      }
                      this.object_perfom = getpediaRet.child_develop[i].ped_condition;
                      this.oneyearobject_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Sleep pattern") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.oneyearsleep_pattern = "no"
                          this.sleep_disabled = true;
                      }
                      this.sleep_pattren = getpediaRet.child_develop[i].ped_condition;
                      this.oneyear_sleepremarks = getpediaRet.child_develop[i].remarks;
                  }
              }


              if (getpediaRet.child_develop[i].age_group == "Toddler") {
                  if (getpediaRet.child_develop[i].develop_type == "Dentition") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.twoyearDention_teeth = "no";
                          this.tedden_disabled = true;
                      }

                      this.twoyear_dent = getpediaRet.child_develop[i].ped_condition;
                      this.twoyearDention_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Moral development") {
                      if (getpediaRet.child_develop[i].ped_condition == "Yes") {
                          this.twoyearmoral_dev = "yes";
                          this.tedmore_disabled = true;
                      }

                      this.twoyear_moral = getpediaRet.child_develop[i].ped_condition;
                      this.twoyearmoral_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Psycho-social development") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.twoyearphyscho_social = "no";
                          this.tedsycho_disabled = true;
                      }
                      //else {
                      //    $scope.devpsycho_yes = "Yes";
                      //    $scope.tedsycho_disabled = false;
                      //}
                      this.twoyear_physcio = getpediaRet.child_develop[i].ped_condition;
                      this.twoyearphysco_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Toilet training") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.twoyeartoilet_training = "no";
                          this.tedtoilet_disabled = true;
                      }
                      //else {
                      //    $scope.devtiolet_yes = "Yes";
                      //    $scope.tedtoilet_disabled = false;
                      //}
                      this.twoyear_toilet = getpediaRet.child_develop[i].ped_condition;
                      this.twoyeartoilet_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Sleep pattern") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.two_yearsleep = "No";
                          this.tedsleep_disabled = true;
                      }
                      //else {
                      //    $scope.devtisleep_yes = "Yes";
                      //    $scope.tedsleep_disabled = false;
                      //}
                      this.twoyear_sleep = getpediaRet.child_develop[i].ped_condition;
                      this.two_yearsleepremarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Playing pattern") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.twoyearplaying_pattren = "no";
                          this.tedplay_disabled = true;
                      }
                      //else {
                      //    $scope.devplaying_yes = "Yes";
                      //    $scope.tedplay_disabled = false;
                      //}
                      this.twoyear_play = getpediaRet.child_develop[i].ped_condition;
                      this.two_yearplayremarks = getpediaRet.child_develop[i].remarks;
                  }
              }

              //Preschool
              if (getpediaRet.child_develop[i].age_group == "Preschool") {
                  if (getpediaRet.child_develop[i].develop_type == "Dentition") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.threeyearDention_teeth = "no";
                          this.preden_disabled = true;
                      }
                      //else {
                      //    $scope.denyear_yes = "Yes";
                      //    $scope.preden_disabled = false;
                      //}
                      this.three_dental = getpediaRet.child_develop[i].ped_condition;
                      this.threeyearDention_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Moral development") {
                      if (getpediaRet.child_develop[i].ped_condition == "Yes") {
                          this.threeyearmoral_dev = "yes";
                          this.premore_disabled = true;
                      }
                      //else {
                      //    $scope.moralyear_yes = "No";
                      //    $scope.premore_disabled = false;
                      //}
                      this.three_physco = getpediaRet.child_develop[i].ped_condition;
                      this.threeyearmoral_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Psycho-social development") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.threeyearphyscho_social = "no";
                          this.prepyscho_disable = true;
                      }
                      //else {
                      //    $scope.moralsyco_yes = "Yes";
                      //    $scope.prepyscho_disable = false;
                      //}
                      this.three_moral = getpediaRet.child_develop[i].ped_condition;
                      this.threeyearphyscho_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Toilet training") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.threeyeartoilet_training = "no";
                          this.pretoilet_disable = true;
                      }

                      this.three_toilet = getpediaRet.child_develop[i].ped_condition;
                      this.threeyeartoilet_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Sleep pattern") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.threeyear_sleep = "no";
                          this.presleep_disable = true;
                      }
                      //else {
                      //    $scope.spattthree_yes = "Yes";
                      //    $scope.presleep_disable = false;
                      //}
                      this.three_sleep = getpediaRet.child_develop[i].ped_condition;
                      this.threeyear_sleep_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  if (getpediaRet.child_develop[i].develop_type == "Playing pattern") {
                      if (getpediaRet.child_develop[i].ped_condition == "Abnormal") {
                          this.threeyearplaying_pattren = "no";
                          this.preplay_disable = true;
                      }

                      this.three_playing = getpediaRet.child_develop[i].ped_condition;
                      this.threeyearplaying_remarks = getpediaRet.child_develop[i].remarks;
                  }

                  this.send_data();
              }
          }
      }
  }
  }
}
