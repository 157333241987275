<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="m-0" style="font-size: 17px;">Appointments</h5>
          <div>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Location<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="diagLocation">
                <option *ngFor="let loc of locationArray" value={{loc}}>{{loc.diag_loc}}</option>
              </select>
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel">Date
              <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateMatePicker(date)"
              [(ngModel)]="date" max="{{currentDate}}" #matInput>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="margin-top: 20px !important;">
            <mat-label class="matlabel">
              <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                (click)="get_nephrology()" />
            </mat-label>
          </div> -->
        </div>
        <div class="row" style="margin-top: 10px !important;">
          <div [hidden]='listArray.length == 0' class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
            <mat-card *ngFor="let app of listArray" (click)="view_neph_details(app)" class="List_border">
                <mat-card-content>Appointment for {{ app.first_name }} {{app.last_name}}</mat-card-content>
            </mat-card>
          </div>
          <div [hidden]='listArray.length!=0' class="nodata">No appointments(s) found</div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>