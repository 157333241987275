import{Component, OnInit,ElementRef,Input,ViewEncapsulation,OnDestroy,Inject} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dental-probingdepthpopup',
  templateUrl: './dental-probingdepthpopup.component.html',
  styleUrls: ['./dental-probingdepthpopup.component.css']
})
export class DentalProbingdepthpopupComponent implements OnInit {
  public frt_value;
  public sec_value;
  public thd_value;
  @Input() id: string;parm: string;  
  public element: any;
  constructor( public dialogRef: MatDialogRef<DentalProbingdepthpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      dialogRef.disableClose = true;
     // this.element = el.nativeElement;
      if(this.data.teeth_value !=null)
    {
      var get_value=this.data.teeth_value;
      var get_all_Value=get_value.toString().split('-');
     
      this.frt_value=get_all_Value[0];
      
      this.sec_value=get_all_Value[1];
      this.thd_value=get_all_Value[2];
      
    } 
  }

  ngOnInit(): void {
  }
  public value:any;
  dismiss() {
    if(this.frt_value==undefined || (this.frt_value!=undefined && this.frt_value.length==0))
    {
      this.frt_value="0";
    }
    if(this.sec_value==undefined || (this.sec_value!=undefined && this.sec_value.length==0))
    {
      this.sec_value="0";
    }
    if(this.thd_value==undefined || (this.thd_value!=undefined && this.thd_value.length==0))
    {
      this.thd_value="0";
    }
  
    
  this.value=this.frt_value+"-"+this.sec_value+"-"+this.thd_value

 
  
  //return this.value
  //  this.dialogRef.close();
    //this.viewCtrl.dismiss(this.frt_value+"-"+this.sec_value+"-"+this.thd_value);
  }
  click_value()
  {

    if(this.frt_value==undefined || (this.frt_value!=undefined && this.frt_value.length==0))
    {
      this.frt_value="0";
    }
    if(this.sec_value==undefined || (this.sec_value!=undefined && this.sec_value.length==0))
    {
      this.sec_value="0";
    }
    if(this.thd_value==undefined || (this.thd_value!=undefined && this.thd_value.length==0))
    {
      this.thd_value="0";
    }
     
    this.value=this.frt_value+"-"+this.sec_value+"-"+this.thd_value
    
  /////  return this.value
  this.dialogRef.close(this.value) 
   
    //this.viewCtrl.dismiss(this.frt_value+"-"+this.sec_value+"-"+this.thd_value);
  }

  ClosePopup(){
    this.dialogRef.close(this.value);
  }

}
