// import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';

import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import {TabsModule } from 'ngx-bootstrap/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ChangePasswordModule } from '../common-module/change-password/change-password.module';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { DietViewComponent } from './diet-view/diet-view.component';
import { DocAppListComponent } from './doc-app-list/doc-app-list.component';
import { DietplanComponent } from './dietplan/dietplan.component';
import { DietAppointmentsComponent } from './diet-appointments/diet-appointments.component';
import { DietAppointmentsCreationComponent } from './diet-appointments-creation/diet-appointments-creation.component';
import { DietCasesheetComponent } from './diet-casesheet/diet-casesheet.component';
import { DietCaseSummaryComponent } from './diet-case-summary/diet-case-summary.component';
//import { DoctorModule } from '../Doctor_module/DoctorModule.module';
import { DietVitalsComponent } from './diet-vitals/diet-vitals.component';
import { DietAppointmentsDetailedViewComponent } from './diet-appointments-detailed-view/diet-appointments-detailed-view.component';
import { DietReadingsComponent } from './diet-readings/diet-readings.component';
import { DietPatientListComponent } from './diet-patient-list/diet-patient-list.component';
import { DietTrackingComponent } from './diet-tracking/diet-tracking.component';
//import { DoctorModule } from '../Doctor_module/DoctorModule.module';
import { DiabReadingsComponent } from './diab-readings/diab-readings.component';
//import { CustomeCommonModule } from '../common-module/common-module.module';
import { ProfileUpdateComponent } from './profile-update/profile-update.component';
import { DietHomeComponent } from './diet-home/diet-home.component';

@NgModule({
  declarations: [
    DietViewComponent,
    DocAppListComponent,
    DietplanComponent,
    DietAppointmentsComponent,
    DietAppointmentsCreationComponent,
    DietCasesheetComponent,
    DietCaseSummaryComponent,
    DietVitalsComponent,
    DietAppointmentsDetailedViewComponent,
    DietReadingsComponent,
    DietPatientListComponent,
    DietTrackingComponent,
    DiabReadingsComponent,
    ProfileUpdateComponent,
    DietHomeComponent
  ],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    CommonModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    MatOptionModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
     DataTablesModule,
    CarouselModule,
    NgbModule,
    MatButtonToggleModule,
    ChangePasswordModule,
    TranslateModule,
    TabsModule,
    //DoctorModule,
   // CustomeCommonModule,
    // DentalTeethPopupPageModule,
    QuillModule.forRoot(),
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  exports:[
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    TranslateModule,
    DietAppointmentsComponent,
    DietAppointmentsDetailedViewComponent,
    DietAppointmentsCreationComponent,
    DocAppListComponent,
    ProfileUpdateComponent,
    DietplanComponent,
    DietCasesheetComponent,
    DietPatientListComponent,
    DietTrackingComponent,
    DiabReadingsComponent,
    
  ],
  providers: [DatePipe],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class DietModule { }
