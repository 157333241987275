import { Component, OnInit } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message_data } from 'src/assets/js/Message_data';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-nurse-doctor',
  templateUrl: './nurse-doctor.component.html',
  styleUrls: ['./nurse-doctor.component.scss']
})
export class NurseDoctorComponent implements OnInit {

  public doctorList = [];
  public hospitalDetails;
  public userInfo;
  public ipaddress;
  public physioNurseList = [];
  public sendPhysioNurseList = [];
  public doctor;
  public doctorName;
  public saveFlag:boolean = true;

  constructor(public http:HttpClient,public toastr:ToastrService) {}

  ngOnInit(): void {
    this.physioNurseList=[];
    this.doctorList=[];
    this.saveFlag = true;
    this.userInfo = Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.ipaddress = Helper_Class.getIPAddress();
    this.getDoctors();
    this.getNurseByDoctor();
  }

  getDoctors() {//Get doctors
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyrep',
      {
        country: "IN",
        reg_id:this.userInfo.user_id,
        imei:  this.ipaddress,
        type: "front-desk",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.doctorList=[];
          if(obj != null){
            for (var i = 0; i < obj.doctors.length; i++) {
              var Docmidname = "";
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                Docmidname = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                Docmidname = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
  
              this.doctorList.push({
                Doc_Name: Docmidname,
                Doc_ID: obj.doctors[i].prov_id
              });
            }
            this.doctorName = this.doctorList[0].Doc_Name;
            this.doctor = this.doctorList[0].Doc_ID;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(){
    for (var i = 0; i < this.doctorList.length; i++) {
      if(this.doctorList[i].Doc_Name == this.doctorName)
        this.doctor = this.doctorList[i].Doc_ID;
    }
  }

  getNurseByDoctor(){
    var send_data = {
      doc_reg_id: this.userInfo.user_id,
      hptl_id: this.hospitalDetails,
      location:this.userInfo.location_desc,
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/nurbyhospid/',
    (send_data), { headers: headers }).subscribe(
      data => {   
        this.physioNurseList=[];
        var obj = JSON.parse(JSON.stringify(data));
        if(obj != undefined){
          for(var i= 0; i<obj.nurses.length;i++){
            var name;
            if(obj.nurses[i].middle_name != undefined){
              name = obj.nurses[i].first_name + " " + obj.nurses[i].middle_name + " " + obj.nurses[i].last_name;
            }else{
              name = obj.nurses[i].first_name + " " + obj.nurses[i].last_name;
            }

            this.physioNurseList.push({
              nurse_name:name,
              nurse_reg_id:obj.nurses[i].nurse_reg_id,
              checked:false,
            });
          }
        }
      },error => {});
  }

  save(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/docnurperm',
      {
        doc_reg_id: this.doctor,
        nurse_reg_id: this.sendPhysioNurseList,
        hptl_clinic_id: this.hospitalDetails
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key != null) {
            if (obj.key == "1") {
              this.saveFlag = false;
              this.toastr.success(obj.result);
              this.ngOnInit();
            }
          }else{
            this.saveFlag = true;
          }
        },
        error => {
          this.saveFlag = true;
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  selectNursePhysio(e,id){
    if(e == true){
      this.sendPhysioNurseList.push(id);
      for (var j = 0; j < this.physioNurseList.length; j++) {
        this.physioNurseList[j].checked = false;
      }
  
      if (this.sendPhysioNurseList != undefined && this.sendPhysioNurseList.length != 0) {
        for (var i = 0; i < this.sendPhysioNurseList.length; i++) {
          for (var j = 0; j < this.physioNurseList.length; j++) {
            if (this.sendPhysioNurseList[i] == this.physioNurseList[j].nurse_reg_id) {
              this.physioNurseList[j].checked = true;
            }
          }
        }
      }  
    }else{
      if(this.sendPhysioNurseList.length != 0){
        for( var k=0; k<this.sendPhysioNurseList.length; k++){
          if(id == this.sendPhysioNurseList[k]){
            for (var j = 0; j < this.physioNurseList.length; j++) {
              if (this.sendPhysioNurseList[k] == this.physioNurseList[j].nurse_reg_id) {
                this.physioNurseList[j].checked = false;
              }
            }
            this.sendPhysioNurseList.splice(k,1);
          }
        }
      }
    }
  }
}
