import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
// import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { Message_data } from '../../../../src/assets/js/Message_data';
import { MenuViewService } from '../../../../src/app/common-module/menu-view/menu-view.service';
import { AdminViewService } from '../../../../src/app/Admin_module/admin-view/admin-view.service';
import moment from 'moment';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FilterpageComponent } from '../filterpage/filterpage.component';
import { speak } from '../../../assets/js/common';
declare var $: any;
import ConectorPluginV3 from "../ConectorPluginV3";
import { TranslateService } from '@ngx-translate/core';
import { requestDevice ,testPrint} from "../../../assets/js/common"

// const { ThermalPrinter, PrinterTypes, CharacterSet, BreakLine } = require('node-thermal-printer');

@Component({
  selector: 'app-bill-estimate',
  templateUrl: './bill-estimate.component.html',
  styleUrls: ['./bill-estimate.component.scss']
})

export class BillEstimateComponent implements OnInit {
  [x: string]: any;
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('billid') billid: ElementRef;
  @ViewChild('printsmallcover') printsmallcover: ElementRef;
  @ViewChild('printsmallcovereng') printsmallcovereng: ElementRef;
  dtOptions: DataTables.Settings = {};
  public amountBDGST;
  public prodDiscount: any = "0.00";
  public amountDicount = "0.00";
  public orderDate: string;
  public orderTime: string;
  public drugDetailsArray: any = [];
  public batchArray: any = [];
  public doctorName = "";
  public totalAmount: any = "0.00";
  public todayDate: string;
  public paidAmount = "0.00";
  public remainBalance = "0.00";
  public imageFile;
  public saveBtn: boolean;
  public printBtn: boolean;
  public MedicneData: any = [];
  public PurchaseType: string;
  public MedicineNameArray: any = [];
  public medicineArray: any = [];
  public drugArray: any = [];
  public discSalesVolArray: any = [];
  public disctransVolArray: any = [];
  public discregsalesvol: any = [];
  public discountDatasArray: any = [];
  public discountType: string;
  public discountURl: string;
  public discoutFlag: boolean;
  public medicineName: string;
  public batchNo: string;
  public saveOrderURL: string;
  public saveEstimateURL: string;
  public updateEstimateURL: string;
  public name: string;
  public sendData;
  public perUnit;
  public image;
  public ReferedDoctorArray: any = [];
  public ReferedNameArray: any = [];
  public getData;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  public pharmaLogo;
  public billTemplate;
  public stateGST;
  public centralGST;
  public bgImage: string;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public dlNO;
  public gstNO;
  public pharmaAddress1;
  public pharmaAddress2;
  public displayDisplay: boolean;
  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeListArrray: any = [];
  public insurerArray: any = [];
  public payType: string;
  public insurerID: string;
  public userName;
  public billID = undefined;
  public tempBatch;
  public recItemID;
  public batch_required = { "border-color": "rgb(250, 40, 2)" };
  public batchFlag: boolean = false;
  public saveFlag: boolean = true;
  public quantityDisable: boolean = true;
  public productReturn;
  public genericDisplayFlag: boolean = false;
  public payDes;
  public paytypreq: boolean = false;
  public payType1: string;
  public amountval: number;
  private tamt: number = 0;
  public paymentsarray: any = [];
  public rackId;
  public binId;
  public newEstimateFlag: boolean = true;
  public updateBtnFlag: boolean = true;
  public saveBtnFlag: boolean = true;
  public patient_name;
  public patientArray: any = [];
  public relation_id: any = "";
  public client_reg_id: any = "";
  public patient_id: any = '';
  // for Estimatelist 
  orderListArray: any = [];
  noDataCurrent: boolean = false;
  // public pharmacyID: string;
  public textAlign;
  public height;
  public width;
  public userinfo;
  public imei;
  // dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public AppType;
  public inpatientFlag;
  public ynopt1: boolean = true;
  public createFlag: boolean = false;
  public currentDate;
  public fromDate;
  public toDate;
  public estimateId;
  public financialYear;
  public transactionID;
  public deliveryType;
  public status;
  // For Quantity
  public drugquantity: number;
  public backenddrugquantity;
  public balanceamount;
  public selectedDrugs: any = [];
  public medicinename: any = [];
  public rowbatchno;
  public rowexpdate;
  public discount;
  public rowprice;
  public rowindex;
  public firstItem: any = [];
  public rackidothermodule;
  public binidothermodule;
  public medicineArrayforbin;
  tokenno: any;
  public checkquantitybatch;
  public checkquantityindex;
  public checkbackendquantity;
  public checkbatchnoarray: any = [];
  public highqtydrugid;
  public editbutton;
  public newbutton;
  public deletebutton;
  public printbutton;
  public viewbutton;
  public new;
  public view;
  public rec_item_id;
  public batcharraylength;
  public checkbatchlength;
  public qty;
  public uhid;
  public auto_order;
  public status_buttonflag;
  public tokenflag: boolean;
  public amountvalue: number = 0;
  public drugprice_value: number = 0;
  public prescrip_drug_qty;
  public checkpresc_drug_qty;
  public prescripted_drug_det: any = [];
  public pres_drug_id;
  public checkpres_batch;
  public balanceqty = 0;
  public amount_pres_med: number = 0;
  public discountamountval: number = 0;
  public amount_to_del: number = 0;
  public particularqty;
  public prescribed_batch;
  public pres_price;
  public blamt;
  public total_pres_qty;
  public pres_rect_id;
  public medicinenameflag: boolean;
  public selectbatch_noflag: boolean;
  public cancelreasonest;
  public selectedbatch_flag: boolean = false;
  public patientdetailsurl = "gen/gpda";
  public patientdetailsarray: any = [];
  public round_off_descflag: boolean;
  public calvalue: string;
  public calculation_value;
  public round_off;
  isInputDisabled: boolean = true;
  public req_roundoffval;
  public rounddesc;
  public sub_rel_Id;
  textToSpeak: string = '';
  public update: boolean;
  public bill_generate: boolean;
  public medicinepaid: number = 0;
  public remainBalancemedic: number = 0;
  public estimate_date;
  public cancel_flag: boolean;
  public retivepatientdata;
  public search_type;
  public retrivenameflag: boolean = true;
  public retrivemr: boolean = false;
  public patsearchtype;
  public patient_detailsflag: boolean;
  public profileImg;
  public clnt_sal: string;
  public clntSal;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public dobDisabledFlag: boolean;
  public clntAge;
  public clntdob;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public whatsappNumber;
  public Ward_name;
  public Bed_no;
  public admissiondate;
  public admission_time;
  public referredDoctor;
  public clientCityId: string = null!;
  public clientStateId: string = null!;
  public clientcountryId: string = null!;
  public clientZipCode: string = null!;
  public fromprescflag: boolean;
  public presdate;
  public ipop;
  public paidFlag: boolean = false;
  public balanceFlag: boolean = false;
  public totalFlag: boolean = true;
  public headergen;
  public clientdoc;
  public morID: boolean;
  public afterID: boolean;
  public eveID: boolean;
  public alternatsession = true;
  public sessionhide: boolean = false;
  public sessionhrs: boolean = true;
  public dayText: string;
  public dayDuration;
  public intakesession;
  public daydurationShow: boolean;
  public afterfoodText: string;
  public morningRequset: boolean;
  public morning;
  public evening;
  public afternoon;
  public night;
  public daydur;
  public period;
  public intake;
  public intake_desc;
  public drug_intake;
  public bill_incl_pharma;
  public bill_incl_pharmaFlag: boolean;
  selectedPrinter: string | undefined;
  printers: string[] = ["Printer1", "Printer2", "Printer3"]; // Sample list of printers
  message: string = "";
  public hosp_id;
  public language;
  public languagetype: boolean;
  public priceundefined: number;
  public getIntakeArray: any = [];
  public intake_id;
  public saveorderarray: any = [];
  public cancelEstimateURL;
  public docsal: string = "Dr. ";
  public docsalflag: boolean = true;
  public paytyperequestflag: boolean = false;
  public statusFlag: boolean;
  public nomedicineflag: boolean;
  public selecteddrugflag: boolean;
  public tokenshowflag: boolean;
  public takensessionflag: boolean;
  public thermalspan: boolean;
  public thermalspantoken: boolean;
  public languageflag: boolean;
  public userdrugquantity = 0;
  public wholedrugquantity = 0;
  public GSTamount:number=0;
  public gstdecimalamt:number=0;
  public GSTamountflag:boolean;
  public stripprice;
  public ddarray_cgst;
  public ddarray_sgst;
  public aftdeletflag:boolean;
  public pres_id;
  public selected_item=[];

  constructor(private translate: TranslateService, public pharmacyservice: PharmacyService, public pharmacyservice1: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public httpp: HttpClient, public http: Http, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public adminService: MenuViewService, public messageservice: MenuViewService, public comm_Data: CommonDataService) {
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.printBtn = true;
    this.saveBtn = true;
    this.takensessionflag = true;
    this.saveOrderURL = ipaddress.getIp + "pharmacontrol/sporder";
    this.saveEstimateURL = ipaddress.getIp + "pharmacontrol/sest";
    this.updateEstimateURL = ipaddress.getIp + "pharmacontrol/uest";
    this.cancelEstimateURL = ipaddress.getIp + "pharmacontrol/cest";
    this.PurchaseType = "Store pickup"
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.languagetype = false;
    this.translate.setDefaultLang('tamil');
    this.translate.use('tamil');
    this.language = "Tamil";
    this.getIntakeSession();
    // Estimate Detailed View
    this.paytyperequestflag = false;
    this.statusFlag = false;
    this.thermalspan = false;
    this.thermalspantoken = false;
    this.languageflag = false;
  }
  ngOnDestroy() {
    this.tokenflag = false;
    this.thermalspan = false;
    this.thermalspantoken = false;
  }
  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.languageflag = true;
    this.imei = Helper_Class.getIPAddress();
    this.userinfo = Helper_Class.getInfo();
    var currentorderflowstatus = "current";
    // this.tokenflag = false;
    this.selecteddrugflag = false;
    this.pres_id = undefined;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getData = Helper_Class.getInfo();
    console.log("get data = " + JSON.stringify(this.getData))
    this.ipaddress = Helper_Class.getIPAddress();
    this.pharmacistID = this.getData.pharmacy;
    this.aftdeletflag=true;
    if (this.getData.user_type == "Admin") {
      this.pharmacyID = this.getData.pharma_id;
    } else {
      this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    }
    this.req_roundoffval = Helper_Class.getInfo().hospitals[0].req_roundoff;
    this.round_off_descflag = this.req_roundoffval === "1" ? true : false;
    this.hosp_id = Helper_Class.getInfo().hosp_id;
    this.pharmacyName = this.getData.hospitals[0].hptl_name;
    this.location = this.getData.hospitals[0].location_desc;
    this.city = this.getData.hospitals[0].city_desc;
    this.state = this.getData.hospitals[0].state_desc;
    this.country = this.getData.hospitals[0].country_desc;
    if (this.getData.hospitals[0].bill_pay_type_req == "1") {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    this.pharmaAddress1 = this.getData.hospitals[0].address1 != undefined && this.getData.hospitals[0].address1 != null && this.getData.hospitals[0].address1 != "" && this.getData.hospitals[0].address1 != "undefined" ? this.getData.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getData.hospitals[0].address2 != undefined && this.getData.hospitals[0].address2 != null && this.getData.hospitals[0].address2 != "" && this.getData.hospitals[0].address2 != "undefined" ? this.getData.hospitals[0].address2 : undefined;
    this.dlNO = this.getData.hospitals[0].dl_no != undefined && this.getData.hospitals[0].dl_no != null && this.getData.hospitals[0].dl_no != "" ? this.getData.hospitals[0].dl_no : undefined;
    this.gstNO = this.getData.hospitals[0].gst_no != undefined && this.getData.hospitals[0].gst_no != null && this.getData.hospitals[0].gst_no != "" ? this.getData.hospitals[0].gst_no : undefined;
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
    this.billTemplate = this.getData.hospitals[0].bill_template;
    this.bgImage = this.getData.hospitals[0].bg_image != undefined ? this.getData.hospitals[0].bg_image : undefined;
    this.userName;
    this.productReturn = this.getData.hospitals[0].product_return;
    if (this.getData.middle_name != undefined)
      this.userName = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    else {
      if (this.getData.last_name != undefined)
        this.userName = this.getData.first_name + " " + this.getData.last_name;
      else
        this.userName = this.getData.first_name;
    }
    this.medicinenameflag = false;
    this.selectbatch_noflag = true;
    this.prescrip_drug_qty = 0;
    this.pres_drug_id = "";
    this.discoutFlag = false;
    this.search_type = "name";
    this.patsearchtype = "name";
    this.dayDuration = "day(s)";
    this.afterfoodText = "After food";
    this.update = true;
    this.patient_detailsflag = true;
    this.bill_generate = true;
    this.takensessionflag = false;
    this.CurrentDate();
    this.getDiscTypes();
    this.getDiscountDetails();
    this.getPaymentType();
    this.addNewDrugDetails();
    this.getCurrentDate();
    this.changeIntake(this.drugDetailsArray);
    this.cancel_flag = false;
    var Moduleidlist;
    this.docsalflag = true;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    this.AppType = "out";
    this.createFlag = Helper_Class.getBillCreate() == "1" ? true : false;



    if(this.getData.user_type == "Admin"){
      this.newbutton = true;
      this.new = true;
      this.viewbutton = true;
              this.view = true;
              this.deletebutton = true;
              this.printbutton = true;  
              this.editbutton = true;     
    }else{
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "48") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
              this.new = true;
  
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
              this.view = true;
            }
  
          }
  
  
  
        }
      }
    }


    // this.currentEstimates(this.pharmacyID);
  }

  printdevice() {

  console.log("token no = "+this.tokenno)
    this.drugDetailsArray.sort((a, b) => {
      if (a.rack_id !== b.rack_id) {
        return a.rack_id - b.rack_id;
      }
      return a.bin_id - b.bin_id;
    });
    this.drugDetailsArray.sort((a, b) => {
      if (a.rack_id !== b.rack_id) {
        return a.rack_id - b.rack_id;
      }
      return a.bin_id - b.bin_id;
    });
    var docdes
    if (this.doctorName)
      var patientdetails = [{
        patientname: this.clntSal + ". " + this.patient_name,
        tokenno: this.tokenno,
        mrnumber: this.uhid,
        estimate_amount: parseFloat(this.totalAmount).toFixed(2)
      }]

    console.log("details of drug " + JSON.stringify(this.drugDetailsArray))

    // alert("number 1 = "+this.tokenno)
    var printData = {
      clntsal: this.clntSal,
      docnamePlusal:"Dr. "+this.doctorName,
      patientnamePlusal:this.clntSal+". "+this.patient_name,
      doctor_desc: "Dr. ",
      template: this.billTemplate,
      // content: template,
      pharma_logo: this.pharmaLogo,
      pharma_name: this.pharmacyName,
      // pharma_addr: pharmaAddr,
      location: this.location,
      city: this.city,
      state: this.state,
      country: this.country,
      dl_no: this.dlNO,
      gst_no: this.gstNO,
      patient_name: this.patient_name,
      age: this.clntAge,
      gender: this.headergen,
      ageandgen: this.clntAge + "/" + this.headergen,
      bill_no: this.billID,
      PurchaseType: this.PurchaseType,
      doc_name: this.doctorName,
      order_date: Date_Formate(this.orderDate),
      time_date: Date_Formate(this.orderDate) + "/" + this.orderTime,
      order_time: this.orderTime,
      drugList: this.drugDetailsArray,
      discount: this.prodDiscount,
      total: parseFloat(this.amountDicount).toFixed(2),
      //  total: parseFloat(this.totalAmount).toFixed(2),
      paid: parseFloat(this.paidAmount).toFixed(2),
      balance: parseFloat(this.remainBalance).toFixed(2),
      order_type: true,
      show_discount: this.displayDisplay,
      pay_type: this.payDes,
      pay_flag: this.paytypreq,
      payments: this.paymentsarray,
      isEstimate: "1",
      tokenno: this.tokenno,
      mrnumber: this.uhid,
      patient_details: patientdetails,
      mrno: this.uhid
    }
    // alert("number 2 = "+this.tokenno)

    console.log("details of drug " + JSON.stringify(printData))


    // setTimeout(() => {
      
      // this.toastr.error(Message_data.getpharmaprice)
      if (Helper_Class.getthermalprint() == null) {
        requestDevice(printData)
      }
      else{
        testPrint(Helper_Class.getthermalprint(), printData)
      }

    // }, 1000);

  }



  testPrint() {
    if (!this.selectedPrinter) {
      this.toastr.error("Please select a printer");
      return;
    }

    if (!this.message) {
      this.toastr.error("Please enter a message to print");
      return;
    }

    // Create an instance of your ConnectorPluginV3 here
    const printer = new ConectorPluginV3();

    // Perform operations like setting text, printing, etc.
    printer
      // .setBold(true)
      // .writeLine('Hello World!')
      // .setBold(false)
      // .feed(4)
      // .cut('full')
      // .flush();      
      // .Start()
      // .WriteText(this.message)
      // .printTo(this.selectedPrinter)
      .Start()
      .SetAlignment(ConectorPluginV3.CENTER_ALIGNMENT)
      .WriteText("Hello Angular from parzibyte.me")
      .Feed(1)
      .WriteText(this.message)
      .Feed(1)
      .DownloadImageFromInternetAndPrint("https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/1200px-Angular_full_color_logo.svg.png", ConectorPluginV3.DOUBLE_WIDTH_AND_HEIGHT_IMAGE_SIZE, 400)
      .Start()
      .Feed(1)
      .printTo(this.selectedPrinter)
      .then((response) => {
        console.log("Printing successful", response);
      })
      .catch((error) => {
        console.error("Printing error", error);
      });
  }
  searchtype(value) {
    if (value == "name") {
      this.patient_detailsflag = true;
      this.name = undefined;
      this.doctorName = undefined;
      this.patientArray = [];
      this.retrivenameflag = true;
      this.retrivemr = false;
      this.patsearchtype = "name";
      this.patient_name = undefined
    }
    else if (value == "mrnumber") {
      this.patient_detailsflag = true;
      // this.name=undefined;
      this.patient_name = undefined
      this.doctorName = undefined;
      this.patientArray = [];
      this.retrivemr = true;
      this.retrivenameflag = false;
      this.patsearchtype = "patientid";
    }
  }
  changePatientName(e) {
    var type_name = "name";
    var senddetails = JSON.stringify({
      first_name: e.target.value.toLocaleUpperCase(),
      type: type_name,
      hptl_clinic_id: Helper_Class.getHospitalId(),
    })
    console.log("senddetails value", senddetails)
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      this.patient_name = this.patient_name.toLocaleUpperCase();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj patient name" + JSON.stringify(obj));

            if (obj.clients != null) {
              this.patientArray = [];

              for (var i = 0; i < obj.clients.length; i++) {
                var patName = obj.clients[i].first_name + " " + obj.clients[i].last_name;

                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: obj.clients[i].gender,
                  age: obj.clients[i].age,
                  mobile: obj.clients[i].mobile,
                })
              }
            }

            // if (obj.med_details != null) {
            //   this.MedicineNameArray = [];
            //   this.medicineArray = [];
            //   this.MedicneData = [];
            //   for (var i = 0; i < obj.med_details.length; i++) {
            //     var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name

            //     this.MedicneData.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name });
            //     this.medicineArray.push({
            //       med_id: obj.med_details[i].med_id,
            //       med_name: obj.med_details[i].med_name,
            //       med_type_id: obj.med_details[i].med_type_id,
            //       type_name: obj.med_details[i].type_name,
            //       short_name: obj.med_details[i].short_name,
            //       short_form: obj.med_details[i].short_form,
            //       show_short_form: obj.med_details[i].show_short_form,
            //       show_intake: obj.med_details[i].show_intake,
            //       medname: medname,
            //       cgst: obj.med_details[i].cgst,
            //       sgst: obj.med_details[i].sgst,
            //       rack_id: obj.med_details[i].rack_id,
            //       bin_id: obj.med_details[i].bin_id
            //     })

            //     // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
            //     //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
            //     // }.bind(this));   
            //   }
            // }

          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  getPatientListByName(name) {
    var send_data;
    console.log("verify send_data " + JSON.stringify({
      hptl_clinic_id: this.pharmacyID,
      country: ipaddress.country_code,
      type: this.patsearchtype,
      center_type: "pharma",
      name: this.patient_name,
      prov_id: this.userinfo.user_id,
      location: this.userinfo.user_type,
    }))

    if (this.retrivemr == true && name.toString().length > 8) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        patient_type: this.patient_typefroname,
        type: this.patsearchtype,
        center_type: "pharma",
        name: this.patient_name,
        prov_id: this.userinfo.user_id,
        location: this.userinfo.user_type,
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];
              var gender;
              var mobilenum
              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                }

                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                  profile: obj.clients[i].profile_image
                })
              }
            }
          },
          error => { });

    } else if (name.toString().length > 2 && this.retrivemr == false) {

      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        patient_type: this.patient_typefroname,
        type: this.patsearchtype,
        center_type: "pharma",
        name: encrypt_decript.Encript(this.patient_name.toLocaleUpperCase()).toString(),
        prov_id: this.userinfo.user_id,
        location: this.userinfo.user_type,
      }
      console.log("send data ====++" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];
              var gender;
              var mobilenum;
              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                  console.log(" g =" + JSON.stringify(gender))
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                  console.log(" m =" + JSON.stringify(mobilenum))
                }

                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                })
              }
            }

          },
          error => { });

    } else {
      this.MedicineNameArray = [];
    }
    // else {
    //   this.patientList = Helper_Class.getPatientList();
    //   this.filteredArr = Helper_Class.getPatientList();
    // }
  }


  patientdetailslist(details) {
    this.patient_name = details.name;
    this.doctorName = details.dr_name;
    this.relation_id = details.relation_id;
    this.client_reg_id = details.client_reg_id;
    this.patient_id = details.mrno;
    this.uhid = details.mrno;
    this.sub_rel_Id = details.sub_rel_id;
    this.docsalflag = false;
    this.ipop = details.op_ip;
    this.retrieveClient(details)
  }

  patientdetail() {
    var senddetails = JSON.stringify({
      hptl_clinic_id: this.pharmacyID,
      country: ipaddress.country_code,
      fdate: this.orderDate,
      tdate: this.orderDate
    })
    console.log("senddetails value", senddetails)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.patientdetailsurl, senddetails,
      { headers: headers })
      .subscribe(
        response => {
          this.patientdetailsarray = [];
          var obj = response.json();
          console.log("patientdetails" + JSON.stringify(obj));
          if (obj.med_procedures != null) {
            for (let i = 0; i < obj.med_procedures.length; i++) {
              var docname;
              var patientname;
              var mobilenumber;
              var ipop;
              if (obj.med_procedures[i].dr_middle_name != undefined) {
                docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_middle_name + " " + obj.med_procedures[i].dr_last_name;
              } else if (obj.med_procedures[i].dr_middle_name == undefined && obj.med_procedures[i].dr_last_name != undefined) {
                docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_last_name;
              } else {
                docname = ""
              }
              if (obj.med_procedures[i].middle_name != undefined) {
                patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].middle_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
              } else if (obj.med_procedures[i].middle_name == undefined && obj.med_procedures[i].last_name != undefined) {
                patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
              } else {
                patientname = ""
              }
              if (obj.med_procedures[i].mobile != undefined) {
                mobilenumber = encrypt_decript.Decript(obj.med_procedures[i].mobile);
              }
              if (obj.med_procedures[i].op_ip != undefined) {
                ipop = obj.med_procedures[i].op_ip
                if (ipop == "op") {
                  ipop = "OP"
                } else if (ipop == "ip") {
                  ipop = "IP"
                  this.fromprescflag = true;
                } else {
                  ipop = obj.med_procedures[i].op_ip
                }
              }
              if (obj.bed_no != undefined) {
                this.Bed_no = obj.bed_no;
              } else {
                this.Bed_no = "";
              }
              if (obj.ward_name != undefined) {
                this.Ward_name = obj.ward_name
              } else {
                this.Ward_name = "";
              }
              if (obj.admission_date != undefined) {
                this.admissiondate = obj.admission_date
              } else {
                this.admissiondate = ""
              }
              if (obj.admission_time != undefined) {
                this.admission_time = Time_Formate(obj.admission_time)
              } else {
                this.admission_time = ""
              }
              this.patientdetailsarray.push({
                mrno: obj.med_procedures[i].mrno,
                name: patientname,
                dr_name: docname,
                mobile: mobilenumber,
                age: obj.med_procedures[i].age,
                client_reg_id: obj.med_procedures[i].client_reg_id,
                relation_id: obj.med_procedures[i].relation_id,
                sub_rel_id: obj.med_procedures[i].sub_rel_id,
                op_ip: ipop
              })
            }
          }
        },
        error => { });
  }


  retrieveClient(person) {
    if (this.retrivemr == true) {
      var get_data = {
        //  user_id: person[0].client_reg_id,
        // relation_id: person[0].relation_id,
        // sub_rel_id: person[0].sub_rel_id,
        // country: ipaddress.country_code
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          this.patient_detailsflag = false;
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))
          this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
          if (this.patsearchtype == "patientid") {
            //  this.client_reg_id = person[0].client_reg_id;
            //  this.relation_id = person[0].relation_id;
            //  this.sub_rel_Id = person[0].sub_rel_id;
            this.client_reg_id = person.client_reg_id;
            this.relation_id = person.relation_id;
            this.sub_rel_Id = person.sub_rel_id;
          } else {
            this.client_reg_id = person.client_reg_id;
            this.relation_id = person.relation_id;
            this.sub_rel_Id = person.sub_rel_id;
          }
          if (obj.client_id != null) {

            if (obj.salutation != undefined) {
              this.clntSal = obj.salutation + "";
            } else {
              this.clntSal = "";
            }

            this.clnt_sal = obj.salutation_id;
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            if (this.clntGender == "Male" || this.clntGender == "M") {
              this.headergen = "M";
            } else if (this.clntGender == "Female" || this.clntGender == "F") {
              this.headergen = "F";
            } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            this.clntAddress1 = '';
            this.clntAddress2 = '';
            if (obj.address2 != null && obj.address2 != undefined && obj.address1 != undefined && encrypt_decript.Decript(obj.address2) != "" && encrypt_decript.Decript(obj.address1)) {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            } else if (obj.address1 != undefined || encrypt_decript.Decript(obj.address1) != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
            } else {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            }
            this.clntLocation = '';
            if (obj.location_desc != undefined && obj.location_desc != null) {
              this.clntLocation = obj.location_desc + ",";
            }

            this.clntCity = '';
            if (obj.city != undefined && obj.city != null) {
              this.clntCity = obj.city_desc + "-";
            }

            this.clntZipcode;
            if (obj.zipcode != undefined && obj.zipcode != null && encrypt_decript.Decript(obj.zipcode) != "") {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode) + ",";
            }

            this.clntState = '';
            if (obj.state_desc != undefined && obj.state_desc != null) {
              this.clntState = obj.state_desc + ".";
            }

            this.clntCountry = '';
            if (obj.country_desc != undefined && obj.country_desc != null) {
              this.country = obj.country_desc + ".";
            }
            this.clntdob = obj.dob != null && obj.dob != "" && obj.dob != undefined && obj.dob != "" ? obj.dob : "";
            if (this.clntMiddleName != "") {
              //  this.patient_name =this.clntSal + this.clntFirstName+" "+this.clntLastName;
              this.clientname = this.clntFirstName + " " + this.clntLastName;
            } else {
              //  this.patient_name = this.clntSal + this.clntFirstName+" "+this.clntMiddleName +" "+this.clntLastName;
              this.clientname = this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
              // this.clntSal+". " +   
            }
            if (obj.patient_id != undefined) {
              this.uhid = obj.patient_id
            }
            if (obj.ref_source != null) {
              this.ref_source = obj.ref_source;
            }

            if (obj.dob != null) {
              this.clntDOB = Date_Formate(obj.dob);

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            if (obj.emerg_contact1 != undefined) {
              this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            }
            if (obj.emerg_contact2 != undefined) {
              this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            }


          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }




  addNewDrugDetails() {
    var index = 0;
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        gen_name: "",
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: "",
        show_batcharray: false,
        quantityDisable: true,
        rack_id: "",
        bin_id: "",
        show_intakeFlag:"false",
        daydur_txt: "day(s)",
        afterfood_txt: "After food",
        dure_txt: "30",
        intake_txt: "Thrice a day",
        morning: 1,
        afternoon: 1,
        evening: 0,
        night: 1,
        mor_lable: false,
        quan_lable: true,
      });
    } else {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            gen_name: "",
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            show_batcharray: false,
            quantityDisable: true,
            rack_id: "",
            bin_id: "",
            daydur_txt: "day(s)",
            afterfood_txt: "After food",
            dure_txt: "30",
            intake_txt: "Thrice a day",
            morning: 1,
            afternoon: 1,
            evening: 0,
            night: 1,
            mor_lable: false,
            quan_lable: true,

          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("pay type = " + JSON.stringify(obj))
          this.payTypeListArrray = obj.payments;
          this.payType = this.payTypeListArrray[0].pay_id;
          this.payDes = this.payTypeListArrray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArrray.forEach(element => {
      if (element.pay_id == this.payType) {
        this.payDes = element.pay_desc;
      }
    });

    if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4")
      this.tranFlag = false;
    else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  calculateRemainBalance() {
    this.paidAmount = this.paidAmount === "" ? "0.00" : this.paidAmount;
    this.remainBalance = (parseInt(this.amountDicount) - parseInt(this.paidAmount)).toFixed(2);
    this.remainBalance = this.remainBalance === "NaN" ? "0.00" : this.remainBalance;
  }

  // {"name":"TAB CIPLOX 500mg","quantity":"6994"}

  changeMedicineName(e, type) {
    console.log("type...........", type)
    var senddetails = JSON.stringify({
      drug_name: e.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.toString() != null && e.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));

            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                this.MedicneData.push({ name: medname, quantity: obj.med_details[i].quantity,hsn_no:obj.med_details[i].hsn_no, generic: obj.med_details[i].generic_name });
                this.medicineArray.push({
                  hsn_no:obj.med_details[i].hsn_no,
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id
                })
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  trailchangeMedicineName(e, type) {
   
    var senddetails = JSON.stringify({
      drug_name: e.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            // nmeddetails
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                this.MedicneData.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name });
                var item = {
                  name: medname,
                  quantity: obj.med_details[i].quantity
                }
                var drug_detailsarray = obj.med_details[i]
                console.log("med details " + JSON.stringify(this.drugDetailsArray))
                var rack = obj.med_details[i].rack_id;
                var bin = obj.med_details[i].bin_id;
                this.medicineArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id
                })
                this.selectMedicineItem(item, drug_detailsarray)
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }




  selectPatient(patient) {
    console.log("patient id = " + patient);
    this.relation_id = patient.relation_id;
    this.client_reg_id = patient.client_reg_id;
    this.sub_rel_Id = patient.sub_rel_id;
    this.patient_id = patient.patient_id;
    this.uhid = patient.patient_id;
    console.log("selectPatient name", this.relation_id, this.client_reg_id)
  }
  selectGeneiric(item, drug_detailsarray) {
  }

  selectMedicineItem(item, drug_detailsarray) {
   
    console.log("drugh detail" + JSON.stringify(drug_detailsarray))
    var shortName;
    this.medicinenameflag = true;
    this.selectedbatch_flag = false;
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (this.medicineArray[i].show_short_form == "1") {
        shortName = this.medicineArray[i].short_name;
      } else {
        shortName = "";
      }
      if (item.name == this.medicineArray[i].medname) {
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        this.medicineName = this.medicineArray[i].med_name;
        drug_detailsarray.cgst = this.medicineArray[i].cgst;
        drug_detailsarray.sgst = this.medicineArray[i].sgst;
        drug_detailsarray.rack_id = this.medicineArray[i].rack_id;
        drug_detailsarray.bin_id = this.medicineArray[i].bin_id;
        drug_detailsarray.show_intake = this.medicineArray[i].show_intake;
        drug_detailsarray.drug_typetxt = this.medicineArray[i].med_type_id;
        drug_detailsarray.hsn_no=this.medicineArray[i].hsn_no
        drug_detailsarray.shortname = shortName;
        drug_detailsarray.intake_desc = "";
        if (this.updateBtnFlag == true) {
          this.getIntakeName(drug_detailsarray)
        }

        this.bill_generate = true;
        this.nomedicineflag = true;
        // this.updateBtnFlag=false;
      }
    }
    console.log("DRUG DETAILS " + JSON.stringify(drug_detailsarray))
    this.aftdeletflag=true;
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  getItemPrices(drug_detailsarray) {

    this.checkbatchnoarray = [];
    console.log(" VALUE 2 =" + JSON.stringify(drug_detailsarray))
  
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    console.log(" value = " + JSON.stringify(this.sendData))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        console.log("drugdata-price" + JSON.stringify(dataval))
    
        console.log("length = " + dataval.pharma_price.length)
 
          if (dataval != undefined && dataval.length != 0 && dataval.pharma_price.length != 0 && dataval.batch_nos.length != 0) {
          drug_detailsarray.batcharray = [];
          this.checkbatchnoarray = [];
          var index = 0;
          if (dataval.pharma_price.length == 1) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          } else if (dataval.pharma_price.length == 0) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          }
          else {
            this.rackId = dataval.pharma_price[1].rackid;
            this.binId = dataval.pharma_price[1].binid;
          }
          // for (var j = 0; j < this.drugDetailsArray.length; j++) {
          //   if (this.drugDetailsArray[j].drug_id == drug_detailsarray.drug_id) {
          //     this.drugDetailsArray[j].batcharray = [];
          //     var index = 0;
          //   }
          // }
          for (var i = 0; i < dataval.pharma_price.length; i++) {
            var itemprice_index=0
            if(this.aftdeletflag == true){
            var color = this.checkExpiryDate(dataval.pharma_price[i].expiry_date);
            this.drugArray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.pharma_price[i].per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: dataval.pharma_price[i].prod_cgst,
              sgst: dataval.pharma_price[i].prod_sgst,
              expiry_date: dataval.pharma_price[i].expiry_date,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              index: index,
              rack_id: dataval.pharma_price[i].rackid,
              bin_id: dataval.pharma_price[i].binid,
              stripprice: dataval.pharma_price[i].price,
              sales_price: dataval.pharma_price[i].sales_price,
              cost_price: dataval.pharma_price[i].cost_price,
              mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),

            });

            if (this.discountDatasArray.length != 0) {
              if (this.discountdesc == "cost plus gst") {
                drug_detailsarray.price = (dataval.pharma_price[0].cost_price / dataval.pharma_price[0].per_unit).toFixed(2);
              }
              else if (this.discountdesc == "sales plus gst") {
                drug_detailsarray.price = (dataval.pharma_price[0].sales_price / dataval.pharma_price[0].per_unit).toFixed(2);
              }
              else if (this.discountdesc == "MRP") {
                drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
              }else {
                drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2)
              }
            }
            drug_detailsarray.batch_no = dataval.pharma_price[0].batch_no;
            drug_detailsarray.expiry_date = dataval.pharma_price[0].expiry_date;
            // drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
            drug_detailsarray.mrp = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
            drug_detailsarray.per_unit = dataval.pharma_price[0].per_unit;
            drug_detailsarray.stripprice = dataval.pharma_price[0].price;
            drug_detailsarray.rec_item_id = dataval.pharma_price[0].rec_item_id;
         
            console.log(" rect_item_id = " + JSON.stringify(drug_detailsarray.rec_item_id))

            drug_detailsarray.sales_price = dataval.pharma_price[0].sales_price
            drug_detailsarray.cost_price = dataval.pharma_price[0].cost_price
            console.log("two =" + this.drugDetailsArray)

            // alert(" index = "+index)
            drug_detailsarray.batcharray.push({
              index: index,
              batch_no: dataval.pharma_price[i].batch_no,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              quantity: dataval.pharma_price[i].avail_qty,
              expiry_date: dataval.pharma_price[i].expiry_date,
              color: color,
            });
            index++;
          }
            this.checkbatchnoarray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              stripprice: dataval.pharma_price[i].price,
              per_unit: dataval.pharma_price[i].per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: dataval.pharma_price[i].prod_cgst,
              sgst: dataval.pharma_price[i].prod_sgst,
              expiry_date: dataval.pharma_price[i].expiry_date,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              index: index,
              rack_id: dataval.pharma_price[i].rackid,
              bin_id: dataval.pharma_price[i].binid
            });
            index += 1;
            // if (drug_detailsarray.days != "" && drug_detailsarray.quantity != "") {
            //   this.calculateAmount(drug_detailsarray)
            // }
          }
          if (this.selectedbatch_flag == false) {
            this.checkquantitybatch = dataval.pharma_price[0].batch_no;
            this.checkquantityindex = 0;
            this.backenddrugquantity = drug_detailsarray.quantity;
            this.checkbackendquantity = dataval.pharma_price[0].avail_qty;
          }
          
          if(this.pres_id != undefined){
            console.log("selected_drug = "+JSON.stringify(drug_detailsarray))
            console.log("selected_drug = "+JSON.stringify(drug_detailsarray))
          
            this.drugquantity=drug_detailsarray.quantity
             var balqty=0;
             var singlebalqty=0;
             if(this.checkbatchnoarray.length == 1){

               
               for(var d=0;d < this.checkbatchnoarray.length;d++){
                 
                 if(this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)){
                   singlebalqty= this.drugquantity - this.checkbatchnoarray[d].quantity
                   this.drugquantity = singlebalqty
                   drug_detailsarray.quantity=this.checkbatchnoarray[d].quantity
                   this.drugDetailsArray.push({
                    pharma_id: this.pharmacyID,
                    drug_id: drug_detailsarray.drug_id,
                    type_name: drug_detailsarray.type_name,
                    drug_name: drug_detailsarray.drug_name,
                    drug_disp_name: drug_detailsarray.short_name + " " + drug_detailsarray.drug_name,
                    quantity: this.checkbatchnoarray[d].quantity,
                    tempquantity: "",
                    amount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    totalamount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    // price: obj.drug_details[i].price,
                    stripprice: drug_detailsarray.stripprice || 0,
                    price: drug_detailsarray.price,
                    discount: drug_detailsarray.discount,
                    per_unit: drug_detailsarray.per_unit,
                    prod_disc: "",
                    batch_no: drug_detailsarray.batch_no,
                    amt_bdgst: "",
                    cgst: drug_detailsarray.cgst,
                    sgst: drug_detailsarray.sgst,
                    expiry_date: drug_detailsarray.expiry_date,
                    rec_item_id: drug_detailsarray.rec_item_id,
                    index: index,
                    gen_name: "",
                    batch_required: drug_detailsarray.batch_required,
                    batcharray: drug_detailsarray.batcharray,
                    show_batcharray: false,
                    quantityDisable: true,
                    rack_id: drug_detailsarray.rack_id,
                    bin_id: drug_detailsarray.bin_id,
                    colo_required: drug_detailsarray.color_req,
                    morning: drug_detailsarray.morning,
                    afternoon: drug_detailsarray.afternoon,
                    evening: drug_detailsarray.evening,
                    night: drug_detailsarray.night,
                    intake: drug_detailsarray.intake,
                    days: this.daydur,
                    period: this.period,
                    intake_desc: this.intake_desc,
                    drug_intake: this.drug_intake,
                    sales_price: drug_detailsarray.sales_price,
                    cost_price: drug_detailsarray.cost_price,
                    mrp: drug_detailsarray.dpricewithcal,
                    show_intake: drug_detailsarray.show_intake,
                    drug_typetxt: drug_detailsarray.drug_typetxt,
                    //no of days
                    day_txt: drug_detailsarray.days,
                    //day or week in letters 
                    daydur_txt: this.period,
                    //minutestime_duration
                    dure_txt: drug_detailsarray.time_duration,
                    //after food or before food
                    afterfood_txt: drug_detailsarray.drug_intake,
                    //intakesession
                    intake_txt: drug_detailsarray.intake_desc,
                    mor_lable: false,
                    quan_lable: true,
                  });
                   this.balanceamount=this.checkbatchnoarray[d].quantity
                   console.log("single bal ="+singlebalqty)
                 }else{
                  //  this.addNewDrugDetails()
                  this.drugDetailsArray.push({
                    pharma_id: this.pharmacyID,
                    drug_id: drug_detailsarray.drug_id,
                    type_name: drug_detailsarray.type_name,
                    drug_name: drug_detailsarray.drug_name,
                    drug_disp_name: drug_detailsarray.short_name + " " + drug_detailsarray.drug_name,
                    quantity: drug_detailsarray.quantity,
                    tempquantity: "",
                    amount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    totalamount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    // price: obj.drug_details[i].price,
                    stripprice: drug_detailsarray.stripprice || 0,
                    price: drug_detailsarray.price,
                    discount: drug_detailsarray.discount,
                    per_unit: drug_detailsarray.per_unit,
                    prod_disc: "",
                    batch_no: drug_detailsarray.batch_no,
                    amt_bdgst: "",
                    cgst: drug_detailsarray.cgst,
                    sgst: drug_detailsarray.sgst,
                    expiry_date: drug_detailsarray.expiry_date,
                    rec_item_id: drug_detailsarray.rec_item_id,
                    index: drug_detailsarray.index,
                    gen_name: "",
                    batch_required: drug_detailsarray.batch_required,
                    batcharray: drug_detailsarray.batcharray,
                    show_batcharray: false,
                    quantityDisable: true,
                    rack_id: drug_detailsarray.rack_id,
                    bin_id: drug_detailsarray.bin_id,
                    colo_required: drug_detailsarray.color_req,
                    morning: drug_detailsarray.morning,
                    afternoon: drug_detailsarray.afternoon,
                    evening: drug_detailsarray.evening,
                    night: drug_detailsarray.night,
                    intake: drug_detailsarray.intake,
                    days: this.daydur,
                    period: this.period,
                    intake_desc: this.intake_desc,
                    drug_intake: this.drug_intake,
                    sales_price: drug_detailsarray.sales_price,
                    cost_price: drug_detailsarray.cost_price,
                    mrp: drug_detailsarray.dpricewithcal,
                    show_intake: drug_detailsarray.show_intake,
                    drug_typetxt: drug_detailsarray.drug_typetxt,
                    //no of days
                    day_txt: drug_detailsarray.days,
                    //day or week in letters 
                    daydur_txt: this.period,
                    //minutestime_duration
                    dure_txt: drug_detailsarray.time_duration,
                    //after food or before food
                    afterfood_txt: drug_detailsarray.drug_intake,
                    //intakesession
                    intake_txt: drug_detailsarray.intake_desc,
                    mor_lable: false,
                    quan_lable: true,
                  });
            
                  this.discountcalculatestaff()
                
              }
               }
        
               if(singlebalqty !=0 ){
                 this.toastr.error("Insufficient stock")  
                 }
         
             }else{
              
               for (var d = 0; d < this.checkbatchnoarray.length; d++) {
              
                 if(drug_detailsarray.rec_item_id == this.checkbatchnoarray[d].rec_item_id){
                  
                  if(this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)){

                  
                    
                    balqty= this.drugquantity - this.checkbatchnoarray[d].quantity
                     this.drugquantity = balqty
                    this.drugDetailsArray.push({
                      pharma_id: this.pharmacyID,
                      drug_id: drug_detailsarray.drug_id,
                      type_name: drug_detailsarray.type_name,
                      drug_name: drug_detailsarray.drug_name,
                      drug_disp_name: drug_detailsarray.short_name + " " + drug_detailsarray.drug_name,
                      quantity: this.checkbatchnoarray[d].quantity,
                      tempquantity: "",
                      amount: parseFloat(drug_detailsarray.amount).toFixed(2),
                      totalamount: parseFloat(drug_detailsarray.amount).toFixed(2),
                      // price: obj.drug_details[i].price,
                      stripprice: drug_detailsarray.stripprice || 0,
                      price: drug_detailsarray.price,
                      discount: drug_detailsarray.discount,
                      per_unit: drug_detailsarray.per_unit,
                      prod_disc: "",
                      batch_no: drug_detailsarray.batch_no,
                      amt_bdgst: "",
                      cgst: drug_detailsarray.cgst,
                      sgst: drug_detailsarray.sgst,
                      expiry_date: drug_detailsarray.expiry_date,
                      rec_item_id: drug_detailsarray.rec_item_id,
                      index: drug_detailsarray.index,
                      gen_name: "",
                      batch_required: drug_detailsarray.batch_required,
                      batcharray: drug_detailsarray.batcharray,
                      show_batcharray: false,
                      quantityDisable: true,
                      rack_id: drug_detailsarray.rack_id,
                      bin_id: drug_detailsarray.bin_id,
                      colo_required: drug_detailsarray.color_req,
                      morning: drug_detailsarray.morning,
                      afternoon: drug_detailsarray.afternoon,
                      evening: drug_detailsarray.evening,
                      night: drug_detailsarray.night,
                      intake: drug_detailsarray.intake,
                      days: this.daydur,
                      period: this.period,
                      intake_desc: this.intake_desc,
                      drug_intake: this.drug_intake,
                      sales_price: drug_detailsarray.sales_price,
                      cost_price: drug_detailsarray.cost_price,
                      mrp: drug_detailsarray.dpricewithcal,
                      show_intake: drug_detailsarray.show_intake,
                      drug_typetxt: drug_detailsarray.drug_typetxt,
                      //no of days
                      day_txt: drug_detailsarray.days,
                      //day or week in letters 
                      daydur_txt: this.period,
                      //minutestime_duration
                      dure_txt: drug_detailsarray.time_duration,
                      //after food or before food
                      afterfood_txt: drug_detailsarray.drug_intake,
                      //intakesession
                      intake_txt: drug_detailsarray.intake_desc,
                      mor_lable: false,
                      quan_lable: true,
                    });
                     this.balanceamount=this.checkbatchnoarray[d].quantity
                   }
                    else{

                  //    this.addNewDrugDetails()
                  console.log("In get item price batcharray "+JSON.stringify(drug_detailsarray.batcharray))
                  this.drugDetailsArray.push({
                    pharma_id: this.pharmacyID,
                    drug_id: drug_detailsarray.drug_id,
                    type_name: drug_detailsarray.type_name,
                    drug_name: drug_detailsarray.drug_name,
                    drug_disp_name: drug_detailsarray.short_name + " " + drug_detailsarray.drug_name,
                    quantity: drug_detailsarray.quantity,
                    tempquantity: "",
                    amount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    totalamount: parseFloat(drug_detailsarray.amount).toFixed(2),
                    // price: obj.drug_details[i].price,
                    stripprice: drug_detailsarray.stripprice || 0,
                    price: drug_detailsarray.price,
                    discount: drug_detailsarray.discount,
                    per_unit: drug_detailsarray.per_unit,
                    prod_disc: "",
                    batch_no: drug_detailsarray.batch_no,
                    amt_bdgst: "",
                    cgst: drug_detailsarray.cgst,
                    sgst: drug_detailsarray.sgst,
                    expiry_date: drug_detailsarray.expiry_date,
                    rec_item_id: drug_detailsarray.rec_item_id,
                    index: drug_detailsarray.index,
                    gen_name: "",
                    batch_required: drug_detailsarray.batch_required,
                    batcharray: drug_detailsarray.batcharray,
                    show_batcharray: false,
                    quantityDisable: true,
                    rack_id: drug_detailsarray.rack_id,
                    bin_id: drug_detailsarray.bin_id,
                    colo_required: drug_detailsarray.color_req,
                    morning: drug_detailsarray.morning,
                    afternoon: drug_detailsarray.afternoon,
                    evening: drug_detailsarray.evening,
                    night: drug_detailsarray.night,
                    intake: drug_detailsarray.intake,
                    days: this.daydur,
                    period: this.period,
                    intake_desc: this.intake_desc,
                    drug_intake: this.drug_intake,
                    sales_price: drug_detailsarray.sales_price,
                    cost_price: drug_detailsarray.cost_price,
                    mrp: drug_detailsarray.dpricewithcal,
                    show_intake: drug_detailsarray.show_intake,
                    drug_typetxt: drug_detailsarray.drug_typetxt,
                    //no of days
                    day_txt: drug_detailsarray.days,
                    //day or week in letters 
                    daydur_txt: this.period,
                    //minutestime_duration
                    dure_txt: drug_detailsarray.time_duration,
                    //after food or before food
                    afterfood_txt: drug_detailsarray.drug_intake,
                    //intakesession
                    intake_txt: drug_detailsarray.intake_desc,
                    mor_lable: false,
                    quan_lable: true,
                  });
                  console.log("drug array in item get price"+JSON.stringify(this.drugDetailsArray))
                    this.discountcalculatestaff()
                      //1. break;
                    }
                 }
               }
               
               if(balqty != 0){
                console.log("check batch no array = "+JSON.stringify(this.checkbatchnoarray))
                 for (var d = 0; d < this.checkbatchnoarray.length; d++) {
                   if(drug_detailsarray.rec_item_id != this.checkbatchnoarray[d].rec_item_id){
                     if(this.drugquantity > this.checkbatchnoarray[d].quantity){
                       balqty= this.drugquantity - this.checkbatchnoarray[d].quantity
                       this.drugquantity = balqty
                       this.balanceamount=this.checkbatchnoarray[d].quantity
                       this.newRowatforquantity(drug_detailsarray.drug_id,this.checkbatchnoarray[d])
                     }else{
                       this.drugquantity=balqty
                       this.balanceamount=balqty
                       this.newRowatforquantity(drug_detailsarray.drug_id,this.checkbatchnoarray[d])
                       balqty=0;
                       break;
                     }
                   }
                 }
               }
               if(balqty !=0 ){
               this.toastr.error("Insufficient stock")  
               }
               console.log("EXCESS JSON STIRN "+JSON.stringify(drug_detailsarray))
             }

             var prec_tx = 0;
             var medsshort, med;
             if (this.drugDetailsArray.length > 1) {
               for (var i = 0; i < this.drugDetailsArray.length; i++) {
                 console.log("amount 4" + this.drugDetailsArray[i]);
                 prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);
           
                 medsshort = this.drugDetailsArray[i].short_name;
                 med = this.drugDetailsArray[i].med_name;
               }
             } else {
               prec_tx = prec_tx + parseFloat(drug_detailsarray.amount);
               medsshort = drug_detailsarray.short_name;
               med = drug_detailsarray.med_name;
             }
             this.returnmedicinename = medsshort + " " + med;
             this.printBtn = false;
             this.saveBtn = false;
             this.MedicneData = [];        
             if (this.rateplaneflag == true) {            
               this.discountcalculatestaff();
             }
             this.drugquantity = 0;
          }
         
          
        }
        else {
          
          if(this.pres_id != undefined){
            this.drugDetailsArray.push({
              pharma_id: this.pharmacyID,
              drug_id: drug_detailsarray.drug_id,
              type_name: drug_detailsarray.type_name,
              drug_name: drug_detailsarray.drug_name,
              drug_disp_name: drug_detailsarray.short_name + " " + drug_detailsarray.drug_name,
              quantity: drug_detailsarray.quantity,
              tempquantity: "",
              amount: parseFloat(drug_detailsarray.amount).toFixed(2),
              totalamount: parseFloat(drug_detailsarray.amount).toFixed(2),
              // price: obj.drug_details[i].price,
              stripprice: drug_detailsarray.stripprice || 0,
              price: drug_detailsarray.price,
              discount: drug_detailsarray.discount,
              per_unit: drug_detailsarray.per_unit,
              prod_disc: "",
              batch_no: drug_detailsarray.batch_no,
              amt_bdgst: "",
              cgst: drug_detailsarray.cgst,
              sgst: drug_detailsarray.sgst,
              expiry_date: drug_detailsarray.expiry_date,
              rec_item_id: drug_detailsarray.rec_item_id,
              index: drug_detailsarray.index,
              gen_name: "",
              batch_required: drug_detailsarray.batch_required,
              batcharray: "",
              show_batcharray: false,
              quantityDisable: true,
              rack_id: drug_detailsarray.rack_id,
              bin_id: drug_detailsarray.bin_id,
              colo_required: drug_detailsarray.color_req,
              morning: drug_detailsarray.morning,
              afternoon: drug_detailsarray.afternoon,
              evening: drug_detailsarray.evening,
              night: drug_detailsarray.night,
              intake: drug_detailsarray.intake,
              days: this.daydur,
              period: this.period,
              intake_desc: this.intake_desc,
              drug_intake: this.drug_intake,
              sales_price: drug_detailsarray.sales_price,
              cost_price: drug_detailsarray.cost_price,
              mrp: drug_detailsarray.dpricewithcal,
              show_intake: drug_detailsarray.show_intake,
              drug_typetxt: drug_detailsarray.drug_typetxt,
              //no of days
              day_txt: drug_detailsarray.days,
              //day or week in letters 
              daydur_txt: this.period,
              //minutestime_duration
              dure_txt: drug_detailsarray.time_duration,
              //after food or before food
              afterfood_txt: drug_detailsarray.drug_intake,
              //intakesession
              intake_txt: drug_detailsarray.intake_desc,
              mor_lable: false,
              quan_lable: true,
            });
          }
          

          setTimeout(() => {
            this.toastr.error(Message_data.getpharmaprice)
          }, 500);
        }
      }, error => { }
    );
  }

  By_pres_qty() {
    // alert(1)
    this.drugDetailsArray.push({
      drug_id: this.highqtydrugid,
      drug_name: this.medicinename,
      drug_disp_name: this.medicinename,
      quantity: this.balanceqty,
      price: this.rowprice,
      discount: this.discount,
      batch_no: this.rowbatchno,
      expiry_date: this.rowexpdate,
      amount: this.amount_pres_med.toFixed(2),
      rec_item_id: this.pres_rect_id,
      index: this.rowindex,
      batcharray: "",
      rack_id: this.rackId,
      bin_id: this.binId
    })

    var drugdetailslength = this.drugDetailsArray.length

    for (var x = drugdetailslength - 1; x <= this.drugDetailsArray.length; x++) {
      console.log("length = " + x)
      console.log("drug details = " + JSON.stringify(this.drugDetailsArray))

      if (this.drugDetailsArray[x].amount != undefined) {
        this.amountvalue += parseInt(this.drugDetailsArray[x].amount)
      }

      this.discountamountval += parseInt(this.drugDetailsArray[x].discount)

      if (this.drugDetailsArray[x].amount == "0") {
        this.totalAmount = this.amountvalue;
        this.prodDiscount = this.discountamountval;
        this.amountDicount = ((this.totalAmount - this.prodDiscount).toFixed(2)).toString();
      } else {
        this.totalAmount = Math.round(this.amountvalue - this.amount_to_del);
        this.prodDiscount = this.discountamountval;
        this.amountDicount = ((this.totalAmount - this.prodDiscount).toFixed(2)).toString();
      }
    }
  }

  tokennospeech(estimateId, finYear, deliveryType, status, orderlist, uhid, token) {
    if (token != undefined && token != "") {
      speak("token number" + token)
    }
  }


  selectedBatch(batch, drug_detailsarray, index) {

    this.firstItem = [];
    this.selectedbatch_flag = true;
    this.checkquantitybatch = batch.batch_no;
    this.checkquantityindex = batch.index;
    this.checkbackendquantity = batch.quantity;

    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    this.backenddrugquantity = drug_detailsarray.quantity
    var rackId, binId;
    this.selectbatch_noflag = true;

    // if (checkList != true) {
     
      this.batchFlag = false;
      drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
      drug_detailsarray.batch_no = batch.batch_no;

      console.log("med details array" + JSON.stringify(this.medicineArrayforbin))

      if (drug_detailsarray.batch_no != "")
        drug_detailsarray.quantityDisable = false;

      for (var i = 0; i < this.drugArray.length; i++) {
        console.log("drugh array" + JSON.stringify(this.drugArray[i]))
        if (batch.batch_no == this.drugArray[i].batch_no) {

          this.drugDetailsArray.rack_id = this.drugArray[i].rack_id;
          this.drugDetailsArray.bin_id = this.drugArray[i].bin_id;

          if (batch.index == this.drugArray[i].index) {
            if (this.tempBatch != undefined) {

              drug_detailsarray.price = this.drugArray[i].price_txt;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              //newly added
              drug_detailsarray.stripprice = this.drugArray[i].stripprice;
              drug_detailsarray.sales_price = this.drugArray[i].sales_price;
              drug_detailsarray.cost_price = this.drugArray[i].cost_price;
              drug_detailsarray.mrp = this.drugArray[i].mrp;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
            }
            else {

              drug_detailsarray.price = this.drugArray[i].price_txt;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              drug_detailsarray.cgst = this.drugArray[i].cgst;
              drug_detailsarray.sgst = this.drugArray[i].sgst;
              drug_detailsarray.discount = this.drugArray[i].discount;
              drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
              drug_detailsarray.stripprice = this.drugArray[i].stripprice;
              drug_detailsarray.sales_price = this.drugArray[i].sales_price;
              drug_detailsarray.cost_price = this.drugArray[i].cost_price;
              drug_detailsarray.mrp = this.drugArray[i].mrp;
            }

            // this.calculateamount(drug_detailsarray);
            this.discountcalculatestaff();
          }
        }
      }
      drug_detailsarray.show_batcharray = false;
      document.getElementById(index).style.display = 'none';
      document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
      document.getElementById("tbl_med").style.overflowY = "auto";

    // } 
    // else {
    //   this.toastr.error("Medicine with same batch no already added");
    // }

  }

  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden";
  }
  openModal(index): void {

    this.selecteddrugflag = !this.selecteddrugflag

  }
  displayStyle = "none";
  openPopup(e, i) {

    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';

    // this.displayStyle = "block"; 
  }
  closePopup(e, i) {
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden"
    // this.displayStyle = "hidden"; 
  }


  fineDrugList(batch, drugID) {
    var getFlag = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
  }

  isReadonly() { return true; }
  CurrentDate() {
    var data = {
      country: ipaddress.country_code
    }

    console.log("data = " + JSON.stringify(data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current date = " + JSON.stringify(obj))
          this.todayDate = obj.current_date;
          this.orderDate = this.todayDate;
          this.orderTime = Time_Formate(obj.current_time);
        },
      )
  }

  addRound(data) {


    if (data == 'plus') {
      this.calculation_value = 'plus'
      this.calvalue = "increase";
    }
    else {
      this.calculation_value = 'minus'
      this.calvalue = "decrease";
    }
    this.isInputDisabled = false;

  }

  calculateAmount(drug_detailsarray) {
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;



      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
    }
    this.calculateDiscount();
  }

  calculateamount(drug_detailsarray) {

    console.log("drug details = " + JSON.stringify(drug_detailsarray))

    if (parseInt(drug_detailsarray.discount) > 100) {
      this.toastr.error("Discount can not be more than 100%");
      drug_detailsarray.discount = "0";

    } else {
      if (drug_detailsarray.quantity != "") {
        var pricecal
        var priceval: any = parseFloat(drug_detailsarray.price);
        if (drug_detailsarray.quantity != undefined) {

          pricecal = (parseFloat(priceval) * parseFloat(drug_detailsarray.quantity)).toFixed(2);
        }
        if (drug_detailsarray.discount != null) {

          var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
          drug_detailsarray.amount = pricecal - disc;
        }
        else {
          disc = 0;
        }
        var pricegst;

        pricegst = (parseFloat(pricecal) - parseFloat(disc)).toFixed(2);
        var dpricewithcal;
        var amount;
        this.drugprice_value = drug_detailsarray.price === undefined ? 0.00 : parseFloat(drug_detailsarray.price);
        var dprice = this.drugprice_value.toFixed(2).toString();
        dpricewithcal = parseFloat(this.drugprice_value.toFixed(2));
        // const perUnit = parseInt(drug_detailsarray.per_unit);
        const perUnit = drug_detailsarray.per_unit === undefined || " " || null ? 1 : parseInt(drug_detailsarray.per_unit);
        amount = perUnit !== 0 ? (parseFloat(this.drugprice_value.toFixed(2)) / perUnit * parseInt(drug_detailsarray.quantity)) : 0;
        drug_detailsarray.amount = amount.toFixed(2);

        this.calculateDiscount();
      }
    }
  }

  updatedvalue(value) {
    if (this.calculation_value != null || this.calculation_value != undefined || this.calculation_value == "") {

      if (value != "") {
        // if (this.discount === undefined || this.discount === "") {
        //   this.discount = 0.00; 
        // } else {
        //   this.discount = parseFloat(this.discount);
        // }
        if (this.prodDiscount === undefined || this.prodDiscount === "") {
          this.prodDiscount = 0.00;
        } else {
          this.prodDiscount = parseFloat(this.prodDiscount);
        }

        if (this.calculation_value == "plus") {

          this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount + parseFloat(value))).toFixed(2);
          this.remainBalance = this.amountDicount;
        }
        else if
          (this.calculation_value == 'minus') {

          this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount - parseFloat(value))).toFixed(2);
          this.remainBalance = this.amountDicount;
        }
        else {

          console.log("error to be calculated symbol")
        }
      } else {
        // this.remainBalance = ((parseFloat(this.amountDicount))).toFixed(2);
        // this.amountDicount = ((parseFloat(this.totalAmount) - this.discount)).toFixed(2);
        this.amountDicount = ((parseFloat(this.totalAmount) - this.prodDiscount)).toFixed(2);
        this.remainBalance = this.amountDicount

      }

    }

  }

  updateRoundOff() {
    if (this.round_off === undefined || this.round_off === "") {
      this.round_off = 0.00;
    } else {
      this.round_off = parseFloat(this.round_off);
    }
    if (this.calvalue === 'increase') {
      this.amountDicount = (parseFloat(this.amountDicount) + this.round_off).toFixed(2);
    } else if (this.calvalue === 'decrease') {
      this.amountDicount = (parseFloat(this.amountDicount) - this.round_off).toFixed(2);
    }
  }

  // createRow(drug_detailsarray) {
  //   var wholeqty=0;
  //   console.log("creterow = "+JSON.stringify(this.drugDetailsArray))
  //   for(var c=0;c < this.drugDetailsArray.length;c++){
  //     wholeqty += parseInt(this.drugDetailsArray[c].quantity)
  //   }
  //   this.drugquantity = parseInt(drug_detailsarray.quantity)
    
  //   if(this.checkquantityindex != undefined){
  //     this.checkbatchlength = this.checkquantityindex + 1 
  //     const firstDrug = this.checkbatchnoarray[this.checkbatchlength];

  //     this.backenddrugquantity = parseInt(firstDrug.quantity);
  //     if (this.drugquantity <= this.backenddrugquantity) {
  //       this.addNewDrugDetails();
  //       this.medicineArrayreturns = [];
  //     } else {
  //       this.quantity(drug_detailsarray)
  //     }  
  //   }
  // }




  createRow(selected_drug) {
    console.log("selected_drug = "+JSON.stringify(selected_drug))
    this.drugquantity=selected_drug.quantity
     var balqty=0;
     var singlebalqty=0;
     console.log(" checkbatchnoarray = "+ JSON.stringify(this.checkbatchnoarray))
   
     if(this.checkbatchnoarray.length == 1){
       
       for(var d=0;d < this.checkbatchnoarray.length;d++){
         if(this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)){
           singlebalqty= this.drugquantity - this.checkbatchnoarray[d].quantity
           this.drugquantity = singlebalqty
           selected_drug.quantity=this.checkbatchnoarray[d].quantity
           this.balanceamount=this.checkbatchnoarray[d].quantity
         }else{
           this.addNewDrugDetails()
       this.discountcalculatestaff()
         }
       }

       if(singlebalqty !=0 ){
         this.toastr.error("Insufficient stock")  
         }
 
     }else{
       
       for (var d = 0; d < this.checkbatchnoarray.length; d++) {
   
         if(selected_drug.rec_item_id == this.checkbatchnoarray[d].rec_item_id){
       
           if(this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)){
             balqty= this.drugquantity - this.checkbatchnoarray[d].quantity
             this.drugquantity = balqty
             selected_drug.quantity=this.checkbatchnoarray[d].quantity
             this.balanceamount=this.checkbatchnoarray[d].quantity
           }else{
             this.addNewDrugDetails()
             this.discountcalculatestaff()
             break;
           }
         }
       }

       if(balqty != 0){
         for (var d = 0; d < this.checkbatchnoarray.length; d++) {
         
           if(selected_drug.rec_item_id != this.checkbatchnoarray[d].rec_item_id){
            
             if(this.drugquantity > this.checkbatchnoarray[d].quantity){
               balqty= this.drugquantity - this.checkbatchnoarray[d].quantity
               this.drugquantity = balqty
               this.balanceamount=this.checkbatchnoarray[d].quantity
               
               this.newRowatforquantity(selected_drug.drug_id,this.checkbatchnoarray[d])
             }else{
               this.drugquantity=balqty
               this.balanceamount=balqty
               this.newRowatforquantity(selected_drug.drug_id,this.checkbatchnoarray[d])
               balqty=0;
               break;
             }
           }
         }
       }
       if(balqty !=0 ){
       this.toastr.error("Insufficient stock")  
       }
     }
     var prec_tx = 0;
     var medsshort, med;
     if (this.drugDetailsArray.length > 1) {
       for (var i = 0; i < this.drugDetailsArray.length; i++) {
         console.log("amount 4" + this.drugDetailsArray[i]);
         prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);
   
         medsshort = this.drugDetailsArray[i].short_name;
         med = this.drugDetailsArray[i].med_name;
       }
     } else {
       prec_tx = prec_tx + parseFloat(selected_drug.amount);
       medsshort = selected_drug.short_name;
       med = selected_drug.med_name;
     }
     this.returnmedicinename = medsshort + " " + med;
     // this.totalAmount = parseInt(prec_tx.toFixed(2));
     this.printBtn = false;
     this.saveBtn = false;
     // this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
     // this.calculateamount(selected_drug);
     this.MedicneData = [];
   
     if (this.rateplaneflag == true) {
       // this.getIntakeName(drug_detailsarray);
       this.discountcalculatestaff();
     }
     this.drugquantity = 0;
   }
 





  // quantity(drug_detailsarray) {

  //   var high_qty_drug_id = drug_detailsarray.drug_id;
  //   this.drugquantity = parseInt(drug_detailsarray.quantity)

  //   console.log("medicine drug details", drug_detailsarray)
  //   console.log("drug quantity" + typeof (this.drugquantity), this.drugquantity);
  //   console.log("drug array", JSON.stringify(this.drugArray))
  //   console.log("length of drug array", this.drugArray.length)
  //   var batchnoforloop;
  //   // this.checkquantityindex;
  //   // this.checkquantitybatch;
  //   // this.checkbackendquantity;
  //   console.log("console.log == " + JSON.stringify(this.checkbatchnoarray))
  //   if (this.checkquantityindex != undefined) {
  //     this.checkbatchlength = this.checkquantityindex + 1
  //     const firstDrug = this.checkbatchnoarray[this.checkquantityindex];
  //     this.backenddrugquantity = parseInt(firstDrug.quantity);
  //     if ((this.drugquantity > this.backenddrugquantity) && (this.checkbatchlength != this.batcharraylength)) {
  //       console.log("first drug---------->>", firstDrug)
  //       console.log("drugarray 1", firstDrug);
  //       console.log(" batch array " + JSON.stringify(this.checkbatchnoarray))
  //       var totalQuantity = 0;
  //       for (var k = this.checkquantityindex + 1; k < this.checkbatchnoarray.length; k++) {
  //         var nextIndex = this.checkbatchnoarray[k].index;

  //         const secondDrug = this.checkbatchnoarray[nextIndex];

  //         this.firstItem = [];
  //         this.firstItem.push(secondDrug)

  //         console.log("second drug 2= ", this.firstItem)
  //         for (var x = 0; x < this.firstItem.length; x++) {
  //           var blamt = 0;
  //           blamt = this.drugquantity - this.backenddrugquantity;

  //           this.balanceamount = this.drugquantity - this.backenddrugquantity;

  //           if (parseInt(this.firstItem[x].quantity) < blamt) {
  //             this.balanceamount = this.firstItem[x].quantity;

  //           }
  //           this.drugquantity = blamt;
  //           this.backenddrugquantity = parseInt(this.firstItem[x].quantity);
  //           this.selectedDrugs.push(this.firstItem[x])
  //           drug_detailsarray.quantity = parseInt(this.checkbackendquantity);
  //           drug_detailsarray.rec_item_id = this.firstItem[x].rec_item_id;
  //           this.rec_item_id = this.firstItem[x].rec_item_id;
  //           drug_detailsarray.drug_id = high_qty_drug_id;
  //           this.newRowatforquantity();
  //           // this.getIntakeName(drug_detailsarray);
  //           this.qty = parseInt(this.checkbatchnoarray[k].quantity);
  //           totalQuantity += this.qty
  //           if (blamt <= totalQuantity) {

  //             break;
  //           }
  //         }
  //         if (blamt <= totalQuantity) {
  //           this.toastr.error(Message_data.quantityerror);
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   var prec_tx = 0;
  //   if (this.drugDetailsArray.length > 1) {
  //     for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //       prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
  //     }
  //   } else {

  //     prec_tx = prec_tx + parseFloat(drug_detailsarray.amount)
  //   }

  //   if(!Number.isNaN(prec_tx)){
  //     this.totalAmount = prec_tx.toFixed(2);
  //   }
  //   this.printBtn = false;
  //   this.saveBtn = false;
  //   this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
  //   this.calculateamount(drug_detailsarray);
  //   this.MedicneData = [];
  // }

  quantity(drug_detailsarray) {
    this.wholedrugquantity = 0;
    var high_qty_drug_id = drug_detailsarray.drug_id;
    this.drugquantity = parseInt(drug_detailsarray.quantity)
    console.log("medicine drug details", drug_detailsarray)
    console.log("drug quantity" + typeof (this.drugquantity), this.drugquantity);
    console.log("drug array", JSON.stringify(this.drugArray))
    console.log("length of drug array", this.drugArray.length)
    var batchnoforloop;
    // this.checkquantityindex;
    // this.checkquantitybatch;
    // this.checkbackendquantity;
    console.log("console.log == " + JSON.stringify(this.checkbatchnoarray))
    var wholeqty=0;
    for(var c=0;c < this.drugDetailsArray.length;c++){
      
      wholeqty += parseInt(this.drugDetailsArray[c].quantity)
     
      for(var index=0;index < this.drugDetailsArray[c].batcharray.length;index++)
      if(drug_detailsarray.batch_no == this.drugDetailsArray[c].batcharray[index].batch_no){
        var batchindex = this.drugDetailsArray[c].batcharray[index].index;
      }
    
    }
    if (this.checkquantityindex != undefined) {
      this.checkbatchlength = this.checkquantityindex + 1;
      if(batchindex >0){
        this.checkquantityindex=batchindex+1
      }
      const firstDrug = this.checkbatchnoarray[this.checkquantityindex];
      this.backenddrugquantity = parseInt(firstDrug.quantity);
   
      if ((this.drugquantity > this.backenddrugquantity) && (this.checkbatchlength != this.batcharraylength)) {
        console.log("first drug---------->>", firstDrug)
        console.log("drugarray 1", firstDrug);
        console.log(" batch array " + JSON.stringify(this.checkbatchnoarray))
        var totalQuantity = 0;
        for (var k = this.checkquantityindex + 1; k < this.checkbatchnoarray.length; k++) {
          var nextIndex = this.checkbatchnoarray[k].index;
          const secondDrug = this.checkbatchnoarray[nextIndex];
          this.firstItem = [];
          this.firstItem.push(secondDrug)
          console.log("second drug 2= ", this.firstItem)
          for (var x = 0; x < this.firstItem.length; x++) {
            var blamt = 0;
            blamt = this.drugquantity - this.backenddrugquantity;
           
            this.balanceamount = this.drugquantity - this.backenddrugquantity;
            if (parseInt(this.firstItem[x].quantity) < blamt) {
              this.balanceamount = this.firstItem[x].quantity;
            }
            this.drugquantity = blamt;
            this.backenddrugquantity = parseInt(this.firstItem[x].quantity);
            this.selectedDrugs.push(this.firstItem[x])
            drug_detailsarray.quantity = parseInt(this.checkbackendquantity);
            
            this.rec_item_id = this.firstItem[x].rec_item_id;
            drug_detailsarray.drug_id = high_qty_drug_id;

            // if (blamt != 0 && blamt > 0) {
            //   this.newRowatforquantity(high_qty_drug_id);
            // }
             this.newRowatforquantity(high_qty_drug_id,"");
            // this.getIntakeName(drug_detailsarray);
            this.qty = parseInt(this.checkbatchnoarray[k].quantity);
          
            this.wholedrugquantity = parseInt(this.checkbatchnoarray[k].quantity);
            totalQuantity += this.qty
            if (blamt <= totalQuantity) {
              break;
            }
          }
          if (blamt <= totalQuantity) {
            break;
          }
        }
      }
    }
    var prec_tx = 0;
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {

        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
      }
    } else {
      prec_tx = prec_tx + parseFloat(drug_detailsarray.amount)
    }
    // this.totalAmount = prec_tx.toFixed(2);
    // this.returnbilltotalAmount = prec_tx.toFixed(2);
    // this.returnamountDicount = this.totalAmount - this.returnbilltotalAmount

    this.printBtn = false;
    this.saveBtn = false;
    this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;

    console.log("DRUG DAARY = " + JSON.stringify(drug_detailsarray))

    this.calculateamount(drug_detailsarray);
    this.MedicneData = [];
    if (this.rateplaneflag = true) {
      // this.getIntakeName(drug_detailsarray);
      this.discountcalculatestaff()
    }
    this.drugquantity = 0;
  }


      newRowatforquantity(drugid,data) {
    this.addNewDrugDetailsforquantity(drugid,data);
  }

  addNewDrugDetailsforquantity(drugid,data) {

    
    // const indexAlreadyPushed = this.drugDetailsArray.some(
    //   item => item.index === this.rowindex
    // );
    // if (!indexAlreadyPushed) {
    this.selectedDrugs=[];  
    this.selectedDrugs.push(data)
    console.log("mediciene name is here", this.selectedDrugs)
    for (var i = 0; i < this.selectedDrugs.length; i++) {
      
        console.log("Selectdrugs = " + JSON.stringify(this.selectedDrugs[i]))
        // this.rowbatchno = this.selectedDrugs[i].batch_no,
        //   this.rowexpdate = this.selectedDrugs[i].expiry_date,
        //   this.discount = this.selectedDrugs[i].discount,
        //   this.rowprice = this.selectedDrugs[i].price_txt,
        //    this.rec_item_id = this.selectedDrugs[i].rec_item_id,
        //   this.rowperunit = this.selectedDrugs[i].per_unit,
        //   this.stripprice = this.selectedDrugs[i].stripprice
        this.rowbatchno =this.selectedDrugs[i].batch_no,
        this.rowexpdate = this.selectedDrugs[i].expiry_date,
        this.discount = this.selectedDrugs[i].discount,
        this.rowprice = this.selectedDrugs[i].price_txt,
        this.rec_item_id = this.selectedDrugs[i].rec_item_id,
        this.ddarray_cgst = this.selectedDrugs[i].cgst,
        this.ddarray_sgst = this.selectedDrugs[i].sgst,
        this.rowperunit = this.selectedDrugs[i].per_unit
    }
    console.log("Array erro" + JSON.stringify(this.drugDetailsArray))
    for (var j = 0; j < this.drugDetailsArray.length; j++) {
      if (drugid == this.drugDetailsArray[j].drug_id) {
        console.log("Array errt" + JSON.stringify(this.drugDetailsArray[j]))
      this.medicinename = this.drugDetailsArray[j].drug_name;
      this.highqtydrugid = this.drugDetailsArray[j].drug_id;
      this.ddarray_day_txtflag = this.drugDetailsArray[j].day_txtflag;
      this.ddarray_show_intakeFlag = this.drugDetailsArray[j].show_intakeFlag;
      this.ddarray_alternativedays = this.drugDetailsArray[j].intake_txt;
      this.ddarray_select = this.drugDetailsArray[j].afterfood_txt;
      this.ddarray_morning = this.drugDetailsArray[j].morning;
      this.ddarray_afternoon = this.drugDetailsArray[j].afternoon;
      this.ddarray_evening = this.drugDetailsArray[j].evening;
      this.ddarray_night = this.drugDetailsArray[j].night;
      this.ddarray_cost_price = this.drugDetailsArray[j].cost_price;
      this.ddarray_sales_price = this.drugDetailsArray[j].sales_price;
      this.ddarray_mrp = this.drugDetailsArray[j].mrp;
      this.rowindex = this.drugDetailsArray[j].index + 1;
      this.ddarray_daytext = this.drugDetailsArray[j].day_txt;
      this.intake=this.drugDetailsArray[j].intake;
      this.ddarray_daytext = this.drugDetailsArray[j].day_txt;
      }
    }
    var amount = this.balanceamount * this.rowprice
    this.drugDetailsArray.push({
      drug_id: this.highqtydrugid,
      drug_name: this.medicinename,
      quantity: this.balanceamount,
      per_unit: this.rowperunit,
      stripprice:this.stripprice,
      price: this.rowprice,
      intake:this.intake,
      discount: this.discount,
      batch_no: this.rowbatchno,
      expiry_date: this.rowexpdate,
      amount: amount.toFixed(2),
      index: this.rowindex,
      batcharray: "",
      rack_id: this.rackId,
      bin_id: this.binId,
      rec_item_id: this.rec_item_id,
      cgst: this.ddarray_cgst,
      sgst: this.ddarray_sgst,
      // day_txt: "",
      daydur_txt: "day(s)",
      afterfood_txt: this.ddarray_select,
      dure_txt: "30",
      intake_txt: this.ddarray_alternativedays,
      
      morning: this.ddarray_morning,
      afternoon: this.ddarray_afternoon,
      evening: this.ddarray_evening,
      night: this.ddarray_night,
      every_six: "1 - 1 - 1",
      mor_lable: false,
      quan_lable: true,
      night_eventxt: true,
      show_intakeFlag:"true",
      sales_price: this.ddarray_sales_price,
      cost_price: this.ddarray_cost_price,
      mrp: this.ddarray_mrp
    })
    console.log("Array_error_drug 2" + JSON.stringify(this.drugDetailsArray))
    this.discountcalculatestaff();
  }



  calculateDiscount() {
    // var tamtbg = 0.00;
    // var amtval = 0.00;

    // for (var i = 0; i < this.drugDetailsArray.length; i++) {
    //   tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
    //   if (this.drugDetailsArray[i].amount != "") {
    //     amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
    //   }

    // }
    // if (this.round_off_descflag == false) {


    //   this.totalAmount = amtval.toFixed(2);

    // }
    // else if (this.round_off_descflag == true) {
    //   if (!Number.isNaN(amtval)) {
    //     this.totalAmount = amtval.toFixed(2);
    //   }
    // }



    // if (this.discountType == "3") {
    //   this.amountBDGST = tamtbg.toFixed(2);
    //   for (var j = 0; j < this.discregsalesvol.length; j++) {
    //     if (parseInt(this.amountBDGST) >= this.discregsalesvol[j].volume) {
    //       var disamt = parseInt(this.amountBDGST) * (this.discregsalesvol[j].discount / 100);
    //       this.prodDiscount = Math.floor(disamt).toFixed(2);
    //       this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
    //       this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       if (this.round_off_descflag == false) {
    //         this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       }
    //       else if (this.round_off_descflag == true) {
    //         this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
    //       }
    //     }
    //   }
    // } else if (this.discountType == "4") {
    //   this.amountBDGST = tamtbg.toFixed(2);
    //   for (var j = 0; j < this.discSalesVolArray.length; j++) {
    //     if (parseInt(this.amountBDGST) >= this.discSalesVolArray[j].volume) {
    //       var disamt = parseInt(this.amountBDGST) * (this.discSalesVolArray[j].discount / 100);
    //       this.prodDiscount = Math.floor(disamt).toFixed(2);
    //       this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
    //       // this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       if (this.round_off_descflag == false) {
    //         this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       }
    //       else if (this.round_off_descflag == true) {
    //         this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
    //       }
    //     }
    //   }
    // } else if (this.discountType == "5") {
    //   this.amountBDGST = tamtbg.toFixed(2);
    //   for (var j = 0; j < this.disctransVolArray.length; j++) {
    //     if (parseInt(this.amountBDGST) >= this.disctransVolArray[j].volume) {
    //       var disamt = parseInt(this.amountBDGST) * (this.disctransVolArray[j].discount / 100);
    //       this.prodDiscount = Math.floor(disamt).toFixed(2);
    //       this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
    //       // this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       if (this.round_off_descflag == false) {
    //         this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    //       }
    //       else if (this.round_off_descflag == true) {
    //         this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
    //       }

    //     }
    //   }
    // } else {

    //    this.amountDicount = amtval.toFixed(2);

    //   if (this.round_off_descflag == false) {
    //      this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);

    //   }
    //   else if (this.round_off_descflag == true) {
    //      this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
    //   }
    // }
    // this.remainBalance = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    
  
    this.changeTotal();
    
  }
  decision() {
    if (this.rounddesc == "without_round") {
      this.round_off_descflag = false

    } else {
      this.round_off_descflag = true
    }
  }
  changeTotal() {
    if (this.prodDiscount == "") {
      this.displayDisplay = false;
      this.prodDiscount = "0.00";
    } else {
      this.displayDisplay = true;
    }
    this.paidAmount = this.paidAmount === "" ? "0.00" : this.paidAmount;
    this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidAmount))).toFixed(2);
  }

  deleteMedicine(drug_id, index) {
  
    for(var j=0;j<this.drugDetailsArray;j++){
      if (this.drugDetailsArray.length == 1) {
        if (this.drugDetailsArray[j].drug_id == "" || this.drugDetailsArray[j].drug_id == undefined || this.drugDetailsArray[j].drug_id == null) {
          this.updateBtnFlag = true;
        }
      }
    }

   

    if (drug_id.length == 0) {

      if (index > 0 && index < this.drugDetailsArray.length) {
        this.drugDetailsArray.splice(index, 1); // Remove the row at the specified index
      }
    }
       else {
        // alert(drug_id+" "+index)
        console.log("drug details = "+JSON.stringify(this.drugDetailsArray))
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null) {
          if (this.drugDetailsArray[i].drug_id == drug_id) {
            if (this.drugDetailsArray[i].index == index) {
              this.drugDetailsArray.splice(i, 1);
            }
          }
        }
      }
      var prec_tx = 0;
      var calamt = 0;
      var discountstaff = 0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        console.log("drugDetailsArray = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null)
    


          prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
        calamt += parseFloat(this.drugDetailsArray[i].calamt)

        discountstaff = parseFloat(this.drugDetailsArray[i].discountstaff)
      }
      if (this.rateplaneflag == true) {
        this.discountcalculatestaff()
        // this.totalAmount = prec_tx.toFixed(2);
        // this.amountDicount = calamt.toString();
        // this.prodDiscount = discountstaff.toFixed(2);
        // this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
      
        // this.remainBalance = (parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
      } else {
        this.discountcalculatestaff()
        // this.totalAmount = prec_tx;
        // this.amountDicount = prec_tx.toString();
        // this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
   
        // // this.remainBalance = parseFloat(this.amountDicount).toFixed(2);
        // this.remainBalance = (parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
      }
      if(this.drugDetailsArray.length > 0){
        var drugarraylength=this.drugDetailsArray.length-1
        this.aftdeletflag=false
        this.getItemPrices(this.drugDetailsArray[drugarraylength])
      }
      if (this.drugDetailsArray.length == 0) {
        this.addNewDrugDetails();
      }

    }
    
  }

  changeAdvance() {
    if (this.paidAmount != undefined && this.paidAmount != "") {
      this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.paidAmount) + parseFloat(this.prodDiscount))).toFixed(2);

    } else {
      this.paidAmount = "0.00";
      this.remainBalance = this.amountDicount;
    }
  }

  paidBlur() {
    if (this.paidAmount != "") {
      this.paidAmount = (parseFloat(this.paidAmount)).toFixed(2);
    }
  }

  dissBlur() {
    if (this.prodDiscount != "") {
      this.prodDiscount = (parseFloat(this.prodDiscount)).toFixed(2);
    }
  }

  saveEstimate(e) {


    var saveflag = false;

    if (this.patient_name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    }
    else if (this.medicinenameflag != true) {
      this.toastr.error(Message_data.selectMedicine)
    }
    else if (this.selectbatch_noflag != true) {
      this.toastr.error(Message_data.selectbatch_no)
    }
    else {
      var paid_flag;
      var paytype;
      var instype;
      var save_order_data;
      this.saveorderarray = [];

      this.saveFlag = false;
      paytype = this.payType;
      instype = this.insurerID;
      if (this.amountDicount == this.remainBalance) {
        paid_flag = "Un paid";
      } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
        paid_flag = "Partially paid";
      } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
        paid_flag = "Fully paid";
      }
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
          this.drugDetailsArray.splice(i, 1);
        }
      }

      if (this.client_reg_id != "" && this.relation_id != "") {

        for (var j = 0; j < this.drugDetailsArray.length; j++) {

          if (this.drugDetailsArray[j].day_txt !== "") {
            this.saveorderarray.push({
              pharma_id: this.drugDetailsArray[j].pharma_id,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              totalamount: this.drugDetailsArray[j].totalamount,
              // price: obj.drug_details[i].price,
              price: this.drugDetailsArray[j].stripprice,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              index: this.drugDetailsArray[j].index,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: this.drugDetailsArray[j].batch_required,
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              colo_required: this.drugDetailsArray[j].colo_required,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              // intake:this.drugDetailsArray[j]. intake,
              // days:this.drugDetailsArray[j]. days,
              period: this.drugDetailsArray[j].period,
              intake_desc: this.drugDetailsArray[j].intake_desc,
              // drug_intake:this.drugDetailsArray[j]. drug_intake,
              sales_price: this.drugDetailsArray[j].sales_price,
              cost_price: this.drugDetailsArray[j].cost_price,
              mrp: this.drugDetailsArray[j].mrp,
              show_intake: this.drugDetailsArray[j].show_intake,
              drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
              //no of days
              days: this.drugDetailsArray[j].day_txt,
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //after food or before food
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              //intakesession
              intake: this.drugDetailsArray[j].intake,
              mor_labl: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
            })
          }else{
            this.saveorderarray.push({
              pharma_id: this.drugDetailsArray[j].pharma_id,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              totalamount: this.drugDetailsArray[j].totalamount,
              // price: obj.drug_details[i].price,
              price: this.drugDetailsArray[j].stripprice,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              index: this.drugDetailsArray[j].index,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: this.drugDetailsArray[j].batch_required,
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              colo_required: this.drugDetailsArray[j].colo_required,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              // intake:this.drugDetailsArray[j]. intake,
              // days:this.drugDetailsArray[j]. days,
              period: this.drugDetailsArray[j].period,
              intake_desc: this.drugDetailsArray[j].intake_desc,
              // drug_intake:this.drugDetailsArray[j]. drug_intake,
              sales_price: this.drugDetailsArray[j].sales_price,
              cost_price: this.drugDetailsArray[j].cost_price,
              mrp: this.drugDetailsArray[j].mrp,
              show_intake: this.drugDetailsArray[j].show_intake,
              drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
              //no of days
           
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //after food or before food
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              //intakesession
              intake: this.drugDetailsArray[j].intake,
              mor_labl: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
            })
          }
        


        }

        save_order_data = {
          pharma_id: this.pharmacyID,
          purchase_type_id: "1",
          medicines: this.saveorderarray,
          country: ipaddress.country_code,
          doc_name: this.doctorName,
          pres_image: this.image,
          disc_type: this.discountType,
          disc_amt: parseFloat(this.prodDiscount).toFixed(2),
          bill_amt: parseFloat(this.amountDicount).toFixed(2),
          paid_amt: parseFloat(this.paidAmount).toFixed(2),
          balance: parseFloat(this.remainBalance).toFixed(2),
          actual_amt: parseFloat(this.totalAmount).toFixed(2),
          paid_flag: paid_flag,
          order_date: this.orderDate,
          pay_type: paytype,
          insurer: instype,
          card_holder_name: this.cardHolderName,
          transaction_no: this.TransactionID,
          bank_name: this.bankName,
          created_by: this.userName,
          payments: this.paymentsarray,
          client_reg_id: this.client_reg_id,
          patient_id: this.patient_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_rel_Id,
          rate_card:this.discountType
        };
      } else {

        for (var j = 0; j < this.drugDetailsArray.length; j++) {
          

          if (this.drugDetailsArray[j].day_txt !== "") {
            this.saveorderarray.push({
              pharma_id: this.drugDetailsArray[j].pharma_id,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              totalamount: this.drugDetailsArray[j].totalamount,
              // price: obj.drug_details[i].price,
              // price:this.drugDetailsArray[j]. price,
              price: this.drugDetailsArray[j].stripprice,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              index: this.drugDetailsArray[j].index,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: this.drugDetailsArray[j].batch_required,
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              colo_required: this.drugDetailsArray[j].colo_required,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              // intake:this.drugDetailsArray[j]. intake,
              // days:this.drugDetailsArray[j]. days,
              period: this.drugDetailsArray[j].period,
              intake_desc: this.drugDetailsArray[j].intake_desc,
              // drug_intake:this.drugDetailsArray[j]. drug_intake,
              sales_price: this.drugDetailsArray[j].sales_price,
              cost_price: this.drugDetailsArray[j].cost_price,
              mrp: this.drugDetailsArray[j].mrp,
              show_intake: this.drugDetailsArray[j].show_intake,
              drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
              //no of days
              days: this.drugDetailsArray[j].day_txt,
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //after food or before food
              // intake:this.drugDetailsArray[j]. afterfood_txt,
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              //intakesession
              // drug_intake:this.drugDetailsArray[j]. intake_txt,
              intake: this.drugDetailsArray[j].intake,
              mor_labl: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
              
            })
        }else{
          this.saveorderarray.push({
            pharma_id: this.drugDetailsArray[j].pharma_id,
            drug_id: this.drugDetailsArray[j].drug_id,
            type_name: this.drugDetailsArray[j].type_name,
            drug_name: this.drugDetailsArray[j].drug_name,
            drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
            quantity: this.drugDetailsArray[j].quantity,
            tempquantity: this.drugDetailsArray[j].tempquantity,
            amount: this.drugDetailsArray[j].amount,
            totalamount: this.drugDetailsArray[j].totalamount,
            // price: obj.drug_details[i].price,
            // price:this.drugDetailsArray[j]. price,
            price: this.drugDetailsArray[j].stripprice,
            discount: this.drugDetailsArray[j].discount,
            per_unit: this.drugDetailsArray[j].per_unit,
            prod_disc: this.drugDetailsArray[j].prod_disc,
            batch_no: this.drugDetailsArray[j].batch_no,
            amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
            cgst: this.drugDetailsArray[j].cgst,
            sgst: this.drugDetailsArray[j].sgst,
            expiry_date: this.drugDetailsArray[j].expiry_date,
            rec_item_id: this.drugDetailsArray[j].rec_item_id,
            index: this.drugDetailsArray[j].index,
            gen_name: this.drugDetailsArray[j].gen_name,
            batch_required: this.drugDetailsArray[j].batch_required,
            batcharray: this.drugDetailsArray[j].batcharray,
            show_batcharray: this.drugDetailsArray[j].show_batcharray,
            quantityDisable: this.drugDetailsArray[j].quantityDisable,
            rack_id: this.drugDetailsArray[j].rack_id,
            bin_id: this.drugDetailsArray[j].bin_id,
            colo_required: this.drugDetailsArray[j].colo_required,
            morning: this.drugDetailsArray[j].morning,
            afternoon: this.drugDetailsArray[j].afternoon,
            evening: this.drugDetailsArray[j].evening,
            night: this.drugDetailsArray[j].night,
            // intake:this.drugDetailsArray[j]. intake,
            // days:this.drugDetailsArray[j]. days,
            period: this.drugDetailsArray[j].period,
            intake_desc: this.drugDetailsArray[j].intake_desc,
            // drug_intake:this.drugDetailsArray[j]. drug_intake,
            sales_price: this.drugDetailsArray[j].sales_price,
            cost_price: this.drugDetailsArray[j].cost_price,
            mrp: this.drugDetailsArray[j].mrp,
            show_intake: this.drugDetailsArray[j].show_intake,
            drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
            //no of days
            
            //day or week in letters 
            day_dur: this.drugDetailsArray[j].daydur_txt,
            //minutes
            time_duration: this.drugDetailsArray[j].dure_txt,
            //after food or before food
            // intake:this.drugDetailsArray[j]. afterfood_txt,
            drug_intake: this.drugDetailsArray[j].afterfood_txt,
            //intakesession
            // drug_intake:this.drugDetailsArray[j]. intake_txt,
            intake: this.drugDetailsArray[j].intake,
            mor_labl: this.drugDetailsArray[j].mor_lable,
            quan_lable: this.drugDetailsArray[j].quan_lable,
            
          })
        }
          

        }

        save_order_data = {
          pharma_id: this.pharmacyID,
          purchase_type_id: "1",
          medicines: this.saveorderarray,
          country: ipaddress.country_code,
          patient_id: this.patient_id,
          doc_name: this.doctorName,
          pres_image: this.image,
          disc_type: this.discountType,
          disc_amt: parseFloat(this.prodDiscount).toFixed(2),
          bill_amt: parseFloat(this.amountDicount).toFixed(2),
          paid_amt: parseFloat(this.paidAmount).toFixed(2),
          balance: parseFloat(this.remainBalance).toFixed(2),
          actual_amt: parseFloat(this.totalAmount).toFixed(2),
          paid_flag: paid_flag,
          order_date: this.orderDate,
          pay_type: paytype,
          insurer: instype,
          card_holder_name: this.cardHolderName,
          transaction_no: this.TransactionID,
          bank_name: this.bankName,
          created_by: this.userName,
          payments: this.paymentsarray,
          delivered_person: this.patient_name,
          rate_card:this.discountType
        };
      }

      console.log("save_order_data" + JSON.stringify(save_order_data));
      console.log("Medicinses det " + JSON.stringify(this.drugDetailsArray))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.saveEstimateURL, JSON.stringify(save_order_data), { headers: headers }).subscribe(
        data => {
          if (data.json().key !== null && data.json().key == "1") {
            console.log("Dataaaaa", JSON.parse(data["_body"]));
            var obj = JSON.parse(data["_body"]);
            Helper_Class.seratecardplan(this.discountType);
            var billnumber = {
              no: "0"
            }
            Helper_Class.setbillprintgen(billnumber);
            this.billID = data.json().estimate_id;
            this.tokenno = obj.token;
            this.toastr.success(Message_data.billgen);
            // this.toastr.success("The Generated Token No is " + obj.token);
            this.printdevice();
            this.intakesessionflag = false;
            this.totalAmount = "0.00";
            this.prodDiscount = "0.00";
            this.amountDicount = "0.00";
            this.paidAmount = "0.00";
            this.remainBalance = "0.00";
            this.saveFlag = true;
            this.status_buttonflag = false;
            this.newEstimateFlag = true;
            this.updateBtnFlag = true;
            // public prodDiscount: any = "0.00";
            // public amountDicount = "0.00";
            // totalAmount: any="0.00";
            // public todayDate: string;
            // public paidAmount = "0.00";
            // public remainBalance = "0.00"
            this.currentEstimates(this.pharmacyID);
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.orderNotPlaced);
          }
        }, error => { this.saveFlag = true; });
    }
    // this.medicinenameflag = false;
  }

  saveOrder(e) {
    console.log("print type =" + e)
    var saveflag = false;
    if (this.doctorName == "" || this.patient_name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    }
    else {
      var paid_flag;
      var paytype;
      var instype;
      this.saveFlag = false;
      paytype = this.payType;
      instype = this.insurerID;
      if (this.amountDicount == this.remainBalance) {
        paid_flag = "Un paid";
      } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
        paid_flag = "Partially paid";
      } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
        paid_flag = "Fully paid";
      }
      for (var i = 0; i < this.drugDetailsArray.length; i++) {


        // if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
        //   this.drugDetailsArray.splice(i, 1);
        // }

        if (this.drugDetailsArray.length == 1) {

          if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
            this.nomedicineflag = false
          } else {

            if (this.drugDetailsArray[i].batch_no == undefined || this.drugDetailsArray[i].rack_id == undefined || this.drugDetailsArray[i].bin_id == undefined) {
              this.bill_generate = false
            }
          }
        }
      }

      console.log("for print" + JSON.stringify(this.drugDetailsArray))
      

      var save_order_data = {
        clntsal: this.clntSal,
        clientname: this.clientname,
        pres_date: this.presdate,
        ipop: this.ipop,
        age: this.clntAge,
        gender: this.clntGender,
        mobileno: this.clntContNumber,
        bed_no: this.Bed_no,
        admission_date: this.admissiondate,
        ward_name: this.Ward_name,
        admission_time: this.admission_time,
        token: this.tokenno,
        pharma_estimate_id: this.estimateId,
        pharma_id: this.pharmacyID,
        purchase_type_id: "1",
        medicines: this.drugDetailsArray,
        country: ipaddress.country_code,
        name: this.patient_name,
        doc_name: this.doctorName,
        pres_image: this.image,
        disc_type: this.discountType,
        
        disc_amt: parseFloat(this.prodDiscount).toFixed(2),
        bill_amt: parseFloat(this.amountDicount).toFixed(2),
        paid_amt: parseFloat(this.paidAmount).toFixed(2),
        balance: parseFloat(this.remainBalance).toFixed(2),
        actual_amt: parseFloat(this.totalAmount).toFixed(2),
        gst_amt:this.GSTamount.toFixed(2),
        // balance:this.remainBalancemedic,
       
        paid_flag: paid_flag,
        order_date: this.orderDate,
        pay_type: paytype,
        insurer: instype,
        card_holder_name: this.cardHolderName,
        transaction_no: this.TransactionID,
        bank_name: this.bankName,
        created_by: this.userName,
        payments: this.paymentsarray,
        mrno: this.uhid,
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_Id,
        rateplan: this.discountType,
        morning: this.morning,
        evening: this.evening,
        afternoon: this.afternoon,
        night: this.night,
        days: this.daydur,
        pres_drug_id: this.pres_drug_id,
        bill_incl_pharma: this.bill_incl_pharma,
        clntdob: this.clntdob,
        clntAddress1: this.clntAddress1,
        clntAddress2: this.clntAddress2,
        clntLocation: this.clntLocation,
        clntCity: this.clntCity,
        clntZipcode: this.clntZipcode,
        clntState: this.clntState
      };
      console.log(" bill gen" + JSON.stringify(save_order_data))
      if (this.bill_generate == false) {
        this.toastr.error(Message_data.produdetails);
      } else if (this.nomedicineflag == false) {

        this.toastr.error(Message_data.nomedicine);
      }
      else {
        this.intakesessionflag = false;
        Helper_Class.setbillgenerate(save_order_data);
        this.messageservice.sendMessage('ordercreate');
      }
    }
  }

  updateEstimate(e) {
    console.log("..." + JSON.stringify(this.drugDetailsArray))
    var saveflag = false;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == undefined) {
        this.update = false;
        console.log("Drug_id == " + JSON.stringify(this.drugDetailsArray[i].drug_id))
      }
      if (this.drugDetailsArray[i].drug_name == undefined) {
        this.update = false;
        console.log("Drug_name == " + JSON.stringify(this.drugDetailsArray[i].drug_name))
      }
      if (this.drugDetailsArray[i].quantity == undefined) {
        this.update = false;
        console.log("Quantity == " + JSON.stringify(this.drugDetailsArray[i].quantity))
      }
      if (this.drugDetailsArray[i].rack_id == undefined) {
        this.update = false;
        console.log("Rack_id == " + JSON.stringify(this.drugDetailsArray[i].rack_id))
      }
      if (this.drugDetailsArray[i].bin_id == undefined) {
        this.update = false;
        console.log("Bin_id == " + JSON.stringify(this.drugDetailsArray[i].bin_id))
      }
      if (this.drugDetailsArray[i].amount == undefined) {
        this.update = false;
        console.log("Amount == " + JSON.stringify(this.drugDetailsArray[i].amount))
      }

    }

    if (this.doctorName == "" || this.patient_name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      if (this.update == false) {
        this.toastr.error(Message_data.produdetails);


      } else {
        var paid_flag;
        var paytype;
        var instype;
        this.saveFlag = false;
        paytype = this.payType;
        instype = this.insurerID;
        if (this.amountDicount == this.remainBalance) {
          paid_flag = "Un paid";
        } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
          paid_flag = "Partially paid";
        } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
          paid_flag = "Fully paid";
        }
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          console.log("in update 1 =" + JSON.stringify(this.drugDetailsArray))
          if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
            this.drugDetailsArray.splice(i, 1);
          }
        }
        console.log("in update 2 = " + JSON.stringify(this.drugDetailsArray));
        var update_order_data;
       
        if (this.auto_order == "1") {

          this.saveorderarray = [];
          for (var j = 0; j < this.drugDetailsArray.length; j++) {
          
            
            this.saveorderarray.push ({
              pharma_id: this.drugDetailsArray[j].pharma_id,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              totalamount: this.drugDetailsArray[j].totalamount,
              // price: obj.drug_details[i].price,
              // price:this.drugDetailsArray[j]. price,
              price: this.drugDetailsArray[j].stripprice,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              index: this.drugDetailsArray[j].index,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: this.drugDetailsArray[j].batch_required,
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              colo_required: this.drugDetailsArray[j].colo_required,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              // intake:this.drugDetailsArray[j]. intake,
              // days:this.drugDetailsArray[j]. days,
              period: this.drugDetailsArray[j].period,
              intake_desc: this.drugDetailsArray[j].intake_desc,
              // drug_intake:this.drugDetailsArray[j]. drug_intake,
              sales_price: this.drugDetailsArray[j].sales_price,
              cost_price: this.drugDetailsArray[j].cost_price,
              mrp: this.drugDetailsArray[j].mrp,
              show_intake: this.drugDetailsArray[j].show_intake,
              drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
              //no of days
              days: this.drugDetailsArray[j].day_txt,
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //after food or before food
              // intake:this.drugDetailsArray[j]. afterfood_txt,
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              //intakesession
              // drug_intake:this.drugDetailsArray[j]. intake_txt,
              intake: this.drugDetailsArray[j].intake,
              mor_labl: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
            })

          //   if (this.drugDetailsArray[j].day_txt !== "") {
          //     saveOrderObj.days = this.drugDetailsArray[j].day_txt;
          // }
      
          // this.saveorderarray.push(saveOrderObj);

          this.saveorderarray.forEach((item) => {
            if (item.days === "") {
                delete item.days;
            }
        });

          }

          update_order_data = {
            fin_year: this.financialYear,
            pharma_estimate_id: this.estimateId,
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: this.saveorderarray,
            country: ipaddress.country_code,
            name: this.patient_name,
            doc_name: this.doctorName,
            pres_image: this.image,
            disc_type: this.discountType,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDicount).toFixed(2),
            paid_amt: parseFloat(this.paidAmount).toFixed(2),
            balance: parseFloat(this.remainBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            created_by: this.userName,
            payments: this.paymentsarray,
            auto_order: this.auto_order,
            estimate_date: this.estimate_date
            // token:this.tokenno
          };

        } else if (this.auto_order == "0") {
          this.saveorderarray = [];
          for (var j = 0; j < this.drugDetailsArray.length; j++) {

            this.saveorderarray.push({
              pharma_id: this.drugDetailsArray[j].pharma_id,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              totalamount: this.drugDetailsArray[j].totalamount,
              // price: obj.drug_details[i].price,
              // price:this.drugDetailsArray[j]. price,
              price: this.drugDetailsArray[j].stripprice,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              index: this.drugDetailsArray[j].index,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: this.drugDetailsArray[j].batch_required,
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              colo_required: this.drugDetailsArray[j].colo_required,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              // intake:this.drugDetailsArray[j]. intake,
              // days:this.drugDetailsArray[j]. days,
              period: this.drugDetailsArray[j].period,
              intake_desc: this.drugDetailsArray[j].intake_desc,
              // drug_intake:this.drugDetailsArray[j]. drug_intake,
              sales_price: this.drugDetailsArray[j].sales_price,
              cost_price: this.drugDetailsArray[j].cost_price,
              mrp: this.drugDetailsArray[j].mrp,
              show_intake: this.drugDetailsArray[j].show_intake,
              drug_typetxt: this.drugDetailsArray[j].drug_typetxt,
              //no of days
              days: this.drugDetailsArray[j].day_txt,
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //after food or before food
              // intake:this.drugDetailsArray[j]. afterfood_txt,
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              //intakesession
              // drug_intake:this.drugDetailsArray[j]. intake_txt,
              intake: this.drugDetailsArray[j].intake,
              mor_labl: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
            })
            
            

          //   if (this.drugDetailsArray[j].day_txt !== "") {
          //     saveOrderObj.days = this.drugDetailsArray[j].day_txt;
          // }
      
          // this.saveorderarray.push(saveOrderObj);

          this.saveorderarray.forEach((item) => {
            if (item.days === "") {
                delete item.days;
            }
        });


          }

          update_order_data = {
            fin_year: this.financialYear,
            pharma_estimate_id: this.estimateId,
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: this.saveorderarray,
            country: ipaddress.country_code,
            name: this.patient_name,
            doc_name: this.doctorName,
            pres_image: this.image,
            disc_type: this.discountType,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDicount).toFixed(2),
            paid_amt: parseFloat(this.paidAmount).toFixed(2),
            balance: parseFloat(this.remainBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            created_by: this.userName,
            payments: this.paymentsarray,
            auto_order: this.auto_order,
            token: this.tokenno,
            estimate_date: this.estimate_date
          };
        }

        console.log("updateEstimate_data" + JSON.stringify(update_order_data));
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.updateEstimateURL, JSON.stringify(update_order_data), { headers: headers }).subscribe(
          data => {
            if (data.json().key !== null && data.json().key == "1") {
              var obj = JSON.parse(data["_body"]);
              console.log("Dataaaaa ((())) " + JSON.stringify(obj));
              var billnumber = {
                no: "0"
              }

              this.tokenflag = true;
              Helper_Class.setbillprintgen(billnumber);
              this.billID = this.estimateId;


              if (this.statusFlag == true) {
                this.toastr.success("Updated successfully");
                
                // this.tokenflag=true;
              }



              // this.toastr.success("The Generated Token No is " + obj.token);
              this.saveFlag = true;
              // this.tokenno = obj.token;
              // if (Helper_Class.getIsAdmin() != undefined) {
              //   this.adminService.sendMessage("pharmaBillingList")
              // } else {

              // this.pharmacyservice1.sendMessage('billestimate');
              //}

              // if (e == 'print' || this.tokenno == undefined) {
              //   this.tokenno = obj.token;
              //   // this.uhid=obj.token;
              //   this.print();
              // }
              if (this.tokenno == undefined) {
                this.tokenno = obj.token;
                console.log("token number = "+this.tokenno)
                this.thermalspan = true;
                this.thermalspantoken = true;
              }
            }
            else {
              this.saveFlag = true;
              this.toastr.error(Message_data.orderNotPlaced);
            }
          }, error => { this.saveFlag = true; });
      }
    }

  }


  cancel() {
    var saveflag = false;
    if (this.doctorName == "" || this.patient_name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      var paid_flag;
      var paytype;
      var instype;
      this.saveFlag = false;
      paytype = this.payType;
      instype = this.insurerID;
      if (this.amountDicount == this.remainBalance) {
        paid_flag = "Un paid";
      } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
        paid_flag = "Partially paid";
      } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
        paid_flag = "Fully paid";
      }
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        console.log("in update 1 =" + JSON.stringify(this.drugDetailsArray))
        if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
          this.drugDetailsArray.splice(i, 1);
        }
      }
      console.log("in update 2 = " + JSON.stringify(this.drugDetailsArray));

      var update_order_data = {

        // client_reg_id:this.client_reg_id,
        // patient_id:this.patient_id,
        // relation_id:this.relation_id,
        fin_year: this.financialYear,
        pharma_estimate_id: this.estimateId,
        pharma_id: this.pharmacyID,
        purchase_type_id: "1",
        medicines: this.drugDetailsArray,
        country: ipaddress.country_code,
        name: this.patient_name,
        doc_name: this.doctorName,
        pres_image: this.image,
        disc_type: this.discountType,
        disc_amt: parseFloat(this.prodDiscount).toFixed(2),
        bill_amt: parseFloat(this.amountDicount).toFixed(2),
        paid_amt: parseFloat(this.paidAmount).toFixed(2),
        balance: parseFloat(this.remainBalance).toFixed(2),
        actual_amt: parseFloat(this.totalAmount).toFixed(2),
        paid_flag: paid_flag,
        order_date: this.orderDate,
        pay_type: paytype,
        insurer: instype,
        card_holder_name: this.cardHolderName,
        transaction_no: this.TransactionID,
        bank_name: this.bankName,
        created_by: this.userName,
        payments: this.paymentsarray,
        auto_order: this.auto_order,
        cancel_reason: this.cancelreasonest
      };

      console.log("updateEstimate_data" + JSON.stringify(update_order_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.updateEstimateURL, JSON.stringify(update_order_data), { headers: headers }).subscribe(
        data => {
          if (data.json().key !== null && data.json().key == "1") {
            var obj = JSON.parse(data["_body"]);
            console.log("cancel data = " + JSON.stringify(obj));
            this.toastr.success(Message_data.cancelsuccess)
            this.pharmacyservice1.sendMessage('billestimate');
         
          }
          else {
            // this.saveFlag = true;
            this.toastr.error(Message_data.cancelerror);
          }
        }, error => {

        });
    }
  }

  printArea() {
    var saveflag = false;
    if (this.doctorName == "" || this.patient_name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      if (this.estimateId != undefined) {

        if (this.tokenshowflag == true) {
          this.updateEstimate('print')
        }
        else {

          this.print()
        }


      } else {

        this.saveEstimate('print');
      }
    }
  }

  async print() {
   
    // if (this.billID != undefined) {
    //   console.log("inside print page ")
    //   var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
    //   var template;
    //   if (this.billTemplate != undefined && this.billTemplate == "banner") {
    //     template = '<div #banner id="banner">'
    //   } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
    //     template = '<div #noheader id="noheader">'
    //   } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
    //     template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
    //   } else {
    //     template = '<div #billprint id="billprint">';
    //   }
    //   console.log(" DRUG DETAILS IN PRINT FUNCTION" + JSON.stringify(this.drugDetailsArray))
    //   this.drugDetailsArray.sort((a, b) => {
    //     if (a.rack_id !== b.rack_id) {
    //       return a.rack_id - b.rack_id;
    //     }
    //     return a.bin_id - b.bin_id;
    //   });
    //   var docdes
    //   if (this.doctorName)
    //     var patientdetails = [{
    //       patientname: this.clntSal + ". " + this.patient_name,
    //       tokenno: this.tokenno,
    //       mrnumber: this.uhid,
    //       estimate_amount: parseFloat(this.totalAmount).toFixed(2)
    //     }]

    //   console.log("details of drug " + JSON.stringify(this.drugDetailsArray))


    //   var printData = {
    //     clntsal: this.clntSal,
    //     doctor_desc: "Dr. ",
    //     template: this.billTemplate,
    //     content: template,
    //     pharma_logo: this.pharmaLogo,
    //     pharma_name: this.pharmacyName,
    //     pharma_addr: pharmaAddr,
    //     location: this.location,
    //     city: this.city,
    //     state: this.state,
    //     country: this.country,
    //     dl_no: this.dlNO,
    //     gst_no: this.gstNO,
    //     patient_name: this.patient_name,
    //     age: this.clntAge,
    //     gender: this.headergen,
    //     bill_no: this.billID,
    //     PurchaseType: this.PurchaseType,
    //     doc_name: this.doctorName,
    //     order_date: Date_Formate(this.orderDate),
    //     order_time: this.orderTime,
    //     drugList: this.drugDetailsArray,
    //     discount: this.prodDiscount,
    //     total: parseFloat(this.amountDicount).toFixed(2),
    //     //  total: parseFloat(this.totalAmount).toFixed(2),
    //     paid: parseFloat(this.paidAmount).toFixed(2),
    //     balance: parseFloat(this.remainBalance).toFixed(2),
    //     order_type: true,
    //     show_discount: this.displayDisplay,
    //     pay_type: this.payDes,
    //     pay_flag: this.paytypreq,
    //     payments: this.paymentsarray,
    //     isEstimate: "1",
    //     tokenno: this.tokenno,
    //     mrnumber: this.uhid,
    //     patient_details: patientdetails,
    //     mrno: this.uhid
    //   }

    //   console.log("print data" + JSON.stringify(printData));
    //   console.log("this.drugDetailsArray", this.drugDetailsArray)


    //   this.printService.print(printData);
    // }
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP'];
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageFile = myReader.result.toString();
      this.image = this.imageFile.split(',')[1];
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.ReferedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.doctorName,
          hospital_id: this.hosp_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(" referal data = " + JSON.stringify(obj))
            this.ReferedNameArray = obj.doctors;
            if (this.ReferedNameArray != undefined) {
              for (var i = 0; i < this.ReferedNameArray.length; i++) {
                var docname;
                if (this.ReferedNameArray[i].middle_name != undefined) {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].middle_name + " " + this.ReferedNameArray[i].last_name;
                } else {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].last_name;
                }
                this.ReferedDoctorArray.push(docname);
              }
            }

            this.ReferedDoctorArray = this.ReferedDoctorArray.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.doctor_txt.toLowerCase()) > -1;
            }.bind(this));

          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(item) {
    this.docsalflag = false;
    this.doctorName = item;
    if (item != undefined) {
      this.clientdoc = item
    } else {
      this.clientdoc = "-"
    }

    this.ReferedDoctorArray = [];
  }

  getDiscountDetails() {
    this.discregsalesvol = [];
    this.discSalesVolArray = [];
    this.disctransVolArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discregsalesvol = obj.regsal_vol;
          this.discSalesVolArray = obj.sales_vol;
          this.disctransVolArray = obj.trans_vol;
        });
  }

  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.discountURl,{center_id:this.pharmacyID}, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        console.log("distype = "+JSON.stringify(distypes))
        this.discountDatasArray = distypes.disc_types;
        
        // this.discountType = this.discountDatasArray[0].disc_type_id;
        for(var i=0;i<this.discountDatasArray.length;i++){
          if(this.discountDatasArray[i].default_val == 1){
            this.discountType = this.discountDatasArray[i].disc_type_id;
            break
          }else{
            this.discountType = this.discountDatasArray[0].disc_type_id;
          }
        } 
        this.discountChange(this.discountType)
      }, error => { });
  }

    discountChange(distype) {

    console.log(" distype = "+distype)
    var discountdesc;
    var discount_id;
    for (let k = 0; k < this.drugDetailsArray.length; k++) {
      if (this.drugDetailsArray[k].drug_id != "") {
        this.rateplaneflag = true;
      }
    }
    for (var i = 0; i < this.discountDatasArray.length; i++) {
      if (distype == this.discountDatasArray[i].disc_type_id) {
        this.discountdesc = this.discountDatasArray[i].discount_type;
        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;
        this.discountcalculatestaff();
      }
    }
    // if (distype != "1" && distype != "2") {
    //   this.discoutFlag = false;
    //   if (this.discregsalesvol != undefined && this.discregsalesvol.length == 0) {
    //     this.getDiscountDetails();
    //   }
    // } else {
    //   this.discoutFlag = true;
    // }
    // if (this.drugDetailsArray[0].drug_id != "" || null || undefined) {
    //   this.calculateDiscount();
    // }

  }

  discountcalculatestaff() {

    if (this.discountdesc == "cost plus gst") {
     
      this.GSTamountflag=false;
      this.discamount = 0;
      this.totalAmount = 0;
      this.GSTamount=0;
      this.gstdecimalamt=0;
      this.amountDicount = "0.00";
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var costlineamount = 0;

       
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
            //costprice=
            costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            console.log("costpricesubstute = " + costpricesubstute + "costlinamount = " + costlineamount + "-" + parseFloat(this.discountvalue_amount) / 100)
            //salesprice
            salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            // discount cal
            var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
            console.log("disamt = " + disamt + "costlinamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            this.discamount += (costlineamount * (parseFloat(this.discountvalue_amount) / 100))
            console.log("discamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            this.aftdiscamt += (costlineamount - disamt);
            console.log("discamount = " + this.aftdiscamt + "* " + costlineamount + "-" + disamt)
          }
          else {
            costpricesubstute = 0;
            salsepricesubstute = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.drugDetailsArray[i].price = costprice.toFixed(2);
          this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt* (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if(this.GSTamount == 0){
            this.GSTamountflag = true
          }else{
            this.GSTamountflag=false
          }
          calTotalamount += this.aftdiscamt + cgstcal + sgstcal;
          this.drugDetailsArray[i].calamt = calTotalamount;
          // this.totalAmount = Math.round(parseFloat(this.totalAmount)+costlineamount).toFixed(2)
          // this.prodDiscount= Math.round(this.discamount).toFixed(2);
          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(costlineamount.toFixed(2));
          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDiscount = this.discamount.toFixed(2);
          if(this.prodDiscount == 0.00){
            this.discoutFlag = true
          }else{
            this.discoutFlag=false
          }
          if (this.amountDicount != "") {
            this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)
            // Math.round(amtval).toFixed(2);
          }

          this.remainBalance = Math.round(parseFloat(this.amountDicount)).toFixed(2);
        }
      }
    } else if (this.discountdesc == "sales plus gst") {
      //salesplucgst----	Own staff
      this.GSTamountflag=false;
      this.discamount = 0;
      this.prodDiscount=0;
      this.totalAmount = 0;
      this.amountDicount = "0.00";
      this.GSTamount=0;
      this.gstdecimalamt=0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        
        console.log("DISCOUNT VALUE sa = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
            //cost
            costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            var costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            //sales
            salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            
            var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
            this.discamount += disamt
            this.aftdiscamt += (saleslineamount - disamt);
            
            console.log("clau = " + salesprice + " " + saleslineamount + " " + disamt + " " + this.aftdiscamt)
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.drugDetailsArray[i].price = salesprice.toFixed(2)
          this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt * (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if(this.GSTamount == 0){
            this.GSTamountflag = true
          }else{
            this.GSTamountflag=false
          }
          calTotalamount += this.aftdiscamt + cgstcal + sgstcal
          this.drugDetailsArray[i].calamt = calTotalamount
          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          // alert(this.totalAmount)
          // this.totalAmount += saleslineamount.toFixed(2);
          this.prodDiscount = this.discamount.toFixed(2);
          if(this.prodDiscount == 0.00){
            this.discoutFlag = true
          }else{
            this.discoutFlag=false
          }
          if (this.amountDicount != "") {
            this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)
          }
          this.remainBalance = Math.round(parseFloat(this.amountDicount)).toFixed(2);
        }
      }
    } else if (this.discountdesc == "MRP") {
      this.discamount = 0;
   
      this.GSTamountflag=true;
      this.totalAmount = 0;
      this.GSTamount=0;
      this.amountDicount = "0.00";
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var mrpprice = 0;
        var mrpcallineamount = 0;
        var mrpdisamt;

        console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
            costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            costpricesubstute = costprice - this.discountvalue_amount
            salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            salsepricesubstute = salsepricesubstute - this.discountvalue_amount
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
          mrpprice = this.drugDetailsArray[i].mrp
          this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);
          console.log(JSON.stringify(this.drugDetailsArray[i].quantity) + " " + JSON.stringify(this.drugDetailsArray[i].mrp) + " " + JSON.stringify((parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2)))

          mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));
          mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
          console.log("mrp disamount = " + mrpdisamt)
          this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
          this.aftdiscamt += (mrpcallineamount - mrpdisamt);
          console.log("aftdiscamt" + this.aftdiscamt)
          this.drugDetailsArray[i].discountstaff = this.discamount;
          calTotalamount += this.aftdiscamt

          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));

          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDiscount = this.discamount.toFixed(2);
          if(this.prodDiscount == 0.00){
            this.discoutFlag = true
          }else{
            this.discoutFlag=false
          }
          if (this.amountDicount != "") {
            this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)
          }
          this.remainBalance = Math.round(parseFloat(this.amountDicount)).toFixed(2);
        }
      }
    }
  }

spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part
    
    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
  
      return parseFloat(newNumberStr);
      
    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }
  

  changeOrderDate(e) {
    this.orderDate = e;
  }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    return color;
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  paidFocus() {
    this.paidAmount = "";
  }

  addPaymentDetails() {
    this.tamt += this.amountval;
    if (this.tamt > parseInt(this.totalAmount)) {
      this.toastr.error(Message_data.getGreaterAmount());
      this.tamt -= this.amountval;
    } else {
      for (var i = 0; i < this.payTypeListArrray.length; i++) {
        if (this.payType1 == this.payTypeListArrray[i].pay_id) {
          this.paymentsarray.push({
            pay_id: this.payTypeListArrray[i].pay_id,
            pay_desc: this.payTypeListArrray[i].pay_desc,
            amount: this.amountval.toFixed(2)
          })
          this.amountval = 0;
        }
      }
    }
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);

    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.pharmacyBranchList();
        })
  }

  selectDate(data, value) {
    if (data != null) {
      if (value == "1") {
        this.fromDate = data;
      } else {
        this.toDate = data;
      }
    }
  }

  pharmacyBranchList() {
    var send_data = JSON.stringify({
      pharma_reg_id: this.userinfo.user_id,
      imei: this.imei
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/locbyregid/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          if (obj.pharma != null) {
            // this.pharmacyID = obj.pharma[0].pharma_id;
            this.userTypeSelect("out");
            localStorage.setItem("pharma_id", obj.pharma[0].pharma_id);
          }
        });
  }

  currentEstimates(pharmacyBranchId) {
    this.orderListArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var send = {
      pharma_id: this.pharmacyID,
      flag: "current",
      inpatient_flag: this.inpatientFlag,
      start_date: this.fromDate,
      end_date: this.toDate,
    }
    console.log("sendData_Toget Est List", JSON.stringify(send));
    this.http.post(ipaddress.getIp + "pharmacontrol/gaest", JSON.stringify(send), { headers: headers }).subscribe(
      response => {
        var obj = response.json();

        console.log("Data For Est List" + JSON.stringify(obj));
        if (obj.order_details != null) {
          this.orderListArray = [];
          this.noDataCurrent = false;
          for (var i = 0; i < obj.order_details.length; i++) {
            var status = obj.order_details[i].status == "0" ? "Completed" : "Open";

            localStorage.setItem("status", obj.order_details[i].status)
            var patient_name;
            if (obj.order_details[i].first_name != undefined) {
              if (obj.order_details[i].middle_name != undefined) {
                patient_name = encrypt_decript.Decript(obj.order_details[i].first_name) + " " + encrypt_decript.Decript(obj.order_details[i].middle_name) + " " + encrypt_decript.Decript(obj.order_details[i].last_name);

              } else {
                patient_name = encrypt_decript.Decript(obj.order_details[i].first_name) + " " + encrypt_decript.Decript(obj.order_details[i].last_name);
              }

            } else {
              patient_name = obj.order_details[i].del_person
            }
            var timeandsession;
            if (obj.order_details[i].pres_time != null || undefined || "") {


              this.fromprescflag = true;
              this.convertToAmPm(obj.order_details[i].pres_time)
              timeandsession = this.convertToAmPm(obj.order_details[i].pres_time)
              var doctor_name
              if (obj.order_details[i].doctor_name == undefined) {
                doctor_name = ""
              } else {
                doctor_name = obj.order_details[i].doctor_name
              }

              this.orderListArray.push({
                token: obj.order_details[i].token,
                pharma_Estimate_Id: obj.order_details[i].pharma_estimate_id,
                // person_Name: obj.order_details[i].del_person,
                person_Name: patient_name,
                doctor_name: doctor_name,
                delivery_Type: obj.order_details[i].description,
                estimation_Date: Date_Formate(obj.order_details[i].estimate_date),
                pres_time: timeandsession,
                pres_date: Date_Formate(obj.order_details[i].pres_date),
                estimation_Time: obj.order_details[i].estimate_time,
                financial_Year: obj.order_details[i].fin_year,
                status: status,
                estimat_datere: obj.order_details[i].estimate_date,
                uhid: obj.order_details[i].uhid
              });
            } else {
              var doctor_name
              this.fromprescflag = false;
              if (obj.order_details[i].doctor_name == undefined) {
                doctor_name = ""
              } else {
                doctor_name = obj.order_details[i].doctor_name
              }
              this.orderListArray.push({
                token: obj.order_details[i].token,
                pharma_Estimate_Id: obj.order_details[i].pharma_estimate_id,
                // person_Name: obj.order_details[i].del_person,
                doctor_name: doctor_name,
                person_Name: patient_name,
                delivery_Type: obj.order_details[i].description,
                estimation_Date: Date_Formate(obj.order_details[i].estimate_date),
                estimat_datere: obj.order_details[i].estimate_date,
                // pres_time:timeandsession,
                // pres_date:Date_Formate(obj.order_details[i].pres_date),
                estimation_Time: obj.order_details[i].estimate_time,
                financial_Year: obj.order_details[i].fin_year,
                status: status,
                uhid: obj.order_details[i].uhid
              });
            }
            if (obj.order_details[i].first_name != null) {
              pharmacy_helper.setcurrentinformation_pharrmacy(this.orderListArray)
            }
          }
        } else {
          this.noDataCurrent = true;
        }
      });
  }

  convertToAmPm(time) {
    if (time != undefined || null || "") {
      const [hours, minutes] = time.split(":");
      const hour = parseInt(hours);
      const period = hour >= 12 ? "PM" : "AM";
      const adjustedHour = hour % 12 || 12; // Convert 0 to 12
      return `${adjustedHour}:${minutes} ${period}`;
    }

  }
  DateFormate(data) {
    var Splitter = data.split("-");
    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
  }
  //rack_id and bin_id not came 
  orderView(estimateId, finYear, deliveryType, status, orderlist, uhid, token, pres_date, pres_time) {
  
    this.languageflag = false;
    this.amountvalue = 0;
    this.tokenflag = false;
    this.tokenshowflag = false;
    this.paytypreq = false;
    this.bill_generate = true;
    this.nomedicineflag = true;
    this.intakesessionflag = false;
    this.intake_desc = "";
    this.morning = 0;
    this.evening = 0;
    this.afternoon = 0;
    this.night = 0;
    this.drugDetailsArray = [];
    this.tokenno = token;
    this.estimate_date = orderlist.estimat_datere;
    this.language = "Tamil";
    console.log("type of tokennumber = " + typeof (this.tokenno))
    this.presdate = orderlist.pres_date;
    if (uhid != undefined) {
      this.uhid = uhid;
    }
    // if(this.viewbutton == "true"){
    // this.newEstimateFlag = ;
    // }
    console.log(" y " + JSON.stringify(orderlist))
    this.editbutton = true
    this.newEstimateFlag = false;
    if (status != "Completed") {
      this.cancel_flag = true;
    }
    this.updateBtnFlag = false;
    this.estimateId = estimateId;
    this.financialYear = finYear;
    this.deliveryType = deliveryType;
    this.status = status;
    var send_data = {
      pharma_id: this.pharmacyID,
      estimate_id: estimateId,
      fin_year: finYear,
      estimate_date: orderlist.estimat_datere,
    }
    var patname = orderlist.person_Name;
    console.log("send data = " + JSON.stringify(send_data));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gest", JSON.stringify(send_data),
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            if (obj.pres_drug_id != undefined || null || "") {
              this.pres_drug_id = obj.pres_drug_id;
              this.fromprescflag = true;
            }

            if (status == "Open") {
              this.status_buttonflag = true;
            } else {
              this.status_buttonflag = false;
            }
            var index = 0;
            console.log("Estimate Detailed View (Stringified)", JSON.stringify(obj))
            this.saveBtnFlag = true;
            this.auto_order = obj.auto_order;
            this.patient_detailsflag = false;
            this.retrivemr = false;
            this.intakesessionflag = true;
            this.retrivenameflag = false;
            this.update = true;
            var durval;
            var quantity;
            var dayduration;
            var sessionnum;
            var days;
            var period;
           
            if (token == undefined) {
              this.auto_order = "1"

            } else {
              this.auto_order = "0"
            }
            
            if (status != "Completed") {
              // this.tokenflag=true;
              this.statusFlag = true;

            } else {
              this.statusFlag = false;

              // this.tokenflag=false
            }

            if (status != "Completed" && token != undefined) {
              this.thermalspan = true;
              this.thermalspantoken = true;
            } else {
              this.thermalspan = false;
              this.thermalspantoken = false;
            }


            if (token != undefined && status != "Completed") {
              this.tokenshowflag = true
            } else {
              this.tokenshowflag = false
            }

            if (this.tokenshowflag == true) {
              this.tokenflag = true
            }


            if (obj.dr_first_name != undefined) {
              this.docsalflag = false;


              if (obj.dr_middle_name != undefined) {
                this.doctorName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              } else {
                this.doctorName = obj.dr_first_name + " " + obj.dr_last_name;
              }
            } else {
              this.docsalflag = false;
              this.doctorName = obj.dr_name;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }
            if (obj.gender != undefined) {
              this.clntGender = obj.gender;
            } else {
              this.clntGender = "";
            }
            if (this.clntGender == "Male" || this.clntGender == "M") {
              this.headergen = "M";

            } else if (this.clntGender == "Female" || this.clntGender == "F") {
              this.headergen = "F";
              this.clntSal = "Ms"
            } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }
            if (obj.salutation_desc != undefined) {
              this.clntSal = obj.salutation_desc;
            }
            else {
              this.clntSal = "";
            }
           
            if (obj.ip_op != undefined) {
              this.ipop = obj.ip_op
            }
            if (obj.mobile != undefined) {
              this.clntContNumber = obj.mobile;
            } else {
              this.clntContNumber = "";
            }
            if (patname != undefined) {

              this.patient_name = patname
              this.clientname = patname

            } else {
              if (obj.middle_name != undefined) {

                this.patient_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                this.clientname = this.clntSal + " " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);

              } else if (obj.first_name != undefined && obj.last_name != undefined) {

                this.patient_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                this.clientname = this.clntSal + " " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              } else {
                this.patient_name = "";
                this.clientname = "";
              }
            }
            
            if(obj.pres_drug_id != undefined){
              this.pres_id=obj.pres_drug_id
              this.rateplaneflag = true
              this.aftdeletflag == true
            }
            if (obj.client_reg_id != undefined || null || "") {
              this.client_reg_id = obj.client_reg_id;
            }
            if (obj.relation_id != undefined || null || "") {
              this.relation_id = obj.relation_id;
            }
            if (obj.sub_rel_id != undefined || null || "") {
              this.sub_rel_Id = obj.relation_id;
            }
            if (obj.bed_no != undefined) {
              this.Bed_no = obj.bed_no; 
            } else {
              this.Bed_no = "";
            }
            if (obj.ward_name != undefined) {
              this.Ward_name = obj.ward_name
            } else {
              this.Ward_name = "";
            }
            if (obj.admission_date != undefined) {
              this.admissiondate = obj.admission_date
            } else {
              this.admissiondate = ""
            }
            if (obj.admission_time != undefined) {
              this.admission_time = Time_Formate(obj.admission_time)
            } else {
              this.admission_time = ""
            }
            if (obj.clnt_address2 != null && obj.clnt_address2 != undefined && obj.clnt_address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.clnt_address1) + ",";
              this.clntAddress2 = encrypt_decript.Decript(obj.clnt_address2) + ",";
            } else if (obj.clnt_address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.clnt_address1) + ",";
            } else {
              this.clntAddress2 = encrypt_decript.Decript(obj.clnt_address2) + ",";
            }
            this.clntLocation = '';

            if (obj.clnt_location != undefined) {
              if (obj.clnt_location != "null") {
                this.clntLocation = obj.clnt_location + ",";
              } else {
                this.clntLocation = "";
              }
            }

            this.clntCity = '';
            if (obj.clnt_city != undefined && obj.clnt_city != null) {
              this.clntCity = obj.clnt_city + "-";
            }

            this.clntZipcode;
            if (obj.clnt_zipcode != undefined && obj.clnt_zipcode != null) {
              this.clntZipcode = encrypt_decript.Decript(obj.clnt_zipcode) + ",";
            }

            this.clntState = '';
            if (obj.phar_state != undefined && obj.phar_state != null) {
              this.clntState = obj.clnt_state + ".";
            }

            this.clntCountry = '';
            if (obj.clnt_country != undefined && obj.clnt_country != null) {
              this.country = obj.clnt_country + ".";
            }

            this.clntdob = obj.dob != null && obj.dob != "" && obj.dob != undefined && obj.dob != "" ? obj.dob : "";
            if (obj.bill_incl_pharma != undefined) {

              if (obj.bill_incl_pharma == "Excluding pharma") {
                this.bill_incl_pharmaFlag = false;
                this.bill_incl_pharma = "Excluding pharma"
              } else {
                this.bill_incl_pharmaFlag = true;
                this.bill_incl_pharma = "Including pharma"
              }
            } else {
              this.bill_incl_pharmaFlag = false;
              this.bill_incl_pharma = "Including pharma"
            }

            for (var i = 0; i < obj.drug_details.length; i++) {
              // 
              if (obj.drug_details[i].drug_name != "") {
              
                index = index + 1;
                obj.drug_details[i].index=index
                var item = {
                  name: obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity
                };
                var senddrug = obj.drug_details[i].drug_name;
                var dprice;
                var amount;
                var dpricewithcal;
                if(obj.drug_details[i].short_name != undefined){
                  obj.drug_details[i].short_name = obj.drug_details[i].short_name
                }else{
                  obj.drug_details[i].short_name =""
                }
                if(obj.drug_details[i].drug_name != undefined){
                  obj.drug_details[i].drug_name = obj.drug_details[i].drug_name
                }else{
                  obj.drug_details[i].drug_name =""
                }
                if (obj.drug_details[i].price != undefined) {

                  obj.drug_details[i].stripprice=obj.drug_details[i].price;
                  const perUnit = obj.drug_details[i].per_unit === undefined || " " || null ? parseInt(obj.drug_details[i].per_unit) : 1;
                  dpricewithcal = (parseFloat(obj.drug_details[i].price) / perUnit).toFixed(2);
                  obj.drug_details[i].dpricewithcal=dpricewithcal
                  this.drugprice_value = obj.drug_details[i].price === undefined ? 0.00 : parseFloat(obj.drug_details[i].price);
                  dprice = this.drugprice_value.toFixed(2).toString();
                  amount = dpricewithcal * parseInt(obj.drug_details[i].quantity)
                  obj.drug_details[i].amount=amount;
                }
                else {
                  const perUnit = obj.drug_details[i].per_unit === undefined || " " || null ? 1 : parseInt(obj.drug_details[i].per_unit);
                  this.priceundefined = obj.drug_details[i].price === undefined || "" || null ? 0 : parseInt(obj.drug_details[i].price);
                  dpricewithcal = (this.priceundefined / perUnit).toFixed(2);
                  obj.drug_details[i].dpricewithcal=dpricewithcal
                  amount = dpricewithcal * parseInt(obj.drug_details[i].quantity)
                  obj.drug_details[i].amount=amount
                }
                console.log("price =" + parseFloat(obj.drug_details[i].price))
                console.log(" per unit =" + parseInt(obj.drug_details[i].per_unit))
                console.log("dprice =" + dprice)
                this.amountvalue += parseFloat(amount)
                console.log("amount" + amount)
                console.log("bill amount =" + JSON.stringify(obj.drug_details[i]))
                this.rackId = obj.drug_details[i].rack_id;
                this.binId = obj.drug_details[i].bin_id;
                var color_req;
                if (obj.drug_details[i].batch_no != undefined) {
                  color_req = true;
                  obj.drug_details[i].color_req=true;
                }
                // Assuming this.discount is a string
                // Now this.discount is a number
                this.checkpresc_drug_qty = parseInt(obj.drug_details[i].quantity)
                this.checkpres_batch = obj.drug_details[i].batch_no;
                this.pres_price = dprice;
                this.amount_to_del = amount
                if (obj.drug_details[i].batch_no != undefined) {
                  obj.drug_details[i].batch_required = { "border-color": "rgb(250,40,2)" }
                } else {
                  obj.drug_details[i].batch_required = { "border-color": "rgb(250,40,2)" }
                }
                if (obj.drug_details[i].morning) {
                  this.morning = obj.drug_details[i].morning
                }
                if (obj.drug_details[i].afternoon) {
                  this.afternoon = obj.drug_details[i].afternoon
                }
                if (obj.drug_details[i].evening) {
                  this.evening = obj.drug_details[i].evening
                }
                if (obj.drug_details[i].night) {
                  this.night = obj.drug_details[i].night
                }
                if (obj.drug_details[i].days) {
                  this.daydur = obj.drug_details[i].days
                }

                if (obj.drug_details[i].period) {
                  this.period = obj.drug_details[i].period
                }
                if (this.period == "days" || "day" || 'day(s)') {
                  this.period = "day(s)"
                } else if (this.period == "weeks" || "week" || 'week(s)') {
                  this.period = "week(s)"
                } else if (this.period == "months" || "month" || 'month(s)') {
                  this.period = "month(s)"
                }
                
                if (obj.drug_details[i].days != undefined && obj.drug_details[i].period != undefined) {
                  days = obj.drug_details[i].days;
                  period = obj.drug_details[i].period;
                  if (obj.drug_details[i].period == "days" || "day") {
                    durval = 1;
                  } else if (obj.drug_details[i].period == "weeks" || "week") {
                    durval = 7;
                  } else if (obj.drug_details[i].period == "months" || "month") {
                    durval = 30;
                  }
                  if (obj.drug_details[i].days != 0) {
                    dayduration = parseInt(days) * parseInt(durval)
                    sessionnum = parseInt(obj.drug_details[i].afternoon) + parseInt(obj.drug_details[i].morning) + parseInt(obj.drug_details[i].evening) + parseInt(obj.drug_details[i].night)
                    if (obj.drug_details[i].drug_typetxt == "5" || obj.drug_details[i].drug_typetxt == "14" || obj.drug_details[i].drug_typetxt == "26" || obj.drug_details[i].drug_typetxt == "44") {
                      quantity = 1
                    } else {
                      quantity = parseInt(sessionnum) * parseInt(dayduration)
                    }
                  } else if (obj.drug_details[i].days == 0) {
                    quantity = obj.drug_details[i].quantity
                  }
                } else {
                  quantity = obj.drug_details[i].quantity
                }

                if (obj.drug_details[i].intake_desc != undefined) {
                  this.intake_desc = obj.drug_details[i].intake_desc
                }
                if (obj.drug_details[i].drug_intake != undefined) {
                  this.drug_intake = obj.drug_details[i].drug_intake
                }
                if (obj.drug_details[i].rec_item_id != undefined) {
                }
               
                this.prescripted_drug_det.push({
                  pharma_id: this.pharmacyID,
                  drug_id: obj.drug_details[i].drug_id,
                  type_name: obj.drug_details[i].type_name,
                  drug_name: obj.drug_details[i].drug_name,
                  drug_disp_name: obj.drug_details[i].short_name + " " + obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity,
                  tempquantity: "",
                  amount: parseFloat(amount).toFixed(2),
                  totalamount: parseFloat(amount).toFixed(2),
                  // price: obj.drug_details[i].price,
                  stripprice: obj.drug_details[i].price || 0,
                  price: dpricewithcal,
                  discount: obj.drug_details[i].discount,
                  per_unit: obj.drug_details[i].per_unit,
                  prod_disc: "",
                  batch_no: obj.drug_details[i].batch_no,
                  amt_bdgst: "",
                  cgst: obj.drug_details[i].cgst,
                  sgst: obj.drug_details[i].sgst,
                  expiry_date: obj.drug_details[i].expiry_date,
                  rec_item_id: obj.drug_details[i].rec_item_id,
                  index: index,
                  gen_name: "",
                  batch_required: obj.drug_details[i].batch_required,
                  batcharray: "",
                  show_batcharray: false,
                  quantityDisable: true,
                  rack_id: obj.drug_details[i].rack_id,
                  bin_id: obj.drug_details[i].bin_id,
                  colo_required: color_req,
                  morning: obj.drug_details[i].morning,
                  afternoon: obj.drug_details[i].afternoon,
                  evening: obj.drug_details[i].evening,
                  night: obj.drug_details[i].night,
                  intake: obj.drug_details[i].intake,
                  days: this.daydur,
                  period: this.period,
                  intake_desc: this.intake_desc,
                  drug_intake: this.drug_intake,
                  sales_price: obj.drug_details[i].sales_price,
                  cost_price: obj.drug_details[i].cost_price,
                  mrp: dpricewithcal,
                  mor_lable: false,
                  quan_lable: true,
                })
                if (obj.token != undefined || null || "") {
                  this.tokenno = obj.token
                }
                var batchflag = false;
                if (obj.drug_details[i].batch_no != undefined) {
                  batchflag = true;
                }

                if (obj.drug_details[i].expiry_date != undefined) {
                  var expdate = obj.drug_details[i].expiry_date.split("-");
                  obj.drug_details[i].expiry_date = expdate[1] + "-" + expdate[0];
                  console.log("1" + expdate[1]);
                  console.log("2" + expdate[0]);
                }
           
                if(this.pres_id == undefined){
                  // this.sesssionBaseChange(obj.drug_details[i])
                  this.drugDetailsArray.push({
                    pharma_id: this.pharmacyID,
                    drug_id: obj.drug_details[i].drug_id,
                    type_name: obj.drug_details[i].type_name,
                    drug_name: obj.drug_details[i].drug_name,
                    drug_disp_name: obj.drug_details[i].short_name + " " + obj.drug_details[i].drug_name,
                    quantity: obj.drug_details[i].quantity,
                    tempquantity: "",
                    amount: parseFloat(amount).toFixed(2),
                    totalamount: parseFloat(amount).toFixed(2),
                    // price: obj.drug_details[i].price,
                    stripprice: obj.drug_details[i].price || 0,
                    price: dpricewithcal,
                    discount: obj.drug_details[i].discount,
                    per_unit: obj.drug_details[i].per_unit,
                    prod_disc: "",
                    batch_no: obj.drug_details[i].batch_no,
                    amt_bdgst: "",
                    cgst: obj.drug_details[i].cgst,
                    sgst: obj.drug_details[i].sgst,
                    expiry_date: obj.drug_details[i].expiry_date,
                    rec_item_id: obj.drug_details[i].rec_item_id,
                    index: index,
                    gen_name: "",
                    batch_required: obj.drug_details[i].batch_required,
                    batcharray: "",
                    show_batcharray: false,
                    quantityDisable: true,
                    rack_id: obj.drug_details[i].rack_id,
                    bin_id: obj.drug_details[i].bin_id,
                    colo_required: color_req,
                    morning: obj.drug_details[i].morning,
                    afternoon: obj.drug_details[i].afternoon,
                    evening: obj.drug_details[i].evening,
                    night: obj.drug_details[i].night,
                    intake: obj.drug_details[i].intake,
                    days: this.daydur,
                    period: this.period,
                    intake_desc: this.intake_desc,
                    drug_intake: this.drug_intake,
                    sales_price: obj.drug_details[i].sales_price,
                    cost_price: obj.drug_details[i].cost_price,
                    mrp: dpricewithcal,
                    show_intake: obj.drug_details[i].show_intake,
                    drug_typetxt: obj.drug_details[i].drug_typetxt,
                    //no of days
                    day_txt: obj.drug_details[i].days,
                    //day or week in letters 
                    daydur_txt: this.period,
                    //minutestime_duration
                    dure_txt: obj.drug_details[i].time_duration,
                    //after food or before food
                    afterfood_txt: obj.drug_details[i].drug_intake,
                    //intakesession
                    intake_txt: obj.drug_details[i].intake_desc,
                    mor_lable: false,
                    quan_lable: true,
                  });
                
                }  
                if(obj.disc_type != undefined && obj.disc_type != "1"){
                  this.discountType = obj.disc_type
                  this.discountChange(this.discountType)
                }
                // if(obj.pres_drug_id != undefined){this.getDiscTypes()}
                // this.trailchangeMedicineName(senddrug, 'medicine')
                this.selected_item.push(obj.drug_details[i])
                this.getItemPrices(obj.drug_details[i])
              
                
              }
            }
          };

          if (obj.bill_amount != undefined) {
            console.log("Drug Details Arrays orderView", this.drugDetailsArray);
          }
        });
  }

  Duration() {
    var duration = this.dayText + " " + this.dayDuration;
    return duration
  }

  changeIntake(drug_detailsarray) {//Intake change
    if (drug_detailsarray.afterfood_txt == "After food" || drug_detailsarray.afterfood_txt == "Before food") {
      drug_detailsarray.dure_show = false;
      drug_detailsarray.dure_require = true;
      drug_detailsarray.dure_txt = "30";
    } else {
      drug_detailsarray.dure_show = true;
      drug_detailsarray.dure_require = false;
      drug_detailsarray.dure_txt = undefined;
    }
  }

  getIntakeName(drug_detailsarray) {
    var send_data = {
      product_id: drug_detailsarray.drug_id
    }
    console.log("getINtake send data = " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("this.getIntakeArray" + JSON.stringify(obj));
          for (var i = 0; i < this.getIntakeArray.length; i++) {
            if (obj.intake == this.getIntakeArray[i].drug_int_id) {
              drug_detailsarray.intake_txt = this.getIntakeArray[i].description;
              drug_detailsarray.intakeid = this.getIntakeArray[i].drug_int_id;
              this.intake_id = this.getIntakeArray[i].drug_int_id;
            }
          }

          if(this.pres_id == undefined){
          this.sesssionBaseChange(drug_detailsarray);
          }
        })

  }

  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("GET SESSION S" + JSON.stringify(obj));
          this.getIntakeArray = obj.drug_intake;
          this.intake = "Thrice a day";

        },
        error => {
        }
      )
  }

  sesssionBaseChange(drug_detailsarray) {
    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))


    this.alternatsession = true;
    // drug_detailsarray.day_txt=drug_detailsarray.days
    // if (drug_detailsarray.drug_id == undefined || "" || null) {

    //   this.toastr.error(Message_data.selectMedicine);
    // }
    // drug_detailsarray.intake=intake.drug_int_id
    for (var j = 0; j < this.getIntakeArray.length; j++) {

      if (drug_detailsarray.intake_txt == this.getIntakeArray[j].description) {

        drug_detailsarray.intake = this.getIntakeArray[j].drug_int_id
      } else {
        if (drug_detailsarray.intake_desc == this.getIntakeArray[j].description) {
          drug_detailsarray.intake = this.getIntakeArray[j].drug_int_id
        }
      }
    }
    //////

    if (drug_detailsarray.intake_txt == "Morning only") {

      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Morning only"
        this.sessionhide = false;
        this.sessionhrs = true;

        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.drug_detailsarray = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.drug_detailsarray = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }



    } else if (drug_detailsarray.intake_txt == "Afternoon only") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;

        this.intakesession = "Afternoon only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }


    } else if (drug_detailsarray.intake_txt == "Evening only") {

      if (drug_detailsarray.show_intake == "1") {


        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Evening only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";
        }
        else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Night only") {


      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Night only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    } else if (drug_detailsarray.intake_txt == "Morning & Afternoon") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Afternoon"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Morning & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }




          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Thrice a day") {
      if (drug_detailsarray.show_intake == "1") {

        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }


          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Four times a day") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Four times a day"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }




          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Evening & Night"
        this.sessionhide = false;
        this.sessionhrs = true;
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
      || drug_detailsarray.intake_txt == "Every 12 hours")) {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = true;
        this.sessionhrs = false;
        if (drug_detailsarray.intake_txt == "Every 6 hours") {
          this.intakesession = "Every 6 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 8 hours") {
          this.intakesession = "Every 8 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 12 hours") {
          this.intakesession = "Every 12 hours";
        } else {
          this.intakesession = "";
        }
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.quan_lable = false;
        drug_detailsarray.morning_dis = false;

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;


        drug_detailsarray.morningID = true;
        this.afterID = true;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = true;
        drug_detailsarray.after_eventxt = true;
        drug_detailsarray.morning_req = true;
        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Stat") {
      this.intakesession = "stat";
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }

        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;
      drug_detailsarray.intake_show = true;
      drug_detailsarray.dure_show = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = true;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Once a day") {
      this.intakesession = "Once a day"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "SOS") {
      this.intakesession = "SOS"
      
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";


      } else {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
      || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Twice a week" || drug_detailsarray.intake_txt == "Once a month") {
      this.alternatsession = false;
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.intake_txt == "Alternative day") {
        this.intakesession = "Alternative day";
      }
      else if (drug_detailsarray.intake_txt == "Once a week") {
        this.intakesession = "Once a week";
      }
      else if (drug_detailsarray.intake_txt == "Once in two weeks") {
        this.intakesession = "Once in two weeks";
      }
      else if (drug_detailsarray.intake_txt == "Once a month") {
        this.intakesession = "Once a month";
      } else if (drug_detailsarray.intake_txt == "Twice a week") {
        this.intakesession = "Twice a week";
      }
      else {
        this.intakesession = "";
      }
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "2.5";
            drug_detailsarray.night = "2.5";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "5";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "5";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "10";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "10";
          }
        }
        // drug_detailsarray.morning = "5";
        //  drug_detailsarray.afternoon = "5";
        //  drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "5";
      }
      drug_detailsarray.drug_detailsarray = false;

      drug_detailsarray.morningID = false;
      drug_detailsarray.mor_lable = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    }
    this.sessionqty(drug_detailsarray)
  }

  sessionqty(drug) {
    console.log(" Session drug  1 = " + JSON.stringify(drug))
    

    var medqty;
    var durationinnum;
    var totalqty;
    var morning; var afternoon; var evening; var night;

    console.log(" =---- " + JSON.stringify(drug.day_txt))
    if (drug.day_txt != undefined) {
      if (drug.day_txt != "") {


        if (drug.drug_typetxt == "5" || drug.drug_typetxt == "14" || drug.drug_typetxt == "26" || drug.drug_typetxt == "44") {

          medqty = 1
          totalqty = 1
        }
        else if (drug.intake_txt == "Morning only") {


          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          }
          else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseInt(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          }
          else {
            evening = 1
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }


          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else if (drug.intake_txt == "Afternoon only") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          }
          else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)



        }
        else if (drug.intake_txt == "Evening only") {


          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Night only") {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)


        } else if (drug.intake_txt == "Morning & Afternoon") {


          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Morning & Evening") {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)


        }
        else if (drug.intake_txt == "Morning & Night") {
          console.log("FORM drug value = " + JSON.stringify(drug))
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {

            evening = parseFloat(drug.evening)

          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Afternoon & Night") {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Afternoon & Evening") {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else if (drug.intake_txt == "Morning,Afternoon & Evening") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Thrice a day") {



          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }
          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)


        }
        else if (drug.intake_txt == "Morning,Afternoon & Night") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)


        }
        else if (drug.intake_txt == "Morning,Afternoon,Evening & Night") {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else if (drug.intake_txt == "Four times a day") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else if (drug.intake_txt == "Afternoon,Evening & Night") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }
          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }

        }
        else if (drug.intake_txt == "Evening & Night") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }
          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if ((drug.intake_txt == "Every 6 hours" || drug.intake_txt == "Every 8 hours"
          || drug.intake_txt == "Every 12 hours")) {

          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }

          if (drug.intake_txt == "Every 6 hours") {
            // for (let v = 0; v < 24; v++) {
            //   if (v * 6 == 24) {
            //     medqty = v
            //   }
            // }
            medqty =  medqty * 4;
          }
          else if (drug.intake_txt == "Every 8 hours") {
            // for (let v = 0; v < 24; v++) {
            //   if (v * 8 == 24) {
            //     medqty = v
            //   }
            // }
            medqty =  medqty *  3;
          }
          else if (drug.intake_txt == "Every 12 hours") {
            // for (let v = 0; v < 24; v++) {
            //   if (v * 12 == 24) {
            //     medqty = v
            //   }
            // }
            medqty =  medqty *  2;
          }

          console.log("drug_text " + drug.day_txt)
          if(durationinnum != undefined) {
            totalqty = parseInt(durationinnum) * medqty
          } else {
            totalqty = medqty
          }
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else if (drug.intake_txt == "Stat") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Once a day") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)


        }
        else if (drug.intake_txt == "SOS") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }

          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          }
          else {
            night = parseFloat(drug.night)
          }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)

        }
        else if (drug.intake_txt == "Alternative day" || drug.intake_txt == "Twice a week" || drug.intake_txt == "Once a week"
          || drug.intake_txt == "Once in two weeks" || drug.intake_txt == "Once a month") {
          if (drug.morning == "1/2") {
            morning = parseFloat("0.5")
          }
          else if (drug.morning == "1/4") {
            morning = parseFloat("0.25")
          }
          else if (drug.morning == "1/3") {
            morning = parseFloat("0.33")
          } else if (drug.morning == "0") {
            morning = parseInt("0")
          } else if (drug.morning == "1") {
            morning = 1
          } else {
            morning = parseFloat(drug.morning)
          }


          if (drug.afternoon == "1/2") {
            afternoon = parseFloat("0.5")
          }
          else if (drug.afternoon == "1/4") {
            afternoon = parseFloat("0.25")
          }
          else if (drug.afternoon == "1/3") {
            afternoon = parseFloat("0.33")
          } else if (drug.afternoon == "0") {
            afternoon = parseInt("0")
          } else if (drug.afternoon == "1") {
            afternoon = 1
          }
          else {
            afternoon = parseFloat(drug.afternoon)
          }

          if (drug.evening == "1/2") {
            evening = parseFloat("0.5")
          }
          else if (drug.evening == "1/4") {
            evening = parseFloat("0.25")
          }
          else if (drug.evening == "1/3") {
            evening = parseFloat("0.33")
          } else if (drug.evening == "0") {
            evening = parseInt("0")
          } else if (evening == "1") {
            evening = 1
          }
          else {
            evening = parseFloat(drug.evening)
          }
          // else{
          //   evening=1
          // }

          if (drug.night == "1/2") {
            night = parseFloat("0.5")
          }
          else if (drug.night == "1/4") {
            night = parseFloat("0.25")
          }
          else if (drug.night == "1/3") {
            night = parseFloat("0.33")
          } else if (drug.night == "0") {
            night = parseInt("0")
          } else if (drug.night == "1") {
            night = 1
          } else {
            night = parseFloat(drug.night)
          }
          // else{
          //   night=1
          // }
          medqty = morning + afternoon + evening + night;
          console.log("morning = " + drug.morning);
          console.log("afternoon " + drug.afternoon)
          console.log("" + drug.evening)
          console.log("night = " + drug.night)
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          console.log("drug_text " + drug.day_txt)
          totalqty = parseInt(durationinnum) * medqty
          console.log("duration minimum = " + durationinnum);
          console.log("med qty = " + medqty)
          console.log("totalqty" + totalqty)
        }
        else {
          medqty = 0
          totalqty = 0
        }
          
          drug.quantity = totalqty;
          this.createRow(drug)
       
      }

    }

  }

  focusFunction(e, drug) {
    console.log("drug details = " + JSON.stringify(drug))
    if (drug.drug_id == "") {
      if ((drug.intake_txt == "Alternative day" || drug.intake_txt == "Once a week"
        || drug.intake_txt == "Once in two weeks" || drug.intake_txt == "Once a month") && drug.drug_id != "") {

        if (e.target.value == '') {
          this.toastr.error(Message_data.fillIntk);
          e.target.focus();
        }
      }
      else {
        if ((e.target.value == '' || e.target.value == '0') && drug.drug_id != "") {
          this.toastr.error(Message_data.fillIntk);
          e.target.focus();
        }
      }
    }
  }
  optionsPopover() {
    const dialogRef = this.dialog.open(FilterpageComponent, {
      width: '25%',
      height: '180px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.currentEstimates(data)
      }
    });
  }

  backButton() {

    if (this.new == 'true') {
      this.newbutton = true;
    }
    this.thermalspan = false;
    this.thermalspantoken = false;
    this.printbutton = false;
    this.editbutton = false;
    this.cancel_flag = false;
    this.newEstimateFlag = true;
    this.updateBtnFlag = true;
    this.saveBtnFlag = true;
    this.tokenflag = false;
    this.intakesessionflag = false;
    this.search_type = "name";
    this.searchtype(this.search_type)
    this.totalAmount = "0.00";
    this.prodDiscount = "0.00";
    this.amountDicount = "0.00";
    this.paidAmount = "0.00";
    this.remainBalance = "0.00";
    this.pres_drug_id = "";
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.currentEstimates(this.pharmacyID);
  }

  back_Nav() {
    this.tokenflag = false
    this.messageservice.sendMessage("pharmahome");
    this.thermalspan = false;
    this.thermalspantoken = false;
  }

  createOrder() {
    this.auto_order = "1"
    this.printbutton = true;
    this.retrivenameflag = true;
    this.patient_detailsflag = true;
    this.paytypreq = true;
    this.status_buttonflag = true;
    this.newEstimateFlag = false;
    this.saveBtnFlag = false;
    this.drugDetailsArray = [];
    this.patient_name = "";
    this.doctorName = '';
    this.fromprescflag = false;
    this.MedicneData = [];
    this.addNewDrugDetails();
    this.pres_id=undefined;
  }

  public patient_typefroname: string = "outpatient";
  userTypeSelect(page) {
    if (page == 'out') {
      this.ipop = "OP"
      this.patient_typefroname = "outpatient";
    } else {
      this.ipop = "IP"
      this.patient_typefroname = "inpatient";
    }
    this.AppType = page;
    this.inpatientFlag = this.AppType == "in" ? true : false;
    this.currentEstimates(this.pharmacyID);
  }

  changeType(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.userTypeSelect('out');
    } else {
      this.ynopt1 = false;
      this.userTypeSelect('in');
    }
  }

  languageChange() {
    if (this.language == "English") {
      this.translate.use('english');
      this.languagetype = true;

    }
    else if (this.language == "Tamil") {
      this.translate.use('tamil');

      this.languagetype = false;

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');

      this.languagetype = false;
    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.languagetype = false;

    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.languagetype = false;

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.languagetype = false;


    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.languagetype = false;

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.languagetype = false;
    }
  }

  printcover() {
    var count = 0

    console.log("array " + JSON.stringify(this.drugDetailsArray))
    for (var i = 0; i < this.drugDetailsArray.length; i++) {

      if ((this.drugDetailsArray[i].drug_id == "" && this.drugDetailsArray[i].drug_name == "")) {
        count = + 1

      }
    }

    if (count == 0) {

      this.printsmallcov();
    } else {
      this.toastr.error(Message_data.removerow);
    }
  }

  printsmallcov() {
    console.log("print cover" + JSON.stringify(this.drugDetailsArray))
    let printContents, popupWin;
    if (this.languagetype == false) {
      printContents = this.printsmallcover.nativeElement.innerHTML;
    } else {
      printContents = this.printsmallcovereng.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=100,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<head>
  <title>Reports</title>
  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>

 
.covertablebig thead td {
  border: 1px solid black;

}
.covertablebig thead th {
  border: 1px solid black;

}
.covertablebig tfoot th {
  border: 1px solid black;
}
.covertablebig tbody td{
  border: 1px solid black;
}

  </style>
</head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">
   
     
    ${printContents}
  
    
 </body>
  </html>`
    );
    popupWin.document.close();

  }

  handleKeyUp(event: KeyboardEvent) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      this.getPatientListByName(this.patient_name)
    }
    if (key === 'Enter') {
      let patient;
      for (let i = 0; i < this.patientArray.length; i++) {
        if (this.patientArray[i].patient_name === this.patient_name) {
          const { patient_id, patient_name, relation_id, doc_name, client_reg_id, sub_rel_id, gender, age, mobile } = this.patientArray[i];
          patient = {
            patient_id,
            doc_name,
            descProductsInput: this.patient_name,
            relation_id,
            client_reg_id,
            sub_rel_id,
            gender,
            age,
            mobile
          };
          break;
        }
      }
      if (patient) {
        this.retrieveClient(patient)
        console.log("value = " + JSON.stringify(patient))
      }
    }
  }

  handleKeydoctorUp(event: KeyboardEvent) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      this.getReferralData(this.doctorName)
    }
    if (key === 'Enter') {
      let doctorname;

      for (let i = 0; i < this.ReferedDoctorArray.length; i++) {
        if (this.ReferedDoctorArray[i].docname === this.doctorName) {
          const { docname } = this.ReferedDoctorArray[i];
          doctorname = {
            docname
          };
          break;
        }
      }
      if (doctorname) {
        this.Select_refer_doctor(doctorname)
        console.log("value = " + JSON.stringify(doctorname))
      }
      this.docsalflag = false;
    }
  }

  handleKeymedicineUp(event: KeyboardEvent, gen_name, drug) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var gen_name
      this.changeMedicineName(gen_name, 'medicine')
    }
    if (key === 'Enter') {
      let medicine;
      for (let i = 0; i < this.MedicneData.length; i++) {
        if (this.MedicneData[i].name === gen_name) {

          const { name, quantity, generic } = this.MedicneData[i];
          medicine = {
            name,
            quantity,
            generic
          };
          break;
        }
      }
      if (gen_name) {
        this.selectMedicineItem(medicine, drug)
        console.log("value = " + JSON.stringify(medicine))
      }
    }
  }

}