import { ReturnStatement } from "@angular/compiler";

export class Admin_Helper{

    //setDiagBill
    static DiagBill;
    static setDiagBill(value:any){
        this.DiagBill=null;
        this.DiagBill=value;
    } 
    static getDiagBill(){
        return this.DiagBill;
    }
    static insurance_list
    static setinsuranceprovidelist(value:any){
        this.insurance_list=null;
        this.insurance_list=value;
    } 
    static getinsuranceprovidelist(){
        return this.insurance_list;
    }
}