<div *ngIf="apptdetails == 'min'">
    <div class="block1_grid">
        <!-- <mat-label class="matlabel">First Name<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="f_name" matInput/>
        </mat-label>
        <mat-label class="matlabel">Age(years)<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="Age_txt" matInput/>
        </mat-label>
        <mat-label class="matlabel">Gender<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="gen_txt" matInput/>
        </mat-label> -->
        <mat-label class="matlabel">Blood group<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="client_blood_group"
                matInput />
        </mat-label>
        <mat-label class="matlabel">Contact Number<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="contact_num"
                matInput />
        </mat-label>
        <mat-label class="matlabel">Appointment Type<br>
            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                [(ngModel)]="Appointment_type_txt" matInput />
        </mat-label>
    </div>
</div>

<div *ngIf="apptdetails == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Personal</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">First Name<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="f_name"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Middle Name<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="m_name"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Last Name<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="l_name"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="row">
                                            <div class="col-5">
                                                <mat-label class="matlabel">Age(years)<br>
                                                    <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                        [(ngModel)]="Age_txt" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-7">
                                                <mat-label class="matlabel">Gender<br>
                                                    <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                        [(ngModel)]="gen_txt" matInput />
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>                        
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Blood group<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="client_blood_group" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Contact Number<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="contact_num"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Appointment</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Appointment Type<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="Appointment_type_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Treatment Type<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="Treate_type"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Specialization<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="specialization_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div [hidden]="appoint_div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label [hidden]="appoint_div" class="matlabel">Hospital Name<br>
                                            <select disableOptionCentering class="ipcss scpersonal_width" [hidden]="old_hosname" required
                                                [(ngModel)]="hospital_nametxt">
                                                <option disabled value="Select">Select</option>
                                                <option *ngFor="let name of secondhopistla_arry" value={{name.hosp_name}}>{{name.hosp_name}}
                                                </option>
                                            </select>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [hidden]="new_hospital"
                                                [(ngModel)]="hospital_name" matInput />
                                        </mat-label>
                                    </div>
                                    <div [hidden]="appoint_div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label [hidden]="appoint_div" class="matlabel">Location<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="location_txt"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div [hidden]="appoint_div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label [hidden]="appoint_div" class="matlabel">District / City<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="district_txt"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="appoint_div" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Check Availability</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-label class="matlabel">Date<br>
                                                    <input type="text" class="ipcss scpersonal_width" [hidden]="se_date1"
                                                        [readonly]="isReadonly()" [(ngModel)]="date_txt1" matInput />
                                                    <input type="date" class="ipcss_date scpersonal_width" [hidden]="se_date" id="appt_date"
                                                        (change)="Date_click(date_txt_first)" max="{{currentDate}}" [(ngModel)]="date_txt_first" #matInput>
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Session<br>
                                                    <select disableOptionCentering class="ipcss scpersonal_width" [hidden]="sess_drop" required
                                                        [(ngModel)]="session_drop">
                                                        <option value="Morning">Morning</option>
                                                        <option value="Afternoon">Afternoon</option>
                                                        <option value="Evening">Evening</option>
                                                    </select>
                                                    <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                        [hidden]="see_txt" [(ngModel)]="session_txt" matInput />
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="row">
                                            <mat-label class="matlabel">Starts From</mat-label>
                                            <div class="col-4" style="padding-right: 0px;">
                                                <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [hidden]="from_dis"
                                                    [(ngModel)]="f_time" matInput />
                                                <select disableOptionCentering class="ipcss scpersonal_width" [hidden]="form_main" required
                                                    [(ngModel)]="Start_time">
                                                    <option *ngFor="let name of starts_from_array" value="{{name}}">{{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4" [hidden]="form_main">
                                                <select [(ngModel)]="end_time" class="ipcss">
                                                    <option *ngFor="let name of ends_at_array" class="ipcss" value="{{name}}">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4" style="padding-left: 0px;">
                                                <select [(ngModel)]="time_session" class="ipcss">
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div class="row">
                                            <div class="col-4" style="padding-right: 0px;">
                                                <mat-label class="matlabel">Ends At<br>
                                                    <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                        [hidden]="to_dis" [(ngModel)]="to_time" matInput />
                        
                                                    <select disableOptionCentering class="ipcss scpersonal_width" [hidden]="to_main"
                                                        [(ngModel)]="Ends_at_from">
                                                        <option *ngFor="let name of End_from_array" value="{{name}}">{{name}}</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-4" [hidden]="to_main">
                                                <mat-label class="matlabel"><br>
                                                    <select [(ngModel)]="Ends_at_to" class="ipcss">
                                                        <option *ngFor="let name of end_to_array" value="{{name}}">{{name}}</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-4" style="padding-left: 0px;">
                                                <mat-label class="matlabel"><br>
                                                    <select [(ngModel)]="endtime_session" class="ipcss" class="ipcss">
                                                        <option>AM</option>
                                                        <option>PM</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="reason_txt" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Reason for cancellation</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div [hidden]="reason_txt" class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                        <textarea class="ipcss scpersonal_width reasonforcancel" [readonly]="isReadonly()"
                                            [(ngModel)]="Reason_cancel" #matInput></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <img [hidden]="AllotBtn" style="float: right;" src="../../../assets/ui_icons/buttons/Allot_button.svg" (click)="Allot_book()"
                            class="savebtn" />
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>