<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Book appointment</h5>
          </div>
          <div class="headerButtons">
            <div class="doctor_name" (click)=" filter()"><i
                style="width: 40px;height: 17px;position: relative;top: 3px;" class="fas fa-filter" filterview></i>
            </div>
            <img class="saveimgbtn_inpatinfo" (click)="SecOpnNext()"
              src="../../../assets/ui_icons/buttons/Next_button.svg" />
            <!-- changes -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="let doctor of doctorList; let i = index">
                <mat-list class="list_style boxlist" (click)="viewDoctorDetails(doctor.Prov_ID, i)"
                  style="position: relative;">
                  <div class="row">
                    <div class="col-12">
                      <div class="pro_image"> <img src="{{doctor.Prof_image}}"
                          onerror="this.src='./././assets/img/default.jpg'" class="profile_image"></div>
                    </div>
                    <div class="col-12">
                      <p class="app_names">
                        <span>{{"Name : "}}</span>{{doctor.Doctor_Name}}
                      </p>
                    </div>
                    <div class="col-12" *ngIf="!hospitalFlag">
                      <p class="app_names1">{{"Hospital : "}}
                        <span class="span_class" *ngFor="let hosp of doctor.Hospital">{{hosp.Hosp_name
                          + " - " + hosp.Hosp_Locat+" "}}</span>
                      </p>
                    </div>
                    <div class="col-12" *ngIf="!specialityFlag">
                      <p class="app_names"><span>{{"Exp : "}}</span>{{doctor.Work_Year + "
                        years"}}</p>
                    </div>
                    <div class="col-12" *ngIf="!specialityFlag">
                      <p class="app_names"><span >{{"Speciality
                          : "}}</span>{{doctor.Speciality}}</p>
                    </div>
                    <div class="col-12" *ngIf="!specialityFlag">
                      <p class="app_names"><span >{{"Gender :
                          "}}</span>{{doctor.gender1}}</p>
                    </div>
                  </div>
                  <img width="25" float-right height="25" src="../../../assets/img/correct.png" item-end
                    *ngIf="doctor.check" style="position: absolute;top: 5px; right: 4px;" />
                </mat-list>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12" *ngIf="secOpnDoctorList.length != 0">
            <table *ngIf="secOpnDoctorList.length" class="table table-nowrap table-sm dataTable  bill_edit_table">
              <thead>
                <tr>
                  <th>Doctor name</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Doctor of secOpnDoctorList; let i=index">
                  <td data-th="Doctor name">{{Doctor.Doc_Name}}</td>
                  <td data-th="Location">{{Doctor.Doc_Location}}</td>
                  <td data-th="Action"><a (click)="deleteSecOpnDoctorDetails(Doctor.Doc_ID)"><img
                        src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editicon"></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>