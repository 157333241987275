import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-access-rights',
  templateUrl: './access-rights.component.html',
  styleUrls: ['./access-rights.component.scss']
})
export class AccessRightsComponent implements OnInit {
  public usertype: string;
  public usertypelist: any = [];
  private hptl_clinic_id: string;
  private diag_centre_id: string;
  private pharma_id: string;
  public userList: any = [];
  public userid: string;
  public usertypedesc: string;
  public modulelist: any = [];
  public modulelist_back: any = [];
  private tempModules: any = [];
  private packageid: string;
  Accountheadarray: any[];
  private center_id: any;
  type_id: any;
  role_id: any;
  Accountheadname: any;
  masterurl: string;
  access_id: any;
  center_type: any;
  print: any;
  view: any;
  delete: any;
  module_id: any;
  create: any;
  edit: any;
  public retrivalarray: any = [];
  public centerName: string;

  constructor(public gservice: CommonDataService, public http: HttpClient, public router: Router, 
    public toastr: ToastrService) {

  }

  ngOnInit(): void {
    console.log("USER DETAILS "+JSON.stringify(Helper_Class.getInfo()))
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
    this.pharma_id = Helper_Class.getInfo().pharma_id;
    this.packageid = Helper_Class.getInfo().hptl_package;
    var data = {
      center_id: this.center_id,
      center_type: this.usertype
    }
    if (Helper_Class.getDepartments() == undefined) {
      this.getUserTypes();
    } else {
      this.usertypelist = Helper_Class.getUserTypes();
    }
  }

  getUserTypes() {
    var send_data = {
      hptl_clinic_id: this.hptl_clinic_id,
      package_id: this.packageid,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/gubh", send_data, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        this.usertypelist = [];
        if (obj.user_types != undefined && obj.user_types.length != 0) {
          this.usertypelist = obj.user_types;
          this.usertype = this.usertypelist[0].type_id;

          for (var i = 0; i < obj.modules.length; i++) {
            var create_flag = false, createval;
            if (obj.modules[i].create == "1") {
              create_flag = false;
              createval = "1"
            } else {
              create_flag = true;
              createval = "0"
            }
            var edit_flag = false, editval;
            if (obj.modules[i].edit == "1") {
              edit_flag = false;
              editval = "1"
            } else {
              edit_flag = true;
              editval = "0"
            }
            var delete_flag = false, deleteval;
            if (obj.modules[i].delete == "1") {
              delete_flag = false;
              deleteval = "1"
            } else {
              delete_flag = true;
              deleteval = "0"
            }
            var view_flag = false, viewval;
            if (obj.modules[i].view == "1") {
              view_flag = false;
              viewval = "1";
            } else {
              view_flag = true;
              viewval = "0";
            }
            var print_flag = false, printval;
            if (obj.modules[i].print == "1") {
              print_flag = false;
              printval = "1"
            } else {
              print_flag = true;
              printval = "0"
            }
            this.tempModules.push({
              user_type_id: obj.modules[i].user_type_id,
              module_id: obj.modules[i].module_id,
              module_desc: obj.modules[i].module_desc,
              create: createval,
              edit: editval,
              delete: deleteval,
              view: viewval,
              print: printval,              
            })
          }
        }
        // this.getUserList();
        this.getRolesByUserType();
      }, error => {
        this.toastr.error(Message_data.unableToFetchUsrtype);
      });
  }

  getUserList() {
    this.modulelist = [];
    if (this.tempModules.length != 0) {
      for (var i = 0; i < this.tempModules.length; i++) {
        if (this.tempModules[i].user_type_id == this.usertype) {
          var cflag, eflag, dflag, vflag, pflag;
          if (this.tempModules[i].create == "1") {
            cflag = false;
          } else {
            cflag = true;
          }
          if (this.tempModules[i].edit == "1") {
            eflag = false;
          } else {
            eflag = true;
          }
          if (this.tempModules[i].delete == "1") {
            dflag = false;
          } else {
            dflag = true;
          }
          if (this.tempModules[i].view == "1") {
            vflag = false;
          } else {
            vflag = true;
          }
          if (this.tempModules[i].print == "1") {
            pflag = false;
          } else {
            pflag = true;
          }
          this.modulelist.push({
            module_id: this.tempModules[i].module_id,
            module_desc: this.tempModules[i].module_desc,
            create: false,
            edit: false,
            view: false,
            delete: false,
            print: false,
            create_flag:cflag,
            edit_flag:eflag,
            view_flag:vflag,
            delete_flag:dflag,
            print_flag:pflag
          })
        }
      }
      this.retrivalarray = this.modulelist;
      Helper_Class.setUserModules(this.modulelist)
    }
    for (var i = 0; i < this.usertypelist.length; i++) {
      if (this.usertype == this.usertypelist[i].type_id) {
        this.usertypedesc = this.usertypelist[i].type_desc + "s"
      }
    }

    if (this.usertype == "2") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Doctor";

    } else if (this.usertype == "3") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Nurse";

    } else if (this.usertype == "4") {
      this.center_id = this.diag_centre_id;
      this.centerName = "Diagnosis";

    } else if (this.usertype == "5") {
      this.center_id = this.pharma_id;
      this.centerName = "Pharmacy";

    } else if (this.usertype == "6") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Physiotherapist";

    } else if (this.usertype == "7") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Front office";

    } else if (this.usertype == "8") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Admin";

    } else if (this.usertype == "9") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Dietician";

    } else if (this.usertype == "11") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Billing";

    } else if (this.usertype == "12") {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "OT admin";

    } else {
      this.center_id = this.hptl_clinic_id;
      this.centerName = "Hospital";
    }
  }

  getRolesByUserType() {
    this.getUserList();
    this.Accountheadarray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      center_type: this.centerName,
      center_id: this.hptl_clinic_id,
      user_type_id: this.usertype
    };

    this.http.post(ipaddress.getIp.toString() + 'adm/gru', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj from add role" + JSON.stringify(obj));
          console.log("requestData 978464654654578456", requestData)
          var Accounthead = obj.user_roles;
          for (var i = 0; i < Accounthead.length; i++) {
            this.Accountheadarray.push({
              role_id: Accounthead[i].role_id,
              Accountheadname: Accounthead[i].description,
              status: Accounthead[i].status
            })
          }
        }, error => {
          this.toastr.warning("error in fetch role")
        }
      )
    // this.getModuleByRole();
  }

  getModuleByRole() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      center_id: this.center_id,
      center_type: this.centerName,
      role_id: this.role_id
    };
    console.log("from full get requset data", JSON.stringify(requestData))
    this.http.post(ipaddress.getIp.toString() + 'adm/gra', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj1 = JSON.parse(JSON.stringify(response));
          var obj = obj1.module_access
          if (obj != undefined) {
            this.modulelist = [];
            this.getUserList();
            this.modulelist = Helper_Class.getUserModules();
            for (var i = 0; i < obj.length; i++) {
              for (var j = 0; j < this.modulelist.length; j++) {
                if (this.modulelist[j].module_id == obj[i].module_id) {
                  this.modulelist[j].access_id = obj[i].access_id;
                  if (obj[i].create == "1") {
                    this.modulelist[j].create = true;
                  } else {
                    this.modulelist[j].create = false;
                  }
                  if (obj[i].edit == "1") {
                    this.modulelist[j].edit = true;
                  } else {
                    this.modulelist[j].edit = false;
                  }
                  if (obj[i].view == "1") {
                    this.modulelist[j].view = true;
                  } else {
                    this.modulelist[j].view = false;
                  }
                  if (obj[i].delete == "1") {
                    this.modulelist[j].delete = true;
                  } else {
                    this.modulelist[j].delete = false;
                  }
                  if (obj[i].print == "1") {
                    this.modulelist[j].print = true;
                  } else {
                    this.modulelist[j].print = false;
                  }
                }
              }
            }
            console.log("MODULE AFTER CHANGE " + JSON.stringify(this.modulelist))
          }

        }, error => {
          this.toastr.warning("error in fetch role")
        }
      )
  }

  saveAccessRights() {
    var center_id;
    if (this.usertype == "4") {
      center_id = this.diag_centre_id;

    } else if (this.usertype == "5") {
      center_id = this.pharma_id;

    } else {
      center_id = this.hptl_clinic_id;
    }
    var users = [];
    if (this.userid == "All") {
      for (var i = 0; i < this.userList.length; i++) {
        users.push(this.userList[i].user_id);
      }
    } else {
      users.push(this.userid)
    }

    var send_data = {
      role_id: this.role_id,
      user_type: this.usertype,
      center_id: center_id,
      users: users,
      modules: this.modulelist,
      center_type: this.centerName,
    };
    console.log("send_data ========== " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/sra', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("to backend  obj", obj);
          console.log("to send_data", send_data);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.saveSuccess);
          }
        }, error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
}
