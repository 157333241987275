<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white " style="padding:7px 20px;">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Specimen Assertion</h5>
               </div>

               <div class="headerButtons" style="width: 50px; text-align: left;">
                  <mat-label class="matlabel">Date:&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <input type="date" class="ipcss" (change)="getSampleList(sampledate)" [(ngModel)]="sampledate"
                     #matInput>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <mat-label class="matlabel"> or&nbsp;&nbsp;&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <mat-label class="matlabel"> Duration:&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <select [(ngModel)]="filtduration" class="ipcss" (change)="getSampleList(sampledate)">
                     <option value="select">Select</option>
                     <option value="7">1 week</option>
                     <option value="30">1 month</option>
                     <option value="91">3 months</option>
                     <option value="183">6 months</option>
                     <option value="365">1 year</option>
                  </select>
               </div>

               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="saveSamples2()" class="saveimgbtn_inpatinfo" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <!-- <div class="row"> -->
            <div>
               <p class="nodata" [hidden]='sampleList.length!=0'>No test(s) found</p>
               <div class="container-fluid" *ngIf="sampleList.length">
                  <div class="row">
                     <div class="col-12">
                        <div>
                           <mat-accordion displayMode="flat" multi="false" class="mat-table">
                              <section matSort class="mat-elevation-z2 mat-header-row"
                                 style="background-color: #c1e6fb;">
                                 <span class="mat-header-cell">Mr.No</span>
                                 <span class="mat-header-cell">Patient Name</span>
                                 <span class="mat-header-cell">Bill Date & time</span>
                                 <span class="mat-header-cell">Doctor Name</span>
                                 <span class="mat-header-cell">Ward Name</span>
                                 <span class="mat-header-cell">Bed No</span>
                                 <!-- <span class="mat-header-cell">Assertion</span> -->
                              </section>

                              <mat-expansion-panel *ngFor="let list of sampleList">
                                 <mat-expansion-panel-header class="mat-row align-items-center"
                                    (click)="getSampleDetails(list)">
                                    <span class="mat-cell">{{list.mrno}}</span>
                                    <span class="mat-cell">{{list.patient_name}}</span>
                                    <span class="mat-cell">{{list.bill_date}} {{list.bill_time}}</span>
                                    <span class="mat-cell">{{list.dr_name}}</span>
                                    <span class="mat-cell">{{list.ward_name}}</span>
                                    <span class="mat-cell">{{list.bed_no}}</span>
                                    <!-- <span class="mat-cell">
                                       <button mat-icon-button matTooltip="Click to open menu"
                                          [matMenuTriggerFor]="menu">
                                          <mat-icon>more_vert</mat-icon>
                                       </button>
                                       <mat-menu #menu="matMenu">
                                          <button mat-menu-item>Wrong Order</button>
                                          <button mat-menu-item>Patient Fixed But Not Collected</button>
                                       </mat-menu>
                                    </span> -->
                                 </mat-expansion-panel-header>
                                 <div>
                                    <p class="nodata" [hidden]='sampleDetails_data.length!=0'>No test(s)
                                       found</p>
                                    <table *ngIf="sampleDetails_data.length">
                                       <thead>
                                          <tr>
                                             <th>Test No</th>
                                             <th style="width: 5%;">&nbsp;</th>
                                             <!-- <th>Test Name</th> -->
                                             <th>Test Name</th>
                                             <th>Specimen Type</th>
                                             <th>Tube Type</th>
                                             <th style="width: 12%;">Assertion</th>
                                             <th>Priority</th>
                                             <th>Outsource</th>
                                             <th style="width: 5%; "><input type="checkbox" (change)="selectAll()"
                                                   [(ngModel)]="isSelected">
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let data of sampleDetails_data">
                                             <td>{{data.test_no}}</td>
                                             <td><img *ngIf="data.priority == '1'"
                                                   src="../../../assets/ui_icons/red_flag_icon.png"
                                                   style="width: 25px;" />
                                                <img *ngIf="data.priority != '1'"
                                                   src="../../../assets/ui_icons/green_flag_icon.png"
                                                   style="width: 25px;" />
                                             </td>
                                             
                                             <!-- <td>{{data.test_type_name}}</td> -->
                                             <td>{{data.subtest_name}}</td>
                                             <td>
                                                <mat-label class="matlabel"></mat-label>
                                                <mat-select class="ipcss" multiple [(ngModel)]="data.selected_specimen"
                                                   (selectionChange)="getTubetype(data, data.selected_specimen);"
                                                   [disabled]="true">
                                                   <mat-option *ngFor="let specimen of data.sampletype_data"
                                                      value="{{specimen.specimen_type_id}}">
                                                      {{specimen.specimen_desc}}</mat-option>
                                                </mat-select>
                                             </td>
                                             <td>
                                                <div class="wrapper">
                                                   <div [style.background-color]="color.color_code"
                                                      *ngFor="let color of data.tube_color" class="box"
                                                      (click)="openDialog(color.tube_image)" (mouseout)="closeDialog()">
                                                      {{color.tube_size }}
                                                   </div>
                                                </div>
                                                <!-- {{data.tube_type}} -->
                                             </td>
                                             <td> 
                                                <mat-label class="matlabel">
                                                   <mat-select class="ipcss" disableOptionCentering [(ngModel)]="data.selected_reason">
                                                      <mat-option *ngFor="let asserts of assertarray" required value={{asserts}}>{{asserts}}</mat-option>
                                                   </mat-select>
                                                   <!-- <select disableOptionCentering class="ipcss" [(ngModel)]="data.selected_reason">
                                                      <mat-option *ngFor="let asserts of assertarray" required value={{asserts}}>{{asserts}}</mat-option> -->
                                                      <!-- <option value='Asserted'>Asserted</option>
                                                      <option value='Sample value low'>Sample value low</option>
                                                      <option value='Sample value high'>Sample value high</option>
                                                      <option value="Sample clot">Sample clot</option>
                                                      <option value="Wrong continer">Wrong container</option>
                                                      <option value="Sample heomolysed">Sample heomolysed</option>
                                                      <option value="Mislabled">Mislabled</option>
                                                      <option value="Sample lipemic">Sample lipemic</option>
                                                      <option value="Diluted sample">Diluted sample</option> -->
                                                   <!-- </select> -->

                                                </mat-label>
                                             </td>
                                             <td>
                                                <mat-select class="ipcss" [(ngModel)]="data.priority" [disabled]="true">
                                                   <mat-option value="0"> Normal </mat-option>
                                                   <mat-option value="1"> High </mat-option>
                                                </mat-select>
                                             </td>
                                             <td>
                                                <mat-label class="matlabel" *ngIf="data.outsource_flag">
                                                   <mat-select disableOptionCentering class="ipcss"
                                                      [(ngModel)]="data.selected_outsource" [disabled]="true">
                                                      <mat-option *ngFor="let outsource of data.outsource" required
                                                         value={{outsource.out_id}}>
                                                         {{outsource.out_desc}}</mat-option>
                                                   </mat-select>
                                                </mat-label>
                                             </td>
                                             <td style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                <mdb-checkbox [(ngModel)]="data.isSelected"
                                                   (change)="unselectAll(data.isSelected)"></mdb-checkbox>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <!-- <div class="row mt-4">
                                       <div class="col-2" *ngFor="let data of sampleDetails_data">
                                          <div class="wrapper">
                                             <div [style.background-color]="color.color_code" [matMenuTriggerFor]="menu"
                                                *ngFor="let color of data.tube_color" class="box">
                                                {{color.tube_size }}
                                             </div>
                                          </div>
                                          <mat-menu #menu="matMenu">
                                             <button mat-menu-item>Wrong Order</button>
                                             <button mat-menu-item>Patient Fixed But Not Collected</button>
                                          </mat-menu>
                                       </div>
                                    </div> -->
                                 </div>
                              </mat-expansion-panel>
                           </mat-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- </div> -->
            <div [hidden]="true">
               <div #idprint id="idprint">
                  <div class="container-fluid m-5">
                     <div class="row">
                        <div class="col-6">
                           <!-- <div class="justify-content-center" style="text-align: center;">
                               <h1 style="font-size: 16px; letter-spacing: 4px;  color: rgb(139, 139, 251);">
                                  {{sample_id}}</h1>
                            </div> -->
                           <div *ngFor="let id of sample_id"
                              style="width: 300px; border-style: solid; border-width: thin; border-radius: 10px; border-color: rgb(139, 139, 251); box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; margin-bottom: 15px;">
                              <div style="margin: 10px;" class="d-flex align-items-center justify-content-center">
                                 <ngx-barcode class="barcode" [bc-value]="id" [bc-display-value]="displayValue"
                                    [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                    [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                    [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                    [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                    [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                    [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>