import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate } from '../../../assets/js/common.js';
import { Router, ActivatedRoute } from '@angular/router';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-app-create-new',
  templateUrl: './app-create-new.component.html',
  styleUrls: ['./app-create-new.component.css']
})
export class AppCreateNewComponent implements OnInit {

  public booktimeMorn = [];
  public booktimeNoon = [];
  public booktimeEve = [];
  public relationFlag: boolean = false;
  public relationName;
  public relationsList: any;
  public payFlag: boolean;
  public n: number = 1;
  public treatementTypeUrl: string;
  public clntDetailedUrl: string;
  public otherRelDetUrl: string;
  public locationUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public doctorListUrl: string;
  public curDateUrl: string;
  public doctorDetailUrl: string;
  public apptBookUrl: string;
  public specializationUrl: string;
  public specializationList = [];

  public clntLocation: string;
  public clntCity: string;
  public clntCityDesc: string;
  public clntState: string;
  public clntStateDesc: string;
  public clntCountry: string;

  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public clntAge: string;
  public clntGender: string;
  public clntDOB: string;
  public clntSubrelID: string;
  public appType: string;
  public homecare: string;
  public currentYear: string;
  public currentDate: string;
  public currentMinDate;
  public doctorProfileImg: string;
  public tempFee: string;
  public typeorTime: string;
  public appTypeTokenFlag: Boolean;
  public appTypeTimeFlag: Boolean;
  public apptType: string;
  public hospitalID: string;
  public hospSltLocation: string;
  public hospSltFee: string;
  public clntMobile: string;
  public clntOccupation: string;
  public payType: string;

  public homecareAppDate;
  public homecareAppTime;
  public hospitalName;
  public relationship;
  public treatementType;
  public visitSession;
  public homecareVisitSession;
  public visitTime;
  public tokenorTime;
  public timeOfAppt;
  public clntSymptom;
  public dataForApptBook;
  public clientRegId;
  public selfRelationData;
  public otherRelationData;
  public relationList = [];
  public treatmentList = [];

  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public clntCountryList = [];

  public doctorList = [];
  public docUnavailableData = [];
  public homecareTableData = [];
  public homecareSessionData = [];
  public doctorDetailTableData = [];
  public hospitalListData = [];
  public visitingSessionData = [];
  public homecareTimeList = [];
  public homecareSessionTableData = [];
  public homecareAppt = [];
  public homecareSendData;

  public doctorSearchFlag: boolean;
  public doctorListNoData: boolean;
  public doctorAvailableFlag: boolean;
  public doctorNonAvailableFlag: boolean;
  public bookFlag: boolean;
  public homecareFlag: Boolean;
  public cashFlag: boolean;
  public onlineFlag: boolean;
  public insuranceFlag: boolean;

  public hospitalFlag: boolean;
  public splID: string;
  public splName: string;
  public doctorID: string;
  public doctorName: string;
  public doctorQualif: string;
  public currentTime: string;
  public appointmentDate;
  public timeList;
  public timeListData;

  public splFlag: boolean;
  public doctorFlag: boolean;
  public doctorListFlag: boolean;
  public referredDoctorName: string;
  public referredDoctorList = [];

  public clntCountryDesc: string;
  public clntCountryID: string;
  public locationNameByUrl: string;
  public doctorFirstName: string;
  public nextBtnFlag;
  public docTreatementReadonlyFlag: boolean;
  public docTreatementFlag: boolean;
  public screensizeBased;

  public symptomData = [];
  public dispSymptomList = [];
  public SymptomList = [];
  public selectedSymptoms = [];
  public locationID;

  constructor(public messageservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    Client_Helper.setSearchByHosptial("search_hospital");
    this.treatementTypeUrl = ipaddress.getIp + "usercontroller/getmedicare/";
    this.clntDetailedUrl = ipaddress.getIp + "usercontroller/clientdet/";
    this.otherRelDetUrl = ipaddress.getIp + "appointment/clnrelweb/";
    this.specializationUrl = ipaddress.getIp + "usercontroller/getspecialization";
    this.doctorListUrl = ipaddress.getIp + "search/doctor/";
    this.curDateUrl = ipaddress.getIp + "adm/curdate";
    this.doctorDetailUrl = ipaddress.getIp + "search/doctorbyid/";
    this.apptBookUrl = ipaddress.getIp + "appointment/booking";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";

    this.splFlag = false;
    this.doctorFlag = true;
    this.doctorListFlag = true;
    this.bookFlag = false;
    this.cashFlag = true;
    this.onlineFlag = true;
    this.insuranceFlag = true;
    this.payFlag = false;
    this.appType = "New";
  }

  ngOnInit(): void {
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.doctorSearchFlag = false;
      this.clientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    } else if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.clientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
      this.otherRelationData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    }

    if (Client_Helper.getSearchByHosptial() == "search_hospital1") {
      this.doctorSearchFlag = false;
    } else {
      this.clntLocation = this.gservice.Client_login_detail_data.Client_Location;
      this.getCountries("0");
      this.doctorSearchFlag = true;
    }
    this.homecare = Client_Helper.getHomecare();
    this.homecareFlag = this.homecare == "1" ? true : false;
    this.clntLocationList = [];
    this.getCurrentDate();
    this.getRelationList();
    this.getTreatTypeMasterData();
    this.getSpecializationMasterData();
  }

  getReferralDoctorList(e) {
    this.referredDoctorList = [];
    this.referredDoctorName = e.target.value.toString();
    if (this.referredDoctorName != "" && this.referredDoctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doctorName: this.referredDoctorName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            var objReferredDocList = obj.doctors;

            if (objReferredDocList != undefined) {
              for (var i = 0; i < objReferredDocList.length; i++) {
                var docname;
                if (objReferredDocList[i].middle_name != undefined) {
                  docname = objReferredDocList[i].first_name + " " + objReferredDocList[i].middle_name + " " + objReferredDocList[i].last_name;
                } else {
                  docname = objReferredDocList[i].first_name + " " + objReferredDocList[i].last_name;
                }
                this.referredDoctorList.push(docname);
              }
            }
            this.referredDoctorList = this.referredDoctorList.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.referredDoctorName.toLowerCase()) > -1;
            }.bind(this));
          },error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.referredDoctorList = [];
    }
  }

  sltReferDoctor(item) {
    this.referredDoctorName = item;
    this.referredDoctorList = [];
  }

  sltSpecialization(splid, splname) {
    this.splID = splid;
    this.splName = splname;
    this.splFlag = true;
    this.doctorListFlag = false;
    this.getDoctors();
  }

  revertSelctedSpl() {
    this.splID = undefined;
    this.splName = undefined;
    this.splFlag = false;
    this.doctorFlag = true;
    this.doctorListFlag = true;
    this.doctorList = [];
  }

  revertSelctedDoctor() {
    this.doctorListFlag = false;
    this.doctorFlag = true;
  }

  selectedDoctor(docid, docname, qualif) {
    this.doctorID = docid;
    this.doctorName = docname;
    this.doctorQualif = qualif;
    this.doctorFlag = false;
    this.doctorListFlag = true;
    this.getDoctorDetails(this.currentDate, this.currentTime)
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        this.currentDate = Date_Formate(data.json().current_date);
        var current_date1 = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.currentYear = data.json().current_date.split('-')[0];
        this.appointmentDate = (current_date1);
        this.homecareAppDate = current_date1;
        this.currentMinDate = data.json().current_date;
      },
      error => {});
  }

  getTreatTypeMasterData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.treatementTypeUrl).subscribe(
      data => {
        if (data.json().medicare != null && data.json().medicare != undefined) {
          this.treatmentList = data.json().medicare;
          this.treatementType = this.treatmentList[0].medicare_name;
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getRelationList() {// Get Relation
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/grels/", this.clientRegId, { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data)
        if (data.json().relationships != null && data.json().relationships != undefined) {
          this.relationList = data.json().relationships;
          Helper_Class.setrelation(this.relationList)
          this.relationship = data.json().relationships[0].relationship_name;
          this.getSltRelationType(this.relationship);
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getSltRelationType(rel_data) { // Relationship Change
    var e = rel_data;
    this.clntFirstName = null;
    this.clntMiddleName = null;
    this.clntLastName = null;
    this.clntAge = null;
    this.clntGender = null;
    this.clntDOB = null;

    if (rel_data == "Self") {
      this.relationFlag = true;
      rel_id = "1"
      if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
        this.selfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: "Self",
          country: "IN"
        }
      } else {
        this.selfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: rel_data,
          country: "IN"
        }
      }
      this.otherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clntDetailedUrl, (this.selfRelationData), { headers: headers }).subscribe(
        data => {
          var obj = JSON.stringify(data);
          Helper_Class.setclientbasicinfo(obj)

          if (data.json().length != 0) {
            if (data.json().middle_name != null && data.json().middle_name != undefined) 
              this.clntMiddleName = data.json().middle_name;

            this.clntFirstName = encrypt_decript.Decript(data.json().first_name);

            if (data.json().middle_name != null)
              this.clntMiddleName = encrypt_decript.Decript(data.json().middle_name);

            this.clntLastName = encrypt_decript.Decript(data.json().last_name);
            this.clntAge = data.json().age != 0 ? data.json().age : "<1";

            if (data.json().gender != null && data.json().gender != "0: undefined") 
              this.clntGender = encrypt_decript.Decript(data.json().gender);

            if (data.json().dob)
              this.clntDOB = data.json().dob;

            this.clntMobile = data.json().mobile;
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.relationFlag = false;
      var rel_id = ""; // to get selected relation id
      this.relationList = Helper_Class.getrelation();

      for (var i = 0; i < this.relationList.length; i++) {
        if (this.relationList[i].relationship_name == rel_data) {
          rel_id = this.relationList[i].relation_id;
          break;
        }
      }

      this.otherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }

      if (rel_data == "Father") 
        this.relationFlag = true;

      if (rel_data == "Mother")
        this.relationFlag = true;

      if (rel_data == "Spouse")
        this.relationFlag = true;

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.otherRelDetUrl, JSON.stringify(this.otherRelationData), { headers: headers }).subscribe(
        data => {
          var obj = JSON.stringify(data);
          this.relationsList = [];

          if (data.json().rel_details != null && data.json().rel_details.length != 0) {
            if (rel_id == "2" || rel_id == "3" || rel_id == "6"){ // Selected relation is Father/mother/spouse
              this.clntSubrelID = data.json().rel_details[0].cln_rel_id;
              this.clntFirstName = data.json().rel_details[0].first_name;

              if (data.json().rel_details[0].middlename != null && data.json().rel_details[0].middlename != undefined)
                this.clntMiddleName = data.json().rel_details[0].middlename;

              this.clntLastName = data.json().rel_details[0].last_name;
              this.clntAge = data.json().rel_details[0].age != 0 ? data.json().rel_details[0].age : "<1";

              if (data.json().rel_details[0].gender != null && data.json().rel_details[0].gender != "0: undefined") 
                this.clntGender = data.json().rel_details[0].gender;

              if (data.json().rel_details[0].dob) 
                this.clntDOB = data.json().rel_details[0].dob;

              if (data.json().mobile != undefined) 
                this.clntMobile = data.json().mobile;

            }else {
              for (var i = 0; i < data.json().rel_details.length; i++) {
                if (data.json().rel_details[i].address2 != null) 
                  var orel_address_2 = encrypt_decript.Decript(data.json().rel_details[i].address2);

                if (data.json().rel_details[i].dob != null) 
                  var orel_dob = data.json().rel_details[i].dob;

                var midname = "";
                if (data.json().rel_details[i].middle_name != undefined) 
                  midname = data.json().rel_details[i].middle_name;

                var name;
                if (data.json().rel_details[i].middle_name != undefined) {
                  name = data.json().rel_details[i].first_name + " " + data.json().rel_details[i].middle_name + " " + data.json().rel_details[i].last_name;
                } else {
                  name = data.json().rel_details[i].first_name + " " + data.json().rel_details[i].last_name;
                }

                this.relationsList.push({
                  name: name,
                  cln_rel_id: data.json().rel_details[i].cln_rel_id,
                  first_name: data.json().rel_details[i].first_name,
                  middle_name: midname,
                  last_name: data.json().rel_details[i].last_name,
                  age: data.json().rel_details[i].age,
                  gender: data.json().rel_details[i].gender,
                  blood_group: data.json().rel_details[i].blood_desc,
                  blood_group_id: data.json().rel_details[i].blood_group,
                  father_guardian_name: data.json().rel_details[i].family_guardian,
                  income: data.json().rel_details[i].family_income,
                  occupation: data.json().rel_details[i].occupation,
                  address1: encrypt_decript.Decript(data.json().rel_details[i].address1),
                  address2: orel_address_2,
                  location: data.json().rel_details[i].location_desc,
                  city: data.json().rel_details[i].city_desc,
                  state: data.json().rel_details[i].state_desc,
                  country: data.json().rel_details[i].country_desc,
                  location_id: data.json().rel_details[i].location,
                  city_id: data.json().rel_details[i].city,
                  state_id: data.json().rel_details[i].state,
                  country_id: data.json().rel_details[i].country,
                  zipcode: encrypt_decript.Decript(data.json().rel_details[i].zipcode),
                  contact_num: data.json().rel_details[i].mobile,
                  dob: orel_dob,
                  mobile: data.json().mobile,
                });
              }
            }
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    }
  }

  getSltRelationDetails(name) {
    for (var i = 0; i < this.relationsList.length; i++) {
      if (name == this.relationsList[i].cln_rel_id) {
        this.clntSubrelID = this.relationsList[i].cln_rel_id;
        this.clntFirstName = this.relationsList[i].first_name;
        this.clntMiddleName = "";
        if (this.relationsList[i].middle_name != null && this.relationsList[i].middle_name != "")
          this.clntMiddleName = this.relationsList[i].middle_name;

        this.clntLastName = this.relationsList[i].last_name;
        this.clntAge = this.relationsList[i].age != 0 ?  this.relationsList[i].age : "<1"; 
        this.clntGender = this.relationsList[i].gender;
        this.clntOccupation = this.relationsList[i].occupation;

        this.clntDOB = this.relationsList[i].dob;
        if (this.relationsList[i].mobile != undefined) 
          this.clntMobile = this.relationsList[i].mobile;
      }
    }
  }

  getCountries(flag) {// Doctor Search
    this.clntCountryList = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.gservice.Client_login_detail_data.Country_ID) {
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryID = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryID, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.clntCountryDesc = this.clntCountryList[c].description;
        this.clntCountryID = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntCity = undefined;
        this.clntLocation = "";
        this.getStates(this.clntCountryID, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.gservice.Client_login_detail_data.State_ID) {
                this.clntState = this.clntStateList[i].state_id;
                this.clntStateDesc = this.clntStateList[i].descrition;

                this.getCities(this.clntState, flag);
                break;
              }
            }
          }
        });
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].state_id == clnt_state) {
        this.clntState = this.clntStateList[i].state_id;
        this.clntStateDesc = this.clntStateList[i].description;
        this.clntCityList = [];
        this.clntCity = undefined;
        this.clntLocation = "";
        this.getCities(this.clntState, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.gservice.Client_login_detail_data.City_ID) {
                this.clntCity = this.clntCityList[i].district_id;
                this.clntCityDesc = this.clntCityList[0].city_desc;
                this.getLocations(this.clntCity);
                break;
              } else {
                this.clntCity = this.clntCityList[0].district_id;
              }
            }
          }
        });
  }

  cityChange(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].district_id == clnt_city) {
        this.clntCity = this.clntCityList[i].district_id;
        this.clntCityDesc = this.clntCityList[0].city_desc;
        this.clntLocation = "";
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, JSON.stringify({ city_id: city }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.gservice.Client_login_detail_data.Location_ID) {
                this.clntLocation = this.clntLocationList[i].description;
                this.clntLocationList = [];
                break;
              }
            }
          }
        });
  }

  locationChange(e) {
    this.clntLocationList = [];
    var locat_desc = e.target.value.toString();

    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCity,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectedLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocation = this.clntLocationList[i].description;
        this.locationID = this.clntLocationList[i].location_id;
        this.getDoctors();
      }
    }
    this.clntLocationList = [];
  }

  getDocListByFirstName() {
    this.doctorList = [];
    this.getDoctors();
  }

  getSpecializationMasterData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.specializationUrl).subscribe(
      data => {
        for (var i = 0; i < data.json().specialization.length; i++) {
          if (data.json().specialization[i].spl_name != "-") {
            if (this.homecare != "0") {
              if (data.json().specialization[i].spl_name != "Dental") {
                this.specializationList.push({
                  Css: false,
                  splty_name: data.json().specialization[i].spl_name,
                  splty_id: data.json().specialization[i].spl_id,
                  splty_img: "assets/imgs/Speciality/" + data.json().specialization[i].spl_name + ".png"
                });
              }
            } else {
              this.specializationList.push({
                Css: false,
                splty_name: data.json().specialization[i].spl_name,
                splty_id: data.json().specialization[i].spl_id,
                splty_img: "assets/imgs/Speciality/" + data.json().specialization[i].spl_name + ".png"
              });
            }
            this.splName = this.specializationList[0].splty_name;
          }
        }
      },error => {});
    setTimeout(() => {
      this.n = this.n + 10;
    }, 1000);
  }

  getDoctors() {
    var data_toget_doc_list = {
      country: this.clntCountry,
      state: this.clntStateDesc,
      city: this.clntCityDesc,
      location: this.clntLocation,
      medicare_name: this.treatementType,
      spl_name: this.splName,
      home_care: this.homecare,
      doc_name: this.doctorFirstName
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorListUrl, JSON.stringify(data_toget_doc_list), { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data);
        if (data.json().info != null && data.json().info.length != 0) {
          this.getDoctorList(data.json().info);
        } else {
          this.doctorListNoData = true;
          this.doctorList = [];
        }
      },error => {});
  }

  getDoctorList(responselist) {
    this.doctorList = [];
    this.doctorListNoData = responselist.length == 0 ? true : false;

    for (var i = 0; i < responselist.length; i++) {
      var Docname = "";
      if (responselist[i].middle_name != undefined && responselist[i].middle_name != "") {
        Docname = "Dr " + responselist[i].first_name + " " + responselist[i].middle_name + " " + responselist[i].last_name;
      } else {
        Docname = "Dr " + responselist[i].first_name + " " + responselist[i].last_name;
      }

      var MedicareName = "";
      var gender_data = "";

      if (responselist[i].gender != null) 
        gender_data = responselist[i].gender;

      if (responselist[i].medicare != null) {
        if (responselist[i].medicare.length != 0) {
          for (var j = 0; j < responselist[i].medicare.length; j++) {
            MedicareName = (j == 0) ? responselist[i].medicare[j] : MedicareName + ", " + responselist[i].medicare[j];
          }
        }
      }

      var Splityname = "", splid = "0";
      if (responselist[i].specialization != null && responselist[i].specialization.length != 0) {
        Splityname = "";
        for (var j = 0; j < responselist[i].specialization.length; j++) {
          Splityname = (j == 0) ? responselist[i].specialization[j] : Splityname + ", " + responselist[i].specialization[j];
        }
        var Medispecial = false;
      } else {
        var Medispecial = true;
      }

      var Qualify = "";
      if (responselist[i].qualification.length != 0) {
        for (var j = 0; j < responselist[i].qualification.length; j++) {
          Qualify = (j == 0) ? responselist[i].qualification[j].degree_name : Qualify + ", " + responselist[i].qualification[j].degree_name;
        }
      }

      var Hosp_detail = [];
      if (responselist[i].hospital.length != 0) {
        var Hosp_id_list = [];
        for (var j = 0; j < responselist[i].hospital.length; j++) {
          if (j == 0) {
            Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
            Hosp_detail.push({
              Hosp_name: responselist[i].hospital[j].hospital_name,
              Hosp_Locat: responselist[i].hospital[j].location
            });
          } else {
            if (this.Array_contains(Hosp_id_list, responselist[i].hospital[j].hospital_id) == false) {
              Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
              Hosp_detail.push({
                Hosp_name: responselist[i].hospital[j].hospital_name,
                Hosp_Locat: responselist[i].hospital[j].location
              });
            }
          }
        }
      }

      var workexp;
      if (parseInt(this.currentYear) - parseInt(responselist[i].registeration_year) == 0)
        workexp = "less than 1 year";
      else if (parseInt(this.currentYear) - parseInt(responselist[i].registeration_year) == 1)
        workexp = "around 1 year";
      else 
        workexp = parseInt(this.currentYear) - parseInt(responselist[i].registeration_year) + " years";

      this.doctorList.push({
        Prov_id: responselist[i].prov_id,
        Doctor_Name: Docname,
        Prof_image: ipaddress.Ip_with_img_address + responselist[i].profile_image,
        Work_Year: workexp,
        Medicare: MedicareName,
        Speciality: Splityname,
        Qualification: Qualify,
        Hospital: Hosp_detail,
        gender1: gender_data,
        check: false
      });
    }
  }

  Array_contains(array, value) {
    var data = false;

    for (var i = 0; i < array.length; i++) {
      if (array[i] == value) {
        data = true;
        break;
      }
    }
    return data;
  }

  getDoctorDetails(curt_date, curt_time) {
    var sendDoctorDetailData = {
      doc_reg_id: this.doctorID,
      // location: this.clntLocation,
      location: this.locationID,
      country: ipaddress.country_code,
      home_care: this.homecare,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorDetailUrl, JSON.stringify(sendDoctorDetailData), { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data);
        if (data.json().prov_id != null) {
          this.doctorID = data.json().prov_id;
          if (data.json().non_avail != null) {
            this.docUnavailableData = data.json().non_avail;
          }
          this.doctorProfileImg = ipaddress.Ip_with_img_address + data.json().profile_image;
        }

        if (data.json().pay_cash == "1") {
          this.cashFlag = true;
          this.payFlag = true;
        } else {
          this.cashFlag = true;
        }

        if (data.json().pay_online == "1") {
          this.onlineFlag = false;
          this.payFlag = true;
        } else {
          this.onlineFlag = true;
        }

        if (data.json().pay_ins == "1") {
          this.insuranceFlag = false;
          this.payFlag = true;
        } else {
          this.insuranceFlag = true;
        }

        if (data.json().consultation != null) {// Normal Consultation
          this.doctorDetailTableData = [];
          this.hospitalListData = [];

          for (var l = 0; l < data.json().consultation.length; l++) {
            this.tempFee = data.json().consultation[l].fees != "0" ? data.json().consultation[l].fees : "NA";

            var temp_address;
            if (this.gservice.get_Array_Contains(this.hospitalListData, "hospital_id", data.json().consultation[l].hospital_id) == false) {
              if (data.json().consultation[l].address2 != null && data.json().consultation[l].address2 != "") {
                temp_address = data.json().consultation[l].address + ", " + data.json().consultation[l].address2;
              } else {
                temp_address = data.json().consultation[l].address;
              }
              this.hospitalListData.push({
                hospital_id: data.json().consultation[l].hospital_id,
                hospname: data.json().consultation[l].hospital,
                Location: data.json().consultation[l].location,
                Address_1: temp_address,
                City: data.json().consultation[l].city,
                fees: this.tempFee,
              });
            }

            this.doctorDetailTableData.push({
              hospitalid: data.json().consultation[l].hospital_id,
              hospital: data.json().consultation[l].hospital,
              location: data.json().consultation[l].location,
              day: this.gservice.get_Day(data.json().consultation[l].day),
              day_num: data.json().consultation[l].day,
              session: this.gservice.get_Session(data.json().consultation[l].session),
              available_from: this.gservice.get_Timeformate(data.json().consultation[l].available_from),
              available_to: this.gservice.get_Timeformate(data.json().consultation[l].available_to),
              fees: this.tempFee,
              TokenorTime: data.json().consultation[l].token_flag,
              Time_Duration: data.json().consultation[l].time_duration,
              Avail_from: data.json().consultation[l].available_from,
              Avail_to: data.json().consultation[l].available_to
            });
          }

          this.hospitalID = this.hospitalListData[0].hospital_id;
          this.hospitalName = this.hospitalListData[0].Hospname;
          this.hospSltLocation = this.hospitalListData[0].Location;
          this.hospSltFee = this.hospitalListData[0].fees;

          if (this.doctorDetailTableData[0].TokenorTime == "true") {
            this.typeorTime = "type";
            this.appTypeTokenFlag = true;
            this.appTypeTimeFlag = false;
            this.apptType = "Token";
            this.doctorAvailableFlag = false;
            this.sessionAdd(this.hospitalID);
            var parts = curt_date.split('-');
            this.doctorAvailable(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
          } else {
            this.typeorTime = "time";
            this.appTypeTokenFlag = false;
            this.appTypeTimeFlag = true;
            this.doctorAvailableFlag = true;
            this.sessionAdd(this.hospitalID);
            var parts = curt_date.split('-');
            this.doctorAvailable(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
            this.apptDateChange(curt_date);
          }
        }

        if (data.json().home_consult != null) {// HomeCare Consult
          this.homecareTableData = [];
          this.homecareSessionData = [];
          for (var i = 0; i < data.json().home_consult.length; i++) {
            this.homecareTableData.push({
              Session: this.gservice.get_Session(data.json().home_consult[i].session),
              Avail_from: this.gservice.get_Timeformate(data.json().home_consult[i].available_from),
              Avail_to: this.gservice.get_Timeformate(data.json().home_consult[i].available_to),
              Fees: data.json().home_consult[i].fees,
              Time_Inter: data.json().home_consult[i].time_interval,
              avail_from: data.json().home_consult[i].available_from,
              avail_to: data.json().home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(data.json().home_consult[i].session));
            this.sessionAdd("");
            this.homecareApptDateChange(curt_date);
          }
        }
      },error => {});
  }

  paymentTypeSelect() {}

  sessionAdd(Hospital) {
    this.visitingSessionData = [];

    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    if (Hospital == "") {
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()){ // Today 
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }
    } else {
      var get_date = this.currentDate == this.appointmentDate ? Date_Formate(this.currentDate) : Date_Formate(this.appointmentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.doctorDetailTableData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailTableData[a].day_num;
        let dayflag = false;
        if (dayName == this.doctorDetailTableData[a].day) {
          dayflag = true;
        } else if (this.doctorDetailTableData[a].day == "All Days") {
          dayflag = true;
        } else if (sel_day_num != 1 && day_num == 8) {
          dayflag = true;
        }

        if (this.doctorDetailTableData[a].hospitalid == Hospital && dayflag == true) {
          if (this.currentDate.trim() == get_date.trim()) { // Today 
            if (this.doctorDetailTableData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailTableData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailTableData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailTableData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailTableData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailTableData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
  }

  ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  checkAvailabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(Date_Formate(get_sel_date));

    if (this.docUnavailableData.length != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        if (this.docUnavailableData[i].full_day != null) {
          if (check_date >= new Date(this.docUnavailableData[i].from_date)
            && check_date <= new Date(this.docUnavailableData[i].to_date)) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.docUnavailableData[i].from_time.split(':');
            var To_Split = this.docUnavailableData[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

            var get_time1 = this.ConvertTimeformat("24", get_f_time);
            var get_time2 = this.ConvertTimeformat("24", get_t_time);

            var get_f_time1 = get_time1.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));

            var get_chk_date = this.getDateMatePicker1(check_date);
            var get_f_date = this.docUnavailableData[i].from_date;
            var get_t_date = this.docUnavailableData[i].from_date;

            if (get_chk_date.toString() == get_f_date.toString()
              && sess == this.docUnavailableData[i].session
              || get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2) 
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 <= t2)) 
                Available_flag = false;

              if ((f_time1 >= t1 && f_time1 == t2)) 
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString()
              && get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;

              if ((f_time1 > t1 && f_time1 == t2)) 
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getDateMatePicker_calendar(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getApptByDate(data) {
    this.appointmentDate = data;

    if (data != null) {
      if (this.homecare == "0") 
        this.homecareAppDate = data;

      if (this.homecare == "1") 
        this.sessionAdd("");
      else
        this.sessionAdd(this.hospitalID);
      this.apptDateChange(Date_Formate(data));
    }
  }

  doctorAvailable(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.doctorDetailTableData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailTableData[i].day);

      if (this.doctorDetailTableData[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);

        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailTableData[i].Avail_to.split(':');

        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);

        if (this.doctorDetailTableData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailTableData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(this.getDateMatePicker_calendar(curtdate))) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.checkAvailabilty(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }

    if (Available_flag == false) {
      this.doctorAvailableFlag = false;
      this.doctorNonAvailableFlag = true;
      this.nextBtnFlag = false;
    } else {
      this.doctorAvailableFlag = true;
      this.doctorNonAvailableFlag = false;
      this.nextBtnFlag = true;
    }
  }

  visitSessionChangeForTime(appdate) {
    this.bookFlag = true;

    var dateval = appdate.split("-");
    var send_data = {
      doc_reg_id: this.doctorID,
      app_date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
      type:"doctor"
      //session: encrypt_decript.Encript(session)
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "appointment/dbt/", JSON.stringify(send_data), { headers: headers }).subscribe(
      response => {
        var data = response.json();

        if (data != null) {
          if (data.morning != null) {
            this.booktimeMorn = data.morning;
          }
          if (data.evening != null) {
            this.booktimeEve = data.evening;
          }
          if (data.afternoon != null) {
            this.booktimeNoon = data.afternoon;
          }
          this.setTimeSlots(appdate);
        }
        else {
          this.setTimeSlots(appdate);
        }
      })
  }

  setTimeSlots(appdate) {
    this.timeListData = [];
    var time_t = [];//
    var session;
    for (var j = 0; j < this.visitingSessionData.length; j++) {
      session = "";
      session = this.visitingSessionData[j].description;
      time_t = [];

      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (session == this.doctorDetailTableData[i].session) {
          var From_Split = this.doctorDetailTableData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailTableData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailTableData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);

          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);

          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            time_t.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              time_t.push(New_time1);
            }
          }

          var substr = this.doctorDetailTableData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailTableData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailTableData[i].Avail_to)) {
                time_t.push(New_time);
              }
            }
          }
        }
      }

      if (time_t.length != 0) {
        if (this.booktimeEve.length != 0) {
          for (var k = 0; k < this.booktimeEve.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeEve[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }
        if (this.booktimeMorn.length != 0) {
          for (var k = 0; k < this.booktimeMorn.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeMorn[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }
        if (this.booktimeNoon.length != 0) {
          for (var k = 0; k < this.booktimeNoon.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeNoon[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }

        this.timeListData.push({
          session: session,
          time: time_t
        });
      }
    }
    if (this.timeListData.length != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        for (var j = 0; j < this.timeListData.length; j++) {
          if (this.docUnavailableData[i].session == this.timeListData[j].session) {
            var tempTime = this.docUnavailableData[i].from_time.split(":")
            var tempdata = new Date();
            tempdata.setHours(tempTime[0]);
            tempdata.setMinutes(tempTime[1]);
            var getStartingTime = this.datepipe.transform(tempdata, 'hh:mm a');
            var index = this.timeListData[j].time.indexOf(getStartingTime);
            if (index !== -1) {
              this.timeListData[j].time.splice(index, 1);
            }
          }
        }
      }
    }
  }

  visitSessionChange(session, appdate) {
    var session1 = encrypt_decript.Encript(session).toString()
    this.bookFlag = true;
    this.timeList = [];

    if (this.typeorTime == "time") {
      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (session == this.doctorDetailTableData[i].session) {
          var From_Split = this.doctorDetailTableData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailTableData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailTableData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);

          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);

          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            this.timeList.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              this.timeList.push(New_time1);
            }
          }

          var substr = this.doctorDetailTableData[i].Time_Duration;

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailTableData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);

            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailTableData[i].Avail_to)) {
                this.timeList.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  apptDateChange(appdate) {
    this.timeList = []; // clear app time list
    if ((this.currentDate) != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = appdate.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;

      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (this.doctorDetailTableData[i].hospitalid == this.hospitalID) {
          if (this.doctorDetailTableData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailTableData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailTableData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }

      if (Available_flag_1 == false) {
        this.doctorAvailableFlag = false;
        this.doctorNonAvailableFlag = true;
      } else {
        this.doctorAvailableFlag = true;
        this.doctorNonAvailableFlag = false;
      }

      this.sessionAdd(this.hospitalID);
      if (this.typeorTime == "time") {
        this.visitSessionChangeForTime(appdate);
        this.appTypeTokenFlag = false;
      } else {
        this.visitSessionChange(this.visitSession, appdate);
        this.appTypeTokenFlag = true;
      }
      this.doctorAvailable(Date_Formate(appdate), this.currentTime);
    } else {
      this.sessionAdd(this.hospitalID);
      if (this.typeorTime == "time")
        this.visitSessionChangeForTime(appdate);
      else
        this.visitSessionChange(this.visitSession, appdate);
      this.doctorAvailable((appdate), this.currentTime);
    }
  }

  hospitalChange(Hospital) {
    this.sessionAdd(Hospital);
    this.doctorAvailable(((this.appointmentDate)), this.currentTime);
    this.visitSessionChange(this.visitSession, this.currentDate);

    for (var r = 0; r < this.hospitalListData.length; r++) {
      if (this.hospitalListData[r].hospital_id == Hospital) {
        this.hospitalID = this.hospitalListData[r].hospital_id;
        this.hospSltLocation = this.hospitalListData[r].Location;
        this.hospSltFee = this.hospitalListData[r].fees;
        break;
      }
    }
  }

  // Homecare
  homecareApptDateChange(appdate) {
    this.homecareTimeList = [];
    this.homecareVisitSession = null;
    this.home_Visitsession_change(appdate);
  }

  home_Visitsession_change(appdate) {
    this.bookFlag = true;
    this.homecareTimeList = [];

    var time_t = [];//
    var session;
    for (var j = 0; j < this.visitingSessionData.length; j++) {
      session = "";
      session = this.visitingSessionData[j].description;
      time_t = [];

      for (var i = 0; i < this.homecareTableData.length; i++) {
        if (session == this.homecareTableData[i].Session) {
          var From_Split = this.homecareTableData[i].avail_from.split(':');
          var To_Split = this.homecareTableData[i].avail_to.split(':');
          var parts = (appdate).split('-');
          var fromday = new Date();

          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);

          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);

          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');

          if (this.currentDate == appdate && fromday < today) { //&& $scope.curr_get_ftime > $scope.curr_datetime            time_t.push(New_time1);
            time_t.push(New_time1);
          } else {
            if (fromday < today) {
              if (this.currentDate != appdate) {
                time_t.push(New_time1);
              }
            }
          }

          var substr = this.homecareTableData[i].Time_Duration;

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.homecareTableData[i].Time_Inter));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);

            if (Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.homecareTableData[i].Avail_to)) {
                time_t.push(New_time);
              }
            }
          }
        }
      }

      if (time_t.length != 0) {
        this.homecareTimeList.push({
          session: session,
          time: time_t
        });
      }
    }
  }

  doctor_filter(send_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorListUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        if (data.json().info != null && data.json().info.length != 0) {
          this.getDoctorList(data.json().info);
        } else {
          this.doctorList = [];
          this.doctorListNoData = true;
        }
      },error => {});
  }

  app_Book_now() {// App Booking
    var valid_flag = false;

    if (this.splName == "Pediatrics") {
      if (16 < parseInt(this.clntAge)) {
        this.toastr.error(Message_data.splOnlyForChild);
        valid_flag = true;
      }
    } else if (this.splName == "Gynecology") {
      if (this.clntGender == "Male") {
        this.toastr.error(Message_data.splOnlyForwomen);
        valid_flag = true;
      }
    }

    if (valid_flag == false) {
      var Provider = "doctor";

      if (this.visitTime != undefined) {
        var app_time = this.visitTime.toString().split(" ");
        this.tokenorTime = this.visitTime.toString();
        if (this.tokenorTime != "true") {
          if (this.tokenorTime != undefined) {
            this.timeOfAppt = app_time[0] + ":00";
          }
        } else {
          this.timeOfAppt = "1";
        }
      } else {
        this.timeOfAppt = "1";
      }

      var sendAppType = this.appType == "New" ? "1" : "2"; 

      if (this.clntAge != null)
        var client_app_age = this.clntAge;

      if (this.clntSymptom != null)
        var clnt_symtom_data = this.clntSymptom;

      if (this.clntMiddleName != null)
        var clntMiddleName = encrypt_decript.Encript(this.clntMiddleName).toString();

      if (this.clntDOB != null)
        var client_send_dob = this.clntDOB;

      if (this.clntSubrelID != null)
        var client_sub_rel_id = this.clntSubrelID;

      if (this.treatementType != null) 
        var medicare_id = this.treatementType.medicare_id;

      if (this.homecareSessionTableData.length != 0) {
        this.homecareAppt = [];
        for (var i = 0; i < this.homecareSessionTableData.length; i++) {
          var dateval = Date_Formate(this.homecareSessionTableData[i].Date);
          this.homecareAppt.push({
            session: encrypt_decript.Encript(this.homecareSessionTableData[i].Session).toString(),
            date: (dateval),
            app_time: this.homecareSessionTableData[i].app_time,
          });
        }
      }

      var token_flag = this.typeorTime == "time" ? false : true;
      var relid = this.otherRelationData.relation_id != undefined ? this.otherRelationData.relation_id :  "1";

      var occenc;
      if (this.clntOccupation != undefined)
        occenc = encrypt_decript.Encript(this.clntOccupation).toString();

      var medicareid;
      for (var i = 0; i < this.treatmentList.length; i++) {
        if (this.treatmentList[i].medicare_name == this.treatementType) 
          medicareid = this.treatmentList[i].medicare_id
      }
      if (this.payType == "Cash")
        this.cashFlag = true;

      this.dataForApptBook = {
        prov_id: this.doctorID,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        // Client
        first_name: encrypt_decript.Encript(this.clntFirstName).toString(),
        middle_name: clntMiddleName,
        last_name: encrypt_decript.Encript(this.clntLastName).toString(),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        age: client_app_age,
        dob: client_send_dob,
        mem_mobile: this.clntMobile,
        symptom: clnt_symtom_data,
        occupation: occenc,
        app_type: sendAppType,
        // Doctor
        medicare_id: medicareid,
        specialization_id: this.splID,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        date: this.appointmentDate,
        token: token_flag,
        time: this.timeOfAppt,
        hptl_id: this.hospitalID,
        home_care: this.homecare,
        home_app_date: this.homecareAppt,
        payment_type: this.payType,
        ref_by: this.referredDoctorName,
      };
      this.book_Doc_appointment();
    }
  }

  book_Doc_appointment() {
    this.bookFlag = false;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptBookUrl, (this.dataForApptBook), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj.status == "1") {
          this.toastr.success(data.json().result);
          this.messageservice.sendMessage("doctor");
        } else {
          this.bookFlag = true;
          this.toastr.error(data.json().result);
        }
      },error => {
        this.toastr.error(error.json().result);
        this.bookFlag = true;
      });
  }

  Home_Table_Add() {// Homecare table add// Homecare Visiting Table data
    var Check_Valid = false;
    if (this.homecareAppTime == undefined)
      this.homecareAppTime = this.visitTime.toString().split(" ");

    if (this.currentDate == Date_Formate(this.homecareAppDate)) {// Current Date
      var Timesplit = this.currentTime.split(':');
      var Current_Dt = new Date();
      Current_Dt.setHours(parseInt(Timesplit[0]));
      Current_Dt.setMinutes(parseInt(Timesplit[1]));

      var Timesplit1 = this.homecareAppTime[0].split(':');
      var Select_Dt = new Date();
      Select_Dt.setHours(Timesplit1[0]);
      Select_Dt.setMinutes(Timesplit1[1]);

      if (Current_Dt > Select_Dt) 
        Check_Valid = true;
    }

    if (this.homecareAppDate != undefined) { }
    if (Check_Valid == false) {
      var book_date = this.homecareAppDate.toString().split("-");
      var app_time = Time_Formate(this.homecareAppTime);
      var get_time = app_time.split(' ');
      var get_time1 = get_time[0] + ":00";
      var Usetype;

      var sessionval = this.homecareVisitSession != undefined ? this.homecareVisitSession : this.visitSession;

      this.homecareSendData = {
        type: Usetype,
        doc_reg_id: localStorage.getItem("Normal_Doctor_ID"),
        appointment_date: book_date[2] + "-" + book_date[1] + "-" + book_date[0],
        from_time: get_time1,
        session: encrypt_decript.Encript(sessionval).toString(),
        hosp_name: this.hospitalName,
        hosp_id: this.hospitalID,
      }
      this.appointmentDate = (book_date[2] + "-" + book_date[1] + "-" + book_date[0])
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "appointment/getappexist/", JSON.stringify(this.homecareSendData), { headers: headers }).subscribe(
        data => {
          if (data.json().result == true) {
            this.toastr.error(Message_data.docNotAvailableTime);
          } else {
            var tw_formate;
            for (var g = 0; g < this.homecareTimeList.length; g++) {
              if (this.homecareTimeList[g].TFformate == this.homecareAppTime) {
                tw_formate = this.homecareTimeList[g].Twformae;
                break;
              }
            }

            if (this.homecareSessionTableData.length == 0) {
              this.homecareSessionTableData.push({
                Date: Date_Formate(this.homecareAppDate),
                Session: sessionval,
                Time: tw_formate,
                New_Time: this.homecareAppTime,
                app_time: get_time1,
                hosp_name: this.hospitalName,
                hosp_id: this.hospitalID,
              });
            } else {
              var contains_flag = false;

              for (var t = 0; t < this.homecareSessionTableData.length; t++) {
                if ((this.homecareAppDate == this.homecareSessionTableData[t].Date) &&
                  (sessionval == this.homecareSessionTableData[t].Session) &&
                  (get_time1 == this.homecareSessionTableData[t].app_time)) {
                  contains_flag = true;
                  break;
                }
              }

              if (contains_flag == false) {
                this.homecareSessionTableData.push({
                  Date: this.homecareAppDate,
                  Session: sessionval,
                  Time: tw_formate,
                  New_Time: this.homecareAppTime,
                  app_time: get_time1,
                  hosp_name: this.hospitalName,
                  hosp_id: this.hospitalID,
                });
              } else {
                this.toastr.error(Message_data.dataAlreadyExist);
              }
            }
          }
        },error => {});
      this.homecareAppTime = undefined;
    } else {
      this.toastr.error(Message_data.enterValidTime);
    }
  }

  Delete_homesession_data(index) {
    for (var h = 0; h < this.homecareSessionTableData.length; h++) {
      if (h == index) {
        this.homecareSessionTableData.splice(h, 1);
        break;
      }
    }
  }

  Onappttimeclick(e, s, index) {
    this.visitSession = s;
    this.visitTime = e;
    var t;
    var htime = [];
    htime = this.homecare == "1" ? this.homecareTimeList : this.timeListData;

    for (var i = 0; i < htime.length; i++) {
      t = [];
      t = htime[i].time;
      for (var j = 0; j < t.length; j++) {
        if (t[j] == e) {
          document.getElementById("time_" + [i] + "_" + index).style.backgroundColor = "#868383";
          document.getElementById("time_" + [i] + "_" + [j]).style.boxShadow = "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
        }else {
          document.getElementById("time_" + [i] + "_" + [j]).style.backgroundColor = "#e0e0e0";
          document.getElementById("time_" + [i] + "_" + [j]).style.boxShadow = "none"
        }
      }
    }
  }

  getHomecareApptByDate(data) {
    this.homecareAppDate = data;
    if (data != null) {
      this.sessionAdd("");
      this.homecareApptDateChange(this.homecareAppDate);
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      searchString = dataval[dataval.length - 1];
    } else {
      searchString = this.clntSymptom;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.SymptomList = [];
              this.dispSymptomList = [];
              this.symptomData = [];
              this.SymptomList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomData.push(obj.symptoms[i].description);
              }

              this.dispSymptomList = this.symptomData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {});
    }
  }

  selectSymptom(data) {
    for (var j = 0; j < this.SymptomList.length; j++) {
      if (this.SymptomList[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }

    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      dataval.pop();
      this.clntSymptom = dataval.join(",");
      this.clntSymptom = this.clntSymptom + "," + data;
    } else {
      this.clntSymptom = data;
    }
    this.dispSymptomList = [];
  }
}
