import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';

@Component({
  selector: 'app-diagnosis-modify-report',
  templateUrl: './diagnosis-modify-report.component.html',
  styleUrls: ['./diagnosis-modify-report.component.scss']
})
export class DiagnosisModifyReportComponent implements OnInit {
  public userType;
  public clientNameArray = [];
  public locationInfoArray: any = [];
  public retivelArray = [];
  public diagLocation;
  public locationID;
  public locationArray = [];
  public sendData: any = [];
  public currentDate;
  date: any = [];
  public clientDataArray: any;
  public clientFName;
  getTestArray: any;
  updateTestArray: any = [];
  public reportApprovel;
  public diagReportType;
  public Select = "Select";
  dtOptions: DataTables.Settings = {};
  public retivelArray1;
  public reporttype: any;
  diag_centre_id: any;
  user_id: any;
  user_type: any;

  constructor(public messageservice: MenuViewService, public service: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
  }

  ngOnInit(): void {
    this.reporttype = Diagnosis_Helper.getReportType();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.user_id = Helper_Class.getInfo().user_id;
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.user_id = Helper_Class.getInfo().user_id;
      this.userType = "diagnosis";
      this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
      this.get_date();
      this.get_location();
    }
    else {
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.userType = Diagnosis_Helper.getUserID_Type().user_id != null ? "diag_user" : "diagnosis";
      this.get_date();
      this.get_location();
      
      // this.getPendingList();
    }
  }
  get_location() {
    console.log("get location" + JSON.stringify({
      diag_name: this.user_id,
      flag: "id",
      imei: localStorage.getItem("ipaddress"), 
      prov_id: this.user_id,
      type: this.userType,
    }))
    this.clientNameArray = [];


   console.log( "1001"+JSON.stringify({
    diag_name: this.user_id,
    flag: "id",
    imei: localStorage.getItem("ipaddress"),
    prov_id:this.user_id,
    type: this.userType,
  }))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
      diag_name: this.user_id,
      flag: "id",
      imei: localStorage.getItem("ipaddress"),
      prov_id:this.user_id,
      type: this.userType,
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().diag_loc != null) {
          this.locationInfoArray = data.json().diag_loc;
          this.retivelArray = [];
          for (var i = 0; i < this.locationInfoArray.length; i++) {
            this.retivelArray = [{
              name: this.locationInfoArray[0].diag_centre_name,
              id: this.locationInfoArray[0].diag_centre_id,
              address1: this.locationInfoArray[0].address1,
              address2: this.locationInfoArray[0].address2,
              loc: this.locationInfoArray[0].diag_loc,
              state: this.locationInfoArray[0].state,
              city: this.locationInfoArray[0].city,
              country: this.locationInfoArray[0].country,
              zipcode: this.locationInfoArray[0].zipcode,
              telephone: this.locationInfoArray[0].telephone,
            }];
            this.diagLocation = this.locationInfoArray[0];
            if (this.locationID == this.locationInfoArray[i].diag_centre_id) {
              this.retivelArray = [{
                name: this.locationInfoArray[i].diag_centre_name,
                id: this.locationInfoArray[i].diag_centre_id,
                address1: this.locationInfoArray[i].address1,
                address2: this.locationInfoArray[i].address2,
                loc: this.locationInfoArray[i].diag_loc,
                state: this.locationInfoArray[i].state,
                city: this.locationInfoArray[i].city,
                country: this.locationInfoArray[i].country,
                zipcode: this.locationInfoArray[i].zipcode,
                telephone: this.locationInfoArray[i].telephone
              }];
              this.diagLocation = this.locationInfoArray[i];
            }
            this.retivelArray1 = this.locationInfoArray[0].diag_centre_name + "-" + this.locationInfoArray[0].diag_loc;
          }
          this.loc_change(this.locationInfoArray[0]);
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  get_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          var new_day = this.currentDate.split('-');
          var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          this.date = [{
            day: current_date_data
          }];
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  get_client(i, data) {
    this.locationID = i.diag_centre_id;
    if (data != null) {
      this.get_location();
      this.sendData = {
        diag_reg_id: this.user_id,
        diag_user: "diag_user",
        location: i.diag_loc
      }
    } else {
      i.forEach(element => {
        this.sendData = {
          diag_reg_id: this.user_id,
          location: element.loc
        }
      });
    }
    console.log("get_client",this.sendData)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/getdiaapp/", JSON.stringify(
      this.sendData
    ), { headers: headers }).subscribe(
      data => {
        if (data.json() != undefined && data.json() != null && data.json() != "") {
          if (data.json().appointment_id != null) {
            this.clientDataArray = data.json().appointment_id;
            this.clientNameArray = [];
            this.clientDataArray.forEach(element => {
              if (element.first_name != null && encrypt_decript.Decript(element.first_name).toString() != "") {
                this.clientFName = encrypt_decript.Decript(element.first_name);
              }
              this.clientNameArray.push({
                name: this.clientFName,
                id: element.app_id,
                rel_id: element.relation_id,
                sub_rel_id: element.sub_rel_id
              });
            });
          }
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }

  loc_change(id) {
    console.log( "loc_change"+JSON.stringify({
      diag_centre_id: this.diag_centre_id,
      type: "reject",
      reporttype:this.reporttype,
    }))
    if (this.diag_centre_id != undefined) {
      this.updateTestArray = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtedit/",
        JSON.stringify({
          diag_centre_id: this.diag_centre_id,
          type: "reject",
          reporttype: this.reporttype,
        }), { headers: headers }).subscribe(
          data => {
            console.log("updateTestArray",data.json())
            if (data.json() != undefined && data.json() != null && data.json() != "") {
              if (data.json().test_edit != null) {
                this.getTestArray = data.json().test_edit;
                this.getTestArray.forEach(element => {
                  var fname, lname;
                  if (element.first_name != null && encrypt_decript.Decript(element.first_name).toString() != "") {
                    fname = encrypt_decript.Decript(element.first_name);
                  }
                  if (element.last_name != null && encrypt_decript.Decript(element.last_name).toString() != "") {
                    lname = encrypt_decript.Decript(element.last_name);
                  }
                  this.updateTestArray.push({
                    first_name: fname,
                    last_name: lname,
                    diag_app_id: element.diag_app_id,
                    pres_diag_id: element.pres_diag_id,
                    test_type: element.test_type,
                    test_name: element.test_name,
                    pres_test_id: element.pres_test_id,
                  })
                });
              }
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }

  viewReportDetails(testtype, diag_app_id, pres_test_id, pres_diag_id, test_name) {
    Diagnosis_Helper.setReportUpload(undefined);
   
    this.diagReportType = "Modify";
    var report = {
      AppFlow: "reportedit",
      type: testtype,
      diag_app_id: diag_app_id,
      report_diag_id: diag_app_id,
      pres_test_id: pres_test_id,
      pres_diag_id: pres_diag_id,
      Diag_report_flag: this.diagReportType,
      test_name: test_name
    };
    Diagnosis_Helper.setReportUpload(report);
    this.messageservice.sendMessage("report_upload_edit");
  }
}
