<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Post Covid Tracking</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="getCovidHistory()"
              src="../../../assets/ui_icons/buttons/History_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="saveData()"
              src="../../../assets/ui_icons/buttons/save_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label style="margin-top: 7px;" class="matlabel">Relation<br>
                <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="relationName">
                  <mat-option disabled>Select</mat-option>
                  <mat-option *ngFor="let relation of relationsList" (click)="changeRelation(relation.relation_id)"
                    value={{relation.relation_id}}>{{relation.name}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion [hidden]="symptomFlag" class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Symptoms
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                      <div class="cover_div">
                        <div class="content_cover">
                          <div class="row">
                            <div class="col-6">
                              <mat-label class="matlabel">Symptom
                                <mat-select disableOptionCentering class="ipcss " [(ngModel)]="symptomId">
                                  <mat-option *ngFor="let symptom of covidSympList" value={{symptom.symp_id}}>
                                    {{symptom.symp_desc}}
                                  </mat-option>
                                </mat-select>
                              </mat-label>
                            </div>
                            <div class="col-6">
                              <mat-label class="matlabel">Severity<br>
                                <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="severity">
                                  <mat-option value="Severe">Severe</mat-option>
                                  <mat-option value="High">High</mat-option>
                                  <mat-option value="Tolerable">Tolerable</mat-option>
                                  <mat-option value="Moderate">Moderate</mat-option>
                                  <mat-option value="Low">Low</mat-option>
                                </mat-select>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 my-auto d-flex justify-content-center">
                      <img src="../../../assets//ui_icons/arrow_green.svg" width="15px" height="auto"
                        (click)="addSymptoms()" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: 15px;">
                      <div class="dig_table_overflow" style="height: 100%;min-height: 86px;">
                        <div class="table-responsive" *ngIf="dailySympList.length != 0">
                          <table id="tbl" class="table table-hover table-dynamic">
                            <thead>
                              <tr>
                                <th>Symptom</th>
                                <th>Severity</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dailySymp of dailySympList">
                                <td style="text-align: left;">{{dailySymp.symptom}}</td>
                                <td style="text-align: left;">{{dailySymp.severity}}</td>
                                <td>{{dailySymp.date}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div [hidden]="sleepFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Vitals
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                        <div class="header_lable">
                          Observations
                        </div>
                        <div class="content_cover">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Temperature
                                <input type="text" class="ipcss " [(ngModel)]="temperature" matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Blood Pressure</mat-label>
                              <div class="row">
                                <div class="col-5">
                                  <input type="text" class="ipcss " placeholder="mm Hg" maxlength="3" [(ngModel)]="BP01"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                                <div class="col-1">
                                  <span>/</span>
                                </div>
                                <div class="col-5">
                                  <input type="text" class="ipcss " placeholder="mm Hg" maxlength="3" [(ngModel)]="BP02"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Saturation (SPO2)
                                <input type="text" class="ipcss " [(ngModel)]="spo2" matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Pulse
                                <input type="text" class="ipcss " [(ngModel)]="pulse" matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Respiratory rate
                                <input type="text" class="ipcss " [(ngModel)]="respiratoryRate" matInput />
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                        <div class="header_lable">
                          Sleeping Pattern
                        </div>
                        <div class="content_cover">
                          <div class="row">
                            <div class="col-12">
                              <mat-label class="matlabel">Sleep time (hh:mm)</mat-label>
                              <div class="row">
                                <div class="col-3">
                                  <input type="text" class="ipcss " placeholder="10" maxlength="3"
                                    [(ngModel)]="sleepStart1" (keyup)="calculateTimeDiff()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                                <div class="col-1 my-auto">
                                  <span>:</span>
                                </div>
                                <div class="col-4">
                                  <input type="text" class="ipcss " placeholder="30" maxlength="2"
                                    [(ngModel)]="sleepStart2" (keyup)="calculateTimeDiff()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                                <div class="col-4">
                                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sleepSession">
                                    <mat-option value="AM">AM</mat-option>
                                    <mat-option value="PM">PM</mat-option>
                                  </mat-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <mat-label class="matlabel">Wakeup time(hh:mm)</mat-label>
                              <div class="row">
                                <div class="col-3">
                                  <input type="text" class="ipcss " placeholder="6" maxlength="3"
                                    (keyup)="calculateTimeDiff()" [(ngModel)]="wakeStart1"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                                <div class="col-1 my-auto">
                                  <span>:</span>
                                </div>
                                <div class="col-4">
                                  <input type="text" class="ipcss " placeholder="30" maxlength="2"
                                    [(ngModel)]="wakeStart2" (keyup)="calculateTimeDiff()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                </div>
                                <div class="col-4">
                                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="wakeSession">
                                    <mat-option value="AM">AM</mat-option>
                                    <mat-option value="PM">PM</mat-option>
                                  </mat-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Duration
                                <input type="text" class="ipcss " [(ngModel)]="sleepDuration" disabled matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <mat-label class="matlabel">Sleep<br>
                                <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sleepId">
                                  <mat-option *ngFor="let sleep of sleepPatternList" value="{{sleep.sleep_id}}">
                                    {{sleep.sleep_desc}}
                                  </mat-option>
                                </mat-select>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div [hidden]="dietFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Diet
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="dite_content_cover">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="morning-tab" data-bs-toggle="tab" data-bs-target="#morning"
                          type="button" role="tab" aria-controls="morning" aria-selected="true">Morning snacks</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="breakfast-tab" data-bs-toggle="tab" data-bs-target="#breakfast"
                          type="button" role="tab" aria-controls="breakfast" aria-selected="false">Breakfast</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="mid_day-tab" data-bs-toggle="tab" data-bs-target="#mid_day"
                          type="button" role="tab" aria-controls="mid_day" aria-selected="false">Mid-day-snacks</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="lunch-tab" data-bs-toggle="tab" data-bs-target="#lunch"
                          type="button" role="tab" aria-controls="lunch" aria-selected="false">Lunch</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="Evening-tab" data-bs-toggle="tab" data-bs-target="#Evening"
                          type="button" role="tab" aria-controls="Evening" aria-selected="false">Evening snacks</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="dinner-tab" data-bs-toggle="tab" data-bs-target="#dinner"
                          type="button" role="tab" aria-controls="dinner" aria-selected="false">Dinner</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="night_snacks-tab" data-bs-toggle="tab"
                          data-bs-target="#night_snacks" type="button" role="tab" aria-controls="night_snacks"
                          aria-selected="false">Night snacks</button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="morning" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select disableOptionCentering class="ipcss" required [(ngModel)]="morFCategory"
                                        (ngModelChange)="changeFoodCategory('Morning')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6 select_bottomy">
                                    <mat-label class="matlabel">Food type<br>
                                      <select disableOptionCentering class="ipcss" required [(ngModel)]="morFType"
                                        (ngModelChange)="changeFoodType('Morning')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of morFoodType" value={{food}}>{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6 select_bottomy">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="morSubFType" (ngModelChange)="changeFoodSubType($event,'Morning')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of morSubfoodType" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6 select_bottomy">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="morFoodQty" (ngModelChange)="changeQuantity($event,'Morning')">
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="morMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="morWeight"
                                        minlength="3" [readonly]="isReadonly()" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="morEnegry"
                                        minlength="3" [readonly]="isReadonly()" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietMorn() "
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="morningFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive " *ngIf="dietMorListData.length != 0">
                                  <table *ngIf="dietMorListData.length != 0" id="tbl"
                                    class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Measure</th>
                                        <th>Quantity (g)</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let mor of dietMorListData">
                                        <td style="text-align: left;" data-th="Category">{{mor.category}}</td>
                                        <td style="text-align: left;" data-th="Food type">{{mor.food_sub_type}}</td>
                                        <td style="text-align: left;" data-th="Food sub type">{{mor.description}}</td>
                                        <td style="text-align: right;" data-th="Measure">{{mor.quantity}}
                                          {{mor.measure}}</td>
                                        <td style="text-align: right;" data-th="Weight(g)/Qty">{{mor.weight}}</td>
                                        <td style="text-align: right;" data-th="Calories">{{mor.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="diet_moredit(mor.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietMorning(mor.description)" />
                                          <!-- <a (click)="deleteDietMorning(mor.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //Breakfast -->
                      <div class="tab-pane fade show" id="breakfast" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="breakFCategory" (ngModelChange)="changeFoodCategory('breakfast')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="breakFType" (ngModelChange)="changeFoodType('breakfast')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of breakFoodType" value={{food}}>{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="breakSubFType"
                                        (ngModelChange)="changeFoodSubType($event,'breakfast')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of breakSubfoodType" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="breakFoodQty" (ngModelChange)="changeQuantity($event,'breakfast')">
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="breakMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="breakWeight" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="breakEnergy" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietbreakfast()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="breakfastFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive " *ngIf="dietBreakFastListData.length != 0">
                                  <table *ngIf="dietBreakFastListData.length != 0"
                                    class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let break of dietBreakFastListData">
                                        <td data-th="Category">{{break.category}}</td>
                                        <td data-th="Food type">{{break.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{break.description}}</td>
                                        <td data-th="Quantity">{{break.quantity}}</td>
                                        <td data-th="Measure">{{break.measure}}</td>
                                        <td data-th="Weight(g)/Qty">{{break.weight}}</td>
                                        <td data-th="Calories">{{break.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../assets/img/edit.png" style="width: 20px; height: 20px;" (click)="diet_breakedit(break.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietBreakfast(break.description)" />
                                          <!-- <a (click)="deleteDietBreakfast(break.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //Mid-Day-Snacks -->
                      <div class="tab-pane fade show" id="mid_day" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="middayFCategory" (ngModelChange)="changeFoodCategory('midday')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="middayFType" (ngModelChange)="changeFoodType('midday')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of middayFoodType" value={{food}}>{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="middaySubFType"
                                        (ngModelChange)="changeFoodSubType($event,'midday')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of middaySubfoodType" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="middayFoodQty" (ngModelChange)="changeQuantity($event,'midday')">
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayWeight" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayEnergy" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietMidday()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="middayFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive" *ngIf="dietMiddayListData.length != 0">
                                  <table *ngIf="dietMiddayListData.length != 0" class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let midday of dietMiddayListData">
                                        <td data-th="Category">{{midday.category}}</td>
                                        <td data-th="Food type">{{midday.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{midday.description}}</td>
                                        <td data-th="Quantity">{{midday.quantity}}</td>
                                        <td data-th="Measure">{{midday.measure}}</td>
                                        <td data-th="Weight(g)/Qty">{{midday.weight}}</td>
                                        <td data-th="Calories">{{midday.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="midday_edit(midday.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietMidday(midday.description)" />
                                          <!-- <a (click)="deleteDietMidday(midday.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //lunch -->
                      <div class="tab-pane fade show" id="lunch" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="lunchFCategory" (ngModelChange)="changeFoodCategory('lunch')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="lunchFType" (ngModelChange)="changeFoodType('lunch')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of lunchFoodType" value={{food}}>{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="lunchSubFType" (ngModelChange)="changeFoodSubType($event,'lunch')">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of lunchSubfoodType" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select disableOptionCentering class="ipcss widthappt" required
                                        [(ngModel)]="lunchFoodQty" (ngModelChange)="changeQuantity($event,'lunch')">
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchWeight" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchEnergy" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietLunch()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="lunchFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive" *ngIf="dietLunchListData.length != 0"
                                  style="margin-top: 15px;">
                                  <table *ngIf="dietLunchListData.length != 0" class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let lunch of dietLunchListData">
                                        <td data-th="Category">{{lunch.category}}</td>
                                        <td data-th="Food type">{{lunch.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{lunch.description}}</td>
                                        <td data-th="Quantity">{{lunch.quantity}}</td>
                                        <td data-th="Measure">{{lunch.measure}}</td>
                                        <td data-th="Weight(g)/Qty">{{lunch.weight}}</td>
                                        <td data-th="Calories">{{lunch.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="lunch_edit(lunch.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietLunch(lunch.description)" />
                                          <!-- <a (click)="deleteDietLunch(lunch.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //evening Snacks -->
                      <div class="tab-pane fade show" id="Evening" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select [(ngModel)]="eveFCategory" id="eveCategory"
                                        (ngModelChange)="changeFoodCategory('eve')" required disableOptionCentering
                                        class="ipcss ">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select [(ngModel)]="eveFType" id="evefoodtype" class="ipcss " required
                                        (ngModelChange)="changeFoodType('eve')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of eveFoodType" value="{{food}}">{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select [(ngModel)]="eveSubFType" id="evesubfoodtype" class="ipcss "
                                        (ngModelChange)="changeFoodSubType($event,'eve')" disableOptionCentering
                                        required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of eveSubfoodType" value="{{food.description}}">
                                          {{food.description}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select [(ngModel)]="eveFoodQty" id="eveQuantity" class="ipcss"
                                        (ngModelChange)="changeQuantity($event,'eve')" required>
                                        <option disabled>Select </option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="eveMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eveWeight"
                                        minlength="3" [readonly]="isReadonly()" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eveEnergy"
                                        minlength="3" [readonly]="isReadonly()" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div>
                                      <a><img (click)="addDietEvening()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="eveFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive" *ngIf="dietEveListData.length != 0">
                                  <table *ngIf="dietEveListData.length != 0" class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let eve of dietEveListData">
                                        <td data-th="Category">{{eve.category}}</td>
                                        <td data-th="Food type">{{eve.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{eve.description}}</td>
                                        <td data-th="Quantity">{{eve.quantity}}</td>
                                        <td data-th="Measure">{{eve.measure}}</td>
                                        <td data-th="Weight(g)/Qty">{{eve.weight}}</td>
                                        <td data-th="Calories">{{eve.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="evening_edit(eve.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietEvening(eve.description)" />
                                          <!-- <a (click)="deleteDietEvening(eve.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //dinner Snacks -->
                      <div class="tab-pane fade show" id="dinner" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select [(ngModel)]="dinnerFCategory" id="dinnerCategory"
                                        (ngModelChange)="changeFoodCategory('dinner')" required disableOptionCentering
                                        class="ipcss ">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select [(ngModel)]="dinnerFType" id="dinnerfoodtype" class="ipcss " required
                                        (ngModelChange)="changeFoodType('dinner')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of dinnerFoodType" value="{{food}}">{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select [(ngModel)]="dinnerSubFType" id="dinnersubfoodtype" class="ipcss "
                                        (ngModelChange)="changeFoodSubType($event,'dinner')" disableOptionCentering
                                        required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of dinnerSubfoodType" value="{{food.description}}">
                                          {{food.description}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select [(ngModel)]="dinnerFoodQty" id="dinnerQuantity" class="ipcss"
                                        (ngModelChange)="changeQuantity($event,'dinner')" required>
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" [readonly]="isReadonly()" id="formqnty"
                                        class="ipcss" [(ngModel)]="dinnerMeasure" minlength="3" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" [readonly]="isReadonly()" id="formqnty"
                                        class="ipcss" [(ngModel)]="dinnerWeight" minlength="3" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" [readonly]="isReadonly()" id="formqnty"
                                        class="ipcss" [(ngModel)]="dinnerEnergy" minlength="3" aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietDinner()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="dinnerFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive" *ngIf="dietDinnerListData.length != 0">
                                  <table *ngIf="dietDinnerListData.length != 0" class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let dinner of dietDinnerListData">
                                        <td data-th="Category">{{dinner.category}}</td>
                                        <td data-th="Food type">{{dinner.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{dinner.description}}</td>
                                        <td data-th="Quantity">{{dinner.quantity}}</td>
                                        <td data-th="Measure">{{dinner.measure}}</td>
                                        <td data-th="Weight(g)/Qty">{{dinner.weight}}</td>
                                        <td data-th="Calories">{{dinner.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="dinner_edit(dinner.description)" /> &nbsp;&nbsp;&nbsp; -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietDinner(dinner.description)" />
                                          <!-- <a (click)="dinner_snackdelete(dinner.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- //night Snacks -->
                      <div class="tab-pane fade show" id="night_snacks" role="tabpanel" aria-labelledby="morning-tab">
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div class="row">
                                  <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                      <select [(ngModel)]="nightFCategory" id="nightCategory"
                                        (ngModelChange)="changeFoodCategory('night')" required disableOptionCentering
                                        class="ipcss ">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategory" value={{food.description}}>
                                          {{food.description}}
                                        </option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                      <select [(ngModel)]="nightFType" id="nightfoodtype" class="ipcss " required
                                        (ngModelChange)="changeFoodType('night')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of nightFoodType" value="{{food}}">{{food}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                      <select [(ngModel)]="nightSubFType" id="nightsubfoodtype" class="ipcss "
                                        (ngModelChange)="changeFoodSubType($event,'night')" disableOptionCentering
                                        required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of nightSubfoodType" value="{{food.description}}">
                                          {{food.description}}</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                      <select [(ngModel)]="nightFoodQty" id="nightQuantity" class="ipcss"
                                        (ngModelChange)="changeQuantity($event,'night')" required>
                                        <option disabled>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="24">24</option>
                                        <option value="26">26</option>
                                        <option value="28">28</option>
                                        <option value="30">30</option>
                                        <option value="32">32</option>
                                        <option value="40">40</option>
                                        <option value="42">42</option>
                                        <option value="48">48</option>
                                        <option value="60">60</option>
                                        <option value="1/8">1/8</option>
                                        <option value="1/4">1/4</option>
                                        <option value="1/3">1/3</option>
                                        <option value="1/2">1/2</option>
                                        <option value="3/4">3/4</option>
                                        <option value="11/2">11/2</option>
                                        <option value="21/2">21/2</option>
                                        <option value="21/8">21/8</option>
                                        <option value="91/2">91/2</option>
                                        <option value="2/3">2/3</option>
                                        <option value="41/4">41/4</option>
                                      </select>
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightMeasure" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightWeight" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                      <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightEnergy" minlength="3" [readonly]="isReadonly()"
                                        aria-required="true">
                                    </mat-label>
                                  </div>
                                  <div class="col-12">
                                    <div>
                                      <a><img (click)="addDietNight()"
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div [hidden]="nightFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="dig_table_overflow" style="margin-top: 15px;">
                                <div class="table-responsive" *ngIf="dietNightListData.length != 0">
                                  <table *ngIf="dietNightListData.length != 0" class="table table-hover table-dynamic">
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Food sub type</th>
                                        <th>Quantity</th>
                                        <th>Measure</th>
                                        <th>Qty</th>
                                        <th>Cals</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let night of dietNightListData">
                                        <td data-th="Category">{{night.category}}</td>
                                        <td data-th="Food type">{{night.food_sub_type}}</td>
                                        <td data-th="Food sub type">{{night.description}}</td>
                                        <td data-th="Quantity">{{night.quantity}}</td>
                                        <td data-th="Measure">{{night.measure}}</td>
                                        <td data-th="Weight(g)/Qty1">{{night.weight}}</td>
                                        <td data-th="Calories">{{night.energy}}</td>
                                        <td data-th="Action">
                                          <!-- <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" style="width: 20px; height: 20px;" (click)="night_edit(night.description)" />  -->
                                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            style="width: 20px; height: 20px;"
                                            (click)="deleteDietNight(night.description)" />
                                          <!-- <a (click)="night_snackdelete(night.description)">Delete</a> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div [hidden]="medicationFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Medication
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <!-- <p class="tervys_heading"><strong>Observations</strong></p> -->
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12">
                      <div class="cover_div">
                        <div class="content_cover">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Date
                                <input type="date" class="ipcss" [(ngModel)]="date" (click)="selectDate(date)"
                                  [max]="currentMaxDate" matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5">
                              <mat-label class="matlabel">Time</mat-label>
                              <div class="row">
                                <div class="col-3">
                                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart1">
                                    <mat-option *ngFor="let name of timeStartsFrom" value={{name}}>{{name}}
                                    </mat-option>
                                  </mat-select>
                                </div>
                                <div class="col-1 my-auto">
                                  <span>:</span>
                                </div>
                                <div class="col-4">
                                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart2">
                                    <mat-option *ngFor="let name of timeEndsAt" value={{name}}>{{name}}</mat-option>
                                  </mat-select>
                                </div>
                                <div class="col-4">
                                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart3">
                                    <mat-option value="AM">AM</mat-option>
                                    <mat-option value="PM">PM</mat-option>
                                  </mat-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Intake<br>
                                <mat-select disableOptionCentering class="ipcss " [(ngModel)]="visitSession"
                                  (ngModelChange)="changeIntakeSession($event)">
                                  <mat-option *ngFor="let session of visitingSessionData" value={{session.description}}>
                                    {{session.description}}</mat-option>
                                </mat-select>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;">
                      <p *ngIf="noData" class="nodata">No medicine found</p>
                      <div class="dig_table_overflow"
                        style="border: 1px solid #51b0e7;border-radius: 7px;height: 100%;">
                        <div class="table-responsive">
                          <table id="tbl" class="table table-hover table-dynamic" cellpadding="5" cellspacing="0"
                            *ngIf="medicineHistList.length !=0">
                            <thead>
                              <tr>
                                <th>Medicine name</th>
                                <th>Quantity</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let medicine of medicineHistList; let i=index;">
                                <td style="text-align: left;" data-th="Medicine name">{{medicine.drug_name}}</td>
                                <td style="text-align: left;" data-th="Quantity" style="width: 70px;">
                                  <input class="ipcss" style="width: 70px;" [(ngModel)]="medicine.quantity"
                                    maxlength="3" matInput>
                                </td>
                                <td style="width: 50px;">{{medicine.short_form}}</td>
                                <td data-th="Delete"><img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                    (click)="deleteDrug(medicine.drug_id,i)" class="editdelicon"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div [hidden]="physicalFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Physical Activities
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12">
                      <div class="cover_div">
                        <div class="content_cover">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Excercise<br>
                                <select disableOptionCentering class="ipcss " required
                                  (change)="changeExercise(exercise)" [(ngModel)]="exercise">
                                  <option *ngFor="let exercise of phyExerList" value={{exercise.description}}>
                                    {{exercise.description}}</option>
                                </select>
                              </mat-label>
                            </div>
                            <div [hidden]="yogaFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label [hidden]="yogaFlag" class="matlabel">Yogasanas<br>
                                <div class="yoga">
                                  <div *ngFor="let yoga of yogaList">
                                    <mat-checkbox color="primary"
                                      (change)="changeYoga(yoga.yoga_asana_id,$event.checked)"
                                      [checked]="yogaCheck.indexOf(yoga.yoga_asana_id) > -1"></mat-checkbox>
                                    {{yoga.description}}
                                  </div>
                                </div>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Duration<br>
                                <select disableOptionCentering class="ipcss " required [(ngModel)]="exerDuration">
                                  <option value="10 mins">10 mins</option>
                                  <option value="15 mins">15 mins</option>
                                  <option value="30 mins">30 mins</option>
                                  <option value="45 mins">45 mins</option>
                                </select>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Frequency<br>
                                <select disableOptionCentering class="ipcss " required [(ngModel)]="exerFrequency">
                                  <option value="Daily">Daily</option>
                                  <option value="Thrice a week">Thrice a week</option>
                                  <option value="4 days a week">4 days a week</option>
                                  <option value="5 days a week">5 days a week</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>