import { Component, OnInit, ElementRef, Input, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Data, Route, Router } from '@angular/router';

@Component({
  selector: 'app-document-details-zoom-page',
  templateUrl: './document-details-zoom-page.component.html',
  styleUrls: ['./document-details-zoom-page.component.css']
})
export class DocumentDetailsZoomPageComponent implements OnInit {
  @Input() id: string;parm: string;  
  public pdfSrc;
  public result;
  public pdfFlag: boolean;
  public controllerSrc: any;

  constructor(public comm_Data:CommonDataService,public el: ElementRef,public toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<DocumentDetailsZoomPageComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer) {
        dialogRef.disableClose = true;
      this.pdfFlag = false;
      this.pdfSrc =this.data.report_pdf;
      const url = this.pdfSrc;
      this.controllerSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  

  ngOnInit(): void {
  }
  
  ClosePopup(){
    this.dialogRef.close(this.result);
  }
}
