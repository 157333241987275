import { Component, OnInit, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CancelAppointmentComponent } from '../cancel-appointment/cancel-appointment.component';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-second-opinion-casesheet-observations',
  templateUrl: './second-opinion-casesheet-observations.component.html',
  styleUrls: ['./second-opinion-casesheet-observations.component.css']
})
export class SecondOpinionCasesheetObservationsComponent implements OnInit {

  public observation = 'min';
  public gen_obser;
  public med_obser;
  public diag_obser;
  public subscription:Subscription;
  public medical_obser;
  public save_button: boolean;
  public general_editor: boolean;
  public general_text: boolean;
  public Medical_editor: boolean;
  public Medical_text: boolean;
  public Diag_editor: boolean;
  public Diag_text: boolean;
  public can_btn: boolean;
  public CurrentDatetime;
  public Appoint_date;
  public date_txt1;
  public header_footer_flag: boolean;
  public gen_observation: boolean;
  public medical_observation: boolean;
  public diagnosis_observation: boolean;
  public nodapp: boolean;
  //Get observation retrivel
  public general_observation;
  public med_observation;
  public diag_observation;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public dialog: MatDialog,public messageservice1: SecondOpinionService) { }

  ngOnInit(): void {
    this.Get_current_date();
    this.subscription = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
        if (message) {
            this.observation = message;
        }
    });

    this.general_editor = true;
    this.Medical_editor = true;
    this.Diag_editor = true;

    this.gen_observation = true;
    this.medical_observation = true;
    this.diagnosis_observation = true;
    this.nodapp = true;
    this.general_text = true;
    this.Medical_text = true;
    this.save_button = true;
    this.can_btn = true;
  }

    canel_opn() {
        const dialogRef = this.dialog.open(CancelAppointmentComponent, {
            width: '500px',
            height: '250px'
        });
  
        dialogRef.afterClosed().subscribe(result => {});
    }

    Get_current_date() {//Get current date
        var obj = Master_Helper.getMasterCurrentDate();
        if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            var date = this.CurrentDatetime.split("-");
            this.Appoint_date = date[2] + "-" + date[1] + "-" + date[0]
        }
        this.Get_observation_retrivel();
    }

    save_opn() {
        if (this.gen_obser == undefined || this.gen_obser == "") {
            this.toastr.error(Message_data.enterObserv);
        }else {
            var med_obser = undefined, diag_obs = undefined;
            if (this.med_obser != undefined) {
                med_obser = this.med_obser;
            }

            if (this.diag_obser != undefined) {
                diag_obs = this.diag_obser;
            }

            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'secopn/updsecopn/',
                JSON.stringify({
                    opinion_id: Helper_Class.getOpnRet().opn_doc_id,
                    gen_observ: this.gen_obser,
                    med_observ: med_obser,
                    diag_observ: diag_obs,
                    opn_doc_id: Helper_Class.getOpnRet().opn_doc_id
                }),
                { headers: headers })
                .subscribe(
                    response => {
                        var obj = response.json();
                        if (obj.key != null) {
                            if (obj.key == "1") {
                                this.toastr.success(Message_data.opnUpdSuccess);;
                            }
                            else {
                                this.toastr.error(Message_data.opnUpdNotSuccess);;
                            }
                        }
                    },
                    error => {
                        this.toastr.error(Message_data.defaultErr);
                    }
                )
        }
    }

    Get_observation_retrivel() {
        var getOpnRet = Helper_Class.getOpnRet();
        if (getOpnRet.gen_observ != null && getOpnRet.med_observ != undefined && getOpnRet.diag_observ != "") {
            this.save_button = true;
            this.can_btn = true;
        }else {
            this.gen_observation = false;
            this.general_editor = false;
            this.medical_observation = false;
            this.Medical_editor = false;
            this.diagnosis_observation = false;
            this.Diag_editor = false;
        }

        if (getOpnRet.gen_observ != null  && getOpnRet.gen_observ != undefined && getOpnRet.gen_observ != "") {
            this.general_observation = getOpnRet.gen_observ;
            this.save_button = false;
            this.general_editor = true;
            this.general_text = false;
            this.gen_observation = false;
        }

        if (getOpnRet.med_observ != null  && getOpnRet.med_observ != undefined && getOpnRet.med_observ != "" ) {
            this.med_observation = getOpnRet.med_observ;
            this.save_button = false;
            this.Medical_editor = true;
            this.Medical_text = false;
            this.medical_observation = false;
        }
        if (getOpnRet.diag_observ != null  && getOpnRet.diag_observ != undefined && getOpnRet.diag_observ != "") {
            this.diag_observation = getOpnRet.diag_observ;
            this.save_button = false;
            this.Diag_editor = true;
            this.Diag_text = false;
            this.diagnosis_observation = false;
        }

        if (getOpnRet.appointment_date != null && getOpnRet.from_time != null && getOpnRet.appointment_date != undefined && getOpnRet.from_time != undefined && getOpnRet.appointment_date != "" && getOpnRet.from_time != "") {
            this.save_button = true;
        }else {
            this.can_btn = false;
        }

        if (getOpnRet.opinion_desc != "Written") {
            if (getOpnRet.gen_observ != null  && getOpnRet.gen_observ != undefined && getOpnRet.gen_observ != "") {
                this.save_button = true;
            }else {
                this.Diag_text = false;
                this.Medical_text = false;
                this.general_text = false;
                this.general_editor = false;
                this.Medical_editor = false;
                this.Diag_editor = false;
            }
        }

        if (getOpnRet.opinion_desc != "Written") {
            if (getOpnRet.appointment_date != null) {
                var date_one = getOpnRet.appointment_date.split('-');
                this.date_txt1 = date_one[2] + "-" + date_one[1] + "-" + date_one[0];
            }
            
            if (this.date_txt1 == this.Appoint_date) {
                this.save_button = false;
            }
        }else {
            this.save_button = false;
            this.can_btn = false;
        }

        if (getOpnRet.gen_observ != null || getOpnRet.med_observ != null || getOpnRet.diag_observ != null) {
            this.save_button = true;
            this.can_btn = true;
        }

        if (getOpnRet.is_cancelled != null) {
            this.save_button = true;
            this.can_btn = true;
        }
        if (getOpnRet.status != null) {
            if (getOpnRet.status == "0") {
                this.save_button = true;
                this.can_btn = true;
            }
        }
    }
}
