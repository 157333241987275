<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('diet_dashboard');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" id="manageappointments" (click)="menuNav('manageappointments');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Doctor appointments</span></div>
                    </div>
                    <div class="menu" id="patientList" (click)="menuNav('patientList');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Patient list</span></div>
                    </div>
                    <div class="menu" id="readings" (click)="menuNav('readings');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Diab Readings</span></div>
                    </div>
                    <div class="menu" id="billing" (click)="menuNav('billList');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Billing</span></div>
                    </div>
                    <div class="menu" id="notification" (click)="menuNav('notification');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/notification.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"><span>Notification</span></div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" > <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                                    class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
                            </div>
                            <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                    src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span  class="header_model_menu">Log out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'manageappointments'">
                                <app-doc-app-list></app-doc-app-list>
                            </div>
                            
                            <div *ngSwitchCase="'profile'">
                                <app-profile-update></app-profile-update>
                            </div>
                            <div *ngSwitchCase="'dietPlan'">
                                <app-dietplan></app-dietplan>
                            </div>
                            <div *ngSwitchCase="'dietPlancard'">
                                <app-dietplan></app-dietplan>
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-diet-appointments></app-diet-appointments>
                            </div>
                            <div *ngSwitchCase="'appt_create'">
                                <app-diet-appointments-creation></app-diet-appointments-creation>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-diet-casesheet></app-diet-casesheet>
                            </div>
                            <div *ngSwitchCase="'notification'">
                                <app-doctor-notification></app-doctor-notification>
                            </div>
                            <div *ngSwitchCase="'patientList'">
                                <app-diet-patient-list></app-diet-patient-list>
                            </div>
                            <div *ngSwitchCase="'patientTracking'">
                                <app-diet-tracking></app-diet-tracking>
                            </div>
                            <div *ngSwitchCase="'billList'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'billCreate'">
                                <app-bill-create></app-bill-create>
                            </div>
                            <div *ngSwitchCase="'billdetail'">
                                <app-bill-detail-view></app-bill-detail-view>
                            </div>
                            <div *ngSwitchCase="'readings'">
                                <app-diet-patient-list></app-diet-patient-list>
                            </div>
                            <div *ngSwitchCase="'diabReadings'">
                                <app-diab-readings></app-diab-readings>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchDefault="'diet_dashboard'">
                                <app-diet-home></app-diet-home>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>