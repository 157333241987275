import { Component, OnInit, ViewChild } from '@angular/core';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { DietService } from '../diet-view/diet-view.service';
import { ServerApi } from 'src/app/server-api';
import { Diet_Helper } from '../Diet_Helper';
import { Http, Headers } from '@angular/http';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diet-appointments',
  templateUrl: './diet-appointments.component.html',
  styleUrls: ['./diet-appointments.component.scss']
})
export class DietAppointmentsComponent implements OnInit {
  @ViewChild('dateTime') dateTime;
  masterSelected: boolean = false;
  public apptOpenCount:number = 0;
  public checkedList: any;
  public currentMaxDate = null;
  public cancelList = [];
  public nodapp: boolean;
  public appointmentList = [];
  public session;
  public appointmentDate: string;
  public userInfo:any = [];
  public userId;
  public checkList = [];
  public checkListId:any = [];
  public Doctor_List_Data = [];
  public doctor;
  public doctorFlag:boolean;
  public event = {
    month: '1990-02-19',
    timeStarts: '07:43',
    timeEnds: '1990-02-20'
  }
  dtOptions: DataTables.Settings = {};

  constructor( public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService, public frontdeskservice:MenuViewService) {
    this.session = "Morning";
    this.nodapp = true; 
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.getCurrentDate();
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentMaxDate = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointmentDate = obj.current_date;
            if(Diet_Helper.getAppFlow() == "Dietician"){
              this.doctorFlag = false;
              this.getDoctorAppt();
            }
          }
        },error => {});
  }

  checkUncheckAll() {
    this.checkList = this.appointmentList;
    if (this.checkListId != "" && this.checkListId.length != 0) {
      if (this.checkListId.length == this.apptOpenCount) {
        this.checkListId = [];
        for (var i = 0; i < this.checkList.length; i++) {
          this.checkList[i].isSelected = this.masterSelected;
        }
      } else {
        this.checkListId = [];
        for (var i = 0; i < this.checkList.length; i++) {
          if (this.checkList[i].status == 'Open' || this.checkList[i].status == 'Confirmed') {
            var doc_id = this.checkList[i].doc_app_id;
            this.checkListId.push(doc_id);
            this.checkList[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i].status == 'Open' || this.checkList[i].status == 'Confirmed') {
          var doc_id = this.checkList[i].doc_app_id;
          this.checkListId.push(doc_id);
          this.checkList[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  selectAppointmentDate(value) {
    this.appointmentDate = value;
    this.getDoctorAppt();
  }

  getDoctorAppt(): void {
    this.appointmentList = [];
    var get_tmp = this.appointmentDate.split('-');
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/npapps',
      JSON.stringify({
        prov_id: this.userId,
        country: ipaddress.country_code.toString(),
        date: this.appointmentDate,
        type: "dietician",
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          console.log("appointments ((())) "+JSON.stringify(data.appointments))
          if (data.appointments != null) {
            var appolist = data.appointments;
            if (appolist.length != "0") {
              for (var i = 0; i < appolist.length; i++) {
                if (appolist[i].status == "0") {
                  var status_txt = "Completed";
                } else if (appolist[i].status == "1") {
                  if (appolist[i].date == this.currentMaxDate || this.currentMaxDate < appolist[i].date) {
                    if (appolist[i].f_time != "1") {
                      var get_time = new Date(appolist[i].date + " " + this.currentMaxDate);
                      var cur_time = new Date(this.currentMaxDate + " " + this.currentMaxDate);
                      if (get_time > cur_time) {
                        status_txt = "Not visited";
                      }
                      else {
                        status_txt = "Open";
                      }
                    } else {
                      var get_time = new Date(appolist[i].date);
                      var cur_time = new Date(this.currentMaxDate);
                      if (get_time > cur_time || get_time == cur_time) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else {
                    if (appolist[i].date > this.currentMaxDate || appolist[i].date == this.currentMaxDate) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else if (appolist[i].status == "2") { 
                  if (appolist[i].date == this.currentMaxDate || this.currentMaxDate < appolist[i].date) {
                    var get_time = new Date(appolist[i].date + " " + this.currentMaxDate);
                    var cur_time = new Date(this.currentMaxDate + " " + this.currentMaxDate);
                    status_txt = "Confirmed";
                  } else {
                    if (appolist[i].date > this.currentMaxDate || appolist[i].date == this.currentMaxDate) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                }
                if (appolist[i].token != null) {
                  var token_time_label =appolist[i].token;
                } else {
                  var Temptimesplit = appolist[i].f_time;
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');
                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {
                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                }
                var Patient_name = null;
                if (appolist[i].middle_name != undefined && appolist[i].middle_name != null) 
                {
                  if (encrypt_decript.Decript(appolist[i].middle_name) != "") 
                  {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                  else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                }
                else {
                  Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                }
                if (appolist[i].sub_rel_id != null) {
                  var sub_rel = appolist[i].sub_rel_id;
                }
                else {
                  sub_rel = "0";
                }
                if (appolist[i].age != null) {
                  var get_age = appolist[i].age;
                }
                else {
                  get_age = "";
                }
                if (appolist[i].address2 != null && appolist[i].address2 != "") {
                  var add_data = appolist[i].address1 + " " + appolist[i].address2;
                }
                else {
                  add_data = appolist[i].address1;
                }
                if (appolist[i].rel_zipcode != null) {
                  var zipcode_data = encrypt_decript.Decript(appolist[i].rel_zipcode);
                }
                else {
                  zipcode_data = appolist[i].zipcode;
                }
                if( status_txt == 'Open'){
                  this.apptOpenCount =  this.apptOpenCount + 1; 
                }
                var gentext;
                if(appolist[i].gender != undefined){
                  gentext=encrypt_decript.Decript(appolist[i].gender);
                }

                this.appointmentList.push({
                  app_data: appolist[i],
                  hptl_clinic_id: appolist[i].hptl_clinic_id,
                  patient_name: Patient_name,
                  tot_app_count: token_time_label,
                  session: encrypt_decript.Decript(appolist[i].session),
                  status: status_txt,
                  profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                  doc_app_id: appolist[i].app_id,
                  client_reg_id: appolist[i].client_reg_id,
                  relation_id: appolist[i].relation_id,
                  sub_rel: sub_rel,
                  rel_count: appolist[i].country_id,
                  rel_stat: appolist[i].state_id,
                  rel_city: appolist[i].city_id,
                  rel_loc: appolist[i].location_id,
                  rel_loc_desc: appolist[i].location,
                  loc_age: get_age,
                  gender_data: gentext,
                  dob_data: appolist[i].dob,
                  life_style_id : appolist[i].life_style_id,
                  spl_id: appolist[i].spl_id,
                  disease : appolist[i].disease,
                  address: add_data,
                  state_desc: appolist[i].state_desc,
                  city_desc: appolist[i].city_desc,
                  country_desc: appolist[i].country_desc,
                  rel_zipcode: zipcode_data,
                  appt_date:appolist[i].date,
                  mobile : appolist[i].mobile  != undefined ? encrypt_decript.Decript(appolist[i].mobile) : "",
                }); 
              }
              this.nodapp = true; 
            } else {
              this.nodapp = false; 
            }
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewAppointment(list) {
    if(list.client_reg_id != undefined){
      if(Diet_Helper.getAppFlow() == "Dietician"){
        Diet_Helper.setClient_Info(null);
        var patient_list:any = [];
        Helper_Class.Set_client_view_app_data(list.app_data);
        Diet_Helper.setClient_Info(list.app_data);
        patient_list = {
          Age_data: list.loc_age,
          Gender_data:list.gender_data,
          app_id: list.doc_app_id,
          client_name: list.patient_name,
          disease : list.disease,
          specialization_id : list.spl_id,
          Client_id:list.client_reg_id,
          rel_id:list.relation_id,
          sub_id : list.sub_rel_id,
          life_style_id : list.life_style_id,
          AppFlow:"DietApp"
      };
      Diet_Helper.setClient_Info(patient_list);
      this.getCaseSheetData();
      }

    } else {
      this.toastr.error(Message_data.getDietAppUpdate());
    }
  }

  getCaseSheetData() {
    Diet_Helper.setDischarge_Summary(undefined);
    var sen_pass
    if (Diet_Helper.getClient_Info().sub_id != null && Diet_Helper.getClient_Info().sub_id != "" && Diet_Helper.getClient_Info().sub_id) {
      sen_pass = {
        doc_reg_id: this.userInfo.user_id,
        client_reg_id: Diet_Helper.getClient_Info().Client_id,
        relation_id: Diet_Helper.getClient_Info().rel_id,
        sub_rel_id: Diet_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      };
    } else {
      sen_pass = {
        doc_reg_id: this.userInfo.user_id,
        client_reg_id: Diet_Helper.getClient_Info().Client_id,
        relation_id: Diet_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      };
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Diet_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            }else {
              Helper_Class.setcasesumret(undefined);
            }
          this.messageService.sendMessage("casesheet");
        },
        error => {});
  }

  clearAll() {
    this.checkList = this.appointmentList;
    for (var i = 0; i < this.checkList.length; i++) {
      this.checkList[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  } 

  isAllSelected(appid) {
    if (this.checkListId != "" && this.checkListId.length != 0) {
      if (this.checkListId.indexOf(appid) !== -1) {
        var index = this.checkListId.indexOf(appid);
        this.checkListId.splice(index, 1);
      } else {
        this.checkListId.push(appid);
      }
    } else {
      this.checkListId.push(appid);
    }
  }

  cancel() {
    if (this.checkListId != "" && this.checkListId.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          diet_cancel: this.checkListId,
          home_care: "0",
          type: "dietician",
          reason: "Busy"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.getDoctorAppt();
              }
            }
          },error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  create(){
    if(Diet_Helper.getAppFlow() == "Dietician")
      this.messageService.sendMessage("appt_create");
  }
  appointmentConfirm(){
    console.log("this.checkListId" + JSON.stringify(this.checkListId));
    if (this.checkListId != "" && this.checkListId.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "dietician",
          doc_apps: this.checkListId,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }
}
