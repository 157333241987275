import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-micro-modal',
  templateUrl: './micro-modal.component.html',
  styleUrls: ['./micro-modal.component.scss']
})
export class MicroModalComponent implements OnInit {
  public medicineList: any = [];
  public sensitivityCardList: any = [];
  public isSensitive: boolean = false;
  public  colonyCount_array: any[];
  public colony_input: any;
  public culture_report_notes: any;
  public  sensitivityTypeList: any[];
  public antibiotics: { culture_report_notes: any; medicine_list: any; };

  constructor(public messageservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog, public modalRef: MdbModalRef<MicroModalComponent>) { }

  ngOnInit(): void {
    this.getSensitivityType();
    this.getMedicineList();
  }
  getMedicineList(){
    // this.medicineList = Helper_Class.getmicromodalData();
    var medicine = [];
     medicine = Helper_Class.getmicromodalData();
    console.log("medicineList -medicine", medicine) 

    for (var i = 0; i < medicine.length; i++){
      this.medicineList.push({
        sensitive_drug_desc: medicine[i].sensitive_drug_desc,
        sensitive_drug_id: medicine[i].sensitive_drug_id,
        culture_sensitive_id: "2"
      })
    }

    console.log("medicineList", this.medicineList)    
  }

  getSensitivityType() {
    this.sensitivityTypeList = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcs', { diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getSensitivityType", obj)
          
          this.sensitivityTypeList = obj.culture_sensitives;
        }, error => { });

  }

  onSensitivyChange(sensitivityId, med, medId){

    for (var i=0; i< this.medicineList.length; i++){
      if (this.medicineList[i].culture_sensitive_id == '1'){
        this.medicineList[i].isSensitive = true;
      } else {
        this.medicineList[i].isSensitive = false;
      }
    }

    if(sensitivityId == '1'){
      this.sensitivityCardList.push({
        med_Name: med,
        med_id: medId
      })
      this.isSensitive = true;
      
    } else {
      for (var i = 0; i < this.sensitivityCardList.length; i++) {
        if (this.sensitivityCardList[i].med_id == medId) {           
          this.sensitivityCardList.splice(i, 1);
          break;
        }
      }
      this.isSensitive = false;
    }
  }

  saveColonyCountData() {
    this.colonyCount_array = [];
    console.log(this.medicineList)
    this.colony_input = "..."

    var sensitiveMedicines = "";
    for(var i = 0; i < this.sensitivityCardList.length; i++){
     
      if(i != 0){
        sensitiveMedicines +=  ",  " + this.sensitivityCardList[i].med_Name ;
      } else {
        sensitiveMedicines +=  this.sensitivityCardList[i].med_Name;
      }
      // sensitiveMedicines += this.sensitivityCardList[i].med_Name + ",  ";      
    }
    
    console.log(sensitiveMedicines)
    this.culture_report_notes = sensitiveMedicines; 

    this.antibiotics = {
      culture_report_notes: this.culture_report_notes,
      medicine_list: this.medicineList
    }
    
    this.modalRef.close(this.antibiotics);
  }

}
