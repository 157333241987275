<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">APGAR score</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
   </div>
 </div>
<div mat-dialog-content class="apsg_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
        <mat-label>Heart rate<br>
          <select class="ipcss" [(ngModel)]="apgar_one_heart_rate">
            <option disabled value="Select">Select</option>
            <option value="No heart rate">No heart rate</option>
            <option value="Fewer than 100 beats per minute indicates that the baby is not very responsive">Fewer than
              100 beats per minute indicates that the baby is not very responsive</option>
            <option value="More than 100 beats per minute indicates that the baby is vigorous">More than 100 beats per
              minute indicates that the baby is vigorous</option>
          </select>
        </mat-label>
       </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
        <mat-label>Respiration<br>
          <select class="ipcss" [(ngModel)]="apgar_one_resp">
            <option disabled value="Select">Select</option>
            <option value="Not breathing">Not breathing</option>
            <option value="Weak cry–may sound like whimpering or grunting">Weak cry–may sound like whimpering or
              grunting</option>
            <option value="Good, strong cry">Good, strong cry</option>

          </select>
        </mat-label>
       </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
        <mat-label>Muscle tone<br>
          <select class="ipcss" [(ngModel)]="apgar_one_muscle">
            <option disabled value="Select">Select</option>
            <option value="Limp">Limp</option>
            <option value="Some flexing (bending) of arms and legs">Some flexing (bending) of arms and legs</option>
            <option value="Active motion">Active motion</option>

          </select>
        </mat-label>
       </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
        <mat-label>Reflex response<br>
          <select class="ipcss" [(ngModel)]="apgar_one_reflex">
            <option disabled value="Select">Select</option>
            <option value="No response to airways being stimulated">No response to airways being stimulated</option>
            <option value="Grimace during stimulation">Grimace during stimulation</option>
            <option value="Grimace and cough or sneeze during stimulation">Grimace and cough or sneeze during
              stimulation</option>

          </select>
        </mat-label>
       </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
        <mat-label>Color<br>
          <select class="ipcss" [(ngModel)]="apgar_one_color">
            <option disabled value="Select">Select</option>
            <option value="The baby’s entire body is blue or pale">The baby’s entire body is blue or pale</option>
            <option value="Good color in body but with blue hands or feet">Good color in body but with blue hands or
              feet</option>
            <option value="Completely pink or good color">Completely pink or good color</option>
            <option value="Limp">Limp</option>

          </select>
        </mat-label>
       </div>
     </div>
   </div>
 </div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="Apgar_scorefiveclick()" [hidden]="apgarfive_hidden" id="add_btn" [mat-dialog-close]="count_apgarfive"
    cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="addimgbtn_icd" />
  </button>
  <button (click)="Apgar_scoreoneclick()" [hidden]="apgarone_hidden" id="add_btn" [mat-dialog-close]="count_apgarone"
    cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="addimgbtn_icd" />
  </button>
 </div>