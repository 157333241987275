<div class="body">
    <app-header></app-header>
    <div class="mainsec top_style">
      <div class="row">
          <div class="col-12">
              <mat-accordion class="mataccordion">
                  <mat-expansion-panel class="expandpanel " [expanded]="true">
                      <mat-expansion-panel-header class="exppanel width">
                          <mat-panel-title class="title">
                              Profile
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr class="hr">
                      <p class="docreg_heading">Personal information</p>
                      <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
                              style="text-align: center;position: relative;">
                              <div style="position: relative;width: 160px;margin: 0 auto;">
                                  <img id="profile_img" class="pro_img_cls" src="../../assets/img/default.jpg" />
                                  <input type="file" (change)="fileChange($event)" name="profile_pic" id="profile_pic">
                                  <!--  (mouseover)="showsupload=true" (mouseout)="showsupload=false" *ngIf="showsupload"-->
                                  <label for="profile_pic" class="edit_profile_icon"><i
                                          class="fas fa-pencil-alt"></i></label>
                              </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                              <div class="row">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">First Name<br>
                                          <input type="text" class="ipcss" required [(ngModel)]="f_name" maxlength="50"
                                              onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"
                                              matInput (change)="Acitvate_savebtn()"/>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Middle Name<br>
                                          <input type="text" class="ipcss" [(ngModel)]="m_name" maxlength="50"
                                              onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"
                                              matInput />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Last Name<br>
                                          <input type="text" class="ipcss  " required [(ngModel)]="l_name" maxlength="50"
                                              onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"
                                              matInput (change)="Acitvate_savebtn()"/>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Gender<br>
                                          <select disableOptionCentering
                                              class="ipcss" required [(ngModel)]="gend_txt">
                                              <option disabled>Select</option>
                                              <option value="Male">Male</option>
                                              <option value="Female">Female</option>
                                              <option value="Transgender">Transgender</option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">DOB<br>
                                          <input [(ngModel)]="Doctor_DOB" (change)="Date_DOB(Doctor_DOB)"  type="date" class="ipcss_date dob_style" matInput />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Age
                                      <input [(ngModel)]="docAge" [readonly]="ageRead" [required]="!ageRead" class="ipcss widthappt"
                                        required matInput [disabled]="ageDisabledFlag">
                                    </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Email<br>
                                          <input type="text" class="ipcss " [(ngModel)]="Doc_email" maxlength="50" autocomplete="false" (click)="Doc_email=''"
                                          readonly onfocus="this.removeAttribute('readonly');" matInput />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Mobile No<br>
                                          <input type="text" class="ipcss" [(ngModel)]="Doc_mobile" maxlength="10"
                                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                              matInput />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Password<br>
                                          <input class="ipcss" [(ngModel)]="Create_password" type="password"  autocomplete="new-password" 
                                              maxlength="15" required matInput  onfocus="this.type='password'"  (change)="Acitvate_savebtn()"/>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                      <mat-label class="matlabel">Confirm Password<br>
                                          <input class="ipcss" [(ngModel)]="confirm_pwd" type="password"  autocomplete="new-password" 
                                              maxlength="15" required matInput />
                                      </mat-label>
                                  </div>
                                  <!-- <div class="col-12 col-sm-9 col-md-12 col-lg-9 col-xl-9">
                                      <div class="row">
                                          <div class="col-4">
                                              <mat-label class="matlabel">Home Care
                                                  <mat-checkbox (change)="home_click()" color="primary"
                                                      [(ngModel)]="togg_home">
                                                  </mat-checkbox>
                                              </mat-label>
                                          </div>
                                      </div>
                                  </div> -->
                              </div>
                          </div>
                      </div>
  
                      <div class="row" [hidden]="Homecare_show">
                          <p class="tervys_heading">Homecare Consultation &nbsp; &nbsp;</p>
                          <div [hidden]="Homecare_show">
                              <div class="row">
                                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                      <mat-label class="matlabel">Session<br>
                                          <select class="ipcss " id="home_session" required [(ngModel)]="homecare_session" (change)="session_change()">
                                              <option value="" disabled>Select</option>
                                              <option value="Morning">Morning</option>
                                              <option value="Afternoon">Afternoon</option>
                                              <option value="Evening">Evening</option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Starts from</mat-label>
                                      <div class="row">
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="Start_time" class="ipcss ">
                                                  <option *ngFor="let name of starts_from_array" value={{name}}>
                                                      {{name}}
                                                  </option>
                                              </select>
                                          </div>
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="end_time" class="ipcss ">
                                                  <option *ngFor="let name of ends_at_array" value={{name}}>{{name}}
                                                  </option>
                                              </select>
                                          </div>
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="time_session" class="ipcss">
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
  
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Ends at</mat-label>
                                      <div class="row">
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="Ends_at_from"
                                                  class="ipcss ">
                                                  <option *ngFor="let name of End_from_array" value={{name}}>{{name}}
                                                  </option>
                                              </select>
                                          </div>
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="Ends_at_to" class="ipcss ">
                                                  <option *ngFor="let name of end_to_array" value={{name}}>{{name}}
                                                  </option>
                                              </select>
                                          </div>
                                          <div class="col-4">
                                              <select disableOptionCentering [(ngModel)]="endtime_session"
                                                  class="ipcss ">
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                      <mat-label class="matlabel">Fee
                                          <input type="text" class="ipcss" placeholder="0.00" [(ngModel)]="homecare_fee"
                                              maxlength="5" required matInput />
                                      </mat-label>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                      <mat-label class="matlabel"><br>
                                          <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg"
                                              (click)="homecare_btn_click()" class="saveimgbtn_doctreg">
                                      </mat-label>
                                  </div>
                              </div>
                              <div [hidden]="homecaretable" class="row">
                                  <table class="table table-hover table-dynamic">
                                      <thead>
                                          <tr>
                                              <th>Session</th>
                                              <th>Starts from</th>
                                              <th>Ends at</th>
                                              <th>Fee</th>
                                              <th>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let getda of homecare_consult_array">
                                              <td>{{getda.session}}</td>
                                              <td>{{getda.available_from1}}</td>
                                              <td>{{getda.available_to1}}</td>
                                              <td>{{getda.home_care_fee}}</td>
                                              <td>
                                                <a (click)="home_consult_delete(getda.session,getda.available_from1,getda.available_to1)">
                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon" />   
                                                </a>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <p class="docreg_heading">Present Address</p>
                              <div class="row">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Address 1<br>
                                          <input type="text" class="ipcss" matInput [(ngModel)]="pres_address1" maxlength="50" required>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Address 2<br>
                                          <input type="text" class="ipcss 2" matInput [(ngModel)]="pres_address2"
                                              maxlength="50" />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Location<br>
                                          <input type="text" class="ipcss " required maxlength="50"
                                              [(ngModel)]="pres_location_txt" (keyup)="pres_location_fun($event)" matInput
                                              [matAutocomplete]="auto" />
                                          <mat-autocomplete #auto="matAutocomplete">
                                              <mat-option (click)="select_pres_location(item)"
                                                  *ngFor="let item of pres_location_filter_List" [value]="item">
                                                  {{item}}
                                              </mat-option>
                                          </mat-autocomplete>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">District / City<br>
                                          <select disableOptionCentering class="ipcss " required
                                              [(ngModel)]="pres_city_select" (ngModelChange)="Pres_City_change($event)">
                                              <option disabled>Select</option>
                                              <option *ngFor="let city of pres_city_list_data" value={{city.city_id}}>
                                                  {{city.city_desc}}
                                              </option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">State<br>
                                          <select disableOptionCentering class="ipcss " required
                                              [(ngModel)]="pres_state" (ngModelChange)="Present_state_change($event)">
                                              <option disabled>Select</option>
                                              <option *ngFor="let state of pres_state_list_data"
                                                  value="{{state.state_id}}">
                                                  {{state.state_desc}}</option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Country<br>
                                          <select disableOptionCentering class="ipcss " [(ngModel)]="pres_country">
                                              <option disabled>Select</option>
                                              <option *ngFor="let country of pres_country_list_data"
                                                  value={{country.country_id}}>
                                                  {{country.country_desc}}</option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Zipcode<br>
                                          <input id="zipcode" class="ipcss zipwidth zipcode" disabled="true" maxlength="6"
                                              [(ngModel)]="pres_zipcode" matInput />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel">Contact number<br>
                                          <input type="text" class="ipcss " required matInput
                                              [(ngModel)]="pres_telephone" type="number" pattern="[0-9]*" 
                                              onKeyPress="if(this.value.length==14) return false;" />
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                      <mat-label class="matlabel wt">
                                          <mat-checkbox color=primary labelPosition="before"
                                              [(ngModel)]="sameasabove_check" (change)="Sameas_Change($event.checked)">
                                              <span style="font-weight: 500 !important;">Permanent Address</span>
                                          </mat-checkbox>
                                      </mat-label>
                                      <label class="same matlabel">same as above</label>
                                  </div>
                              </div>
                              <div [hidden]="perm_div">
                                  <div class="row">
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Address 1<br>
                                              <input type="text" class="ipcss 2" [(ngModel)]="perm_address1"
                                                  maxlength="50" required matInput />
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Address 2<br>
                                              <input type="text" class="ipcss 2" [(ngModel)]="perm_address2"
                                                  maxlength="50" matInput />
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Location<br>
                                              <input type="text" class="ipcss " required maxlength="50"
                                                  [(ngModel)]="perm_location_txt" (keyup)="perm_location_fun($event)"
                                                  matInput [matAutocomplete]="auto2" />
                                              <mat-autocomplete #auto2="matAutocomplete">
                                                  <mat-option (click)="select_perm_location(item)"
                                                      *ngFor="let item of perm_location_filter_List" [value]="item">
                                                      {{item}}
                                                  </mat-option>
                                              </mat-autocomplete>
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">District / City<br>
                                              <select disableOptionCentering class="ipcss " required
                                                  [(ngModel)]="perm_city" (ngModelChange)="Perm_City_change($event)">
                                                  <option disabled>Select</option>
                                                  <option *ngFor="let city of perm_city_list_data"
                                                      value={{city.city_id}}>
                                                      {{city.city_desc}}
                                                  </option>
                                              </select>
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">State<br>
                                              <select disableOptionCentering class="ipcss " required
                                                  [(ngModel)]="perm_state"
                                                  (ngModelChange)="Permanent_state_change($event)">
                                                  <option disabled>Select</option>
                                                  <option *ngFor="let state of perm_state_list_data"
                                                      value={{state.state_id}}>
                                                      {{state.state_desc}}</option>
                                              </select>
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Country<br>
                                              <select disableOptionCentering class="ipcss " required
                                                  [(ngModel)]="perm_country">
                                                  <option disabled>Select</option>
                                                  <option *ngFor="let country of perm_country_list_data"
                                                      value={{country.country_id}}>
                                                      {{country.country_desc}}</option>
                                              </select>
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Zipcode<br>
                                              <input disabled="true" maxlength="6" class="ipcss zipwidth zipcode" matInput
                                                  [(ngModel)]="perm_zipcode" />
                                          </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Contact Number<br>
                                              <input class="ipcss " matInput [(ngModel)]="perm_telephone" type="number" required
                                                  pattern="[0-9]*" onKeyPress="if(this.value.length==14) return false;" />
                                          </mat-label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                      <div class="row">
  
                          <div class="row">
                              <p class="tervys_heading col-12">Registration details &nbsp;&nbsp;</p>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <mat-label class="matlabel">Licence code
                                      <input type="text" class="ipcss " [(ngModel)]="registeration_code" maxlength="15"
                                          required matInput (change)="Acitvate_savebtn()"/>
                                  </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <mat-label class="matlabel">Year
                                      <input type="text" class="ipcss  " [(ngModel)]="registeration_year" required
                                          maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                          matInput (change)="Acitvate_savebtn()"/>
                                  </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <mat-label class="matlabel">State / County
                                      <input type="text" class="ipcss " [(ngModel)]="registeration_state" required
                                          maxlength="25"
                                          onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"
                                          matInput />
                                  </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="signatureFlag">
                                <mat-label class="matlabel">Signature<br>
                                    <img id="signature_img" class="ipcss" style="width: 175px;height: 50px;"/>
                                </mat-label>                                                                                                                                                                                               
                            </div>           
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Signature upload<br>
                                    <input type="file" class="ipcss" (change)="onFileChange($event)" name="signature_img"
                                    id="signature_img">
                                </mat-label>                                                                            
                            </div>    
                          </div>
                      </div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
          <div class="information">
              <mat-accordion class="mataccordion">
                  <mat-expansion-panel class="expandpanel" >
                      <mat-expansion-panel-header class="exppanel width">
                          <mat-panel-title class="title">
                              Work location
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr class="hr">
                      <div class="row" style="padding-top: 20px;">
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Hospital / Clinic name <br>
                                  <input type="text" class="ipcss " required maxlength="50" [(ngModel)]="hosp_name_txt"
                                      (keyup)="hospital_filter($event)" matInput [matAutocomplete]="auto10" />
                                  <mat-autocomplete #auto10="matAutocomplete">
                                      <mat-option (click)="select_hospital(item)" *ngFor="let item of filteredhospList"
                                          value={{item}}>
                                          {{item}}
                                      </mat-option>
                                  </mat-autocomplete>
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Location<br>
                                  <select disableOptionCentering class="ipcss " required [(ngModel)]="hosp_loc_txt"
                                      (ngModelChange)="Loc_change()">
                                      <option disabled>Select</option>
                                      <option *ngFor="let location of location_list_data" value={{location}}>
                                          {{location}}
                                      </option>
                                  </select>
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">District / City<br>
                                  <input class="ipcss " [(ngModel)]="hosp_dist" disabled disabled="true" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">State<br>
                                  <input class="ipcss " [(ngModel)]="hosp_state" disabled disabled="true" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label cl ass="matlabel">Country<br>
                                  <input class="ipcss " [(ngModel)]="hosp_country" disabled disabled="true" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Zip code<br>
                                  <input class="ipcss " [(ngModel)]="hosp_zip" disabled disabled="true" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Address 1<br>
                                  <input type="text" class="ipcss 2" matInput [(ngModel)]="hosp_address1" disabled
                                      maxlength="50">
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Address 2<br>
                                  <input type="text" class="ipcss 2" matInput [(ngModel)]="hosp_address2" disabled
                                      maxlength="50" />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Phone number<br>
                                  <input type="text" class="ipcss " matInput [(ngModel)]="hosp_contact_num" disabled
                                      maxlength="14" />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Website<br>
                                  <input type="text" class="ipcss " matInput [(ngModel)]="hop_website" disabled
                                      maxlength="100" />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Consultation fee<br>
                                  <input type="text" class="ipcss " matInput [(ngModel)]="hosp_fee" placeholder="0.00"
                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="5" />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <div class="row">
                                  <div class="col-6">
                                      <mat-label class="matlabel">Day<br>
                                          <select disableOptionCentering class="ipcss " [(ngModel)]="hosp_days" required>
                                              <option selected value="Select">Select</option>
                                              <option value="Sunday">Sunday</option>
                                              <option value="Monday">Monday</option>
                                              <option value="Tuesday">Tuesday</option>
                                              <option value="Wednesday">Wednesday</option>
                                              <option value="Thursday">Thursday</option>
                                              <option value="Friday">Friday</option>
                                              <option value="Saturday">Saturday</option>
                                              <option value="Saturday">Saturday</option>
                                              <option value="Mon - Sat">Mon - Sat</option>
                                              <option value="All Days">All Days</option>
                                          </select>
                                      </mat-label>
                                  </div>
                                  <div class="col-6">
                                      <mat-label class="matlabel">Session<br>
                                          <select disableOptionCentering class="ipcss "
                                              (change)="session_change1()" [(ngModel)]="hosp_session" required>
                                              <option selected value="Select">Select</option>
                                              <option value="Morning">Morning</option>
                                              <option value="Afternoon">Afternoon</option>
                                              <option value="Evening">Evening</option>
                                          </select>
                                      </mat-label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Starts from</mat-label>
                              <div class="row">
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="start_from1" class="ipcss ">
                                          <option *ngFor="let name of starts_from_array" value={{name}}>{{name}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="start_from2" class="ipcss ">
                                          <option *ngFor="let name of ends_at_array" value={{name}}>{{name}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="start_from3" class="ipcss ">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Ends at</mat-label>
                              <div class="row">
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="end_at1" class="ipcss ">
                                          <option *ngFor="let name of starts_from_array" value={{name}}>{{name}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="end_at2" class="ipcss ">
                                          <option *ngFor="let name of ends_at_array" value={{name}}>{{name}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-4">
                                      <select disableOptionCentering [(ngModel)]="end_at3" class="ipcss ">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
  
                          <div class="row">
                              <div>
                                  <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg" (click)="work_btn_click()" class="saveimgbtn_doctreg" />
                              </div>
                          </div>
  
                          <div class="row">
                              <table [hidden]="work_arry.length==0" class="table table-hover table-dynamic">
                                  <thead>
                                      <tr>
                                          <th>Hospital / Clinic name</th>
                                          <th>Location</th>
                                          <th>Day</th>
                                          <th>Session</th>
                                          <th>Starts from</th>
                                          <th>Ends at</th>
                                          <th>Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let work of  work_arry">
                                          <td>{{work.name}}</td>
                                          <td>{{work.location}}</td>
                                          <td>{{work.day_desc}}</td>
                                          <td>{{work.session_desc}}</td>
                                          <td>{{work.available_from}}</td>
                                          <td>{{work.available_to}}</td>
                                          <td>
                                              <a
                                                  (click)="work_delete(work.name,work.day_desc,work.session_desc,work.available_from)"><img
                                                  src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                  class="editDelicon" /></a>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
          <div class="information">
              <mat-accordion class="mataccordion">
                  <mat-expansion-panel class="expandpanel" >
                      <mat-expansion-panel-header class="exppanel width">
                          <mat-panel-title class="title">
                              Qualification
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr class="hr">
                      <div class="row" style="padding-top: 20px;">
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Degree<br>
                                  <input type="text" class="ipcss " [(ngModel)]="qualification_degree" required
                                      maxlength="150" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">School/College<br>
                                  <input type="text" class="ipcss 2" [(ngModel)]="qualification_clg" maxlength="75"
                                      matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">University<br>
                                  <input type="text" class="ipcss 2" [(ngModel)]="qualification_uni" required
                                      maxlength="75" matInput />
                              </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                              <mat-label class="matlabel">Year of completion<br>
                                  <input type="text" class="ipcss " (change)="Date_qulification(qualification_year)" [(ngModel)]="qualification_year"
                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                                      matInput />
                              </mat-label>
                          </div>
                          <div class="col-12">
                              <mat-label class="matlabel"><br>
                                  <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_doctreg"
                                      (click)="qualification_btn_click()" />
                              </mat-label>
                          </div>
                      </div>
                      <div class="row" style="padding-top: 20px;">
                          <table [hidden]="qualification_array.length==0" class="table table-hover table-dynamic">
                              <thead>
                                  <tr>
                                      <th>Degree name</th>
                                      <th>School/College name</th>
                                      <th>University name</th>
                                      <th>Year</th>
                                      <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let qualification of  qualification_array">
  
                                      <td>{{qualification.degree}}</td>
                                      <td>{{qualification.institute}}</td>
                                      <td>{{qualification.university}}</td>
                                      <td>{{qualification.year}}</td>
                                      <td>
                                          <a (click)="qua_delete(qualification.degree,qualification.year)"><img
                                            src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            class="editDelicon" /></a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
          <div class="information">
              <mat-accordion class="mataccordion">
                  <mat-expansion-panel class="expandpanel">
                      <mat-expansion-panel-header class="exppanel widthexp">
                          <mat-panel-title class="title">
                              Terms and Condition
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr class="hr">
                      <div class="gridview terms padding-top">
                          <b>Welcome to Tervys! </b>
                          <p class="pterms">Tervys is a platform designed to empower patients to better manage their
                              health
                              and
                              medical
                              conditions outside of the clinic while informing more intelligent, better health and patient
                              relationship management for providers. Our e-enabled solution guides and supports patients
                              to
                              manage their health better, while providing critical information to healthmatic providers to
                              more effectively manage patient clinical risks.</p>
                          <p class="pterms">BY CLICKING THE 'I AGREE' BUTTON, AND/OR BY INSTALLING AND/OR USING THE Tervys
                              HEATHCARE
                              APPS/APPLICATION OR ANY INCLUDED DOCUMENTATION, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS
                              AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
                              AGREEMENT
                              (THE "AGREEMENT" ), AND THIS WILL BE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND TERVYS.
                          </p>
                          <p class="pterms">IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU
                              SHOULD
                              LOGOFF
                              FROM THE
                              SYSTEM AND BY NOT INSTALLING OR USING THE APP/APPLICATION.</p>
                          <p class="pterms">YOUR ACCESS TO AND USE OF OUR APPLICATION, WEBSITE, AND SERVICES IS SUBJECT IN
                              ALL
                              RESPECTS TO
                              THE TERMS OF OUR PRIVACY POLICY, AS WE MAY UPDATE THAT PRIVACY POLICY FROM TIME TO TIME
                              ("PRIVACY POLICY").</p>
                          <p class="pterms">YOU FURTHER ACKNOWLEDGE AND UNDERSTAND THAT THIS AUTHORIZATION IS NOT A
                              CONDITION
                              FOR
                              RECEIVING
                              ANY TREATMENT, PAYMENT, OR BENEFIT AND YOU UNDERSTAND THAT YOUR REFUSING TO AGREE TO THE
                              TERMS
                              OF THIS AGREEMENT, INCLUDING THIS AUTHORIZATION, WILL NOT AFFECT ANY TREATMENT, PAYMENT, OR
                              BENEFIT FOR WHICH YOU MAY BE ELIGIBLE. HOWEVER, YOU AKNOWLEDGE AND UNDERSTAND THAT IF YOU DO
                              NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU WILL NOT BE PROVIDED WITH
                              ACCESS
                              TO THE THE TERVYS APP/APPLICATION OR SERVICES AS DESCRIBED HEREIN.</p>
                          <p class="pterms">THE APP/APPLICATION IS NOT FOR MEDICAL EMERGENCIES. ALWAYS SEEK THE ADVICE OF
                              YOUR
                              PHYSICIAN OR
                              OTHER QUALIFIED HEALTH PROVIDER, DIRECTLY AND NOT THROUGH OUR APPLICATION, WITH ANY
                              QUESTIONS
                              YOU MAY HAVE REGARDING A MEDICAL CONDITION.</p>
                          <p class="pterms">References in this Agreement to "you" or "your" refer to both you and any
                              person
                              or
                              entity on
                              whose behalf you act, if any.</p>
                          <b class="trm_cond_font2">Grant of license</b>
                          <p class="pterms">Tervys hereby grants to you a personal, non-transferable, non-exclusive,
                              limited
                              license to
                              install, access and use the application for your own personal purposes of tracking and
                              attaining support toward disease prevention and for sharing and communicating your progress
                              with designated healthmatic providers. Portions of this application, including substantial
                              portions of the services accessible as part of the application, will be provided via remote
                              access of your device to to Tervys platform. you may utilize the application for
                              communications
                              to and from your healthmatic provider.</p>
                          <b class="trm_cond_font2">Application use restrictions</b>
                          <p class="pterms">You shall not sublicense, distribute, loan or otherwise convey the application
                              or
                              Content or any
                              portion thereof to anyone, and under no circumstance may you use or allow the use of the
                              application in any manner other than as expressly set forth above. You shall not incorporate
                              the application in whole or in part in any other product or create derivative works based on
                              all or part of the application. You shall not remove any copyright, trademark, proprietary
                              rights, disclaimer or warning notice included on or embedded in any part of the application.
                              You shall not use the application in connection with a service bureau, time sharing or
                              fee-for-service arrangement with third parties. Except to the extent permitted by applicable
                              local law, you shall not reverse assemble, decompile or disassemble or otherwise reverse
                              engineer any portion of the application. If you dispose of any media embodying application
                              or
                              Content, you will ensure that you have completely erased or otherwise destroyed any
                              application
                              and Content stored on such media.</p>
  
                          <p class="pterms">THE APPLICATION IS NOT INTENDED FOR USE IN ANY SITUATION IN WHICH THE FAILURE
                              OF
                              THE
                              APPLICATION
                              COULD LEAD TO DEATH OR BODILY INJURY OF ANY TYPE. YOU ARE SOLELY RESPONSIBLE FOR USING THE
                              APPLICATION IN A MANNER CONSISTENT WITH ALL APPLICABLE INTERNATIONAL, FEDERAL AND STATE
                              LAWS.
                          </p>
                          <b class="trm_cond_font2">Protected health information (PHI)</b>
  
                          <p class="pterms">Each country's governing law requires that Tervys protect the privacy of
                              health
                              information and
                              information that could be used to identify you (e.g. medical information, date of birth,
                              name,
                              address, etc.). This information is called Protected Health Information (&quot;PHI&quot).
                              Tervys uses encryption technology to ensure the privacy of the PHI and other identifying
                              information (collectively, the &quot;Data&quot;) you or your physician enter on the Website,
                              or
                              through the application as part of the Services provided herein.</p>
                          <p class="pterms">By agreeing to the terms of this Agreement, you are authorizing Tervys to
                              utilize
                              your
                              PHI, and
                              any other data that you submit in connection with your use of the application, to collect,
                              use,
                              and disclose your Data in order to provide you with access to and use of the application, to
                              enhance the treatment provided to you by your provider and for purposes of health coaching.
                              In
                              addition, Tervys may use your PHI for evaluation of the Services and for quality improvement
                              purposes. Tervys use and disclosure of any Protected Health Information will be conducted in
                              accordance with our Privacy Policy found at www.tervys.com/privacy-policy and applicable
                              federal and state regulations.</p>
                          <b class="trm_cond_font2">Clinical support information</b>
  
                          <p class="pterms">Tervys may provide information to assist you in clinical decision-making. This
                              may
                              include
                              information and reminders concerning drug interactions, allergies, dosages, as well as
                              general
                              health-care related information and resources. We may also provide forums for Our Users to
                              exchange information. The information and materials available on the Website are for
                              informational and educational purposes only and are not intended to and do not in any way
                              constitute professional advice, diagnosis or treatment, and do not and should not be used as
                              a
                              substitute for Your professional judgment.</p>
                          <b class="trm_cond_font2">Password protection</b>
  
                          <p class="pterms">You are responsible for keeping your password confidential. You should notify
                              us
                              immediately if
                              your password is hacked or stolen. Tervys is not responsible or liable to you in any way if
                              information is intercepted by an unauthorized person, either in transit or at your home,
                              business or other place of access.</p>
  
                          <b class="trm_cond_font2">Use of content</b>
  
                          <p class="pterms">Tervys grants to you, subject to this license agreement, a non-transferable,
                              non-sub-licensable,
                              non-exclusive, limited right to access and use the application and related Services solely
                              for
                              your personal use. The application and the content available through the application
                              (&quot;Content&quot) may contain typographical errors, other inadvertent errors or
                              inaccuracies. We reserve the right to make changes to the application, document names and
                              Content, descriptions or specifications of products or Services, or other information
                              without
                              obligation to issue any notice of such changes.</p>
                          <p class="pterms">You may view, copy, download, and print Content that is available through this
                              application,
                              subject to the following conditions:</p>
                          <p class="pterms">a.The Content may be used solely for your personal informational purposes. No
                              part of this application or its content may be reproduced or transmitted in any form, by any
                              means, electronic or mechanical, including photocopying and recording for any other purpose.
                          </p>
  
                          <p class="pterms">b.The Content may not be modified.</p>
                          <p class="pterms">c.Copyright, trademark, and other proprietary notices may not be removed.</p>
                          <p>Nothing contained on Tervys Website or in the Application or Services should be construed as
                              granting, by implication, estoppel, or otherwise, any license or right to use this
                              Application
                              or any Content displayed on our Website, through the use of framing or otherwise, except:
                              (a)
                              as expressly permitted by these terms of use; or (b) with our prior written permission or
                              the
                              permission of such third party that may own the trademark or copyright of material displayed
                              on
                              our Website.</p>
                          <p>If you violate any of these terms and conditions, your permission to use the Application and
                              Content automatically terminates and you must immediately destroy any copies you have made
                              of
                              any portion of the Application or Content. Your records containing your personal health
                              information are available in accordance with Tervys Privacy Policy.</p>
                          <b class="trm_cond_font2">Copying restrictions</b>
  
                          <p class="pterms">You agree that you will not copy the application onto any of your medical
                              device,
                              and
                              you may
                              not make one (1) copy of the application for backup or archival purposes. You agree that and
                              (2) your use and possession of such copies shall be destroyed under the terms and conditions
                              of
                              this Agreement. You are not permitted to copy the Application or Content.</p>
                          <b class="trm_cond_font2">Disclosure restrictions</b>
  
                          <p class="pterms">You acknowledge that the Application, including the source code for the
                              Application
                              and any
                              information derived therefrom, constitutes a valuable trade secret of Tervys. If you should
                              gain access to such materials, you shall not disclose them to anyone.</p>
  
  
                          <b class="trm_cond_font2">Ownership of application</b>
  
                          <p class="pterms">You agree and acknowledge that (i) the Application is licensed to you, not
                              sold,
                              and
                              Tervys
                              transfers no ownership interest in the Application, in the intellectual property in any
                              Application or in any Application copy, to you under this Agreement or otherwise, (ii) that
                              Tervys and its licensors reserve all rights not expressly granted to you hereunder, (iii)
                              Tervys >> or its licensors own the Application (including, but not by way of limitation, any
                              images, algorithms, photographs, animations, video, audio, music and text incorporated in
                              the
                              Application), and (v) the Application is protected by United States Copyright Law and
                              international treaties relating to protection of copyright. The Application includes, and
                              this
                              Agreement will cover, any updates, upgrades or bug fixes for the Application provided to
                              you.
                          </p>
                          <b class="trm_cond_font2">User info</b>
  
                          <p class="pterms">To download, access and use the Application, you must register with Tervys by
                              providing your
                              name, address, email, mobile number and other information that may be used to personally
                              identify you. Tervys reserves the right to authenticate any information you provide.
                              Furthermore, Tervys collects certain non-personal information concerning your interactions
                              with
                              the Application. Tervys policy is to respect and protect your privacy. Tervys uses any user
                              information strictly for the business purposes for which it was collected, in accordance
                              with
                              our Privacy Policy. Before installing or using the Application, you should carefully review
                              our
                              Privacy Policy, which can be found at www.tervys.com/privacy-policy. Your continued use of
                              the
                              Application shall be deemed your acceptance of the Privacy Policy, as it may be amended from
                              time to time.</p>
                          <b class="trm_cond_font2">Login info and access</b>
                          <p class="pterms">Your access to the Application must be via login credentials ( "Login
                              Credentials"
                              ).
                              You agree
                              that you are responsible for protecting your Login Credentials from unauthorized use, and
                              you
                              are responsible for all activity that occurs under those Login Credentials. You agree to
                              notify
                              us immediately if you believe that any of Your Login Credentials have been or may be used
                              without your permission so that appropriate action can be taken. You may not (i) create more
                              than one account to access the Application, (ii) share your Login Credentials with any third
                              party nor (iii) transfer your account to any third party. Tervys is not responsible for any
                              loss or damage caused by, or expense incurred by you as a result of, your failure to
                              safeguard
                              Your Login Credentials. Tervys may terminate any Login Credentials in its sole discretion;
                              you
                              may not be permitted to create a new account to access the Application or Content if your
                              prior
                              Login Credentials have been terminated by Tervys.</p>
                          <p class="pterms">Tervys will protect as confidential any information that you may provide to
                              complete
                              the
                              applicable online forms to establish your Login Credentials with Tervys. You agree to
                              provide,
                              maintain and update true, accurate, current and complete information, and represent that you
                              will not misrepresent your identity or your affiliation with any person or entity.</p>
                          <b class="trm_cond_font2">Transfer restrictions</b>
                          <p class="pterms">You may not sublicense, delegate, assign or otherwise transfer this Agreement,
                              the
                              license
                              granted herein, or any other of your rights or obligations under this Agreement, in whole or
                              in
                              part.</p>
                          <b class="trm_cond_font2">Export restrictions</b>
                          <p class="pterms">You agree to comply with all applicable foreign, federal, state and local laws
                              and
                              regulations
                              governing your use of the Product. Without limiting the foregoing, in the event that this
                              Agreement permits. You shall be solely responsible for export of the product outside the
                              country and comply with the local export laws, rules and regulations.</p>
                          <b class="trm_cond_font2">Breach and Termination</b>
                          <p class="pterms">This Agreement and any license granted herein may be suspended or terminated
                              by
                              Tervys
                              immediately and without notice if you fail to comply with any term or condition of this
                              Agreement. In addition, Tervys may at any time suspend your access to the Application, if
                              you
                              are in breach of this Agreement or using the Application in a manner that impairs the
                              operation
                              of the Application or that violates the Privacy Policy.</p>
  
                          <p class="pterms">You acknowledge and agree that the Application is under development and will
                              continually change
                              as Tervys may determine from time to time. Tervys reserves the right to terminate the
                              provision
                              of the Application and related Services at any time.</p>
                          <b class="trm_cond_font2">Enforcement of terms; effect of termination</b>
                          <p class="pterms">If you fail to fulfil any of your obligations under this Agreement, and this
                              Agreement
                              terminates, Tervys and/or its licensors may pursue all available legal remedies available to
                              them. You agree that Tervys licensors referenced in the Application are third- party
                              beneficiaries of this Agreement, and may enforce this Agreement as it relates to their
                              intellectual property. Sections of this Agreement which by their nature survive expiration
                              or
                              termination of this Agreement shall survive according to their terms. Upon termination, you
                              shall promptly uninstall and remove the Application from the mobile device on which it was
                              installed.</p>
  
                          <b class="trm_cond_font2">Assumption of risk</b>
                          <p class="pterms">The Application is designed to provide a designated third-party with
                              health-related
                              information
                              based on your use of the Application. The Application and any related data supplied to you
                              by
                              Tervys does not provide medical advice. By granting you the right to use the Application,
                              Tervys does not assume any obligation or liability with respect to your health. In no event
                              shall Tervys be liable for any death or bodily injury that you suffer, or that you cause to
                              any
                              third party, in connection with your use of the Application or any activity you undertake in
                              connection with your use of the Application.</p>
                          <b class="trm_cond_font2">Disclaimer of warranty</b>
                          <p class="pterms">TERVYS PROVIDES THE APPLICATION TO YOU &quot;AS IS&quot;, WITH ALL FAULTS, AND
                              WITHOUT
                              WARRANTY
                              OF ANY KIND, EXPRESS, STATUTORY, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY
                              WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. TERVYS
                              MAKES NO REPRESENTATION OR WARRANTY THAT THE APPLICATION IS ACCURATE, COMPLETE OR UP-TO-
                              DATE.
                              TERVYS MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND WITH RESPECT TO THE USE OR THE
                              RESULTS
                              OF THE USE OF ANY DATA OR INTERACTIONS OF ANY USER. NO ORAL OR WRITTEN INFORMATION OR ADVICE
                              GIVEN BY ANY TERVYS EMPLOYEE, REPRESENTATIVE OR DISTRIBUTOR SHALL CREATE A WARRANTY FOR THE
                              APPLICATION, AND YOU MAY NOT RELY ON ANY SUCH INFORMATION OR ADVICE. TERVYS LICENSORS
                              EXPLICITLY DISCLAIM ANY AND ALL WARRANTIES WITH RESPECT TO THE APPLICATION.</p>
                          <b class="trm_cond_font2">Limitations of liability and releases</b>
                          <p class="pterms">IN NO EVENT SHALL TERVYS OR ITS LICENSORS BE LIABLE TO YOU FOR ANY SPECIAL,
                              CONSEQUENTIAL,
                              PUNITIVE, EXEMPLARY, INCIDENTAL OR INDIRECT DAMAGES OF ANY KIND (INCLUDING WITHOUT
                              LIMITATION
                              THE COST OF COVER, DAMAGES ARISING FROM LOSS OF DATA, USE, PROFITS OR GOODWILL), WHETHER OR
                              NOT
                              TERVYS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, HOWEVER CAUSED AND ON ANY THEORY OF
                              LIABILITY ARISING OUT OF THIS AGREEMENT. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING THE
                              FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</p>
                          <p class="pterms">TERVYS MAXIMUM AGGREGATE LIABILITY ARISING OUT OF THIS AGREEMENT AND/OR YOUR
                              USE
                              OR
                              POSSESSION
                              OF THE APPLICATION, INCLUDING WITHOUT LIMITATION ANY CLAIMS IN TORT (INCLUDING NEGLIGENCE),
                              CONTRACT, BREACH OF WARRANTY, STRICT LIABILITY OR OTHERWISE, AND FOR ANY AND ALL CLAIMS
                              COMBINED, WILL NOT EXCEED U.S. $1.</p>
                          <b class="trm_cond_font2">Exclusions</b>
                          <p class="pterms">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
                              LIMITATIONS
                              ON
                              CERTAIN
                              TYPES OF DAMAGES, SO THE ABOVE DISCLAIMERS AND LIMITATIONS MAY NOT APPLY TO YOU WITH RESPECT
                              TO
                              CERTAIN TYPES OF DAMAGES OR CLAIMS.</p>
                          <b class="trm_cond_font2">User submissions</b>
                          <p class="pterms">Please refer to our Privacy Policy for additional information on our practices
                              for
                              handling
                              personally identifiable information. When you choose to provide information to register for
                              or
                              participate in this Application, you agree that you will provide accurate, complete, and up
                              to
                              date information as requested on the screens that collect information from you.</p>
                          <p class="pterms">You agree that you will not upload or transmit any communications or content
                              of
                              any
                              type to the
                              Public Areas (including message boards if we add them to our Services) that infringe or
                              violate
                              any rights of any party. By submitting communications or content to the Public Areas, you
                              agree
                              that such submission is non-confidential for all purposes.</p>
  
                          <p class="pterms">If you make any such submission you agree that you will not send or transmit
                              to
                              TERVYS
                              by email,
                              (including through the email addresses listed on the &quot;Contact Us&quot; page) any
                              communication or content that infringes or violates any rights of any party. If you submit
                              any
                              business information, idea, concept or invention to Tervys by email, you agree such
                              submission
                              is non-confidential for all purposes.</p>
  
                          <p class="pterms">If you make any submission to a publicly accessible area of our Website or if
                              you
                              submit any
                              business information, idea, concept or invention to Tervys by email or otherwise, you
                              automatically grant- or warrant that the owner of such content or intellectual property has
                              expressly granted-Tervys a royalty-free, perpetual, irrevocable, world-wide nonexclusive
                              license to use, reproduce, create derivative works from, modify, publish, edit, translate,
                              distribute, perform, and display the communication or content in any media or medium, or any
                              form, format, or forum now known or hereafter developed. Tervys may sublicense its rights
                              through multiple tiers of sublicenses. If you wish to keep any business information, ideas,
                              concepts or inventions private or proprietary, do not submit them to the publicly accessible
                              area of our Website or to Tervys. We try to answer correspondence in a timely manner, but
                              are
                              not always able to do so.</p>
                          <b class="trm_cond_font2">Termination</b>
                          <p class="pterms">We may terminate any user's access to our Application, including access to any
                              online
                              discussion
                              forum or chat room, in our sole discretion, for any reason and at any time, with or without
                              prior notice. It is our policy to terminate users who violate this Agreement, as deemed
                              appropriate in our sole discretion. You agree that we are not liable to you or any third
                              party
                              for any termination of your access to our Application.</p>
                          <b class="trm_cond_font2">Prohibited content</b>
  
                          <p class="pterms">By accessing our Application or using our Services, you agree to abide by the
                              following
                              standards of conduct. You agree that you will not, and will not authorize or facilitate any
                              attempt by another person to use our Application to:</p>
                          <p class="pterms">a. Transmit any Content that is unlawful, harmful, threatening, abusive,
                              harassing, defamatory, vulgar, offensive, obscene, pornographic, lewd, lascivious, or
                              otherwise
                              objectionable, as determined by [Client].</p>
                          <p class="pterms">b. Use a name or language that Tervys, in its sole discretion, deems
                              offensive.
                          </p>
                          <p class="pterms">c. Transmit or post defamatory statements.</p>
                          <p class="pterms">d. Transmit or post hateful or racially or ethnically objectionable Content.
                          </p>
  
                          <p class="pterms">e. Transmit or post Content which infringes another's copyright, trademark or
                              trade secret..</p>
                          <p class="pterms">f. Transmit or post unsolicited advertising or unlawfully promote products or
                              services.</p>
                          <p class="pterms">g. Harass, threaten or intentionally embarrass or cause distress to another
                              person or entity.</p>
                          <p class="pterms">h. Impersonate another person.</p>
                          <p class="pterms">
                              i. Upload, post, e-mail, transmit or otherwise make available: (A) any information or
                              material
                              that infringes upon a third party right, especially intellectual property rights; (B) any
                              third
                              party advertisements,
                              <span class="sub2">including banner exchange services; (C) any application viruses, Trojan
                                  horses, worms or any other malicious application or (D) any information or material
                                  which
                                  may constitute or encourage conduct</span><span class="sub2"> that is a criminal offense
                                  or
                                  civil wrong or otherwise violates any applicable law.</span>
                          </p>
                          <p class="pterms">j. Stalk, threaten or harass any other users or infringe upon or attempt to
                              infringe upon their privacy.</p>
                          <p class="pterms">k. Promote, solicit, or participate in any multi-level marketing or pyramid
                              schemes.</p>
                          <p class="pterms">l. Exploit children under 18 years of age.</p>
                          <p class="pterms">m. Engage in disruptive activity.</p>
                          <p class="pterms">n. Obtain unauthorized access to any computer system through the Website.</p>
                          <p class="pterms">
                              o. Invade the privacy of any person, including but not limited to posting personally
                              identifying or otherwise private information about a person without their consent (or their
                              parent's consent in the case of
                              <span class="sub1">a child under 13 years of age).</span>
                          </p>
                          <p class="pterms">p. Solicit personal information from children under 13 years of age.</p>
                          <p class="pterms">q. Violate any federal, state, local, or international law or regulation.</p>
                          <p class="pterms">r. Encourage conduct that would constitute a criminal or civil offense.</p>
                          <b class="trm_cond_font2">Ads, searches and links to other sites</b>
                          <p>Tervys may provide links to third-party web sites. Tervys Hpersonalform.$valid && ealthcare
                              also
                              may select certain sites as priority responses to search terms you enter and Tervys may
                              agree
                              to allow advertisers to respond to certain search terms with advertisements or sponsored
                              content. Tervys does not recommend and does not endorse the content on any third-party
                              websites. Tervys is not responsible for the content of linked third-party sites, sites
                              framed
                              within the Tervys Site, third-party sites provided as search results, or third-party
                              advertisements, and does not make any representations regarding their content or accuracy.
                              Your
                              use of third-party websites is at your own risk and subject to the Terms of Service of use
                              for
                              such sites. You acknowledge and understand that should you choose to provide any
                              individually
                              identifiable information or protected health information to such third party sites, the
                              recipients of such individually identifiable information or PHI may not be subject to the
                              same
                              obligations under the Federal Privacy and Security regulations (HIPAA) and may use or
                              re-disclose the information so that it is no longer protected by federal law. Tervys >does
                              not
                              endorse any product, service, or treatment advertised on the Tervys site.</p>
                          <b class="trm_cond_font2">Jurisdictional issues</b>
                          <p class="pterms">We make no representation that information on the application or Services are
                              appropriate or
                              available for use outside the countries as mentioned in the privacy-policy. Those who choose
                              to
                              access this Site from outside the boundary do so on their own initiative and at their own
                              risk
                              and are responsible for compliance with applicable local laws.</p>
                          <b class="trm_cond_font2">Intellectual property rights</b>
                          <p class="pterms">Unless otherwise noted, all Content contained on this application is the
                              property
                              of
                              Tervys
                              and/or its affiliates or licensors, and is protected from unauthorized copying and
                              dissemination by respective country’s copyright law, trademark law, international
                              conventions
                              and other intellectual property laws. Domain names and product names are trademarks or
                              registered trademarks of their respective owners. Content and Services are subject to change
                              or
                              termination without notice and at the discretion of Tervys. All rights not expressly granted
                              herein are reserved to Tervys and its licensors.</p>
                          <p class="pterms">We do not claim ownership of Content submitted by Users without compensation
                              by
                              Tervys
                              and with
                              the expectation that such Content will be made publicly accessible through our application.
                              By
                              submitting such Content, however, you agree to grant us a world-wide, royalty-free,
                              perpetual,
                              irrevocable, non-exclusive license to use, distribute, reproduce, modify, adapt, create
                              derivative works from, and publicly perform or display such Content. This license shall
                              remain
                              in effect until we delete the Content from our systems.</p>
                          <b class="trm_cond_font2">Complete agreement, waiver, severability</b>
                          <p class="pterms">This Agreement supersedes all proposals, oral or written, all negotiations,
                              conversations,
                              discussions and all past course of dealing between you and Tervys relating to the
                              application
                              or the terms of its license to you, and may only be modified in writing signed by you and
                              Tervys. In the event any term of this Agreement is held by a court of competent jurisdiction
                              not to be enforceable, such unenforceability shall not affect the remaining terms of this
                              Agreement in such jurisdiction or render unenforceable or invalidate such terms and
                              provisions
                              of this Agreement in other jurisdictions. Upon such determination that any of the terms or
                              provisions of this Agreement are held to be invalid under any applicable statute or rule of
                              law, they shall be severed from this Agreement and the remaining provisions of this
                              Agreement
                              shall be interpreted so as best to reasonably effect the intent of the parties and the
                              parties
                              agree to replace any invalid or unenforceable provisions in a mutually acceptable manner in
                              order that the transactions contemplated hereby be consummated as originally contemplated to
                              the greatest extent possible. No waiver of any right or obligation contained herein shall be
                              given except in writing signed by the party against whom the waiver is sought to be
                              enforced.
                          </p>
  
                          <b class="trm_cond_font2">Our liability</b>
                          <p class="pterms">Under no circumstances shall Tervys or it partners be liable for any indirect
                              incidental,consequential or exemplary damages arising out of or in connection with your
                              access
                              or inability to access the application.</p>
  
                          <b class="trm_cond_font2">Your liability</b>
  
                          <p class="pterms">Under the following circumstances you will be held responsible for the damage
                              caused
                              to Tervys
                              in the form of access the application.</p>
  
                          <p class="pterms">- Unauthorized sharing of information to third party </p>
                          <p class="pterms">- Unauthorizd access to the product using illegal / unethical ways </p>
                          <p class="pterms">- try to modify the information using non-recommended or unauthorized ways
                          <p class="pterms">For additional information If you have any questions about this license
                              agreement,
                              please
                              contact info@tervys.com. </p>
  
                          <p class="pterms">Copyright © 2012-2018 Tervys. All rights reserved. </p>
                          <p class="pterms">*** YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND
                              AGREE TO
                              BE
                              BOUND
                              BY ITS TERMS AND CONDITIONS. ***</p>
                      </div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
          <mat-label class="matlabel wt paddingwt">
              <mat-checkbox color=primary labelPosition="after" [(ngModel)]="agree"><span
                      style="font-weight: 500 !important;">I
                      agree</span></mat-checkbox>
          </mat-label>
          <a [hidden]="save_flag" (click)="register_next_click()" style="padding: 0px 39px 19px 0;"><img style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg"
                  class="saveimgbtn_doctreg" /></a>
      </div>
    </div>
    <app-footer></app-footer>
  </div>