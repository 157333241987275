<div *ngIf="diagnosisFlag == 'min'">
    <div class="block1_grid">
        <div *ngIf="cardio_flag">
            <div style="display: flex;">
                <mat-label class="matlabel">Heart disorder<br>
                    <select class="ipcss " disableOptionCentering [(ngModel)]="cardio_dis" [disabled]="!diagnosis_flag">
                        <option disabled>Select</option>
                        <option *ngFor="let name of cardiodis_array" (click)="cardio_dis_change(name.description)"
                            value={{name.description}}>{{name.description}}</option>
                    </select>
                </mat-label>
            </div>
        </div>
        <div *ngIf="Diabetes_flag">
            <div class="block1">
                <mat-label class="matlabel" [hidden]="diabetichidden">Diabetes type<br>
                    <select class="ipcss  " disableOptionCentering [(ngModel)]="Diabetic_type">
                        <option disabled>Select</option>
                        <option *ngFor="let name of diabetes_type" value={{name.description}}>{{name.description}}
                        </option>
                    </select>
                </mat-label>
            </div>
        </div>
        <div *ngIf="General_flag">
            <!-- <div class="block1">
                <mat-label class="matlabel">{{diab_typelabel}}<br>
                    <textarea class="ipcss ip" #myInput id="myInput" rows="1" [(ngModel)]="disease_typetxt"
                        required></textarea>
                </mat-label>
            </div> -->
        </div>

        <div *ngIf="Pediatrics_flag">
            <div class="block1">
                <mat-label class="matlabel" [hidden]="disease_hidden">Disease<br>
                    <textarea class="ipcss " [(ngModel)]="disease_txt"></textarea>
                </mat-label>
            </div>

        </div>
        <!-- <h5 [hidden]="phyex_hidden"><strong>Physical activities</strong></h5> -->
        <div class="block1_grid" [hidden]="phyex_hidden">
            <mat-label class="matlabel" *ngIf="!phyex_hidden">Exercise<br>
                <select class="ipcss" [(ngModel)]="life_exer" disableOptionCentering>
                    <option disabled value="Select">Select</option>
                    <option *ngFor="let exercise of Physcial_exercise" (click)="Exercise_change(exercise.description)"
                        value={{exercise.description}}>
                        {{exercise.description}}</option>
                </select>
            </mat-label>
          
       

            <mat-label class="matlabel" style="text-transform: capitalize;">{{phydure_label}}<br>
                <select class="ipcss  " [(ngModel)]="life_exerdure" disableOptionCentering>
                    <option disabled value="Select">Select</option>
                    <option value="10 mins">10 mins</option>
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="45 mins">45 mins</option>
                </select>
            </mat-label>

        </div>

    </div>
</div>

<div *ngIf="diagnosisFlag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div [hidden]="icd_hidden" class="col-11 col-sm-11 col-md-5 col-lg-5 col-xl-5">
                    <mat-label class="matlabel">{{icd_label}}</mat-label>
                    <input class="ipcss" [readonly]="isReadonly()" [(ngModel)]="Icd_code" matInput  (change)="send_data()"/>
                </div>
                <div [hidden]="icd_hidden" class="col-1 p-0">
                    <img src="../../../assets/img/icd.png" class="icd_img" (click)="Icd_click()" />
                </div>
                <div [hidden]="icddis_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel">{{icddis_label}}</mat-label>
                    <textarea class="ipcss" rows="1" [(ngModel)]="Icd_Descript" matInput (keyup)=lookupICDCodes()
                        [matAutocomplete]="auto10" (blur)="send_data()"></textarea>
                    <mat-autocomplete #auto10="matAutocomplete">
                        <mat-option (click)="select_icdcode(item.desc)" *ngFor="let item of icdList"
                            value="{{item.desc}}">
                            {{item.desc}}
                        </mat-option>
                    </mat-autocomplete>
                    <!-- <input type="text" #myInput id="myInput" rows="3" [(ngModel)]="Icd_Descript" (keyup)=lookupICDCodes()> -->
                    <!-- <div class="auto_complete_text" *ngIf="icdList.length > 0">
                        <ul *ngFor="let item of icdList">
                          <li>
                            <a (click)="select_icdcode(item.desc)">{{item.desc}}</a>
                          </li>
                        </ul>
                      </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="cardio_flag">
                    <mat-label class="matlabel">Heart disorder<br>
                        <select class="ipcss " [(ngModel)]="cardio_dis" required [disabled]="!diagnosis_flag || client" (change)="send_data(); cardio_dis_change(cardio_dis)">
                            <option disabled>Select</option>
                            <option *ngFor="let name of cardiodis_array" value={{name.description}}>{{name.description}}</option>
                        </select>
                    </mat-label>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="Diabetes_flag">
                    <mat-label [hidden]="diabetichidden" class="matlabel">Diabetes type<br>
                        <select class="ipcss " [(ngModel)]="Diabetic_type" required [disabled]="client" (change)="send_data()">
                            <option disabled>Select</option>
                            <option *ngFor="let name of diabetes_type" value={{name.description}}>{{name.description}}
                            </option>
                        </select>
                    </mat-label>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" required *ngIf="General_flag">
                    <mat-label class="matlabel">Disease<br>
                        <textarea class="ipcss ip" #myInput id="myInput" rows="1" [(ngModel)]="disease_typetxt"
                            [disabled]="client" required (blur)="send_data()"></textarea>
                    </mat-label>
                </div>

            </div>

            <div *ngIf="Gynecology_flag">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel" [hidden]="disease_hidden">Disease<br>
                            <select class="ipcss " [required]="disease_required" [(ngModel)]="disease_txt" (change)="send_data()"
                                disableOptionCentering [disabled]="client">
                                <option disabled value="Select">Select</option>
                                <option *ngFor="let disease of Get_thedise" value={{disease.description}}>
                                    {{disease.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                </div>
            </div>

            <div *ngIf="Pediatrics_flag">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel" [hidden]="disease_hidden">Disease<br>
                            <textarea class="ipcss " [(ngModel)]="disease_txt" [disabled]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="ENT_flag">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="diabetichidden">
                    <mat-label class="matlabel">Disease<br>
                        <textarea class="ipcss " [(ngModel)]="Diabetic_type" required [disabled]="client" (blur)="send_data()"></textarea>
                    </mat-label>
                </div>
            </div>

            <div *ngIf="Others_flag">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel" [hidden]="diabetichidden">Disease<br>
                            <textarea class="ipcss " [(ngModel)]="Diabetic_type" required [disabled]="client" (blur)="send_data()"></textarea>
<!-- 
                            <select class="ipcss " required [(ngModel)]="Diabetic_type" disableOptionCentering
                                [disabled]="client">
                                <option disabled value="Select">Select</option>
                                <option *ngFor="let name of diab_type" value={{name.description}}>{{name.description}}
                                </option>
                            </select> -->
                        </mat-label>
                    </div>
                </div>
            </div>

        </div>
        
        
    <div *ngIf="cardio_flag" class="row">
        <p class="tervys_heading col-12">Treatment</p>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
            <mat-label class="matlabel" [hidden]="medproc_hidden">{{medproc_label}}<br>
                <select class="ipcss " [(ngModel)]="medical_procedure" [disabled]="client" (change)="send_data()">
                    <option *ngFor="let name of medicalproc_array" value={{name.procedure_id}}>
                        {{name.description}}</option>
                </select>
            </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
            <mat-label class="matlabel" [hidden]="fear_hidden">Fear/Anxiety<br>
                <select class="ipcss " [(ngModel)]="fear" [disabled]="client" (change)="send_data()">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-6" [hidden]="popcomp_hidden">
            <mat-label class="matlabel">{{popproc_label}}<br>
                <textarea class="ipcss " [(ngModel)]="popcomp_txt" [disabled]="client" (blur)="send_data()"></textarea>
            </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" style="margin-bottom: 10px;">
            <mat-label class="matlabel">{{next_label}}<br>
                <input type="date" [disabled]="client" class="ipcss_date " id="appt_date" (blur)="send_data()"
                    (change)="OnDateMatePicker(next_data)" [(ngModel)]="next_data" min={{currentDate}} #matInput
                    style="width: 150px;">
            </mat-label>
        </div>
    </div>
    <!-- prabhu doctor -->
    <div class="row">
        <div class="col-6" style="margin-top: 5px;">
            <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" [hidden]="follow_hidden && avoid_hidden">
                    <button class="nav-link " id="diet-tab" data-bs-toggle="tab" data-bs-target="#diet" type="button"
                        role="tab" aria-controls="diet" aria-selected="true" (click)="changetab('diet')"> <div class="tab_cover"><div class="tab_icon_cover"><img src="../../../assets/ui_icons/Add_icon.svg" class="iocinStyle" alt=""></div><div class="lableStyle">Diet</div> </div> </button>
                </li>
                <li class="nav-item" role="presentation" [hidden]="phyex_hidden">
                    <button class="nav-link " id="exercise-tab" data-bs-toggle="tab" data-bs-target="#exercise" type="button"
                        role="tab" aria-controls="exercise" aria-selected="true" (click)="changetab('exercise')"> <div class="tab_cover"><div class="tab_icon_cover"><img src="../../../assets/ui_icons/Add_icon.svg" class="iocinStyle" alt=""></div><div class="lableStyle">Exercise</div> </div> </button>
                </li>
                <li class="nav-item" role="presentation" *ngIf="physioTabFlag">
                   
                    <button class="nav-link " id="therapy-tab" data-bs-toggle="tab" data-bs-target="#therapy"
                        type="button" role="tab" aria-controls="therapy" aria-selected="true"
                        (click)="changetab('therapy')"><div class="tab_cover"><div class="tab_icon_cover"><img src="../../../assets/ui_icons/Add_icon.svg" class="iocinStyle" alt=""></div><div class="lableStyle">Physio</div> </div> </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="Prognosis-tab" data-bs-toggle="tab" data-bs-target="#Prognosis"
                        type="button" role="tab" aria-controls="Prognosis" aria-selected="false"
                        (click)="changetab('prognosis')"><div class="tab_cover"><div class="tab_icon_cover"><img src="../../../assets/ui_icons/progonosis.svg" class="iocinStyle" alt=""></div><div class="lableStyle">Prognosis</div> </div></button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Notes-tab" data-bs-toggle="tab" data-bs-target="#Notes" type="button"
                        role="tab" aria-controls="Notes" aria-selected="false"
                        (click)="changetab('notes')"><div class="tab_cover"><div class="tab_icon_cover"><img src="../../../assets/ui_icons/Notes.svg" class="iocinStyle" alt=""></div><div class="lableStyle">Notes</div> </div></button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane " id="diet" role="tabpanel" aria-labelledby="home-tab"
                    [hidden]="follow_hidden && avoid_hidden">
                    <div class="row">
                        <div [hidden]="follow_hidden" class="col-10">
                            <mat-card id="cardcontent_follow" style="position: relative;">
                                <mat-card-title id="cardheading_follow">Follow</mat-card-title>
                                <mat-card-content style="padding:5px;margin-bottom: 4px;height: 117px;">
                                    <div class="row" style="height: 100%;">
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food type<br>
                                                <select class="ipcss " required [(ngModel)]="food_type_name" (blur)="send_data()"
                                                    disableOptionCentering [disabled]="client">
                                                    <option disabled value="Select">Select</option>
                                                    <option *ngFor="let food of get_foodtype_arry"
                                                        value={{food.description}}>
                                                        {{food.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food item
                                                <input type="text" class="ipcss " required id="country"
                                                    [(ngModel)]="Foot_Item_txt" (keyup)="followfilter($event)" matInput
                                                    [matAutocomplete]="auto7" [readonly]="client" (change)="send_data()"/>
                                                <mat-autocomplete #auto7="matAutocomplete">
                                                    <mat-option id="optionfont" (click)="select_follow_item(item)"
                                                        *ngFor="let item of filteredList" value="{{item}}">{{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div [hidden]="follow_hidden" class="col-2 my-auto" style="text-align: center;">
                            <a (click)="DietToFollowAdd()" [disabled]="client"><img
                                    src="../../../assets/ui_icons/arrow_green.svg" class="saveimgbtn_inpatinfo" /></a>
                        </div>
                        <!-- <div [hidden]="avoid_hidden" class="col-10">
                            <mat-card id="cardcontent_avoid" style="position: relative;">
                                <mat-card-title id="cardheading_avoid">Avoid</mat-card-title>
                                <mat-card-content style="padding:5px;height: 117px;">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food type<br>
                                                <select class="ipcss " required [(ngModel)]="Food_type_avoid"
                                                    disableOptionCentering [disabled]="client"  (change)="send_data()">
                                                    <option disabled value="Select">Select</option>
                                                    <option *ngFor="let food of get_foodtype_arry"
                                                        value={{food.description}}>
                                                        {{food.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food item
                                                <input type="text" class="ipcss " required
                                                    [(ngModel)]="Foot_Item_avoidtxt" (keyup)="avoidfilter($event)"
                                                    aria-label="Number" matInput [matAutocomplete]="auto8" (change)="send_data()"
                                                    [disabled]="client" />
                                                <mat-autocomplete #auto8="matAutocomplete">
                                                    <mat-option id="optionfont" (click)="select_avoid_item(item)"
                                                        *ngFor="let item of filteredList1" value="{{item}}">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div [hidden]="avoid_hidden" class="col-2 my-auto" style="text-align: center;">
                            <a (click)="DietToAvoidAdd()" [disabled]="client"><img
                                    src="../../../assets/ui_icons/arrow_red.svg" class="saveimgbtn_inpatinfo" /></a>
                        </div> -->
                    </div>
                </div>
                <div class="tab-pane " id="exercise" role="tabpanel" aria-labelledby="home-tab"
                [hidden]="phyex_hidden">
                <div class="row" [hidden]="phyex_hidden">
                    <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-5" *ngIf="!phyex_hidden">
                        <mat-label class="matlabel">Exercise<br>
                            <select class="ipcss" [(ngModel)]="life_exer" disableOptionCentering
                                (change)="Exercise_change(life_exer)" [disabled]="client" (change)="send_data()">
                                <option disabled value="Select">Select</option>
                                <option *ngFor="let exercise of Physcial_exercise" value={{exercise.description}}>
                                    {{exercise.description}}</option>
                            </select>
                        </mat-label>
                        <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;" *ngIf="!yoga_div">
                            <table style="margin-top: -5px;">
                                <tr *ngFor="let yoga of Get_theyoga">
                                    <td class="test">
                                        <mat-checkbox multiple [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                            (change)="yoga_click(yoga.yoga_asana_id,yoga.description,$event.checked); send_data()" [disabled]="client">
                                        </mat-checkbox>
                                    </td>
                                    <td style="text-align: left !important;font-size: 10px;">
                                        <span>{{yoga.description}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-xl-3 col-lg-3 col-md-4" *ngIf="!yoga_div">
                        <mat-label class="matlabel">Yoga</mat-label>
    
                    </div> -->
                    <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-3">
                        <mat-label class="matlabel">{{phydure_label}}<br>
                            <select class="ipcss " [(ngModel)]="life_exerdure" disableOptionCentering [disabled]="client" (change)="send_data()">
                                <option disabled value="Select">Select</option>
                                <option value="10 mins">10 mins</option>
                                <option value="15 mins">15 mins</option>
                                <option value="30 mins">30 mins</option>
                                <option value="45 mins">45 mins</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                        <mat-label class="matlabel">{{phyfreq_label}}<br>
                            <select class="ipcss " [(ngModel)]="life_exe_freq" disableOptionCentering [disabled]="client" (change)="send_data()">
                                <option disabled value="Select">Select</option>
                                <option value="Daily">Daily</option>
                                <option value="Alternative days">Alternative days</option>
                                <option value="Twice a week">Twice a week</option>
                                <option value="Thrice a week">Thrice a week</option>
                                <option value="4 days a week">4 days a week</option>
                                <option value="5 days a week">5 days a week</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                        <mat-label class="matlabel">Session<br>
                            <select class="ipcss " [(ngModel)]="exersession" disableOptionCentering [disabled]="client" (change)="send_data()">
                                <option disabled value="Select">Select</option>
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                                <option value="Evening">Evening</option>
                                <option value="Night">night</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-2 my-auto" style="text-align: center;">
                        <a (click)="adexercise(); send_data()" [disabled]="client"><img
                                src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                    </div>
    
                </div>
            </div>
                <div class="tab-pane " id="therapy" role="tabpanel" aria-labelledby="home-tab"
                    [hidden]="therapy_hidden">
                    <div class="row ">
                        <div class="col-11">
                            <div class="row therapyCover">
                                <div class="col-6" >
                                    <mat-label class="matlabel" >Therapy
                                        <select required class="ipcss" [(ngModel)]="therapy_txt">
                                            <option [ngValue]="Select">Select</option>
                                            <option *ngFor="let therapy of therapy_array" value={{therapy.value}}>
                                                {{therapy.label}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4" style="padding: 0;">
                                            <mat-label class="matlabel">Sittings
                                                <input class="ipcss" required [(ngModel)]="sittings" maxlength="3"
                                                    onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)" (change)="send_data()">
                                            </mat-label>
                                        </div>
                                        <div class="col-8">
                                            <mat-label class="matlabel">Start date
                                                <input type="date" [disabled]="client" class="ipcss_date " id="appt_date"
                                                    (change)="physioOnDateMatePicker(next_data)" [(ngModel)]="physioStartDate" min={{currentDate}} #matInput (change)="send_data()"
                                                    >  
                                            </mat-label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-6" *ngIf="therapyhist_array.length != 0">
                                    <mat-label class="matlabel">Physio Theraphist
                                        <select class="ipcss" required [(ngModel)]="physio_name" required (ngModelChange)="checkAvilablity()" (change)="send_data()">
                                            <option [ngValue]="Select">Select</option>
                                            <option *ngFor="let name of therapyhist_array" value={{name.prov_id}}>
                                                {{name.name}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                                    <mat-label class="matlabel">Session<br>
                                        <select class="ipcss" (change)="send_data()"
                                            (ngModelChange)="selectSession($event)" required
                                            [(ngModel)]="session_txt">
                                            <option *ngFor="let name of session"
                                                    value="{{name}}">{{name}}</option>
                                           
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel">From</mat-label>
                                    <div class="row">
                                        <div class="col-4" style="padding-right: 0px;">
                                            <select class="ipcss  " [(ngModel)]="Start_time" (change)="send_data()">
                                                <option *ngFor="let name of starts_from_array"
                                                    value="{{name}}">{{name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4" [hidden]="session_hide"
                                            style="padding-right: 0px;">
                                            <select class="ipcss " [(ngModel)]="end_time" (change)="send_data()">
                                                <option *ngFor="let name of ends_at_array"
                                                    value="{{name}}">{{name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4" [hidden]="session_hide"
                                           >
                                            <select class="ipcss" [(ngModel)]="time_session" (change)="send_data()">
                                                <option value="AM" Selected>AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">Frequency
                                        <select class="ipcss" required [(ngModel)]="visit_freq" required (change)="send_data()">
                                            <option>Every day</option>
                                            <option>Alternative days</option>
                                            <option>3 days once</option>
                                            <option>Once in a week</option>
                                            <option>Once in fortnight</option>
                                            <option>Once in a month</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel">  Remarks  <br>
                                        <textarea class="ipcss noappt_width" [(ngModel)]="physio_remarks" maxlength="500" (change)="send_data()"></textarea>
                                    </mat-label>
                                </div>

                                
                                
                            </div>
                        </div>
                        <div class="col-1 my-auto" style="text-align: center;">
                            <a (click)="addTherapy()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>
                       
                    </div>
                </div>
                <div class="tab-pane  show active" id="Prognosis" role="tabpanel" aria-labelledby="Prognosis-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea style="height: 150px !important;" class="ipcss" required maxlength="750"
                                [(ngModel)]="prog_txt" [disabled]="client"></textarea>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;" [hidden]="prog_hidden">
                            <a (click)="addPrognosis(); send_data()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>

                    </div>
                </div>
                <div class="tab-pane" id="Notes" role="tabpanel" aria-labelledby="Notes-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea class="ipcss txtarea" style="height: 150px !important;" rows="1" maxlength="750"
                                [(ngModel)]="remarks" [disabled]="client" (keyup)="getNotes()" (change)="send_data()">
                                
                            </textarea>
                            <div class="auto_complete_text1" *ngIf="noteslist.length != 0">
                                <ul *ngFor="let symptom of noteslist">
                                    <li>
                                        <a (click)="select_symptom(symptom)">{{symptom}}</a>
                                    </li>
                                </ul>
                            </div>   
                            
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addRemarks()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 ">
            <div class="table_cover " *ngIf="diet_table" style="margin-top: 55px;"
                [hidden]="follow_hidden && avoid_hidden">
                <div class="col-12" style="margin-top: 5px; height: 160px;">
                    <div [hidden]="dietfollowadd.length == 0" class="dig_table_overflow">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dietfollw of dietfollowadd">
                                        <td>
                                            <div class="dia_table_left">{{dietfollw.food_type_name}}</div>
                                        </td>
                                        <td>
                                            <div class="dia_table_left">{{dietfollw.food_item}}</div>
                                        </td>
                                        <td>
                                            <a [disabled]="client"
                                                (click)="DeleteDietFollow(dietfollw.food_type_name,dietfollw.food_item)"><img
                                                    src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                    class="editDelicon" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="margin-top: 5px; height: 160px;">
                    <div [hidden]="dietavoidwadd.length == 0" class="dig_table_overflow">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Food type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dietavoid of dietavoidwadd">
                                        <td>
                                            <div class="dia_table_left">{{dietavoid.food_type_name}}</div>
                                        </td>
                                        <td>
                                            <div class="dia_table_left">{{dietavoid.food_item}}</div>
                                        </td>
                                        <td>
                                            <a [disabled]="client"
                                                (click)="DeleteDietAvoid(dietavoid.Food_type_avoid,dietavoid.food_item)"><img
                                                    src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                    class="editDelicon" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table_cover" *ngIf="prognosis_table" style="margin-top: 55px;">
                <div class="col-12" *ngIf="prognosis_array_flag" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="prognosis_array.length != 0">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Doctor name</th>
                                        <th>Description</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prognosis of prognosis_array">
                                        <td style="width: 16%;font-size: 10px;">{{prognosis.date}}</td>
                                        <td style="width: 27%;text-align: left;font-size: 10px;">{{prognosis.doc_name}}</td>
                                        <td style="text-align: left;font-size: 10px;">{{prognosis.prognosis}}</td>
                                        <td style="width: 40px;" *ngIf="!prognosis_array_flag"></td>
                                        <td style="width: 40px;" *ngIf="prognosis_array_flag" [disabled]="client"><img
                                                src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                                (click)="edit_prognosis(prognosis)" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table_cover" *ngIf="notes_table" style="margin-top: 55px;">
                <div class="col-12" *ngIf="remarksList.length" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="remarksList.length != 0">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th style="width: 150px;">Date</th>
                                        <th>Note</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let remark of remarksList">
                                        <td style="font-size: 10px;">{{remark.Date}}</td>
                                        <td style="text-align: left;font-size: 10px;">{{remark.remarks_data}}</td>
                                        <td style="width: 40px;" *ngIf="!remarksList_flag"></td>
                                        <td style="width: 40px;" *ngIf="remarksList_flag"><img
                                                src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                                (click)="edit_remarks(remark)" [disabled]="client" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="table_cover " *ngIf="therapy_table" style="margin-top: 55px;">
                <div class="col-12" style="position: relative;">
                    <!-- <div class="toggleCover" [hidden]="therapyList.length == 0"> 
                        <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="booknowFlag" ngDefaultControl
                        [checked]="booknowFlag" (change)="bookNow($event.source.checked)" [disabled]="client">
                        <span class="familytab" *ngIf="!booknowFlag"><img class="family_toggle_img"
                                src="../../../assets/ui_icons/physio_test_red.svg"></span>
                        <span class="familytab" *ngIf="booknowFlag"><img class="family_toggle_img"
                             src="../../../assets/ui_icons/physio_test_green.svg"></span>
                         </mat-button-toggle>
                    </div> -->
<!--                     
                    <img src="../../../assets/ui_icons/update_icon.svg" (click)="updateTherapy()" class="updateIcon" style="float: right;"/> -->

                    <div [hidden]="therapyList.length == 0" class="dig_table_overflow">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Theraphy</th>
                                    <th>No of sittings</th>
                                    <th>Frequency</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let therapy of therapyList">
                                    <td>{{therapy.therapy_name}}</td>
                                    <td>{{therapy.sittings}}</td>
                                    <td>{{therapy.frequency}}</td>
                                    <td>
                                      <a (click)="delete_therapy(therapy.therapy_id)"><img
                                        src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                        class="editDelicon" /></a>
                                    </td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12" >
                    
                    <!-- <img src="../../../assets/ui_icons/buttons/history.svg" (click)="getTherapyHist()" class="saveimgbtn_inpatinfo" style="float: right;"/> -->
                </div>
            </div>
            <div class="table_cover" *ngIf="exercisetable" style="margin-top: 55px;">
                <div class="col-12" *ngIf="exercise_array_flag" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="exercisearray.length != 0">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <!-- <th>Date</th> -->
                                        <th>Exercise</th>
                                        <th>Duration</th>
                                        <th>Frequency</th>                                       
                                        <th>Session</th>
                                        <th> &nbsp;</th>
                                        <th> &nbsp;</th>
                                        <!-- <th> &nbsp;</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prognosis of exercisearray">
                                        <td style="width: 16%;font-size: 10px;">{{prognosis.life_exer}} <div><span *ngIf="prognosis.yogaflag == false">- {{prognosis.yogasanas}}</span></div></td>
                                        <td style="width: 27%;text-align: left;font-size: 10px;">{{prognosis.recom_exec_dur}}</td>
                                        <td style="text-align: left;font-size: 10px;">{{prognosis.recom_exec_freq}}</td>
                                        <td style="width: 40px;" >{{prognosis.session}}</td>
                                        <!-- <td style="width: 40px;" *ngIf="prognosis.yogaflag == false">{{prognosis.yogasanas}}</td> -->
                                        <td style="width: 40px;"  [disabled]="client"><img
                                                src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                                (click)="editexercise(prognosis)" /></td>
                                        <td style="width: 40px;"  [disabled]="client"><img
                                                    src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon"
                                                    (click)="deleteexercise(prognosis)" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

    </div>

    <div class="row" style="padding-top: 20px;">
        <div class="col-12 col-sm-6 col-ld-4 col-md-4 col-xl-4"
            *ngIf="General_flag || ENT_flag || Pediatrics_flag || Diabetes_flag || !oflag || Gynecology_flag">
            <div>
                <mat-label [hidden]="next_hidden" class="matlabel">{{next_label}}<br>
                    <input type="date" class="ipcss_date " id="appt_date" (change)="OnDateMatePicker(next_data)"
                        [disabled]="client" [(ngModel)]="next_data" min={{currentDate}} #matInput style="width: 140px;" (blur)="send_data()">
                </mat-label>

            </div>
        </div>
        <!-- <div class="col-12 col-sm-6 col-ld-4 col-md-4 col-xl-4" *ngIf="Diabetes_flag || !oflag">
            <div>
                <mat-label class="matlabel">{{next_label}}<br>
                    <input type="date" class="ipcss_date " id="appt_date" (change)="OnDateMatePicker(next_data)"
                        [disabled]="client" [(ngModel)]="next_data" min={{currentDate}} #matInput style="width: 140px;"
                        [readonly]="client">
                </mat-label>
            </div>
        </div> -->
    </div>
    <div *ngIf="refer_flag" style="margin-top: 20px;">
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading"><strong>Referred doctor</strong></p>
            </div>
        </div>
        <div class="row" *ngIf="refer_flag">
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <mat-label class="matlabel">{{hospital_label}}<br>
                    <input type="text" class="ipcss " maxlength="50" [(ngModel)]="hosp_name"
                        (keyup)="hospital_filter($event)" aria-label="Number" matInput [matAutocomplete]="auto1"
                        [disabled]="client" (change)="send_data()"/>
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option id="optionfont" *ngFor="let item of filteredhospList"
                            (click)="select_hospital(item)" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-label>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <mat-label class="matlabel">{{spl_label}}<br>
                    <mat-select class="ipcss" [(ngModel)]="speclization_txt" [disabled]="client" (blur)="send_data()">
                        <mat-option *ngFor="let spl of get_speclization_arry" value={{spl.spl_name}}>{{spl.spl_name}}
                        </mat-option>
                    </mat-select>
                </mat-label>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <mat-label class="matlabel">{{refer_label}}<br>
                    <input type="text" class="ipcss " maxlength="50" [(ngModel)]="refer_txt"
                        (keyup)="Get_referal($event)" (blur)="doctornamebased()" aria-label="Number" matInput [matAutocomplete]="auto2"
                        [disabled]="client" (change)="send_data()"/>
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                            (click)="Select_refer_doctor(item)" value={{item}}>
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-label>
            </div>
        </div>
    </div>
</div>