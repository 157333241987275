<div class="Details">
    
    <div class="row" style="margin: 0px;">
        <div class="header" *ngIf="!cardview_flag">
            <div ><a (click)="backClicked()" style="float: right;padding: 10px 2px;"></a>
            </div>
        </div>
        <div *ngIf="cardview_flag" id="casesheet_slider_cover">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let user of cards;let i = index">
                    <div class="slide">
                        <div class="cu_slider_cover">
                            <!-- <mdb-card class="m-2 view rgba-white-slight waves-light card"
                                (click)="viewRecord(user, i,'card_'+i)"> -->
                                
                                <div class="card_border1" (click)="viewRecord(user, i,'card_'+i)">
                                    <div [ngSwitch]="user">
                                        <div *ngSwitchCase="'Appointment_Details'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/appointments.svg" alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Personal'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/personal.svg" alt="Card image cap">
                                        </div>
                                       
                                        <div *ngSwitchCase="'Family'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/family_details.svg" alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Health & Medication'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/health_medications.svg" alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Observations'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/Observations.svg" alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Reports'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/reports.svg" alt="Card image cap">
                                        </div>
                                    </div>
                                </div>
                                <a tabindex="0">
                                    <div class="mask">
                                    </div>
                                </a>
                            <!-- </mdb-card> -->
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>

        <!-- <div class="card" style=" width: 100%;">
            
            <div class="card-header  white-text">
                <div class="d-flex">
                    <div class="font-small font-weight-bold col-8">Name</div>
                    <div class="font-small font-weight-bold col-2">Gender</div>
                    <div class="font-small font-weight-bold col-2">Age</div>
                </div>
            </div>
            <div class="card-body card_border">
                <div class="d-flex">
                    <div class="col-8">
                      
                        {{patient_name}}
                    </div>
                    <div class="col-2">{{patient_gender}}</div>
                    <div class="col-2">{{patient_age}}</div>
                </div>
            </div>
        </div> -->

        <div class="mt-1">
            <div class="col-12 container-fluid p-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" [id]="'cardindex_'+i"
                    *ngFor="let user of cards;let i = index">
                        <!-- Card -->
                        <mdb-card class="mb-4 view card_border" [id]="'card_'+i" mdbwaveseffect style="height: 290px;"  >
                            <mdb-card-header class="text-center primary-color white-text">
                                <div [ngSwitch]="user">
                                    <div *ngSwitchCase="'Personal'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/personal.svg" class="details_card"  alt="">
                                    </div>
                                    <div *ngSwitchCase="'Health & Medication'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/health_medication.svg" class="details_card"  alt="">
                                    </div>
                                    <div *ngSwitchCase="'Appointment_Details'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/appointments.svg" class="details_card"  alt="">
                                    </div>
                                    <div *ngSwitchCase="'Family'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/family_details.svg" class="details_card"  alt="">
                                    </div>
                                    <div *ngSwitchCase="'Reports'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/reports.svg" class="details_card"  alt="">
                                    </div>
                                    <div *ngSwitchCase="'Observations'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/Observations.svg" class="details_card"  alt="">
                                    </div>
                                </div>
                                <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 1px 0px 1px 33px">{{ user }}</h5>
                                <div *ngIf="cardview_flag">
                                    <a (click)="opencard(userlist.patient_id,user,i,'card_'+i,false)"
                                        class="white-text">
                                        <span class="closeicon"><img  src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:20px"> </span>
                                        <!-- <mdb-icon fas icon="cancel"></mdb-icon> -->
                                    </a>
                                </div>
                            </mdb-card-header>
                            <!--Card content-->
                            <mdb-card-body class="">
                                <div> 
                                    <div *ngIf="user == 'Appointment_Details'">
                                        <app-second-opinion-casesheet-apptdetails>
                                        </app-second-opinion-casesheet-apptdetails>
                                    </div>
                                    <div *ngIf="user == 'Personal'">
                                        <app-second-opinion-casesheet-personal>
                                        </app-second-opinion-casesheet-personal>
                                    </div>
                                    <div *ngIf="user == 'Health & Medication'">
                                        <app-second-opinion-casesheet-hm></app-second-opinion-casesheet-hm>
                                    </div>
                                    <div *ngIf="user == 'Family'">
                                        <app-second-opinion-casesheet-familyhistory>
                                        </app-second-opinion-casesheet-familyhistory>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-second-opinion-casesheet-reports></app-second-opinion-casesheet-reports>
                                    </div>
                                    <div *ngIf="user == 'Observations'">
                                        <app-second-opinion-casesheet-observations></app-second-opinion-casesheet-observations>
                                    </div>
                                </div>
                            </mdb-card-body>
                            <a *ngIf="!cardview_flag" (click)="opencard(userlist.patient_id,user,i,'card_'+i,true)">
                                <div class="mask waves-effect waves-light" mdbWavesEffect></div>
                            </a>
                        </mdb-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>