<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back_Nav()" class="saveimgbtn_inpatinfo" />
            <img class="saveimgbtn_inpatinfo" (click)="book()" src="../../../assets/ui_icons/buttons/Book_now.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6" *ngFor="let diag of diagList">
                <mat-card id="cardcontent" style="margin:5px">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-4">
                        <img class="diagimage" src="{{diag.profile_image}}">
                      </div>
                      <div class="col-8">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel"><b>{{diag.center_name}}</b></mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{ diag.address}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.location}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.city}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.state}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">Telephone :{{diag.telephone}}</mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card id="cardcontent" style="margin:5px">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel"><b>Wesite :</b> {{diag.website}}</mat-label>
                      </div>
                      <div class="col-12">
                        <mat-label class="matlabel"><b>Availability :</b>{{ diag.available}}</mat-label>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel">Booking Date
                      <input type="date" class="ipcss_date" [(ngModel)]="date" (change)="apptBookingDate(date,'date')"
                        [min]="currentDate" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel">Booking Session<br>
                      <mat-select class="ipcss " [(ngModel)]="visitSession" disableOptionCentering>
                        <mat-option *ngFor="let session of visitingSessionData" value={{session.description}}>
                          {{session.description}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <div class="table-responsive dig_table_overflow">
                  <table id="tbl" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Group name</th>
                        <th>Test name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tests of test_type; let i=index;">
                        <td data-th="Group name" style="text-align: left;">{{tests.test}}</td>
                        <td data-th="Test name" style="text-align: left;">{{tests.subtest}}</td>
                        <td data-th="Action">
                          <mat-checkbox color="primary" [(ngModel)]="tests.check"></mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>