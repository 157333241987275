import * as CryptoJS from 'crypto-js';

export class encrypt_decript {
    
    static Encript(text) {
        var key = CryptoJS.enc.Utf8.parse("23251B659CBB6E293F6CB59373120398");
        var iv = CryptoJS.enc.Utf8.parse("F8FA505BDD36EFBD");

        return CryptoJS.AES.encrypt(text, key, { iv: iv });
    }

    static Decript(text) {
        var key = CryptoJS.enc.Utf8.parse("23251B659CBB6E293F6CB59373120398");
        var iv = CryptoJS.enc.Utf8.parse("F8FA505BDD36EFBD");

        var decrypt = CryptoJS.AES.decrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypt.toString(CryptoJS.enc.Utf8);
    }
}