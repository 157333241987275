import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { DatePipe, Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-assign-list',
  templateUrl: './nurse-assign-list.component.html',
  styleUrls: ['./nurse-assign-list.component.scss']
})
export class NurseAssignListComponent implements OnInit {
  public currentDatetime = null;
  public appDate = null;
  public cancelArray = [];
  public nodapp: boolean;
  public homeCare: string;
  public caseSheet: string;
  public caseSummery: string;
  public appointmentArray = [];
  public loader: boolean;
  public getAppDate;
  public session;
  public appointmentDate: string;
  public userInfo: any = [];
  public userId;
  hptl_clinic_id: any;
  user_ids: any;
  is_admin: boolean = false;
  doctorid: any;
  NursenameList: any;
  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService, public datepipe: DatePipe) {
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.changeProviderType();
    this.getCurrentDate();

  }
  changeProviderType() {

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.is_admin = true;
      this.getNurseList();
    }

  }
  changeNurse() {
    this.userId = this.doctorid;
    this.getCurrentDate();
  }
  getNurseList() {
    this.NursenameList = [];
    var send_data = {
      center_id: this.hptl_clinic_id,
      type: "3"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/guth', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var NurseName = '';
              var fname, mname, lname;
              if (
                obj.users[i].middle_name != '' &&
                obj.users[i].middle_name != undefined
              ) {
                NurseName =
                  obj.users[i].first_name +
                  ' ' +
                  obj.users[i].middle_name +
                  ' ' +
                  obj.users[i].last_name;
                fname = obj.users[i].first_name;
                mname = obj.users[i].middle_name;
                lname = obj.users[i].last_name;
              } else {
                NurseName =
                  obj.users[i].first_name + ' ' + obj.users[i].last_name;
                fname = obj.users[i].first_name;
                lname = obj.users[i].last_name;
              }
              this.NursenameList.push({
                NurseName: NurseName,
                Nursedata_id: obj.users[i].user_id,

              });
            }
          }
        },
        (error) => {

        }
      );
  }
  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            //this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
            this.appointmentDate = obj.current_date;
            //this.tomorrow = new Date(obj.current_date);
            // this.tomorrow.setDate(this.tomorrow.getDate() + 2);
            //this.Appointment_Date = this.CurrentDatetime;
            //this.currentDate = this.getDateMatePicker1(this.tomorrow);//display in html for maximum date
            this.getDoctorApp(this.userId);
          }
        },
        error => {
        }
      )
  }
  getDoctorApp(data): void {
    this.loader = false;
    this.appointmentArray = [];
    var data = null;
    if (this.caseSheet != null || this.caseSummery != null) {
      this.homeCare = undefined
    }
    var get_tmp = this.appointmentDate.split('-');
    this.getAppDate = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'hcare/getasg',
      JSON.stringify({
        prov_id: this.userId,
        country: ipaddress.country_code.toString(),
        imei: this.userInfo.imei_no,
        type: "nurse"
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          // if (data.login != null) {
          //   this.loader = true;
          //   let myModal = this.modalCtrl.create('SessionBlockPopupPage');
          //   myModal.present();
          // } else {
          if (data.appointments != null) {
            this.loader = true;
            if (data.appointments.length != "0") {
              for (var i = 0; i < data.appointments.length; i++) {
                var schduleTime = data.appointments[i].schedule_time.split(':');
                var time = this.tConvert(schduleTime[0] + ":" + schduleTime[1]);
                //   var time = Date_Formate(data.appointments[i].schedule_time);
                var appdate = data.appointments[i].start_date.split('-');
                this.appDate = appdate[2] + "-" + appdate[1] + "-" + appdate[0]
                var clientname;
                if (data.appointments[i].client_middle_name != undefined || data.appointments[i].client_middle_name != null) {
                  clientname = encrypt_decript.Decript(data.appointments[i].client_first_name) + " " + encrypt_decript.Decript(data.appointments[i].client_middle_name) + " " + encrypt_decript.Decript(data.appointments[i].client_last_name);
                } else {
                  clientname = encrypt_decript.Decript(data.appointments[i].client_first_name) + " " + encrypt_decript.Decript(data.appointments[i].client_last_name);
                }
                var docname = data.appointments[i].doc_first_name + " " + data.appointments[i].doc_middle_name + " " + data.appointments[i].doc_last_name;
                var apptext = this.appDate + " - " + time + " appointment for " + clientname + " by " + docname;
                this.appointmentArray.push({
                  appointment: apptext,
                  app_date: this.appDate,
                  doc_app_id: data.appointments[i].assignment_id,
                  checked: false,
                });
             
              }
              this.nodapp = true; // show
            } else {
              this.nodapp = false; // show
            }
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
          //}
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  cancelMultiApp(app_id, check) {
    if (check == false) {
      this.cancelArray.push(app_id);
      for (var i = 0; i < this.appointmentArray.length; i++) {
        if (app_id == this.appointmentArray[i].doc_app_id) {
          this.appointmentArray[i].checked = true;
        }
      }
    } else {
      for (var i = 0; i < this.cancelArray.length; i++) {
        if (app_id == this.cancelArray[i]) {
          this.cancelArray.splice(this.cancelArray.indexOf(app_id), 1);
          break;
        }
      }
      for (var j = 0; j < this.appointmentArray.length; j++) {
        if (app_id == this.appointmentArray[j].doc_app_id) {
          this.appointmentArray[j].checked = false;
        }
      }
    }
  }
  Date(value) {
    this.appointmentDate = value;
    this.getDoctorApp(this.userId);
  }
  doctorView(doc_app_id) {
    Nurse_Helper.setAssignInfo(null);
    var assing = {
      // city_zip : city_desc + " - " + rel_zipcode,
      // state_country :  state_desc + " , " + country_desc,
      app_id: doc_app_id,
      home_care: "1"
    }
    Nurse_Helper.setAssignInfo(assing);
    this.messageService.sendMessage("assignDetailView");
    // this.navCtrl.push('NurseAssignViewPage', {
    //   app_id: doc_app_id,
    //   home_care: "1"
    // })
  }
  cancel() {
    if (this.cancelArray != undefined && this.cancelArray.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          np_cancel: this.cancelArray,
          home_care: "0",
          type: "nurse",
          reason: "Busy"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.getDoctorApp(this.userId);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
