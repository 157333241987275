import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-donationhistory',
  templateUrl: './donationhistory.component.html',
  styleUrls: ['./donationhistory.component.css']
})
export class DonationhistoryComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public donateHistoryData = [];
  public donationUrl;
  public sendData;

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router,public dialog: MatDialog) {
    this.donationUrl = ipaddress.getIp + "bd/bdhist";
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      }
    }
    
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.getRelations();
  }
 
  myCallbackFunction = function (_params) { // callback...
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.donationUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.donateHistoryData = [];
        if (data.json().donation_history != null) {
          for (var b = 0; b < data.json().donation_history.length; b++) {
            var obj = data.json().donation_history[b];
            this.donateHistoryData.push({
              hospital: obj.hptl_name,
              date: obj.donation_date,
              units: obj.no_units,
              accept: obj.donated,
            });
          }
        }
      }, error => {});
  }
}
