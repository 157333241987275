<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Diagnosis Prescription</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()"  width="85px" height="auto" src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="padding-left: 23px;padding-right: 23px;">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Relation
              <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="relationId" required>
                <mat-option *ngFor="let relation of relationList" (click)="getSltRelationDetails(relation.rel_id)"
                  value="{{relation.rel_id}}">{{relation.rel_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div [hidden]="!report_show" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-label class="matlabel">No Report found</mat-label>
          </div>
          <div [hidden]="diagPresListFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="table-responsive dig_table_overflow">
              <table id="tbl" class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let prescription of diagPresList" (click)="openDiagpres(prescription.pres_diag_id)">
                    <td style="text-align: left;">{{"Dr."+ prescription.doctorname}}</td>
                    <td style="text-align: left;">{{prescription.clientname}}</td>
                    <td>{{prescription.date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>