import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { DomSanitizer } from '@angular/platform-browser';
import { ServerApi } from '../../server-api';
import { DietService } from '../diet-view/diet-view.service';
import { Diet_Helper } from '../Diet_Helper';
import { DietCasesheetService } from '../diet-casesheet/diet-casesheet.service';
declare var $: any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diet-vitals',
  templateUrl: './diet-vitals.component.html',
  styleUrls: ['./diet-vitals.component.scss']
})
export class DietVitalsComponent implements OnInit {
  public vitalFlag = "min";
  subscription: Subscription;

  public heightMeasure;
  public weightMeasure;
  public height;
  public weight;
  public BP01;
  public BP02;
  public heartRate;
  public temperature;
  public lifeStyleId;
  public pulse:boolean;
  public bmi;
  public bmr;
  public exerRoutine: string;
  public calories: any;
  public bodyNature: string;
  public patientList;
  public userInfo;
  public userId;
  public client: boolean;

  constructor(public dialog: MatDialog, public http: Http, public serviceAPI: ServerApi,public dietservice:MenuViewService,
    public router: Router, public route: ActivatedRoute, public toastr: ToastrService,public sanitizer: DomSanitizer,public messageservice: DietCasesheetService) { 
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      this.vitalFlag = message;
      this.heightMeasure = "cms";
      this.weightMeasure = "kgs";
    });
    this.heightMeasure = "cms";
    this.weightMeasure = "kgs";
    this.userInfo = Helper_Class.getInfo();
    this.patientList = Diet_Helper.getClient_Info();
    this.userId = this.userInfo.user_id;
    this.getVitals();
    if(this.patientList != undefined){
      if(this.patientList.height != undefined && this.patientList.height != "" && this.patientList.height != null){
         if(this.patientList.height != undefined && this.patientList.height != ""){
          this.height = this.patientList.height;
          this.heightMeasure = this.patientList.height_measure;
        }
        if(this.patientList.weight != undefined && this.patientList.weight != ""){
          this.weight = this.patientList.weight;
          this.weightMeasure = this.patientList.weight_measure;
        }
        this.temperature = this.patientList.temparature;
        if(this.height != undefined && this.weight != undefined){
          this.calculateCalories();
        }
        if(this.patientList.bp != undefined){
          var bp = this.patientList.bp.split("/");
          if(bp[0] != null && bp[0] != "null")
            this.BP01 = bp[0];
          else
            this.BP01 = "";

          this.BP02 = bp[1];
        }
        this.heartRate =  this.patientList.pulse;
              //Bmi and mnr
        if (this.bmi != null) {
          var datavalue=null;
          datavalue={
            txt_bmr:this.bmr,
            txt_bmi:this.bmi,
            height:this.height,
            height_measure:this.heightMeasure,
            weight: this.weight,
            weight_measure: this.weightMeasure,
            temparature:this.temperature,
            bp:this.patientList.bp,
            pulse:this.heartRate, 
            bodynatute:this.bodyNature,  
            flag:"vitals"       
          }
          
          
        }        
      }
    }
  }

  calculateCalories() {//Get bmi and bmr and calories data
    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmr = "";
      this.bmi = "";
    } else {
      if (this.height != undefined) {
        if (this.heightMeasure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');

        } else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');

          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }

        if (this.heightMeasure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }

      if (this.weightMeasure == "kgs" && this.heightMeasure == "inch") {
        var pound = this.weight * 2.20462;
        var height_txt = parseFloat(this.height)
        var dat = pound / (height_txt * height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

        if (this.patientList.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.patientList.Age_data) + 5;
          
          this.bmr = Math.round(get_bmr);

        } else if (this.patientList.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.patientList.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          
        }

      } else if (this.weightMeasure == "kgs" && this.heightMeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);

        if (this.patientList.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.patientList.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
          
          } else if (this.patientList.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.patientList.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          
        }

      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "inch") {
        var height_txt = parseFloat(this.height);
        var dat = this.weight / (height_txt * height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

        if (this.patientList.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.patientList.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
          

        } else if (this.patientList.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.patientList.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          

        }

      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "cms") {
        var height_txt = parseFloat(this.height);
        var inch = height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      

        if (this.patientList.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.patientList.Age_data) + 5;
          this.bmr = Math.round(get_bmr);

        } else if (this.patientList.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.patientList.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }
      
      var SelectedText1 = this.exerRoutine;
      if (SelectedText1 == "Light") {
        var get_txt = this.bmr * 1.375;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.bmr * 1.55;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.bmr * 1.725;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.bmr * 1.9;
        this.calories = Math.round(get_txt);

      } else {
        var get_txt = parseFloat(this.bmr) * 1.375;
        this.calories = Math.round(get_txt);
      }

      if (this.bmi < 18.5) {
        this.bodyNature = "Under weight";

      } else if (this.bmi >= 18.5 && this.bmi <= 24.9) {
        this.bodyNature = "Normal weight";

      } else if (this.bmi >= 25 && this.bmi <= 29.9) {
        this.bodyNature = "Over weight";

      } else {
        this.bodyNature = "Obase";
      }

    }
    this.send_data();
    //this.dentalservice.sendMessage(THIS.TXT);
  }

  send_data(){
    var vitals = {
      height: this.height,
      height_measure: this.heightMeasure,
      weight: this.weight,
      weight_measure: this.weightMeasure,
      bmi: this.bmi,
      bmr: this.bmr,
      temparature:this.temperature,
      blood_pressure:this.patientList.bp,
      pulse:this.heartRate, 
      body_nature:this.bodyNature,  
    }
    Diet_Helper.setVitals(vitals);
  }

  save() {
    var chk_read_flag = true;
    var Send_data = null;
    var pedia_case_hist_id = undefined;
    var bpvar: any;
    var lif_stl_id = undefined;

    if (this.height == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.heightMeasure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weight == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weightMeasure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().life_style_id != null) {
        lif_stl_id = Helper_Class.getRet().life_style_id;
      }

      if (Helper_Class.getRet().case_hist_id != null) {
        pedia_case_hist_id = Helper_Class.getRet().case_hist_id;
      }
    }

    if (this.BP01 != undefined && this.BP02 != undefined) {
      bpvar = this.BP01 + "/" + this.BP02;
    } else {
      bpvar = null;
    }

    if (this.patientList.sub_id != "" && this.patientList.sub_id != undefined) {
      Send_data = {
        client_reg_id: this.patientList.Client_id,
        doc_app_id: this.patientList.app_id,
        relation_id: this.patientList.rel_id,
        sub_rel_id: this.patientList.sub_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height,
        height_measure: this.heightMeasure,
        weight: this.weight,
        weight_measure: this.weightMeasure,
        bp: bpvar,
        //pulse: this.Heart_Beat,
        temparature: this.temperature,
        case_hist_id: pedia_case_hist_id,
        bmi:this.bmi,
        bmr:this.bmr,
      };
    }
    else {
      Send_data = {
        client_reg_id: this.patientList.Client_id,
        doc_app_id: this.patientList.app_id,
        relation_id: this.patientList.rel_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height,
        height_measure: this.heightMeasure,
        weight: this.weight,
        weight_measure: this.weightMeasure,
        bp: bpvar,
      // pulse: this.Heart_Beat,
        temparature: this.temperature,
        case_hist_id: pedia_case_hist_id,
        bmi:this.bmi,
        bmr:this.bmr,
      };
    }
  

    if (chk_read_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/svt', JSON.stringify(Send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.key != null) {
              if (obj.key == "1") {
                var datavalue=null;
                datavalue={
                  // bodynatute:this.body_nature,
                  txt_bmr:this.bmr,
                  txt_bmi:this.bmi,
                  height:this.height,
                  height_measure:this.heightMeasure,
                  weight: this.weight,
                  weight_measure: this.weightMeasure,
                  temparature:this.temperature,
                  bp:bpvar,
                  pulse:this.heartRate,
                  bodynatute:this.bodyNature,
                  flag:"vitals"       
                }
                    
                this.toastr.success("Vital details saved successfully");
              } else {
                this.toastr.error("Vital details not saved, please try again later");
              }
            }
          },
          error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          }
        )

    } else {
      this.toastr.error("Please enter mandatory fields");
    }
  }

  async getVitals(){
    var sen_pass = {
      life_style_id : this.patientList.life_style_id
    }
    var response = await this.serviceAPI.PostData('lifestyle/gvt', sen_pass).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.height = obj.height != undefined ? obj.height : "";
      this.heightMeasure = obj.height_measure != undefined ? obj.height_measure : "";
      this.weight = obj.weight != undefined ? obj.weight : "";
      this.weightMeasure = obj.weight_measure != undefined ? obj.weight_measure : "";
      this.bmi = obj.bmi != undefined ? obj.bmi : "";
      this.bmr = obj.bmr != undefined ? obj.bmr : "";
      this.temperature = obj.temparature != undefined ? obj.temparature : "";
      this.BP01 = obj.BP_01 != undefined ? obj.BP_01 : "";
      this.BP02 = obj.BP_02 != undefined ? obj.BP_02 : "";
      this.heartRate = obj.Heart_Beat != undefined ? obj.Heart_Beat : "";
    }
  }
}
