import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-physio-appointment-list',
  templateUrl: './physio-appointment-list.component.html',
  styleUrls: ['./physio-appointment-list.component.css']
})
export class PhysioAppointmentListComponent implements OnInit {
  public apptUrl: string;
  public Client_Id;
  public appointmentList = [];
  public currentDate;
  public currentTime;
  public timeSplit;
  public currentDateUrl;
  public checkboxFlag: Boolean = false;
  public deleteViewFlag: Boolean = false;
  public deleteAppList = [];
  public deleteAppUrl;
  public checkDeleteFlag: Boolean = false;
  public appId;
  public nodata: boolean;
  public homecare: string;
  public appNursePhysio;

  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any
  public checklistid: any = [];
  public appOpenCount:number = 0;
public typeLableName;
  constructor(public messageservice:ClientViewService,public commData:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.apptUrl = ipaddress.getIp + "appointment/getnpapps/";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.deleteAppUrl = ipaddress.getIp + "appointment/npdelapps";
  }

  ngOnInit(): void {
    this.appNursePhysio= Client_Helper.getHomecare_nurse_physio();
    this.homecare = Client_Helper.getHomecare();
    this.getCurrentDate();
    this.nodata = true;
    this.typeLableName = this.appNursePhysio == 'dietician' ? "Dietician" : "Physiotherapist";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [6] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients with name or "+this.typeLableName+" or Appointment Date "
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getCurrentDate();
    this.getApptList("all");
  }

  getCurrentDate() {
    var sendData = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(sendData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
      }, error => {});
  }

  getApptList(relation_desc) {
    if (this.commData.Client_login_detail_data != undefined) {
      var send_data = {
        client_reg_id: this.commData.Client_login_detail_data.Client_Id,
        relation: relation_desc,
        type: this.appNursePhysio,
        home_care: this.homecare,
        country:"IN"
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        this.appointmentList = [];
        var response = data.json();
        for (var i = 0; i < response.appointments.length; i++) {
          var new_datalist = response.appointments[i];
          var clnt_name = "";
          if (new_datalist.middle_name != undefined) {
            clnt_name = encrypt_decript.Decript(new_datalist.first_name.toString()) + ' ' + encrypt_decript.Decript(new_datalist.middle_name.toString()) + ' ' + encrypt_decript.Decript(new_datalist.last_name.toString());
          } else {
            clnt_name = encrypt_decript.Decript(new_datalist.first_name.toString()) + ' ' + encrypt_decript.Decript(new_datalist.last_name.toString());
          }

          var doc_name = "";
          if (new_datalist.dr_middle_name != undefined) {
            doc_name = new_datalist.dr_first_name + ' ' + new_datalist.dr_middle_name + ' ' + new_datalist.dr_last_name;
          } else {
            doc_name = new_datalist.dr_first_name + ' ' + new_datalist.dr_last_name;
          }
          console.log("CLIENT NAME "+clnt_name)
          if (new_datalist.f_time != null) {
            this.currentTime = Time_Formate_Session(new_datalist.f_time, encrypt_decript.Decript(new_datalist.session));
          }
          var Status_txt = "";
          if (this.currentDate == new_datalist.date) 
          {
            this.timeSplit = this.currentTime.split(':');
            if (new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              if (encrypt_decript.Decript(new_datalist.session).toString() == "Morning") {
                if (parseInt(this.timeSplit[0]) < 16) {
                  Status_txt = "Open";
                } else {
                  Status_txt = "Not visited";
                }
              } else if (encrypt_decript.Decript(new_datalist.session).toString() == "Afternoon") {
                Status_txt = "Open";
              } else if (encrypt_decript.Decript(new_datalist.session).toString() == "Evening") {
                Status_txt = "Open";
              } else {
                Status_txt = "Open";
              }
            }
            if (new_datalist.status == "2" && new_datalist.is_cancelled == "0") {
              if (encrypt_decript.Decript(new_datalist.session).toString() == "Morning") {
                if (parseInt(this.timeSplit[0]) < 16) {
                  Status_txt = "Confirmed";
                } else {
                  Status_txt = "Not visited";
                }
              } else if (encrypt_decript.Decript(new_datalist.session).toString() == "Afternoon") {
                Status_txt = "Confirmed";
              } else if (encrypt_decript.Decript(new_datalist.session).toString() == "Evening") {
                Status_txt = "Confirmed";
              } else {
                Status_txt = "Confirmed";
              }
            }
          } else {
            if (new Date(this.currentDate) < new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Open";
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Not visited";
            }
            if (new Date(this.currentDate) < new Date(new_datalist.date) && new_datalist.status == "2" && new_datalist.is_cancelled == "0") {
              Status_txt = "Confirmed";
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "2" && new_datalist.is_cancelled == "0") {
              Status_txt = "Not visited";
            }
          }
          if (new_datalist.is_cancelled == "1" && new_datalist.status == "1") {
            Status_txt = "Rejected"; 
          }
          if (new_datalist.is_cancelled == "1" && new_datalist.status == "0") {
            Status_txt = "Cancelled"; 
          }
          if (new_datalist.is_cancelled == "0" && new_datalist.status == "0") {
            Status_txt = "Completed";
          }
          if (this.deleteViewFlag == true) {
            if (Status_txt != "Open" && Status_txt != "Confirmed" && Status_txt != "Awaiting for rating") {
              this.appointmentList.push({
                app_id: new_datalist.app_id,
                Doctor_Name: doc_name,
                Client_Name: clnt_name,
                Date: new_datalist.date,
                Session: encrypt_decript.Decript(new_datalist.session).toString(),
                Time_Token: this.currentTime,
                statustxt: Status_txt
              })
            }
          } else {
            this.appointmentList.push({
              app_id: new_datalist.app_id,
              Doctor_Name: doc_name,
              Client_Name: clnt_name,
              Date: new_datalist.date,
              Session: encrypt_decript.Decript(new_datalist.session).toString(),
              Time_Token: this.currentTime,
              statustxt: Status_txt
            })
          }
          if (this.appointmentList.length == 0) {
            this.nodata = true;
          } else {
            this.nodata = false;
          }
        }
      },
      error => {
      }
    )
  }

  delAppList() {
    this.checkboxFlag = true;
    this.deleteViewFlag = true
    this.checkDeleteFlag = true;
    this.getApptList("all");
  }
  
  getdelete(app_id, check_value) {
    if (check_value == true) {
      this.deleteAppList.push(app_id);
    } else {
      for (var i = 0; i < this.deleteAppList.length; i++) {
        if (this.deleteAppList[i] == app_id) {
          this.deleteAppList.splice(this.deleteAppList.indexOf(i), 1);
          break;
        }
      }
    }
  }

  viewAppt(app_id) {
    this.appId = app_id;
    Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
    Client_Helper.setApp_id(app_id);
    this.messageservice.sendMessage("physio_appointment_detailed_view");
  }

  deleteAppointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.deleteAppUrl, 
      JSON.stringify({ 
        apps_id: this.deleteAppList, 
        type: this.appNursePhysio,
       }), { headers: headers }).subscribe(
      data => {
        var response = data.json();
        if (response.result == "Appointment deleted successfully") {
          this.checkDeleteFlag = false;
          this.checkboxFlag = false;
          this.toastr.success(response.result);
          this.getApptList("all");
        } else {
          this.toastr.error(response.result);
        }
      },
      error => {
      })
  }

  confirmDelete() {
    if (this.deleteAppList.length == 0) {
      this.toastr.error(Message_data.sltAppt);
    }
    else {
      this.deleteAppointment();
    }
  }

  createPhysioNurseAppt(){
    if(this.appNursePhysio == "physio" || this.appNursePhysio == "dietician"){
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      this.messageservice.sendMessage("physio_appcreate_new");
    }
    if(this.appNursePhysio == "nurse"){
      this.messageservice.sendMessage("client_nurse_appcreate_new");
    }
  }

  checkUncheckAll() {
    this.checklist = this.appointmentList;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.appOpenCount) {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].statustxt == 'Open' || this.checklist[i].statustxt == 'Confirmed') {
            var doc_id = this.checklist[i].app_id;
            this.checklistid.push(doc_id);
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].statustxt == 'Open' || this.checklist[i].statustxt == 'Confirmed') {
          var doc_id = this.checklist[i].app_id;
          this.checklistid.push(doc_id);
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  isAllSelected(appid) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.indexOf(appid) !== -1) {
        var index = this.checklistid.indexOf(appid);
        this.checklistid.splice(index, 1);
      } else {
        this.checklistid.push(appid);
      }
    } else {
      this.checklistid.push(appid);
    }
  }

  getCheckedItemList(){
  if (this.checklist.length == this.checklistid.length) {
    this.toastr.success(Message_data.allRowSlt);
  } else if (this.checklistid.length != 0) {
    this.toastr.success('Thank you...', ``);
  } else {
    this.checklistid = `No row seleted`;
    this.toastr.success(Message_data.noRowSlt);
  } 
  }

  clearAll() {
    this.checklist = this.appointmentList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  confirmAppointment() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: this.appNursePhysio,
          doc_app_ids: this.checklistid,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.getApptList("all");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }

  cancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var send
      if(this.appNursePhysio=='dietician'){
        send ={
          type: this.appNursePhysio,
          diet_cancel: this.checklistid,
          home_care: this.homecare,
        }   
      }else{
        send ={
          type: this.appNursePhysio,
          np_cancel: this.checklistid,
          home_care: this.homecare,
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify(send),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.getApptList("all");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
