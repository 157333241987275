import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { OtcNonpresPageComponent } from '../otc-nonpres-page/otc-nonpres-page.component';
import { DocumentDetailsZoomPageComponent } from '../document-details-zoom-page/document-details-zoom-page.component';
import { ReactPageComponent } from '../react-page/react-page.component';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { DropdownModelpageComponent } from 'src/app/Doctor_module/dropdown-modelpage/dropdown-modelpage.component';
import pdfMake from 'pdfmake/build/pdfmake';
import { Message_data } from 'src/assets/js/Message_data';
declare var $: any;

@Component({
  selector: 'app-secondopinionretrival',
  templateUrl: './secondopinionretrival.component.html',
  styleUrls: ['./secondopinionretrival.component.css']
})
export class SecondopinionretrivalComponent implements OnInit {
  public retrival;
  public exerciseMasterList = [];
  public retrvExerciseList = [];
  public exercise: any;
  public toggAlchol: boolean;
  public toggSmoking: boolean;
  public toggGutka: boolean;
  public toggExercise;
  public getSecOpnData;
  public panelOpenState10: boolean;
  public panelOpenState1: boolean;
  public panelOpenState2: boolean;
  public panelOpenState3: boolean;
  public panelOpenState4: boolean;
  public panelOpenState5: boolean;
  public panelOpenState6: boolean;
  public panelOpenState7: boolean;
  public panelOpenState8: boolean;
  public panelOpenState9: boolean;
  public subRelId;
  public mainConcernMasterList = [];
  public sendMainConcernList = [];
  public mainconcern: any;
  public medicalHistoryMasterList = [];
  public sendMedicalHistoryList = [];
  public medhist: any;
  public religiousBeliefMasterList = [];
  public sendReligiousBeliefList = [];
  public retrvReligiousBelief = [];
  public religiousBelief: any;
  public anyOtherIssue: string;
  public bloodPressure: string;
  public stressFactor: string;
  public dietaryHabits: string;
  public bmi;
  public height;
  public heightMeasure: string;
  public weight;
  public weightMeasure: string;
  public agreeBox: boolean;
  public opinionType: any;
  public presUrl: string;
  public docUrl: string;
  public sendData;
  public sendDocumentData;
  public prescriptionList;
  public presIdsList = [];
  public documentList = [];
  public docIdsList;
  public sendImageData;
  public imageFileStr;
  public imageFileName;
  public Image_table_list = [];
  public setGeneralDataList;
  public saveSecOpnFinalData;
  public secOpnDoctorList;
  public appointmentClientData;
  public appointmentDoctorData;
  public secOpnGeneralData;
  public secOpnHealthmedicineData;
  public secOpnFamilyData;
  public secOpnDocumentData;
  public sendReportData: any;
  public appointmentData;
  public appointmentDataList;
  public bloodReportList = [];
  public urineReportList = [];
  public feacesReportList = [];
  public xrayReportList = [];
  public scanReportList = [];
  public ultrasoundReportList = [];
  public retrvBloodReportList = [];
  public retrvUrineReportList = [];
  public retrvFeacesReportList = [];
  public retrvXrayReportList = [];
  public retrvScanReportList = [];
  public retrvUltrasoundReportList = [];
  public retrvPrescriptionList = [];
  public retrvDocumentList;
  public retrvImageList = [];
  public header_footer_flag: boolean = false;
  public addDocumentFlag: boolean = false;
  public extraadd_document_div: boolean = false;
  public agreeBoxFlag: boolean = false;
  public retival;
  public sendUltraSelectList = [];
  public sendScanSelectList = [];
  public sendXraySelectList = [];
  public sendFeacesSelectList = [];
  public sendUrineSelectList = [];
  public sendBloodSelectList = [];
  public saveUpdateFlag: boolean;
  public newUpload;
  public ynoption: boolean = false;
  public ynopt13: boolean = false;
  public ynopt1: boolean = false;
  public ynopt2: boolean = false;
  public ynopt3: boolean = false;
  public ynopt4: boolean = false;
  public ynopt5: boolean = false;
  public ynopt6: boolean = false;
  public ynopt7: boolean = false;
  public ynopt8: boolean = false;
  public ynopt9: boolean = false;
  public ynopt10: boolean = false;
  public ynopt11: boolean = false;
  public ynopt12: boolean = false;
  public familyHistData;
  public fieldDisable: boolean = false;
  exits: boolean;
  public panelOpenState11: boolean;
  public panelOpenState12: boolean;
  public panelOpenState13: boolean;
  public panelOpenState14: boolean;
  public anyPresMedFlag: boolean;
  public otcMed: boolean;
  public otcMedFlag: boolean;
  public vitamins: boolean;
  public vitaminsFlag: boolean;
  public react: boolean;
  public reactFlag: boolean;
  public recentFlag: boolean;
  public freqFlag: boolean;
  public wgtLossFlag: boolean;
  public wgtGainFlag: boolean;
  public allergyFlag: boolean;
  public allergy: boolean;
  public otcNonpresData = [];
  public vitaminHerbalData = [];
  public reactionMedsupplyData = [];
  public allergyData = [];
  public setHealthDataList;
  public getHealthData;
  public presMedFlag: boolean;
  public ImgFilePath: any;
  public RetPresImg: any;
  public retrvMedicalHistory = [];
  public retrvMainConcern = [];
  public pastMedical;
  public userInfo: any;
  public userId: any;
  public opinionList = [];
  public maritalStatus: string;
  public exerRoutine: string;
  public exerDuration: string;
  public exerFreq: string;
  public exerFreqLabel;
  public exerciseFlag: boolean
  public smokeDuration: string;
  public smokeFrequency: string;
  public alcholDuration: string;
  public alcoholFrequency: string;
  public gutkaDuration: string;
  public gutkaFrequency: string;
  public alcoholQuant: string;
  public alcoholMix: string;
  public exerciseDuration: string;
  public alcoholQuantityList = [];
  public alcoholMixingList = [];
  public exerciseRoutineList = [];
  public exerDurationLabel;
  public yogaFlag: boolean = true;
  public getYogaList;
  public sendYogaData = [];
  public diagnosisDisabledFlag;
  public retrvSecOpnAppointmntdata: any = {};
  public retrvSecOpnGeneralData: any = {};
  public retrvSecOpnHealthData;
  public imageDataList = [];
  public clntBlood;
  public clntContNumber;
  public observeFlag: boolean;
  public genObser: string;
  public medObser: string;
  public diagObser: string;
  public pdfObj;
  public photos = [];
  public pdf;
  public base64data;

  constructor(public clientview:ClientViewService,public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.saveUpdateFlag = false;
    this.presMedFlag = true;
    this.ImgFilePath = null;
    this.familyHistData = {
      diabetics: false,
      heart_attack: false,
      stroke: false,
      arterial: false,
      endocrine: false,
      autoimmune: false,
      hypertension: false,
      hemophilia: false,
      tuberculosis: false,
      hiv: false,
      hepatitis: false,
      glaucoma: false,
      other: ""
    }
    this.gservice.set_second_health_data(null);
    this.gservice.set_appoint_client_data(null);
    this.gservice.set_second_family_data(null);
    this.gservice.set_second_general_data(null);
    this.gservice.set_second_appointment_data(null);
    this.gservice.set_second_document_data(null);
    this.gservice.second_opinion_gen_mainconcern = [];
    this.gservice.second_opinion_gen_medhist = [];
    this.gservice.second_opinion_gen_religious_beliefs = [];
    this.gservice.second_opinion_gen_excer = [];
    this.saveUpdateFlag = false;
    this.newUpload = false;
    this.sendUltraSelectList = [];
    this.sendScanSelectList = [];
    this.sendXraySelectList = [];
    this.sendFeacesSelectList = [];
    this.sendUrineSelectList = [];
    this.sendBloodSelectList = [];
    this.docIdsList = [];
    this.Image_table_list = [];
    this.prescriptionList = [];
    this.documentList = [];

    this.maritalStatus = "Single";
    this.stressFactor = "None"
    this.anyOtherIssue = "None";
    this.bloodPressure = "Normal";
    this.heightMeasure = "cms";
    this.weightMeasure = "kgs";
    this.alcholDuration = "<1 Year";
    this.alcoholFrequency = "<5";
    this.smokeDuration = "<1 Year";
    this.smokeFrequency = "<5";
    this.gutkaDuration = "<1 Year";
    this.gutkaFrequency = "<5";
    this.exerciseDuration = "15 Mins";
    this.anyPresMedFlag = false;
    this.otcMedFlag = false;
    this.vitaminsFlag = false;
    this.vitamins = false;
    this.reactFlag = false;
    this.vitaminsFlag = false;
    this.react = false;
    this.reactFlag = false;
    this.recentFlag = false;
    this.wgtGainFlag = false;
    this.wgtLossFlag = false;
    this.freqFlag = false;
    this.allergyFlag = false;
    this.imageFileStr = "assets/img/default.jpg";
    this.presUrl = ipaddress.getIp + "record/medprescriptions/";
    this.docUrl = ipaddress.getIp + "secopn/cdoc";

  }
  ngOnInit() {
    this.getOpinionData();
    this.getMedicalHistory();
    this.getMainConcern();
    this.getReligiousOtherBeliefs();
    this.getSecOpnData = Client_Helper.getopinionchecked();
    this.getSecOpnRetrivalData();
    this.setGeneralData();
    if (this.getSecOpnData != null && this.getSecOpnData.opinion_id != undefined) {
      this.agreeBoxFlag = true;
      this.appointmentData = this.gservice.get_second_appointment_data();
      this.sendData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation: this.getSecOpnData.relationship_name,
        home_care: "0"
      };
      this.sendDocumentData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.getSecOpnData.relationship_name,
      };
      this.sendReportData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation: this.getSecOpnData.relationship_name,
        home_care: "0"
      }
      this.getPrescriptions();
      this.getDocuments();
      this.getBloodReports(this.sendReportData);
      this.getUrineReports(this.sendReportData);
      this.getFeacesReports(this.sendReportData);
      this.getScanReports(this.sendReportData);
      this.getXrayReports(this.sendReportData);
      this.getUltrasoundReports(this.sendReportData);
      this.appointmentDataList = Client_Helper.getopinionlistarray();
      if (this.appointmentDataList.status != null && this.appointmentDataList.status == "0") {
        this.presIdsList = [];
        this.docIdsList = [];
        this.extraadd_document_div = false;
        this.addDocumentFlag = false;
      } else {
        this.extraadd_document_div = true;
      }
    }else {
      this.agreeBoxFlag = false;
      this.appointmentData = this.gservice.get_appoint_client_data();
      this.sendData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation: this.getSecOpnData.relationship_name,
        home_care: "0"
      };
      this.sendDocumentData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.getSecOpnData.relationship_name,
      };
      this.sendReportData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation: this.getSecOpnData.relationship_name,
        home_care: "0"
      }
      this.presIdsList = [];
      this.docIdsList = [];
      this.getDocuments();
      this.getBloodReports(this.sendReportData);
      this.getUrineReports(this.sendReportData);
      this.getFeacesReports(this.sendReportData);
      this.getScanReports(this.sendReportData);
      this.getXrayReports(this.sendReportData);
      this.getUltrasoundReports(this.sendReportData);
      this.addDocumentFlag = true;
    }
  }

  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  back() {
  this.clientview.sendMessage('secondopinionlist')
  }

  getSecOpnRetrivalData() {
    this.storeGeneralData()
    this.setGeneralData();
    this.retrival = Client_Helper.getSO_client_details();
    for (var i = 0; i < this.retrival.length; i++) {
      var opinion_id = this.retrival[i].opinion_id;
      var doc_reg_id = this.retrival[i].doc_id
    }
    var list = JSON.stringify({
      opinion_id: opinion_id,
      doc_reg_id: doc_reg_id,
      country: "IN"
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "secopn/opnbyid", list, { headers: headers }).subscribe(
      data => {
        this.retrvSecOpnGeneralData = {};
        this.secOpnGeneralData = {};
        this.retrvSecOpnAppointmntdata = {};
        this.secOpnFamilyData = {};
        if (data.json() != null && data.json().opn_doc_id != undefined) {
          this.retrvSecOpnAppointmntdata.opinion_id = data.json().opinion_id;
          if (data.json().doc_reg_id != null) {
            this.retrvSecOpnAppointmntdata.doc_reg_id = data.json().doc_reg_id;
          }
          if (data.json().sub_rel_id != null) {
            this.subRelId = data.json().sub_rel_id;
          }
          if (data.json().opn_doc_id != null) {
            this.retrvSecOpnAppointmntdata.opn_doc_id = data.json().opn_doc_id;
          }
          if (data.json().status != null) {
            this.retrvSecOpnAppointmntdata.status = data.json().status;
          }
          if (data.json().relationship_name != null) {
            this.retrvSecOpnAppointmntdata.relationship_name = data.json().relationship_name;
          }
          if (data.json().mem_first_name != null) {
            this.retrvSecOpnAppointmntdata.mem_first_name = encrypt_decript.Decript(data.json().mem_first_name);
          }
          if (data.json().mem_middle_name != null) {
            this.retrvSecOpnAppointmntdata.mem_middle_name = encrypt_decript.Decript(data.json().mem_middle_name);
          }
          else { }
          if (data.json().mem_last_name != null) {
            this.retrvSecOpnAppointmntdata.mem_last_name = encrypt_decript.Decript(data.json().mem_last_name);
          }
          if (data.json().mem_mobile != null) {
            this.retrvSecOpnAppointmntdata.mem_mobile = encrypt_decript.Decript(data.json().mem_mobile);
          }
          if (data.json().blood_desc != null) {
            this.retrvSecOpnAppointmntdata.blood_desc = data.json().blood_desc;
          }
          if (data.json().age != null) {
            if (data.json().age == 0) {
              this.retrvSecOpnAppointmntdata.age = "<1";
            } else {
              this.retrvSecOpnAppointmntdata.age = data.json().age;
            }
          }
          if ((data.json().gender) != null) {
            this.retrvSecOpnAppointmntdata.gender = encrypt_decript.Decript(data.json().gender);
          }
          if ((data.json().location) != null) {
            this.retrvSecOpnAppointmntdata.location = encrypt_decript.Decript(data.json().location);
          }
          if ((data.json().city) != null) {
            this.retrvSecOpnAppointmntdata.city = encrypt_decript.Decript(data.json().city);
          }
          if (data.json().opinion_desc != null) {
            this.retrvSecOpnAppointmntdata.opinion_desc = data.json().opinion_desc;
            this.secOpnGeneralData.opinion_desc = data.json().opinion_desc;
          }
          if (data.json().medicare_name != null) {
            this.retrvSecOpnAppointmntdata.medicare_name = data.json().medicare_name;
          }
          if (data.json().specialization != null) {
            this.retrvSecOpnAppointmntdata.specialization_name = data.json().specialization;
          }
          if (data.json().hptl_name != null) {
            if (data.json().dr_middle_name != null && data.json().dr_middle_name != "") {
              this.retrvSecOpnAppointmntdata.doctor_name = data.json().dr_first_name + " " + data.json().dr_middle_name + " " + data.json().dr_last_name;
            }
            else {
              this.retrvSecOpnAppointmntdata.doctor_name = data.json().dr_first_name + " " + data.json().dr_last_name;
            }
            this.retrvSecOpnAppointmntdata.hptl_name = data.json().hptl_name;
            this.retrvSecOpnAppointmntdata.hptl_location = data.json().hptl_location;
            this.retrvSecOpnAppointmntdata.hptl_city = data.json().hptl_city;
            this.retrvSecOpnAppointmntdata.appointment_date = this.gservice.get_Date_Formate(data.json().appointment_date);
            this.retrvSecOpnAppointmntdata.app_session = encrypt_decript.Decript(data.json().session);
            this.retrvSecOpnAppointmntdata.app_from_time = this.gservice.get_Timeformate(data.json().from_time);
            this.retrvSecOpnAppointmntdata.app_to_time = this.gservice.get_Timeformate(data.json().to_time);
          }
          if (data.json().status == "0") {
            this.retrvSecOpnAppointmntdata.status = data.json().status;
          }
          this.gservice.set_second_appointment_data(this.retrvSecOpnAppointmntdata);
          this.gservice.set_appoint_client_data(this.retrvSecOpnGeneralData)
          if (data.json().alc_duration != null) 
          {
            this.retrvSecOpnGeneralData.alcohol = "Yes";
            this.retrvSecOpnGeneralData.quantity = data.json().alcohol_qty_id;
            this.retrvSecOpnGeneralData.mixing_type = data.json().alcohol_mixing_id;
            this.retrvSecOpnGeneralData.alc_duration = data.json().alc_duration;
            this.retrvSecOpnGeneralData.alc_frequency = data.json().alc_frequency;
          } else {
            this.retrvSecOpnGeneralData.alcohol = "No";
            this.panelOpenState7 = false;
          }
          if (data.json().smoke_duration != null) 
          {
            this.retrvSecOpnGeneralData.smoke = "Yes";
            this.retrvSecOpnGeneralData.smoke_duration = data.json().smoke_duration;
            this.retrvSecOpnGeneralData.smoke_frequency = data.json().smoke_frequency;
          } else {
            this.retrvSecOpnGeneralData.smoke = "No";
          }
          if (data.json().gutka_duration != null) 
          {
            this.retrvSecOpnGeneralData.gutka = "Yes";
            this.retrvSecOpnGeneralData.gutka_duration = data.json().gutka_duration;
            this.retrvSecOpnGeneralData.gutka_frequency = data.json().gutka_frequency;
          } else {
            this.retrvSecOpnGeneralData.gutka = "No";
          }
          if (data.json().opinion_desc != null) {
            this.retrvSecOpnGeneralData.opinion_type = data.json().opinion_desc;
          }
          if (data.json().marital_status != null) {
            this.retrvSecOpnGeneralData.marital_status = data.json().marital_status;
          }
          if (data.json().bmi != null) {
            this.retrvSecOpnGeneralData.bmi = data.json().bmi;
            this.bmi = data.json().bmi;
          }
          if (data.json().stress_factor != null) {
            this.retrvSecOpnGeneralData.stress_factor = data.json().stress_factor;
          }
          if (data.json().blood_pressure != null) {
            this.retrvSecOpnGeneralData.blood_pressure = data.json().blood_pressure;
          }
          if (data.json().diet_habits != null) {
            this.retrvSecOpnGeneralData.diet_habits = data.json().diet_habits;
          }
          if (data.json().height != null) {
            this.retrvSecOpnGeneralData.height = data.json().height;
          }
          if (data.json().height_measure != null) {
            this.retrvSecOpnGeneralData.height_measure = data.json().height_measure;
          }
          if (data.json().weight != null) {
            this.retrvSecOpnGeneralData.weight = data.json().weight;
          }
          if (data.json().weight_measure != null) {
            this.retrvSecOpnGeneralData.weight_measure = data.json().weight_measure;
          }
          if (data.json().other_issues != null) {
            this.retrvSecOpnGeneralData.other_issues = data.json().other_issues;
          }
          if (data.json().exercises != null) {
            for (var i = 0; i < data.json().exercises.length; i++) {
              this.gservice.second_opinion_gen_excer.push(data.json().exercises[i].exercise_id)
              this.retrvExerciseList.push(data.json().exercises[i].exercise_id);
            }
            this.changeExercise(this.retrvExerciseList)
          }
          if (data.json().exer_routine != null) {
            this.retrvSecOpnGeneralData.exer_routine = data.json().exer_routine;
          }
          if (data.json().exer_day_dur != null) {
            this.retrvSecOpnGeneralData.exer_day_dur = data.json().exer_day_dur;
          }
          if (data.json().medical_history != null) {
            for (var i = 0; i < data.json().medical_history.length; i++) {
              this.gservice.second_opinion_gen_medhist.push(data.json().medical_history[i].medical_hist_id)
              this.retrvMedicalHistory.push(data.json().medical_history[i].med_hist_id);
            }
            this.changeMedicalHistory(this.retrvMedicalHistory)
          }
          if (data.json().main_concerns != null) {
            for (var i = 0; i < data.json().main_concerns.length; i++) {
              this.gservice.second_opinion_gen_mainconcern.push(data.json().main_concerns[i].main_concerns_id)
              this.retrvMainConcern.push(data.json().main_concerns[i].main_concerns_id);
            }
            this.changeMainConcern(this.retrvMainConcern)
          }
          if (data.json().religious_beliefs != null) {
            this.gservice.second_opinion_gen_religious_beliefs = [];
            for (var i = 0; i < data.json().religious_beliefs.length; i++) {
              this.gservice.second_opinion_gen_religious_beliefs.push(data.json().religious_beliefs[i].religious_belief_id)
              this.retrvReligiousBelief.push(data.json().religious_beliefs[i].religious_belief_id);
            }
            this.changeReligiousBelief(this.retrvReligiousBelief)
          }
          this.gservice.set_second_general_data(this.retrvSecOpnGeneralData);
          this.setGeneralData();
          this.getBasicData();
          if (data.json().diabetics != null) 
          {
            this.secOpnFamilyData.Retrival = true;
            if (data.json().diabetics != "No" && data.json().diabetics != "no") {
              this.ynopt1 = true;
              this.secOpnFamilyData.diabetics = "yes";
            } else {
              this.ynopt1 = false;
              this.secOpnFamilyData.diabetics = "no";
            }
            if (data.json().heart_attack != "No" && data.json().heart_attack != "no") {
              this.ynopt2 = true;
              this.secOpnFamilyData.heart_attack = "yes";
            } else {
              this.ynopt2 = false;
              this.secOpnFamilyData.heart_attack = "no";
            }
            if (data.json().stroke != "No" && data.json().stroke != "no") {
              this.ynopt3 = true;
              this.secOpnFamilyData.stroke = "yes";
            } else {
              this.ynopt3 = false;
              this.secOpnFamilyData.stroke = "no";
            }
            if (data.json().arterial != "No" && data.json().arterial != "no") {
              this.ynopt4 = true;
              this.secOpnFamilyData.arterial = "yes";
            } else {
              this.ynopt4 = false;
              this.secOpnFamilyData.arterial = "no";
            }
            if (data.json().endocrine != "No" && data.json().endocrine != "no") {
              this.ynopt5 = true;
              this.secOpnFamilyData.endocrine = "yes";
            } else {
              this.ynopt5 = false;
              this.secOpnFamilyData.endocrine = "no";
            }
            if (data.json().autoimmune != "No" && data.json().autoimmune != "no") {
              this.ynopt6 = true;
              this.secOpnFamilyData.autoimmune = "yes";
            } else {
              this.ynopt6 = false;
              this.secOpnFamilyData.autoimmune = "no";
            }
            if (data.json().hypertension != "No" && data.json().hypertension != "no") {
              this.ynopt7 = true;
              this.secOpnFamilyData.hypertension = "yes";
            } else {
              this.ynopt7 = false;
              this.secOpnFamilyData.hypertension = "no";
            }
            if (data.json().hemophilia != "No" && data.json().hemophilia != "no") {
              this.ynopt8 = true;
              this.secOpnFamilyData.hemophilia = "yes";
            } else {
              this.ynopt8 = false;
              this.secOpnFamilyData.hemophilia = "no";
            }
            if (data.json().tuberculosis != "No" && data.json().tuberculosis != "no") {
              this.ynopt9 = true;
              this.secOpnFamilyData.tuberculosis = "yes";
            } else {
              this.ynopt9 = false;
              this.secOpnFamilyData.tuberculosis = "no";
            }
            if (data.json().hiv != "No" && data.json().hiv != "no") {
              this.ynopt10 = true;
              this.secOpnFamilyData.hiv = "yes";
            } else {
              this.ynopt10 = false;
              this.secOpnFamilyData.hiv = "no";
            }
            if (data.json().hepatitis != "No" && data.json().hepatitis != "no") {
              this.ynopt11 = true;
              this.secOpnFamilyData.hepatitis = "yes";
            } else {
              this.ynopt11 = false;
              this.secOpnFamilyData.hepatitis = "no";
            }
            if (data.json().glaucoma != "No" && data.json().glaucoma != "no") {
              this.ynopt12 = true;
              this.secOpnFamilyData.glaucoma = "yes";
            } else {
              this.ynopt12 = false;
              this.secOpnFamilyData.glaucoma = "no";
            }
            this.secOpnFamilyData.others = data.json().others;
            this.secOpnFamilyData.disabled = true;
            this.gservice.set_second_family_data(this.secOpnFamilyData);
          }
          this.retrvSecOpnHealthData = {};
          if (data.json().pres_med_doc != null) {
            this.retrvSecOpnHealthData.pres_med_doc = data.json().pres_med_doc
          }
          if (data.json().recent_weight_gain != null) {
            this.retrvSecOpnHealthData.disabled = true;
            this.retrvSecOpnHealthData.recent_weight_gain = data.json().recent_weight_gain;
          }
          if (data.json().recent_weight_loss != null) {
            this.retrvSecOpnHealthData.recent_weight_loss = data.json().recent_weight_loss
          }
          if (data.json().tiredness_frequency != null) {
            this.retrvSecOpnHealthData.tiredness_frequency = data.json().tiredness_frequency
          }
          if (data.json().recent_fever_chills != null) {
            this.retrvSecOpnHealthData.recent_fever_chills = data.json().recent_fever_chills
          }
          if (data.json().prescribed_medicine != null) {
            this.retrvSecOpnHealthData.prescribed_medicine = data.json().prescribed_medicine
          }
          if (data.json().allergies != null) {
            this.retrvSecOpnHealthData.allergies = data.json().allergies
          }
          if (data.json().otc_medicines != null) {
            this.retrvSecOpnHealthData.otc_medicines = data.json().otc_medicines
          }
          if (data.json().med_reaction != null) {
            this.retrvSecOpnHealthData.med_reaction = data.json().med_reaction
          }
          if (data.json().vitamins != null) {
            this.retrvSecOpnHealthData.vitamins = data.json().vitamins
          }
          this.gservice.set_second_health_data(this.retrvSecOpnHealthData);
          this.getRetrievData()
          this.secOpnDocumentData = {};
          if (data.json().doc1 != null && data.json().doc1 != "") {
            if (data.json().doc1.indexOf("#") >= 0) {
              var document_data = data.json().doc1.split('#');
              this.imageDataList.push({
                documentFile: data.json().doc1,
                document: document_data[1],
                document_image: data.json().doc1, 
                show_image: "assets/img/Page_icon/pdf_preview.png" 
              })
            } else {
              var document_data = data.json().doc1.split('secondopinion/');
              this.imageDataList.push({
                documentFile: data.json().doc1,
                document: document_data[1],
                document_image: ipaddress.Ip_with_img_address + data.json().doc1,
                show_image: ipaddress.Ip_with_img_address + data.json().doc1,
              })
            }
            this.secOpnDocumentData.doc1 = data.json().doc1;
          }
          if (data.json().doc2 != null && data.json().doc2 != "") {
            if (data.json().doc2.indexOf("#") >= 0) {
              var document_data = data.json().doc2.split('#');
              this.imageDataList.push({
                documentFile: data.json().doc2,
                document: document_data[1],
                document_image: data.json().doc2,
                show_image: "assets/img/default.jpg"
              })
            } else {
              var document_data = data.json().doc2.split('secondopinion/');
              this.imageDataList.push({
                documentFile: data.json().doc2,
                document: document_data[1],
                document_image: ipaddress.Ip_with_img_address + data.json().doc2,
                show_image: ipaddress.Ip_with_img_address + data.json().doc2,
              })
            }
            this.secOpnDocumentData.doc2 = data.json().doc2;
          }
          if (data.json().doc3 != null && data.json().doc3 != "") {
            if (data.json().doc3.indexOf("#") >= 0) {
              var document_data = data.json().doc3.split('#');
              this.imageDataList.push({
                documentFile: data.json().doc3,
                document: document_data[1],
                document_image: data.json().doc3,
                show_image: "assets/img/default.jpg"
              })
            } else {
              var document_data = data.json().doc3.split('secondopinion/');
              this.imageDataList.push({
                documentFile: data.json().doc3,
                document: document_data[1],
                document_image: ipaddress.Ip_with_img_address + data.json().doc3,
                show_image: ipaddress.Ip_with_img_address + data.json().doc3,
              })
            }
            this.secOpnDocumentData.doc3 = data.json().doc3;
          }
          if (data.json().doc4 != null && data.json().doc4 != "") {
            if (data.json().doc4.indexOf("#") >= 0) {
              var document_data = data.json().doc4.split('#');
              this.imageDataList.push({
                documentFile: data.json().doc4,
                document: document_data[1],
                document_image: data.json().doc4,
                show_image: "assets/img/default.jpg"
              })
            } else {
              var document_data = data.json().doc4.split('secondopinion/');
              this.imageDataList.push({
                documentFile: data.json().doc4,
                document: document_data[1],
                document_image: ipaddress.Ip_with_img_address + data.json().doc4,
                show_image: ipaddress.Ip_with_img_address + data.json().doc4,
              })
            }
            this.secOpnDocumentData.doc4 = data.json().doc4;
          }
          if (data.json().doc5 != null && data.json().doc5 != "") {
            if (data.json().doc5.indexOf("#") >= 0) {
              var document_data = data.json().doc5.split('#');
              this.imageDataList.push({
                documentFile: data.json().doc5,
                document: document_data[1],
                document_image: data.json().doc5,
                show_image: "assets/img/default.jpg"
              })
            } else {
              var document_data = data.json().doc5.split('secondopinion/');
              this.imageDataList.push({
                documentFile: data.json().doc5,
                document: document_data[1],
                document_image: ipaddress.Ip_with_img_address + data.json().doc5,
                show_image: ipaddress.Ip_with_img_address + data.json().doc5,
              })
            }
            this.secOpnDocumentData.doc5 = data.json().doc5;
          }
          if (this.imageDataList.length != 0) {
            this.secOpnDocumentData.upload_documentimg_list = this.imageDataList;
          } else {
          }
          if (data.json().blood == null && data.json().urine == null &&
            data.json().faeces == null && data.json()["x-ray"] == null &&
            data.json().scans == null && data.json()["ultra-sound"] == null) {
          } else {
            if (data.json().blood != null) 
            {
              this.secOpnDocumentData.Blood_Report_Data = data.json().blood;
            }
            if (data.json().urine != null) 
            {
              this.secOpnDocumentData.Urine_Report_Data = data.json().urine;
            }
            if (data.json().faeces != null) 
            {
              this.secOpnDocumentData.Faeces_Report_Data = data.json().faeces;
            }
            if (data.json()["x-ray"] != null) 
            {
              this.secOpnDocumentData.Xray_Report_Data = data.json()["x-ray"];
            }
            if (data.json().scans != null) 
            {
              this.secOpnDocumentData.Scan_Report_Data = data.json().scans;
            }
            if (data.json()["ultra-sound"] != null) 
            {
              this.secOpnDocumentData.UltraSound_Report_Data = data.json()["ultra-sound"];
            }
          }
          if (data.json().pres_drug != null) {
            this.secOpnDocumentData.Prescription_List_Data = data.json().pres_drug;
          }
          if (data.json().gen_observ != null || data.json().med_observ != null || data.json().diag_observ != null) {
            this.observeFlag = false;
            this.genObser = data.json().gen_observ;
            this.medObser = data.json().med_observ;
            this.diagObser = data.json().diag_observ
          }
          if (data.json().status == "0") {
            this.secOpnDocumentData.status = data.json().status;
          }
          this.gservice.set_second_document_data(this.secOpnDocumentData);
          this.setRetrivalDocumentData();
        }
      }, error => {
      });
  }

  changeMainConcern(selected) {
    this.mainconcern = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.retrvMainConcern = selected;
    if (this.retrvMainConcern.length != 0) {
      var n = 0;
      for (var j = 0; j < this.retrvMainConcern.length; j++) {
        for (var i = 0; i < this.mainConcernMasterList.length; i++) {
          if (this.retrvMainConcern[j] == this.mainConcernMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.mainConcernMasterList[i].label);
          }
        }
      }
      this.mainconcern = selected_data.toString();
    }
  }

  selectMedicalHistory() {
    for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
      this.medicalHistoryMasterList[j].checked = false;
    }
    if (this.retrvMedicalHistory != undefined && this.retrvMedicalHistory.length != 0) {
      for (var i = 0; i < this.retrvMedicalHistory.length; i++) {
        for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
          if (this.retrvMedicalHistory[i] == this.medicalHistoryMasterList[j].value) {
            this.medicalHistoryMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Past Medical History",
        main_array: this.medicalHistoryMasterList,
        selected_list:this.retrvMedicalHistory,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changeMedicalHistory(result);
        }
    });
  }

  selectExercise() {
    for (var j = 0; j < this.exerciseMasterList.length; j++) {
      this.exerciseMasterList[j].checked = false;
    }
    if (this.retrvExerciseList != undefined && this.retrvExerciseList.length != 0) {
      for (var i = 0; i < this.retrvExerciseList.length; i++) {
        for (var j = 0; j < this.exerciseMasterList.length; j++) {
          if (this.retrvExerciseList[i] == this.exerciseMasterList[j].value) {
            this.exerciseMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Exercises",
        main_array: this.exerciseMasterList,
        selected_list:this.retrvExerciseList,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changeExercise(result);
        }
    });
  }

  changeMedicalHistory(selected) {
    this.medhist = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.retrvMedicalHistory = selected;
    }
    if (this.retrvMedicalHistory.length != 0) {
      var n = 0;
      for (var j = 0; j < this.retrvMedicalHistory.length; j++) {
        for (var i = 0; i < this.medicalHistoryMasterList.length; i++) {
          if (this.retrvMedicalHistory[j] == this.medicalHistoryMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.medicalHistoryMasterList[i].label);
          }
        }
      }
      this.medhist = selected_data.toString();
    }
  }

  changeReligiousBelief(selected) {
    this.religiousBelief = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.retrvReligiousBelief = selected;
    if (this.retrvReligiousBelief.length != 0) {
      var n = 0;
      for (var j = 0; j < this.retrvReligiousBelief.length; j++) {
        for (var i = 0; i < this.religiousBeliefMasterList.length; i++) {
          if (this.retrvReligiousBelief[j] == this.religiousBeliefMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.religiousBeliefMasterList[i].label);
          }
        }
      }
      this.religiousBelief = selected_data.toString();
    }
  }

  getPrescriptions() {
    this.getSecOpnData = Client_Helper.getopinionchecked();
    this.sendData = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      relation: this.getSecOpnData.relationship_name,
      home_care: "0"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.presUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          if (data.json().prescriptions != null && data.json().prescriptions.length != 0) {
            this.prescriptionList = [];
            for (var pres_int = 0; pres_int < data.json().prescriptions.length; pres_int++) {
              var pres = data.json().prescriptions[pres_int];
              var doc_name = "";
              if (pres.middle_name != null) {
                doc_name = pres.first_name + " " + pres.middle_name + " " + pres.last_name;
              } else {
                doc_name = pres.first_name + " " + pres.last_name;
              }
              var clnt_name = "";
              if (pres.client_middle_name != null) {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_middle_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              } else {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              }
              var pres_date = "";
              if (pres.date != null) {
                pres_date = " on " + pres.date.split('-')[2] + "-" + pres.date.split('-')[1] + "-" + pres.date.split('-')[0];
              }
              this.prescriptionList.push({
                data: "Dr." + doc_name + " prescribed for " + clnt_name + pres_date,
                id: pres.pres_drug_id
              });
            }
          }
        }
      }, error => {
      });
  }

  getDocuments() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.docUrl, JSON.stringify(this.sendDocumentData), { headers: headers }).subscribe(
      data => {
        if (data.json().documents != null && data.json().documents.length != 0) {
          this.documentList = [];
          for (var d = 0; d < data.json().documents.length; d++) {
            var doc = data.json().documents[d];
            var doc_date = "";
            if (doc.document_date != null) {
              doc_date = doc.document_date.split('-')[2] + "-" + doc.document_date.split('-')[1] + "-" + doc.document_date.split('-')[0];
            }
            this.documentList.push({
              data: doc.document_name + " created : " + doc_date,
              id: doc.client_doc_id,
              name: doc.document_name
            });
          }
        }
      }, error => {
      });
  }

  changePrescriptions(pres_id, e) {
    var chk = e;
    var check_value = true;
    if (this.presIdsList.length != 0) {
      for (var h = 0; h < this.presIdsList.length; h++) {
        if (this.presIdsList[h] == pres_id) {
          check_value = false;
          break;
        }
      }
    }
    if (this.retrvPrescriptionList.length != 0) {
      for (var h = 0; h < this.retrvPrescriptionList.length; h++) {
        if (this.retrvPrescriptionList[h].test_id == pres_id) {
          check_value = false;
          break;
        }
      }
    }
    if (check_value == true) {
      this.presIdsList.push(pres_id);
    } else {
      for (var i = 0; i < this.presIdsList.length; i++) {
        if (this.presIdsList[i] == pres_id) {
          this.presIdsList.splice(this.presIdsList.indexOf(i), 1);
          break;
        }
      }
    }
  }

  changeDocument(doc_id, docname) {
    var check_value = true;
    for (var h = 0; h < this.docIdsList.length; h++) {
      if (this.docIdsList[h] == doc_id) {
        check_value = false;
      } else {
        check_value = true;
      }
    }
    if (check_value == true) {
      this.docIdsList.push(doc_id + "#" + docname);
    } else {
      for (var i = 0; i < this.docIdsList.length; i++) {
        if (this.docIdsList[i] == doc_id + "#" + docname) {
          this.docIdsList.splice(this.docIdsList.indexOf(i), 1);
          break;
        }
      }
    }
  }

  viewMedPres(test_id) {
    this.appointmentClientData = this.gservice.get_appoint_client_data();
    var viewmedicine = {
      AppFlow: 'presdetails1',
      "pres_test_id": test_id,
    }
  }

  viewDocument(doc_id, name) {
    this.viewReportImagepdfDetail(doc_id + "#" + name);
  }

  getBloodReports(get_data) {
    get_data.type = "Blood";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.bloodReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {
        });
  }

  getUrineReports(get_data) {
    get_data.type = "Urine";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.urineReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {
        });
  }

  getFeacesReports(get_data) {
    get_data.type = "faeces";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.feacesReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {
        });
  }

  getScanReports(get_data) {
    get_data.type = "scans";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.scanReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {
        });
  }

  getXrayReports(get_data) {
    get_data.type = "x-ray";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.xrayReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {
        });
  }

  getUltrasoundReports(get_data) {
    get_data.type = "Ultra-Sound";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist", JSON.stringify(get_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().reportlist != null && data.json().reportlist.length != 0) {
              for (var i = 0; i < data.json().reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (data.json().reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                }
                if (data.json().middle_name != null) {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                } else {
                  doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                }
                this.ultrasoundReportList.push({
                  Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                  Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                  Centre_Name: data.json().reportlist[i].diag_centre_name,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name,
                  TestId: "Test_" + i
                });
              }
            }
          }
        },
        error => {
        });
  }

  addDocumentImage() {
    if( this.imageFileName != "" && this.imageFileName != undefined ){
      this.sendImageData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        docname: this.imageFileName,
        document: this.base64data.split(',')[1],
        country: "IN",
        type:"pdf"
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "secopn/uplfileweb/",
        JSON.stringify(this.sendImageData), { headers: headers }).subscribe(
          data => {
            if (data.json().key == "1" && data.json().file_path != null) {
              this.Image_table_list.push({
                image_path: data.json().file_path,
                image_name: this.imageFileName
              });
              this.imageFileStr = "assets/img/default.jpg";
              this.imageFileName = undefined;
              $("#file").val('');
            }
          },
          error => {
          })
    } else {
      this.toastr.error("please select file to upload");
    }
    
  }

  deleteDocumentImage(index_val) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "secopn/delfl/",JSON.stringify({ filename: this.Image_table_list[index_val].image_path }), { headers: headers }).subscribe(
        data => {
          if (data.json().result != null && data.json().key == "1") {
            this.Image_table_list.splice(index_val, 1);
          } else {
            this.toastr.error(Message_data.unableToDelDocument);
          }
        },
        error => {
        })
  }

  viewReportImagepdfDetail(document) {
    if (document.indexOf("#") >= 0) {
      var report_data = document.split('#');
      var get_file_type = report_data[1].split('.');
      var send_data;
      send_data = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        document_name: report_data[1],
      }
      if(report_data[0] != undefined && report_data[0] != null && report_data[0] != "")
        send_data.client_doc_id = report_data[0];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/durl/", JSON.stringify(send_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              var report_detail = ipaddress.Ip_with_img_address + data.json().path;
              if (get_file_type[1] != undefined && get_file_type[1] == "pdf") {
                if (this.header_footer_flag == true) {
                  const dialogRef = this.dialog.open(DocumentDetailsZoomPageComponent, {
                    width: '40%',
                    height: '300px',
                    data: {
                      report_pdf: report_detail,
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                  });
                }
                else {
                  const dialogRef = this.dialog.open(DocumentDetailsZoomPageComponent, {
                    width: '40%',
                    height: '300px',
                    data: {
                      report_pdf: report_detail,
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                  });
                }
              }
            }
          },
          error => {
          });
    } else {
      const dialogRef = this.dialog.open(DocumentDetailsZoomPageComponent, {
        width: '40%',
        height: '300px',
        data: {
          report_image: document
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  setRetrivalDocumentData() {
    if (this.gservice.get_second_documet_data() != null) {
      this.saveUpdateFlag = true;
      this.retrvDocumentList = this.gservice.get_second_documet_data();
      if (this.retrvDocumentList != null) {
        if (this.retrvDocumentList.upload_documentimg_list != null) {
          this.retrvImageList = this.retrvDocumentList.upload_documentimg_list;
          for (var i = 0; i < this.retrvImageList.length; i++) {
            this.Image_table_list.push({
              image_path: this.retrvImageList[i].documentFile,
              image_name: this.retrvImageList[i].document,
            })
          }
        }
        if (this.retrvDocumentList.Blood_Report_Data != null) {
          this.retrvBloodReportList = this.retrvDocumentList.Blood_Report_Data;
        }
        if (this.retrvDocumentList.Urine_Report_Data != null) {
          this.retrvUrineReportList = this.retrvDocumentList.Urine_Report_Data;
        }
        if (this.retrvDocumentList.Faeces_Report_Data != null) {
          this.retrvFeacesReportList = this.retrvDocumentList.Faeces_Report_Data;
        }
        if (this.retrvDocumentList.Xray_Report_Data != null) {
          this.retrvXrayReportList = this.retrvDocumentList.Xray_Report_Data;
        }
        if (this.retrvDocumentList.Scan_Report_Data != null) {
          this.retrvScanReportList = this.retrvDocumentList.Scan_Report_Data;
        }
        if (this.retrvDocumentList.UltraSound_Report_Data != null) {
          this.retrvUltrasoundReportList = this.retrvDocumentList.UltraSound_Report_Data;
        }
        if (this.retrvDocumentList.Prescription_List_Data != null) {
          this.retrvPrescriptionList = this.retrvDocumentList.Prescription_List_Data;
        }
      }
    }
  }

  viewReport(test_id, type, app_id,) {
  }

  changeBlood(check, test_id) {
    if (check.checked == true) {
      this.sendBloodSelectList.push(test_id);
      for (var i = 0; i < this.retrvBloodReportList.length; i++) {
        if (this.retrvBloodReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendBloodSelectList.splice(this.sendBloodSelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendBloodSelectList.splice(this.sendBloodSelectList.indexOf(test_id), 1);
    }
  }

  changeUrine(check, test_id) {
    if (check.checked == true) {
      this.sendUrineSelectList.push(test_id);
      for (var i = 0; i < this.retrvUrineReportList.length; i++) {
        if (this.retrvUrineReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendUrineSelectList.splice(this.sendUrineSelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendUrineSelectList.splice(this.sendUrineSelectList.indexOf(test_id), 1);
    }
  }

  changeFeaces(check, test_id) {
    if (check.checked == true) {
      this.sendFeacesSelectList.push(test_id);
      for (var i = 0; i < this.retrvFeacesReportList.length; i++) {
        if (this.retrvFeacesReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendFeacesSelectList.splice(this.sendFeacesSelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendFeacesSelectList.splice(this.sendFeacesSelectList.indexOf(test_id), 1);
    }
  }

  changeXray(check, test_id) {
    if (check.checked == true) {
      this.sendXraySelectList.push(test_id);
      for (var i = 0; i < this.retrvXrayReportList.length; i++) {
        if (this.retrvXrayReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendXraySelectList.splice(this.sendXraySelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendXraySelectList.splice(this.sendXraySelectList.indexOf(test_id), 1);
    }
  }

  changeScan(check, test_id) {
    if (check.checked == true) {
      this.sendScanSelectList.push(test_id);
      for (var i = 0; i < this.retrvScanReportList.length; i++) {
        if (this.retrvScanReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendScanSelectList.splice(this.sendScanSelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendScanSelectList.splice(this.sendScanSelectList.indexOf(test_id), 1);
    }
  }

  changeUltraSound(check, test_id) {
    if (check.checked == true) {
      this.sendUltraSelectList.push(test_id);
      for (var i = 0; i < this.retrvUltrasoundReportList.length; i++) {
        if (this.retrvUltrasoundReportList[i].test_id == test_id) {
          this.toastr.error(Message_data.existTest);
          this.sendUltraSelectList.splice(this.sendUltraSelectList.indexOf(test_id), 1);
        }
      }
    } else {
      this.sendUltraSelectList.splice(this.sendUltraSelectList.indexOf(test_id), 1);
    }
  }

  update() {
    var Valid_flag = true;
    var ImgLength = this.Image_table_list.length + this.docIdsList.length + this.sendBloodSelectList.length +
      this.sendUrineSelectList.length + this.sendFeacesSelectList.length + this.sendXraySelectList.length +
      this.sendScanSelectList.length + this.sendUltraSelectList.length + this.presIdsList;
    ImgLength = this.documentList.length + this.bloodReportList.length + this.urineReportList.length +
      +this.feacesReportList.length + this.xrayReportList.length + this.scanReportList.length + this.ultrasoundReportList.length +
      this.prescriptionList;
    if (ImgLength >= 5) {
      this.toastr.error(Message_data.maxFiveReportsCanAttach);
    } else {
      var Image_document_1 = undefined;
      var Image_document_2 = undefined;
      var Image_document_3 = undefined;
      var Image_document_4 = undefined;
      var Image_document_5 = undefined;
      if (this.Image_table_list.length != 0) {
        for (var i = 0; i < this.Image_table_list.length; i++) {
          if (i == 0) {
            Image_document_1 = this.Image_table_list[i].image_path;
          } else if (i == 1) {
            Image_document_2 = this.Image_table_list[i].image_path;
          } else if (i == 2) {
            Image_document_3 = this.Image_table_list[i].image_path;
          } else if (i == 3) {
            Image_document_4 = this.Image_table_list[i].image_path;
          } else if (i == 4) {
            Image_document_5 = this.Image_table_list[i].image_path;
          }
        }
      }
      if (this.docIdsList.length != 0) {
        for (var i = 0; i < this.docIdsList.length; i++) {
          if (Image_document_1 == undefined) {
            Image_document_1 = this.docIdsList[i];
          } else if (Image_document_2 == undefined) {
            Image_document_2 = this.docIdsList[i];
          } else if (Image_document_3 == undefined) {
            Image_document_3 = this.docIdsList[i];
          } else if (Image_document_4 == undefined) {
            Image_document_4 = this.docIdsList[i];
          } else if (Image_document_5 == undefined) {
            Image_document_5 = this.docIdsList[i];
          }
        }
      }
      var bloodList = undefined, UrineList = undefined, FaecesList = undefined, XrayTest = undefined, ScanTest = undefined, ULtraTest = undefined;
      if (this.sendBloodSelectList.length != 0)
        bloodList = this.sendBloodSelectList;
      if (this.sendUrineSelectList.length != 0)
        UrineList = this.sendUrineSelectList;
      if (this.sendFeacesSelectList.length != 0)
        FaecesList = this.sendFeacesSelectList;
      if (this.sendXraySelectList.length != 0)
        XrayTest = this.sendXraySelectList;
      if (this.sendScanSelectList.length != 0)
        ScanTest = this.sendScanSelectList;
      if (this.sendUltraSelectList.length != 0)
        ULtraTest = this.sendUltraSelectList;
      this.saveSecOpnFinalData = {
        doc1: Image_document_1,
        doc2: Image_document_2,
        doc3: Image_document_3,
        doc4: Image_document_4,
        doc5: Image_document_5,
        blood_test: bloodList,
        urine_test: UrineList,
        faeces_test: FaecesList,
        xray_test: XrayTest,
        scan_test: ScanTest,
        ultrasound_test: ULtraTest,
        pres_drug: this.presIdsList,
        opinion_id:  Client_Helper.getopinionchecked().opinion_id,
        country: ipaddress.country_code,
      };
      this.saveSecOpinion("secopn/usecopn");
    }
  }

  uploadNewReports(d) {
    this.ynoption = !this.ynoption;
    if (this.ynoption == true) {
      switch (d) {
        case 13: {
          this.ynopt13 = true;
          this.newUpload = "yes";
          this.addDocumentFlag = true;
          break;
        }
      }
    }
    if (this.ynoption == false) {
      switch (d) {
        case 13: {
          this.ynopt13 = false;
          this.newUpload = "No";
          this.addDocumentFlag = false;
        }
      }
    }
  }

  save() {
    var ImgLength = this.Image_table_list.length + this.docIdsList.length + this.sendBloodSelectList.length +
      this.sendUrineSelectList.length + this.sendFeacesSelectList.length + this.sendXraySelectList.length +
      this.sendScanSelectList.length + this.sendUltraSelectList.length + this.presIdsList.length;
    if (this.agreeBox == undefined || this.agreeBox == false) {
      this.toastr.error(Message_data.confirmAboveDet);
    } else if (ImgLength > 5) {
      this.toastr.error(Message_data.maxFiveReportsCanAttach);
    } else {
      this.appointmentClientData = this.gservice.get_appoint_client_data();
      
      var clnt_send_spl_id;
      if (this.appointmentClientData.speciality != null) {
        clnt_send_spl_id = this.appointmentClientData.speciality.specialization_id;
      }
      if (CommonDataService.OpnSplId != undefined && CommonDataService.OpnSplId != null) {
        clnt_send_spl_id = CommonDataService.OpnSplId;
      }
      if (this.appointmentClientData.clnt_age != null) {
        var client_app_age = this.appointmentClientData.clnt_age;
      }
      if (this.appointmentClientData.clnt_blood_id != null) {
        var clnt_blood_id = this.appointmentClientData.clnt_blood_id;
      }
      if (this.appointmentClientData.clnt_symptom != null) {
        var clnt_symtom_data = encrypt_decript.Encript(this.appointmentClientData.clnt_symptom).toString();
      }
      if (this.appointmentClientData.clnt_fg_name != null) {
        var clnt_fatgad_name = encrypt_decript.Encript(this.appointmentClientData.clnt_fg_name).toString();
      }
      if (this.appointmentClientData.clnt_occupation != null) {
        var clnt_fgoccup_data = encrypt_decript.Encript(this.appointmentClientData.clnt_occupation).toString();
      }
      if (this.appointmentClientData.clnt_income != null) {
        var clnt_fgincome_data = encrypt_decript.Encript(this.appointmentClientData.clnt_income).toString();
      }
      if (this.appointmentClientData.clnt_mname != null) {
        var clnt_middle_name = encrypt_decript.Encript(this.appointmentClientData.clnt_mname).toString();
      }
      if (this.appointmentClientData.clnt_dob != null) {
        var client_send_dob = this.appointmentClientData.clnt_dob;
      }
      if (this.appointmentClientData.clnt_address2 != null) {
        var client_send_address2 = encrypt_decript.Encript(this.appointmentClientData.clnt_address2).toString();
      }
      if (this.appointmentClientData.sub_rel_id != null) {
        var client_sub_rel_id = this.appointmentClientData.sub_rel_id;
      }
      this.secOpnGeneralData = this.gservice.get_second_general_data();
      this.secOpnHealthmedicineData = this.gservice.get_second_health_data();
      var Image_document_1 = undefined;
      var Image_document_2 = undefined;
      var Image_document_3 = undefined;
      var Image_document_4 = undefined;
      var Image_document_5 = undefined;
      if (this.Image_table_list.length != 0) {
        for (var i = 0; i < this.Image_table_list.length; i++) {
          if (i == 0) {
            Image_document_1 = this.Image_table_list[i].image_path;
          } else if (i == 1) {
            Image_document_2 = this.Image_table_list[i].image_path;
          } else if (i == 2) {
            Image_document_3 = this.Image_table_list[i].image_path;
          } else if (i == 3) {
            Image_document_4 = this.Image_table_list[i].image_path;
          } else if (i == 4) {
            Image_document_5 = this.Image_table_list[i].image_path;
          }
        }
      }
      if (this.docIdsList.length != 0) {
        for (var i = 0; i < this.docIdsList.length; i++) {
          if (Image_document_1 == undefined) {
            Image_document_1 = this.docIdsList[i];
          } else if (Image_document_2 == undefined) {
            Image_document_2 = this.docIdsList[i];
          } else if (Image_document_3 == undefined) {
            Image_document_3 = this.docIdsList[i];
          } else if (Image_document_4 == undefined) {
            Image_document_4 = this.docIdsList[i];
          } else if (Image_document_5 == undefined) {
            Image_document_5 = this.docIdsList[i];
          }
        }
      }
      var bloodList = undefined, UrineList = undefined, FaecesList = undefined, XrayTest = undefined, ScanTest = undefined, ULtraTest = undefined;
      if (this.sendBloodSelectList.length != 0)
        bloodList = this.sendBloodSelectList;
      if (this.sendUrineSelectList.length != 0)
        UrineList = this.sendUrineSelectList;
      if (this.sendFeacesSelectList.length != 0)
        FaecesList = this.sendFeacesSelectList;
      if (this.sendXraySelectList.length != 0)
        XrayTest = this.sendXraySelectList;
      if (this.sendScanSelectList.length != 0)
        ScanTest = this.sendScanSelectList;
      if (this.sendUltraSelectList.length != 0)
        ULtraTest = this.sendUltraSelectList;
      this.secOpnDoctorList = this.gservice.get_second_doctor_data();
      this.saveSecOpnFinalData = {
        country: ipaddress.country_code,
        docarray: this.secOpnDoctorList,
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        sub_rel_id: client_sub_rel_id,
        relation_id: this.getSecOpnData.relation_id,
        mem_first_name: encrypt_decript.Encript(this.appointmentClientData.clnt_fname).toString(),
        mem_middle_name: clnt_middle_name,
        mem_last_name: encrypt_decript.Encript(this.appointmentClientData.clnt_lname).toString(),
        age: client_app_age,
        blood_group: clnt_blood_id,
        gender: encrypt_decript.Encript(this.appointmentClientData.clnt_gender).toString(),
        location: encrypt_decript.Encript(this.appointmentClientData.clnt_location_desc).toString(),
        city: encrypt_decript.Encript(this.appointmentClientData.clnt_city_desc).toString(),
        mem_mobile: encrypt_decript.Encript(this.appointmentClientData.clnt_cont_number).toString(),
        guardian: clnt_fatgad_name,
        income: clnt_fgincome_data,
        occupation: clnt_fgoccup_data,
        medicare_id: this.appointmentClientData.treatement_type != undefined ? this.appointmentClientData.treatement_type.medicare_id.toString() :  undefined,
        specialization_id: clnt_send_spl_id,
        opinion_type: this.secOpnGeneralData.opinion_type,
        marital_status: this.secOpnGeneralData.marital_status,
        other_issues: this.secOpnGeneralData.other_issues,
        height: this.secOpnGeneralData.height,
        height_measure: this.secOpnGeneralData.height_measure,
        weight: this.secOpnGeneralData.weight,
        weight_measure: this.secOpnGeneralData.weight_measure,
        bmi: this.secOpnGeneralData.bmi,
        blood_pressure: this.secOpnGeneralData.blood_pressure,
        stress_factor: this.secOpnGeneralData.stress_factor,
        diet_habits: this.secOpnGeneralData.diet_habits,
        opinion_concerns: this.secOpnGeneralData.opinion_concerns,
        medical_history: this.secOpnGeneralData.medical_history,
        religious_belief: this.secOpnGeneralData.religious_belief,
        alcohol: this.secOpnGeneralData.alcohol,
        quantity: this.secOpnGeneralData.quantity,
        mixing_type: this.secOpnGeneralData.mixing_type,
        alc_duration: this.secOpnGeneralData.alc_duration,
        alc_frequency: this.secOpnGeneralData.alc_frequency,
        smoke: this.secOpnGeneralData.smoke,
        smoke_duration: this.secOpnGeneralData.smoke_duration,
        smoke_frequency: this.secOpnGeneralData.smoke_frequency,
        gutka: this.secOpnGeneralData.gutka,
        gutka_duration: this.secOpnGeneralData.gutka_duration,
        gutka_frequency: this.secOpnGeneralData.gutka_frequency,
        exercises: this.secOpnGeneralData.exercises,
        exer_day_dur: this.secOpnGeneralData.exc_dur,
        exer_routine: this.secOpnGeneralData.exc_rout,
        recent_weight_gain: this.secOpnHealthmedicineData.recent_weight_gain,
        recent_weight_loss: this.secOpnHealthmedicineData.recent_weight_loss,
        tiredness_frequency: this.secOpnHealthmedicineData.tiredness_frequency,
        recent_fever_chills: this.secOpnHealthmedicineData.recent_fever_chills,
        prescribed_medicine: this.secOpnHealthmedicineData.prescribed_medicine,
        pres_med_doc: this.secOpnHealthmedicineData.pres_med_doc, 
        "non-otc": this.secOpnHealthmedicineData["non-otc"],
        vitamins: this.secOpnHealthmedicineData.vitamins,
        med_reaction: this.secOpnHealthmedicineData.med_reaction,
        allergies: this.secOpnHealthmedicineData.allergies,
        fam_hist: this.gservice.get_second_family_data(),
        doc1: Image_document_1,
        doc2: Image_document_2,
        doc3: Image_document_3,
        doc4: Image_document_4,
        doc5: Image_document_5,
        blood_test: bloodList,
        urine_test: UrineList,
        faeces_test: FaecesList,
        xray_test: XrayTest,
        scan_test: ScanTest,
        ultrasound_test: ULtraTest,
        pres_drug: this.presIdsList,
      }
      this.saveSecOpinion("secopn/savesecopn/");
    }
  }

  storeGeneralData() {
    this.setGeneralDataList = {};
    this.setGeneralDataList.opinion_type = this.opinionType;
    if (this.maritalStatus != undefined) {
      this.setGeneralDataList.marital_status = this.maritalStatus;
    }
    if (this.anyOtherIssue != undefined) {
      this.setGeneralDataList.other_issues = this.anyOtherIssue;
    }
    if (this.height != undefined) {
      this.setGeneralDataList.height = this.height;
    }
    if (this.heightMeasure != undefined) {
      this.setGeneralDataList.height_measure = this.heightMeasure;
    }
    if (this.weight != undefined) {
      this.setGeneralDataList.weight = this.weight;
    }
    if (this.weightMeasure != undefined) {
      this.setGeneralDataList.weight_measure = this.weightMeasure;
    }
    if (this.bmi != undefined) {
      this.setGeneralDataList.bmi = this.bmi;
    }
    if (this.bloodPressure != undefined) {
      this.setGeneralDataList.blood_pressure = this.bloodPressure;
    }
    if (this.stressFactor != undefined) {
      this.setGeneralDataList.stress_factor = this.stressFactor;
    }
    if (this.dietaryHabits != undefined) {
      this.setGeneralDataList.diet_habits = this.dietaryHabits;
    }
    if (this.sendMainConcernList.length != 0) {
      this.setGeneralDataList.opinion_concerns = this.sendMainConcernList;
    }
    if (this.sendMedicalHistoryList.length != 0) {
      this.setGeneralDataList.medical_history = this.sendMedicalHistoryList;
    }
    if (this.sendReligiousBeliefList.length != 0) {
      this.setGeneralDataList.religious_belief = this.sendReligiousBeliefList;
    }
    if (this.toggAlchol == true) 
    {
      this.setGeneralDataList.alcohol = "Yes";
      this.setGeneralDataList.quantity = this.alcoholQuant;
      this.setGeneralDataList.mixing_type = this.alcoholMix;
      this.setGeneralDataList.alc_duration = this.alcholDuration;
      this.setGeneralDataList.alc_frequency = this.alcoholFrequency;
    }
    else {
      this.setGeneralDataList.alcohol = "No";
    }
    if (this.toggSmoking == true) 
    {
      this.setGeneralDataList.smoke = "Yes";
      this.setGeneralDataList.smoke_duration = this.smokeDuration;
      this.setGeneralDataList.smoke_frequency = this.smokeFrequency;
    }
    else {
      this.setGeneralDataList.smoke = "No";
    }
    if (this.toggGutka == false) 
    {
      this.setGeneralDataList.gutka = "Yes";
      this.setGeneralDataList.gutka_duration = this.gutkaDuration;
      this.setGeneralDataList.gutka_frequency = this.gutkaFrequency;
    }
    else {
      this.setGeneralDataList.gutka = "No";
    }
    if (this.toggExercise == true) 
    {
      if (this.retrvExerciseList.length != 0) {
        this.setGeneralDataList.exercises = this.retrvExerciseList;
      }
      this.setGeneralDataList.exc_dur = this.exerciseDuration;
      if (this.exerciseRoutineList != undefined) {
        for (var z = 0; z < this.exerciseRoutineList.length; z++) {
          if (this.exerciseRoutineList[z].exer_rout_id == this.exerRoutine) {
            this.setGeneralDataList.exc_rout = this.exerciseRoutineList[z].exer_rout_id;
            break;
          }
        }
      }
    }
    this.gservice.set_second_general_data(this.setGeneralDataList); 
  }

  saveSecOpinion(url) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + url,
      JSON.stringify(this.saveSecOpnFinalData), { headers: headers }).subscribe(
        data => {
          if (data.json().key != "0") {
            this.toastr.success("data saved successfully");
            this.clientview.sendMessage('secondopinionlist')
          } else {
            this.toastr.error(data.json().result)
          }
        },error => {
        })
  }

  calculateCalories() {
    if (this.height != undefined) {
      if (this.heightMeasure == "cms") {
        this.height = this.height.replace(/[^0-9]/g, '');
      }
      else {
        this.height = this.height.replace('/[^-0-9\.]/g', '');
        if (this.height.indexOf('.') !== -1) {
          if (this.height.split('.').length > 2) {
            this.height = this.height.height.slice(0, -1);
          }
        }
      }
      if (this.heightMeasure == "cms") {
        if (parseFloat(this.height) > 300) {
          this.height = this.height.slice(0, -1);
        }
      }
      else {
        if (parseFloat(this.height) > 100) {
          this.height = this.height.slice(0, -1);
        }
      }
    }
    if (this.weightMeasure != undefined && this.heightMeasure != undefined && this.height != undefined &&
      this.weight != undefined) {
      if (this.weightMeasure == "kgs" && this.heightMeasure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
      else if (this.weightMeasure == "kgs" && this.heightMeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
      }
      else if (this.weightMeasure == "lbs" && this.heightMeasure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
      else if (this.weightMeasure == "lbs" && this.heightMeasure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
    }
  }

  getColor(v) {
    if (v == "Yes") {
      return "green";
    }
    if (v = "No") {
      return "red";
    }
  }

  getRetrievData() {
    this.wgtGainFlag = false;
    if (this.gservice.get_second_health_data() != null) {
      this.presMedFlag = false;
      this.exits = true;
      this.getHealthData = this.gservice.get_second_health_data();
      if (this.getHealthData.disabled != null) {
        this.fieldDisable = true;
      }
      if (this.getHealthData.recent_weight_gain == "false") {
        this.wgtGainFlag = false;
        document.getElementById("hm1").style.background = "../../../assests/ui_icons/cross_mark.svg";
      }
      else {
        this.wgtGainFlag = true;
        document.getElementById("hm1").style.background = "../../../assests/ui_icons/tick_mark.svg";
      }
      if (this.getHealthData.recent_weight_loss == "false") {
        this.wgtLossFlag = false;
        document.getElementById("hm2").style.backgroundColor = "red";
      }
      else {
        this.wgtLossFlag = true;
        document.getElementById("hm2").style.backgroundColor = "green";
      }
      if (this.getHealthData.tiredness_frequency == "false") {
        this.freqFlag = false;
        document.getElementById("hm3").style.backgroundColor = "red";
      }
      else {
        this.freqFlag = true;
        document.getElementById("hm3").style.backgroundColor = "green";
      }
      if (this.getHealthData.recent_fever_chills == "false") {
        this.recentFlag = false;
        document.getElementById("hm4").style.backgroundColor = "red";
      }
      else {
        this.recentFlag = true;
        document.getElementById("hm4").style.backgroundColor = "green";
      }
      if (this.getHealthData.prescribed_medicine == "true") {
        this.anyPresMedFlag = true;
        document.getElementById("hm5").style.backgroundColor = "green";
      } else {
        this.anyPresMedFlag = false;
        document.getElementById("hm5").style.backgroundColor = "red";
      }
      if (this.getHealthData.pres_med_doc != null) {
        this.RetPresImg = this.getHealthData.pres_med_doc;
      }
      if (this.getHealthData.allergies != null) {
        this.allergyFlag = true;
        this.allergy = true;
        this.allergyData = this.getHealthData.allergies
        document.getElementById("hm9").style.backgroundColor = "green";
      } else {
        this.allergyFlag = false;
        document.getElementById("hm9").style.backgroundColor = "red";
      }
      if (this.getHealthData.otc_medicines != null) {
        this.otcMedFlag = true;
        this.otcMed = true;
        this.otcNonpresData = this.getHealthData.otc_medicines
        document.getElementById("hm6").style.backgroundColor = "green";
      } else {
        this.otcMedFlag = false;
        document.getElementById("hm6").style.backgroundColor = "red";
      }
      if (this.getHealthData.med_reaction != null) {
        this.reactFlag = true;
        this.react = true;
        this.reactionMedsupplyData = this.getHealthData.med_reaction
        document.getElementById("hm8").style.backgroundColor = "green";
      } else {
        this.reactFlag = false;
        document.getElementById("hm8").style.backgroundColor = "red";
      }
      if (this.getHealthData.vitamins != null) {
        this.vitaminsFlag = true;
        this.vitamins = true;
        this.vitaminHerbalData = this.getHealthData.vitamins
        document.getElementById("hm7").style.backgroundColor = "green";
      } else {
        this.vitaminsFlag = false;
        document.getElementById("hm7").style.backgroundColor = "red";
      }
    }
  }

  navigateOtcHerbal(type_text) {
    if (type_text == "OTC") {
      const dialogRef = this.dialog.open(OtcNonpresPageComponent, {
        width: '50%',
        height: '500px',
        data: {
          type: type_text,
          retrival: true,
          flag: this.header_footer_flag,
          table_data: this.otcNonpresData
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data != undefined) {
          if (data.length != 0) {
            if (data[0].type == "OTC") 
            {
              this.otcNonpresData = [];
              for (var p = 0; p < data.length; p++) {
                var intake_qty_data = data[p].intake_qty.split('-');
                this.otcNonpresData.push({
                  medicine_name: data[p].med_name,
                  dosage: data[p].dosage,
                  intake: data[p].session.drug_id,
                  morning: intake_qty_data[0],
                  afternoon: intake_qty_data[1],
                  evening: intake_qty_data[2],
                  side_effects: data[p].side_effect,
                });
              }
            } else {
              this.vitaminHerbalData = [];
              for (var p = 0; p < data.length; p++) {
                var intake_qty_data = data[p].intake_qty.split('-');
                this.vitaminHerbalData.push({
                  supplement: data[p].med_name,
                  dosage: data[p].dosage,
                  intake: data[p].session.drug_id,
                  morning: intake_qty_data[0],
                  afternoon: intake_qty_data[1],
                  evening: intake_qty_data[2],
                  side_effects: data[p].side_effect,
                });
              }
            }
          }
        }
      });
    }
    else {
      if (type_text != null) {
        const dialogRef = this.dialog.open(OtcNonpresPageComponent, {
          width: '50%',
          height: '500px',
          data: {
            type: type_text,
            retrival: false,
            flag: this.header_footer_flag,
            table_data: this.otcNonpresData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != undefined) {
            if (data.length != 0) {
              if (data[0].type == "OTC") 
              {
                this.otcNonpresData = [];
                for (var p = 0; p < data.length; p++) {
                  var intake_qty_data = data[p].intake_qty.split('-');
                  this.otcNonpresData.push({
                    medicine_name: data[p].med_name,
                    dosage: data[p].dosage,
                    intake: data[p].session.drug_id,
                    morning: intake_qty_data[0],
                    afternoon: intake_qty_data[1],
                    evening: intake_qty_data[2],
                    side_effects: data[p].side_effect,
                  });
                }
              } else {
                this.vitaminHerbalData = [];
                for (var p = 0; p < data.length; p++) {
                  var intake_qty_data = data[p].intake_qty.split('-');
                  this.vitaminHerbalData.push({
                    supplement: data[p].med_name,
                    dosage: data[p].dosage,
                    intake: data[p].session.drug_id,
                    morning: intake_qty_data[0],
                    afternoon: intake_qty_data[1],
                    evening: intake_qty_data[2],
                    side_effects: data[p].side_effect,
                  });
                }
              }
            }
          }
        });
      }
      else {
        const dialogRef = this.dialog.open(OtcNonpresPageComponent, {
          width: '50%',
          height: '500px',
          data: {
            type: type_text,
            retrival: false,
            flag: this.header_footer_flag,
            table_data: this.vitaminHerbalData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != undefined) {
            if (data.length != 0) {
              if (data[0].type == "OTC") 
              {
                this.otcNonpresData = [];
                for (var p = 0; p < data.length; p++) {
                  var intake_qty_data = data[p].intake_qty.split('-');
                  this.otcNonpresData.push({
                    medicine_name: data[p].med_name,
                    dosage: data[p].dosage,
                    intake: data[p].session.drug_id,
                    morning: intake_qty_data[0],
                    afternoon: intake_qty_data[1],
                    evening: intake_qty_data[2],
                    side_effects: data[p].side_effect,
                  });
                }
              } else {
                this.vitaminHerbalData = [];
                for (var p = 0; p < data.length; p++) {
                  var intake_qty_data = data[p].intake_qty.split('-');
                  this.vitaminHerbalData.push({
                    supplement: data[p].med_name,
                    dosage: data[p].dosage,
                    intake: data[p].session.drug_id,
                    morning: intake_qty_data[0],
                    afternoon: intake_qty_data[1],
                    evening: intake_qty_data[2],
                    side_effects: data[p].side_effect,
                  });
                }
              }
            }
          }
        });
      }
    }
  }

  navigateReactAllergy(type_text) {
    let modelPage;
    if (type_text == "reaction") {
      if (type_text != null) {
        const dialogRef = this.dialog.open(ReactPageComponent, {
          width: '40%',
          height: '200px',
          data: {
            type: type_text,
            retrival: true,
            flag: this.header_footer_flag,
            table_data: this.reactionMedsupplyData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != null) {
            if (data.length != 0) {
              if (data[0].type == "reaction") {
                this.reactionMedsupplyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.reactionMedsupplyData.push({
                    supplement_name: data[p].med_supply_name,
                    reaction: data[p].react_allrgy
                  });
                }
              } else {
                this.allergyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.allergyData.push({
                    medicine_name: data[p].med_supply_name,
                    allergies: data[p].react_allrgy
                  });
                }
              }
            }
          }
        });
      }
      else {
        const dialogRef = this.dialog.open(ReactPageComponent, {
          width: '40%',
          height: '200px',
          data: {
            type: type_text,
            retrival: false,
            flag: this.header_footer_flag,
            table_data: this.reactionMedsupplyData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != null) {
            if (data.length != 0) {
              if (data[0].type == "reaction") {
                this.reactionMedsupplyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.reactionMedsupplyData.push({
                    supplement_name: data[p].med_supply_name,
                    reaction: data[p].react_allrgy
                  });
                }
              } else {
                this.allergyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.allergyData.push({
                    medicine_name: data[p].med_supply_name,
                    allergies: data[p].react_allrgy
                  });
                }
              }
            }
          }
        });
      }
    }
    else {
      if (type_text != null) {
        const dialogRef = this.dialog.open(ReactPageComponent, {
          width: '40%',
          height: '200px',
          data: {
            type: type_text,
            retrival: true,
            flag: this.header_footer_flag,
            table_data: this.allergyData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != null) {
            if (data.length != 0) {
              if (data[0].type == "reaction") {
                this.reactionMedsupplyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.reactionMedsupplyData.push({
                    supplement_name: data[p].med_supply_name,
                    reaction: data[p].react_allrgy
                  });
                }
              } else {
                type_text = "allergy"
                this.allergyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.allergyData.push({
                    medicine_name: data[p].med_supply_name,
                    allergies: data[p].react_allrgy
                  });
                }
              }
            }
          }
        });
      }
      else {
        const dialogRef = this.dialog.open(ReactPageComponent, {
          width: '40%',
          height: '200px',
          data: {
            type: type_text,
            retrival: false,
            flag: this.header_footer_flag,
            table_data: this.allergyData
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != null) {
            if (data.length != 0) {
              if (data[0].type == "reaction") {
                this.reactionMedsupplyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.reactionMedsupplyData.push({
                    supplement_name: data[p].med_supply_name,
                    reaction: data[p].react_allrgy
                  });
                }
              } else {
                this.allergyData = [];
                for (var p = 0; p < data.length; p++) {
                  this.allergyData.push({
                    medicine_name: data[p].med_supply_name,
                    allergies: data[p].react_allrgy
                  });
                }
              }
            }
          }
        });
      }
    }
  }

  healthMedOpen() {
    if (this.otcMedFlag != false) {
      var otc_table_data = this.otcNonpresData;
    }
    if (this.vitaminsFlag != false) {
      var health_table_data = this.vitaminHerbalData;
    }
    if (this.reactFlag != false) {
      var medsupply_table_data = this.reactionMedsupplyData;
    }
    if (this.allergyFlag != false) {
      var allergy_table_data = this.allergyData;
    }
    var presImgPath = undefined;
    if (this.ImgFilePath != null) {
      presImgPath = this.ImgFilePath;
    }
    this.setHealthDataList = {
      recent_weight_gain: this.wgtGainFlag,
      recent_weight_loss: this.wgtLossFlag,
      tiredness_frequency: this.freqFlag,
      recent_fever_chills: this.recentFlag,
      prescribed_medicine: this.anyPresMedFlag,
      pres_med_doc: presImgPath, 
      "non-otc": otc_table_data,
      vitamins: health_table_data,
      med_reaction: medsupply_table_data,
      allergies: allergy_table_data,
    }
    this.gservice.set_second_health_data(this.setHealthDataList);
    this.getRetrievData();
  }

  fileChange(data) {
    this.ImgFilePath = null;
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP','pdf'];
    if (inputValue.length != 0) {
      var file: File = inputValue.files[0];
      var fileExtension = file.type.split("/").pop();
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        var fileSize = Math.round(inputValue.files[0].size / 1024);
        this.imageFileStr = myReader.result;
        this.imageFileName = inputValue.files[0].name.replace(" ", "");
        var ImgFlag = false;
        if (this.imageFileName.length != 0) {
          var FileName = this.imageFileName.split('.');
          for (var i = 0; i < ValidFileExtension.length; i++) {
            if (ValidFileExtension[i] == FileName[1]) {
              ImgFlag = true;
              break;
            }
          }
        }
        if(fileExtension == 'pdf'){
          this.pdf = file;
        }else{
          this.photos.push(this.imageFileStr)
        }
        if (ImgFlag == false) {
          this.toastr.error(Message_data.chkFileFormat);
          this.imageFileStr = null;
          $("#file").val('');
        } else {
          this.createPdf();
         
        }
      }
      if (inputValue.files[0] != undefined && inputValue.files[0].length != 0)
        myReader.readAsDataURL(inputValue.files[0]);
    }
  }

  createPdf(){
    if(this.photos.length !=0){
      let imgFinding = [];
      var fileExtension;
      for(var i=0;i<this.photos.length;i++){
          imgFinding.push({image: this.photos[i],fit: [540, 700]})
      }
          let docDefinition = {
           pageSize: 'A4',
           content: imgFinding
          };
          this.pdfObj = pdfMake.createPdf(docDefinition);
          this.pdfObj.getBuffer((buffer) => {
          var blob = new Blob([buffer], { 
            type: 'application/pdf' 
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = function(this:any) {
            var base64data = reader.result;    
            this.base64data = base64data;
            this.savePres(base64data) 
          }.bind(this);        
        });
    }else if(this.pdf != undefined){
      var reader = new FileReader();
      reader.readAsDataURL(this.pdf); 
      reader.onloadend = function(this:any) {
        var base64data = reader.result;    
        this.base64data = base64data;
        this.savePres(base64data) 
      }.bind(this);   
    }
    else {
      this.toastr.error(Message_data.addAtlstOneImg);
    }
  }

  savePres(pdffile) {
    if (this.imageFileStr != null) {
      var image_send_data = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        docname: this.imageFileName,
        document: pdffile.split(',')[1],
        country: "IN",
        type:"pdf"
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "secopn/uplfileweb/",
        JSON.stringify(image_send_data), { headers: headers }).subscribe(
          data => {
            if (data.json().key == "1" && data.json().file_path != null) {
              this.toastr.success(Message_data.fileAddSuccess);
              this.ImgFilePath = data.json().file_path;
            }
          },
          error => {
          })
    } else {
      this.toastr.error(Message_data.sltFile);
    }
  }

  getOpinionData() {
    this.http.get(ipaddress.getIp + "secopn/opntype").subscribe(
      data => {
        if (data.json().appointment_types != null) {
          this.opinionList = data.json().appointment_types;
          this.opinionType = this.opinionList[0].description;
        }
      },
      error => {
      }
    )
  }

  getMainConcern() {
    this.mainConcernMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + 'gen/mcorn',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.main_concerns.length != 0) {
            for (var i = 0; i < obj.main_concerns.length; i++) {
              this.mainConcernMasterList.push({
                type: 'checkbox',
                value: obj.main_concerns[i].main_concerns_id,
                label: obj.main_concerns[i].description,
                checked: false
              });
            }
          }
          if (this.gservice.second_opinion_gen_mainconcern != undefined && this.gservice.second_opinion_gen_mainconcern.length != 0) {
            this.changeMainConcern(this.gservice.second_opinion_gen_mainconcern)
          }
        },
        error => {
        }
      )
    this.getQuantity();
    this.getMixingtype();
    this.getExerciseRoutine();
    this.getExerciseData();
  }

  getMedicalHistory() {
    this.medicalHistoryMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + 'gen/pmedhis', { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        if (obj.med_hist.length != 0) {
          for (var i = 0; i < obj.med_hist.length; i++) {
            this.medicalHistoryMasterList.push({
              type: 'checkbox',
              value: obj.med_hist[i].med_hist_id,
              label: obj.med_hist[i].description,
              checked: false
            });
            if(obj.med_hist[i].med_hist_id == 32){
              this.pastMedical = obj.med_hist[i].description;
            }
          }
        }
        if (this.gservice.second_opinion_gen_medhist != undefined && this.gservice.second_opinion_gen_medhist.length != 0) {
          this.secOpnGeneralData = this.gservice.get_second_general_data();
          if (this.gservice.second_opinion_gen_medhist.length != 0) {
            this.changeMedicalHistory(this.gservice.second_opinion_gen_medhist)
          }
        }
      },
      error => {
      }
    )
  }

  getReligiousOtherBeliefs() {
    this.religiousBeliefMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + 'gen/relblf',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.religious_beliefs.length != 0) {
            for (var i = 0; i < obj.religious_beliefs.length; i++) {
              this.religiousBeliefMasterList.push({
                type: 'checkbox',
                value: obj.religious_beliefs[i].religious_belief_id,
                label: obj.religious_beliefs[i].description,
                checked: false
              });
            }
          }
          if (this.gservice.second_opinion_gen_religious_beliefs != undefined && this.gservice.second_opinion_gen_religious_beliefs.length != 0) {
            this.changeReligiousBelief(this.gservice.second_opinion_gen_religious_beliefs)
          }
        },
        error => {
        }
      )
  }

  getExerciseData() {
    this.exerciseMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.exercises.length != 0) {
            for (var i = 0; i < obj.exercises.length; i++) {
              this.exerciseMasterList.push({
                type: 'checkbox',
                value: obj.exercises[i].exercise_id,
                label: obj.exercises[i].description,
                checked: false
              });
            }
          }
          if (this.gservice.second_opinion_gen_excer != undefined && this.gservice.second_opinion_gen_excer.length != 0) {
            this.changeExercise(this.gservice.second_opinion_gen_excer)
          }
        },
        error => {
        }
      )
  }

  changeExercise(selectedValue) {
    this.exercise = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.retrvExerciseList = selectedValue;
    var n = 0;
    for (var j = 0; j < this.exerciseMasterList.length; j++) {
      for (var i = 0; i < this.retrvExerciseList.length; i++) {
        if (this.retrvExerciseList[i] == this.exerciseMasterList[j].value) {
          n = n + 1;
          if (n > 1) {
            selected_data.append(",");
            selected_data.append("\n");
          }
          selected_data.append(this.exerciseMasterList[j].label);
        }
      }
    }
    this.exercise = selected_data.toString();
  }

  getExerciseRoutine() {
    this.exerciseRoutineList;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exerciseRoutineList = obj.exercise_routine;
          this.exerRoutine = this.exerciseRoutineList[0].exer_rout_id;
          this.calculateCalories();
        },
        error => {
        }
      )
  }

  getQuantity() {
    this.alcoholQuantityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/aqty',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.alcoholQuantityList = obj.mixing_quantity;
          this.alcoholQuant = obj.mixing_quantity[0].alcohol_qty_id;
        },
        error => {
        }
      )
  }

  getMixingtype() {
    this.alcoholMixingList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/amix',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.alcoholMixingList = obj.mixing_types;
          this.alcoholMix = obj.mixing_types[0].mixing_id;
        },
        error => {
        }
      )
  }

  setGeneralData() {
    if (this.gservice.get_second_general_data() != null) {
      this.fieldDisable = true;
      this.exits = true;
      this.secOpnGeneralData = this.gservice.get_second_general_data();
      if (this.secOpnGeneralData.opinion_type != null) {
        this.opinionType = this.secOpnGeneralData.opinion_type
      }
      if (this.secOpnGeneralData.alc_duration != null) 
      {
        this.panelOpenState11 = true;
        this.toggAlchol = this.secOpnGeneralData.alcohol
        this.alcoholQuant = this.secOpnGeneralData.quantity;
        this.alcoholMix = this.secOpnGeneralData.mixing_type;
        this.alcholDuration = this.secOpnGeneralData.alc_duration;
        this.alcoholFrequency = this.secOpnGeneralData.alc_frequency;
      }
      if (this.secOpnGeneralData.smoke_duration != null) 
      {
        this.panelOpenState12 = true;
        this.toggSmoking = this.secOpnGeneralData.smoke;
        this.smokeDuration = this.secOpnGeneralData.smoke_duration;
        this.smokeFrequency = this.retrvSecOpnGeneralData.smoke_frequency;
      }
      else {
        this.toggSmoking = this.secOpnGeneralData.smoke;
      }
      if (this.secOpnGeneralData.gutka_duration != null) 
      {
        this.secOpnGeneralData.gutka = "Yes";
        this.panelOpenState13 = true;
        this.toggGutka = this.secOpnGeneralData.gutka;
        this.gutkaDuration = this.secOpnGeneralData.gutka_duration;
        this.gutkaFrequency = this.secOpnGeneralData.gutka_frequency;
      }
      else {
        this.toggGutka = this.secOpnGeneralData.gutka;
      }
      if (this.secOpnGeneralData.exer_routine != null) 
      {
        this.secOpnGeneralData.exercises = "Yes"
        this.panelOpenState14 = true;
        this.toggExercise = this.secOpnGeneralData.exercises;
        this.exerciseDuration = this.secOpnGeneralData.exer_day_dur;
        this.exerRoutine = this.secOpnGeneralData.exer_routine;
      }
      else {
        this.toggExercise = this.secOpnGeneralData.exercises
      }
      if (this.secOpnGeneralData.marital_status != null && this.secOpnGeneralData.marital_status != undefined) {
        this.maritalStatus = this.secOpnGeneralData.marital_status;
        this.bmi = this.secOpnGeneralData.bmi;
        this.bloodPressure = this.secOpnGeneralData.blood_pressure;
        this.dietaryHabits = this.secOpnGeneralData.diet_habits;
        this.height = this.secOpnGeneralData.height;
        this.heightMeasure = this.secOpnGeneralData.height_measure;
        this.weight = this.secOpnGeneralData.weight;
        this.weightMeasure = this.secOpnGeneralData.weight_measure;
        this.anyOtherIssue = this.secOpnGeneralData.other_issues;
      }
    }
  }

  getBasicData() {
    var appointment_client_data = this.gservice.get_appoint_client_data();
    if (this.subRelId != null && this.subRelId != undefined) {
      var send_data = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.getSecOpnData.relation_id,
        sub_rel_id: this.subRelId
      })
    }
    else {
      var send_data = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.getSecOpnData.relation_id,
      })
    }
    if (appointment_client_data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/asgdet", send_data
        ,
        { headers: headers }).subscribe(
          data => {
            this.secOpnGeneralData = {};
            this.secOpnFamilyData = {};
            if (data.json().alc_duration != null) 
            {
              this.secOpnGeneralData.alcohol = "Yes";
              this.secOpnGeneralData.quantity = data.json().alcohol_qty_id;
              this.secOpnGeneralData.mixing_type = data.json().alcohol_mixing_id;
              this.secOpnGeneralData.alc_duration = data.json().alc_duration;
              this.secOpnGeneralData.alc_frequency = data.json().alc_frequency;
            }
            else {
              this.secOpnGeneralData.alcohol = "No";
            }
            if (data.json().smoke_duration != null) 
            {
              this.secOpnGeneralData.smoke = "Yes"
              this.secOpnGeneralData.smoke_duration = data.json().smoke_duration;
              this.secOpnGeneralData.smoke_frequency = data.json().smoke_frequency;
            }
            else {
              this.secOpnGeneralData.smoke = "No";
              this.toggSmoking = this.secOpnGeneralData.smoke
            }
            if (data.json().gutka_duration != null) 
            {
              this.secOpnGeneralData.gutka = "Yes";
              this.secOpnGeneralData.gutka_duration = data.json().gutka_duration;
              this.secOpnGeneralData.gutka_frequency = data.json().gutka_frequency;
            }
            else {
              this.secOpnGeneralData.gutka = "No";
            }
            if (data.json().exer_routine != null) 
            {
              for (var i = 0; i < data.json().exercises.length; i++) {
                this.gservice.second_opinion_gen_excer.push(data.json().exercises[i].exercise_id)
                this.retrvExerciseList.push(data.json().exercises[i].exercise_id);
              }
              this.changeExercise(this.retrvExerciseList)
              this.panelOpenState14 = true;
              this.toggExercise = "Yes"
              this.secOpnGeneralData.exercises = data.json().exercises;
              this.secOpnGeneralData.exer_routine = data.json().exer_routine;
              this.secOpnGeneralData.exer_day_dur = data.json().smoke_frequency;
            }
            else {
              this.secOpnGeneralData.exercises = "No"
            }
            if (data.json().medical_history != null) {
              this.changeMedicalHistory(data.json().medical_history);
              this.secOpnGeneralData.medical_history = data.json().medical_history;
            }
            if (data.json().opinion_desc != null) {
              this.secOpnGeneralData.opinion_type = data.json().opinion_desc;
              this.opinionType = this.secOpnGeneralData.opinion_type
            }
            this.gservice.set_second_general_data(this.secOpnGeneralData);
            this.setGeneralData();
            if (data.json().diabetics != null) 
            {
              if (data.json().diabetics != "No" && data.json().diabetics != "no") {
                this.secOpnFamilyData.diabetics = "yes";
                this.ynopt1 = true;
              }
              else {
                this.secOpnFamilyData.diabetics = "no";
                this.ynopt1 = false;
              }
              if (data.json().heart_attack != "No" && data.json().heart_attack != "no") {
                this.secOpnFamilyData.heart_attack = "yes";
                this.ynopt2 = true;
              }
              else {
                this.secOpnFamilyData.heart_attack = "no";
                this.ynopt2 = false;
              }
              if (data.json().stroke != "No" && data.json().stroke != "no") {
                this.secOpnFamilyData.stroke = "yes";
                this.ynopt3 = true;
              } else {
                this.secOpnFamilyData.stroke = "no";
                this.ynopt3 = false;
              }
              if (data.json().arterial != "No" && data.json().arterial != "no") {
                this.secOpnFamilyData.arterial = "yes";
                this.ynopt4 = true;
              } else {
                this.ynopt4 = false;
                this.secOpnFamilyData.arterial = "no";
              }
              if (data.json().endocrine != "No" && data.json().endocrine != "no") {
                this.secOpnFamilyData.endocrine = "yes";
              }
              else {
                this.secOpnFamilyData.endocrine = "no";
              }
              if (data.json().autoimmune != "No" && data.json().autoimmune != "no") {
                this.ynopt5 = true;
                this.secOpnFamilyData.autoimmune = "yes";
              }
              else {
                this.ynopt5 = false;
                this.secOpnFamilyData.autoimmune = "no";
              }
              if (data.json().hypertension != "No" && data.json().hypertension != "no") {
                this.secOpnFamilyData.hypertension = "yes";
              }
              else {
                this.secOpnFamilyData.hypertension = "no";
              }
              if (data.json().hemophilia != "No" && data.json().hemophilia != "no") {
                this.secOpnFamilyData.hemophilia = "yes";
              }
              else {
                this.secOpnFamilyData.hemophilia = "no";
              }
              if (data.json().tuberculosis != "No" && data.json().tuberculosis != "no") {
                this.secOpnFamilyData.tuberculosis = "yes";
              }
              else {
                this.secOpnFamilyData.tuberculosis = "no";
              }
              if (data.json().hiv != "No" && data.json().hiv != "no") {
                this.secOpnFamilyData.hiv = "yes";
              }
              else {
                this.secOpnFamilyData.hiv = "no";
              }
              if (data.json().hepatitis != "No" && data.json().hepatitis != "no") {
                this.secOpnFamilyData.hepatitis = "yes";
              }
              else {
                this.secOpnFamilyData.hepatitis = "no";
              }
              if (data.json().glaucoma != "No" || data.json().glaucoma != "no") {
                this.secOpnFamilyData.glaucoma = "yes";
              }
              else {
                this.secOpnFamilyData.glaucoma = "no";
              }
              this.secOpnFamilyData.others = data.json().others;
              this.secOpnFamilyData.disabled = true;
              this.gservice.set_second_family_data(this.secOpnFamilyData);
              this.getFamilyData();
            }
          },
          error => {
          })
    }
  }

  getFamilyData() {
    if (this.gservice.get_second_family_data() != null && this.gservice.get_second_family_data() != undefined) {
      this.familyHistData = this.gservice.get_second_family_data();
      if (this.familyHistData.disabled != null) {
        this.fieldDisable = true;
        this.saveUpdateFlag = true;
        if (this.familyHistData.Retrival != null) {
          this.exits = true;
        }
      }
      if (this.familyHistData.diabetics == null || this.familyHistData.diabetics == undefined
        || this.familyHistData.diabetics == "" || this.familyHistData.diabetics.length == 0) {
        this.familyHistData = {
          diabetics: false,
          heart_attack: false,
          stroke: false,
          arterial: false,
          endocrine: false,
          autoimmune: false,
          hypertension: false,
          hemophilia: false,
          tuberculosis: false,
          hiv: false,
          hepatitis: false,
          glaucoma: false,
          other: ""
        }
      }
      else {
        if (this.familyHistData.diabetics == "yes")
          this.familyHistData.diabetics = true;
        else
          this.familyHistData.diabetics = false;
        if (this.familyHistData.heart_attack == "yes")
          this.familyHistData.heart_attack = true;
        else
          this.familyHistData.heart_attack = false;
        if (this.familyHistData.stroke == "yes")
          this.familyHistData.stroke = true;
        else
          this.familyHistData.stroke = false;
        if (this.familyHistData.arterial == "yes")
          this.familyHistData.arterial = true;
        else
          this.familyHistData.arterial = false;
        if (this.familyHistData.endocrine == "yes")
          this.familyHistData.endocrine = true;
        else
          this.familyHistData.endocrine = false;
        if (this.familyHistData.autoimmune == "yes")
          this.familyHistData.autoimmune = true;
        else
          this.familyHistData.autoimmune = false;
        if (this.familyHistData.hypertension == "yes")
          this.familyHistData.hypertension = true;
        else
          this.familyHistData.hypertension = false;
        if (this.familyHistData.hemophilia == "yes")
          this.familyHistData.hemophilia = true;
        else
          this.familyHistData.hemophilia = false;
        if (this.familyHistData.tuberculosis == "yes")
          this.familyHistData.tuberculosis = true;
        else
          this.familyHistData.tuberculosis = false;
        if (this.familyHistData.hiv == "yes")
          this.familyHistData.hiv = true;
        else
          this.familyHistData.hiv = false;
        if (this.familyHistData.hepatitis == "yes")
          this.familyHistData.hepatitis = true;
        else
          this.familyHistData.hepatitis = false;
        if (this.familyHistData.glaucoma == "yes") {
          this.familyHistData.glaucoma = true;
        }
        else {
          this.familyHistData.glaucoma = false;
        }
        this.familyHistData.other = this.familyHistData.others;
      }
    }
  }

  changeYoga(valuecard, event) {
    if (event.selected == true) {
      this.sendYogaData.push(
        valuecard
      )
    } else {
      for (var i = 0; this.sendYogaData.length; i++) {
        if (this.sendYogaData[i] == valuecard) {
          this.sendYogaData.splice(i, 1);
          break;
        }
      }
    }
  }

  religious() {
    for (var j = 0; j < this.religiousBeliefMasterList.length; j++) {
      this.religiousBeliefMasterList[j].checked = false;
    }
    if (this.retrvReligiousBelief != undefined && this.retrvReligiousBelief.length != 0) {
      for (var i = 0; i < this.retrvReligiousBelief.length; i++) {
        for (var j = 0; j < this.religiousBeliefMasterList.length; j++) {
          if (this.retrvReligiousBelief[i] == this.religiousBeliefMasterList[j].value) {
            this.religiousBeliefMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Religious",
        main_array: this.religiousBeliefMasterList,
        selected_list:this.retrvReligiousBelief,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changeReligiousBelief(result);
        }
    });
  }
  
  mainconcernpopup() {
    for (var j = 0; j < this.mainConcernMasterList.length; j++) {
      this.mainConcernMasterList[j].checked = false;
    }
    if (this.retrvMainConcern != undefined && this.retrvMainConcern.length != 0) {
      for (var i = 0; i < this.retrvMainConcern.length; i++) {
        for (var j = 0; j < this.mainConcernMasterList.length; j++) {
          if (this.retrvMainConcern[i] == this.mainConcernMasterList[j].value) {
            this.mainConcernMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Main Concerns",
        main_array: this.mainConcernMasterList,
        selected_list:this.retrvMainConcern,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changeMainConcern(result);
        }
    });
  }
}
