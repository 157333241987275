<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Time Line</h5>
          </div>
          <div class="headerButtons">
            
             <mat-label class="matlabel"><br>
              <!-- (click)="todisplaycoverdiv()" -->
              <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" (click)="todisplaycoverdiv()"
               *ngIf="backbutton" />
              
              <!-- <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a> -->
            
                  <!-- <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" style="margin-right:10px" *ngIf="backbutton" /> -->

            </mat-label> 
          
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            
          </div> -->
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
          <div class="header_lable">
            {{reportheading}} Details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " [(ngModel)]="fromDate" max="{{currentDate}}"
                     #matInput style="width: 140px;">
                </mat-label>
              </div>
        
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getSalesData()" />
                </mat-label>
              </div>
             
            </div>
          </div>
        </div>

        <!-- <div
          style="box-shadow: 0 0 2px 0 ;
        background: inherit;
        backdrop-filter: blur(10px);border: 1px solid #66b9eb;border-radius: 5px;padding: 1px 20px 5px 20px;margin-top:5px"
          *ngIf="amountcard">
        
          <div style="margin-top: 5px;" class="row">
         


          </div>
        </div> -->
        <p class="nodata" *ngIf="timelinedatalist.length == 0">No records found</p>

        <!-- <div [hidden]="true">
        <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
          <tr>
            <td>
              <img alt="image" src={{hospital_logo}}>
            </td>
          </tr>
        </table>
        <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo id="hospitalwithnamelogo">
          <span><b>{{hosp_name}}</b>,</span><br />
          <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
          <span>{{hosp_csz}}</span><br />
          <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
              height="15px" />{{hosp_tel}}.</span><br>
        </div>
        </div> -->

        <!-- <div class="table-responsive cover_div" [ngStyle]="{'width': applystyle ? '100%' : '50%','height': applyheight ? '65vh':'65vh'}" *ngIf="salesList.length != 0">
         
         
        </div> -->

        <div class="table-responsive cover_div" *ngIf="timelinedatalist.length != 0" style="overflow-y: hidden" [ngStyle]="{'width': applystyle ? '100%' : '50%','height': applyheight ? '65vh':'65vh'}">
       
          <div class="table-container" *ngIf="reportType == 'htat'">
            <div class="header-container">
       
          <table   class="table-header"
            style="font-size: 12px; width: 70%;">
            <thead>
              <tr>
                <th style="width:10px;text-align: left;border:1px solid gainsboro">S.No</th>
                <th style="text-align: left;width:15%;border:1px solid gainsboro">Patient name</th>
                <th style="text-align: left;border:1px solid gainsboro;width:700px">Flow</th>
                <th style="border:1px solid gainsboro">TAT</th>
        
              </tr>
            </thead>
            </table>
          </div>



          <div class="body-container">

            <table class="table" style="font-size: 12px;width:70%" >
            <tbody style="height:300px;">
              <tr *ngFor="let timeline of timelinedatalist;let i = index " [hidden]="undefinedtable">
                <td style="width:54px;text-align: left;border:1px solid gainsboro">{{ getSerialNumber(i) }}</td>
                <td  style="border:1px solid gainsboro;width:15%;text-align: left;">{{timeline.name}}</td>
                <td style="border:1px solid gainsboro;width:700px;text-align: start;">
<!--                   width:70%
                  right:4% -->
                  <div class="process-wrapper" style="position:relative;">
                    <div id="progress-bar-container">
                        <br /><br />
                        <!-- style="margin-left:auto;margin-right: auto;" -->
                        <div>
                
                  
                
                
                  <div class="breadcrumb flat">
                  
                
                
                
                    <div>
                      
                      App time
                    </div>
                    
                
                  <div>
                   
                        Consult
                    </div>
                  
                    <div >
                     
                      Prescription
                    </div>
                  
                    <div >
                      
                
                      
                        Dx report
                    </div>
                    
                    <div >
                     
                     Drx estimate
                    </div>
                    
                    <div>
                      
                        Drx bill 
                    </div>
                
                
                
                
                </div>
                
                
                <!-- <center> -->
                  <div style="width: 80%;
                  display: flex;
                  justify-content: space-evenly;">
                    <!-- <div *ngIf="app_time.length != 0 || ''||null || undefined " style="border:1px solid gray;    padding: 3px; 
                    
                    font-size: 10px;border-radius: 5px;position: relative;
                    right: 10px;"> {{timeline.app_time}} </div> -->
                    <div *ngIf="timeline.app_time != undefined" style="border:1px solid gray;    padding: 3px; 
                    
                    font-size: 10px;border-radius: 5px;position: relative;
                    right: 40px;"> {{timeline.app_time}} </div>
                
                
                <div *ngIf="timeline.app_time == undefined" style="border:1px solid transparent; 
                    
                font-size: 0px;border-radius: 5px;position: relative;color:transparent;"></div>

                  
<!--  -->
                  <div *ngIf="timeline.consult_time == undefined" style="border:1px solid transparent;color:transparent;
                    
                    font-size: 0px;border-radius: 5px;"></div>


                        <div *ngIf="timeline.consult_time != undefined" style="border:1px solid grey;    padding: 3px;
                    
font-size: 10px;border-radius: 5px;position: relative;
right: 30px;"> {{timeline.consult_time}} </div>    

                
                   <!--  -->
                   <div *ngIf="timeline.pres_time == undefined" style="border:1px solid transparent;color:transparent;
                    
                    font-size: 0px;border-radius: 5px;position: relative;
                    right: 20px;"></div>

                    <div *ngIf="timeline.pres_time != undefined"  style="border:1px solid grey;    padding: 3px;
                    
                    font-size: 10px;border-radius: 5px;position: relative;
right: 20px;">{{timeline.pres_time}} </div>



                    
                    <div *ngIf="timeline.diag_time == undefined" style="border:1px solid transparent;color:transparent;
                    
                    font-size: 0px;border-radius: 5px;position: relative;
                    right: 20px;"></div>
                    
                    <div *ngIf="timeline.diag_time != undefined" style="border:1px solid grey;  padding: 3px;
                    
                    font-size: 10px;border-radius: 5px;position: relative;
                    left: 0px;">{{timeline.diag_time}}</div>


                    
                    
                    <div *ngIf="timeline.estimatetime == undefined" style="border:1px solid transparent;color:transparent;
                    
                    font-size: 0px;border-radius: 5px;position: relative;
                    right: 10px;"></div>

                    <div *ngIf="timeline.estimatetime != undefined" style="border:1px solid grey;    padding: 3px;
                    
                    font-size: 10px;border-radius: 5px;position: relative;
                    left: 50px;">{{timeline.estimatetime}}</div>
                   

                   
                   <div *ngIf="timeline.order_time == undefined" style="border:1px solid transparent;color:transparent;
                   
                    font-size: 0px;border-radius: 5px;position: relative;
                    right: 40px;"></div>

                    <div *ngIf="timeline.order_time != undefined" style="border:1px solid gray; padding: 3px;
                   
                      font-size: 10px;border-radius: 5px;position: relative;  
                      left: 60px;"> {{timeline.order_time}}</div>


                    </div>

                    
                    <!-- left:25px -->
                  <div class="progress" style="width: 85%;height:3px;border:1px solid;margin-top:5px;position: relative;left:10px">
                      
                
                    
                    <div class="progress-bar"
                          style="width: 70%; background-color: green">
                      </div>
                      <div class="progress-bar progress-bar-stripped"
                          style="width: 60%; background-color: green">
                      </div>
                      <div class="progress-bar progress-bar-stripped"
                          style="width: 70%; background-color:green">
                      </div>
                      <div class="progress-bar"
                      style="width: 80%; background-color: green">
                  </div>
                  <div class="progress-bar progress-bar-stripped"
                      style="width: 80%; background-color:green">
                  </div>
                  <div class="progress-bar progress-bar-stripped"
                      style="width: 70%; background-color:green">
                  </div>
                  
                
                </div>
                  <br />
                <!-- </center> -->
                
                </div>
                
                </div>
                
                </div>
                
                
                
                </td>
                
                <td *ngIf="timeline.total != 'NaN.NaN'" style="text-align:center">{{timeline.total}}</td>
                <td style="color:white" *ngIf="timeline.total == 'NaN.NaN'">{{timeline.total}}</td>

              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
        



      <div class="table-container" style="width:100%" *ngIf="reportType == 'pharmatat'">
        <div class="header-container">
   
      <table   class="table-header"
        style="font-size: 12px; width: 100%;">
        <thead>
          <tr>
            <th style="text-align: left;width:6%;border:1px solid gainsboro">S.No</th>
            
            <th style="text-align: left;width:16%;border:1px solid gainsboro">Patient name</th>
            <!-- <th style="text-align: left;width:15%;border:1px solid gainsboro"> Delivered by</th> -->

            <th style="text-align: left;border:1px solid gainsboro;width:80%">Flow</th>
            <th style="border:1px solid gainsboro;padding-right:23px">TAT</th>
    
          </tr>
        </thead>
        </table>
      </div>



      <div class="body-container">

        <table class="table" style="font-size: 12px;width:100%">
        <tbody style="height:300px;">
          <tr *ngFor="let timeline of timelinedatalist">
            <td style="text-align: left;width:6%;border:1px solid gainsboro">{{timeline.sno}}</td>
           
            <td   style="border:1px solid gainsboro;width:16%;text-align: left;">{{timeline.name}}</td>
            <!-- <td ng*If="" style="border:1px solid gainsboro;width:16%;text-align: left;">{{timeline.delivered_person}}</td> -->
           
           
            <td style="border:1px solid gainsboro;width:80%">
              
              <div class="process-wrapper" style="position:relative;">
                <div id="progress-bar-container">
                    <br /><br />
                    <!-- style="margin-left:auto;margin-right: auto;" -->
                    <div>
            
              
            
            
              <div class="breadcrumb flat">
              
            
            
            
                <div>
                  
                  Estimate initiated time
                </div>
                
            
             
                <!-- <div *ngIf="type == 'estimate time'"> -->
                  <div *ngIf="timeline.estimate_time != undefined && type == 'estimate time'">
               Estimate time
                    
                </div>
              
                <div >
                 Order time
                  
                </div>
              
               
            
            
            
            
            </div>
            
            
            <center>
              <div style="width: 50%;
              display: flex;
              justify-content: space-evenly;">
             
               
               <div *ngIf="timeline.estimate_initiate_time != undefined" style="border:1px solid gray;    padding: 3px; 
                
                font-size: 10px;border-radius: 5px;position: relative;
                right: 40px;"> {{timeline.estimate_initiate_time}} </div>
            
            
            <div *ngIf="timeline.estimate_initiate_time == undefined" style="border:1px solid transparent; 
                
            font-size: 0px;border-radius: 5px;position: relative;
            right: 10px;color:transparent;"></div>

              

              <div *ngIf="timeline.estimate_time == undefined" style="border:1px solid transparent;color:transparent;    padding: 3px;
                
                font-size: 0px;border-radius: 5px;position: relative;
                right: 50px;"></div>


                 
                    
                    <div *ngIf="timeline.estimate_time != undefined && type == 'estimate time'" style="border:1px solid grey;    padding: 3px;
                
                    font-size: 10px;border-radius: 5px;position: relative;
                  left: 40px;"> 

                    
                    {{timeline.estimate_time}} </div>    

            
               <!--  -->
               <div *ngIf="timeline.order_time == undefined" style="border:1px solid transparent;color:transparent;
                font-size: 0px;border-radius: 5px;position: relative;
                right: 20px;"></div>
                <!--  -->
                <div *ngIf="timeline.order_time != undefined && timeline.estimate_initiate_time != timeline.estimate_time"  style="border:1px solid grey;    padding: 3px;
                
                font-size: 10px;border-radius: 5px;position: relative;
                left: 100px;">{{timeline.order_time}} </div>


  
                <div *ngIf="timeline.order_time != undefined && timeline.estimate_initiate_time == timeline.estimate_time"  style="border:1px solid grey;  padding: 3px;font-size: 10px;border-radius: 5px;position: relative;
                left: 50px;">{{timeline.order_time}}</div>   

                
                <!-- <div *ngIf="timeline.diag_time == undefined" style="border:1px solid white;color:white;    padding: 3px;
                
                font-size: 10px;border-radius: 5px;position: relative;
                right: 20px;"> 12:00 PM </div>
                
                <div *ngIf="timeline.diag_time != undefined" style="border:1px solid grey;  padding: 3px;
                
                font-size: 10px;border-radius: 5px;position: relative;
                left: 25px;">{{timeline.diag_time}}</div>


                
                
                <div *ngIf="timeline.estimatetime == undefined" style="border:1px solid white;color:white;    padding: 3px;
                
                font-size: 10px;border-radius: 5px;position: relative;
                right: 10px;"> 1:00 PM</div>

                <div *ngIf="timeline.estimatetime != undefined" style="border:1px solid grey;    padding: 3px;
                
                font-size: 10px;border-radius: 5px;position: relative;
                left: 40px;">{{timeline.estimatetime}}</div>
               

               
               <div *ngIf="timeline.order_time == undefined" style="border:1px solid white;color:white;    padding: 3px;
               
                font-size: 10px;border-radius: 5px;position: relative;
                right: 40px;"> 0:00 </div>

                <div *ngIf="timeline.order_time != undefined" style="border:1px solid gray; padding: 3px;
               
                  font-size: 10px;border-radius: 5px;position: relative;  
                  left: 100px;"> {{timeline.order_time}}</div> -->


                </div>

                
              
              <div class="progress" style="max-width: 60%;height:3px;border:1px solid;margin-top:5px;position: relative;left:10px">
                  
            
                <!-- style="width: 70%; background-color: green" -->
                <div class="progress-bar" 
                      
                      [ngClass]="timeline.estimatestyle ? 'estimatetimeextended':'estimatewithintime'"                     
                      >
                  </div>

                 
                  <div class="progress-bar progress-bar-stripped"
                  [ngClass]="timeline.estimatedispatchstyle ? 'timeextended':'withintime'">
                  </div>
                 
                  <!-- style='background-color': {{timeline.estimatedispatchstyle}} -->
                  <!-- style={{timeline.estimatedispatchstyle}}  -->









                   
                  <!-- <div class="progress-bar progress-bar-stripped"
                      style="width: 70%; background-color:green">
                  </div>
                  <div class="progress-bar"
                  style="width: 80%; background-color: green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 80%; background-color:green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 70%; background-color:green">
              </div> -->
              
            
            </div>

            <!-- {{timeline.estimatedispatchstyle}} -->
            
            <br />

              <div class="progress" style="max-width: 60%;height:3px;border:1px solid;margin-top:5px;position: relative;left:10px">
                  
            
                
                <div class="progress-bar"
                      style="width: 70%; background-color: green">
                  </div>
                  
                  <div class="progress-bar progress-bar-stripped"
                      style="width: 60%; background-color: green">
                  </div>
                  <div class="progress-bar progress-bar-stripped"
                      style="width: 70%; background-color:green">
                  </div>
                  <div class="progress-bar"
                  style="width: 80%; background-color: green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 80%; background-color:green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 70%; background-color:green">
              </div>

              
            
            </div>
               {{estimateandorder}}
            <div class="timechart" *ngIf="timeline.estimate_time != undefined && type == 'estimate time'">

              <div  style="border:1px solid gray;    padding: 3px; 
                
              font-size: 10px;border-radius: 5px"> {{timeline.estimate}} </div>

             
              <div  style="border:1px solid gray;    padding: 3px; 
                
              font-size: 10px;border-radius: 5px"> {{timeline.estimat_disp}} </div>

              </div>


             



            </center>
            
            </div>
            
            </div>
            
            </div>
            
            
            
            </td>


            <!-- <td style="text-align:center"> 0.09</td> -->
            <td *ngIf="timeline.total != 'NaN.NaN'" style="text-align:center;padding-right: 22px">{{timeline.total}}</td>
            <td style="color:transparent" *ngIf="timeline.total == 'NaN.NaN'">{{timeline.total}}</td>


          </tr>
        </tbody>
      </table>
    </div>
    
  </div>



  <div class="diagtable-container" *ngIf="reportType == 'diagtat'">
    <div class="header-container">

  <table   class="table-header"
    style="font-size: 12px; width: 100%;">
    <thead>
      <tr>
        <th style="width:10px;text-align: left;border:1px solid gainsboro">S.No</th>
        <th style="text-align: left;width:15%;border:1px solid gainsboro">Patient name</th>
        <th style="text-align: left;border:1px solid gainsboro;width:70%">Flow</th>
        <th style="border:1px solid gainsboro">TAT</th>

      </tr>
    </thead>
    </table>
  </div>



  <div class="body-container">

    <table class="diagtable" style="font-size: 12px;">
    <tbody style="height:300px;">
      <tr *ngFor="let timeline of timelinedatalist">
        <td style="width:54px;text-align: left;border:1px solid gainsboro">{{timeline.sno}}</td>
        <td  style="border:1px solid gainsboro;width:15%;text-align: left;">{{timeline.name}}</td>
        <td style="border:1px solid gainsboro;width:70%;text-align: left;">
          
          <div class="process-wrapper" style="position:relative;">
            <div id="progress-bar-container">
                <br /><br />
                <!-- style="margin-left:auto;margin-right: auto;" -->
                <div>
        
          
        
        
          <div class="breadcrumb flat">
          
        
        
        
            <div>
              
              App time
            </div>
            
        
          <div>
           
                Sample
            </div>
          
            <div >
             
              Report entry time
            </div>
          
            <div >
              
        
              
                Approval time
            </div>
            
            <!-- <div >
             
             Drx estimate
            </div>
            
            <div>
              
                Drx bill 
            </div>
         -->
        
        
        
        </div>
        
        
        <!-- <center> -->
          
          <div style="width: 79%;
          display: flex;
          justify-content: space-evenly;">
            <!-- <div *ngIf="app_time.length != 0 || ''||null || undefined " style="border:1px solid gray;    padding: 3px; 
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> {{timeline.app_time}} </div> -->
            
            <div *ngIf="timeline.app_time != undefined" style="border:1px solid gray;    padding: 3px; 
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 40px;"> {{timeline.app_time}} </div>
        
        
        <div *ngIf="timeline.app_time == undefined" style="border:1px solid transparent; 
            
        font-size: 10px;border-radius: 5px;position: relative;color:transparent;"></div>

          
<!--  -->
          <div *ngIf="timeline.sample_time == undefined" style="border:1px solid transparent;color:transparent;   
            
            font-size: 10px;border-radius: 5px;position: relative;"></div>


                <div *ngIf="timeline.sample_time != undefined" style="border:1px solid grey;    padding: 3px;
            
font-size: 10px;border-radius: 5px;position: relative;
right: 60px;"> {{timeline.sample_time}} </div>    

        
           <!--  -->
           <div *ngIf="timeline.report_entry_time == undefined" style="border:1px solid transparent;color:transparent;
            
            font-size: 10px;border-radius: 5px;position: relative;"></div>

            <div *ngIf="timeline.report_entry_time != undefined"  style="border:1px solid grey;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
right: 60px;">{{timeline.report_entry_time}} </div>



            
            <div *ngIf="timeline.approval_time == undefined" style="border:1px solid transparent;color:transparent;
            
            font-size: 10px;border-radius: 5px;position: relative;"></div>
            
            <div *ngIf="timeline.approval_time != undefined" style="border:1px solid grey;  padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            left: 25px;">{{timeline.approval_time}}</div>


            
            
            <!-- <div *ngIf="timeline.estimatetime == undefined" style="border:1px solid white;color:white;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> 1:00 PM</div>

            <div *ngIf="timeline.estimatetime != undefined" style="border:1px solid grey;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            left: 40px;">{{timeline.estimatetime}}</div> -->
           

           
           <!-- <div *ngIf="timeline.order_time == undefined" style="border:1px solid white;color:white;    padding: 3px;
           
            font-size: 10px;border-radius: 5px;position: relative;
            right: 40px;"> 0:00 </div>

            <div *ngIf="timeline.order_time != undefined" style="border:1px solid gray; padding: 3px;
           
              font-size: 10px;border-radius: 5px;position: relative;  
              left: 100px;"> {{timeline.order_time}}</div> -->


            </div>

            
          
          <div class="progress" style="max-width: 70%;height:3px;border:1px solid;margin-top:5px;position: relative;left:10px">
              
        
            
            <div class="progress-bar"
                  style="width: 70%; background-color: green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 60%; background-color: green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 70%; background-color:green">
              </div>
              <div class="progress-bar"
              style="width: 80%; background-color: green">
          </div>
          <div class="progress-bar progress-bar-stripped"
              style="width: 80%; background-color:green">
          </div>
          <div class="progress-bar progress-bar-stripped"
              style="width: 70%; background-color:green">
          </div>
          
        
        </div>
          <br />
     
          <!-- </center> -->
        
        </div>
        
        </div>
        
        </div>
        
        
        
        </td>
        <td *ngIf="timeline.total != 'NaN.NaN'" style="text-align:center">{{timeline.total}}</td>
        <td style="color:transparent" *ngIf="timeline.total == 'NaN.NaN'">{{timeline.total}}</td>

      </tr>
    </tbody>
  </table>
</div>

</div>








      </div>
        
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

























<!-- <h1>Time Line</h1>
  <br>

<div class="container">
<div class="row" style="margin-left:5%">
   
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
        <mat-label class="matlabel" style="margin-top: 7px;">From
          <input type="date" class="ipcss_date " [(ngModel)]="fromDate" max="{{currentDate}}"
             #matInput style="width: 140px;">
        </mat-label>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
        <mat-label class="matlabel"><br>
          <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
            (click)="getSalesData()" />
        </mat-label>
      </div>

    </div>
</div>




<p class="nodata" *ngIf="timelinedatalist.length == 0">No records found</p>
<div class="table-responsive cover_div" *ngIf="timelinedatalist.length != 0" [ngStyle]="{'width': applystyle ? '100%' : '50%'}">
  <table  
    style="font-size: 12px; width: 150%;">
    <thead>
      <tr>
        <th>S.No</th>
        <th>Patient name</th>
        <th>Flow</th>
        <th>TAT</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let timeline of timelinedatalist">
        <td>{{timeline.sno}}</td>
        <td>{{timeline.name}}</td>
        <td>
          
          <div class="process-wrapper">
            <div id="progress-bar-container">
                <br /><br />
                <div  style="margin-left:auto;margin-right: auto;">
        
          
        
        
          <div class="breadcrumb flat" 
          
          >
          
        
        
        
            <div>
              
              App Time
            </div>
            
        
          <div>
           
                Consult
            </div>
          
            <div >
             
              prescription
            </div>
          
            <div >
              
        
              
                Pathology
            </div>
            
            <div >
             
             Drug estimate
            </div>
            
            <div>
              
                Drug bill generate
            </div>
        
        
        
        
        </div>
        
        
        <center>
          <div style="width: 79%;
          display: flex;
          justify-content: space-evenly;">
            <div style="border:1px solid gray;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> 09:00 AM </div>
        
            <div style="border:1px solid gray;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> 10:00 AM </div>
        
            <div style="border:1px solid gray;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 20px;"> 11:00 AM</div>
        
            <div style="border:1px solid gray;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 20px;"> 12:00 PM </div>
            <div style="border:1px solid gray;    padding: 3px;
            
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> 1:00 PM</div>
            <div style="border:1px solid gray;    padding: 3px;
           
            font-size: 10px;border-radius: 5px;position: relative;
            right: 10px;"> 2:00 PM</div>
          </div>
          
          <div class="progress" style="max-width: 80%;height:8px;border:1px solid;margin-top:10px">
              
        
            
            <div class="progress-bar"
                  style="width: 70%; background-color: green">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 60%; background-color: red">
              </div>
              <div class="progress-bar progress-bar-stripped"
                  style="width: 70%; background-color:grey">
              </div>
              <div class="progress-bar"
              style="width: 80%; background-color: green">
          </div>
          <div class="progress-bar progress-bar-stripped"
              style="width: 80%; background-color:green">
          </div>
          <div class="progress-bar progress-bar-stripped"
              style="width: 70%; background-color:green">
          </div>
          
        
        </div>
          <br />
        </center>
        
        </div>
        
        </div>
        
        </div>
        
        
        
        </td>
        <td></td>
        
      </tr>
    </tbody>
  </table>

 

</div> -->











<!-- time line -->
  <!-- <div class="process-wrapper">
    <div id="progress-bar-container">
        <br /><br />
        <div  style="margin-left:auto;margin-right: auto;">

  


  <div class="breadcrumb flat" style="position: relative;left: 11%;">
	



    <div>
      
      App Time
    </div>
    

	<div>
   
        Consult
    </div>
	
    <div >
     
      prescription
    </div>
	
    <div >
      

      
        Pathology
    </div>
    
    <div >
     
     Drug estimate
    </div>
    
    <div>
      
        Drug bill generate
    </div>




</div>


<center>
  <div style="width: 79%;
  display: flex;
  justify-content: space-evenly;">
    <div style="border:1px solid gray;    padding: 3px;
    
    font-size: 10px;border-radius: 5px;position: relative;
    right: 10px;"> 09:00 AM </div>

    <div style="border:1px solid gray;    padding: 3px;
    
    font-size: 10px;border-radius: 5px;position: relative;
    right: 10px;"> 10:00 AM </div>

    <div style="border:1px solid gray;    padding: 3px;
    
    font-size: 10px;border-radius: 5px;position: relative;
    right: 20px;"> 11:00 AM</div>

    <div style="border:1px solid gray;    padding: 3px;
    
    font-size: 10px;border-radius: 5px;position: relative;
    right: 20px;"> 12:00 PM </div>
    <div style="border:1px solid gray;    padding: 3px;
    
    font-size: 10px;border-radius: 5px;position: relative;
    right: 10px;"> 1:00 PM</div>
    <div style="border:1px solid gray;    padding: 3px;
   
    font-size: 10px;border-radius: 5px;position: relative;
    right: 10px;"> 2:00 PM</div>
  </div>
  
  <div class="progress" style="max-width: 80%;height:8px;border:1px solid;margin-top:10px">
      

    
    <div class="progress-bar"
          style="width: 70%; background-color: green">
      </div>
      <div class="progress-bar progress-bar-stripped"
          style="width: 60%; background-color: red">
      </div>
      <div class="progress-bar progress-bar-stripped"
          style="width: 70%; background-color:grey">
      </div>
      <div class="progress-bar"
      style="width: 80%; background-color: green">
  </div>
  <div class="progress-bar progress-bar-stripped"
      style="width: 80%; background-color:green">
  </div>
  <div class="progress-bar progress-bar-stripped"
      style="width: 70%; background-color:green">
  </div>
  

</div>
  <br />
</center>

</div>

</div>

</div> -->
<!-- time line  -->

 
 



