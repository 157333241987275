<div class="speciality-section pt-5 pb-5" id="specialitySection">
    <div class="container">
       <div class="row">
          <div class="text-center">
             <h1 class="speciality-heading" style="font-size: 35px;">Speciality We Cover</h1>
          </div>
          <div class="container my-5">
             <div class="row d-flex justify-content-center">
                <div class="col-12">
                   <div id="carousel" class="carousel slide" data-ride="carousel">
                      <ol class="carousel-indicators">
                         <li data-target="#carousel" data-slide-to="0" class="active bg-secondary"></li>
                         <li data-target="#carousel" data-slide-to="1" class="bg-secondary"></li>
                      </ol>
                      <div class="carousel-inner">
                         <div class="carousel-item active">
                            <div class="row">
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/orthopedic.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Orthopedic</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="./Icons for web/psychology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Psychiatry</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="./Icons for web/ophthalmology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Ophthalmology</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="./Icons for web/Dental.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Dental</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/Neurology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Neurology</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="/Icons for web/Cardiology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Cardiology</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/Pulmonology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Pulmonology</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="/Icons for web/Nephrology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Nephrology</p>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <div class="carousel-item">
                            <div class="row">
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/Diabetes.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Diabetes</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="/Icons for web/Urology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Urology</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/ent.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">ENT</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="./Icons for web/Gynecology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Gynecology</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/Gastroenterology.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Gastroenterology</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="/Icons for web/In vitro fertilization.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">IVF</p>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-12 col-md-6 col-xl-3 mb-4">
                                  <div class="mr-3">
                                     <div class="specialty-card text-center mb-3">
                                        <img src="/Icons for web/General surgery.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">General surgery</p>
                                     </div>
                                     <div class="specialty-card text-center">
                                        <img src="/Icons for web/Infectious diseases.svg"
                                           class="specialty-card-icon" />
                                        <p class="specialty-para pt-2">Infectious disease</p>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>