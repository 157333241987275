<div *ngIf="reports == 'min'">
    <p class="tervys_heading">Document Upload</p>
    <div [hidden]="document_list.length!=0"><p class="nodata">No data found </p></div>
    <div [hidden]="document_list.length==0">
                <table [hidden]="document_list.length==0" id="so_report_tbl" >
                    <thead>
                      <tr>
                        <th>Preview document</th>
                        <th>View document</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let viewdoc of document_list | slice:0:1">
                        <td>
                          <img id="doc_image" src="{{viewdoc.document}}" class="document_img" />
                        </td>
                        <td>
                          <a (click)="report_detail(viewdoc.document)">View</a>
                        </td>
                      </tr>
                    </tbody>
                </table>
    </div>
</div>

<div *ngIf="reports == 'max'">
    <div class="Details"  style="min-height: 400px;">
        <p class="tervys_heading">Document Upload</p>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                <div [hidden]="document_list.length!=0"><p class="nodata">No data found </p></div>
                <div [hidden]="document_list.length==0">
                    <table [hidden]="document_list.length==0" id="so_report_tbl" >
                        <thead>
                          <tr>
                            <th>Preview document</th>
                            <th>View document</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let viewdoc of  document_list">
                            <td>
                              <img id="doc_image" src="{{viewdoc.document}}" class="document_img" />
                            </td>
                            <td>
                              <a (click)="report_detail(viewdoc.document)">View</a>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <p  [hidden]="reports_upload" class="tervys_heading">Report Upload</p>
        <div class="row"  [hidden]="reports_upload">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div [hidden]="blood_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                        Blood  
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 10px">
                                    <div *ngFor="let list of second_blood_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card  (click)="report_view(list.test_id,'Blood',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label class="matlabel">{{list.test_name+" "+" "}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>    
                    </div>
                </div>
                <div [hidden]="urine_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                        Urine
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row col-sm-12 col-xl-12 " style="padding-left: 13px;padding-top: 10px">
                                    <div *ngFor="let list of second_urine_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card (click)="report_view(list.test_id,'Urine',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div [hidden]="faeces_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                    Faeces
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 10px">
                                    <div *ngFor="let list of second_faces_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card (click)="report_view(list.test_id,'Faeces',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div [hidden]="xray_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                    X-ray 
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 10px">
                                    <div *ngFor="let list of second_xray_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card (click)="report_view(list.test_id,'X-ray',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div [hidden]="scan_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion  class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                    Scans
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 10px">
                                    <div *ngFor="let list of second_scane_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card (click)="report_view(list.test_id,'scans',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div [hidden]="ultra_hidden" style="margin-bottom: 5px;">
                    <div class="block1">
                        <mat-accordion  class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                            <mat-expansion-panel-header class="exppanel width">
                                <mat-panel-title class="title ">
                                    Ultra-Sound 
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-left: 13px;">
                                    <div *ngFor="let list of second_ultra_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                        <mat-card (click)="report_view(list.test_id,'ultra-sound',list.app_id)"  class="List_border">
                                            <mat-card-header></mat-card-header>
                                            <mat-card-content>
                                                <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                                            </mat-card-content>
                                        </mat-card><br>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <p class="tervys_heading" *ngIf="second_medical_preslist.length != 0">Medical Prescription</p>
        <div class="row" *ngIf="second_medical_preslist.length != 0">
            <div *ngFor="let list of second_medical_preslist" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="view_med(list.test_id)"  class="List_border">
                    <mat-card-header></mat-card-header>
                    <mat-card-content>
                        <mat-label>{{list.test_name+" "+"test"}}</mat-label><br>
                    </mat-card-content>
                </mat-card><br>
            </div>
        </div>
    </div>
</div>

