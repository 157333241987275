const { Jsonp } = require("@angular/http");
const { Helper_Class } = require("src/app/helper_class");
// time format based on session 
function Time_Formate_Session(timetxt, session) {
    var time = timetxt.split(':');

    if (session == "Morning") {
        if (time[0] == "12") {
            return time[0] + ":" + time[1] + " PM";
        }
        else {
            return time[0] + ":" + time[1] + " AM";
        }
    }
    else {
        return time[0] + ":" + time[1] + " PM";
    }
}
exports.Time_Formate_Session = Time_Formate_Session;

//12 hours to 24 hours1

function ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
}
exports.ConvertTimeformat = ConvertTimeformat;

//=================================== to get session Return1 
function Session_Return(text) {

    if (text == "1") {
        return "Morning";
    }
    else if (text == "2") {
        return "Afternoon";
    }
    else if (text == "3") {
        return "Evening";
    }
}
exports.Session_Return = Session_Return;

//=================================== to get seesion_nameid 

function seesion_nameid(text) {

    if (text == "Morning") {
        return 1;
    }
    else if (text == "Afternoon") {
        return 2;
    }
    else if (text == "Evening") {
        return 3;
    }
}
exports.seesion_nameid = seesion_nameid;

//====================================== to get day_nameid

function day_nameid(text) {

    if (text == "Sunday") {
        return 1;
    }
    else if (text == "Monday") {
        return 2;
    }
    else if (text == "Tuesday") {
        return 3;
    }
    else if (text == "Wednesday") {
        return 4;
    }
    else if (text == "Thursday") {
        return 5;
    }
    else if (text == "Friday") {
        return 6;
    }
    else if (text == "Saturday") {
        return 7;
    }
    else if (text == "Mon - Sat") {
        return 8;
    }
    else if (text == "All Days") {
        return 9;
    }

}
exports.day_nameid = day_nameid;

//========================  to get seesion_ampm
function seesion_ampm(text) {

    if (text == "Morning") {
        return "am";
    }
    else if (text == "Afternoon") {
        return "pm";
    }
    else if (text == "Evening") {
        return "pm";
    }
}
exports.seesion_ampm = seesion_ampm;

//======================= to get day return

function Day_Return(text) {

    if (text == "1") {
        return "Sunday";
    }
    else if (text == "2") {
        return "Monday";
    }
    else if (text == "3") {
        return "Tuesday";
    }
    else if (text == "4") {
        return "Wednesday";
    }
    else if (text == "5") {
        return "Thursday";
    }
    else if (text == "6") {
        return "Friday";
    }
    else if (text == "7") {
        return "Saturday";
    }
    else if (text == "8") {
        return "Mon - Sat";
    }
    else if (text == "9") {
        return "All Days";
    }

}

exports.Day_Return = Day_Return;

//Nurse day return

function Nurse_Day_Return(text) {

    if (text == "1") {
        return "Mon - Sat";
    }
    else if (text == "2") {
        return "All Days";
    }

}




exports.Nurse_Day_Return = Nurse_Day_Return;

//======================================format time 
function Time_Formate(text) {

    var time = text.split(':');

    if (time[0] >= 12) {

        if (time[0] == 12) {
            return time[0] + ":" + time[1] + " PM";

        }
        else {
            var hours = time[0] - 12;

            if (hours < 10) {


                return "0" + hours + ":" + time[1] + " PM";
            }
            else {
                return hours + ":" + time[1] + " PM";
            }
        }

    }
    else {
        return time[0] + ":" + time[1] + " AM"
    }
}

exports.Time_Formate = Time_Formate;

//======================================format time1
function Time_Formate1(text) {

    var time = text.split(':');

    if (time[0] >= 12) {

        if (time[0] == 12) {
            return time[0] + ":" + time[1] + ":" + "PM";

        }
        else {
            var hours = time[0] - 12;

            if (hours < 10) {


                return "0" + hours + ":" + time[1] + ":" + "PM";
            }
            else {
                return hours + ":" + time[1] + ":" + "PM";
            }
        }

    }
    else {
        return time[0] + ":" + time[1] + ":" + "AM"
    }
}
exports.Time_Formate1=Time_Formate1;
//=======================================  date format

function formattedDate(d = new Date) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
 

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;


    // return `${month}/${day}/${year}`;
    return `${year}-${month}-${day}`;

 
}

exports.formattedDate = formattedDate;



function Date_Formate(data) {
    var Splitter = data.split("-");

    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
}
exports.Date_Formate = Date_Formate;
function Ignorecase_data(str_1, str_2) {

    return str_1.toUpperCase() == str_2.toUpperCase();
}

exports.Ignorecase_data = Ignorecase_data;


function first_uppercase(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
}

exports.first_uppercase = first_uppercase;



function session_based_time_fun(t1, t2, ses) {




    var date1 = new Date("03/13/2014" + ' ,' + t1 + ":00");
    var date2 = new Date("03/13/2014" + ' ,' + t2 + ":00");

    var options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    var timeString1 = date1.toLocaleString('en-US', options);
    var timeString2 = date2.toLocaleString('en-US', options);

    var ll1 = timeString1.split(' ');
    var ll2 = timeString2.split(' ');

    var t1_spt = timeString1.split(':');
    var t2_spt = timeString2.split(':');


    var fromvalue = t1_spt[0] + "00";
    var tovalue = t2_spt[0] + "00";

    //var f_time = t1.split(':');
    var chk_flag = false;
    if (ses == "Morning") {



        if (ll1[1] == "PM" && ll2[1] == "PM") {
            chk_flag = false;
        }
        else if (ll1[1] == "PM") {
            chk_flag = false;
        }
        else if (ll2[1] == "PM" && t2_spt[0] != "12") {
            chk_flag = false;
        }
        else {


            var morningfrom = 1200;
            var morningTo = 1200;
            var result = compareRangeMorning(fromvalue, morningfrom, morningTo);
            if (result == true) {
                var result1 = compareRangeMorning(tovalue, morningfrom, morningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }

                if (t1_spt[0] < 12 && t2_spt[0] <= 12) {
                    chk_flag = true;
                }
            }
        }
    }
    else if (ses == "Afternoon") {

        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {
            var afternoonfrom = 1200;
            var afternoonTo = 400;
            var result = compareRangeNoon(fromvalue, afternoonfrom, afternoonTo);



            if (result == true) {

                var res = compareValuesNoon(fromvalue, tovalue);


                if (res == true) {
                    var toresult = compareRangeNoon(tovalue, afternoonfrom, afternoonTo);
                    if (toresult == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                    //	System.out.println("compareRangeNoon");

                }
                else {
                    //	System.out.println("compareRangeNoon 123");
                    chk_flag = false;
                }

            }
            else {
                chk_flag = false;
            }
        }

        //if (t1_spt[0] >= 12 && t2_spt[0] <= 16) {
        //    chk_flag = true;
        //}

    }
    else {
        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {



            //if (t1_spt[0] > 16 && t2_spt[0] <= 24) {
            //    chk_flag = true;
            //}

            var eveningfrom = 400;
            var eveningTo = 1100;

            var result = compareRangeEvening(fromvalue, eveningfrom, eveningTo);
            if (result == true) {
                var result1 = compareRangeEvening(tovalue, eveningfrom, eveningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }
            }
            else {
                chk_flag = false;
            }
        }
    }

    return chk_flag;

}

function compareRangeMorning(value, from, to) {
    var result;
    if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeEvening(value, from, to) {
    var result;
    if (parseInt(value) >= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeNoon(value, from, to) {

    var result = false;
    if (parseInt(value) >= 100 && parseInt(value) <= 400) {
        //MessageBox.Show("inside " + value + "   " + from + "  " + to);
        if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
            ///	System.out.println("inside fdfa");
            result = true;
        }
    }
    else if (parseInt(value) >= parseInt(from) && parseInt(value) >= parseInt(to)) {
        // MessageBox.Show("inside123 " + value + "   " + from + "  " + to);
        result = true;
    }
    else {
        result = false;
    }
    //System.out.println("inside " + result);
    return result;
}


function compareValuesNoon(from, to) {
    var result = false;

    if (parseInt(from) >= 100 && parseInt(from) <= 400) {

        if (parseInt(from) <= parseInt(to)) {
            result = true;
        }
    }
    else if (parseInt(from) >= parseInt(to)) {
        result = true;
    }
    else {

        result = false;
    }
    return result;
}

function compareValues(from, to) {
    var result;
    if (parseInt(from) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}


exports.session_based_time_fun = session_based_time_fun;

function diag_dayid(text) {
    if (text == "Mon - Sat") {
        return 1;

    } else if (text == "All Days") {
        return 2;
    }
}
exports.diag_dayid = diag_dayid;


function Nurse_Day_id(text) {
    if (text == "Mon - Sat") {

        return 1;

    } else if (text == "All Days") {

        return 2;
    }
}
exports.Nurse_Day_id = Nurse_Day_id;

function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};
exports.tConv24 = tConv24;


function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
}
exports.capitalize = capitalize;

function Time_Formate_Table(timetxt) {
    var time = timetxt.split('-');

    return time[2] + "/" + time[1] + "/" + time[0];
}
exports.Time_Formate_Table = Time_Formate_Table;


function convertNumberToWords(data, country_code) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    var amount = data;
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    var value = "";


    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }


        if (country_code == "IN") {
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crore ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakh ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }

        }
        else {
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Billion ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Million ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }

        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}
exports.convertNumberToWords = convertNumberToWords;

function speak(textToSpeak) {
    // if ('speechSynthesis' in window) {
    //   var synthesis = window.speechSynthesis;
    //   var utterance = new SpeechSynthesisUtterance(textToSpeak);
    //   synthesis.speak(utterance);
    // } else {

    //     console.log('Text-to-speech is not supported in your browser. Please try a different browser.');
    // }
   
    if ('speechSynthesis' in window) {
        var synthesis = window.speechSynthesis;

        // Function to set the female voice
        function setFemaleVoice() {
            // Get the list of available voices
            var voices = synthesis.getVoices();

            // Find a female voice
            var femaleVoice = voices.find(function (voice) {
                return voice.name.toLowerCase().includes('female');
                // return voice.name.toLowerCase().includes('indian') || voice.name.toLowerCase().includes('tamil');
            });

            // Create a SpeechSynthesisUtterance with the chosen voice
            var utterance = new SpeechSynthesisUtterance(textToSpeak);
            utterance.voice = femaleVoice;

            synthesis.speak(utterance);
        }

        // Check if voices are already available
        if (synthesis.getVoices().length !== 0) {
            setFemaleVoice();
        } else {
            // Wait for the 'voiceschanged' event to ensure voices are available
            synthesis.onvoiceschanged = setFemaleVoice;
        }
    } else {
        alert('Text-to-speech is not supported in your browser. Please try a different browser.');
    }

}
exports.speak = speak;



//////////////////////////////frontdesk
// async function requestDevice_frontdesk1(patient_details_json) {
//    this.requestDevice_frontdesk(patient_details_json)
// }
// async function requestDevice_frontdesk(patient_details_json) {
//     try {
//         const device = await navigator.usb.requestDevice({ filters: [] });
//         const elem = document.querySelector('#device');
//         elem.textContent = `Print with '${device.productName}'`;
//         console.log("check print connection"+elem.textContent)
//         elem.onclick = () => testPrintfrontdesk(device, patient_details_json);
//     } catch (e) {
//         console.error(e);
//     }
// }

// exports.requestDevice_frontdesk = requestDevice_frontdesk;
// async function testPrintfrontdesk(device, patient_details_json) {


//     await device.open();
//     await device.selectConfiguration(1);
//     await device.claimInterface(0);
//     await device.transferOut(
//         device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
//         new Uint8Array(

//             await summ_returnn(patient_details_json)
//         ),
//     );
//     await device.close();
// }
// exports.testPrintfrontdesk = testPrintfrontdesk;




// let encoder = new EscPosEncoder({

//     wordWrap: true,
//     size: 'small'
// });

// var keo = ['drug_disp_name', "batch_no", "rack_id", "bin_id", "quantity"];

// function summ_returnn(patient_details_json) {
//     let result = encoder
//         .initialize()
//         .text([patient_details_json['hospital_name']])
//         .newline()
//         .table(
//             [
//                 { width: 36, align: 'left' },
//                 { width: 36, align: 'left' },
//                 // { width: 15, align: 'left' },
//                 // { width: 20, align: 'left' },
//             ],
//             [
//                 // ['', "","",""],
//                 [ patient_details_json['name'] ? data['name'] : "",data['clntAge'] ? data['clntAge'] : "",],
//                 // ['Name :', data['patient_name'] ? data['patient_name'] : "", 'Doctor :', data['doc_name'] ? data['doc_name'] : ""],
//             ],
//         )
//         .barcode([patient_details_json['mrNumber']], 'ean13', 60)
//         .cut("full")
//         .encode()

//         // <ESC>!R
//         // SIZE 48 mm,25 mm
//         // CLS
//         // TEXT 10,10,"4",0,1,1,"HackerNoon"
//         // BARCODE 10,60,"128",90,1,0,2,2,"altospos.com"
//         // PRINT 1
//         // END


        


//     return result
// }
// exports.summ_returnn = summ_returnn

// async function med_table_data(row_data) {
//     var med_row_data = [["Medicine Name", "Batch No", "Rack - Bin", "Qty"],]
//     row_data.forEach(function (medicine_data) {
//         med_row_data.push(['', "", "", ""])
//         med_row_data.push(
//             [medicine_data['drug_disp_name'], medicine_data['batch_no']
//                 , medicine_data['rack_id'] + "-" + medicine_data['bin_id'],
//             medicine_data['quantity'] + ""])


//     })
   
//     med_row_data.push(['', "", "", ""])
//     med_row_data.push(['', "", "", ""])
//     return med_row_data

// }
// exports.med_table_data = med_table_data




// async function return_table(data) {
//     // Calculate column width dynamically
//     const numColumns = 4; // Number of columns
//     const totalWidth = 80; // Total width available for the table
//     const columnWidth = totalWidth / numColumns;

//     let medicine_row_Data = await med_table_data(data['drugList'])

//     let result = encoder

//         .table(
//             [
//                 { width: 17, align: 'left' },
//                 { width: 18, align: 'left' },
//                 { width: 15, align: 'left' },
//                 { width: 20, align: 'left' },
//             ],
//             [
//                 ['', "","",""],
//                 ['Name :', data['patient_name'] ? data['patient_name'] : "", 'Doctor :', data['doc_name'] ? data['doc_name'] : ""],
//                 // ['', "","",""],
//                 ['Age/Gen :', data['ageandgen'] ? data['ageandgen'] : "", 'Bill type :', data['PurchaseType'] ? data['PurchaseType'] : ""],
//                 // ['', "","",""],
//                 // ['Gender :', data['gender'] ? data['gender'] : "", 'Bill date :', data['order_date'] ? data['order_date'] : ""],
//                 // ['', "","",""],
//                 ['Token no :', data['tokenno'] ? data['tokenno'] : "", 'MR No :', data['mrno'] ? data['mrno'] : ""],
//                 // ['', "","",""],
//                 ['Time/date :', data['time_date'] ? data['time_date'] : "", "", ""]
//             ]
//         )

//         .newline()
//         .newline()
//         .rule({ style: 'single' })       
//         .initialize() // Initialize printer
//         // .textStyle({ size: 'normal', bold: false, underline: false }) // Set text style (normal size, not bold, no underline)
//         .table(
//             [
//                 { width: 23, align: 'left', },
//                 { width: 12, align: 'right' },
//                 { width: 15, align: 'right', },
//                 { width: 10, align: 'right' },
//             ],
//             medicine_row_Data

//         )
//         .newline()
//         .rule({ style: 'single' }) 
//         .newline()
//         .cut("full")
//         .newline()
       
//         .table(
//             [
//                 { width: 25, marginRight: 2, align: 'left' },
//                 { width: 25, align: 'right' }
//             ],
//             [
//                 ['Name :', data['patient_name'] ? data['patient_name'] : ""],
//                 // ['', ""],
//                 ['Token no', data['tokenno'] ? data['tokenno'] : ""],
//                 // ['', ""],
//                 ['MR No', data['mrno'] ? data['mrno'] : ""],
//                 // ['', ""],
//                 ['Estimates Amount', data['total']],
//                 ['', ""],
//                 ['', ""],
//             ]
//         )
        
//         .newline()
//         .newline()
//         .newline()
//         .cut("full")
//         .encode()

//     return result
// }


// exports.return_table = return_table;

// async function requestDevice(patient_details_json) {
//     try {
//         const device = await navigator.usb.requestDevice({ filters: [] });
//         const elem = document.querySelector('#device');
//         elem.textContent = `Print with '${device.productName}'`;
//         elem.onclick = () => testPrint(device, patient_details_json);
//     } catch (e) {
//         console.error(e);
//     }
// }

// exports.requestDevice = requestDevice;


// async function testPrint(device, patient_details_json) {


//     await device.open();
//     await device.selectConfiguration(1);
//     await device.claimInterface(0);
//     await device.transferOut(
//         device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
//         new Uint8Array(

//             await return_table(patient_details_json)
//         ),
//     );
//     await device.close();
// }
// exports.testPrint = testPrint
//pharmacy thermal print
let encoder = new EscPosEncoder({

    wordWrap: true,
    size: 'small'
});

var keo = ['drug_disp_name', "batch_no", "rack_id", "bin_id", "quantity"];

function summ_returnn() {
    let result = encoder
        .initialize()
        .text('The quick brown fox jumps over the lazy dog')
        .newline()
        .qrcode('https://nielsleenheer.com')
        .encode()

    return result
}
exports.summ_returnn = summ_returnn

async function med_table_data(row_data) {
    var med_row_data=[];
    // var med_row_data = [["Medicine Name", "Batch No", "Rack - Bin", "Qty"],]
    row_data.forEach(function (medicine_data) {
        med_row_data.push(['', "", "", ""])
        med_row_data.push(
            [medicine_data['drug_disp_name'], medicine_data['batch_no']
                , medicine_data['rack_id'] + "-" + medicine_data['bin_id'],
            medicine_data['quantity'] + ""])
    })
    med_row_data.push(['', "", "", ""])
    med_row_data.push(['', "", "", ""])
    return med_row_data

}
exports.med_table_data = med_table_data

async function return_table(data) {
    // Calculate column width dynamically
    const numColumns = 4; // Number of columns
    const totalWidth = 80; // Total width available for the table
    const columnWidth = totalWidth / numColumns;

    let medicine_row_Data = await med_table_data(data['drugList'])
    let result = encoder
    .table(
            [
                { width: 7, align: 'left' },
                {width:2, align: 'left' },
                { width: 24, align: 'left' },
                { width: 7, align: 'left' },
                {width:2, align: 'left' },
                { width: 25, align: 'left' }],
            [     
                
                ['Name',':',data['patientnamePlusal'] ? data['patientnamePlusal'] : ""],
                
                  ['Age/Gen',':', data['ageandgen'] ? data['ageandgen'] : "",'Token',':', data['tokenno'] ? data['tokenno'] : ""],
                 
                  ['MR No',':', data['mrno'] ? data['mrno'] : "",'Date',':', data['time_date'] ? data['time_date'] : "", "", ""],
                
                ['Doctor',':',data['docnamePlusal'] ? data['docnamePlusal'] : ""],
            ]
        )
        .newline()
        .rule({ style: 'single',width: 65})
       
        .initialize() // Initialize printer
       
       .table([
        { width: 23, align: 'left', },
        { width: 12, align: 'right' },
        { width: 15, align: 'right', },
        { width: 10, align: 'right' },
       ],
       [["Medicine Name","Batch No","Rack - Bin","Qty"]]
        )
        .rule({ style: 'single',width: 65})
        .table(
            [
                { width: 23, align: 'left', },
                { width: 12, align: 'right' },
                { width: 15, align: 'right', },
                { width: 10, align: 'right' },
            ],
            medicine_row_Data
        )
        .newline()
        .newline()
        .newline()
           .cut("full")
        .table(
            [
                { width: 25, marginRight: 2, align: 'left' },
                { width: 25, align: 'right' }
            ],
            [
                ['Name :', data['patient_name'] ? data['patient_name'] : ""],
                ['Token no', data['tokenno'] ? data['tokenno'] : ""],
                ['MR No', data['mrno'] ? data['mrno'] : ""],
                ['Estimates Amount', data['total']],


                ['', ""],
                ['', ""],
            ]
        )
        .newline()
         .newline()
          .cut("full")
        .encode()
    return result
}
exports.return_table = return_table;


async function checkdevice(){

}
exports.checkdevice = checkdevice


async function requestDevice(patient_details_json) {
    try {
        const device = await navigator.usb.requestDevice({filters: [] });
       
        Helper_Class.setthermalprint(device)
        testPrint(device, patient_details_json);
      
    } catch (e) {
        console.error(e);
    }
}

exports.requestDevice = requestDevice;

async function testPrint(device, patient_details_json) {

    console.log("DEVICE 4= "+JSON.stringify(device))
    console.log("DEVICE 6 = "+device)
    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);

    await device.transferOut(
        device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
        new Uint8Array(
            await return_table(patient_details_json)
        ),
    );

    console.log("DEVICE 9 = "+JSON.stringify(device))        
    console.log("DEVICE 8 = "+device)

    await device.close();
}
exports.testPrint = testPrint
