<div class="container">
     <div class="row" style="margin-right: auto;margin-left: auto;width:90%;margin-top:15px">   
    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-5 select_bottom" style="position:relative;right:5px">
        <mat-label class="matlabel"> Racks <br>
          <select [(ngModel)]="racknames" class="ipcss" >
            <!-- <option value="All">All</option> -->
            <option *ngFor="let data of rackslist" value="{{data.rackid}}">{{data.rackname}}</option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
        <mat-label class="matlabel"><br>
            <!-- <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
              (click)="getSalesData()" /> -->
              <img src="../../../assets/img/button_icon/save.png" class="saveimgbtn_inpatinfo"
              (click)="sendbinandracks()" />
              <!-- D:\development\svn\TervysWeb\src\assets\img\button_icon\save.png -->
          </mat-label>  
    </div>
      </div>
      
      <div class="container" style="width:90%;margin-top:15px;border:1px solid gainsboro;;height:70vh;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;border-radius: 5px;">
      <div class="row">

      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2" *ngFor="let item of binslist" style="padding:1%;margin-left:10px">
        <label>
          <input
            type="checkbox"
            value="{{item.binid}}"
            (change)="onCheckboxChange(item.binid)"

            />
            <!-- [checked]="selectedBins.includes(item.binid)" -->
          {{item.binname}}
        </label>
      </div>
      
    </div>
    </div>
    
    </div>
