import { Component, OnInit, HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import { getIp, Ip_with_img_address } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { DentalService, TreatmentService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';

@Component({
  selector: 'app-dental-spl-nata-hist-pane',
  templateUrl: './dental-spl-nata-hist-pane.component.html',
  styleUrls: ['./dental-spl-nata-hist-pane.component.css']
})
export class DentalSplNataHistPaneComponent implements OnInit {
  public famil_mal_occ;
  public gen_hist;
  public la = [];
  public Get_client_view_app_data;
  public get_spl_name;
  public tons_hidden: boolean;
  public his_tonsil_ande;
  public diet_hist_hidden: boolean;
  public hidden_pedia1: boolean;
  public narr_txt;
  public history_txt: boolean;
  public type_dev;
  public infect_dis_mot;
  public health_mot;
  public drug_taken_mot;
  public birth_weight_txt
  public rh_comp_dis_txt;
  public fing_txt;
  public nail_txt;
  public tong_thru_txt;
  public dummy_txt;
  public brux_txt;
  public inju_txt;
  public ceph_ind_txt;
  public facial_index_txt;
  public last_vit_hidden: boolean;
  public sitting_txt;
  public craw_txt;
  public stand_txt; public post_txt: boolean;
  public rh_hidden: boolean;
  public Caries_Div;
  public dey_hidden;
  public parent_mal_hist;
  public birth_hidden: boolean;
  public natalTab: boolean;
  public health_hidden: boolean;
  public drug_mot_hidden: boolean;
  public gst_hidden: boolean;
  public dis_mot_hidden: boolean;
  public Natal_Hist: boolean;
  public walk_txt;
  public panelOpenState13: boolean;
  public run_txt;
  public phon_txt;
  public gest_period;
  public nat_delivery_typ_req: boolean;
  public nat_mot_health_req: boolean;
  public nat_sit_req: boolean;
  public nat_craw_req: boolean;
  public nat_stand_req: boolean;
  public nat_walk_req: boolean;
  public nat_run_req: boolean;
  public nat_phon_req: boolean;
  public hidden_pedia: boolean;
  public subscription: Subscription;
  public send_spl_natal_hist;
  public client:boolean = false;
  constructor(public http: Http, public toastr: ToastrService, public messageservice: TreatmentService, public dentalservice: DentalService) {

  }
  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();

  }
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    this.send_data();
  }
  ngOnInit(): void {
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
        // var data=message.spl
        //this.get_spl_name=message.spl;
        Doc_Helper.Set_dent_spl(message.spl);
        this.speciality_change(message.spl);
        //this.send_data(data);
      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    this.natal_change();
  }


  natal_change() {
    var get_spl_name = Doc_Helper.get_dental_spl_name()
    if (get_spl_name == "Orthodontics") {
      if (this.narr_txt != undefined || this.type_dev != undefined || this.infect_dis_mot != undefined || this.gest_period != undefined || (this.health_mot != undefined && this.health_mot.length != 0) ||
        (this.drug_taken_mot != undefined && this.drug_taken_mot.length != 0) ||
        (this.birth_weight_txt != undefined && this.birth_weight_txt.length != 0) || (this.rh_comp_dis_txt != undefined && this.rh_comp_dis_txt.length != 0) ||
        (this.sitting_txt != undefined && this.sitting_txt.length != 0) || (this.craw_txt != undefined && this.craw_txt.length != 0) ||
        (this.stand_txt != undefined && this.stand_txt.length != 0) || (this.walk_txt != undefined && this.walk_txt.length != 0) ||
        (this.run_txt != undefined && this.run_txt.length != 0) || (this.phon_txt != undefined && this.phon_txt.length != 0) ||
        (this.fing_txt != undefined && this.fing_txt.length != 0) || (this.nail_txt != undefined && this.nail_txt.length != 0) ||
        (this.dummy_txt != undefined && this.dummy_txt.length != 0) || (this.brux_txt != undefined && this.brux_txt.length != 0) ||
        (this.tong_thru_txt != undefined && this.tong_thru_txt.length != 0) || (this.inju_txt != undefined && this.inju_txt.length != 0) || (this.his_tonsil_ande != undefined && this.his_tonsil_ande.length != 0) ||
        (this.famil_mal_occ != undefined && this.famil_mal_occ.length != 0) || this.parent_mal_hist != undefined) {

        this.nat_delivery_typ_req = true;
        this.nat_mot_health_req = true;
        this.nat_sit_req = true;
        this.nat_craw_req = true;
        this.nat_stand_req = true;
        this.nat_walk_req = true;
        this.nat_run_req = true;
        this.nat_phon_req = true;
      }
      else {
        this.nat_delivery_typ_req = false;
        this.nat_mot_health_req = false;
        this.nat_sit_req = false;
        this.nat_craw_req = false;
        this.nat_stand_req = false;
        this.nat_walk_req = false;
        this.nat_run_req = false;
        this.nat_phon_req = false;
      }

    }
    if (get_spl_name == "Pediatrics") {
      if (this.narr_txt != undefined || this.type_dev != undefined || this.infect_dis_mot != undefined || this.gest_period != undefined || (this.health_mot != undefined && this.health_mot.length != 0) ||
        (this.drug_taken_mot != undefined && this.drug_taken_mot.length != 0)) {
        this.nat_delivery_typ_req = true;
        this.nat_mot_health_req = true;
      }
      else {
        this.nat_delivery_typ_req = false;
        this.nat_mot_health_req = false
      }
    }
    this.RetrivalFunction();
  }

  speciality_change(e) {
    this.get_spl_name = "";
    this.get_spl_name = e;
    if (this.get_spl_name == "General") {

    }
    else if (this.get_spl_name == "Periodontics") {

    }
    else if (this.get_spl_name == "Orthodontics") {
      this.natalTab = false;
      this.dey_hidden = false;
      this.dis_mot_hidden = false;
      this.gst_hidden = false;
      this.health_hidden = false;
      this.drug_mot_hidden = false;
      this.Natal_Hist = false;
      this.birth_hidden = false;
      this.last_vit_hidden = false;
      this.rh_hidden = false;
      this.post_txt = false;
      this.hidden_pedia = true;
      this.history_txt = false;
      this.tons_hidden = false;





    }
     else if (this.get_spl_name == "Pediatrics") {
      this.Natal_Hist = true;
      this.birth_hidden = true;
      this.rh_hidden = true;
      this.post_txt = true;
      this.natalTab = false;
      this.tons_hidden = true;
      this.diet_hist_hidden = true;
      this.hidden_pedia = true;
      this.dey_hidden = false;
      this.dis_mot_hidden = false;
      this.gst_hidden = false;
      this.health_hidden = false;
      this.drug_mot_hidden = false;
      this.history_txt = true;
      this.Get_client_view_app_data = Helper_Class.Get_client_view_app_data();

      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) <= 6) {
          this.post_txt = false;

        }
        else {
          this.post_txt = true;
        }
      }
      else {
        this.post_txt = false;
      }


    }
    else if (this.get_spl_name == "Prosthodontics") {

      this.Caries_Div = false;
      this.post_txt = true;





    }
    else if (this.get_spl_name == "Public health"){
        this.post_txt=false;
        this.Natal_Hist=true;
        this.birth_hidden=true;
        this.history_txt=true;
        this.tons_hidden=true;

    }
  }
  FilterFields() {
    var getret = Helper_Class.getHopitalfieldsRet();
    var dental_spl = Doc_Helper.get_dental_spl_name()
    if (getret != undefined) {
      for (var i = 0; i < getret.length; i++) {
        if (getret[i].page_name == "Speciality") {
          if (getret[i].fields != undefined) {

            if (getret[i].fields != undefined) {
              for (var j = 0; j < getret[i].fields.length; j++) {
                if (getret[i].fields[j].field_name == "Gait") {

                  //this.gait_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Type of delivery") {
                  this.dey_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Infectious disease of mother") {
                  this.dis_mot_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Gestation period") {
                  this.gst_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Health Of mother") {
                  this.health_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Drug taken by mother") {
                  this.drug_mot_hidden = false;
                }

                if (getret[i].fields[j].field_name == "Birth weight") {
                  this.birth_hidden = false;
                }
                if (getret[i].fields[j].field_name == "Rh compatibility") {
                  this.rh_hidden = false;
                }

                // if (getret[i].fields[j].field_name == "Grade scale") {
                //   this.mal_hist_hidden = false;

              }
            }
          }
        }
      }
    }
  }
  getColor(v) {
    if (v == "Yes") {
      return "green";
    }
    if (v = "No") {
      return "red";
    }
  }


  RetrivalFunction() {
    // retival
     // retival
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
       // var data = message.spl
        this.speciality_change( message.spl);
        Doc_Helper.Set_dent_spl(message.spl)
        

      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });

    // this.get_spl_name = this.messageservice.getMessage();

    var getret1 = Helper_Class.Get_den_ret1()
    var getret2 = Helper_Class.Get_den_ret2()
    if (getret2 != undefined) {
      // this.loader = true;
      if (getret2.delivery_mtd != null) {
        this.gest_period = getret2.delivery_mtd;
      }

      if (getret2.type != null && getret2.type != "") {
        this.type_dev = getret2.type;
      }
      if (getret2.delivery_mtd != null) {
        this.gest_period = getret2.delivery_mtd;
      }

      if (getret2.sitting != null) {
        this.sitting_txt = getret2.sitting;
      }
      if (getret2.crawling != null) {
        this.craw_txt = getret2.crawling;
      }
      if (getret2.standing != null) {
        this.stand_txt = getret2.standing;
      }
      if (getret2.walking != null) {
        this.walk_txt = getret2.walking;
      }
      if (getret2.running != null) {
        this.run_txt = getret2.running;
      }
      if (getret2.phonation != null) {
        this.phon_txt = getret2.phonation;
      }
      if (getret2.familial_malocclusion_his != null) {
        this.famil_mal_occ = getret2.familial_malocclusion_his;
      }
      if (getret2.parents_malocclusion_type != null) {
        this.parent_mal_hist = getret2.parents_malocclusion_type;
      }
      if (getret2.his_tonsill_adenoid != null) {
        this.his_tonsil_ande = getret2.his_tonsill_adenoid;
      }
      if (getret2.narrator != null && getret2.narrator != "") {
        this.narr_txt = getret2.narrator;
      }
      if (getret2.infe_moth_preg != null && getret2.infe_moth_preg != "") {
        this.infect_dis_mot = getret2.infe_moth_preg;
      }
      if (getret2.health_moth_preg != null) {
        this.health_mot = getret2.health_moth_preg;
      }

      if (getret2.drug_moth_preg != null) {
        this.drug_taken_mot = getret2.drug_moth_preg;
      }
      if (getret2.birth_weight != null) {
        this.birth_weight_txt = getret2.birth_weight;
      }
      if (getret2.rh_comp_dis != null) {
        this.rh_comp_dis_txt = getret2.rh_comp_dis;
      }
      if (getret2.fing_thumb_stick != null) {
        this.fing_txt = getret2.fing_thumb_stick;
      }
      if (getret2.nail_lip_biting != null) {
        this.nail_txt = getret2.nail_lip_biting;
      }
      if (getret2.tongue_bitting_thrust != null) {
        this.tong_thru_txt = getret2.tongue_bitting_thrust;
      }
      if (getret2.dummy_suck != null) {
        this.dummy_txt = getret2.dummy_suck;
      }
      if (getret2.bruxium != null) {
        this.brux_txt = getret2.bruxium;
      }
      if (getret2.injuries != null) {
        this.inju_txt = getret2.injuries;
      }
      if (getret2.cephalic_index != null) {
        this.ceph_ind_txt = getret2.cephalic_index;
      }
      if (getret2.facial_index != null) {
        this.facial_index_txt = getret2.facial_index;
      }
      if (getret2.drug_moth_preg != null) {
        this.drug_taken_mot = getret2.drug_moth_preg;
      }
      if (getret2.birth_weight != null) {
        this.birth_weight_txt = getret2.birth_weight;
      }
      if (getret2.rh_comp_dis != null) {
        this.rh_comp_dis_txt = getret2.rh_comp_dis;
      }
      if (getret2.fing_thumb_stick != null) {
        this.fing_txt = getret2.fing_thumb_stick;
      }
      if (getret2.nail_lip_biting != null) {
        this.nail_txt = getret2.nail_lip_biting;
      }
      if (getret2.tongue_bitting_thrust != null) {
        this.tong_thru_txt = getret2.tongue_bitting_thrust;
      }
      if (getret2.dummy_suck != null) {
        this.dummy_txt = getret2.dummy_suck;
      }
      if (getret2.bruxium != null) {
        this.brux_txt = getret2.bruxium;
      }
      if (getret2.injuries != null) {
        this.inju_txt = getret2.injuries;
      }
      if (getret2.cephalic_index != null) {
        this.ceph_ind_txt = getret2.cephalic_index;
      }
      if (getret2.facial_index != null) {
        this.facial_index_txt = getret2.facial_index;
      }



    }
  }


  send_data() {
    Doc_Helper.Set_den_spl_natal(null);
    this.get_spl_name = Doc_Helper.get_dent_spl();
    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";
    // general history spl
    //var this.gen_hist =null;

    if (this.get_spl_name == "General") {
      var intr_flg = "no"
    }
    else if (this.get_spl_name == "Periodontics") {
      var super_tx = "no", med_flg = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";
      this.gen_hist ={
        // den_was_flag: denwa_flg,
        // int_oral_flag: intr_flg,
        // // supra_flag: super_tx,
        //tee_exam_pub_flag: teet_exampubflg,
        // teeth_exam_flag: texam_flg,
        // gingiva_flag: ging_flg,
        //gin_exam_flag: gin_exfig,
        //  overbite_flag: over_flg,
        // medi_hist_dis_flag: med_flg,
      }
    }
    else if (this.get_spl_name == "Orthodontics") {


      if (this.fing_txt != undefined || this.nail_txt != undefined || this.dummy_txt != undefined ||
        this.tong_thru_txt != undefined || this.inju_txt != undefined || this.brux_txt != undefined) {
        hab_txt = "yes";
      }
      if (this.health_mot == "") {
        this.health_mot = undefined;
      }

      if (this.drug_taken_mot == "") {
        this.drug_taken_mot = undefined;
      }



      var hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var resp_flgtxt = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      if (this.narr_txt != undefined || this.infect_dis_mot != undefined || this.health_mot != undefined ||
        this.type_dev != undefined) {
        pre_txt = "yes";
      }
      if (this.sitting_txt != undefined || this.craw_txt != undefined || this.stand_txt != undefined || this.walk_txt != undefined ||
        this.run_txt != undefined || this.phon_txt != undefined) {
        post_ex = "yes"
      }
      if (this.fing_txt != undefined || this.nail_txt != undefined || this.dummy_txt != undefined ||
        this.tong_thru_txt != undefined || this.inju_txt != undefined || this.brux_txt != undefined) {
        hab_txt = "yes";
      }
      var maxmand_arrylist = [{
        den_position_id: "1",
      },
      {
        den_position_id: "2",
      }];
      var ante_poster_arry = [{
        den_position_id: "5",
      },
      {
        den_position_id: "6",
      }];

      if (ante_poster_arry.length != 0) {
        var den_anflag = "Yes"
      }
      else {
        den_anflag = "No"
      }
      var ceph_ind_txtdata = this.ceph_ind_txt;
      var facial_index_txtdata = this.facial_index_txt;
      //var dietsession_txtdata = this.dietsession_txt;

      if (this.health_mot == "") {
        this.health_mot = undefined;
      }

      if (this.drug_taken_mot == "") {
        this.drug_taken_mot = undefined;
      }

      this.gen_hist ={
        overbite_flag: over_biteflg,
        //bite_condition_flag: bite_conflg,
        resp_flag: resp_flgtxt,
        habit_exists: hab_txt,
        post_exists: post_ex,
        pre_exists: pre_txt,
        //   fam_exists: fam_flg,
        int_oral_flag: intr_flg,
        oral_hygeine_flag: oral_falg,
        //  birth_exists: birth_flag,
        physical_status_flag: physical_status_flag,
        cephalic_index: ceph_ind_txtdata,
        facial_index: facial_index_txtdata,
        gnathic: maxmand_arrylist,
        den_ant_post: ante_poster_arry,
        den_ant_post_flag: den_anflag,
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,
        ortho_treat: ortho_treat,
        //natal
        narrator: this.narr_txt,
        type: this.type_dev,
        infe_moth_preg: this.infect_dis_mot,
        gestation_peroid: this.gest_period,
        delivery_mtd: this.gest_period,
        health_moth_preg: this.health_mot,
        drug_moth_preg: this.drug_taken_mot,
        birth_weight: this.birth_weight_txt,
        rh_comp_dis: this.rh_comp_dis_txt,
        sitting: this.sitting_txt,
        crawling: this.craw_txt,
        standing: this.stand_txt,
        walking: this.walk_txt,
        running: this.run_txt,
        phonation: this.phon_txt,
        thumb_stick: this.fing_txt,
        nail_biting: this.nail_txt,
        dummy_suck: this.dummy_txt,
        bruxium: this.brux_txt,
        tongue_bitting: this.tong_thru_txt,
        injuries: this.inju_txt,
        his_tonsill_adenoid: this.his_tonsil_ande,
        familial_maloc_his: this.famil_mal_occ,
        parents_maloc_type: this.parent_mal_hist,
      }
    }

    else if (this.get_spl_name == "Pediatrics") {
      var ped_flag = "no", diet_nalsis_flg = "no", ped_histflg = "no", gen_flag = "no", case_pedflg = "no", condition_txt = "", descrption_data = "", freq_data = "", inten_data = "", dure_dataoral = "", age_what = "", cond_suck = "";
      var over_biteflg = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", ped_histflg = "no", soft_flag = "no", oral_habit = "Yes", type_habit_id = 0, child_went_home = "no";
      if (this.narr_txt != undefined || this.infect_dis_mot != undefined || this.health_mot != undefined ||
        this.type_dev != undefined) {
        pre_txt = "yes";
      }
      if (this.sitting_txt != undefined || this.craw_txt != undefined || this.stand_txt != undefined || this.walk_txt != undefined ||
        this.run_txt != undefined || this.phon_txt != undefined) {
        post_ex = "yes"
      }
      if (this.fing_txt != undefined || this.nail_txt != undefined || this.dummy_txt != undefined ||
        this.tong_thru_txt != undefined || this.inju_txt != undefined) {
        hab_txt = "yes";
      }
      var intr_flg = "no"
      this.gen_hist ={
        habit_exists: hab_txt,
        post_exists: post_ex,
        narrator: this.narr_txt,
        type: this.type_dev,
        infe_moth_preg: this.infect_dis_mot,
        gestation_peroid: this.gest_period,
        delivery_mtd: this.gest_period,
        health_moth_preg: this.health_mot,
        drug_moth_preg: this.drug_taken_mot,
        sitting: this.sitting_txt,
        crawling: this.craw_txt,
        standing: this.stand_txt,
        walking: this.walk_txt,
        running: this.run_txt,
        phonation: this.phon_txt,
        thumb_stick: this.fing_txt,
        nail_biting: this.nail_txt,
        dummy_suck: this.dummy_txt,
        bruxium: this.brux_txt,
        tongue_bitting: this.tong_thru_txt,
        injuries: this.inju_txt,
        his_tonsill_adenoid: this.his_tonsil_ande,
        familial_maloc_his: this.famil_mal_occ,
        parents_maloc_type: this.parent_mal_hist,

      }
    }
    else if (this.get_spl_name == "Prosthodontics") {
      var resp_flgtxt = "no", oral_falg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no";
    }
    else if (this.get_spl_name == "Public health") {
      var hab_txt = "no", post_ex = "no", diet_nalsis_flg = "no", vital_sign_flag = "no", soft_flag = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";
      if (this.sitting_txt != undefined || this.craw_txt != undefined || this.stand_txt != undefined || this.walk_txt != undefined ||
        this.run_txt != undefined || this.phon_txt != undefined) {
        post_ex = "yes"
      }
      if (this.fing_txt != undefined || this.nail_txt != undefined || this.dummy_txt != undefined ||
        this.tong_thru_txt != undefined || this.inju_txt != undefined) {
        hab_txt = "yes";
      }
      var intr_flg = "no"

      this.gen_hist ={
        int_oral_flag: intr_flg,
        habit_exists: hab_txt,
        post_exists: post_ex,
        diet_analysis_flag: diet_nalsis_flg,
        tee_exam_pub_flag: teet_exampubflg,
        teeth_exam_flag: texam_flg,
        // gingiva_flag: ging_flg,
        //gin_exam_flag: gin_exfig,
        vital_sign_flag: vital_sign_flag,
        //  softisoth_flag: soft_flag,
        diet_flag: diet_flag_arry,
        sitting: this.sitting_txt,
        crawling: this.craw_txt,
        standing: this.stand_txt,
        walking: this.walk_txt,
        running: this.run_txt,
        phonation: this.phon_txt,
        thumb_stick: this.fing_txt,
        nail_biting: this.nail_txt,
        dummy_suck: this.dummy_txt,
        bruxium: this.brux_txt,
        tongue_bitting: this.tong_thru_txt,
        injuries: this.inju_txt,
        his_tonsill_adenoid: this.his_tonsil_ande,
        familial_maloc_his: this.famil_mal_occ,
        parents_maloc_type: this.parent_mal_hist,
      }
    }
    Doc_Helper.Set_den_spl_natal(this.gen_hist);

    
  }

}
