import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session } from '../../../assets/js/common.js';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  public reportPresId;
  public reportType;
  public reportDiagId;
  public reportTestId;
  public reportImgFlag;
  public date: string;
  public diagName: string;
  public diagAddr: string;
  public diagLocation: string;
  public diagCityZipcode: string;
  public diagStateCountry: string;
  public diagTelephoneNo: string;
  public clientAge: string;
  public ageFlag: boolean;
  public reviewSign: string;
  public clientId: string;
  public reviewBy: string;
  public clientName: string;
  public referredBy: string;
  public clientGender: string;
  public timeCollect: string;
  public pathologyTestList = [];
  public pathologySubTestList = [];
  public radiologyTestList = [];
  public sendTestRange = null;
  public reportImg: string;
  public pathologyFlag: boolean;
  public radiologyFlag: boolean;
  public opnId;
  public opnType;
  public opnPresTestId;
  public opnAppId;
  public clientFlag: boolean;
  public docFlag:boolean;
  public diagFlag:boolean;
  public relation: any;
  public getReportDetails;

  constructor(public sanitizer:DomSanitizer,public clientservice:ClientViewService,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
  }

  ngOnInit(): void {
    this.getReportDetails= Client_Helper.getReportDetails();
    this.reportPresId =  this.getReportDetails.pres_test_id;
    this.reportDiagId =  this.getReportDetails.diag_app_id;
    this.reportTestId = this.getReportDetails.pres_test_id;
    this.relation = this.getReportDetails.relation;
    this.reportType = this.getReportDetails.report_type;
    this.getReportDetailView(this.getReportDetails.cat_type);
  }

  back() {
    this.clientservice.sendMessage('recordwallet');
  }

  getReportDetailView(type) {
        var send_json = null, url = null;
        if (type == "normal" ) {
            url = 'record/diagreportview/';
            send_json = JSON.stringify({
                type: this.getReportDetails.report_type,
                flag: "doctor",
                diag_app_id: this.reportDiagId,
                country: "IN",
                pres_diag_id: this.reportPresId,
                pres_test_id: this.reportTestId,
            })
        } else if(type == "diag"){
            url = 'record/diagreportview/';
            send_json = JSON.stringify({
                type: this.reportType,
                flag: "diagnosis",
                diag_app_id: this.reportDiagId,
                country: ipaddress.country_code,
                pres_diag_id: this.reportPresId,
                pres_test_id: this.reportTestId,
            })
        } else {
           // url = "record/diagreportview";
             url = 'secopn/greport/';
            send_json = JSON.stringify({
                type: this.opnType,
                pres_test_id: this.opnPresTestId,
                opinion_id: this.opnId,
                country: ipaddress.country_code,
                app_id: this.reportDiagId
            })
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + url, send_json,
            { headers: headers })
            .subscribe(
                response => {
                    var obj = response.json();
                    if (obj.diag_centre_name != null) {
                        if (obj.date_entered != null) {
                            var datereport = obj.date_entered.split('-');
                            this.date = datereport[2] + "-" + datereport[1] + "-" + datereport[0]
                        }
                        this.diagName = obj.diag_centre_name;
                        if (obj.address2 != null) {
                            this.diagAddr = obj.address1 + " " + obj.address2;
                        } else {
                            this.diagAddr = obj.address1;
                        }
                        this.diagLocation = obj.location;
                        this.diagCityZipcode = obj.city + " - " + obj.zipcode;
                        this.diagStateCountry = obj.state + " - " + obj.country;
                        this.diagTelephoneNo = obj.telephone;
                        if (obj != null) {
                            this.clientAge = obj.age;
                            this.ageFlag = false;
                        } else {
                            this.ageFlag = true;
                        }
                        if (obj.sign != null) {
                            this.reviewSign = "data:image/png;base64," + obj.sign;
                        } else {
                        }

                        if (obj.app_middle_name != null) {
                            if (obj.app_first_name != undefined && obj.app_middle_name != undefined && obj.app_last_name != undefined) {
                                this.reviewBy = obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name;
                            }
                        } else {
                            if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                                this.reviewBy = obj.app_first_name + " " + obj.app_last_name;
                            }
                        }
                        this.clientId = obj.client_reg_id;
                        localStorage.setItem("client_decrypt_first", obj.first_name);
                        localStorage.setItem("client_decrypt_middle", obj.middle_name);
                        localStorage.setItem("client_decrypt_last", obj.last_name);
                        if (obj.middle_name != null) {
                            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                        } else {
                            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                        }
                        if( obj.doc_middle_name != undefined && obj.doc_middle_name != null){
                            this.referredBy = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                        } else{
                            this.referredBy = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                        }
                        this.clientAge = obj.age;
                        this.clientGender = encrypt_decript.Decript(obj.gender);
                        this.timeCollect = Time_Formate_Session(obj.time_collected);
                        this.pathologyTestList = [];
                        this.pathologySubTestList = [];
                        this.radiologyTestList = [];
                        if (this.getReportDetails.report_type == "Urine" || this.getReportDetails.report_type == "Blood" || this.getReportDetails.report_type == "Faeces") {
                            this.pathologyFlag = false;
                            this.radiologyFlag = true;
                            for (var i = 0; i < obj.main_tests.length; i++) {
                                this.pathologyTestList.push({
                                    pres_test_id: obj.main_tests[i].pres_test_id,
                                    test_id: obj.main_tests[i].test_id,
                                    test_name: obj.main_tests[i].test_name,
                                });
                                for (var i = 0; i < obj.main_tests.length; i++) {
                                    for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                        this.sendTestRange = "";
                                        this.sendTestRange = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].subtests[j].range);
                                        this.pathologySubTestList.push({
                                            sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                            reading: obj.main_tests[i].subtests[j].reading,
                                            test_range: this.sendTestRange,
                                            unit: obj.main_tests[i].subtests[j].unit,
                                            subtest_name_txt: obj.main_tests[i].subtests[j].test_name
                                        });
                                    }
                                }
                            }
                        } else {
                            this.pathologyFlag = true;
                            this.radiologyFlag = false;
                            for (var i = 0; i < obj.main_tests.length; i++) {
                                if (obj.main_tests[i].image != null) {
                                    this.reportImg = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                }
                                var sequences:any = "";
                                if(obj.main_tests[i].sequences != undefined){
                                    sequences = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].sequences);
                                }
                                var impression:any = "";
                                if(obj.main_tests[i].impression != undefined){
                                    impression = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].impression);
                                }
                                this.radiologyTestList.push({
                                    pres_test_id: obj.main_tests[i].pres_test_id,
                                    test_name: obj.main_tests[i].test_name,
                                    sequences: sequences,
                                    impression: impression,
                                });
                            }
                        }
                    } else {
                        this.toastr.error(Message_data.network);
                    }
                },
                error => {
                    this.toastr.error(Message_data.network);
                }
            )
  }
}
