import { Component, OnInit } from '@angular/core';
import { Helper_Class } from 'src/app/helper_class';
import { ServerApi } from '../../server-api';
import { ipaddress } from '../../ipaddress';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { DietService } from '../diet-view/diet-view.service';
import { Diet_Helper } from '../Diet_Helper';
import { Date_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diet-patient-list',
  templateUrl: './diet-patient-list.component.html',
  styleUrls: ['./diet-patient-list.component.scss']
})
export class DietPatientListComponent implements OnInit {
  public userInfo;
  public clientName;
  public hptlClinicId;
  public patientList = [];
  public appointmentType;
  public tdate;
  public currentDate;
  public dateFlag;
  public toDate;
  public fromDate;
  public f_date;
  public getFlow:boolean = false;
  constructor(public serviceAPI: ServerApi,public dietservice:MenuViewService,public messageService: MenuViewService,) {
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.userInfo = Helper_Class.getInfo();
    this.appointmentType = "Today";
    console.log("elper_Class.getDiagReadingList()" + JSON.stringify(Helper_Class.getDiagReadingList()))
    this.getFlow =  Helper_Class.getDiagReadingList() == "readings" ? true : false;
    this.getCurrentDate();
  }

  async getCurrentDate() {
    var response = await this.serviceAPI.PostData('adm/curdate', { country: ipaddress.country_code }).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.current_date != null) {
        this.currentDate = obj.current_date;
        this.f_date = this.currentDate;
        this.toDate = Date_Formate(this.currentDate);
        this.clientName = "all";
        this.changeAppointmentType();
        this.getClientDetails();
      } 
    }
  }
  
  async getClientDetails() {
    this.clientName = this.clientName.toUpperCase();
    var name = this.clientName.length >= 3 ? this.clientName : "all";
      var sendData = {
        hptl_clinic_id: this.hptlClinicId,
        first_name : name,
        from_date : this.f_date,
        to_date : this.tdate,
        type: "dietician",
        diab_flag : this.getFlow,
      }
      var response = await this.serviceAPI.PostData('appointment/gcdbd', sendData).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        this.patientList = [];
        if(obj.client_details != undefined){
            obj = obj.client_details;
            for(var i = 0; i < obj.length; i++){
              var name;
              if( obj[i].middle_name != "" &&  obj[i].middle_name != undefined){
                  name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
              }else{
                if(obj[i].last_name != "" &&  obj[i].last_name != undefined){
                  name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                }else{
                  name = encrypt_decript.Decript(obj[i].first_name);
                }
              }
                this.patientList.push({
                    name : name,
                    profileImage : ipaddress.Ip_with_img_address + obj[i].profile_image,
                    lastVisit : Date_Formate(obj[i].last_visit),
                    client_reg_id  : obj[i].client_reg_id,
                    relation_id  : obj[i].relation_id,
                    sub_id  : obj[i].sub_id,
                    life_style_id: obj[i].life_style_id,
                })
            }
        }
      }
  }

  selectClient(list) {
    var relationlist = null;
    relationlist = {
      name : list.name,
      Client_id : list.client_reg_id,
      rel_id: list.relation_id,
      sub_id: list.sub_id,
    }
    if( Helper_Class.getDiagReadingList() == "readings" ){
      Helper_Class.setDiabReadingsLifeStyle(list.life_style_id)
      this.messageService.sendMessage("diabReadings"); 
    }else{
      Diet_Helper.setClientSendData(null);
      Diet_Helper.setClientSendData(relationlist);
      this.messageService.sendMessage("patientTracking");
    }
  }

  changeAppointmentType() {
    var e;
    console.log("this.appointment_type" + this.appointmentType)
    this.tdate = this.currentDate;
    this.dateFlag = false;
    if (this.appointmentType == "Today") {
      e = this.currentDate;
      this.f_date = e;
    } else if (this.appointmentType == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 30)
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() - 92)
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 183)
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 365)
      e = this.getDateFormate(d);
      this.f_date = e;
    } else if (this.appointmentType == "Date"){
      this.dateFlag = true;
    }
    
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  selectToDate(e) {
    this.toDate = e;
    this.tdate = Date_Formate(e);
  }
  
  selectFromDate(e) {
    this.fromDate = e;
    this.f_date = Date_Formate(e);
  }
}
