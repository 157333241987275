<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{Titile}}</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin: 7px;">Location<br>
              <input type="text" class="ipcss widthappt" required maxlength="50" [(ngModel)]="locationName"
                (keyup)="followFilter($event)" matInput [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option (click)="selectLocation(location)" *ngFor="let location of locationList"
                  [value]="location">
                  {{location}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin: 7px;">Search City<br>
              <mat-select required class="ipcss widthappt" [(ngModel)]="filtCity" disableOptionCentering>
                <mat-option (click)="cityChange(city.city_id)" *ngFor="let city of cityList"
                  value={{city.city_id}}>
                  {{city.city_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin: 7px;">Search State<br>
              <mat-select required class="ipcss widthappt" [(ngModel)]="filtState" disableOptionCentering>
                <mat-option *ngFor="let state of stateList" value={{state.state_id}}
                  (click)="stateChange(state.state_id)">{{state.state_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin: 7px;">Search Country<br>
              <mat-select required class="ipcss widthappt" [(ngModel)]="filtCountry" disableOptionCentering>
                <mat-option *ngFor="let country of countryList" value={{country.country_desc}}>{{country.country_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            *ngIf="pharmaDetails.length == 0 && diagList.length == 0">
            <div class="nodata">No {{Titile}} found</div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            *ngIf="pharmaFlag && pharmaDetails.length!=0">
            <div *ngFor="let pharma of pharmaDetails">
              <mat-card id="cardcontent">
                <mat-card-content>
                  <div class="block1">
                    <div class="block1_grid">
                      <p>
                        <b>{{pharma.pharmacy_name}}</b>
                      </p>
                      <p>{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</p>
                      <p>{{pharma.phar_loc}}</p>
                      <p>{{pharma.phar_city}} - {{pharma.phar_zipcode}}</p>
                      <p>{{pharma.phar_state}}, {{pharma.phar_cnty}}</p>
                      <p>{{pharma.telephone}}</p>
                      <p>Working days : {{pharma.phar_word_desc}}</p>
                      <p>{{pharma.phar_work_time}}</p>
                      <p>Store pickup validity : {{pharma.phar_valid}}</p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <div *ngIf="pharma.is_del_option">
                <b>Delivery options</b>
                <br />
                <img src="../../../assets/ui_icons/buttons/Delivery_button.svg" class="saveimgbtn_doctreg"
                  *ngIf="pharma.is_home_delivery"
                  (click)="homeDel(pharma.pharma_id,pharma.pharmacy_name,pharma.phar_addr1,pharma.phar_addr2,pharma.phar_loc,pharma.phar_city,pharma.phar_zipcode,pharma.phar_state,pharma.phar_cnty,pharma.telephone)">
                <img src="../../../assets/ui_icons/buttons/store_pickup_button.svg" class="saveimgbtn_doctreg"
                  *ngIf="pharma.is_store_pick"
                  (click)="storePickup(pharma.pharma_id,pharma.pharmacy_name,pharma.phar_addr1,pharma.phar_addr2,pharma.phar_loc,pharma.phar_city,pharma.phar_zipcode,pharma.phar_state,pharma.phar_cnty,pharma.telephone,pharma.phar_valid,pharma.phar_work_time)">
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="diagFlag && diagList.length!=0">
            <mat-card id="cardcontent" *ngFor="let diag of diagList" (click)="viewAppt(diag.center_id,diag.profile_image,diag.center_name,diag.address,diag.location,diag.city,
            diag.state,diag.telephone,diag.website,diag.available,diag.diag_tests_list)">
              <mat-card-content>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <img class="diagimage" src="{{diag.profile_image}}">
                  </div>
                  <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel"><b>{{diag.center_name}}</b></mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{ diag.address}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{diag.location}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{diag.city}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{diag.state}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">Telephone :{{diag.telephone}}</mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>