import { Component, OnInit, ElementRef, Input, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
import { Nurse_Helper } from '../Nurse_Helper';
import { MedicineTrackHistoryComponent } from '../../client/medicine-track-history/medicine-track-history.component';
import { ipaddress } from '../../ipaddress'; import { Message_data } from 'src/assets/js/Message_data';
import { Time_Formate } from '../../../assets/js/common';
@Component({
  selector: 'app-inpat-medicine-tracker',
  templateUrl: './inpat-medicine-tracker.component.html',
  styleUrls: ['./inpat-medicine-tracker.component.scss']
})
export class InpatMedicineTrackerComponent implements OnInit {

  public TwformaTime;
  public date;
  public visitSession;
  public startsFromArray = [];
  public endsAtrray = [];
  public visitingData = [];
  public sendCurrentData;
  public sendURl;
  public medURL;
  public saveMed;
  public getCurrenrDate;
  public currentTime;
  private Curttime;
  public sendMed;
  public medicineHistArray = [];
  public drugListArray = [];
  public startFrom3;
  public startFrom2;
  public startFrom1;
  public drugID;
  public quantity;
  public subRelID;
  public relationID;
  public clientID;
  loader: boolean;
  Nodata: boolean;
  public medicineFlag: Boolean = false;
  public time: string;

  constructor(public dialog: MatDialog, public toastr: ToastrService, public http: Http, 
    public el: ElementRef, public dialogRef: MatDialogRef<InpatMedicineTrackerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("data "+JSON.stringify(data))
      this.loader = false;
    this.Nodata = false;
    this.sendURl = ipaddress.getIp + "adm/curdate";
    this.medURL = ipaddress.getIp + "lifestyle/pmeds";
    this.saveMed = ipaddress.getIp + "lifestyle/medtrack";
    var med_tracker = data;
    if (med_tracker.sub_rel_id != null && med_tracker.sub_rel_id != ""
      && med_tracker.sub_rel_id != undefined && med_tracker.sub_rel_id != "0") {
      this.subRelID = med_tracker.sub_rel_id
    }

    if (med_tracker.relation_id != null) {
      this.relationID = med_tracker.relation_id;
    }
    this.clientID = med_tracker.client_reg_id;
    
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";

    this.getSession();
    this.sendCurrentData = {
      country: ipaddress.country_code
    }
    this.appGetDate();
  }

  ngOnInit() {
  }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  appGetDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.sendURl, JSON.stringify(this.sendCurrentData), { headers: headers }).subscribe(
      data => {
        this.loader = true;
        this.getCurrenrDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.time = Time_Formate(this.currentTime);
        this.date = (data.json().current_date);
        this.changeVisit("Morning");
      }, error => {
      }
    );
  }

  getSession() {
    this.visitingData = [{ "id": 1, "description": "Morning" }, { "id": 2, "description": "Afternoon" }, { "id": 3, "description": "Evening" }, { "id": 4, "description": "Night" }];
    this.visitSession = this.visitingData[0].description;
  }

  changeVisit(data) {
    this.Nodata = false;
    this.sendMed = {
      client_reg_id: this.clientID,
      relation_id: this.relationID,
      // sub_rel_id: this.sub_rel_id,
      time_string: data
    }
    console.log("med dta"+JSON.stringify(this.sendMed))
    if (data != null && data.length != 0 && data != undefined) {
      this.medicineFlag = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.medURL, JSON.stringify(this.sendMed), { headers: headers }).subscribe(
        data => {
          console.log("med dta"+JSON.stringify(data.json()))
          this.medicineHistArray = [];
          if (data.json().medicines != null && data.json().medicines.length != 0) {
            for (var i = 0; i < data.json().medicines.length; i++) {
              this.medicineHistArray.push({
                drug_id: data.json().medicines[i].drug_id,
                drug_name: data.json().medicines[i].drug_name,
                quantity: data.json().medicines[i].quantity,
                short_form: data.json().medicines[i].short_form,
              });
            }

          } else {
            this.Nodata = true;
            this.medicineHistArray = [];
          }
        },
        error => {

        })
    } else {

    }
  }

  deleteDrug(Drug_id, ind) {

    for (var i = 0; i < this.medicineHistArray.length; i++) {
      if (this.medicineHistArray[i].drug_id == Drug_id) {
        this.medicineHistArray.splice(i, 1);
        break;
      }
    }
  }

  getDate(date, value) {
    if (value == "Appointment_Date") {
      this.date = date;
    }
    // let search_model = this.modalCtrl.create('CalendarPage', {
    //   flow: "past"
    // });
    // search_model.present();

    // search_model.onDidDismiss(
    //   data => {
    //     if (data != null) {
    //       if(value =="Appointment_Date"){
    //         this.date = data;
    //       }           
    //     }
    //   });
  }

  saveMedTrack() {
    var saveFlag = true;
    var Drug_Hist_List = [];

    if (this.medicineHistArray.length == 0) {
      saveFlag = false;
    }
    if (this.date == undefined) {
      this.toastr.error(Message_data.sltDate);
      saveFlag = false;
    }
    else if (this.visitSession == undefined) {
      this.toastr.error(Message_data.sltSession);
      saveFlag = false;
    }

    else {
      if (this.startFrom1 == undefined && this.startFrom2 == undefined) {
        this.toastr.error(Message_data.sltTime);
        saveFlag = false;
      }
      var Allottime = this.convertTime12to24(this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3);

      var Timesplit = Allottime.split(':');

      this.Curttime = this.currentTime.split(':');

      var get_date = (this.date);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Night";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Night";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Night";
      }

      if (new Date(this.getCurrenrDate) < new Date((this.date))) // Date not Equal
      {
        if (this.visitSession == "Morning") {
          if (parseInt(Timesplit[0]) >= 12) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
        }
        else if (this.visitSession == "Afternoon") {
          if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime);

            saveFlag = false;


          }
        }

        else if (this.visitSession == "Night") {
          if (parseInt(Timesplit[0]) < 16) {

            this.toastr.error(Message_data.enterValidTime);

            saveFlag = false;
          }
        }
      }
      else {
        if (this.visitSession == "Morning") {

          if (this.startFrom3 == "PM") {
            this.toastr.error(Message_data.enterValidTime);

            saveFlag = false;
          }
          else if (parseInt(Timesplit[0]) >= 12) {
            this.toastr.error(Message_data.enterValidTime);

            saveFlag = false;
          }
          else {
            if (parseInt(this.Curttime[0]) < parseInt(Timesplit[0])) {

              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.Curttime[0]) == parseInt(Timesplit[0])) {

              if (parseInt(this.Curttime[1]) <= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;


              }
            }

          }
        }
        else if (this.visitSession == "Afternoon") {
          if (this.startFrom3 == "AM") {
            this.toastr.error(Message_data.enterValidTime + '1')
            saveFlag = false;

          }
          else if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime + '2')
            saveFlag = false;

          }
          else {
            if (cuur_mor_sess != "" && (parseInt(this.Curttime[0]) > parseInt(Timesplit[0]))) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (cuur_afetr_sess != "" && (parseInt(this.Curttime[0]) > parseInt(Timesplit[0]))) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.Curttime[0]) == parseInt(Timesplit[0])) {

              if (parseInt(this.Curttime[1]) <= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;


              }
            }
          }
        }
        else if (this.visitSession == "Night") {
          if (this.startFrom3 == "AM") {

            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else if (parseInt(Timesplit[0]) < 16) {

            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else {
            if (parseInt(this.Curttime[0]) > parseInt(Timesplit[0])) {

              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.Curttime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.Curttime[1]) <= parseInt(Timesplit[1])) {

                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;
              }
            }
          }
        }


      }
    }
    // if (this.header_footer_flag == true) {

    //   if (this.start_from3 == "PM") {

    //     if (this.start_from1 != "12") {
    //       this.TwformaTime = parseInt(this.start_from1) + 12 + ":" + this.start_from2;
    //     }
    //     else {
    //       this.TwformaTime = "12" + ":" + this.start_from2;
    //     }
    //   } else {
    //     this.TwformaTime = this.start_from1 + ":" + this.start_from2;
    //   }
    // } else {

    this.TwformaTime = this.startFrom1;
    // }

    for (var i = 0; i < this.medicineHistArray.length; i++) {

      if (saveFlag == true && (this.medicineHistArray[i].quantity == "" || this.medicineHistArray[i].quantity == "0")) {
        this.toastr.error(Message_data.validQty);
        saveFlag = false;
      }
      Drug_Hist_List.push({
        drug_id: this.medicineHistArray[i].drug_id,
        quantity: this.medicineHistArray[i].quantity,
        intake: this.visitSession,
        created_date: (this.date),
        created_time: (this.TwformaTime),
      });
    }

    if (saveFlag == true) {
      this.sendMed = {
        client_reg_id: this.clientID,
        relation_id: this.relationID,
        // sub_rel_id: this.sub_rel_id,
        medicines: Drug_Hist_List,
        country: ipaddress.country_code
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.saveMed, JSON.stringify(this.sendMed), {
        headers: headers
      }).subscribe(
        data => {

          var obj = JSON.parse(data["_body"]);
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success(Message_data.saveSuccess);
              this.dialogRef.close();
              // Client_Helper.setTrackerFlag("medicine");
              // this.messageservice.sendMessage("medicine");
              // localStorage.setItem("trackerFlag","medicine")
              // this.router.navigateByUrl("/lifestyle_tracker")
              //this.navCtrl.push('LifestyleTrackerPage', { trackerFlag: "medicine" });
            }
            else {
              this.toastr.error(Message_data.notSavedSuccess);

            }
          }
        }), error => {

        }
    }

  }

  history() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(MedicineTrackHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        relation_id: this.relationID,
        flow: "nurse"
        //sub_rel_id: this.sub_rel_id,
      }
    });
  }

  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return hours + ':' + minutes;
  }

  closePopup() {
    this.dialogRef.close();
  }

}
