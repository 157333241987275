<div *ngIf="nephVitalsFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel" [hidden]="height_hidden">Height<br>
            <input matInput (click)="bmi_calculation()" class="ipcss nephro_width"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" required [(ngModel)]="height_txt">
        </mat-label>
        <mat-label class="matlabel" [hidden]="height_hidden">Measure<br>
            <select class="ipcss nephro_width" (change)="bmi_calculation()" [(ngModel)]="height_measure"
                disableOptionCentering>
                <option value="cms">cms</option>
                <option value="inch">inch</option>
            </select>
        </mat-label>
        <mat-label class="matlabel" [hidden]="weight_hidden">Weight<br>
            <input matInput (click)="bmi_calculation()" class="ipcss nephro_width"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" required [(ngModel)]="weight_txt">
        </mat-label>
        <mat-label class="matlabel" [hidden]="weight_hidden">Measure<br>
            <select class="ipcss nephro_width" (change)="bmi_calculation()" [(ngModel)]="weight_measure"
                disableOptionCentering>
                <option value="kgs">kgs</option>
                <option value="lbs">lbs</option>
            </select>
        </mat-label>
    </div>
</div>

<div *ngIf="nephVitalsFlag == 'max'">
    <a (click)="save_click();"><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px" style="width:25px" /></span> </a>
    <div class="row">
        <div class="col-12">
            <div class="cover_div" style="padding-bottom: 14px;">
                <div class="header_lable">
                    BMI
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="height_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="height_hidden">Height<br>
                                <input matInput (change)="bmi_calculation()" class="ipcss nephro_width"
                                    [disabled]="client" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    required [(ngModel)]="height_txt">
                            </mat-label>
                        </div>
                        <div [hidden]="height_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="height_hidden">Measure<br>
                                <select class="ipcss nephro_width" (change)="bmi_calculation()" [disabled]="client"
                                    [(ngModel)]="height_measure">
                                    <option value="cms">cms</option>
                                    <option value="inch">inch</option>
                                </select>
                            </mat-label>
                        </div>

                        <div [hidden]="weight_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="weight_hidden">Weight<br>
                                <input matInput (change)="bmi_calculation()" class="ipcss nephro_width"
                                    [disabled]="client" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    required [(ngModel)]="weight_txt">
                            </mat-label>
                        </div>
                        <div [hidden]="weight_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="weight_hidden">Measure<br>
                                <select class="ipcss nephro_width" (change)="bmi_calculation()" [disabled]="client"
                                    [(ngModel)]="weight_measure">
                                    <option value="kgs">kgs</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Bmi
                                <input class="ipcss bmi_lenght" matInput type="text" [readonly]="client"
                                    [(ngModel)]="txt_bmi" [readonly]="client" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Bmr
                                <input class="ipcss" style="width: 100%;" matInput [readonly]="client"
                                    [(ngModel)]="txt_bmr" [readonly]="client" />
                            </mat-label>
                        </div>
                        <div [hidden]="temp_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="temp_hidden">Temperature<br>
                                <input [readonly]="client" matInput class="ipcss " [(ngModel)]="Temperature">
                            </mat-label>
                        </div>
                        <div class="col-12" *ngIf="!client">
                            <a [hidden]="client" (click)="bmi_click()" [hidden]="bmi_hide">
                                <img src="../../../assets/ui_icons/buttons/History_button.svg" style="float: right;"
                                    class="saveimgbtn_inpatinfo" />
                            </a>
                            <a style="float: right;" [hidden]="client" (click)="save_click()"><img
                                    src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Vitals
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Sitting
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="blood_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel" [hidden]="blood_hidden">Blood Pressure<br></mat-label>
                                            <div class="row">
                                                <div class="col-6">
                                                    <input matInput class="ipcss nephro_width" placeholder="mm Hg" maxlength="3"
                                                        [(ngModel)]="BP_01" 
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [disabled]="client">
                                                </div>
                                                <div class="col-6">
                                                    <input matInput class="ipcss nephro_width" placeholder="mm Hg" maxlength="3"
                                                        [(ngModel)]="BP_02" 
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [disabled]="client">
                                                </div>
                                            </div>
                
                                        </div>
                
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="heart_hidden">Heart Beat<br>
                                                <input matInput class="ipcss nephro_width" placeholder="Pulse" required maxlength="3"
                                                    [(ngModel)]="Heart_Beat"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [disabled]="client">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="respi_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            style="padding-right: 0;">
                                            <mat-label class="matlabel" [hidden]="respi_hidden">Respiratory Rate<br>
                                                <input matInput class="ipcss nephro_width" placeholder="Rate / minute" required
                                                    maxlength="2" [(ngModel)]="Respiratory_rate"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [disabled]="client">
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Standing
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="blood_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel" [hidden]="blood_hidden">Blood Pressure<br></mat-label>
                                            <div class="row">
                                                <div class="col-6">
                                                    <input matInput class="ipcss nephro_width" placeholder="mm Hg" maxlength="3"
                                                        [(ngModel)]="BP_01_stand"  [disabled]="client"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                                <div class="col-6">
                                                    <input matInput class="ipcss nephro_width" placeholder="mm Hg" maxlength="3"
                                                        [(ngModel)]="BP_02_stand"  [disabled]="client"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                            </div>
                                        </div>
                
                                        <div [hidden]="heart_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="heart_hidden">Heart Beat<br>
                                                <input matInput class="ipcss nephro_width" placeholder="Pulse" required maxlength="3"
                                                    [disabled]="client" [(ngModel)]="Heart_Beat_stand"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                            </mat-label>
                                        </div>
                                        <div [hidden]="respi_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                            style="padding-right: 0;">
                                            <mat-label class="matlabel" [hidden]="respi_hidden">Respiratory Rate<br>
                                                <input matInput class="ipcss nephro_width" placeholder="Rate / minute" required
                                                    maxlength="2" [disabled]="client" [(ngModel)]="Respiratory_rate_stand"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" *ngIf="!client">
                            <a [hidden]="client" (click)="vitals_click()" [hidden]="sitting_hide">
                                <img src="../../../assets/ui_icons/buttons/History_button.svg"
                                    class="saveimgbtn_inpatinfo" style="float: right;" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-12" style="margin-top: 3px;margin-left: -19px;">
            <a style="float: right;" [hidden]="client" (click)="save_click()"><img
                    src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
        </div> -->
    </div>

</div>