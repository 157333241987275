<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="back()" />
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" *ngIf="cancelFlag" class="saveimgbtn_inpatinfo"
              (click)="cancel()" />
              <img class="saveimgbtn_inpatinfo" *ngIf="payTpe == 'Online'" (click)="payWithRazor()"
              src="../../../assets/ui_icons/buttons/pay_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
             Personal details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label  class="matlabel">Relationship
                    <input type="text" class="ipcss" [(ngModel)]="relation" disabled matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label  class="matlabel">Name
                    <input type="text" class="ipcss" [(ngModel)]="clntName" disabled matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label  class="matlabel">Age
                    <input type="text" class="ipcss" [(ngModel)]="age" disabled matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label  class="matlabel">Gender
                    <input type="text" class="ipcss" [(ngModel)]="gender" disabled matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Blood Group
                    <input type="text" class="ipcss" [(ngModel)]="bloodGroup" disabled matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Symptom
                    <input class="ipcss" [(ngModel)]="symptoms" disabled matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Phone Number
                    <input class="ipcss" [(ngModel)]="mobile" disabled matInput>
                  </mat-label>
                </div>
                <div *ngIf="appNursePhysio == 'physio'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label *ngIf="appNursePhysio == 'physio'" class="matlabel">Physiotherapist
                    <input class="ipcss" [(ngModel)]="physioNurseName" disabled matInput>
                  </mat-label>
                </div>
                <div *ngIf="appNursePhysio == 'nurse'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label *ngIf="appNursePhysio == 'nurse'" class="matlabel">Nurse Name
                    <input class="ipcss" [(ngModel)]="physioNurseName" disabled matInput>
                  </mat-label>
                </div>
                <div *ngIf="reasonFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label *ngIf="reasonFlag" class="matlabel">Reason
                    <textarea class="ipcss" [(ngModel)]="reason" matInput disabled></textarea>
                  </mat-label>
                </div>
                <div *ngIf="homecare == '0'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label *ngIf="homecare == '0'" class="matlabel">Address
                    <textarea style="height: 80px;" class="ipcss" [(ngModel)]="hospAddress" matInput disabled></textarea>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Appointment Details
                    <textarea style="height: 80px;" class="ipcss" [(ngModel)]="appDetails" matInput disabled></textarea>
                  </mat-label>
                </div>
            </div>
          </div>
      </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>