<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Upload Document</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="upload_content">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Report Type<br>
                    <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="doc_type">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let rep of report_datas" value={{rep.document_type_id}}>{{rep.description}}
                        </mat-option>
                    </mat-select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Report Name
                    <input type="text" class="ipcss widthappt" [(ngModel)]="report_name" required matInput />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Disease
                    <input type="text" class="ipcss widthappt" [(ngModel)]="disease" matInput />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Document Date
                    <input type="date" class="ipcss_date noappt_width"  (change)="Date_Click(doc_date)" [(ngModel)]="doc_date"
                                                max="{{current_date}}" required #matInput>

                    <!-- <input type="date" class="ipcss" id="appt_date" (change)="Date_Click(doc_date)" 
                        [(ngModel)]="doc_date" max="{{current_date}}">-->
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pobup" style="position: relative;left: 266px;margin-top: 33px;">
                <mat-label class="matlabel">
                    <input type="file" class="ipcss" (change)="fileChange($event)" name="filUpload" id="file" />
                    <label class="closeoption" (change)="fileChange($event)" for="file"
                        style="position: absolute;top: 0px;left: 4px;width: 100%;"><span
                            class="excr_confirm matlabel"><i class="fa fa-upload" aria-hidden="true"></i> Upload
                            Document</span> </label>
                </mat-label>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
        <button (click)="createPdf()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-raised-button>
            <img class="addimgbtn_icd" src="../../../assets/ui_icons/buttons/save_button.svg" />
        </button>
</div>