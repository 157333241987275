<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Change Password</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="onNoClick()" width="20px" height="20px" id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diag_content">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mat-label class="matlabel">Current Password<br>
                    <input class="ipcss " [(ngModel)]="Old_Password" required matInput>
                </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mat-label class="matlabel">New Password<br>
                    <input class="ipcss widthappt" [(ngModel)]="New_Password" required matInput>
                </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mat-label class="matlabel">Confirm Password<br>
                    <input class="ipcss widthappt" [(ngModel)]="Conform_Password" required matInput>
                </mat-label>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="Change_password_confirm()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
        <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button>
    <button (click)="Change_password_cancel()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
        <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="addimgbtn_icd" />
    </button>
</div>