import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../Client_helper';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';

@Component({
    selector: 'app-doctorfilterpage',
    templateUrl: './doctorfilterpage.component.html',
    styleUrls: ['./doctorfilterpage.component.css']
})
export class DoctorfilterpageComponent implements OnInit {
    public doctorList = [];
    public secondOpinionFlag: string;
    public specialityName;
    public curDateUrl: string;
    public sendData;
    public currentDate: string;
    public currentYear: string;
    public doctorListUrl: string;
    public sendDoctorList;
    public homecare: string;
    public autolocationFlag: Boolean = false;
    public knobValues: any = {
        upper: 1500,
        lower: 0
    }
    public clntLocationList = [];
    public clntCountryList = [];
    public clntStateList = [];
    public clntCityList = [];
    public filtFee;
    public filtGender;
    public filtSession;
    public filtAvailableDay;
    public clntLocation: string;
    public clntCity: string;
    public clnt_state: string;
    public clnt_country: string;
    public clntLocationId: string;
    public clntCityId: string;
    public clntStateId: string;
    public clntCountryId: string;
    public searchClntLocation: string;
    public searchClntCity: string;
    public searchClntState: string;
    public searchClntCountry: string;
    public cityUrl: string;
    public stateUrl: string;
    public countryUrl: string;
    public locationNameByUrl: string;
    public minValue: number = 300;
    public maxValue: number = 500;
    options: Options = {
        floor: 100,
        ceil: 1500,
        step: 100,
        translate: (value: number, label: LabelType): string => {
        switch (label) {
            case LabelType.Low:
            return '<span style="font-size:10px;">Rs' + value +'</span>';
            case LabelType.High:
            return '<span style="font-size:10px;">Rs' + value +'</span>';
            default:
            return '<span style="font-size:10px;">Rs' + value+ '</span>';
        }
        }
    };

    constructor(public gservice: CommonDataService, public http: Http, public el: ElementRef, public dialogRef: MatDialogRef<DoctorfilterpageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            dialogRef.disableClose = true;
        if(Helper_Class.getTreatmentList() == undefined){
            this.clntLocation = this.gservice.Client_login_detail_data.Client_Location;
        }
    }

    ngOnInit() {
        this.sendData = {
            country: "IN"
        }
        this.homecare = "0";
        this.curDateUrl = ipaddress.getIp + "adm/curdate";
        this.doctorListUrl = ipaddress.getIp + "search/doctor/";
        this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
        this.cityUrl = ipaddress.getIp + "usercontroller/city";
        this.stateUrl = ipaddress.getIp + "usercontroller/state";
        this.countryUrl = ipaddress.getIp + "usercontroller/count";

        Client_Helper.setsecondopiniondoctorlistflag(null);
        if(Client_Helper.getsecondopinion() != undefined){
            if (Client_Helper.getsecondopinion().app_flow == "second_opinion" || Client_Helper.getsecondopinion() == "without_second_opinion") {
                this.secondOpinionFlag = "1";
            }
        }

        if(Helper_Class.getTreatmentList() != undefined){
            if (Helper_Class.getTreatmentList().app_flow == "second_opinion" || Helper_Class.getTreatmentList().app_flow == "without_second_opinion") {
                this.secondOpinionFlag = "1";
                Client_Helper.setsecondopiniondoctorlistflag(this.secondOpinionFlag);
            }
        }

        this.getCurrentDate();
        
        if(Helper_Class.getTreatmentList() != undefined){
            this.clntCountryId = Helper_Class.getTreatmentList().login_search.Search_Country;
            this.clntStateId = Helper_Class.getTreatmentList().login_search.Search_State_id;
            this.clntCityId = Helper_Class.getTreatmentList().login_search.Search_City_id;
            this.clntLocationId = Helper_Class.getTreatmentList().login_search.Search_Location_id;
            this.searchClntLocation = Helper_Class.getTreatmentList().login_search.Search_Location;

            this.getCountries("0", this.clntCountryId);
        }else{
            if (this.gservice.Client_login_detail_data.Country_ID != null) {
                this.clntCountryId = this.gservice.Client_login_detail_data.Country_ID;
                this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
                this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
                this.clntLocationId = this.gservice.Client_login_detail_data.Location_ID;
                this.searchClntLocation = this.gservice.Client_login_detail_data.Client_Location;

                this.getCountries("0", this.clntCountryId);
            }
        }
    }

    getCurrentDate() {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.curDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
            data => {
                this.currentDate = data.json().current_date;
                this.currentYear = data.json().current_date.split('-')[0];
                this.getDoctorList();
            },error => {});
    }

    getDoctorDetails(responselist) {
        this.doctorList = [];
        for (var i = 0; i < responselist.length; i++) {
            var Docname = "";
            if (responselist[i].middle_name != undefined && responselist[i].middle_name != "") {
                Docname = responselist[i].first_name + " " + responselist[i].middle_name + " " + responselist[i].last_name;
            } else {
                Docname = responselist[i].first_name + " " + responselist[i].last_name;
            }

            var MedicareName = "";
            var gender_data = "";
            if (responselist[i].gender != null) {
                gender_data = responselist[i].gender;
            }

            if (responselist[i].medicare != null) {
                if (responselist[i].medicare.length != 0) {
                    for (var j = 0; j < responselist[i].medicare.length; j++) {
                        MedicareName =  (j == 0) ? responselist[i].medicare[j] : MedicareName + ", " + responselist[i].medicare[j];
                    }
                }
            }

            var Splityname = "", splid = "0";

            if (responselist[i].specialization != null && responselist[i].specialization.length != 0) {
                Splityname = "";
                for (var j = 0; j < responselist[i].specialization.length; j++) {
                    Splityname = (j == 0) ? responselist[i].specialization[j] : Splityname + ", " + responselist[i].specialization[j];
                }
                var Medispecial = false;

            } else {
                var Medispecial = true;
            }

            var Qualify = "";
            if (responselist[i].qualification.length != 0) {
                for (var j = 0; j < responselist[i].qualification.length; j++) {
                    Qualify = (j == 0) ? responselist[i].qualification[j].degree_name : Qualify + ", " + responselist[i].qualification[j].degree_name;
                }
            }

            var Hosp_detail = [];
            if (responselist[i].hospital.length != 0) {
                var Hosp_id_list = [];
                for (var j = 0; j < responselist[i].hospital.length; j++) {
                    if (j == 0) {
                        Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                        Hosp_detail.push({
                            Hosp_name: responselist[i].hospital[j].hospital_name,
                            Hosp_Locat: responselist[i].hospital[j].location
                        });
                    } else {
                        if (this.checkArrayContains(Hosp_id_list, responselist[i].hospital[j].hospital_id) == false) {
                            Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                            Hosp_detail.push({
                                Hosp_name: responselist[i].hospital[j].hospital_name,
                                Hosp_Locat: responselist[i].hospital[j].location
                            });
                        }
                    }
                }
            }

            var experiece;
            if ((parseInt(this.currentYear) - parseInt(responselist[i].registeration_year)) == 0) {
                experiece = "<1";
            } else {
                experiece = parseInt(this.currentYear) - parseInt(responselist[i].registeration_year);
            }

            this.doctorList.push({
                Prov_ID: responselist[i].prov_id,
                Doctor_Name: Docname,
                Prof_image: ipaddress.Ip_with_img_address + responselist[i].profile_image,
                Work_Year: experiece,
                Medicare: MedicareName,
                Speciality: Splityname,
                Qualification: Qualify,
                Hospital: Hosp_detail,
                gender1: gender_data,
                check: false
            });
        }
    }

    checkArrayContains(array, value) {
        var data = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) {
                data = true;
                break;
            }
        }
        return data;
    }



    getDoctorList() {// get doctors list
        function StringBuilder(this: any, value) {
            this.strings = new Array("");
            this.append(value);
        }
       
        StringBuilder.prototype.append = function (value) { // Appends the given value to the end of this instance.
            if (value) {
                this.strings.push(value);
            }
        }

        
        StringBuilder.prototype.clear = function () {// Clears the string buffer
            this.strings.length = 1;
        }

       
        StringBuilder.prototype.toString = function () { // Converts this instance to a String.
            return this.strings.join("");
        }

        if (Helper_Class.getTreatmentList() != undefined){
            if (Helper_Class.getTreatmentList().doc_search.splity != null) {
                var speciality_name = Helper_Class.getTreatmentList().doc_search.splity.specialization_name;
            }
    
            if (Helper_Class.getTreatmentList().doc_search.Treatment_Type != undefined) {
                        var medtype = Helper_Class.getTreatmentList().doc_search.Treatment_Type.medicare_name;
            }
    
            var sec_opn = undefined;
            if (this.secondOpinionFlag == "1") {
                sec_opn = "1";
            }
    
            this.sendDoctorList = {
                second_opinion: sec_opn,
                country: Helper_Class.getTreatmentList().doc_search.Search_Country,
                state: Helper_Class.getTreatmentList().doc_search.Search_State,
                city: Helper_Class.getTreatmentList().doc_search.Search_City,
                location: Helper_Class.getTreatmentList().doc_search.Search_Location,
                medicare_name: medtype,
                spl_name: speciality_name,
                home_care: this.homecare
            }
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "search/doctor/", JSON.stringify(this.sendDoctorList), { headers: headers }).subscribe(
            data => {
                if (data.json().info != null && data.json().info.length != 0) {
                    this.getDoctorDetails(data.json().info);
                    if (this.autolocationFlag == true) {
                        this.clientAutoLocation();
                    }
                } else {
                    this.doctorList = [];
                }
            },error => {});
    }

    getCountries(flag, country) {
        this.clntCountryList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.get(this.countryUrl, { headers: headers }).subscribe(
            data => {
                this.clntCountryList = [];
                if (data.json().countries != null) {
                    this.clntCountryList = data.json().countries;
                    for (var c = 0; c < this.clntCountryList.length; c++) {
                        if (this.clntCountryList[c].country_id == country) {
                            this.searchClntCountry = this.clntCountryList[c].description;
                            this.clnt_country = this.clntCountryList[c].description;
                            this.clntCountryId = this.clntCountryList[c].country_id;
                            this.getStates(this.clntCountryId, flag);
                            break;

                        }
                    }
                    
                }
            },error => {});
    }

    countryChange(clnt_country, flag) {
        for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].description == clnt_country) {
                if (flag == "0") {
                    this.clnt_country = this.clntCountryList[c].description;
                    this.clntCountryId = this.clntCountryList[c].country_id;
                    this.clntLocation = "";
                    this.clntStateList = [];
                    this.clntCityList = [];
                    this.clntLocationId = undefined;
                } else {
                    this.searchClntCountry = this.clntCountryList[c].description;
                    this.clntCountryId = this.clntCountryList[c].country_id;
                    this.searchClntLocation = "";
                    this.clntLocationId = undefined;
                }
                this.getStates(this.clntCountryId, flag);
            }
        }
    }

    getStates(country, flag) {
        if (flag == 0) {
            this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
        }
        this.clntStateList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
            { headers: headers }).subscribe(
                data => {
                    if (data.json().states != null) {
                        this.clntStateList = data.json().states;
                        for (var i = 0; i < this.clntStateList.length; i++) {
                            if (this.clntStateList[i].state_id == this.clntStateId) {
                                this.clntStateId = this.clntStateList[i].state_id;
                                this.clnt_state = this.clntStateList[i].description;
                                this.searchClntState = this.clntStateList[i].description;
                                this.getCities(this.clntStateId, flag);
                                break;
                            }
                        }
                    }
                });
    }

    stateChange(clnt_state, flag) {
        for (var i = 0; i < this.clntStateList.length; i++) {
            if (this.clntStateList[i].description == clnt_state) {
                if (flag == "0") {
                    this.clntStateId = this.clntStateList[i].state_id;
                    this.clnt_state = this.clntStateList[i].description;
                    this.clntLocation = "";
                } else {
                    this.clntStateId = this.clntStateList[i].state_id;
                    this.searchClntState = this.clntStateList[i].description;
                    this.searchClntLocation = "";
                }

                this.clntCityList = [];
                this.clntCityId = undefined;
                this.clntLocationId = undefined;
                this.getCities(this.clntStateId, flag);
            }
        }
    }

    getCities(state, flag) {
        if (flag == 0) {
            this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
        }
        this.clntCityList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
            { headers: headers }).subscribe(
                data => {
                    if (data.json().cities != null) {
                        this.clntCityList = data.json().cities;
                        for (var i = 0; i < this.clntCityList.length; i++) {
                            if (this.clntCityList[i].district_id == this.clntCityId) {
                                this.clntCityId = this.clntCityList[i].district_id;
                                this.clntCity = this.clntCityList[i].description;
                                this.searchClntCity = this.clntCityList[i].description;
                                break;
                            } else {
                                if (flag == "0") {
                                    this.clntCity = this.clntCityList[0].description;
                                } else {
                                    this.searchClntCity = this.clntCityList[0].description;
                                }
                            }
                        }
                    }
                });
    }

    cityChange(clnt_city, flag) {
        this.clntLocationList = [];
        for (var i = 0; i < this.clntCityList.length; i++) {
            if (this.clntCityList[i].description == clnt_city) {
                if (flag == "0") {
                    this.clntCityId = this.clntCityList[i].district_id;
                    this.clntCity = this.clntCityList[i].description;
                    this.clntLocationId = undefined;
                    this.clntLocation = "";
                } else {
                    this.clntCityId = this.clntCityList[i].district_id;
                    this.searchClntCity = this.clntCityList[i].description;
                    this.clntLocationId = undefined;
                    this.searchClntLocation = "";
                }
            }
        }
    }

    locationChange(e) {
        var locat_desc = e.target.value.toString();
        this.clntLocationList = [];

        if (locat_desc != null && locat_desc.length > 2) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(this.locationNameByUrl, JSON.stringify({
                name: locat_desc,
                city_id: this.clntCityId,
            }), { headers: headers }).subscribe(
                data => {
                    if (data.json().locations != null && data.json().locations.length != 0) {
                        this.clntLocationList = data.json().locations;
                    }
                },error => {});
        } else {
            this.clntLocationList = [];
        }
    }

    selectedLocation(location) {
        this.searchClntLocation = location;
        for (var i = 0; i < this.clntLocationList.length; i++) {
            if (this.clntLocationList[i].location_id == location.location_id) {
                this.clntLocationId = this.clntLocationList[i].location_id;
                this.searchClntLocation = this.clntLocationList[i].description;
            }
        }
        this.clntLocationList = [];
    }


    doctorFilter() {
        var doctotfilt = Client_Helper.getsplnextdoclistpage();
        function StringBuilder(this: any, value) {
            this.strings = new Array("");
            this.append(value);
        }

        StringBuilder.prototype.append = function (value) {// Appends the given value to the end of this instance.
            if (value) {
                this.strings.push(value);
            }
        }

        StringBuilder.prototype.clear = function () {// Clears the string buffer
            this.strings.length = 1;
        }

        StringBuilder.prototype.toString = function () {// Converts this instance to a String.
            return this.strings.join("");
        }

        if (doctotfilt.secOpn != undefined) {
            var selected_data = new StringBuilder("");
            var getSpl = doctotfilt.secOpn;
            for (var i = 0; i < getSpl.length; i++) {
                this.specialityName = getSpl[i].specialization_name;
            }
            var n = 0;
        }

        if (this.filtFee != undefined && this.filtFee != "") {
            var price_asc = (this.filtFee == "Low To High") ? "asc" :  "desc";
        }

        var sec_opn = undefined;
        if (this.secondOpinionFlag == "1") {
            sec_opn = "1"
        }

        if (price_asc != undefined) {
            price_asc = price_asc;
        }

        if (this.filtGender != undefined) {
            this.filtGender = this.filtGender
        }

        if (this.filtSession != undefined) {
            this.filtSession = this.filtSession;
        }

        if (this.filtAvailableDay != undefined) {
            this.filtAvailableDay = this.filtAvailableDay
        }

        if (this.knobValues.lower! = undefined) {
            this.knobValues.lower = this.knobValues.lower
        }

        if (this.knobValues.upper! = undefined) {
            this.knobValues.upper = this.knobValues.upper
        }

        if(Helper_Class.getTreatmentList() != undefined){
            if (Helper_Class.getTreatmentList().doc_search.splity != null) {
                var speciality_name = Helper_Class.getTreatmentList().doc_search.splity.specialization_name;
            }
            if (Helper_Class.getTreatmentList().doc_search.Treatment_Type != undefined) {
                var medtype = Helper_Class.getTreatmentList().doc_search.Treatment_Type.medicare_name;
            }
    
            var sec_opn = undefined;
            if (this.secondOpinionFlag == "1") {
                sec_opn = "1"
            }
    
            this.sendDoctorList = {
                second_opinion: sec_opn,
                medicare_name: medtype,
                spl_name: speciality_name,
                home_care: this.homecare,
                fees_from: this.minValue,
                fees_to: this.maxValue,
                price: price_asc,
                gender: this.filtGender,
                session: this.filtSession,
                available_day: this.filtAvailableDay,
                country: this.searchClntCountry,
                country_id:this.clntCountryId,
                state_id:this.clntStateId,
                city_id:this.clntCityId,
                location_id:this.clntLocationId,
                state: this.searchClntState,
                city: this.searchClntCity,
                location: this.searchClntLocation,
            }
        }else{
            this.sendDoctorList = {
                second_opinion: this.secondOpinionFlag,
                fees_from: this.minValue,
                fees_to: this.maxValue,
                price: price_asc,
                gender: this.filtGender,
                session: this.filtSession,
                available_day: this.filtAvailableDay,

                medicare_name: "Allopathy",
                spl_name: this.specialityName,
                home_care: "0",

                country: this.searchClntCountry,
                state: this.searchClntState,
                city: this.searchClntCity,
                location: this.searchClntLocation,
            }
        }
        
        this.dialogRef.close(this.sendDoctorList);
    }

    myCallbackFunction = function (_params) { // callback...
        return new Promise((resolve, reject) => {
            resolve(_params);
        });
    }


    clientAutoLocation() {// Web Filter       
        this.clntLocation = Client_Helper.getDocSearch().Search_Location;
        //this.clientAutoCity(this.clntLocation);
    }

    ClosePopup() {
        this.dialogRef.close(this.sendDoctorList);
    }
}
