import { Component, OnInit } from '@angular/core';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { ConvertTimeformat, Date_Formate, Time_Formate, Time_Formate1 } from '../../../assets/js/common';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Message_data } from 'src/assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
@Component({
  selector: 'app-discharge-timeline',
  templateUrl: './discharge-timeline.component.html',
  styleUrls: ['./discharge-timeline.component.scss']
})
export class DischargeTimelineComponent implements OnInit {
  public profileImg;
  public initiateDischargeFlag;
  public clinicalDischargeFlag;
  public financialDischargeFlag;
  public reportFinalizedFlag;
  public physicalDischargeFlag;
  public inpatient_id;
  public patientList = [];
  public mrno;
  public patient_name;
  public gender_age;
  public mobile_no;
  public doctor_name;
  public admitted_date;
  public admitted_time;
  public speciality;
  public ward_name;
  public bed_no;
  public location;
  public city;
  public discharge_time1: string;
  public discharge_time2: string;
  public discharge_time3: string;
  public dischage_hrs = [];
  public dischage_min = [];
  public phy_discharge_time1: string;
  public phy_discharge_time2: string;
  public phy_discharge_time3: string;
  public phy_dischage_hrs = [];
  public phy_dischage_min = [];
  public currentDateTime;
  public currentDate;
  public currentTime;
  public timevalue;
  public expt_discharge_date;
  public diag_bill_count = '';
  public hosp_bill_count = '';
  public pharma_bill_count = '';
  public phy_discharge_date;
  public initiateDischarge:boolean = false;
  public discharge_initiate;
  public discharge_doctor = '';
  public initiate_discharge_remarks = '';
  public clinicalDischarge:boolean = false;
  public discharge_clinical;
  public physicalDischarge:boolean = false;
  public discharge_physical;
  public clinical_discharge_remarks = '';
  public expt_discharge_time;
  public phy_discharge_time;
  public discharge_doctor_name = '';
  public discharge_id = undefined;
  public address1;
  public address2;
  public zipcode;
  public state;
  public country;
  public billList = [];
  public billingList = [];
  public pharmaBillList = [];
  public filter: string;
  public userInfo: any;
  public diagBillListFlag: boolean = false;
  public hospBillListFlag: boolean = false;
  public billingListFlag: boolean = false;
  public billListFlag: boolean = false;
  public pharmaBillListFlag: boolean = false;
  public client_reg_id;
  public pharmaBillingListFlag: boolean = false;
  public relation_id: any;
  public admission_date: any;

  constructor(private http: HttpClient,public toastr: ToastrService,public messageservice:MenuViewService) {
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.phy_dischage_hrs.push('0' + i);
      } else {
        this.phy_dischage_hrs.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.phy_dischage_min.push('0' + i);
      } else {
        this.phy_dischage_min.push(i);
      }
    }
   }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    this.patientList = Helper_Class.getinpatientfilterdata();
    this.inpatient_id = FrontDesk_Helper.getDischargeTimeLineInpatientId();
    console.log(this.inpatient_id);
    this.getDate();
    this.getPatientDetails();
    this.getDischargeTimeLineDetails();
    this.filter = 'Patient_ID';
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {
        country: ipaddress.country_code.toString()
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;                    
            this.currentDate = Date_Formate(obj.current_date); 
            this.currentTime = obj.current_time;            
            this.timevalue = Time_Formate(this.currentTime);    
            this.phy_discharge_date = obj.current_date;                                           
          }
        },error => {});
  }

  getPatientDetails() {
    if(this.patientList != undefined && this.patientList.length != 0) {
      for(var i = 0;i < this.patientList.length; i++) {
        if (this.patientList[i].inpatient_id == this.inpatient_id) {
          this.discharge_doctor = this.patientList[i].doc_reg_id;
          this.discharge_doctor_name = this.patientList[i].doctor_name;
          this.client_reg_id = this.patientList[i].client_reg_id;
          this.relation_id = this.patientList[i].relation_id;
          this.admission_date = this.patientList[i].admission_date;
          var image;
          if (this.patientList[i].profile_image != undefined) {
            if (this.patientList[i].profile_image == "default") {
              image = "../../../assets/img/default.jpg";
            } else {
              image = ipaddress.Ip_with_img_address.toString() + this.patientList[i].profile_image;
            }
          }
          this.profileImg = image;
          this.mrno = this.patientList[i].patient_id != undefined ? this.patientList[i].patient_id : "";
          this.patient_name = this.patientList[i].patient_name != undefined ? this.patientList[i].patient_name : "";
          this.gender_age = this.patientList[i].gender_age != undefined ? this.patientList[i].gender_age : "";
          this.mobile_no = this.patientList[i].mobilenumber != undefined ? this.patientList[i].mobilenumber : "";
          this.doctor_name = this.patientList[i].doctor_name != undefined ? this.patientList[i].doctor_name : "";
          this.admitted_date = this.patientList[i].admit_date != undefined ? this.patientList[i].admit_date : "";
          this.admitted_time = this.patientList[i].admit_time != undefined ? this.patientList[i].admit_time : "";
          this.speciality = this.patientList[i].speciality != undefined ? this.patientList[i].speciality : "";
          this.ward_name = this.patientList[i].ward_name != undefined ? this.patientList[i].ward_name : "";
          this.bed_no = this.patientList[i].bed_no != undefined ? this.patientList[i].bed_no : "";
          this.address1 = this.patientList[i].address1 != undefined ? this.patientList[i].address1 : "";
          this.address2 = this.patientList[i].address2 != undefined ? this.patientList[i].address2 : "";
          this.location = this.patientList[i].location != undefined ? this.patientList[i].location : "";
          this.city = this.patientList[i].district != undefined ? this.patientList[i].district : "";
          this.zipcode = this.patientList[i].zipcode != undefined ? this.patientList[i].zipcode : "";
          this.state = this.patientList[i].state != undefined ? this.patientList[i].state : "";
          this.country = this.patientList[i].country != undefined ? this.patientList[i].country : "";
        }
      }
    }
  }

  getDischargeTimeLineDetails() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'inpat/gdid ',
      {
        inpatient_id: this.inpatient_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj != undefined) {
            if (obj.discharge_id != undefined) {
              this.discharge_id = obj.discharge_id;
            }

            if (obj.discharge_initiate != undefined && obj.discharge_initiate == '1') {
              this.initiateDischarge = true;
              this.change_type(1,'initiate_discharge');
            } else {
              this.initiateDischarge = false;
            }

            if (obj.discharge_date != undefined) {
              this.expt_discharge_date = obj.discharge_date;
              console.log(this.expt_discharge_date);
            } else {
              this.expt_discharge_date = this.currentDateTime;
              console.log(this.expt_discharge_date);
            }

            if (obj.discharge_time != undefined) {
              var expected_dis_time;
                  expected_dis_time = Time_Formate1(obj.discharge_time);
                  console.log("expected_dis_time",expected_dis_time);
                  expected_dis_time = expected_dis_time.split(':');
                  this.discharge_time1 = expected_dis_time[0];
                  this.discharge_time2 = expected_dis_time[1];
                  this.discharge_time3 = expected_dis_time[2];
            }
            
            if (obj.discharge_doctor != undefined && this.discharge_doctor != undefined) {             
              if (obj.discharge_doctor == this.discharge_doctor) {
                this.discharge_doctor_name = this.discharge_doctor_name;
              }             
            }

            if (obj.discharge_remarks != undefined) {
              this.initiate_discharge_remarks = obj.discharge_remarks;
            }

            if (obj.discharge_clinical != undefined && obj.discharge_clinical == '1') {
              this.clinicalDischarge = true;
              this.change_type(2,'clinical_discharge');
            } else {
              this.clinicalDischarge = false;
            }

            if (obj.dis_clinical_remarks != undefined) {
              this.clinical_discharge_remarks = obj.dis_clinical_remarks;
            }

            if (obj.diag_bills != undefined) {
              this.diag_bill_count = obj.diag_bills;
            }
            if (obj.hosp_bills != undefined) {
              this.hosp_bill_count = obj.hosp_bills;
            }
            if (obj.pharma_bills != undefined) {
              this.pharma_bill_count = obj.pharma_bills;
            } 

            if (obj.discharge_physical != undefined && obj.discharge_physical == '1') {
              this.physicalDischarge = true;
              this.change_type(5,'physical_discharge');
            } else {
              this.physicalDischarge = false;
            }
           
            if (obj.dis_physical_date != undefined) {
              this.phy_discharge_date = obj.dis_physical_date;
            }

            if (obj.dis_physical_time != undefined) {
              var dis_physical_time;
                  dis_physical_time = Time_Formate1(obj.dis_physical_time);
                  console.log("dis_physical_time",dis_physical_time);
                  dis_physical_time = dis_physical_time.split(':');
                  this.phy_discharge_time1 = dis_physical_time[0];
                  this.phy_discharge_time2 = dis_physical_time[1];
                  this.phy_discharge_time3 = dis_physical_time[2];
            }                            
          }      
        },
        error => {
          this.toastr.error();
    });
  }

  change_type(id, value) {
    if (value == "initiate_discharge") {
      this.initiateDischargeFlag = true;
      this.clinicalDischargeFlag = false;
      this.financialDischargeFlag = false;   
      this.reportFinalizedFlag = false;
      this.physicalDischargeFlag = false; 
    } else if(value == "clinical_discharge"){
      this.initiateDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = false;   
      this.reportFinalizedFlag = false;
      this.physicalDischargeFlag = false;
    } else if(value == "financial_discharge"){
      this.initiateDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = false;
      this.physicalDischargeFlag = false;     
    } else if(value == "report_finalized"){
      this.initiateDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = true;
      this.physicalDischargeFlag = false;     
    } else if(value == "physical_discharge"){
      this.initiateDischargeFlag = true;
      this.clinicalDischargeFlag = true;
      this.financialDischargeFlag = true;   
      this.reportFinalizedFlag = true;
      this.physicalDischargeFlag = true;     
    } 
  }

  backClicked() {
    this.messageservice.sendMessage('Patients');
  }

  save() {
    if (this.initiateDischarge == true) {
      this.discharge_initiate = '1';
    } else {
      this.discharge_initiate = '0';
    }

    if (this.clinicalDischarge == true) {
      this.discharge_clinical = '1';
    } else {
      this.discharge_clinical = '0';
    }

    if (this.physicalDischarge == true) {
      this.discharge_physical = '1';
    } else {
      this.discharge_physical = '0';
    }


    if (this.discharge_time1 != undefined && this.discharge_time2 != undefined) {     
      this.expt_discharge_time = ConvertTimeformat('',this.discharge_time1 + ':' + this.discharge_time2 + ' ' + this.discharge_time3);
    }
    if (this.phy_discharge_time1 != undefined && this.phy_discharge_time2 != undefined) {
      this.phy_discharge_time = ConvertTimeformat('',this.phy_discharge_time1 + ':' + this.phy_discharge_time2 + ' ' + this.phy_discharge_time3);
    }

    var sendData,sendURL;
    if (this.discharge_id != undefined) {
      sendURL = 'inpat/udissum';
      sendData = {
        inpatient_id: this.inpatient_id,
        discharge_id: this.discharge_id,
        discharge_date: this.expt_discharge_date,
        discharge_time: this.expt_discharge_time,
        discharge_initiate: this.discharge_initiate,
        discharge_doctor: this.discharge_doctor,
        discharge_remarks: this.initiate_discharge_remarks,
        discharge_clinical: this.discharge_clinical,
        dis_clinical_remarks: this.clinical_discharge_remarks,
        discharge_physical: this.discharge_physical,
        dis_physical_date: this.phy_discharge_date,
        dis_physical_time: this.phy_discharge_time
      }
    } else {
      sendURL = 'inpat/sdissum';
      sendData = {
        inpatient_id: this.inpatient_id,
        discharge_date: this.expt_discharge_date,
        discharge_time: this.expt_discharge_time,
        discharge_initiate: this.discharge_initiate,
        discharge_doctor: this.discharge_doctor,
        discharge_remarks: this.initiate_discharge_remarks,
        discharge_clinical: this.discharge_clinical,
        dis_clinical_remarks: this.clinical_discharge_remarks,
        discharge_physical: this.discharge_physical,
        dis_physical_date: this.phy_discharge_date,
        dis_physical_time: this.phy_discharge_time
      }
    }
    console.log(sendURL);
    console.log(sendData);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + sendURL, sendData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj); 
          if (obj.key == '1') {
            this.toastr.success("Discharge timeline saved successfully");
            this.backClicked();
          } else {
            this.toastr.error("Error in save discharge timeline");
          }        
        },
        error => {
          this.toastr.error();
    });
  }  

  diagnosisPathFunction(type) {
    if (type === 'diagnosis') {
      if (this.diagBillListFlag == false) {
        this.diagBillListFlag = true;
        this.searchBill(type);
      } else if (this.diagBillListFlag == true) {
        this.diagBillListFlag = false;
        this.billingList = [];
      }
    }
  }

  hospitalPathFunction(type) {
    if (type === 'doctor') {
      if (this.hospBillListFlag == false) {
        this.hospBillListFlag = true;
        this.searchBill(type);
      } else if (this.hospBillListFlag == true) {
        this.hospBillListFlag = false;
        this.billList = [];
      }
    }
  }

  pharmaPathFunction(type) {
    if (type === 'pharmacy') {
      if (this.pharmaBillListFlag == false) {
        this.pharmaBillListFlag = true;
        this.searchPharmaBill(type);
      } else if (this.pharmaBillListFlag == true) {
        this.pharmaBillListFlag = false;
        this.pharmaBillList = [];
      }
    }
  }

  searchBill(type) {        
    var fieldvalue;
    var inpatient = "yes";        
    var providerID = type == "diagnosis" ? this.userInfo.diag_center_id : this.userInfo.hptl_clinic_id;
    var appType = undefined;
    
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        provider_id: providerID,
        patient_id: this.mrno,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Mobile") {
      fieldvalue = {
        provider_id: providerID,
        mobile: this.mobile_no,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    }
    console.log("BILL FILEDS " + JSON.stringify(fieldvalue))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', { fieldvalue },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.bills != 0) {
            if (type == "diagnosis") {
              this.inSetBillList(obj);           
            } else {
              this.setBillList(obj);             
            }
          } 
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  setBillList(obj) {
    this.billList = [];
    console.log(obj);
    if (obj.bills != null) {
      for (var i = 0; i < obj.bills.length; i++) {
        var status;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") {
            status = "Closed";
          } else if (obj.bills[i].status === "1") {
            status = "Open";
          } else if (obj.bills[i].status === "2") {
            status = "Finalized";         
          } else if (obj.bills[i].status === "3") {
            status = "Settled";          
          }
        }

        var get_date = obj.bills[i].date.split('-');
        if (obj.bills[i].status === "1") {
          this.billList.push({
            bill_id: obj.bills[i].bill_id,
            fin_year: obj.bills[i].fin_year,
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            pay_type: obj.bills[i].pay_type,
            amount: encrypt_decript.Decript(obj.bills[i].amount),
            status: status,
          });
        }        
      }
      if (this.billList.length != 0) {
        this.billListFlag = true;
      } else {
        this.billListFlag = false;
      }
      console.log(this.billList);   
    }
  }

  inSetBillList(obj) {
    this.billingList = [];
    if (obj.bills != null) {
      console.log("BILL DETAILS " + obj);
      for (var i = 0; i < obj.bills.length; i++) {
        var status;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") {
            status = "Closed";
          } else if (obj.bills[i].status === "1") {
            status = "Open";
          } else if (obj.bills[i].status === "2") {
            status = "Finalized";          
          } else if (obj.bills[i].status === "3") {
            status = "Settled";         
          }
        }

        var get_date = obj.bills[i].date.split('-');
        if (obj.bills[i].status === "1") {
          this.billingList.push({
            bill_id: obj.bills[i].bill_id,
            fin_year: obj.bills[i].fin_year,
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],       
            amount: encrypt_decript.Decript(obj.bills[i].amount),       
            status: status,         
          });
        }       
      }

      if (this.billingList.length != 0) {
        this.billingListFlag = true;
      } else {
        this.billingListFlag = false;
      }
    }
    console.log("this.billingList" + JSON.stringify(this.billingList));
  }

  searchPharmaBill(type) {    
    var send_data = {  
        inpatient_id: this.inpatient_id  
      }
     
    console.log(send_data);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpbbi", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("PharmacyList"+JSON.stringify(obj));
          this.pharmaBillList = [];                 
          if (obj.pharma_bills != null) {
            console.log("PHARMA BILL DETAILS " + obj);
            for (var i = 0; i < obj.pharma_bills.length; i++) {
              var get_date = obj.pharma_bills[i].order_date.split('-');
              if (obj.pharma_bills[i].status === "Open") {
                this.pharmaBillList.push({
                  bill_id: obj.pharma_bills[i].bill_no,
                  date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],       
                  amount: obj.pharma_bills[i].bill_amount,       
                  status: obj.pharma_bills[i].status,         
                });
              }       
            }

            if (this.pharmaBillList.length != 0) {
              this.pharmaBillingListFlag = true;
            } else {
              this.pharmaBillingListFlag = false;
            }
          }
          console.log("this.pharmaBillList" + JSON.stringify(this.pharmaBillList));
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

}
