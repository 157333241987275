import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
@Component({
  selector: 'app-concent-form',
  templateUrl: './concent-form.component.html',
  styleUrls: ['./concent-form.component.scss']
})
export class ConcentFormComponent implements OnInit {
  
 
  public hospital_details: any;
  public hospital_name: string;
  public hospital_id: string;
  public hospitalAddress: string;
  public hosp_tel: string;
  public full_details: any;
  public occupation: string;
  public admission_date: string;
  public admission_time:string;
  public age: string;
  public allergy: string;
  public patient_name: string;
  public get_gender: string;
  public gender: string;
  public address: string;
  public room_no: string;
  public consultant: string;
  public discharge_date: string;
  public discharge_time: string;
  public guardian: string;


  constructor() { }

  ngOnInit(): void {
    this.full_details=Helper_Class.getConcentForm();   
    this.hospital_name = this.full_details.hospital_details.hptl_name;
    this.hospitalAddress = this.full_details.hospital_details.address1 +","+this.full_details.hospital_details.address2 +","+ this.full_details.hospital_details.city_desc+"-"+this.full_details.hospital_details.zipcode;
    this.hosp_tel = this.full_details.hospital_details.telephone;
    this.hospital_id = this.full_details.hospital_details.hptl_clinic_id;
    this.occupation = this.full_details.user_details.Occupation.toLocaleUpperCase();
    if(this.occupation == "NULL"){
      this.occupation = " ";
    }
    this.admission_date = this.full_details.user_details.admission_date;
    this.admission_time = this.full_details.user_details.admission_time;
    this.age = this.full_details.user_details.age;
    this.allergy = this.full_details.user_details.allergy.toLocaleUpperCase();
    this.patient_name = encrypt_decript.Decript(this.full_details.user_details.first_name).toLocaleUpperCase() +" "+encrypt_decript.Decript(this.full_details.user_details.middle_name)+" "+encrypt_decript.Decript(this.full_details.user_details.last_name).toLocaleUpperCase();
    this.get_gender = encrypt_decript.Decript(this.full_details.user_details.gender).toLocaleUpperCase();
    this.gender = this.get_gender[0];
    if(this.get_gender[0]=="T"){
      this.gender = "Tgen";
    }
    this.address = encrypt_decript.Decript(this.full_details.user_details.address1).toLocaleUpperCase();
    this.room_no = this.full_details.user_details.ward_id;
    this.consultant = (this.full_details.user_details.dr_first_name+" "+this.full_details.user_details.dr_middle_name+" "+this.full_details.user_details.dr_last_name).toLocaleUpperCase();
    this.discharge_date = this.full_details.user_details.discharge_date;
    this.discharge_time = this.full_details.user_details.discharge_time;
    this.guardian = this.full_details.user_details.guardian.toLocaleUpperCase();
    if( this.guardian == "NULL"){
      this.guardian = " ";
    }
  }
  
  print_area() {
    let printContents, popupWin;
    printContents = document.getElementById('inpatientConcentForm')!.innerHTML;
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
          @page { size: auto; margin: 0mm; }
          table {
            width: 100%;
          } 
          .details {
            margin-top: -10px;
          }           
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  } 
}


