import { Component, OnInit ,ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../../client/Client_helper';
import { ipaddress } from '../../ipaddress';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-without-login-search',
  templateUrl: './without-login-search.component.html',
  styleUrls: ['./without-login-search.component.scss']
})
export class WithoutLoginSearchComponent implements OnInit {

  public get_location_url: string;
  public get_city_url: string;
  public get_state_url: string;
  public get_country_url: string;
  public get_locname_url: string;
  public clnt_location_list = [];
  public clnt_country_list=[];
  public clnt_state_list=[];
  public clnt_city_list=[];
  public clnt_country_id;
  public clnt_state_id;
  public clnt_city_id;
  public clnt_location_id;
  public search_location;
  public search_city;
  public search_state;
  public search_city_desc;
  public search_state_desc;
  public search_country;
  public homecare_show: Boolean = false;
  public homecare_toggle: Boolean = false;
  public search_list_data;
  public header_text: string;
  public search_hosp_type: string;
  public type_hosp_doct: string;
  public type_div: Boolean;
  public url_txt;
  public current_date: string;
  public stype;
  public Select = "Select";
  //treamentlist
  public treatmentlistURL: string;
  public treatmentlistData = [];
  public treatement_obj;
  public showSpeciality_flag:boolean;
  public showDoctorlist_flag:boolean;
  public showTreatment_flag:boolean
  //specialityList
  public url_specialization: string;
  public specialization_list_id = [];
  public doctor_search_data;
  public SecOpnListSpl;
  public homecare;
  //doctorlist
  speciality_option:OwlOptions = {
    //autoplay : true,
    autoplay: false,
    autoplayTimeout: 3000,
    loop: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    // navRewind: false,
    // navText: ['',''],
    navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      558: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true,
  }
  public showDoctorList_flag:boolean
  public screensize_based: boolean;
  public filter_style: boolean;
  public header_footer_flag: boolean;

  public spl_nametxt;
  public Doctor_list = [];
  public list_header: string;
  public second_opinion_flag: string;
  public second_opinion_next: Boolean = false;

  public Current_url_txt: string;
  public send_current_data;
  public current_year: string;
  public image_ip: string;

  public get_doc_list_url: string;
  public get_nurse_list_url: string;
  public data_toget_doc_list;
  public second_next_btn: boolean;
  public filter_url: string;
  public filter_sebnd_data;

  public param_doctor_data;
  public param_doctor_location: string;
  public autolocation_flag: Boolean = false;
  public Second_Doctor_List = [];
  public Second_Doctor_id = [];

  public Home_care;
  public hospital_div: Boolean;
  public special_div: Boolean;
  public accordian_list_data = [];
  public knobValues: any = {
      upper: 1500,
      lower: 0
  }

  // Filter Data
  public location_url: string;
  public city_url: string;
  public state_url: string;
  public country_url: string;

  public city_list_data;
  public state_list_data;
  public country_list_data;

  public clnt_location;
  public filt_city;
  public filt_state;
  public filt_country;
  public filt_city_desc;
  public filt_state_desc;

  public filt_fees_from;
  public filt_fees_to;
  public filt_fee;
  public filt_price_sort;
  public filter_gender;
  public filter_session;
  public filt_available_day;

  public Doctor_filter_div: boolean;

  public filter_search_location: string;
  public filter_search_city: string;
  public filter_search_state: string;
  public filter_search_country: string;
  public doctornodata: boolean;
  loader: boolean;
  public nursenodata: boolean;
  fabButtonOpened: Boolean;
  public withoutlogin: boolean;
  Title: string;
  public loginheader;
  public withlogin;
  public detail_view_flag:boolean = false;

  //doctor_detail
  public showDoctorDetail_flag:boolean
  public Available_Div: boolean;
  public NonAvailable_Div: boolean;
  public Nextbtn_Div: boolean;
  // Current Date time  
  public get_datetime_url: string;
  public current_time: string;

  // Doctor Details
  public get_doctordet_url: string;
  public get_doctor_detail_data;
  public doc_unavailable_data = [];

  public doctor_ID: string;
  public hospital_id: string;
  public hospital_location: string;
  public hospital_city: string;

  // Display doctor details
  public doctorname: string;
  public doc_Experiance: string;
  public doc_Profile_Image: string;
  public doctor_Qualify_txt: string;
  public splityname: string;
  public densub_Splityname: string;
  public treatementName: string;

  // Client details
  public client_detail_url: string;
  public client_detail_send_data;

  public temp_address: string;
  public temp_fee: string;

  public doctor_Medicare_list = [];
  public doctor_Speciality_list = [];
  public doctor_qualify_data = [];
  public hospital_list_data = [];
  public doctor_detail_table_data = [];

  public visiting_Session_Data = [];
  public Appointment_Date;
  public hospital_name;
  public time_List = [];
  public visit_Session;
  public visit_Time;

  public typeorTime: string;
  public tokenorTime;
  public time_or_token_flag: Boolean;
  public app_Type_Token: Boolean;
  public app_Type_Time: Boolean;
  public appoint_Status: string;

  // Homecare 
  public Homecare_div: Boolean;

  public Homecare_Table_Data = [];
  public Home_Session_Data = [];
  public Home_Time_List = [];
  public Homesessiontable_Data = [];

  public home_Visit_Session;
  public Home_App_Date;
  public home_App_Time;

  public home_add_send_data;

  // treate spl hide show
  public treatement_type;
  public treate_speciality;
  public doc_treatement_div: Boolean;
  public doc_treatement_readonly: Boolean;
  public doc_special_div: Boolean;

  // app exists
  public appexist_url: string;
  public data_for_appexist;

  public hospital_data_list = []; // helper class use
  public appoint_doctor_data; // helper class use

  // mobile layout
  public mobile_layout: Boolean;
  public hosp_selct_location: string;
  public hosp_selct_fee: string;

  public other_med_spl;

  public nurse_physio_div: Boolean;
  public physioadd: boolean;

  medicareList: any;
  SplList: any;
  RelationList: any;
  public time_card_flag:boolean = false;
  public time_List_time = [];
  public add_session_table_list = [];
  public add_session_btn_flag:boolean = false;
  public presentdate;
  public address_flag:boolean;

  constructor(public router:Router,public http:Http, public el: ElementRef,public datepipe: DatePipe, public toastr:ToastrService,public gservice:CommonDataService) {   
    this.url_txt = ipaddress.getIp + "adm/curdate";
    this.header_text = "Search doctor";
    this.screensize_based = false;

    this.get_location_url = ipaddress.getIp + "usercontroller/loc";
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";
    this.get_city_url = ipaddress.getIp + "usercontroller/city";
    this.get_state_url = ipaddress.getIp + "usercontroller/state";
    this.get_country_url = ipaddress.getIp + "usercontroller/count";

    //TreamentList
    this.treatmentlistURL = ipaddress.getIp + "usercontroller/getmedicare/";
    //speciality
    this.url_specialization = ipaddress.getIp + "usercontroller/getspecialization";

    //doctor
    this.location_url = ipaddress.getIp + "gen/loc";
    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.Current_url_txt = ipaddress.getIp + "adm/curdate";
    this.get_doc_list_url = ipaddress.getIp + "search/doctor/";

    //doctor_detail
    this.get_datetime_url = ipaddress.getIp + "adm/curdate";
    this.get_doctordet_url = ipaddress.getIp + "search/doctorbyid/";
    this.client_detail_url = ipaddress.getIp + "appointment/clndet/";
  }

  ngOnInit(): void {
    Client_Helper.setHomecare_nurse_physio("");
    this.stype = Helper_Class.getSearchType();
    this.homecare_show = true;

    if(Helper_Class.getTreatmentList() != undefined){
      this.clnt_country_id = Helper_Class.getTreatmentList().doc_search.Search_Country_id;
      this.clnt_state_id = Helper_Class.getTreatmentList().doc_search.Search_State_id;
      this.clnt_city_id = Helper_Class.getTreatmentList().doc_search.Search_City_id;
      this.clnt_location_id = Helper_Class.getTreatmentList().doc_search.Search_Location_id; 
      this.getCountries("0");
      this.treatement_obj = Helper_Class.getTreatmentList().doc_search.Treatment_Type;
      this.homecare = Helper_Class.getTreatmentList().homecare;
    }else{
      this.getCountries("0");
    }
    this.getCurrentDate();
    this.treamentlistData();
    this.specializationGetlist();
    this.detail_view_flag = false;
  }

  homecare_check(value) {
  }

  search_Doctor() {
    var valid_flag = false;

    if (this.search_location == undefined || this.search_location == null ||
      this.search_city == undefined || this.search_city == null ||
      this.search_state == undefined || this.search_state == null ||
      this.search_country == undefined || this.search_country == null) {
      valid_flag = true;
    }
    
    if (this.stype == "search_by_home_mobile") {
      if (this.search_hosp_type != undefined && this.search_hosp_type != "") {
        if (this.search_hosp_type.length < 3) {
          valid_flag = true;
        }
      }
      if (this.type_hosp_doct == undefined || this.type_hosp_doct == "" || this.search_hosp_type==undefined || this.search_hosp_type=="") {
        valid_flag = true;
      }
    }
    var homecare;
    if(this.homecare_toggle == false){
      homecare = "0";
      this.homecare = "0"; 
    }else{
      homecare = "1";
      this.homecare = "1"; 
    }

    if (valid_flag == false) {
      this.search_list_data = {
        Search_Location: this.search_location,
        Search_Location_id:this.clnt_location_id,
        Search_City_id: this.search_city,
        Search_City: this.search_city_desc,
        Search_State_id: this.search_state,
        Search_State: this.search_state_desc,
        Search_Country: this.search_country,
        Search_Country_id:this.clnt_country_id,
        homecare: homecare,
        app_flow:this.stype,
      }

      if(this.homecare_toggle == false){
        Client_Helper.setHomecare('0');
      }else{
        Client_Helper.setHomecare('1');
      }
      Helper_Class.setDocSearch(this.search_list_data);
      this.showTreatment_flag = true;
    }
    else {
      this.toastr.error(Message_data.mandatory);
      this.showTreatment_flag = false;
    }
  }

  getCurrentDate() {
    var send_current_data = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.url_txt, JSON.stringify(send_current_data), { headers: headers }).subscribe(
      data => {
        this.current_date = data.json().current_date;
        this.current_time = data.json().current_time;
        this.Appointment_Date = data.json().current_date;
        this.Home_App_Date = this.Appointment_Date;   
        this.current_year = data.json().current_date.split('-')[0];
      }, error => {});
  }

  getCountries(flag) {
    this.clnt_country_list = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.get_country_url, { headers: headers }).subscribe(
      data => {
        this.clnt_country_list = [];
        if (data.json().countries != null) {
          this.clnt_country_list = data.json().countries;
          for (var c = 0; c < this.clnt_country_list.length; c++) {
            if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
              this.search_country = this.clnt_country_list[c].description;
              this.clnt_country_id = this.clnt_country_list[c].country_id;
              this.getStates(this.clnt_country_id, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }
  
  countryChange(clnt_country, flag){
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.search_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list = [];
        this.clnt_city_list = [];
        this.clnt_location_id = undefined;
        this.search_location = "";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag){
    this.clnt_state_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_state_url,JSON.stringify({country_id : country }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.clnt_state_list = data.json().states;
          for (var i = 0; i < this.clnt_state_list.length; i++) {
            if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
              this.clnt_state_id = this.clnt_state_list[i].state_id;
              this.search_state = this.clnt_state_list[i].description;
              this.getCities(this.clnt_state_id, flag);
              break;
            }
          }
        }
      });
  }

  stateChange(clnt_state, flag){
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.search_city = this.clnt_state_list[i].description;
        this.clnt_city_list=[];
        this.clnt_city_id=undefined;
        this.clnt_location_id=undefined;
        this.search_location="";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag){
    this.clnt_city_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_city_url,JSON.stringify({state_id : state }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.clnt_city_list = data.json().cities;
          for (var i = 0; i < this.clnt_city_list.length; i++) {
            if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
              this.clnt_city_id = this.clnt_city_list[i].district_id;
              this.search_city = this.clnt_city_list[i].description;
              break;
            } else {
              this.search_city = this.clnt_city_list[0].description;
            }
          }
        }
      });
  }

  cityChange(clnt_city, flag){
    this.clnt_location_list=[];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.search_city = this.clnt_city_list[i].description;
        this.clnt_location_id=undefined;
        this.search_location="";
      }
    }
  }

  location_change(locat_desc) {
    this.clnt_location_list =[];
    if (locat_desc.target.value.toString() != null && locat_desc.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc.target.value.toString(),
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clnt_location_list = [];
      this.treatement_obj = "select";
      this.SecOpnListSpl = "select";
      this.showDoctorDetail_flag = false;
      this.showDoctorList_flag = false;
      this.showSpeciality_flag = false;
      this.showTreatment_flag = false;
      this.Doctor_list = [];
      this.doctor_detail_table_data = [];
      this.Homecare_Table_Data = [];
      this.Homesessiontable_Data = [];
      this.time_List_time = [];
      this.add_session_table_list = [];
      this.doc_Profile_Image = "";
      this.doctorname = "";
      this.doctor_Qualify_txt = "";
      this.treatementName = "";
      this.splityname = "";
      this.densub_Splityname = "";
      this.hospital_list_data = [];
      this.doctor_qualify_data = [];
      this.doc_Experiance = "";
      Helper_Class.setTreatmentList(undefined);
    }
  }

  location_selected(location){
    this.search_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.search_location = this.clnt_location_list[i].description;
      }
    }
    this.clnt_location_list = [];
  }

  treamentlistData() { //Treament
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.treatmentlistURL).subscribe(
      data => {
        for (var i = 0; i < data.json().medicare.length; i++) {
          this.treatmentlistData.push({
            medicare_name: data.json().medicare[i].medicare_name,
            medicare_id: data.json().medicare[i].medicare_id,
            treatementTypeimage: "assets/img/Medicare_type/" + data.json().medicare[i].medicare_name + ".png"
          });
        }
      },error => {});
  }

  treatement_type_check(treatement) {
    var send_data;
    if (treatement.medicare_name == "Allopathy") {
      this.showSpeciality_flag = true;
      this.showDoctorlist_flag = false;
      this.search_list_data.Treatment_Type = { medicare_id: treatement.medicare_id, medicare_name: treatement.medicare_name };

      send_data = {
          app_flow: Helper_Class.getDocSearch().app_flow, 
          doc_search: this.search_list_data ,
          login_search:Helper_Class.getDocSearch().login_search
        };
      Helper_Class.setTreatmentList(send_data);
    }else {
      this.showDoctorlist_flag = true;
      this.showSpeciality_flag = false;

      send_data = {
          app_flow: Helper_Class.getDocSearch().app_flow, 
          doc_search: { Treatment_Type: { medicare_id: treatement.medicare_id, medicare_name: treatement.medicare_name } },
          login_search:Helper_Class.getDocSearch().login_search
        }
      Helper_Class.setTreatmentList(send_data);
      this.showDoctorList_flag = false;
      this.Get_Doctor_Data_List();
    }
  }

  specializationGetlist() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.url_specialization).subscribe(
      data => {
        var emptySpecilization = [];

        for (var i = 0; i < data.json().specialization.length; i++) {
          if (data.json().specialization[i].spl_name != "-") {
            if (this.homecare != "0") {
              if (data.json().specialization[i].spl_name != "Dental") {
                this.specialization_list_id.push({
                  Css: false,
                  specialization_name: data.json().specialization[i].spl_name,
                  specialization_id: data.json().specialization[i].spl_id,
                  splty_img: "assets/img/Speciality/" + data.json().specialization[i].spl_name + ".png"
                });
              }
            } else {
              this.specialization_list_id.push({
                Css: false,
                specialization_name: data.json().specialization[i].spl_name,
                specialization_id: data.json().specialization[i].spl_id,
                splty_img: "assets/img/Speciality/" + data.json().specialization[i].spl_name + ".png"
              });
            }
          }
        }
      },error => {});
  }

  speciality_click(speciality) {
    this.doctor_search_data = Helper_Class.getDocSearch();

    CommonDataService.OpnSplId = speciality.specialization_id;
    var send_data;
    this.doctor_search_data = Helper_Class.getTreatmentList().doc_search;
    this.doctor_search_data.splity = { specialization_id: speciality.specialization_id, specialization_name: speciality.specialization_name };
    send_data = {
      homecare: this.homecare,
      app_flow: Helper_Class.getTreatmentList().app_flow,
      login_search:Helper_Class.getTreatmentList().login_search,
      doc_search: this.doctor_search_data
    }
    Helper_Class.setTreatmentList(send_data);
    this.Get_Doctor_Data_List();
  }

  Get_Doctor_Data_List() {// get doctors list
    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.Doctor_filter_div = true;
    this.autolocation_flag = true;

    if (Helper_Class.getTreatmentList().doc_search.splity != null) {
        var speciality_name = Helper_Class.getTreatmentList().doc_search.splity.specialization_name;
    }

    this.param_doctor_location = Helper_Class.getTreatmentList().doc_search.Search_Location;

    if (Helper_Class.getTreatmentList().doc_search.medicare_name != undefined) {
        var medtype = Helper_Class.getTreatmentList().doc_search.medicare_name;
    }

    var sec_opn = undefined;
    if (this.second_opinion_flag == "1") {
        sec_opn = "1"
    }

    this.data_toget_doc_list = {
        second_opinion: sec_opn,
        country: Helper_Class.getTreatmentList().doc_search.Search_Country,
        state: Helper_Class.getTreatmentList().doc_search.Search_State,
        city: Helper_Class.getTreatmentList().doc_search.Search_City,
        location: Helper_Class.getTreatmentList().doc_search.Search_Location,
        medicare_name: medtype,
        spl_name: speciality_name,
        home_care: this.homecare
    }

    this.http.post(this.get_doc_list_url, JSON.stringify(this.data_toget_doc_list), { headers: headers }).subscribe(
        data => {
            this.loader = true;
            if (data.json().info != null && data.json().info.length != 0) {
                this.Get_Doctor_Details(data.json().info);
                if (this.autolocation_flag == true) {
                    this.Client_location_auto();
                }
            } else {
                this.loader = true;
                this.doctornodata = true;
                this.Doctor_list = [];
            }
        },error => {});
  }

  Get_Doctor_Details(responselist) {// Get Doctor List
    this.Doctor_list = [];

    if (responselist.length == 0) {
        this.doctornodata = true;
        this.loader = true;
    } else {
        this.loader = true;
        this.doctornodata = false;
    }
    var name,hname,lname;

    for (var i = 0; i < responselist.length; i++) {
        var Docname = "";
        if (responselist[i].middle_name != undefined && responselist[i].middle_name != "") {
            Docname = responselist[i].first_name + " " + responselist[i].middle_name + " " + responselist[i].last_name;
        } else {
            Docname = responselist[i].first_name + " " + responselist[i].last_name;
        }

        var MedicareName = "";
        var gender_data = "";

        if (responselist[i].gender != null) {
          gender_data = responselist[i].gender;
        }

        if (responselist[i].medicare != null) {
          if (responselist[i].medicare.length != 0) {
            for (var j = 0; j < responselist[i].medicare.length; j++) {
              if (j == 0) {
                  MedicareName = responselist[i].medicare[j];
  
              } else {
                  MedicareName = MedicareName + ", " + responselist[i].medicare[j];
              }
            }
          }
        }

        var Splityname = "", splid = "0";

        if (responselist[i].specialization != null && responselist[i].specialization.length != 0) {
            Splityname = "";
            for (var j = 0; j < responselist[i].specialization.length; j++) {
                if (j == 0) {
                    Splityname = responselist[i].specialization[j];
                } else {
                    Splityname = Splityname + ", " + responselist[i].specialization[j];
                }
            }
            var Doct_Special_Row = true;
            var Medispecial = false;
        } else {
            var Doct_Special_Row = false;
            var Medispecial = true;
        }

        var Qualify = "";
        if (responselist[i].qualification.length != 0) {
            for (var j = 0; j < responselist[i].qualification.length; j++) {
                if (j == 0) {
                    Qualify = responselist[i].qualification[j].degree_name;
                } else {
                    Qualify = Qualify + ", " + responselist[i].qualification[j].degree_name;
                }
            }
        }

        var Hosp_detail = [];

        if (responselist[i].hospital.length != 0) {
            var Hosp_id_list = [];
            for (var j = 0; j < responselist[i].hospital.length; j++) {
                if (j == 0) {
                    Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                    Hosp_detail.push({
                        Hosp_name: responselist[i].hospital[j].hospital_name,
                        Hosp_Locat: responselist[i].hospital[j].location
                    });
                } else {
                    if (this.Array_contains(Hosp_id_list, responselist[i].hospital[j].hospital_id) == false) {
                        Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                        Hosp_detail.push({
                            Hosp_name: responselist[i].hospital[j].hospital_name,
                            Hosp_Locat: responselist[i].hospital[j].location
                        });
                    }
                }
            }
            for(var j = 0; j < responselist[i].hospital.length;j++){
                hname = responselist[i].hospital[j].hospital_name;
                lname = responselist[i].hospital[j].location;
              }
        }
        var experiece;
        if((parseInt(this.current_year) - parseInt(responselist[i].registeration_year))==0){
            experiece="<1";
        } else {
            experiece = parseInt(this.current_year) - parseInt(responselist[i].registeration_year);
        }
        this.Doctor_list.push({
            Prov_ID: responselist[i].prov_id,
            Doctor_Name: Docname,
            first_name :responselist[i].first_name,
            middle_name :responselist[i].middle_name,
            last_name :responselist[i].last_name,
            profile_image:ipaddress.Ip_with_img_address + responselist[i].profile_image,
            Prof_image: ipaddress.Ip_with_img_address + responselist[i].profile_image,
            Work_Year: experiece,
            Medicare: MedicareName,
            Speciality: Splityname,
            Qualification: Qualify,
            hospital_name : hname,
            location : lname,
            hospital : responselist[i] .hospital,
            Hospital: Hosp_detail,
            gender1: gender_data,
            check: false
        });
    }

    if (Medispecial == true) {
        var image_profig_cls = "nurse_image_hotspot";
    } else {
        var image_profig_cls = "image_hotspot";
    }
    this.showDoctorList_flag = true;
  }

  Array_contains(array, value) {
    var data = false;

    for (var i = 0; i < array.length; i++) {
        if (array[i] == value) {
            data = true;
            break;
        }
    }
    return data;
  }

  Client_location_auto() { // Web Filter
    if (Helper_Class.getTreatmentList().app_flow == "search_by_treatement" ||
        Helper_Class.getTreatmentList().app_flow == "search_by_speciality" ||
        Helper_Class.getTreatmentList().app_flow == "nurse_physio_app") {
        this.clnt_location = this.gservice.Client_login_detail_data.Client_Location;
        this.filter_search_location = this.gservice.Client_login_detail_data.Client_Location;
        this.filter_search_city = this.gservice.Client_login_detail_data.Client_City;
        this.filter_search_state = this.gservice.Client_login_detail_data.Client_State;
        this.filter_search_country = this.gservice.Client_login_detail_data.Client_Country;
    } else {
        this.clnt_location = Helper_Class.getTreatmentList().doc_search.Search_Location;
        this.filter_search_location = Helper_Class.getTreatmentList().doc_search.Search_Location;
        this.filter_search_city = Helper_Class.getTreatmentList().doc_search.Search_City;
        this.filter_search_state = Helper_Class.getTreatmentList().doc_search.Search_State;
        this.filter_search_country = Helper_Class.getTreatmentList().doc_search.Search_Country;
    }
    this.Get_City(this.filter_search_location, "1");
  }

  Get_City(data, flag) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
        data => {
            if (data.json().cities != null) {
                this.city_list_data = data.json().cities;
                if (flag != null && flag == "1") { // Client location
                    for (var a = 0; a < this.city_list_data.length; a++) {
                        if (this.city_list_data[a].city_desc == this.filter_search_city) {
                            this.filt_city = this.city_list_data[a].city_id;
                            this.filt_city_desc = this.city_list_data[a].city_desc;
                            this.City_change(this.city_list_data[a].city_id, "1");
                            break;
                        }
                    }
                } else { // auto binding
                    this.filt_city = this.city_list_data[0].city_id;
                    this.filt_city_desc = this.city_list_data[0].city_desc;
                    this.City_change(this.city_list_data[0].city_id, "0");
                }
            }
        },error => {});
  }

  City_change(data, flag) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
          data => {
            if (data.json().states != null) {
              this.state_list_data = data.json().states;
              if (flag != null && flag == "1") { // Client location
                  for (var b = 0; b < this.state_list_data.length; b++) {
                      if (this.state_list_data[b].state_desc == this.filter_search_state) {
                          this.filt_state = this.state_list_data[b].state_id;
                          this.filt_state_desc = this.state_list_data[b].state_desc;
                          this.State_change(this.state_list_data[b].state_id, "1");

                          break;
                      }
                  }

              } else { // auto binding
                  this.filt_state = this.state_list_data[0].state_id;
                  this.filt_state_desc = this.state_list_data[0].state_desc;
                  this.State_change(this.state_list_data[0].state_id, "0");
              }
            }
        },error => {});
    }
  }

  State_change(data, flag) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            this.country_list_data = data.json().countries;

            if (flag != null && flag == "1") { // Client location
              for (var c = 0; c < this.country_list_data.length; c++) {
                  if (this.country_list_data[c].country_desc == this.filter_search_country) {
                      this.filt_country = this.country_list_data[c].country_desc;
                      break;
                  }
              }
            } else { // Auto location
              this.filt_country = this.country_list_data[0].country_desc;
              this.Country_Change(this.filt_country);
            }
          }
        },error => {});
    }
  }

  Country_Change(data) {
    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.param_doctor_location = this.clnt_location;
        if (Helper_Class.getTreatmentList().doc_search.splity != null) {
            var speciality_name = Helper_Class.getTreatmentList().doc_search.splity.specialization_name;
        }

        var sec_opn = undefined;
        if (this.second_opinion_flag == "1") {
            sec_opn = "1"
        }

        this.data_toget_doc_list = {
            second_opinion: sec_opn,
            country: this.filt_country,
            state: this.filt_state_desc,
            city: this.filt_city_desc,
            location: this.clnt_location,
            medicare_name: Helper_Class.getTreatmentList().doc_search.Treatment_Type.medicare_name,
            spl_name: speciality_name,
            home_care: this.homecare
        }

        
        this.http.post(this.get_doc_list_url, JSON.stringify(this.data_toget_doc_list), { headers: headers }).subscribe(
            data => {
                

                if (data.json().info != null && data.json().info.length != 0) {
                    this.Get_Doctor_Details(data.json().info);

                } else {
                    this.Doctor_list = [];
                    this.loader = true;
                    this.doctornodata = true;
                }
            },
            error => {

            })

    //this.closeFabButton();
  }

  Doctor_View(doctor,value) { // View or Select Button Click
    Helper_Class.setDoctorProvID(doctor.Prov_ID);
    this.param_doctor_data = {
        doct_id: doctor.Prov_ID,
        location: this.param_doctor_location
    }
    var send_data;
    if (this.homecare != "0" || (Helper_Class.getTreatmentList().doc_search.homecare != "0" && Helper_Class.getTreatmentList().doc_search.homecare == "0")) {
        send_data = {
        homecare: this.homecare,
        login_search: Helper_Class.getTreatmentList().login_search,
        app_flow: Helper_Class.getTreatmentList().app_flow, 
        doctor: this.param_doctor_data, 
        doc_search: Helper_Class.getTreatmentList().doc_search
      }
      Helper_Class.setTreatmentList(send_data);
      Client_Helper.setPhysio_Doctor_List(doctor);
      this.showDoctorDetail_flag = true;
      this.showDoctorList_flag = false;
      // this.router.navigateByUrl("/doctor_detail");
    } else if (Helper_Class.getTreatmentList().doc_search.homecare != "0") {
        if (Helper_Class.getTreatmentList().doc_search.homecare == "1") {
          send_data = {
            homecare: this.homecare,
            login_search: Helper_Class.getTreatmentList().login_search,
            app_flow: Helper_Class.getTreatmentList().app_flow, 
            doctor: this.param_doctor_data, 
            doc_search: Helper_Class.getTreatmentList().doc_search
          }
          Helper_Class.setTreatmentList(send_data);
          Client_Helper.setPhysio_Doctor_List(doctor);
          this.showDoctorDetail_flag = true;
          this.showDoctorList_flag = false;
        //  this.router.navigateByUrl("/doctor_detail");

        //   this.clientview.sendMessage('physio_doctor_detail');
          this.detail_view_flag = true;
        } else {
          send_data = {
            app_flow: Helper_Class.getTreatmentList().app_flow,
            login_search: Helper_Class.getTreatmentList().login_search,
            doctor: this.param_doctor_data, 
            doc_search: Helper_Class.getTreatmentList().doc_search,
            homecare:this.homecare
          }
          Helper_Class.setTreatmentList(send_data);
          Client_Helper.setPhysio_Doctor_List(doctor);
          this.showDoctorDetail_flag = true;
          this.showDoctorList_flag = false;
          //this.router.navigateByUrl("/doctor_detail");
        //   this.clientview.sendMessage('physio_doctor_detail');
          this.detail_view_flag = true;
        }
    
    } else {
      send_data = {
        app_flow: Helper_Class.getTreatmentList().app_flow,
        doctor: this.param_doctor_data,
        login_search: Helper_Class.getTreatmentList().login_search,
        doc_search: Helper_Class.getTreatmentList().doc_search,
        homecare:this.homecare
      }
      Helper_Class.setTreatmentList(send_data);
      Client_Helper.setPhysio_Doctor_List(doctor);
      this.showDoctorDetail_flag = true;
      this.showDoctorList_flag = false;
      // this.router.navigateByUrl("/doctor_detail");
        // this.navCtrl.push('DoctorDetailPage', {
        //     app_flow: Helper_Class.getTreatmentList().app_flow,
        //     doctor: this.param_doctor_data,
        //     login_search: Helper_Class.getTreatmentList().login_search,
        //     doc_search: Helper_Class.getTreatmentList().doc_search,
        //     homecare:this.homecare
        // });
    }
          //doctor_detail
    if (Helper_Class.getTreatmentList().doc_search.homecare != null && Helper_Class.getTreatmentList().doc_search.homecare == "1") {
      this.Homecare_div = true;
    }else {
        this.Homecare_div = false;
    }
    this.get_doctor_detail_data = {
      doc_reg_id: Helper_Class.getTreatmentList().doctor.doct_id,
      location: Helper_Class.getTreatmentList().doctor.location,
      home_care: this.homecare,
      country: ipaddress.country_code
    }
    this.get_doctor_detail(this.current_date, this.current_time);
  }

  get_doctor_detail(curt_date, curt_time) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_doctordet_url, JSON.stringify(this.get_doctor_detail_data), { headers: headers }).subscribe(
        data => {
            if (data.json().prov_id != null) {
                this.doctor_ID = data.json().prov_id;

                if (data.json().non_avail != null) {
                    this.doc_unavailable_data = data.json().non_avail;
                }

                if (data.json().middle_name != null && data.json().middle_name != "") {
                    this.doctorname = data.json().first_name + " " + data.json().middle_name + " " + data.json().last_name;
                } else {
                    this.doctorname = data.json().first_name + " " + data.json().last_name;
                }

                this.doc_Profile_Image = ipaddress.Ip_with_img_address + data.json().profile_image;

                if (parseInt(curt_date.split('-')[2]) == parseInt(data.json().registeration_year)) {
                    this.doc_Experiance = "<1 year";
                } else {
                    this.doc_Experiance = (parseInt(curt_date.split('-')[2]) - parseInt(data.json().registeration_year)).toString() + " years";
                }

                if (data.json().qualification != null) {
                    this.doctor_qualify_data = data.json().qualification;

                    for (var i = 0; i < data.json().qualification.length; i++) {
                        if (i == 0) {
                            this.doctor_Qualify_txt = " - " + data.json().qualification[i].degree_name;
                        } else {
                            this.doctor_Qualify_txt = this.doctor_Qualify_txt + ", " + data.json().qualification[i].degree_name;
                        }
                    }
                }

                if (data.json().medicare != null) {
                    for (var j = 0; j < data.json().medicare.length; j++) {
                        if (j == 0) {
                            this.treatementName = data.json().medicare[j].medicare_name;
                        } else {
                            this.treatementName = this.treatementName + ", " + data.json().medicare[j].medicare_name;
                        }
                        this.doctor_Medicare_list.push(data.json().medicare[j]);
                    }

                    if (Helper_Class.getTreatmentList().app_flow == "search_hospital" || Helper_Class.getTreatmentList().app_flow == "search_by_home") {
                        this.doc_treatement_div = true;
                        this.treatement_type = this.doctor_Medicare_list[0].medicare_name;

                        if (this.doctor_Medicare_list.length > 1) {
                            this.doc_treatement_readonly = false;
                        } else {
                            this.doc_treatement_readonly = true;
                        }
                    }
                }

                if (data.json().specialization != null && data.json().specialization.length != 0) {
                    this.doctor_Speciality_list = [];
                    if (data.json().specialization != null) {
                        for (var k = 0; k < data.json().specialization.length; k++) {
                            if (data.json().specialization[k].specialization_name != "-") {
                                if (k == 0) {
                                    this.splityname = data.json().specialization[k].specialization_name;
                                } else {
                                    this.splityname = this.splityname + ", " + data.json().specialization[k].specialization_name;
                                }
                                this.doctor_Speciality_list.push(data.json().specialization[k]); // For Dropdown
                            } else {
                                this.other_med_spl = data.json().specialization[k];
                            }
                        }

                        if (this.doctor_Speciality_list.length != 0) {
                            this.treate_speciality = this.doctor_Speciality_list[0].specialization_name;
                        }
                    }

                    if (data.json().den_spl != null) {
                        this.densub_Splityname = this.gservice.get_String_from_Array(data.json().den_spl, "den_special_name");
                    }

                    if (Helper_Class.getTreatmentList().app_flow == "search_hospital" || Helper_Class.getTreatmentList().app_flow == "search_by_home") {
                        if (this.doctor_Speciality_list.length != 0) {
                            this.doc_special_div = true;
                        }
                    }
                }
            }

            if (data.json().consultation != null) {// Normal Consultation
                this.doctor_detail_table_data = [];
                this.hospital_list_data = [];

                for (var l = 0; l < data.json().consultation.length; l++) {
                    if (data.json().consultation[l].fees != "0") {
                        this.temp_fee = data.json().consultation[l].fees;
                    } else {
                        this.temp_fee = "NA";
                    }

                    if (this.gservice.get_Array_Contains(this.hospital_list_data, "hospital_id", data.json().consultation[l].hospital_id) == false) {
                      if (data.json().consultation[l].address2 != null && data.json().consultation[l].address2 != "") {
                            this.temp_address = data.json().consultation[l].address + ", " + data.json().consultation[l].address2;
                        } else {
                            this.temp_address = data.json().consultation[l].address;
                        }

                        this.hospital_list_data.push({
                            hospital_id: data.json().consultation[l].hospital_id,
                            Hospname: data.json().consultation[l].hospital,
                            Location: data.json().consultation[l].location,
                            Address_1: this.temp_address,
                            City: data.json().consultation[l].city,
                            fees: this.temp_fee,
                        });
                    }
                    this.hospital_name = this.hospital_list_data[0].Hospname;
                    this.hospital_id = this.hospital_list_data[0].hospital_id;

                    this.doctor_detail_table_data.push({
                        hospitalid: data.json().consultation[l].hospital_id,
                        hospital: data.json().consultation[l].hospital,
                        location: data.json().consultation[l].location,
                        day: this.gservice.get_Day(data.json().consultation[l].day),
                        day_num: data.json().consultation[l].day,
                        session: this.gservice.get_Session(data.json().consultation[l].session),
                        available_from: this.gservice.get_Timeformate(data.json().consultation[l].available_from),
                        available_to: this.gservice.get_Timeformate(data.json().consultation[l].available_to),
                        fees: this.temp_fee,
                        TokenorTime: data.json().consultation[l].token_flag,
                        Time_Duration: data.json().consultation[l].time_duration,
                        Avail_from: data.json().consultation[l].available_from,
                        Avail_to: data.json().consultation[l].available_to
                    });
                }

                if (this.doctor_detail_table_data[0].TokenorTime == "true") {
                    this.app_Type_Token = true;
                    this.time_card_flag = true;
                    this.app_Type_Time = false;
                    this.appoint_Status = "Token";
                    this.Session_Add(this.hospital_id);
                    var parts = curt_date.split('-');
                    this.Available_function(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
                } else {
                    this.typeorTime = "time";
                    this.time_card_flag = true;
                    this.app_Type_Token = false;
                    this.app_Type_Time = true;
                    this.Session_Add(this.hospital_id);
                    var parts = curt_date.split('-');
                    this.Available_function(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
                    this.app_Date_Change(curt_date);
                }

                if (Helper_Class.getTreatmentList().app_flow == "search_hospital") {
                    for (var o = 0; o < this.hospital_list_data.length; o++) {
                        if (this.hospital_list_data[o].Hospname == Helper_Class.getTreatmentList().doc_search.hosp_name) {
                            this.hospital_id = this.hospital_list_data[0].hospital_id;
                            this.hospital_name = this.hospital_list_data[o].Hospname;
                            this.hosp_selct_location = this.hospital_list_data[o].Location;
                            this.hosp_selct_fee = this.hospital_list_data[o].fees;
                            break;
                        }
                    }
                } else {
                    this.hospital_id = this.hospital_list_data[0].hospital_id;
                    this.hospital_name = this.hospital_list_data[0].Hospname;
                    this.hosp_selct_location = this.hospital_list_data[0].Location;
                    this.hosp_selct_fee = this.hospital_list_data[0].fees;
                }
            }

            if (data.json().home_consult != null) {// HomeCare Consult
                this.Homecare_Table_Data = [];
                this.Home_Session_Data = [];
                for (var i = 0; i < data.json().home_consult.length; i++) {
                    this.Homecare_Table_Data.push({
                        Session: this.gservice.get_Session(data.json().home_consult[i].session),
                        Avail_From: this.gservice.get_Timeformate(data.json().home_consult[i].available_from),
                        Avail_To: this.gservice.get_Timeformate(data.json().home_consult[i].available_to),
                        Fees: data.json().home_consult[i].fees,
                        Time_Inter: data.json().home_consult[i].time_interval,
                        avail_from: data.json().home_consult[i].available_from,
                        avail_to: data.json().home_consult[i].available_to
                    });
                    this.Home_Session_Data.push(this.gservice.get_Session(data.json().home_consult[i].session));
                    this.Session_Add("");
                    this.time_card_flag = true;
                    this.Homeapp_Date_Change(curt_date);
                }

                if (data.json().home_consult.length != 0) {
                    this.Nextbtn_Div = true;
                }
            }
        }, error => {});
  }

  Get_Client_Details(appflag) {
    this.client_detail_send_data = {
        flag: appflag,
        country: ipaddress.country_code,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.client_detail_url, JSON.stringify(this.client_detail_send_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (data.json().first_name != null) {
              this.RelationList = {
                  relation_id: data.json().relation_id,
                  relationship_name: data.json().relationship_name
              }

              this.SplList = {
                  specialization_id: data.json().specialization_id,
                  specialization_name: data.json().specialization_name
              };

              this.medicareList = {
                  medicare_id: data.json().medicare_id,
                  medicare_name: data.json().medicare_name
              }; // 25

              if (data.json().specialization_id != null) {
                  localStorage.setItem("Speciality_ID", data.json().specialization_id);
                  localStorage.setItem("Speciality_Name", data.json().specialization_name);
              }

              // Pharmacy booking flow
              var appoint_client_data = {
                  relation: this.RelationList,
                  clnt_fname: encrypt_decript.Decript(data.json().first_name),
                  clnt_mname: encrypt_decript.Decript(data.json().middle_name),
                  clnt_lname: encrypt_decript.Decript(data.json().last_name),
                  clnt_age: data.json().age,
                  clnt_dob: data.json().dob,
                  clnt_gender: encrypt_decript.Decript(data.json().gender),
                  clnt_blood: data.json().blood_desc,
                  clnt_blood_id: data.json().blood_id,
                  clnt_fg_name: encrypt_decript.Decript(data.json().family_guardian),
                  clnt_income: encrypt_decript.Decript(data.json().family_income),
                  clnt_occupation: encrypt_decript.Decript(data.json().occupation),
                  clnt_address1: data.json().address1,
                  clnt_address2: data.json().address2,

                  clnt_location_desc: data.json().location_desc,
                  clnt_city_desc: data.json().district_desc,
                  clnt_state_desc: data.json().state_desc,
                  clnt_country_desc: data.json().country_desc,

                  clnt_location: data.json().location,
                  clnt_city: data.json().city,
                  clnt_state: data.json().state,
                  clnt_country: data.json().country,

                  clnt_zipcode: data.json().zipcode,
                  clnt_cont_number: encrypt_decript.Decript(data.json().mobile),
                  clnt_symptom: data.json().symptom,
                  app_type: data.json().app_type,
                  sub_rel_id: data.json().sub_relation,
                  // treatement_type: this.medicareId,
                  treatement_type: this.medicareList,
                  // medicare_id: this.medicareId,
                  speciality: this.SplList,
              }

              this.gservice.set_appoint_client_data(appoint_client_data); // data for helper class
          }
        },
        error => {});
  }

  Session_Add(Hospital) {
    this.visiting_Session_Data = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    if (Hospital == "") {
      var get_date = Date_Formate(this.Home_App_Date);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.current_time);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.Home_Session_Data.length; a++) {
        if (this.current_date.trim() == get_date.trim()) // Today 
        {
          if (this.Home_Session_Data[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visiting_Session_Data.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.Home_Session_Data[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visiting_Session_Data.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.Home_Session_Data[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visiting_Session_Data.push({
              description: "Evening",
              id: "3"
            });
          }

        } else {
          if (this.Home_Session_Data[a] == "Morning") {
            this.visiting_Session_Data.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.Home_Session_Data[a] == "Afternoon") {
            this.visiting_Session_Data.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.Home_Session_Data[a] == "Evening") {
            this.visiting_Session_Data.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }

    } else {
      var get_date:string = Date_Formate(this.Appointment_Date);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.current_time);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.doctor_detail_table_data.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctor_detail_table_data[a].day_num;

        let dayflag = false;
        if (dayName == this.doctor_detail_table_data[a].day) {
          dayflag = true;

        } else if (this.doctor_detail_table_data[a].day == "All Days") {
          dayflag = true;

        } else if (sel_day_num != 1 && day_num == 8) {
          dayflag = true;
        }

        if (this.doctor_detail_table_data[a].hospitalid == Hospital
          && dayflag == true) {

          if (this.current_date.trim() == get_date.trim()) // Today 
          {
            if (this.doctor_detail_table_data[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visiting_Session_Data.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctor_detail_table_data[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visiting_Session_Data.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctor_detail_table_data[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visiting_Session_Data.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctor_detail_table_data[a].session == "Morning") {
              this.visiting_Session_Data.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctor_detail_table_data[a].session == "Afternoon") {
              this.visiting_Session_Data.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctor_detail_table_data[a].session == "Evening") {
              this.visiting_Session_Data.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
  }

  ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  Check_Availabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_sel_date);

    if (this.doc_unavailable_data.length != 0) {
        for (var i = 0; i < this.doc_unavailable_data.length; i++) {
            if (this.doc_unavailable_data[i].full_day != null) {
                if (check_date >= new Date(this.doc_unavailable_data[i].from_date) 
                    && check_date <= new Date(this.doc_unavailable_data[i].to_date)) {
                    Available_flag = false;
                    break;
                }
            } else {
                if (sess != null) {
                    // get unavialable date
                    var From_Split = this.doc_unavailable_data[i].from_time.split(':');
                    var To_Split = this.doc_unavailable_data[i].to_time.split(':');

                    var t1 = new Date(check_date);
                    t1.setHours(From_Split[0]);
                    t1.setMinutes(From_Split[1]);

                    var t2 = new Date(check_date); // t_time
                    t2.setHours(To_Split[0]);
                    t2.setMinutes(To_Split[1]);

                    var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
                    var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

                    var get_time1 = this.ConvertTimeformat("24", get_f_time);
                    var get_time2 = this.ConvertTimeformat("24", get_t_time);

                    // get available date
                    var From_Split1 = get_time1.split(':');
                    var To_Split1 = get_time2.split(':');

                    var get_f_time1 = get_time1.split(':');
                    var get_f_time2 = get_time2.split(':');

                    var f_time1 = new Date(check_date);
                    f_time1.setHours(parseInt(get_f_time1[0]));
                    f_time1.setMinutes(parseInt(get_f_time1[1]));
                    
                    var get_chk_date = this.gservice.get_Date_Formate(get_sel_date);
                    var get_f_date = this.gservice.get_Date_Formate(this.doc_unavailable_data[i].from_date);
                    var get_t_date = this.gservice.get_Date_Formate(this.doc_unavailable_data[i].from_date);

                    if (get_chk_date.toString() == get_f_date.toString() 
                        && sess == this.doc_unavailable_data[i].session 
                        || get_chk_date.toString() == get_t_date.toString() 
                        && sess == this.doc_unavailable_data[i].session) {

                        if (f_time1 > t1 && f_time1 < t2) {
                            Available_flag = false;
                        }

                        if ((f_time1 == t1 && f_time1 <= t2)) {
                            Available_flag = false;
                        }

                        if ((f_time1 >= t1 && f_time1 == t2)) {
                            Available_flag = false;
                        }

                        if ((f_time1 == t1 && f_time1 == t2)) {
                            Available_flag = false;
                        }
                    }

                    if (get_chk_date.toString() == get_f_date.toString() 
                        && get_chk_date.toString() == get_t_date.toString() 
                        && sess == this.doc_unavailable_data[i].session) {

                        if (f_time1 > t1 && f_time1 < t2) {
                            Available_flag = false;
                        }

                        if ((f_time1 == t1 && f_time1 < t2)) {
                            Available_flag = false;
                        }

                        if ((f_time1 > t1 && f_time1 == t2)) {
                            Available_flag = false;
                        }

                        if ((f_time1 == t1 && f_time1 == t2)) {
                            Available_flag = false;
                        }
                    }
                }
            }
        }
    }
    return Available_flag;
  }

  Available_function(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.doctor_detail_table_data.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctor_detail_table_data[i].day);
      if (this.doctor_detail_table_data[i].hospital == this.hospital_name) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);

        var Avtotimesplit = [];
        Avtotimesplit = this.doctor_detail_table_data[i].Avail_to.split(':');

        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);

        if (this.doctor_detail_table_data[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.current_date == (curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctor_detail_table_data[i].day_num) {
            if (this.current_date == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.current_date == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.Check_Availabilty(curtdate, null, null, null);
    if (this.visiting_Session_Data.length == 0) {
      Available_flag = false;
    }

    if (Available_flag == false) {
      this.Available_Div = false;
      this.NonAvailable_Div = true;
      this.Nextbtn_Div = false;
    } else {
      this.Available_Div = true;
      this.NonAvailable_Div = false;
      this.Nextbtn_Div = true;
    }
  }

  visit_Session_Change(session, appdate) {
    var session1 = encrypt_decript.Encript(session).toString()
    this.time_List = [];

    if (this.typeorTime == "time") {
      for (var i = 0; i < this.doctor_detail_table_data.length; i++) {
        if (session == this.doctor_detail_table_data[i].session) {
          var From_Split = this.doctor_detail_table_data[i].Avail_from.split(':');
          var To_Split = this.doctor_detail_table_data[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.current_time);
          var curr_datetime = new Date(datetime + " " + this.current_time);
          var curr_get_ftime = new Date(datetime + " " + this.doctor_detail_table_data[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);

          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.Check_Availabilty(appdate, session, chk_time, end_time);

          if (Available_flag == true && this.current_date == appdate && curr_get_ftime > curr_datetime) {
            this.time_List.push(New_time1);
          } else {
            if (Available_flag == true && this.current_date != appdate) {
              this.time_List.push(New_time1);
            }
          }

          var substr = this.doctor_detail_table_data[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.current_date == appdate) {
              var Now_Split = this.current_time.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctor_detail_table_data[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.Check_Availabilty(appdate, session, chk_time1, end_time);

            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctor_detail_table_data[i].Avail_to)) {
                this.time_List.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  app_Date_Change(appdate) {
    this.time_List = []; // clear app time list

    if (this.current_date != appdate) {
        var gsDayNames = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];

        var get_date = appdate.split('-');
        var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
        var dayName = gsDayNames[result.getDay()];
        var get_sel_day_num = this.gservice.get_Day_num(dayName);
        var Available_flag_1 = false;

        for (var i = 0; i < this.doctor_detail_table_data.length; i++) {
            if (this.doctor_detail_table_data[i].hospital_id == this.hospital_id) {
                if (this.doctor_detail_table_data[i].day_num == "8" || (get_sel_day_num != 1 && this.doctor_detail_table_data[i].day_num == "9")) {
                    Available_flag_1 = true;
                } else {
                    if (((new Date(appdate)).getDay() + 1) == this.doctor_detail_table_data[i].day_num) {
                        Available_flag_1 = true;
                    }
                }
            }
        }

        if (Available_flag_1 == false) {
            this.Available_Div = false;
            this.NonAvailable_Div = true;
            this.Nextbtn_Div = false;
        } else {
            this.Available_Div = true;
            this.NonAvailable_Div = false;
            this.Nextbtn_Div = true;
        }

        this.Session_Add(this.hospital_id);
        if (this.typeorTime == "time") {
          this.visit_Session_Change_ForTime(appdate);
          this.app_Type_Token = false;
        } else {
          this.visit_Session_Change(this.visit_Session, appdate);
          this.app_Type_Token = true;
        }

        this.Available_function(Date_Formate(appdate), this.current_time);
      } else {
        this.Session_Add(this.hospital_id);
        if (this.typeorTime == "time")
          this.visit_Session_Change_ForTime(appdate);
        else
          this.visit_Session_Change(this.visit_Session, appdate);
        this.Available_function((appdate), this.current_time);
      }
  }

  hospital_change(Hospital) {
    this.Session_Add(Hospital);
    this.Available_function(Date_Formate(this.Appointment_Date), this.current_time);
    this.visit_Session_Change(this.visit_Session, this.current_date);

    for (var r = 0; r < this.hospital_list_data.length; r++) {
        if (this.hospital_list_data[r].hospital_id == Hospital) {
            this.hospital_id = this.hospital_list_data[r].hospital_id;
            this.hosp_selct_location = this.hospital_list_data[r].Location;
            this.hosp_selct_fee = this.hospital_list_data[r].fees;
            break;
        }
    }
  }

  Homeapp_Date_Change(appdate) {
    this.Home_Time_List = [];
    this.home_Visitsession_change(appdate);
  }

  home_Visitsession_change(appdate) {
    this.Home_Time_List = [];
    var time_t =[];//
    var session;
    for(var j=0; j<this.visiting_Session_Data.length; j++){
      session="";
      session = this.visiting_Session_Data[j].description;
      time_t=[];
      
      for (var i = 0; i < this.Homecare_Table_Data.length; i++) {
        if (session == this.Homecare_Table_Data[i].Session) {
          var From_Split = this.Homecare_Table_Data[i].avail_from.split(':');
          var To_Split = this.Homecare_Table_Data[i].avail_to.split(':');
          var parts = (appdate).split('-');
          var fromday = new Date();
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');

          if (this.current_date == appdate && fromday < today) { //&& $scope.curr_get_ftime > $scope.curr_datetime            time_t.push(New_time1);
            time_t.push(New_time1);
          } else {
            if (fromday < today) {
              if (this.current_date != appdate) {
                time_t.push(New_time1);
              }
            }
          }

          var substr = this.Homecare_Table_Data[i].Time_Duration;

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.current_date == appdate) {
              var Now_Split = this.current_time.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.Homecare_Table_Data[i].Time_Inter));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.Check_Availabilty(appdate, session, chk_time1, end_time);

            if (Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.Homecare_Table_Data[i].Avail_To)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      
      if(time_t.length !=0){
        this.Home_Time_List.push({
          session:session,
          time:time_t
        });
      }
    } 
  }
  
  Home_Table_Add(){ // Homecare table add// Homecare Visiting Table data
    var Check_Valid = false;

    if(this.home_App_Time == undefined){
      this.home_App_Time = this.visit_Time;
    }

    if (this.current_date == this.Home_App_Date) {// Current Date
      var Timesplit = this.current_time.split(':');
      var Current_Dt = new Date();
      Current_Dt.setHours(parseInt(Timesplit[0]));
      Current_Dt.setMinutes(parseInt(Timesplit[1]));
      
      var Timesplit1 = this.home_App_Time.split(':');
      var Select_Dt = new Date();
      Select_Dt.setHours(Timesplit1[0]);
      Select_Dt.setMinutes(Timesplit1[1]);

      if (Current_Dt > Select_Dt) {
          Check_Valid = true;
      }
    }

    if(this.Home_App_Date != undefined){}
    if (Check_Valid == false) {
        var book_date = this.Home_App_Date.toString().split("-");
        var app_time = Time_Formate(this.home_App_Time);
        var get_time = app_time.split(' ');
        var get_time1 = get_time[0] + ":00";
        var Usetype;
        Usetype = "doctor";
        var sessionval;
        if(this.home_Visit_Session != undefined){
            sessionval = this.home_Visit_Session;
        } else {
            sessionval = this.visit_Session;
        }
        this.home_add_send_data = {
            type: Usetype,
            doc_reg_id: Helper_Class.getDoctorProvID(),
            appointment_date: book_date[2] + "-" + book_date[1] + "-" + book_date[0],
            from_time: get_time1,
            session: encrypt_decript.Encript(sessionval),
            hosp_name: this.hospital_name,
            hosp_id: this.hospital_id,
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "appointment/getappexist/", JSON.stringify(this.home_add_send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().result == true) {
                    this.toastr.error(Message_data.docNotAvailableTime);
                } else {
                    var tw_formate;
                    for (var g = 0; g < this.Home_Time_List.length; g++) {
                      if (this.Home_Time_List[g].TFformate == this.home_App_Time) {
                        tw_formate = this.Home_Time_List[g].Twformae;
                        break;
                      }
                    }

                    if (this.Homesessiontable_Data.length == 0) {
                        this.Homesessiontable_Data.push({
                            Date: this.Home_App_Date,
                            Session: sessionval,
                            Time: tw_formate,
                            New_Time: this.home_App_Time,
                            app_time: get_time1,
                            hosp_name: this.hospital_name,
                            hosp_id: this.hospital_id,
                        });
                    } else {
                        var contains_flag = false;
                        for (var t = 0; t < this.Homesessiontable_Data.length; t++) {
                            if ((this.Home_App_Date == this.Homesessiontable_Data[t].Date) &&
                                (sessionval == this.Homesessiontable_Data[t].Session) &&
                                (get_time1 == this.Homesessiontable_Data[t].app_time)) {
                                contains_flag = true;
                                break;
                            }
                        }

                        if (contains_flag == false) {
                            this.Homesessiontable_Data.push({
                                Date: this.Home_App_Date,
                                Session: sessionval,
                                Time: tw_formate,
                                New_Time: this.home_App_Time,
                                app_time: get_time1,
                                hosp_name: this.hospital_name,
                                hosp_id: this.hospital_id,
                            });
                        } else {
                            this.toastr.error(Message_data.dataAlreadyExist);
                        }
                    }
                }
            },error => {});
        this.home_App_Time=undefined;
    } else {
        this.toastr.error(Message_data.enterValidTime);
    }
  }

  Delete_Session(index) {
    if(this.homecare != "1"){
      for (var h = 0; h < this.add_session_table_list.length; h++) {
        if (h == index) {
            this.add_session_table_list.splice(h, 1);
            break;
        }
      }
    }else{
      for (var h = 0; h < this.Homesessiontable_Data.length; h++) {
        if (h == index) {
            this.Homesessiontable_Data.splice(h, 1);
            break;
        }
      }
    }
  }

  doctor_Detail_Next() {
    if (this.typeorTime == "time" && this.visit_Time == undefined) {
        this.toastr.error(Message_data.sltConsultationTime);
    } else {
      var valid_flag = false;
      if (this.homecare != "1") {
        if (this.hospital_name == undefined || this.hospital_name == "") {
            this.toastr.error(Message_data.sltHosptl);
            var valid_flag = true;
        } else if (this.visit_Session == undefined || this.visit_Session == "" || this.visit_Session == "Select") {
            this.toastr.error(Message_data.sltSession);
            var valid_flag = true;
        }
      }

      if (valid_flag == false) {
        for (var y = 0; y < this.hospital_list_data.length; y++) {
          if (this.hospital_list_data[y].hospital_id == this.hospital_id) {
              this.hospital_id = this.hospital_list_data[y].hospital_id;
              this.hospital_location = this.hospital_list_data[y].Location;
              this.hospital_city = this.hospital_list_data[y].City;
              break;
          }
        }

        if (this.homecare != "1"){ // Normal
          if (this.typeorTime == "time"){ // Time doctor
              var app_time = this.visit_Time.toString().split(" ");
              this.tokenorTime = this.visit_Time.toString();
              this.time_or_token_flag = false;
              this.appexist_url = ipaddress.getIp + "appointment/getappexist/";
              this.data_for_appexist = {
                  type: "doctor",
                  doc_reg_id: this.doctor_ID,
                  appointment_date: (this.Appointment_Date),
                  from_time: app_time[0] + ":00",
                  session: encrypt_decript.Encript(this.visit_Session).toString(),
              }
          } else {// Token doctor
              this.tokenorTime = "Token";
              this.time_or_token_flag = true;

              this.appexist_url = ipaddress.getIp + "appointment/doctoken/";
              this.data_for_appexist = {
                  doc_reg_id: this.doctor_ID,
                  date: (this.Appointment_Date),
                  hospital_id: this.hospital_id,
                  session: encrypt_decript.Encript(this.visit_Session).toString(),

              }
          }

          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(this.appexist_url, JSON.stringify(this.data_for_appexist), { headers: headers }).subscribe(
              response => {
                
                  var data = response.json();
                  if (data.result != null) {
                      if (this.typeorTime == "type" && data.result != "true") {
                          this.toastr.error(Message_data.sryApptClosedForSession);

                      } else if (this.typeorTime == "time" && data.result == true) {
                          this.toastr.error(Message_data.timeSlotAllotedToSomeone);

                      } else {
                          this.appoint_doctor_data = {
                              doc_id: this.doctor_ID,
                              doc_name: this.doctorname,
                              session: this.visit_Session,
                              date: this.Appointment_Date,
                              hosp_name: this.hospital_name,
                              hosp_id: this.hospital_id,
                              hosp_locat: this.hospital_location,
                              hosp_city: this.hospital_city,
                              tokenortime: this.tokenorTime,
                              typeortime: this.typeorTime,
                              token_flag: this.time_or_token_flag,
                              homecare: this.homecare
                          }
                          


                              
                              this.appoint_doctor_data.treatement_type = Helper_Class.getTreatmentList().doc_search.Treatment_Type;
                              if (Helper_Class.getTreatmentList().doc_search.splity != null) {
                                  this.appoint_doctor_data.speciality = Helper_Class.getTreatmentList().doc_search.splity;

                              } else {
                                  this.appoint_doctor_data.speciality = this.other_med_spl;
                              }
                              this.gservice.set_appoint_doctor_data(this.appoint_doctor_data); // set data to helper_class
                              this.router.navigateByUrl("/loginpage");
                              // this.navCtrl.push('LoginPage', { app_flow: Helper_Class.getTreatmentList().app_flow });
                        }
                  }
              },
              error => {
                  
              }
          )
        } else {// homecare appointment
            if (this.add_session_table_list.length != 0) {
                this.appoint_doctor_data = {
                    hosp_id: this.hospital_id,
                    hosp_locat: this.hospital_location,
                    hosp_name: this.hospital_name,
                    tokenortime: this.tokenorTime,
                    typeortime: this.typeorTime,
                    doc_id: this.doctor_ID,
                    doc_name: this.doctorname,
                    session: this.home_Visit_Session,
                    date: this.Home_App_Date,
                    homecare_data: this.add_session_table_list,
                    homecare : this.homecare
                }

                this.appoint_doctor_data.treatement_type = Helper_Class.getTreatmentList().doc_search.Treatment_Type;
                if (Helper_Class.getTreatmentList().doc_search.splity != null) {
                    this.appoint_doctor_data.speciality = Helper_Class.getTreatmentList().doc_search.splity;
                }
                this.gservice.set_appoint_doctor_data(this.appoint_doctor_data); // set data to helper_class
                if (Helper_Class.getTreatmentList().app_flow == "without_Normal") {
                  this.router.navigateByUrl("/loginpage");
                } else {
                    // this.navCtrl.push('DoctorAppointmentConformPage', {
                    //     homecare: this.homecare,
                    //     app_flow: Helper_Class.getTreatmentList().app_flow,
                    //     doctor: Helper_Class.getTreatmentList().doctor,
                    //     doc_search: Helper_Class.getTreatmentList().doc_search,
                    //     login_search: this.navParams.get('login_search'),
                    // });
                }
            }
            else {
                this.toastr.error(Message_data.addHomeCaredata);
            }
        }
      }
    }
  }

  Date_click(data,value) {// layout
    this.time_card_flag = true;
    if (data != null) {
      if (this.homecare == "1") {
        this.Home_App_Date = data;
        this.Session_Add("");
        this.Homeapp_Date_Change(Date_Formate(data));
      } 
      if (this.homecare == "0") {
        this.Appointment_Date = data;
        this.Session_Add(this.hospital_id);
        this.app_Date_Change(Date_Formate(data));
      }
    }
  }

  visit_Session_Change_ForTime(appdate){
    this.time_List_time = [];
    var time_t =[];//
    var session;

    for(var j=0; j < this.visiting_Session_Data.length; j++){
      session ="";
      session = this.visiting_Session_Data[j].description;
      time_t = [];
      
      for (var i = 0; i < this.doctor_detail_table_data.length; i++) {
        if (session == this.doctor_detail_table_data[i].session) {
          var From_Split = this.doctor_detail_table_data[i].Avail_from.split(':');
          var To_Split = this.doctor_detail_table_data[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.current_time);
          var curr_datetime = new Date(datetime + " " + this.current_time);
          var curr_get_ftime = new Date(datetime + " " + this.doctor_detail_table_data[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          // this.time_List = [];
          
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');

          var Available_flag = this.Check_Availabilty(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.current_date == appdate && curr_get_ftime > curr_datetime) {
            time_t.push(New_time1);
          } else {
            if (Available_flag == true && this.current_date != appdate) {
              time_t.push(New_time1);
            }
          }

          var substr = this.doctor_detail_table_data[i].Time_Duration;

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.current_date == appdate) {
              var Now_Split = this.current_time.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctor_detail_table_data[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.Check_Availabilty(appdate, session, chk_time1, end_time);

            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctor_detail_table_data[i].Avail_to)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      
      if(time_t.length !=0){
        this.time_List_time.push({
          session:session,
          time:time_t
        });
      }
    } 
  }

  Add_Session(){
    var flag:boolean = false;
    if(this.add_session_table_list.length != 0){
      for(var i =0 ; i < this.add_session_table_list.length; i++){
        if(this.visit_Time == this.add_session_table_list[i].visit_Time){
          this.toastr.error(Message_data.timeSlotsAlrdyBooked);
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    if(flag == false){
      var apptdate;
      if(this.homecare == '0'){
        apptdate = Date_Formate(this.Appointment_Date)
      }else{
        apptdate = Date_Formate(this.Home_App_Date)
      }
      this.add_session_table_list.push({
        date: apptdate,
        session : this.visit_Session,
        time : this.setTimeAddSession(this.visit_Time),
        visit_Time: this.visit_Time
      })
    }
  }

  setTimeAddSession(visitTime){
    if(visitTime != undefined){
      var table_visit_time,tokenorTime;
      var app_time = visitTime.toString().split(" ");
      tokenorTime = visitTime.toString();

      if (tokenorTime != "true") {
        if (tokenorTime != undefined) {
          table_visit_time = app_time[0] + ":00";
        }
      } else {
        table_visit_time = "1";
      }
    } else {
      table_visit_time = "1";
    }
    return table_visit_time;
  }

  Onappttimeclick(e,s,index){
    this.add_session_btn_flag = true;
    this.visit_Session = s;
    this.visit_Time = e;
    var t;
    if(this.homecare == '0'){
      for(var i= 0; i<this.time_List_time.length; i++){
        t=[];
        t= this.time_List_time[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("time_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("time_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }
    }else{
      for(var i= 0; i<this.Home_Time_List.length; i++){
        t=[];
        t= this.Home_Time_List[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("hometime_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("hometime_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }

    }
  }
}
