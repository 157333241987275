import { Component, OnInit,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-clinical',
  templateUrl: './physio-clinical.component.html',
  styleUrls: ['./physio-clinical.component.scss']
})
export class PhysioClinicalComponent implements OnInit {
  public clinicalFlag = "min";
  subscription:Subscription;
  public toggHeartAttackFlag;
  public toggStrokeFlag;
  public toggEndoFlag;
  public toggHyperFlag;
  public toggTuberFlag;
  public toggHepatFlag;
  public toggArterFlag;
  public toggAutoFlag;
  public toggHemoFlag;
  public toggHivFlag;
  public toggGlaucomaFlag;
  public toggDiabFlag;
  public toggDiab: string;
  public toggStorke: string;
  public toggEndo: string;
  public toggHyper: string;
  public toggTuber: string;
  public toggHepat: string;
  public toggHeartAttack: string;
  public toggArter: string;
  public toggAuto: string;
  public toggHemo: string;
  public toggHiv: string;
  public toggGlaucoma: string;
  public familyOther;
  public lifeStyleId;
  public family;
  public client: boolean = false;
  public getHopitalfieldsRet;
  public diabeticFlag: boolean;
  public diabeticLabel;
  public strokeLabel;
  public strokeFlag: boolean;
  public endrocineLabel;
  public endrocineFlag: boolean;
  public hypertensionLabel;
  public hyperFlag: boolean;
  public tuberculosisLabel;
  public tuberculosisFlag: boolean;
  public hepatitisLabel;
  public hepatisFlag: boolean;
  public heartAttackLabel;
  public heartAttackFlag: boolean;
  public arterialLabel;
  public arterialFlag: boolean;
  public autoImmueLabel;
  public autoImmueFlag: boolean;
  public hempoPhilaLabel;
  public hemophilaFlag: boolean;
  public hivLabel;
  public hivFlag: boolean;
  public glaucomaLabel;
  public glaucomaFlag: boolean;
  public otherLabel;
  public otherFlag: boolean;
  public physioCaseHistId: string;
  public locationList = [];
  public charPainList = [];
  public radiationList = [];
  public aggravatingFactorsList = [];
  public painSymptomList = [];
  public painCauseList = [];
  public doUFeelPainLabel: string;
  public locationLabel: string;
  public charOfPainLabel: string;
  public radiationLabel: string;
  public durationLabel: string;
  public aggravatLabel: string;
  public relieveLabel: string;
  public associatedSymptomLabel: string;
  public intensityLabel: string;
  public causeOfPainLabel: string;
  public location: string;
  public character: string;
  public radiation: string;
  public duration: string;
  public aggravat: string;
  public relieve: string;
  public assosymp: string;
  public intensity: string;
  public paincause: string;
  public pain: string;
  public doUFeelPainFlag: boolean;
  public locationFlag: boolean;
  public charOfPainFlag: boolean;
  public radiationFlag: boolean;
  public durationFlag: boolean;
  public aggravatFlag: boolean;
  public relieveFlag: boolean;
  public associatedSymptomFlag: boolean;
  public intensityFlag: boolean;
  public causeOfPainFlag: boolean;
  public charPainDisabledFlag: boolean;
  public causeOfPainRequiredFlag:boolean;
  public intensityRequiredFlag:boolean;
  public assosymp_required:boolean;
  public associatedSymptomRequiredFlag:boolean;
  public aggravatRequiredFlag:boolean;
  public durationRequiredFlag:boolean;
  public radiationRequiredFlag:boolean;
  public charOfPainRequiredFlag:boolean;
  public locationRequiredFlag:boolean;

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  constructor(public dropdown_dialog:MatDialog,public dialog:MatDialog,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService)   {
    this.doUFeelPainFlag = true;
    this.locationFlag = true;
    this.charOfPainFlag = true;
    this.radiationFlag = true;
    this.durationFlag = true;
    this.aggravatFlag = true;
    this.relieveFlag = true;
    this.associatedSymptomFlag = true;
    this.intensityFlag = true;
    this.causeOfPainFlag = true;
    this.charPainDisabledFlag = true;
    this.causeOfPainRequiredFlag=true;
    this.intensityRequiredFlag=true;
    this.assosymp_required=true;
    this.associatedSymptomRequiredFlag=true;
    this.aggravatRequiredFlag=true;
    this.durationRequiredFlag=true;
    this.radiationRequiredFlag=true;
    this.charOfPainRequiredFlag=true;
    this.locationRequiredFlag=true;
    this.diabeticFlag = true;
    this.strokeFlag = true;
    this.endrocineFlag = true;
    this.hyperFlag = true;
    this.tuberculosisFlag = true;
    this.hepatisFlag = true;
    this.heartAttackFlag = true;
    this.arterialFlag = true;
    this.autoImmueFlag = true;
    this.hemophilaFlag = true;
    this.hivFlag = true;
    this.glaucomaFlag = true;
    this.otherFlag = true;
    this.toggDiab = "no";
    this.toggStorke = "no";
    this.toggEndo = "no";
    this.toggHyper = "no";
    this.toggTuber = "no";
    this.toggHepat = "no";
    this.toggHeartAttack = "no";
    this.toggArter = "no";
    this.toggAuto = "no";
    this.toggHemo = "no";
    this.toggHiv = "no";
    this.toggGlaucoma = "no";
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.clinicalFlag = message;
      }
    });
    if(Physio_Helper.getRet() != null){
      if (Physio_Helper.getRet().case_hist_id != undefined) {
        this.physioCaseHistId = Physio_Helper.getRet().case_hist_id;
      }
      if (Physio_Helper.getRet().life_style_id != undefined) {
        this.lifeStyleId = Physio_Helper.getRet().life_style_id;
      }
    }
    for (var i = 0; i < Physio_Helper.getHopitalfieldsRet().length; i++) {
      if (Physio_Helper.getHopitalfieldsRet()[i].page_name == "Clinical") {
        if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
          if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Physio_Helper.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Diabetics") {
                this.diabeticLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.diabeticFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Stroke") {
                this.strokeLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.strokeFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Endocrine") {
                this.endrocineLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.endrocineFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Hypertension") {
                this.hypertensionLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hyperFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Tuberculosis") {
                this.tuberculosisLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.tuberculosisFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Hepatitis") {
                this.hepatitisLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hepatisFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Heart attack") {
                this.heartAttackLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.heartAttackFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Arterial") {
                this.arterialLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.arterialFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Autoimmune") {
                this.autoImmueLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.autoImmueFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Hemophilia") {
                this.hempoPhilaLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hemophilaFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "HIV") {
                this.hivLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hivFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Glaucoma") {
                this.glaucomaLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.glaucomaFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Others") {
                this.otherLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.otherFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Do you feel pain in the chest") {
                this.doUFeelPainLabel = "Do you feel pain ?";
                this.doUFeelPainFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Location") {
                this.locationLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.locationFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Character of pain") {
                this.charOfPainLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.charOfPainFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Radiation") {
                this.radiationLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.radiationFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Duration") {
                this.durationLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.durationFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Aggravating factors") {
                this.aggravatLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.aggravatFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Relieving factors") {
                this.relieveLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.relieveFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Associated Symptoms") {
                this.associatedSymptomLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.associatedSymptomFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Intensity of pain") {
                this.intensityLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.intensityFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Cause of pain") {
                this.causeOfPainLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.causeOfPainFlag = false;
              }
            }
          }
        }
      }
    }
    this.getChestpainLocationDetails();
  }

  getChestpainLocationDetails() {
    this.locationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/painloc',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_locations.length;i++){
              this.locationList.push({
                location_id: obj.pain_locations[i].location_id,
                location_desc: obj.pain_locations[i].location_desc,
              })
            }
            this.getChestpainCharacters();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getChestpainCharacters() {
    this.charPainList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/paincar',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_characters.length;i++){
              this.charPainList.push({
                character_id: obj.pain_characters[i].character_id,
                character_desc: obj.pain_characters[i].character_desc,
              })
            }
          }
          this.getChestpainRadiations();
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getChestpainRadiations() {
    this.radiationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/painrad',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_radiations.length;i++){
              this.radiationList.push({
                radiation_id: obj.pain_radiations[i].radiation_id,
                radiation_desc: obj.pain_radiations[i].radiation_desc,
              })
            }
            this.getChestpainAgrelFactors();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getChestpainAgrelFactors() {
    this.aggravatingFactorsList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/painagrel',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_agrels.length;i++){
              this.aggravatingFactorsList.push({
                agrrelfact_id: obj.pain_agrels[i].agrrelfact_id,
                agrrelfact_desc: obj.pain_agrels[i].agrrelfact_desc,
              })
            }
            this.aggravat = this.aggravatingFactorsList[0].agrrelfact_desc;
            this.relieve = this.aggravatingFactorsList[0].agrrelfact_desc;
            this.getChestpainAssociatedSymptoms();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getChestpainAssociatedSymptoms() {
    this.painSymptomList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/painasympt',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_asympts.length;i++){
              this.painSymptomList.push({
                asssympt_id: obj.pain_asympts[i].asssympt_id,
                asssympt_desc: obj.pain_asympts[i].asssympt_desc,
              })
            }
            this.assosymp = this.painSymptomList[0].asssympt_desc;
            this.getChestpainCauses();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getChestpainCauses() {
    this.painCauseList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'cardio/paincause',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != null){
            for(var i=0;i<obj.pain_causes.length;i++){
              this.painCauseList.push({
                cause_id: obj.pain_causes[i].cause_id,
                cause_desc: obj.pain_causes[i].cause_desc,
              })
            }
            this.paincause = this.painCauseList[0].cause_desc;
            this.getClinicalDetails();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  changeDoUFeelPain(){
    if(this.pain =="Yes"){
      this.charPainDisabledFlag = false;
      this.causeOfPainRequiredFlag=true;
      this.intensityRequiredFlag=true;
      this.assosymp_required=true;
      this.associatedSymptomRequiredFlag=true;
      this.aggravatRequiredFlag=true;
      this.durationRequiredFlag=true;
      this.radiationRequiredFlag=true;
      this.charOfPainRequiredFlag=true;
      this.locationRequiredFlag=true;
    }else{
      this.charPainDisabledFlag=true;
      this.paincause=null;
      this.intensity=null;
      this.assosymp=null;
      this.relieve=null;
      this.aggravat=null;
      this.duration=null;
      this.radiation=null;
      this.character=null;
      this.location = null;
      this.causeOfPainRequiredFlag=false;
      this.intensityRequiredFlag=false;
      this.assosymp_required=false;
      this.associatedSymptomRequiredFlag=false;
      this.aggravatRequiredFlag=false;
      this.durationRequiredFlag=false;
      this.radiationRequiredFlag=false;
      this.charOfPainRequiredFlag=false;
      this.locationRequiredFlag=false;
    }   
  }

  getClinicalDetails() {
    var obj = Physio_Helper.getRet();
    if (obj != undefined) {
      if (obj.diabetics == "yes") {
        this.toggDiabFlag = true;
        this.changeDiab(true);  
      } else {
          this.toggDiabFlag = false;
          this.changeDiab(false);
        }
      if (obj.heart_attack == "yes") {
        this.toggHeartAttackFlag = true;
        this.changeHeartAttack(true);  
      } else {
        this.toggHeartAttackFlag = false;
        this.changeHeartAttack(false);        
      }
      if (obj.stroke == "yes") {
        this.toggStrokeFlag = true;
        this.changeStroke(true);  
      } else {
        this.toggStrokeFlag = false;
        this.changeStroke(false);
      }
      if (obj.arterial == "yes") {
        this.toggArterFlag = true;
        this.changeArter(true);  
      } else {
        this.toggArterFlag = false;
        this.changeArter(false);        }
      if (obj.autoimmune == "yes") {
        this.toggAutoFlag = true;
        this.changeAutoImmune(true);
        } else {
          this.toggAutoFlag = false;
          this.changeAutoImmune(false);
          }
      if (obj.endocrine == "yes") {
        this.toggEndoFlag = true;
        this.changeEndro(true);
      } else {
        this.toggEndoFlag = false;
        this.changeEndro(false);
        }
      if (obj.hypertension == "yes") {
        this.toggHyperFlag = true;
        this.changeHypertension(true);
      } else {
        this.toggHyperFlag = false;
        this.changeHypertension(false);
        }
      if (obj.hemophilia == "yes") {
        this.toggHemoFlag = true;
        this.changeHemophilia(true);
      } else {
        this.toggHemoFlag = false;
        this.changeHemophilia(false);
        }
      if (obj.tuberculosis == "yes") {
        this.toggTuberFlag = true;
        this.changeTuber(true);
      } else {
        this.toggTuberFlag = false;
        this.changeTuber(false);
        }
      if (obj.hiv == "yes") {
        this.toggHivFlag = true;
        this.changeHiv(true);
      } else {
        this.toggHivFlag = false;
        this.changeHiv(false);
        }
      if (obj.hepatitis == "yes") {
        this.toggHepatFlag = true;
        this.changeHepatitis(true);
      } else {
        this.toggHepatFlag = false;
        this.changeHepatitis(false);
        }
      if (obj.glaucoma == "yes") {
        this.toggGlaucomaFlag = true;
        this.changeGlaucoma(true);
      } else {
        this.toggGlaucomaFlag = false;
        this.changeGlaucoma(false);
        }
      if (obj.others != undefined) {
        this.familyOther = obj.others;
      }
      this.pain = obj.pain;
      this.location = obj.location;
      this.character = obj.character;
      this.radiation = obj.radiation;
      this.duration = obj.duration;
      this.aggravat = obj.aggreviate;
      this.relieve = obj.relieve;
      this.assosymp = obj.assosymp;
      this.intensity = obj.intensity;
      this.sendData();
    }
  }

  changeDiab(e) {
    this.toggDiabFlag = e;
    this.toggDiab = (e == false) ?  "no" : "yes";
  }

  changeHeartAttack(e) {
    this.toggHeartAttackFlag = e;
    this.toggHeartAttack = (e == false) ?  "no" : "yes";
  }

  changeStroke(e) {
    this.toggStrokeFlag = e;
    this.toggStorke = (e == false) ?  "no" : "yes";
  }

  changeArter(e) {
    this.toggArterFlag = e;
    this.toggArter = (e == false) ?  "no" : "yes";
  }

  changeEndro(e) {
    this.toggEndoFlag = e;
    this.toggEndo = (e == false) ?  "no" : "yes";
  }

  changeAutoImmune(e) {
    this.toggAutoFlag = e;
    this.toggAuto = (e == false) ?  "no" : "yes";
  }

  changeHypertension(e) {
    this.toggHyperFlag = e;
    this.toggHyper = (e == false) ?  "no" : "yes";
  }

  changeHemophilia(e) {
    this.toggHemoFlag = e;
    this.toggHemo = (e == false) ?  "no" : "yes";
  }

  changeTuber(e) {
    this.toggTuberFlag = e;
    this.toggTuber = (e == false) ?  "no" : "yes";
  }

  changeHiv(e) {
    this.toggHivFlag = e;
    this.toggHiv = (e == false) ?  "no" : "yes";
  }

  changeHepatitis(e) {
    this.toggHepatFlag = e;
    this.toggHepat = (e == false) ?  "no" : "yes";
  }

  changeGlaucoma(e) {
    this.toggGlaucomaFlag = e;
    this.toggGlaucoma = (e == false) ?  "no" : "yes";
  }

  sendData() {
    if (this.familyOther != null) {
      var others = this.familyOther;
    }
    var family_hist_data = {
      diabetics: this.toggDiab,
      heart_attack: this.toggHeartAttack,
      stroke: this.toggStorke,
      arterial: this.toggArter,
      endocrine: this.toggEndo,
      autoimmune: this.toggAuto,
      hypertension: this.toggHyper,
      tuberculosis: this.toggTuber,
      hemophilia: this.toggHemo,
      hiv: this.toggHiv,
      hepatitis: this.toggHepat,
      glaucoma: this.toggGlaucoma,
      others: others,
      pain: this.pain,
      location: this.location,
      character: this.character,
      radiation:this.radiation,
      duration: this.duration,
      aggreviate: this.aggravat,
      relieve: this.relieve,
      assosymp: this.assosymp,
      intensity: this.intensity,
      paincause: this.paincause,    
    }
    Physio_Helper.setPhysioClinical(family_hist_data);
  }

  diseaseHistory(data) {
  }
}
