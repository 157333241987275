import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';

import { Observable, Observer, Subscription } from 'rxjs';
import { DentalService, TreatmentService } from '../service';
import HighCharts from 'highcharts';

import { IcdCodePopupComponent } from '../icd-code-popup/icd-code-popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DentalProbingdepthpopupComponent } from '../dental-probingdepthpopup/dental-probingdepthpopup.component';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Doc_Helper } from '../Doc_Helper';
import { DentalteethpopupComponent } from '../dentalteethpopup/dentalteethpopup.component';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-dentalexamination',
  templateUrl: './dentalexamination.component.html',
  styleUrls: ['./dentalexamination.component.css']
})
export class DentalexaminationComponent implements OnInit {

  // @Input() dentalExaminationFlag;
  public dentalExaminationFlag = 'min';

  // public teeth_div;
  public disabled: boolean;
  public teeth_div;
  public vitalTab;
  public spl_name;
  public pedio = []
  public Get_teethname = [];
  public get_spl_name;
  public minflag: boolean = true
  public clinicalflag: boolean;
  public panelOpenState3: boolean;
  public remarksList_flag: boolean = true;
  public miss_teeth_hidden: boolean;
  public Calculus_hidden: boolean;
  public oral_hyg_hidden: boolean;
  public swell_hidden: boolean;
  public mal_hidden: boolean;
  public sup_hidden: boolean;
  public mob_hidden: boolean;
  public bleed_hidden: boolean;
  public stains_hidden: boolean;
  public dental_hidden: boolean;
  public root_hidden: boolean;
  public other_hidden: boolean;
  public send_teeth_name_array = [];
  public shape_of_teeth_array = [];
  public color_of_teeth_array = [];
  public consistency_of_teeth_array = [];
  public condition_of_teeth_array = [];
  public clinic_arry_list = [];
  subscription: Subscription;
  public getdetails;
  public Value_18_01;
  public Value_17_01;
  public Value_16_01;
  public Value_15_01;
  public Value_14_01;
  public Value_13_01;
  public Value_12_01;
  public Value_11_01;
  public Value_21_01;
  public Value_22_01;
  public Value_23_01;
  public Value_24_01;
  public Value_25_01;
  public Value_26_01;
  public Value_27_01;
  public Value_28_01;

  public Value_48_01;
  public Value_47_01;
  public Value_46_01;
  public Value_45_01;

  public Value_44_01;
  public Value_43_01;
  public Value_42_01;
  public Value_41_01;
  public Value_31_01;
  public Value_32_01;
  public Value_33_01;
  public Value_34_01;
  public Value_35_01;
  public Value_36_01;
  public Value_37_01;
  public Value_38_01;

  public Upper_Mobility_List = [];
  public Upper_Implant_List = [];
  public Upper_Furcation_List = [];
  public Upper_BleedProbe_List = [];
  public Upper_Plaque_List = [];
  public Upper_Gingival_List = [];
  public Upper_Palpation_List = [];
  public Upper_Attachment_List = [];
  public Upper_Recession_List = [];
  public Upper_Image_List = [];
  public Lower_Image_List = [];

  public Lower_Mobility_List = [];
  public Lower_Implant_List = [];
  public Lower_Furcation_List = [];
  public Lower_BleedProbe_List = [];
  public Lower_Plaque_List = [];
  public Lower_Gingival_List = [];
  public Lower_ProbDepth_List = [];
  public Lower_Palpation_List = [];
  public Lower_Attachment_List = [];
  public Lower_Recession_List = [];

  public shape_of_teeth;
  public consistency_of_teeth;
  public color_of_teeth;
  public condition_of_teeth;
  public jaw_txt;
  public teeth_type_txt;
  public teethid_Name;
  public icd10_txt;
  public note_data;
  public missing_teeth;
  public calculus_txt;
  public swell_txt;
  public mal_occ_txt;
  public sup_erup_txt;
  public mobility_txt;
  public Mobility;
  public bleeding_txt;
  public stains_txt;
  public dental_carries_txt;
  public root_stem_txt;
  public other_txt;
  public oral_hygiene_txt;
  public loader: boolean;
  public static Upper_DataArray = [];
  public static Lower_DataArray = [];

  public upper_teeth_start1;

  public Upper_18_Style;
  public Upper_17_Style;
  public Upper_16_Style;
  public Upper_15_Style;
  public Upper_14_Style;
  public Upper_13_Style;
  public Upper_12_Style;
  public Upper_11_Style;
  public Upper_21_Style;
  public Upper_22_Style;
  public Upper_23_Style;
  public Upper_24_Style;
  public Upper_25_Style;
  public Upper_26_Style;
  public Upper_27_Style;
  public Upper_28_Style;

  public Lower_48_Chart_style;
  public Lower_47_Chart_style;
  public Lower_46_Chart_style;
  public Lower_45_Chart_style;
  public Lower_44_Chart_style;
  public Lower_43_Chart_style;
  public Lower_42_Chart_style;
  public Lower_41_Chart_style;
  public Lower_31_Chart_style;
  public Lower_32_Chart_style;
  public Lower_33_Chart_style;
  public Lower_34_Chart_style;
  public Lower_35_Chart_style;
  public Lower_36_Chart_style;
  public Lower_37_Chart_style;
  public Lower_38_Chart_style;
  public client: boolean = false;
  public perio_div: boolean = true;
  public n: number = 1;

  constructor(public messageservice1: CasesheetService, public dialog: MatDialog, public messageservice: TreatmentService, public http: Http,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public dentalservice: DentalService,
    public masterData: MasterCSHelperService) {
    this.miss_teeth_hidden = true;

    this.minflag = true;
    this.jaw_txt = "Select"
    //this.color_of_teeth="Select";
    this.consistency_of_teeth = "Select";
    this.teeth_type_txt = "Select";
    this.condition_of_teeth = "Select";
    this.shape_of_teeth = "Select"
    this.Calculus_hidden = true;
    this.oral_hyg_hidden = true;
    this.swell_hidden = true;
    this.mal_hidden = true;
    this.sup_hidden = true;
    this.mob_hidden = true;
    this.bleed_hidden = true;
    this.stains_hidden = true;
    this.dental_hidden = true;
    this.root_hidden = true;
    this.other_hidden = true;
    this.upper_teeth_start1 = {
      "strokeColor": "0000ff", "strokeWidth": 2, "fillColor": "ffffff", "fillOpacity": "0.6"
    };

    // this.perio_div = true;

    var Red_Obj = {
      "float": "left",
      "width": "100%",
      "background-color": "transparent"
    }
    var Yellow_Obj = {
      "float": "left",
      "width": "100%",
      "background-color": "transparent"
    }
    for (var i = 0; i < 16; i++) {
      this.Upper_Mobility_List.push({ Value: "0" });
      this.Upper_Implant_List.push({ Check: false });
      this.Upper_Furcation_List.push({ Value: "0" });
      this.Upper_BleedProbe_List.push({ Value: "0", Style: Red_Obj });
      this.Upper_Plaque_List.push({ Value: "0", Style: Yellow_Obj });
      this.Upper_Gingival_List.push({ Check: false });
      this.Upper_Palpation_List.push({ Value: "0" });
      this.Upper_Attachment_List.push({ Check: false });
      this.Upper_Recession_List.push({ Value: "0" });
    }

    for (var i = 0; i < 16; i++) {
      this.Lower_Mobility_List.push({ Value: "0" });
      this.Lower_Implant_List.push({ Check: false });
      this.Lower_Furcation_List.push({ Value: "0" });
      this.Lower_BleedProbe_List.push({ Value: "0", Style: Red_Obj });
      this.Lower_Plaque_List.push({ Value: "0", Style: Yellow_Obj });
      this.Lower_Gingival_List.push({ Check: false });
      this.Lower_Palpation_List.push({ Value: "0" });
      this.Lower_Attachment_List.push({ Check: false });
      this.Lower_Recession_List.push({ Value: "0" });
    }

    // Upper Teeth Image
    this.Upper_Image_List.push({ Teeth: "18", Imgpath: "assets/img/Teeth_Small_img/18_third_molar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "17", Imgpath: "assets/img/Teeth_Small_img/17_second_molar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "16", Imgpath: "assets/img/Teeth_Small_img/16_first_molar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "15", Imgpath: "assets/img/Teeth_Small_img/15_second_premolar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "14", Imgpath: "assets/img/Teeth_Small_img/14_first_premolar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "13", Imgpath: "assets/img/Teeth_Small_img/13_canine_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "12", Imgpath: "assets/img/Teeth_Small_img/12_latrel_insisor_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "11", Imgpath: "assets/img/Teeth_Small_img/11_central_insisor_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "21", Imgpath: "assets/img/Teeth_Small_img/11_central_insisor_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "22", Imgpath: "assets/img/Teeth_Small_img/12_latrel_insisor_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "23", Imgpath: "assets/img/Teeth_Small_img/13_canine_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "24", Imgpath: "assets/img/Teeth_Small_img/14_first_premolar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "25", Imgpath: "assets/img/Teeth_Small_img/15_second_premolar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "26", Imgpath: "assets/img/Teeth_Small_img/16_first_molar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "27", Imgpath: "assets/img/Teeth_Small_img/17_second_molar_reverse.png", Value: "" });
    this.Upper_Image_List.push({ Teeth: "28", Imgpath: "assets/img/Teeth_Small_img/18_third_molar_reverse.png", Value: "" });

    // Lower Teeth Image
    this.Lower_Image_List.push({ Teeth: "48", Imgpath: "assets/img/Teeth_Small_img/48_third_molar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "47", Imgpath: "assets/img/Teeth_Small_img/47_second_molar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "46", Imgpath: "assets/img/Teeth_Small_img/46_first_molar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "45", Imgpath: "assets/img/Teeth_Small_img/45_second_premolar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "44", Imgpath: "assets/img/Teeth_Small_img/44_first_premolar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "43", Imgpath: "assets/img/Teeth_Small_img/43_canine.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "42", Imgpath: "assets/img/Teeth_Small_img/42_latrel_insisor.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "41", Imgpath: "assets/img/Teeth_Small_img/41_central_insisor.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "31", Imgpath: "assets/img/Teeth_Small_img/41_central_insisor.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "32", Imgpath: "assets/img/Teeth_Small_img/42_latrel_insisor.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "33", Imgpath: "assets/img/Teeth_Small_img/43_canine.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "34", Imgpath: "assets/img/Teeth_Small_img/44_first_premolar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "35", Imgpath: "assets/img/Teeth_Small_img/45_second_premolar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "36", Imgpath: "assets/img/Teeth_Small_img/46_first_molar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "37", Imgpath: "assets/img/Teeth_Small_img/47_second_molar.png", Value: "" });
    this.Lower_Image_List.push({ Teeth: "38", Imgpath: "assets/img/Teeth_Small_img/48_third_molar.png", Value: "" });

  }

  async ngOnInit() {
    if (Doc_Helper.getAppFlow() == "client") {
      this.client = true;
    } else {
      this.client = false;
    }
    this.get_condition_of_teeth_array();
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.dentalExaminationFlag = message;
        if (this.dentalExaminationFlag == "max") {
          this.getdetails = Helper_Class.getHopitalfieldsRet();
          var getdata = (Helper_Class.getInfo());

          if (getdata.provider == "Doctor") {
            this.disabled = false;
            if (Helper_Class.getHopitalfieldsRet() != undefined) {
              for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
                if (Helper_Class.getHopitalfieldsRet()[i].page_name == "Clinical examination") {
                  if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
                    if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
                      for (var j = 0; j < Helper_Class.getHopitalfieldsRet()[i].fields.length; j++) {
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Missing teeth") {
                          this.miss_teeth_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Calculus") {
                          this.Calculus_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Oral hygiene") {
                          this.oral_hyg_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Swelling") {
                          this.swell_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Malocclusion") {
                          this.mal_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Supraeruption") {
                          this.sup_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Mobility") {
                          this.mob_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Bleeding") {
                          this.bleed_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Stains") {
                          this.stains_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Dental caries") {
                          this.dental_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Root system") {
                          this.root_hidden = false;
                        }
                        if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Other") {
                          this.other_hidden = false;
                        }
                      }
                    }
                  }
                }
              }
            }

          } else {
            this.disabled = true;
            this.miss_teeth_hidden = false;
            this.Calculus_hidden = false;
            this.oral_hyg_hidden = false;
            this.swell_hidden = false;
            this.mal_hidden = false;
            this.sup_hidden = false;
            this.mob_hidden = false;
            this.bleed_hidden = false;
            this.stains_hidden = false;
            this.dental_hidden = false;
            this.root_hidden = false;
            this.other_hidden = false;
          }
        }
      }
    });
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      var message1 = message.spl
      if (message1) {
        this.speciality_change(message1)
      } else {
        this.pedio = [];
      }
    });

    if (masterCSData_Helper.getMasterUneruptedTeeth() == undefined) {
      await this.masterData.getUneruptedTeeth();
      this.get_teeth_name_array();
    } else {
      this.get_teeth_name_array();
    }
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
  attachment(e) {
    var upp_attas = "no"

    // if (this.Upper_Attachment_List[].Check == true) {
    //   upp_attas = "yes";
    // }

  }

  open_icdcodeservice() {
    const dialogRef = this.dialog.open(IcdCodePopupComponent, {
      width: '700px',
      height: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.icd10_txt = result;
    });
    this.send_data();
  }


  //table
  clinical_click() {
    if (this.clinic_arry_list.length == 0) {
      this.clinicalflag = false;
    }
    else {
      this.clinicalflag = true;
    }
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    if (this.jaw_txt == undefined) {
      this.toastr.error(Message_data.sltJawType);
    }
    else if (this.teeth_type_txt == undefined) {
      this.toastr.error(Message_data.sltTeethType);
    }
    else if (this.send_teeth_name_array.length == 0) {
      this.toastr.error(Message_data.sltTeethName);
    } 
    else {
      var teethid_Name = "", note_txt = "";

      var sb = new StringBuilder("")
      for (var i = 0; i < this.send_teeth_name_array.length; i++) {
        for (var j = 0; j < this.Get_teethname.length; j++) {
          if (this.send_teeth_name_array[i] == this.Get_teethname[j].den_teeth_name_id) {
            if (teethid_Name == "") {
              teethid_Name = this.Get_teethname[j].den_teeth_name_id.toString();

            } else {
              teethid_Name = teethid_Name + "," + this.Get_teethname[j].den_teeth_name_id.toString();
            }
            sb.append(this.Get_teethname[j].description + ', ');
          }
        }
      }

      var cond_dec = "", teeth_shape = "", teeth_consis = "", teeth_color = "";
      for (var i = 0; i < this.condition_of_teeth_array.length; i++) {
        if (this.condition_of_teeth_array[i].den_issue_id == this.condition_of_teeth) {
          cond_dec = this.condition_of_teeth_array[i].description;
        }
      }
      for (var i = 0; i < this.shape_of_teeth_array.length; i++) {
        if (this.shape_of_teeth_array[i].shape_id == this.shape_of_teeth) {
          teeth_shape = this.shape_of_teeth_array[i].description;
        }
      }
      for (var i = 0; i < this.consistency_of_teeth_array.length; i++) {
        if (this.consistency_of_teeth_array[i].teeth_cons_id == this.consistency_of_teeth) {
          teeth_consis = this.consistency_of_teeth_array[i].description;
        }
      }
      for (var i = 0; i < this.color_of_teeth_array.length; i++) {
        if (this.color_of_teeth_array[i].teeth_color_id == this.color_of_teeth) {
          teeth_color = this.color_of_teeth_array[i].description;
        }
      }
      if (this.note_data != undefined) {
        note_txt = this.note_data;
      }

      this.clinic_arry_list.push({
        jaw_type: this.jaw_txt,
        teeth_type: this.teeth_type_txt,
        time_line: teethid_Name,
        teeth_color: this.color_of_teeth,
        teeth_shape: this.shape_of_teeth,
        teeth_cons: this.consistency_of_teeth,
        dental_issue: this.condition_of_teeth,
        teethname: sb.toString(),
        colour: teeth_color,
        consistency: teeth_consis,
        condition: cond_dec,
        teeth_shape_show: teeth_shape,
        icd_10_code: this.icd10_txt,
        notes: note_txt,
      });

      this.jaw_txt = undefined;
      this.consistency_of_teeth = undefined;
      this.condition_of_teeth = undefined;
      this.shape_of_teeth = undefined;
      this.color_of_teeth = undefined;
      this.teeth_type_txt = undefined;
      this.icd10_txt = "";
      this.note_data = "";
      this.send_teeth_name_array = [];

    }
    this.send_data()
  }

  clinic_delete(jaw, type, name, shape, condition) {
    for (var i = 0; i < this.clinic_arry_list.length; i++) {
      if (jaw == this.clinic_arry_list[i].jaw_type && type == this.clinic_arry_list[i].teeth_type
        && name == this.clinic_arry_list[i].teethname && shape == this.clinic_arry_list[i].teeth_shape_show
        && condition == this.clinic_arry_list[i].condition) {
        this.clinic_arry_list.splice(i, 1);
        break;
      }
    }
    this.send_data()
  }

  jaw_change() {
    this.send_teeth_name_array = [];
  }

  teeth_click_new(valuecard, event) {
    if (event.selected == true) {
      this.send_teeth_name_array.push(
        valuecard
      )

    } else {
      for (var i = 0; this.send_teeth_name_array.length; i++) {
        if (this.send_teeth_name_array[i] == valuecard) {
          this.send_teeth_name_array.splice(i, 1);
          break;
        }
      }
    }
    this.teeth_click(valuecard, event);
  }

  teeth_click(Check, teeth_id) {
    if (Check == true) {
      if (this.jaw_txt == "Upper") {
        if (teeth_id == "16") {
          //mapstar("upper", "16", true)

        } else if (teeth_id == "14") {
          //mapstar("upper", "14", true)

        } else if (teeth_id == "12") {
          //mapstar("upper", "12", true)

        } else if (teeth_id == "10") {
          //mapstar("upper", "10", true)

        } else if (teeth_id == "8") {
          //mapstar("upper", "8", true)

        } else if (teeth_id == "6") {
          //mapstar("upper", "6", true)

        } else if (teeth_id == "4") {
          //mapstar("upper", "4", true)

        } else if (teeth_id == "2") {
          //mapstar("upper", "2", true)

        } else if (teeth_id == "1") {
          //mapstar("upper", "1", true)

        } else if (teeth_id == "3") {
          //mapstar("upper", "3", true)

        } else if (teeth_id == "5") {
          //mapstar("upper", "5", true)

        } else if (teeth_id == "7") {
          //mapstar("upper", "7", true)

        } else if (teeth_id == "9") {
          //mapstar("upper", "9", true)

        } else if (teeth_id == "11") {
          //mapstar("upper", "11", true)

        } else if (teeth_id == "13") {
          //mapstar("upper", "13", true)

        } else if (teeth_id == "15") {
          //mapstar("upper", "15", true)
        }

      } else if (this.jaw_txt == "Lower") {
        if (teeth_id == "15") {
          //mapstar("lower", "15", true)

        } else if (teeth_id == "13") {
          //mapstar("lower", "13", true)

        } else if (teeth_id == "11") {
          //mapstar("lower", "11", true)

        } else if (teeth_id == "9") {
          //mapstar("lower", "9", true)

        } else if (teeth_id == "7") {
          //mapstar("lower", "7", true)

        } else if (teeth_id == "5") {
          //mapstar("lower", "5", true)

        } else if (teeth_id == "3") {
          //mapstar("lower", "3", true)

        } else if (teeth_id == "1") {
          //mapstar("lower", "1", true)

        } else if (teeth_id == "2") {
          //mapstar("lower", "2", true)

        } else if (teeth_id == "4") {
          //mapstar("lower", "4", true)

        } else if (teeth_id == "6") {
          //mapstar("lower", "6", true)

        } else if (teeth_id == "8") {
          //mapstar("lower", "8", true)

        } else if (teeth_id == "10") {
          //mapstar("lower", "10", true)

        } else if (teeth_id == "12") {
          //mapstar("lower", "12", true)

        } else if (teeth_id == "14") {
          //mapstar("lower", "14", true)

        } else if (teeth_id == "16") {
          //mapstar("lower", "16", true)
        }
      }
      
    } else {
      var teethfield = $("input[name='teethname']").serializeArray();

      if (this.jaw_txt == "Upper") {
        if (teeth_id == "16") {
          //mapstar("upper", "16", false)

        } else if (teeth_id == "14") {
          //mapstar("upper", "14", false)

        } else if (teeth_id == "12") {
          //mapstar("upper", "12", false)

        } else if (teeth_id == "10") {
          //mapstar("upper", "10", false)

        } else if (teeth_id == "8") {
          //mapstar("upper", "8", false)

        } else if (teeth_id == "6") {
          //mapstar("upper", "6", false)

        } else if (teeth_id == "4") {
          //mapstar("upper", "4", false)

        } else if (teeth_id == "2") {
          //mapstar("upper", "2", false)

        } else if (teeth_id == "1") {
          //mapstar("upper", "1", false)

        } else if (teeth_id == "3") {
          //mapstar("upper", "3", false)

        } else if (teeth_id == "5") {
          //mapstar("upper", "5", false)

        } else if (teeth_id == "7") {
          //mapstar("upper", "7", false)

        } else if (teeth_id == "9") {
          //mapstar("upper", "9", false)

        } else if (teeth_id == "11") {
          //mapstar("upper", "11", false)

        } else if (teeth_id == "13") {
          //mapstar("upper", "13", false)

        } else if (teeth_id == "15") {
          //mapstar("upper", "15", false)
        }

      } else if (this.jaw_txt == "Lower") {
        if (teeth_id == "15") {
          //mapstar("lower", "15", false)

        } else if (teeth_id == "13") {
          //mapstar("lower", "13", false)

        } else if (teeth_id == "11") {
          //mapstar("lower", "11", false)

        } else if (teeth_id == "9") {
          //mapstar("lower", "9", false)

        } else if (teeth_id == "7") {
          //mapstar("lower", "7", false)

        } else if (teeth_id == "5") {
          //mapstar("lower", "5", false)

        } else if (teeth_id == "3") {
          //mapstar("lower", "3", false)

        } else if (teeth_id == "1") {
          //mapstar("lower", "1", false)

        } else if (teeth_id == "2") {
          //mapstar("lower", "2", false)

        } else if (teeth_id == "4") {
          //mapstar("lower", "4", false)

        } else if (teeth_id == "6") {
          //mapstar("lower", "6", false)

        } else if (teeth_id == "8") {
          //mapstar("lower", "8", false)

        } else if (teeth_id == "10") {
          //mapstar("lower", "10", false)

        } else if (teeth_id == "12") {
          //mapstar("lower", "12", false)

        } else if (teeth_id == "14") {
          //mapstar("lower", "14", false)

        } else if (teeth_id == "16") {
          //mapstar("lower", "16", false)
        }
      }
    }
  }

  send_data() {
    var gen_hist = null;
    gen_hist = {
      clinical_exam: this.clinic_arry_list,
      missing_teeth: this.missing_teeth,
      calculus: this.calculus_txt,
      oral_hygiene: this.oral_hygiene_txt,
      swelling: this.swell_txt,
      malocclusion: this.mal_occ_txt,
      supraeruption: this.sup_erup_txt,
      mobility: this.mobility_txt,
      bleeding: this.bleeding_txt,
      stains: this.stains_txt,
      dental_carries: this.dental_carries_txt,
      root_stem: this.root_stem_txt,
      clinic_other: this.other_txt,
      country: ipaddress.country_code,
    }
    this.spl_name = Doc_Helper.get_dental_spl_name();

    if (this.spl_name == "Periodontics" || this.spl_name == "Endodontics" || this.spl_name == "Prosthodontics") {
      // set perio chart array
      var perio_upper = [];
      var perio_lower = [];

      // lower teeth
      for (var i = 0; i < this.Lower_Mobility_List.length; i++) {
        var teeth_no = null;
        var Lower_Probdepth_Val = "0-0-0";

        if (i == 0) {
          teeth_no = "48";
          Lower_Probdepth_Val = this.Value_48_01;

        } else if (i == 1) {
          teeth_no = "47";
          Lower_Probdepth_Val = this.Value_47_01;

        } else if (i == 2) {
          teeth_no = "46";
          Lower_Probdepth_Val = this.Value_46_01;

        } else if (i == 3) {
          teeth_no = "45";
          // Lower_Probdepth_Val = this.Value_45_01;

        } else if (i == 4) {
          teeth_no = "44";
          Lower_Probdepth_Val = this.Value_44_01;

        } else if (i == 5) {
          teeth_no = "43";
          Lower_Probdepth_Val = this.Value_43_01;

        } else if (i == 6) {
          teeth_no = "42";
          Lower_Probdepth_Val = this.Value_42_01;

        } else if (i == 7) {
          teeth_no = "41";
          Lower_Probdepth_Val = this.Value_41_01;

        } else if (i == 8) {
          teeth_no = "31";
          Lower_Probdepth_Val = this.Value_31_01;

        } else if (i == 9) {
          teeth_no = "32";
          Lower_Probdepth_Val = this.Value_32_01;

        } else if (i == 10) {
          teeth_no = "33";
          Lower_Probdepth_Val = this.Value_33_01;

        } else if (i == 11) {
          teeth_no = "34";
          Lower_Probdepth_Val = this.Value_34_01;

        } else if (i == 12) {
          teeth_no = "35";
          Lower_Probdepth_Val = this.Value_35_01;

        } else if (i == 13) {
          teeth_no = "36";
          Lower_Probdepth_Val = this.Value_36_01;

        } else if (i == 14) {
          teeth_no = "37";
          Lower_Probdepth_Val = this.Value_37_01;

        } else if (i == 15) {
          teeth_no = "38";
          Lower_Probdepth_Val = this.Value_38_01;
        }

        if (this.Lower_Mobility_List[i].Value != "0" || this.Lower_Implant_List[i].Check != false ||
          this.Lower_Furcation_List[i].Value != "0" || (this.Lower_BleedProbe_List[i].Value != "" && this.Lower_BleedProbe_List[i].Value != "0") ||
          (this.Lower_Plaque_List[i].Value != "" && this.Lower_Plaque_List[i].Value != "0") || this.Lower_Gingival_List[i].Check != false ||
          this.Lower_Palpation_List[i].Value != "0" || this.Lower_Attachment_List[i].Check != false || this.Lower_Recession_List[i].Value != "0" ||
          this.Lower_Image_List[i].Value != "" || (Lower_Probdepth_Val != undefined && Lower_Probdepth_Val != "" && Lower_Probdepth_Val != "0")) {

          var upp_impl_low = "no", upp_gin_low = "no", upp_attas_low = "no";
          var image_str = [];

          if (this.Lower_Image_List[i].Value != "") {
            var get_img = this.Lower_Image_List[i].Value.split('.');
            var get_img1 = get_img[0].split('c');
            var get_img2 = get_img1[0];

            for (var j = 0; j < get_img2.length; j++) {
              image_str.push(get_img2[j]);
            }
          }

          if (this.Lower_Implant_List[i].Check == true) {
            upp_impl_low = "yes";
          }

          if (this.Lower_Gingival_List[i].Check == true) {
            upp_gin_low = "yes";
          }

          if (this.Lower_Attachment_List[i].Check == true) {
            upp_attas_low = "Yes";

          } else {
            upp_attas_low = "No";
          }

          perio_lower.push({
            den_teeth_num_id: teeth_no,
            mobility: this.Lower_Mobility_List[i].Value,
            implant: upp_impl_low,
            furcation: this.Lower_Furcation_List[i].Value,
            bleed_probe: this.Lower_BleedProbe_List[i].Value,
            plaque: this.Lower_Plaque_List[i].Value,
            gingiv_margin: upp_gin_low,
            // probe_depth :$scope.Lower_ProbDepth_List[i].Value + "-" + $scope.Lower_ProbDepth_List[i].Value_1 + "-" + $scope.Lower_ProbDepth_List[i].Value_2,
            probe_depth: Lower_Probdepth_Val,
            palpation: this.Lower_Palpation_List[i].Value,
            attach_loss: upp_attas_low,
            reccesion: this.Lower_Recession_List[i].Value,
            den_position_id: "2",
            teeth_affect: image_str,
          });
        }
      }

      //upper teeth
      for (var i = 0; i < this.Upper_Mobility_List.length; i++) {
        var Upper_Probdepth_Val = "0-0-0";
        if (i == 0) {
          teeth_no = "18";
          Upper_Probdepth_Val = this.Value_18_01;

        } else if (i == 1) {
          teeth_no = "17";
          Upper_Probdepth_Val = this.Value_17_01;

        } else if (i == 2) {
          teeth_no = "16";
          Upper_Probdepth_Val = this.Value_16_01;

        } else if (i == 3) {
          teeth_no = "15";
          Upper_Probdepth_Val = this.Value_15_01;

        } else if (i == 4) {
          teeth_no = "14";
          Upper_Probdepth_Val = this.Value_14_01;

        } else if (i == 5) {
          teeth_no = "13";
          Upper_Probdepth_Val = this.Value_13_01;

        } else if (i == 6) {
          teeth_no = "12";
          Upper_Probdepth_Val = this.Value_12_01;

        } else if (i == 7) {
          teeth_no = "11";
          Upper_Probdepth_Val = this.Value_11_01;

        } else if (i == 8) {
          teeth_no = "21";
          Upper_Probdepth_Val = this.Value_21_01;

        } else if (i == 9) {
          teeth_no = "22";
          Upper_Probdepth_Val = this.Value_22_01;

        } else if (i == 10) {
          teeth_no = "23";
          Upper_Probdepth_Val = this.Value_23_01;

        } else if (i == 11) {
          teeth_no = "24";
          Upper_Probdepth_Val = this.Value_24_01;

        } else if (i == 12) {
          teeth_no = "25";
          Upper_Probdepth_Val = this.Value_25_01;

        } else if (i == 13) {
          teeth_no = "26";
          Upper_Probdepth_Val = this.Value_26_01;

        } else if (i == 14) {
          teeth_no = "27";
          Upper_Probdepth_Val = this.Value_27_01;

        } else if (i == 15) {
          teeth_no = "28";
          Upper_Probdepth_Val = this.Value_28_01;
        }

        if (this.Upper_Mobility_List[i].Value != "0" || this.Upper_Implant_List[i].Check != false ||
          this.Upper_Furcation_List[i].Value != "0" || (this.Upper_BleedProbe_List[i].Value != "" && this.Upper_BleedProbe_List[i].Value != "0") ||
          (this.Upper_Plaque_List[i].Value != "" && this.Upper_Plaque_List[i].Value != "0") || this.Upper_Gingival_List[i].Check != false ||
          this.Upper_Palpation_List[i].Value != "0" || this.Upper_Attachment_List[i].Check != false || this.Upper_Recession_List[i].Value != "0" ||
          this.Upper_Image_List[i].Value != "" || (Upper_Probdepth_Val != undefined && Upper_Probdepth_Val != "" && Upper_Probdepth_Val != "0")) {

          var image_str = [], upp_impl = "no", upp_gin = "no", upp_attas = "no";
          if (this.Upper_Image_List[i].Value != "") {
            var get_img = this.Upper_Image_List[i].Value.split('.');
            var get_img1 = get_img[0].split('c');
            var get_img2 = get_img1[0];

            for (var j = 0; j < get_img2.length; j++) {
              image_str.push(get_img2[j]);
            }
          }

          if (this.Upper_Implant_List[i].Check == true) {
            upp_impl = "yes";
          }

          if (this.Upper_Gingival_List[i].Check == true) {
            upp_gin = "yes";
          }

          if (this.Upper_Attachment_List[i].Check == true) {
            upp_attas = "yes";
          }


          perio_upper.push({
            den_teeth_num_id: teeth_no,
            mobility: this.Upper_Mobility_List[i].Value,
            implant: upp_impl,
            furcation: this.Upper_Furcation_List[i].Value,
            bleed_probe: this.Upper_BleedProbe_List[i].Value,
            plaque: this.Upper_Plaque_List[i].Value,
            gingiv_margin: upp_gin,
            // probe_depth : $scope.Upper_ProbDepth_List[i].Value + "-" + $scope.Upper_ProbDepth_List[i].Value_1 + "-" + $scope.Upper_ProbDepth_List[i].Value_2,
            probe_depth: Upper_Probdepth_Val,
            palpation: this.Upper_Palpation_List[i].Value,
            attach_loss: upp_attas,
            reccesion: this.Upper_Recession_List[i].Value,
            den_position_id: "1",
            teeth_affect: image_str,
          });
        }

      }

      if (perio_upper.length != 0 || perio_lower.length != 0) {
        var perio_upper_array = perio_upper.concat(perio_lower);

        var sen_perio_obj = ({
          perio_chart: perio_upper_array,
          perio_chart_flag: "Yes",
        });
        Helper_Class.Set_perio_chart_data(sen_perio_obj);

      }
    }
    Doc_Helper.Set_den_cli_exam(gen_hist);

  }

  async get_teeth_name_array() {
    var obj = masterCSData_Helper.getMasterUneruptedTeeth();
    if (obj != undefined)
      this.Get_teethname = obj.teeth_names;
    if (masterCSData_Helper.getMasterShapeofTeeth() == undefined) {
      await this.masterData.getShapeofTeeth();
      this.get_shape_of_teeth_array();
    } else {
      this.get_shape_of_teeth_array();
    }
  }

  teeth_name_list_change(selectedValue) {
    this.send_teeth_name_array = selectedValue;
  }
  //select event

  General_Upper_Chart(value1, value2, value3, render_div) {
    var Newarray = [];
    Newarray.push(0); // 0

    if (value1 != undefined) {
      Newarray.push(parseFloat(value1)); // 1

    } else {
      Newarray.push(parseFloat("0")); // 1
    }

    if (value2 != undefined) {
      Newarray.push(parseFloat(value2)); // 2

    } else {
      Newarray.push(parseFloat("0")); // 2
    }

    if (value3 != undefined) {
      Newarray.push(parseFloat(value3)); // 3

    } else {
      Newarray.push(parseFloat("0")); // 3
    }

    Newarray.push(0); // 4
    var Chart_size = 0;
    var Stylevalue = "";

    // Manage Hieght of graph based on values
    if (Newarray[1] == 0 && Newarray[2] == 0 && Newarray[3] == 0) {
      Chart_size = 0;
      Newarray = [];
    }

    if (Newarray[1] <= 1 && Newarray[2] <= 1 && Newarray[3] <= 1) {
      Chart_size = 28;
      Stylevalue = "50px";
    }

    if (Newarray[1] <= 2 && Newarray[2] <= 2 && Newarray[3] <= 2) {
      Chart_size = 38;
      Stylevalue = "33px";

    } else if (Newarray[1] <= 3 && Newarray[2] <= 3 && Newarray[3] <= 3) {
      Chart_size = 44;
      Stylevalue = "27px";

    } else if (Newarray[1] <= 4 && Newarray[2] <= 4 && Newarray[3] <= 4) {
      Chart_size = 50;
      Stylevalue = "21px";

    } else if (Newarray[1] <= 5 && Newarray[2] <= 5 && Newarray[3] <= 5) {
      Chart_size = 56;
      Stylevalue = "16px"; //33px

    } else if (Newarray[1] <= 6 && Newarray[2] <= 6 && Newarray[3] <= 6) {
      Chart_size = 64;
      Stylevalue = "9px";

    } else if (Newarray[1] <= 7 && Newarray[2] <= 7 && Newarray[3] <= 7) {
      Chart_size = 68;
      Stylevalue = "3px";

    } else if (Newarray[1] <= 8 && Newarray[2] <= 8 && Newarray[3] <= 8) {
      Chart_size = 76;
      Stylevalue = "0px";

    } else {
      Stylevalue = "0px";
      Chart_size = 80;
    }
    this.General_UpperTeeth_Chart(Newarray, render_div, Chart_size, Stylevalue);
  }
  
  General_UpperTeeth_Chart(data_array, render_id, chart_hight, Stylevalue) {
    var width_value = null;
    if (render_id == "Upper_18_Chart") {
      width_value = "42";
      this.Upper_18_Style = {
        "width": "42px",
        "height": "100px",

        "padding-top": Stylevalue,
        "margin-left": "-7px",
        "display": "block"
      };
    }

    if (render_id == "Upper_17_Chart") {
      width_value = "44";
      this.Upper_17_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "2px",
        "display": "block"
      };
    }

    if (render_id == "Upper_16_Chart") {
      width_value = "44";

      this.Upper_16_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "7px",
        "display": "block"
      };
    }

    if (render_id == "Upper_15_Chart") {
      width_value = "50";

      this.Upper_15_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "-7px",
        "display": "block"
      };
    }

    if (render_id == "Upper_14_Chart") {
      width_value = "44";

      this.Upper_14_Style = {
        "width": "42px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "-3px",
        "display": "block"
      };
    }

    if (render_id == "Upper_13_Chart") {
      width_value = "44";

      this.Upper_13_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "display": "block",
        "margin-left": "-4px",
      };
    }
    if (render_id == "Upper_12_Chart") {
      width_value = "44";

      this.Upper_12_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "display": "block",
        "margin-left": "0px",
      };
    }

    if (render_id == "Upper_11_Chart") {
      width_value = "44";

      this.Upper_11_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "display": "block"
      };
    }

    if (render_id == "Upper_21_Chart") {
      width_value = "50";

      this.Upper_21_Style = {
        "width": "50px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "-1px",
        "display": "block"
      };
    }

    if (render_id == "Upper_22_Chart") {
      width_value = "44";

      this.Upper_22_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "-3px",
        //"padding-bottom":"10px",
        "display": "block"
      };
    }

    if (render_id == "Upper_23_Chart") {
      width_value = "50";

      this.Upper_23_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        "margin-left": "6px",
        //"padding-bottom":"10px",
        "display": "block"
      };
    }

    if (render_id == "Upper_24_Chart") {
      width_value = "44";

      this.Upper_24_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        //"padding-bottom":"10px",
        "display": "block",
        "margin-left": "0px"
      };
    }

    if (render_id == "Upper_25_Chart") {
      width_value = "44";

      this.Upper_25_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        //"padding-bottom":"10px",
        "display": "block",
        "margin-left": "3px"
      };
    }

    if (render_id == "Upper_26_Chart") {
      width_value = "44";

      this.Upper_26_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        // "padding-bottom":"10px",
        "display": "block",
        "margin-left": "9px",
      };
    }

    if (render_id == "Upper_27_Chart") {
      width_value = "44";

      this.Upper_27_Style = {
        "width": "44px",
        "height": "100px",
        "padding-top": Stylevalue,
        // "padding-bottom":"10px",
        "display": "block"
      };
    }

    if (render_id == "Upper_28_Chart") {
      width_value = "58";

      this.Upper_28_Style = {
        "width": "58px",
        "height": "100px",
        "padding-top": Stylevalue,
        // "padding-bottom":"10px",
        "display": "block",
        "margin-left": "-6px",
      };
    }

    HighCharts.setOptions({
      colors: ['#cc6c66']
    });

    var aChart = new HighCharts.Chart({
      chart: {
        type: 'areaspline',
        renderTo: render_id,
        backgroundColor: "transparent",
        height: chart_hight,
        width: width_value,
      },
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },

      subtitle: {
        text: ''
      },

      legend: {
        enabled: false
      },

      loading: {
        style: {
          backgroundColor: "transparent",
        }
      },
      xAxis: {
        categories: ['', '', '', '', ''],
        tickWidth: 0,
        gridLineWidth: 0,
        lineWidth: 0,
        // offset: 40,
      },
      yAxis: {
        //gridLineColor: '#197F07',
        gridLineWidth: 0, // Remove lines from teeth
        lineWidth: 0,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        },
        areaspline: {
          fillOpacity: 0.5
        }
      },
      series: [{
        data: data_array
      }],
      exporting: {

        enabled: false,
      }
    });


    aChart.redraw();
  }


  // lower teeth selection
  General_Lower_Chart(value1, value2, value3, render_div) {
    var Newarray = [];
    Newarray.push(0); // 0

    // Graph reversed

    if (value3 != undefined) {
      Newarray.push(parseFloat(value3)); // 3

    } else {
      Newarray.push(parseFloat("0")); // 3
    }

    if (value2 != undefined) {
      Newarray.push(parseFloat(value2)); // 2

    } else {
      Newarray.push(parseFloat("0")); // 2
    }

    if (value1 != undefined) {
      Newarray.push(parseFloat(value1)); // 1

    } else {
      Newarray.push(parseFloat("0")); // 1
    }

    Newarray.push(0); // 4
    var Chart_size = 0;
    var Stylevalue = "";

    // Manage Hieght of graph based on values
    if (Newarray[1] == 0 && Newarray[2] == 0 && Newarray[3] == 0) {
      Chart_size = 0;
      Newarray = [];

    } else if (Newarray[1] <= 1 && Newarray[2] <= 1 && Newarray[3] <= 1) {
      Chart_size = 34;
      Stylevalue = "50px";
      //General_Lower_Chart
    } else if (Newarray[1] <= 2 && Newarray[2] <= 2 && Newarray[3] <= 2) {
      Chart_size = 38;
      Stylevalue = "24px";

    } else if (Newarray[1] <= 3 && Newarray[2] <= 3 && Newarray[3] <= 3) {
      Chart_size = 44;
      Stylevalue = "27px";

    } else if (Newarray[1] <= 4 && Newarray[2] <= 4 && Newarray[3] <= 4) {
      Chart_size = 50;
      Stylevalue = "21px";

    } else if (Newarray[1] <= 5 && Newarray[2] <= 5 && Newarray[3] <= 5) {
      Chart_size = 56;
      Stylevalue = "21px"; //33px

    } else if (Newarray[1] <= 6 && Newarray[2] <= 6 && Newarray[3] <= 6) {
      Chart_size = 64;
      Stylevalue = "9px";

    } else if (Newarray[1] <= 7 && Newarray[2] <= 7 && Newarray[3] <= 7) {
      Chart_size = 68;
      Stylevalue = "3px";

    } else if (Newarray[1] <= 8 && Newarray[2] <= 8 && Newarray[3] <= 8) {
      Chart_size = 76;
      Stylevalue = "0px";

    } else {
      Chart_size = 80;
      Stylevalue = "0px";
    }
    this.General_LowTeeth_Chart(Newarray, render_div, Chart_size);
  }

  General_LowTeeth_Chart(data_array, render_id, chart_hight) {
    var lower_width = null;
    if (render_id == "Lower_48_Chart") {
      lower_width = "55";
      this.Lower_48_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "0px"
      };
    }
    
    if (render_id == "Lower_47_Chart") {
      lower_width = "44";

      this.Lower_47_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "5px"
      };
    }

    if (render_id == "Lower_46_Chart") {
      lower_width = "44";

      this.Lower_46_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "5px"
      };
    }

    if (render_id == "Lower_45_Chart") {
      lower_width = "44";

      this.Lower_45_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "9px"
      };
    }

    if (render_id == "Lower_44_Chart") {
      lower_width = "44";

      this.Lower_44_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block"
      };
    }

    if (render_id == "Lower_43_Chart") {
      lower_width = "44";

      this.Lower_43_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "9px"
      };
    }

    if (render_id == "Lower_42_Chart") {
      lower_width = "44";

      this.Lower_42_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block"
      };
    }

    if (render_id == "Lower_41_Chart") {
      lower_width = "50";

      this.Lower_41_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "0px"
      };
    }

    if (render_id == "Lower_31_Chart") {
      lower_width = "44";

      this.Lower_31_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "0px"
      };
    }

    if (render_id == "Lower_32_Chart") {
      lower_width = "44";

      this.Lower_32_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "-7px"
      };
    }

    if (render_id == "Lower_33_Chart") {
      lower_width = "50";

      this.Lower_33_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "20px",
        "display": "block"
      };
    }

    if (render_id == "Lower_34_Chart") {
      lower_width = "44";

      this.Lower_34_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block"
      };
    }

    if (render_id == "Lower_35_Chart") {
      lower_width = "44";

      this.Lower_35_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block",
        "margin-left": "5px"
      };
    }

    if (render_id == "Lower_36_Chart") {
      lower_width = "44";

      this.Lower_36_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block"
      };
    }

    if (render_id == "Lower_37_Chart") {
      lower_width = "44";

      this.Lower_37_Chart_style = {
        "width": "44px",
        "height": "100px",
        "padding-top": "24px",
        "display": "block"
      };
    }

    if (render_id == "Lower_38_Chart") {
      lower_width = "58";

      this.Lower_38_Chart_style = {
        "width": "58px",
        "height": "100px",
        "padding-top": "20px",
        "display": "block"
      };
    }

    HighCharts.setOptions({
      colors: ['#ff6f34']
    });

    if(data_array.length !=0){
      var aChart = new HighCharts.Chart({
        chart: {
          type: 'areaspline',
          renderTo: render_id,
          backgroundColor: "transparent",
          height: chart_hight,
          width: lower_width
        },
        title: {
          text: ''
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        legend: {
          enabled: false
        },
        loading: {
          style: {
            backgroundColor: "transparent",
          }
        },
        xAxis: {
          categories: ['', '', '', '', ''],
          tickWidth: 0,
          gridLineWidth: 0,
          reversed: true
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0, // Remove lines from teeth
          reversed: true
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          areaspline: {
            fillOpacity: 0.5
          }
        },
        series: [{
          data: data_array
          //   data: [0, 0, 0]
        }],
        exporting: {
          enabled: false,
        }
      });
      aChart.redraw();
    }
    
  }

  // show teeth popup window
  Upper_Image_View_Click(Imagepath, teeth_number, Data, index) {
    DentalexaminationComponent.Upper_DataArray = [];
    DentalexaminationComponent.Upper_DataArray.push(""); // 0
    DentalexaminationComponent.Upper_DataArray.push(""); // 1
    DentalexaminationComponent.Upper_DataArray.push(""); // 2
    DentalexaminationComponent.Upper_DataArray.push(""); // 3
    DentalexaminationComponent.Upper_DataArray.push(""); // 4
    DentalexaminationComponent.Upper_DataArray.push(""); // 5
    DentalexaminationComponent
    if (Data != "") {
      var Templist = Data.split('c');
      var Tempsplitag = Templist[0].split('');

      for (var i = 0; i < Tempsplitag.length; i++) {
        if (Tempsplitag[i] == "1") {
          DentalexaminationComponent.Upper_DataArray[0] = "1";

        } else if (Tempsplitag[i] == "2") {
          DentalexaminationComponent.Upper_DataArray[1] = "2";

        } else if (Tempsplitag[i] == "3") {
          DentalexaminationComponent.Upper_DataArray[2] = "3";

        } else if (Tempsplitag[i] == "4") {
          DentalexaminationComponent.Upper_DataArray[3] = "4";

        } else if (Tempsplitag[i] == "5") {
          DentalexaminationComponent.Upper_DataArray[4] = "5";

        } else if (Tempsplitag[i] == "6") {
          DentalexaminationComponent.Upper_DataArray[5] = "6";
        }
      }
    }
    var data1 = {
      img_path: Imagepath,
      teeth_no: teeth_number,
      index: index,
      Upper_Image_List: this.Upper_Image_List,
      Lower_Image_List: this.Lower_Image_List
    }
    //Doc_Helper.setexam
    const dialogRef = this.dialog.open(DentalteethpopupComponent, {
      width: '355px',
      height: '425px',

      data: {
        img_path: Imagepath,
        teeth_no: teeth_number,
        index: index,
        Upper_Image_List: this.Upper_Image_List,
        Lower_Image_List: this.Lower_Image_List
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      //this.dis_txt = result;

      if (data != undefined) {
        if (data["Upper_Image_List"] != null) {
          this.Upper_Image_List = data["Upper_Image_List"];
        }

        if (data["Lower_Image_List"] != null) {
          this.Lower_Image_List = data["Lower_Image_List"];
        }
      }
    });
    this.send_data();
    // let myModal = this.modalCtrl.create("DentalTeethPopupPage", {
    //   img_path: Imagepath,
    //   teeth_no: teeth_number,
    //   index: index,
    //   Upper_Image_List: this.Upper_Image_List,
    //   Lower_Image_List: this.Lower_Image_List

    // });
    // myModal.present();

    // myModal.onDidDismiss(data => {

    //   if (data != undefined) {
    //     if (data["Upper_Image_List"] != null) {
    //       this.Upper_Image_List = data["Upper_Image_List"];
    //     }

    //     if (data["Lower_Image_List"] != null) {
    //       this.Lower_Image_List = data["Lower_Image_List"];
    //     }
    //   }
    // })
  }

  Lower_Image_View_Click(Imagepath, teeth_number, Data, index) {
    DentalexaminationComponent.Lower_DataArray = [];
    DentalexaminationComponent.Lower_DataArray.push(""); // 0
    DentalexaminationComponent.Lower_DataArray.push(""); // 1
    DentalexaminationComponent.Lower_DataArray.push(""); // 2
    DentalexaminationComponent.Lower_DataArray.push(""); // 3
    DentalexaminationComponent.Lower_DataArray.push(""); // 4
    DentalexaminationComponent.Lower_DataArray.push(""); // 5

    if (Data != "") {
      var Templist = Data.split('c');

      var Tempsplitag = Templist[0].split('');

      for (var i = 0; i < Tempsplitag.length; i++) {
        if (Tempsplitag[i] == "1") {
          DentalexaminationComponent.Lower_DataArray[0] = "1";

        } else if (Tempsplitag[i] == "2") {
          DentalexaminationComponent.Lower_DataArray[1] = "2";

        } else if (Tempsplitag[i] == "3") {
          DentalexaminationComponent.Lower_DataArray[2] = "3";

        } else if (Tempsplitag[i] == "4") {
          DentalexaminationComponent.Lower_DataArray[3] = "4";

        } else if (Tempsplitag[i] == "5") {
          DentalexaminationComponent.Lower_DataArray[4] = "5";

        } else if (Tempsplitag[i] == "6") {
          DentalexaminationComponent.Lower_DataArray[5] = "6";
        }
      }
    }
    var data1 = {
      img_path: Imagepath,
      teeth_no: teeth_number,
      index: index,
      Upper_Image_List: this.Upper_Image_List,
      Lower_Image_List: this.Lower_Image_List
    }
    //Doc_Helper.setexam
    const dialogRef = this.dialog.open(DentalteethpopupComponent, {
      width: '355px',
      height: '425px',
      data: {
        img_path: Imagepath,
        teeth_no: teeth_number,
        index: index,
        Upper_Image_List: this.Upper_Image_List,
        Lower_Image_List: this.Lower_Image_List
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      //this.dis_txt = result;
      if (data != undefined) {
        if (data["Upper_Image_List"] != null) {
          this.Upper_Image_List = data["Upper_Image_List"];
        }

        if (data["Lower_Image_List"] != null) {
          this.Lower_Image_List = data["Lower_Image_List"];
        }
      }
    });
    this.send_data();
    // let myModal = this.modalCtrl.create("DentalTeethPopupPage", {
    //   img_path: Imagepath,
    //   teeth_no: teeth_number,
    //   index: index,
    //   Upper_Image_List: this.Upper_Image_List,
    //   Lower_Image_List: this.Lower_Image_List

    // });
    // myModal.present();

    // myModal.onDidDismiss(data => {
    //   if (data != undefined) {
    //     if (data["Upper_Image_List"] != null) {
    //       this.Upper_Image_List = data["Upper_Image_List"];
    //     }

    //     if (data["Lower_Image_List"] != null) {
    //       this.Lower_Image_List = data["Lower_Image_List"];
    //     }
    //   }
    // })
  }

  accordian_perio(flag) {

  }

  // set perio chart data
  Set_Chart_Data(periochart) {
    for (var i = 0; i < periochart.length; i++) {
      if (periochart[i].den_teeth_name_id == "18") {
        this.Set_Upper_Value(periochart[i], 0);

      } else if (periochart[i].den_teeth_name_id == "17") {
        this.Set_Upper_Value(periochart[i], 1);

      } else if (periochart[i].den_teeth_name_id == "16") {
        this.Set_Upper_Value(periochart[i], 2);

      } else if (periochart[i].den_teeth_name_id == "15") {
        this.Set_Upper_Value(periochart[i], 3);

      } else if (periochart[i].den_teeth_name_id == "14") {
        this.Set_Upper_Value(periochart[i], 4);

      } else if (periochart[i].den_teeth_name_id == "13") {
        this.Set_Upper_Value(periochart[i], 5);

      } else if (periochart[i].den_teeth_name_id == "12") {
        this.Set_Upper_Value(periochart[i], 6);

      } else if (periochart[i].den_teeth_name_id == "11") {
        this.Set_Upper_Value(periochart[i], 7);

      } else if (periochart[i].den_teeth_name_id == "21") {
        this.Set_Upper_Value(periochart[i], 8);

      } else if (periochart[i].den_teeth_name_id == "22") {
        this.Set_Upper_Value(periochart[i], 9);

      } else if (periochart[i].den_teeth_name_id == "23") {
        this.Set_Upper_Value(periochart[i], 10);

      } else if (periochart[i].den_teeth_name_id == "24") {
        this.Set_Upper_Value(periochart[i], 11);

      } else if (periochart[i].den_teeth_name_id == "25") {
        this.Set_Upper_Value(periochart[i], 12);

      } else if (periochart[i].den_teeth_name_id == "26") {
        this.Set_Upper_Value(periochart[i], 13);

      } else if (periochart[i].den_teeth_name_id == "27") {
        this.Set_Upper_Value(periochart[i], 14);

      } else if (periochart[i].den_teeth_name_id == "28") {
        this.Set_Upper_Value(periochart[i], 15);
      }

      // Lower Data
      else if (periochart[i].den_teeth_name_id == "48") {
        this.Set_Lower_Value(periochart[i], 0);

      } else if (periochart[i].den_teeth_name_id == "47") {
        this.Set_Lower_Value(periochart[i], 1);

      } else if (periochart[i].den_teeth_name_id == "46") {
        this.Set_Lower_Value(periochart[i], 2);

      } else if (periochart[i].den_teeth_name_id == "45") {
        this.Set_Lower_Value(periochart[i], 3);

      } else if (periochart[i].den_teeth_name_id == "44") {
        this.Set_Lower_Value(periochart[i], 4);

      } else if (periochart[i].den_teeth_name_id == "43") {
        this.Set_Lower_Value(periochart[i], 5);

      } else if (periochart[i].den_teeth_name_id == "42") {

        this.Set_Lower_Value(periochart[i], 6);

      } else if (periochart[i].den_teeth_name_id == "41") {
        this.Set_Lower_Value(periochart[i], 7);

      } else if (periochart[i].den_teeth_name_id == "31") {
        this.Set_Lower_Value(periochart[i], 8);

      } else if (periochart[i].den_teeth_name_id == "32") {
        this.Set_Lower_Value(periochart[i], 9);

      } else if (periochart[i].den_teeth_name_id == "33") {
        this.Set_Lower_Value(periochart[i], 10);

      } else if (periochart[i].den_teeth_name_id == "34") {
        this.Set_Lower_Value(periochart[i], 11);

      } else if (periochart[i].den_teeth_name_id == "35") {
        this.Set_Lower_Value(periochart[i], 12);

      } else if (periochart[i].den_teeth_name_id == "36") {
        this.Set_Lower_Value(periochart[i], 13);

      } else if (periochart[i].den_teeth_name_id == "37") {
        this.Set_Lower_Value(periochart[i], 14);

      } else if (periochart[i].den_teeth_name_id == "38") {
        this.Set_Lower_Value(periochart[i], 15);
      }
    }
  }

  Set_Upper_Value(data, position) {
    if (data.mobility != "") {
      this.Upper_Mobility_List[position] = { Value: data.mobility };

    } else {
      this.Upper_Mobility_List[position] = { Value: "0" };
    }

    if (data.implant == "Yes" || data.implant == "yes") {
      this.Upper_Implant_List[position] = { Check: true };

    } else {
      this.Upper_Implant_List[position] = { Check: false };
    }

    if (data.furaction != "") {
      this.Upper_Furcation_List[position] = { Value: data.furaction };

    } else {
      this.Upper_Furcation_List[position] = { Value: "0" };
    }

    var Red_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#ed1c24"
    }

    if (data.bleed_probe == "1" || data.bleed_probe == "5") {
      Red_Obj.width = "33%";
      this.Upper_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else if (data.bleed_probe == "2" || data.bleed_probe == "4") {
      Red_Obj.width = "66%";
      this.Upper_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else if (data.bleed_probe == "3") {
      Red_Obj.width = "100%";
      this.Upper_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else {
      Red_Obj.width = "100%";
      Red_Obj["background-color"] = "transparent";
      this.Upper_BleedProbe_List[position] = { Value: "0", Style: Red_Obj };
    }

    var Yellow_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#f7d431"
    }

    if (data.plaque == "1" || data.plaque == "5") {
      Yellow_Obj.width = "33%";
      this.Upper_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else if (data.plaque == "2" || data.plaque == "4") {
      Yellow_Obj.width = "66%";
      this.Upper_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else if (data.plaque == "3") {
      Yellow_Obj.width = "100%";
      this.Upper_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else {
      Yellow_Obj.width = "100%";
      Yellow_Obj["background-color"] = "transparent";

      this.Upper_Plaque_List[position] = { Value: "0", Style: Yellow_Obj };
    }

    if (data.gingiv_margin == "Yes" || data.gingiv_margin == "yes") {
      this.Upper_Gingival_List[position] = { Check: true };

    } else {
      this.Upper_Gingival_List[position] = { Check: false };
    }

    // Upper Prob & Depth data
    if (data.probe_depth != null) {
      if (position == 0) {
        this.Value_18_01 = "0-0-0";
        this.Value_18_01 = data.probe_depth;
        var get_data = this.Value_18_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_18_Chart");

      } else if (position == 1) {
        this.Value_17_01 = "0-0-0";
        this.Value_17_01 = data.probe_depth;
        var get_data = this.Value_17_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_17_Chart");

      } else if (position == 2) {
        this.Value_16_01 = "0-0-0";
        this.Value_16_01 = data.probe_depth;
        var get_data = this.Value_16_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_16_Chart");

      } else if (position == 3) {
        this.Value_15_01 = "0-0-0";
        this.Value_15_01 = data.probe_depth;
        var get_data = this.Value_15_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_15_Chart");

      } else if (position == 4) {
        this.Value_14_01 = "0-0-0";
        this.Value_14_01 = data.probe_depth;
        var get_data = this.Value_14_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_14_Chart");

      } else if (position == 5) {
        this.Value_13_01 = "0-0-0";
        this.Value_13_01 = data.probe_depth;
        var get_data = this.Value_13_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_13_Chart");

      } else if (position == 6) {
        this.Value_12_01 = "0-0-0";
        this.Value_12_01 = data.probe_depth;
        var get_data = this.Value_12_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_12_Chart");

      } else if (position == 7) {
        this.Value_11_01 = "0-0-0";
        this.Value_11_01 = data.probe_depth;
        var get_data = this.Value_11_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_11_Chart");

      } else if (position == 8) {
        this.Value_21_01 = "0-0-0";
        this.Value_21_01 = data.probe_depth;
        var get_data = this.Value_21_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_21_Chart");

      } else if (position == 9) {
        this.Value_22_01 = "0-0-0";
        this.Value_22_01 = data.probe_depth;
        var get_data = this.Value_22_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_22_Chart");

      } else if (position == 10) {
        this.Value_23_01 = "0-0-0";
        this.Value_23_01 = data.probe_depth;
        var get_data = this.Value_23_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_23_Chart");

      } else if (position == 11) {
        this.Value_24_01 = "0-0-0";
        this.Value_24_01 = data.probe_depth;
        var get_data = this.Value_24_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_24_Chart");

      } else if (position == 12) {
        this.Value_25_01 = "0-0-0";
        this.Value_25_01 = data.probe_depth;
        var get_data = this.Value_25_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_25_Chart");

      } else if (position == 13) {
        this.Value_26_01 = "0-0-0";
        this.Value_26_01 = data.probe_depth;
        var get_data = this.Value_26_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_26_Chart");

      } else if (position == 14) {
        this.Value_27_01 = "0-0-0";
        this.Value_27_01 = data.probe_depth;
        var get_data = this.Value_27_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_27_Chart");

      } else if (position == 15) {
        this.Value_28_01 = "0-0-0";
        this.Value_28_01 = data.probe_depth;
        var get_data = this.Value_28_01.split('-');
        this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_28_Chart");
      }
    }

    if (data.palpation != "") {
      this.Upper_Palpation_List[position] = { Value: data.palpation };

    } else {
      this.Upper_Palpation_List[position] = { Value: "0" };
    }

    if (data.attach_loss == "Yes" || data.attach_loss == "yes") {
      //
      this.Upper_Attachment_List[position] = { Check: true };

    } else {
      this.Upper_Attachment_List[position] = { Check: false };
    }

    if (data.reccesion != "") {
      this.Upper_Recession_List[position] = { Value: data.reccesion };

    } else {
      this.Upper_Recession_List[position] = { Value: "0" };
    }

    if (data.area != null) {
      var Oneflag = "";
      var Twoflag = "";
      var Threeflag = "";
      var Fourflag = "";
      var Fiveflag = "";
      var Sixflag = "";
      var Temp_Data = "";

      if (data.area.length != 0) {
        for (var i = 0; i < data.area.length; i++) {
          if (data.area[i] == "1") {
            Oneflag = "1";

          } else if (data.area[i] == "2") {
            Twoflag = "2";

          } else if (data.area[i] == "3") {
            Threeflag = "3";

          } else if (data.area[i] == "4") {
            Fourflag = "4";

          } else if (data.area[i] == "5") {
            Fiveflag = "5";

          } else if (data.area[i] == "6") {
            Sixflag = "6";
          }
        }
        Temp_Data = Oneflag + Twoflag + Threeflag + Fourflag + Fiveflag + Sixflag + "c.png";

      } else {
        Temp_Data = "";
      }
      this.Teeth_Upper_Area_Image(position, Temp_Data);
    }
  }
  Upper_BleedProbe_Btnclick(click, index) {
    var Click_Value = click;

    if (parseInt(Click_Value) < 6) {
      ++Click_Value;
    }
    else {
      Click_Value = "1";
    }

    var Red_Obj = {
      "width": "0%",
      "background-color": "#ed1c24",
      "float": "left"
    }

    if (Click_Value == "1" || Click_Value == "5") {
      Red_Obj.width = "33%";
      this.Upper_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else if (Click_Value == "2" || Click_Value == "4") {
      Red_Obj.width = "66%";
      this.Upper_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else if (Click_Value == "3") {
      Red_Obj.width = "100%";
      this.Upper_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else {
      Red_Obj.width = "100%";
      Red_Obj["background-color"] = "transparent";

      this.Upper_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };
    }
    this.send_data();
  }
  Lower_BleedProbe_Btnclick(click, index) {
    var Click_Value = click;

    if (parseInt(Click_Value) < 6) {
      ++Click_Value;

    } else {
      Click_Value = "1";
    }

    var Red_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#ed1c24"
    }

    if (Click_Value == "1" || Click_Value == "5") {
      Red_Obj.width = "33%";
      this.Lower_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else if (Click_Value == "2" || Click_Value == "4") {
      Red_Obj.width = "66%";
      this.Lower_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else if (Click_Value == "3") {
      Red_Obj.width = "100%";
      this.Lower_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };

    } else {
      Red_Obj.width = "100%";
      Red_Obj["background-color"] = "transparent";
      this.Lower_BleedProbe_List[index] = { Value: Click_Value, Style: Red_Obj };
    }
    this.send_data()
  }

  Upper_Plaque_Btnclick(click, index) {
    var Click_Value = click;

    if (parseInt(Click_Value) < 6) {
      ++Click_Value;
    }
    else {
      Click_Value = "1";
    }

    var Yellow_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#f7d431"
    }

    if (Click_Value == "1" || Click_Value == "5") {
      Yellow_Obj.width = "33%";
      this.Upper_Plaque_List[index] = { Value: Click_Value, Style: Yellow_Obj };

    } else if (Click_Value == "2" || Click_Value == "4") {
      Yellow_Obj.width = "66%";
      this.Upper_Plaque_List[index] = { Value: Click_Value, Style: Yellow_Obj };

    } else if (Click_Value == "3") {
      Yellow_Obj.width = "100%";
      this.Upper_Plaque_List[index] = { Value: Click_Value, Style: Yellow_Obj };

    } else {
      Yellow_Obj.width = "100%";
      Yellow_Obj["background-color"] = "transparent";
      this.Upper_Plaque_List[index] = { Value: Click_Value, Style: Yellow_Obj };
    }
    this.send_data()
  }

  Lower_Plaque_Btnclick(click, index) {
    var Click_Value = click;

    if (parseInt(Click_Value) < 6) {
      ++Click_Value;

    } else {
      Click_Value = "1";
    }

    var Lower_Yellow_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#f7d431"
    }

    if (Click_Value == "1" || Click_Value == "5") {
      Lower_Yellow_Obj.width = "33%";
      this.Lower_Plaque_List[index] = { Value: Click_Value, Style: Lower_Yellow_Obj };

    } else if (Click_Value == "2" || Click_Value == "4") {
      Lower_Yellow_Obj.width = "66%";
      this.Lower_Plaque_List[index] = { Value: Click_Value, Style: Lower_Yellow_Obj };

    } else if (Click_Value == "3") {
      Lower_Yellow_Obj.width = "100%";
      this.Lower_Plaque_List[index] = { Value: Click_Value, Style: Lower_Yellow_Obj };

    } else {
      Lower_Yellow_Obj.width = "100%";
      Lower_Yellow_Obj["background-color"] = "transparent";
      this.Lower_Plaque_List[index] = { Value: Click_Value, Style: Lower_Yellow_Obj };
    }
    this.send_data()
  }

  Set_Lower_Value(data, position) {
    if (data.mobility != "") {
      this.Lower_Mobility_List[position] = { Value: data.mobility };
    }
    else {
      this.Lower_Mobility_List[position] = { Value: "0" };
    }

    if (data.implant == "Yes" || data.implant == "yes") {
      this.Lower_Implant_List[position] = { Check: true };
    }
    else {
      this.Lower_Implant_List[position] = { Check: false };
    }

    if (data.furaction != "") {
      this.Lower_Furcation_List[position] = { Value: data.furaction };
    }
    else {
      this.Lower_Furcation_List[position] = { Value: "0" };
    }

    var Red_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#ed1c24"
    }

    if (data.bleed_probe == "1" || data.bleed_probe == "5") {
      Red_Obj.width = "33%";
      this.Lower_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else if (data.bleed_probe == "2" || data.bleed_probe == "4") {
      Red_Obj.width = "66%";
      this.Lower_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else if (data.bleed_probe == "3") {
      Red_Obj.width = "100%";
      this.Lower_BleedProbe_List[position] = { Value: data.bleed_probe, Style: Red_Obj };

    } else {
      Red_Obj.width = "100%";
      Red_Obj["background-color"] = "transparent";

      this.Lower_BleedProbe_List[position] = { Value: "0", Style: Red_Obj };
    }

    var Yellow_Obj = {
      "float": "left",
      "width": "0%",
      "background-color": "#f7d431"
    }

    if (data.plaque == "1" || data.plaque == "5") {
      Yellow_Obj.width = "33%";
      this.Lower_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else if (data.plaque == "2" || data.plaque == "4") {
      Yellow_Obj.width = "66%";
      this.Lower_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else if (data.plaque == "3") {
      Yellow_Obj.width = "100%";
      this.Lower_Plaque_List[position] = { Value: data.plaque, Style: Yellow_Obj };

    } else {
      Yellow_Obj.width = "100%";
      Yellow_Obj["background-color"] = "transparent";

      this.Lower_Plaque_List[position] = { Value: "0", Style: Yellow_Obj };
    }

    if (data.gingiv_margin == "Yes" || data.gingiv_margin == "yes") {
      this.Lower_Gingival_List[position] = { Check: true };
    }
    else {
      this.Lower_Gingival_List[position] = { Check: false };
    }

    // Probing Depth Data
    if (data.probe_depth != null) {
      if (position == 0) {
        this.Value_48_01 = data.probe_depth;
        var get_data = this.Value_48_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_48_Chart");
     
      } else if (position == 1) {
        this.Value_47_01 = data.probe_depth;
        var get_data = this.Value_47_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_47_Chart");
      
      } else if (position == 2) {
        this.Value_46_01 = data.probe_depth;
        var get_data = this.Value_46_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_46_Chart");
      
      } else if (position == 3) {
        this.Value_45_01 = data.probe_depth;
        var get_data = this.Value_45_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_45_Chart");
      
      } else if (position == 4) {
        this.Value_44_01 = data.probe_depth;
        var get_data = this.Value_44_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_44_Chart");
      
      } else if (position == 5) {
        this.Value_43_01 = data.probe_depth;
        var get_data = this.Value_43_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_43_Chart");
      
      } else if (position == 6) {
        this.Value_42_01 = data.probe_depth;
        var get_data = this.Value_42_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_42_Chart");
      
      } else if (position == 7) {
        this.Value_41_01 = data.probe_depth;
        var get_data = this.Value_41_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_41_Chart");
      
      } else if (position == 8) {
        this.Value_31_01 = data.probe_depth;
        var get_data = this.Value_31_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_31_Chart");
      
      } else if (position == 9) {
        this.Value_32_01 = data.probe_depth;
        var get_data = this.Value_32_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_32_Chart");
      
      } else if (position == 10) {
        this.Value_33_01 = data.probe_depth;
        var get_data = this.Value_33_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_33_Chart");
      
      } else if (position == 11) {
        this.Value_34_01 = data.probe_depth;
        var get_data = this.Value_34_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_34_Chart");
      
      } else if (position == 12) {
        this.Value_35_01 = data.probe_depth;
        var get_data = this.Value_35_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_35_Chart");
      
      } else if (position == 13) {
        this.Value_36_01 = data.probe_depth;
        var get_data = this.Value_36_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_36_Chart");
      
      } else if (position == 14) {
        this.Value_37_01 = data.probe_depth;
        var get_data = this.Value_37_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_37_Chart");
      
      } else if (position == 15) {
        this.Value_38_01 = data.probe_depth;
        var get_data = this.Value_38_01.split('-');
        this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_38_Chart");
      }
    }

    // $scope.Lower_ProbDepth_List[position] = { Value: data.probe_depth };
    this.Lower_Palpation_List[position] = { Value: data.palpation };
    if (data.attach_loss == "yes" || data.attach_loss == "Yes") {
      this.Lower_Attachment_List[position] = { Check: true };
    } else {
      this.Lower_Attachment_List[position] = { Check: false };
    }

    if (data.reccesion != "") {
      this.Lower_Recession_List[position] = { Value: data.reccesion };
    } else {
      this.Lower_Recession_List[position] = { Value: "0" };
    }

    if (data.area != null) {
      var Oneflag = "";
      var Twoflag = "";
      var Threeflag = "";
      var Fourflag = "";
      var Fiveflag = "";
      var Sixflag = "";

      var Temp_Data = "";

      if (data.area.length != 0) {
        for (var i = 0; i < data.area.length; i++) {
          if (data.area[i] == "1") {
            Oneflag = "1";
          }
          else if (data.area[i] == "2") {
            Twoflag = "2";
          }
          else if (data.area[i] == "3") {
            Threeflag = "3";
          }
          else if (data.area[i] == "4") {
            Fourflag = "4";
          }
          else if (data.area[i] == "5") {
            Fiveflag = "5";
          }
          else if (data.area[i] == "6") {
            Sixflag = "6";
          }
        }

        //if ($scope.Oneflag == "" || $scope.Twoflag == "" || $scope.Threeflag == "" || $scope.Fourflag == "" || $scope.Fiveflag == "" || $scope.Sixflag == "") {
        //    $scope.Temp_Data = $scope.Oneflag + $scope.Twoflag + $scope.Threeflag + $scope.Fourflag + $scope.Fiveflag + $scope.Sixflag + "c.png";
        //}
        //else {
        //    $scope.Temp_Data = $scope.Oneflag + $scope.Twoflag + $scope.Threeflag + $scope.Fourflag + $scope.Fiveflag + $scope.Sixflag + ".png";
        //}

        Temp_Data = Oneflag + Twoflag + Threeflag + Fourflag + Fiveflag + Sixflag + "c.png";

      }
      else {
        Temp_Data = "";
      }

      this.Teeth_Lower_Area_Image(position, Temp_Data);
    }
    // $scope.Lower_Recession_List[position] = { Value: data.reccesion };
  }

  Teeth_Upper_Area_Image(position, data) {

    var Tempimgpath = null;
    if (position == "0" || position == "15") // Teeth 18 & 28
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_18_third_molar_rev" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/18_third_molar_reverse.png";
      }

      if (position == "0") {
        this.Upper_Image_List[position] = { Teeth: "18", Imgpath: Tempimgpath, Value: data }

      }
      else {
        this.Upper_Image_List[position] = { Teeth: "28", Imgpath: Tempimgpath, Value: data }

      }
    }
    else if (position == "1" || position == "14") // Teeth 17 & 27
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_17_Sec_molar_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/17_second_molar_reverse.png";
      }
      if (position == "1") {
        this.Upper_Image_List[position] = { Teeth: "17", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "27", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "2" || position == "13") // Teeth 16 & 26
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_16_Fir_molar_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/16_first_molar_reverse.png";
      }
      if (position == "2") {
        this.Upper_Image_List[position] = { Teeth: "16", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "26", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "3" || position == "12") // Teeth 15 & 25
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_15_SecPre_molar_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/15_second_premolar_reverse.png";
      }
      if (position == "3") {
        this.Upper_Image_List[position] = { Teeth: "15", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "25", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "4" || position == "11") // Teeth 14 & 24
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_14_FirPre_molar_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/14_first_premolar_reverse.png";
      }
      if (position == "4") {
        this.Upper_Image_List[position] = { Teeth: "14", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "24", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "5" || position == "10") // Teeth 13 & 23
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_13_canine_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/13_canine_reverse.png";
      }

      this.Upper_Image_List[position] = { Teeth: "13,23", Imgpath: Tempimgpath, Value: data }
    }
    else if (position == "6" || position == "9") // Teeth 12 & 22
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_12_Lat_insisor_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/12_latrel_insisor_reverse.png";
      }
      if (position == "6") {
        this.Upper_Image_List[position] = { Teeth: "12", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "22", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "7" || position == "8") // Teeth 11 & 21
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Upper_11_Central_insisor_rev/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/11_central_insisor_reverse.png";
      }
      if (position == "7") {
        this.Upper_Image_List[position] = { Teeth: "11", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Upper_Image_List[position] = { Teeth: "21", Imgpath: Tempimgpath, Value: data }
      }
    }

  }

  Teeth_Lower_Area_Image(position, data) {

    var Tempimgpath = null;
    if (position == "0" || position == "15") // Teeth 18 & 28
    {

      if (data != "") {
        Tempimgpath = "../../../assets/img/Teethimg/TSI_Lower_48_third_molar/" + data;
      }
      else {
        Tempimgpath = "../../../assets/img/Teeth_Small_img/48_third_molar.png";
      }
      if (position == "0") {
        this.Lower_Image_List[position] = { Teeth: "48", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "38", Imgpath: Tempimgpath, Value: data }

      }
    }
    else if (position == "1" || position == "14") // Teeth 17 & 27
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_47_Sec_molar/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/47_second_molar.png";
      }
      if (position == "1") {
        this.Lower_Image_List[position] = { Teeth: "47", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "37", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "2" || position == "13") // Teeth 16 & 26
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_46_Fir_molar/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/46_first_molar.png";
      }
      if (position == "2") {
        this.Lower_Image_List[position] = { Teeth: "46", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "36", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "3" || position == "12") // Teeth 15 & 25
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_45_SecPre_molar/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/45_second_premolar.png";
      }
      if (position == "3") {
        this.Lower_Image_List[position] = { Teeth: "45", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "35", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "4" || position == "11") // Teeth 14 & 24
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_44_FirPre_molar/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/44_first_premolar.png";
      }
      if (position == "4") {
        this.Lower_Image_List[position] = { Teeth: "44", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "34", Imgpath: Tempimgpath, Value: data }

      }
    }
    else if (position == "5" || position == "10") // Teeth 13 & 23
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_43_canine/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/43_canine.png";
      }
      if (position == "5") {
        this.Lower_Image_List[position] = { Teeth: "43", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "33", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "6" || position == "9") // Teeth 12 & 22
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_42_Lat_insisor/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/42_latrel_insisor.png";
      }
      if (position == "6") {
        this.Lower_Image_List[position] = { Teeth: "42", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "32", Imgpath: Tempimgpath, Value: data }
      }
    }
    else if (position == "7" || position == "8") // Teeth 11 & 21
    {
      if (data != "") {
        Tempimgpath = "../../assets/img/Teethimg/TSI_Lower_41_Central_insisor/" + data;
      }
      else {
        Tempimgpath = "../../assets/img/Teeth_Small_img/41_central_insisor.png";
      }
      if (position == "7") {
        this.Lower_Image_List[position] = { Teeth: "41", Imgpath: Tempimgpath, Value: data }
      }
      else {
        this.Lower_Image_List[position] = { Teeth: "31", Imgpath: Tempimgpath, Value: data }

      }
    }
  }

  default_graph() {
    // General_Upper_Chart
    var Stylevalue = "0px";
    var Chart_size = 0;
    var Newarray = [];
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      var message1 = message.spl
      if (message1) {
        this.speciality_change(message1)

        //var i= await(this.speciality_change(message1));
        Doc_Helper.set_dental_spl_name(message1.spl);

      } else {
        // clear messages when empty message received
        this.pedio = [];
      }
    });

  }

  // ngOnDestroy() {
  //   // unsubscribe to ensure no memory leaks
  //    this.subscription.unsubscribe();
  //   }

  speciality_change(e) {


    this.get_spl_name = "";
    this.get_spl_name = e;

    if (this.get_spl_name == "Periodontics" || this.get_spl_name == "Endodontics" || this.get_spl_name == "Prosthodontics") {
      this.perio_div = false;

    } else {
      this.perio_div = true;
    }


  }
  Retrival_data() {
    // retival
    var getden_ret2 = Helper_Class.Get_den_ret2();

    if (getden_ret2 != undefined && getden_ret2.clinical_exam != undefined) {
      this.clinic_arry_list = [];

      for (var i = 0; i < getden_ret2.clinical_exam.length; i++) {
        this.clinic_arry_list.push({
          jaw_type: getden_ret2.clinical_exam[i].jaw_type,
          teeth_type: getden_ret2.clinical_exam[i].teeth_type,
          time_line: getden_ret2.clinical_exam[i].time_line,
          teeth_color: getden_ret2.clinical_exam[i].colour,
          teeth_shape: getden_ret2.clinical_exam[i].teeth_shape,
          teeth_cons: getden_ret2.clinical_exam[i].consistency,
          dental_issue: getden_ret2.clinical_exam[i].den_issue_id,
          teethname: getden_ret2.clinical_exam[i].time_line_desc,
          colour: getden_ret2.clinical_exam[i].color_desc,
          consistency: getden_ret2.clinical_exam[i].Contiontxt,
          condition: getden_ret2.clinical_exam[i].den_issue_desc,
          teeth_shape_show: getden_ret2.clinical_exam[i].teeth_shape_desc,
          icd_10_code: getden_ret2.clinical_exam[i].icd_10_code,
          notes: getden_ret2.clinical_exam[i].note,
          clinic_data: "0"
        })
      }

      if (this.clinic_arry_list.length != 0) {
        this.clinicalflag = true;

      } else {
        this.clinicalflag = false;
      }

      var den_ret1 = Helper_Class.Get_den_ret1()
      if (den_ret1.missing_teeth != null) {
        this.missing_teeth = den_ret1.missing_teeth;
      }

      if (den_ret1.calculus != null) {
        this.calculus_txt = den_ret1.calculus;
      }

      if (den_ret1.oral_hygiene != null) {
        this.oral_hygiene_txt = den_ret1.oral_hygiene;
      }

      if (den_ret1.swelling != null) {
        this.swell_txt = den_ret1.swelling;
      }

      if (den_ret1.malocclusion != null) {
        this.mal_occ_txt = den_ret1.malocclusion;
      }

      if (den_ret1.supraeruption != null) {
        this.sup_erup_txt = den_ret1.supraeruption;
      }

      if (den_ret1.clinic_other != null) {
        this.other_txt = den_ret1.clinic_other;
      }

      if (den_ret1.mobility != null) {
        this.mobility_txt = den_ret1.mobility;
      }

      if (den_ret1.bleeding != null) {
        this.bleeding_txt = den_ret1.bleeding;
      }

      if (den_ret1.stains != null) {
        this.stains_txt = den_ret1.stains;
      }

      if (den_ret1.dental_carries != null) {
        this.dental_carries_txt = den_ret1.dental_carries;
      }
      if (den_ret1.root_stem != null) {
        this.root_stem_txt = den_ret1.root_stem;
      }

      if (getden_ret2.periochart != null) {
        this.Set_Chart_Data(getden_ret2.periochart);
      }

    }
  }

  Loaddefaultgraph() {
    for (var i = 11; i <= 18; i++) {
      var id = "Upper_" + i + "_Chart";
      this.General_Upper_Chart("0", "0", "0", id);
    }

    for (var i = 21; i <= 28; i++) {
      var id = "Upper_" + i + "_Chart";
      this.General_Upper_Chart("0", "0", "0", id);
    }

    for (var i = 31; i <= 38; i++) {
      var id = "Lower_" + i + "_Chart";
      this.General_Lower_Chart("0", "0", "0", id);
    }
    for (var i = 41; i <= 48; i++) {
      var id = "Lower_" + i + "_Chart";
      this.General_Lower_Chart("0", "0", "0", id);
    }
  }

  selected_teethimage(data){
    if (this.send_teeth_name_array.length != 0) {
      var chk_flag = true;;
      for (var i = 0; i < this.send_teeth_name_array.length; i++) {
        if (data == this.send_teeth_name_array[i]) {
          this.send_teeth_name_array.splice(i, 1);
          chk_flag = false;
          break;
        }
      }
      if (chk_flag == true) {
        for (var i = 0; i < this.Get_teethname.length; i++) {
          if (data == this.Get_teethname[i].den_teeth_name_id) {
            this.send_teeth_name_array.push(this.Get_teethname[i].den_teeth_name_id);
            //teeth_des.style.backgroundImage = "";
            break;
          }
        }
      }

    } else {
      for (var i = 0; i < this.Get_teethname.length; i++) {
        if (data == this.Get_teethname[i].den_teeth_name_id) {
          this.send_teeth_name_array.push(this.Get_teethname[i].den_teeth_name_id);
          //teeth_des.style.backgroundImage = "";
          break;
        }
      }
    }
  }

  //shape of teeth
  async get_shape_of_teeth_array() {
    var obj = masterCSData_Helper.getMasterShapeofTeeth();
    if (obj != undefined) {
      this.shape_of_teeth_array = obj.teeth_shape;
      this.shape_of_teeth = this.shape_of_teeth_array[0].shape_id;
    }

    if (masterCSData_Helper.getMasterColorofTeeth() == undefined) {
      await this.masterData.getColorofTeeth();
      this.get_color_of_teeth_array();
    } else {
      this.get_color_of_teeth_array();
    }
  }

  //color
  async get_color_of_teeth_array() {
    var obj = masterCSData_Helper.getMasterColorofTeeth();
    if (obj != undefined) {
      this.color_of_teeth_array = obj.teeth_color;
      this.color_of_teeth = this.color_of_teeth_array[0].teeth_color_id;
    }
    if (masterCSData_Helper.getMasterConsistencyofTeeth() == undefined) {
      await this.masterData.getConsistencyofTeeth();
      this.get_consistency_of_teeth_array();
    } else {
      this.get_consistency_of_teeth_array();
    }
  }
  // consistency

  async get_consistency_of_teeth_array() {
    var obj = masterCSData_Helper.getMasterConsistencyofTeeth();
    if (obj != undefined) {
      this.consistency_of_teeth_array = obj.teeth_cons;
      this.consistency_of_teeth = this.consistency_of_teeth_array[0].teeth_cons_id;
    }
    if (masterCSData_Helper.getMasterConditionofTeeth() == undefined) {
      await this.masterData.getConditionofTeeth();
      this.get_condition_of_teeth_array();
    } else {
      this.get_condition_of_teeth_array();
    }
  }

  get_condition_of_teeth_array() {
    var obj = masterCSData_Helper.getMasterConditionofTeeth();
    if (obj != undefined) {
      this.condition_of_teeth_array = obj.dental_issues;
      this.condition_of_teeth = this.condition_of_teeth_array[0].den_issue_id;
    }
    this.Retrival_data();
  }

  lower_probing_depth(teeth_number, value) {
    if (value == undefined) {
      value = "0-0-0"
    }

    const dialogRef = this.dialog.open(DentalProbingdepthpopupComponent, {
      width: '300px',
      height: '200px',
      data: {
        teeth_no: teeth_number,
        teeth_value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {


      if (result != undefined) {
        if (teeth_number == "48") {
          this.Value_48_01 = result;
          var get_data = this.Value_48_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_48_Chart");

        } else if (teeth_number == "47") {
          this.Value_47_01 = result;
          var get_data = this.Value_47_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_47_Chart");

        } else if (teeth_number == "46") {
          this.Value_46_01 = result;
          var get_data = this.Value_46_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_46_Chart");

        } else if (teeth_number == "45") {
          this.Value_45_01 = result;
          var get_data = this.Value_45_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_45_Chart");

        } else if (teeth_number == "44") {
          this.Value_44_01 = result;
          var get_data = this.Value_44_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_44_Chart");

        } else if (teeth_number == "43") {
          this.Value_43_01 = result;
          var get_data = this.Value_43_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_43_Chart");

        } else if (teeth_number == "42") {
          this.Value_42_01 = result;
          var get_data = this.Value_42_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_42_Chart");

        } else if (teeth_number == "41") {
          this.Value_41_01 = result;
          var get_data = this.Value_41_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_41_Chart");

        } else if (teeth_number == "31") {
          this.Value_31_01 = result;
          var get_data = this.Value_31_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_31_Chart");

        } else if (teeth_number == "32") {
          this.Value_32_01 = result;
          var get_data = this.Value_32_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_32_Chart");

        } else if (teeth_number == "33") {
          this.Value_33_01 = result;
          var get_data = this.Value_33_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_33_Chart");

        } else if (teeth_number == "34") {
          this.Value_34_01 = result;
          var get_data = this.Value_34_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_34_Chart");

        } else if (teeth_number == "35") {
          this.Value_35_01 = result;
          var get_data = this.Value_35_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_35_Chart");

        } else if (teeth_number == "36") {
          this.Value_36_01 = result;
          var get_data = this.Value_36_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_36_Chart");

        } else if (teeth_number == "37") {
          this.Value_37_01 = result;
          var get_data = this.Value_37_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_37_Chart");

        } else if (teeth_number == "38") {
          this.Value_38_01 = result;
          var get_data = this.Value_38_01.split('-');
          this.General_Lower_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Lower_38_Chart");
        }
      }


    });
    this.send_data();
  }

  // upper teeth selection
  upper_probing_depth(teeth_number, value) {
    if (value == undefined) {
      value = "0-0-0"
    }
    const dialogRef = this.dialog.open(DentalProbingdepthpopupComponent, {
      width: '300px',
      height: '200px',
      data: {
        teeth_no: teeth_number,
        teeth_value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {

        if (teeth_number == "18") {
          this.Value_18_01 = result;
          var get_data = this.Value_18_01.split('-');
          this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_18_Chart");

        } else if (teeth_number == "17") {
          this.Value_17_01 = result;
          var get_data = this.Value_17_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_17_Chart");

        } else if (teeth_number == "16") {
          this.Value_16_01 = result;
          var get_data = this.Value_16_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_16_Chart");

        } else if (teeth_number == "15") {
          this.Value_15_01 = result;
          var get_data = this.Value_15_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_15_Chart");

        } else if (teeth_number == "14") {
          this.Value_14_01 = result;
          var get_data = this.Value_14_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_14_Chart");

        } else if (teeth_number == "13") {
          this.Value_13_01 = result;
          var get_data = this.Value_13_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_13_Chart");

        } else if (teeth_number == "12") {
          this.Value_12_01 = result;
          var get_data = this.Value_12_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_12_Chart");

        } else if (teeth_number == "11") {
          this.Value_11_01 = result;
          var get_data = this.Value_11_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_11_Chart");

        } else if (teeth_number == "21") {
          this.Value_21_01 = result;
          var get_data = this.Value_21_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_21_Chart");

        } else if (teeth_number == "22") {
          this.Value_22_01 = result;
          var get_data = this.Value_22_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_22_Chart");

        } else if (teeth_number == "23") {
          this.Value_23_01 = result;
          var get_data = this.Value_23_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_23_Chart");

        } else if (teeth_number == "24") {
          this.Value_24_01 = result;
          var get_data = this.Value_24_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_24_Chart");

        } else if (teeth_number == "25") {
          this.Value_25_01 = result;
          var get_data = this.Value_25_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_25_Chart");

        } else if (teeth_number == "26") {
          this.Value_26_01 = result;
          var get_data = this.Value_26_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_26_Chart");

        } else if (teeth_number == "27") {
          this.Value_27_01 = result;
          var get_data = this.Value_27_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_27_Chart");

        } else if (teeth_number == "28") {
          this.Value_28_01 = result;
          var get_data = this.Value_28_01.split('-');
          this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_28_Chart");

        }
      }


    });
    this.send_data();
    // let myModal = this.modalCtrl.create("DentalProbdepathPopupPage", {
    //   // img_path: Imagepath,
    //   teeth_no: teeth_number,
    //   teeth_value: value
    // });
    // myModal.present();
    // myModal.onDidDismiss(data => {

    //   if (data != undefined) {
    //     if (teeth_number == "18") {
    //       this.Value_18_01 = data;
    //       var get_data = this.Value_18_01.split('-');
    //       this.General_Upper_Chart(get_data[0].toString(), get_data[1].toString(), get_data[2].toString(), "Upper_18_Chart");

    //     } else if (teeth_number == "17") {
    //       this.Value_17_01 = data;
    //       var get_data = this.Value_17_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_17_Chart");

    //     } else if (teeth_number == "16") {
    //       this.Value_16_01 = data;
    //       var get_data = this.Value_16_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_16_Chart");

    //     } else if (teeth_number == "15") {
    //       this.Value_15_01 = data;
    //       var get_data = this.Value_15_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_15_Chart");

    //     } else if (teeth_number == "14") {
    //       this.Value_14_01 = data;
    //       var get_data = this.Value_14_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_14_Chart");

    //     } else if (teeth_number == "13") {
    //       this.Value_13_01 = data;
    //       var get_data = this.Value_13_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_13_Chart");

    //     } else if (teeth_number == "12") {
    //       this.Value_12_01 = data;
    //       var get_data = this.Value_12_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_12_Chart");

    //     } else if (teeth_number == "11") {
    //       this.Value_11_01 = data;
    //       var get_data = this.Value_11_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_11_Chart");

    //     } else if (teeth_number == "21") {
    //       this.Value_21_01 = data;
    //       var get_data = this.Value_21_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_21_Chart");

    //     } else if (teeth_number == "22") {
    //       this.Value_22_01 = data;
    //       var get_data = this.Value_22_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_22_Chart");

    //     } else if (teeth_number == "23") {
    //       this.Value_23_01 = data;
    //       var get_data = this.Value_23_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_23_Chart");

    //     } else if (teeth_number == "24") {
    //       this.Value_24_01 = data;
    //       var get_data = this.Value_24_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_24_Chart");

    //     } else if (teeth_number == "25") {
    //       this.Value_25_01 = data;
    //       var get_data = this.Value_25_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_25_Chart");

    //     } else if (teeth_number == "26") {
    //       this.Value_26_01 = data;
    //       var get_data = this.Value_26_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_26_Chart");

    //     } else if (teeth_number == "27") {
    //       this.Value_27_01 = data;
    //       var get_data = this.Value_27_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_27_Chart");

    //     } else if (teeth_number == "28") {
    //       this.Value_28_01 = data;
    //       var get_data = this.Value_28_01.split('-');
    //       this.General_Upper_Chart(get_data[0], get_data[1], get_data[2], "Upper_28_Chart");

    //     }
    //   }
    // })
  }

}
