import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-inventory-config',
  templateUrl: './inventory-config.component.html',
  styleUrls: ['./inventory-config.component.scss']
})
export class InventoryConfigComponent implements OnInit {

  public indentapprovalflag:boolean=false;
  public storesaveflag:boolean=false;
  public storeListArray:any=[];
  public storename:string;
  public hptl_clinic_id;
  public approvedstorenamearray=[];
  public userListArray=[];
  public username;
  public pharmaid: any;
  public userInfo: any;
  public req_estimation:boolean;
  public req_roundOff:boolean;
  public req_regFee:boolean;
  public app_book_TAT: number;
  public consult_TAT: number;
  public pharmaEst_TAT: number;
  public pharmaDisp_TAT: number;
  public pharmaBillGen_TAT: number;
  public inwardDrug_EXP: number;
  public isIndentReq_Checked: boolean;
  public isIndentApprv_Checked: boolean;
  public isPOApprv_Checked: boolean;
  public ReturnProd_Exp: any;

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hptl_clinic_id=Helper_Class.getInfo().hptl_clinic_id;
    this.pharmaid = this.userInfo.pharma_id;

    this.getusername();
  }

  indentrequest() {
    // const checkeditem = event.target.checked
    if (this.isIndentReq_Checked == true) {
      this.indentapprovalflag = true;
    }
  }

  indentapproval() {
    // const checkboxId = event.target.id;
    // const checkeditem = event.target.checked
    if (this.isIndentApprv_Checked == true) {

      this.storesaveflag = true;
      this.storeListfunction();
      this.getusername();
    } else {
      this.storesaveflag = false;
    }

  }
  storeListfunction() {
    var requestData = {
      hptl_clinic_id: this.hptl_clinic_id
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gsnh', requestData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj " + JSON.stringify(obj))
          var StoreName = obj.store_names;
          console.log("store name" + JSON.stringify(StoreName))
          for (var i = 0; i < StoreName.length; i++) {
            this.storeListArray.push({
              name: StoreName[i].store_desc,
              id: StoreName[i].store_names_id,
              status: StoreName[i].status
            })
          }
        },
        error => {
          // this.toastr.error(Message_data.defaultErr);
          console.log("error in retriveing data")
        }
      )
    // this.storeListArray=[{name:"store - 1"},{name:"store - 2"},{name:"store - 3"},{name:"store - 4"},{name:"store - 5"},{name:"store - 6"},]
  }
  getusername() {
    var requestData = {
      hptl_clinic_id: this.hptl_clinic_id
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/gubs', requestData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj " + JSON.stringify(obj))
          var UserName = obj.users;
          console.log("store name" + JSON.stringify(UserName))
          for (var i = 0; i < UserName.length; i++) {
            this.userListArray.push({
              firstname: UserName[i].first_name,
              id: UserName[i].user_id,
              lastname: UserName[i].last_name,
              name: UserName[i].first_name + " " + UserName[i].last_name
            })
          }
        },
        error => {
          // this.toastr.error(Message_data.defaultErr);

          console.log("error in retriveing data")
        }
      )
  }

  approvestorename(storenamelist: any, username) {
    for (var i = 0; i < this.storeListArray.length; i++) {
      if (storenamelist == this.storeListArray[i].name) {
        var storename = this.storeListArray[i].name
        var storeid = this.storeListArray[i].id
      }
      console.log("store name = " + storename + ";store id =" + storeid)
    }

    for (var j = 0; j < this.userListArray.length; j++) {
      console.log(" name = " + JSON.stringify(this.userListArray[j].name))
      console.log("user name =" + username)
      if (username == this.userListArray[j].id) {
        var approvalname = this.userListArray[j].name
        var approverId = this.userListArray[j].id
      }
    }

    this.approvedstorenamearray.push({
      store_name: storename,
      store_name_id: storeid,
      store_approver_name: approvalname,
      store_approver: approverId
    })

    console.log("approved storenamearray =" + JSON.stringify(this.approvedstorenamearray))
  }

  saveConfig(){
    var send_data;
    var appBookTat;
    var consulTat;
    var pharmaEstTat;
    var pharmaDispTat;
    var pharmaBillGenTat;

    var reqRoundoff;
    var indentApprv;
    var indentReq;
    var pOapprv;


    if (this.req_roundOff == true){
      reqRoundoff = '1';
    } else {
      reqRoundoff = "0";
    }

    if (this.isIndentApprv_Checked == true){
      indentApprv = "1";
    } else {
      indentApprv = "0";
    }

    if (this.isIndentReq_Checked == true){
      indentReq = "1";
    } else {
      indentReq = "0";
    }

    if (this.isPOApprv_Checked == true){
      pOapprv = "1";
    } else {
      pOapprv = "0";
    }

    if (this.app_book_TAT != undefined){
      const hours = Math.floor(this.app_book_TAT / 60);
      const minutes = this.app_book_TAT % 60;
      appBookTat = `${hours}:${minutes.toString().padStart(2, '0')}`
      console.log("appBookTat",appBookTat)
    }

    if (this.consult_TAT != undefined){
      const hours = Math.floor(this.consult_TAT / 60);
      const minutes = this.consult_TAT % 60;
      consulTat = `${hours}:${minutes.toString().padStart(2, '0')}`
      console.log("consulTat",consulTat)
    }

    if (this.pharmaEst_TAT != undefined){
      const hours = Math.floor(this.pharmaEst_TAT / 60);
      const minutes = this.pharmaEst_TAT % 60;
      pharmaEstTat = `${hours}:${minutes.toString().padStart(2, '0')}`
      console.log("pharmaEstTat",pharmaEstTat)
    }

    if (this.pharmaDisp_TAT != undefined){
      const hours = Math.floor(this.pharmaDisp_TAT / 60);
      const minutes = this.pharmaDisp_TAT % 60;
      pharmaDispTat = `${hours}:${minutes.toString().padStart(2, '0')}`
      console.log("pharmaDispTat",pharmaDispTat)
    }

    if (this.pharmaBillGen_TAT != undefined){
      const hours = Math.floor(this.pharmaBillGen_TAT / 60);
      const minutes = this.pharmaBillGen_TAT % 60;
      pharmaBillGenTat = `${hours}:${minutes.toString().padStart(2, '0')}`
      console.log("pharmaBillGenTat",pharmaBillGenTat)
    }

    send_data = {
      hptl_clinic_id: this.hptl_clinic_id,
      pharma_id: this.pharmaid,
      req_roundoff: reqRoundoff,
      app_booking_tat: appBookTat,
      app_consult_tat: consulTat,
      estimate_tat: pharmaEstTat,
      estimate_disp_tat: pharmaDispTat,
      bill_gen_tat: pharmaBillGenTat,
      inward_drug_exp_dur: this.inwardDrug_EXP,
      drug_exp_measure: "Months",
      indent_required: indentReq,
      indent_approval_req: indentApprv,  
      po_approval_req: pOapprv,
      po_approver: this.username,
      ret_prod_batch_exp: this.ReturnProd_Exp,
      store_approve:  this.approvedstorenamearray,

    }

    console.log("saveConfig----------send_data",send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/shcd', send_data, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj " + JSON.stringify(obj))
          
        },
        error => {
          // this.toastr.error(Message_data.defaultErr);
          console.log("error in retriveing data")
        }
      )
  }
}
