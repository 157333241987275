<!-- Microbiology drug group -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'CultureSensitivityDrugGroup'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Microbiology drug group
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Enter culture sensitivity drug type"
                                    [(ngModel)]="CultureSensitivename" style="margin-left:6%" matInput>

                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addCulture('','','save')" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let CultureSensitive of CultureSensitivearray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{CultureSensitive.CultureSensitiveList}}</td>
                                                <td class="butnstyle">
                                                    <a>
                                                        <img *ngIf="editbutton"
                                                            (click)="editCultureDetails(CultureSensitive.CultureSensitiveList,CultureSensitive.sensitive_drug_type_id)"
                                                            src="../../../assets/img/edit.png" width="20" height="20"
                                                            class="btun" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="addCulture(CultureSensitive.sensitive_drug_type_id,CultureSensitive.CultureSensitiveList,'Erase')">

                                                        <img src="../../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- sensitive Mapping-->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'CultureSensitivityDrug'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Sensitive Drug
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <mat-label class="matlabel">Type</mat-label>
                                <select class="form-select" [(ngModel)]="sensitive_drug_type_id"
                                    (ngModelChange)="updateSendatab()">
                                    <option *ngFor="let DiagnosticD of DiagnosticDarray; let i = index"
                                        [value]="DiagnosticD.sensitive_drug_type_id">
                                        {{DiagnosticD.DiagnosticDname}}
                                    </option>
                                </select>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addCultureMapName()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let drug of all_sensitive_drugs">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{ drug.allsensitiveRname }}</td>
                                                <td class="butnstyle">
                                                    <!-- <input type="checkbox"  (change)="onCheckboxChange(drug)">  -->
                                                    <mat-checkbox color="primary"
                                                        [checked]="isDrugSelected(drug)"></mat-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Add role  -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'ADmaster'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Roles
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <mat-label class="m-2 matlabel">Type
                                    <select class="form-select" [(ngModel)]="selectedUserType" name="userType" required
                                        (ngModelChange)="getuserdptdata(selectedUserType)">
                                        <option *ngFor="let usergetType of usergettype"
                                            [value]="usergetType.user_type_id">
                                            {{ usergetType.description }}
                                        </option>
                                    </select>
                                </mat-label>

                                <input class="input" placeholder="Enter the Role Name"
                                    [(ngModel)]="Accountheadinputname" matInput>

                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="addroles('','','','save')" *ngIf=" newbutton" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let Accounthead of Accountheadarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{ Accounthead.Accountheadname }}</td>
                                                <td>{{ Accounthead.description }}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editAccountheadDetails(Accounthead.Accountheadname, Accounthead.role_id )">

                                                        <img class="butn1" src="../../../assets/img/edit.png" width="20"
                                                            height="20" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="addroles(Accounthead.role_id, Accounthead.Accountheadname,Accounthead.typeuser_type_id,'Erase')">
                                                        <img class="btun" src="../../../assets/dist/images/delete.png"
                                                            width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- culture pathology -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'culturepathology'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    culture pathology
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="culture pathology code" [(ngModel)]="CodeNameinputname"
                                    style="margin-left:3%" matInput>
                                <input placeholder="culture pathology" [(ngModel)]="culturepathologyinputname"
                                    style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo"
                                    (click)="addpathology(culturepathologyinputname,CodeNameinputname)" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let StoreName of StoreNamearray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                                    width: 50%">{{StoreName.culture_pathogen_desc}}</td>
                                                <td style="text-align: left;padding-left:5px;
                                                    width: 10%">{{StoreName.culture_pathogen_code}}</td>

                                                <td style="text-align: right;">
                                                    <a *ngIf="editbutton"
                                                        (click)="editpathologyDetails(StoreName.culture_pathogen_code, StoreName.culture_pathogen_id, StoreName.culture_pathogen_desc)">
                                                        <img src="../../../assets/img/edit.png" width="20" height="20"
                                                            style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deletepathologyDetails(StoreName.culture_pathogen_id)">

                                                        <img src="../../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Specimen type -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'sampletype'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Specimen type
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="d-flex">
                                <input placeholder="Enter Specimen type" class="inputpw"
                                    [(ngModel)]="samplelistinputname" matInput>
                                <mat-label class="matlabel ml-5">type</mat-label>
                                <mat-select class="mat" [(ngModel)]="tube_type_id" multiple>
                                    <mat-option *ngFor="let Diagnostict of testtube_data"
                                        [value]="Diagnostict.tube_type_id">
                                        {{Diagnostict.tube_desc}}
                                    </mat-option>
                                </mat-select>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                class="saveimgbtn_inpatinfo" (click)="addsamplelist()" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                *ngFor="let samplelist of samplelistarray">
                                <table id="salesTable" class="table table-sm discount_table">
                                    <tbody style="border-radius: 10px;">
                                        <tr>
                                            <td style="text-align: left;padding-left:5px; width: 45%">
                                                {{samplelist.samplelistname}}</td>
                                            <td style="text-align: right;">
                                                <a *ngIf="editbutton"
                                                    (click)="editsamplelistDetails(samplelist.samplelistname, samplelist.specimen_type_id)">
                                                    <img src="../../assets/img/edit.png" width="20" height="20"
                                                        style="margin: 0 5px;" />
                                                </a>
                                                <a *ngIf="deletebutton"
                                                    (click)="deletesamplelistDetails(samplelist.specimen_type_id)">
                                                    <img src="../../assets/dist/images/delete.png" width="20"
                                                        height="20" style="margin: 0 5px;" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Diagnostic Department -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'DiagnosticD'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">Diagnostic Department</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Diagnostic Department" [(ngModel)]="DiagnosticDinputname"
                                    style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addDiagnosticD()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let DiagnosticD of DiagnosticDarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;width: 45%">{{DiagnosticD.DiagnosticDname}}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton" (click)="editDiagnosticDDetails(DiagnosticD.DiagnosticDname,DiagnosticD.diag_department_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20" class="btun" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deleteDiagnosticDDetails(DiagnosticD.diag_department_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20" height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Diagnostic tubes -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Diagnostict'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">Test Tube Type</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Enter Test Tube Type Name" [(ngModel)]="Diagnostictinputname"
                                    style="margin-left:6%" matInput>
                                <input type="color" [(ngModel)]="color_code" class="hi" />
                                <mat-label class="matlabel"> size
                                    <select class="matpw form-select" [(ngModel)]="tube_size">
                                        <option value="5mm">5mm</option>
                                        <option value="15mm">15mm</option>
                                        <option value="20mm">20mm</option>
                                    </select>
                                </mat-label>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="addDiagnostict()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol" *ngFor="let Diagnostict of Diagnostictarray">
                                    <table id="salesTable" class="table table-sm  discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;width: 45%">{{Diagnostict.Diagnostictname}}</td>
                                                <td><i [style.color]="Diagnostict.color_code" class="fa-solid fa-droplet"></i></td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton" (click)="editDiagnostictDetails(Diagnostict.Diagnostictname,Diagnostict.tube_type_id,Diagnostict.color_code,Diagnostict.tube_size)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20" class="btun1" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deleteDiagnostictDetails(Diagnostict.tube_type_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20" height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Diagnostic Reagent -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'DiagnosticR'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">Test Reagents</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input class="input" placeholder="Enter Test Reagents" [(ngModel)]="DiagnosticRinputname" matInput>
                                <mat-label class="matlabel">type
                                    <select class="form-select" [(ngModel)]="reagent_type_id">
                                        <option *ngFor="let DiagnosticR of DiagnosticRarraytype" [value]="DiagnosticR.reagent_type_id">
                                            {{ DiagnosticR.reagent_type_desc }}
                                        </option>
                                    </select>
                                </mat-label>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addDiagnosticR()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol" *ngFor="let DiagnosticR of DiagnosticRarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{ DiagnosticR.DiagnosticRname }}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton" (click)="editDiagnosticRDetails(DiagnosticR.DiagnosticRname, DiagnosticR.reagent_type_id)">
                                                        <img class="butn1" src="../../assets/img/edit.png" width="20" height="20" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deleteDiagnosticRDetails(DiagnosticR)">
                                                        <img class="btun" src="../../assets/dist/images/delete.png" width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- stock adjustment account -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'stockar'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">Stock Adjustment Reasons</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Enter stock adjustment Reasons" [(ngModel)]="SAAinputname" style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addstoctadujustmentrecent()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol" *ngFor="let samplelist of SAAarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;width: 45%">{{samplelist.SAAname}}</td>
                                                <td style="text-align: right;">
                                                    <a *ngIf="editbutton" (click)="editstoctDetails(samplelist.SAAname, samplelist.stock_adj_reason_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20" style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deletestoctDetails(samplelist.stock_adj_reason_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Store Name -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'StoreName'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">Store Name</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Store Name" [(ngModel)]="StoreNameinputname" style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo" (click)="addStoreName()" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let StoreName of StoreNamearray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px; width: 55%">{{StoreName.StoreNamename}}</td>
                                                <td style="text-align: right;">
                                                    <a *ngIf="editbutton" (click)="editStoreNameDetails(StoreName.StoreNamename, StoreName.store_names_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20" style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deleteStoreNameDetails(StoreName.store_names_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20"height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Generic -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'GM'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                <div class="header_lable">Generic</div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Generic name" [(ngModel)]="genericinputname" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg" 
                                    style="width: 90px;height: 30px;border: 1px solid gainsboro;border-radius: 5px;margin-left:10px"
                                    class="saveimgbtn_inpatinfo" (click)="addgeneric()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol" *ngFor="let generic of genericarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr style="margin-bottom: 5px;">
                                                <td style="  text-align: left;padding-left:5px;
                                                padding-bottom: 15px;width: 45%;">{{generic.genericname}}</td>
                                                <td style="  text-align: right;padding-bottom: 15px">
                                                    <a *ngIf="editbutton" (click)="editgenericDetails(generic.genericname,generic.generic_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20" style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton" (click)="deletegenericDetails(generic.generic_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20" height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Schedule -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'SM'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                <div class="header_lable">Schedule
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Schedule name" [(ngModel)]="scheduleinputname"
                                    style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg" style="width: 90px;
                        height: 30px;
                        border: 1px solid gainsboro;border-radius: 5px;margin-left:10px" class="saveimgbtn_inpatinfo"
                                    (click)="addschedule()" />
                            </div>


                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">

                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let schedule of schedulearray">


                                    <table id="salesTable" class="table table-sm  discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;
                                                padding-left:5px;
                                                padding-bottom: 15px;
                                                 width: 45%;">{{schedule.schedulename}}</td>
                                                <td style="  text-align: right;
                                                padding-bottom: 15px">
                                                    <a *ngIf="editbutton"
                                                        (click)="editscheduleDetails(schedule.schedulename,schedule.schedule_type_id)">


                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deletescheduleDetails(schedule.schedule_type_id)">


                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>

                            </div>






                        </div>





                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Product Form -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'IFM'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                <div class="header_lable">
                    Product Form
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Product name" [(ngModel)]="itemforminputname" style="margin-left:6%"
                                    matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg" style="width: 90px; 
                    height: 30px;
                    border: 1px solid gainsboro;border-radius: 5px;margin-left:10px" class="saveimgbtn_inpatinfo"
                                    (click)="additemform()" />
                            </div>


                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">

                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let itemform of itemformarray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;
                                                padding-left:5px;
                                                padding-bottom: 15px;
                                                 width: 45%;"> {{itemform.itemformname}}</td>
                                                <td style="  text-align: right;
                                                padding-bottom: 15px">
                                                    <a *ngIf="editbutton"
                                                        (click)="edititemformDetails(itemform.itemformname,itemform.item_form_id)">
                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteitemformDetails(itemform.item_form_id)">
                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>

                            </div>






                        </div>





                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Add Sub Department -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Sub-Deparment'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Add Sub Department
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <mat-label class="matlabel">Type

                                    <select class="form-select selectdropdown" [(ngModel)]="diag_department_id"
                                        (ngModelChange)="updateSendatabSubDeparment()">
                                        <option *ngFor="let dept_data of department_data"
                                            [value]="dept_data.diag_department_id">
                                            {{ dept_data.diag_dept_desc }}
                                        </option>
                                    </select>
                                </mat-label>
                                <input class="inputsubdep" placeholder="Sub department" [(ngModel)]="descsubdepartinput"
                                    matInput>
                                <input class="inputsubdep" placeholder="Oder by" [(ngModel)]="Odersubdepartinput"
                                    matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addSubDeparment()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let test_category_data of subdepartarray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{test_category_data.subdepartname}}</td>
                                                <td>{{test_category_data.Odersubdepart}}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editSubDeparmentDetails(test_category_data.Odersubdepart,test_category_data.subdepartname, test_category_data.diag_test_cat_id)">

                                                        <img class="butn1" src="../../../assets/img/edit.png" width="20"
                                                            height="20" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteSubDeparmentDetails(test_category_data.Odersubdepart,test_category_data.diag_test_cat_id,test_category_data.subdepartname)">
                                                        <img class="btun" src="../../../assets/dist/images/delete.png"
                                                            width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Add Surgery_Equipments-->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Surgery_Equipments'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Add Surgery products
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <mat-label class="matlabel">Type
                                    <select class="form-select selectdropdown" [(ngModel)]="surgery_id"
                                        (ngModelChange)="updateSendatabProducts()">
                                        <option *ngFor="let surg_data of surgery_data" [value]="surg_data.procedure_id">
                                            {{ surg_data.description }}
                                        </option>
                                    </select>
                                </mat-label>
                                <mat-label class="matlabel"><br>
                                    <input type="text" style="margin-top: 10px;" maxlength="50"
                                        (keyup)="getSurgeryProductsByName()" maxlength="50"
                                        [(ngModel)]="descProductsInput" matInput [matAutocomplete]="auto1"
                                        placeholder="Products" /> <!--class="inputsubdep"-->
                                    <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                                        <mat-option *ngFor="let item of productList" (click)="selectProduct(item)"
                                            value="{{item.product_id}}">
                                            {{item.product_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>

                                <input placeholder="Quantity" [(ngModel)]="productsQuantityInput" matInput>
                                <!--class="inputsubdep"-->
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo btn-outline-success rounded" style="cursor: pointer;"
                                    (click)="addProducts()" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let product_data of productsarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{product_data.productname}}</td>
                                                <td>{{product_data.productquantity}}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editProductsDetails(product_data.productquantity,product_data.product_id, product_data.surgery_equipment_id,product_data.product_type_id,product_data.productname)">
                                                        <img class="butn1"
                                                            src="../../../assets/ui_icons/pencil_icon_New.svg"
                                                            width="20" height="20" style="cursor: pointer;" />
                                                    </a>
                                                    &nbsp;
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteProductsDetails(product_data.productquantity,product_data.surgery_equipment_id,product_data.product_id,product_data.product_type_id,product_data.productname)">
                                                        <img class="btun"
                                                            src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                            width="20" height="20" style="cursor: pointer;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- add template -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Addtemplate'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Add Template
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <mat-label>Type</mat-label>
                                <select class="form-select ml1" [(ngModel)]="DepartmentID"
                                    (ngModelChange)="updateSendatemplate()">
                                    <option *ngFor="let dept_data of department_data"
                                        [value]="dept_data.diag_department_id">
                                        {{ dept_data.diag_dept_desc }}
                                        {{diag_department_id}}
                                    </option>
                                </select>

                                <input class="inputsubdep" placeholder="Enter the template Name"
                                    [(ngModel)]="Odersubdepartinput" matInput>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    *ngIf="newbutton" (click)=" addtemplate()" />
                            </div>
                        </div>
                        <div class="quill-container">
                            <mat-label class="mat-label"><span style="color: red; font-size: 20px;">*</span></mat-label>
                            <quill-editor [(ngModel)]="desctemplateinput" [style]="editorStyle" [modules]="modules"
                                placeholder="Enter Text"></quill-editor>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdataboxtemplate">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let addtemplate_data of Addtemparray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{addtemplate_data.template_name}}</td>

                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="edittemplateDetails(addtemplate_data.template_desc,addtemplate_data.template_name, addtemplate_data.template_id)">

                                                        <img class="butn1" src="../../../assets/img/edit.png" width="20"
                                                            height="20" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deletetemplateDetails(addtemplate_data.template_desc,addtemplate_data.template_id,addtemplate_data.template_name)">
                                                        <img class="btun" src="../../../assets/dist/images/delete.png"
                                                            width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Gramstains -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Gramstains'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Gram stains
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Gram stains" [(ngModel)]="GramStainsinputname"
                                    style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addGramStains()" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let Gram_Stain_A of Gram_Stains_array">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{Gram_Stain_A.gram_stain_desc}}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editGramStainsDetails(Gram_Stain_A.gram_stain_desc,Gram_Stain_A. gram_stain_id)">
                                                        <img src="../../../assets/img/edit.png" width="20" height="20"
                                                            class="btun" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteGramStainsDetails(Gram_Stain_A. gram_stain_id)">
                                                        <img src="../../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- RateCard -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'RateCard'">
    <div class="cover_div">
        <div class="header_lable">
            Rate card
        </div>
        <div class="content_cover">
            <div class="row">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                <mat-label class="matlabel ml-3">Center type </mat-label>
                                <select disableOptionCentering class="matrcdrop" [(ngModel)]="center_typePR"
                                    style="width:100%" (change)="checkcenter(center_typePR)">
                                    <!-- <option disabled>Select</option> -->
                                    <option value="hospital" selected>Hospital</option>
                                    <option value="diagnosis">Diagnosis</option>
                                    <option value="pharma">Pharmacy</option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                <mat-label class="matlabel ml-3">&nbsp;</mat-label>
                                <input placeholder="Ratecard name" [(ngModel)]="rate_card_desc"
                                    style="margin-left:6%; width:100%" matInput>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                <mat-label class="matlabel ml-3">&nbsp;</mat-label>
                                <input placeholder="Discount" [(ngModel)]="discount" style="margin-left:3%; width:100%"
                                    matInput>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2" [hidden]="discounttypeflag">
                                <mat-label class="matlabel ml-3"> Discount Type</mat-label>
                                <select disableOptionCentering class="matrcdrop" [(ngModel)]="discount_typePR" style="width:100%">
                                    <option disabled>Select</option>
                                    <option value="cost plus gst">Cost + GST</option>
                                    <option value="sales plus gst">Sales + GST</option>
                                    <option value="MRP">MRP</option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2" style="margin-top: 20px !important;">
                                <mat-label class="matlabel wt">
                                    <mat-checkbox color=primary labelPosition="after" [(ngModel)]="default" 
                                    (change)="isDefaultSelected()"><span style="font-weight: 500 !important;">Default</span></mat-checkbox>
                                </mat-label>

                                <!-- <mat-label class="matlabel" style="margin-top:20px !important">Default select
                                    <mat-checkbox color="primary" [(ngModel)]="default"
                                        (change)="isDefaultSelected()"></mat-checkbox>
                                </mat-label> -->
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2" style="margin-top: 10px !important;margin-left: -50px !important;">
                                <mat-label class="matlabel ml-3">&nbsp;</mat-label>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="saveRateCard('','','','','save')" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                            *ngFor="let RateCard of RateCardarray">
                            <table id="salesTable" class="table table-sm discount_table">
                                <tbody style="border-radius: 3px;">
                                    <tr>
                                        <td style="text-align: left;padding-left: 5px;width: 40%;">
                                            {{RateCard.rate_card_desc}}</td>
                                        <td style="text-align: left;padding-left: 5px;width: 5%;">{{RateCard.discount}}
                                        </td>
                                        <td style=" text-align: right;padding-left: 20px;width: 25%;">
                                            {{RateCard.default}}
                                        </td>
                                        <td style="text-align: right;">
                                            <a *ngIf="editbutton"
                                                (click)="editRateCard(RateCard)">
                                                <img src="../../../assets/img/edit.png" width="20" height="20"
                                                    class="btun" />
                                            </a>
                                            <a *ngIf="deletebutton"
                                                (click)="saveRateCard(RateCard.rate_card_id,RateCard.discount,RateCard.rate_card_desc,RateCard.default,'Erase')">
                                                <img src="../../../assets/dist/images/delete.png" width="20" height="20"
                                                    class="btun" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Visit purpose -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Visitpurpose'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Visit purpose
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Visit purpose" [(ngModel)]="VisitPurposeinputname"
                                    style="margin-left:6%" matInput>
                                <mat-label class="matlabel">Provider
                                    <select class="form-select" [(ngModel)]="visit_purpose_name"
                                        (ngModelChange)="updateSendatabVP()">
                                        <option value="hospital">Hospital</option>
                                        <option value="dietician">Dietician</option>
                                        <option value="physio">Physio</option>
                                    </select>
                                </mat-label>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    *ngIf="newbutton" (click)="addVisitPurpose()" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let Visit_Purpose_A of Visit_Purpose_array">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{ Visit_Purpose_A.visit_purpose_desc }}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editVisitPurposeDetails(Visit_Purpose_A.visit_purpose_desc,Visit_Purpose_A. visit_purpose_id)">
                                                        <img src="../../../assets/img/edit.png" width="20" height="20"
                                                            class="btun" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteVisitPurposeDetails(Visit_Purpose_A. visit_purpose_id)">
                                                        <img src="../../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Add Sensitive Drug -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'CultureSensitivityDrugName'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Add Sensitive Drug
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input class="input" placeholder="Sensitive Drug" [(ngModel)]="DiagnosticRinputname"
                                    matInput>
                                <mat-label class="matlabel">type
                                    <select [(ngModel)]="sensitive_drug_type_id"
                                        (ngModelChange)="updateCultureSendatab()">
                                        <option *ngFor="let DiagnosticD of DiagnosticDarray"
                                            [value]="DiagnosticD.sensitive_drug_type_id">
                                            {{ DiagnosticD.DiagnosticDname }}
                                        </option>
                                    </select>
                                </mat-label>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    *ngIf="newbutton" (click)="addCulturedata()" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">


                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let DiagnosticR of DiagnosticRarray">


                                    <table id="salesTable" class="table table-sm discount_table">

                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td class="edit">{{ DiagnosticR.DiagnosticRname }}</td>
                                                <td class="butnstyle">
                                                    <a *ngIf="editbutton"
                                                        (click)="editCultureRDetails(DiagnosticR.DiagnosticRname, DiagnosticR.sensitive_drug_id)">

                                                        <img class="butn1" src="../../../assets/img/edit.png" width="20"
                                                            height="20" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="deleteCultureRDetails(DiagnosticR)">
                                                        <img class="btun" src="../../../assets/dist/images/delete.png"
                                                            width="20" height="20" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- User Roles -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'UserRole'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    User role Mapping
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" style="width: 168px">
                            <mat-label class="matlabel"> User Type <br></mat-label>
                            <select class="form-select m-1" [(ngModel)]="selectedUserTypeId" (change)="getUserRole()"
                                class="lir">
                                <option *ngFor="let usertypelist of tempModules" [value]="usertypelist.type_id">
                                    {{ usertypelist.type_desc }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" style="width: 168px">
                            <mat-label class="matlabel"> Users </mat-label><br>
                            <select required class="form-select m-1" [(ngModel)]="userid" class="lir">
                                <option *ngFor="let user of userList" value={{user.user_id}}>{{user.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" style="width: 168px">
                            <mat-label class="matlabel"> User role<br></mat-label>
                            <select class="form-select m-1" [(ngModel)]="selectedRoleId" class="lir">
                                <option *ngFor="let getUserrole of getUserrolearrays" [value]="getUserrole.role_id">
                                    {{ getUserrole.Userroledname }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                            <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                class="saveimgbtn_inpatinfo" style="margin-top: 22px" (click)="saveUserRole()" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterbo"
                            *ngFor="let getUserroles of getUsserrolearrayall">
                            <table id="salesTable" class="table table-sm discount_table sizeing">
                                <tbody style="border-radius: 3px;">
                                    <tr style="padding: 0px;">
                                        <td style="text-align: left;width: 30%;">
                                            {{getUserroles.doc_name }}</td>
                                        <td style="text-align: left;width: 25%;">
                                            {{getUserroles.role_desc}}</td>
                                        <td style="text-align: left;width: 25%;">
                                            {{getUserroles.user_type_desc}}</td>
                                        <td class="butnstyle">
                                            <a *ngIf="deletebutton"
                                                (click)="deleteUserRole(getUserroles.role_id, getUserroles.user_id, getUserroles.user_role_id,getUserroles.user_type_id)">
                                                <img src="../../../assets/dist/images/delete.png" width="20" height="20"
                                                    class="btun" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- bill credit types -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'billcredittypes'">
    <div class="row">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="cover_div">
                <div class="header_lable">
                    Bill Credit Types
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">

                                <input placeholder="Enter Bill Credit Types" [(ngModel)]="billcredittypesname"
                                    style="margin-left:6%" matInput>

                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addbillcredittypes('','','save')" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let billcredittypes of billcredittypesarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                            width: 45%">{{billcredittypes.credit_type}}</td>
                                                <td class="butnstyle">
                                                    <a>

                                                        <img *ngIf="editbutton"
                                                            (click)="editbillcredittypesDetails(billcredittypes.credit_type,billcredittypes.bill_credits_id)"
                                                            src="../../../assets/img/edit.png" width="20" height="20"
                                                            class="btun" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="addbillcredittypes(billcredittypes.bill_credits_id,billcredittypes.credit_type,'Erase')">

                                                        <img src="../../../assets/dist/images/delete.png" width="20"
                                                            height="20" class="btun" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Accessioner -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'specimenaccessioner'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    {{masterpageheading}}
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="row">
                            <div class="col-12">
                                <input placeholder="Specimen assertion" [(ngModel)]="AccessionerNameinputname"
                                    style="margin-left:6%" matInput>
                                <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="addAccessioner('','','save')" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                    *ngFor="let Accessioner of specimenaccessionerarray">
                                    <table id="salesTable" class="table table-sm discount_table">
                                        <tbody style="border-radius: 10px;">
                                            <tr>
                                                <td style="text-align: left;padding-left:5px;
                                                    width: 55%">{{Accessioner.assertion_desc}}</td>
                                                <td style="text-align: right;">
                                                    <a *ngIf="editbutton"
                                                        (click)="editAccessionerDetails(Accessioner.diag_assertion_id, Accessioner.assertion_desc)">

                                                        <img src="../../assets/img/edit.png" width="20" height="20"
                                                            style="margin: 0 5px;" />
                                                    </a>
                                                    <a *ngIf="deletebutton"
                                                        (click)="addAccessioner(Accessioner.diag_assertion_id,Accessioner.assertion_desc,'delete')">

                                                        <img src="../../assets/dist/images/delete.png" width="20"
                                                            height="20" style="margin: 0 5px;" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>