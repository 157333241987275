import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Date_Formate, ConvertTimeformat, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreviousPrescriptionPopupComponent } from '../previous-prescription-popup/previous-prescription-popup.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MedPresPopupPageComponent } from '../med-pres-popup-page/med-pres-popup-page.component';
import { PresDialogComponent } from '../pres-dialog/pres-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress'; import { BmiandbmrService } from '../service/bmiandbmr.service';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';
declare var $: any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import moment from 'moment';

@Component({
  selector: 'app-med-diag-pres-nocase',
  templateUrl: './med-diag-pres-nocase.component.html',
  styleUrls: ['./med-diag-pres-nocase.component.scss']
})
export class MedDiagPresNocaseComponent implements OnInit {
  @ViewChild('printnoheaderwithname') printnoheaderwithname: ElementRef;
  public medicalprescription: boolean = false;
  public dur_time_flag: boolean = false;
  public inp_diag_bill_gen;
  public barcode_url;
  public clnt_heartrate;
  public heartrateflag;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public patient_name; public printLanguageflag: boolean = true;
  public LabtestNamenew;
  public intakesession: boolean = false;
  public medstyle: boolean = false;
  public medstyle1: boolean = false;
  public medstyle2: boolean = false;
  public medstyle3: boolean = false;
  public sno = 0;
  public doc_bill_generate;
  public no_of_days_diag; public nextvisit_print;
  public testnowflag: boolean = true;
  public nextvisitflag_print: boolean;
  public pricetable: any = [];
  public param_four: boolean;
  public genericname;
  public updatedArrays;
  public totalbillamtvalue;
  public printtime;
  public print_nextvisitflag: boolean = false;
  public packages: any = [];
  public kidney_flag: boolean = false;
  public cvsflag: boolean = false;
  public rsflag: boolean = false
  public pulseflag: boolean = false;
  public next_datetxt_diagflag: boolean = false;
  public next_datetxt_diag;
  public diseaseflag = false;
  public complaintsflag = false;; public instructionsflag: boolean = false;
  public selectedpackages: any = []
  public selectedpackageid: any = [];
  public speclization_arry;
  public patient_age;
  public patient_gender;
  public addressdetails;
  public doctor_name;
  public inpatientnuber;
  public mrnflag: boolean = false;
  public persondataflag: boolean = false;
  public ageflag: boolean = false;
  public genderflag: boolean = false;
  public not_Inpatient: boolean = false;
  public totalSelectedPrice;
  public barcode_flag: boolean = false;
  public mrnno;
  public profileImg;
  public patient_image;
  public visitpurpose;
  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  public complaints;
  public mrNumber;
  public print_complaints;
  public print_disease;
  public allow_diag_selectflag: boolean;
  public genericnameflag: boolean = false;
  public DATE;
  public nextvistflag;
  public next_visit_flag;
  public next_datetxt1;
  public selected_profileIds: any = [];
  public labTest_arraynew: any = [];
  public InpatMedPres: boolean = false;
  public isRequired: boolean;
  public nextvisitflag: boolean;
  public getVisitingSessionArray;
  public appDate;
  public visitSession;
  public timeSession;
  public startTime;
  public endTime;
  public sessionFlag;
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public nextvist_test: boolean;
  public testflagnew: boolean = true;
  public no_of_days;
  public dateAfter3Days;
  public radioTest_arraynew: any = [];
  public microTest_arraynew: any = [];
  public profilesnew: any = [];
  public testGroupArraynew: any = [];
  public filteredLabArraynew: any = [];
  public filteredRadioArraynew: any = [];
  public filteredMicroArraynew: any = [];
  public filteredProfilesArraynew: any = []
  public filteredProfilesArray = [];
  public selected_profilesnew: any = [];
  public radioarraynew: any = [];
  public labarraynew: any = [];
  public test_existsnew: boolean = false;
  public radiotestNamenew;
  public microtestNamenew;
  public profilesNamenew;
  public print_view_flag: boolean = true;
  public disease_flag: boolean = false;
  public printFlag: boolean = false;
  public ynopt1: boolean = true;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  @ViewChild('printbannerfullpage') printbannerfullpage: ElementRef;
  //printbannerfullpage
  public list_button_flag: boolean = false;
  public saveflag: boolean = false;
  public medPresFlag = "min";
  public hosp_img;
  public hptl_pres_logo: string;
  public doctoraddress;
  public treatflag: boolean;
  public refer_txt;
  public ref_flag: boolean;
  public treatment;
  public new_med: boolean;
  public newmed_flag: boolean;
  public flagvalue: boolean;
  public rowid: string;
  public order_now;
  public remark_txt: string;
  public clnt_bp: string;
  public clnt_pulse: string;
  public addmed: boolean = false;
  public CurrentDatetime;
  public Appoint_Date: string;
  public daydur_txt;
  public dct_hospital: string;
  public doct_name: string;
  public doct_location: string;
  public doct_address: string;
  public doct_city: string;
  public doct_state: string;
  public doct_clientname: string;
  public mobile: string;
  public Medicare_name: string;
  public Medical_prescription_array = [];
  public eng_flag: boolean;
  public tamil_flag: boolean;
  public telugu_flag: boolean;
  public kannada_flag: boolean;
  public malay_flag: boolean;
  public ortho_flag: boolean;
  public hospitalinfoarray;
  public clnt_cvs: string;
  public clnt_rs: string;
  public clnt_kidney: string;
  public instructions: any;
  public print_instruction: any;
  public bp_flag: boolean;
  public symptoms_flag: boolean;
  public med_typetxt1 = [];
  public medtypetxt_type;
  public med_typetxt: string;
  public drug_typetxt: string;
  public medicine_nametxt: string;
  public drug_med_id: string;
  public Get_Intake_array = [];
  public med_detail_list = [];
  public drug_id_list = [];
  public intake_txt: string;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public every_six: string;
  public dure_txt: string;
  public dure_write: string;
  public listProducts: any = [];
  public day_txt: string;
  public afterfood_txt: string;
  public afterfood: string;
  public medicineaddbtn: boolean;
  public Medicine_table: boolean = true;
  public prfer_pharmatxt: string;
  public prefer_add: string;
  public Client_id;
  public rel_id;
  public sub_id;
  public specilization_txt;
  public app_id;
  public print_template: string;
  public doc_qualif: string;
  public label1 = "Mor";
  public instr_flag: boolean;

  public dis_txt;
  public oberse_med;
  public home_care: string;
  public presmed_normal: string;
  public diab_medpres: string;
  public check_pres;
  public pharma_id;
  public Pharma_list_arry;
  public Pharma_data = [];
  public Pharmanamelist;
  public dure_show: boolean = false;
  public mor_lable: boolean = true;
  public after_eventxt: boolean = true;
  public mor_id: boolean;
  public after_id: boolean;
  public quan_lable: boolean = true;
  public Med_addbtn: boolean = false;
  public hosp_address: boolean;
  public intake_id;
  public Icd_code: string;
  public Icd_Descript: string;
  matches: String[];
  isRecording = false;
  public header_footer_flag: boolean;
  public pop_flag = null;
  public popup_footer: boolean;
  public hospital_clinic_id;
  public client_gender;
  public client_age;
  public doctorname;
  prev_pres;
  autoRx;
  refill_pres;
  public morning_req: boolean;
  public morning_dis: boolean;
  public afternoon_req: boolean;
  public even_dis: boolean;
  public afternoon_dis: boolean;
  public evening_req: boolean;
  public night_req: boolean;
  public night_dis: boolean;
  public backFlag: boolean = false;
  public bg_image: string;
  public ganericName: string;

  public areas = [
    { size: 30 },
    { size: 70 },
  ];

  presentdate = new Date()
  public mic_off: any = {
    icon: 'mic',
    color: 'balance'
  };
  public mic_on: any = {
    icon: 'mic',
    color: 'danger'
  };
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };
  public mic: any = this.mic_off;
  public recognition: any;
  public recognizing: Boolean;
  public get_txt: string;
  public dure_flag: boolean;
  public medtypetxt;
  public short_form;
  public show;
  public show_intake;
  public eve_eventxt: boolean = false;
  public night_eventxt: boolean = false;
  public fill_type: string;
  public morn_eventxt: boolean;
  public intake_show: boolean;
  public eve_id: boolean;
  public ngt_dis: boolean;
  public licence_code: string;
  public specializations_name = [];
  public qualification = [];
  public dure_require: boolean;
  public pdfObj;
  public imagestring;
  public sendpdf;
  public printpres: string;
  public hosp_footer: string;
  public printf_flag: boolean;
  //list view
  public med_pres_list = [];
  public send_client_reg_id: any = {};
  public med_pres_list_data;
  public med_pres_listdata: boolean;
  public docname_flag: boolean;
  flow: string;
  public curr_date;
  public doc_reg_id;
  public list_data = [];
  public pharcy_details = [];
  public pharma_control: boolean = false;
  public doctor_qualify_data;
  public doctor_Qualify_txt;
  public hptl_name;
  public next_txt1;
  public address;
  public doc_loc;
  public doc_city;
  public doc_state;
  public doc_country;
  public doc_telephone;
  public url_prescription: string;
  public prescription_pres_id;
  public data_prescription: any;
  public tablet_tracker = [];
  public date_pop = [];
  public total_name: string;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription: string;
  public disease_name: string;
  public disease_describtion: string;
  public sub_rel_id: string;
  public rel_name: string;
  public toastMessage: string;
  public has_pharma: boolean = false;
  public pharmacy_name: string;
  public phar_addr1: string;
  public phar_addr2: string;
  public phar_loc: string;
  public phar_city: string;
  public phar_zipcode: string;
  public phar_state: string;
  public phar_cnty: string;
  public telephone: string;
  public store_pick: boolean = false;
  public delopt: boolean = false;
  public home_del: boolean = false;
  public val_days;
  public work_time;
  public notification_id;
  public send_data;
  public has_Search: boolean = false;
  public has_multi_tab: boolean = false;
  public appointment: boolean = false;
  public symptoms: string;
  public language: string;

  public balance_quantity: number = 0;
  public docnameflag: boolean;
  public hospflag: boolean;
  public sen_data;
  public med_view_list: boolean = false;
  public splname;
  subscription: Subscription;
  public flagviewrx: boolean;
  public current_year: string;
  public current_date: string;
  public current_time: string;
  public personalinfo;
  public userinfo;
  public locationinfo;
  public doct_country;
  public zipcode;
  public navigtionflag: string;
  tomorrow = new Date();
  public inpatBackFlag: boolean = false;
  public hospital_logo;
  public doct_address1;
  public doct_address2;
  public add_MP: boolean = false;

  public sugeryimg: string;
  public getdata_value;

  public symptom_data = [];
  public symptomlist = [];
  public symptoms_list = [];
  public selected_symptoms = [];
  public newsymptoms = [];
  public symptoms_text = "";

  public print_same_page: boolean;

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
  public mediceList = [];
  public presID;
  public presListView: boolean;
  public diagpresflag: boolean = false;
  public filteredpackageArray = []

  Reset() {
    $("#obs_txt1").val("");
    this.get_txt = "";
  }

  public pritn_blood_flag: boolean = false;
  // content;
  // htmlString: string = '';

  bindings = {
    // This will overwrite the default binding also named 'tab'
    tab: {
      key: 9,
      handler: function () {
        // Handle tab
      }
    },

    custom: {
      key: 'B',
      shiftKey: true,
      handler: function (range, context) {
        // Handle shift+b
      }
    },

    list: {
      key: 'backspace',
      format: ['list'],
      handler: function (range, context) {
        if (context.offset === 0) {
          // When backspace on the first character of a list,
          // remove the list instead
          // this.quill.format('list', false, Quill.sources.USER);
        } else {
          // Otherwise propogate to Quill's default
          return true;
        }
      }
    }
  };


  // diagnosis and medical flow
  public loc_name;
  public subtestname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestxname: any = [];
  public subtestbname: any = [];
  public subtesturname: any = [];
  public subtestfname: any = [];
  public subtestcname: any = [];

  public bloodtest: boolean = true;
  public ultratest: boolean = true;
  public scantest: boolean = true;
  public xraytest: boolean = true;
  public biopsytest: boolean = true;
  public urinetest: boolean = true;
  public faecestest: boolean = true;
  public culturetest: boolean = true;

  public Blood_test = [];
  public filt_city;
  public loc_id;
  public sercah_center: string;

  public filt_state;
  public filt_country;
  public obs_diag: string;
  public city_url = ipaddress.getIp + "gen/city";
  public state_url = ipaddress.getIp + "gen/state";
  public country_url = ipaddress.getIp + "gen/count";
  public urine_test_array = [];
  public feace_test_array = [];
  public scan_test_array = [];
  public xray_test_array = [];
  public ultra_test_array = [];
  public culture_test_array = [];
  public biopsy_test_array = []
  public dig: any;
  public dig_view_flag: boolean;
  public diag_detail_List;
  public location_search_items;
  public location_txt;
  public diag_list_address;
  public diag_address1;
  public diag_address2;
  public diag_cen_name;
  public obs_add_diag: any = [];
  public test_Show: boolean = false;
  public diag_cen_id;
  public diag_centre_id;
  public addr: any;

  public clocation: any[];
  public Urine_test = [];
  public Feace_test = [];
  public Xray_test = [];
  public Scan_test = [];
  public Ultra_test = [];
  public testtypename: any = [];
  public filt_state_desc;
  public filt_city_desc;
  public country_id;
  public diag_location;
  public diag_city;
  public diag_state;
  public diag_zipcode;
  public diag_cntry;
  public diag_telpho;
  public test_flag: boolean = false;
  public checked: boolean = false;
  public frommedical: boolean;
  public test_now: boolean = true;
  public book_app: any;
  public observation: string;
  public blood_test = [];
  public flagtxt;

  public pritn_scan_flag: boolean = false;
  public pritn_us_flag: boolean = false;
  public pritn_xray_flag: boolean = false;
  public pritn_bio_flag: boolean = false;
  public pritn_ft_flag: boolean = false;
  public pritn_ut_flag: boolean = false;
  public pritn_culture_flag: boolean = false;

  public print_diag: boolean;
  public print_medidiag: boolean = false;
  public print_med: boolean;
  public inpatiant_save: boolean = false;

  public both_save_flag: boolean = false;
  public med_save_flag: boolean = false;
  public diag_save_flag: boolean = false;

  public get_locname_url: string;

  public clnt_location_list = [];

  public clnt_country_list = [];
  public clnt_state_list = [];
  public clnt_city_list = [];

  public clnt_country_id;
  public clnt_state_id;
  public clnt_city_id;
  public clnt_location_id;

  public search_location;
  public search_city;
  public search_state;
  public search_city_desc;
  public search_state_desc;
  public search_country;

  public height;
  public weight;
  public temparature;
  public height_flag: boolean;
  public weight_flag: boolean;
  public temparature_flag: boolean;

  //Get medicine name
  public Medicinenamelist;
  public Medicne_data = [];
  public Medicine_list = [];

  //Medical prescription save
  public next_txt;
  public next_datetxt;
  public daydur_show: boolean;
  public investigation;
  public currentDate;
  public medicineFlag: boolean = false;
  public testname;
  // inpatient view
  public medInpatientViewPrec: boolean;
  public viewpreslist_array = [];
  public user_id;
  public culture_test;
  dtOptions: DataTables.Settings = {};
  private med_id;
  public short_name;
  public genericDisplayFlag: boolean = false;
  public book_app_now: boolean = false;
  public dur_flag: boolean = false;
  public notes_flag: boolean = false;
  public medpres: boolean = true;
  public diagpres: boolean = false;
  public headerstyle;
  public footerstyle;
  public preslogo_style;
  public med_style;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public note_style;
  public salutation;
  public presType;
  private req_estimate: string;
  private pageflag: string;
  public mixtype: any = [];
  public intakeOption: any = [];
  public mixing_txt: string;
  public mix_show: boolean = true;
  public no_med: boolean = false;

  public labTest_array: any = [];
  public radioTest_array: any = [];
  public microTest_array: any = [];
  public profiles: any = [];
  public testGroupArray: any = [];
  public filteredLabArray: any = [];
  public filteredRadioArray: any = [];
  public filteredMicroArray: any = [];
  public selected_profiles: any = [];
  public radioarray: any = [];
  public labarray: any = [];
  public test_exists: boolean = false;
  public LabtestName;
  public radiotestName;
  public microtestName;
  public profilesName;
  testFlag: boolean = true;
  public userheaderdata;

  public packagesnew: any = [];
  public filteredpackageArraynew: any = [];

  public med_print_flag: boolean = false;
  public diag_print_flag: boolean = false;

  public selectedpackagesnew: any = [];
  public selectedpackageidnew: any = [];
  public selected_profileIdsnew: any = [];

  constructor(private translate: TranslateService, public sanitizer: DomSanitizer, public bmiservice: BmiandbmrService,
    public dialog: MatDialog, public http: Http, public cd: ChangeDetectorRef,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: CasesheetService, public naviagationService: MenuViewService, public masterCSdata: MasterCSHelperService, public nurseservice: MenuViewService,) {
    this.mor = "1";
    this.aft = "1";
    this.ngt = "1";
    this.eve = "0";
    this.instr_flag = true;

    this.dure_flag = false;
    this.eng_flag = false;
    this.ortho_flag = false;
    this.bp_flag = false;
    this.treatflag = false;
    this.ref_flag = false;
    this.printf_flag = true;
    this.newmed_flag = true;
    this.dure_show = false;
    this.get_txt = "";
    this.flagviewrx = true;
    this.recognition = null;
    this.recognizing = false;

    this.translate.setDefaultLang('english');
    this.translate.use('english');
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";

    this.addmed = false;
    this.frommedical = false;
    this.listProducts = [];

    this.day_txt = "";
    this.dis_txt = "";
    this.listProducts = [];
    this.Blood_test = [];
    this.testtypename = [];
    this.sercah_center = "";
    this.subtestname = [];
    this.subtestbname = [];
    this.subtestfname = [];
    this.subtestname = [];
    this.subtestname = [];
    this.subtestsname = [];
    this.subtestuname = [];
    this.subtesturname = [];
    this.subtestxname = [];
    this.subtestcname = [];
    this.diag_detail_List = [];
    this.location_txt = [];
    this.obs_add_diag = "";
    this.dig_view_flag = false;

    this.Medicine_table = true;
    this.quan_lable = true;
    this.mor_lable = false;
    this.after_eventxt = false;
    this.eve_eventxt = false;
    this.daydur_show = false;
    this.night_eventxt = false;
    this.mor_id = false;
    this.after_id = false;
    this.eve_id = true;
    this.Med_addbtn = true;
    this.language = "English";
    this.daydur_txt = "day(s)"
    this.afterfood_txt = "After food";
    this.dure_txt = "30";
    this.flow = "order";
  }

  async ngOnInit() {
    this.userheaderdata = Doc_Helper.getClient_Info();
    console.log("this.userheaderdata list" + JSON.stringify(Doc_Helper.getClient_Info()))

    this.userinfo = Helper_Class.getInfo();
    console.log("this.userinfo.hospitals ---" + JSON.stringify(this.userinfo.hospitals))
    this.diag_centre_id = this.diag_cen_id = this.userinfo.hospitals[0].diag_centre_id
    console.log("userheaderdata list" + JSON.stringify(this.userheaderdata))
    console.log("************" + JSON.stringify(this.userinfo.hospitals[0]))

    console.log("chekc list" + JSON.stringify(this.diag_cen_id))
    this.print_same_page = true;

    //Doc_Helper.getAppFlow()
    this.order_now = "1"
    this.subscription = this.bmiservice.getMessage().subscribe(message => {
      if (message) {
        if (message.flag == "vitals") {
          if (message.height != undefined && message.height != null && message.height != "") {
            this.height = message.height + message.height_measure;
            this.height_flag = true;
          } else {
            this.height_flag = false;
          }
          if (message.weight != undefined && message.weight != null && message.weight != "") {
            this.weight = message.weight + message.weight_measure;
            this.weight_flag = true;
          } else {
            this.weight_flag = false;
          }
          if (message.temparature != undefined && message.temparature != "") {
            this.temparature = message.temparature;
            this.temparature_flag = true;
          } else {
            this.temparature_flag = false;
          }
          if (message.bp != null && message.bp != undefined) {
            this.clnt_bp = message.bp + " " + "mmHg";
            this.bp_flag = true;
          } else {
            this.bp_flag = false;
          }
          if (message.pulse != undefined && message.bp != null) {
            this.clnt_pulse = message.pulse;
            this.bp_flag = true;
          } else {
            this.bp_flag = false;
            this.clnt_pulse = "";
          }
        }
      }
    });

    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.sessionTimeStart.push("0" + i);
      } else {
        this.sessionTimeStart.push(i);
      }
    }
    if (this.userheaderdata.client_name != "") {
      this.patient_name = this.userheaderdata.salutation + "." + this.userheaderdata.client_name;
      this.persondataflag = true
    }
    if (this.userheaderdata.spl != undefined) {
      this.speclization_arry = this.userheaderdata.spl;
    }
    if (this.userheaderdata.Age_data != undefined) {
      this.ageflag = true;
      this.patient_age = this.userheaderdata.Age_data;
    }
  //  alert(this.userheaderdata.complaints)
    if (this.userheaderdata.ascomplaints != undefined) {
      if (this.userheaderdata.ascomplaints != "") {
        this.complaints = this.userheaderdata.ascomplaints;
      } else {
        if (this.userheaderdata.complaints != undefined) {
          this.complaints = this.userheaderdata.complaints;
        } else {
          this.complaints = this.userheaderdata.complaints;
        }
      }
      //  this.ageflag = true;

    } else if (this.userheaderdata.complaints != undefined) {
      this.complaints = this.userheaderdata.complaints;
    }

    if (this.userheaderdata.Gender_data != undefined) {
      this.genderflag = true;
      var gender = this.userheaderdata.Gender_data;
      if (gender == "Male") {
        this.patient_gender = "M"
      } else if (gender == "Female") {
        this.patient_gender = "F"
      } else {
        this.patient_gender = "T"
      }

    }
    if (this.userinfo.user_type != "doctor") {
      if (this.userheaderdata.doctor_name != undefined) {
        this.doctor_name = this.userheaderdata.doctor_name;

      }
    } else {
      if (this.userheaderdata.doctor_name != undefined) {
        this.doctor_name = "Dr" + " " + this.userheaderdata.doctor_name;
      }
    }
    // if (this.userinfo.hospitals != undefined) {
    //   this.language = this.userinfo.hospitals[0].language;
    //   // this.translate.setDefaultLang('tamil');
    //   if (this.language == "English") {
    //     this.translate.use('english');
    //     this.printLanguageflag = false;
       

    //   } else if (this.language == "Tamil") {
    //     this.translate.use('tamil');
    //     this.printLanguageflag = true;
        

    //   } else if (this.language == "Telugu") {
    //     this.printLanguageflag = true;
    //     this.translate.use('telugu');

       

    //   } else if (this.language == "Malayalam") {
    //     this.printLanguageflag = true;
    //     this.translate.use('malayalam');
       
    //   } else if (this.language == "Kannada") {
    //     this.printLanguageflag = true;
    //     this.translate.use('kannada');
      

    //   } else if (this.language == "Oriya") {
    //     this.printLanguageflag = true;
    //     this.translate.use('oriya');
        
    //   } else if (this.language == "Bengali") {
    //     this.printLanguageflag = true;
    //     this.translate.use('bangla');
      

    //   } else if (this.language == "Hindi") {
    //     this.printLanguageflag = true;
    //     this.translate.use('hindi');
        
    //   }

    // }
    if (this.language != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
    this.patient_image = this.userheaderdata.image;
    console.log("this.userlist.clientaddress2 " + JSON.stringify(this.userheaderdata))
    console.log("this.Doc_Helper.getAppFlow(.clientaddress2 " + typeof (this.userheaderdata.clientaddress2))
    if (this.userheaderdata.clientaddress2 != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (this.userheaderdata.clientaddress2 != undefined) {
          if (this.userheaderdata.clientaddress2 != "null" && this.userheaderdata.clientaddress2 != "") {
            this.addressdetails = (this.userheaderdata.clientaddress1) + "," + (this.userheaderdata.clientaddress2) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          } else {
            this.addressdetails = (this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          }

        }
        else {

          this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

        }

      }
      if (Doc_Helper.getAppFlow() != "Inpatient") {
        if (this.userheaderdata.clientaddress2 != undefined) {
          if (this.userheaderdata.clientaddress2 && this.userheaderdata.clientaddress2 !== "null") {
            this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + encrypt_decript.Decript(this.userheaderdata.clientaddress2) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;
          } else {
            this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          }
        } else {
          this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + this.userheaderdata.clnt_zipcodevalue + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

        }
      }
    } else {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (this.userheaderdata.clientaddress2 != undefined) {
          if (this.userheaderdata.clientaddress2 != "null" && this.userheaderdata.clientaddress2 != "") {
            this.addressdetails = (this.userheaderdata.clientaddress1) + "," + (this.userheaderdata.clientaddress2) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          } else {
            this.addressdetails = (this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          }

        }
        else {
          this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + (this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

        }

      }
      if (Doc_Helper.getAppFlow() != "Inpatient") {
        if (this.userheaderdata.clientaddress2 != undefined) {
          if (this.userheaderdata.clientaddress2 != "null" && this.userheaderdata.clientaddress2 != "") {
            this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + encrypt_decript.Decript(this.userheaderdata.clientaddress2) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;
          } else {
            this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + encrypt_decript.Decript(this.userheaderdata.clientaddress2) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + encrypt_decript.Decript(this.userheaderdata.clientzipcode) + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

          }
        } else {
          this.addressdetails = encrypt_decript.Decript(this.userheaderdata.clientaddress1) + "," + this.userheaderdata.clientlocation + "," + this.userheaderdata.clientcity + "-" + this.userheaderdata.clnt_zipcodevalue + "," + this.userheaderdata.clientstate + "-" + this.userheaderdata.clientcountry;

        }
      }
    }
    this.complaints = this.userheaderdata.complaints;

    if (Doc_Helper.getAppFlow() != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (Helper_Class.getInfo().middle_name != undefined) {
          this.doctor_name = "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().middle_name + " " + Helper_Class.getInfo().last_name;
        } else {
          this.doctor_name == "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().last_name;
        }
        // bed_no
        //  if(this.userheaderdata.wardlist != undefined){
        //   const bedName = this.getBedName(this.userheaderdata.bed_no);
        //   if (bedName) {
        //     console.log(` is occupied by ${bedName}.`);
        // } else {
        //     console.log(` is vacant.`);
        // }
        //  }
        // this.inpatflag = true;
        // this.wardno = this.userlist.wardlist;
        // this.admission_time = this.userlist.admission_time;
        // if (this.userlist.admission_date != undefined) {
        //   this.admission_date = Date_Formate(this.userlist.admission_date)
        // } else {
        //   this.admission_date = "";
        // }

        // this.surgery_inpat = this.userlist.surgery_name;
        // this.bedno = this.userlist.bed_no
        //  this.wardno 
      }
    }


    if (this.dur_flag == true && this.notes_flag == true) {
      this.medstyle = true;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.medstyle = false;
      this.medstyle1 = true;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = true;
      this.medstyle3 = false;

    } else {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = true;
    }
    if (this.userheaderdata.visitpurpose != "") {
      this.visitpurpose = this.userheaderdata.visitpurpose;

    }
    if (this.userheaderdata.inpatientnuber != undefined) {
      this.inpatientnuber = this.userheaderdata.inpatientnuber;

    } else {
      if (this.userheaderdata.app_id != undefined) {
        this.inpatientnuber = this.userheaderdata.app_id;

      }
    }
    if (this.userinfo.hospitals[0].inp_diag_bill_gen != undefined) {
      this.inp_diag_bill_gen = this.userinfo.hospitals[0].inp_diag_bill_gen
    }

    //doctor_name
    if (this.userheaderdata.pat_id != "" && this.userheaderdata.pat_id != undefined) {
      this.mrnno = this.userheaderdata.pat_id;
      this.mrnflag = true;
    }
    if (this.userheaderdata.profile_image != undefined) {
      this.profileImg = this.userheaderdata.profile_image;
    }
    if (this.userheaderdata.barcode != undefined) {
      this.barcode_flag = true;
      this.barcode_url = ipaddress.Ip_with_img_address + this.userheaderdata.barcode;
    
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.sessionTimeEnd.push("0" + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      order: [[1, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: "Search by medicine name"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>'
    };
    var Moduleidlist;
    if (Doc_Helper.getModuleList() != null) {
      console.log('nlkjhjjh ' + JSON.stringify(Doc_Helper.getModuleList()))
      Moduleidlist = Doc_Helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        // alert(Moduleidlist[i].module_id)s
        if (Moduleidlist[i].module_id == "34") {
          this.genericDisplayFlag = true;
        }
        // if (Moduleidlist[i].module_id == "119") {
        //   this.diagnosisprescription = true;
        // }
        if (Moduleidlist[i].module_id == "6") {
          this.medicalprescription = true;
        }

        if (Moduleidlist[i].module_id == "119") {
          this.diagpresflag = true;
        }
      }
    }
    if (Doc_Helper.getClient_Info() != undefined) {
      if (Doc_Helper.getClient_Info().AppFlow == "Nurseoutpatient") {
        if (Helper_Class.getInfo().both_modules.length != 0) {
          var Moduleidlist;
          Moduleidlist = Helper_Class.getInfo().both_modules;
          for (var i = 0; i < Moduleidlist.length; i++) {
            // console.log("user Moduleidlist-->"+JSON.stringify(Moduleidlist[i].module_id))   
            if (Moduleidlist[i].module_id == "6") {
              this.medicalprescription = true;
            }
            if (Moduleidlist[i].module_id == "119") {
              this.diagpresflag = true;
            }
          }
        }
      }
    }

    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      this.medPresFlag = message;
      if (message == "max") {
        this.med_view_list = false;
        this.print_view_flag = true;
        Helper_Class.set_med_pres_id(undefined);
      }

      if (Doc_Helper.getDiagAppflow() != "diag") {
        this.addmed = false;
        this.frommedical = true;
        Doc_Helper.setMedicalHeading("false");

      } else {
        this.saveflag = true;
        if (Doc_Helper.getMedDiag() != undefined) {
          if (Doc_Helper.getMedDiag() == true) {
            this.saveflag = false;
          } else {
            this.saveflag = true;
          }
        } else if ((Doc_Helper.getDiagSave() != undefined) || (Doc_Helper.getMedSave() != undefined)) {
          if (Doc_Helper.getDiagSave() == true && Doc_Helper.getMedSave() == undefined) {
            this.saveflag = true;
          }

          if (Doc_Helper.getDiagSave() == true && Doc_Helper.getMedSave() == true) {
            this.saveflag = false;
          }

          if (Doc_Helper.getMedSave() == true && Doc_Helper.getDiagSave() == undefined) {
            this.saveflag = false;
          }

        } else {
          this.saveflag = true;
        }
        this.frommedical = false;
        this.addmed = true;
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {
      this.add_MP = true;
      this.personalinfo = Helper_Class.getInfo();
      this.req_estimate = this.personalinfo.hospitals[0].req_estimate;
      if (this.personalinfo.hospitals[0].pres_duration_flag == "0") {
        this.dur_flag = true;
      }
      if (this.personalinfo.hospitals[0].pres_notes_flag == "0") {
        this.notes_flag = true;
      }

      this.userinfo = Helper_Class.getclient_hospitalDetails();

      // this.patient_name = this.userlist.client_name;
      // this.patient_age = this.userlist.Age_data;
      // this.patient_gender = this.userlist.Gender_data;

      this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
      this.doct_name = this.doctorname;
      this.dct_hospital = this.userinfo.hptl_name;
      this.doct_address1 = this.userinfo.address1;
      this.doct_address2 = this.userinfo.address2;
      this.doct_location = this.userinfo.location;
      this.doct_city = this.userinfo.city;
      this.doct_state = this.userinfo.state;
      this.zipcode = this.userinfo.zipcode;
      this.doct_country = this.userinfo.country;
      if (this.personalinfo.Age_data != undefined) {
        this.client_age = this.personalinfo.Age_data;
        // this.ageflag=true;
      }
      if (this.personalinfo.Gender_data != undefined) {
        this.client_gender = this.personalinfo.Gender_data;
        // this.genderflag=true;
      }
      this.Appoint_Date = this.personalinfo.Appoint_Date;
      this.mrNumber = this.personalinfo.pat_id;

      if (this.personalinfo.salutation != undefined) {
        this.doct_clientname = this.personalinfo.salutation + ". " + this.personalinfo.client_name;
      } else {
        this.doct_clientname = this.personalinfo.client_name;
      }
      this.mobile = this.personalinfo.mobile;
      this.hospital_clinic_id = this.personalinfo.hptl_clinic_id;
      this.splname = this.personalinfo.spl;
      this.home_care = "0";
      this.Get_pharmacy_address();

    } else {
      this.userinfo = Helper_Class.getInfo();
      if (this.userinfo.hospitals[0].allow_diag_select != undefined) {
        if (this.userinfo.hospitals[0].allow_diag_select == "0" || this.userinfo.hospitals[0].allow_diag_select == 0) {
          this.allow_diag_selectflag == false;
        } else {
          this.allow_diag_selectflag == true;
        }
      } else {
        this.allow_diag_selectflag == true;
      }

      this.req_estimate = this.userinfo.hospitals[0].req_estimate;
      if (this.userinfo.hospitals[0].pres_duration_flag == "0") {
        this.dur_flag = true;
      }
      if (this.userinfo.hospitals[0].pres_notes_flag == "0") {
        this.notes_flag = true;
      }
      if (this.userinfo.hospitals[0].doc_bill_generate != undefined) {
        this.doc_bill_generate = this.userinfo.hospitals[0].doc_bill_generate
      }
      if (this.userinfo.pres_dur_min_flag != undefined) {
        this.dur_time_flag = this.userinfo.pres_dur_min_flag;

        if (this.userinfo.pres_dur_min_flag == "0") {
          this.dur_time_flag = true;
        } else {
          this.dur_time_flag = false;
        }

      }
      this.personalinfo = Doc_Helper.getClient_Info(); this.add_MP = false;
      console.log("PERSONAL ")
      this.dct_hospital = Doc_Helper.getClient_Info().hptl_name;
      this.doct_address1 = Doc_Helper.getClient_Info().address1;
      this.doct_address2 = this.doct_address2 != undefined ? Doc_Helper.getClient_Info().address2 : "";
      this.doct_address = this.doct_address1 + this.doct_address2;
      this.doct_location = Doc_Helper.getClient_Info().location;

      this.search_location = Doc_Helper.getClient_Info().location;
      this.doct_city = Doc_Helper.getClient_Info().city;
      this.doct_state = Doc_Helper.getClient_Info().state;
      this.zipcode = Doc_Helper.getClient_Info().zipcode;
      this.doct_country = Doc_Helper.getClient_Info().country_id;
      this.mrNumber = Doc_Helper.getClient_Info().pat_id;
      if (this.doct_country != null && this.doct_country != undefined) {
        this.clnt_country_id = this.doct_country;
        this.clnt_state_id = Doc_Helper.getClient_Info().state_id;
        this.clnt_city_id = Doc_Helper.getClient_Info().city_id;
        this.getCountries("0");
      } else {
        this.getCountries("0");
      }

      this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
      this.client_gender = this.personalinfo.Gender_data;
      this.Appoint_Date = this.personalinfo.Appoint_Date;
      this.client_age = this.personalinfo.Age_data;
      if (this.personalinfo.salutation != undefined) {
        this.doct_clientname = this.personalinfo.salutation + ". " + this.personalinfo.client_name;
      } else {
        this.doct_clientname = this.personalinfo.client_name;
      }

      this.mobile = this.personalinfo.mobile;

      if (this.personalinfo.height != undefined && this.personalinfo.height != null && this.personalinfo.height != "") {
        this.height = this.personalinfo.height + this.personalinfo.height_measure;
        this.height_flag = true;
      } else {
        this.height_flag = false;
      }
      if (this.personalinfo.weight != undefined && this.personalinfo.weight != null && this.personalinfo.weight != "") {
        this.weight = this.personalinfo.weight + this.personalinfo.weight_measure;
        this.weight_flag = true;
      } else {
        this.weight_flag = false;
      }
      if (this.personalinfo.temparature != undefined && this.personalinfo.temparature != null) {
        this.temparature = this.personalinfo.temparature;
        this.temparature_flag = true;
      } else {
        this.temparature_flag = false;
      }

      var getdata = this.userinfo.medicares;
      if (getdata != undefined && getdata != null) {
        for (var i = 0; i < getdata.length; i++) {
          this.Medicare_name = getdata[0].medicare_name;
        }
      }
    }

    if (Helper_Class.getInpatientFlow() == "nurse") {
      this.user_id = this.personalinfo.user_id;

      if (Helper_Class.getmodulelist() != undefined) {
        Moduleidlist = Helper_Class.getmodulelist();
        console.log("Doc_Helper.getModuleList()" + JSON.stringify(Helper_Class.getmodulelist()))
        for (var i = 0; i < Moduleidlist.length; i++) {
          // alert(Moduleidlist[i].module_id)
          if (Moduleidlist[i].module_id == "34") {
            this.genericDisplayFlag = true;
          }
          if (Moduleidlist[i].module_id == "6") {
            this.medicalprescription = true;
          }


          if (Moduleidlist[i].module_id == "119") {
            this.diagpresflag = true;
          }
        }
      }
    } else {
      this.user_id = this.userinfo.user_id;
    }

    if (Helper_Class.getHospitalInfo() != undefined) {
      this.printFlag = Helper_Class.getHospitalInfo().doc_pres_print == "1" ? true : false;
    }

    this.doctoraddress = Doc_Helper.getDoctorAddr();
    this.locationinfo = Helper_Class.getHospitalInfo();
    this.loc_name = Doc_Helper.getClient_Info().location;
    //this.personalinfo.AppFlow == "InpatMedPres_dis" || this.personalinfo.AppFlow == "InpatMedPres"
    if (this.personalinfo.AppFlow == "InpatMedPres") {
      this.medPresFlag = 'max';
      this.navigtionflag = "info";
      this.inpatiant_save = true;
      this.backFlag = true;
      this.frommedical = true;
      this.not_Inpatient = false;

      this.nextvisitflag = false;
      this.InpatMedPres = false;
    } else if (this.personalinfo.AppFlow == "InpatMedPres_dis") {
      this.medPresFlag = 'max';
      this.navigtionflag = "dis";
      this.inpatiant_save = true;
      this.backFlag = true;
      this.frommedical = true;
      this.not_Inpatient = false;

      this.addmed = true;

    } else if (this.personalinfo.AppFlow == "Inpatient") {
      this.medInpatientViewPrec = true;
      this.Get_pharmacy_address();
      this.not_Inpatient = false;

    } else {
      this.backFlag = false;
      this.medInpatientViewPrec = false;
      this.nextvisitflag = true;
      this.not_Inpatient = true;

    }

    this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
    this.doct_name = this.doctorname;
    this.hospitalinfoarray = Helper_Class.getHospital();

    this.pharma_id = this.hospitalinfoarray[0].pharma_id;

    this.prfer_pharmatxt = this.hospitalinfoarray[0].pharma_name;
    if (this.prfer_pharmatxt != undefined) {
      this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      this.Get_pharmacy_address();
      this.getmedicineonit();
    }

    this.specializations_name = Helper_Class.getSpecializations();
    this.qualification = Helper_Class.getQualification();
    this.doc_qualif = Helper_Class.getQualification();
    this.recognition = null;
    this.recognizing = false;
    this.licence_code = this.userinfo.licence_code;
    Helper_Class.set_med_pres_id(null);
    Helper_Class.set_diag_pres_id(null);

    if (Doc_Helper.getHospital_logo() != undefined) {
      this.hosp_img = Doc_Helper.getHospital_logo();
      this.getBase64Image(this.hosp_img);
    }

    if (Doc_Helper.getHospFooter() != undefined) {
      this.hosp_footer = Doc_Helper.getHospFooter();
      this.printf_flag = false;
    } else {
      this.printf_flag = true;
    }

    if (this.hospitalinfoarray.hptl_name != undefined && this.hospitalinfoarray.hptl_name != "undefined") {
      this.dct_hospital = this.hospitalinfoarray.hptl_name;
    }
    if (Helper_Class.getHospital()[0].pres_print_template != undefined) {
      this.print_template = Helper_Class.getHospital()[0].pres_print_template;
    } else {
      this.print_template = "noheader";
    }

    if (Helper_Class.getHospital()[0].pres_print_logo != undefined && Helper_Class.getHospital()[0].pres_print_logo != "undefined") {
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + Helper_Class.getHospital()[0].pres_print_logo;
      this.preslogo_style = Helper_Class.getHospital()[0].pres_print_logo_style;
    }
    this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style;
    this.headerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_heading);
    this.footerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_footer);

    if (Doc_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Doc_Helper.getHospital_bg_image();
    }

    if (this.personalinfo.treatment != undefined && this.personalinfo.treatment != "undefined") {
      this.treatment = this.sanitizer.bypassSecurityTrustHtml(this.personalinfo.treatment);
      this.treatflag = true;
    }

    if (this.personalinfo.refer_by != null && this.personalinfo.refer_by != undefined && this.personalinfo.refer_by != "" && this.personalinfo.refer_by != 'null') {
      this.refer_txt = this.personalinfo.refer_by;
      this.ref_flag = true;
    } else {
      this.ref_flag = false;
    }

    if (this.personalinfo.symptoms != undefined) {
      this.oberse_med = this.personalinfo.symptoms;
    }
    //list view
    this.header_footer_flag = false;
    this.med_pres_listdata = false;
    this.clnt_bp = this.personalinfo.bp == undefined ? "" : this.personalinfo.bp + " " + "mmHg";
    if (this.personalinfo.bp != null && this.personalinfo.bp != undefined) {
      this.clnt_bp = this.personalinfo.bp + " " + "mmHg";
      this.bp_flag = true;
    } else {
      // this.clnt_bp = "";
      this.bp_flag = false;
    }
    if (this.personalinfo.pulse != undefined && this.personalinfo.bp != null) {
      this.clnt_pulse = this.personalinfo.pulse;
      this.pulseflag = true;
      this.bp_flag = true;
    } else {
      this.bp_flag = false;
      this.clnt_pulse = "";
    }

    if (this.personalinfo.cvs != null) {
      this.clnt_cvs = this.personalinfo.cvs;
      this.ortho_flag = true;
      this.cvsflag = true;
    } else {
      //this.clnt_cvs = "";
      this.ortho_flag = false;
    }

    if (this.personalinfo.rs != null) {
      this.rsflag = true;
      this.clnt_rs = this.personalinfo.rs
      this.ortho_flag = true;
    } else {
      this.clnt_rs = "";
    }

    if (this.personalinfo.rate != undefined) {
      this.clnt_heartrate = this.personalinfo.rate;
      this.heartrateflag = true
    }


    if (this.personalinfo.kidney != null) {
      this.kidney_flag = true;
      this.clnt_kidney = this.personalinfo.kidney;
      this.ortho_flag = true;
    } else {
      this.clnt_kidney = "";
    }

    this.appGetdate();
    this.flagviewrx = this.medPresFlag == "min" ? true : false;
    this.splname = this.personalinfo.spl;
    this.send_client_reg_id.client_id = this.personalinfo.Client_id;
    this.send_client_reg_id.relation = this.personalinfo.relationship_name;
    this.send_client_reg_id.home_care = Helper_Class.getapp_flow();
    this.home_care = Helper_Class.getapp_flow();
    if (masterCSData_Helper.getMasterIntakeSession != undefined) {
      await this.masterCSdata.getIntakeSession();
      this.Get_Intake_session();
    } else {
      this.Get_Intake_session();
    }

    this.languageChange();
    this.hosp_address = this.home_care == "1" ? true : false;

    if (Doc_Helper.getAppFlow() == "Inpatient") {
      this.inpatBackFlag = true;
      this.addmed = this.personalinfo.addmed == "write" ? true : false;
    }

    this.listProducts = [];
    if (this.personalinfo.spl_id == "29") {
      this.getSugeryImage();
    }
    this.presListView = true;

    if (this.dur_flag == true && this.notes_flag == true) {
      this.med_style = "width: 275px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 150px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.med_style = "width: 250px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 50px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width:50px;border: 1px solid black;border-collapse: collapse;";
    }
  }

  getmedicineonit() {
    this.mediceList = [];
    console.log("check med name--" + JSON.stringify(this.hospital_clinic_id))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gmd',
      {
        hptl_clinic_id: this.hospital_clinic_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("check med name--" + JSON.stringify(obj))
          var list = obj.drug_details;
          for (var i = 0; i < list.length; i++) {
            if (list[i].med_type_id != undefined) {
              this.mediceList.push({
                med_id: list[i].med_id,
                type_name: list[i].type_name,
                med_name: list[i].med_name,
                quantity: list[i].quantity,
                med_type_id: list[i].med_type_id,
                show_intake: list[i].show_intake,
                // short_form: list[i].show_short_form,
                short_form: list[i].short_form,
                show_short_form: list[i].show_short_form,
                genericname: list[i].generic_name,
              });
            }
          }
        },
        error => { });
  }

  appGetdate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.curr_date = obj.current_date;
      this.current_date = Date_Formate(obj.current_date);
      this.current_time = obj.current_time;
      this.printtime = Time_Formate(this.current_time)
      this.current_year = obj.current_date.split('-')[0];
      this.CurrentDatetime = obj.current_date;
      this.currentDate = obj.current_date;
      // this.next_datetxt= (obj.current_date);
      this.appDate = (obj.current_date);
      var currenttime = obj.current_time.split(":");
      console.log("obj currenttime" + JSON.stringify(currenttime[0]))
      // this.appDate = (obj.current_date);
      // this.next_datetxt= (obj.current_date);
      if (currenttime[0] >= 3 && currenttime[0] < 12) {
        this.visitSession = "Morning";
      } else if (currenttime[0] >= 12 && currenttime[0] < 16) {
        this.visitSession = "Afternoon";
      } else if (currenttime[0] >= 16 && currenttime[0] < 19) {
        this.visitSession = "Evening";
      } else if (currenttime[0] >= 19 && currenttime[0] < 24) {
        this.visitSession = "Evening";
      }
      this.getVisitingSessionArray = [{ "description": this.visitSession }];

    }
  }

  getBase64Image(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    },
      { headers: headers })
      .subscribe(
        response => {
          //  JSON.parse(JSON.stringify(response));
          var data = response.json();
          this.imagestring = "data:image/jpeg;base64," + data.imagestr;
        });
  }

  languageChange() {
    if(this.language != "English"){
      this.printLanguageflag = true;
    }else{
      this.printLanguageflag = false;
    }
    if (this.language == "English") {
      this.translate.use('english');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Tamil") {
      this.translate.use('tamil');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("15ml தண்ணீருடன்")
      this.mixtype.push("100ml தண்ணீருடன்")
      this.mixtype.push("தயிருடன்")

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    }
    if (this.language != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
  }

  Get_Intake_session() {
    var obj = masterCSData_Helper.getMasterIntakeSession();
    if (obj != undefined)
      this.Get_Intake_array = obj.drug_intake;
    this.intake_txt = "Thrice a day";
  }

  getSugeryImage() {
    this.sugeryimg = Doc_Helper.getSugeryDetails().sugImageString;
  }

  Get_pharmacy_address() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
      {
        hptl_clinic_id: this.hospital_clinic_id,
        type: "pharma"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          this.prfer_pharmatxt = obj.hptl_name;
          if (obj.address1 != undefined) {
            this.prefer_add = obj.address1 + ", " + obj.location + ", " + obj.city + " - " + obj.zipcode + ", " + obj.state + ", " + obj.country;
          }
          this.appmedpresList();
        },
        error => { });
  }

  getCountries(flag) {
    this.clnt_country_list = [];
    var data = Master_Helper.getMasterCountries();
    if (data.countries != null) {
      this.clnt_country_list = data.countries;
      for (var c = 0; c < this.clnt_country_list.length; c++) {
        if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
          this.search_country = this.clnt_country_list[c].description;
          this.clnt_country_id = this.clnt_country_list[c].country_id;
          this.getStates(this.clnt_country_id, flag);
          break;
        }
      }
    }
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.search_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list = [];
        this.clnt_city_list = [];
        this.clnt_location_id = undefined;
        this.search_location = "";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clnt_state_list = [];
    var data;
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/state", JSON.stringify({ country_id: country }),
        { headers: headers }).subscribe(
          response => {
            data = response.json();
            this.clnt_state_list = data.states;
          });
    } else {
      data = Master_Helper.getMasterStates();
      this.clnt_state_list = data.states;
    }
    if (this.clnt_state_list != null) {
      for (var i = 0; i < this.clnt_state_list.length; i++) {
        if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
          this.clnt_state_id = this.clnt_state_list[i].state_id;
          this.search_state = this.clnt_state_list[i].description;
          this.getCities(this.clnt_state_id, flag);
          break;
        }
      }
    }
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.search_city = this.clnt_state_list[i].description;
        this.clnt_city_list = [];
        // this.clnt_city_id=undefined;
        this.clnt_location_id = undefined;
        this.search_location = "";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/city", JSON.stringify({ state_id: state }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.cities != null)
              this.clnt_city_list = data.cities;

          });
    } else {
      var data = Master_Helper.getMasterCities();
      if (data.cities != null)
        this.clnt_city_list = data.cities;
    }

    if (this.clnt_city_list != null) {
      for (var i = 0; i < this.clnt_city_list.length; i++) {
        if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
          this.clnt_city_id = this.clnt_city_list[i].district_id;
          this.search_city = this.clnt_city_list[i].description;
          this.getLocations(this.clnt_city_id, flag);
          break;
        } else {
          this.search_city = this.clnt_city_list[0].description;
        }
      }
    }
  }

  cityChange(clnt_city, flag) {
    this.clnt_location_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.search_city = this.clnt_city_list[i].description;
        this.clnt_location_id = undefined;
        this.search_location = "";
      }
    }
  }

  getLocations(city, flag) {
    this.clnt_location_list = [];

    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/loc", JSON.stringify({ city_id: city }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.locations != null && data.locations.length != 0)
              this.clnt_location_list = data.locations;
          });
    } else {
      var data = Master_Helper.getMasterLocation();
      if (data.locations != null && data.locations.length != 0)
        this.clnt_location_list = data.locations;
    }
    if (this.clnt_location_list != null && this.clnt_location_list.length != 0) {
      for (var i = 0; i < this.clnt_location_list.length; i++) {
        if (this.clnt_location_list[i].location_id == this.clnt_location_id) {
          this.clnt_location_id = this.clnt_location_list[i].location_id;
          this.search_location = this.clnt_location_list[i].description;
          this.clnt_location_list = [];
          break;
        }
      }
      this.SerCountry_Change(this.search_country, this.search_state, this.search_city, this.search_location);
    }
  }

  location_change(locat_desc) {
    this.clnt_location_list = [];
    if (locat_desc.target.value.toString() != null && locat_desc.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc.target.value.toString(),
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clnt_location_list = [];
    }
  }

  location_selected(location) {
    this.search_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.search_location = this.clnt_location_list[i].description;
      }
    }
    this.clnt_location_list = [];
    this.SerCountry_Change(this.search_country, this.search_state, this.search_city, this.search_location);
  }

  SerCountry_Change(countrytxt, statetxt, citytxt, loctxt) {
    this.diag_detail_List = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetbyname/',
      JSON.stringify({
        country: countrytxt,
        state: statetxt,
        city: citytxt,
        location: loctxt,
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();;
          if (obj.diagnosis != null && obj.diagnosis != undefined && obj.diagnosis != "") {
            this.diag_detail_List = obj.diagnosis;
            for (var i = 0; i < obj.diagnosis.length; i++) {

              if (obj.diagnosis[i].diag_centre_name != null) {
                this.diag_cen_name = obj.diagnosis[i].diag_centre_name;
              }
              if (obj.diagnosis[i].address1 != null || obj.diagnosis[i].address2 != null) {
                this.diag_address1 = obj.diagnosis[i].address1;
                this.diag_address2 = obj.diagnosis[i].address2;
              }


              if (obj.diagnosis[i].location != null) {
                this.diag_location = obj.diagnosis[i].location;
              }

              if (obj.diagnosis[i].city != null) {
                this.diag_city = obj.diagnosis[i].city;
              }

              if (obj.diagnosis[i].state != null) {
                this.diag_state = obj.diagnosis[i].state;
              }

              if (obj.diagnosis[i].zipcode != null) {
                this.diag_zipcode = obj.diagnosis[i].zipcode;
              }

              if (obj.diagnosis[i].country != null) {
                this.diag_cntry = obj.diagnosis[i].country;

              }
              if (obj.diagnosis[i].telephone != null) {
                this.diag_telpho = obj.diagnosis[i].telephone;
              }
              this.sercah_center = this.diag_detail_List[0].diag_centre_name;
              this.serch_centerchange(this.diag_detail_List[0]);
            }
            //this.diag_centre_id = obj.diagnosis[0].diag_centre_id;
          }
        },
        error => {

        }
      )
  }

  appmedpresList() {
    console.log("inpat info" + JSON.stringify(this.personalinfo))
    console.log("inpat info" + JSON.stringify({
      inpatient_id: Doc_Helper.getInpatientId(),
      country: ipaddress.country_code,
      spl_name: "General",
    }))

    if (this.personalinfo.AppFlow == "Inpatient" || this.personalinfo.AppFlow == "InpatMedPres") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyinpid/',
        JSON.stringify({
          inpatient_id: Doc_Helper.getInpatientId(),
          country: ipaddress.country_code,
          spl_name: "General",
        }),
        { headers: headers })
        .subscribe(
          response => {

            var obj = JSON.parse(response["_body"]);
            console.log("inpat info" + JSON.stringify(obj))

            this.viewpreslist_array = [];
            if (obj.prescriptions != null) {
              var spl_view_med_preslist = obj.prescriptions;
              if (spl_view_med_preslist.length != 0) {
                for (var i = 0; i < spl_view_med_preslist.length; i++) {
                  var med_format = null;
                  var dateview = spl_view_med_preslist[i].date.split('-');
                  var medicines = [];

                  for (var j = 0; j < spl_view_med_preslist[i].drug_list.length; j++) {
                    var drugintakevalue;
                    var shortform = spl_view_med_preslist[i].drug_list[j].short_form;
                    var morning = " ", afternoon = " ", evening = " ", night = "";
                    if (spl_view_med_preslist[i].drug_list[j].show_short_form == "1") {
                      if (spl_view_med_preslist[i].drug_list[j].intake == "1") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + shortform + "-0-0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "2") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - " + spl_view_med_preslist[i].drug_list[j].afternoon + shortform + " - 0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "3") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - 0 - " + spl_view_med_preslist[i].drug_list[j].night + shortform;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "4") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + shortform + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + shortform + " - 0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "5") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + shortform + " - 0 - " + spl_view_med_preslist[i].drug_list[j].night + shortform;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "6") {  // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - " + spl_view_med_preslist[i].drug_list[j].afternoon + shortform + " - " + spl_view_med_preslist[i].drug_list[j].night + shortform;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "7") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + shortform + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + shortform + " - " + spl_view_med_preslist[i].drug_list[j].night + shortform;
                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "8") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].evening + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].night + spl_view_med_preslist[i].drug_list[j].short_form;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "9") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - Every 6 hours";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "10") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - Every 8 hours";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "11") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - Every 12 hours ";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "12") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - Once a day";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "13") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " SOS - if required";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "14") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].evening + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].night + spl_view_med_preslist[i].drug_list[j].short_form;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "15") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].evening + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].night + spl_view_med_preslist[i].drug_list[j].short_form;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "16") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].evening + spl_view_med_preslist[i].drug_list[j].short_form + " - " + spl_view_med_preslist[i].drug_list[j].night + spl_view_med_preslist[i].drug_list[j].short_form;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "17") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " STAT";
                      }

                      if (spl_view_med_preslist[i].drug_list[j].time_duration != null && spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                        med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                      } else {
                        med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                      }

                    } else {
                      if (spl_view_med_preslist[i].drug_list[j].intake == "1") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - 0 - 0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "2") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - 0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "3") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - 0 - " + spl_view_med_preslist[i].drug_list[j].night;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "4") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - 0";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "5") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - 0 - " + spl_view_med_preslist[i].drug_list[j].night;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "6") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = "0 - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].night;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "7") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].night;
                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "8") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].evening + " - " + spl_view_med_preslist[i].drug_list[j].night;

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "9") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - Every 6 hours";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "10") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - Every 8 hours";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "11") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - Every 12 hours";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "12") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - Once a day";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "13") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " SOS - if required";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "14") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].evening + " - " + spl_view_med_preslist[i].drug_list[j].night + " Alternative days";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "15") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].evening + " - " + spl_view_med_preslist[i].drug_list[j].night + " Once a week";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "16") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].evening + " - " + spl_view_med_preslist[i].drug_list[j].night + " Once in two weeks";

                      } else if (spl_view_med_preslist[i].drug_list[j].intake == "17") {
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " STAT";
                      }
                      else if (spl_view_med_preslist[i].drug_list[j].intake == "16") {
                        // if (spl_view_med_preslist[i].drug_list[j].morning  !== "0") {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " " + this.short_form + " - ";
                        // } else {
                        //   morning = spl_view_med_preslist[i].drug_list[j].morning  + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].afternoon !== "0") {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " " + this.short_form + " - ";
                        // } else {
                        //   afternoon = spl_view_med_preslist[i].drug_list[j].afternoon + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].evening !== "0") {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening + " " + this.short_form + " - ";
                        // } else {
                        //   evening = spl_view_med_preslist[i].drug_list[j].evening[i].drug_list[j].eveningxt + " - ";
                        // }
                        // if (spl_view_med_preslist[i].drug_list[j].night !== "0") {
                        //   night = spl_view_med_preslist[i].drug_list[j].night + " " + this.short_form;
                        // } else {
                        //   night = spl_view_med_preslist[i].drug_list[j].night;
                        // }

                        // this.every_six = morning + afternoon + evening + night; 
                        drugintakevalue = spl_view_med_preslist[i].drug_list[j].morning + " - " + spl_view_med_preslist[i].drug_list[j].afternoon + " - " + spl_view_med_preslist[i].drug_list[j].evening + " - " + spl_view_med_preslist[i].drug_list[j].night + " Once in two weeks";

                      }

                      if (spl_view_med_preslist[i].drug_list[j].time_duration != "" || spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                        med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                      } else {
                        med_format = drugintakevalue + "    " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                      }
                    }

                    medicines.push({
                      drug: spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name,
                      duration: spl_view_med_preslist[i].drug_list[j].days + " " + spl_view_med_preslist[i].drug_list[j].period,
                      catagory: med_format, drug_id: spl_view_med_preslist[i].drug_list[j].drug_id
                    })
                  }

                  var docname;
                  if (spl_view_med_preslist[i].middle_name != undefined && spl_view_med_preslist[i].middle_name != null) {
                    docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].middle_name + " " + spl_view_med_preslist[i].last_name
                  } else {
                    docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].last_name
                  }

                  this.viewpreslist_array.push({
                    tot_doct_name: docname,
                    pres_drug_id: spl_view_med_preslist[i].pres_drug_id,
                    view_date: "Date :" + dateview[2] + "-" + dateview[1] + "-" + dateview[0],
                    date: dateview[2] + "-" + dateview[1] + "-" + dateview[0],
                    medicines: medicines,
                    disease: spl_view_med_preslist[i].disease
                  });
                }
              }
            }
          },
          error => { }
        )

    } else {
      this.med_pres_list = [];
      var sendata;
      if (this.personalinfo.sub_id != null && this.personalinfo.sub_id != undefined &&
        this.personalinfo.sub_id != "" && this.personalinfo.sub_id.length != 0) {
        sendata = JSON.stringify
          ({
            client_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            sub_rel_id: this.personalinfo.sub_id,
            home_care: this.home_care,
            spl_name: this.splname,
          });
      }
      else {
        sendata = JSON.stringify
          ({
            client_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            home_care: this.home_care,
            spl_name: this.splname,
          });
      }
      if (this.personalinfo.pres_id == undefined) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyclntid/', sendata,
          { headers: headers }).subscribe(
            response => {
              var data = response.json();

              if (data != null) {
                this.med_pres_list_data = data.prescriptions;
                if (this.med_pres_list_data != null) {
                  for (var i = 0; i < this.med_pres_list_data.length; i++) {
                    var new_datalist = this.med_pres_list_data[i];
                    this.docname_flag = false;
                    var Docname = "";
                    if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                      Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
                    }
                    else {
                      Docname = new_datalist.first_name + " " + new_datalist.last_name;
                    }

                    var App_date = "";
                    if (new_datalist.date != null) {
                      App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
                    }

                    this.med_pres_list.push({
                      doctorname: Docname,
                      date: App_date,
                      disease: new_datalist.disease,
                      pres_drug_id: this.med_pres_list_data[i].pres_drug_id,
                      docname_flag: this.docname_flag,
                      remarks: this.med_pres_list_data[i].note
                    });
                  }
                }
                this.med_pres_listdata = this.med_pres_list.length == 0 ? true : false;
              }
            },
            error => { }
          )
      }
    }
  }

  getmedicine() {
    this.medicineFlag = !this.medicineFlag;
  }

  setMedicine(id) {
    this.medicineFlag = false;
    console.log("mediceList" + JSON.stringify(this.mediceList))
    for (var i = 0; i < this.mediceList.length; i++) {
      if (this.mediceList[i].med_id == id) {
        this.medicine_nametxt = this.mediceList[i].med_name;
        this.drug_typetxt = this.mediceList[i].med_type_id;
        this.medtypetxt = this.mediceList[i].type_name;
        this.show_intake = this.mediceList[i].show_intake;
        this.short_name = this.mediceList[i].type_name;
        this.med_id = this.mediceList[i].med_id;

        this.short_form = this.mediceList[i].short_form;
        this.show = this.mediceList[i].show_short_form;
        this.genericname = this.mediceList[i].genericname;
        if (this.genericname != undefined) {
          if (Helper_Class.getInfo().both_modules.length != 0) {
            var Moduleidlist
            Moduleidlist = Helper_Class.getInfo().both_modules;
            for (var i = 0; i < Moduleidlist.length; i++) {
              if (Moduleidlist[i].module_id == "34") {
                this.medicalprescription = false;
                this.genericDisplayFlag = true;
              }
            }
          }

          this.ganericName = this.genericname
        }
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        //this.getIntakeSession();
        break;
      }
    }
  }

  showMedType(new_med) {
    this.medtypetxt = "";
    this.new_med = new_med

    if (this.new_med == true) {
      this.newmed_flag = false;

    } else {
      this.newmed_flag = true;
    }
  }

  print_Viewprescription() {
    if (this.next_datetxt == undefined) {
      if (this.next_datetxt_diag != undefined) {
        this.nextvisit_print = Date_Formate(this.next_datetxt_diag);
        this.nextvisitflag_print = true;
      } else {
        this.nextvisit_print = " ";
        this.nextvisitflag_print = true;
      }

    } else {
      if (this.next_datetxt != undefined) {
        this.nextvisit_print = Date_Formate(this.next_datetxt);
        this.nextvisitflag_print = true;
      } else {
        this.nextvisit_print = " ";
        this.nextvisitflag_print = true;
      }
    }
    console.log("this.personalinfo.AppFlow" + JSON.stringify(this.personalinfo))
    if (this.personalinfo.AppFlow != undefined) {
      if (this.personalinfo.AppFlow != "InpatMedPres" && this.personalinfo.AppFlow != "Inpatient") {
        var flag_check = false;
        if (this.listProducts.length != 0) {
          if (this.dis_txt == "") {
            flag_check = true;
            this.toastr.error("Enter Diagnosis")
          }
        }

        if (flag_check == false) {
          this.print_area();
        }
      }
      else if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
        this.print_area();
      }
      else {
        this.print_area();
      }

    } else {
      this.print_area();
    }

  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.presType = "Rx";
      this.medpres = true;
      this.diagpres = false;
      Doc_Helper.setDiagAppflow("medical")

    } else {
      this.ynopt1 = false;
      this.presType = "Dx";
      this.medpres = false;
      this.diagpres = true;
      Doc_Helper.setDiagAppflow("diag")
    }
  }

  checkNextVisit(value) {
    this.book_app_now = value;
  }

  fillType(e) {
    this.fill_type = e;
    var send_data;
    var sub_rel_id = null;
    if(this.personalinfo.sub_id != undefined){
      if (this.personalinfo.sub_id.length != 0) {
        sub_rel_id = this.personalinfo.sub_id;
      }  
    }
   
    var spl = Doc_Helper.getClient_Info().spl;

    if (this.fill_type == "refill") {
      send_data = {
        doc_reg_id: this.user_id,
        spl_id: spl,
        client_age: this.client_age,
        client_reg_id: this.personalinfo.Client_id,
        relation_id: this.personalinfo.rel_id,
        sub_rel_id: sub_rel_id
      }

      const dialogRef1 = this.dialog.open(MedPresPopupPageComponent, {
        width: '700px',
      });
      dialogRef1.afterClosed().subscribe(result => {
        console.log("MedPresPopupPageComponent" + JSON.stringify(result))
        if (result != null) {
          this.genericnameflag = true;
          this.listProducts = result;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
            this.saveflag = true;
            this.print_view_flag = false;
            this.printFlag = true;
          }
        }
      });
    } else if (this.fill_type == "autoRx") {
      this.getMedicineDetails();

    } else if (this.fill_type == "previous") {
      send_data = {
        client_id: this.personalinfo.Client_id,
        relation_id: this.personalinfo.rel_id,
        sub_rel_id: this.personalinfo.sub_id,
        spl_name: "General",
        doc_reg_id: this.user_id
      }
      const dialogRef2 = this.dialog.open(PreviousPrescriptionPopupComponent, {

        width: '950px',
      });

      dialogRef2.afterClosed().subscribe(result => {
        console.log("juiuhih" + JSON.stringify(result))
        if (result != null) {
          this.listProducts = result;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
            this.saveflag = true;
            this.printFlag = true;
            this.print_view_flag = false;
          }
        }
        this.Medicine_table = false;
      });
    }
  }

  select_symptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptoms_list.length; j++) {
      if (this.symptoms_list[j].description == data) {
        this.selected_symptoms.push(data);
        break;
      }
    }

    if (this.dis_txt.indexOf(',') > -1) {
      var dataval = this.dis_txt.split(",");
      dataval.pop();
      this.dis_txt = dataval.join(",");

      this.dis_txt = this.dis_txt + "," + data;
    } else {
      this.dis_txt = data;
    }
    this.symptomlist = [];
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.instructions = event.html;
    if (this.instructions != undefined) {
      this.print_instruction = this.sanitizer.bypassSecurityTrustHtml(this.instructions);
    }
  }

  onSelectionChangedchange = (event) => { }
  onContentChangedchange = (event) => {
    this.complaints = event.html;
    if (this.complaints != undefined) {
      this.print_complaints = this.sanitizer.bypassSecurityTrustHtml(this.complaints);
    }

  }
  changecomplaints = (event) => {
    this.complaints = event.html;
    if (this.complaints.length > 250) {
      this.complaints = this.complaints.substring(0, 250);
      console.log(`Content truncated to  characters.`);
      this.complaintsflag = true;
      this.print_complaints = this.complaints
    }
  };
  changediagnosis = (event) => {
    this.dis_txt = event.html;
    if (this.dis_txt.length > 250) {
      this.dis_txt = this.dis_txt.substring(0, 250);
      console.log(`Content truncated to  characters.`);
      this.diseaseflag = true;
      this.print_disease = this.dis_txt
    }
  };


  getMedicineDetails() {
    var agerange;
    if (this.client_age == "<1") {
      agerange = "<1";
    } else if (this.client_age == "1" || this.client_age == "2" || this.client_age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.client_age == "4" || this.client_age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.client_age == "6" || this.client_age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.client_age == "8" || this.client_age == "9" || this.client_age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.client_age == "11" || this.client_age == "12" || this.client_age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.client_age == "14" || this.client_age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.client_age == "16" || this.client_age == "17" || this.client_age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.client_age == "19" || this.client_age == "20" || this.client_age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

    var temp;
    if (Helper_Class.getTemparature() != undefined || Helper_Class.getTemparature() != null) {
      temp = Helper_Class.getTemparature();
    }

    var bp;
    if (this.clnt_bp != null && this.clnt_bp != "" && this.clnt_bp != undefined) {
      bp = this.clnt_bp;
    }

    var cgender;
    if (this.client_age != undefined && parseInt(this.client_age) > 10) {
      if (this.client_gender != undefined) {
        cgender = this.personalinfo.Gender_data;
      }
    }

    this.symptoms = Helper_Class.getsymptoms();
    if (this.symptoms != undefined) {
      var sym = this.symptoms;
    }
    if (temp != undefined) {
      var temp_value = temp
    }

    var genflag = true;
    if (cgender != undefined && (encrypt_decript.Decript(cgender) == "Male" && this.personalinfo.spl == "Gynecology")) {
      genflag = false;
    } else {
      genflag = true
    }

    var gender_val = encrypt_decript.Encript(cgender).toString()

    //  /  if(this.cgender !=null)
    var age = this.client_age != undefined && this.client_age != "" ? this.client_age : undefined;
    var ageRange = this.client_age != undefined && this.client_age != "" ? agerange : undefined;

    this.symptoms = this.getdata_value;
    if (genflag == true) {
      var send_data = {
        relation_id: this.personalinfo.rel_id,
        spl_name: this.personalinfo.spl,
        agerange: ageRange,
        gender: gender_val,
        symptoms: this.symptoms,
        bp: bp,
        temparature: temp,
        diagnosis: this.dis_txt,
        clntage: age,
      }
      console.log("genflag" + genflag)
      console.log("send_data send_data" + JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/gmpbycasv/', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.listProducts = [];
            if (obj != undefined && obj.pres_drug_id != undefined) {
              this.dis_txt = obj.disease;
              if (obj.drug_list.length != 0) {
                console.log("obj.drug_list check 00---->" + JSON.stringify(obj.drug_list))
                for (var i = 0; i < obj.drug_list.length; i++) {
                  if (obj.drug_list[i].show_short_form == "1") {
                    var shortform = obj.drug_list[i].short_form;
                    this.short_form = obj.drug_list[i].short_form;
                    var morning = " ", afternoon = " ", evening = " ", night = " "
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      // this.every_six =  obj.drug_list[i].morning + shortform  + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  + " - 0";
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + shortform;

                      // this.every_six = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;

                    } else if (obj.drug_list[i].intake == "4") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "5") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      this.param_four = false;

                    } else if (obj.drug_list[i].intake == "6") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "9") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 6 hours";

                    } else if (obj.drug_list[i].intake == "10") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 8 hours";

                    } else if (obj.drug_list[i].intake == "11") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 12 hours ";

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning + shortform + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning + shortform + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "15") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = true;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }

                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    var frequency;
                    if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" || obj.drug_list[i].intake == "Twice a week" || obj.drug_list[i].intake == "Once a month") {
                      frequency = this.intake_txt;
                    } else {
                      if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                        frequency = "";
                      } if (obj.drug_list[i].intake == "Stat" || obj.drug_list[i].intake == "SOS") {
                        frequency = " ";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      drug_name: obj.drug_list[i].drug_name,
                      // //genericname: this.ganericName,
                      genericname: genericname,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      // morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
                      // afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
                      // evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
                      // night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                      // morning: obj.drug_list[i].morning,
                      // afternoon: obj.drug_list[i].afternoon,
                      // evening: obj.drug_list[i].evening,
                      // night: obj.drug_list[i].night,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      short_name: obj.drug_list[i].short_name,
                      mixval: obj.drug_list[i].mixtype,
                      param_four: this.param_four
                    }


                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                  } else {
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "4") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "5") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "6") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "9") {
                      this.every_six = obj.drug_list[i].morning + " - Every 6 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "10") {
                      this.every_six = obj.drug_list[i].morning + " - Every 8 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "11") {
                      this.every_six = obj.drug_list[i].morning + " - Every 12 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;
                    } else if (obj.drug_list[i].intake == "15") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " Once a month";
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    else {
                      if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                        if (obj.drug_list[i].morning !== "0") {
                          morning = obj.drug_list[i].morning + " - ";
                        } else {
                          morning = obj.drug_list[i].morning + " - ";
                        }
                        if (obj.drug_list[i].afternoon !== "0") {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        } else {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        }
                        if (obj.drug_list[i].evening !== "0") {
                          evening = obj.drug_list[i].evening + " - ";
                        } else {
                          evening = obj.drug_list[i].evening + " - ";
                        }
                        if (obj.drug_list[i].night !== "0") {
                          night = obj.drug_list[i].night;
                        } else {
                          night = obj.drug_list[i].night;
                        }

                        this.every_six = morning + afternoon + evening + night;
                      } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                        if (obj.drug_list[i].morning !== "0") {
                          morning = obj.drug_list[i].morning + " - ";
                        } else {
                          morning = obj.drug_list[i].morning + " - ";
                        }
                        if (obj.drug_list[i].afternoon !== "0") {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        } else {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        }
                        if (obj.drug_list[i].evening !== "0") {
                          evening = obj.drug_list[i].evening + " - ";
                        } else {
                          evening = obj.drug_list[i].evening + " - ";
                        }
                        if (obj.drug_list[i].night !== "0") {
                          night = obj.drug_list[i].night_eventxt;
                        } else {
                          night = obj.drug_list[i].night;
                        }

                        this.every_six = morning + afternoon + evening + night;
                      }
                      this.param_four = false;
                    }

                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }
                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var frequency;
                    frequency = obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" ? this.intake_txt : "Daily";
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      genericname: genericname,
                      drug_name: obj.drug_list[i].drug_name,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                      // night: obj.drug_list[i].night,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      remarks: obj.drug_list[i].remarks,
                      short_name: obj.drug_list[i].short_name,
                      param_four: this.param_four
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                    console.log("cj check 00---->" + JSON.stringify(this.listProducts))
                  }
                }
                if(this.listProducts.length != 0){
                  this.saveflag = true;
                }else{
                  this.saveflag = false;
                }
                this.Medicine_table = false;
              }
            }
          });
    }
  }

  Pharma_medicine_name(item) {
    this.prfer_pharmatxt = item;

    if (item == this.Pharmanamelist) {
      for (var i = 0; i < this.Pharma_list_arry.length; i++) {
        this.pharma_id = this.Pharma_list_arry[i].pharma_id;
        if (this.Pharma_list_arry[i].telephone != undefined && this.Pharma_list_arry[i].telephone != 'undefined') {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country + " , " + this.Pharma_list_arry[i].telephone;
        } else {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country;
        }
      }
      this.Pharmanamelist = [];
    }
  }

  Prefer_pharmacy_change(e) {
    this.Pharmanamelist = [];
    this.Pharma_data = [];
    this.prfer_pharmatxt = e.target.value.toString();

    if (this.prfer_pharmatxt != undefined && this.prfer_pharmatxt.length >= 3) {
      this.prefer_add = "";

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharmabyname/',
        {
          location: this.doct_location,
          pharma_name: this.prfer_pharmatxt,
          drug_ids: this.drug_id_list,
          flag: "prescription",
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj.pharma_list != null) {
              this.Pharma_list_arry = obj.pharma_list;
              for (var i = 0; i < obj.pharma_list.length; i++) {
                this.Pharma_data.push(obj.pharma_list[i].pharmacy_name);
                this.pharma_id = obj.pharma_list[i].pharma_id;
              }

              Doc_Helper.setpharmaid(this.pharma_id)
              this.Pharmanamelist = this.Pharma_data.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.prfer_pharmatxt.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.toastr.error(Message_data.noPharmaFound);
            }
          },
          error => { }
        )
    } else {
      this.prefer_add = "";
    }
  }

  Duration() {
    var duration = this.day_txt + " " + this.daydur_txt;
    return duration
  }

  OnDateMatePicker(e) {
    this.next_datetxt = e;
    this.next_txt = Date_Formate(e);
    this.nextvisit = e;
    this.next_datetxt_diag = e;
    var check1 = moment(this.CurrentDatetime, 'YYYY-MM-DD')
    var check2 = moment(this.next_datetxt, 'YYYY-MM-DD')
    this.no_of_days = check2.diff(check1, 'days');
    // this.dateAfter3Days = this.next_txt.add(this.no_of_days, 'days');
  }
  OnDateMatePicker1(e) {
    // this.next_datetxt = e;
    // this.next_txt = Date_Formate(e);
    this.next_datetxt_diag = e;
    var check1 = moment(this.CurrentDatetime, 'YYYY-MM-DD')
    var check2 = moment(this.next_datetxt_diag, 'YYYY-MM-DD')
    this.no_of_days_diag = check2.diff(check1, 'days');
    // this.dateAfter3Days = this.next_txt.add(this.no_of_days, 'days');
  }

  Medicine_name_change(e, type) {
    console.log("check e value " + JSON.stringify(e))
    if (this.new_med != true) {
      if (e.target.value.toString() != null && e.target.value.length.toString() > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        console.log("check e value " + JSON.stringify({
          drug_category: this.userinfo.user_id,
          drug_name: e.target.value.toString(),
          type: type,
          pharmacy_id: this.pharma_id,
        }))
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/',
          {
            drug_category: this.userinfo.user_id,
            drug_name: e.target.value.toString(),
            type: type,
            pharmacy_id: this.pharma_id,
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              console.log("ressponse --" + JSON.stringify(obj))
              if (obj.med_details != null) {
                this.Medicinenamelist = [];
                this.Medicine_list = [];
                this.Medicne_data = [];

                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                  this.Medicne_data.push({ name: medname, generic: obj.med_details[i].generic_name, quantity: obj.med_details[i].quantity, drug_id: obj.med_details[i].med_id });

                  //  this.Medicne_data.push({ name: medname, generic: obj.med_details[i].generic_name });
                  this.Medicine_list.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname
                  })
                }

                this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
                  return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
                }.bind(this));

                this.Med_addbtn = true;
              }
            },
            error => {

            }
          )
      } else {
        this.Medicinenamelist = [];
      }

    } else {
      if (this.medtypetxt == "" || this.medtypetxt == undefined) {
        this.toastr.error(Message_data.enterMedType);
      }
      this.med_id = "0";
      this.Med_addbtn = true;
    }
  }

  select_medicine_item(item) {
    console.log("check item --" + JSON.stringify(item))
    console.log("this.viewpreslist_array-" + JSON.stringify(this.viewpreslist_array))
    console.log("viewpreslist_array" + JSON.stringify(this.viewpreslist_array))
    if (this.personalinfo.AppFlow == "Inpatient" || this.personalinfo.AppFlow == "InpatMedPres") {
      if (this.intake_txt != "SOS") {
        var matchingPrescription = this.viewpreslist_array.find((prescription) => {
          return prescription.medicines.some((medicine) => {
            return medicine.drug_id === item.drug_id.toString();
          });
        });
      }
      else {
        for (var i = 0; i < this.Medicine_list.length; i++) {
          if (this.Medicine_list[i].medname == item.name) {
            this.medicine_nametxt = this.Medicine_list[i].med_name;
            this.drug_typetxt = this.Medicine_list[i].med_type_id;
            this.medtypetxt = this.Medicine_list[i].type_name;
            this.ganericName = item.generic;
            this.short_form = this.Medicine_list[i].short_form;
            this.show = this.Medicine_list[i].show_short_form;
            this.show_intake = this.Medicine_list[i].show_intake;
            this.med_id = this.Medicine_list[i].med_id;
            this.short_name = this.Medicine_list[i].short_name;
            if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
              this.mix_show = false;
            } else {
              this.mix_show = true;
            }
          }
        }
        // this.sesssion_basechange();
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        this.Medicne_data = [];
        this.Medicinenamelist = [];
      }
      console.log("viewpreslist_array" + JSON.stringify(matchingPrescription))


      if (matchingPrescription) {
        this.toastr.error("Already prescriped medicine");
        this.medicine_nametxt = ""
        item = ""
        console.log("Matching Prescription found:", matchingPrescription);
      } else {
        for (var i = 0; i < this.Medicine_list.length; i++) {
          if (this.Medicine_list[i].medname == item.name) {
            this.medicine_nametxt = this.Medicine_list[i].med_name;
            this.drug_typetxt = this.Medicine_list[i].med_type_id;
            this.medtypetxt = this.Medicine_list[i].type_name;
            this.ganericName = item.generic;
            this.short_form = this.Medicine_list[i].short_form;
            this.show = this.Medicine_list[i].show_short_form;
            this.show_intake = this.Medicine_list[i].show_intake;
            this.med_id = this.Medicine_list[i].med_id;
            this.short_name = this.Medicine_list[i].short_name;
            if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
              this.mix_show = false;
            } else {
              this.mix_show = true;
            }
          }
        }
        // this.sesssion_basechange();
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }

        this.Medicne_data = [];
        this.Medicinenamelist = [];
      }

    } else {
      for (var i = 0; i < this.Medicine_list.length; i++) {
        if (this.Medicine_list[i].medname == item.name) {
          this.medicine_nametxt = this.Medicine_list[i].med_name;
          this.drug_typetxt = this.Medicine_list[i].med_type_id;
          this.medtypetxt = this.Medicine_list[i].type_name;
          this.ganericName = item.generic;
          this.short_form = this.Medicine_list[i].short_form;
          this.show = this.Medicine_list[i].show_short_form;
          this.show_intake = this.Medicine_list[i].show_intake;
          this.med_id = this.Medicine_list[i].med_id;
          this.short_name = this.Medicine_list[i].short_name;
          if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
            this.mix_show = false;
          } else {
            this.mix_show = true;
          }
        }
      }
      // this.sesssion_basechange();
      if (this.show_intake == "1" || this.show_intake == 1) {
        this.getIntakeSession();
        this.intakesession = false
      } else {
        this.intakesession = true;
        this.quan_lable = false;
        this.morning_dis = false;
        this.mor_id = true;
        this.after_id = true;
        this.eve_id = true;
        this.intake_show = true;
        this.dure_show = true;

        this.eve_eventxt = true;
        this.night_eventxt = true;
        this.daydur_show = true;

        this.mor_lable = true;
        this.after_eventxt = true;
        this.morning_req = true;
        this.morn_eventxt = false;
      }
      this.Medicne_data = [];
      this.Medicinenamelist = [];
    }
    this.Medicne_data = [];
    this.Medicinenamelist = [];
    console.log("cjeckmjnjk" + this.show_intake + "this.showthis.showthis.show" + this.show)

  }

  getIntakeSession() {
    var send_data = {
      product_id: this.med_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          for (var i = 0; i < this.Get_Intake_array.length; i++) {
            if (obj.intake == this.Get_Intake_array[i].drug_int_id) {
              this.intake_txt = this.Get_Intake_array[i].description
              this.intake_id = this.Get_Intake_array[i].drug_int_id;
            }
          }
          this.sesssion_basechange();

        })
  }

  Delete_medicalpres(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.Medicine_table = false;
      this.print_medidiag = true;
    }
    else {
      this.Medicine_table = true;
      this.print_medidiag = false;
      this.saveflag = false;
      this.printFlag = false;
      this.print_view_flag = true;
    }
  }

  editMedicalPres(productid, rowid, drugname, genericname, intake) {
    this.drug_typetxt = "";
    console.log("this.productid" + JSON.stringify(productid))
    console.log("this.productid" + JSON.stringify(this.listProducts))
    console.log("this.Get_Intake_array" + JSON.stringify(this.Get_Intake_array))

    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].drug_id == productid && this.listProducts[i].drug_name == drugname && this.listProducts[i].intake == intake) {
        this.rowid = rowid;
        console.log("this.productid" + JSON.stringify(this.listProducts))

        this.short_name = this.listProducts[i].short_name;
        this.medtypetxt = this.listProducts[i].med_typetxt;
        this.medicine_nametxt = this.listProducts[i].drug_name;
        if (this.listProducts[i].genericname != undefined) {
          this.ganericName = this.listProducts[i].genericname
        }
        this.med_id = this.listProducts[i].drug_id;

        var dayvale = this.listProducts[i].days.split(' ');
        console.log("this.productid days" + JSON.stringify(this.listProducts[i].days))

        this.day_txt = this.listProducts[i].days;

        if (this.listProducts[i].period == "day" || this.listProducts[i].period == "days") {
          this.daydur_txt = "day(s)";

        } else if (this.listProducts[i].period == "week" || this.listProducts[i].period == "weeks") {
          this.daydur_txt = "week(s)";

        } else if (this.listProducts[i].period == "month" || this.listProducts[i].period == "month") {
          this.daydur_txt = "month(s)";

        } else {
          this.daydur_txt = "day(s)";
        }

        this.afterfood_txt = this.listProducts[i].drug_intake;
        var mintxt = this.listProducts[i].dure_txt_table.split(' ');
        this.dure_txt = mintxt[0];
        if (this.dure_txt != undefined) {
          this.dure_show = false;
        }

        this.mixing_txt = this.listProducts[i].mixval;
        if (this.listProducts[i].mixval != undefined) {
          this.mix_show = false;
        } else {
          this.mix_show = true;
        }

        this.intake_id = this.listProducts[i].intake;
        this.remark_txt = this.listProducts[i].remarks;

        if (this.intake_id == 8) {
          this.quan_lable = false;
          this.mor_lable = true;

        } else {
          this.quan_lable = true;
          this.mor_lable = false;
        }

        this.mor = this.listProducts[i].morning;
        this.aft = this.listProducts[i].afternoon;
        this.eve = this.listProducts[i].evening;
        this.ngt = this.listProducts[i].night;
        this.Med_addbtn = true;

        if (this.listProducts[i].show_intake != undefined) {
          this.show_intake = this.listProducts[i].show_intake;
        }

        if (this.listProducts[i].short_form != undefined) {
          this.short_form = this.listProducts[i].short_form;
        }

        this.listProducts.splice(i, 1);
        this.getMedtypeForEdit();

        break;
      }
    }
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_id == this.Get_Intake_array[i].drug_int_id) {
        this.intake_txt = this.Get_Intake_array[i].description;
        this.drug_typetxt = this.Get_Intake_array[i].drug_type_id
        console.log("this.intake_txt" + this.intake_txt)
        this.sesssion_basechange();
      }
    }

    if (this.listProducts.length != 0) {
      this.Medicine_table = false;

    } else {
      this.Medicine_table = true;
      this.saveflag = false;
      this.printFlag = false;
      this.print_view_flag = true;
    }
  }

  getMedtypeForEdit() {
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypesedit/',
        {
          medicare_id: this.Medicare_name,
          med_name: this.medtypetxt,
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.Medical_prescription_array = [];
            this.drug_typetxt = obj.med_code;
            this.short_form = obj.short_form;
            this.show = obj.show_short_form;
            this.show_intake = obj.show_intake;
            this.intake_change();
          },
          error => {

          }
        )
    }
  }

  intake_change = function (this: any) {
    if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
      this.dure_show = false;
      this.dure_require = true;
      this.dure_txt = "30";
    } else {
      this.dure_show = true;
      this.dure_require = false;
      this.dure_txt = undefined;
    }
  }

  sesssion_basechange() {

    if (this.show_intake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }
    console.log("sesion bmbm" + this.intake_txt + this.show_intake + this.drug_typetxt)

    if (this.intake_txt == "Morning only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }

      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.afternoon_dis = false;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Evening only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Night only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.afternoon_dis = true;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Afternoon" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning,Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Thrice a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Four times a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if ((this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
      || this.intake_txt == "Every 12 hours") && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Stat") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;
      this.intake_show = true;
      this.dure_show = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = true;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.morn_eventxt = false;
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = "";
      this.afterfood_txt = ""

    } else if (this.intake_txt == "Once a day") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;
      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "SOS") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }

      } else {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      }
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = "";
      this.afterfood_txt = ""
      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoon_dis = false;
      this.morning_dis = false;
      this.mor_id = false;
      this.mor_lable = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.even_dis = false;
      this.ngt_dis = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.evening_req = true;
      this.afternoon_req = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }
    if (this.intake_txt != "Stat") {
      this.daydur_txt = "day(s)"
      this.afterfood_txt = "After food";
      this.dure_txt = "30";
    }
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_txt == this.Get_Intake_array[i].description) {
        // this.intake_txt = this.Get_Intake_array[i].description
        this.intake_id = this.Get_Intake_array[i].drug_int_id;
        break;
      }
    }
    this.intake_change();
  }

  noMedSelected() {
    if (this.no_med == true) {
      this.saveflag = true;
      this.printFlag = true;
      this.print_view_flag = false;
      this.Med_addbtn = false;

    } else {
      this.saveflag = false;
      this.printFlag = false;
      this.print_view_flag = true;
      this.Med_addbtn = true;
    }
  }
  public found

  Medicalpres_add() {
    if (this.ganericName != undefined) {
      this.genericnameflag = true;
    }
    console.log("Medical_prescription_array" + JSON.stringify(this.listProducts))
    console.log("Medical_prescription_array" + JSON.stringify(this.med_id))

    console.log("Medical_prescription_array" + JSON.stringify(this.Medical_prescription_array))
    var flag = false;
    if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
      flag = false;
    } else {
      if (this.intake_txt != "Stat" && this.intake_txt != "SOS" && (this.day_txt == undefined || this.day_txt == "" || this.day_txt == " ")) {
        this.toastr.error(Message_data.enterNoOfDays);
        flag = true;
      }

    }
    this.found = this.listProducts.some(item => item.drug_id === this.med_id && item.intake === this.intake_id);

    if (this.new_med == true) {
      if (this.medtypetxt == undefined || this.medtypetxt == "" || this.medtypetxt == " ") {
        this.toastr.error(Message_data.enterMedType);
        flag = true;
        this.new_med = true;
        this.newmed_flag = false;
      }
    }

    if (this.medicine_nametxt == undefined || this.medicine_nametxt == "") {
      this.toastr.error(Message_data.enterMedName);
      flag = true;
    } else if (this.intake_txt == undefined) {
      this.toastr.error(Message_data.enterIntkSess);
      flag = true;
    }
    if (this.found == true) {
      this.toastr.error("Already prescriped medicine");
      flag = true;
    }

    if (this.show_intake == "1") {
      if (this.intake_txt != undefined && this.intake_txt == "Morning only" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening only" && (this.eve == undefined || this.eve == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Night only" && (this.ngt == undefined || this.ngt == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Afternoon" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Evening" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon,Evening & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon,Evening & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Thrice a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Four times a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
        || this.intake_txt == "Every 12 hours" || this.intake_txt == "Once a day" || this.intake_txt == "Stat")
        && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt == "Stat" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
        || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") && (this.mor == "0" && this.aft == "0" && this.eve == "0" && this.ngt == "0")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      }
    }


    if (flag == false) {
      this.saveflag = true;
      this.printFlag = true;
      this.print_view_flag = false;

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.medtypetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      for (var i = 0; i < this.Get_Intake_array.length; i++) {
        if (this.intake_txt == this.Get_Intake_array[i].description) {
          this.intake_id = this.Get_Intake_array[i].drug_int_id;
        }
      }

      this.Medicine_table = false;
      if (this.med_id == null) {
        this.med_id = "0";
      }

      var aftfood;
      if (this.show == "1") {
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.every_six = this.intake_txt;
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";
          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            // this.every_six = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt + " - per month";

            // this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          }

          else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        console.log("seeeeeeeeedure_txteeeeeee" + this.dure_txt)

        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        if (this.afterfood_txt == undefined) {
          this.afterfood_txt = "";
        }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              frequency = "Daily";
            }
          }
        }

        if (this.intake_txt == "Stat") {
          period = "";
          this.day_txt = "";
        } else {
          var period
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";

          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";

          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";

          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";

          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";

          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            period = "";
          }
        }

        if (this.intake_txt != "Stat" && (this.afterfood_txt != undefined && this.afterfood_txt != 'Select')) {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }

        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (this.day_txt != "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt,
              param_four: this.param_four
            });

          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              // days: this.day_txt,
              // period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt, param_four: this.param_four
            });
          }
        }
        else {
          this.listProducts.push({
            med_typetxt: this.medtypetxt,
            drug_name: this.medicine_nametxt,
            genericname: this.ganericName,
            days: this.day_txt,
            period: period,
            drug_intake: aftfood,
            intake: this.intake_id,
            every_six: this.every_six,
            dure_txt_table: this.dure_write,
            time_duration: this.dure_txt,
            morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
            afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
            evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
            night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
            morning: this.mor,
            afternoon: this.aft,
            evening: this.eve,
            night: this.ngt,
            drug_id: this.med_id,
            drug_type_id: this.drug_typetxt,
            remarks: this.remark_txt,
            frequency: frequency,
            show_intake: this.show_intake,
            rowid: this.rowid,
            short_name: this.short_name,
            mixval: this.mixing_txt, param_four: this.param_four
          });
        }
      } else {
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.every_six = this.intake_txt;
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";

          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            //  this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          }

          else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        // if(this.intake_txt == "SOS"){
        //   alert(this.day_txt)
        //   if(this.day_txt != " " ){

        //   }else{

        //   }
        // }
        // if (this.dure_txt != undefined && this.dure_txt != "") {
        //   this.dure_write = this.dure_txt + " " + "mins";
        // } else {
        //   this.dure_write = "";
        //   this.dure_flag = true;
        // }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              frequency = "Daily";
            }
          }
        }

        var period
        if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
          period = "day";
        } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
          period = "days";
        } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
          period = "week";
        } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
          period = "weeks";
        } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
          period = "month";
        } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
          period = "months";
        } else {
          period = "";
        }

        if (this.afterfood_txt != undefined && this.afterfood_txt != 'Select') {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }
        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              // days: this.day_txt,
              // period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four
            });
          }

        } else {
          // alert(this.day_txt)
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              // days: this.day_txt,
              // period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four
            });
          }
        }
      }
      console.log("list product::::::" + JSON.stringify(this.listProducts))
      //Resets the form 
      this.med_typetxt = "";
      this.medicine_nametxt = "";
      this.short_name = "";
      this.short_form = "";
      this.newmed_flag = true;
      this.new_med = false;
      this.print_med = true;
      this.mixing_txt = "";
      this.ganericName = "";
      this.day_txt = ""
      // this.dure_txt=""
      //}
      this.medicineaddbtn = false; // Add button
      console.log("MED LIST " + JSON.stringify(this.listProducts))
    }

    var flag: boolean;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }
    if (flag == true && this.listProducts.length != 0) {
      this.print_medidiag = true;
    } else {
      this.print_medidiag = false;
    }

  }
  //Get medicine type
  Get_medicine_type(e) {
    this.medtypetxt = e.target.value.toString();
    this.Medical_prescription_array = [];
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        {
          medicare_id: this.Medicare_name,
          medicine_name: this.medtypetxt
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var medtypetxt1 = [];
            this.Medical_prescription_array = obj.med_details;
          },
          error => {
          }
        )
    }
  }

  Medcine_type_change(l) {
    for (var i = 0; i < this.Medical_prescription_array.length; i++) {
      if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
        this.drug_typetxt = this.Medical_prescription_array[i].med_code;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/meddetails/',
      {
        drug_type: this.drug_typetxt,
        drug_category: this.user_id,
        drug_name: this.medicine_nametxt,
        pharmacy_id: this.pharma_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.med_details != null) {
            this.Medicine_list = obj.med_details;
            for (var i = 0; i < obj.med_details.length; i++) {
              this.Medicne_data.push(obj.med_details[i].med_name);
            }
            this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
            }.bind(this));
            this.Med_addbtn = true;
          }
        },
        error => { })
  }

  clearmedtype() {
    this.medtypetxt = null;
    this.drug_typetxt = undefined;
    this.medicine_nametxt = null;
    this.Medicinenamelist = [];
    this.Medicne_data = [];
  }

  select_medicine_type(item) {
    console.log("select med type---" + JSON.stringify(item))
    this.medtypetxt = item.med_name;
    this.drug_typetxt = item.med_code;
    this.short_form = item.short_form;
    this.short_name = item.short_form;
    this.show = item.show_short_form;
    this.show_intake = item.show_intake;

    this.intakesession = false;
    this.Medical_prescription_array = [];
    this.sesssion_basechange();


  }

  getSymptoms() {
    var searchString;
    if (this.dis_txt.indexOf(',') > -1) {
      var dataval = this.dis_txt.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.dis_txt;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.symptoms_list = [];
              this.symptomlist = [];
              this.symptom_data = [];
              this.symptoms_list = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptom_data.push(obj.symptoms[i].description);
              }

              this.symptomlist = this.symptom_data.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));

            }
          },
          error => {

          })
    }
  }

  backtoApplist() {
    console.log("fllow --" + JSON.stringify(Doc_Helper.getClient_Info().AppFlow))
    console.log("fllow --" + JSON.stringify(Doc_Helper.getClient_Info()))
    console.log("fllow --" + JSON.stringify(Helper_Class.getInpatientFlow()))
    console.log("fllow --" + JSON.stringify(this.personalinfo))

    if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
      if (Helper_Class.getInpatientFlow() != "nurse") {
        if (Helper_Class.getassflow() != undefined) {
          if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
            this.naviagationService.sendMessage("docassessment");
          }
        } else {
          this.naviagationService.sendMessage("Patients");
        }
      } else {
        // alert("OK")
        if (Helper_Class.getInpatientFlow() == "nurse") {
          this.nurseservice.sendMessage("viewIpatient");
        }
      }


      if (Helper_Class.getInpatientFlow() == "InpatMedPres") {
        // alert("OK1")
        this.naviagationService.sendMessage("inPatientViewInfo");
      }
      else {
        if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
          //  alert("OK2222")
          this.nurseservice.sendMessage("viewIpatient");
        } else {
          this.naviagationService.sendMessage("inPatientViewInfo");
        }
      }
    }
    else if (Helper_Class.getassflow() != undefined) {
      if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
        this.naviagationService.sendMessage("docassessment");
      }
    }
    else if (this.personalinfo != undefined) {
      if (this.personalinfo.AppFlow == "Doc_app_list") {
        this.naviagationService.sendMessage("appointment_list");
      }
      else if (this.personalinfo.AppFlow == "Nurseoutpatient") {
        this.nurseservice.sendMessage('appointment');
       }
    }
    else if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis") {
      this.naviagationService.sendMessage("inPatientDS");
    }

    else if (Helper_Class.getInpatientFlow() == "nurse") {
     // alert("ok")
      if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.naviagationService.sendMessage("viewIpatient");
      }
      else if (this.personalinfo.AppFlow == "Nurseoutpatient") {
      //  alert("ok5")
        this.nurseservice.sendMessage('appointment');
       }
      
      else {
        this.naviagationService.sendMessage('Patients');
      }

    }else if (Doc_Helper.getClient_Info().AppFlow == "Nurseoutpatient") {
     // alert("ok3")
      this.nurseservice.sendMessage('appointment');
    } 
    else if (Helper_Class.getInpatientFlow() == "Nurseoutpatient" || Doc_Helper.getClient_Info().AppFlow == "Nurseoutpatient") {
     // alert("ok8")
      this.nurseservice.sendMessage('appointment');
    } 
    else {
      this.nurseservice.sendMessage("appointment_list");
    }
  }

  print_area() {
    this.printtime = moment().format("h:mm A");
    this.savePrescription('print')

    if (this.listProducts.length != 0) {
      this.med_print_flag = true;
    }
    if (this.labarray.length != 0) {
      this.diag_print_flag = true;
    }
    if (this.radioarray.length != 0) {
      this.diag_print_flag = true;
    }
    if (this.selected_profiles.length != 0) {
      this.diag_print_flag = true;
    }
    var flag = true;
    if (Doc_Helper.getDiagAppflow() == "diag") {
      if (this.subtestuname.length != 0) {
        flag = true;

      } else if (this.subtestfname.length != 0) {
        flag = true;

      } else if (this.subtestname.length != 0) {
        flag = true;

      } else if (this.subtestsname.length != 0) {
        flag = true;

      } else if (this.subtestxname.length != 0) {
        flag = true;

      } else if (this.subtesturname.length != 0) {
        flag = true;

      } else if (this.subtestbname.length != 0) {
        flag = true;

      } else if (this.subtestcname.length != 0) {
        flag = true;

      } else {
        flag = false;
      }

      if (flag == false) {
        this.toastr.error(Message_data.enterDiagpres);
      }
    }

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }
    if (flag == true) {
      if (Helper_Class.get_med_pres_id() == undefined) {       
      }
      var footerimg = this.hosp_footer;
      var footerflag = this.printf_flag;
      var backimg = this.bg_image;
      let printContents, popupWin;

      if (this.print_template != undefined && this.print_template == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "bannerfullpage") {
        printContents = this.printbannerfullpage.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      }
      else {
        printContents = this.printnoheader.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">

        <style>
          @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .block1_grid{
            margin: 7px auto;
            width: 90%;
          }
          .block1{
            margin:3px 0;
          }
          .test-names-container {
            display: flex;
            flex-wrap: wrap;
          }
          .rowformat{
            display: flex;
            flex-wrap: wrap;
          }
          table td{
            font-size:11px !important;
          }
          table th{
            font-size:12px !important;
          }
          .test-name {
            margin-right: 10px; /* Adjust spacing between test names */
            white-space: nowrap; /* Prevent wrapping before reaching the width limit */
          }
        </style>
      </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
      );
      popupWin.document.close();
      printContents = "";
      if (this.print_view_flag == false) {
        this.next_txt = "";
        this.instructions = "";
        this.print_instruction = "";
        this.oberse_med = "";
        this.disease_name = "";
        this.disease_flag = false;
        //this.spiltarea('default');
        this.med_view_list = false;
        this.print_diag = false;
        this.Blood_test = [];
        this.sercah_center = "";
        this.obs_add_diag = "";
        this.subtestbname = [];
        this.subtestfname = [];
        this.subtestname = [];
        this.subtestname = [];
        this.subtestsname = [];
        this.subtestuname = [];
        this.subtesturname = [];
        this.subtestxname = [];
        this.subtestcname = [];
      } else {
        this.next_txt = "";
        this.instructions = "";
        this.oberse_med = "";
        this.print_instruction = "";
        this.print_diag = false;
      }
      this.print_view_flag = true;
      //this.listProducts = [];
      //this.appGetdate();
    }
  }

  savePrescription(data) {
    if (this.complaints != undefined && this.complaints != "") {
      this.complaintsflag = true;
      this.print_complaints = this.complaints
    }
    if (this.dis_txt != undefined && this.dis_txt != "") {
      this.diseaseflag = true;
      this.print_disease = this.dis_txt
    }
    if (this.instructions != undefined && this.instructions != "") {
      this.instructionsflag = true;
      this.print_instruction = this.instructions
    }
    if (this.next_datetxt != "") {
      this.nextvisitflag = true;
      this.next_txt = this.next_datetxt
    }
    if (data == "print") {
      if (this.listProducts.length != 0) {
        if(Helper_Class.get_med_pres_id() == null){
          this.Medical_prescription_save(null);
          this.printpres = "1";
        }
      
      } else {
        if(Helper_Class.get_diag_pres_id() == null){
        this.Diagnosis_prescription_save();
        this.printpres = "1";
        }
      }

    } else {
      this.printpres = "0";
      if (this.print_template != undefined && this.print_template == "banner") {
        this.bannerTemplateToPdf();

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        this.printlogowithnamepdf();

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        //printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        this.printnoheaderdf();
      }
    }
  }

  bannerTemplateToPdf() {
    //margin:[left, top, right, bottom]
    var tempArr: any = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'Date: ' + this.Appoint_Date],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, 'BP: ' + this.clnt_bp + '     Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'

        },
        {
          text: 'Complaints: ' + this.complaints,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        // this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  printlogowithnamepdf() {
    var tempArr: any = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }
    if (this.dct_hospital == undefined) {
      this.dct_hospital = "";
    }
    if (this.doct_address == undefined) {
      this.doct_address = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [60, 600],
            body: [
              [
                {
                  rowspan: 4,
                  image: this.imagestring,
                  width: 50,
                  height: 50,
                },
                [
                  {
                    table: {
                      widths: [310, 250],
                      style: 'personaltable',
                      body: [
                        [this.dct_hospital, 'Doctor: Dr.' + this.doct_name],
                        [this.doct_address + " " + this.doct_location, 'Date: ' + this.Appoint_Date],
                        [this.doct_city, ' '],
                        [this.doct_state, ' '],
                      ]
                    },
                    layout: 'noBorders'
                  }
                ],
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Complaints: ' + this.complaints,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        // {
        //   text: 'Diagnosis: ' + this.dis_txt,
        //   margin: [0, 20, 0, 20],
        //   style: 'diagstyle'
        // },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);

    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        //  this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  printnoheaderdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          margin: [0, 150, 0, 0],
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Complaints: ' + this.complaints,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        // {
        //   text: 'Diagnosis: ' + this.dis_txt,
        //   margin: [0, 20, 0, 20],
        //   style: 'diagstyle'
        // },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        //   this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  medicinetable(data, columns) {
    return {
      table: {
        widths: [140, 60, 50, 120, 100],
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push(columns);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column]);
      })
      body.push(dataRow);
    });
    return body;
  }

  saveData() {
    if (this.no_med == false && this.listProducts.length != 0) {
      this.Medical_prescription_save(null);
    } else if (this.no_med == true && this.listProducts.length == 0) {
      this.Medical_prescription_save(null);
    } else {
      this.Diagnosis_prescription_save();
    }
  }

  Medical_prescription_save(pdffilevalue) {
    // Doc_Helper.getDiagAppflow()   
    var flag = true;
    if (this.personalinfo.AppFlow != "InpatMedPres") {
      if (Doc_Helper.getDiagAppflow() == "medical") {
        if (this.dis_txt == "") {
          this.toastr.error(Message_data.enterDisease);
          flag = false;
        }
        if (this.no_med == false && this.listProducts.length == 0) {
          this.toastr.error(Message_data.addMedDetails);
          flag = false;
        }
      }
    } else {
      if (this.no_med == false && this.listProducts.length == 0) {
        this.toastr.error(Message_data.addMedDetails);
        flag = false;
      }
      if (this.book_app_now == true) {
        if (this.no_of_days == undefined && this.no_of_days == " ")
          this.toastr.error("select next visit date");
        flag = false;

      }
    }



    var ordernow = this.order_now == true ? "1" : 0;

    if (this.dis_txt.trim() != "") {
      var new_symptoms = [];
      if (this.dis_txt.indexOf(',') > -1) {
        var dataval = this.dis_txt.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newsymptoms.length != 0) {
            for (var j = 0; j < this.newsymptoms.length; j++) {
              if (dataval[i] != "") {
                if (this.newsymptoms[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          } else {
            if (dataval[i] != "") {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        }

      } else {
        if (this.newsymptoms.length != 0) {
          for (var j = 0; j < this.newsymptoms.length; j++) {
            if (this.newsymptoms[j] != this.dis_txt.trim()) {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        } else {
          new_symptoms.push(this.dis_txt.trim());
        }
      }

    } else {
      this.dis_txt = "";
    }

    if (flag == true) {
      this.saveflag = false;
      var sen_pass;
      var pres = this.presID != undefined ? this.presID : undefined;

      var splid;
      if (this.personalinfo != undefined) {
        splid = this.personalinfo.spl_id;
      } else {
        splid = Helper_Class.getspl_id();
      }
      var medid;
      if (this.userinfo.medicares != undefined) {
        medid = this.userinfo.medicares[0].medicare_id;

      } else {
        medid = "1";
      }
      var nomed;
      if (this.no_med == true) {
        nomed = "1";
      } else {
        nomed = "0";
      }

      var nextdate;
      if (this.next_datetxt != undefined) {
        nextdate = this.next_datetxt;
      } else {
        nextdate = this.next_datetxt1
      }
      if (this.personalinfo.sub_id) {
        if (pdffilevalue != null) {
          sen_pass = JSON.stringify({
            client_reg_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            sub_rel_id: this.personalinfo.sub_id,
            appointment_id: this.personalinfo.app_id,
            date: this.CurrentDatetime,
            disease: this.dis_txt,
            investigation: this.oberse_med,
            instructions: this.instructions,
            next_visit: this.nextvisit,
            medicines: this.listProducts,
            pharmacy_id: this.pharma_id,
            order_now: ordernow,
            language: this.language,
            pdfdoc: pdffilevalue,
            pdfip: ipaddress.Ip_with_img_address,
            print: this.printpres,
            newsymptoms: new_symptoms,
            mobile: this.personalinfo.mobile,
            doc_reg_id: this.user_id,
            pres_drug_id: pres,
            book_now: this.book_app_now,
            hptl_clinic_id: this.hospital_clinic_id,
            time: "1",
            medicare_id: medid,
            specialization_id: splid,
            gender: this.client_gender,
            mem_mobile: this.personalinfo.mobile,
            req_estimate: this.req_estimate,
            country: ipaddress.country_code,
            no_med: nomed, complaints: this.complaints,
          });

        } else {
          sen_pass = JSON.stringify({
            client_reg_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            sub_rel_id: this.personalinfo.sub_id,
            appointment_id: this.personalinfo.app_id,
            date: this.CurrentDatetime,
            disease: this.dis_txt,
            investigation: this.oberse_med,
            instructions: this.instructions,
            next_visit: this.nextvisit,
            medicines: this.listProducts,
            pharmacy_id: this.pharma_id,
            order_now: ordernow,
            language: this.language,
            // pdfdoc: pdffilevalue,
            pdfip: ipaddress.Ip_with_img_address,
            print: this.printpres,
            newsymptoms: new_symptoms,
            mobile: this.personalinfo.mobile,
            doc_reg_id: this.user_id,
            pres_drug_id: pres,
            book_now: this.book_app_now,
            hptl_clinic_id: this.hospital_clinic_id,
            time: "1",
            medicare_id: medid,
            specialization_id: splid,
            gender: this.client_gender,
            mem_mobile: this.personalinfo.mobile,
            req_estimate: this.req_estimate,
            country: ipaddress.country_code,
            no_med: nomed, complaints: this.complaints,
          });
        }

      } else {
        if (pdffilevalue != null) {
          sen_pass = JSON.stringify({
            client_reg_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            appointment_id: this.personalinfo.app_id,
            date: this.CurrentDatetime,
            disease: this.dis_txt,
            comment: this.oberse_med,
            investigation: this.oberse_med,
            instructions: this.instructions,
            next_visit: this.nextvisit,
            medicines: this.listProducts,
            pharmacy_id: this.pharma_id,
            order_now: ordernow,
            language: this.language,
            pdfdoc: pdffilevalue,
            pdfip: ipaddress.Ip_with_img_address,
            print: this.printpres,
            newsymptoms: new_symptoms,
            mobile: this.personalinfo.mobile,
            doc_reg_id: this.user_id,
            pres_drug_id: pres,
            book_now: this.book_app_now,
            hptl_clinic_id: this.hospital_clinic_id,
            time: "1",
            medicare_id: medid,
            specialization_id: splid,
            gender: this.client_gender,
            mem_mobile: this.personalinfo.mobile,
            req_estimate: this.req_estimate,
            country: ipaddress.country_code,
            no_med: nomed, complaints: this.complaints,
          });

        } else {
          sen_pass = JSON.stringify({
            client_reg_id: this.personalinfo.Client_id,
            relation_id: this.personalinfo.rel_id,
            appointment_id: this.personalinfo.app_id,
            date: this.CurrentDatetime,
            disease: this.dis_txt,
            comment: this.oberse_med,
            investigation: this.oberse_med,
            instructions: this.instructions,
            next_visit: this.nextvisit,
            medicines: this.listProducts,
            pharmacy_id: this.pharma_id,
            order_now: ordernow,
            language: this.language,
            pdfip: ipaddress.Ip_with_img_address,
            print: this.printpres,
            newsymptoms: new_symptoms,
            mobile: this.personalinfo.mobile,
            doc_reg_id: this.user_id,
            pres_drug_id: pres,
            book_now: this.book_app_now,
            hptl_clinic_id: this.hospital_clinic_id,
            time: "1",
            medicare_id: medid,
            specialization_id: splid,
            gender: this.client_gender,
            mem_mobile: this.personalinfo.mobile,
            req_estimate: this.req_estimate,
            country: ipaddress.country_code,
            no_med: nomed, complaints: this.complaints,
          });
        }
      }
      console.log("save pres--" + JSON.stringify(sen_pass))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            Helper_Class.set_med_pres_id(obj["pres_id"]);
            console.log("diag --" + JSON.stringify(Helper_Class.get_diag_pres_id()))
            console.log("diag --" + JSON.stringify(Helper_Class.get_med_pres_id()))
            Doc_Helper.setMedForDischarge(this.listProducts);
            this.print_med = true;
            console.log("obj MEDICAL--" + JSON.stringify(obj))
            console.log("this.personalinfo.discharge_id--" + JSON.stringify(this.personalinfo))
            console.log("this.personalinfo.discharge_id--" + JSON.stringify(this.personalinfo.discharge_id))            
            //var flag_diag = true;
            this.pageflag = "medical";
            if (this.personalinfo.discharge_id != "" && this.personalinfo.discharge_id != undefined) {
              Helper_Class.set_med_pres_id(obj["pres_id"]);
              this.getPres();
            } else {
              if (this.labarray.length != 0) {
                this.diag_print_flag = true;
              }
              if (this.radioarray.length != 0) {
                this.diag_print_flag = true;
              }
              if (this.selected_profiles.length != 0) {
                this.diag_print_flag = true;
              }
              if (this.diag_print_flag == true) {
                this.Diagnosis_prescription_save();
              } else {
                if (this.home_care != "0" && this.home_care != undefined) {
                  if (obj["pres_id"] != null) {
                    Helper_Class.set_med_pres_id(obj["pres_id"]);
                    if (this.personalinfo.AppFlow == "Doc_app_list") {
                      if (Doc_Helper.getDiagAppflow() == 'medical') {
                        Doc_Helper.setMedSave(true);
                        this.listProducts = [];
                        // this.appmedpresList();
                        this.addmed = false;
                        //this.messageservice.sendDiagComponent("appointment_list");
                        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                          if (Helper_Class.getassflow() != undefined) {
                            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                              this.naviagationService.sendMessage("docassessment");
                            }
                          }
                          else {
                            this.naviagationService.sendMessage("inPatientViewInfo");
                          }
                        }
                        else if (Helper_Class.getassflow()! == undefined) {
                          if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                            this.naviagationService.sendMessage("docassessment");
                          }
                        } else {
                          this.naviagationService.sendMessage("appointment_list");
                        }

                      } else {
                        Doc_Helper.setMedDiag(true);
                        this.subtestbname = [];
                        this.subtestfname = [];
                        this.subtestname = [];
                        this.subtestsname = [];
                        this.subtestuname = [];
                        this.subtesturname = [];
                        this.subtestxname = [];
                        this.subtestcname = [];
                        // this.messageservice.sendDiagComponent("appointment_list");
                        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                          if (Helper_Class.getassflow() != undefined) {
                            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                              this.naviagationService.sendMessage("docassessment");
                            }
                          }
                          else {
                            this.naviagationService.sendMessage("inPatientViewInfo");
                          }
                        }
                        else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                          this.naviagationService.sendMessage("docassessment");
                        } else {
                          this.naviagationService.sendMessage("appointment_list");
                        }
                      }
                      this.saveflag = false;
                      this.printFlag = false;
                      this.toastr.success(Message_data.prescriptionSavedSuccess);
                    }
                    if (this.personalinfo.AppFlow == "InpatMedPres") {
                      if (Doc_Helper.getDiagAppflow() == 'medical') {
                        Doc_Helper.setMedSave(true);
                        this.listProducts = [];
                        this.appmedpresList();
                        this.addmed = false;
                        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                          if (Helper_Class.getassflow() != undefined) {
                            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                              this.naviagationService.sendMessage("docassessment");
                            }
                          }
                          else {
                            this.naviagationService.sendMessage("inPatientViewInfo");
                          }
                        }
                        else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                          this.naviagationService.sendMessage("docassessment");
                        } else {
                          this.naviagationService.sendMessage("appointment_list");
                        }

                      } else {
                        Doc_Helper.setMedDiag(true);
                        this.subtestbname = [];
                        this.subtestfname = [];
                        this.subtestname = [];
                        this.subtestsname = [];
                        this.subtestuname = [];
                        this.subtesturname = [];
                        this.subtestxname = [];
                        this.subtestcname = [];
                        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                          if (Helper_Class.getassflow() != undefined) {
                            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                              this.naviagationService.sendMessage("docassessment");
                            }
                          }
                          else {
                            this.naviagationService.sendMessage("inPatientViewInfo");
                          }
                        }
                        else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                          this.naviagationService.sendMessage("docassessment");
                        } else {
                          this.naviagationService.sendMessage("appointment_list");
                        }
                      }
                      this.saveflag = false;
                      this.printFlag = false;
                      this.toastr.success(Message_data.prescriptionSavedSuccess);
                    }

                    this.book_app_now = false;
                    this.next_datetxt = undefined;

                  } else {
                    this.saveflag = true;
                    this.toastr.error(Message_data.unableToSavePrescription);
                  }

                } else {      
                  // alert("ok" +JSON.stringify(Helper_Class.get_med_pres_id()))    
                  //  alert("ok" +JSON.stringify(Helper_Class.get_diag_pres_id()))          
                  if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                    Helper_Class.set_med_pres_id(obj["pres_id"]);
                    this.diab_medpres = obj["pres_id"];
                    if (Helper_Class.get_med_pres_id() != null && Helper_Class.get_diag_pres_id() != null) {
                      this.commonredirect(Helper_Class.get_med_pres_id(), Helper_Class.get_diag_pres_id())
                    } else {
                      if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                        this.toastr.success(Message_data.prescriptionSavedSuccess);
                      }
                    }
                    // var bodytext;
                    // if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                    //   if (Doc_Helper.getDiagAppflow() == 'medical') {
                    //     Doc_Helper.setMedSave(true);
                    //     this.listProducts = [];
                    //     //this.appmedpresList();
                    //     this.addmed = false;
                    //     bodytext = "Would you like to write diagnostic prescription";
                    //     this.doct_clientname = "";
                    //     this.mobile = "";
                    //     this.client_age = "";
                    //     this.client_gender = "";
                    //     this.oberse_med = "";
                    //     this.disease_name = "";
                    //     this.refer_txt = "";
                    //     this.clnt_bp = "";
                    //     this.temparature = "";
                    //     if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                    //       if (Helper_Class.getassflow() != undefined) {
                    //         if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //           this.naviagationService.sendMessage("docassessment");
                    //         }
                    //       }
                    //       else {
                    //         this.naviagationService.sendMessage("inPatientViewInfo");
                    //       }
                    //     }
                    //     else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //       this.naviagationService.sendMessage("docassessment");
                    //     } else {
                    //       this.naviagationService.sendMessage("appointment_list");
                    //     }
                    //     //this.messageservice.sendDiagComponent("appointment_list");
                    //   } else {
                    //     Doc_Helper.setMedDiag(true);
                    //     this.subtestbname = [];
                    //     this.subtestfname = [];
                    //     this.subtestname = [];
                    //     this.subtestsname = [];
                    //     this.subtestuname = [];
                    //     this.subtesturname = [];
                    //     this.subtestxname = [];
                    //     this.subtestcname = [];
                    //     bodytext = "Would you like to write medical prescription";
                    //     if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                    //       if (Helper_Class.getassflow() != undefined) {
                    //         if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //           this.naviagationService.sendMessage("docassessment");
                    //         }
                    //       }
                    //       else {
                    //         this.naviagationService.sendMessage("inPatientViewInfo");
                    //       }
                    //     }
                    //     else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //       this.naviagationService.sendMessage("docassessment");
                    //     } else {
                    //       this.naviagationService.sendMessage("appointment_list");
                    //     }
                    //     //this.messageservice.sendDiagComponent("appointment_list");
                    //   }
                    //   this.saveflag = false;
                    //   this.printFlag = false;
                    //   this.toastr.success(Message_data.prescriptionSavedSuccess);
                    // }
                    // if (this.personalinfo.AppFlow == "Doc_app_list") {
                    //   //alert("cihihouh")
                    //   if (Doc_Helper.getDiagAppflow() == 'medical') {
                    //     Doc_Helper.setMedSave(true);
                    //     this.listProducts = [];
                    //     //  this.appmedpresList();
                    //     this.addmed = false;
                    //     bodytext = "Would you like to write diagnostic prescription";
                    //     this.doct_clientname = "";
                    //     this.mobile = "";
                    //     this.client_age = "";
                    //     this.client_gender = "";
                    //     this.oberse_med = "";
                    //     this.disease_name = "";
                    //     this.refer_txt = "";
                    //     this.clnt_bp = "";
                    //     this.temparature = "";

                    //     //this.messageservice.sendDiagComponent("appointment_list");
                    //   } else {
                    //     // alert("cihi3333333houh")
                    //     Doc_Helper.setMedDiag(true);
                    //     this.subtestbname = [];
                    //     this.subtestfname = [];
                    //     this.subtestname = [];
                    //     this.subtestsname = [];
                    //     this.subtestuname = [];
                    //     this.subtesturname = [];
                    //     this.subtestxname = [];
                    //     this.subtestcname = [];
                    //     bodytext = "Would you like to write medical prescription";
                    //     //this.messageservice.sendDiagComponent("appointment_list");
                    //   }

                    //   this.saveflag = false;
                    //   this.printFlag = false;
                    //   this.toastr.success(Message_data.prescriptionSavedSuccess);
                    // }
                    // var diagpres = Helper_Class.get_diag_pres_id();
                    // if (this.diagpresflag == false && diagpres == null) {
                    //   // alert("00000kkk"+JSON.stringify(this.personalinfo.AppFlow ))
                    //   if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                    //     if (Helper_Class.getassflow() != undefined) {
                    //       if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //         this.naviagationService.sendMessage("docassessment");
                    //       }
                    //     }
                    //     else {
                    //       this.naviagationService.sendMessage("inPatientViewInfo");
                    //     }
                    //   }
                    //   else if (Helper_Class.getassflow() != undefined) {
                    //     if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
                    //       this.naviagationService.sendMessage("docassessment");
                    //     } else {
                    //       this.naviagationService.sendMessage("appointment_list");
                    //     }
                    //   } else {
                    //     //alert("00000kkk"+JSON.stringify(Helper_Class.getassflow()))
                    //     this.naviagationService.sendMessage("appointment_list");
                    //   }
                    //   // this.showConfirm(bodytext)
                    // } else {
                    //   this.backtoApplist();
                    // }

                    // var dat = this.check_pres;
                    // if (dat == "0") {
                    //   this.check_pres = "1";

                    // } else {
                    //   var seconds = 1;
                    //   setInterval(function () {
                    //     seconds--;

                    //   }, 500);
                    // }

                    // this.book_app_now = false;
                    // this.next_datetxt = undefined;

                  } else {//nn
                    this.saveflag = true;
                    this.toastr.error(Message_data.unableToSavePrescription);
                  }
                }
              }
            }
          },
          error => {
          }
        )
    }
  }
  // intake_change = function (this: any) {
  //   if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
  //     this.dure_show = false;
  //     this.dure_require = true;
  //     this.dure_txt = "30";
  //   } else {
  //     this.dure_show = true;
  //     this.dure_require = false;
  //     this.dure_txt = undefined;
  //   }
  // }
  Diagnosis_prescription_save() {
    console.log("this.userinfo.doc_bill_generate" + JSON.stringify(this.userinfo))
    var flag = true;
    if (this.search_location == undefined || this.search_city == undefined || this.search_state == undefined || this.search_country == undefined || this.sercah_center == undefined) {
      flag = false;
      this.toastr.error(Message_data.mandatory);
    }
    if (flag == true && (this.labarray == undefined || this.radioarray == undefined)) {
      flag = false;
      this.toastr.error(Message_data.sltTestType);
    }


    if (flag == true && (this.selectedpackages == undefined)) {
      flag = false;
      this.toastr.error("Select package");
    }

    if (this.labarray.length != 0 || this.labarraynew.length != 0) {
      flag = true;

    } else if (this.radioarray.length != 0 || this.radioarraynew.length != 0) {
      flag = true;

    } else if (this.selected_profiles.length != 0 || this.selected_profilesnew.length != 0) {
      flag = true;

    }
    else if (this.selectedpackages.length != 0 || this.selectedpackagesnew.length != 0) {
      flag = true;

    } else {
      flag = false;
      this.toastr.error(Message_data.sltAtleastOneTest);

    }
    if (this.testflagnew == false) {
      if (this.next_datetxt_diag == undefined) {
        flag = false;
        this.toastr.error("Select Next visit date");
      }
    }


    if (flag == true) {
      var lab_array = [];
      var radio_array = []; var package_array = []; var profilearray = []

      if (this.labarray.length != 0) {
        for (var i = 0; i < this.labarray.length; i++) {
          lab_array.push(this.labarray[i].test_id);
        }
      }
      if (this.radioarray.length != 0) {
        for (var i = 0; i < this.radioarray.length; i++) {
          radio_array.push(this.radioarray[i].test_id);
        }
      }
      if (this.selectedpackages.length != 0) {
        for (var i = 0; i < this.selectedpackages.length; i++) {
          package_array.push(this.selectedpackages[i].health_pkg_id);
        }
      }
      console.log("select lab" + JSON.stringify(this.selected_profiles))

      if (this.selected_profiles.length != 0) {
        for (var i = 0; i < this.selected_profiles.length; i++) {
          profilearray.push(this.selected_profiles[i].diag_tests_id);
        }
      }
      console.log("select lab" + JSON.stringify(this.labarray))
      console.log("select lab" + JSON.stringify(this.radioarray))

      console.log("select lab" + JSON.stringify(this.selectedpackages))

      console.log("select lab" + JSON.stringify(this.selected_profiles))

      var lab_arraynew: any = [];
      var radio_arraynew: any = [];
      if (this.labarraynew.length != 0) {
        for (var i = 0; i < this.labarraynew.length; i++) {
          lab_arraynew.push(this.labarraynew[i].test_id);
        }
      }
      if (this.radioarraynew.length != 0) {
        for (var i = 0; i < this.radioarraynew.length; i++) {
          radio_arraynew.push(this.radioarraynew[i].test_id);
        }
      }

      if (this.obs_diag != undefined) {
        this.observation = this.obs_diag;
      } else {
        this.observation = "";
      }
      if (this.test_now == true) {
        this.book_app = "1";
      } else {
        this.book_app = "0";
      }

      var sen_pass;
      var data;
      data = {
        "labtest": lab_arraynew, "radiotest": radio_arraynew, "profiles": this.selected_profileIdsnew, "packages": this.selectedpackageidnew,
      }
      var nextdate;
      if (this.next_datetxt != undefined) {
        nextdate = this.next_datetxt;
        nextdate = this.next_datetxt_diag;
      } else {
        nextdate = this.next_datetxt1
      }

      var date, timestring;
      // if(this.nextvisitflag == true){
      //   if(nextdate != undefined){
      //     date = Date_Formate(nextdate)
      //   }else{
      //     this.toastr.error("Select next visit date")
      //   }


      // }else{
      date = this.appDate
      if (this.startTime != undefined && this.endTime != undefined) {
        var time = this.startTime + ":" + this.endTime;
        timestring = this.timeconversion(time, this.visitSession)
      }

      // }
      // next_visit:date,
      // app_session:this.visitSession,
      // time:this.timeconversion(time,this.visitSession)

      if (this.personalinfo.sub_id != "" && this.personalinfo.sub_id != undefined) {
        sen_pass = JSON.stringify({
          appointment_id: this.personalinfo.app_id,
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          sub_rel_id: this.personalinfo.sub_id,
          date: this.CurrentDatetime,
          diagnosis: "diag",
          disease: "",
          comment: this.observation,
          diagnosis_id: this.diag_centre_id,
          spl_id: this.personalinfo.spl_id,
          "labtest": lab_array,
          "radiotest": radio_array,
          "profiles": this.selected_profileIds,
          packages: package_array,
          "book_now": this.book_app,
          // packages:this.selectedpackageid,
          book_app_now: this.book_app_now,
          //  next_visit: nextdate,
          nextvistflag: true,
          next_visit_flag: "1",
          nextvisttest: data,
          next_visit: date,
          app_session: this.visitSession,

          time: timestring,
          country: ipaddress.country_code,
          doc_bill_generate: this.doc_bill_generate
        });

      } else {
        sen_pass = JSON.stringify({
          appointment_id: this.personalinfo.app_id,
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          date: this.CurrentDatetime,
          diagnosis: "diag",
          disease: "",
          comment: this.observation,
          diagnosis_id: this.diag_centre_id,
          spl_id: this.personalinfo.spl_id,
          "labtest": lab_array,
          "radiotest": radio_array,
          packages: package_array,
          "profiles": this.selected_profileIds,
          "book_now": this.book_app,
          // packages:this.selectedpackageid,
          book_app_now: this.book_app_now,
          // next_visit: nextdate,
          nextvistflag: true,
          next_visit_flag: "1",
          nextvisttest: data,
          next_visit: date,
          app_session: this.visitSession,
          doc_bill_generate: this.doc_bill_generate,
          time: timestring,
          country: ipaddress.country_code,
        });
      }
      console.log("check diagnosis --" + JSON.stringify(sen_pass))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj DIAGNOSIS--" + JSON.stringify(obj))
            console.log("check objobj --" + JSON.stringify(Helper_Class.get_med_pres_id()))
            Helper_Class.set_diag_pres_id(obj["pres_id"]);
            if (this.home_care != "0" && this.home_care != undefined) {
              if (obj != null) {
                var bodytext;
                this.pageflag = "diag";
                if (this.personalinfo.AppFlow == "Doc_app_list") {
                  Doc_Helper.setMedDiag(true);
                  this.backtoApplist()
                  // }
                  this.saveflag = false;
                  this.printFlag = false;
                  this.toastr.success("Diagnosis " + Message_data.prescriptionSavedSuccess);

                  if (Helper_Class.get_med_pres_id() == null) {
                    // this.showConfirm(bodytext)
                  } else {
                    this.backtoApplist();
                  }
                }
                if (this.personalinfo.AppFlow == "InpatMedPres") {
                  if (Doc_Helper.getDiagAppflow() == 'medical') {
                    Doc_Helper.setMedSave(true);
                  } else {
                    Doc_Helper.setMedDiag(true);
                  }
                  this.saveflag = false;
                  this.printFlag = false;
                  this.toastr.success("Diagnosis " + Message_data.prescriptionSavedSuccess);
                  if (Helper_Class.get_med_pres_id() == null) {
                    //  this.showConfirm(bodytext)
                  } else {
                    this.backtoApplist();
                  }
                }

              } else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }

            } else {
              // alert("viljkjh diagnosis")
              if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                if (Helper_Class.get_med_pres_id() != null && Helper_Class.get_diag_pres_id() != null) {
                  this.commonredirect(Helper_Class.get_med_pres_id(), Helper_Class.get_diag_pres_id())
                }else{
                  if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                    this.toastr.success(Message_data.prescriptionSavedSuccess);
                  }
                }
              } else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }
            }
            if (obj.status == "1") {
              if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
                if (this.inp_diag_bill_gen == "1") {
                  let newArray: any[] = [];
                  this.pricetable = [];
                  newArray.push(...this.labarray);
                  newArray.push(...this.radioarray);
                  newArray.push(...this.selected_profiles);
                  newArray.push(...this.selectedpackages);
                  let totalPrice = newArray.reduce((acc, curr) => acc + parseInt(curr.price), 0);

                  console.log(totalPrice);

                  this.updatedArrays = newArray;
                  this.totalbillamtvalue = totalPrice.toString();
                  console.log("select totalbillamtvalue" + JSON.stringify(this.totalbillamtvalue) + JSON.stringify(newArray))
                  for (var i = 0; i < this.updatedArrays.length; i++) {
                    if (this.updatedArrays[i].test_id) {
                      this.testname = "test"
                    } else if (this.updatedArrays[i].diag_profile_id) {
                      this.testname = "profiles"
                    } else {
                      this.testname = "packages"
                    }
                    this.sno = i + 1;
                    this.pricetable.push({
                      sno: this.sno,
                      test_id: this.updatedArrays[i].test_id,
                      test_name: this.updatedArrays[i].test_name,
                      price: this.updatedArrays[i].price,
                      amount_txt: this.updatedArrays[i].price,
                      concession: parseFloat("0.00"),
                      fee: parseFloat(this.updatedArrays[i].price).toFixed(2),
                      charge_type: this.updatedArrays[i].test_name,
                      type_of_test: this.testname,//test/packages/profiles
                      // pay_type: paydesc,
                      // paytype: this.payType,
                      // insurer: this.insuranceId,
                      quantity: 1,
                      priority: 0,
                    })
                    this.sno += 1;
                  }
                  console.log("result.updatedArrays" + JSON.stringify(this.updatedArrays)); // Output: array with updated checked status
                  console.log("result.updatedArrays" + JSON.stringify(this.pricetable));
                  //  alert(">>>>>>"+this.personalinfo.AppFlow+typeof(this.totalbillamtvalue))
                  // var toatlamt_string:string;
                  // toatlamt_string=this.totalbillamtvalue.toString()
                  // alert(">>>>>>"+toatlamt_string+typeof(toatlamt_string))
                  var billval = encrypt_decript.Encript(this.totalbillamtvalue).toString();
                  var billcreate_value;
                  if (this.personalinfo.sub_id != "") {
                    billcreate_value = {
                      diag_centre_id: this.diag_cen_id,
                      appointment_id: this.personalinfo.app_id,
                      client_reg_id: this.personalinfo.Client_id,
                      relation_id: this.personalinfo.rel_id,
                      sub_rel_id: this.personalinfo.sub_id,
                      balance: this.totalbillamtvalue,
                      paid_amt: "0.00",
                      paid_flag: "Un paid",
                      bill_amount: billval,
                      created_by: this.userinfo.user_id,
                      app_type: "diagnosis",
                      bills: this.pricetable,
                      country: ipaddress.country_code,
                      citizenship: "indian",


                    }
                  } else {
                    billcreate_value = {
                      diag_centre_id: this.diag_cen_id,
                      appointment_id: this.personalinfo.app_id,
                      client_reg_id: this.personalinfo.Client_id,
                      relation_id: this.personalinfo.rel_id,
                      balance: this.totalbillamtvalue,
                      paid_amt: "0.00",
                      paid_flag: "Un paid",
                      bill_amount: billval,
                      created_by: this.userinfo.user_id,
                      app_type: "diagnosis",
                      bills: this.pricetable,
                      country: ipaddress.country_code,
                      citizenship: "indian",


                    }
                  }

                  console.log("obj DIAGNOSIS-****" + JSON.stringify(billcreate_value))
                  // console.log("check objobj --" + JSON.stringify(obj))

                  var headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  this.http.post(ipaddress.getIp.toString() + 'bill/savebill/', billcreate_value,
                    { headers: headers })
                    .subscribe(
                      response => {
                        console.log("obj DIAGNOSIS- Res-" + JSON.stringify(obj))
                        console.log("check objobj --" + JSON.stringify(obj))
                        var response_data = JSON.parse(JSON.stringify(response));

                      })
                }
              }
            }



          },
          error => { }
        )
    }
  }

  showConfirm(bodytext) {
    const dialogRef1 = this.dialog.open(PresDialogComponent, {
      width: '400px',
      data: {
        dialogTitle: 'Prescription',
        dialogMessageLine1: bodytext,
        yesButtonText: 'Yes',
        noButtonText: 'No'
      }
    });
    dialogRef1.afterClosed().subscribe(result => {
      if (result != null) {
        var data = result;

        if (data == false) {
          this.backtoApplist();
        } else {
          if (this.pageflag == "medical") {
            this.ynopt1 = true;
            this.medpres = true;
            this.diagpres = false;
          } else {
            this.ynopt1 = false;
            this.medpres = false;
            this.diagpres = true;
          }
        }
      }
    });
  }

  getPres() {
    var sen_pass = {
      pres_drug_id: Helper_Class.get_med_pres_id(),
      inpatient_id: this.personalinfo.inpatient_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/udsp/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("upadte ---" + JSON.stringify(obj))
          if (obj.key != "0") {
            this.toastr.success("Prescription updated successfully");
            Doc_Helper.setAdmission_id(null)
            var send = {
              admission_id: this.personalinfo.app_id,
              flag: "edit"
            }
            Doc_Helper.setAdmission_id(send);
            this.naviagationService.sendMessage("inPatientDS");
          }

        },
        error => { }
      )
  }

  //Get diagnosis center

  serch_centerchange(data) {
    this.obs_add_diag = undefined;
    var datae: string = "";
    datae = data.diag_centre_name;
    for (var i = 0; i < this.diag_detail_List.length; i++) {
      if (this.diag_detail_List[i].diag_centre_name == datae) {
        this.diag_cen_id = this.diag_detail_List[i].diag_centre_id;
        if (this.diag_detail_List[i].address1 != null && this.diag_detail_List[i].address1 != "") {
          this.diag_address1 = this.diag_detail_List[i].address1;
        }
        else {
          this.diag_address1 = this.diag_detail_List[i].address1;
        }
        if (this.diag_detail_List[i].address1 != null || this.diag_detail_List[i].address2 != null) {
          this.diag_address2 = this.diag_detail_List[i].address2;
        }
        else {
          this.diag_address2 = this.diag_detail_List[i].address2;
        }
        this.obs_add_diag = this.diag_address1 + ", " + this.diag_address2;
      }
      else {
      }
    }

    this.Blood_test = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
      JSON.stringify({
        diag_centre_id: this.diag_cen_id, hptl_clinic_id: this.hospital_clinic_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("respons --" + JSON.stringify(obj))
          // if (obj.diag_tests.length == 0) {
          //   this.test_flag = true;
          // }
          // else {
          //   this.test_flag = false;
          // }
          // this.diag_centre_id = this.diag_cen_id;

          // this.Blood_test = obj.diag_tests;
          // for (var k = 0; k < this.Blood_test.length; k++) {
          //   this.testtypename[k] = this.Blood_test[k].test_type_name;
          //   for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
          //     this.Blood_test[k].sub_tests[j] = {
          //       test_id: this.Blood_test[k].sub_tests[j].test_id,
          //       test_name: this.Blood_test[k].sub_tests[j].test_name,
          //       checked: false
          //     }
          //   }
          // }


          this.labTest_array = [];
          this.radioTest_array = [];
          this.microTest_array = [];
          this.profiles = [];
          this.packages = [];

          this.filteredLabArray = [];
          this.filteredRadioArray = [];
          this.filteredMicroArray = [];
          this.filteredProfilesArray = [];
          this.filteredpackageArray = [];

          this.labTest_arraynew = [];
          this.radioTest_arraynew = [];
          this.microTest_arraynew = [];
          this.profilesnew = [];
          this.packagesnew = [];

          this.filteredLabArraynew = [];
          this.filteredRadioArraynew = [];
          this.filteredMicroArraynew = [];
          this.filteredProfilesArraynew = [];
          this.filteredpackageArraynew = [];


          this.labTest_array = obj.labtests;
          this.radioTest_array = obj.radiotests;
          this.microTest_array = obj.microtests;
          this.profiles = obj.profiles;
          this.packages = obj.packages;


          this.filteredLabArray = obj.labtests;
          this.filteredRadioArray = obj.radiotests;
          this.filteredMicroArray = obj.microtests;
          this.filteredProfilesArray = obj.profiles;
          this.filteredpackageArray = obj.packages;

          this.labTest_arraynew = obj.nlabtests;
          this.radioTest_arraynew = obj.nradiotests;
          this.microTest_arraynew = obj.nmicrotests;
          this.profilesnew = obj.nprofiles;
          this.packagesnew = obj.npackages;

          this.filteredLabArraynew = obj.nlabtests;
          this.filteredRadioArraynew = obj.nradiotests;
          this.filteredMicroArraynew = obj.nmicrotests;

          this.filteredProfilesArraynew = obj.nprofiles;
          this.filteredpackageArraynew = obj.npackages;

          if (obj.diag_tests != undefined && obj.diag_tests.length == 0) {
            this.test_flag = true;

          } else {
            this.test_flag = false;
          }

          //this.Blood_test = obj.diag_tests;
          this.testGroupArray = obj.packages;

          // for (var k = 0; k < this.Blood_test.length; k++) {
          //   this.testtypename[k] = this.Blood_test[k].test_type_name;
          //   for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
          //     this.Blood_test[k].sub_tests[j] = {
          //       test_id: this.Blood_test[k].sub_tests[j].test_id,
          //       test_name: this.Blood_test[k].sub_tests[j].test_name,
          //       frequency_val: this.Blood_test[k].sub_tests[j].frequency_val,
          //       frequency_measure: this.Blood_test[k].sub_tests[j].frequency_measure,
          //       checked: false,
          //       test_type: this.Blood_test[k].sub_tests[j].test_type,
          //       department: this.Blood_test[k].sub_tests[j].department,
          //     }
          //   }
          //   for (var j = 0; j < this.Blood_test[k].profiles.length; j++) {
          //     this.Blood_test[k].profiles[j] = {
          //       test_id: this.Blood_test[k].profiles[j].test_id,
          //       test_name: this.Blood_test[k].profiles[j].test_name,
          //       frequency_val: this.Blood_test[k].profiles[j].frequency_val,
          //       frequency_measure: this.Blood_test[k].profiles[j].frequency_measure,
          //       department: this.Blood_test[k].profiles[j].department,
          //       checked: false
          //     }
          //   }
          // }

        },
        error => { }
      )
  }

  // ChangeTestType(event, id, data, type) {
  //   if (event == true) {
  //     this.saveflag=true;
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test_type_name;
  //       if (this.testtypename[k] == type) {
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if (this.Blood_test[k].sub_tests[j].test_id == id) {
  //             this.Blood_test[k].sub_tests[j].checked = true;
  //           }

  //         }
  //       }

  //     }
  //     if (type == "Blood Test") {
  //       this.bloodtest = false;
  //       this.subtestname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.blood_test_head = type;
  //       if (this.blood_test_content != '' && this.blood_test_content != undefined) {
  //         this.blood_test_content = this.blood_test_content + ", " + data;
  //       } else {
  //         this.blood_test_content = data;
  //       }
  //       this.pritn_blood_flag = true;
  //     } else if (type == "Scan") {
  //       this.scantest = false;
  //       this.subtestsname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.scan_test_head = type;
  //       if (this.scan_test_content != '' && this.scan_test_content != undefined) {
  //         this.scan_test_content = this.scan_test_content + ", " + data;
  //       } else {
  //         this.scan_test_content = data;
  //       }
  //       this.pritn_scan_flag = true;
  //     } else if (type == "Ultra Sound") {
  //       this.ultratest = false;
  //       this.subtestuname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.us_test_head = type;
  //       if (this.us_test_content != '' && this.us_test_content != undefined) {
  //         this.us_test_content = this.us_test_content + ", " + data;
  //       } else {
  //         this.us_test_content = data;
  //       }
  //       this.pritn_us_flag = true;

  //     } else if (type == "X-ray") {
  //       this.xraytest = false;
  //       this.subtestxname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.xray_test_head = type;
  //       if (this.xray_test_content != '' && this.xray_test_content != undefined) {
  //         this.xray_test_content = this.xray_test_content + ", " + data;
  //       } else {
  //         this.xray_test_content = data;
  //       }
  //       this.pritn_xray_flag = true;
  //     } else if (type == "Biopsy") {
  //       this.biopsytest = false;
  //       this.subtestbname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.bio_test_head = type;
  //       if (this.bio_test_content != '' && this.bio_test_content != undefined) {
  //         this.bio_test_content = this.bio_test_content + ", " + data;
  //       } else {
  //         this.bio_test_content = data;
  //       }
  //       this.pritn_bio_flag = true;
  //     } else if (type == "Urine Test") {
  //       this.urinetest = false;
  //       this.subtesturname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.ut_test_head = type;
  //       if (this.ut_test_content != '' && this.ut_test_content != undefined) {
  //         this.ut_test_content = this.ut_test_content + ", " + data;
  //       } else {
  //         this.ut_test_content = data;
  //       }
  //       this.pritn_ut_flag = true;
  //     } else if (type == "Faeces Test") {
  //       this.faecestest = false;
  //       this.subtestfname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.ft_test_head = type;
  //       if (this.ft_test_content != '' && this.ft_test_content != undefined) {
  //         this.ft_test_content = this.ft_test_content + ", " + data;
  //       } else {
  //         this.ft_test_content = data;
  //       }
  //       this.pritn_ft_flag = true;
  //     } else if (type == "Culture") {
  //       this.culturetest = false;
  //       this.subtestcname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //       this.culture_test_head = type;
  //       if (this.culture_test_content != '' && this.culture_test_content != undefined) {
  //         this.culture_test_content = this.culture_test_content + ", " + data;
  //       } else {
  //         this.culture_test_content = data;
  //       }
  //       this.pritn_culture_flag = true;
  //     }

  //   } else if (event == false) {
  //     if (type == "Blood Test") {
  //       if (this.blood_test_content != "") {
  //         this.blood_test_content = this.blood_test_content.replace(data + ', ', "");
  //         this.blood_test_content = this.blood_test_content.replace(data, "");

  //       }
  //       if (this.blood_test_content == "") {
  //         this.blood_test_head = "";
  //         this.pritn_blood_flag = false;
  //       } else {
  //         this.pritn_blood_flag = true;
  //       }
  //     }
  //     if (type == "Scan") {
  //       if (this.scan_test_content != "") {
  //         this.scan_test_content = this.scan_test_content.replace(data + ', ', "");
  //         this.scan_test_content = this.scan_test_content.replace(data, "");

  //       }
  //       if (this.scan_test_content == "") {
  //         this.scan_test_head = "";
  //         this.pritn_scan_flag = false;
  //       } else {
  //         this.pritn_scan_flag = true;
  //       }
  //     }
  //     if (type == "Ultra Sound") {
  //       if (this.us_test_content != "") {
  //         this.us_test_content = this.us_test_content.replace(data + ', ', "");
  //         this.us_test_content = this.us_test_content.replace(data, "");

  //       }
  //       if (this.us_test_content == "") {
  //         this.us_test_head = "";
  //         this.pritn_us_flag = false;
  //       } else {
  //         this.pritn_us_flag = true;
  //       }
  //     }
  //     if (type == "X-ray") {
  //       if (this.xray_test_content != "") {
  //         this.xray_test_content = this.xray_test_content.replace(data + ', ', "");
  //         this.xray_test_content = this.xray_test_content.replace(data, "");

  //       }
  //       if (this.xray_test_content == "") {
  //         this.xray_test_head = "";
  //         this.pritn_xray_flag = false;
  //       } else {
  //         this.pritn_xray_flag = true;
  //       }
  //     }
  //     if (type == "Biopsy") {
  //       if (this.bio_test_content != "") {
  //         this.bio_test_content = this.bio_test_content.replace(data + ', ', "");
  //         this.bio_test_content = this.bio_test_content.replace(data, "");

  //       }
  //       if (this.bio_test_content == "") {
  //         this.bio_test_head = "";
  //         this.pritn_bio_flag = false;
  //       } else {
  //         this.pritn_bio_flag = true;
  //       }
  //     }
  //     if (type == "Urine Test") {
  //       if (this.ut_test_content != "") {
  //         this.ut_test_content = this.ut_test_content.replace(data + ', ', "");
  //         this.ut_test_content = this.ut_test_content.replace(data, "");

  //       }
  //       if (this.ut_test_content == "") {
  //         this.ut_test_head = "";
  //         this.pritn_ut_flag = false;
  //       } else {
  //         this.pritn_ut_flag = true;
  //       }
  //     }
  //     if (type == "Faeces Test") {
  //       if (this.ft_test_content != "") {
  //         this.ft_test_content = this.ft_test_content.replace(data + ', ', "");
  //         this.ft_test_content = this.ft_test_content.replace(data, "");

  //       }
  //       if (this.ft_test_content == "") {
  //         this.ft_test_head = "";
  //         this.pritn_ft_flag = false;
  //       } else {
  //         this.pritn_ft_flag = true;
  //       }
  //     }
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test_type_name;
  //       if (this.testtypename[k] == type) {
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if (this.Blood_test[k].sub_tests[j].test_id == id) {
  //             this.Blood_test[k].sub_tests[j].checked = false;
  //           }

  //         }
  //       }

  //     }
  //     for (var h = 0; h < this.subtestname.length; h++) {
  //       if (this.subtestname[h].test_id == id) {
  //         this.subtestname.splice(h, 1);hospitals
  //         this.checked = false;
  //         if (this.subtestname.length == 0) {
  //           this.bloodtest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestsname.length; h++) {
  //       if (this.subtestsname[h].test_id == id) {
  //         this.subtestsname.splice(h, 1);
  //         if (this.subtestsname.length == 0) {
  //           this.scantest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestuname.length; h++) {
  //       if (this.subtestuname[h].test_id == id) {
  //         this.subtestuname.splice(h, 1);
  //         if (this.subtestuname.length == 0) {
  //           this.ultratest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestxname.length; h++) {
  //       if (this.subtestxname[h].test_id == id) {
  //         this.subtestxname.splice(h, 1);
  //         if (this.subtestxname.length == 0) {
  //           this.xraytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestbname.length; h++) {
  //       if (this.subtestbname[h].test_id == id) {
  //         this.subtestbname.splice(h, 1);
  //         if (this.subtestbname.length == 0) {
  //           this.biopsytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtesturname.length; h++) {
  //       if (this.subtesturname[h].test_id == id) {
  //         this.subtesturname.splice(h, 1);
  //         if (this.subtesturname.length == 0) {
  //           this.urinetest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestfname.length; h++) {
  //       if (this.subtestfname[h].test_id == id) {
  //         this.subtestfname.splice(h, 1);
  //         if (this.subtestfname.length == 0) {
  //           this.faecestest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestcname.length; h++) {
  //       if (this.subtestcname[h].test_id == id) {
  //         this.subtestcname.splice(h, 1);
  //         if (this.subtestcname.length == 0) {
  //           this.culturetest = true;
  //         }
  //       }
  //     }
  //   }

  //   this.print_diag = false;
  //   if (this.subtestname.length != 0) {
  //     this.print_diag = true;
  //   }

  //   if (this.subtesturname.length != 0) {
  //     this.print_diag = true;

  //   }
  //   if (this.subtestfname.length != 0) {
  //     this.print_diag = true;

  //   }
  //   if (this.subtestsname.length != 0) {
  //     this.print_diag = true;

  //   }
  //   if (this.subtestxname.length != 0) {
  //     this.print_diag = true;

  //   }
  //   if (this.subtestuname.length != 0) {
  //     this.print_diag = true;
  //   }
  //   if (this.subtestcname.length != 0) {
  //     this.print_diag = true;

  //   }

  //   if (this.print_diag == true && this.listProducts.length != 0) {
  //     this.print_medidiag = true;
  //   } else {
  //     this.print_medidiag = false;
  //   }
  // }


  closeSelectedTest(type, id, subtype) {
    for (var k = 0; k < this.Blood_test.length; k++) {
      if (type == this.Blood_test[k].test_type_name) {
        for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
          if (this.Blood_test[k].sub_tests[j].test_id == id) {
            this.Blood_test[k].sub_tests[j].checked = false;
          }
        }
      }
    }
    if (type == "Blood Test") {
      for (var h = 0; h < this.subtestname.length; h++) {
        if (this.subtestname[h].test_id == id) {
          this.subtestname.splice(h, 1);
          if (this.subtestname.length == 0) {
            this.bloodtest = true;
          }
        }

      }
    }
    if (type == "Scan") {
      for (var h = 0; h < this.subtestsname.length; h++) {
        if (this.subtestsname[h].test_id == id) {
          this.subtestsname.splice(h, 1);
          if (this.subtestsname.length == 0) {
            this.scantest = true;
          }
        }
      }
    }
    if (type == "Ultra Sound") {
      for (var h = 0; h < this.subtestuname.length; h++) {
        if (this.subtestuname[h].test_id == id) {
          this.subtestuname.splice(h, 1);
          if (this.subtestuname.length == 0) {
            this.ultratest = true;
          }
        }
      }
    }
    if (type == "X-ray") {
      for (var h = 0; h < this.subtestxname.length; h++) {
        if (this.subtestxname[h].test_id == id) {
          this.subtestxname.splice(h, 1);
          if (this.subtestxname.length == 0) {
            this.xraytest = true;
          }
        }
      }
    }
    if (type == "Biopsy") {
      for (var h = 0; h < this.subtestbname.length; h++) {
        if (this.subtestbname[h].test_id == id) {
          this.subtestbname.splice(h, 1);
          if (this.subtestbname.length == 0) {
            this.biopsytest = true;
          }

        }
      }
    }
    if (type == "Urine Test") {
      for (var h = 0; h < this.subtesturname.length; h++) {
        if (this.subtesturname[h].test_id == id) {
          this.subtesturname.splice(h, 1);
          if (this.subtesturname.length == 0) {
            this.urinetest = true;
          }

        }
      }
    }
    if (type == "Faeces Test") {
      for (var h = 0; h < this.subtestfname.length; h++) {
        if (this.subtestfname[h].test_id == id) {
          this.subtestfname.splice(h, 1);
          if (this.subtestfname.length == 0) {
            this.faecestest = true;
          }
        }
      }
    }
    if (type == "Culture") {
      for (var h = 0; h < this.subtestcname.length; h++) {
        if (this.subtestcname[h].test_id == id) {
          this.subtestcname.splice(h, 1);
          if (this.subtestcname.length == 0) {
            this.culturetest = true;
          }
        }
      }
    }
    var flag;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }
    if (flag == true && this.listProducts.length != 0) {
      this.print_medidiag = true;
      this.saveflag = false;
    } else {
      this.print_medidiag = false;
    }
  }
  check_addedTests(profile_array, lab_array, radio_array) {
    var flag = false;
    for (var i = 0; i < profile_array.tests.length; i++) {
      if (lab_array != undefined && lab_array.length != 0) {
        for (var j = 0; j < lab_array.length; j++) {
          if (profile_array.tests[i].diag_tests_id == lab_array[j].test_id) {
            flag = true;
            break;
          }
        }
      }

      if (radio_array != undefined && radio_array.length != 0) {
        for (var k = 0; k < radio_array.length; k++) {
          if (profile_array.tests[i].diag_tests_id == radio_array[k].test_id) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }
  public toatalprice = 0
  clickTest(e: any, data) {
    console.log('this.profiles' + JSON.stringify(this.profiles))
    if (e == true) {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          var results
          results = this.check_addedTests(this.profiles[i], this.labarray, this.radioarray);
          // alert(results)

          if (results != true) {
            this.profiles[i].checked = true;
            this.selected_profiles.push(this.profiles[i])
            this.selected_profileIds.push(this.profiles[i].diag_profile_id)
          } else {
            this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
          }
        }
      }
      //this.addAmount(this.selected_profiles,"Laboratory");
      // this.totalSelectedPrice = addAmount(this.selected_profiles, jsonArray2, jsonArray3);


    } else {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          this.profiles[i].checked = false;
          for (var h = 0; h < this.selected_profiles.length; h++) {
            if (this.selected_profiles[h].diag_profile_id == data) {
              this.selected_profiles.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selected_profileIds.length; k++) {
            if (this.selected_profileIds[k] == data) {
              this.selected_profileIds.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selected_profiles)
    if (this.selected_profiles != 0) {
      this.saveflag = true;
    } else {
      if (this.labarray.length == 0 || this.radioarray.length == 0 || this.selected_profiles.length == 0) {
        this.saveflag = false;
      }
    }
    console.log("this.selected_profiles", this.selected_profiles)
  }
  mainFilter(testName) {
    this.filterLabArray(testName)
    this.filterRadioArray(testName)
    this.filterMicroArray(testName);
    this.filterProfileArray(testName)
    this.filterPackageArray(testName)
  }
  filterLabArray(LabtestName) {
    this.filteredLabArray = this.labTest_array.filter(item =>
      item.test_name.toLowerCase().includes(LabtestName.toLowerCase())
    );
  }

  filterRadioArray(radiotestName) {
    this.filteredRadioArray = this.radioTest_array.filter(item =>
      item.test_name.toLowerCase().includes(radiotestName.toLowerCase())
    );
  }

  filterMicroArray(microtestName) {
    this.filteredMicroArray = this.microTest_array.filter(item =>
      item.test_name.toLowerCase().includes(microtestName.toLowerCase())
    );
  }
  filterPackageArray(profileName) {
    this.filteredpackageArray = this.packages.filter(item =>
      item.package_name.toLowerCase().includes(profileName.toLowerCase())
    );
  }
  filterProfileArray(profileName) {

    console.log("profiles --profiles" + JSON.stringify(this.profiles))
    this.filteredProfilesArray = this.profiles.filter(item =>
      item.profile_name.toLowerCase().includes(profileName.toLowerCase())
    );
    console.log("profiles --profiles" + JSON.stringify(this.filteredProfilesArray))

  }
  async ChangeTestType(event, id, data, type, subtest, test_type, testdept, totalTAT) {
    try {
      this.getFrequency(event, id, data, subtest, type, test_type, testdept, totalTAT);
    }
    catch (err) { }
  }

  getFrequency(event, id, data, subtest, type, test_type, testdept, totalTAT) {
    var send_data = {
      client_reg_id: this.personalinfo.Client_id,
      relation_id: this.personalinfo.rel_id,
      sub_rel_id: this.personalinfo.sub_rel_id,
      date: this.currentDate,
      test_id: subtest.diag_tests_id,
      interval: subtest.frequency_val,
      test_type: test_type,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gftt', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          // alert("exists "+obj.test_exists+" === "+this.test_exists)
          if (obj.test_exists != undefined) {
            if (obj.test_exists == false) {
              if (event == true) {
                if (this.test_exists == false) {
                  var results
                  results = this.finekeyInArray_addTest(this.selected_profiles, id);
                  if (results != true) {


                    if (testdept == 'labtest') {
                      for (var k = 0; k < this.labTest_array.length; k++) {
                        if (this.labTest_array[k].diag_tests_id == id) {
                          this.labTest_array[k].checked = true;

                        }
                      }

                    } else if (testdept == 'radiotest') {
                      for (var k = 0; k < this.radioTest_array.length; k++) {
                        if (this.radioTest_array[k].diag_tests_id == id) {
                          this.radioTest_array[k].checked = true;
                          //  this.addAmount(subtest,this.radioTest_array)
                        }
                      }
                    } else if (testdept == 'microtest') {
                      for (var k = 0; k < this.microTest_array.length; k++) {
                        if (this.microTest_array[k].diag_tests_id == id) {
                          this.microTest_array[k].checked = true;
                          // this.addAmount(subtest,this.microTest_array)
                        }
                      }
                    } else {
                      for (var k = 0; k < this.profiles.length; k++) {
                        if (this.profiles[k].diag_tests_id == id) {
                          this.profiles[k].checked = true;
                          //this.addAmount(subtest,this.profiles)
                        }
                      }
                    }
                    if (this.personalinfo.AppFlow == "InpatMedPres") {

                    }

                    //this.addTotalamount(this.labTest_array, this.radioTest_array, this.microTest_array, this.profiles)
                    if (subtest.department == "Laboratory") {
                      this.labarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        totalTAT: totalTAT
                      })

                    } else {
                      this.radioarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        totalTAT: totalTAT
                      })
                    }

                    if ((this.labarray != undefined && this.labarray.length != 0)
                      || (this.radioarray != undefined && this.radioarray.length != 0)) {
                      this.saveflag = true;
                    } else {
                      this.saveflag = false;
                    }
                  } else {
                    this.toastr.error("Selected test is already added in selected profile", null, { timeOut: 3000 });
                  }
                  console.log("New Arrays", this.labarray, this.radioarray)

                } else {
                  this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
                }
              } else if (event == false) {
                if (testdept == 'labtest') {
                  for (var k = 0; k < this.labTest_array.length; k++) {
                    if (this.labTest_array[k].diag_tests_id == id) {
                      this.labTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'radiotest') {
                  for (var k = 0; k < this.radioTest_array.length; k++) {
                    if (this.radioTest_array[k].diag_tests_id == id) {
                      this.radioTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'microtest') {
                  for (var k = 0; k < this.microTest_array.length; k++) {
                    if (this.microTest_array[k].diag_tests_id == id) {
                      this.microTest_array[k].checked = false;
                    }
                  }
                } else {
                  for (var k = 0; k < this.profiles.length; k++) {
                    if (this.profiles[k].diag_tests_id == id) {
                      this.profiles[k].checked = false;
                    }
                  }
                }

                if (subtest.department == "Laboratory") {
                  for (var i = 0; i < this.labarray.length; i++) {
                    if (this.labarray[i].test_id == id) {
                      this.labarray.splice(i, 1);
                    }
                  }

                } else {
                  for (var i = 0; i < this.radioarray.length; i++) {
                    if (this.radioarray[i].test_id == id) {
                      this.radioarray.splice(i, 1);
                    }
                  }
                }

                if ((this.labarray != undefined && this.labarray.length == 0)
                  && (this.radioarray != undefined && this.radioarray.length == 0)
                  && (this.selected_profiles != undefined && this.selected_profiles.length == 0)) {
                  this.saveflag = false;

                } else {
                  this.saveflag = true;
                }
              }

              this.print_diag = false;
              if (this.subtestname.length != 0) {
                this.print_diag = true;
              }

              if (this.subtesturname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestfname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestsname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestxname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestuname.length != 0) {
                this.print_diag = true;
              }
              if (this.subtestcname.length != 0) {
                this.print_diag = true;

              }

              console.log("New Arrays", this.labarray, this.radioarray)
            } else {
              this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
            }
          }
        }, error => { });
  }


  removeTest(test_id, dept) {

    if (dept == 'labtest') {
      for (var k = 0; k < this.labTest_array.length; k++) {
        if (this.labTest_array[k].test_id == test_id) {
          this.labTest_array[k].checked = false;
        }
      }
    } else if (dept == 'radiotest') {
      for (var k = 0; k < this.radioTest_array.length; k++) {
        if (this.radioTest_array[k].test_id == test_id) {
          this.radioTest_array[k].checked = false;
        }
      }
    } else if (dept == 'microtest') {
      for (var k = 0; k < this.microTest_array.length; k++) {
        if (this.microTest_array[k].test_id == test_id) {
          this.microTest_array[k].checked = false;
        }
      }
    } else if (dept == 'package') {
      for (var k = 0; k < this.packages.length; k++) {
        if (this.packages[k].health_pkg_id == test_id) {
          this.packages[k].checked = false;
        }
      }
    }

    else {
      for (var k = 0; k < this.profiles.length; k++) {
        if (this.profiles[k].diag_profile_id == test_id) {
          this.profiles[k].checked = false;
        }
      }
    }

    if (dept == "lab") {
      for (var i = 0; i < this.labarray.length; i++) {
        console.log("this.filteredLabArray" + JSON.stringify(this.filteredLabArray))
        console.log("this.labarray" + JSON.stringify(this.labarray))
        if (this.labarray[i].test_id == test_id) {
          this.labarray.splice(i, 1);
        }
      }
      for (var k = 0; k < this.filteredLabArray.length; k++) {
        if (this.filteredLabArray[k].diag_tests_id == test_id) {
          this.filteredLabArray[k].checked = false;
        }
      }
      console.log("*****************" + JSON.stringify(this.filteredLabArray))
    } else if (dept == "radio") {
      for (var i = 0; i < this.radioarray.length; i++) {
        if (this.radioarray[i].test_id == test_id) {
          this.radioarray.splice(i, 1);
        }
      }
      for (var k = 0; k < this.filteredRadioArray.length; k++) {
        if (this.filteredRadioArray[k].diag_tests_id == test_id) {
          this.filteredRadioArray[k].checked = false;
        }
      }

    } else if (dept == "profile") {
      for (var i = 0; i < this.selected_profiles.length; i++) {
        if (this.selected_profiles[i].diag_profile_id == test_id) {
          this.selected_profiles[i].checked = false;
          this.selected_profiles.splice(i, 1);
        }
      }
      for (var k = 0; k < this.packages.length; k++) {
        console.log("this.profiles" + JSON.stringify(this.packages[k].health_pkg_id))
        if (this.packages[k].health_pkg_id == test_id) {

          this.packages[k].checked = false;
        }
      }
    } else {
      for (var i = 0; i < this.selectedpackages.length; i++) {
        if (this.selectedpackages[i].health_pkg_id == test_id) {
          this.selectedpackages[i].checked = false;
          this.selectedpackages.splice(i, 1);
        }
      }
      for (var k = 0; k < this.packages.length; k++) {
        console.log("this.profiles" + JSON.stringify(this.packages[k].health_pkg_id))
        if (this.packages[k].health_pkg_id == test_id) {

          this.packages[k].health_pkg_id = false;
        }
      }
    }

  }

  public nextvisit;
  Noofdays(date) {
    this.DATE = parseInt(date);
    this.currentDate = moment().format('YYYY-MM-DD');
    this.nextvisit = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
    this.next_datetxt = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
  }
  //next_datetxt_diag

  Noofdaysdiag(date) {
    this.no_of_days_diag = parseInt(date);
    this.currentDate = moment().format('YYYY-MM-DD');
    //  this.next_datetxt = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
    this.next_datetxt_diag = moment().add(this.no_of_days_diag, 'days').format('YYYY-MM-DD')

  }


  clickTestnew(e: any, data) {
    console.log('this.profiles' + JSON.stringify(this.profilesnew))

    if (e == true) {
      for (var i = 0; i < this.profilesnew.length; i++) {
        if (this.profilesnew[i].diag_profile_id == data) {
          var results
          results = this.check_addedTestsnew(this.profiles[i], this.labarray, this.radioarray);
          // alert(results)

          if (results != true) {
            this.profilesnew[i].checked = true;
            this.selected_profilesnew.push(this.profiles[i])

            this.selected_profileIdsnew.push(this.profiles[i].diag_profile_id)
          } else {
            this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
          }
        }
      }

    } else {
      for (var i = 0; i < this.profilesnew.length; i++) {
        if (this.profilesnew[i].diag_profile_id == data) {
          this.profilesnew[i].checked = false;
          for (var h = 0; h < this.selected_profilesnew.length; h++) {
            if (this.selected_profilesnew[h].diag_profile_id == data) {
              this.selected_profilesnew.splice(h, 1)
            }
          }


          for (var k = 0; k < this.selected_profileIdsnew.length; k++) {
            if (this.selected_profileIdsnew[k] == data) {
              this.selected_profileIdsnew.splice(k, 1)
            }
          }
        }
      }
    }
    if (this.selected_profilesnew != 0) {
      this.saveflag = true;
    } else {
      if (this.labarraynew.length == 0 && this.radioarraynew.length == 0 && this.selected_profilesnew.length == 0) {
        this.saveflag = false;
      }
    }
    console.log("this.selected_profiles", this.selected_profilesnew)
  }
  filterLabArraynew(LabtestNamecheck) {
    this.filteredLabArraynew = this.labTest_arraynew.filter(item =>
      item.test_name.toLowerCase().includes(LabtestNamecheck.toLowerCase())
    );
  }

  filterRadioArraynew(radiotestName) {
    this.filteredRadioArraynew = this.radioTest_arraynew.filter(item =>
      item.test_name.toLowerCase().includes(radiotestName.toLowerCase())
    );
  }

  filterMicroArraynew(microtestName) {
    this.filteredMicroArraynew = this.microTest_arraynew.filter(item =>
      item.test_name.toLowerCase().includes(microtestName.toLowerCase())
    );
  }

  async ChangeTestTypenew(event, id, data, type, subtest, test_type, testdept) {
    try {
      this.getFrequencynew(event, id, data, subtest, type, test_type, testdept);
    }
    catch (err) { }
  }

  getFrequencynew(event, id, data, subtest, type, test_type, testdept) {
    var send_data = {
      client_reg_id: this.personalinfo.Client_id,
      relation_id: this.personalinfo.rel_id,
      sub_rel_id: this.personalinfo.sub_rel_id,
      date: this.currentDate,
      test_id: subtest.diag_tests_id,
      interval: subtest.frequency_val,
      test_type: test_type,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gftt', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.test_exists != undefined) {
            if (obj.test_exists == false) {
              if (event == true) {
                if (this.test_exists == false) {
                  var results
                  results = this.finekeyInArray_addTest(this.selected_profiles, id);
                  if (results != true) {


                    if (testdept == 'labtest') {
                      for (var k = 0; k < this.labTest_arraynew.length; k++) {
                        if (this.labTest_arraynew[k].diag_tests_id == id) {
                          this.labTest_arraynew[k].checked = true;
                        }
                      }

                    } else if (testdept == 'radiotest') {
                      for (var k = 0; k < this.radioTest_arraynew.length; k++) {
                        if (this.radioTest_arraynew[k].diag_tests_id == id) {
                          this.radioTest_arraynew[k].checked = true;
                        }
                      }
                    } else if (testdept == 'microtest') {
                      for (var k = 0; k < this.microTest_arraynew.length; k++) {
                        if (this.microTest_arraynew[k].diag_tests_id == id) {
                          this.microTest_arraynew[k].checked = true;
                        }
                      }
                    } else {
                      for (var k = 0; k < this.profilesnew.length; k++) {
                        if (this.profilesnew[k].diag_tests_id == id) {
                          this.profilesnew[k].checked = true;
                        }
                      }
                    }

                    if (subtest.department == "Laboratory") {
                      this.labarraynew.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                      })

                    } else {
                      this.radioarraynew.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                      })
                    }

                    if ((this.labarraynew != undefined && this.labarraynew.length != 0)
                      || (this.radioarraynew != undefined && this.radioarraynew.length != 0)) {
                      this.saveflag = true;
                    } else {
                      this.saveflag = false;
                    }
                  } else {
                    this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
                  }
                  console.log("New Arrays", this.labarraynew, this.radioarraynew)

                } else {
                  this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
                }
              } else if (event == false) {
                if (testdept == 'labtest') {
                  for (var k = 0; k < this.labTest_arraynew.length; k++) {
                    if (this.labTest_arraynew[k].diag_tests_id == id) {
                      this.labTest_arraynew[k].checked = false;
                    }
                  }
                } else if (testdept == 'radiotest') {
                  for (var k = 0; k < this.radioTest_arraynew.length; k++) {
                    if (this.radioTest_arraynew[k].diag_tests_id == id) {
                      this.radioTest_arraynew[k].checked = false;
                    }
                  }
                } else if (testdept == 'microtest') {
                  for (var k = 0; k < this.microTest_arraynew.length; k++) {
                    if (this.microTest_arraynew[k].diag_tests_id == id) {
                      this.microTest_arraynew[k].checked = false;
                    }
                  }
                } else {
                  for (var k = 0; k < this.profilesnew.length; k++) {
                    if (this.profilesnew[k].diag_tests_id == id) {
                      this.profilesnew[k].checked = false;
                    }
                  }
                }

                if (subtest.department == "Laboratory") {
                  for (var i = 0; i < this.labarraynew.length; i++) {
                    if (this.labarraynew[i].test_id == id) {
                      this.labarraynew.splice(i, 1);
                    }
                  }

                } else {
                  for (var i = 0; i < this.radioarraynew.length; i++) {
                    if (this.radioarraynew[i].test_id == id) {
                      this.radioarraynew.splice(i, 1);
                    }
                  }
                }

                if ((this.labarraynew != undefined && this.labarraynew.length == 0)
                  && (this.radioarraynew != undefined && this.radioarraynew.length == 0)
                  && (this.selected_profilesnew != undefined && this.selected_profilesnew.length == 0)) {
                  this.saveflag = false;

                } else {
                  this.saveflag = true;
                }
              }

              this.print_diag = false;
              if (this.subtestname.length != 0) {
                this.print_diag = true;
              }

              if (this.subtesturname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestfname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestsname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestxname.length != 0) {
                this.print_diag = true;

              }
              if (this.subtestuname.length != 0) {
                this.print_diag = true;
              }
              if (this.subtestcname.length != 0) {
                this.print_diag = true;

              }

              console.log("New Arrays", this.labarray, this.radioarray)
            } else {
              this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
            }
          }
        }, error => { });
  }

  removeTestnew(test_id, dept) {

    if (dept == 'lab') {
      for (var k = 0; k < this.labTest_arraynew.length; k++) {
        if (this.labTest_arraynew[k].test_id == test_id) {
          this.labTest_arraynew[k].checked = false;
        }
      }
    } else if (dept == 'radio') {
      for (var k = 0; k < this.radioarraynew.length; k++) {
        if (this.radioarraynew[k].test_id == test_id) {
          this.radioarraynew[k].checked = false;
        }
      }
    } else if (dept == 'microtest') {
      for (var k = 0; k < this.microTest_arraynew.length; k++) {
        if (this.microTest_arraynew[k].test_id == test_id) {
          this.microTest_arraynew[k].checked = false;
        }
      }

    } else if (dept == 'package') {
      for (var k = 0; k < this.packagesnew.length; k++) {
        if (this.packagesnew[k].health_pkg_id == test_id) {
          this.packagesnew[k].checked = false;
        }
      }
    }
    else {
      for (var k = 0; k < this.selected_profilesnew.length; k++) {
        if (this.selected_profilesnew[k].diag_profile_id == test_id) {
          this.selected_profilesnew[k].checked = false;
        }
      }
    }
    if (dept == "lab") {
      for (var i = 0; i < this.labarraynew.length; i++) {
        console.log("this.filteredLabArray" + JSON.stringify(this.filteredLabArray))
        console.log("this.labarray" + JSON.stringify(this.labarray))
        if (this.labarraynew[i].test_id == test_id) {
          this.labarraynew.splice(i, 1);
        }
      }
      for (var k = 0; k < this.filteredLabArraynew.length; k++) {
        if (this.filteredLabArraynew[k].diag_tests_id == test_id) {
          this.filteredLabArraynew[k].checked = false;
        }
      }
      console.log("*****************" + JSON.stringify(this.filteredLabArray))
    } else if (dept == "radio") {
      for (var i = 0; i < this.radioarraynew.length; i++) {
        if (this.radioarraynew[i].test_id == test_id) {
          this.radioarraynew.splice(i, 1);
        }
      }
      for (var k = 0; k < this.filteredRadioArraynew.length; k++) {
        if (this.filteredRadioArraynew[k].diag_tests_id == test_id) {
          this.filteredRadioArraynew[k].checked = false;
        }
      }

    }
    else if (dept == "profile") {
      for (var i = 0; i < this.selected_profilesnew.length; i++) {
        if (this.selected_profilesnew[i].diag_profile_id == test_id) {
          this.selected_profilesnew[i].checked = false;
          this.selected_profilesnew.splice(i, 1);
        }
      }
      for (var k = 0; k < this.profilesnew.length; k++) {
        console.log("this.profiles" + JSON.stringify(this.profilesnew[k].diag_profile_id))
        if (this.profilesnew[k].diag_profile_id == test_id) {
          this.profilesnew[k].checked = false;
        }
      }
    } else {
      for (var i = 0; i < this.selectedpackagesnew.length; i++) {
        if (this.selectedpackagesnew[i].health_pkg_id == test_id) {
          this.selectedpackagesnew[i].checked = false;
          this.selectedpackagesnew.splice(i, 1);
        }
      }
      for (var k = 0; k < this.packagesnew.length; k++) {
        console.log("this.profiles" + JSON.stringify(this.packagesnew[k].health_pkg_id))
        if (this.packagesnew[k].health_pkg_id == test_id) {

          this.packagesnew[k].checked = false;
        }
      }
    }
  }
  changenextvisitdiagtest(nextvisitselection) {
    this.testflagnew = !this.testflagnew
    if (this.testflagnew == false) {
      this.print_nextvisitflag = true;
      this.testnowflag = false;
      //   this.labarray=[];
      //   this.radioarray=[];
      //   this.labarraynew=[];
      //   this.radioarraynew=[];
      //   this.selected_profiles=[];this.selected_profilesnew=[];
      // this.selected_profileIds=[]; this.selected_profileIdsnew=[];
      // this.selectedpackages = []; this.selectedpackageid = [];
      // this.selectedpackagesnew = []; this.selectedpackageidnew = [];
      this.serch_centerchange(this.diag_detail_List[0]);
    } else {
      this.labarraynew = [];
      this.radioarraynew = [];
      this.selected_profilesnew = [];
      this.selected_profileIdsnew = [];
      this.selectedpackageidnew = [];
      this.testnowflag = true;
      this.serch_centerchange(this.diag_detail_List[0]);
    }
  }
  isInputRequired() {
    if (Helper_Class.getInpatientFlow() == "nurse") {
      this.isRequired = false
    } else {
      this.isRequired = true
    }
  }

  clickAppDate(data) {
    this.appDate = data;
    var dataval = Date_Formate(this.currentDate);

    if (data != dataval) {
      this.getVisitingSessionArray = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      this.visitSession = this.getVisitingSessionArray[0].description;
    }
  }

  selectSession() {
    if (this.visitSession == "Morning") {
      this.timeSession = "AM";
      this.startTime = "06";
      this.endTime = "00";
    } else if (this.visitSession == "Evening") {
      this.startTime = "06";
      this.endTime = "00";
      this.timeSession = "PM";
    } else {
      this.timeSession = "PM";
      this.startTime = "12";
      this.endTime = "00";
    }
    this.sessionFlag = false;
    // this.enableBooking();
  }
  timeconversion(timeString, session) {
    var timeParts = timeString.split(":");
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);

    if (session === "Afternoon") {
      hours = (hours + 12) % 24;
    } else if (session === "Evening") {
      hours = (hours + 12) % 24;
    }

    var result = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    return result
    console.log(result);

  }
  check_addedTestsnew(profile_array, lab_array, radio_array) {
    var flag = false;
    for (var i = 0; i < profile_array.tests.length; i++) {
      if (lab_array != undefined && lab_array.length != 0) {
        for (var j = 0; j < lab_array.length; j++) {
          if (profile_array.tests[i].diag_tests_id == lab_array[j].test_id) {
            flag = true;
            break;
          }
        }
      }

      if (radio_array != undefined && radio_array.length != 0) {
        for (var k = 0; k < radio_array.length; k++) {
          if (profile_array.tests[i].diag_tests_id == radio_array[k].test_id) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }
  finekeyInArray_addTest(array, id) {
    // alert(id + "    " + JSON.stringify(array))
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].tests.length; j++) {
        if (array[i].tests[j].diag_tests_id === id) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }
  finekeyInArray_addTestnew(array, id) {
    // alert(id + "    " + JSON.stringify(array))
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].tests.length; j++) {
        if (array[i].tests[j].diag_tests_id === id) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }
  mainFilternew(testName) {
    this.filterLabArraynew(testName)
    this.filterRadioArraynew(testName)
    this.filterMicroArraynew(testName)
    this.filterProfileArraynew(testName)
    this.filterPacakgeArraynewvalue(testName)
  }
  filterProfileArraynew(profileName) {
    this.filteredProfilesArraynew = this.profilesnew.filter(item =>
      item.profile_name.toLowerCase().includes(profileName.toLowerCase())
    );
  }
  filterPacakgeArraynewvalue(profileName) {
    this.filteredpackageArraynew = this.packagesnew.filter(item =>
      item.package_name.toLowerCase().includes(profileName.toLowerCase())
    );
  }
  changepackage(e: any, data) {
    console.log('this.profiles' + JSON.stringify(this.packages))
    console.log('this.profiles ****' + JSON.stringify(data))
    if (e == true) {
      for (var i = 0; i < this.packages.length; i++) {
        if (this.packages[i].health_pkg_id == data) {
          var results
          results = this.check_addedTestnew(this.packages[i], this.labarray, this.radioarray);
          // alert(results)

          if (results != true) {
            this.packages[i].checked = true;
            this.selectedpackages.push(this.packages[i])
            this.selectedpackageid.push(this.packages[i].health_pkg_id)
            //this.packages.push(this.packages[i].health_pkg_id)
          } else {
            this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
          }
          console.log("package datta" + JSON.stringify(this.selectedpackages))
        }
      }

    } else {
      for (var i = 0; i < this.packages.length; i++) {
        if (this.packages[i].health_pkg_id == data) {
          this.packages[i].checked = false;
          for (var h = 0; h < this.selectedpackages.length; h++) {
            if (this.selectedpackages[h].health_pkg_id == data) {
              this.selectedpackages.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selectedpackageid.length; k++) {
            if (this.selectedpackageid[k] == data) {
              this.selectedpackageid.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selectedpackages)
    if (this.selectedpackages.length != 0) {
      this.saveflag = true;
    } else {
      if (this.labarray.length == 0 || this.radioarray.length == 0 && this.selected_profiles.length == 0 || this.selectedpackages.length == 0) {
        this.saveflag = false;
      }
    }
    console.log("this.selected_profiles", this.selected_profiles)
  }

  changepackageNew(e: any, data) {
    console.log('this.profiles' + JSON.stringify(this.packages))
    console.log('this.profiles ****' + JSON.stringify(data))
    if (e == true) {
      for (var i = 0; i < this.packagesnew.length; i++) {
        if (this.packagesnew[i].health_pkg_id == data) {
          var results
          results = this.check_addedTestnew(this.packagesnew[i], this.labarray, this.radioarray);
          // alert(results)

          if (results != true) {
            this.packagesnew[i].checked = true;
            this.selectedpackagesnew.push(this.packagesnew[i])
            this.selectedpackageidnew.push(this.packagesnew[i].health_pkg_id)
            //this.packages.push(this.packages[i].health_pkg_id)
          } else {
            this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
          }
          console.log("package datta" + JSON.stringify(this.selectedpackagesnew))
        }
      }

    } else {
      for (var i = 0; i < this.packagesnew.length; i++) {
        if (this.packagesnew[i].health_pkg_id == data) {
          this.packagesnew[i].checked = false;
          for (var h = 0; h < this.selectedpackagesnew.length; h++) {
            if (this.selectedpackagesnew[h].health_pkg_id == data) {
              this.selectedpackagesnew.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selectedpackageidnew.length; k++) {
            if (this.selectedpackageidnew[k] == data) {
              this.selectedpackageidnew.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selectedpackagesnew)
    if (this.selectedpackagesnew.length != 0) {
      this.saveflag = true;
    } else {
      if (this.labarraynew.length == 0 || this.radioarraynew.length == 0 || this.selected_profilesnew.length == 0 || this.selectedpackagesnew.length == 0) {
        this.saveflag = false;
      }
    }
  }

  check_addedTestnew(packagearray, lab_array, radio_array) {
    console.log("add new pacj" + JSON.stringify(packagearray))
    var flag = false;
    for (var i = 0; i < packagearray.length; i++) {

      if (this.selectedpackages != undefined && this.selectedpackages.length != 0) {
        for (var k = 0; k < this.selectedpackages.length; k++) {
          if (packagearray[i].health_pkg_id == this.selectedpackages[k].health_pkg_id) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }
  isSOSRequired(every_six: string): boolean {
    return every_six.toLowerCase().includes('sos required');
  }

  isStatRequired(every_six: string): boolean {
    return every_six.toLowerCase().includes('stat required');
  }

  //  calculateTotalPrice(): number {
  //   let totalPrice = 0;

  //   function calculatePriceFromArray(testArray: TestItem[]): void {
  //     const selectedItems = testArray.filter((item) => item.checked);
  //     selectedItems.forEach((item) => {
  //       totalPrice += item.price;
  //     });
  //   }

  //   // calculatePriceFromArray(labTest_array);
  //   // calculatePriceFromArray(radioTest_array);
  //   // calculatePriceFromArray(microTest_array);
  //   // calculatePriceFromArray(profiles);

  //   return totalPrice;
  // }

  // // Example usage
  // // Assuming items in the arrays have already been updated based on selection
  // const totalSelectedPrice = this.calculateTotalPrice();
  // console.log(totalSelectedPrice);
  calculatePriceFromArray(testArray) {
    let totalPrice = 0;
    let updatedArray = testArray.map((item) => {
      if (item.checked) {
        totalPrice += parseInt(item.price, 10);
      }
      return { ...item };
    });
    return { totalPrice, updatedArray };
  }

  addTotalamount(labTest_array, radioTest_array, microTest_array, profiles) {
    let totalPrice = 0;
    let updatedArrays = [];

    function processArray(testArray) {
      let updatedArray = testArray.filter((item) => {
        if (item.checked) {
          totalPrice += parseInt(item.price, 10);
          return true; // Keep the item in the array
        }
        return false; // Remove the item from the array
      });
      updatedArrays.push(...updatedArray);
    }

    processArray(labTest_array);
    processArray(radioTest_array);
    processArray(microTest_array);
    processArray(profiles);

    return { totalPrice, updatedArrays };
  }


  // Example usage
  commonredirect(medid, diagid) {
    console.log("data cheiheh" + JSON.stringify(this.personalinfo.AppFlow))
    console.log("data cheiheh" + JSON.stringify(this.personalinfo.AppFlow))
    console.log("data cheiheh" + JSON.stringify(this.personalinfo.AppFlow))

    // var bodytext;
    if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
      if (Doc_Helper.getDiagAppflow() == 'medical') {
        Doc_Helper.setMedSave(true);
        this.listProducts = [];
        //this.appmedpresList();
        this.addmed = false;
        this.doct_clientname = "";
        this.mobile = "";
        this.client_age = "";
        this.client_gender = "";
        this.oberse_med = "";
        this.disease_name = "";
        this.refer_txt = "";
        this.clnt_bp = "";
        this.temparature = "";
        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (Helper_Class.getassflow() != undefined) {
            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
              this.naviagationService.sendMessage("docassessment");
            }
          }
          else {
            this.naviagationService.sendMessage("inPatientViewInfo");
          }
        }
        else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
          this.naviagationService.sendMessage("docassessment");
        } else {
          this.naviagationService.sendMessage("appointment_list");
        }
        //this.messageservice.sendDiagComponent("appointment_list");
      } else {
        Doc_Helper.setMedDiag(true);
        this.subtestbname = [];
        this.subtestfname = [];
        this.subtestname = [];
        this.subtestsname = [];
        this.subtestuname = [];
        this.subtesturname = [];
        this.subtestxname = [];
        this.subtestcname = [];
        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (Helper_Class.getassflow() != undefined) {
            if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
              this.naviagationService.sendMessage("docassessment");
            }
          }
          else {
            this.naviagationService.sendMessage("inPatientViewInfo");
          }
        }
        else if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
          this.naviagationService.sendMessage("docassessment");
        } else {
          this.naviagationService.sendMessage("appointment_list");
        }
        //this.messageservice.sendDiagComponent("appointment_list");
      }
      this.saveflag = false;
      this.printFlag = false;
      this.toastr.success(Message_data.prescriptionSavedSuccess);
    }
    if (this.personalinfo.AppFlow == "Doc_app_list") {
      //alert("cihihouh")
      if (Doc_Helper.getDiagAppflow() == 'medical') {
        Doc_Helper.setMedSave(true);
        this.listProducts = [];
        //  this.appmedpresList();
        this.addmed = false;
        this.doct_clientname = "";
        this.mobile = "";
        this.client_age = "";
        this.client_gender = "";
        this.oberse_med = "";
        this.disease_name = "";
        this.refer_txt = "";
        this.clnt_bp = "";
        this.temparature = "";

        //this.messageservice.sendDiagComponent("appointment_list");
      } else {
        // alert("cihi3333333houh")
        Doc_Helper.setMedDiag(true);
        this.subtestbname = [];
        this.subtestfname = [];
        this.subtestname = [];
        this.subtestsname = [];
        this.subtestuname = [];
        this.subtesturname = [];
        this.subtestxname = [];
        this.subtestcname = [];
        //this.messageservice.sendDiagComponent("appointment_list");
      }

      this.saveflag = false;
      this.printFlag = false;
      this.toastr.success(Message_data.prescriptionSavedSuccess);
    }
    var diagpres = Helper_Class.get_diag_pres_id();
    if (this.diagpresflag == false && diagpres == null) {
      // alert("00000kkk"+JSON.stringify(this.personalinfo.AppFlow ))
      if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
        if (Helper_Class.getassflow() != undefined) {
          if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
            this.naviagationService.sendMessage("docassessment");
          }
        }
        else {
          this.naviagationService.sendMessage("inPatientViewInfo");
        }
      }
      else if (Helper_Class.getassflow() != undefined) {
        if (Helper_Class.getassflow().AppFlowkey == "Assessment") {
          this.naviagationService.sendMessage("docassessment");
        } else {
          this.naviagationService.sendMessage("appointment_list");
        }
      } else {
        //alert("00000kkk"+JSON.stringify(Helper_Class.getassflow()))
        this.naviagationService.sendMessage("appointment_list");
      }
      // this.showConfirm(bodytext)
    } else {
      this.backtoApplist();
    }

    var dat = this.check_pres;
    if (dat == "0") {
      this.check_pres = "1";

    } else {
      var seconds = 1;
      setInterval(function () {
        seconds--;

      }, 500);
    }

    this.book_app_now = false;
    this.next_datetxt = undefined;
  }

}
