import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper';
import { Date_Formate, ConvertTimeformat, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-ipatient-bill-create',
  templateUrl: './ipatient-bill-create.component.html',
  styleUrls: ['./ipatient-bill-create.component.scss'],
})
export class IpatientBillCreateComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public save_billcreate: any;
  public quantityArray = [];
  tomorrow = new Date();
  public doc_visitscreate = [];
  public warddetails_billcreate = [];
  public bill_palnche;
  public appoint_txt;
  public charge_type: string;
  public charge_id: string;
  public amount_txt;
  public final_amount: any = '0.00';
  public totalBillAmount: any = '0.00';
  public inGST: boolean;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;
  public concession_txt;
  public con_txt;
  public session: string;
  public amount_aftcon: any;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public appointment_list: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public middle_appoin: string;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];
  public gst_data_list: any = [];
  public chk_treat_arr: any = [];
  public charge_name;
  public estimate_id: any = [];
  public paytypelist: any = [];
  public insurerlist: any = [];
  public pay_type: string;
  public insurer_id: string;
  public insurFlag: boolean;
  public remaing_billbal = '0.00';
  public addvancetxt;
  public totalcolletxt;
  public cent_gst;
  public sta_gst;
  public doc_client_reg_id;
  public doc_relation_id;
  public doc_sub_rel_id;
  public Charge_id;
  public charge_desc;
  public hptl_clinic_id: string;
  public header_footer_flag: boolean;
  public addbtn_flag: boolean;
  public bill_date;
  public current_amount: any = '0.00';
  public billdate;
  public diet_followtable: boolean;
  public Gsthidden: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;
  public cenra_gst;
  public stat_gst;
  public prev_bal: string;
  public total_bill: string;
  public total_bill1: string;
  public currentDate;
  public userinfo;
  public tempRoundoff = '0.00';
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public persons: any = [];
  public masterSelected: boolean = false;
  public checklist: any;
  public checkedList: any;
  public user_id;
  //Get apointment details
  public patient_name: string;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public round_off;
  public paid_bill;
  public sign;
  public billing_print_array;
  public medcalDetailsArray: any = [];
  public digDetailsArray: any = [];
  public hospitalAddress;
  public Hospital_location;
  public diagnosisAmount = '0.00';
  public medicine_amount = '0.00';
  public medicinBal = '0.00';
  public dog_reg_id;
  public serviceType: any = [];
  public inpatientEdit: boolean = false;
  public quantity: any = '1';
  public hospitalBills = '0.00';
  public saveBillURL;
  public bill_id;
  public pre_paid_bill = '0.00';
  public pre_bill_amount = '0.00';
  public pharmaPaid = '0.00';
  public diagPaid = '0.00';
  public show_save_btn: boolean = true;
  public bill_cons_flag: boolean = true;
  public bill_qty_flag: boolean = true;
  private rowid: number = 1;
  public bill_time1;
  public bill_time2;
  public bill_time3;
  public bill_hrs: any = [];
  public bill_min: any = [];
  public currentTime;
  public department_id;
  public ward_id;
  public chargeNameArray: any = [];
  public transId;
  public RateCardArray = [];
  public rate_card;
  public checkBox: any;
  public paytypreq: boolean = false;
  public credit_flag: boolean = true;
  public hosp_credits: any = [];
  public credit_type:string;
  public credit_bill;
  public payType1;
  public amountval: any;
  public paymentsarray: any = [];
  public mrno;
  public clnt_saldesc;
  public clnt_age;
  public gender;
  public mobile_no;
  public e_contact;
  public admitted_date;
  public admitted_time;
  public discharge_date;
  public discharge_time;
  public createpage_flag: boolean = false;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public bulkAddFlag: boolean = false;
  public credit_person;
  public bill_head;
  public billId;
  public numToWords: any;
  public time;
  public temp_key: any;
  public print_size: any = null;
  public showModal: boolean = false;
  public cashDenomination: { value: number; count: number }[] = [
    { value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 },
    { value: 5, count: 0 },
    { value: 2, count: 0 },
    { value: 1, count: 0 },
  ];
  public totalAmount: number;
  public doctorVisitsChargeArray = [];
  public hospitalLogo: any;
  public printlogostyle: any;
  public print_template: any;
  public doctor_name: any;
  public dept_desc: any;
  public wardName: any;
  public bed_no: any;
  public profileImg: string;
  public discount_amount: any = '0.00';
  public tot_pay_array_amount: any;
  public filter: string;
  public toFlag: boolean;
  public fromFlag: boolean;
  public date;
  public firstNameFlag: boolean;
  public firstName: string;
  public uhidFlag: boolean;
  public patientId;
  public mobileFlag: boolean;
  public mobileNo: string;
  public lastName: string;
  public mr_no_flag: boolean;
  public total: string;
  public other_charges_array = [];
  public sendAdvanceAmount: any;
  public dateval: moment.Moment;
  public enddate: any;
  public endtime: any;
  public HalfDayHour: number;
  public DayHour: number;
  public HourlyHour: number;
  public cardFlag: boolean;
  public cardNumber;
  public cardreqflag: boolean = false;
  public cardHolderName;
  public transactionFlag: boolean;
  public transactionId;
  public transreqflag: boolean = false;
  public chequeFlag: boolean;
  public chequereqflag: boolean = false;
  public bankName;
  public insreqflag: boolean = false;
  public discount_flag:boolean = false;
  public open_app_billing;
  public wardbasecharge:string;
  public surgery: any;
  public barcode_url;
  public barcode_flag:boolean = false;
  public logUserName;
  public barcode_style;
  public nobanner_style;
  public inpatient_flag: boolean = true;
  public multiply_flag: boolean = true;
  public amount_td_style;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15; 
  background = '#ffffff'; 
  

  constructor(
    public doctorservice: MenuViewService,
    public toastr: ToastrService,
    public http: Http,
    public router: Router,
    public messageservice: MenuViewService,
    public masterData: MasterHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.addbtn_flag = true;
    this.insurFlag = true;
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.billdate = new FormControl(new Date());
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.bill_time1 = '';
    this.bill_time2 = '00';
    this.amount_txt = ' ';
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.bill_hrs.push('0' + i);
      } else {
        this.bill_hrs.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.bill_min.push('0' + i);
      } else {
        this.bill_min.push(i);
      }
    }
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.mr_no_flag = false;
    this.discount_flag = false;
  }

  ngOnInit(): void {
    this.profileImg = '../../assets/img/default.jpg';
    this.show_save_btn = true;
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);
    this.wardbasecharge = this.userinfo.ward_base_charge;
    this.print_size = this.userinfo.bill_print_paper;
    if (this.userinfo.bill_pay_type_req == '1') {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    // this.paytypreq = false;
    this.pay_type = 'Cash';
    this.hospital_logo =
      ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    if (this.userinfo.bulk_add_req === '1') {
      this.bulkAddFlag = true;
    }
    this.dtOptions = {
      pageLength: 25,
      lengthMenu: [
        [25, 50, -1],
        [25, 50, 'All'],
      ],
      columnDefs: [{ orderable: false, targets: [2, 3, 4, 5, 6] }],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: 'Search chargetypes by name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };

    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == '7') {
          if (this.Moduleidlist[i].edit == '1') {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == '1') {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == '1') {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == '1') {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == '1') {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.doctor_profile_img = this.userinfo.profile_image;
    this.amount_change();
    this.getPaymentType();
    this.getCreditTypes();
    this.final_amount = '0.00';
    this.discount_amount = '0.00';
    this.totalBillAmount = '0.00';
    this.gst_amount_txt = '0.00';
    this.Advance_bill = '0.00';
    this.paid_bill = '0.00';
    this.round_off = '0.00';
    this.concession_txt = 0;
    this.quantity = '1';
    this.inGST = false;
    this.Get_current_date();
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.Get_Gst_Data();
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
    this.getInsurers();
    this.RateCardData();
    this.saveBillURL = 'bill/savebill/';

    if (Helper_Class.getInfo().bill_cons_flag == 0) {
      this.bill_cons_flag = true;
      
    } else {
      this.bill_cons_flag = false;
    }


    if (Helper_Class.getInfo().bill_qty_flag == 0) {
      console.log(Helper_Class.getInfo().bill_qty_flag);
      this.bill_qty_flag = true;
    } else {
      console.log(Helper_Class.getInfo().bill_qty_flag);
      this.bill_qty_flag = false;
    }
    
    console.log("Bill_Qty_Flag",this.bill_qty_flag);

    if (this.userinfo.bill_print_template != undefined) {
      this.print_template = this.userinfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }

    if (this.userinfo.open_app_billing != null) {
      this.open_app_billing = this.userinfo.open_app_billing;
    }

    if (this.userinfo.first_name != undefined) {
      this.logUserName = this.userinfo.first_name;
      if (this.userinfo.last_name != undefined) {
        this.logUserName = this.userinfo.first_name+" "+this.userinfo.last_name;
      }
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.filter = 'Patient_ID';
    this.cdr.detectChanges();
    this.focusMRNoInput();
  }

  focusMRNoInput() {
    this.patientId = '';
    document.getElementById('focusMRNo').focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusMobileNoInput() {
    this.mobileNo = '';
    document.getElementById('focusMobileNo').focus();
    this.focusMobileNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = '';
    document.getElementById('focusFName').focus();
    this.focusFName.nativeElement.focus();
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userinfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => {}
      );
  }

  backClicked() {
    if (this.userinfo.user_type == 'Admin') {
      this.messageservice.sendMessage('frontdeskadminbilling');
    } else {
      if (Helper_Class.getInpatientBillCreate() == 'front-desk') {
        this.messageservice.sendMessage('frontdeskbilling');
      } else {
          Doc_Helper.setIpCreateBill('ipbill');
          this.doctorservice.sendMessage('billing');
      }    
    }
  }   
  

  clearData() {
    this.createpage_flag = false;
    this.profile_image = '';
    this.patientId = '';
    this.firstName = '';
    this.lastName = '';
    this.mobileNo = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.billing_print_array = [];
    this.mobileNo = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.appointment_list = [];
    this.createpage_flag = false;
    this.billaddlist = [];
    this.appoint_txt = '';
    this.totalBillAmount = '0.00';
    this.discount_amount = '0.00';
    this.final_amount = '0.00';
    this.remaing_billbal = '0.00';
    this.amountval = 0;
    this.paid_bill = '0.00';
    this.payType1 = this.paytypelist[0].pay_id;
    this.paymentsarray = [];
    this.gst_amount_txt = '0';
    this.Advance_bill = '0.00';
    this.previous_balshow = true;
    this.balance_show = true;
  }

  changeFilter() {
    this.appointment_list = [];
    this.clearData();
    if (this.filter == 'Date') {
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.searchPatient();
    } else if (this.filter == 'Name') {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();
    } else if (this.filter == 'Patient_ID') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMRNoInput();
    } else if (this.filter == 'Mobile_No') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMobileNoInput();
    }
  }

  OnDateChangedto(e) {
    this.date = e;
    this.clearData();
    this.searchPatient();
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if (patientId != undefined && patientId.length > 8) {
      this.searchPatient();
    }
  }

  getPatientListByMobile_no(mobileNo) {
    if (mobileNo != undefined && mobileNo.length > 9) {
      this.searchPatient();
    }
  }

  RateCardData() {
    this.RateCardArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var requestData = { 
      centre_id: this.hptl_clinic_id,
      center_type:'hospital',
    };
    this.http
      .post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          var RateCard = obj.rate_cards;
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default,
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for (var i = 0; i < this.RateCardArray.length; i++) {
              if (this.RateCardArray[i].default == '1') {
                this.rate_card = this.RateCardArray[i].rate_card_id;
              }
            }
            console.log(this.RateCardArray);
            this.getConcession();
            this.charge_name = Helper_Class.getBillCharges();
            this.charge_name = this.charge_name.charges;
            console.log(this.charge_name);
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  //Bill add table
  Bill_add_click() {
    this.diet_followtable = true;
    this.addbtn_flag = true;

    if (this.appoint_txt == undefined) {
      this.toastr.error(Message_data.sltAppId);
    } else if (this.charge_type == undefined) {
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);
    } else {
      if (this.final_amount == '0.00') {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;
      var flag = true;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (
          this.billaddlist[i].biill_type == this.charge_type &&
          this.billaddlist[i].paytype == this.pay_type
        ) {
          flag = false;
          break;
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.charge_type == this.charge_name[i].description) {
              this.charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }
        this.con_txt = this.concession_txt == '' ? 0 : this.concession_txt;
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }

        this.total = (parseFloat(this.quantity) * parseFloat(this.amount_txt)).toFixed(2);
        var result = this.finekeyInArray_billaddChargeType(
          this.billaddlist,
          this.charge_id
        );
        if (result == true) {
          this.toastr.error('Charge type already exists');
        } else if (this.paytypreq == true) {
          this.billaddlist.push({
            sno: this.rowid,
            biill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: this.con_txt,
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            quantity: parseFloat(this.quantity).toFixed(2),
            total: this.total,
          });
        } else {
          this.billaddlist.push({
            sno: this.rowid,
            biill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: this.con_txt,
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            ref_code: this.transId,
            quantity: parseFloat(this.quantity).toFixed(2),
            total: this.total,
          });
        }

        this.hospitalBills = '0.00';
        this.rowid = 1;
        if (this.billaddlist.length != 0) {
          for (var i = 0; i < this.billaddlist.length; i++) {
            if (this.billaddlist[i].fee == '') {
              this.billaddlist[i].fee = '0.00';
            }
            this.hospitalBills = (
              parseFloat(this.hospitalBills) +
              parseFloat(this.billaddlist[i].fee)
            ).toFixed(2);
            this.billaddlist[i].sno = this.rowid;
            this.rowid += 1;
          }
        }
        this.charge_type = ' ';
        this.amount_txt = ' ';
        this.amount_aftcon = ' ';
      }

      if (this.final_amount == '0.00') {
        this.inGST == false;
      }

      this.Calculate_gst('0', '0');
      this.checkBox = true;
      this.checkBoxTrueOrFalse(this.charge_id, this.checkBox);
    }
  }

  finekeyInArray_billaddChargeType(array, biill_type) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type == biill_type) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  updateBillList(charge) {
    var results = this.finekeyInArray_billadd(
      this.billaddlist,
      charge.charge_type
    );
    if (charge.selected) {
      if (results == false) {
        this.charge_type = charge.biill_type;
        this.amount_txt = parseFloat(charge.amount_txt).toFixed(2);
        this.concession_txt = charge.concession;
        this.amount_aftcon = parseFloat(charge.fee).toFixed(2);
        this.charge_id = charge.charge_type;
        this.insurer_id = charge.insurer;
        this.transId = charge.ref_code;
        this.quantity = charge.quantity;
        setTimeout(() => {
          this.Bill_add_click();
        }, 500);
      }
    } else {
      this.Delete_bill(charge);
    }
  }

  Delete_bill(charge) {
    this.checkBox = false;
    this.checkBoxTrueOrFalse(charge.charge_type, this.checkBox);
    if (charge.charge_type.length != 0) {
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (
            this.billaddlist[i].charge_type == charge.charge_type &&
            this.billaddlist[i].amount_txt == charge.amount_txt
          ) {
            this.billaddlist.splice(i, 1);
            break;
          }
        }
      }
      this.rowid = 0;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].status != '2') {
          this.rowid += 1;
          this.billaddlist[i].sno = this.rowid;
        }
      }

      this.hospitalBills = '0.00';
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].fee == '') {
            this.billaddlist[i].fee = '0.00';
          }
          this.hospitalBills = (
            parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
          ).toFixed(2);
        }
      }
      if (this.billaddlist.length == 0) {
        this.table_div = false;
        this.final_div = false;
      } else {
        this.table_div = true;
      }

      if (this.final_amount == '0.00') {
        this.inGST == false;
      }
      this.Calculate_gst('0', '0');
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.sno == this.billaddlist[i].sno) {
          if (charge.biill_type == '') {
            this.billaddlist.splice(i, 1);
            this.Calculate_gst('0', '0');
            break;          
          }      
        }
      }
      this.rowid = 1;
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].sno = this.rowid;
        this.rowid += 1;
      }
    }
  }

  finekeyInArray_billadd(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  getBillingCharges() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.hptl_clinic_id,
          provider: 'doctor',
          type: 'in-patient',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          this.charge_name = obj.charges;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  Get_Bill_Type() {
    for (var i = 0; i < this.RateCardArray.length; i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        this.concession_txt = this.RateCardArray[i].discount;
      }
    }

    var paydesc;
    if (this.paytypelist.length != 0) {
      for (var i = 0; i < this.paytypelist.length; i++) {
        if (this.pay_type == this.paytypelist[i].pay_id) {
          paydesc = this.paytypelist[i].pay_desc;
        }
      }
    }

    if (this.charge_name == undefined) {
      this.getBillingCharges();
    }
    
    var totamount = 0;
    var quantity: any = 1;
    if (this.charge_name != undefined && this.charge_name.length != 0) {      
      for (var i = 0; i < this.charge_name.length; i++) {
        var perce!: number | null;
        perce = this.concession_txt / 100;
        var curdateval = moment(
          this.enddate[2] + '/' + this.enddate[1] + '/' + this.enddate[0] + ' ' + this.endtime,
          'DD/MM/YYYY HH:mm'
        );
        console.log(curdateval);
        if (this.charge_name[i].charge_base != undefined && this.charge_name[i].charge_base != "") {
          if (this.charge_name[i].charge_base === '1') {  
            var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 24).toFixed(2).split('.');          
            this.DayHour = parseInt(hourdif[0]);
            console.log(this.DayHour);
            var remhour = Math.round(parseInt(hourdif[1]) / 24);
            console.log(remhour);
            if (remhour > 12) {
              this.quantity = this.DayHour + 1;
            } else {
              this.quantity = this.DayHour;
            }    

            if (this.quantity == 0) {
              this.quantity = 1;
            }
            console.log("DAY QUANTITY",this.quantity);                 
          }
          else if (this.charge_name[i].charge_base === '2') {
            var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 12).toFixed(2).split('.');
            this.HalfDayHour = parseInt(hourdif[0]);
            console.log(this.HalfDayHour);
            var remhour = Math.round(parseInt(hourdif[1]) / 12);
            console.log(remhour);
            if (remhour > 6) {          
              this.quantity = this.HalfDayHour + 0.5;
            } else {
              this.quantity = this.HalfDayHour;
            }      
            
            if (this.quantity == 0) {
              this.quantity = 1;
            }
            console.log("HALF_DAY QUANTITY", this.quantity);               
          }
          else if (this.charge_name[i].charge_base === '3') {           
            var hourdif = (moment(curdateval, "DD/MM/YYYY HH:mm").diff(moment(this.dateval, "DD/MM/YYYY HH:mm"), 'minutes')).toFixed(2).split('.');
            console.log(hourdif);           
            var totalBulkMinutes = parseInt(hourdif[0]);         
            console.log(totalBulkMinutes);
            this.HourlyHour = (totalBulkMinutes / 60);
            console.log(this.HourlyHour);
            this.HourlyHour = Math.round(this.HourlyHour);
            console.log(this.HourlyHour);
            var remainingMinutes = totalBulkMinutes % 60;
            console.log(remainingMinutes);
            console.log(this.HourlyHour + " hours and " + remainingMinutes + " minutes");         
            if (remainingMinutes > 30) {
              this.quantity = this.HourlyHour + 1;          
            } else {
              this.quantity = this.HourlyHour;
            }

            if (this.quantity == 0) {
              this.quantity = 1;
            }
            console.log("HOURLY QUANTITY",this.quantity);
          } 
          else if (this.charge_name[i].charge_base === '4') {
            this.quantity = 1;
            console.log("COUNT QUANTITY",this.quantity);
          } 
        } else {
          this.quantity = 1;
          console.log("ELSE_QUANTITY",this.quantity);
        }
        console.log("QUANTITY",this.quantity)
        quantity = this.quantity;

        var amt = this.charge_name[i].amount;
        if (quantity > 1) {
          amt = amt * quantity;
        } else if (quantity == undefined || quantity == 0) {
          quantity = 1;
        }

        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amount_aftcon = Math.round(tot);

        var total;
        total = (
          parseFloat(quantity) * parseFloat(this.charge_name[i].amount)
        ).toFixed(2);

        if (
          this.charge_name[i].auto_generate != null &&
          this.charge_name[i].auto_generate == '1'
        ) {
          if (
            this.department_id == this.charge_name[i].department_id &&
            this.ward_id == this.charge_name[i].ward_id
          ) {
            this.billaddlist.push({
              sno: this.rowid,
              biill_type: this.charge_name[i].description,
              amount_txt: parseFloat(this.charge_name[i].amount).toFixed(2),
              concession: this.concession_txt,
              fee: parseFloat(this.amount_aftcon).toFixed(2),
              charge_type: this.charge_name[i].charge_id,
              pay_type: paydesc,
              paytype: this.pay_type,
              insurer: this.insurer_id,
              quantity: parseFloat(quantity).toFixed(2),
              total: total,
              edit_flag: true
            });
            totamount += parseFloat(this.amount_aftcon);
          }
        }
      }
    }
    console.log('charge-array',this.charge_name);
    console.log('DoctorVisitArray', this.doc_visitscreate);
    console.log('doctorVisitsChargeArray', this.doctorVisitsChargeArray);
    console.log('quantityArray', this.quantityArray);
    console.log('Other_charges_array',this.other_charges_array);
    if (this.doc_visitscreate != undefined) {
      if (this.doc_visitscreate.length != 0) {        
        for (var j = 0; j < this.doc_visitscreate.length; j++) {      
          var perce!: number | null;
          perce = this.concession_txt / 100;
          quantity = 1;
          console.log(this.doc_visitscreate[j].fee);
          if (this.doc_visitscreate[j].fee == "" || this.doc_visitscreate[j].fee == undefined) {
            this.doc_visitscreate[j].fee = 0;
          }
          var amt = this.doc_visitscreate[j].fee;
          if (quantity > 1) {
            amt = amt * quantity;
          } else if (quantity == undefined || quantity == 0) {
            quantity = 1;
          }

          var net_pay = parseFloat(amt) * parseFloat(perce.toString());
          var tot = amt - net_pay;
          if (isNaN(tot)) {
            tot = 0;
          }

          this.amount_aftcon = Math.round(tot);

          var total;
          total = (
            parseFloat(quantity) * parseFloat(this.doc_visitscreate[j].fee)
          ).toFixed(2);

          if (this.doc_visitscreate[j].doc_reg_id != undefined) {
            this.billaddlist.push({
              sno: this.rowid,
              biill_type: this.doc_visitscreate[j].charge_desc,
              amount_txt: parseFloat(this.doc_visitscreate[j].fee).toFixed(2),
              concession: this.concession_txt,
              fee: parseFloat(this.amount_aftcon).toFixed(2),
              quantity: parseFloat(quantity).toFixed(2),
              charge_type: this.doc_visitscreate[j].doc_reg_id,
              pay_type: paydesc,
              paytype: this.pay_type,
              insurer: this.insurer_id,
              total: total,
              edit_flag: true
            });
          }          
        }       
        totamount += parseFloat(this.amount_aftcon);
      }
    }

    if (this.other_charges_array != undefined && this.other_charges_array.length != 0) {
      for (var j = 0; j < this.other_charges_array.length; j++) {
        for (var i = 0; i < this.charge_name.length; i++) {
          var perce!: number | null;
          perce = this.concession_txt / 100;
         
          quantity = this.other_charges_array[j].quantity;

          var amt = this.charge_name[i].amount;
          if (quantity > 1) {
            amt = amt * quantity;
          } else if (quantity == undefined || quantity == 0) {
            quantity = 1;
          }

          var net_pay = parseFloat(amt) * parseFloat(perce.toString());
          var tot = amt - net_pay;
          if (isNaN(tot)) {
            tot = 0;
          }

          this.amount_aftcon = Math.round(tot);
          var total;
          total = (
            parseFloat(quantity) *
            parseFloat(this.charge_name[i].amount)
          ).toFixed(2);

          if (this.other_charges_array[j].bill_charge_id == this.charge_name[i].charge_id) {
            console.log(total);
            this.billaddlist.push({
              sno: this.rowid,
              biill_type: this.charge_name[i].description,
              amount_txt: parseFloat(this.charge_name[i].amount).toFixed(2),
              concession: this.concession_txt,
              fee: parseFloat(this.amount_aftcon).toFixed(2),
              charge_type: this.charge_name[i].charge_id,
              paytype: this.pay_type,
              pay_type: paydesc,
              insurer: this.insurer_id,
              quantity: parseFloat(quantity).toFixed(2),
              total: total,
              edit_flag: true
            });
            totamount += parseFloat(this.amount_aftcon);
          }         
        }
      }
    }

    if (this.billaddlist.length == 0) {
      this.addNewBillDetails();
    }

    this.rowid = 1;
    for (var i = 0; i < this.billaddlist.length; i++) {
      this.billaddlist[i].sno = this.rowid;
      this.rowid += 1;
      if (this.billaddlist[i].fee == '') {
        this.billaddlist[i].fee = '0.00';
      }
      this.hospitalBills = (
        parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
      ).toFixed(2);
      this.Calculate_gst('0', '0');
    }

    this.current_amount = totamount.toFixed(2);
  }

  addNewBillDetails() {
    this.billaddlist.push({
      sno: this.rowid,
      biill_type: '',
      amount_txt: '',
      concession: '',
      fee: '',
      charge_type: '',
      pay_type: '',
      insurer: '',
      ref_code: '',
      quantity: '',
      total: '',
    });
    this.rowid = 1;
    for (var i = 0; i < this.billaddlist.length; i++) {
      this.billaddlist[i].sno = this.rowid;
      this.rowid += 1;
    }
    setTimeout(function () {
      $(".getChargeDesc").last().focus();
    }, 500);
  }

  getamountAftCon1(amount) {
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if (RateCard[i].discount == '0' && RateCard[i].rate_card_desc != 'Service wise') {
          return (this.amount_aftcon = parseFloat(amount) * this.quantity);
        } else {
          return (this.amount_aftcon =
            parseFloat(amount) * this.quantity -
            (this.concession_txt / 100) *
              (parseFloat(amount) * this.quantity));
        }
      }
    }
  }

  //Get payment type
  getPaymentType() {
    var obj = Master_Helper.getMasterPaymentType();
    this.paytypelist = obj.payments;
    this.pay_type = this.paytypelist[0].pay_id;
    this.payType1 = this.paytypelist[0].pay_id;
  }

  pay_change() {
    document.getElementById('paytype')!.style.borderColor = '#2ca8fe';
    if (this.pay_type == '3') {
      this.insurFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.pay_type == '2') {
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag = true;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '3') {
      this.cardreqflag = false;
      this.insurFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = false;
      this.insreqflag = true;
    } else if (this.pay_type == '4') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '5') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '6') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '7') {
      this.chequeFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = true;
      this.insreqflag = false;
    }
    this.Appointmnet_change(this.appoint_txt);
  }

  //Get payment type
  getInsurers() {
    var obj = Master_Helper.getMasterInsurers();
    this.insurerlist = obj.insurers;
  }

  getConcession() {
    for (var i = 0; i < this.RateCardArray.length; i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {         
          this.discount_flag = false;          
          if (this.concession_txt != '') {
            this.concession_txt = parseFloat(this.concession_txt).toFixed(2);
          } else {
            this.concession_txt = parseFloat(this.RateCardArray[i].discount).toFixed(2);
          }       
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession_txt = parseFloat(this.RateCardArray[i].discount).toFixed(2);
        }   
        this.flag_function();
      }
    }
    console.log(this.discount_flag);
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].concession = this.concession_txt;
        this.changeamount1(this.billaddlist[i],0);
      }
    }
    this.paymentsarray = [];
  }

  charge_change(charge) {
    this.charge_type = charge.description;
    this.serviceType = [];
    for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == this.charge_type) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  checkBoxTrueOrFalse(type, checkBox) {
    for (var i = 0; i < this.chargeNameArray.length; i++) {
      for (var j = 0; j < this.billaddlist.length; j++) {
        for (var k = 0; k < this.charge_name.length; k++) {
          if (this.chargeNameArray[i].selected == true || checkBox) {
            if (
              this.billaddlist[j].charge_type ==
              this.chargeNameArray[i].charge_type
            ) {
              this.chargeNameArray[i].selected = true;
              this.chargeNameArray[i].quantity = this.billaddlist[j].quantity;
              this.chargeNameArray[i].amount_txt = parseFloat(
                this.billaddlist[j].amount_txt
              ).toFixed(2);
              this.chargeNameArray[i].concession =
                this.billaddlist[j].concession;
              this.chargeNameArray[i].fee = parseFloat(
                this.billaddlist[j].fee
              ).toFixed(2);
            }
          }
          if (this.chargeNameArray[i].selected == false || !checkBox) {
            if (
              type == this.chargeNameArray[i].charge_type &&
              this.charge_name[k].charge_id == type
            ) {
              this.chargeNameArray[i].selected = false;
              this.chargeNameArray[i].quantity = this.quantity;
              this.chargeNameArray[i].amount_txt = parseFloat(
                this.charge_name[k].amount
              ).toFixed(2);
              this.chargeNameArray[i].concession = this.concession_txt;
              this.chargeNameArray.forEach((value) => this.changeamount(value));
            }
          }
        }
      }
    }
  }

  changeChargeType1(charge, person) {
    var result = this.finekeyInArray_billaddChargeType(
      this.billaddlist,
      charge.charge_id
    );
    if (result == true) {
      this.toastr.error('Charge type already exists');
      this.serviceType = [];
      person.biill_type = '';
    } else {
      this.quantity = 1;
      person.biill_type = charge.description;
      person.edit_flag = true;
      person.charge_type = charge.charge_id;
      person.amount_txt = parseFloat(charge.amount).toFixed(2);    
      person.quantity = parseFloat(this.quantity).toFixed(2);
      person.concession = this.concession_txt;
      person.fee = this.getamountAftCon1(charge.amount).toFixed(2);
      person.total = (parseFloat(this.quantity) * parseFloat(charge.amount)).toFixed(2);
      this.hospitalBills = '0.00';
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].fee == '') {
            this.billaddlist[i].fee = '0.00';
          }
          this.hospitalBills = (
            parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
          ).toFixed(2);
        }
      }
      this.Calculate_gst('0', '0');
      this.checkBox = true;
      this.checkBoxTrueOrFalse(charge.charge_id, this.checkBox);
      this.serviceType = [];
      this.addNewBillDetails();
    }
  }

  getServiceType(serviceType) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.hptl_clinic_id,
          provider: 'doctor',
          type: 'in-patient',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          this.charge_name = obj.charges;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );

    this.serviceType = [];
    if (serviceType.length >= 3) {
      if (this.charge_name.length != 0) {
        if (
          this.charge_name.filter((o) =>
            o.description.toLowerCase().startsWith(serviceType.toLowerCase())
          )
        ) {
          var chg = this.charge_name.filter((o) =>
            o.description.toLowerCase().startsWith(serviceType.toLowerCase())
          );
          for (var i = 0; i < chg.length; i++) {
            this.serviceType.push(chg[i].description);
          }
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  getServiceType1(bill_type) {
    this.serviceType = [];
    var filteredItems = [];
    if (bill_type.length >= 2 && bill_type !== undefined) {
      if (this.charge_name.length !== 0) {
        
        if (
          this.department_id != undefined &&
          this.department_id != '' &&
          this.ward_id != undefined &&
          this.ward_id != ''
        ) {
          if(this.wardbasecharge =="1") {
            filteredItems = this.charge_name.filter(
              (o) =>
                o.description.toLowerCase().includes(bill_type.toLowerCase()) &&
                o.department_id.includes(this.department_id) &&
                o.ward_id.includes(this.ward_id)
            );
          } else {
            filteredItems = this.charge_name.filter((o) =>
              o.description.toLowerCase().includes(bill_type.toLowerCase())
            );
          }
          
        } else {
          filteredItems = this.charge_name.filter((o) =>
            o.description.toLowerCase().includes(bill_type.toLowerCase())
          );
        }

        console.log(filteredItems);
        if (filteredItems.length > 0) {
          filteredItems.forEach((o) => {
            this.serviceType.push({
              description: o.description,
              amount: o.amount,
              charge_id: o.charge_id,
            });
          });
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  handleKeyUp(event: KeyboardEvent, biill_type, person) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType1(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
          };
          break;
        }
        console.log('charge', charge);
      }
      if (biill_type) {
        this.changeChargeType1(charge, person);
      }
    }
  }

  searchPatient() {
    this.appointment_list = [];
    var fieldvalue;
    if (this.filter == 'Patient_ID') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mr_no: this.patientId,
      };
    } else if (this.filter == 'Name') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        first_name: this.firstName,
        last_name: this.lastName,
      };
    } else if (this.filter == 'Date') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        date: this.date,
      };
    } else if (this.filter == 'Mobile_No') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mobile: this.mobileNo,
      };
    }
    console.log(fieldvalue);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'bill/inpa', fieldvalue, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              var wardDetails = [];
              if (
                obj.appointment[i].ward_details != undefined &&
                obj.appointment[i].ward_details.length != 0
              ) {
                 wardDetails = obj.appointment[i].ward_details;
              }

              var doc_visits = [];
              if (
                obj.appointment[i].doc_visits != undefined &&
                obj.appointment[i].doc_visits.length != 0
              ) {
                doc_visits = obj.appointment[i].doc_visits;
              }

              var other_charges = [];
              if (
                obj.appointment[i].other_charges != undefined &&
                obj.appointment[i].other_charges.length != 0
              ) {
                  other_charges = obj.appointment[i].other_charges;
              }

              var date =
                obj.appointment[i].admitted_date != undefined
                  ? ' (' + Date_Formate(obj.appointment[i].admitted_date) + ')'
                  : ' ';
              if (obj.appointment[i].middle_name != undefined) {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              } else {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              }

              var sessiondata = null;
              if (
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != null
              ) {
                sessiondata = encrypt_decript.Decript(
                  obj.appointment[i].session
                );
              }

              var address1 = '',
                address2 = '';
              if (
                obj.appointment[i].address2 != null &&
                obj.appointment[i].address2 != undefined &&
                obj.appointment[i].address1 != undefined
              ) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
                if (
                  obj.appointment[i].address2 != ' ' &&
                  obj.appointment[i].address2 != 'null'
                ) {
                  if (
                    encrypt_decript.Decript(obj.appointment[i].address2) == ''
                  ) {
                    address2 == '';
                  } else {
                    address2 =
                      encrypt_decript.Decript(obj.appointment[i].address2) +
                      ',';
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
              } else if (obj.appointment[i].address2 != undefined) {
                address2 =
                  encrypt_decript.Decript(obj.appointment[i].address2) + ',';
              }

              var location;
              if (
                obj.appointment[i].location != undefined &&
                obj.appointment[i].location != ''
              ) {
                location = obj.appointment[i].location + ',';
              }

              var city = '';
              if (
                obj.appointment[i].district != undefined &&
                obj.appointment[i].district != null
              ) {
                city = obj.appointment[i].district + '-';
              }

              var zipcode;
              if (
                obj.appointment[i].zipcode != undefined &&
                obj.appointment[i].zipcode != null
              ) {
                zipcode =
                  encrypt_decript.Decript(obj.appointment[i].zipcode) + ',';
              }

              var state = '';
              if (
                obj.appointment[i].state != undefined &&
                obj.appointment[i].state != null
              ) {
                state = obj.appointment[i].state + '-';
              }

              var country = '';
              if (
                obj.appointment[i].country != undefined &&
                obj.appointment[i].country != null
              ) {
                country = obj.appointment[i].country + '.';
              }

              var hospital;
              if (obj.hosp_address2 != undefined) {
                hospital = obj.hosp_address1 + ',' + obj.hosp_address2;
              } else {
                hospital = obj.hosp_address1;
              }

              var docName;
              if (
                obj.appointment[i].dr_middle_name != undefined &&
                obj.appointment[i].dr_middle_name != null
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_middle_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else if (
                obj.appointment[i].dr_first_name != undefined &&
                obj.appointment[i].dr_last_name != undefined
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              }

              if (obj.appointment[i].profile_image != '') {
                var image =
                  ipaddress.Ip_with_img_address +
                  obj.appointment[i].profile_image;
              }

              var inpatientId =
                obj.appointment[i].inpat_id != undefined
                  ? obj.appointment[i].inpat_id
                  : '-';
              this.hospital_name =
                obj.hosp_name != undefined ? obj.hosp_name : '';
              this.hospitalAddress = hospital;
              this.Hospital_location =
                obj.hosp_location +
                ', ' +
                obj.hosp_district +
                ' - ' +
                obj.hosp_state +
                ' ' +
                obj.hosp_country +
                '  ' +
                obj.hosp_zip;
              this.mobile_no =
                obj.appointment[i].mobile != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].mobile)
                  : '';
              this.e_contact =
                obj.appointment[i].emerg_contact != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].emerg_contact)
                  : '';

              var surgery = obj.appointment[i].surgery_desc != undefined ? obj.appointment[i].surgery_desc : '';
              var barcode;
              if (obj.appointment[i].barcode != undefined && obj.appointment[i].barcode != null) {
                this.barcode_flag = true;
                barcode = ipaddress.Ip_with_img_address + obj.appointment[i].barcode;
              }
              this.appointment_list.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                doc_reg_id: obj.appointment[i].doc_reg_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                address1: address1,
                address2: address2,
                location: location,
                district: city,
                state: state,
                country: country,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                city: city,
                zipcode: zipcode,
                docid_name: this.Docidname,
                docName: docName,
                advance: obj.appointment[i].advance,
                balance: obj.appointment[i].balance,
                inpatientId: inpatientId,
                cgst: '12%',
                sgst: '12$',
                admitted_date: obj.appointment[i].admitted_date,
                admitted_time: obj.appointment[i].admitted_time,
                discharge_date: obj.appointment[i].discharge_date,
                discharge_time: obj.appointment[i].discharge_time,
                department_id: obj.appointment[i].department_id,
                department_desc: obj.appointment[i].department_desc,
                ward_id: obj.appointment[i].ward_id,
                ward_name: obj.appointment[i].ward_name,
                bed_no: obj.appointment[i].bed_no,
                profile_image: image,
                patient_id: obj.appointment[i].patient_id,
                age: obj.appointment[i].age,
                gender: obj.appointment[i].gender,
                mobile: this.mobile_no,
                emerg_contact: this.e_contact,
                salutation_desc: obj.appointment[i].salutation_desc,
                ward_details: wardDetails,
                doc_visits: doc_visits,
                other_charges: other_charges,
                surgery_desc: surgery,
                barcode: barcode
              });
              console.log('appointment_details_array', this.appointment_list);
            }

            if (
              this.appointment_list != undefined &&
              this.appointment_list.length === 1
            ) {
              for (var i = 0; i < this.appointment_list.length; i++) {
                this.appoint_txt = this.appointment_list[i].docid_name;
                this.Appointmnet_change(this.appoint_txt);
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  Appointmnet_change(e) {
    var patientname = e.split('-');
    this.billden_plan = [];
    this.billaddlist = [];
    this.medcalDetailsArray = [];
    this.digDetailsArray = [];
    this.billing_print_array = [];
    this.quantityArray = [];
    this.other_charges_array = [];
    this.final_amount = '0.00';
    this.totalBillAmount = '0.00';
    this.remaing_billbal = '0.00';
    this.balance_data = '0.00';
    this.Advance_bill = '0.00';
    this.mrno = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.mobile_no = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.dept_desc = '';
    this.wardName = '';
    this.bed_no = '';
    this.ward_id = '';
    this.profileImg = '';
    this.hospitalBills = '0.00';
    this.doc_visitscreate = [];
    this.doctorVisitsChargeArray = [];

    for (var i = 0; i < this.appointment_list.length; i++) {
      if (this.appointment_list[i].doc_app_id == parseFloat(patientname[0])) {
        if (
          this.appointment_list[i].ward_details != null &&
          this.appointment_list[i].ward_details.length != 0
        ) {
          this.warddetails_billcreate = this.appointment_list[i].ward_details;
          console.log(this.warddetails_billcreate);

          this.warddetails_billcreate.forEach((detail) => {
            var createdDateTime = moment(
              `${detail.created_date} ${detail.created_time}`
            );
            var changeDateTime;
            if (detail.change_date && detail.change_time != undefined) {
              changeDateTime = moment(
                `${detail.change_date} ${detail.change_time}`
              );
            } else {
              changeDateTime = moment(); // Set to current date and time
              detail.change_date = changeDateTime.format('YYYY-MM-DD');
              detail.change_time = changeDateTime.format('HH:mm:ss');
            }
            console.log(changeDateTime);

            var duration = moment.duration(
              changeDateTime.diff(createdDateTime)
            );
            console.log('Duration', duration);
            var hourdif = (
              moment(changeDateTime).diff(createdDateTime, 'hours') / 24
            )
              .toFixed(2)
              .split('.');
            console.log('Hourdif', hourdif);

            var hours = hourdif[0];
            console.log('Hours', hours);

            var remhour = (parseInt(hourdif[1]) * 24) / 100;
            if (hours != '0') {
              if (this.userinfo.inpat_bill_type == 'Hourly') {
                if (remhour > 12) {
                  this.quantity =
                    parseInt(hours) + remhour * 0.0416666666666667;
                } else if (remhour != 0 && remhour > 1) {
                  this.quantity =
                    parseInt(hours) + remhour * 0.0416666666666667;
                } else {
                  this.quantity = hours;
                }
              } else if (this.userinfo.inpat_bill_type == 'Half a day') {
                if (remhour > 12) {
                  this.quantity = parseInt(hours) + 1;
                } else if (remhour != 0 && remhour > 1) {
                  this.quantity = hours + '.5';
                } else {
                  this.quantity = hours;
                }
              } else {
                this.quantity = hours;
              }
              if (this.quantity !== '0') {
                this.quantityArray.push(this.quantity);
              } else {
                console.log(
                  'Quantity is 0 Value not inserted into quantityArray'
                );
                this.quantity = '1';
              }
              console.log('Quantity Array', this.quantityArray);
            } else {
              this.quantity = '1';
            }
          });
        }

        if (
          this.appointment_list[i].doc_visits != null &&
          this.appointment_list[i].doc_visits.length != 0
        ) {
          this.doc_visitscreate = this.appointment_list[i].doc_visits;
          for (var j = 0; j < this.doc_visitscreate.length; j++) {
            if (this.doc_visitscreate[j].charge_id != undefined) {
              this.doctorVisitsChargeArray.push({
                charge_id: this.doc_visitscreate[j].charge_id,
              });
            }
          }
          console.log('DoctorVisitingArray', this.doctorVisitsChargeArray);
        }
        if (this.appointment_list[i].admitted_date != undefined) {
          var admdate =
            this.appointment_list[i].admitted_date != undefined
              ? this.appointment_list[i].admitted_date.split('-')
              : '';
          var admtime =
            this.appointment_list[i].admitted_time != undefined
              ? this.appointment_list[i].admitted_time
              : '';
          this.dateval = moment(
            admdate[2] + '/' + admdate[1] + '/' + admdate[0] + ' ' + admtime,
            'DD/MM/YYYY HH:mm'
          );
          console.log(this.dateval);

          if (
            this.appointment_list[i].discharge_date &&
            this.appointment_list[i].discharge_time != undefined &&
            '' &&
            null
          ) {
            this.enddate = this.appointment_list[i].discharge_date.split('-');
            this.endtime = this.appointment_list[i].discharge_time;
            
          } else {
            this.enddate = this.CurrentDatetime.split('-');
            this.endtime = this.currentTime;
          }
          console.log("END_TIME",this.endtime);
        }
        this.department_id = this.appointment_list[i].department_id;
        console.log('Department_Id', this.department_id);

        this.ward_id = this.appointment_list[i].ward_id;
        console.log('Ward_Id', this.ward_id);

        if (this.appointment_list[i].middle_name != undefined) {
          this.patient_name =
            encrypt_decript.Decript(this.appointment_list[i].first_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].middle_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].last_name);
        } else {
          if (this.appointment_list[i].last_name != undefined) {
            this.patient_name =
              encrypt_decript.Decript(this.appointment_list[i].first_name) +
              ' ' +
              encrypt_decript.Decript(this.appointment_list[i].last_name);
          } else {
            this.patient_name = encrypt_decript.Decript(
              this.appointment_list[i].first_name
            );
          }
        }

        if (this.appointment_list[i].advance != undefined) {
          this.Advance_bill = parseFloat(
            this.appointment_list[i].advance
          ).toFixed(2);
        }

        if (
          this.appointment_list[i].balance != '0' ||
          this.appointment_list[i].balance != '0.00'
        ) {
          this.amount_collected = true;
          this.balance_show = false;
          this.previous_balshow = false;
          this.balance_data = parseFloat(
            this.appointment_list[i].balance
          ).toFixed(2);
          this.total_collected = parseFloat(
            this.appointment_list[i].balance
          ).toFixed(2);
        }

        this.dog_reg_id = this.appointment_list[i].doc_reg_id;
        var zipcode =
          this.appointment_list[i].czipcode != undefined
            ? encrypt_decript.Decript(this.appointment_list[i].czipcode)
            : '';
        this.mrno = this.appointment_list[i].patient_id;

        if (this.appointment_list[i].salutation_desc != undefined) {
          this.clnt_saldesc = this.appointment_list[i].salutation_desc + '.';
        }

        this.clnt_age = this.appointment_list[i].age;
        if (
          this.appointment_list[i].gender != null &&
          this.appointment_list[i].gender != undefined
        ) {
          this.gender = encrypt_decript.Decript(
            this.appointment_list[i].gender
          );
          this.gender = this.gender[0];
          if (this.gender[0] == 'T') {
            this.gender = 'Tgen';
          }
        }

        this.mobile_no =
          this.appointment_list[i].mobile != undefined
            ? this.appointment_list[i].mobile
            : '';
        this.e_contact =
          this.appointment_list[i].emerg_contact != undefined
            ? this.appointment_list[i].emerg_contact
            : '';
        this.admitted_date =
          this.appointment_list[i].admitted_date != undefined
            ? this.appointment_list[i].admitted_date
            : '';
        this.admitted_time = admtime != undefined ? Time_Formate(admtime) : '';
        this.discharge_date =
          this.appointment_list[i].discharge_date != undefined
            ? this.appointment_list[i].discharge_date
            : '';
        this.doctor_name =
          this.appointment_list[i].docName != undefined
            ? this.appointment_list[i].docName
            : '';
        this.dept_desc =
          this.appointment_list[i].department_desc != undefined
            ? this.appointment_list[i].department_desc
            : '';
        this.wardName =
          this.appointment_list[i].ward_name != undefined
            ? this.appointment_list[i].ward_name
            : '';
        this.bed_no =
          this.appointment_list[i].bed_no != undefined
            ? this.appointment_list[i].bed_no
            : '';
        this.profileImg =
          this.appointment_list[i].profile_image != undefined
            ? this.appointment_list[i].profile_image
            : '';

        if (
          this.appointment_list[i].discharge_time != undefined &&
          this.appointment_list[i].discharge_time != ''
        ) {
          this.discharge_time = Time_Formate(
            this.appointment_list[i].discharge_time
          );
        }

        if (this.appointment_list[i].other_charges != undefined) {
          this.other_charges_array = this.appointment_list[i].other_charges;
        }

        this.surgery = this.appointment_list[i].surgery_desc != undefined ? this.appointment_list[i].surgery_desc : '';
        this.barcode_url = this.appointment_list[i].barcode;
        this.billing_print_array.push({
          dr_name: this.appointment_list[i].docName,
          licence_code: '',
          hptl_name: '',
          created_date: '',
          address1: this.appointment_list[i].address1,
          address2: this.appointment_list[i].address2,
          location: this.appointment_list[i].location,
          city: this.appointment_list[i].city + this.appointment_list[i].zipcode,
          state: this.appointment_list[i].state + this.appointment_list[i].country,
          telephone: this.appointment_list[i].telephone,
          inpatientId: this.appointment_list[i].inpatientId,
          appointment_id: this.appointment_list[i].doc_app_id,
          client_name: this.patient_name,
          caddress: '',
          clocation: this.appointment_list[i].clocation + ', ',
          clientcity: this.appointment_list[i].ccity + ' - ' + zipcode + ',',
          clestare: this.appointment_list[i].cstate + ' - ' + this.appointment_list[i].ccountry + '.',
          client_reg_id: this.appointment_list[i].client_reg_id,
          advance: this.appointment_list[i].advance,
          balance: this.appointment_list[i].balance,
          relation_id: this.appointment_list[i].relation_id,
          sub_rel_id: this.appointment_list[i].sub_rel_id,
        });

        this.createpage_flag = true;
        this.Get_Bill_Type();
      }
    }
    this.medicinBal = '0.00';
    this.diagnosisAmount = '0.00';
  }

  digAmount(id, value) {
    for (var i = 0; i < this.digDetailsArray.length; i++) {
      if (this.digDetailsArray[i].digID == id) {
        var val = value != '' ? value : '0.00';
        var previous = this.digDetailsArray[i].previous_value;
        this.current_amount = (
          parseFloat(this.current_amount) -
          parseFloat(this.digDetailsArray[i].previous_value)
        ).toFixed(2);
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(val)
        ).toFixed(2);
        this.total_bill1 = this.current_amount;
        this.total_bill = this.current_amount;
        this.final_amount = this.current_amount;
        if (value != '') {
          this.digDetailsArray[i].amount_txt = val;
          this.digDetailsArray[i].fee = val;
          this.digDetailsArray[i].previous_value = val;
        } else {
          this.digDetailsArray[i].amount_txt = '0.00';
          this.digDetailsArray[i].fee = '0.00';
          this.digDetailsArray[i].previous_value = '0.00';
        }
      }
    }
  }

  amount_change() {
    var perce!: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    var amt_cons = this.amount_aftcon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot) * parseInt(this.quantity);
  }

  changeamount(charge: any) {
    for (var i = 0; i < this.chargeNameArray.length; i++) {
      if (charge.charge_type == this.chargeNameArray[i].charge_type) {
        var concec;
        if (charge.concession == 'NaN') {
          concec = 0;
        } else {
          concec = charge.concession;
        }
        charge.fee =
          charge.quantity *
          (charge.amount_txt - charge.amount_txt * (concec / 100));
        charge.fee = parseFloat(charge.fee).toFixed(2);
      }
    }
  }


  changeamount1(charge,num) {
    this.hospitalBills = '0.00';
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.biill_type == this.billaddlist[i].biill_type) {
          if (num != 2) {
            var concec;
            if (charge.concession == 'NaN') {
              charge.concession = '0.00';
              concec = 0;
            } else {
              concec = charge.concession;
            }
            charge.fee =
              charge.quantity *
              (charge.amount_txt - charge.amount_txt * (concec / 100));
            charge.fee = parseFloat(charge.fee).toFixed(2);

            charge.total = charge.quantity * charge.amount_txt;
            charge.total = parseFloat(charge.total).toFixed(2);
          } else {
            var fee;
            if (charge.fee == 'NaN') {
              charge.fee = '0.00';
              fee = 0;
            } else {
              fee = charge.fee;
            }
             
            if (charge.amount_txt != '0.00' && charge.quantity != '0.00' && charge.fee != '') {
              charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100)).toFixed(3);
            } else {
              charge.concession = '0.00';
            }
          }       
        }
      }

      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == '') {
          this.billaddlist[i].fee = '0.00';
        }
        this.hospitalBills = (
          parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
        ).toFixed(2);
        this.Calculate_gst('0', num);
      }

      this.Calculate_gst('0', num);
    }
  }

  //Get gst
  async Get_Gst_Data() {
    var obj = Master_Helper.getMasterGSTData();
    if (Master_Helper.getMasterGSTData() == undefined) {
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if (obj != undefined) this.gst_retrive_list = obj.taxes;
  }

  // Include GST
  include_gst_click(gst_click) {
    this.Calculate_gst('0', '0');
  }

  Calculate_gst(gst_click, num) {
    this.final_amount = '0';
    this.totalBillAmount = '0';
    if (num != 1 ) {
      this.discount_amount = '0';
    }  
    this.gst_amount_txt = '0';
    this.total_collected = '0';
    this.current_amount = '0';
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (
            this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id
          ) {
            this.current_amount = (
              parseFloat(this.current_amount) +
              parseFloat(this.billden_plan[i].estimate)
            ).toFixed(2);
            this.final_amount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            ).toFixed(2);
            this.totalBillAmount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            ).toFixed(2);
            this.gst_amount_txt = (
              parseFloat(this.gst_amount_txt) +
              parseFloat(this.billden_plan[i].estimate)
            ).toFixed(2);

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (
                parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
              ).toFixed(2);
              this.remaing_billbal = (
                parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
              ).toFixed(2);
            }
            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (
                parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
              ).toFixed(2);
            }

            this.amount_collected = true;
            if (this.total_collected == '0') {
              this.amount_collected = false;
            }
            if (this.remaing_billbal == '0') {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    if (this.pre_bill_amount != undefined && this.inpatientEdit == true) {
      this.pre_bill_amount = (
        parseFloat(this.pre_bill_amount) +
        parseFloat(this.pharmaPaid) +
        parseFloat(this.diagPaid)
      ).toFixed(2);
      this.current_amount = (
        parseFloat(this.current_amount) + parseFloat(this.pre_bill_amount)
      ).toFixed(2);
      this.final_amount = (
        parseFloat(this.final_amount) + parseFloat(this.pre_bill_amount)
      ).toFixed(2);
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.pre_bill_amount)
      ).toFixed(2);
      this.gst_amount_txt = (
        parseFloat(this.gst_amount_txt) + parseFloat(this.pre_bill_amount)
      ).toFixed(2);
      this.remaing_billbal = '0.00';
      this.paid_bill =
        this.Advance_bill != undefined
          ? (
              parseFloat(this.pre_bill_amount) - parseFloat(this.Advance_bill)
            ).toFixed(2)
          : parseFloat(this.pre_bill_amount).toFixed(2);

      if (this.total_collected == '0') {
        this.amount_collected = false;
      }

      if (this.remaing_billbal == '0') {
        this.balance_show = true;
      }
    } else {
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].amount_txt == '') {
            this.billaddlist[i].amount_txt = '0.00';
          }
          if (this.billaddlist[i].quantity == '') {
            this.billaddlist[i].quantity = '0.00';
          }
          if (this.billaddlist[i].concession == '') {
            this.billaddlist[i].concession = '0.00';
          }


          if (num != 1) {
            this.discount_amount = (
              parseFloat(this.discount_amount) +
              parseFloat(this.billaddlist[i].amount_txt) *
                parseFloat(this.billaddlist[i].quantity) *
                (parseFloat(this.billaddlist[i].concession) / 100)
            ).toFixed(2);
          }
          
          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.billaddlist[i].amount_txt) *
              parseFloat(this.billaddlist[i].quantity)
          ).toFixed(2);
        }
        this.current_amount = Math.round(
          parseInt(
            (
              parseFloat(this.current_amount) + parseFloat(this.hospitalBills)
            ).toFixed(2)
          )
        );
        this.current_amount = parseFloat(this.current_amount).toFixed(2);
        
        if (this.inGST == false) {
          console.log("ifCalled");
          this.final_amount = Math.round(parseFloat((parseFloat(this.final_amount) + parseFloat(this.hospitalBills)).toFixed(2)));
        } else {
          console.log("elseCalled");
          this.final_amount = (
              parseFloat(this.final_amount) + parseFloat(this.hospitalBills)
            ).toFixed(2);
        }
        this.final_amount = parseFloat(this.final_amount).toFixed(2);

        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.hospitalBills)
        ).toFixed(2);

        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          ).toFixed(2);
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          ).toFixed(2);
          this.total_collected = '0';
        }

        if (this.total_collected == '0') {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == '0') {
          this.balance_show = true;
        }
      }
      if (this.medcalDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.medicinBal)
        ).toFixed(2);
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.medicinBal)
        ).toFixed(2);
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.medicinBal)
        ).toFixed(2);
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.medicinBal)
        ).toFixed(2);
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          ).toFixed(2);
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          ).toFixed(2);
          this.total_collected = '0';
        }

        if (this.total_collected == '0') {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == '0') {
          this.balance_show = true;
        }
      }
      if (this.digDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.diagnosisAmount)
        ).toFixed(2);
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.diagnosisAmount)
        ).toFixed(2);
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.diagnosisAmount)
        ).toFixed(2);
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.diagnosisAmount)
        ).toFixed(2);
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          ).toFixed(2);
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          ).toFixed(2);
          this.total_collected = '0';
        }

        if (this.total_collected == '0') {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == '0') {
          this.balance_show = true;
        }
      }
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == '0.00' && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].fee = '';
        }
        if (this.billaddlist[i].amount_txt == '0.00' && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].amount_txt = '';
        }
        if (this.billaddlist[i].quantity == '0.00' && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].quantity = '';
        }
        if (this.billaddlist[i].total == '0.00' && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].total = '';
        }
        if (this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].concession = '';
        }

        if (this.billaddlist[i].concession != '') {          
          var floatValue = this.billaddlist[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;
  
          if (digitsAfterDecimal === 2) { 
            console.log("Value has 2 digits after the decimal point.");
          } else if (digitsAfterDecimal === 3) {
            this.billaddlist[i].concession = parseFloat(this.billaddlist[i].concession).toFixed(2);
            console.log("Value has 3 digits after the decimal point.");
          } else {
            console.log("Value doesn't have 2 or 3 digits after the decimal point.");
          }
        }
        
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc +
            ' ' +
            this.gst_retrive_list[i].tax,
          Gst_amount: (
            parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ).toFixed(2),
        });
        this.final_amount = Math.round(parseFloat(this.final_amount) +
        parseFloat(this.gst_amount_txt) *
          (parseFloat(this.gst_retrive_list[i].tax) / 100));
          
        
        this.totalBillAmount =
          parseFloat(this.totalBillAmount) +
          parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100);
      }
      this.final_amount = parseFloat(this.final_amount).toFixed(2);
      this.totalBillAmount = parseFloat(this.totalBillAmount).toFixed(2);
    }

    if (this.balance_data != undefined) {
      this.final_amount = (
        parseFloat(this.final_amount) + parseFloat(this.balance_data)
      ).toFixed(2);
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.balance_data)
      ).toFixed(2);
    }

    //amount to be collected
    this.remaing_billbal = (
      parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
    ).toFixed(2);
    if (
      this.estimate_id.length == 0 &&
      this.billaddlist.length == 0 &&
      this.medcalDetailsArray.length == 0 &&
      this.digDetailsArray.length == 0
    ) {
      this.inGST = false;
      this.gst_data_list = [];
      this.final_amount = '0.00';
      this.discount_amount = '0.00';
      this.totalBillAmount = '0.00';
      this.gst_amount_txt = 0;
      this.total_collected = '0';
      this.remaing_billbal = '0';
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
      this.remaing_billbal = '0';
    }
    this.advance_change();
    this.paid_change();
  }

  changeDiscount() {
    if (this.discount_amount != '') {
      this.final_amount = '0';
      this.totalBillAmount = '0.00'; 
      if (this.billaddlist.length != 0) {   
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].fee == '') {
            this.billaddlist[i].fee = '0.00';
          }
          if (this.billaddlist[i].amount_txt == '') {
            this.billaddlist[i].amount_txt = '0.00';
          }
          if (this.billaddlist[i].quantity == '') {
            this.billaddlist[i].quantity = '0.00';
          }
          if (this.billaddlist[i].concession == '') {
            this.billaddlist[i].concession = '0.00';
          }
 
          this.final_amount = (
            parseFloat(this.final_amount) + parseFloat(this.billaddlist[i].amount_txt)
          ).toFixed(2);    
          console.log("NEWCHANGEDISCOUNTFINALAMOUNT",this.final_amount);
          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.billaddlist[i].amount_txt) *
              parseFloat(this.billaddlist[i].quantity)
          ).toFixed(2);                
        }
        console.log('discount_amount', this.discount_amount);      
      }

      if (this.discount_amount <= this.final_amount) {
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.discount_amount)).toFixed(2);
        if (this.inGST == false) {
          this.final_amount = Math.round(parseFloat(this.final_amount));
        }
        this.final_amount = parseFloat(this.final_amount).toFixed(2);
      }   
      this.calculatePercentage();     
    }

    if (this.discount_amount == '') {
      this.discount_amount = '0.00';
    }
  }

  calculatePercentage() {
    if (this.totalBillAmount && this.discount_amount) {
      this.concession_txt = ((this.discount_amount / this.totalBillAmount) * 100).toFixed(3);
    } else {
      this.concession_txt = 0;
    }
    
    if (this.concession_txt != '') {     
      if (this.billaddlist != undefined && this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          this.billaddlist[i].concession = this.concession_txt;
          this.changeamount1(this.billaddlist[i],1);
        }
      } 
      console.log("Calculated_Concession",this.concession_txt);
    }      
  }

  //add the bill details
  advance_change() {
    this.Advance_bill = this.Advance_bill == '' ? '0.00' : this.Advance_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    ).toFixed(2);
  }

  addRound(data) {
    this.sign = data;
    this.round_off = this.round_off == '' ? '0.00' : this.round_off;
    if (data == 'puls') {
      this.remaing_billbal = (
        parseFloat(this.final_amount) +
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      ).toFixed(2);
    } else {
      this.remaing_billbal = (
        parseFloat(this.final_amount) -
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      ).toFixed(2);
    }
    this.tempRoundoff = this.round_off;
  }

  paid_change() {
    this.paid_bill = this.paid_bill == '' ? '0.00' : this.paid_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    ).toFixed(2);
    if (parseFloat(this.paid_bill) > this.final_amount) {
      this.amountval = parseFloat(this.final_amount).toFixed(2);
      this.numToWords = convertNumberToWords(
        this.final_amount,
        ipaddress.country_code
      );
    } else {
      this.amountval = parseFloat(this.paid_bill).toFixed(2);
      this.numToWords = convertNumberToWords(
        this.paid_bill,
        ipaddress.country_code
      );
    }

    if (this.paid_bill != undefined && this.paid_bill != "") {    
      if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.final_amount).toFixed(2);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.final_amount)) {
            this.amountval = parseFloat(this.final_amount).toFixed(2);
          } else {
            this.amountval = (parseFloat(this.final_amount) - payArr_Amt).toFixed(2);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paid_bill).toFixed(2);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paid_bill)) {
            this.amountval = parseFloat(this.paid_bill).toFixed(2);
          } else {
            this.amountval = (parseFloat(this.paid_bill) - payArr_Amt).toFixed(2);
          }
        }
      }
    } else {
      this.paid_bill = "0.00";
      this.remaing_billbal = this.final_amount;
    }
  }

  //Bill click
  bill_click(planid, planmodal, rowid) {
    if (this.estimate_id.length != 0) {
      if (this.estimate_id.indexOf(planid) > -1) {
        for (var i = 0; i < this.estimate_id.length; i++) {
          if (this.estimate_id[i] == planid) {
            this.estimate_id.splice(i, 1);
            this.Calculate_gst('0', '0');
          }
        }
      } else {
        this.estimate_id.push(planid);
        this.Calculate_gst('0', '0');
      }
    } else {
      this.estimate_id.push(planid);
      this.Calculate_gst('0', '0');
    }

    for (var i = 0; i < this.billden_plan.length; i++) {
      for (var j = 0; j < this.estimate_id.length; j++) {
        if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
          this.billden_plan[i].checked = true;
        }
      }
    }
  }

  flag_function () {
      this.inpatient_flag = true;
      if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
        this.multiply_flag = false;
      } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
        this.multiply_flag = true;
      } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
        this.multiply_flag = false;
      } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
        this.multiply_flag = true;
      }

      if (this.bill_qty_flag == true && this.discount_flag == true && !this.inpatient_flag) {
        this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
      } else {
        this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
      }
  }

  Bill_save() {
    var flag = true;
    var paytype;
    var instype;
    var patientname = this.appoint_txt.split('-');

    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {      
        if (
          this.billaddlist[i].biill_type == '' ||
          this.billaddlist[i].amount_txt == '' 
        ) {       
          this.billaddlist.splice(i);
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].sno = this.rowid;
        this.rowid += 1;
      }
    }
    console.log(this.billaddlist);

    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        paytype = this.billaddlist[i].paytype;
        instype = this.billaddlist[i].insurer;
        if (this.billaddlist[i].biill_type != '' && (this.billaddlist[i].amount_txt == '0.00' || this.billaddlist[i].amount_txt == 0 || this.billaddlist[i].amount_txt == '0')) {
          this.toastr.error('Service charge cannot be zero');
          flag = false;
        }
      }
    }
    paytype = this.pay_type;
    if (flag == true) {
      this.show_save_btn = false;
      if (this.billing_print_array.length != 0) {
        for (var i = 0; i < this.billing_print_array.length; i++) {
          this.doc_client_reg_id = this.billing_print_array[i].client_reg_id;
          this.doc_relation_id = this.billing_print_array[i].relation_id;
          if (this.billing_print_array[i].sub_rel_id != undefined) {
            this.doc_sub_rel_id = this.billing_print_array[i].sub_rel_id;
          }
        }
      }

      var bill_time;
      if (this.bill_time1 != undefined && this.bill_time2 != undefined) {
        bill_time = ConvertTimeformat(
          '',
          this.bill_time1 + ':' + this.bill_time2 + ' ' + this.bill_time3
        );
      }

      this.addvancetxt =
        this.Advance_bill != undefined ? this.Advance_bill : '0';
      this.totalcolletxt =
        this.total_collected != undefined ? this.total_collected : '0';

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }
      if (this.estimate_id.length != 0) {
        var estimateid = this.estimate_id;
      }
      var finaleBillAmount = (
        parseFloat(this.final_amount) -
        (parseFloat(this.medicinBal) + parseFloat(this.diagnosisAmount))
      ).toFixed(2);
      Doc_Helper.setfinalamountbillcreate(finaleBillAmount);
      var doctp_id = Helper_Class.getInfo().user_id;
      var finalDiagArray: any = [];
      var results;
      if (this.digDetailsArray.length != 0) {
        for (i = 0; i < this.digDetailsArray.length; i++) {
          if (this.digDetailsArray[i].bill_id != undefined) {
            if (finalDiagArray.length != 0) {
              results = this.array_exits(
                finalDiagArray,
                this.digDetailsArray[i].bill_id
              );
              if (results != true) {
                finalDiagArray.push({
                  bill_id: this.digDetailsArray[i].bill_id,
                  paid_amount: parseFloat(
                    this.digDetailsArray[i].bill_amount
                  ).toFixed(2),
                  center_id: this.digDetailsArray[i].center_id,
                });
              }
            } else {
              finalDiagArray.push({
                bill_id: this.digDetailsArray[i].bill_id,
                paid_amount: parseFloat(
                  this.digDetailsArray[i].bill_amount
                ).toFixed(2),
                center_id: this.digDetailsArray[i].center_id,
              });
            }
          }
        }
      }
      var check1 = finalDiagArray;
      var finalMedArray: any = [];
      var result2;
      if (this.medcalDetailsArray.length != 0) {
        for (i = 0; i < this.medcalDetailsArray.length; i++) {
          if (this.medcalDetailsArray[i].bill_id != undefined) {
            if (finalMedArray.length != 0) {
              result2 = this.array_exits(
                finalMedArray,
                this.medcalDetailsArray[i].bill_id
              );
              if (
                result2 != true &&
                this.medcalDetailsArray[i].bill_id != undefined
              ) {
                finalMedArray.push({
                  bill_id: this.medcalDetailsArray[i].bill_id,
                  paid_amount: parseFloat(
                    this.medcalDetailsArray[i].bill_amount
                  ).toFixed(2),
                  center_id: this.medcalDetailsArray[i].center_id,
                });
              }
            } else {
              finalMedArray.push({
                bill_id: this.medcalDetailsArray[i].bill_id,
                paid_amount: parseFloat(
                  this.medcalDetailsArray[i].bill_amount
                ).toFixed(2),
                center_id: this.medcalDetailsArray[i].center_id,
              });
            }
          }
        }
      }
      
      this.sendAdvanceAmount =
        this.Advance_bill != undefined ? this.Advance_bill : '0.00';

      var paid_flag;
      if (parseInt(this.final_amount) != 0 && parseInt(this.remaing_billbal) != 0) {
        if (this.final_amount == this.remaing_billbal) {
          paid_flag = 'Un paid';
        }   
      } else if (
        this.final_amount != '0.00' &&
        this.sendAdvanceAmount != '0.00' &&
        this.final_amount != this.sendAdvanceAmount
      ) {
        paid_flag = 'Partially paid';
      } else if (
        this.remaing_billbal == '0.00' ||
        this.remaing_billbal == '0' || parseInt(this.remaing_billbal) < 0
      ) {
        paid_flag = 'Fully paid';
      }

      if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
        this.paid_bill = this.final_amount;
        paid_flag = 'Fully paid';
      }
      console.log("PAID_FLAG",paid_flag);
      var cbill;
      var credit_person;
      if (this.credit_bill == true) {
        cbill = this.credit_type;
        credit_person = this.credit_person;
      } else {
        this.credit_type = '';
        this.credit_person = '';
      }

      if (this.surgery == '' && this.credit_type == '') {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
        this.nobanner_style = "width: 100%; height: 95px;";
      } else if (this.surgery != '' && this.credit_type != '') {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -65px;";
        this.nobanner_style = "width: 100%; height: 115px;";
      } else if (this.surgery != '' || this.credit_type != '') {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
        this.nobanner_style = "width: 100%; height: 105px;";
      }

      var check = finalMedArray;
      var billId =
        Helper_Class.getInpatientBillEdit().flow == 'inpatien-edit'
          ? Helper_Class.getInpatientBillEdit().bill_id
          : undefined;
      var updater_type =
        Helper_Class.getInpatientBillEdit().flow == 'inpatien-edit'
          ? Helper_Class.getInpatientBillEdit().updater_type
          : undefined;
      var doctor_reg_o;
      var advance;
      var totalAdvance;
      if (
        parseFloat(finaleBillAmount) ==
        parseFloat(this.paid_bill) + parseFloat(this.addvancetxt)
      ) {
        advance = '0.00';
        totalAdvance = parseFloat(this.addvancetxt).toFixed(2);
      } else if (parseFloat(finaleBillAmount) < parseFloat(this.addvancetxt)) {
        advance = (
          parseFloat(this.Advance_bill) - parseFloat(finaleBillAmount)
        ).toFixed(2);
        totalAdvance = (
          parseFloat(this.addvancetxt) - parseFloat(advance)
        ).toFixed(2);
      } else {
        advance = '0.00';
        totalAdvance = parseFloat(this.Advance_bill).toFixed(2);
      }
      var balance =
        parseInt(this.remaing_billbal) <= 0
          ? '0.00'
          : this.remaing_billbal.toString();
      console.log(
        'remainingBalance',
        this.remaing_billbal,
        'parseInt - remainingBalance',
        parseInt(this.remaing_billbal)
      );
      var status = parseInt(this.remaing_billbal) <= 0 ? '0' : '1';
      console.log('status', status);

      var finalAmount;
      console.log("FinalAmount",parseInt(this.final_amount));
      console.log("DiscountAmount",this.discount_amount);
      if (parseInt(this.final_amount) == 0) {
        finalAmount = encrypt_decript.Encript(this.discount_amount).toString();
      } else {
        finalAmount = encrypt_decript.Encript(this.final_amount).toString();
      }
      console.log("AfterCalculated FinalAmount",finalAmount);

      if (
        this.doc_sub_rel_id != null &&
        this.doc_sub_rel_id != undefined &&
        this.doc_sub_rel_id != '' &&
        this.doc_sub_rel_id.length != 0
      ) {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: 'doctor',
          hptl_clinic_id: this.hptl_clinic_id.toString(),
          doc_reg_id: this.dog_reg_id.toString(),
          bill_amount: finalAmount,
          created_by: this.user_id.toString(),
          bills: this.billaddlist,
          country: ipaddress.country_code.toString(),
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id.toString(),
          relation_id: this.doc_relation_id.toString(),
          sub_rel_id: this.doc_sub_rel_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype.toString(),
          insurer: instype,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          userid: doctp_id,
          paid_flag: paid_flag,
          paid_amount: this.paid_bill,
          bill_id: billId,
          updater_type: updater_type,
          bill_date: this.bill_date,
          bill_time: bill_time,
          credit_type: cbill,
          credit_person: credit_person,
          payments: this.paymentsarray,
          rate_card: this.rate_card,
          status: status,
          discount: this.discount_amount,
          open_app_billing: this.open_app_billing
        });
      } else {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: 'doctor',
          hptl_clinic_id: this.hptl_clinic_id,
          doc_reg_id: this.dog_reg_id,
          bill_amount: finalAmount,
          created_by: this.user_id,
          bills: this.billaddlist,
          country: ipaddress.country_code,
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id,
          relation_id: this.doc_relation_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype,
          insurer: instype,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          userid: doctp_id,
          paid_flag: paid_flag,
          paid_amount: this.paid_bill,
          bill_id: billId,
          updater_type: updater_type,
          bill_date: this.bill_date,
          bill_time: bill_time,
          credit_type: cbill,
          credit_person: credit_person,
          payments: this.paymentsarray,
          rate_card: this.rate_card,
          status: status,
          discount: this.discount_amount,
          open_app_billing: this.open_app_billing
        });
      }

      Doc_Helper.setbillid_create(this.billaddlist);
      console.log(this.save_billcreate);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + this.saveBillURL,
          this.save_billcreate,
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.result != null) {
              if (obj.key == '1') {
                this.billId = obj.bill_no;
                if (this.temp_key === 'print') {
                  this.cdr.detectChanges();
                  this.print_area1();
                }
                this.show_save_btn = false;
                this.toastr.success(Message_data.billgen);
                this.backClicked();
              } else {
                this.show_save_btn = true;
                this.toastr.error(Message_data.getBillUngen());
              }
            }
          },
          (error) => {
            this.show_save_btn = true;         
          }
        );
    } else {
      this.show_save_btn = true;
    }
  }

  Get_current_date() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      this.currentTime = obj.current_time;
      this.time = Time_Formate(this.currentTime);
      var timevalue = Time_Formate(this.currentTime);
      var tval = timevalue.split(' ');
      var tval1 = tval[0].split(':');
      this.bill_time1 = tval1[0];
      this.bill_time2 = tval1[1];
      this.bill_time3 = tval[1];
      var get_date = obj.current_date.split('-');
      this.Appoint_Date = get_date[2] + '-' + get_date[1] + '-' + get_date[0];
      this.currentDate = obj.current_date;
      this.bill_date = obj.current_date;
      this.date = obj.current_date;
      this.Appoint_Date = this.CurrentDatetime;
      Doc_Helper.setBillFromDate(null);
      Doc_Helper.setBillToDate(null);
      Doc_Helper.setBillFromDate(obj.current_date);
      Doc_Helper.setBillToDate(obj.current_date);
    }
  }

  billDateChange(date) {
    if (date > this.currentDate) {
      this.toastr.error('Select current or past date');
      this.bill_date = undefined;
    } else {
      this.bill_date = date;
    }
  }

  checkUncheckAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }
  
    if (this.checklist.length == this.checkedList.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  print_area(print) {
    this.temp_key = print;
    var flag = true;
    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      if (this.inpatientEdit != true) {
        this.Bill_save();
      }
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].bill_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  showCashModal() {
    this.payType1 = '1';
    this.showModal = true;
  }

  saveCashDenomination() {
    console.log('Cash Denomination:', this.cashDenomination);
    this.amountval = this.totalAmount;
    this.showModal = false;
  }

  calculateTotalAmount() {
    this.totalAmount = this.cashDenomination.reduce((total, denomination) => {
      return total + denomination.value * denomination.count;
    }, 0);
  }

  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    if (this.payType1 != undefined && this.payType1 != '') {
      for (var i = 0; i < this.paytypelist.length; i++) {
        if (this.payType1 == this.paytypelist[i].pay_id) {
          if (this.paymentsarray.length != 0) {
            for (var j = 0; j < this.paymentsarray.length; j++) {
              bamt += parseInt(this.paymentsarray[j].amount);
            }
            bamt += parseFloat(this.amountval);
          } else {
            bamt += parseFloat(this.amountval);
          }
  
          if (bamt > this.paid_bill) {
            this.toastr.error('Amount cannot be more than bill amount');
          } else if (this.amountval == 0 || this.amountval < 0) {
            this.toastr.error('Amount cannot be zero or minus');
          } else if (results == true) {
            this.toastr.error('Payment mode already exists');
          } else {
            if (
              this.amountval < parseFloat(this.paid_bill) ||
              this.amountval == parseFloat(this.paid_bill)
            ) {
              if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
                if (bamt > parseFloat(this.final_amount)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paytypelist[i].pay_id,
                    pay_desc: this.paytypelist[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.final_amount) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              } else {
                if (bamt > parseFloat(this.paid_bill)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paytypelist[i].pay_id,
                    pay_desc: this.paytypelist[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.paid_bill) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              }
            } else {
              this.amountval = 0;
            }
          }
        } 
      }
    } else {
      this.toastr.error('Please select the payment mode');
    }   
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  setDefaultChargeTypeAmount() {
    for (var i = 0; i < this.charge_name.length; i++) {
      for (var j = 0; j < this.chargeNameArray.length; j++) {
        for (var k = 0; k < this.billaddlist.length; k++) {
          if (
            this.chargeNameArray[j].biill_type ==
              this.charge_name[i].description &&
            this.chargeNameArray[j].biill_type !=
              this.billaddlist[k].biill_type &&
            this.chargeNameArray[i].selected == false
          ) {
            this.chargeNameArray[j].quantity = this.quantity;
            this.chargeNameArray[j].amount_txt = parseFloat(
              this.charge_name[i].amount
            ).toFixed(2);
            this.chargeNameArray[j].concession = this.concession_txt;
            this.chargeNameArray.forEach((value) => this.changeamount(value));
          }
        }
      }
    }
  }
}
