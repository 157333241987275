import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { DoctorfilterpageComponent } from '../doctorfilterpage/doctorfilterpage.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
    selector: 'app-doctorlist',
    templateUrl: './doctorlist.component.html',
    styleUrls: ['./doctorlist.component.css']
})
export class DoctorlistComponent implements OnInit {
    public getData = [];
    public doctorList = [];
    public secondOpinionFlag: string;
    public secondOpinionDocListFlag: Boolean = false;
    public specialityName;
    public curDateUrl: string;
    public sendData;
    public currentDate: string;
    public currentYear: string;
    public doctorListUrl: string;
    public sendDoctorList;
    public homecare: string;
    public sendDoctorData;
    public sendDoctorLocation: string;
    public autolocationFlag: Boolean = false;
    public secOpnDoctorList = [];
    public sendSecOpnDoctorData = [];
    public hospitalFlag: Boolean;
    public specialityFlag: Boolean;
    public knobValues: any = {
        upper: 1500,
        lower: 0
    }
    public locationflag1;
    public stateUrl: string;
    public countryUrl: string;
    public clntLocationList = [];
    public cityList;
    public stateList;
    public countryList;
    public clntLocation;
    public filtCity;
    public filtState;
    public filtCountry;
    public filtCityDesc;
    public filtStateDesc;
    public sendValue: any;
    public filterSearchLocation: string;
    public filterSearchCity: string;
    public filterSearchState: string;
    public filterSearchCountry: string;

    constructor(public dialog: MatDialog, public clientservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public router: Router, public toastr: ToastrService) {
        this.getData = Client_Helper.getsplnextdoclistpage().secOpn;
        this.homecare = "0";
        Client_Helper.setsecondopiniondoctorlistflag(null);
    }

    ngOnInit() {
        this.sendData = {
            country: "IN"
        }    

        this.stateUrl = ipaddress.getIp + "gen/state";
        this.countryUrl = ipaddress.getIp + "gen/count";
        this.curDateUrl = ipaddress.getIp + "adm/curdate";
        this.doctorListUrl = ipaddress.getIp + "search/doctor/";

        if (Client_Helper.getHomecare() != "0" && Client_Helper.getHomecare() == "1") {
            this.homecare = "1";
        }
        
        if (Client_Helper.getsplnextdoclistpage().app_flow == "second_opinion" || Client_Helper.getsplnextdoclistpage().app_flow == "without_second_opinion") {
            this.secondOpinionFlag = "1";
        }

        if (Client_Helper.getsecondopinion() == "second_opinion" || Client_Helper.getsecondopinion() == "without_second_opinion") {
            this.secondOpinionFlag = "1";
            Client_Helper.setsecondopiniondoctorlistflag(this.secondOpinionFlag);
        }
        
        if (Client_Helper.getSO_client_details() != null) {
            this.clntLocation = Client_Helper.getSO_client_details().clnt_location_desc;
            this.filtCity = Client_Helper.getSO_client_details().clnt_city_desc;
            this.filtState = Client_Helper.getSO_client_details().clnt_state_desc;
            this.filtCountry = Client_Helper.getSO_client_details().clnt_country_desc;
            if (Client_Helper.getsplnextdoclistpage().secOpn != null) {
                for (var i = 0; i < Client_Helper.getsplnextdoclistpage().secOpn.length; i++) {
                    this.specialityName = Client_Helper.getsplnextdoclistpage().secOpn[i].specialization_name;
                }
            }

            this.sendValue = {
                location: Client_Helper.getSO_client_details().Search_Location,
                city: Client_Helper.getSO_client_details().Search_City,
                state: Client_Helper.getSO_client_details().Search_State,
                country: Client_Helper.getSO_client_details().Search_Country,
                medicare_name: "Allopathy",
                home_care: this.homecare,
                spl_name: this.specialityName
            }
            this.doctorFilter(this.sendValue);
        }
    }

    getDoctorDetails(responselist) {// Get Doctor List
        this.doctorList = [];

        for (var i = 0; i < responselist.length; i++) {
            var Docname = "";
            if (responselist[i].middle_name != undefined && responselist[i].middle_name != "") {
                Docname = responselist[i].first_name + " " + responselist[i].middle_name + " " + responselist[i].last_name;
            } else {
                Docname = responselist[i].first_name + " " + responselist[i].last_name;
            }

            var MedicareName = "";
            var gender_data = "";
            if (responselist[i].gender != null) {
                gender_data = responselist[i].gender;
            }

            if (responselist[i].medicare != null) {
                if (responselist[i].medicare.length != 0) {
                    for (var j = 0; j < responselist[i].medicare.length; j++) {
                        MedicareName = (j == 0) ? responselist[i].medicare[j] : MedicareName + ", " + responselist[i].medicare[j];
                    }
                }
            }

            var Splityname = "", splid = "0";
            if (responselist[i].specialization != null && responselist[i].specialization.length != 0) {
                Splityname = "";
                for (var j = 0; j < responselist[i].specialization.length; j++) {
                    Splityname = (j == 0) ? responselist[i].specialization[j] : Splityname + ", " + responselist[i].specialization[j];
                }
                var Medispecial = false;
            } else {
                var Medispecial = true;
            }

            var Qualify = "";
            if (responselist[i].qualification.length != 0) {
                for (var j = 0; j < responselist[i].qualification.length; j++) {
                    Qualify = (j == 0) ? responselist[i].qualification[j].degree_name : Qualify + ", " + responselist[i].qualification[j].degree_name;
                }
            }

            var Hosp_detail = [];
            if (responselist[i].hospital.length != 0) {
                var Hosp_id_list = [];
                for (var j = 0; j < responselist[i].hospital.length; j++) {
                    if (j == 0) {
                        Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                        Hosp_detail.push({
                            Hosp_name: responselist[i].hospital[j].hospital_name,
                            Hosp_Locat: responselist[i].hospital[j].location
                        });
                    } else {
                        if (this.checkArrayContains(Hosp_id_list, responselist[i].hospital[j].hospital_id) == false) {
                            Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                            Hosp_detail.push({
                                Hosp_name: responselist[i].hospital[j].hospital_name,
                                Hosp_Locat: responselist[i].hospital[j].location
                            });
                        }
                    }
                }
            }

            var experiece;
            if ((parseInt(this.currentYear) - parseInt(responselist[i].registeration_year)) == 0) {
                experiece = "<1";
            } else {
                experiece = parseInt(this.currentYear) - parseInt(responselist[i].registeration_year);
            }

            this.doctorList.push({
                Prov_ID: responselist[i].prov_id,
                Doctor_Name: Docname,
                Prof_image: ipaddress.Ip_with_img_address + responselist[i].profile_image,
                Work_Year: experiece,
                Medicare: MedicareName,
                Speciality: Splityname,
                Qualification: Qualify,
                Hospital: Hosp_detail,
                gender1: gender_data,
                check: false
            });
        }
    }

    checkArrayContains(array, value) {
        var data = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) {
                data = true;
                break;
            }
        }
        return data;
    }

    viewDoctorDetails(Doctor_ID, value) {// View or Select Button Click
        if (Client_Helper.getsecondopinion() == "second_opinion" || Client_Helper.getsecondopinion() == "without_second_opinion") {// Second Opinion
            for (var i = 0; i < this.doctorList.length; i++) {
                if (this.doctorList[i].Prov_ID == Doctor_ID) {
                    if (this.sendSecOpnDoctorData.length == 0) {
                        var spl = [];
                        spl = this.doctorList[i].Speciality.split(",");
                        this.getData = Client_Helper.getsplnextdoclistpage().secOpn;
                        for (var j = 0; j < this.getData.length; j++) {
                            for (var t = 0; t < spl.length; t++) {
                                if (spl[t] == this.getData[j].specialization_name) {
                                    this.sendSecOpnDoctorData.push({
                                        doc_reg_id: this.doctorList[i].Prov_ID.toString(),
                                        specialization: this.getData[j].specialization_name,
                                    })
                                }
                            }
                        }

                        var Location = "";
                        for (var j = 0; j < this.doctorList[i].Hospital.length; j++) {
                            if (this.sendDoctorLocation == this.doctorList[i].Hospital[j].Hosp_Locat) {
                                Location = this.doctorList[i].Hospital[j].Hosp_Locat;
                                break;
                            }
                        }

                        if (this.secOpnDoctorList.length != 0) {
                            if (this.secOpnDoctorList.find(x => x.Doc_ID === Doctor_ID) == undefined) {
                                this.secOpnDoctorList.push({
                                    Doc_ID: this.doctorList[i].Prov_ID,
                                    Doc_Name: this.doctorList[i].Doctor_Name,
                                    Doc_Location: Location,
                                });
                            }
                        } else {
                            this.secOpnDoctorList.push({
                                Doc_ID: this.doctorList[i].Prov_ID,
                                Doc_Name: this.doctorList[i].Doctor_Name,
                                Doc_Location: Location,
                            });

                        }

                        this.doctorList[value].check = true;
                        this.doctorList[i].chang_col = true;
                        break;
                    } else {
                        if (this.gservice.get_Array_Contains_string(this.sendSecOpnDoctorData, this.doctorList[i].Prov_ID) == false) {// New
                            var spl = [];
                            spl = this.doctorList[i].Speciality.split(",");
                            for (var j = 0; j < this.getData.length; j++) {
                                for (var t = 0; t < spl.length; t++) {
                                    if (spl[t].trim() == this.getData[j].specialization_name.trim()) {
                                        if (this.sendSecOpnDoctorData.find(x => x.doc_reg_id === Doctor_ID) == undefined) {
                                            this.sendSecOpnDoctorData.push({
                                                doc_reg_id: this.doctorList[i].Prov_ID.toString(),
                                                specialization: this.getData[j].specialization_name,
                                            })
                                        }
                                    }
                                }
                            }

                            this.doctorList[value].check = true;
                            var Location = "";
                            for (var j = 0; j < this.doctorList[i].Hospital.length; j++) {
                                if (this.sendDoctorLocation == this.doctorList[i].Hospital[j].Hosp_Locat) {
                                    Location = this.doctorList[i].Hospital[j].Hosp_Locat;
                                    break;
                                }
                            }

                            this.doctorList[i].chang_col = true;
                            if (this.secOpnDoctorList.length != 0) {
                                if (this.secOpnDoctorList.find(x => x.Doc_ID === Doctor_ID) == undefined) {
                                    this.secOpnDoctorList.push({
                                        Doc_ID: this.doctorList[i].Prov_ID,
                                        Doc_Name: this.doctorList[i].Doctor_Name,
                                        Doc_Location: Location,
                                    });
                                }
                            } else {
                                this.secOpnDoctorList.push({
                                    Doc_ID: this.doctorList[i].Prov_ID,
                                    Doc_Name: this.doctorList[i].Doctor_Name,
                                    Doc_Location: Location,
                                });
                            }
                            break;
                        } else {// Delete
                            for (var i = 0; i < this.secOpnDoctorList.length; i++) {
                                if (this.secOpnDoctorList[i].Doc_ID == Doctor_ID) {
                                    this.doctorList[value].check = false;

                                    this.secOpnDoctorList.splice(i, 1);
                                    this.sendSecOpnDoctorData.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    }
                    break;
                }
                this.gservice.set_second_doctor_data(this.sendSecOpnDoctorData);
            }

            this.secondOpinionDocListFlag = (this.secOpnDoctorList.length != 0) ? true : false;
        } else {
            // Normal appointment
            localStorage.setItem("Normal_Doctor_ID", Doctor_ID);
            this.sendDoctorData = {
                doct_id: Doctor_ID,
                location: this.sendDoctorLocation
            }
        }
    }

    deleteSecOpnDoctorDetails(index) {
        for (var i = 0; i < this.secOpnDoctorList.length; i++) {
            if (this.secOpnDoctorList[i].Doc_ID == index) {
                for (var j = 0; j < this.doctorList.length; j++) {
                    if (this.secOpnDoctorList[i].Doc_ID == this.doctorList[j].Prov_ID) {
                        this.doctorList[j].check = false;
                    }
                }
                this.secOpnDoctorList.splice(i, 1);
                this.sendSecOpnDoctorData.splice(i, 1);
                break;
            }
        }
    }

    SecOpnNext() {
        this.gservice.set_second_health_data(null);
        this.gservice.set_second_family_data(null);
        this.gservice.set_second_general_data(null);
        this.gservice.set_second_appointment_data(null);
        this.gservice.set_second_document_data(null);

        if (this.secOpnDoctorList.length != 0) {
            this.gservice.set_second_doctor_data(this.sendSecOpnDoctorData);
            var getdata = Client_Helper.getDocSearch();
            this.clientservice.sendMessage('secondopinioncreate')
        } else {
            this.toastr.error(Message_data.sltDoctor);
        }
    }

     filter() {
        const dialogRef = this.dialog.open(DoctorfilterpageComponent, {
            width: '248px',
            height: '447px',
            position: { right: '3%', top: '7%' },
            data: {
            }
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data != null) {
                this.sendDoctorList = data;
                this.doctorFilter(this.sendDoctorList);
            }
        });
    }

    doctorFilter(send_data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.doctorListUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().info != null && data.json().info.length != 0) {
                    this.getDoctorDetails(data.json().info);
                } else {
                    this.doctorList = [];
                }
            },error => {});
    }

    // callback...
    myCallbackFunction = function (_params) {
        return new Promise((resolve, reject) => {
            resolve(_params);
        });
    }
}
