import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { capitalize } from '../../../assets/js/common';
import * as $ from 'jquery/dist/jquery.min.js';
import { ipaddress } from '../../ipaddress';
declare var cordova: any;
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-user-view',
  templateUrl: './diagnosis-user-view.component.html',
  styleUrls: ['./diagnosis-user-view.component.scss']
})
export class DiagnosisUserViewComponent implements OnInit {
  public userTypeArray = [];
  public locationArray = [];
  public UserType;
  public diagID;
  public Location;
  public showUserLocation: boolean;
  public userImageFlag: boolean;
  public showImageFlag: boolean;
  public imgURL;
  public imgString;
  public mobileNumber;
  public emailID;
  public firstName;
  public middleName;
  public lastName;
  public Gender;
  public userType;
  public mobileNo;
  public Email;
  public password;
  public confirmPassword;
  public diagMidName;
  public diagUserEmail;
  public sendImage;
  public diagCenterID;
  public diagRegID;
  public uploadedSignature: boolean;
  public retriveLocation;
  public saveFlag: boolean;
  public updateFlag: boolean;
  public reteiveLocationFlag: boolean;
  public showLoactionFlag: boolean;
  public retrivelNameFlag: boolean;
  public reportApproval;
  public reportData;
  
  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http) {
    this.userImageFlag = true;
    this.mobileNumber = "0";
    this.emailID = "0";
  }

  ngOnInit(): void {
    this.diagID = Diagnosis_Helper.getDiagnosis_id();
    this.diagRegID = Diagnosis_Helper.getDiag_user_reg_id();
    this.userTypeArray.push("Chief pathologist");
    this.userTypeArray.push("Pathologist");
    this.userTypeArray.push("Chief lab technician");
    this.userTypeArray.push("Lab technician");
    this.userTypeArray.push("front-desk");
    this.imgURL = "default";
    if (this.diagRegID != null) {
      this.userRetrieval(this.diagRegID);
      this.saveFlag = true;
      this.updateFlag = false;
      this.reteiveLocationFlag = false;
      this.showLoactionFlag = true;

    } else {
      this.saveFlag = false;
      this.updateFlag = true;
      this.reteiveLocationFlag = true;
      this.showLoactionFlag = false;
    }
    this.GetLocation();
  }

  public createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + ".jpg";
    return newFileName;
  }

  convertToBase64(url, outputFormat) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'),
          dataURL;
        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        dataURL = canvas.toDataURL(outputFormat);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }

  public pathForImage(img) {
    if (img === null)
      return '';
    else
      return cordova.file.dataDirectory + img;
  }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgString = null;
      } else {
        this.imgString = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
      this.imgURL = myReader.result;
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  GetLocation() {//Get location
    this.locationArray = [];
    this.UserType = Diagnosis_Helper.getUserID_Type().user_type != null ? "diag_user" : "diagnosis";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: Diagnosis_Helper.getUserID_Type().user_id,
        flag: "id",
        imei: localStorage.getItem("ipaddress"),
        prov_id: Diagnosis_Helper.getUserID_Type().user_id,
        type: this.UserType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.locationArray.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          for (var i = 0; i < this.locationArray.length; i++) {
            if (this.diagID == this.locationArray[i].centre_id) {
              this.Location = this.locationArray[i].view_data;
              this.showUserLocation = true;
              break;
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeUsertype() {
    if (this.userType == "Pathologist") {
      this.userImageFlag = false;
      this.showImageFlag = true;
    } else if (this.userType == "Chief pathologist") {
      this.userImageFlag = false;
      this.showImageFlag = true;
    } else {
      this.userImageFlag = true;
      this.showImageFlag = false;
    }
  }

  usertypeChange1(Usertype) {
    if (Usertype == "Pathologist") {
      this.userImageFlag = false;
      this.showImageFlag = true;
    } else if (Usertype == "Chief pathologist") {
      this.userImageFlag = false;
      this.showImageFlag = true;
    } else {
      this.userImageFlag = true;
      this.showImageFlag = false;
    }
  }

  saveUser(flag) { //Save user 
    var Valid_flag = false;
    if (flag == "save") {
      if (this.firstName == undefined || this.firstName == "" || this.lastName == undefined || this.lastName == "" ||
        this.Gender == undefined || this.Gender == null ||
        this.Gender == "" || this.userType == undefined || this.userType == null ||
        this.userType == "" || this.mobileNo == undefined || this.mobileNo == "" ||
        this.password == undefined && this.password == "" || this.confirmPassword == undefined ||
        this.confirmPassword == "") {
        Valid_flag = true;
        this.toastr.error(Message_data.mandatory);
      } else if (this.mobileNo.length != 10) {
        Valid_flag = true;
        this.toastr.error(Message_data.enterCorrectMobileno);

      } else if (this.password != this.confirmPassword) {
        Valid_flag = true;
        this.toastr.error(Message_data.pwdNotMatched);
      }

    } else {
      if (this.firstName == undefined || this.firstName == "" || this.lastName == undefined ||
        this.lastName == "" || this.Gender == undefined || this.Gender == null ||
        this.Gender == "" || this.userType == undefined || this.userType == null ||
        this.userType == "" || this.mobileNo == undefined || this.mobileNo == "") {
        Valid_flag = true;
        this.toastr.error(Message_data.mandatory);
      } else if (this.mobileNo.length != 10) {
        Valid_flag = true;
        this.toastr.error(Message_data.enterCorrectMobileno);
      }
    }

    if (Valid_flag == false) {
      if (this.Email != undefined && this.Email != "") {
        var x = this.Email;
        var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
        if (!re.test(x)) {
          Valid_flag = true;
          this.toastr.error(Message_data.validEmail);
        } else {
          if (flag == "save") {
            this.checkUserMailExist(flag);
          } else {
            if (this.emailID != "0") {
              if (this.emailID != this.Email) {
                this.checkUserMailExist(flag);
              } else if (this.mobileNo != this.mobileNumber) {
                this.checkUserMobileExist(flag);
              } else {
                this.getUserLastData(flag);
              }
            }
          }
        }
      } else {
        if (flag == "save")
          this.checkUserMobileExist(flag);
        else {
          if (this.mobileNo != this.mobileNumber)
            this.checkUserMobileExist("");
          else
            this.getUserLastData(flag);
        }
      }
    }
  }

  checkUserMailExist(flag) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist/',
      JSON.stringify({
        email: this.Email,
        flag: "7"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result != null) {
            if (flag == "save") {
              if (obj.result == true)
                this.toastr.error(Message_data.existEmailID);
              else
                this.checkUserMobileExist(flag);

            } else {
              if (obj.result == true)
                this.toastr.error(Message_data.existEmailID);
              else if (this.mobileNo != this.mobileNumber)
                this.checkUserMobileExist(flag);
              else
                this.getUserLastData(flag);
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  checkUserMobileExist(flag) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist/',
      JSON.stringify({
        mobile: this.mobileNo,
        flag: "7"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result != null) {
            if (obj.result == true)
              this.toastr.error(Message_data.existMobileNo);
            else
              this.getUserLastData(flag);
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getUserLastData(flag) {//save and update user
    if (this.middleName != undefined && this.middleName != "")
      this.diagMidName = capitalize(this.middleName);
    else
      this.diagUserEmail = undefined;

    this.diagUserEmail = this.Email != undefined && this.Email != "" ? this.Email : undefined;
    this.reportData = this.reportApproval == true ? "1" : "0";
    var save_url = "";
    if (flag == "save") {
      if (this.imgURL != "default") { // Path
        save_url = ipaddress.getIp.toString() + "diagnosiscontroller/suserweb/";
        this.sendImage = this.imgURL.split(',')[1];
      } else {
        save_url = ipaddress.getIp.toString() + "diagnosiscontroller/suser/";
      }
      if (this.locationArray != undefined) {
        for (var i = 0; i < this.locationArray.length; i++) {
          if (this.locationArray[i].view_data == this.Location) {
            this.diagCenterID = this.locationArray[i].centre_id;
          }
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(save_url,
        JSON.stringify({
          diag_centre_id: this.diagCenterID,
          diag_reg_id: Diagnosis_Helper.getUserID_Type().user_id,
          first_name: capitalize(this.firstName),
          middle_name: this.diagMidName,
          last_name: capitalize(this.lastName),
          user_type: this.userType,
          gender: this.Gender,
          mobile: this.mobileNo,
          email: this.Email,
          password: this.password,
          sign: this.sendImage,
          report_approval: this.reportData
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null && obj.key == "1") {
              this.saveFlag = true;
              this.toastr.success(Message_data.userSavedSuccess);
              this.messageservice.sendMessage("users");
            } else {
              if (obj.result != null)
                this.toastr.error(obj.result);
              else
                this.toastr.error(Message_data.userNotSavedSuccess);
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });

    } else {
      if (this.imgURL != "default") {// Path
        save_url = ipaddress.getIp.toString() + "diagnosiscontroller/uuweb/";
        this.sendImage = this.imgURL.split(',')[1];
      } else {
        save_url = ipaddress.getIp.toString() + "diagnosiscontroller/udu/";
      }
      if (this.locationArray != undefined) {
        for (var i = 0; i < this.locationArray.length; i++) {
          if (this.locationArray[i].view_data == this.Location) {
            this.diagCenterID = this.locationArray[i].centre_id;
          }
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(save_url,
        JSON.stringify({
          diag_users_id: this.diagRegID,
          user_type: this.userType,
          mobile: this.mobileNo,
          email: this.Email,
          sign: this.sendImage,
          report_approval: this.reportData
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null && obj.key == "1") {
              this.updateFlag = true;
              this.toastr.success(Message_data.userSavedSuccess);
              this.messageservice.sendMessage("users");
            } else {
              if (obj.result != null)
                this.toastr.error(obj.result);
              else {
                this.toastr.error(Message_data.userNotSavedSuccess);
              }
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }

  userRetrieval(user_id) { // retrieval data
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gdu/",
      JSON.stringify({
        diag_users_id: user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.first_name != null) {
            this.firstName = obj.first_name;
            this.retrivelNameFlag = true;
          }
          if (obj.middle_name != null) {
            this.middleName = obj.middle_name;
          }
          this.lastName = obj.last_name;
          this.Gender = obj.gender;
          this.userType = obj.user_type;
          this.mobileNumber = obj.mobile;
          this.mobileNo = obj.mobile;
          if (obj.email != null) {
            this.emailID = obj.email;
            this.Email = obj.email;
          }
          if (obj.report_approval != null && obj.report_approval == "1") {
            this.reportApproval = true;
          }
          if (obj.sign != null) {
            this.userImageFlag = false;
            $('#profile_img').attr('src', 'data:image/jpeg;base64,' + obj.sign);
          }
          this.retriveLocation = obj.location;
          this.changeUsertype();
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  backClicked() {
    this.messageservice.sendMessage("users");
  }
}
