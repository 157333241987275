import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Nurse_Day_Return, Session_Return, ConvertTimeformat, diag_dayid, Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var cordova: any;
import * as $ from 'jquery/dist/jquery.min.js';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../Doctor_module/Master_Helper';

@Component({
  selector: 'app-diagnosis-home',
  templateUrl: './diagnosis-home.component.html',
  styleUrls: ['./diagnosis-home.component.css']
})
export class DiagnosisHomeComponent implements OnInit {
  //profile
  public imgString;
  public profileIMG;
  public mobileString;
  public emailString;
  public mobile;
  public email;
  public homeSample;
  public clientFirstName;
  public clientMidName;
  public clientLastName;
  public gender;
  public dob;
  public regCode;
  public regYear;
  public regState;
  public homeSampleFlag: boolean = true;
  //work_location
  public workArray = [];
  public startArray = [];
  public endArray = [];
  public hospitalName: string;
  public hospitaldistric;
  public hospitalLocation: string;
  public hospitalState;
  public hospitalCountry;
  public hospitalZip: string;
  public hospitalAddress1: string;
  public hospitalAddres2: string;
  public hosptialNumber: string;
  public hopitalWebsite: string;
  public hospitalFees: string;
  public hospitalDays: string;
  public hospitalSession: string;
  public startFrom1: string;
  public startFrom2: string;
  public startFrom3: string;
  public endAt1: string;
  public endAt2: string;
  public endAt3: string;
  public hospitalConsult: boolean;
  public appTokenFlag: boolean;
  public appTimeFlag: boolean;
  public count: string;
  public hospitalArray = [];
  public location = []
  public selectedCity;
  public selectedState;
  public locID: string;
  public countryID: string;
  public country: string;
  public locationArray = [];
  public filteredlocList = [];
  public hosArray = [];
  public hospitalListArray = [];
  public locationList;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public cityListArray;
  public stateListArray;
  public countryListArray;
  public hospitalID;
  public sendCItyID;
  public IsExists: boolean;
  public sendStateID;
  public sendCity;
  //update
  public profile: any;
  public profileWorkTabArray: any;
  public profileWorkAddArray: any;
  public sendURL = "usercontroller/updprov/";
  public emailID = null;
  public userType;

  public from_date;
  public to_date;
  currentDate: any;

  public sample_Testcount: any = 0;
  public sample_Ipcount: any = 0;
  public sample_Opcount: any = 0;
  public sample_green: any = 0;
  public sample_orange: any = 0;
  public sample_red: any = 0;
  public spl_Investigation: any = 0;
  public assert_Microbiology: any = 0;
  public assert_Pathology: any = 0;
  public assert_Radiology: any = 0;
  public assert_Green: any = 0;
  public assert_Orange: any = 0;
  public assert_Red: any = 0;
  public pending_spl_Investigation: any = 0;
  public pending_Microbiology: any = 0;
  public pending_Pathology: any = 0;
  public pending_Radiology: any = 0;
  public pending_Green: any = 0;
  public pending_Orange: any = 0;
  public pending_Red: any = 0;
  dash_upd_date: any;
  currentTime: any;
  dash_upd_time: any;
  test_nt_completed: any;
  test_nt_signed: any;
  hospital_details: any;
  hosp_id: any;
  userInfo: any;
  diag_centre_id: any;


  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
    this.hospitalName = "";
    this.hospitalLocation = "";
    this.hospitaldistric = "";
    this.hospitalState = "";
    this.hospitalCountry = "";
    this.hospitalZip = "";
    this.hospitalAddress1 = "",
      this.hospitalAddres2 = "";
    this.hosptialNumber = "";
    this.hopitalWebsite = '';
    this.hospitalFees = "";
    this.hospitalDays = "";
    this.hospitalSession = "";
    this.hospitalConsult = false;
    this.count = "";
    this.appTokenFlag = true;
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";
    this.endAt1 = "08";
    this.endAt2 = "00";
    this.endAt3 = "AM";
    this.hospitalID = 0;
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.IsExists = false;
    // time format
    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.startArray.push("0" + i);
      else
        this.startArray.push(i);
    }
    for (var j = 0; j <= 55; j += 5) {
      if (j < 10)
        this.endArray.push("0" + j);
      else
        this.endArray.push(j);
    }
  }
  ngOnInit(): void {
    // if(Diagnosis_Helper.getUserID_Type().user_type != undefined){
    //   this.userType = Diagnosis_Helper.getUserID_Type().user_type;
    // } else {

    // }

    this.userInfo = Helper_Class.getInfo();

    if (this.userInfo.admin == '1' || this.userInfo.user_type == 'Admin') {
      this.diag_centre_id = this.userInfo.diag_centre_id
    } else {
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }

    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hosp_id = this.hospital_details.hptl_clinic_id;

    console.log(this.diag_centre_id)

    this.userType = "diagnosis"
    this.getProfile();
    this.getDate();

  }

  // async get_curr_date() {
  //   var obj = Master_Helper.getMasterCurrentDate();
  //   if (obj != undefined) {
  //     this.curr_date = obj.current_date;
  //     this.to_date = obj.current_date;
  //     this.from_date = Date_Formate(this.curr_date);
  //     this.to_date = Date_Formate(this.curr_date);
  //   }
  // }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        this.currentTime = data.json().current_time;
      },
      error => { });
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.from_date = data.json().current_date;
          this.to_date = data.json().current_date;
          // var new_day = this.currentDate.split('-');
          // var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          // this.date = [{
          //   day: current_date_data
          // }];
          this.getDashData();
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  //Read only(disabeld)
  isReadonly() { return true; }
  public createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + ".jpg";
    return newFileName;
  }
  convertToBase64(url, outputFormat) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'),
          dataURL;
        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        dataURL = canvas.toDataURL(outputFormat);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }
  fileChange($event): void {
    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgString = null;
      } else {
        this.imgString = myReader.result.toString();
        $('#profile_img1').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  public pathForImage(img) {
    if (img === null)
      return '';
    else
      return cordova.file.dataDirectory + img;
  }
  sendProfile() {
    var homesample = null;
    homesample = this.homeSampleFlag == true ? "1" : "0";
    var img_path = this.profileIMG, upd_url = "0";
    if (this.imgString != null) {
      img_path = this.imgString.split(',')[1];
      upd_url = "1";
    } else {
      upd_url = undefined;
    }
    this.mobileString = encrypt_decript.Encript(this.mobile).toString();
    this.emailString = encrypt_decript.Encript(this.email).toString();
    var send_array = null;
    send_array = {
      upd_url: upd_url,
      profile_image: img_path,
      serv_provider_reg_id: Diagnosis_Helper.getUserID_Type().user_id,
      mobile: this.mobileString,
      client_call: "0",
      home_sample: homesample,
      email: this.emailString,
      language: "",
      provider: "diagnosis",
    }
    Helper_Class.setprofile_array(send_array);
  }
  // profile retrieval data
  getProfile() {
    if (Helper_Class.getInfo().user_type == "diagnosis") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/providerdetails',
        JSON.stringify({
          diagnosis: Diagnosis_Helper.getUserID_Type().user_id,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.profile_image != null) {
              this.profileIMG = obj.profile_image
              try {
                var img = document.getElementById("profile_img") as HTMLImageElement;
                img.src = ipaddress.Ip_with_img_address + obj.profile_image;
              } catch (error) { }
              try {
                var img1 = document.getElementById("profile_img1") as HTMLImageElement;
                img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
              } catch (error) { }
            } else {
              this.toastr.error(Message_data.getNetworkMessage());
            }
            if (obj.first_name != null)
              this.clientFirstName = obj.first_name;
            if (obj.middle_name != null)
              this.clientMidName = obj.middle_name;
            if (obj.last_name != null)
              this.clientLastName = obj.last_name;
            if (obj.dob != null)
              this.dob = Date_Formate(obj.dob);
            if (obj.home_sample != null)
              this.homeSampleFlag = obj.home_sample == "1" ? true : false;
            if (obj.mobile != null)
              this.mobile = encrypt_decript.Decript(obj.mobile);
            if (obj.email != null)
              this.email = encrypt_decript.Decript(obj.email);
            if (obj.gender != null)
              this.gender = obj.gender;
            if (obj.registeration_code != null)
              this.regCode = obj.registeration_code;
            if (obj.registeration_year != null)
              this.regYear = obj.registeration_year;
            if (obj.registeration_state != null)
              this.regState = obj.registeration_state;
            var workarray = [];
            for (var i = 0; i < obj.consultation.length; i++) {
              var token_name = undefined, time_dure = undefined;
              if (obj.consultation[i].token != "0") {
                token_name = obj.consultation[i].token;
                time_dure = "0";
              } else {
                token_name = "0";
                time_dure = obj.consultation[i].app_duration;
              }
              this.workArray.push({
                hospital_id: obj.consultation[i].hospital_clinic_id,
                name: obj.consultation[i].name,
                location: obj.consultation[i].location,
                city: obj.consultation[i].city,
                state: obj.consultation[i].state,
                country: obj.consultation[i].country,
                zipcode: obj.consultation[i].zipcode,
                address1: obj.consultation[i].address1,
                address2: obj.consultation[i].address2,
                mobilenum: obj.consultation[i].telephone,
                website: obj.consultation[i].hospital_website,
                day_desc: Nurse_Day_Return(obj.consultation[i].day),
                day: obj.consultation[i].day,
                session_desc: Session_Return(obj.consultation[i].day_session),
                day_session: obj.consultation[i].day_session,
                available_from: Time_Formate(obj.consultation[i].available_from),
                available_to: Time_Formate(obj.consultation[i].available_to),
                consultant: obj.consultation[i].consultant,
                token: token_name,
                app_duration: time_dure,
                consult_time_id: obj.consultation[i].consultation_time_id,
              })
            }
            Helper_Class.set_work_array(workarray);
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }
  changeHomeSample(e) {//toggle
    this.homeSampleFlag = e;
    if (e == true)
      document.getElementById("toggid").style.background = "red";
    else
      document.getElementById("toggid").style.background = "green";
  }
  getWork() {//work_location // add table
    if (this.IsExists == false) {
      var cosult_id = null;
      cosult_id = this.hospitalConsult == true ? 1 : 0;
      if (this.hospitalName == "" || this.hospitalLocation == "" ||
        this.hospitaldistric == undefined || this.hospitalState == "" || this.hospitalCountry == "" ||
        this.hospitalZip == "" || this.hospitalAddress1 == "" || this.hosptialNumber == "" ||
        this.hospitalDays == "") {
        this.toastr.error(Message_data.mandatory)
      } else {
        var f_time = ConvertTimeformat("", this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3);
        var t_time = ConvertTimeformat("", this.endAt1 + ":" + this.endAt2 + " " + this.endAt3);
        for (var i = 0; i < this.cityListArray.length; i++) {
          if (this.cityListArray[i].city_id == this.hospitaldistric) {
            this.sendCity = this.cityListArray[i].city_desc;
          }
        }
        for (var i = 0; i < this.stateListArray.length; i++) {
          if (this.stateListArray[i].state_id == this.hospitalState) {
            this.sendStateID = this.stateListArray[i].state_desc;
          }
        }
        this.workArray.push({
          hospital_id: this.hospitalID,
          name: this.hospitalName,
          location: this.hospitalLocation,
          city: this.sendCity,
          state: this.sendStateID,
          country: this.hospitalCountry,
          zipcode: this.hospitalZip,
          address1: this.hospitalAddress1,
          address2: this.hospitalAddres2,
          mobilenum: this.hosptialNumber,
          website: this.hopitalWebsite,
          day_desc: this.hospitalDays,
          day: diag_dayid(this.hospitalDays),
          available_from: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3,
          available_to: this.endAt1 + ":" + this.endAt2 + " " + this.endAt3,
          consultant: cosult_id,
          appointment: this.appTokenFlag + "" + this.appTimeFlag,
          consult_time_id: "0"
        })
        this.hospitalName = "";
        this.hospitalLocation = "";
        this.hospitaldistric = "";
        this.hospitalState = "";
        this.hospitalCountry = "";
        this.hospitalZip = "";
        this.hospitalAddress1 = "",
          this.hospitalAddres2 = "";
        this.hosptialNumber = "";
        this.hopitalWebsite = '';
        this.hospitalFees = "";
        this.hospitalDays = "";
        this.hospitalSession = "";
        this.hospitalConsult = false;
        this.count = "";
        this.startFrom1 = "06";
        this.startFrom2 = "00";
        this.startFrom3 = "AM";
        this.endAt1 = "08";
        this.endAt2 = "00";
        this.endAt3 = "AM";
      }
    } else {
      this.toastr.error(Message_data.diagCenterAlrdyRegInLoc);
    }
  }
  deleteWork(name_data, day_desc, from_time, end_time) {//table delecte action
    for (var i = 0; i < this.workArray.length; i++) {
      if (name_data == this.workArray[i].name && day_desc == this.workArray[i].day_desc
        && from_time == this.workArray[i].available_from && end_time == this.workArray[i].available_to) {
        this.workArray.splice(i, 1);
      }
    }
  }
  filterHospital(e) {
    this.cityListArray = [];
    this.stateListArray = [];
    this.countryListArray = [];
    this.hospitalArray = [];
    this.hospitalLocation = '';
    this.hospitaldistric = '';
    this.hospitalState = '';
    this.hospitalCountry = '';
    this.hospitalZip = '';
    this.hospitalAddress1 = '';
    this.hospitalAddres2 = '';
    this.hosptialNumber = '';
    this.hopitalWebsite = '';
    this.hospitalArray = [];
    this.hospitalListArray = [];
    if (this.hospitalName !== "" && this.hospitalName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagnosis/',
        JSON.stringify({
          diag_name: this.hospitalName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.diagnosis != null) {
              this.hospitalListArray = obj.diagnosis;
              for (var i = 0; i < this.hospitalListArray.length; i++) {
                this.hospitalArray.push(this.hospitalListArray[i].diag_name);
              }
              this.hosArray = this.hospitalArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospitalName.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.hosArray = [];
    }
  }
  selectHospital(data) {
    this.hospitalName = data;
    this.hosArray = [];
  }
  locFilter(e) { //location autopopulate
    this.hospitaldistric = '';
    this.hospitalState = '';
    this.hospitalCountry = '';
    this.hospitalZip = '';
    this.hospitalAddress1 = '';
    this.hospitalAddres2 = '';
    this.hosptialNumber = '';
    this.hopitalWebsite = '';
    this.hospitalID = 0;
    this.location = [];
    this.location = [];
    if (this.hospitalLocation !== "" && this.hospitalLocation.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hospitalLocation
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.locationArray = obj.locations;
              this.filteredlocList = this.locationArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospitalLocation.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.hospitalArray = [];
    }
  }
  selectLocation(data) {
    this.hospitalLocation = data;
    this.hospitalArray = [];
    this.getCity(data);
    this.stateListArray = [];
    this.cityListArray = [];
    this.countryListArray = [];
  }
  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.cityListArray = data.json().cities;
          this.hospitaldistric = this.cityListArray[0].city_id;
          this.selectedCity = this.cityListArray[0].city_desc;
          this.locID = this.cityListArray[0].location_id;
          this.hospitalZip = this.cityListArray[0].zipcode;
          this.changeCity(this.cityListArray[0].city_id);
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }
  changeCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateListArray = data.json().states;
            this.hospitalState = this.stateListArray[0].state_id;
            this.selectedState = this.stateListArray[0].state_desc;
            this.changeState(this.stateListArray[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.stateListArray = [];
      this.cityListArray = [];
      this.countryListArray = [];
    }
  }
  changeState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.countryListArray = data.json().countries;
            this.countryID = this.countryListArray[0].country_id;
            this.hospitalCountry = this.countryListArray[0].country_desc;
            this.changeSerCountry();
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.stateListArray = [];
      this.countryListArray = [];
    }
  }
  changeSerCountry() {
    for (var i = 0; i < this.cityListArray.length; i++) {
      if (this.cityListArray[i].city_desc == this.hospitaldistric) {
        this.sendCItyID = this.cityListArray[i].city_id;
      }
    }
    if (this.hospitalName != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/isdiagexist/',
        JSON.stringify({
          diag_name: this.hospitalName,
          location: this.hospitalLocation,
          city: this.sendCItyID
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.is_exists != null) {
              if (obj.is_exists == true) {
                this.IsExists = true;
                this.toastr.error(Message_data.diagCenterAlrdyRegInLoc);
              } else {
                this.IsExists = false;
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetailsbyname/',
                  JSON.stringify({
                    diag_name: this.hospitalName,
                    location: this.hospitalLocation,
                  }),
                  { headers: headers })
                  .subscribe(
                    response => {
                      var obj = JSON.parse(response["_body"]);
                      if (obj.diag_id != null) {
                        this.hospitalID = obj.diag_id;
                        this.hospitalAddress1 = obj.address1;
                        this.hospitalAddres2 = obj.address2;
                        if (obj.telephone != null) {
                          this.hosptialNumber = obj.telephone;
                        }
                        if (obj.website != null) {
                          this.hopitalWebsite = obj.website;
                        }
                      }
                    }, error => {
                      this.toastr.error(Message_data.getNetworkMessage());
                    });
              }
            }
            else {
              this.IsExists = false;
              this.hospitalID = 0;
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }
  sendData() {
    var work_arry1 = [];
    var work_address_array = [];
    for (var i = 0; i < this.workArray.length; i++) {
      if (this.workArray[i].hospital_id == "0") {
        var address2_txt = undefined;
        address2_txt = this.workArray[i].address2 != undefined ? this.workArray[i].address2 : "";
        work_address_array.push({
          name: this.workArray[i].name,
          address1: this.workArray[i].address1,
          address2: address2_txt,
          city: this.workArray[i].city,
          state: this.workArray[i].state,
          zipcode: this.workArray[i].zipcode,
          country: this.workArray[i].country,
          telephone: this.workArray[i].mobilenum,
          website: this.workArray[i].hospital_website,
          location: this.workArray[i].location,
        });
        var hosp_nursename = this.workArray[i].name;
        var hosp_nurseloc = this.workArray[i].location;
      }
      var consult_time_id = this.workArray[i].consult_time_id != "0" ? this.workArray[i].consult_time_id : "0";
      work_arry1.push({
        hospital_id: this.workArray[i].hospital_id,
        available_from: ConvertTimeformat("", this.workArray[i].available_from),
        available_to: ConvertTimeformat("", this.workArray[i].available_to),
        day: this.workArray[i].day,
        consultation_time_id: consult_time_id,
        hosp_name: hosp_nursename,
        location: hosp_nurseloc,
      });
    }
    Helper_Class.set_work_array(work_arry1);
    Helper_Class.set_work_add_array(work_address_array);
  }
  update() {//update
    this.sendProfile();
    this.sendData();
    this.profile = Helper_Class.getprofile_array();
    this.profileWorkTabArray = Helper_Class.get_work_array();
    this.profileWorkAddArray = Helper_Class.get_work_add_array();
    for (var key in this.profile) {
      if (key == "upd_url" && this.profile[key] != undefined)
        this.sendURL = "usercontroller/updprovweb/";
      else if (key == "mobile" && this.profile[key] != undefined)
        this.mobile = this.profile[key];
      else if (key == "email" && this.profile[key] != undefined)
        this.emailID = this.profile[key];
    }
    if (encrypt_decript.Decript(this.profile.email).toString().trim() == "" || encrypt_decript.Decript(this.profile.mobile).length != 10)
      this.toastr.error(Message_data.validEmail);
    else if (encrypt_decript.Decript(this.mobile).toString().trim() == "" || encrypt_decript.Decript(this.mobile).length != 10)
      this.toastr.error(Message_data.validMobileNo);
    else if (this.profileWorkTabArray == undefined || this.profileWorkTabArray.length == 0)
      this.toastr.error(Message_data.verifyWrkLocation);
    else {
      if (this.mobile != Helper_Class.getprofile_array().mobile)
        this.mobileCheck();
      else
        this.emailCheck();
    }
  }
  mobileCheck() {
    if (this.mobile != Helper_Class.getprofile_array().mobile) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
        JSON.stringify({
          mobile: this.mobile,
          flag: "3"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true)
              this.toastr.error(Message_data.existMobileNo);
            else
              this.emailCheck();
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }
  emailCheck() {
    if (this.emailID != Helper_Class.getprofile_array().email) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: this.emailID,
          flag: "3"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true)
              this.toastr.error(Message_data.existEmailID);
            else
              this.finalUpdate();
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.finalUpdate();
    }
  }
  finalUpdate() {
    var send_work = null;
    send_work = {
      consultation: this.profileWorkTabArray
    }
    var finalObj = $.extend(true, this.profile, send_work);
    var output = null;
    output = finalObj;
    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_work_add_array();
    if (getfoot_ass != undefined && getfoot_ass.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output = $.extend(true, finalObj, send_foot);
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.sendURL, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.profileUpdSuccess);
            this.router.navigateByUrl("/loginpage");
          } else {
            this.toastr.error(Message_data.profileUpdFailed);
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  getDashData() {
    this.getCurrentDate()
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/gddfh',
      JSON.stringify({
        diag_centre_id: this.diag_centre_id,
        // hosp_id: this.hosp_id,
        from_date: this.from_date,
        to_date: this.to_date,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(obj);

          console.log(JSON.stringify({
            diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
            // hosp_id: this.hosp_id,
            from_date: this.from_date,
            to_date: this.to_date,
          }))

          this.dash_upd_date = Date_Formate(this.currentDate);
          this.dash_upd_time = this.currentTime;

          if (obj.sample != undefined) {
            if (obj.sample[0].Test_count != undefined) {
              this.sample_Testcount = obj.sample[0].Test_count;
            } else {
              this.sample_Testcount = 0;
            }

            if (obj.sample[0].inpatient != undefined) {
              this.sample_Ipcount = obj.sample[0].inpatient;
            } else {
              this.sample_Ipcount = 0;
            }

            if (obj.sample[0].outpaitent != undefined) {
              this.sample_Opcount = obj.sample[0].outpaitent;
            } else {
              this.sample_Opcount = 0;
            }

            if (obj.sample[0].green != undefined) {
              this.sample_green = obj.sample[0].green;
            } else {
              this.sample_green = 0;
            }

            if (obj.sample[0].orange != undefined) {
              this.sample_orange = obj.sample[0].orange;
            } else {
              this.sample_orange = 0;
            }

            if (obj.sample[0].red != undefined) {
              this.sample_red = obj.sample[0].red;
            } else {
              this.sample_red = 0;
            }
          }

          if (obj.assertion != undefined) {
            if (obj.assertion[0].Special_Investigation != undefined) {
              this.spl_Investigation = obj.assertion[0].Special_Investigation;
            } else {
              this.spl_Investigation = 0;
            }

            if (obj.assertion[0].Microbiology != undefined) {
              this.assert_Microbiology = obj.assertion[0].Microbiology;
            } else {
              this.assert_Microbiology = 0;
            }

            if (obj.assertion[0].Pathology != undefined) {
              this.assert_Pathology = obj.assertion[0].Pathology;
            } else {
              this.assert_Pathology = 0;
            }

            if (obj.assertion[0].Radiology != undefined) {
              this.assert_Radiology = obj.assertion[0].Radiology;
            } else {
              this.assert_Radiology = 0;
            }

            this.assert_Green = obj.assertion[0].Green != undefined ? obj.assertion[0].Green : 0;
            this.assert_Orange = obj.assertion[0].Orange != undefined ? obj.assertion[0].Orange : 0;
            this.assert_Red = obj.assertion[0].Red != undefined ? obj.assertion[0].Red : 0;
          }

          if (obj.pending != undefined) {

            this.pending_spl_Investigation = obj.pending[0].special_investigation != undefined ? obj.pending[0].special_investigation : 0;
            this.pending_Microbiology = obj.pending[0].Microbiology != undefined ? obj.pending[0].Microbiology : 0;
            this.pending_Pathology = obj.pending[0].Pathology != undefined ? obj.pending[0].Pathology : 0;
            this.pending_Radiology = obj.pending[0].Radiology != undefined ? obj.pending[0].Radiology : 0;

            this.pending_Green = obj.pending[0].Green != undefined ? obj.pending[0].Green : 0;
            this.pending_Orange = obj.pending[0].Orange != undefined ? obj.pending[0].Orange : 0;
            this.pending_Red = obj.pending[0].Red != undefined ? obj.pending[0].Red : 0;
          }

          if (obj.approve != undefined) {
            this.test_nt_completed = obj.approve[0].test_not_complete != undefined ? obj.approve[0].test_not_complete : 0;
            this.test_nt_signed = obj.approve[0].test_not_signed != undefined ? obj.approve[0].test_not_signed : 0;
          }

        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });

  }
}
