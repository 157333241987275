import { Component, OnInit, ViewChild, ElementRef,HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Data, Route, Router } from '@angular/router';

import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { Date_Formate, Time_Formate } from '../../../assets/js/common.js';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Message_data } from '../../../assets/js/Message_data';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { EditorChangeContent, EditorChangeSelection, Focus } from 'ngx-quill';
import { ipaddress } from '../../ipaddress';
import { Nurse_Helper } from 'src/app/Nurse_module/Nurse_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { PreviousPrescriptionPopupComponent } from '../previous-prescription-popup/previous-prescription-popup.component';
import { MedPresPopupPageComponent } from '../med-pres-popup-page/med-pres-popup-page.component';
declare var $: any;
declare var $: any;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-noappointment',
  templateUrl: './noappointment.component.html',
  styleUrls: [
    './noappointment.component.css',
    '../../FrontDesk_module/recept-app-create-page/recept-app-create-page.component.css'
  ]
})
export class NoappointmentComponent implements OnInit {
  @ViewChild('cfname') cfname;
  @ViewChild('myInputField') myInputField!: ElementRef;
  @ViewChild('cmname') cmname;
  @ViewChild('clname') clname;
  @ViewChild('cmobile') cmobile;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  public med_style;
  public printtime;
  public print:boolean;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public medstyle: boolean = false;
  public medstyle1: boolean = false;
  public medstyle2: boolean = false;
  public medstyle3: boolean = false;
  public not_Inpatient: boolean = true;
  public intakesession: boolean = false;
  public dur_flag: boolean = false;
  public notes_flag: boolean = false;
  public weight_flag:boolean=false;
  public pulseflag:boolean=false;
  public headerstyle;
  public footerstyle;
  public preslogo_style;
  public rsflag:boolean=false;
  public printLanguageflag:boolean=true;
  public barcode;
  public barcodeflag:boolean=false;
  public param_four: boolean;
  public found;
  public dob_flag: boolean;
  public disable_dob: boolean;
  public disable_age: boolean;
  public saveFlag: boolean = false;
  public historyFlag: boolean = true;
  public get_locname_url: string;
  public treate_list_data = [];
  public cvsflag: boolean = false;
  public kidneyflag:boolean=false;
  public clnt_fname: string;
  public clnt_mname: string;
  public clnt_lname: string;
  public clnt_age;
  public clnt_gender: string;
  public clnt_blood: string;
  public clnt_blood_id: string;
  public clnt_fg_name: string;
  public clnt_income: string;
  public clnt_occupation: string;
  public clnt_address1!: string | null;
  public clnt_address2!: string | null;
  public clnt_location: string;
  public clnt_city: string;
  public clnt_state: string;
  public clnt_country: string;
  public clnt_location_id!: string | undefined;
  public clnt_city_id!: string | undefined;
  public clnt_state_id: string;
  public clnt_country_id: string;
  public clnt_zipcode: string;
  public clnt_cont_number;
  public clnt_symptom: string;
  public newsymptoms_flag: boolean = false;
  public newmedhist_flag: boolean = false;
  public newfamhist_flag: boolean = false;
  public newpresill_flag: boolean = false;
  public newphyexamdet_flag: boolean = false;
  public newtreatdet_flag: boolean = false;

  public newsymptoms: any = [];
  public newmedhist: any = [];
  public newfamhist: any = [];
  public newpresill: any = [];
  public newphyexamdet: any = [];
  public newtreatdet: any = [];

  public clnt_dob;
  public submenu_flag: boolean = false;

  // public opinion_type;
  public treatement_type;
  public clnt_location_list: any = [];
  public clnt_city_list: any = [];
  public clnt_state_list: any = [];
  public clnt_country_list: any = [];
  public clnt_sal_list: any = [];
  public surgeryarray: any = [];

  public App_type: string;
  public Relationship;
  public homecarevalue: string;

  public clnt_url: string;
  public user_type: boolean = true;
  public new_user: boolean;
  public exist_user: boolean;
  public Client_type: string;

  public height_txt;
  public hmeasure: string;
  public weight_txt;
  public wmeasure: string;
  public temp_txt: string;
  public bmi_txt;
  public txt_bmr;
  public encheight;
  public encweight;
  public chief_comp;
  public pres_ill: string;
  public med_hist: string;
  public family_hist: string;
  public physical_exam: string;
  public client_reg_id: string;
  public clnt_sal: string;
  public age_url: string;
  public CurrentDatetime: string;
  public get_date: string;
  public tokenumber: string;
  public spl_id: string;
  public spl_array: any = [];
  public app_id: string;
  public bp_txt: string;
  public pulse_txt: string;
  public cvs_txt: string;
  public rs_txt: string;
  public kidney_txt: string;
  public spo_txt: string;
  public arthritis_type: string;
  public deformities: string;
  public treatment;
  public surgery_id: string;
  public surgery_type: string;
  public sug_procedure: string;
  public sug_investigation: string;
  public pft_txt: string;
  public ref_doc: string;
  public advice: string;
  public sendImge: string;

  public Confirm_btn: boolean;
  public hospital_id: string;
  public update_btn: boolean;
  public viewrx_flag: boolean;
  public follow_flag: boolean;
  public require_flag: boolean;
  public casesheetbtn: boolean;
  public case_sumbtn: boolean;
  public ortho_flag: boolean;
  public surgery_show: boolean = true;
  public pulm_flag: boolean;

  public hospital_txt: any = [];
  public filteredhospList: any = [];
  public hospital_filterlist: any = [];
  public hosp_name;
  public hosp_id: string;
  public refer_txt!: string | null;
  public Refered_name;
  public speclization_txt;
  public spl_type_array: any = [];
  public Refered_doctor: any = [];

  public userinfo: any;
  public user_id: any;

  public hospital_logo;
  public hospital_name;
  public hospital_details;

  public Is_Gynecology;
  public Is_specilization;
  public Gynecology_flag: boolean = false;
  public Pediatrics_flag: boolean = false;
  public Cardio_flag: boolean = false;
  public Nephrology_flag: boolean = false;
  public Others_flag: boolean = false;
  public Dental_flag: boolean = false;
  public Diabetes_flag: boolean = false;
  public spl_url;
  public save_data_flag: boolean = false;
  public patient_name;
  public currentDate;
  public currentTime;
  public currentYear;

  public spl;
  public observList = [];
  public covidSympList = [];
  public comorbList = [];
  public finalcovidsymp = [];
  public finalcomorbList = [];
  public clflag: string;
  public lreq_flag: boolean;
  public clientarray = [];
  public rel_list_data = [];
  public name_flag: boolean;
  public resp_txt: string;

  public covid_type: string;
  public covid_duration: string;
  public covid_flag: boolean;
  public observetable: boolean;
  public scan_01: string;
  public scan_02: string;
  public rtpcr_result: string;
  public BP_01: string = null;
  public BP_02: string = null;
  public other_hidden: boolean;
  public blood_preshidden: boolean;
  public blood_disabled: boolean;

  public rel_id: string;
  public h_location;
  public h_address1;
  public h_address2;
  public h_city;
  public h_country;
  public h_state;
  public h_zipcode;
  public h_city_id;
  public h_country_id;
  public h_state_id;
  public saveflag: boolean = false;
  public tempreq_flag: boolean = false;
  public bp_01req_flag: boolean = false;
  public bp_02req_flag: boolean = false;
  public spo2req_flag: boolean = false;
  public pulsereq_flag: boolean = false;
  public respratereq_flag: boolean = false;
  public ctscan1ereq_flag: boolean = false;
  public ctscan2ereq_flag: boolean = false;

  public symptom_data = [];
  public symptomlist = [];
  public symptoms_list = [];
  public selected_symptoms = [];

  public imagePath: any;
  public relation_id;
  public sub_rel_id;

  public sugeryimage;
  public clientDetails: any = [];
  public sugeryImges: boolean;
  public surgeryNewFlag: boolean = false;
  public report_img = [];
  public print_same_age: boolean;

  public existUserFlag: boolean = true
  //editor
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold'], // toggled buttons
      [{ 'color': [] }], // dropdown with defaults from theme
    ]
  };

  editorStyle = {
    height: '300px;',
  };

  public chiefComplaintsList = [];
  public chiefComplaints = [];
  public presentIllenessList = [];
  public medicalHistoryList = [];
  public familyHistoryList = [];
  public physicalExamList = [];
  public treatmentList = [];
  public chief_data = [];
  public pres_ill_data = [];
  public med_hist_data = [];
  public family_hist_data = [];
  public phy_exam_data = [];
  public treatment_data = [];
  public med_hist_text: string = "";
  public family_hist_text: string = "";
  public pres_ill_text: string = "";
  public physical_exam_text: string = "";
  public treatment_text: string = "";
  public symptoms_text: string = "";

  public chief_margin_left;
  public chief_comp1;
  public ynopt2: boolean;
  public ynopt1: boolean;
  dtOptions: DataTables.Settings = {};
  public doctorname;
  public doct_address1;
  public doct_address2;
  public doct_city;
  public doct_state;
  public zipcode;
  public doct_address;
  public bp;
  public hosp_img;
  public imagestring;
  public pdfObj;
  public print_view_flag = true;
  public hosp_footer;
  public bg_image;
  public print_template;
  public print_instruction;
  public current_date;
  public current_time;
  public hptl_pres_logo;
  public disease_name;
  public disease_flag;
  public licence_code;
  public qualification;
  public hospitalinfoarray: any = [];
  public hospitall_clinic_id;
  public noPres: boolean = false;
  public searchFlag: boolean;
  // print 
  public doct_name;
  public specializations_name;
  public doc_qualif;
  public dct_hospital;
  public personalinfo;
  public header_footer_flag: boolean;
  public med_pres_listdata: boolean;
  public clnt_bp;
  public clnt_pulse;
  public clnt_cvs;
  public clnt_rs;
  public clnt_kidney;
  public add_MP;
  public doct_country;
  public client_gender;
  public Appoint_Date;
  public doct_clientname;
  public height;
  public height_flag;
  public weight;
  
  public temparature;
 
  public temparature_flag:boolean;
  public ganericName;
  public genericDisplayFlag: boolean = false;
  public searchTypeFlag: boolean;
  public searchby: string;
  public searchtext: string;
  public patientList: any = [];
  public filteredArr: any = [];
  public patientName;
  public ageRead: boolean = false;
  private ageUrl: string;
  public appclass: boolean = false;
  public showpatient: boolean = true;
  public mixtype: any = [];
  public intakeOption: any = [];
  public mixing_txt: string;
  public mix_show: boolean = true;
  private nodob: boolean = false;
  public showpatflag: boolean = false;
  public no_med;
  public is_admin: boolean = false;
  // medical precription
  public language;
  public fill_type
  public dis_txt;
  public oberse_med;
  public medicineFlag: boolean = false;
  public mediceList = [];
  public symptoms_flag: boolean;
  public med_typetxt1 = [];
  public medtypetxt_type;
  public med_typetxt: string;
  public drug_typetxt: string;
  public medicine_nametxt: string;
  public drug_med_id: string;
  public Get_Intake_array = [];
  public med_detail_list = [];
  public drug_id_list = [];
  public intake_txt: string;
  public medtypetxt;
  public show_intake;
  private med_id;
  public short_form;
  public show;
  public new_med: boolean;
  public newmed_flag: boolean;
  public Medical_prescription_array = [];
  public Medicare_name: string;
  public pharma_id;
  //Get medicine name
  public Medicinenamelist;
  public Medicne_data = [];
  public Medicine_list = [];
  public Med_addbtn: boolean = false;
  public short_name;
  public listProducts = [];
  public day_txt: string;
  public mor;
  public aft;
  public eve;
  public ngt;
  public Medicine_table;
  public every_six;
  public dure_txt;
  public dure_write;
  public dure_flag;
  public afterfood_txt;
  public daydur_txt;
  public intake_id;
  public remark_txt;
  public rowid;
  public print_med;
  public medicineaddbtn: boolean;
  public print_medidiag: boolean;
  public Pharmanamelist = [];
  public Pharma_data = [];
  public prfer_pharmatxt = "";
  public prefer_add = "";
  public doct_location = "";
  public Pharma_list_arry = [];
  public client_age;
  public morning_dis: boolean;
  public afternoon_dis: boolean;
  public mor_id: boolean;
  public after_id: boolean;
  public eve_id: boolean;
  public eve_eventxt: boolean;
  public night_eventxt: boolean;
  public daydur_show: boolean;
  public even_dis: boolean;
  public ngt_dis: boolean;
  public mor_lable: boolean;
  public after_eventxt: boolean;
  public quan_lable: boolean;
  public morning_req: boolean;
  public afternoon_req: boolean;
  public evening_req: boolean;
  public night_req: boolean;
  public intake_show: boolean;
  public dure_show: boolean;
  public morn_eventxt: boolean;
  public order_now: boolean;
  public instr_flag: boolean;
  public eng_flag: boolean;
  public bp_flag: boolean;
  public treatflag: boolean;
  public ref_flag: boolean;
  public printf_flag: boolean;
  public get_txt = "";
  public flagviewrx: boolean;
  public recognition;
  public recognizing: boolean;
  public addmed: false;
  public frommedical: boolean;
  public instructions;
  public next_datetxt;
  public printpres;
  public next_txt;
  editbutton: boolean = false;
  newbutton: boolean = false;
  deletebutton: boolean = false;
  printbutton: boolean = false;
  viewbutton: boolean = false;

  user_ids: any;
  doctorList: any[];
  doctorid: any;
  public cns_txt;
  public abd_txt;
  public pr_txt;
  modulelist: any;
  currentLimit = 1;
  typeflag: string;
  patient_id: boolean;
  private estimate_required:boolean;

  constructor(public masterData: MasterHelperService, public dialog: MatDialog, public http: Http, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService, public masterCSdata: MasterCSHelperService, public sanitizer: DomSanitizer, public translate: TranslateService,) {
    Doc_Helper.setDiagAppflow("medical");
    Helper_Class.setInpatientFlow(undefined);
    Nurse_Helper.setINPatient(undefined);
    Helper_Class.setAsg(undefined);
    Helper_nephro_casesheet.nephro_get_ret = undefined;

    this.clnt_url = ipaddress.getIp + "usercontroller/cdetnoapph/";
    this.age_url = ipaddress.getIp + "adm/ddif";
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";
    this.ageUrl = ipaddress.getIp + "adm/ddif";

    this.homecarevalue = "0";
    this.App_type = "New";
    this.user_type = true;
    this.Client_type = "Existing";
    this.exist_user = false;
    this.searchFlag = false;
    this.new_user = true;
    this.update_btn = true;
    this.viewrx_flag = true;
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
    this.follow_flag = false;
    this.require_flag = true;
    this.casesheetbtn = true;
    this.case_sumbtn = true;
    this.ortho_flag = true;
    this.pulm_flag = true;
    this.ageRead = false;
    this.covid_type = "no";
    this.scan_02 = "25";
    this.rtpcr_result = "Positive";
    this.covid_flag = false;
    this.observetable = true;
    this.name_flag = true;
    this.lreq_flag = false;
    this.temp_txt = "";
    this.BP_01 = "";
    this.BP_02 = "";
    this.spo_txt = "";
    this.pulse_txt = "";
    this.resp_txt = "";
    this.scan_01 = "";
    this.Confirm_btn = false;
    this.update_btn = true;
    this.viewrx_flag = true;
    this.dob_flag = true;
    this.Client_type = "Existing";

    Helper_Class.setPageFlow("noapp");
    Doc_Helper.setAppFlow(null);
    Doc_Helper.setAppFlow('case_sheet');
    Doc_Helper.setClient_Info(null);
    Doc_Helper.setSugeryDetails(undefined);

    // medical precription
    this.translate.setDefaultLang('english');
    this.translate.use('english');

    this.mor = "1";
    this.aft = "1";
    this.ngt = "1";
    this.instr_flag = true;

    this.dure_flag = false;
    this.eng_flag = false;
    this.ortho_flag = false;
    this.bp_flag = false;
    this.treatflag = false;
    this.ref_flag = false;
    this.printf_flag = true;
    this.newmed_flag = true;
    this.dure_show = false;
    this.get_txt = "";

    this.flagviewrx = true;

    this.recognition = null;
    this.recognizing = false;

    this.translate.setDefaultLang('english');
    this.translate.use('english');
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";
    this.addmed = false;
    this.frommedical = false;
    this.listProducts = [];
    this.day_txt = "";
    this.dis_txt = "";
    this.listProducts = [];
    this.Medicine_table = true;
    this.quan_lable = true;
    this.mor_lable = false;
    this.after_eventxt = false;
   
    this.eve_eventxt = false;
    this.daydur_show = false;
    this.night_eventxt = false;
    this.mor_id = false;
    this.after_id = false;
    this.eve_id = true;
    this.Med_addbtn = true;
    this.language = "English";
    this.daydur_txt = "day(s)"
    this.afterfood_txt = "After food";
    this.dure_txt = "30";

  }

  async ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    this.changeProviderType();
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      order: [[1, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: "Search by medicine name"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>'
    };
    
    this.appGetdate()
    this.searchType('patientid');
    this.appclass = true;  
    this.order_now = true;
    this.user_id = this.userinfo.user_id;
    this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
    this.doct_name = this.doctorname;
    
    // if(this.userinfo.hospitals[0].language != undefined){
    //   this.language = this.userinfo.hospitals[0].language;
    // }
    
    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    this.clnt_location_id = this.hospital_details.location;
    this.clnt_city_id = this.hospital_details.city;
    this.clnt_state_id = this.hospital_details.state;
    this.clnt_country_id = this.hospital_details.country;
    this.dct_hospital = this.hospital_details.hptl_name;
    this.doct_address1 = this.hospital_details.address1;
    this.doct_address2 = this.doct_address2 != undefined ? this.hospital_details.address2 : "";
    this.doct_location = this.hospital_details.location_desc;
    this.doct_city = this.hospital_details.city_desc;
    this.doct_state = this.hospital_details.state_desc;
    this.zipcode = this.hospital_details.zipcode;
    this.doct_country = this.hospital_details.country_id;
    this.estimate_required = this.userinfo.req_estimate,
    this.change_type('client', 1, 'no');
    this.change_type('app', 1, 'yes');

    var splvalue = Helper_Class.getSpecializations();
   
    if(Helper_Class.getSpecializations() != undefined){
      for (var i = 0; i < splvalue.length; i++) {
        this.spl_array.push({
          spl_id: splvalue[i].spl_id,
          spl_name: splvalue[i].spl_name,
        })
      }
      this.spl_id = this.spl_array[0].spl_id;
      this.spl = this.spl_array[0].spl_name;
    }else{
      this.getSpecializations();
    }
    
    this.ortho_flag = this.spl_id == "15" ? false : true;
    this.surgery_show = this.spl_id == "29" && this.follow_flag != false ? false : true;
    this.pulm_flag = this.spl_id == "17" ? false : true;
    this.treatement_type = Doc_Helper.getMedicare_name();

    if (Master_Helper.getMasterSpecialization() != undefined)
      this.spl_type_array = Master_Helper.getMasterSpecialization().specialization;

    if (Master_Helper.getMasterGeneralSurgeries() != undefined)
      this.surgeryarray = Master_Helper.getMasterGeneralSurgeries().med_procedures;

    if (Helper_Class.getPatientList() == undefined) {
      this.getPatientList();
    } else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
    if (this.language != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
    this.Get_Relation_Data();
    this.getCurrentToken();
    this.getCurrentDate();
    this.getHospitalDetails();
    this.getSalutations();
    this.getCountries("0");
    this.getCovidSymptoms();
    this.getComorbidities();
    this.licence_code = this.userinfo.licence_code;
    this.qualification = Helper_Class.getQualification();
    if (this.dur_flag == true && this.notes_flag == true) {
      this.medstyle = true;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.medstyle = false;
      this.medstyle1 = true;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = true;
      this.medstyle3 = false;

    } else {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = true;
    }
    if (this.dur_flag == true && this.notes_flag == true) {
      this.med_style = "width: 275px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 150px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.med_style = "width: 250px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 50px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width:50px;border: 1px solid black;border-collapse: collapse;";
    }
    if (Doc_Helper.getHospital_logo() != undefined) {
      this.hosp_img = Doc_Helper.getHospital_logo();
      this.getBase64Image(this.hosp_img);
    }
    if (Doc_Helper.getHospFooter() != undefined) {
      this.hosp_footer = Doc_Helper.getHospFooter();
      this.printf_flag = false;
    } else {
      this.printf_flag = true;
    }
    if (Doc_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Doc_Helper.getHospital_bg_image();
    }
    if (Doc_Helper.getHospital_print_template() != undefined && Doc_Helper.getHospital_print_template() != "undefined") {
      this.print_template = Doc_Helper.getHospital_print_template();
    } else {
      this.print_template = "noheader";
    }

    if (Doc_Helper.getHospital_pres_logo() != undefined && Doc_Helper.getHospital_pres_logo() != "undefined") {
      this.hptl_pres_logo = Doc_Helper.getHospital_pres_logo();
    }
    if (this.spl_id == "29") {
      this.getCasesheetFields(this.spl_id);
    }
    this.hospitalinfoarray = Helper_Class.getHospital();
    this.pharma_id = this.hospitalinfoarray[0].pharma_id;
    this.prfer_pharmatxt = this.hospitalinfoarray[0].pharma_name;
    if (this.prfer_pharmatxt != undefined) {
      this.hospitall_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      this.Get_pharmacy_address();
      this.getmedicineonit();
    }
    if (masterCSData_Helper.getMasterIntakeSession != undefined) {
      await this.masterCSdata.getIntakeSession();
      this.Get_Intake_session();
    } else {
      this.Get_Intake_session();
    }
    var getdata = this.userinfo.medicares;
    if (getdata != undefined && getdata != null) {
      for (var i = 0; i < getdata.length; i++) {
        this.Medicare_name = getdata[0].medicare_name;
      }
    }
    var Moduleidlist;
    if (Doc_Helper.getModuleList() != null) {
      Moduleidlist = Doc_Helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
         this.genericDisplayFlag = true;
         
        }} 
    }
    
    if(Helper_Class.getmodulelist() != undefined){
      this.modulelist = Helper_Class.getmodulelist();
      if (this.modulelist != null) {
        for (var i = 0; i < this.modulelist.length; i++) {
  
          if (this.modulelist[i].module_id == "5") {
            if (this.modulelist.edit == "1") {
              this.editbutton = true;
            }
            if (this.modulelist[i].create == "1") {
              this.newbutton = true;
            }
            if (this.modulelist[i].delete == "1") {
              this.deletebutton = true;
            }
            if (this.modulelist[i].print == "1") {
              this.printbutton = true;
            }
            if (this.modulelist[i].view == "1") {
              this.viewbutton = true;
            }
          }
         
        }
      }
    }else{
      if(this.userinfo.user_type == "Admin"){
        this.newbutton =true;
      }
      
    }
   
    this.languageChange();  
  }

  changeProviderType() {
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hospital_id = Helper_Class.getInfo().hptl_clinic_id;
      this.user_id = Helper_Class.getInfo().user_id;
      this.user_type = Helper_Class.getInfo().user_type;
      this.is_admin = true;
      this.getDoctors();
    }
  }
 
  changedoc() {
    this.user_id = this.doctorid;
    this.userinfo.user_id = this.doctorid;
   
    this.getPatientList()
    this.searchType('name');
  }


  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.user_id,
      type: this.user_type,
      hptl_clinic_id: this.hospital_id,
    };
    console.log(
      'check---' +
      JSON.stringify({
        country: 'IN',
        doc_reg_id: this.user_id,
        type: this.user_type,
        hptl_clinic_id: this.hospital_id,
      })
    );
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/docbyhosp', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            this.doctorid = this.userinfo.user_id;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

              }
            }
          }
        },
        (error) => {

        }
      );
  }

  noMedSelected() {
    if (this.no_med == true) {
      this.saveflag = true;
      this.print_view_flag = false;
      this.Med_addbtn = false;

    } else {
      this.saveflag = false;
      this.print_view_flag = true;
      this.Med_addbtn = true;
    }
  }

  getPatientList() {//get patient list
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
      {
        hptl_clinic_id: this.hospital_id,
        country: ipaddress.country_code,
        //imei: this.ipaddress,
        limit: 200,
        filterby: "Patient Id",
        type: "doctor",
        start_no: this.currentLimit,
        prov_id: this.userinfo.user_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          // this.patientList = [];
          // this.filteredArr = [];
          if (obj.clients != null) {
            console.log("datat check"+JSON.stringify(obj.clients))
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              console.log("datat check"+encrypt_decript.Decript(obj.clients[i].first_name))
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });
            }
            Helper_Class.setPatientList(this.patientList);
          }
        },
        error => { });
  } 
  searchType(data){
    this.searchby=data;
    if(data =="name"){
      this.searchTypeFlag = true
      this.searchtext="search by name"
    } else {
      this.searchTypeFlag = false;
      this.searchtext="search by mobile"
    }

    if(data =="name"){
      this.typeflag = "name";
      this.patientName="";
      this.searchtext="search by name"
      this.getPatientListByName(this.patientName)

    } else if(data =="patientid"){
      this.typeflag = "patientid";
      this.patientName="";
      this.searchtext="search by id"
      this.patient_id = true;
      this.getPatientListByName(this.patientName)

    }  else {
      this.typeflag = "mobile";
      this.patientName="";
      this.searchtext="search by mobile"
      this.getPatientListByName(this.patientName)
    }
   
  }
 
 
  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }
  getPatientListByName(name) {//get patient list
  
   
    console.log("name --"+name)
    var send_data;
    if(name != ""){    
    if (name.length > 2 && this.searchby != "patientid") {
       send_data = {
        hptl_clinic_id: this.hospital_id,
        country: ipaddress.country_code,
        type: this.searchby,
        name: encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),       
        
        prov_id: this.user_id,
        location: "front-desk",
      }
      console.log("send_data --"+JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            
           
            var obj = response.json();
            console.log("verify this " + JSON.stringify(obj))
            this.patientList = [];
            this.filteredArr = [];
           
            
            if (obj.clients.length != 0) {
              for (var i = 0; i < obj.clients.length; i++) {
                var name;
                if (obj.clients[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                this.patientList.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });

                this.filteredArr.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
              }
              //Helper_Class.setPatientList(this.patientList);
         
            } 
          },
          error => { });
  
    }else if(name.length > 8 && this.searchby == "patientid"){
      send_data = {
        hptl_clinic_id: this.hospital_id,
        country: ipaddress.country_code,
        type: this.searchby,
        name: encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),
        prov_id: this.user_id,
        location: "front-desk",
      }
       console.log("send_data --"+JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("verify this " + JSON.stringify(obj))
            this.patientList = [];
            this.filteredArr = [];
            if ( obj.clients.length != 0) {
              for (var i = 0; i < obj.clients.length; i++) {
                var name;
                if (obj.clients[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
              
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                this.patientList.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });

                this.filteredArr.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
              }
              //Helper_Class.setPatientList(this.patientList);
            } 
          },
          error => { });
   
    }
    
    else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
  }else{
    this.getPatientList()
  }
  }

  retrieveClient(person) {
    console.log("send data --"+JSON.stringify(person))
    this.clnt_address2 = "";
    this.clnt_address1 = "";
    this.clnt_zipcode = "";
    var get_data;
    if (person.sub_rel_id != undefined) {
      get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        country: ipaddress.country_code
      };
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log(" gr clnt ------"+JSON.stringify(obj))

          if (obj.client_id != null) {
            this.saveFlag = true;
            if (obj.salutation != null) {
              for (var i = 0; i < this.clnt_sal_list.length; i++) {
                if (this.clnt_sal_list[i].sal_desc == obj.salutation) {
                  this.clnt_sal = this.clnt_sal_list[i].sal_id;
                }
              }
            }
            this.client_reg_id = person.client_reg_id;
            this.relation_id = person.relation_id;
            this.sub_rel_id = person.sub_rel_id;
            this.clnt_fname = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clnt_mname = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clnt_lname = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clnt_gender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clnt_cont_number = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            //this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            //this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            console.log(" gr clnt ------"+JSON.stringify(this.clnt_fname))

            if (obj.age != undefined) {
              this.clnt_age = obj.age;

            } else {
              this.clnt_age = "";
            }

            if (obj.dob != 'null') {
              this.clnt_dob = obj.dob;
              this.selectDob(this.clnt_dob)

            } else {
              this.clnt_dob = null;
              //this.dobDisabledFlag = true;
              this.ageRead = false;
            }
            if (this.App_type != "New") {
              this.clnt_symptom = obj.symptom;
            }
            if (obj.barcode != undefined) {
              this.barcode = ipaddress.Ip_with_img_address+obj.barcode;
              this.barcodeflag=true;
            }

            this.clnt_fg_name = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clnt_address1 = encrypt_decript.Decript(obj.address1);
            }

            if (obj.address2 != null && obj.address2 != "") {
              this.clnt_address2 = encrypt_decript.Decript(obj.address2);
            }
            this.clnt_location = this.checkData(obj.location_desc);
            this.clnt_location_id = this.checkData(obj.location);
            this.clnt_country = this.checkData(obj.country_desc);
            this.clnt_country_id = this.checkData(obj.country);
            this.clnt_state = this.checkData(obj.state_desc);
            this.clnt_state_id = this.checkData(obj.state);
            this.clnt_city = this.checkData(obj.city_desc);
            if (obj.address2 != null && obj.address2 != "") {
              this.clnt_zipcode = encrypt_decript.Decript(obj.zipcode);
            }
          }
          // this.retrvDisabledFlag = true;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  selectDob(e) {
    if (this.clnt_dob != undefined) {
      this.ageRead = true;
      this.clnt_dob = e;

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.ageUrl, {
        dobdate: Date_Formate(this.clnt_dob),
        curdate: Date_Formate(this.currentDate),
        spl: this.spl_id,
      }, { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          if (obj != undefined) {
            this.clnt_age = obj.value;
          }
        });
    } else {
      this.ageRead = false;
    }
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getCurrentToken() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/dtoken',
      JSON.stringify({
        doc_reg_id: this.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != undefined) {
            this.tokenumber = obj.tokem;
          }
        });
  }

  async Get_Relation_Data() {
    if (Master_Helper.getMasterRelationData() == undefined) {
      await this.masterData.getRelationData();
    }
    var obj = Master_Helper.getMasterRelationData();
    if (obj != undefined) {
      this.rel_list_data = obj.relation;
      this.Relationship = this.rel_list_data[0].relationship_name;
      this.rel_id = this.rel_list_data[0].relationship_id;
    }
  }

  getComorbidities() {
    var obj = Master_Helper.getMasterComorbidities();
    if (obj != undefined) {
      for (var i = 0; i < obj.comorbidities.length; i++) {
        this.comorbList.push({
          comorb_id: obj.comorbidities[i].comorbidity_id,
          comorb_desc: obj.comorbidities[i].comorbidity,
        });
      }
    }
  }

  addObservations() {
   
    if (this.temp_txt.length != 0 || this.BP_01.length != 0 || this.BP_02.length != 0 || this.spo_txt.length != 0
      || this.resp_txt.length != 0 )
      // /  && this.scan_01.length != 0)
     {
      this.BP_01 = this.BP_01 != null ? this.BP_01 : "00";
      this.BP_02 = this.BP_02 != null ? this.BP_02 : "00";
      this.bp = this.BP_01 + "/" + this.BP_02;
      this.observList.push({
        temp: this.temp_txt,
        bp: this.BP_01 + "/" + this.BP_02,
        sop2: this.spo_txt,
        pulse: this.pulse_txt,
        resprate: this.resp_txt,
        ct_scan: this.scan_01 + "/" + this.scan_02,
        rtpcr: this.rtpcr_result,
        status: "1",
      });
      this.observetable = false

    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  covidtypeSelect() {
    if (this.covid_type == "yes") {
      this.covid_flag = true;
      this.tempreq_flag = true;
      this.bp_01req_flag = true;
      this.bp_02req_flag = true;
      this.spo2req_flag = true;
      this.pulsereq_flag = true;
      this.respratereq_flag = true;
      this.ctscan1ereq_flag = true;
    } else {
      this.observList = [];
      this.resp_txt = "";
      this.scan_01 = "";
      this.covid_duration = "";
      this.tempreq_flag = false;
      this.bp_01req_flag = false;
      this.bp_02req_flag = false;
      this.spo2req_flag = false;
      this.pulsereq_flag = false;
      this.respratereq_flag = false;
      this.ctscan1ereq_flag = false;

      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }

      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }
      this.covid_flag = false;
    }
  }

  Relationship_change(rel_data) {
    for (var i = 0; i < this.rel_list_data.length; i++) {
      if (this.rel_list_data[i].relationship_name == rel_data) {
        this.rel_id = this.rel_list_data[i].relationship_id;
      }
    }
  }

  select_client(data) {
    for (var i = 0; i < this.clientarray.length; i++) {
      if (this.clientarray[i].name == data) {
        var mname;
        if (this.clientarray[i].middle_name != undefined) {
          mname = this.clientarray[i].middle_name;
        }
        var senddata = {
          mobile: this.clientarray[i].mobile,
          fname: this.clientarray[i].first_name,
          mname: mname,
          lname: this.clientarray[i].last_name,
          country: "IN",
          hptl_id: this.hospital_id,
          type: this.Client_type,
          is_doc: 1
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.clnt_url, JSON.stringify(senddata), { headers: headers }).subscribe(
          data => {
            console.log("pat details--"+JSON.stringify(data))
            if (data.json().client_reg_id != undefined) {
              this.setClientDetails(data);
            } else {
              this.toastr.error(Message_data.unableToFetchData);
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        )
      }
    }
    this.clientarray = [];
  }

  setClientDetails(data) {
    this.viewrx_flag = false;
    this.client_reg_id = data.json().client_reg_id;
    Doc_Helper.setClient_Info(data.json());
    this.clientDetails = Doc_Helper.getClient_Info();
    Doc_Helper.setClient_id(this.client_reg_id);
    if (this.clientDetails.relation_id != undefined) {
      Doc_Helper.setRel_id("1");
      this.relation_id = this.clientDetails.relation_id;
    } else {
      this.relation_id = "1";
    }
    this.sub_rel_id = this.clientDetails.sub_rel_id != undefined ? this.clientDetails.sub_rel_id : "";
    Doc_Helper.setClient_gender(this.clientDetails.gender);
    Doc_Helper.setRefer_by(this.clientDetails.ref_by);
    if(data.barcode != undefined){
      this.barcode =data.barcode
    }
    this.surgery_id = this.clientDetails.surgery_id;
    this.surgery_type = this.clientDetails.surgery_type;
    this.sug_investigation = this.clientDetails.surgery_inv;
    this.sug_procedure = this.clientDetails.surgery_proc;
    this.sugeryimage = ipaddress.Ip_with_img_address + this.clientDetails.surgery_image;
    this.sendImge = this.clientDetails.surgery_image;
    if (this.clientDetails.surgery_image != "" && this.clientDetails.surgery_image != undefined && this.clientDetails.surgery_image != null) {
      this.report_img = [];
      this.report_img.push({
        image: ipaddress.Ip_with_img_address.toString() + this.clientDetails.surgery_image,
      });
      this.sugeryImges = true;
    } else {
      this.sugeryImges = false;
    }

    if (this.clientDetails.dob != undefined && this.clientDetails.dob != "" && this.clientDetails.dob != null) {
      var ddata = this.clientDetails.dob.split("-");
      this.clnt_dob = this.clientDetails.dob;
      //if (this.spl_id == "10") {
      this.Date_click(this.clnt_dob);
      //}

    } else {
      this.disable_dob = true;
    }

    if (this.clientDetails.age != 0) {
      this.clnt_age = this.clientDetails.age;
    } else {
      this.clnt_age = "<1";
    }
    Doc_Helper.setClient_age(this.clnt_age);

    if (this.spl_id == "15") {
      var cvs: any = this.clientDetails.cvs;
      var rs: any = this.clientDetails.rs;
      var kidney: any = this.clientDetails.kidney;
      var deformities: any = this.clientDetails.deformities;
      var arthritis_type: any = this.clientDetails.arthritis_type;
    } else {
      cvs = null;
      rs = null;
      kidney = null;
      deformities = null;
      arthritis_type = null;
    }

    this.clnt_sal = this.clientDetails.salutation;
    this.clnt_fname = encrypt_decript.Decript(this.clientDetails.first_name);
    this.clnt_lname = encrypt_decript.Decript(this.clientDetails.last_name);

    if (this.clientDetails.middle_name != null && this.clientDetails.last_name != null) {
      this.clnt_mname = encrypt_decript.Decript(this.clientDetails.middle_name);
      this.clnt_lname = encrypt_decript.Decript(this.clientDetails.last_name);
      Doc_Helper.setClient_name(this.clnt_fname + " " + this.clnt_mname + " " + encrypt_decript.Decript(this.clientDetails.last_name));
    } else if (this.clientDetails.last_name != null) {
      Doc_Helper.setClient_name(this.clnt_fname + " " + encrypt_decript.Decript(this.clientDetails.last_name));
      this.clnt_lname = encrypt_decript.Decript(this.clientDetails.last_name);
    }
    else {
      Doc_Helper.setClient_name(this.clnt_fname);
    }

    if (this.clientDetails.gender != null && encrypt_decript.Decript(this.clientDetails.gender) != "") {
      this.clnt_gender = encrypt_decript.Decript(this.clientDetails.gender);
    }

    this.clnt_blood = this.clientDetails.blood_desc;
    this.clnt_blood_id = this.clientDetails.blood_group;

    if (this.clientDetails.family_guardian != null) {
      this.clnt_fg_name = encrypt_decript.Decript(this.clientDetails.family_guardian);
    }
    if (this.clientDetails.family_income != null) {
      this.clnt_income = encrypt_decript.Decript(this.clientDetails.family_income);
    }
    if (this.clientDetails.Occupation != null && this.clientDetails.Occupation != "null" && this.clientDetails.Occupation != "") {
      this.clnt_occupation = encrypt_decript.Decript(this.clientDetails.Occupation);
    }

    if (this.clientDetails.address1 != null && encrypt_decript.Decript(this.clientDetails.address1) != "") {
      this.clnt_address1 = encrypt_decript.Decript(this.clientDetails.address1);
    }

    if (this.clientDetails.address2 != null) {
      this.clnt_address2 = encrypt_decript.Decript(this.clientDetails.address2);
    }

    this.clnt_location = this.clientDetails.location_desc;
    this.clnt_city = this.clientDetails.city_desc;
    this.clnt_state = this.clientDetails.state_desc;
    this.clnt_country = this.clientDetails.country_desc;

    this.clnt_location_id = this.clientDetails.location;
    this.clnt_city_id = this.clientDetails.city;
    this.clnt_state_id = this.clientDetails.state;
    this.clnt_country_id = this.clientDetails.country;

    this.clnt_zipcode = encrypt_decript.Decript(this.clientDetails.zipcode);
    this.clnt_cont_number = encrypt_decript.Decript(this.clientDetails.mobile);

    this.height_txt = this.clientDetails.height;
    this.hmeasure = this.clientDetails.hmeasure;
    this.weight_txt = this.clientDetails.weight;
    this.wmeasure = this.clientDetails.wmeasure;
    this.bmi_txt = this.clientDetails.bmi;
    if (this.clientDetails.complaints.trim() != "" && this.clientDetails.complaints != null && this.clientDetails.complaints != undefined) {
      this.chief_comp = this.clientDetails.complaints.replace(/\\/g, "");
      var chief = this.removeTags(this.chief_comp);
      if (chief.indexOf(',') > -1) {
        var chiefdata = chief.trim().split(",");
        for (var i = 0; i < chiefdata.length; i++) {
          this.newsymptoms.push(chiefdata[i]);
        }
      } else {
        this.newsymptoms.push(chief);
      }
    }
    if (this.clientDetails.medical_history != "" && this.clientDetails.medical_history != null && this.clientDetails.medical_history != undefined) {
      this.med_hist = this.clientDetails.medical_history;
      var med = this.removeTags(this.med_hist);
      if (med.indexOf(',') > -1) {
        var meddata = med.trim().split(",");
        for (var i = 0; i < meddata.length; i++) {
          this.newmedhist.push(meddata[i]);
        }
      } else {
        this.newmedhist.push(med);
      }
    }
    if (this.clientDetails.family_history != "" && this.clientDetails.family_history != null && this.clientDetails.family_history != undefined) {
      this.family_hist = this.clientDetails.family_history;
      var family = this.removeTags(this.family_hist);
      if (family.indexOf(',') > -1) {
        var familydata = family.trim().split(",");
        for (var i = 0; i < familydata.length; i++) {
          this.newfamhist.push(familydata[i]);
        }
      } else {
        this.newfamhist.push(family);
      }
    }
    if (this.clientDetails.present_illness != "" && this.clientDetails.present_illness != null && this.clientDetails.present_illness != undefined) {
      this.pres_ill = this.clientDetails.present_illness;
      var presill = this.removeTags(this.pres_ill);
      if (presill.indexOf(',') > -1) {
        var presilldata = presill.trim().split(",");
        for (var i = 0; i < presilldata.length; i++) {
          this.newpresill.push(presilldata[i]);
        }
      } else {
        this.newpresill.push(presill);
      }
    }
    if (this.clientDetails.physical_exam != "" && this.clientDetails.physical_exam != null && this.clientDetails.physical_exam != undefined) {
      this.physical_exam = this.clientDetails.physical_exam;
      var phyexam = this.removeTags(this.physical_exam);
      if (phyexam.indexOf(',') > -1) {
        var phyexamdata = phyexam.trim().split(",");
        for (var i = 0; i < phyexamdata.length; i++) {
          this.newphyexamdet.push(phyexamdata[i]);
        }
      } else {
        this.newphyexamdet.push(phyexam);
      }
    }
    if (this.clientDetails.treatment != "" && this.clientDetails.treatment != null && this.clientDetails.treatment != undefined) {
      this.treatment = this.clientDetails.treatment;
      var trt = this.removeTags(this.treatment);
      if (trt.indexOf(',') > -1) {
        var trtdata = trt.trim().split(",");
        for (var i = 0; i < trtdata.length; i++) {
          this.newtreatdet.push(trtdata[i]);
        }
      } else {
        this.newtreatdet.push(trt);
      }
    }
    this.app_id = this.clientDetails.app_id;
    this.clnt_blood = this.clientDetails.blood_group;
    this.ref_doc = this.clientDetails.ref_by;
    this.covid_type = this.clientDetails.covid_patient;
    this.covid_duration = this.clientDetails.covid_dur;
    if (this.clientDetails.refer_txt != null && this.clientDetails.refer_txt != "null" && this.clientDetails.refer_txt != "") {
      this.refer_txt = this.clientDetails.refer_txt;
    }
    this.hosp_name = this.clientDetails.hosp_name;
    this.speclization_txt = this.clientDetails.spl_txt;

    if (this.covid_type == "yes") {
      this.covid_flag = true;
    } else {
      this.covid_flag = false;
    }

    this.finalcovidsymp = [];
    this.finalcomorbList = [];

    if (this.clientDetails.covid_symp != undefined) {
      this.finalcovidsymp = this.clientDetails.covid_symp;
    }
    if (this.clientDetails.covid_comorb != undefined) {
      this.finalcomorbList = this.clientDetails.covid_comorb;
    }
    if (this.clientDetails.covid_observ != undefined) {
      this.observList = this.clientDetails.covid_observ;
      this.observetable = false;
    }

    if (this.finalcovidsymp != undefined && this.finalcovidsymp.length != 0) {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          for (var j = 0; j < this.finalcovidsymp.length; j++) {
            if (this.covidSympList[i].symp_id == this.finalcovidsymp[j]) {
              this.covidSympList[i].checked = true;
            }
          }
        }
      }
    }

    if (this.finalcomorbList != undefined && this.finalcomorbList.length != 0) {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          for (var j = 0; j < this.finalcomorbList.length; j++) {
            if (this.comorbList[i].comorb_id == this.finalcomorbList[j]) {
              this.comorbList[i].checked = true;
            }
          }
        }
      }
    }

    if (this.clientDetails.family_guardian != undefined) {
      this.clnt_fg_name = this.clientDetails.family_guardian;

    } else {
      this.clnt_fg_name = this.clientDetails.care_taker;
    }

    if (this.follow_flag == true) {
      this.physical_exam = this.clientDetails.physical_exam;
      this.temp_txt = this.clientDetails.temparature;
      this.clnt_symptom = encrypt_decript.Decript(this.clientDetails.symptom);
      this.pres_ill = this.clientDetails.present_illness;
      this.refer_txt = this.clientDetails.refer_txt;
      this.hosp_name = this.clientDetails.hosp_name;
      this.speclization_txt = this.clientDetails.spl_txt;
      this.bp_txt = this.clientDetails.blood_pressure;
      this.advice = this.clientDetails.advice;
      this.pulse_txt = this.clientDetails.pulse;
      this.cvs_txt = this.clientDetails.cvs;
      this.rs_txt = this.clientDetails.rs;
      this.kidney_txt = this.clientDetails.kidney;
      this.spo_txt = this.clientDetails.spo2;
      this.arthritis_type = this.clientDetails.arthritis_type;
      this.deformities = this.clientDetails.deformities;
      this.treatment = this.clientDetails.treatment;
      this.surgery_type = this.clientDetails.surgery_type;
      this.pft_txt = this.clientDetails.pft;
      this.surgery_id = this.clientDetails.surgery_id;
      this.clnt_symptom = this.clientDetails.symptom;
      if (this.clientDetails.blood_pressure != undefined) {
        var bpre = this.clientDetails.blood_pressure.split('/');
        this.BP_01 = bpre[0];
        this.BP_02 = bpre[1];
        this.bp = this.BP_01 + "/" + this.BP_02;
      }
    }

    if (this.surgery_id != undefined) {
      this.surgery_change(this.surgery_id);
    }

    if (this.app_id != undefined) {
      Doc_Helper.setApp_id(this.app_id);
    }


    if (this.BP_01 != null && this.BP_01 != undefined && this.BP_01 != "") {

      this.bp = this.BP_01 + "/" + this.BP_02;
    }

    this.clnt_location = this.clientDetails.location_desc;
    this.clnt_city = this.clientDetails.city_desc;
    this.clnt_state = this.clientDetails.state_desc;
    this.clnt_country = this.clientDetails.country_desc;

    var walkin_info: any = [];
    walkin_info = {
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Client_id: this.client_reg_id,
      app_id: this.app_id,
      Age_data: this.clnt_age,
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      medicare_name: this.treatement_type,
      AppFlow: "Walkin",
      location: this.clnt_location,
      city: this.clnt_city,
      state: this.clnt_state,
      country: this.clnt_country,
      zipcode: this.clnt_zipcode,
      rel_id: this.relation_id,
      sub_id: this.sub_rel_id,
      bp: this.bp,
      pulse: this.pulse_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bmr: this.txt_bmr,
      temparature: this.temp_txt,
      symptoms: this.clnt_symptom,
      treatment: this.treatment,
      refer_by: this.refer_txt,
      cvs: cvs,
      rs: rs,
      kidney: kidney,
      deformities: deformities,
      arthritis_type: arthritis_type,

    };

    Doc_Helper.setWalkin(walkin_info);
  }

  calculateAge(dobdata) {
    if (dobdata != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.age_url, JSON.stringify({
        dobdate: Date_Formate(dobdata),
        curdate: Date_Formate(this.CurrentDatetime),
        spl: this.spl_id,
      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj != undefined) {
            this.clnt_age = obj.value;
          }
        })
    }
  }

  focus_fname() {
    this.cfname;
  }

  focus_mname() {
    this.cmname;
    this.clflag = "2";
  }

  focus_lname() {
    this.lreq_flag = true;
    this.clname;
    this.clflag = "3";
  }

  fucusMobile() {
    this.cmobile;
    this.clflag = "4";
    this.Acitvate_savebtn();
  }

  getHospitalDetails() {
    this.h_location = Master_Helper.getMasterHospital().location;
    this.h_address1 = Master_Helper.getMasterHospital().address1;
    this.h_address2 = Master_Helper.getMasterHospital().address2;
    this.h_city = Master_Helper.getMasterHospital().city;
    this.h_state = Master_Helper.getMasterHospital().state;
    this.h_country = Master_Helper.getMasterHospital().country;
    this.h_zipcode = Master_Helper.getMasterHospital().zipcode;
    this.h_city_id = Master_Helper.getMasterHospital().city_id;
    this.h_state_id = Master_Helper.getMasterHospital().state_id;
    this.h_country_id = Master_Helper.getMasterHospital().country_id;
  }

  hospital_filter(e) {
    var tmp_arr = [];
    this.hosp_name = e.target.value.toString();
    if (this.hosp_name !== "" && this.hosp_name.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.hospitals != null) {
              this.hospital_filterlist = obj.hospitals;

              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }

              var hopsitalName = this.hosp_name;
              this.filteredhospList = this.hospital_txt.filter(function (el) {
                return el.toLowerCase().indexOf(hopsitalName.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { }
        )
    } else {
      this.filteredhospList = [];
    }
  }

  select_hospital(data) {
    this.hosp_name = data;
    for (var i = 0; i < this.hospital_filterlist.length; i++) {
      if (this.hospital_filterlist[i].hosp_name == data) {
        this.hosp_id = this.hospital_filterlist[i].hosp_id;
      }
    }
    this.filteredhospList = [];
    this.refer_txt = null;
  }

  // Refer doctor list
  Get_referal(e, value) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    var dname;

    dname = value == "to" ? this.refer_txt : this.ref_doc;

    if (dname != "" && dname.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          hospital_id: this.hosp_id,
          spl_name: this.speclization_txt,
          doc_name: dname
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.Refered_name = obj.doctors;
            if (this.Refered_name != undefined) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if (this.Refered_name[i].middle_name != undefined) {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name;
                } else {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            this.Refered_doctor = this.Refered_doctor.filter(function (el) {
              return el.toLowerCase().indexOf(dname.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.Refered_doctor = [];
    }
  }

  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  SelectedRefDoctor(item) {
    this.ref_doc = item;
    this.Refered_doctor = [];
  }

  async getSalutations() {
    if (Master_Helper.getMasterSalutation() == undefined) {
      await this.masterData.getSalutations();
    }
    var salutation = Master_Helper.getMasterSalutation();
    if (salutation != undefined) {
      for (var i = 0; i < salutation.salutations.length; i++) {
        this.clnt_sal_list.push({
          sal_id: salutation.salutations[i].salutation_id,
          sal_desc: salutation.salutations[i].salutation_desc,
        });
      }
    }
    this.clnt_sal = "select";
    this.changeSalutation(this.clnt_sal);
  }

  appTypeSelect(e) {
    if (e == "New") {
      this.follow_flag = false;
      this.sugeryImges = false;
    } else {
      this.follow_flag = true;
      this.sugeryImges = true;
      if (this.spl_id == "29") {
        this.surgery_show = false;
      } else {
        this.surgery_show = true;
      }
    }
  }

  userTypeSelect(e) {
    this.saveFlag = false;
    this.Client_type = e;
    if (this.Client_type == "New") {
      if (this.spl_id == "29") {
        this.surgery_show = false;
      }
      this.user_type = false;
      this.new_user = false;
      this.exist_user = true;
      this.searchFlag = true;
      this.client_reg_id = "0";
      this.relation_id = "1";
      this.sub_rel_id = "";
      this.clnt_gender = "Male";
      this.dob_flag = false;
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_age = "";
      this.clnt_gender = "";
      this.clnt_blood = "";
      this.clnt_blood_id = "";
      this.clnt_fg_name = "";
      this.clnt_income = "";
      this.clnt_occupation = "";
      this.clnt_address1 = "NA";
      this.clnt_address2 = null;

      this.clnt_cont_number = null;
      this.clnt_symptom = "";
      this.clnt_dob = null;

      this.height_txt = "";
      this.hmeasure = "cms";
      this.weight_txt = "";
      this.wmeasure = "kgs";
      this.bmi_txt = ""
      this.temp_txt = "";
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp = "";
      this.pres_ill = "";
      this.med_hist = "";
      this.family_hist = "";
      this.physical_exam = "";
      this.hosp_name = "";
      this.refer_txt = null;
      this.covid_type = "no";
      this.scan_02 = "25";
      this.rtpcr_result = "Positive";
      this.covid_flag = false;
      this.observList = [];
      this.BP_01 = "";
      this.BP_02 = "";
      this.spo_txt = "";
      this.pulse_txt = "";
      this.cvs_txt = "";
      this.rs_txt = "";
      this.kidney_txt = "";
      this.resp_txt = "";
      this.pft_txt = "";
      this.deformities = "";
      this.surgery_type = "";
      this.speclization_txt = "";
      this.advice = "";
      this.clnt_symptom = "";
      this.disable_age = false;
      this.disable_dob = false;

      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }

      if (this.clnt_country_list.length != 0) {
        this.countryChange(this.clnt_country_id, "1");
      } else {
        this.getCountries("1");
      }
      this.clnt_sal = "select";

    } else {
      if (this.spl_id == "29") {
        this.surgery_show = false;
      }
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_cont_number = null;
      this.clnt_age = "";
      this.clnt_gender = "";
      this.clnt_blood = "";
      this.clnt_blood_id = "";
      this.clnt_fg_name = "";
      this.clnt_income = "";
      this.clnt_occupation = "";
      this.clnt_address1 = "NA";
      this.clnt_address2 = null;
      this.height_txt = "";
      this.hmeasure = "cms";
      this.weight_txt = "";
      this.wmeasure = "kgs";
      this.bmi_txt = "";
      this.relation_id = "1";
      this.sub_rel_id = "";
      this.temp_txt = "";
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp = "";
      this.pres_ill = "";
      this.med_hist = "";
      this.family_hist = "";
      this.physical_exam = "";
      this.hosp_name = "";
      this.covid_type = "no";
      this.scan_02 = "25";
      this.rtpcr_result = "Positive";
      this.covid_flag = false;
      this.observList = [];
      this.user_type = true;
      this.new_user = true;
      this.exist_user = false;
      this.dob_flag = true;
      this.searchFlag = false;
      this.BP_01 = "";
      this.BP_02 = "";
      this.spo_txt = "";
      this.pulse_txt = "";
      this.cvs_txt = "";
      this.rs_txt = "";
      this.kidney_txt = "";
      this.resp_txt = "";
      this.pft_txt = "";
      this.deformities = "";
      this.surgery_type = "";
      this.speclization_txt = "";
      this.refer_txt = "";
      this.advice = "";
      this.clnt_symptom = "";
      this.clnt_dob = null;

      this.disable_age = false;
      this.disable_dob = false;
      this.clnt_location_id = this.hospital_details.location;
      this.clnt_city_id = this.hospital_details.city;
      this.clnt_state_id = this.hospital_details.state;
      this.clnt_country_id = this.hospital_details.country;
      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }
      this.getCountries("0");
    }
  }

  Acitvate_savebtn() {
    if (this.clnt_fname != null && this.clnt_lname != null && this.clnt_cont_number != null && this.clnt_age != "") {
      if (this.Client_type == "New") {
        this.saveFlag = true;
      }
    }
  }

  removeEmptyStringsFrom(obj) {
    const clone = { ...obj };
    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }

  getUserDetails() {
    var name_flag = true;
    if (this.clnt_fname == undefined && this.clnt_lname == undefined && this.clnt_cont_number == undefined) {
      name_flag = false;
    }

    if (name_flag == true) {
      var fname, mname, lname, mnumber;

      if (this.clnt_fname != null) {
        fname = encrypt_decript.Encript(this.clnt_fname.trim()).toString();
      }

      if (this.clnt_mname != null && this.clnt_mname != "") {
        mname = encrypt_decript.Encript(this.clnt_mname.trim()).toString();
      }

      if (this.clnt_lname != null && this.clnt_lname != "") {
        lname = encrypt_decript.Encript(this.clnt_lname.trim()).toString();
      }

      if (this.clnt_cont_number != undefined) {
        mnumber = encrypt_decript.Encript(this.clnt_cont_number).toString();
      }

      var senddata = {
        mobile: mnumber,
        fname: fname,
        mname: mname,
        lname: lname,
        country: "IN",
        hptl_id: this.hospital_id,
        type: this.Client_type,
        rel_id: this.rel_id,
        is_doc: 1
      }
      senddata = this.removeEmptyStringsFrom(senddata);

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.clnt_url, JSON.stringify(senddata), { headers: headers }).subscribe(
        data => {
          if (data.json() != undefined && data.json().length != 0) {
            this.saveFlag = true;
            this.historyFlag = false;
            this.setClientDetails(data);
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      )
    } else {
      this.toastr.error(Message_data.enterNameOrOtherDet);
    }
  }

  getCurrentDate() {
    this.CurrentDatetime = Master_Helper.getMasterCurrentDate().current_date;
    this.currentDate = Master_Helper.getMasterCurrentDate().current_date;
    this.currentTime = Master_Helper.getMasterCurrentDate().current_time;
    this.get_date = Master_Helper.getMasterCurrentDate().current_date.split('-');
    this.currentYear = this.get_date[0];
    this.Appoint_Date = Date_Formate(this.CurrentDatetime);
    Helper_Class.setKey(Master_Helper.getMasterCurrentDate().provider + Master_Helper.getMasterCurrentDate().clientid + Master_Helper.getMasterCurrentDate().messageid);
    Helper_Class.setIV(Master_Helper.getMasterCurrentDate().random);
  }

  fname_toUpper() {
    if (this.clnt_fname != undefined) {
      this.clnt_fname = this.clnt_fname.toLocaleUpperCase();
    }
  }

  mname_toUpper() {
    if (this.clnt_mname != undefined) {
      this.clnt_mname = this.clnt_mname.toLocaleUpperCase();
    }
  }

  lname_toUpper() {
    if (this.clnt_lname != undefined) {
      this.clnt_lname = this.clnt_lname.toLocaleUpperCase();
      this.clientarray = [];
      this.getClientDetails();
    } else {
      this.clname;
      this.clnt_lname == null;
      this.lreq_flag = true;
    }
  }

  Date_click(e) {
    var l = e.split("-");
    this.clnt_dob = e;

    this.disable_age = e != null ? true : false;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.age_url, JSON.stringify({
      dobdate: Date_Formate(this.clnt_dob),
      curdate: Date_Formate(this.CurrentDatetime),
      spl: this.spl_id,
    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();

        if (obj != undefined) {
          this.clnt_age = obj.value;
          this.Acitvate_savebtn();
        }
      })
  }

  //Get surgery types
  getGeneralSurgeries() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/medproc', { "spl_id": "29" },
      { headers: headers })
      .subscribe(
        response => {
          this.surgeryarray = [];
          var obj = response.json();

          if (obj.med_procedures != null) {
            this.surgeryarray = obj.med_procedures;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  surgery_change(e) {
    for (var i = 0; i < this.surgeryarray.length; i++) {
      if (this.surgeryarray[i].procedure_id == e) {
        this.surgery_id = this.surgeryarray[i].procedure_id;
      }
    }
  }

  create_appointment(print) {
    console.log("printprint"+print)
    var create_flag = true; var dob;
    if (this.clnt_cont_number.length != 10) {
      create_flag = false;
    }

    if (this.symptoms_text.trim() != "" && this.symptoms_text != undefined && this.symptoms_text != null) {
      var new_symptoms = [];
      new_symptoms = this.filterNew(this.symptoms_text, this.newsymptoms);
    } else {
      this.chief_comp = "";
    }

    if (this.med_hist_text.trim() != "" && this.med_hist_text != undefined && this.med_hist_text != null) {
      var new_med_hist = [];
      new_med_hist = this.filterNew(this.med_hist_text, this.newmedhist);
    } else {
      this.med_hist = "";
    }

    if (this.family_hist_text.trim() != "" && this.family_hist_text != undefined && this.family_hist_text != null) {
      var new_family_hist = [];
      new_family_hist = this.filterNew(this.family_hist_text, this.newfamhist);
    } else {
      this.family_hist = "";
    }

    if (this.pres_ill_text.trim() != "" && this.pres_ill_text != undefined && this.pres_ill_text != null) {
      var new_pres_ill = [];
      new_pres_ill = this.filterNew(this.pres_ill_text, this.newpresill);
    } else {
      this.pres_ill = "";
    }

    if (this.physical_exam_text.trim() != "" && this.physical_exam_text != undefined && this.physical_exam_text != null) {
      var new_phys_exam = [];
      new_phys_exam = this.filterNew(this.physical_exam_text, this.newphyexamdet);
    } else {
      this.physical_exam = "";
    }

    if (this.treatment_text.trim() != "" && this.treatment_text != undefined && this.treatment_text != null) {
      var new_treatment = [];
      new_treatment = this.filterNew(this.treatment_text, this.newtreatdet);
    } else {
      this.treatment_text = "";
    }

    if (this.chief_comp != "" && this.chief_comp != undefined && this.chief_comp != null) {
      this.chief_comp = this.addComma(this.chief_comp);
    }

    if (this.med_hist != "" && this.med_hist != undefined && this.med_hist != null) {
      this.med_hist = this.addComma(this.med_hist);
    }

    if (this.family_hist != "" && this.family_hist != undefined && this.family_hist != null) {
      this.family_hist = this.addComma(this.family_hist);
    }

    if (this.pres_ill != "" && this.pres_ill != undefined && this.pres_ill != null) {
      this.pres_ill = this.addComma(this.pres_ill);
    }

    if (this.physical_exam != "" && this.physical_exam != undefined && this.physical_exam != null) {
      this.physical_exam = this.addComma(this.physical_exam);
    }

    if (this.treatment != "" && this.treatment != undefined && this.treatment != null) {
      this.treatment = this.addComma(this.treatment);
    }

    if (this.clnt_address1 == null || this.clnt_address1 == undefined || this.clnt_address1 == "") {
      this.clnt_address1 = "";
    }

    if (this.clnt_address2 == null || this.clnt_address2 == undefined || this.clnt_address2 == "") {
      this.clnt_address2 = "";
    }

    if (this.Client_type == "New") {
      if (this.clnt_cont_number == undefined || this.clnt_fname == undefined || this.clnt_lname == undefined
        || this.clnt_gender == undefined || this.spl_id == undefined || this.clnt_location == null || this.clnt_location == "") {
        create_flag = false;
      }

      if (this.require_flag == true) {
        if (this.clnt_age == "") {
          create_flag = false;
        }
      }
    } else {
      if (this.clnt_cont_number == undefined || this.spl_id == undefined) {
        create_flag = false;
      }
    }

    if (this.spl_id == "11") {
      if (this.clnt_gender != "Female") {
        create_flag = false;
      }
    }

    if (this.listProducts.length != 0) {
      if (this.clnt_symptom == "") {
        create_flag = false;
      }
    }

    if (this.clnt_address1 == "") {
      create_flag = false;
    }

    if (create_flag == false) {
      if (this.spl_id == "11") {
        if (this.clnt_gender != "Female") {
          create_flag = false;
          this.toastr.error(Message_data.splOnlyForwomen);
        }
      } else if (this.clnt_cont_number.length != 10) {
        this.toastr.error(Message_data.validMobileNo);
      } else if (this.clnt_symptom == "" && this.listProducts.length != 0) {
        this.toastr.error("Enter diagnosis");
      } else {
        this.toastr.error(Message_data.mandatory);
      }

    } else {
      this.saveFlag = true;
      this.patient_name = null;
      if (this.clnt_mname != undefined && this.clnt_mname != null) {
        if (this.clnt_mname != "") {
          this.patient_name = this.clnt_fname.trim() + " " + this.clnt_mname.trim() + " " + this.clnt_lname.trim();
        } else {
          this.patient_name = this.clnt_fname.trim() + " " + this.clnt_lname.trim();
        }
      } else {
        this.patient_name = this.clnt_fname.trim() + " " + this.clnt_lname.trim();
      }
      console.log("this.patient_namethis.patient_name==="+this.patient_name+this.temp_txt+this.cvs_txt+this.rs_txt)
      Doc_Helper.setClient_name(this.patient_name);
      Doc_Helper.setClient_gender(this.clnt_gender);
      Doc_Helper.setClient_age(this.clnt_age);

      if (this.spl_id == "15") {
        var cvs: any = this.cvs_txt;
        var rs: any = this.rs_txt;
        var kidney: any = this.kidney_txt;
        var deformities: any = this.deformities;
        var arthritis_type: any = this.arthritis_type;

      } else {
        cvs = null;
        rs = null;
        kidney = null;
        deformities = null;
        arthritis_type = null;
      }

      var curr_datetime = new Date(this.currentDate + " " + this.currentTime);
      var curr_session;

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = "Morning";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = "Afternoon";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = "Evening";
      }

      curr_session = "Evening";
      var apptype;
      apptype = this.App_type == "New" ? "1" : "2";

      var midname;
      midname = this.clnt_mname != undefined ? encrypt_decript.Encript(this.clnt_mname.trim()).toString() : "";

      var occupation;
      occupation = this.clnt_occupation != undefined ? encrypt_decript.Encript(this.clnt_occupation.trim()).toString() : "";


      var addr1;
      addr1 = this.clnt_address1 != undefined ? encrypt_decript.Encript(this.clnt_address1.trim()).toString() : null;

      var addr2;
      addr2 = this.clnt_address2 != undefined ? encrypt_decript.Encript(this.clnt_address2.trim()).toString() : null;

      if (this.clnt_dob != undefined && this.clnt_dob != "" && this.clnt_dob.length != 0 && this.clnt_dob != null) {
        dob = this.clnt_dob;
      }

      var address: any = [];
      address.push({
        address1: addr1,
        address2: addr2,
        location: this.clnt_location_id,
        city: this.clnt_city_id,
        state: this.clnt_state_id,
        country: this.clnt_country_id,
        zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type: "pres"
      });

      var bpval;
      if (this.BP_01 != null && this.BP_01 != undefined && this.BP_01 != "") {
        //  bpval = this.BP_01 + "/" + this.BP_02;
        this.bp = this.BP_01 + "/" + this.BP_02;
      }

      if (this.spl_id == "29") {
        var sugeryDetails = {
          surgery: this.surgery_id,
          surgery_type: this.surgery_type,
          surgery_inv: this.sug_investigation,
          surgery_proc: this.sug_procedure,
          surgery_image: this.sendImge,
          sugImageString: this.imagePath,
          updateImage: this.surgeryNewFlag,
          flow: "walkin"
        }
        Doc_Helper.setSugeryDetails(sugeryDetails);
      }

      var save_data;
      if (this.surgery_show == false) {
        save_data = JSON.stringify({
          prov_id: this.userinfo.user_id,
          client_id: this.client_reg_id,
          relation_id: this.rel_id,
          salutation: this.clnt_sal,
          first_name: encrypt_decript.Encript(this.clnt_fname.trim()).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname.trim()).toString(),
          age: this.clnt_age,
          dob: dob,
          gender: encrypt_decript.Encript(this.clnt_gender).toString(),
          mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
          medicare_id: "1",
          specialization_id: this.spl_id,
          hptl_id: this.hospital_id,
          date: this.CurrentDatetime,
          Appoint_Date: Date_Formate(this.CurrentDatetime),
          time: "1",
          token: this.tokenumber,
          session: encrypt_decript.Encript(curr_session).toString(),
          symptoms: this.clnt_symptom,
          app_type: apptype,
          height: this.height_txt,
          height_measure: this.hmeasure,
          weight: this.weight_txt,
          weight_measure: this.wmeasure,
          bmi: this.bmi_txt,
          bmr: this.txt_bmr,
          temparature: this.temp_txt,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          home_care: this.homecarevalue,
          blood_group: this.clnt_blood,
          occupation: occupation,
          income: this.clnt_income,
          guardian: this.clnt_fg_name,
          citizenship: encrypt_decript.Encript("Indian").toString(),
          nationality: encrypt_decript.Encript("Indian").toString(),
          address: address,
          flag: "mobile",
          hosp_name: this.hosp_name,
          refer_txt: this.refer_txt,
          spl_txt: this.speclization_txt,
          blood_pressure: bpval,
          pulse: this.pulse_txt,
          cvs: this.cvs_txt,
          rs: this.rs_txt,
          kidney: this.kidney_txt,
          spo2: this.spo_txt,
          cns_txt: this.cns_txt,
          abd_txt: this.abd_txt,
          pr_txt: this.pr_txt,
          treatment: this.treatment,
          arthritis_type: this.arthritis_type,
          deformities: this.deformities,
        
          pft: this.pft_txt,
          ref_by: this.ref_doc,
          covid_symp: this.finalcovidsymp,
          covid_comorb: this.finalcomorbList,
          covid_observ: this.observList,
          is_covid: this.covid_type,
          covid_dur: this.covid_duration,
          advice: this.advice,
          location: this.h_location,
          address1: this.h_address1,
          address2: this.h_address2,
          city: this.h_city,
          state: this.h_state,
          country: this.h_country,
          zipcode: this.h_zipcode,
          newsymptoms: new_symptoms,
          newmedhist: new_med_hist,
          newfamhist: new_family_hist,
          newpresill: new_pres_ill,
          phyexamdet: new_phys_exam,
          treatdet: new_treatment,
          surgery_type: this.surgery_type,
          surgery: this.surgery_id,
          surgery_inv: this.sug_investigation,
          surgery_proc: this.sug_procedure,
          surgery_image: this.sendImge,
          updateImage: this.surgeryNewFlag,
          barcode:this.barcode
        });

      } else {
        save_data = JSON.stringify({
          prov_id: this.userinfo.user_id,
          client_id: this.client_reg_id,
          relation_id: this.rel_id,
          salutation: this.clnt_sal,
          first_name: encrypt_decript.Encript(this.clnt_fname.trim()).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname.trim()).toString(),
          age: this.clnt_age,
          dob: dob,
          gender: encrypt_decript.Encript(this.clnt_gender).toString(),
          mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
          medicare_id: "1",
          specialization_id: this.spl_id,
          hptl_id: this.hospital_id,
          date: this.CurrentDatetime,
          Appoint_Date: Date_Formate(this.CurrentDatetime),
          time: "1",
          token: this.tokenumber,
          session: encrypt_decript.Encript(curr_session).toString(),
          symptoms: this.clnt_symptom,
          app_type: apptype,
          height: this.height_txt,
          height_measure: this.hmeasure,
          weight: this.weight_txt,
          weight_measure: this.wmeasure,
          bmi: this.bmi_txt,
          bmr: this.txt_bmr,
          temparature: this.temp_txt,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          home_care: this.homecarevalue,
          blood_group: this.clnt_blood,
          occupation: occupation,
          income: this.clnt_income,
          guardian: this.clnt_fg_name,
          citizenship: encrypt_decript.Encript("Indian").toString(),
          nationality: encrypt_decript.Encript("Indian").toString(),
          address: address,
          flag: "mobile",
          hosp_name: this.hosp_name,
          refer_txt: this.refer_txt,
          spl_txt: this.speclization_txt,
          blood_pressure: bpval,
          pulse: this.pulse_txt,
          cvs: this.cvs_txt,
          rs: this.rs_txt,
          kidney: this.kidney_txt,
          spo2: this.spo_txt,
          cns_txt: this.cns_txt,
          abd_txt: this.abd_txt,
          pr_txt: this.pr_txt,
          treatment: this.treatment,
          arthritis_type: this.arthritis_type,
          deformities: this.deformities,
          pft: this.pft_txt,
          ref_by: this.ref_doc,
          covid_symp: this.finalcovidsymp,
          covid_comorb: this.finalcomorbList,
          covid_observ: this.observList,
          is_covid: this.covid_type,
          covid_dur: this.covid_duration,
          advice: this.advice,
          location: this.h_location,
          address1: this.h_address1,
          address2: this.h_address2,
          city: this.h_city,
          state: this.h_state,
          country: this.h_country,
          zipcode: this.h_zipcode,
          newsymptoms: new_symptoms,
          newmedhist: new_med_hist,
          newfamhist: new_family_hist,
          newpresill: new_pres_ill,
          phyexamdet: new_phys_exam,
          treatdet: new_treatment,
          barcode:this.barcode
        });
      }

      var referal = {
        hosp_name: this.hosp_name,
        refer_txt: this.refer_txt,
        spl_txt: this.speclization_txt,
      };
      Doc_Helper.setReferal(referal);
      console.log(" cpap ==save_data=="+JSON.stringify(save_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/capp', save_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(" cpap ===="+JSON.stringify(obj))
            if (obj.app_id != undefined) {
              this.toastr.success(Message_data.apptSavedSuccess);
              this.app_id = obj.app_id;
              this.client_reg_id = obj.client_id;
              this.barcode=obj.barcode
              Doc_Helper.setClient_Info(undefined);
              Doc_Helper.setApp_id(this.app_id);
              Doc_Helper.setClient_gender(this.clnt_gender);

              for (var i = 0; i < this.spl_array.length; i++) {
                if (this.spl_id == this.spl_array[i].spl_id) {
                  var splname = this.spl_array[i].spl_name
                }
              }

              Doc_Helper.setSpl_id(this.spl_id);
              Doc_Helper.setSpl_name(splname);
              var walkin_info: any = [];
              var sub_rel = "";
              if (this.Client_type == "New") {
                Doc_Helper.setClient_id(obj.client_id);
                Doc_Helper.setRel_id("1");
                Doc_Helper.setSub_id(sub_rel);
                walkin_info = {
                  Gender_data: this.clnt_gender,
                  client_name: this.patient_name,
                  Client_id: obj.client_id,
                  app_id: obj.app_id,
                  Age_data: this.clnt_age,
                  Case_Clnt_dob: this.clnt_dob,
                  spl_id: this.spl_id,
                  hptl_clinic_id: this.hospital_id,
                  mobile: this.clnt_cont_number.toString(),
                  medicare_name: this.treatement_type,
                  AppFlow: "Walkin",
                  rel_id: "1",
                  sub_id: "",
                  bp: bpval,
                  pulse: this.pulse_txt,
                  height: this.height_txt,
                  height_measure: this.hmeasure,
                  weight: this.weight_txt,
                  weight_measure: this.wmeasure,
                  bmi: this.bmi_txt,
                  bmr: this.txt_bmr,
                  temparature: this.temp_txt,
                  symptoms: this.clnt_symptom,
                  treatment: this.treatment,
                  refer_by: this.refer_txt,
                  refer_txt: this.refer_txt,
                
                  cvs: this.cvs_txt,
                  rs: this.rs_txt,
                  kidney: kidney,
                  deformities: deformities,
                  arthritis_type: arthritis_type,
                  spl: this.spl,
                  relation: "Self",
                  covid_symp: this.finalcovidsymp,
                  covid_comorb: this.finalcomorbList,
                  covid_observ: this.observList,
                  is_covid: this.covid_type,
                  covid_dur: this.covid_duration,
                  advice: this.advice,
                  location: this.h_location,
                  address1: this.h_address1,
                  address2: this.h_address2,
                  city: this.h_city,
                  state: this.h_state,
                  country: this.h_country,
                  zipcode: this.h_zipcode,
                  Appoint_Date: Date_Formate(this.CurrentDatetime),
                  country_id: this.h_country_id,
                  state_id: this.h_state_id,
                  city_id: this.h_city_id,
                  occupation: this.clnt_occupation
                };

              } else {
                Doc_Helper.setClient_id(this.client_reg_id);
                Doc_Helper.setRel_id("1");
                Doc_Helper.setSub_id(sub_rel);
                walkin_info = {
                  Gender_data: this.clnt_gender,
                  client_name: this.patient_name,
                  Client_id: this.client_reg_id,
                  app_id: this.app_id,
                  Age_data: this.clnt_age,
                  Case_Clnt_dob: this.clnt_dob,
                  spl_id: this.spl_id,
                  hptl_clinic_id: this.hospital_id,
                  mobile: this.clnt_cont_number.toString(),
                  medicare_name: this.treatement_type,
                  AppFlow: "Walkin",
                  rel_id: "1",
                  sub_id: "",
                  bp: bpval,
                  pulse: this.pulse_txt,
                  height: this.height_txt,
                  height_measure: this.hmeasure,
                  weight: this.weight_txt,
                  weight_measure: this.wmeasure,
                  bmi: this.bmi_txt,
                  bmr: this.txt_bmr,
                  temparature: this.temp_txt,
                  symptoms: this.clnt_symptom,
                  treatment: this.treatment,
                  refer_by: this.refer_txt,
                
                  cvs: this.cvs_txt,
                  rs: this.rs_txt,
                  kidney: kidney,
                  deformities: deformities,
                  arthritis_type: arthritis_type,
                  spl: this.spl,
                  relationship_name: "Self",
                  covid_symp: this.finalcovidsymp,
                  covid_comorb: this.finalcomorbList,
                  covid_observ: this.observList,
                  is_covid: this.covid_type,
                  covid_dur: this.covid_duration,
                  advice: this.advice,
                  location: this.h_location,
                  address1: this.h_address1,
                  address2: this.h_address2,
                  city: this.h_city,
                  state: this.h_state,
                  country: this.h_country,
                  zipcode: this.h_zipcode,
                  Appoint_Date: Date_Formate(this.CurrentDatetime),
                  country_id: this.h_country_id,
                  state_id: this.h_state_id,
                  city_id: this.h_city_id,
                  occupation: this.clnt_occupation
                };
              }
              console.log(" this.patient_name ===="+JSON.stringify(this.patient_name)+this.temparature+this.cvs_txt+this.kidney_txt)
              Doc_Helper.setClient_Info(walkin_info);
              Helper_Class.setapp_flow("0");
            
              
              this.personalinfo = Doc_Helper.getClient_Info();
              this.add_MP = false;

              if (this.doct_country != null && this.doct_country != undefined) {
                this.clnt_country_id = this.doct_country;
                this.clnt_state_id = Doc_Helper.getClient_Info().state_id;
                this.clnt_city_id = Doc_Helper.getClient_Info().city_id;
                this.getCountries("0");

              } else {
                this.getCountries("0");
              }

              this.doct_name = this.doctorname;
              this.client_gender = this.clnt_gender;
              this.Appoint_Date = Date_Formate(this.CurrentDatetime)
              this.client_age = this.clnt_age;
              this.doct_clientname = this.patient_name;

              if (this.height_txt != undefined && this.height_txt != null && this.height_txt != "") {
                this.height = this.height_txt + this.hmeasure;
                this.height_flag = true;
              } else {
                this.height_flag = false;
              }

              if (this.weight_txt != undefined && this.weight_txt != null && this.weight_txt != "") {
                this.weight = this.weight_txt + this.wmeasure;
                this.weight_flag = true;
              } else {
                this.weight_flag = false;
              }

              if (this.temp_txt != undefined && this.temp_txt != null) {
                this.temparature = this.temp_txt;
                this.temparature_flag = true;
              } else {
                this.temparature_flag = false;
              }

              var getdata = this.userinfo.medicares;
              if (getdata != undefined && getdata != null) {
                for (var i = 0; i < getdata.length; i++) {
                  this.Medicare_name = getdata[0].medicare_name;
                }
              }

              this.hospitalinfoarray = Helper_Class.getHospital();
              this.pharma_id = this.hospitalinfoarray[0].pharma_id;
              this.prfer_pharmatxt = this.hospitalinfoarray[0].pharma_name;
              if (this.prfer_pharmatxt != undefined) {
                this.hospitall_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
                this.Get_pharmacy_address();
                this.getmedicineonit();
              }

              this.specializations_name = Helper_Class.getSpecializations();
              this.qualification = Helper_Class.getQualification();
              this.doc_qualif = Helper_Class.getQualification();
              this.recognition = null;
              this.recognizing = false;
              this.licence_code = this.userinfo.licence_code;
              if (Doc_Helper.getHospital_logo() != undefined) {
                this.hosp_img = Doc_Helper.getHospital_logo();
                this.getBase64Image(this.hosp_img);
              }

              if (Doc_Helper.getHospFooter() != undefined) {
                this.hosp_footer = Doc_Helper.getHospFooter();
                this.printf_flag = false;
              } else {
                this.printf_flag = true;
              }

              if (Doc_Helper.getHospital_print_template() != undefined && Doc_Helper.getHospital_print_template() != "undefined") {
                this.print_template = Doc_Helper.getHospital_print_template();
              } else {
                this.print_template = "noheader";
              }

              if (Doc_Helper.getHospital_pres_logo() != undefined && Doc_Helper.getHospital_pres_logo() != "undefined") {
                this.hptl_pres_logo = Doc_Helper.getHospital_pres_logo();
                this.preslogo_style = Helper_Class.getHospital()[0].pres_print_logo_style;

              }
              this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style;
              this.headerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_heading);
              this.footerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_footer);

              if (Doc_Helper.getHospital_bg_image() != undefined) {
                this.bg_image = Doc_Helper.getHospital_bg_image();
              }

              if (this.treatment != undefined && this.treatment != "undefined") {
                this.treatment = this.sanitizer.bypassSecurityTrustHtml(this.treatment);
                this.treatflag = true;
              }

              if (this.refer_txt != null && this.refer_txt != undefined && this.refer_txt != "" && this.refer_txt != 'null') {
                this.refer_txt = this.refer_txt;
                this.ref_flag = true;
              } else {
                this.ref_flag = false;
              }

              if (this.oberse_med != undefined) {
                this.oberse_med = this.oberse_med;
              }
              if (this.clnt_symptom != undefined) {
                
                this.clnt_symptom = this.clnt_symptom;
              }
             
              this.header_footer_flag = false;
              this.med_pres_listdata = false;
              this.clnt_bp = bpval == undefined ? "" : bpval;
              if (bpval != null && bpval != undefined) {
                this.clnt_bp = bpval;
                this.bp_flag = true;

              } else {
            
                this.bp_flag = false;
              }

              if (this.pulse_txt != undefined && bpval != null) {
                this.clnt_pulse = this.pulse_txt;
                
                this.pulseflag = true;

              } else {
               
                this.pulseflag = false;
                this.clnt_pulse = "";
              }

           
              if (this.cvs_txt != null) {
                this.clnt_cvs = this.cvs_txt;
                this.cvsflag = true;

              } else {
                
                this.cvsflag = false;
              }

             
              if (this.rs_txt != null) {
                this.clnt_rs = this.rs_txt
                this.rsflag = true;

              } else {
                this.clnt_rs = "";
              }

            
              if (this.kidney_txt != null && this.kidney_txt != "") {
                this.clnt_kidney = this.kidney_txt;
                this.kidneyflag = true;

              } else {
                this.clnt_kidney = "";
              }

              this.update_btn = false;
              this.viewrx_flag = false;
              this.Confirm_btn = true;
              this.casesheetbtn = false;
              this.case_sumbtn = false;
              this.save_data_flag = true;//for casesheet 
              
              this.print =true;
              
              if (this.noPres == false && this.listProducts.length != 0) {
                this.savePrescription("print");
              } else if (this.noPres == true) {
                this.closeAppointment()
              } else {
                if (this.viewCaseSheet == true) {
                  this.check_specialization();
                } else {
                  
                  setTimeout(() => {
                    this.change_type('client', 1, 'no');
                  }, 1000);
                }
              }

            } else {
              this.toastr.error(Message_data.unableToSave);
            }
          });
    }
  }

  noPrecription(action) {
    if (action == "yes") {
      this.noPres = true;
    } else {
      this.noPres = false;
    }
  }

  closeAppointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/clsapp/',
      JSON.stringify({
        app_id: this.app_id,
        mobile: this.clnt_cont_number,
        type: "doctor"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.key != null) {
            if (obj.key == "1") {
              // this.toastr.success("Successfully closed appointment");
              if (this.viewCaseSheet == true) {
                this.check_specialization();
              } else {
                this.change_type('client', 1, 'no');
              }
              //this.navCtrl.push("clientapplistPage");

            } else {
              this.toastr.error("Unable to close appointment, try again")
            }
          }
        }
      );

  }
  //update appointment 
  update_appointment() {
    var bpval;
    if (this.BP_01 != null && this.BP_01 != undefined && this.BP_01 != "") {
      bpval = this.BP_01 + "/" + this.BP_02;
    }

    var update_data = JSON.stringify({
      client_id: this.client_reg_id,
      relation_id: this.rel_id,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bmr: this.txt_bmr,
      complaints: this.chief_comp,
      present_illness: this.pres_ill,
      medical_history: this.med_hist,
      family_history: this.family_hist,
      physical_exam: this.physical_exam,
      doc_app_id: this.app_id,
      blood_pressure: bpval,
      pulse: this.pulse_txt,
      cvs: this.cvs_txt,
      rs: this.rs_txt,
      kidney: this.kidney_txt,
      spo2: this.spo_txt,
      treatment: this.treatment,
      arthritis_type: this.arthritis_type,
      deformities: this.deformities,
      surgery_type: this.surgery_type,
      surgery: this.surgery_id,
      prt: this.pft_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      advice: this.advice,
      spl: this.spl,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/updappn/', update_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.key == "1") {
            this.toastr.error(obj.result);
            this.messageservice.sendMessage("walkin")
          } else {
            this.toastr.error(obj.result);
          }
        });
  }

  getCasesheetFields(e) {
    this.spl_id = e;
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_id == this.spl_array[i].spl_id) {
        this.spl = this.spl_array[i].spl_name;
      }
    }

    if (this.spl_id == "29") {
      this.surgery_show = false;
      if (this.App_type == "Follow-up") {
        this.sugeryImges = true;
      } else {
        this.sugeryImges = false;
      }
      this.loadCss();
      this.loadScript();
    }
  }

  getPatientCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var getFields = JSON.stringify({
      hosp_id: hptl_id,
      spl_id: spl_id
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gpfhsp/', getFields,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setHopitalfieldsRet(obj.pages);
          if (this.save_data_flag == true) {
            this.get_case_sheet_data(Doc_Helper.getApp_id());
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr)
        }
      )
  }

  get_case_sheet_data(doc_app_id) {
    // call cbook
    Doc_Helper.setDischarge_Summary(undefined);
    var sen_pass = JSON.stringify({
      doc_reg_id: this.userinfo.user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            } else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }

          if (this.Gynecology_flag == true) {
            const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
              width: '280px',
              height: '182px'
            });
         
            
          } else {
            this.messageservice.sendMessage("casesheet")
          }
        },
        error => { }
      )
  }

  write_casesheet() {
    if (this.casesheetbtn == false) {
      this.check_specialization();
    }
  }

  check_specialization() {
    var oflag: boolean = false;
    this.Gynecology_flag = false;
    Helper_Class.setapp_flow("0");
    this.Is_specilization = this.spl;
    if (this.Is_specilization == "Gynecology") {
      this.Gynecology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == "Pediatrics") {
      this.Pediatrics_flag = true;
      oflag = true;
    } else if (this.Is_specilization == "Cardiology") {
      this.Cardio_flag = true;
      oflag = true;
    } else if (this.Is_specilization == "Nephrology") {
      this.Nephrology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == "Dental") {
      this.Dental_flag = true;
      oflag = true;
    } else if (this.Is_specilization == "Diabetes") {
      this.Diabetes_flag = true;
      oflag = true;
    } else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }

    if (this.Gynecology_flag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.Gynecology_flag == false) {
      this.getpatientcasesheetDetails();
    }
  }

  getpatientcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);
    this.getASGDET(Doc_Helper.getClient_id(), Doc_Helper.getRel_id(), "");

    var send_data = JSON.stringify({
      doc_reg_id: this.userinfo.user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: "IN",
    });

    if (this.Pediatrics_flag == true) {
      this.spl_url = 'pedia/chist';
    }
    else if (this.Cardio_flag == true) {
      this.spl_url = 'cardio/chist';
    }
    else if (this.Nephrology_flag == true) {
      this.spl_url = 'neph/chist';
    }
    else if (this.Dental_flag == true) {
      this.spl_url = 'dental/perdet/';
    }
    else if (this.Diabetes_flag == true) {
      this.spl_url = 'diab/chist'
    }
    else {
      this.spl_url = 'other/chist';
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.spl_url, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj["key"] != 0) {
            if (obj != null || obj != "" || obj != undefined) {
              Helper_Class.setRet(obj);
              if (this.Pediatrics_flag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.Nephrology_flag == true) {

                Helper_nephro_casesheet.nephro_get_ret = obj;
              }
              if (this.Dental_flag == true) {

                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.Dental_flag == false && this.Nephrology_flag == false) {
                this.messageservice.sendMessage("casesheet_list")
              } else {
                this.getPatientCaseSheetFields(this.hospital_id, Doc_Helper.getSpl_id(), Doc_Helper.getApp_id());
              }
            }
          } else {
            if (Doc_Helper.getCasesheet_flag() == "1") {
              this.getPatientCaseSheetFields(this.hospital_id, Doc_Helper.getSpl_id(), Doc_Helper.getApp_id());
            } else {
              this.getPatientCaseSheetFields(this.hospital_id, Doc_Helper.getSpl_id(), Doc_Helper.getApp_id());
            }
          }

        },//response
        error => { })
  }

  getASGDET(Client_id, rel_id, sub_id) {
    var sen_pass;
    if (sub_id != null && sub_id != undefined && sub_id != "") {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        sub_rel_id: sub_id,
        country: ipaddress.country_code
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setAsg(obj);
        });

  }

  clearMobile() {
    this.clnt_cont_number = null;
  }

  checkClient() {
    if (this.clnt_cont_number != undefined) {
      this.getClientDetails();
    }
  }

  getClientDetails() {
    if (this.Client_type == "New") {
      var clntname = encrypt_decript.Encript(this.clnt_fname).toString();
      var lname;

      if (this.clnt_lname != undefined && this.clnt_lname.trim() != undefined) {
        lname = encrypt_decript.Encript(this.clnt_lname).toString();
      }

      var mobiledata;
      if (this.clnt_cont_number != undefined) {
        mobiledata = encrypt_decript.Encript(this.clnt_cont_number).toString();
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyname/',
        JSON.stringify({
          first_name: clntname,
          last_name: lname,
          mobile: mobiledata,
          type: "New",
          hosp_id: this.hospital_id,
        }),
        { headers: headers })
        .subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            if (obj.clients != null) {
              this.toastr.error(obj.clients);
              if (obj.key == "1") {
                this.lreq_flag = true;
              } else {
                this.Confirm_btn = false
              }
            }

            if (this.clflag == "2") {
              this.cmname;
            } else if (this.clflag == "3") {
              this.clname;
            } else if (this.clflag == "4") {
              this.cmobile;
            }
          },
          error => { }
        )
    }
  }

  comorb_click(e: any, data) {
    if (e == true) {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = true;
            this.finalcomorbList.push(this.comorbList[i].comorb_id)
          }
        }
      }
    } else {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = false;
          }
        }
      }

      if (this.finalcomorbList.length != 0) {
        for (var j = 0; j < this.finalcomorbList.length; j++) {
          if (data == this.finalcomorbList[j]) {
            this.finalcomorbList.splice(j, 1)
          }
        }
      }
    }
  }

  symptom_click(e: any, data) {
    if (e == true) {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = true;
            this.finalcovidsymp.push(this.covidSympList[i].symp_id)
          }
        }
      }
    } else {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = false;
          }
        }
      }
      if (this.finalcovidsymp.length != 0) {
        for (var j = 0; j < this.finalcovidsymp.length; j++) {
          if (data == this.finalcovidsymp[j]) {
            this.finalcovidsymp.splice(j, 1)
          }
        }
      }
    }
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)

    var sendata = JSON.stringify({
      doc_reg_id: this.userinfo.user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code
    })

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {


          var obj = response.json();
          if (obj["key"] != 0) {
            if (obj != null && obj != undefined && obj != "") {
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              this.messageservice.sendMessage("casesheet_list")
            }
          } else {
            this.getPatientCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  ChangeAge() {
    if (this.clnt_age != "") {
      this.disable_dob = true;
      this.clnt_dob = "";
    } else {
      this.disable_dob = false;
    }
    this.nodob = true;
    var dateval = this.currentDate.split("-");
    this.clnt_dob = (parseInt(dateval[0]) - parseInt(this.clnt_age)) + "-01-01";
    this.disable_dob = false;
    this.Acitvate_savebtn();
  }

  Dental_Retrieval_2() {
    var sen_pass = JSON.stringify({
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code,
      den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/patdet/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.Set_den_ret2(obj);
        }
      )
  }

  // View casesheet summary
  case_summ() {
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_id == this.spl_array[i].spl_id) {
        var splname = this.spl_array[i].spl_name
      }
    }
  }

  async getCountries(flag) {
    this.clnt_country_list = [];
    if (Master_Helper.getMasterCountries() == undefined) {
      await this.masterData.getCountries();
    }
    var countryList = Master_Helper.getMasterCountries();
    if (countryList.countries != null) {
      this.clnt_country_list = countryList.countries;
      for (var c = 0; c < this.clnt_country_list.length; c++) {
        if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
          this.clnt_country = this.clnt_country_list[c].description;
          this.clnt_country_id = this.clnt_country_list[c].country_id;
          this.getStates(this.clnt_country_id, flag);
          break;
        }
      }
    }
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.clnt_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list = [];
        this.clnt_city_list = [];
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clnt_state_list = [];
    var data;
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/state", JSON.stringify({ country_id: country }),
        { headers: headers }).subscribe(
          response => {
            data = response.json();
            if (data.states != null) {
              this.clnt_state_list = data.states;
            }
          });
    } else {
      data = Master_Helper.getMasterStates();
      if (data.states != null) {
        this.clnt_state_list = data.states;
      }
    }
    if (this.clnt_state_list != null) {
      for (var i = 0; i < this.clnt_state_list.length; i++) {
        if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
          this.clnt_state_id = this.clnt_state_list[i].state_id;
          this.clnt_state = this.clnt_state_list[i].description;
          this.getCities(this.clnt_state_id, flag);
          break;
        }
      }
    }
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.clnt_state = this.clnt_state_list[i].description;
        this.clnt_city_list = [];
        this.clnt_city_id = undefined;
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/city", JSON.stringify({ state_id: state }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.cities != null)
              this.clnt_city_list = data.cities;

          });
    } else {
      var data = Master_Helper.getMasterCities();
      if (data.cities != null)
        this.clnt_city_list = data.cities;
    }

    if (this.clnt_city_list != null) {
      for (var i = 0; i < this.clnt_city_list.length; i++) {
        if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
          this.clnt_city_id = this.clnt_city_list[i].district_id;
          this.clnt_city = this.clnt_city_list[i].description;
          this.getLocations(this.clnt_city_id, flag);
          break;
        } else {
          this.clnt_city = this.clnt_city_list[0].description;
        }
      }
    }
  }

  cityChange(clnt_city, flag) {
    this.clnt_location_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.clnt_city = this.clnt_city_list[i].description;
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
      }
    }
  }

  getLocations(city, flag) {
    this.clnt_location_list = [];

    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/loc", JSON.stringify({ city_id: city }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.locations != null && data.locations.length != 0)
              this.clnt_location_list = data.locations;
          });
    } else {
      var data = Master_Helper.getMasterLocation();
      if (data.locations != null && data.locations.length != 0)
        this.clnt_location_list = data.locations;
    }
    if (this.clnt_location_list != null && this.clnt_location_list.length != 0) {
      for (var i = 0; i < this.clnt_location_list.length; i++) {
        if (this.clnt_location_list[i].location_id == this.clnt_location_id) {
          this.clnt_location_id = this.clnt_location_list[i].location_id;
          this.clnt_location = this.clnt_location_list[i].description;
          this.clnt_zipcode = this.clnt_location_list[i].pincode;
          this.clnt_location_list = [];
          break;
        }
      }
    }
  }

  location_change(locat_desc) {
    this.clnt_location_list = [];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc,
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clnt_location_list = [];
    }
  }

  location_selected(location) {
    this.clnt_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.clnt_location = this.clnt_location_list[i].description;
        this.clnt_zipcode = this.clnt_location_list[i].pincode;
      }
    }
    this.clnt_location_list = [];
  }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  calories_data() {
    if ((this.height_txt != undefined && this.height_txt.length != 0)
      || (this.weight_txt != undefined && this.weight_txt.length != 0)
      || (this.chief_comp != undefined && this.chief_comp.length != 0)
      || (this.family_hist != undefined && this.family_hist.length != 0)
      || (this.pres_ill != undefined && this.pres_ill.length != 0)
      || (this.med_hist != undefined && this.med_hist.length != 0)
      || (this.physical_exam != undefined && this.physical_exam.length != 0)) {
      if (this.app_id == undefined) {
        this.Confirm_btn = false;
      }
      if (this.height_txt != undefined && this.height_txt.length != 0) {
        this.encheight = encrypt_decript.Encript(this.height_txt).toString();
      }
      if (this.weight_txt != undefined && this.weight_txt.length != 0) {
        this.encweight = encrypt_decript.Encript(this.weight_txt).toString();
      }
    } else {
      this.Confirm_btn = true;
    }
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.bmi_txt = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.hmeasure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.hmeasure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);

        if (this.clnt_gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.clnt_age) + 5;
          this.txt_bmr = Math.round(get_bmr);

        } else if (this.clnt_gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.clnt_age.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.bmi_txt = Math.round(to);

        if (this.clnt_gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.clnt_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.clnt_gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.clnt_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);

        if (this.clnt_gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.clnt_age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.clnt_gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.clnt_age.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);

        if (this.clnt_gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.clnt_age) + 5;
          this.txt_bmr = Math.round(get_bmr);

        } else if (this.clnt_gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.clnt_age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }
    }
  }

  view_prescription() {
    var walkinViewpres: any = [];
    walkinViewpres.push({
      Gender_data: this.clnt_gender,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: "1",
      sub_id: "",
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      relationship_name: "Self",

      symptoms: this.clnt_symptom,
      medicare_name: this.treatement_type,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      advice: this.advice,
      addmed: "view",
      AppFlow: "NoapptMedPres",
      refer_by: this.refer_txt,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      country_id: this.h_country_id,
      state_id: this.h_state_id,
      city_id: this.h_city_id,
    })
    Doc_Helper.setClient_Info(walkinViewpres);
    this.messageservice.sendMessage("medprescription");
  }

  change_spec(spl_id) {
    this.speclization_txt = "";
    for (var i = 0; i < this.spl_type_array.length; i++) {
      if (spl_id == this.spl_type_array[i].spl_id) {
        this.speclization_txt = this.spl_type_array[i].spl_name;
      }
    }
  }

  async getCovidSymptoms() {
    if (Master_Helper.getMasterCovidSymptoms() == undefined) {
      await this.masterData.getCovidSymptoms();
    }
    var obj = Master_Helper.getMasterCovidSymptoms();
    if (obj != undefined) {
      for (var i = 0; i < obj.covid_symptoms.length; i++) {
        this.covidSympList.push({
          symp_id: obj.covid_symptoms[i].covid_symp_id,
          symp_desc: obj.covid_symptoms[i].description,
        });
      }
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clnt_symptom;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.newsymptoms_flag = false;
              this.symptoms_list = [];
              this.symptomlist = [];
              this.symptom_data = [];
              this.symptoms_list = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptom_data.push(obj.symptoms[i].description);
              }

              this.symptomlist = this.symptom_data.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));

            } else {
              this.symptomlist = [];
              this.newsymptoms_flag = true;
              this.symptoms_text = this.clnt_symptom;
            }
          },
          error => {

          })
    }
  }

  select_symptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptoms_list.length; j++) {
      if (this.symptoms_list[j].description == data) {
        this.selected_symptoms.push(data);
        break;
      }
    }

    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(",");
      dataval.pop();
      this.clnt_symptom = dataval.join(",");

      this.clnt_symptom = this.clnt_symptom + "," + data;
    } else {
      this.clnt_symptom = data;
    }
    this.symptomlist = [];
  }
  // canvase image function 

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);

  }

  public loadCss() {
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");
  }

  savesend_data() {
    if ((document.getElementById("theimage")) != null) {
      var img_str = (document.getElementById("theimage") as HTMLImageElement).src;
    }

    this.imagePath = img_str;
    this.sendImge
    var img_path = "";
    if (img_str != null) {
      img_path = (img_str.toString()).split(',')[1];

      this.surgeryNewFlag = false;
      // upd_url = "1";
    }
    else {
      img_path = "";
    }
    this.sendImge = img_path;
    this.surgeryNewFlag = true;
    //upload document
  }

  history() {
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        doc_reg_id: this.userinfo.user_id,
        client_reg_id: this.client_reg_id,
        relation_id: "1",
        sub_rel_id: "",
        History: "walkin",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onContentChanged_chief_comp = (event) => {
    this.chief_comp = event.html;
    this.symptoms_text = event.text;
    var searchString = "";

    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(",");
      searchString = dataval[dataval.length - 1].trim();
      this.chiefComplaintsList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);


            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.newsymptoms_flag = false;

              this.chief_data = [];
              this.chiefComplaintsList = [];
              // this.chiefComplaintsList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.chief_data.push(obj.symptoms[i].description);
              }
              this.chiefComplaintsList = this.chief_data;
              if (this.chiefComplaintsList.length != 0) {
                this.chief_margin_left = event.text.length.toString() + "px";

              }

              // .filter(function (el) {
              //   return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              // }.bind(this));
            } else {
              this.chiefComplaintsList = [];
              this.newsymptoms_flag = true;
              //this.chief_margin_left = event.text.length.toString() +"px";
            }
          },
          error => {

          })
    } else {
      this.chiefComplaintsList = [];
    }
  }

  onContentChanged_pres_ill = (event) => {
    this.pres_ill = event.html;
    this.pres_ill_text = event.text;

    if (event.html == null) {
      this.newpresill = [];
    }

    var searchString = "";
    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(',');
      searchString = dataval[dataval.length - 1].trim();
      this.presentIllenessList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gpresill',
        JSON.stringify({
          presill_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.present_illness != null && obj.present_illness != undefined && obj.present_illness != "" && obj.present_illness.length != 0) {
              this.newpresill_flag = false;
              this.pres_ill_data = [];
              this.presentIllenessList = [];
              for (var i = 0; i < obj.present_illness.length; i++) {
                this.pres_ill_data.push(obj.present_illness[i].presill_desc);
              }
              this.presentIllenessList = this.pres_ill_data;
            } else {
              this.newpresill_flag = true;
              this.presentIllenessList = [];
            }
          },
          error => { })
    } else {
      this.presentIllenessList = [];
    }
  }

  onContentChanged_med_hist = (event) => {
    this.med_hist = event.html;
    this.med_hist_text = event.text;

    if (event.html == null) {
      this.newmedhist = [];
    }

    var searchString = "";
    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(',');
      searchString = dataval[dataval.length - 1].trim();
      this.medicalHistoryList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gmedhis',
        JSON.stringify({
          medhis_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.medical_history != null && obj.medical_history != undefined && obj.medical_history != "" && obj.medical_history.length != 0) {
              this.newmedhist_flag = false;
              this.med_hist_data = [];
              this.medicalHistoryList = [];
              for (var i = 0; i < obj.medical_history.length; i++) {
                this.med_hist_data.push(obj.medical_history[i].med_desc);
              }
              this.medicalHistoryList = this.med_hist_data;
            } else {
              this.newmedhist_flag = true;
              this.medicalHistoryList = [];
            }
          },
          error => { })
    } else {
      this.medicalHistoryList = [];
    }
  }

  onContentChanged_family_hist = (event) => {
    this.family_hist = event.html;
    this.family_hist_text = event.text;
    if (event.html == null) {
      this.newfamhist = [];
    }
    var searchString = "";
    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(',');
      searchString = dataval[dataval.length - 1].trim();
      this.familyHistoryList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gfamhis',
        JSON.stringify({
          famhis_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.family_history != null && obj.family_history != undefined && obj.family_history != "" && obj.family_history.length != 0) {
              this.newfamhist_flag = false;
              this.family_hist_data = [];
              this.familyHistoryList = [];
              for (var i = 0; i < obj.family_history.length; i++) {
                this.family_hist_data.push(obj.family_history[i].famhist_desc);
              }
              this.familyHistoryList = this.family_hist_data;
            } else {
              this.newfamhist_flag = false;
              this.familyHistoryList = [];
            }
          },
          error => { })
    } else {
      this.familyHistoryList = [];
    }
  }

  onContentChanged_physical_exam = (event) => {
    this.physical_exam = event.html;
    this.physical_exam_text = event.text;

    if (event.html == null) {
      this.newphyexamdet = [];
    }

    var searchString = "";
    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(',');
      searchString = dataval[dataval.length - 1].trim();
      this.physicalExamList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gphyexm',
        JSON.stringify({
          phyexm_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.physical_exam != null && obj.physical_exam != undefined && obj.physical_exam != "" && obj.physical_exam.length != 0) {
              this.newphyexamdet_flag = false;
              this.phy_exam_data = [];
              this.physicalExamList = [];
              for (var i = 0; i < obj.physical_exam.length; i++) {
                this.phy_exam_data.push(obj.physical_exam[i].phyexm_desc);
              }

              this.physicalExamList = this.phy_exam_data;
            } else {
              this.newphyexamdet_flag = false;
              this.physicalExamList = [];
            }
          },
          error => { })
    } else {
      this.physicalExamList = [];
    }
  }

  onContentChanged_treatment = (event) => {
    this.treatment = event.html;
    this.treatment_text = event.text;

    if (event.html == null) {
      this.newtreatdet = [];
    }

    var searchString = "";
    if (event.text.indexOf(',') > -1) {
      var dataval = event.text.split(',');
      searchString = dataval[dataval.length - 1].trim();
      this.treatmentList = [];
    } else {
      searchString = event.text;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gtreat',
        JSON.stringify({
          treat_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.present_illness != null && obj.present_illness != undefined && obj.present_illness != "" && obj.present_illness.length != 0) {
              this.newtreatdet_flag = false;
              this.treatment_data = [];
              this.treatmentList = [];
              for (var i = 0; i < obj.present_illness.length; i++) {
                this.treatment_data.push(obj.present_illness[i].treatment_desc);
              }
              this.treatmentList = this.treatment_data;
            } else {
              this.newtreatdet_flag = true;
              this.treatmentList = [];
            }
          },
          error => { })
    } else {
      this.treatmentList = [];
    }
  }

  select_chiefComplaints(data) {
    this.newsymptoms.push(data);
    if (this.chief_comp.indexOf(",") > -1) {
      var dataval = this.chief_comp.split(",");
      dataval.pop();
      this.chief_comp = dataval.join(",");
      this.chief_comp = this.chief_comp + "," + data + "," + "</p>";
    } else {
      this.chief_comp = data + "," + "</p>";
    }
    this.chiefComplaintsList = [];
  }

  select_presentIlleness(data) {
    this.newpresill.push(data);
    if (this.pres_ill.indexOf(",") > -1) {
      var dataval = this.pres_ill.split(",");
      dataval.pop();
      this.pres_ill = dataval.join(",");
      this.pres_ill = this.pres_ill + "," + data + "," + "</p>";
    } else {
      this.pres_ill = data + "," + "</p>";
    }
    this.presentIllenessList = [];
  }

  select_medicalHistory(data) {
    this.newmedhist.push(data);
    if (this.med_hist.indexOf(",") > -1) {
      var dataval = this.med_hist.split(",");
      dataval.pop();
      this.med_hist = dataval.join(",");
      this.med_hist = this.med_hist + "," + data + "," + "</p>";
    } else {
      this.med_hist = data + "," + "</p>";
      this.med_hist_text = data;
    }
    this.medicalHistoryList = [];
  }

  select_familyHistory(data) {
    this.newfamhist.push(data);
    if (this.family_hist.indexOf(",") > -1) {
      var dataval = this.family_hist.split(",");
      dataval.pop();
      this.family_hist = dataval.join(",");
      this.family_hist = this.family_hist + "," + data + "," + "</p>";
    } else {
      this.family_hist = data + "," + "</p>";
    }
    this.familyHistoryList = [];
  }

  select_physicalExam(data) {
    this.newphyexamdet.push(data);
    if (this.physical_exam.indexOf(",") > -1) {
      var dataval = this.physical_exam.split(",");
      dataval.pop();
      this.physical_exam = dataval.join(",");
      this.physical_exam = this.physical_exam + "," + data + "," + "</p>";
    } else {
      this.physical_exam = data + "," + "</p>";
    }
    this.physicalExamList = [];
  }

  select_treatment(data) {
    this.newtreatdet.push(data);
    if (this.treatment.indexOf(",") > -1) {
      var dataval = this.treatment.split(",");
      dataval.pop();
      this.treatment = dataval.join(",");
      this.treatment = this.treatment + "," + data + "," + "</p>";
    } else {
      this.treatment = data + "," + "</p>";
    }
    this.treatmentList = [];
  }

  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  addComma(event) {
    var s = "";
    var l = "";
    var n = "";
    var n1 = "";
    var dataval = [];
    var dataval1 = [];
    if (event.indexOf(" ") > -1) {
      dataval = event.toString().split("</p>");
      s = dataval[0].charAt(dataval[0].length - 1);
      if (s == ",") {
        event = dataval[0] + "</p>";
        event = event.replace(/^\/+|\/+$/g, '');
      } else if (s == ">") {
        if (dataval[0].match("</strong>")) {
          dataval1 = dataval[0].split("</strong>");
          l = dataval1[0].charAt(dataval1[0].length - 1);
          if (l == ',') {
            var re = /\\/gi;
            event = dataval1[0] + "</strong>" + "</p>";
            event = event.replace(re, "");
          }
          else {
            var re = /\\/gi;
            event = dataval1[0] + "," + "</strong>" + "</p>";
            event = event.replace(re, "");
          }
        }
        if (dataval[0].match('</span>')) {
          dataval1 = dataval.toString().split("</span>");
          l = dataval1[0].charAt(dataval1[0].length - 1);
          if (l == ',') {
            event = dataval1[0] + "</span>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          } else {
            event = dataval1[0] + "," + "</span>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          }
        }
      } else {
        event = dataval[0] + "," + "</p>";
        event = event.replace(/^\/+|\/+$/g, '');
      }
    } else {
      var s = "";
      dataval = event.split("</p>");
      s = dataval[0].charAt(dataval[0].length - 1);
      if (s == ",") {
        event = dataval[0] + "</p>";
        event = event.replace(/^\/+|\/+$/g, '');
      } else if (s == ">") {
        if (dataval.indexOf("</strong>") > -1) {
          dataval1 = dataval[0].split("</strong>");
          l = dataval1[0].charAt(dataval1[0].length - 1);
          if (l == ',') {
            event = dataval1[0] + "</strong>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          }
          else {
            event = dataval1[0] + "," + "</strong>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          }
        }
        if (n1 == '</span>') {
          dataval1 = dataval[0].split("</span>");
          l = dataval1[0].charAt(dataval1[0].length - 1);
          if (l == ',') {
            event = dataval1[0] + "</span>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          } else {
            event = dataval1[0] + "," + "</span>" + "</p>";
            event = event.replace(/^\/+|\/+$/g, '');
          }
        }
      } else {
        event = dataval[0] + "," + "</p>";
        event = event.replace(/^\/+|\/+$/g, '');
      }
    }
    return event;
  }

  filterNew(event, newEvent) {
    if (event.trim() != "" && event != undefined && event != null) {
      var new_symptoms = [];
      if (event.indexOf(',') > -1) {
        var dataval1 = event.trim().split(",");
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val))
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (dataval[i].trim() != "" && dataval[i].trim() != undefined && dataval[i].trim() != null) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      } else {
        var dataval1 = event.trim().split(",");
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val))
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (dataval[i].trim() != "" && dataval[i].trim() != undefined && dataval[i].trim() != null) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      }
      return new_symptoms;
    }
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clnt_gender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clnt_gender = "Female";
    else if (e == "5")
      this.clnt_gender = "Transgender";
    else {
      this.clnt_gender = "";
    }
  }

  changeGender(e) {
    if (this.clnt_age != undefined && this.clnt_age != null && this.clnt_age != "") {
      if (e == "Male") {
        if (this.clnt_age <= 15)
          this.clnt_sal = "6";
        else
          this.clnt_sal = "1";
      } else if (e == "Female") {
        if (this.clnt_age <= 15)
          this.clnt_sal = "7";
        else
          this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    } else {
      if (e == "Male") {
        this.clnt_sal = "1";
      } else if (e == "Female") {
        this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    }
  }

  change_type(type, id, value) {
    this.listProducts = [];
    if (type == "app") {
      if (value == "yes") {
        this.ynopt1 = true;
        this.App_type = "New";
        this.appTypeSelect('New');
        this.existUserFlag = true;
      }
      else {
        this.ynopt1 = false;
        this.App_type = "Follow-up";
        this.appTypeSelect('Follow-up');

        this.ynopt2 = false;
        this.Client_type = "Existing";
        this.userTypeSelect('Existing');
        this.existUserFlag = false;
      }
    } else {
      if (value == "yes") {
        this.ynopt2 = true;
        this.Client_type = "New";
        this.userTypeSelect('New');
        this.appclass = false;
        this.showpatflag = true;
      }
      else {
        this.ynopt2 = false;
        this.Client_type = "Existing";
        this.userTypeSelect('Existing');
        this.appclass = true;
        this.showpatflag = false;
      }
    }

  }

  //Get medicine type
  Get_medicine_type(e) {
    this.medtypetxt = e.target.value.toString();
    this.Medical_prescription_array = [];
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        {
          medicare_id: this.Medicare_name,
          medicine_name: this.medtypetxt
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var medtypetxt1 = [];
            this.Medical_prescription_array = obj.med_details;
          },
          error => {
          }
        )
    }
  }

  Medcine_type_change(l) {
    for (var i = 0; i < this.Medical_prescription_array.length; i++) {
      if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
        this.drug_typetxt = this.Medical_prescription_array[i].med_code;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/meddetails/',
      {
        drug_type: this.drug_typetxt,
        drug_category: this.user_id,
        drug_name: this.medicine_nametxt,
        pharmacy_id: this.pharma_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("medinice_data" + JSON.stringify(obj))

          if (obj.med_details != null) {
            this.Medicine_list = obj.med_details;
        
            

            for (var i = 0; i < obj.med_details.length; i++) {
              this.Medicne_data.push(obj.med_details[i].med_name);
              this.Medicne_data.push(obj.med_details[i].quantity);
            }


            this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
            }.bind(this));
            this.Med_addbtn = true;
          }
        },
        error => { })
  }

  select_medicine_item(item) {
    for (var i = 0; i < this.Medicine_list.length; i++) {
      if (this.Medicine_list[i].medname == item.name) {
        this.medicine_nametxt = this.Medicine_list[i].med_name;
        this.drug_typetxt = this.Medicine_list[i].med_type_id;
        this.medtypetxt = this.Medicine_list[i].type_name;
        this.ganericName = item.generic_name;
        this.short_form = this.Medicine_list[i].short_form;
        this.show = this.Medicine_list[i].show_short_form;
        this.show_intake = this.Medicine_list[i].show_intake;
        this.med_id = this.Medicine_list[i].med_id;
        this.short_name = this.Medicine_list[i].short_name;
        if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
          this.mix_show = false;
        } else {
          this.mix_show = true;
        }
      }
    }
    this.getIntakeSession();
    this.Medicinenamelist = [];
  }
  getIntakeSession() {
    var send_data = {
      product_id: this.med_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          for (var i = 0; i < this.Get_Intake_array.length; i++) {
            if (obj.intake == this.Get_Intake_array[i].drug_int_id) {
              this.intake_txt = this.Get_Intake_array[i].description
              this.intake_id = this.Get_Intake_array[i].drug_int_id;
            }
          }
          this.sesssion_basechange();

        })
  }
  //Get intake session
  Get_Intake_session() {
    var obj = masterCSData_Helper.getMasterIntakeSession();
    if (obj != undefined)
      this.Get_Intake_array = obj.drug_intake;
    this.intake_txt = "Thrice a day";
  }

  
  Medicalpres_add() {
    if (this.ganericName != undefined) {
      this.ganericName = true;
    }
    console.log("Medical_prescription_array" + JSON.stringify(this.listProducts))
    console.log("Medical_prescription_array" + JSON.stringify(this.med_id))

    console.log("Medical_prescription_array" + JSON.stringify(this.Medical_prescription_array))
    var flag = false;
   
      if (this.intake_txt != "Stat" && this.intake_txt != "SOS" && (this.day_txt == undefined || this.day_txt == "" || this.day_txt == " ")) {
        this.toastr.error(Message_data.enterNoOfDays);
        flag = true;
      

    }
    this.found = this.listProducts.some(item => item.drug_id === this.med_id && item.intake === this.intake_id);

    if(this.new_med == true){
      if (this.medtypetxt == undefined || this.medtypetxt == "" || this.medtypetxt == " ") {
        this.toastr.error(Message_data.enterMedType);
        flag = true;
        this.new_med = true;
        this.newmed_flag = false;
      }
    }
   
    if (this.medicine_nametxt == undefined || this.medicine_nametxt == "") {
      this.toastr.error(Message_data.enterMedName);
      flag = true;
  
    } else if (this.intake_txt == undefined) {
      this.toastr.error(Message_data.enterIntkSess);
      flag = true;
    }
    if (this.found == true) {
      this.toastr.error("Already prescriped medicine");
      flag = true;
    }

    if (this.show_intake == "1") {
      if (this.intake_txt != undefined && this.intake_txt == "Morning only" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening only" && (this.eve == undefined || this.eve == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Night only" && (this.ngt == undefined || this.ngt == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Afternoon" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Evening" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon,Evening & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon,Evening & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Thrice a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == "" ))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Four times a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
        || this.intake_txt == "Every 12 hours" || this.intake_txt == "Once a day" || this.intake_txt == "Stat")
        && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt == "Stat" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
        || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") && (this.mor == "0" && this.aft == "0" && this.eve == "0" && this.ngt == "0")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      }
    }


    if (flag == false) {
      this.saveflag = true;
    //  this.printFlag = true;
      this.print_view_flag = false;

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.medtypetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      for (var i = 0; i < this.Get_Intake_array.length; i++) {
        if (this.intake_txt == this.Get_Intake_array[i].description) {
          this.intake_id = this.Get_Intake_array[i].drug_int_id;
        }
      }

      this.Medicine_table = false;
      if (this.med_id == null) {
        this.med_id = "0";
      }

      var aftfood;
      if (this.show == "1") {
      
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.every_six = this.intake_txt;
        } else {
         
          var morning = " ", afternoon = " ", evening = " ", night = " ";
          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
          
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
           
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
             
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
             
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }
         

          } else if (this.intake_txt == "Once a week") {
        
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
          
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
          
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Alternative day") {
            
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once a day") {
            
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
         
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            // this.every_six = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt + " - per month";

            // this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          }

          else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        console.log("seeeeeeeeedure_txteeeeeee" + this.dure_txt)

     
        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        if (this.afterfood_txt == undefined) {
          this.afterfood_txt = "";
        }

        var frequency;

        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              frequency = "Daily";
            }
          }
        }

        if (this.intake_txt == "Stat") {
          period = "";
          this.day_txt = "";
        } else {
          var period
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";

          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";

          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";

          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";

          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";

          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            period = "";
          }
        }

        if (this.intake_txt != "Stat" && (this.afterfood_txt != undefined && this.afterfood_txt != 'Select')) {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }

        this.listProducts.push({
          med_typetxt: this.medtypetxt,
          drug_name: this.medicine_nametxt,
          genericname: this.ganericName,
          days: this.day_txt,
          period: period,
          drug_intake: aftfood,
          intake: this.intake_id,
          every_six: this.every_six,
          dure_txt_table: this.dure_write,
          time_duration: this.dure_txt,
          morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
          afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
          evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
          night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
          morning: this.mor,
          afternoon: this.aft,
          evening: this.eve,
          night: this.ngt,
          drug_id: this.med_id,
          drug_type_id: this.drug_typetxt,
          remarks: this.remark_txt,
          frequency: frequency,
          show_intake: this.show_intake,
          rowid: this.rowid,
          short_name: this.short_name,
          mixval: this.mixing_txt, param_four: this.param_four
        });
      } else {
       
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.every_six = this.intake_txt;
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";

      
          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
        
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }
        

          } else if (this.intake_txt == "Once a week") {
          
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
         
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
          
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Alternative day") {
            
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Once a day") {
           
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
         
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            //  this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          }

          else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else {
           
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

      
          } else {
            console.log("else **" + this.intake_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        // if (this.dure_txt != undefined && this.dure_txt != "") {
        //   this.dure_write = this.dure_txt + " " + "mins";
        // } else {
        //   this.dure_write = "";
        //   this.dure_flag = true;
        // }

        var frequency;
      
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              frequency = "Daily";
            }
          }
        }

        var period
        if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
          period = "day";
        } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
          period = "days";
        } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
          period = "week";
        } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
          period = "weeks";
        } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
          period = "month";
        } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
          period = "months";
        } else {
          period = "";
        }

        if (this.afterfood_txt != undefined && this.afterfood_txt != 'Select') {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }
        this.listProducts.push({
          med_typetxt: this.medtypetxt,
          drug_name: this.medicine_nametxt,
          genericname: this.ganericName,
          days: this.day_txt,
          period: period,
          drug_intake: aftfood,
          intake: this.intake_id,
          every_six: this.every_six,
          dure_txt_table: this.dure_write,
          time_duration: this.dure_txt,
          morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
          afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
          evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
          night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
          morning: this.mor,
          afternoon: this.aft,
          evening: this.eve,
          night: this.ngt,
          drug_id: this.med_id,
          drug_type_id: this.drug_typetxt,
          frequency: frequency,
          remarks: this.remark_txt,
          show_intake: this.show_intake,
          rowid: this.rowid,
        
          short_name: this.short_name,
          param_four: this.param_four
        });
      }
      console.log("list product::::::" + JSON.stringify(this.listProducts))
      //Resets the form 
      this.med_typetxt = "";
      this.medicine_nametxt = "";
      this.short_name = "";
      this.short_form = "";
      this.newmed_flag = true;
      this.new_med = false;
      this.print_med = true;
      this.mixing_txt = "";
      this.ganericName = "";
      this.day_txt = ""
      // this.dure_txt=""
      //}
      this.medicineaddbtn = false; // Add button
      console.log("MED LIST " + JSON.stringify(this.listProducts))
    }

    var flag: boolean;
    

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }
    if (flag == true && this.listProducts.length != 0) {
      this.print_medidiag = true;
    } else {
      this.print_medidiag = false;
    }
    

  }

  Delete_medicalpres(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.Medicine_table = false;
      this.print_medidiag = true;
    }
    else {
      this.Medicine_table = true;
      this.print_medidiag = false;
    }
  }

  //Get prefer pharmacy
  Prefer_pharmacy_change(e) {
    this.Pharmanamelist = [];
    this.Pharma_data = [];
    this.prfer_pharmatxt = e.target.value.toString();
    if (this.prfer_pharmatxt != undefined && this.prfer_pharmatxt.length >= 3) {
      this.prefer_add = "";

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharmabyname/',
        {
          location: this.doct_location,
          pharma_name: this.prfer_pharmatxt,
          drug_ids: this.drug_id_list,
          flag: "prescription",
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj.pharma_list != null) {
              this.Pharma_list_arry = obj.pharma_list;
              for (var i = 0; i < obj.pharma_list.length; i++) {
                this.Pharma_data.push(obj.pharma_list[i].pharmacy_name);
                this.pharma_id = obj.pharma_list[i].pharma_id;
              }

              Doc_Helper.setpharmaid(this.pharma_id)
              this.Pharmanamelist = this.Pharma_data.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.prfer_pharmatxt.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.toastr.error(Message_data.noPharmaFound);
            }
          },
          error => { }
        )
    }
  }

  Pharma_medicine_name(item) {
    this.prfer_pharmatxt = item;

    if (item == this.Pharmanamelist) {
      for (var i = 0; i < this.Pharma_list_arry.length; i++) {
        this.pharma_id = this.Pharma_list_arry[i].pharma_id;
        if (this.Pharma_list_arry[i].telephone != undefined && this.Pharma_list_arry[i].telephone != 'undefined') {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country + " , " + this.Pharma_list_arry[i].telephone;
        } else {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country;
        }
      }
      this.Pharmanamelist = [];
    }
  }

  sesssion_basechange() {

    if (this.show_intake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }
    console.log("sesion bmbm" + this.intake_txt + this.show_intake + this.drug_typetxt)

    if (this.intake_txt == "Morning only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

     
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }

      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.afternoon_dis = false;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

    
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Evening only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
    
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Night only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.afternoon_dis = true;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Afternoon" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
     
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

    
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

    
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
    
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning,Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Thrice a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

     
      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Four times a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
    
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
     
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if ((this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
      || this.intake_txt == "Every 12 hours") && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Stat") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
       
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;
      this.intake_show = true;
      this.dure_show = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = true;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.morn_eventxt = false;
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = " ";
      this.afterfood_txt = ""

    } else if (this.intake_txt == "Once a day") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;
      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "SOS") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
     
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }

      } else {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      }
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = " ";
      this.afterfood_txt = ""
      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
    
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
      
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoon_dis = false;
      this.morning_dis = false;
      this.mor_id = false;
      this.mor_lable = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.even_dis = false;
      this.ngt_dis = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.evening_req = true;
      this.afternoon_req = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }
    if(this.intake_txt != "Stat"){
      this.daydur_txt = "day(s)"
      this.afterfood_txt = "After food";
      this.dure_txt = "30";
    }
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_txt == this.Get_Intake_array[i].description) {
        // this.intake_txt = this.Get_Intake_array[i].description
        this.intake_id = this.Get_Intake_array[i].drug_int_id;
        break;
      }
    }
    this.intake_change();
  }

  //Intake change
  intake_change = function (this: any) {
    if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
      this.dure_show = false;
      this.dure_require = true;
      this.dure_txt = "30";
    } else {
      this.dure_show = true;
      this.dure_require = false;
      this.dure_txt = undefined;
    }
  }

  Medical_prescription_save(pdffilevalue) {
    var flag = true;
    this.dis_txt = this.clnt_symptom;
    if (Doc_Helper.getDiagAppflow() == "medical") {
      if (this.dis_txt == "") {
        this.toastr.error(Message_data.enterDisease);
        flag = false;
      }
      if (this.listProducts.length == 0) {
        this.toastr.error(Message_data.addMedDetails);
        flag = false;
      }
    }

    var ordernow = this.order_now == true ? "1" : 0;
    if (this.dis_txt.trim() != "") {
      var new_symptoms = [];
      if (this.dis_txt.indexOf(',') > -1) {
        var dataval = this.dis_txt.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newsymptoms.length != 0) {
            for (var j = 0; j < this.newsymptoms.length; j++) {
              if (dataval[i] != "") {
                if (this.newsymptoms[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          } else {
            if (dataval[i] != "") {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        }
      } else {
        if (this.newsymptoms.length != 0) {
          for (var j = 0; j < this.newsymptoms.length; j++) {
            if (this.newsymptoms[j] != this.dis_txt.trim()) {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        } else {
          new_symptoms.push(this.dis_txt.trim());
        }
      }
    } else {
      this.dis_txt = "";
    }

    if (flag == true) {
      var nomed;
      if (this.no_med == true) {
        nomed = "1";
      } else {
        nomed = "0";
      }

      var sen_pass;
      if (pdffilevalue != null) {
        sen_pass = JSON.stringify({
          client_reg_id: this.client_reg_id,
          relation_id: "1",
          appointment_id: this.app_id,
          date: this.CurrentDatetime,
          disease: this.dis_txt,
          comment: this.oberse_med,
          investigation: this.oberse_med,
          instructions: this.instructions,
          next_visit: this.next_datetxt,
          medicines: this.listProducts,
          pharmacy_id: this.pharma_id,
          order_now: ordernow,
          language: this.language,
          pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printpres,
          newsymptoms: new_symptoms,
          mobile: this.clnt_cont_number,
          doc_reg_id: this.user_id,
          no_med: nomed,
          country:ipaddress.country_code,
          req_estimate:this.estimate_required,
        });

      } else {
        sen_pass = JSON.stringify({
          client_reg_id: this.client_reg_id,
          relation_id: "1",
          appointment_id: this.app_id,
          date: this.CurrentDatetime,
          disease: this.dis_txt,
          comment: this.oberse_med,
          investigation: this.oberse_med,
          instructions: this.instructions,
          next_visit: this.next_datetxt,
          medicines: this.listProducts,
          pharmacy_id: this.pharma_id,
          order_now: ordernow,
          language: this.language,
          // pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printpres,
          newsymptoms: new_symptoms,
          mobile: this.clnt_cont_number,
          doc_reg_id: this.user_id,
          no_med: nomed,
          country:ipaddress.country_code,
          req_estimate:this.estimate_required,
        });
      }
      console.log("save prescription"+JSON.stringify(sen_pass))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            Doc_Helper.setMedForDischarge(this.listProducts);
            this.print_med = true;
            var flag_diag = true;
            if (this.print == true) {
              this.print_area();
            }
            if (this.viewCaseSheet == true) {
              this.check_specialization();
            } else {
              this.change_type('client', 1, 'no');
            }
          },
          error => {
          }
        )
    }
  }

  Get_pharmacy_address() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
      {
        hptl_clinic_id: this.hospital_id,
        type: "pharma"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          this.prfer_pharmatxt = obj.hptl_name;
          if (obj.address1 != undefined) {
            this.prefer_add = obj.address1 + ", " + obj.location + ", " + obj.city + " - " + obj.zipcode + ", " + obj.state + ", " + obj.country;
          }
        },
        error => { });
  }

  fillType(e) {
    this.fill_type = e;
    var send_data;
    var sub_rel_id = null;


    var spl = Doc_Helper.getClient_Info().spl;

    if (this.fill_type == "refill") {
      send_data = {
        doc_reg_id: this.user_id,
        spl_id: spl,
        client_age: this.clnt_age,
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_id
      }

      const dialogRef1 = this.dialog.open(MedPresPopupPageComponent, {
        width: '700px',
      });
      dialogRef1.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
          }
        }
      });
    } else if (this.fill_type == "autoRx") {
      this.getMedicineDetails();
    } else if (this.fill_type == "previous") {
      send_data = {
        client_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_id,
        spl_name: "General",
        doc_reg_id: this.user_id
      }
      const dialogRef2 = this.dialog.open(PreviousPrescriptionPopupComponent, {
        width: '900px',
      });

      dialogRef2.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
          }
        }
        this.Medicine_table = false;
      });
    }
  }

  getMedicineDetails() {
    var agerange;
    if (this.clnt_age == "<1") {
      agerange = "<1";
    } else if (this.clnt_age == "1" || this.clnt_age == "2" || this.clnt_age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.clnt_age == "4" || this.clnt_age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.clnt_age == "6" || this.clnt_age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.clnt_age == "8" || this.clnt_age == "9" || this.clnt_age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.clnt_age == "11" || this.clnt_age == "12" || this.clnt_age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.clnt_age == "14" || this.clnt_age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.clnt_age == "16" || this.clnt_age == "17" || this.clnt_age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.clnt_age == "19" || this.clnt_age == "20" || this.clnt_age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

    var temp;
    if (Helper_Class.getTemparature() != undefined || Helper_Class.getTemparature() != null) {
      temp = Helper_Class.getTemparature();
    }
    var bp;
    if (this.BP_01 != null && this.BP_01 != undefined && this.BP_01 != "") {
      bp = this.BP_01 + "/" + this.BP_02;
    }
    var bp;

    var cgender;
    if (this.clnt_age != undefined && parseInt(this.clnt_age) > 10) {
      if (this.clnt_gender != undefined) {
        cgender = this.clnt_gender;
      }
    }

    this.clnt_symptom = Helper_Class.getsymptoms();
    if (this.clnt_symptom != undefined) {
      var sym = this.clnt_symptom;
    }
    if (temp != undefined) {
      var temp_value = temp
    }

    var genflag = true;
    if (cgender != undefined && (encrypt_decript.Decript(cgender) == "Male" && this.spl == "Gynecology")) {
      genflag = false;
    } else {
      genflag = true
    }

    var gender_val = encrypt_decript.Encript(cgender).toString()

    //  /  if(this.cgender !=null)
    if (genflag == true) {
      var send_data = {
        relation_id: "1",
        spl_name: this.spl,
        agerange: agerange,
        gender: gender_val,
        symptoms: this.clnt_symptom,
        bp: bp,
        temparature: temp,
        diagnosis: this.dis_txt,
        clntage: this.clnt_age,
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/gmpbycasv/', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.listProducts = [];
            if (obj != undefined && obj.pres_drug_id != undefined) {
              this.dis_txt = obj.disease;
              if (obj.drug_list.length != 0) {
                console.log("obj.drug_list check 00---->" + JSON.stringify(obj.drug_list))
                for (var i = 0; i < obj.drug_list.length; i++) {
                  if (obj.drug_list[i].show_short_form == "1") {
                    var shortform = obj.drug_list[i].short_form;
                    this.short_form= obj.drug_list[i].short_form;
                    var morning = " ", afternoon = " ", evening = " ", night = " "
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      // this.every_six =  obj.drug_list[i].morning + shortform  + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  + " - 0";
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + shortform;

                      // this.every_six = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;

                    } else if (obj.drug_list[i].intake == "4") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "5") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      this.param_four = false;

                    } else if (obj.drug_list[i].intake == "6") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "9") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 6 hours";

                    } else if (obj.drug_list[i].intake == "10") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 8 hours";

                    } else if (obj.drug_list[i].intake == "11") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " - Every 12 hours ";

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning + shortform + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning + shortform + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "15") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + shortform + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = true;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }

                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    var frequency;
                    if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" ||obj.drug_list[i].intake == "Twice a week" || obj.drug_list[i].intake == "Once a month") {
                      frequency = this.intake_txt;
                    } else {
                      if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                        frequency = "";
                      } if (obj.drug_list[i].intake == "Stat" || obj.drug_list[i].intake == "SOS") {
                        frequency = " ";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      drug_name: obj.drug_list[i].drug_name,
                      // //genericname: this.ganericName,
                      genericname: genericname,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      // morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
                      // afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
                      // evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
                      // night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
                      morning: obj.drug_list[i].morning ,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                      // morning: obj.drug_list[i].morning,
                      // afternoon: obj.drug_list[i].afternoon,
                      // evening: obj.drug_list[i].evening,
                      // night: obj.drug_list[i].night,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      short_name: obj.drug_list[i].short_name,
                      mixval: obj.drug_list[i].mixtype,
                      param_four: this.param_four
                    }


                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                  } else {
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+" - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon=obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "4") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning =obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "5") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "6") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening + " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "9") {
                      this.every_six = obj.drug_list[i].morning + " - Every 6 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "10") {
                      this.every_six = obj.drug_list[i].morning + " - Every 8 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "11") {
                      this.every_six = obj.drug_list[i].morning + " - Every 12 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+ " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon=obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+ " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                        this.param_four = false;
                    } else if (obj.drug_list[i].intake == "15") {
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning + " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                        this.param_four = false;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " Once a month";
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                                        }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if(obj.drug_list[i].morning !== "0"){
                        morning=obj.drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.drug_list[i].morning+ " - ";
                      }
                      if(obj.drug_list[i].afternoon !== "0"){
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }else{
                        afternoon =obj.drug_list[i].afternoon+ " - ";
                      }
                      if(obj.drug_list[i].evening !== "0"){
                        evening=obj.drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.drug_list[i].evening+ " - ";
                      }
                      if(obj.drug_list[i].night !== "0"){
                        night=obj.drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                      }


                    else {
                      if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                        if(obj.drug_list[i].morning !== "0"){
                          morning=obj.drug_list[i].morning + " - ";
                        }else{
                          morning =obj.drug_list[i].morning+ " - ";
                        }
                        if(obj.drug_list[i].afternoon !== "0"){
                          afternoon=obj.drug_list[i].afternoon + " - ";
                        }else{
                          afternoon =obj.drug_list[i].afternoon+ " - ";
                        }
                        if(obj.drug_list[i].evening !== "0"){
                          evening=obj.drug_list[i].evening + " - ";
                        }else{
                          evening =obj.drug_list[i].evening+ " - ";
                        }
                        if(obj.drug_list[i].night !== "0"){
                          night=obj.drug_list[i].night ;
                        }else{
                          night =obj.drug_list[i].night;
                        }
                        
                        this.every_six = morning+afternoon+evening+night;  
                      } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                        if(obj.drug_list[i].morning !== "0"){
                          morning=obj.drug_list[i].morning + " - ";
                        }else{
                          morning =obj.drug_list[i].morning+ " - ";
                        }
                        if(obj.drug_list[i].afternoon !== "0"){
                          afternoon=obj.drug_list[i].afternoon+ " - ";
                        }else{
                          afternoon =obj.drug_list[i].afternoon+ " - ";
                        }
                        if(obj.drug_list[i].evening !== "0"){
                          evening=obj.drug_list[i].evening + " - ";
                        }else{
                          evening =obj.drug_list[i].evening+ " - ";
                        }
                        if(obj.drug_list[i].night !== "0"){
                          night=obj.drug_list[i].night_eventxt ;
                        }else{
                          night =obj.drug_list[i].night;
                        }
                        
                        this.every_six = morning+afternoon+evening+night;  
                       }
                      this.param_four = false;
                    }

                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }
                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var frequency;
                    frequency = obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" ? this.intake_txt : "Daily";
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      genericname: genericname,
                      drug_name: obj.drug_list[i].drug_name,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning ,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                      // night: obj.drug_list[i].night,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      remarks: obj.drug_list[i].remarks,
                      short_name: obj.drug_list[i].short_name,
                      param_four: this.param_four
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                    console.log("cj check 00---->" + JSON.stringify(this.listProducts))
                  }
                }
                this.Medicine_table = false;
              }
            }
          });
    }
  }

  previousprescriptionMethod(data) {
    if (data != null) {
      this.listProducts = data;
      this.Medicine_table = false;
      this.drug_id_list = [];
      for (var i = 0; i < this.listProducts.length; i++) {
        this.drug_id_list.push(this.listProducts[i].drug_id);
      }
    }
  }

  select_medicine_type(item) {
    this.medtypetxt = item.med_name;
    this.drug_typetxt = item.med_code;
    this.short_form = item.short_form;
    this.show = item.show_short_form;
    this.show_intake = item.show_intake;
    this.Medical_prescription_array = [];

    this.sesssion_basechange();
  }

  clearmedtype() {
    this.medtypetxt = null;
    this.drug_typetxt = undefined;
    this.medicine_nametxt = null;
    this.Medicinenamelist = [];
  }

  Duration() {
    var duration = this.day_txt + " " + this.daydur_txt;
    return duration
  }

  Back() {
    this.addmed = false;
  }
  editMedicalPres(productid, rowid) {
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_id == this.Get_Intake_array[i].drug_int_id) {
        this.intake_txt = this.Get_Intake_array[i].description;
        this.sesssion_basechange();
      }
    }

    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].drug_id == productid && this.listProducts[i].rowid == rowid) {
        this.rowid = rowid;
        this.medtypetxt = this.listProducts[i].med_typetxt;
        this.medicine_nametxt = this.listProducts[i].drug_name;
        var dayvale = this.listProducts[i].days.split(' ');
        this.day_txt = dayvale[0];

        if (this.listProducts[i].period == "day" || this.listProducts[i].period == "days") {
          this.daydur_txt = "day(s)";

        } else if (this.listProducts[i].period == "week" || this.listProducts[i].period == "weeks") {
          this.daydur_txt = "week(s)";

        } else if (this.listProducts[i].period == "month" || this.listProducts[i].period == "month") {
          this.daydur_txt = "month(s)";

        } else {
          this.daydur_txt = "day(s)";
        }

        this.afterfood_txt = this.listProducts[i].drug_intake;
        var mintxt = this.listProducts[i].dure_txt_table.split(' ');
        this.dure_txt = mintxt[0];

        this.intake_id = this.listProducts[i].intake;
        this.remark_txt = this.listProducts[i].remarks;

        if (this.intake_id == 8) {
          this.quan_lable = false;
          this.mor_lable = true;

        } else {
          this.quan_lable = true;
          this.mor_lable = false;
        }

        this.mor = this.listProducts[i].morning;
        this.aft = this.listProducts[i].afternoon;
        this.eve = this.listProducts[i].evening;
        this.ngt = this.listProducts[i].night;
        this.Med_addbtn = true;

        if (this.listProducts[i].show_intake != undefined) {
          this.show_intake = this.listProducts[i].show_intake;
        }

        if (this.listProducts[i].short_form != undefined) {
          this.short_form = this.listProducts[i].short_form;
        }

        this.listProducts.splice(i, 1);
        this.getMedtypeForEdit();

        break;
      }
    }

    if (this.listProducts.length != 0) {
      this.Medicine_table = false;

    } else {
      this.Medicine_table = true;
    }

  }

  //Get medicine type
  getMedtypeForEdit() {
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypesedit/',
        {
          medicare_id: this.Medicare_name,
          med_name: this.medtypetxt,
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            this.Medical_prescription_array = [];
            this.drug_typetxt = obj.med_code;
            this.short_form = obj.short_form;
            this.show = obj.show_short_form;
            this.show_intake = obj.show_intake;
            this.intake_change();
          },
          error => {

          }
        )
    }
  }

  printlogowithnamepdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [60, 600],
            body: [
              [
                {
                  rowspan: 4,
                  image: this.imagestring,
                  width: 50,
                  height: 50,
                },
                [
                  {
                    table: {
                      widths: [310, 250],
                      style: 'personaltable',
                      body: [
                        [this.clientDetails.dct_hospital, 'Doctor: Dr.' + this.doctorname],
                        [this.doct_address + " " + this.doct_location, 'Date: ' + Date_Formate(this.CurrentDatetime)],
                        [this.doct_city, ' '],
                        [this.doct_state, ' '],
                      ]
                    },
                    layout: 'noBorders'
                  }
                ],
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.patient_name, 'BP: ' + this.bp],
              ['', ''],
              ['Age: ' + this.clnt_age + '     Gender: ' + this.clnt_gender, ' Pulse: ' + this.pulse_txt],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorname + " " + Helper_Class.getQualification(),
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.userinfo.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);

    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  printnoheaderdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          margin: [0, 150, 0, 0],
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.patient_name, 'BP: ' + this.bp],
              ['', ''],
              ['Age: ' + this.clnt_age + '     Gender: ' + this.clnt_gender, ' Pulse: ' + this.pulse_txt],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorname + " " + Helper_Class.getQualification(),
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.userinfo.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  print_area() {
    var flag = true;


    if (this.listProducts.length != 0) {
      this.print_med = true;
    }

    if (flag == true) {
      if (Helper_Class.get_med_pres_id() == undefined) {
        if (this.print_view_flag == true) {
          this.savePrescription("print");
        }
      }
      var footerimg = this.hosp_footer;
      var footerflag = this.printf_flag;
      var backimg = this.bg_image;
      let printContents, popupWin;
      if (this.print_template != undefined && this.print_template == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
        <head>
          <title>Prescription</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          @media all {
            .page-break { display: none; }
            }
            
            @media print {
            .page-break { display: block; page-break-before: always; }
            }
          @media print {
            body{
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              -ms-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            footer {
              position: fixed;
              bottom: 0;
            }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            .block1_grid{
              margin: 7px auto;
              width: 90%;
            }
            .block1{
              margin:3px 0;
            }
            .test-names-container {
              display: flex;
              flex-wrap: wrap;
            }
            .rowformat{
              display: flex;
              flex-wrap: wrap;
            }
            .test-name {
              margin-right: 10px; /* Adjust spacing between test names */
              white-space: nowrap; /* Prevent wrapping before reaching the width limit */
            }
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        <footer [hidden]=${footerflag}>
        <img src=${footerimg}>
      </footer>
      </html>`
      );
      popupWin.document.close();
      printContents = "";
      if (this.print_view_flag == false) {
        this.next_txt = "";
        this.instructions = "";
        this.print_instruction = "";
        this.oberse_med = "";
        this.disease_name = "";
        this.disease_flag = false;

      } else {
        this.next_txt = "";
        this.instructions = "";
        this.oberse_med = "";
        this.print_instruction = "";

      }
      this.print_view_flag = true;
      this.listProducts = [];
    }
  }

  savePrescription(data) {
    if (data == "print") {
      this.Medical_prescription_save(null);
      this.printpres = "1";
    } else {
      this.printpres = "0";
      if (this.print_template != undefined && this.print_template == "banner") {
        this.bannerTemplateToPdf();

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        this.printlogowithnamepdf();

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        //printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        this.printnoheaderdf();
      }
    }
  }

  languageChange() {
    if(this.language != "English"){
      this.printLanguageflag = true;
    }else{
      this.printLanguageflag = false;
    }
    if (this.language == "English") {
      this.translate.use('english');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Tamil") {
      this.translate.use('tamil');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("15ml தண்ணீருடன்")
      this.mixtype.push("100ml தண்ணீருடன்")
      this.mixtype.push("தயிருடன்")

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    }
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push(columns);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column]);
      })
      body.push(dataRow);
    });
    return body;
  }

  medicinetable(data, columns) {
    return {
      table: {
        widths: [140, 60, 50, 120, 100],
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  bannerTemplateToPdf() {
    //margin:[left, top, right, bottom]
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        // {
        //   image: this.imagestring,
        //   width: 200,
        //   height: 200,
        //   margin: [-30, -20, 0, 20],
        //   layout: 'noBorders'
        // },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.patient_name, 'Date: ' + Date_Formate(this.CurrentDatetime)],
              ['', ''],
              ['Age: ' + this.clnt_age + '     Gender: ' + this.clnt_gender, 'BP: ' + this.bp + '     Pulse: ' + this.pulse_txt],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'

        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorname + " " + Helper_Class.getQualification(),
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.userinfo.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  OnDateMatePicker(e) {
    this.next_datetxt = e;
    this.next_txt = Date_Formate(e);
    //this.get_doc_apps(this.user_id);
  }

  appGetdate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.current_date = Date_Formate(obj.current_date);
      this.current_time = obj.current_time;
      this.CurrentDatetime = obj.current_date;
      this.currentDate = obj.current_date
      this.printtime = Time_Formate(this.current_time)
    }
  }

  app_Date_Change(Appointment_Date) {

    if (this.current_date != Appointment_Date) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = Appointment_Date.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      //var get_sel_day_num = this.gservice.get_Day_num(dayName);

      var Available_flag_1 = false;
    }

  }

  //Get medicine name
  Medicine_name_change(e, type) {
    if (this.new_med != true) {
      if (e.target.value.toString() != null && e.target.value.length.toString() > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/',
          {
            drug_category: this.user_id,
            drug_name: e.target.value.toString(),
            type: type
            // pharmacy_id: this.pharma_id,
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              console.log("obj" + JSON.stringify(obj))
              if (obj.med_details != null) {
                this.Medicinenamelist = [];
                this.Medicine_list = [];
                this.Medicne_data = [];

                for (var i = 0; i < obj.med_details.length; i++) {
                  console.log("med change details = " + obj.med_details[i])
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name;

                  this.Medicne_data.push({
                    name: medname,
                    generic: obj.med_details[i].generic_name,
                    med_id: obj.med_details[i].med_id,
                    quantity: obj.med_details[i].quantity
                  });

                  this.Medicine_list.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname
                  })
                }

                this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
                  return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
                }.bind(this));

                this.Med_addbtn = true;
              }
            },
            error => {

            }
          )
      } else {
        this.Medicinenamelist = [];
      }

    } else {
      if (this.medtypetxt == "" || this.medtypetxt == undefined) {
        this.toastr.error(Message_data.enterMedType);
      }
      this.med_id = "0";
      this.Med_addbtn = true;
    }
  }

  showMedType(new_med) {
    this.medtypetxt = "";
    this.new_med = new_med

    if (this.new_med == true) {
      this.newmed_flag = false;

    } else {
      this.newmed_flag = true;
    }
  }

  getBase64Image(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    },
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          this.imagestring = "data:image/jpeg;base64," + data.imagestr;
        });
  }
  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.instructions = event.html;
    if (this.instructions != undefined) {
      this.print_instruction = this.sanitizer.bypassSecurityTrustHtml(this.instructions);
    }

  }
  getmedicineonit() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gmd',
      {
        hptl_clinic_id: this.hospital_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log(" object = " + JSON.stringify(obj))
          var list = obj.drug_details;
          for (var i = 0; i < list.length; i++) {
            if (list[i].med_type_id != undefined) {
              this.mediceList.push({
                med_id: list[i].med_id,
                type_name: list[i].type_name,
                med_name: list[i].med_name,
                quantity: list[i].quantity,
                med_type_id: list[i].med_type_id,
                show_intake: list[i].show_intake,
                // short_form: list[i].show_short_form,
                short_form: list[i].short_form,
                show_short_form: list[i].show_short_form,
                genericname: list[i].generic_name,
              });
            }
          }
        },
        error => { });
  }

  getmedicine() {
    this.medicineFlag = !this.medicineFlag;
  }

  
 
  setMedicine(id) {
    this.medicineFlag = false;
    console.log("mediceList" + JSON.stringify(this.mediceList))
    for (var i = 0; i < this.mediceList.length; i++) {
      if (this.mediceList[i].med_id == id) {
        this.medicine_nametxt = this.mediceList[i].med_name;
        this.drug_typetxt = this.mediceList[i].med_type_id;
        this.medtypetxt = this.mediceList[i].type_name;
        this.show_intake = this.mediceList[i].show_intake;
        this.short_name = this.mediceList[i].type_name;
        this.med_id = this.mediceList[i].med_id;

        this.short_form = this.mediceList[i].short_form;
        this.show = this.mediceList[i].show_short_form;
        this.ganericName = this.mediceList[i].genericname;
        if (this.ganericName != undefined) {
          if (Helper_Class.getInfo().both_modules.length != 0) {
            var Moduleidlist
            Moduleidlist = Helper_Class.getInfo().both_modules;
            for (var i = 0; i < Moduleidlist.length; i++) {
              if (Moduleidlist[i].module_id == "34") {
               // this.medicalprescription = false;
                this.genericDisplayFlag = true;
              }
            }
          }

          this.ganericName = this.ganericName
        }
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        //this.getIntakeSession();
        break;
      }
    }
  }
  
  public viewCaseSheet: boolean = false;
  changeViewCaseSheet(e) {
    this.viewCaseSheet = e;
  }

  showHidePatients(data) {
    data = !data;
    if (data == true) {
      this.appclass = true;
      this.searchFlag = false;
      this.showpatient = true;
    } else {
      this.appclass = false;
      this.searchFlag = true;
      this.showpatient = false;
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop+oset+2;
    
    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ",this.currentLimit)
    }
  }
  changecomplaints = (event) => {
    this.oberse_med = event.html;
    
      // this.oberse_med = this.oberse_med.substring(0, 250);
      // console.log(`Content truncated to  characters.`);
     // this.complaintsflag = true;
      //this.print_complaints = this.complaints
    
  };
  changediagnosis = (event) => {
    this.clnt_symptom = event.html;
  
      // this.clnt_symptom = this.clnt_symptom.substring(0, 250);
      // console.log(`Content truncated to  characters.`);
      // this.diseaseflag = true;
      // this.print_disease = this.dis_txt
    
  };
  getSpecializations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'adm/gsplbyhptl',
        JSON.stringify({ hptl_clinic_id: this.hospital_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.spl_array = [];
          var obj = JSON.parse(data['_body']);
          console.log('SPECIALIZATIONS **' + JSON.stringify(this.hospital_id));
          console.log('SPECIALIZATIONS **' + JSON.stringify(obj));
          if (obj.specializations != null) {
            this.spl_array = obj.specializations;
            this.spl_id = this.spl_array[0].spl_id;
            this.spl = this.spl_array[0].spl_name;         
          }
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }


}

