import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {FormControl} from '@angular/forms';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common.js';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-diag-reschedule',
  templateUrl: './diag-reschedule.component.html',
  styleUrls: ['./diag-reschedule.component.css']
})
export class DiagRescheduleComponent implements OnInit {
  public appointmentDate;
  public visitSession;
  public rescheduleReason;
  public sendData;
  public currentDateUrl;
  public currentDate;
  public visitingSessionData;
  public diagAppId;
  public sendBookingData;
  public reschBookingUrl;

  constructor(public clientservice:ClientViewService,public dialogRef: MatDialogRef<DiagRescheduleComponent>,public toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router) {
      dialogRef.disableClose = true;
      this.currentDateUrl = ipaddress.getIp + "adm/curdate";
      this.reschBookingUrl = ipaddress.getIp + "appointment/reschedule";
      this.diagAppId = data.diag_app_id;
     }

  ngOnInit(): void {
    this.sendData = {
      country: ipaddress.country_code
    }
    this.getCurrentDate();
    
  }


  close(){
    this.dialogRef.close();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.appointmentDate = (data.json().current_date);
        this.currentDate = data.json().current_date;
        this.visitingSessionData =[];
        this.visitingSessionData.push({ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" })
        
        // var curr_datetime = new Date(data.json().currentDate + " " + data.json().current_time);
        // if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        //   this.visitingSessionData = [
        //      { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
        //   this.visitSession = this.visitingSessionData[0].description;
        // }

        // if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        //   this.visitingSessionData = [{ "description": "Afternoon" }, { "description": "Evening" }];
        //   this.visitSession = this.visitingSessionData[0].description;
        // }
        
        // if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        //   this.visitingSessionData = [{ "description": "Evening" }];
        //   this.visitSession = this.visitingSessionData[0].description;
        // }
      }, error => {});
  }

  selectApptDate(data,value) {
    if (data != null) {
      this.appointmentDate = data;
      this.visitingSessionData =[];
      this.visitingSessionData.push({ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" })
      var curdate = this.currentDate.split("-");
      // var seldate = Date_Formate(this.appointmentDate).split("-");
      // if (curdate[2] < seldate[0]) {
      //   this.visitingSessionData = [
      //     { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      //   this.visitSession = this.visitingSessionData[0].description;
      // } else {
      //   var curr_datetime = new Date(data.json().current_date + " " + data.json().current_time);
      //   if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
      //     this.visitingSessionData = [
      //       { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      //     this.visitSession = this.visitingSessionData[0].description;
      //   }

      //   if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
      //     this.visitingSessionData = [{ "description": "Afternoon" }, { "description": "Evening" }];
      //     this.visitSession = this.visitingSessionData[0].description;
      //   }
        
      //   if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
      //     this.visitingSessionData = [{ "description": "Evening" }];
      //     this.visitSession = this.visitingSessionData[0].description;
      //   }
      // }
    }
  }

  book_appointment() {
    if (this.visitSession == "Select") {
      this.toastr.error(Message_data.sltSession);
    } else if (this.rescheduleReason == null || this.rescheduleReason == undefined) {
      this.toastr.error(Message_data.enterReason);
    } else {
      this.sendBookingData = {
        diag_app_id: this.diagAppId,
        date: this.appointmentDate,
        session: this.visitSession,
        reschedule_reason: this.rescheduleReason
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.reschBookingUrl, JSON.stringify(this.sendBookingData), { headers: headers }).subscribe(
        data => {
          if (data.json().result == "Can't reschedule more then three times") {
            this.toastr.error(data.json().result);
          } else {
            this.toastr.success(data.json().result);
            this.clientservice.sendMessage("diagnosis");
          }
        }, error => {});
    }
  }
}
