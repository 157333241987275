import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { DiagnosisService } from './diagnosis.service';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss']
})
export class DiagnosisComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  subscription: Subscription;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public hospitalArray;
  public profileIMG;
  public userName;
  public userLastName;
  public settings: boolean = false;
  public subMenuFlag: boolean = false;
  public dashSubMenuFlag: boolean = false;
  public minimizedAsiedFlag: boolean = false;
  public selectedMenu;
  public appointmentData: boolean;
  public displayPage;
  //hidden_Menu_flag
  public diagBillFlag: boolean;
  public reportFlag: boolean;
  public repAppFlag: boolean;
  public dashFlag: boolean;
  public menuAppFlag: boolean;
  public menuReports: boolean;
  public user: boolean;
  public test: boolean;
  public nepAppFlag: boolean;
  public diabFlag: boolean;
  public userHidden: boolean;
  public biillingFlag: boolean = false;
  public reference_video: boolean = true;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public dialog: MatDialog) { }
  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        this.menuNav(message);
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.userName = this.userInfo.first_name;
    this.userLastName = this.userInfo.last_name;
    this.profileIMG = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalArray = this.userInfo.hospitals;
    this.hospitalName = this.hospitalArray[0].hptl_name;
    this.hospitalLogo = ipaddress.Ip_with_img_address + this.hospitalArray[0].logo;
    this.subMenuFlag = false;
    this.dashSubMenuFlag = false;
    this.minimizedAsiedFlag = false;
    if (Diagnosis_Helper.getUserID_Type().user_type == "Chief pathologist" || Diagnosis_Helper.getUserID_Type().user_type == "Pathologist") {
      this.appointmentData = true;
      this.diagBillFlag = true;
      this.reportFlag = true;
      this.repAppFlag = false;
      this.dashFlag = true;
      this.menuAppFlag = true;
      this.menuReports = true;
      this.test = true;
      this.nepAppFlag = true;
      this.diabFlag = true;
      this.userHidden = true;
      this.menuNav("report_approval");
    } else if (Diagnosis_Helper.getUserID_Type().user_type == "Chief lab technician" || Diagnosis_Helper.getUserID_Type().user_type == "Lab technician") {
      this.appointmentData = false;
      this.diagBillFlag = true;
      this.reportFlag = false;
      this.repAppFlag = true;
      this.dashFlag = true;
      this.menuAppFlag = false;
      this.repAppFlag = true;
      this.menuReports = false;
      this.test = true;
      this.nepAppFlag = false;
      this.diabFlag = false;
      this.userHidden = true;
    } else if (Diagnosis_Helper.getUserID_Type().user_type == "front-desk") {
      this.menuNav("diagbilldetailview");
    } else {
      this.appointmentData = false;
      this.diagBillFlag = false;
      this.reportFlag = false;
      this.repAppFlag = false;
      this.dashFlag = false;
      this.menuAppFlag = false;
      this.repAppFlag = false;
      this.menuReports = false;
      this.test = false;
      this.nepAppFlag = false;
      this.diabFlag = false;
      this.userHidden = false;
    }
    // dynamic menu display
    var Moduleidlist;
    if (Helper_Class.getmodulelist() != null) {
       Moduleidlist = Helper_Class.getmodulelist();
       console.log("Moduleidlist" + JSON.stringify(Moduleidlist));
      var bill:any = {};
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "28") { // Bill View
          this.diagBillFlag = false;
          Helper_Class.setBillView(null);
          Helper_Class.setBillView("1");
        }else if (Moduleidlist[i] == "54") { // Bill Create
          Helper_Class.setBillCreate(null);
          Helper_Class.setBillCreate("1");
        } else if (Moduleidlist[i] == "55") { // Bill Edit
          Helper_Class.setBillEdit(null);
          Helper_Class.setBillEdit("1");
        } else if (Moduleidlist[i] == "85") { // Bill Print
          Helper_Class.setBillPrint(null);
          Helper_Class.setBillPrint("1");
        } else if (Moduleidlist[i] == "46") {
          this.reference_video = false;
        }
      }
    }
  }
  openSetting() {
    this.settings = !this.settings;
  }
  logOut(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "diagnosis",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        }, error => {
          this.toastr.error(Message_data.network);
        });
  }
  menuNav(page) {
    let page_nmae: string = page;
    this.selectedMenu = page;
    this.subMenuFlag = false;
    this.dashSubMenuFlag = false;
    switch (page_nmae) {
      case 'diagnosis_home': this.displayPage = page; break;
      case 'appointment': this.displayPage = page; break;
      case 'tests': this.displayPage = page; break;
      case 'report_upload':
        this.subMenuFlag = !this.subMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case 'pending': this.subMenuFlag = false; this.displayPage = page; break;
      case 'rejected': this.subMenuFlag = false; this.displayPage = page; break;
      case 'completed': this.subMenuFlag = false; this.displayPage = page; break;
      case 'report_upload_edit': this.displayPage = page; break;
      case 'report_upload_view': this.displayPage = page; break;
      case 'report_approval': this.displayPage = page; break;
      case 'nephrology_readings': this.displayPage = page; break;
      case 'diabetics_readings': this.displayPage = page; break;
      case 'billing': this.displayPage = page; break;
      case 'users': this.displayPage = page; break;
      case 'help': this.displayPage = page; break;
      case 'diagnosisview': this.displayPage = page; break;
      case 'nephrologyreadingviewpage': this.displayPage = page; break;
      case 'users_view': this.displayPage = page; break;
      case 'report_approval_detailed': this.displayPage = page; break;
      case 'diagtestadd': this.displayPage = page; break;
      case 'diagbilldetailview': this.displayPage = page; break;
      case 'diagbillcreate': this.displayPage = page; break;
      case 'diag_app_create': this.displayPage = page; break;
      case 'dashboard': this.dashSubMenuFlag = !this.dashSubMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case 'patient_list': this.dashSubMenuFlag = false; this.displayPage = page; break;
      case 'reports': this.dashSubMenuFlag = false; this.displayPage = page; break;
      case 'billedit': this.displayPage = page; break;
      case 'reference_video':this.displayPage = page; break;
      case 'listPackage' :this.displayPage = page;break; 
      case 'createPackage' :this.displayPage = page;break; 
      case 'clientPackageList' :this.displayPage = page;break;
      case 'createUserPackage' :this.displayPage = page; break;
      case 'diagPackageCreate' :this.displayPage = page; break;
    }
    customjs.SelectMenuFun(this.selectedMenu, "diag");
  }
  minimizing() {
    this.minimizedAsiedFlag = !this.minimizedAsiedFlag;
    if (this.minimizedAsiedFlag == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.subMenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
  }
  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsiedFlag = false;
  }
  openMenumodel() {
    this.minimizedAsiedFlag = false;
    customjs.openMobileMenuFun();
  }
  menuModelAction(action) {
    if (action == 'diagnosis_home') {
      this.menuNav(action);
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "diagnosis",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          }, error => {
            this.toastr.error(Message_data.network);
          });
    }
    $('#exampleModal').modal('hide');
  }
}
