<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                                width="85px" style="margin-right: 9px;" />
                           <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel_click()"
                                width="85px" style="margin-right: 9px;" />
                            <img src="../../../assets/ui_icons/buttons/diet.svg" (click)="diettrack()"
                                width="85px" style="margin-right: 9px;" />
                           <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="appointment_update()"
                                width="85px" style="margin-right: 9px;" /> 
                          <img (click)="Write_prescription()" *ngIf="medicalprescriptionflag"
                                    src="../../../assets/ui_icons/buttons/write_RX_Button.svg"    width="85px" style="margin-right: 9px;" />
                            <img (click)="reportview()" *ngIf="reportflag"
                                   src="../../../assets/ui_icons/buttons/Report_button.svg"    width="85px" style="margin-right: 9px;" />
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Patient details
                                </div>
                            <div class="content_cover">                       
                                <div class="row" >
                                  <div class="col-1">
                                    <img src="{{this.profile_image}}"
                                        onerror="this.src='../../../assets/img/default.jpg';"
                                        class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                                  </div>
                                  <div class="col-11">
                                    <div class="row" >
                                      <div class="col-3">
                                        <p class="textformat"> MR no: {{this.mrno}}</p>
                                      </div>
                                    <div class="col-3">
                                      <p class="textformat"> Name: {{this.clientName}}</p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">DOB: {{this.dob}}</p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">  Age: {{age}} </p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">  Gender: {{gender}} </p>
                                    </div>
                                    <div class="col-3">
                                        Mobile: {{this.phoneNo}}
                                    </div>
                                    <div class="col-3">
                                        <p class="textformat">Specialization: {{this.specialization}}</p>
                                      </div>
                                      <div class="col-3">
                                        <p class="textformat">Doctor name: {{this.doctorname}}</p>
                                      </div>
                    
                                    <div class="col-3">
                                      <p class="textformat">    Address: {{this.address1}}</p>
                                    </div>
                                 
                                    <div class="col-3">
                                      <p class="textformat">   Zipcode: {{this.zipcode}} </p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">     Location: {{this.location_desc}} </p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">   District / City: {{this.city_desc}} </p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">   State: {{this.state_desc}}</p>
                                    </div>
                                    <div class="col-3">
                                      <p class="textformat">   Country: {{this.country_desc}}</p>
                                    </div>
                                   
                                   
                                   
                                    <!-- <div class="col-3">
                                      <p class="textformat">   Referred by: {{this.referredDoctor}} </p>
                                    </div> -->
                                    <!-- <div class="col-3">
                                      <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                                    </div> -->
                                </div>
                              </div>
                                </div>
                            </div>
                            </div>
                        
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Medical & Appointment details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Complaints
                                                <input type="text" class="ipcss" [(ngModel)]="symtomes" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Speciality
                                                <input type="text" class="ipcss" [(ngModel)]="spl" readonly matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Doctor name
                                                <input type="text" class="ipcss" [(ngModel)]="doctorName" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Appointment date
                                                <input type="text" class="ipcss" [(ngModel)]="appDate" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Session
                                                <input type="text" class="ipcss" [(ngModel)]="session" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Token number
                                                <input type="text" class="ipcss" [(ngModel)]="tokenNo" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Vitals
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Height
                                                <input type="text" class="ipcss" (change)="calories_data()"
                                                    maxlength="{{heightMesure == 'cms' && (3) || (5)}}"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required [(ngModel)]="height" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Measure
                                                <select class="ipcss" required [(ngModel)]="heightMesure"
                                                    (change)="calories_data()">
                                                    <option value="cms">cms</option>
                                                    <option value="inch">inch</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Weight
                                                <input type="text" class="ipcss" (change)="calories_data()"
                                                    maxlength="5"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required [(ngModel)]="weight" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Measure
                                                <select class="ipcss" required [(ngModel)]="weightMesure"
                                                    (change)="calories_data()">
                                                    <option value="kgs">kgs</option>
                                                    <option value="lbs">lbs</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Temperature
                                                <input  [ngStyle]="{'color': tempcolorstyle ? 'red' : 'black'}" (input)="settempcolor(temprature)" type="text" class="ipcss" [(ngModel)]="temprature" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">BMI
                                                <input type="text" class="ipcss" [(ngModel)]="bmi" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3" >
                                            <mat-label class="matlabel">Blood pressure</mat-label>
                                            <div class="row">
                                              <div class=" col-6" >
                                                <input type="text" class="ipcss widthch1" placeholder="mm Hg"
                                                  maxlength="3" [(ngModel)]="BP01" (input)="sistol(BP01)" [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                              </div>
                                              <div class="col-6">
                                                <input type="text" class="ipcss widthch1" placeholder="mm Hg"
                                                  maxlength="3" [(ngModel)]="BP02" [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}" (input)="diostol(BP02)"
                                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                              </div>
                                            </div>
                                        </div>

                                        <div  class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                            <mat-label class="matlabel">SPO2<br>
                                                <input  matInput class="ipcss " placeholder="SPO2" [(ngModel)]="spo2"  (input)="spo2value(spo2)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                                            </mat-label>
                                        </div>
                  
                                        <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                          <mat-label class="matlabel">Pulse<br>
                                              <input  matInput class="ipcss " placeholder="Pulse" [(ngModel)]="pulse">
                                          </mat-label>
                                      </div>
                  
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">CVS<br>
                                                <input  matInput class="ipcss " maxlength="25" placeholder="cvs" [(ngModel)]="cvs_txt">
                                            </mat-label>
                                        </div>
                                        <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">RS<br>
                                                <input  matInput class="ipcss " maxlength="25" placeholder="rs" [(ngModel)]="rstxt">
                                            </mat-label>
                                        </div>
                                        <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">CNS<br>
                                                <input  matInput class="ipcss "maxlength="25" placeholder="cns" [(ngModel)]="cns_txt">
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Abdomen<br>
                                                <input  matInput class="ipcss "maxlength="25" placeholder="ABD" [(ngModel)]="abd_txt">
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">P/R<br>
                                                <input  matInput class="ipcss "maxlength="25"  placeholder="p/r" [(ngModel)]="pr_txt">
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                                        *ngIf="vitals_List.length != 0">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th style="text-align: left;">S.No</th>
                                              <th style="text-align: left;">Height</th>
                                              <th style="text-align: left;">Weight</th>
                                              <th style="text-align: left;">BMI</th>
                                              <th style="text-align: left;">Temp</th>
                                              <th style="text-align: left;">BP</th>
                                              <th style="text-align: left;">SPO2</th>
                                              <th style="text-align: left;">Pulse</th>
                                              <th style="text-align: left;">CVS</th>
                                              <th style="text-align: left;">RS</th>
                                              <th style="text-align: left;">CNS</th>
                                              <th style="text-align: left;">ABD</th>
                                              <th style="text-align: left;">Date</th>
                                              <!-- <th style="text-align: left;">Time</th> -->
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let vital of vitals_List">
                                              <td style="text-align: left;">{{vital.sNo}}</td>
                                              <td style="text-align: left;">{{vital.height}}</td>
                                              <td style="text-align: left;">{{vital.weight}}</td>
                                              <td style="text-align: left;">{{vital.bmi}}</td>
                                              <td style="text-align: left;">{{vital.temperature}}</td>
                                              <td style="text-align: left;">{{vital.bloodPressure}}</td>
                                              <td style="text-align: left;">{{vital.SPO2}}</td>
                                              <td style="text-align: left;">{{vital.pulse}}</td>
                                              <td style="text-align: left;">{{vital.Cvs}}</td>
                                              <td style="text-align: left;">{{vital.Rs}}</td>
                                              <td style="text-align: left;">{{vital.Cns}}</td>
                                              <td style="text-align: left;">{{vital.ABD}}</td>
                                              <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                                              <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Other observations 
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Chief complaints
                                                <textarea type="text" class="ipcss" [(ngModel)]="chiefCompalaint"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                                            <div class="row">
                                              <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                  <b class="label_bold">Chief complaints</b>
                                                </mat-label>
                                              </div>
                                              <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="chiefCompalaint" [styles]="{height: 'calc(35vh - 100px)'}"
                                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event)" (keyup)="calories_data()">
                                                </quill-editor>
                                                <div>
                                                  <!-- Preview -->
                                                  <quill-view-html hidden id="linemgmt" [content]="chiefCompalaint"></quill-view-html>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                                            <div class="row">
                                              <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                  <b class="label_bold">Present illness</b>
                                                </mat-label>
                                              </div>
                                              <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="presentIllness" [styles]="{height: 'calc(35vh - 100px)'}"
                                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt1($event)" (keyup)="calories_data()">
                                                </quill-editor>
                                                <div>
                                                  <!-- Preview -->
                                                  <quill-view-html hidden id="linemgmt" [content]="presentIllness"></quill-view-html>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Present illness
                                                <textarea type="text" class="ipcss" [(ngModel)]="presentIllness"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                                            <div class="row">
                                              <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                  <b class="label_bold">Past medical history</b>
                                                </mat-label>
                                              </div>
                                              <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="medicalHistory" [styles]="{height: 'calc(35vh - 100px)'}"
                                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt2($event)" (keyup)="calories_data()">
                                                </quill-editor>
                                                <div>
                                                  <!-- Preview -->
                                                  <quill-view-html hidden id="linemgmt" [content]="medicalHistory"></quill-view-html>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Past medical history
                                                <textarea type="text" class="ipcss" [(ngModel)]="medicalHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                                            <div class="row">
                                              <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                  <b class="label_bold">Family history</b>
                                                </mat-label>
                                              </div>
                                              <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="familyHistory" [styles]="{height: 'calc(35vh - 100px)'}"
                                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt3($event)" (keyup)="calories_data()">
                                                </quill-editor>
                                                <div>
                                                  <!-- Preview -->
                                                  <quill-view-html hidden id="linemgmt" [content]="familyHistory"></quill-view-html>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Family history
                                                <textarea type="text" class="ipcss" [(ngModel)]="familyHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Physical examination
                                                <textarea type="text" class="ipcss" [(ngModel)]="physicalExamination"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                                            <div class="row">
                                              <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                  <b class="label_bold">Physical examination</b>
                                                </mat-label>
                                              </div>
                                              <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="physicalExamination" [styles]="{height: 'calc(35vh - 100px)'}"
                                                  [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt4($event)" (keyup)="calories_data()">
                                                </quill-editor>
                                                <div>
                                                  <!-- Preview -->
                                                  <quill-view-html hidden id="linemgmt" [content]="physicalExamination"></quill-view-html>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="cover_div" style="padding-bottom: 13px;margin-top: 10px;" *ngIf="previousflag">
                                <div class="header_lable">Previous Visit</div>
                                <div class="content_cover">
                                  <div class="table-responsive dig_table_overflow">
                                    <table *ngIf="previousvisits.length" class="table table-nowrap table-sm dataTable billlisttable">
                                      <thead>
                                        <tr>
                                          <th>Doctor Name</th>
                                          <th>Visiting date</th>
                                          <th>Visit purpose</th>
                                          <th>Specialization</th>
                                          <th>Visit Type</th>
                                       
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let person of previousvisits; let i = index">
                                          <td style="font-size: 12px; text-align: left !important;">{{ person.doc_name }}</td>
                                          <td style="font-size: 12px;">{{ person.visit_date }}</td>
                                          <td style="font-size: 12px;">{{ person.visit_purpose }}</td>
                                          <td style="font-size: 12px;">{{ person.specialization_name }}</td>
                                          <td style="font-size: 12px; text-align: center;">{{ person.app_type }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>