<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Pharma racks & bins</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="saveData('save')" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="cover_div" style="padding-bottom: 13px;">
                                    <div class="header_lable">
                                        Racks
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">
                                            <div class="col-8">
                                                <input class="ipcss"  [(ngModel)]="racks" maxlength="10" matInput>
                                            </div>

                                            <div class="col-4">
                                                <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                                    class="saveimgbtn_inpatinfo" (click)="addRacks()" />
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="table-responsive dig_table_overflow">
                                                    
                                                    <table id="TransactionTable"
                                                        class="table table-striped table-sm table-hover discount_table">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 45%;">Rack name</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let rack of racksArray">
                                                                
                                                                <!-- <td *ngIf="rack.status==1">{{rack.rackname}}</td> -->
                                                                <td *ngIf="rack.status==1 && !rack.deleted">{{rack.rackname}}</td>

                                                                <td *ngIf="rack.status==1 && !rack.deleted" style="text-align: center;">
                                                                    <a *ngIf="editbutton" (click)="editRackDetails(rack.rackname)">
                                                                        <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                                            width="20" height="20"
                                                                            style="margin: 0 5px;" /></a>
                                                                    <a *ngIf="editbutton"  (click)="deleteRackDetails(rack.rackname,rack.rackid)">
                                                                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                            width="20" height="20"
                                                                            style="margin: 0 5px;" /></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                               
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div" style="padding-bottom: 13px;">
                            <div class="header_lable">
                                Bins
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="row">
                                            <div class="col-8">
                                                <input class="ipcss" [(ngModel)]="bins" maxlength="7" matInput>
                                            </div>
                                            <div class="col-4">
                                                <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                                    class="saveimgbtn_inpatinfo" (click)="addBins()" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="table-responsive dig_table_overflow">
                                           
                                            <table id="TransactionTable"
                                                class=" discount_table table table-striped table-sm table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 80%;">Bin name</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let bins of binsArray">
                                                        <td *ngIf="bins.status==1">{{bins.binname}}</td>
                                                        <td *ngIf="bins.status==1" style="text-align: center;">
                                                            <a *ngIf="editbutton" (click)="editBins(bins.binname)">
                                                                <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                                    width="20" height="20" style="margin: 0 5px;" /></a>
                                                            <a  *ngIf="editbutton" (click)="deleteBins(bins.binname)">
                                                                <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                    width="20" height="20" style="margin: 0 5px;" /></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>