<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Video</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="container">
    <div class="row" style="padding: 10px;">
      <div class="col-12">
        <img src="../../../assets/ui_icons/Generate_url_button.svg" class="addimgbtn_icd" height="auto" (click)="generate = !generate">
      </div>
      <div class="col-11" [hidden]="generate">
        <input type="text" class="ipcss" [(ngModel)]="videolink" id="myvalue" readonly />
      </div>
      <div class="col-1" [hidden]="generate">
        <img src="../../../assets/ui_icons/Copy_icon.svg" width="30px" height="auto" (click)="copyToClipboard()">
      </div>
      <div class="col-11">
        <input type="text" class="ipcss" [(ngModel)]="videolink1" id="myvalue" [readonly]="readonly" />
      </div>
      <!-- <div class="col-1">
        <img src="../../../assets/ui_icons/Connect_icon.svg" width="30px" height="auto" (click)="videolinkpage()">
      </div> -->
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="videolinkpage()" id="add_btn" mat-button>
    <img src="../../../assets/ui_icons/connect_button.svg" class="addimgbtn_icd" />
  </button>
</div>