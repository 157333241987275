import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MatDialog } from '@angular/material/dialog';
import { ClientCancelAppointmentComponent } from '../client-cancel-appointment/client-cancel-appointment.component';
@Component({
  selector: 'app-clientapplist',
  templateUrl: './clientapplist.component.html',
  styleUrls: ['./clientapplist.component.css']
})
export class ClientapplistComponent implements OnInit {
  public curDateUrl: string;
  public sendCurrentData;
  public appointmentList = [];
  public deleteApptList = [];
  public relationUrl: string;
  public sendClientRegId;
  public apptListUrl;
  public sendClientRetrvData;
  public clientRetrvListData;
  public deleteApptUrl;
  public currentTime: string;
  public currentDate: string;
  public deleteFlag: boolean = false;
  public relationList = [];
  public homecare;
  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any
  public checklistid: any = [];

  constructor(public messageservice:ClientViewService,private gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public dialog: MatDialog) {  
    this.curDateUrl = ipaddress.getIp + "adm/curdate";
    this.relationUrl = ipaddress.getIp + "usercontroller/grels";
    this.apptListUrl = ipaddress.getIp + "appointment/getallappdetails";
    this.deleteApptUrl = ipaddress.getIp + "appointment/delapps/";
    Client_Helper.setDoctorApptdetail(null);
    this.sendCurrentData = {
      country: ipaddress.country_code
    }
    if (this.gservice.Client_login_detail_data != undefined) {
      this.sendClientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      }
    }
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [10, 25, 50,100, -1], [10, 25, 50, 100, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [0,6] },
        {"targets":3, "type":"date-eu"}
      ],
      "language": {
            "search": "<img src='../../../assets/img/search.png' width='18px'/>",
            searchPlaceholder: "Search by doctor name, patient name or session"
          },
          dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.homecare = Client_Helper.getHomecare();
    this.sendClientRetrvData = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      home_care: this.homecare,
      provider: "doctor",
      relation: "all",
      country:"IN"
    }
    this.getCurrentDate();
    this.getRelationList();
    this.getApptRetrieval();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curDateUrl, JSON.stringify(this.sendCurrentData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
      },error => {
        this.toastr.error(Message_data.defaultErr);
      });
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, JSON.stringify(this.sendClientRegId), { headers: headers }).subscribe(
      data => {
        for (var l = 0; l < data.json().relationships.length; l++) {
          this.relationList.push({
            type: 'radio',
            value: data.json().relationships[l].relation_id,
            label: data.json().relationships[l].relationship_name,
          });
        }
      },error => {
        this.toastr.error(Message_data.defaultErr);
      });
  }

  getApptRetrieval() {
    this.appointmentList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptListUrl, JSON.stringify(
      this.sendClientRetrvData
      ), { headers: headers }).subscribe(
      data => {
        
        this.clientRetrvListData = data.json();
        for (var i = 0; i < this.clientRetrvListData.appointments.length; i++) {
          var new_datalist = this.clientRetrvListData.appointments[i];
          var middle = "";
          var first, last;
          if (new_datalist.first_name != null) {
            first=encrypt_decript.Decript(new_datalist.first_name);
          }
          if (new_datalist.last_name != null) {
            last=encrypt_decript.Decript(new_datalist.last_name);
          }
          if (new_datalist.middle_name != null) {
            middle = first + ' ' + encrypt_decript.Decript(new_datalist.middle_name) + ' ' + last;
          } else {
            middle = first + ' ' + last;
          }
          var docname;
          if (new_datalist.dr_middle_name != undefined) {
            docname = new_datalist.dr_first_name + ' ' + new_datalist.dr_middle_name + ' ' + new_datalist.dr_last_name;
          } else {
            docname = new_datalist.dr_first_name + ' ' + new_datalist.dr_last_name;
          }
          var App_date;
          if (new_datalist.date != null) {
            var d = new_datalist.date.split('-');
            App_date = d[2] + "-" + d[1] + "-" + d[0];
          }
          var Time_token;
          if (new_datalist.token_count == "false") {
            Time_token = "Time : " + Time_Formate_Session(new_datalist.f_time,encrypt_decript.Decript(new_datalist.session));
          } else {
            Time_token = "Token no : " + new_datalist.token;
          }
          var Status_txt = "";
          if (this.currentDate == new_datalist.date) 
          {
            var Time_Split = this.currentTime.split(':');
            if (new_datalist.status == "1" && new_datalist.is_canclled == "0") {
              if (encrypt_decript.Decript(new_datalist.session) == "Morning") {
                if (parseInt(Time_Split[0]) < 16) {
                  Status_txt = "Open";
                } else {
                  Status_txt = "Not visited";
                }
              } else if (encrypt_decript.Decript(new_datalist.session) == "Afternoon") {
                Status_txt = "Open";
              } else if (encrypt_decript.Decript(new_datalist.session) == "Evening") {
                Status_txt = "Open";
              } else {
                Status_txt = "Open";
              }
            }
            if (new_datalist.status == "2" && new_datalist.is_canclled == "0") {
              if (encrypt_decript.Decript(new_datalist.session) == "Morning") {
                if (parseInt(Time_Split[0]) < 16) {
                  Status_txt = "Confirmed";
                } else {
                  Status_txt = "Not visited";
                }
              } else if (encrypt_decript.Decript(new_datalist.session) == "Afternoon") {
                Status_txt = "Confirmed";
              } else if (encrypt_decript.Decript(new_datalist.session) == "Evening") {
                Status_txt = "Confirmed";
              } else {
                Status_txt = "Confirmed";
              }
            }
          } else {
            if (new Date(this.currentDate) < new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_canclled == "0") {
              Status_txt = "Open";
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_canclled == "0") {
              Status_txt = "Not visited";
            }
            if (new Date(this.currentDate) < new Date(new_datalist.date) && new_datalist.status == "2" && new_datalist.is_canclled == "0") {
              Status_txt = "Confirmed";
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "2" && new_datalist.is_canclled == "0") {
              Status_txt = "Not visited";
            }
          }
          if (new_datalist.is_canclled == "1" && new_datalist.status == "1") {
            Status_txt = "Rejected"; 
          }
          if (new_datalist.is_canclled == "1" && new_datalist.status == "0") {
            Status_txt = "Cancelled"; 
          }
          if (new_datalist.is_canclled == "0" && new_datalist.status == "0") {
            Status_txt = "Completed";
          }
          if (this.deleteFlag == true) {        
            if (Status_txt != "Open" && Status_txt != "Confirmed" && Status_txt != "Awaiting for rating") {
              this.appointmentList.push({
                app_id: new_datalist.app_id,
                name: middle,
                doc_name: docname,
                date: App_date,
                session: encrypt_decript.Decript(new_datalist.session),
                time: Time_token,
                statustxt: Status_txt,
                profile_image: ipaddress.Ip_with_img_address + new_datalist.profile_image,
                delete_flg: false
              })
            }
          } else {
            this.appointmentList.push({
              app_id: new_datalist.app_id,
              name: middle,
              doc_name: docname,
              date: App_date,
              session: encrypt_decript.Decript(new_datalist.session),
              time: Time_token,
              statustxt: Status_txt,
              profile_image: ipaddress.Ip_with_img_address + new_datalist.profile_image,
              delete_flg: false
            })
          }
        }
        
      },
      error => {
        this.toastr.error(Message_data.defaultErr);
      }
    )
  }

  appDelete() {
    this.getApptRetrieval();
    this.deleteFlag = true;
  }

  getDelete(app_id, check_value) {
    if (check_value == true) {
      this.deleteApptList.push(app_id);
    } else {
      for (var i = 0; i < this.deleteApptList.length; i++) {
        if (this.deleteApptList[i] == app_id) {
          this.deleteApptList.splice(this.deleteApptList.indexOf(i), 1);
          break;
        }
      }
    }
  }

  deleteAppt() {
    if(this.deleteApptList.length!=0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.deleteApptUrl, JSON.stringify({ 
        apps_id: this.deleteApptList,
        type: "doctor"
        }), { headers: headers }).subscribe(
        data => {
            var obj = data.json();
            this.getApptRetrieval();
        },error => {});
    } else{
      this.toastr.error(Message_data.selectapp)
    }
  }

  delete() {
    this.deleteAppt();
  }

  deleteCancel() {
    this.deleteFlag = false;
    this.sendClientRetrvData = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      home_care: this.homecare,
      provider: "doctor",
      relation: "all"
    }
    this.getApptRetrieval();
  }

  viewAppt(appid, check_value, status) {
    var selectlist;
    if (this.deleteFlag != true){ 
      selectlist = {
        status: status,
        doc_app_id:appid
      }
      Client_Helper.setHomecare(this.homecare);
      Client_Helper.setDoctorApptdetail(selectlist);
      if (this.homecare != null) {
        this.homecare = this.homecare;
      }
      this.messageservice.sendMessage("doctor_apptdetails");
    }
  }

  checkUncheckAll() {
    this.checklist = this.appointmentList;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.appointmentList.length) {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].statustxt == 'Open' || this.checklist[i].statustxt == 'Confirmed') {
            var doc_id = this.checklist[i].app_id;
            this.checklistid.push(doc_id);
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].statustxt == 'Open' || this.checklist[i].statustxt == 'Confirmed') {
          var doc_id = this.checklist[i].app_id;
          this.checklistid.push(doc_id);
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  isAllSelected(appid) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.indexOf(appid) !== -1) {
        var index = this.checklistid.indexOf(appid);
        this.checklistid.splice(index, 1);
      } else {
        this.checklistid.push(appid);
      }
    } else {
      this.checklistid.push(appid);
    }
  }

  getCheckedItemList(){
    if (this.checklist.length == this.checklistid.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checklistid.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.checklistid = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    } 
  }

  clearAll() {
    this.checklist = this.appointmentList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  confirmAppt() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "client",
          doc_app_ids: this.checklistid,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
              }
            }
          },error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }

  create(){
    this.messageservice.sendMessage("doctor_appcreate");
  }
  
  cancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {

      const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
        width: '50%',
        height: '300px',
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          if (result != undefined && result != undefined) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
              JSON.stringify({
                type: "client",
                reason: result,
                doc_cancel: this.checklistid,
              }),
              { headers: headers })
              .subscribe(
                response => {
                  var obj = JSON.parse(response["_body"]);
                  if (obj.key != null) {
                    if (obj.key == "1") {
                      this.toastr.success(Message_data.appcancel);
                      this.ngOnInit();
                    }
                  }
                },error => {
                  this.toastr.error(Message_data.defaultErr);
                });
                }
              }
            });
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
