import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';

@Component({
  selector: 'app-diag-outsource-payments-adv',
  templateUrl: './diag-outsource-payments-adv.component.html',
  styleUrls: ['./diag-outsource-payments-adv.component.scss']
})
export class DiagOutsourcePaymentsAdvComponent implements OnInit {
  
  dtOptions: DataTables.Settings = {};
  diag_centre_id: any;
  diag_center_id: any;
  hptl_clinic_id: any;

  department: any;
  big_sub_data: any[];
  diag_tests_id: any;
  test_name: any;
  sub_sugger_data: any[] = [];
  dynamicArrays: any[];
  testtype: {
    Blood_test: string[];
    microtests: string[];
    radiotests: string[];
  };
  public bigSuggerDataString: any;
  arrayNames: string[];
  big_four_data: any[] = [];
  dataArray: any[] = [];
  dataArrayBloodTest: any[] = [];
  dataArrayMicroTests: any[] = [];
  dataArrayRadioTests: any[] = [];
  dataArrayProfiles: any[] = [];

  constructor(public http: HttpClient, public toastr: ToastrService, public messageservice: MenuViewService,) { }

  public fromDate;
  public toDate;
  private tmr = new Date();
  public currentDate;
  public currDate;
  public outsourceList: any = [];
  public outsourceCenterNameList: any = [];
  public outSrcBills: any = [];
  public filtduration: string;
  public outSrcId;
  public paid_amount: number = 0;
  public invoice_number;
  public invoiceNo;
  public selectedList: any = [];
  public currenttime;
  public presentdate;
  public diagId;
  big_sugger_data: any[] = [];
  Blood_test: any[] = [];
  microtests: any[] = [];
  radiotests: any[] = [];
  profiles: any[] = [];

  // outsource Adv
  public outSrcType = '1';
  public outSrcTypeArray: any = [];
  public test_flag;
  public testtypename: any = [];
  public outSrcAmt;
  public showTable: boolean = false;
  public outSrcAmtLabel;
  public outsourceAmt_entered: any = [];

  public showTest_type: any;
  public userinfo;
  public selectedTests: any = [];
  public noshow = false;
  isSticky: boolean = false;
  
  ngOnInit(): void {
    this.filtduration = "select";
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.diag_center_id = Helper_Class.getInfo().diag_center_id;
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].diag_centre_id;
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].diag_center_id;
    } else {
      this.diag_center_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    this.getOutsourceList();
    this.getCurrentDate();
    this.getOutsourceType();
    this.showTests();
    this.userinfo = Helper_Class.getInfo();
    this.showTest_type = this.userinfo.hospitals[0].show_by_test_type;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search invoice, date, or due date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getOutsourceList() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gosl", { diag_center_id: this.diag_center_id },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval != undefined && dataval.outsource_list != undefined) {
            this.outsourceList = dataval.outsource_list;
            this.outSrcId = this.outsourceList[0].outsource_id;

            this.serch_centerchange();
            for (let center in this.outsourceList) {
              this.outsourceCenterNameList.push(this.outsourceList[center].centre_name)
              console.log("this.outsourceCenterNameList", this.outsourceList);
            }

          }
        })
  }

  getSampleList(date) {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      country: ipaddress.country_code,
      diag_center_id: this.diag_center_id,
      duration: durval,
      date: date,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gscl", send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        if (dataval != undefined && dataval.outsource_list != undefined) {
          this.outsourceList = dataval.outsource_list;
        }

      }, error => { });
  }

  getOutsourceBills() {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      diag_center_id: this.diag_center_id,
      outsource_id: this.outSrcId,
      duration: durval,
      from_date: this.fromDate,
      to_date: this.toDate,
      country: ipaddress.country_code,
    }

    console.log("getOutsrcBills-Send data", send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gobl", send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        this.outSrcBills = dataval.out_bill_list;
        for (let bills in this.outSrcBills) {
          this.outSrcBills[bills].paid_amount = "0";
          this.outSrcBills[bills].inv_num = "";
        }
        console.log("getOutsourceBills--dataval", this.outSrcBills)

      }, error => { });
  }

  getInvoiceNo() {
    console.log(this.outSrcBills[0].isSelected);
    for (let bills in this.outSrcBills) {
      if (this.outSrcBills[bills].isSelected == true) {
        this.outSrcBills[bills].inv_num = this.invoiceNo;
      }
    }
  }

  savePayments() {
    var send_data;
    this.selectedList = [];


    for (let bills in this.outSrcBills) {
      if (this.outSrcBills[bills].isSelected == true) {
        this.selectedList.push({
          invoice_no: this.outSrcBills[bills].inv_num,
          paid_amount: this.outSrcBills[bills].paid_amount,
          paid_flag: this.outSrcBills[bills].paid_flag,
          country: ipaddress.country_code,
          billing_id: this.outSrcBills[bills].billing_id,
          fin_year: this.outSrcBills[bills].fin_year,
          outsource_id: this.outSrcBills[bills].outsource_id,
          diag_centre_id: this.diag_centre_id,
        })
      }
    }

    send_data = {
      selected_bills: this.selectedList,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sob", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response);
        if (response.key == "1") {
          this.toastr.success("Successfully saved Outsource Payments")
        }

      }, error => { })

    this.messageservice.sendMessage("appointment");
  }

  isAnySelected(): boolean {
    return this.outSrcBills.some(item => item.isSelected);
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  serch_centerchange() {
    this.big_sugger_data = [];
    this.dataArrayBloodTest=[];
    var order_by = this.showTest_type === "0" ? "sub_dept" : "test_type";
    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtso/', {
      diag_centre_id: this.hptl_clinic_id,
      orderby: order_by,
      out_source_id: this.outSrcId,
    }, { headers: headers })
      .subscribe(
        response => {
          var obj = response as any;
          this.Blood_test = obj.labtests || [];
          this.microtests = obj.microtests || [];
          this.radiotests = obj.radiotests || [];
          //this.profiles = obj.profiles || [];
          if (this.Blood_test != undefined && this.Blood_test.length != 0) {
            this.Blood_test.forEach(element => {
              this.selectedTests.push()
            });
          }
          this.big_four_data = [
            {
              Blood_test: this.Blood_test,
              tittle: "Laboratory"
            },
            {
              microtests: this.microtests,
              tittle: "Microbiology"
            },
            {
              radiotests: this.radiotests,
              tittle: "Radiology"
            },
            // {
            //   profiles: this.profiles,
            //   tittle: "Profiles"
            // }
          ];
        
          if (obj.labtests.length !== 0) {
            for (var i = 0; i < obj.labtests.length; i++) {
              if (obj.labtests[i].outsource_cost !== undefined) {
                this.dataArrayBloodTest.push(obj.labtests[i].test_name);
              }
            }
          }
          if (obj.microtests.length !== 0) {
            for (var i = 0; i < obj.microtests.length; i++) {
              if (obj.microtests[i].outsource_cost !== undefined) {
                this.dataArrayBloodTest.push(obj.microtests[i].test_name);
              }
            }
          }
          if (obj.radiotests.length !== 0) {
            for (var i = 0; i < obj.radiotests.length; i++) {
              if (obj.radiotests[i].outsource_cost !== undefined) {
                this.dataArrayBloodTest.push(obj.radiotests[i].test_name);
              }
            }
          }
          // if (obj.profiles.length !== 0) {
          //   for (var i = 0; i < obj.profiles.length; i++) {
          //     if (obj.profiles[i].price !== undefined) {
          //       this.dataArrayBloodTest.push(obj.profiles[i].profile_name);
          //     }
          //   }
          // }
        },
        error => {
          console.error("Error fetching data:", error);
        }
      );
  }

  showTests() {
    if (this.outSrcType == "1") {
      this.showTable = false;
      this.noshow = false;
      this.outSrcAmtLabel = "Fixed Amount"
    } else if (this.outSrcType == "2") {
      this.showTable = false;
      this.outSrcAmtLabel = "Percentage Base"
      this.noshow = false;

    } else {
      this.noshow = true;
      this.showTable = true;
      this.outSrcAmtLabel = "Percentage Base"
    }
  }

  getOutsourceType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'mas/gdot',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var outsourceArray = obj.outsource_types;
          for (var i = 0; i < outsourceArray.length; i++) {
            this.outSrcTypeArray.push({
              outsource_type_id: outsourceArray[i].outsource_type_id,
              out_type_desc: outsourceArray[i].out_type_desc
            })
          }
          console.log('getOutsourceType', this.outSrcTypeArray)
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  saveOutsourcePayments() {
    var send_data;
    this.outsourceAmt_entered = [];

    for (var k = 0; k < this.big_sugger_data.length; k++) {
      var arrayName = this.arrayNames[k];

      for (var j = 0; j < this.big_sugger_data[k][arrayName].length; j++) {
        if (this.big_sugger_data[k][arrayName][j].outsource_cost !== undefined) {
          this.outsourceAmt_entered.push({
            diag_test_id: this.big_sugger_data[k][arrayName][j].diag_tests_id,
            outs_cost: this.big_sugger_data[k][arrayName][j].outsource_cost,
            ins_cost: this.big_sugger_data[k][arrayName][j].insource_cost,
          });
        }
      }
    }
    if (this.showTable) {
      send_data = {
        paytype: this.outSrcType,
        tests: this.outsourceAmt_entered
      };
    } else {
      send_data = {
        paytype: this.outSrcType,
        amount: this.outSrcAmt,
        oursourceid: this.outSrcId,
        diag_centre_id: this.hptl_clinic_id
      };
    }
    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "diagnosiscontroller/udod", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response);
        if (response.key == "1") {
          this.toastr.success("Successfully saved Outsource Payments");
        }
      },
      error => {
        console.error("Error saving Outsource Payments:", error);
      }
    );
  }

  setInsourceValue() {
    for (var k = 0; k < this.big_sugger_data.length; k++) {
      var arrayName = this.arrayNames[k];
      for (var j = 0; j < this.big_sugger_data[k][arrayName].length; j++) {
        this.big_sugger_data[k][arrayName][j].insource_cost = this.big_sugger_data[k][arrayName][j].outsource_cost;
      }
    }
  }
}
