import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { FormControl } from '@angular/forms';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-diagnosis-booking',
  templateUrl: './diagnosis-booking.component.html',
  styleUrls: ['./diagnosis-booking.component.css']
})
export class DiagnosisBookingComponent implements OnInit {

  public testTypeFlag: boolean = false;
  public test;
  public sendApptBookingData;
  public date;
  public sendData;
  public diagTest = [];
  public test_type = [];
  public diagList = [];
  public diagListDetails
  public visitingSessionData = [];
  public visitSession;
  public currentDateUrl;
  public currentDate;
  public currentTime;
  public presId;
  public diagCenterId;
  public apptBookingUrl;

  constructor(public messageservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.apptBookingUrl = ipaddress.getIp + "appointment/booking";
    this.sendData = {
      country: ipaddress.country_code
    }
 }

  ngOnInit(): void {
    var diagbookshow = Client_Helper.getDiagBookNow();
    this.presId = diagbookshow.pres_id;
    
    if (diagbookshow.diag_list_details != null && diagbookshow.diag_list_details.length != 0) {
      this.diagListDetails = diagbookshow.diag_list_details;
      this.getDiagApptDetails();
    }
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.date = (data.json().current_date);
        this.visitingSessionData = [];
        this.visitingSessionData.push({
          "description": "Select"
        })

        var curr_datetime = new Date(data.json().current_date + " " + this.currentTime);

        if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
          this.visitingSessionData = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
          this.visitSession = this.visitingSessionData[0].description;
        }

        if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
          this.visitingSessionData = [{ "description": "Select" }, { "description": "Afternoon" }, { "description": "Evening" }];
          this.visitSession = this.visitingSessionData[0].description;
        }

        if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
          this.visitingSessionData = [{ "description": "Select" }, { "description": "Evening" }];
          this.visitSession = this.visitingSessionData[0].description;
        }
      }, error => {});
  }

  getDiagApptDetails() {
    this.diagCenterId = "";
    if (this.diagListDetails != null && this.diagListDetails.length != 0) {
      for (var i = 0; i < this.diagListDetails.length; i++) {
        this.diagList.push({
          center_id: this.diagListDetails[i].center_id,
          center_name: this.diagListDetails[i].center_name,
          city: this.diagListDetails[i].city,
          location: this.diagListDetails[i].location,
          state: this.diagListDetails[i].state,
          telephone: this.diagListDetails[i].telephone,
          profile_image: this.diagListDetails[i].profile_image,
          website: this.diagListDetails[i].website,
          available: this.diagListDetails[i].available,
          address: this.diagListDetails[i].address
        })
        this.diagCenterId = this.diagListDetails[i].center_id;
        this.diagTest = this.diagListDetails[i].diag_tests_list
        if (this.diagTest != null && this.diagTest.length != 0) {
          this.testTypeFlag = true;
          for (var j = 0; j < this.diagTest.length; j++) {
            this.test = this.diagTest[j].test
            var test_sub = this.diagTest[j].subtest.split(",");
            var pres_test_id = this.diagTest[j].pres_test_id.split(",");

            for (var k = 0; k < test_sub.length; k++) {
              var sub_test = test_sub[k];
              var test_id = pres_test_id[k];

              this.test_type.push({
                test: this.test,
                subtest: sub_test,
                pres_test_id: test_id,
                check: true
              })
            }
          }
        } else {
          this.testTypeFlag = false;
        }
      }
    }
  }

  book() {
    if (this.visitSession == "Select") {
      this.toastr.error(Message_data.sltSession);
    } else {
      this.sendApptBookingData = {};
      var blood_array = [];
      var urine_arry = [];
      var scan_arry = [];
      var Faeces_tests = [];
      var Xray_tests = [];
      var ultra_tests = [];
      var testsList = [];

      for (var y = 0; y < this.test_type.length; y++) {
        if (this.test_type[y].check == true) {
          if (this.test_type[y].test == "Blood tests") {
            blood_array.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.blood_tests = blood_array;
          } else if (this.test_type[y].test == "Urine tests") {
            urine_arry.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.urine_tests = urine_arry;
          } else if (this.test_type[y].test == "Scan tests") {
            scan_arry.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.scan_tests = scan_arry;
          } else if (this.test_type[y].test == "Faeces tests") {
            Faeces_tests.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.faeces_tests = Faeces_tests;
          } else if (this.test_type[y].test == "Xray tests") {
            Xray_tests.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.xray_tests = Xray_tests;
          } else if (this.test_type[y].test == "Ultrasound tests") {
            ultra_tests.push(this.test_type[y].pres_test_id)
            this.sendApptBookingData.ultrasound_tests = ultra_tests;
          }
        }
      }

      this.sendApptBookingData.country =ipaddress.country_code;
      this.sendApptBookingData.diag_centre_id = this.diagCenterId;
      this.sendApptBookingData.pres_id = this.presId;
      this.sendApptBookingData.provider = "diagnosis";
      this.sendApptBookingData.date = (this.date);
      this.sendApptBookingData.session = this.visitSession;
      this.sendApptBookingData.client_reg_id = Client_Helper.getDiagPresList().client_reg_id;
      this.sendApptBookingData.relation_id = Client_Helper.getDiagPresList().relation_id;
      this.sendApptBookingData.spl_id = Client_Helper.getDiagBookNow().spl_id;
      if(Client_Helper.getDiagPresList().sub_rel_id != undefined){
        this.sendApptBookingData.sub_rel_id = Client_Helper.getDiagPresList().sub_rel_id;
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.apptBookingUrl, JSON.stringify(this.sendApptBookingData), { headers: headers }).subscribe(
        data => {
          var response_data = data.json();
          if (response_data.status == "1") {
            this.toastr.success(response_data.result);
            this.messageservice.sendMessage("diagnosis");
          } else {
            this.toastr.error(response_data.result);
          }
        }, error => {});
    }
  }

  apptBookingDate(data,value) {
    if (data != null) {
      this[value] = data;
      var dataval = localStorage.getItem("current_date");
      
      if(data != dataval){
        this.visitingSessionData = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
        this.visitSession = this.visitingSessionData[0].description;
      }
    }
  }

  back_Nav(){
    var diagpres = {
      pres_id: this.presId,
      flag: "diagnosis",
    }
    Client_Helper.setDiagPresList(diagpres);
    this.messageservice.sendMessage("diagnosis_pres_list");
  }
}
