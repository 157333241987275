<div *ngIf="nephReportsFlag == 'min'">
    <div class="appint_graph_min">
        <div id="eGFR_Chart_Unavail" class="graph_unavail_div">
          <p class="Paraclass_min">
            <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
          </p>
        </div>
        <div id="eGFR_Chart" class="chart_div_min"></div>
        <div>
          <img src="../../../assets/img/zoom_in.png" class="zoom" (click)="reading_poup('1')" [hidden]="Serum_Chart_zoom">
          <p class="text-center">eGFR</p>
        </div>
      </div>

</div>

<div *ngIf="nephReportsFlag == 'max'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="appint_graph">
                <div id="eGFR_Chart_Unavail" class="graph_unavail_div">
                  <p class="Paraclass">
                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                  </p>
                </div>
                <div id="eGFR_Chart" class="chart_div"></div>
                <div>
                  <img src="../../../assets/img/zoom_in.png" class="zoom" (click)="reading_poup('1')" [hidden]="Serum_Chart_zoom">
                  <p class="text-center">eGFR</p>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="appint_graph">
                <div id="Serum_Chart_Unavail" class="graph_unavail_div">
                  <p class="Paraclass">
                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                  </p>
                </div>
                <div id="Serum_Chart" class="chart_div"></div>
                <div>
                  <img src="../../../assets/img/zoom_in.png" class="zoom" (click)="reading_poup('2')" [hidden]="Serum_Chart_zoom">
                  <p class="text-center cahrat_para">Serum - Creatinine</p>
                </div>
              </div>
  
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: 10px;">
            <div class="appint_graph">
                <div id="Albuminuria_Unavail" class="graph_unavail_div">
                  <p class="Paraclass">
                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                  </p>
                </div>
                <div id="Albuminuria_Chart" class="chart_div"></div>
                <div>
                  <img src="../../../assets/img/zoom_in.png" class="zoom" (click)="reading_poup('3')" [hidden]="Serum_Chart_zoom">
                  <p class="text-center">Albuminuria (24 hrs)</p>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: 10px;">
            <div class="appint_graph">
                <div id="Proteinuria_Unavail" class="graph_unavail_div">
                  <p class="Paraclass">
                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                  </p>
                </div>
                <div id="Proteinuria_Chart" class="chart_div"></div>
                <div>
                  <img src="../../../assets/img/zoom_in.png" class="zoom" (click)="reading_poup('4')" [hidden]="Serum_Chart_zoom">
                  <p class="text-center cahrat_para">Proteinuria (24 hrs)</p>
                </div>
              </div>
        </div>
    </div>
</div>

