<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Current order</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/order_button.svg" (click)="orderFlow()" width="85px" style="margin-right: 9px;"/>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <p class="nodata" *ngIf="pharmaClientList.length == 0">No currentOrder(s) found</p>
              <table *ngIf="pharmaClientList.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable pharmacycurrentorder" >
              <thead class="tableheading"> 
                <tr>
                  <th>Name</th>
                  <th>Delivery type </th>
                  <th>Order date </th>
                  <th>Status </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of pharmaClientList" class="tdvalues"(click)="viewClientDetails(list.drug_purchase_id,list.pharma)">
                  <td style="font-size: 12px; text-align: left;">{{list.clientName}}</td>
                  <td style="font-size: 12px; text-align: left;"> {{list.deliverType}}</td>
                  <td style="font-size: 12px;">{{list.orderDate}} - {{list.orderTime}}</td>
                  <td style="font-size: 12px;"> {{list.orderStatus}}</td>
                </tr>
              </tbody>
            </table> 
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>