import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
// import { PharmacyService } from '../../../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ipaddress } from '../../../ipaddress';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-phrama-manufacturer-mst',
  templateUrl: './phrama-manufacturer-mst.component.html',
  styleUrls: ['./phrama-manufacturer-mst.component.scss']
})
export class PhramaManufacturerMstComponent implements OnInit {
  public sendDataValue
  private pharmacyID;
  public supplerIndex;
  supplerMasterArray = [];
  public supplierTable;
  public countryListArray = [];
  public cityListArray = [];
  public stateListArray = [];
  public supplierState: any;
  public supplierCity: any;
  public supplierTelephone: any;
  public supplierMobile: any;
  public countryID: string;
  getCityValue: any;
  public supplierName: string="";
  public supplierCode: string;
  public supplierAddress1: string;
  public supplierAddress2: string;
  public supplierCountry: any;
  public get: string;
  public supplyData;
  public suppID = "0";
  public pharmauserId;
  public address1;
  public address2;
  tempArray: any = []
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  public userInfo;
  public imei;
  public hospitalID;
  public productMasterAddArray = [];
  a: boolean;
  uomType: any = [];
  productID: string = "";
  uomListArray: any;
  public manufacListArray = [];
  public categoryTypeListArray: any = [];
  public mdeicareTypeList: any = [];
  public productTypeList: any = [];
  public filteredProdList = [];
  public hsnNo: string = "";
  public productName: string = "";
  public shortName: string = "";
  public genericName: string = "";
  public productTypeID;
  public medicareMedID;
  public categoryCTGID;
  public uomUomCode;
  public manufacturerMfgID;
  public productTypeArray: any = [];
  public medicareTypeArray: any = [];
  public categoryTypeArray: any = [];
  public manufacturer: any = [];
  public quantity: any = "";
  public costPrice: any;
  public sellingPrice: any;
  public mrp: any = "";
  public prodGST: any;
  public prodDisc;
  public product: any = [];
  public index = this.product.length;
  public productTable;
  public prodType;
  public showManufacturerList: boolean;
  public saveFlag: boolean = false;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;




  constructor(public comm_Data: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog,
    public messageservice: MenuViewService) { }
  ngOnInit(): void {
    this.showManufacturerList = pharmacy_helper.getManufacturer() == true ? false : true;
    this.userInfo = Helper_Class.getInfo();
    // var getvalue2 = Helper_Class.getInfo().hospitals;
    this.supplierCountry = this.userInfo.hospitals[0].country;
    this.supplierCity = this.userInfo.hospitals[0].city;
    this.supplierState = this.userInfo.hospitals[0].state;
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hospitalID = Helper_Class.getInfo().pharma_id;
    }
    else {
      this.hospitalID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    this.imei = Helper_Class.getIPAddress();
    this.pharmauserId = this.userInfo.user_id;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [8] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search Manufacturer name or Manufacturer code or address"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.get_country();
    this.suppler_master();


    if (Helper_Class.getmodulelist() != undefined) {

      console.log("id" + JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "49" && Helper_Class.getmodulelist()[i].description == "Manufacturer master") {

          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }


        }

      }
    }
  }


  checkInputFields() {
    if (this.supplierName && this.supplierCode && this.supplierAddress1) {
      this.saveFlag = true;
    } else {
      this.saveFlag = false;
    }
  }

  suppler_master() {
    this.supplerMasterArray = [];
    this.supplerIndex = 0;
    this.sendDataValue = JSON.stringify({
      pharmacist_id: this.pharmauserId,
      pharmacy_id: this.hospitalID,
      imei: this.imei,
    })
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'pharmacontrol/gmfg/', this.sendDataValue,
      { headers: headers })
      .subscribe(
        data => {
          var obj = data.json().manuf_details;
          console.log(" supplier list = " + JSON.stringify(obj))
          if (obj != null) {
            this.supplierTable = true;
            for (var i = 0; i < obj.length; i++) {
              if (obj[i].address2 != null && obj[i].address2 != undefined) {
                var add_txt = obj[i].address1 + "," + obj[i].address2;
              } else {
                add_txt = obj[i].address1;
              }
              this.supplerMasterArray.push({
                Index: this.supplerIndex,
                name: obj[i].name,
                mfg_code: obj[i].mfg_code,
                address: add_txt,
                address1: obj[i].address1,
                address2: obj[i].address2,
                city_desc: obj[i].city_desc,
                state_desc: obj[i].state_desc,
                country_desc: obj[i].country_desc,
                telephone: obj[i].telephone,
                mobile: obj[i].mobile,
                mfg_id: obj[i].mfg_id,
                city: obj[i].city,
                state: obj[i].state,
                country: obj[i].country,
              });
              this.supplerIndex++;
            }
            this.tempArray = this.supplerMasterArray;
          }
        },
        error => { });
  }

  get_country() {
    this.http.get(ipaddress.getIp + "usercontroller/count/").subscribe(
      data => {
        if (data.json().countries != null) {
          this.countryListArray = data.json().countries;
          this.get_state(this.supplierCountry, '1', this.supplierState);
        }
      },
      error => { });
  }
  get_state(country, flag, state) {
    this.stateListArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/state/", JSON.stringify({ country_id: country }), { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.stateListArray = data.json().states;
          if (flag != null && flag == "1") {
            this.stateListArray.forEach(stateid => {
              if (stateid.state_id == state) {
                this.supplierState = stateid.state_id;
                this.get_city(this.supplierState, '1');
              }
            });
          }
        }
      },
      error => { });
  }
  get_city(state, flag) {
    this.cityListArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/city/", JSON.stringify({ state_id: state }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityListArray = data.json().cities;
          if (flag != null && flag == '1') {
            this.cityListArray.forEach(element => {
              if (element.district_id == this.supplierCity)
                this.supplierCity = element.district_id;
            });
          }
        }
      },
      error => { });
  }
  get_city_id(id) {
    this.getCityValue = id.district_id
  }
  suppler_masterclick() {
    this.supplyData = [];
    this.saveFlag = true;
    if (this.supplierName == undefined || this.supplierName == "" || this.supplierCode == undefined
      || this.supplierCode == "" || this.supplierAddress1 == undefined || this.supplierAddress1 == ""
      || this.supplierCountry == undefined || this.supplierCountry == "" || this.supplierState == undefined
      || this.supplierState == undefined || this.supplierCity == undefined || this.supplierCity == undefined) {
      this.toastr.error(Message_data.mandatory);
    } else {
      // this.saveFlag = false;
      var sup_flag = true;
      if (this.supplerMasterArray.length != 0) {
        for (var i = 0; i < this.supplerMasterArray.length; i++) {
          if (this.supplerMasterArray[i].mfg_code == this.supplierCode) {
            sup_flag = false;
            break;
          }
        }
      }
      var add_txt = undefined;
      if (sup_flag == true) {
        if (this.supplierAddress2 != undefined && this.supplierAddress2 != "") {
          add_txt = this.supplierAddress2;
        }
        this.supplyData = ({
          name: this.supplierName,
          mfg_code: this.supplierCode,
          address1: this.supplierAddress1,
          address2: add_txt,
          country: this.supplierCountry,
          state: this.supplierState,
          city: this.supplierCity,
          telephone: this.supplierTelephone,
          mobile: this.supplierMobile,
          pharmacy_id: this.hospitalID,
          mfg_id: this.suppID,
        });
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "pharmacontrol/smfg/", this.supplyData, { headers: headers }).subscribe(
          data => {
            if (data.json().key == "1") {
              // this.saveFlag = false;
              
              if (pharmacy_helper.getManufacturer() == true) {
                if (pharmacy_helper.getProductMst() != undefined) {
                  pharmacy_helper.getProductMst().mfg_id = data.json().mfg_id;
                  this.messageservice.sendMessage("product");
                }
              }
              this.toastr.success(Message_data.manDetAddedSuccess);
              // this.saveFlag = false;
              this.supplierName = "";
              this.supplierCode = "";
              this.supplierAddress1 = "";
              this.supplierAddress2 = "";
              this.supplierTelephone = "";
              this.supplierMobile = "";
              this.supplierCountry = "";
              this.supplierState = "";
              this.supplierCity = "";
              this.suppler_master();
              if (this.supplierName && this.supplierCode && this.supplierAddress1) {
                this.saveFlag = true;
              } else {
                this.saveFlag = false;
              }
            } else {
              this.saveFlag = true;
              this.toastr.error(Message_data.unableToSave);
            }
          }, error => {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          });
      }
    }
  }
  suppler_edit(data) {


  
    if(this.supplierName == "" || this.supplierName == undefined){

    var supplier_table = true;
    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.supplerMasterArray[i].Index == data) {
        var flag = true;
        var coun_id = null;
        // if ((this.supplierName == undefined || this.supplierName == "") && (this.supplierCode == undefined || this.supplierCode == "") && (this.supplierAddress1 == undefined || this.supplierAddress1 == "") && (this.supplierAddress2 == undefined || this.supplierAddress2 == "") && (this.supplierCountry == undefined || this.supplierCountry == "") && (this.supplierState == undefined || this.supplierState == "") && (this.supplierCity == undefined || this.supplierCity == "") && (this.supplierTelephone == undefined || this.supplierTelephone == "") && (this.supplierMobile == undefined || this.supplierMobile == "")) {
        this.suppID = this.supplerMasterArray[i].mfg_id;
        if (this.suppID != "0") {
          var satte_txt = this.supplerMasterArray[i].state;
          var city_txt = this.supplerMasterArray[i].city;
          var supp_add = this.supplerMasterArray[i].address.split(',');
          this.supplierName = this.supplerMasterArray[i].name;
          this.supplierCode = this.supplerMasterArray[i].mfg_code;
          this.supplierAddress1 = this.supplerMasterArray[i].address1;
          this.supplierAddress2 = this.supplerMasterArray[i].address2;
          this.supplierTelephone = this.supplerMasterArray[i].telephone;
          this.supplierMobile = this.supplerMasterArray[i].mobile;
          if (this.supplierName && this.supplierCode && this.supplierAddress1) {
            this.saveFlag = true;
          } else {
            this.saveFlag = false;
          }
          for (var j = 0; j < this.countryListArray.length; j++) {
            if (this.countryListArray[j].country_id == this.supplerMasterArray[i].country) {
              this.supplierCountry = this.countryListArray[j].country_id;
              coun_id = this.countryListArray[j].country_id;
              break;
            }
          }
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp + "usercontroller/state/", JSON.stringify({
            country_id: coun_id
          }), { headers: headers }).subscribe(
            data => {
              var obj = JSON.parse(data["_body"]);
              this.stateListArray = [];
              this.cityListArray = [];
              this.stateListArray = obj.states;
              console.log("state array"+JSON.stringify(this.stateListArray))
              for (var j = 0; j < this.stateListArray.length; j++) {
                if (this.stateListArray[j].state_id == satte_txt) {
                  this.supplierState = this.stateListArray[j].state_id;
                  var headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  this.http.post(ipaddress.getIp + "usercontroller/city/", JSON.stringify({
                    state_id: this.stateListArray[j].state_id
                  }), { headers: headers }).subscribe(
                    data => {
                      var obj = JSON.parse(data["_body"]);
                      this.cityListArray = [];
                      this.cityListArray = obj.cities;
                      for (var j = 0; j < this.cityListArray.length; j++) {
                        if (this.cityListArray[j].district_id == city_txt) {
                          this.supplierCity = this.cityListArray[j].district_id;
                          break;
                        }
                      }
                    },
                    error => { });
                  break;
                }
              }
            },
            error => { });
           this.supplerMasterArray.splice(i, 1);
        }
      }
    }
  }else{
    this.toastr.error("Clear all the field before edit another manufacture data");
  }




  }
  reset() {
    this.supplierName = "";
    this.supplierCode = "";
    this.supplierAddress1 = "";
    this.supplierAddress2 = "";
    this.supplierCountry = "";
    this.supplierState = "";
    this.supplierCity = "";
    this.supplierTelephone = "";
    this.supplierMobile = "";
    if (this.supplierName && this.supplierCode && this.supplierAddress1) {
      this.saveFlag = true;
    } else {
      this.saveFlag = false;
    }

      this.suppler_master();
  }

  productmaster_edit(data) {
    if (this.productMasterAddArray.length != 0) {
      this.prodType = "Existing";
      for (var i = 0; i < this.productMasterAddArray.length; i++) {
        this.productTable = true;
        if (this.productMasterAddArray[i].Index == data) {
          this.productID = this.productMasterAddArray[i].product_id;
          this.hsnNo = this.productMasterAddArray[i].hsn_no;
          this.productName = this.productMasterAddArray[i].name;
          this.shortName = this.productMasterAddArray[i].short_name;
          this.genericName = this.productMasterAddArray[i].generic_name;
          for (var j = 0; j < this.productTypeList.length; j++) {
            if (this.productTypeList[j].med_code == this.productMasterAddArray[i].product_type_id) {
              this.productTypeArray = this.productTypeList[j];
              this.productTypeID = this.productMasterAddArray[i].product_type_id;
            }
          }
          for (var j = 0; j < this.mdeicareTypeList.length; j++) {
            if (this.mdeicareTypeList[j].medicare_id == this.productMasterAddArray[i].medicare_id) {
              this.medicareTypeArray = this.mdeicareTypeList[j];
              this.medicareMedID = this.productMasterAddArray[i].medicare_id;
            }
          }
          for (var j = 0; j < this.categoryTypeListArray.length; j++) {
            if (this.categoryTypeListArray[j].category_id == this.productMasterAddArray[i].category_type_id) {
              this.categoryTypeArray = this.categoryTypeListArray[j];
              this.categoryCTGID = this.productMasterAddArray[i].category_type_id;
            }
          }
          for (var j = 0; j < this.uomListArray.length; j++) {
            if (this.uomListArray[j].uom_code == this.productMasterAddArray[i].uom_code) {
              this.uomType = this.uomListArray[j];
              this.uomUomCode = this.productMasterAddArray[i].uom_code;
            }
          }
          for (var j = 0; j < this.manufacListArray.length; j++) {
            if (this.manufacListArray[j].mfg_id == this.productMasterAddArray[i].mfg_id) {
              this.manufacturer = this.manufacListArray[j];
              this.manufacturerMfgID = this.productMasterAddArray[i].mfg_id;
            }
          }
          this.quantity = this.productMasterAddArray[i].per_unit;
          this.costPrice = this.productMasterAddArray[i].cost_price;
          this.sellingPrice = this.productMasterAddArray[i].sales_price;
          this.mrp = this.productMasterAddArray[i].MRP;
          this.prodDisc = this.productMasterAddArray[i].prod_disc,
            this.prodGST = this.productMasterAddArray[i].prod_gst,
            this.productMasterAddArray.splice(i, 1);
        }
      }
    }
  }
  back() {
    this.messageservice.sendMessage("product");
  }
}
