import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;

@Component({
  selector: 'app-doc-ref-list',
  templateUrl: './doc-ref-list.component.html',
  styleUrls: ['./doc-ref-list.component.scss']
})
export class DocRefListComponent implements OnInit {
  public referralist:any=[];
  public userInfo;
  public ipaddress;
  dtOptions: DataTables.Settings = {};
  private currentDate;
  editbuttondie: boolean = false;
  newbuttondie:  boolean = false;
  delatebuttondie: boolean = false;
  printbuttondie:  boolean = false;
  viewbuttondie:  boolean = false;

  constructor(public toastr: ToastrService, public frontdeskservice: MenuViewService, public http: Http) {
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();

    this.getCurrentDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this. getDoctorReferrals();

    if (FrontDesk_Helper.getmodules() != null) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "24") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.delatebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getDoctorReferrals(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/drh',
      JSON.stringify({
        country: ipaddress.country_code,
        hosp_id: this.userInfo.hptl_clinic_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj != null && obj.referrals.length !=0) {
            for(var i=0;i<obj.referrals.length;i++){
              var cname;
              if(obj.referrals[i].middle_name != undefined){
                cname=encrypt_decript.Decript(obj.referrals[i].first_name)+" "+encrypt_decript.Decript(obj.referrals[i].middle_name)+" "+encrypt_decript.Decript(obj.referrals[i].last_name);
              } else {
                cname=encrypt_decript.Decript(obj.referrals[i].first_name)+" "+encrypt_decript.Decript(obj.referrals[i].last_name);
              }
              this.referralist.push({
                profile_image:this.ipaddress.Ip_with_img_address+obj.referrals[i].profile_image,
                referred_by:obj.referrals[i].referred_by,
                referred_to:obj.referrals[i].referred_to,
                ref_hospital:obj.referrals[i].ref_hospital,
                referred_date:obj.referrals[i].referred_date,
                notes:obj.referrals[i].notes,
                name:cname,
                hosp_ref_id:obj.referrals[i].hosp_ref_id,
                speciality:obj.referrals[i].speciality,
              })
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewReferral(data){

  }

  create(){
    this.frontdeskservice.sendMessage("docrefcreate");
  }
}
