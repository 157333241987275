<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Medical prescription details</h5>
          </div>
          <div class="headerButtons">
            <img  *ngIf="orderBackFlag" src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()" class="saveimgbtn_inpatinfo" />
            <img (click)="order()" *ngIf="pharmaFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/Next_button.svg" />
            <img (click)="search()" *ngIf="searchFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/search_button.svg" />
            <img (click)="bookAppointment()" *ngIf="appointmentFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/Book_now.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="row" *ngIf="!hospFlag">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-6">
              <div class="cover_div">
                <div class="header_lable">
                   Patient details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <th class="basic_tbl">Patient Name </th>
                      <td *ngIf="nameFlag" style="text-align: left;"> : {{patientName}}
                      </td>
                    </tr>
                    <tr>
                      <th class="basic_tbl">Disease </th>
                      <td style="text-align: left;"> : {{diseaseName}} {{diseaseDescribtion}}
                      </td>
                    </tr>
                  </table>
                </div>
            </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-6">
              <div class="cover_div">
                <div class="header_lable">
                   Doctor details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <th class="basic_tbl" style="width: 70px;">Doctor : </th>
                      <td *ngIf="nameFlag" style="text-align: left;"> {{doctorName}} {{doctorQualifyData}}
                      </td>
                    </tr>
                    <tr>
                      <th class="basic_tbl" style="width: 70px;">Date &nbsp;&nbsp;&nbsp;: </th>
                      <td style="text-align: left;"> {{prescriptionDate}}
                      </td>
                    </tr>
                  </table>
                </div>
            </div>
            </div>
          </div>
          <div classs="row" *ngIf="tabletTracker.length!=0" style="padding-top: 30px;">
            <div class="col-12">
              <div class="table-responsive dig_table_overflow">
                <table *ngIf="tabletTracker.length" id="tbl" class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Duration</th>
                      <th *ngIf="!multiTabFlag">Intake</th>
                      <th *ngIf="!multiTabFlag">Note</th>
                      <th *ngIf="multiTabFlag">Total</th>
                      <th *ngIf="multiTabFlag">&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let medicene of tabletTracker;let i=index;" class="tdvalues">
                      <td style="text-align: left;">{{medicene.medicene_name}}</td>
                      <td style="text-align: left;">{{medicene.medicene_dosage}}</td>
                      <td style="text-align: left;" >{{medicene.medicene_intakedays}} </td>
                      <td style="text-align: left;" *ngIf="!multiTabFlag">{{medicene.frequency}}</td>
                      <td style="text-align: left;" *ngIf="!multiTabFlag">{{medicene.note == undefined ? "" : medicene.note}}</td>
                      <td style="text-align: left;" *ngIf="multiTabFlag">{{medicene.drug_qty}}</td>
                      <td *ngIf=multiTabFlag><a (click)="deleteTabletTracker(i)">
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px"></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div [hidden]="PdfFlag" class="pdf_style">
            <iframe [hidden]="PdfFlag" class="controls" [src]="pdfFile" width="70%" height="500px" frameborder="0"></iframe>
          </div>
          <ng-container *ngIf="pharmacyDetailsFlag" >
            <div class="row" *ngFor="let pharma of pharmacyDetails; let i=index" style="padding-top: 30px;">
              <div class="col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5">
                <div class="row">
                  <div class="col-2 my-auto" style="padding-bottom: 26px;">
                    <img alt="image" src="../../../assets/img/Pharmacy_01.png" class="hosp_image_class">
                  </div>
                  <div class="col-8">
                    <p class="changefont"><b>{{pharma.pharmacy_name}}</b></p>
                    <p class="changefont">{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</p>
                    <p class="changefont">{{pharma.phar_loc}}</p>
                    <p class="changefont">{{pharma.phar_city}} - {{pharma.phar_zipcode}}</p>
                    <p class="changefont">{{pharma.phar_state}}, {{pharma.phar_cnty}}</p>
                    <p class="changefont">{{pharma.telephone}}</p><br>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="pharma.is_del_option" class="optionalign">
                <b>Delivery options</b><br />
              </div>
            </div>
          </ng-container>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>