<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Health Information</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" [hidden]="saveFlag" (click)="save()"
              src="../../../assets/ui_icons/buttons/save_button.svg" />
            <img class="saveimgbtn_inpatinfo" [hidden]="updateFlag" (click)="save()"
              src="../../../assets/ui_icons/buttons/update_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Personal details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Relation<br>
                  <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="relationName">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let relation of relationsList" (click)="relationChange(relation.name)"
                      value={{relation.name}}>{{relation.name}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div [hidden]="currentOccupationFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" [hidden]="currentOccupationFlag" class="matlabel">Current occupation
                  <input type="text" class="ipcss " [(ngModel)]="currentOccupation" maxlength="25" required matInput />
                </mat-label>
              </div>
              <div [hidden]="maritialFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" [hidden]="maritialFlag" class="matlabel">Marital status<br>
                  <select disableOptionCentering class="ipcss " [disabled]="maritialDisabledFlag" required
                    [(ngModel)]="maritialStatus">
                    <option disabled>Select</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Spinster">Spinster</option>
                    <option value="Divorcy">Divorcy</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                </mat-label>
              </div>
              <div [hidden]="physActivitiesFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" [hidden]="physActivitiesFlag" class="matlabel">Physical
                  Activities<br>
                  <textarea class="ipcss " maxlength="250" [(ngModel)]="physicalActivities"></textarea>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Medical details
          </div>
          <div class="content_cover">
            <div class="row">
              <div [hidden]="mainConcernFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="mainConcernFlag" class="matlabel">Main concerns<br>
                  <textarea class="ipcss" [(ngModel)]="mainConcern" (click)="selectMainConcern()" readonly></textarea>
                </mat-label>
              </div>
              <div [hidden]="pastMedical" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="pastMedical" class="matlabel">Past Medical History<br>
                  <textarea class="ipcss " [(ngModel)]="pastMedical" (click)="selectMedicalHistory()"
                    readonly></textarea>
                </mat-label>
              </div>
              <div [hidden]="presentIllness" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="presentIllness" class="matlabel">Present Illness<br>
                  <textarea class="ipcss " required [(ngModel)]="presentIllness" (click)="selectPresentIllness()"
                    readonly></textarea>
                </mat-label>
              </div>
              <div [hidden]="drugAllergyFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="drugAllergyFlag" class="matlabel">Drug allergy<br>
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="drugAllergy"
                    [disabled]="drugAllergyDisabledFlag" (ngModelChange)="drugAllergyChange(drugAllergy)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option value="No">No</mat-option>
                    <mat-option value="Yes">Yes</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div [hidden]="drugsFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="drugsFlag" class="matlabel">Drugs<br>
                  <textarea class="ipcss " [disabled]="drugDisabledFlag" [required]="drugRequiredFlag" maxlength="150"
                    [(ngModel)]="drugs"></textarea>
                </mat-label>
              </div>
              <div [hidden]="feelStressFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="feelStressFlag" class="matlabel">Feeling stressed?<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="feelStress"
                    (ngModelChange)="changeStress(feelStress)" [disabled]="feelStressDisabledFlag">
                    <option disabled>Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </mat-label>
              </div>
              <div [hidden]="stressFactorFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="stressFactorFlag" class="matlabel">Stress factors<br>
                  <textarea class="ipcss " [required]="stressRequiredFlag" maxlength="200" rows="1" value="None"
                    [(ngModel)]="stressFactor" matInput></textarea>
                </mat-label>
              </div>
              <div [hidden]="anyMedFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="anyMedFlag" class="matlabel">Taking Any Medications<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="anyMedicine"
                    (change)="anyMedicineChange()">
                    <option disabled>Select</option>
                    <option selected value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </mat-label>
              </div>
              <div [hidden]="anyMedFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="anyMedFlag" class="matlabel">Medicines
                  <input type="text" class="ipcss " maxlength="100" [disabled]="medicineFlag" required
                    [(ngModel)]="medicine" matInput />
                </mat-label>
              </div>
              <div [hidden]="feelStressFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="feelStressFlag" class="matlabel">Symptoms<br>
                  <textarea class="ipcss " maxlength="250" rows="1" required [(ngModel)]="symptom"
                    [disabled]="medicineFlag"></textarea>
                </mat-label>
              </div>
              <div [hidden]="hdisorderFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="hdisorderFlag" class="matlabel">Heart disorders<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="hdisorder"
                    (change)="hdisorderChange()" [disabled]="hdisorderDisabledFlag">
                    <option disabled>Select</option>
                    <option selected value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </mat-label>
              </div>
              <div [hidden]="hdisorderFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="hdisorderFlag" class="matlabel">Treatment<br>
                  <textarea class="ipcss " maxlength="250" rows="1" required [(ngModel)]="treat"
                    [disabled]="hDisTreatDisabledFlag"></textarea>
                </mat-label>
              </div>
              <div [hidden]="hospFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="hospFlag" class="matlabel">Previous Hospitalization<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="hosp"
                    [disabled]="prevHospDisabledFlag" (change)="changePrevHosp()">
                    <option disabled>Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </mat-label>
              </div>
              <div [hidden]="hospFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="hospFlag" class="matlabel">Details<br>
                  <textarea class="ipcss " maxlength="250" rows="1" required [(ngModel)]="hospDetails"
                    [disabled]="hospDisabledFlag"></textarea>
                </mat-label>
              </div>
              <div [hidden]="childHabitsFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label [hidden]="childHabitsFlag" class="matlabel">Child habits<br>
                  <textarea class="ipcss " [(ngModel)]="childHabits" (click)="selectChildHabit()" readonly></textarea>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div [hidden]="observationFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 15px;">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Observation
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 15px;">
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Height
                        <input type="number" class="ipcss heightmsr"
                          maxlength="{{heightMeasure == 'cms' && (3) || (5)}}"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" required [(ngModel)]="height"
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Measure<br>
                        <mat-select disableOptionCentering class="ipcss heightmsr" required [(ngModel)]="heightMeasure">
                          <mat-option disabled>Select</mat-option>
                          <mat-option value="cms">cms</mat-option>
                          <mat-option value="inch">inch</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Weights
                        <input type="number" class="ipcss heightmsr" maxlength="5"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" required [(ngModel)]="weight"
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Measure<br>
                        <mat-select disableOptionCentering class="ipcss heightmsr" required [(ngModel)]="weightMeasure">
                          <mat-option disabled>Select</mat-option>
                          <mat-option value="kgs">kgs</mat-option>
                          <mat-option value="lbs">lbs</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label [hidden]="bodyTypeFlag" class="matlabel">Body type<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="bodyType">
                          <mat-option *ngFor="let btype of bodytTypeList" value={{btype.bodytype_name}}>
                            {{btype.bodytype_name}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <mat-label [hidden]="hospFlag" class="matlabel">Dietary Habits<br>
                        <textarea class="ipcss " maxlength="250" rows="1" required [(ngModel)]="dietHabits"></textarea>
                      </mat-label>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="surgicalHistoryFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Surgical history
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">D&C<br>
                        <input type="date" class="ipcss " (click)="selectDcDate(dc)" [(ngModel)]="dc"
                          [max]="currentMaxDate" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Ovarian Surgery
                        <input type="date" class="ipcss " (click)="selectOvarainDate(ovarianSurgery)"
                          [max]="currentMaxDate" [(ngModel)]="ovarianSurgery" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Hysteroscopy
                        <input type="date" class="ipcss " (click)="selectHysteroscopyDate(hysteroscopy)"
                          [max]="currentMaxDate" [(ngModel)]="hysteroscopy" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Infertility Surgery
                        <input type="date" class="ipcss " (click)="selectInfertilitySurgeryDate(infertilitySurgery)"
                          [max]="currentMaxDate" [(ngModel)]="infertilitySurgery" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">L cyst(s) Removed
                        <input type="date" class="ipcss " (click)="selectLeftCystDate(leftCystRemoved)"
                          [max]="currentMaxDate" [(ngModel)]="leftCystRemoved" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">R cyst(s) Removeds
                        <input type="date" class="ipcss " (click)="selectRightCystDate(rightCystRemoved)"
                          [max]="currentMaxDate" [(ngModel)]="rightCystRemoved" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Tuboplasty
                        <input type="date" class="ipcss " (click)="selectTuboplastyDate(tuboplasty)"
                          [max]="currentMaxDate" [(ngModel)]="tuboplasty" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Tubal Ligation
                        <input type="date" class="ipcss " (click)="selectTubalLigationDate(tubalLigation)"
                          [max]="currentMaxDate" [(ngModel)]="tubalLigation" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">L ovary removed
                        <input type="date" class="ipcss " (click)="selectLeftOvaryDate(leftOvaryRemoved)"
                          [max]="currentMaxDate" [(ngModel)]="leftOvaryRemoved" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">R ovary removed
                        <input type="date" class="ipcss " (click)="selectRightOvaryDate(rightOvaryRemoved)"
                          [max]="currentMaxDate" [(ngModel)]="rightOvaryRemoved" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Laparoscopy
                        <input type="date" class="ipcss " (click)="selectLaparoscopyDate(laparoscopy)"
                          [max]="currentMaxDate" [(ngModel)]="laparoscopy" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Hysterectomy (vaginal)
                        <input type="date" class="ipcss " (click)="selectHysterectomyDate(hysterectomyVaginal)"
                          [max]="currentMaxDate" [(ngModel)]="hysterectomyVaginal" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Hysterectomy (abdominal)
                        <input type="date" class="ipcss "
                          (click)="selectHysterectomyAbdominalDate(hysterectomyAbdominal)" [max]="currentMaxDate"
                          [(ngModel)]="hysterectomyAbdominal" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Myomectomy
                        <input type="date" class="ipcss " (click)="selectMyomectomyDate(myomectomy)"
                          [(ngModel)]="myomectomy" [max]="currentMaxDate" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Vaginal or bladder repair for prolapsed or incontinence
                        <input type="date" class="ipcss " (click)="selectBladderRepairDate(bladderRepair)"
                          [max]="currentMaxDate" [(ngModel)]="bladderRepair" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <mat-label class="matlabel">Others
                        <textarea class="ipcss obsttxtareaheight " rows="1" maxlength="150"
                          [(ngModel)]="others"></textarea>
                      </mat-label>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="menstrualFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Menstrual
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Stage
                        <mat-select disableOptionCentering class="ipcss " [disabled]="stageDisabledFlag"
                          (ngModelChange)="changeStage(stageDesc)" [(ngModel)]="stageDesc">
                          <mat-option *ngFor="let stage of menstrualStageList" value="{{stage.description}}">
                            {{stage.description}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Stage Date
                        <input type="date" class="ipcss " (change)="selectStageDate(mensStageDate)"
                          [(ngModel)]="mensStageDate" max="{{currentDate}}" required matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Menstrual periods
                        <mat-select disableOptionCentering class="ipcss " [disabled]="menstrualPeriodDisabledFlag"
                          [(ngModel)]="menstrualPeriod">
                          <mat-option value="Regular">Regular</mat-option>
                          <mat-option value="Irregular">Irregular</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Last menstrual period
                        <input type="date" class="ipcss " required (change)="selectLastMensPeriodDate(lastMensPeriod)"
                          [(ngModel)]="lastMensPeriod" max="{{currentDate}}" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Cycle length<br>
                        <mat-select disableOptionCentering class="ipcss " [disabled]="cycleDisabledFlag"
                          [(ngModel)]="cycleLength">
                          <mat-option value="3">3</mat-option>
                          <mat-option value="4">4</mat-option>
                          <mat-option value="5">5</mat-option>
                          <mat-option value="6">6</mat-option>
                          <mat-option value="7">7</mat-option>
                          <mat-option value="8">8</mat-option>
                          <mat-option value="9">9</mat-option>
                          <mat-option value="10">10</mat-option>
                          <mat-option value="11">11</mat-option>
                          <mat-option value="12">12</mat-option>
                          <mat-option value="13">13</mat-option>
                          <mat-option value="14">14</mat-option>
                          <mat-option value="15">15</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Frequency<br>
                        <mat-select disableOptionCentering class="ipcss " [disabled]="frequencyLengthDisabledFlag"
                          [(ngModel)]="frequencyLength">
                          <mat-option value="5">5</mat-option>
                          <mat-option value="6">6</mat-option>
                          <mat-option value="7">7</mat-option>
                          <mat-option value="8">8</mat-option>
                          <mat-option value="9">9</mat-option>
                          <mat-option value="10">10</mat-option>
                          <mat-option value="11">11</mat-option>
                          <mat-option value="12">12</mat-option>
                          <mat-option value="13">13</mat-option>
                          <mat-option value="14">14</mat-option>
                          <mat-option value="15">15</mat-option>
                          <mat-option value="16">16</mat-option>
                          <mat-option value="17">17</mat-option>
                          <mat-option value="18">18</mat-option>
                          <mat-option value="19">19</mat-option>
                          <mat-option value="20">20</mat-option>
                          <mat-option value="21">21</mat-option>
                          <mat-option value="22">22</mat-option>
                          <mat-option value="23">23</mat-option>
                          <mat-option value="24">24</mat-option>
                          <mat-option value="25">25</mat-option>
                          <mat-option value="26">26</mat-option>
                          <mat-option value="27">27</mat-option>
                          <mat-option value="28">28</mat-option>
                          <mat-option value="29">29</mat-option>
                          <mat-option value="30">30</mat-option>
                          <mat-option value="31">31</mat-option>
                          <mat-option value="32">32</mat-option>
                          <mat-option value="33">33</mat-option>
                          <mat-option value="34">34</mat-option>
                          <mat-option value="35">35</mat-option>
                          <mat-option value="36">36</mat-option>
                          <mat-option value="37">37</mat-option>
                          <mat-option value="38">38</mat-option>
                          <mat-option value="39">39</mat-option>
                          <mat-option value="40">40</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="menstrualPainFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="menstrualPainFlag" class="matlabel">Menstrual pain<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="menstrualPain"
                          [disabled]="menstrualPainDisbaledFlag"
                          (ngModelChange)="selectMenstrualPainDate(menstrualPain)">
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Yes">Yes</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="mensTimeOfPainFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="mensTimeOfPainFlag" class="matlabel">Timing of pain<br>
                        <select disableOptionCentering class="ipcss " [(ngModel)]="mensTimeOfPain"
                          [disabled]="mensTimeOfPainDisabledFlag" [required]="mensTimeOfPainRequiredFlag">
                          <option disabled>Select</option>
                          <option value="Before menses">Before menses</option>
                          <option value="During menses">During menses</option>
                          <option value="Both">Both</option>
                        </select>
                      </mat-label>
                    </div>
                    <div [hidden]="mensCharOfPainFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="mensCharOfPainFlag" class="matlabel">Character of pain<br>
                        <select disableOptionCentering class="ipcss " [required]="mensCharOfPainRequiredFlag"
                          [disabled]="mensCharOfPainDisabledFlag" [(ngModel)]="mensCharOfPain">
                          <option disabled>Select</option>
                          <option *ngFor="let character of charOfPainList" value={{character.description}}>
                            {{character.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div [hidden]="mensDisorderFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="mensDisorderFlag" class="matlabel">Menstrual disorders<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="mensDisorder"
                          [disabled]="mensDisorderDisabledFlag" (ngModelChange)="changeMensDisorder(mensDisorder)">
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Hypomenorrhea">Hypomenorrhea</mat-option>
                          <mat-option value="Polymenorrhea">Polymenorrhea</mat-option>
                          <mat-option value="Metrorrhagia">Metrorrhagia</mat-option>
                          <mat-option value="Menorrhagia">Menorrhagia</mat-option>
                          <mat-option value="Menometrorrhagia">Menometrorrhagia</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="mensNoOfDaysFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="mensNoOfDaysFlag" class="matlabel">No of days<br>
                        <select disableOptionCentering class="ipcss " [required]="mensNoOfDaysRequiredFlag"
                          [disabled]="mensNoOfDaysDisabledFlag" [(ngModel)]="mensNoOfDays">
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </mat-label>
                    </div>
                    <div [hidden]="mensPainFreqFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="mensPainFreqFlag" class="matlabel">Frequency<br>
                        <select disableOptionCentering class="ipcss " [disabled]="freuencyDisabledFlag"
                          [required]="frequencyRequiredFlag" [(ngModel)]="mensPainFreq">
                          <option disabled>Select</option>
                          <option *ngFor="let pain of painFreqlist" value={{pain.description}}>{{pain.description}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="sexualHistoryFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Sexual History
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row">
                    <div [hidden]="sexualStatusFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="sexualStatusFlag" class="matlabel">Sexual status<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sexualStatus"
                          [disabled]="sexualStatusDisabledFlag" (ngModelChange)="changeSexualStatus(sexualStatus)">
                          <mat-option value="None">None</mat-option>
                          <mat-option value="In Active">In Active</mat-option>
                          <mat-option value="Active">Active</mat-option>
                          <mat-option value="Very Active">Very Active</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="difficultiesflag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="difficultiesflag" class="matlabel">Difficulties during intercourse<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sexualDifficulties"
                          [disabled]="difficultDisabledFlag"
                          (ngModelChange)="changeSexualDifficulties(sexualDifficulties)">
                          <mat-option value="None">None</mat-option>
                          <mat-option value="Pain">Pain</mat-option>
                          <mat-option value="Discomfort">Discomfort</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="typeofpainFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="typeofpainFlag" class="matlabel">Type of pain<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sexualTypeOfPain"
                          [disabled]="sexTypeOfPainDisabledFlag">
                          <mat-option value="None">None</mat-option>
                          <mat-option value="Superficial">Superficial</mat-option>
                          <mat-option value="Sometimes">Sometimess</mat-option>
                          <mat-option value="Always">Always</mat-option>
                          <mat-option value="Deep">Deep</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="sexualSatisfactionFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="sexualSatisfactionFlag" class="matlabel">Sexual satisfaction<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="sexualSatisfaction"
                          [disabled]="sexualSatDisabledFlag">
                          <mat-option disabled>Select</mat-option>
                          <mat-option value="Satisfied">Satisfied</mat-option>
                          <mat-option value="Not satisfied">Not satisfied</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="sexualActiveFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="sexualActiveFlag" class="matlabel">Age when first sexually active
                        <input type="text" class="ipcss " maxlength="3" [(ngModel)]="sexualActive"
                          [disabled]="sexualActiveDisabledFlag" matInput />
                      </mat-label>
                    </div>
                    <div [hidden]="typeOfSexFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="typeOfSexFlag" class="matlabel">Type of sex<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="typeOfSex"
                          [disabled]="typeOfSexDisabledFlag">
                          <mat-option disabled>Select</mat-option>
                          <mat-option value="Oral">Oral</mat-option>
                          <mat-option value="Anal">Anal</mat-option>
                          <mat-option value="Vaginal">Vaginal</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="contraceptFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="contraceptFlag" class="matlabel">Contraceptives<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="contracept"
                          (ngModelChange)="changeContracept(contracept)" [disabled]="contraceptDisabledFlag">
                          <mat-option value="None">None</mat-option>
                          <mat-option value="Regularly">Regularly</mat-option>
                          <mat-option value="Often">Often</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="typeOfContraceptFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="typeOfContraceptFlag" class="matlabel">Type of contraceptive<br>
                        <mat-select disableOptionCentering class="ipcss " [required]="typeOfContraceptRequiredFlag"
                          [(ngModel)]="typeOfContracept" [disabled]="typeOfContraceptDisabledFlag">
                          <mat-option disabled>Select</mat-option>
                          <mat-option *ngFor="let contracept of contraceptiveList" value={{contracept.description}}>
                            {{contracept.description}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="reasonForDiscontFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="reasonForDiscontFlag" class="matlabel">Reason for discontinue
                        <textarea class="ipcss obsttxtareaheight" [required]="reasonForDiscontRequiredFlag"
                          [disabled]="reasonForDiscontDisabledFlag" #myInput id="myInput" rows="1" maxlength="150"
                          [(ngModel)]="reasonForDiscont"></textarea>
                      </mat-label>
                    </div>
                    <div [hidden]="complicationInSexFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="complicationInSexFlag" class="matlabel">Complications, if any
                        <textarea class="ipcss " [disabled]="complicationInSexDisabledFlag" maxlength="150"
                          [(ngModel)]="complicationInSex"></textarea>
                      </mat-label>
                    </div>
                    <div [hidden]="infectionFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="infectionFlag" class="matlabel">Infections<br>
                        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="infection"
                          [disabled]="infectDisabledFlag">
                          <mat-option disabled>Select</mat-option>
                          <mat-option *ngFor="let infect of infectionList" value={{infect.description}}>
                            {{infect.description}}
                          </mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div [hidden]="otherIssueSexFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label [hidden]="otherIssueSexFlag" class="matlabel">Any other issues?
                        <textarea class="ipcss " [disabled]="otherIssueSexDisabledFlag" maxlength="150"
                          [(ngModel)]="otherIssueSex"></textarea>
                      </mat-label>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="bloodGlucoseFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Blood Glucose
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 7px;">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Blood Glucose</mat-label>
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">Fasting
                            <mat-checkbox color="primary" [(ngModel)]="fasting" (change)="changefasting()">
                            </mat-checkbox>
                          </mat-label>
                        </div>
                        <div class="col-6">
                          <mat-label class="matlabel">OGTT
                            <mat-checkbox color="primary" [(ngModel)]="ogtt" (change)="changeOgtt()"></mat-checkbox>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Fasting / 1/2 hour
                        <input type="text" class="ipcss heightmsr" placeholder="mg/dl" maxlength="3"
                          [(ngModel)]="fastingHalfhour" required
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">1 hour
                        <input type="text" class="ipcss heightmsr" placeholder="mg/dl" maxlength="3"
                          disabled="{{fastOneDisabledFlag}}" [(ngModel)]="fastingOnehour"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">1 1/2 hours
                        <input type="text" class="ipcss heightmsr" placeholder="mg/dl" maxlength="3"
                          disabled="{{fastOnehalfDisabledFlag}}" [(ngModel)]="fastingOnehalfhour"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Postprandial
                        <input type="text" class="ipcss heightmsr" placeholder="mg/dl" maxlength="3"
                          [(ngModel)]="postprandial" required
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">HbA1c
                        <input type="text" class="ipcss heightmsr" placeholder="mg/dl" maxlength="3" [(ngModel)]="HbA1c"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"
                          matInput />
                      </mat-label>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="habitualFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Habitual
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Alcohol
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState1" hideToggle [(ngModel)]="toggAlchol"
                              (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Quantity
                                    <select class="sdropdown" [(ngModel)]="alcQuantity">
                                      <option disabled value="Select">Select</option>
                                      <option value={{quan.description}} *ngFor="let quan of alcQuantityList">
                                        {{quan.description}}</option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Mixing Type
                                    <select class="sdropdown" [(ngModel)]="alcMix">
                                      <option disabled value="Select">Select</option>
                                      <option value={{Mix.description}} *ngFor="let Mix of alcMissingList">
                                        {{Mix.description}}
                                      </option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="alcholDuration">
                                      <option value="&lt;1 Year">
                                        &lt;1 Year</option>
                                      <option value="&lt;5 Years">
                                        &lt;5 Years</option>
                                      <option value=">5 Years"> >5 Years</option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Frequency/day
                                    <select class="sdropdown" [(ngModel)]="alcholFrequency">
                                      <option value=&lt;5>
                                        &lt;5</option>
                                      <option value=">5 to 10">>5 to 10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Smoke
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState2" hideToggle
                              (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="smokeDuration">
                                      <option value="&lt;1 Year"> &lt;1 Year</option>
                                      <option value="&lt;5 Years "> &lt;5 Years </option>
                                      <option value="&gt;5 Years">&gt;5 Years </option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Frequency/day(times)
                                    <select class="sdropdown" [(ngModel)]="smokeFrequency">
                                      <option value="&lt;5">&lt;5</option>
                                      <option value="&gt;5 to &lt;10">&gt;5 to &lt;10 </option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Gutka
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState3"
                              (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="gutkaDuration">
                                      <option value="&lt;1 Year">&lt;1 Year</option>
                                      <option value="&lt;5 Years ">&lt;5 Years </option>
                                      <option value="&gt;5 Years">&gt;5 Years</option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Frequency/day (Times)
                                    <select class="sdropdown" [(ngModel)]="gutkaFrequency">
                                      <option value="&lt;5">&lt;5</option>
                                      <option value="&gt;5 to &lt;10">&gt;5 to &lt;10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Exercise
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState4"
                              (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <div class="col-12">
                                    <mat-label class="matlabel ">Exercise</mat-label>
                                  </div>
                                  <div class="col-12" style="padding-left: 0px;">
                                    <mat-select class="ipcss " [(ngModel)]="toggExercise"
                                      (ngModelChange)="toggleExercise($event)" disableOptionCentering>
                                      <mat-option value="Yes">Yes</mat-option>
                                      <mat-option value="No" selected>No</mat-option>
                                    </mat-select>
                                  </div>
                                  <div class="col-12">
                                    <div [hidden]="exerciseShowFlag" class="row">
                                      <div class="col-12">
                                        <mat-label class="matlabel">Duration
                                          <mat-select class="ipcss " [(ngModel)]="exerciseDuration"
                                            disableOptionCentering>
                                            <mat-option value="15 Mins">15 Mins</mat-option>
                                            <mat-option value="30 Mins">30 Mins</mat-option>
                                            <mat-option value="45 Mins">45 Mins</mat-option>
                                            <mat-option value="&lt;1 Hour">&lt;1 Hour</mat-option>
                                          </mat-select>
                                        </mat-label>
                                      </div>
                                      <div class="col-12">
                                        <mat-label class="matlabel">Exercise routine
                                          <mat-select class="ipcss " [(ngModel)]="exerRoutine" disableOptionCentering>
                                            <mat-option *ngFor="let routine of exerRoutineList"
                                              value={{routine.description}}>
                                              {{routine.description}}</mat-option>
                                          </mat-select>
                                        </mat-label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="pediaFlag && pediaVaccineFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Vaccination
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped" *ngIf="mainVaccineData != undefined">
                        <thead>
                          <tr>
                            <th width="20%">Age</th>
                            <th width="30%">Vaccine</th>
                            <th width="22%">Due date</th>
                            <th width="23%">Given date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let key of keys">
                            <td style="width:20%">{{key}}</td>
                            <td style="width:30%">
                              <div class="vaccine_td" *ngFor="let key1 of mainVaccineData[key]">
                                {{key1.vaccine_name}}
                              </div>
                            </td>
                            <td style="width:22%">
                              <div class="vaccine_td" *ngFor="let key1 of mainVaccineData[key]; let i=index">
                                <span *ngIf="key1.due_flag == null">
                                  <input type="date" class="ipcss"
                                    (change)="selectVaccDueDate(key,key1.due_date1,i,'due')"
                                    [(ngModel)]="key1.due_date1" style="width: 140px;">
                                </span>
                                <span *ngIf="key1.due_flag!=null">
                                  <input class="ipcss" matInput disabled [(ngModel)]="key1.due_date">
                                </span>
                              </div>
                            </td>
                            <td style="width:22%">
                              <div class="vaccine_td" *ngFor="let key1 of mainVaccineData[key]; let i=index">
                                <span *ngIf="key1.given_flag==null">
                                  <input type="date" class="ipcss"
                                    (change)="selectVaccGivenDate(key,i,'given',key1.given_date1,key1.ped_vaccine_age_id,key1.due_date)"
                                    [(ngModel)]="key1.given_date1">
                                </span>
                                <span *ngIf="key1.given_flag!=null">
                                  <input class="ipcss " matInput disabled [(ngModel)]="key1.given_date"
                                    ng-model-options="{ allowInvalid: true}">
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div [hidden]="pediaFlag && pediaDevlpFlag">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                  <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title">
                      Child Development
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div class="row paddingheader">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="oneYearAccordianFlag">
                      <mat-accordion class="mataccordion">
                        <mat-expansion-panel class="expandpanel">
                          <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title">
                              &#60;1 Year (Infant)
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <hr class="hr">
                          <div class="row">
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel pediatrics_lbl_width ">Deciduous Teeth </mat-label>
                              </div>
                              <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="oneYearDecideTeeth">
                                  <mat-radio-button class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="oneYearDecideTeeth=='yes'" class="ipcss " #myInput
                                  [disabled]="decideDisabledFlag" id="myInput" [(ngModel)]="oneYearDecideTeethRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel pediatrics_lbl_width ">Dental Caries</mat-label>
                              </div>
                              <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="oneYearDentalCaries">
                                  <mat-radio-button class="pediatrics_width" [disabled]="dentalDisabledFlag"
                                    value="yes">Yes
                                  </mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="dentalDisabledFlag" value="no">
                                    No
                                  </mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="oneYearDentalCaries=='no'"
                                class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="oneYearDentalCaries=='no'" class="ipcss " #myInput
                                  [disabled]="dentalDisabledFlag" id="myInput" [(ngModel)]="oneYearDentalCariesRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Object Performance</mat-label>
                              </div>
                              <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <mat-radio-group class="matlabel " color="primary"
                                  [(ngModel)]="oneYearObjectPerformance">
                                  <mat-radio-button [disabled]="objectDisabledFlag" class="pediatrics_width"
                                    value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button [disabled]="objectDisabledFlag" class="pediatrics_width" value="no">
                                    Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="oneYearObjectPerformance=='yes'"
                                class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="oneYearObjectPerformance=='yes'" class="ipcss " #myInput
                                  [disabled]="objectDisabledFlag" id="myInput" [(ngModel)]="oneYearObjectRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Sleep Pattern</mat-label>
                              </div>
                              <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="oneYearSleepPattern">
                                  <mat-radio-button [disabled]="sleepDisabledFlag" class="pediatrics_width" value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button [disabled]="sleepDisabledFlag" class="pediatrics_width" value="no">
                                    Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="oneYearSleepPattern=='yes'"
                                class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="oneYearSleepPattern=='yes'" class="ipcss " #myInput
                                  [disabled]="sleepDisabledFlag" id="myInput" [(ngModel)]="oneYearSleepRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                            <div [hidden]="cognitiveOneMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                      1-2 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog1To2monthData;">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogOneDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Normal</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogOneDisabledFlag"
                                          [value]="true" [checked]="Developement.No">Abnormal</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogOneDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cognitiveThreeMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      3 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog3monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogThreeDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogThreeDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogThreeDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cognitiveFourMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      4-5 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog4To5monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogFiveDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogFiveDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogFiveDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cognitiveSixMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      6-7 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog6To7monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogSixDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogSixDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogSixDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cognitiveEightMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      8-9 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog8To9monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}} </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogEightDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogEightDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogEightDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cognitiveTenMonthFlag"
                              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      10-12 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog10To12monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogTenDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="cogTenDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="cogTenDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <p class="tervys_heading"><strong>Language Development</strong></p>
                            <div [hidden]="langOneFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      1-2 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang1To2monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langOneDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langOneDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langOneDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="langThreeFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      3 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang3monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langThreeDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langThreeDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langThreeDisabledFlag"
                                        [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="langFourFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      4-5 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang4To5monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langFourDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langFourDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langFourDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="langSixFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width ">
                                    <mat-panel-title class="title ">
                                      6-7 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang6To7monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langSixDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langSixDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langSixDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="lanEightFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      8-9 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang8To9monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langeightDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langeightDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langeightDisabledFlag"
                                        [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="langTenFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      10-12 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develLang10To12monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="langTenDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="langTenDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="langTenDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <div [hidden]="twoYearAccordianFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <mat-accordion class="mataccordion">
                        <mat-expansion-panel class="expandpanel">
                          <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title ">
                              1-2 Years (Toddler)
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <hr class="hr">
                          <div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Dentition</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearDentionTeeth">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoDenDisabledFlag"
                                    value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoDenDisabledFlag" value="no">
                                    Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearDentionTeeth=='yes'"
                                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoYearDentionTeeth=='yes'" class="ipcss " #myInput
                                  [disabled]="twoDenDisabledFlag" id="myInput" [(ngModel)]="twoYearDentionRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Moral development</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearMoralDevlp">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoMoralDisabledFlag"
                                    value="yes">
                                    Yes</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoMoralDisabledFlag"
                                    value="no">No
                                  </mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearMoralDevlp=='no'"
                                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoYearMoralDevlp=='no'" class="ipcss " #myInput
                                  [disabled]="twoMoralDisabledFlag" id="myInput" [(ngModel)]="twoYearMoralRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Psycho-social development</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearPhyschoSocial">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoPsychoDisabledFlag"
                                    value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoPsychoDisabledFlag"
                                    value="no">
                                    Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearPhyschoSocial=='yes'"
                                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoYearPhyschoSocial=='yes'" class="ipcss " #myInput
                                  [disabled]="twoPsychoDisabledFlag" id="myInput" [(ngModel)]="twoYearPhyscoRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Toilet training</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearToiletTraining">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoToiletDisabledFlag"
                                    value="yes">Normal
                                  </mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoToiletDisabledFlag"
                                    value="no">
                                    Abnormal
                                  </mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearToiletTraining=='yes'"
                                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoYearToiletTraining=='yes'" class="ipcss " #myInput
                                  [disabled]="twoToiletDisabledFlag" id="myInput" [(ngModel)]="twoYearToiletRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Sleep Pattern</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearSleep">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoSleepDisabledFlag"
                                    value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoSleepDisabledFlag"
                                    value="no">
                                    Abnormal
                                  </mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearSleep=='yes'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea class="ipcss " #myInput [disabled]="twoSleepDisabledFlag" id="myInput"
                                  [(ngModel)]="twoYearSleepRemarks" rows="1"></textarea>
                              </div>
                            </div>
                            <div class="row paddingheader">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel ">Playing Pattern</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel " color="primary" [(ngModel)]="twoYearPlayingPattren">
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoPlayDisabledFlag"
                                    value="yes">
                                    Normal</mat-radio-button>
                                  <mat-radio-button class="pediatrics_width" [disabled]="twoPlayDisabledFlag"
                                    value="no">
                                    Abnormal</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              <div [hidden]="twoYearPlayingPattren=='yes'"
                                class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoYearPlayingPattren=='yes'" class="ipcss " #myInput
                                  [disabled]="twoPlayDisabledFlag" id="myInput" [(ngModel)]="twoYearPlayRemarks"
                                  rows="1"></textarea>
                              </div>
                            </div>
                            <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                            <div [hidden]="cogFifFlag" class="row">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      15 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog15monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="devFifDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="devFifDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="devFifDisabledFlag" [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div [hidden]="cogEightFlag" class="block1">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      18 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div class="row paddingheader" *ngFor="let Developement of develCog18monthData">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                      <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                        <mat-radio-button class="pediatrics_width" [disabled]="devEighteenDisabledFlag"
                                          [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="pediatrics_width" [disabled]="devEighteenDisabledFlag"
                                          [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                      </mat-radio-group>
                                    </div>
                                    <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                      <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                        [disabled]="devEighteenDisabledFlag"
                                        [(ngModel)]="Developement.remarks"></textarea>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div class="row">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      24 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div [hidden]="cogTwntyFlag" class="block1_grid">
                                    <div class="row paddingheader" *ngFor="let Developement of develCog24monthData">
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                        <mat-radio-group class="matlabel " color="primary"
                                          [(ngModel)]="Developement.No">
                                          <mat-radio-button class="pediatrics_width" [disabled]="devTwenDisabledFlag"
                                            [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                          <mat-radio-button class="pediatrics_width" [disabled]="devTwenDisabledFlag"
                                            [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                        </mat-radio-group>
                                      </div>
                                      <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                          [disabled]="devTwenDisabledFlag"
                                          [(ngModel)]="Developement.remarks"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <p class="tervys_heading"><strong>Language Development</strong></p>
                            <div class="row">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      15 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div [hidden]="langFifMonthFlag" class="block1_grid">
                                    <div class="row paddingheader" *ngFor="let Developement of develLang15monthData">
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                        <mat-radio-group class="matlabel " color="primary"
                                          [(ngModel)]="Developement.No">
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langFifteenDisabledFlag" [value]="false"
                                            [checked]="Developement.Yes">Yes</mat-radio-button>
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langFifteenDisabledFlag" [value]="true"
                                            [checked]="Developement.No">No</mat-radio-button>
                                        </mat-radio-group>
                                      </div>
                                      <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                          [disabled]="langFifteenDisabledFlag"
                                          [(ngModel)]="Developement.remarks"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div class="block1">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      18 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div [hidden]="langEightMonthFlag" class="block1_grid">
                                    <div class="row paddingheader" *ngFor="let Developement of develLang18monthData">
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                        <mat-radio-group class="matlabel " color="primary"
                                          [(ngModel)]="Developement.No">
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langEighteenDisabledFlag" [value]="false"
                                            [checked]="Developement.Yes">Yes</mat-radio-button>
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langEighteenDisabledFlag" [value]="true"
                                            [checked]="Developement.No">No</mat-radio-button>
                                        </mat-radio-group>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                          [disabled]="langEighteenDisabledFlag"
                                          [(ngModel)]="Developement.remarks"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div class="block1">
                              <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                  <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                      24 months
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <hr class="hr">
                                  <div [hidden]="langTwntyMonthFlag" class="block1_grid">
                                    <div class="row paddingheader" *ngFor="let Developement of develLang24monthData">
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                        <mat-radio-group class="matlabel " color="primary"
                                          [(ngModel)]="Developement.No">
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langTwnFourDisabledFlag" [value]="false"
                                            [checked]="Developement.Yes">Yes</mat-radio-button>
                                          <mat-radio-button class="pediatrics_width"
                                            [disabled]="langTwnFourDisabledFlag" [value]="true"
                                            [checked]="Developement.No">No</mat-radio-button>
                                        </mat-radio-group>
                                      </div>
                                      <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                          [disabled]="langTwnFourDisabledFlag"
                                          [(ngModel)]="Developement.remarks"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <mat-accordion class="mataccordion">
                        <mat-expansion-panel class="expandpanel">
                          <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title ">
                              3 – 5 Years (Pre-Schooler)
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <hr class="hr">
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Dentition</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearDentionTeeth">
                                <mat-radio-button class="pediatrics_width" [disabled]="preDenDisabledFlag" value="yes">
                                  Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preDenDisabledFlag" value="no">
                                  Abnormal
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearDentionTeeth=='yes'"
                              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearDentionTeeth=='yes'" class="ipcss " #myInput
                                [disabled]="preDenDisabledFlag" id="myInput" [(ngModel)]="threeYearDentionRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Moral Development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearmoral">
                                <mat-radio-button class="pediatrics_width" [disabled]="preMoralDisabledFlag"
                                  value="yes">
                                  Yes
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preMoralDisabledFlag" value="no">
                                  No
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearmoral=='no'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearmoral=='no'" class="ipcss " #myInput
                                [disabled]="preMoralDisabledFlag" id="myInput" [(ngModel)]="threeYearMoralRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Psycho-Social Development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearPhyschoSocial">
                                <mat-radio-button class="pediatrics_width" [disabled]="prePyschoDisabledFlag"
                                  value="yes">
                                  Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="prePyschoDisabledFlag"
                                  value="no">
                                  Abnormal
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearPhyschoSocial=='yes'"
                              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearPhyschoSocial=='yes'" class="ipcss " #myInput
                                [disabled]="prePyschoDisabledFlag" id="myInput" [(ngModel)]="threeYearPhyschoRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Toilet Training</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearToiletTraining">
                                <mat-radio-button class="pediatrics_width" [disabled]="preToiletDisabledFlag"
                                  value="yes">
                                  Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preToiletDisabledFlag"
                                  value="no">
                                  Abnormal
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearToiletTraining=='yes'"
                              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearToiletTraining=='yes'" class="ipcss " #myInput
                                [disabled]="preToiletDisabledFlag" id="myInput" [(ngModel)]="threeYearToiletRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Sleep Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearSleep">
                                <mat-radio-button class="pediatrics_width" [disabled]="preSleepDisabledFlag"
                                  value="yes">
                                  Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preSleepDisabledFlag" value="no">
                                  Abnormal
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearSleep=='yes'" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearSleep=='yes'" class="ipcss " #myInput
                                [disabled]="preSleepDisabledFlag" id="myInput" [(ngModel)]="threeYearSleepRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <div class="row paddingheader">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-label class="matlabel ">Playing Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <mat-radio-group class="matlabel " color="primary" [(ngModel)]="threeYearPlayingPattren">
                                <mat-radio-button class="pediatrics_width" [disabled]="prePlayDisabledFlag" value="yes">
                                  Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="prePlayDisabledFlag" value="no">
                                  Abnormal
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <div [hidden]="threeYearPlayingPattren=='yes'"
                              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                              <textarea [hidden]="threeYearPlayingPattren=='yes'" class="ipcss " #myInput
                                [disabled]="prePlayDisabledFlag" id="myInput" [(ngModel)]="threeYearPlayingRemarks"
                                rows="1"></textarea>
                            </div>
                          </div>
                          <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                          <div [hidden]="cogThreeFlag" class="row">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    3 years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develCog3yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="devThreeYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="devThreeYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="devThreeYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <div [hidden]="cogFourFlag" class="row">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    4 Years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develCog4yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="devFourYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="devFourYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="devFourYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <div [hidden]="cogFiveFlag" class="block1">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    5 Years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develCog5yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="devFiveYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="devFiveYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="devFiveYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <p class="tervys_heading"><strong>Language Development</strong></p>
                          <div [hidden]="langThreeYearFlag" class="row">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    3 years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develLang3yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="langThreeYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="langThreeYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="langThreeYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <div [hidden]="langFourYearFlag" class="block1">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    4 Years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develLang4yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="langFourYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="langFourYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="langFourYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <div [hidden]="langFiveYearFlag" class="block1">
                            <mat-accordion class="mataccordion">
                              <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                  <mat-panel-title class="title ">
                                    5 Years
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row paddingheader" *ngFor="let Developement of develLang5yearData">
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-label class="matlabel ">{{Developement.Description}}</mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                    <mat-radio-group class="matlabel " color="primary" [(ngModel)]="Developement.No">
                                      <mat-radio-button class="pediatrics_width" [disabled]="langFiveYearDisabledFlag"
                                        [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                      <mat-radio-button class="pediatrics_width" [disabled]="langFiveYearDisabledFlag"
                                        [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                  <div *ngIf="Developement.No" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <textarea *ngIf="Developement.No" class="ipcss " #myInput
                                      [disabled]="langFiveYearDisabledFlag"
                                      [(ngModel)]="Developement.remarks"></textarea>
                                  </div>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>