import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { ServerApi } from 'src/app/server-api';
import { Diet_Helper } from '../Diet_Helper';
import { Http, Headers } from '@angular/http';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { masterCSData_Helper } from 'src/app/Doctor_module/casesheet/masterCSData_Helper';
import { MasterCSHelperService } from 'src/app/Doctor_module/casesheet/masterCSData.service';
import { CommonDataService } from '../../providers/common-data.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-diet-appointments-creation',
  templateUrl: './diet-appointments-creation.component.html',
  styleUrls: ['./diet-appointments-creation.component.scss']
})
export class DietAppointmentsCreationComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  public currentLimit = 1;
  public apptTypeFlag: boolean;
  public appclass: boolean = false;
  public appdisable: boolean = false;
  public homecareSessionData: any = [];
  public visitingSessionData: any = [];
  public doctorDetailTableData: any = [];
  public timeList: any = [];
  public doctorAvailableFlag;
  public doctorNonAvailableFlag;
  public typeorTime;
  public appTypeTokenFlag;
  public visitSession;
  public booktimeMorn = [];
  public booktimeEve = [];
  public booktimeNoon = [];
  public homecareAppDate;
  public docUnavailableData: any = [];
  public timeListData: any = [];
  public doctorID;
  public doctorProfileImg;
  public hospitalListData: any = [];
  public tempFee;
  public hospitalName;
  public hospSltLocation;
  public hospSltFee;
  public appTypeTimeFlag;
  public apptType;
  public homecareTableData: any = [];
  public homecareTimeList: any = [];
  public homecareVisitSession: any = [];
  public visitTime;
  public aadharNumber;
  public whatsappNumber;
  public clntFatherOrGuardName; 
  public ipaddress;
  public address;
  public patientList=[];
  public filteredArr=[];
  public searchby;
  public typeflag;
  public searchtext;
  public patientid;
  public profileImg;
  public retrvDisabledFlag:boolean;
  public bookFlag;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clientId;
  public clientname;
  public mrno;
 
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clntDOB;
  public dobDisabledFlag: boolean;
  public ageDisabledFlag: boolean;
  public saveFlag: boolean = false;
  public historyFlag: boolean = true;
  public locationUrl: string;
  public locByNameUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public clntFName: string;
  public clntMName: string;
  public clntLName: string;
  public clntAge;
  public clntBloodDesc: string;
  public clntBloodId: string;
  public clntFGName: string;
  public clntIncome: string;
  public clntOccupation: string;
  public clntAddress1!: string | null;
  public clntAddress2!: string | null;
  public clntLocation: string;
  public clntCity: string;
  public clntState: string;
  public clntCountry: string;
  public clntLocationId!: string | undefined;
  public clntCityId!: string | undefined;
  public clntStateId: string;
  public clntCountryId: string;
  public clntZipcode: string;
  public clntContactNo;
  public clntSymptom: string;
  public newsymptoms: any = [];
  public clntDetailsUrl: string;
  public hptlUrl: string;
  public salUrl: string;
  public userTypeFlag: boolean = true;
  public newUserFlag: boolean;
  public existUserFlag: boolean;
  public clientType: string;
  public ageUrl: string;
  public relationUrl: string;
  public userInfo: any;
  public userId: any;
  public hospitalId: string;
  public height;
  public hmeasure: string;
  public weight;
  public wmeasure: string;
  public temperature: string;
  public bmi;
  public bmr;
  public apptId: string;
  public symptomListData = [];
  public symptomList = [];
  public symptomsMainList = [];
  public selectedSymptoms = [];
  public symptoms: string = "";
  public clntSalList: any = [];
  public clntSal: string;
  public hospLocation;
  public hospAddress1;
  public hospAddress2;
  public hospCity;
  public hospCountry;
  public hospState;
  public hospZipcode;
  public hospCityId;
  public hospCountryId;
  public hospStateId;
  public relationList = [];
  public relationship;
  public selectedRelationId: string;
  public currentDatetime: string;
  public currentDate;
  public currentTime;
  public currentYear;
  public clientRegId: string;
  public relationId;
  public subRelId;
  public clntDob;
  public referredDoctor: string;
  public hospName;
  public retrvReferredDoctor: string | null;
  public clntLocationList: any = [];
  public clntCityList: any = [];
  public clntStateList: any = [];
  public clntCountryList: any = [];
  public lastNameReqFlag: boolean;
  public clientList = [];
  public splId: string;
  public clientDetails: any = [];
  public followFlag: boolean;
  public speclization;
  public patientName;
  public ageRequireFlag: boolean;
  public appType: string;
  public nameFlag: boolean;
  public dietType;
  public dietTypeDesc: string;
  public dietTypeList = [];
  public doctorFlag: boolean;
  public doctorName;
  public doctorList = [];
  public doctor;
  public frontDeskUserId;
  public appointmentDate;
  public ynopt2: boolean;
  public ynopt1: boolean;
  public newPOV: any = [];
  public splFlag: boolean = false;
  public specializationList = [];
  public homecare = "0";
  public tempUser: boolean = false;
  public healthPackageID: any;
  public subscribeFlag: boolean = false;
  public healthPackage: any = [];
  private nodob: boolean = false;
  public frontdesktophysio = "";
  public protype;
  public pursopearray = [];
  public type;
  public url;
  public sendproviders;
  public hospital;



  constructor(public http: Http, public serviceAPI: ServerApi, public toastr: ToastrService, public messageService: MenuViewService, public frontdeskservice: MenuViewService, public masterCSdata: MasterCSHelperService, public gservice: CommonDataService, public datepipe: DatePipe) {
    this.clntDetailsUrl = ipaddress.getIp + "usercontroller/cdetnoapph/";
    this.hptlUrl = ipaddress.getIp + "usercontroller/hospdetails";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locByNameUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
    this.relationUrl = ipaddress.getIp.toString() + "gen/getrel";
    
  }

  async ngOnInit() {

    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userInfo = Helper_Class.getInfo();
    this.hospital =Helper_Class.getHospital()
  
    if (masterCSData_Helper.getMasterSpecilizationType() == undefined) {
      await this.masterCSdata.getSpecilizationType();
      this.getSpecilizationType()
    } else {
      this.getSpecilizationType();
    }
    this.getCurrentDate();
   
    var hosp: any = Helper_Class.getHospital();
    this.hospitalId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.clntLocationId = this.userInfo.hospitals[0].location;
    this.clntCityId = this.userInfo.hospitals[0].city;
    this.clntStateId = this.userInfo.hospitals[0].state;
    this.clntCountryId = this.userInfo.hospitals[0].country;
    Helper_Class.setPageFlow("noapp");
    this.getRelationList();
  
    this.tableFilter(this.patientName);
     //this.getreferencesorce();
     this.getCurrentDate();
     this.getSalutations();
    this.getHospitalDetails();
    this.getSalutations();
    this.getCountries("0");
    this.getPackagesFun();
    
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
    
    if (Diet_Helper.getAppFlow() == "Dietician") {
      this.doctorFlag = false;
      this.userId = this.userInfo.user_id;
      this.userId = this.userInfo.user_id;
      this.sendproviders = "dietician";
      this.getDoctorDetails(this.currentDate, this.currentTime)

    } else if (FrontDesk_Helper.getreceptionistcreatepage() != null) {
      if (FrontDesk_Helper.getreceptionistcreatepage() != null) {
        this.doctorFlag = true;
        this.frontDeskUserId = FrontDesk_Helper.getreceptionistcreatepage().user_id;
        this.type = "9"
        this.url = 'appointment/dapp';
        this.sendproviders = "dietician";
        this.getDoctors();
      }

    } else if (FrontDesk_Helper.getphysioapp().physio_id == "1") {
      this.frontdesktophysio = FrontDesk_Helper.getphysioapp().physio_id;
      if (this.frontdesktophysio == "1") {
        this.doctorFlag = true;

        this.frontDeskUserId = FrontDesk_Helper.getphysioapp().user_id;
        this.type = "6"
        this.url = "appointment/cpapp";
        this.sendproviders = "physio";
        this.getDoctors();
      }
    }
    this.searchType('name');
    this.getpurpose();
    this.changeType('client', 'New', 'yes');
    this.changeType('app', 'New', 'no');
  
  }

  back() {
    this.messageService.sendMessage("manageappointments")
  }

  getDoctors() {
    var send_data = {
      country: "IN",
      reg_id: this.frontDeskUserId,
      center_id: this.hospitalId,
      imei: Helper_Class.getIPAddress(),
      type: this.type,
    }
    console.log("send data" + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/guth',
      JSON.stringify({
        country: "IN",
        reg_id: this.frontDeskUserId,
        imei: Helper_Class.getIPAddress(),
        type: this.type,
        center_id: this.hospitalId,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("DOCTOR" + JSON.stringify(obj))
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.users[i].middle_name != "" && obj.users[i].middle_name != undefined) {
                this.doctorName = obj.users[i].first_name + " " + obj.users[i].middle_name + " " + obj.users[i].last_name;
                fname = obj.users[i].first_name; mname = obj.users[i].middle_name; lname = obj.users[i].last_name;
              } else {
                this.doctorName = obj.users[i].first_name + " " + obj.users[i].last_name;
                fname = obj.users[i].first_name; lname = obj.users[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                // Doc_ID: obj.doctors[i].prov_id,
                Doc_ID: obj.users[i].user_id
              });
            }
            this.doctor = this.doctorList[0].Doc_Name;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getSymptoms() {
    var searchString;
    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clntSymptom;
    }
    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.symptomsMainList = [];
              this.symptomList = [];
              this.symptomListData = [];
              this.symptomsMainList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomListData.push(obj.symptoms[i].description);
              }
              this.symptomList = this.symptomListData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.symptomList = [];
              this.symptoms = this.clntSymptom;
            }
          },
          error => {
          })
    }
  }

  selectSymptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptomsMainList.length; j++) {
      if (this.symptomsMainList[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }
    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      dataval.pop();
      this.clntSymptom = dataval.join(",");
      this.clntSymptom = this.clntSymptom + "," + data;
    } else {
      this.clntSymptom = data;
    }
    this.symptomList = [];
  }

  getSalutations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clntSal = "select";
          this.changeSalutation(this.clntSal);
        }
      })
  }


  getpurpose() {

    if (this.frontdesktophysio == "1") {
      this.protype = "physio"
    }
    else if (FrontDesk_Helper.getreceptionistcreatepage() != null) {
      this.protype = "diet";
    }else{
      this.protype = "diet";
    }
    console.log("PURPOSE --se----" + JSON.stringify({
      hptl_clinic_id: this.userInfo.hospitals[0].hptl_clinic_id,
      prov_type: this.protype
    }))
    console.log(" prov_ type" + this.protype)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gvpp ',
      JSON.stringify({
        hptl_clinic_id: this.userInfo.hospitals[0].hptl_clinic_id,
        prov_type: this.protype
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("PURPOSE ------" + JSON.stringify(obj))

          this.pursopearray = obj.visit_purposes
          console.log("PURPOSE ------" + JSON.stringify(obj))
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )

  }

  changevisit(visit_purpose){
    this.visit_purpose=visit_purpose
  }



  getHospitalDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.hptlUrl, JSON.stringify({
      hosp_clinic_id: this.hospitalId
    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined) {
          this.hospLocation = obj.location;
          this.hospAddress1 = obj.address1;
          this.hospAddress2 = obj.address2;
          this.hospCity = obj.city;
          this.hospState = obj.state;
          this.hospCountry = obj.country;
          this.hospZipcode = obj.zipcode;
          this.hospCityId = obj.city_id;
          this.hospStateId = obj.state_id;
          this.hospCountryId = obj.country_id;
          this.hospName = obj.hosp_name;
          if (obj.hptl_logo != undefined) {
            Diet_Helper.setHospital_logo(ipaddress.Ip_with_img_address + obj.hptl_logo);
          }
          Diet_Helper.setHospital_name(obj.hosp_name);
          if (obj.hptl_pres_logo != undefined) {
            Diet_Helper.setHospital_pres_logo(ipaddress.Ip_with_img_address + obj.hptl_pres_logo);
          }
          if (obj.bg_image != undefined) {
            Diet_Helper.setHospital_bg_image(ipaddress.Ip_with_img_address + obj.bg_image);
          }
          Diet_Helper.setHospital_print_template(obj.print_template);
          Diet_Helper.setHospital_location(obj.location);
          if (obj.address2 != null && obj.address2 != "") {
            Diet_Helper.setHospital_address(obj.address1 + " " + obj.address2);
          } else {
            Diet_Helper.setHospital_address(obj.address1);
          }
          Diet_Helper.setHospital_city_zip(obj.city + " - " + obj.zipcode);
          Diet_Helper.setHospital_state_country(obj.state + " , " + obj.country);
        }
      })
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.relationUrl, { headers: headers }).subscribe(
      data => {
        if (data.json().relation != null && data.json().relation != undefined) {
          this.relationList = data.json().relation;
          this.relationship = data.json().relation[0].relationship_name;
          this.selectedRelationId = data.json().relation[0].relationship_id;
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      }
    )
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            this.currentDate = obj.current_date;
            this.appointmentDate = obj.current_date;
            this.currentTime = obj.current_time;
            var get_date = obj.current_date.split('-');
            this.currentYear = get_date[0];
            Helper_Class.setKey(obj.provider + obj.clientid + obj.messageid);
            Helper_Class.setIV(obj.random);
            console.log("************")
            if (Diet_Helper.getAppFlow() == "Dietician") {
              this.getDoctorDetails(this.currentDate, this.currentTime)
            }
            this.getPatientList();
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  getTypes() {
    var send_data;
    this.dietType = undefined;
    if (this.dietTypeDesc.length > 2 && this.dietTypeDesc != undefined) {
      send_data = {
        visit_purpose: this.dietTypeDesc
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gdat', JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.visit_types != undefined && obj.visit_types.length != 0) {
              this.dietTypeList = obj.visit_types;
            } else {
            }
          });
    } else {
      this.dietType = undefined;
      this.dietTypeList = [];
    }
  }


  changeType(type, id, value) {
    if (type == "app") {
      if (value == "yes") {
        this.apptTypeFlag = true;
        this.appType = "New";
        this.userTypeSelect('New');

      } else {
        this.apptTypeFlag = false;
        this.appType = "Follow-up";
        this.userTypeSelect('Follow-up');
      }

    } else {
      if (value == "yes") {
     //   this.clientTypeFlag = true;
        this.clientType = "New";
        this.userTypeSelect('New');
        this.relationId = "1";
        this.appclass = false;
        this.appdisable = true;
        this.appType = "New";
     //   this.searchFlag = true;
        this.apptTypeFlag = true;
      //  this.styleOne = false;
        this.bookFlag = true;
        if (this.userInfo.reg_clnt_app_booking == "1") {
        //  this.readonly = false;
        }

      } else {
     //   this.clientTypeFlag = false;
        this.clientType = "Existing";
        this.appclass = true;
        this.userTypeSelect('Existing');
        this.appdisable = false;
       // this.styleOne = true;
        this.bookFlag = true;
      }
    }
  }


  userTypeSelect(type) {
    this.saveFlag = false;
    if (this.clientType == "New") {
      this.userTypeFlag = false;
      this.newUserFlag = false;
      this.existUserFlag = true;
      this.clientRegId = "0";
      this.relationId = "1";
      this.subRelId = "";
      this.clntGender = "Male";
      this.clntFName = null;
      this.clntMName = null;
      this.clntLName = null;
      this.clntAge = "";
      this.clntGender = "";
      this.clntBloodDesc = "";
      this.clntBloodId = "";
      this.clntFGName = "";
      this.clntIncome = "";
      this.clntOccupation = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null;
      this.clntContactNo = null;
      this.clntSymptom = "";
      this.clntDob = null;
      this.height = "";
      this.hmeasure = "cms";
      this.weight = "";
      this.wmeasure = "kgs";
      this.bmi = ""
      this.temperature = "";
      this.bmi;
      this.hospName = "";
      this.retrvReferredDoctor = null;
      this.clntSymptom = "";
      this.ageDisabledFlag = false;
      this.dobDisabledFlag = false;
      if (this.clntCountryList.length != 0) {
        this.changeCountry(this.clntCountryId, "0");
      } else {
        this.getCountries("0");
      }
      if(this.clntSalList.length != 0){
        this.clntSal = this.clntSalList[0].sal_id;
      }
      
    } else if (this.clientType == "Existing" || this.appType == "Follow-up") {
      this.clntFName = null;
      this.clntMName = null;
      this.clntLName = null;
      this.clntContactNo = null;
      this.clntAge = "";
      this.clntGender = "";
      this.clntBloodDesc = "";
      this.clntBloodId = "";
      this.clntFGName = "";
      this.clntIncome = "";
      this.clntOccupation = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null;
      this.height = "";
      this.hmeasure = "cms";
      this.weight = "";
      this.wmeasure = "kgs";
      this.bmi = "";
      this.relationId = "1";
      this.subRelId = "";
      this.temperature = "";
      this.bmi;
      this.hospName = "";
      this.userTypeFlag = true;
      this.newUserFlag = true;
      this.existUserFlag = false;
      this.retrvReferredDoctor = "";
      this.clntSymptom = "";
      this.clntDob = null;
      this.ageDisabledFlag = false;
      this.dobDisabledFlag = false;
      this.clntLocationId = this.userInfo.hospitals[0].location;
      this.clntCityId = this.userInfo.hospitals[0].city;
      this.clntStateId = this.userInfo.hospitals[0].state;
      this.clntCountryId = this.userInfo.hospitals[0].country;
      this.getCountries("0");
    }
  }

  changeRelation(rel_data) {
    for (var i = 0; i < this.relationList.length; i++) {
      if (this.relationList[i].relationship_name == rel_data) {
        this.selectedRelationId = this.relationList[i].relationship_id;
      }
    }
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else {
      this.clntGender = "";
    }
  }

  changeDoctor(e) {
    this.doctor = e;
    for (var i = 0; i < this.doctorList.length; i++) {
      if (this.doctorList[i].Doc_Name.trim() == this.doctor) {
        this.userId = this.doctorList[i].Doc_ID;
        this.getDoctorDetails(this.currentDate, this.currentTime)
      }
    }
  }

  acitvateSavebtn() {
    if (this.clntFName != null && this.clntLName != null && this.clntContactNo != null) {
      this.saveFlag = true;
    }
  }

  fucusMobile() {
    this.acitvateSavebtn();
  }

  fnameToUpper() {
    if (this.clntFName != undefined) {
      this.clntFName = this.clntFName.toLocaleUpperCase();
    }
  }

  mnameToUpper() {
    if (this.clntMName != undefined) {
      this.clntMName = this.clntMName.toLocaleUpperCase();
    }
  }

  lnameToUpper() {
    if (this.clntLName != undefined) {
      this.clntLName = this.clntLName.toLocaleUpperCase();
      this.clientList = [];
      this.getClientDetails();
    } else {
      this.clntLName == null;
      this.lastNameReqFlag = true;
    }
  }

  selectClient(data) {
    for (var i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].name == data) {
        var mname;
        if (this.clientList[i].middle_name != undefined) {
          mname = this.clientList[i].middle_name;
        }
        var senddata = {
          mobile: this.clientList[i].mobile,
          fname: this.clientList[i].first_name,
          mname: mname,
          lname: this.clientList[i].last_name,
          country: "IN",
          hptl_id: this.hospitalId,
          type: this.clientType,
          is_doc: 0
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.clntDetailsUrl, JSON.stringify(senddata), { headers: headers }).subscribe(
          data => {
            if (data.json().client_reg_id != undefined) {
              this.setClientDetails(data);
            } else {
              this.toastr.error(Message_data.unableToFetchData);
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        )
      }
    }
    this.clientList = [];
  }

  getUserDetails() {
    var name_flag = true;
    if (this.clntFName == undefined && this.clntLName == undefined && this.clntContactNo == undefined) {
      name_flag = false;
    }
    if (name_flag == true) {
      var fname, mname, lname, mnumber;
      if (this.clntFName != null)
        fname = encrypt_decript.Encript(this.clntFName.trim()).toString();
      if (this.clntMName != null && this.clntMName != "")
        mname = encrypt_decript.Encript(this.clntMName.trim()).toString();
      if (this.clntLName != null && this.clntLName != "")
        lname = encrypt_decript.Encript(this.clntLName.trim()).toString();
      if (this.clntContactNo != undefined)
        mnumber = encrypt_decript.Encript(this.clntContactNo).toString();
      var send_data = {
        mobile: mnumber,
        fname: fname,
        mname: mname,
        lname: lname,
        country: "IN",
        hptl_id: this.hospitalId,
        type: this.clientType,
        rel_id: "1",
        is_doc: 0
      }
      send_data = this.removeEmptyStringsFrom(send_data);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clntDetailsUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
          if (data.json() != undefined && data.json().length != 0) {
            this.saveFlag = true;
            this.historyFlag = false;
            this.setClientDetails(data);
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      )
    } else {
      this.toastr.error(Message_data.enterNameOrOtherDet);
    }
  }

  selectDob(e) {
    var l = e.split("-");
    this.clntDob = e;
    if (e != null) {
      this.ageDisabledFlag = true;
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.clntDob),
      curdate: Date_Formate(this.currentDatetime),
      spl: this.splId,
    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined) {
          this.clntAge = obj.value;
          this.acitvateSavebtn();
        }
      })
  }

  changeAge() {
    if (this.clntAge != "") {
      this.dobDisabledFlag = true;
      this.clntDob = "";
    } else {
      this.dobDisabledFlag = false;
    }
    this.nodob = true;
    var dateval = this.currentDate.split("-");
    this.clntDob = (parseInt(dateval[0]) - parseInt(this.clntAge)) + "-01-01";
    this.dobDisabledFlag = false;
    this.acitvateSavebtn();
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender")
        this.clntSal = "5";
      else {
        this.clntSal = "";
      }
    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender")
        this.clntSal = "5";
      else {
        this.clntSal = "";
      }
    }
  }

  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      })
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.clntStateId) {
                this.clntStateId = this.clntStateList[i].state_id;
                this.clntState = this.clntStateList[i].description;
                this.getCities(this.clntStateId, flag);
                break;
              }
            }
          }
        })
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].description;
        this.clntCityList = [];
        this.clntCityId = undefined;
        this.clntLocationId = undefined;
        this.clntLocation = "";
        this.clntZipcode = "";
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.clntCityId) {
                this.clntCityId = this.clntCityList[i].district_id;
                this.clntCity = this.clntCityList[i].description;
                this.getLocations(this.clntCityId);
                break;
              } else {
                this.clntCity = this.clntCityList[0].description;
              }
            }
          }
        })
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].description;
        this.clntLocationId = undefined;
        this.clntLocation = "";
        this.clntZipcode = "";
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, JSON.stringify({ city_id: city }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.clntLocationId) {
                this.clntLocationId = this.clntLocationList[i].location_id;
                this.clntLocation = this.clntLocationList[i].description;
                this.clntZipcode = this.clntLocationList[i].pincode;
                this.clntLocationList = [];
                break;
              }
            }
          }
        })
  }

  changeLocation(locat_desc) {
    this.clntLocationList = [];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locByNameUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
  }

  getClientDetails() {
    if (this.clientType == "New") {
      var clntname = encrypt_decript.Encript(this.clntFName).toString();
      var lname;
      if (this.clntLName != undefined && this.clntLName.trim() != undefined) {
        lname = encrypt_decript.Encript(this.clntLName).toString();
      }
      var mobiledata;
      if (this.clntContactNo != undefined) {
        mobiledata = encrypt_decript.Encript(this.clntContactNo).toString();
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyname/',
        JSON.stringify({
          first_name: clntname,
          last_name: lname,
          mobile: mobiledata,
          type: "New",
          hosp_id: this.hospitalId,
        }),
        { headers: headers })
        .subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            if (obj.clients != null) {
              this.toastr.error(obj.clients);
              if (obj.key == "1") {
                this.lastNameReqFlag = true;
              }
            }
          },
          error => {
          }
        )
    }
  }

  removeEmptyStringsFrom(obj) {
    const clone = { ...obj };
    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }

  setClientDetails(data) {
    this.clientRegId = data.json().client_reg_id;
    Diet_Helper.setClient_Info(data.json());
    this.clientDetails = Diet_Helper.getClient_Info();
    Diet_Helper.setClient_id(this.clientRegId);
    if (data.json().relation_id != undefined) {
      Diet_Helper.setRel_id("1");
      this.relationId = data.json().relation_id;
    } else {
      this.relationId = "1";
    }
    if (data.json().sub_rel_id != undefined) {
      this.subRelId = data.json().sub_rel_id;
    } else {
      this.subRelId = "";
    }
    Diet_Helper.setClient_gender(data.json().gender);
    Diet_Helper.setRefer_by(data.json().ref_by);
    if (data.json().dob != undefined && data.json().dob != "" && data.json().dob != null) {
      var ddata = data.json().dob.split("-");
      this.clntDob = data.json().dob;
      if (this.splId == "10") {
        this.selectDob(this.clntDob);
      }
    }
    else {
      this.dobDisabledFlag = true;
    }
    if (data.json().age != 0) {
      this.clntAge = data.json().age;
      var tempage = data.json().dob;
    } else {
      this.clntAge = "<1";
    }
    Diet_Helper.setClient_age(this.clntAge);
    if (this.splId == "15") {
      var cvs: any = data.json().cvs;
      var rs: any = data.json().rs;
      var kidney: any = data.json().kidney;
      var deformities: any = data.json().deformities;
      var arthritis_type: any = data.json().arthritis_type;
    } else {
      cvs = null;
      rs = null;
      kidney = null;
      deformities = null;
      arthritis_type = null;
    }
    this.clntSal = data.json().salutation;
    this.clntFName = encrypt_decript.Decript(data.json().first_name);
    this.clntLName = encrypt_decript.Decript(data.json().last_name);
    if (data.json().middle_name != null && data.json().last_name != null) {
      this.clntMName = encrypt_decript.Decript(data.json().middle_name);
      this.clntLName = encrypt_decript.Decript(data.json().last_name);
      Diet_Helper.setClient_name(this.clntFName + " " + this.clntMName + " " + encrypt_decript.Decript(data.json().last_name));
    } else if (data.json().last_name != null) {
      Diet_Helper.setClient_name(this.clntFName + " " + encrypt_decript.Decript(data.json().last_name));
      this.clntLName = encrypt_decript.Decript(data.json().last_name);
    }
    else {
      Diet_Helper.setClient_name(this.clntFName);
    }
    this.clntGender = encrypt_decript.Decript(data.json().gender);
    this.clntBloodDesc = data.json().blood_desc;
    this.clntBloodId = data.json().blood_group;
    if (data.json().family_guardian != null) {
      this.clntFGName = encrypt_decript.Decript(data.json().family_guardian);
    }
    if (data.json().family_income != null) {
      this.clntIncome = encrypt_decript.Decript(data.json().family_income);
    }
    if (data.json().Occupation != null && data.json().Occupation != "null" && data.json().Occupation != "") {
      this.clntOccupation = encrypt_decript.Decript(data.json().Occupation);
    }
    this.clntAddress1 = encrypt_decript.Decript(data.json().address1);
    if (data.json().address2 != null) {
      this.clntAddress2 = encrypt_decript.Decript(data.json().address2);
    }
    this.clntLocation = data.json().location_desc;
    this.clntCity = data.json().city_desc;
    this.clntState = data.json().state_desc;
    this.clntCountry = data.json().country_desc;
    this.clntLocationId = data.json().location;
    this.clntCityId = data.json().city;
    this.clntStateId = data.json().state;
    this.clntCountryId = data.json().country;
    this.clntZipcode = encrypt_decript.Decript(data.json().zipcode);
    this.clntContactNo = encrypt_decript.Decript(data.json().mobile);
    this.height = data.json().height;
    this.hmeasure = data.json().hmeasure;
    this.weight = data.json().weight;
    this.wmeasure = data.json().wmeasure;
    this.bmi = data.json().bmi;
    this.apptId = data.json().app_id;
    this.clntBloodDesc = data.json().blood_group;
    this.referredDoctor = data.json().ref_by;
    if (data.json().refer_txt != null && data.json().refer_txt != "null" && data.json().refer_txt != "") {
      this.retrvReferredDoctor = data.json().refer_txt;
    }
    this.hospName = data.json().hosp_name;
    this.speclization = data.json().spl_txt;
    if (data.json().family_guardian != undefined) {
      this.clntFGName = data.json().family_guardian;
    } else {
      this.clntFGName = data.json().care_taker;
    }
    if (this.followFlag == true) {
      this.temperature = data.json().temparature;
      this.clntSymptom = encrypt_decript.Decript(data.json().symptom);
      this.retrvReferredDoctor = data.json().refer_txt;
      this.hospName = data.json().hosp_name;
      this.speclization = data.json().spl_txt;
      this.clntSymptom = data.json().symptom;
      if (data.json().blood_pressure != undefined) {
        var bpre = data.json().blood_pressure.split('/');
      }
    }
    if (this.apptId != undefined) {
      Diet_Helper.setApp_id(this.apptId);
    }
    this.clntLocation = data.json().location_desc;
    this.clntCity = data.json().city_desc;
    this.clntState = data.json().state_desc;
    this.clntCountry = data.json().country_desc;
    var walkin_info: any = [];
    walkin_info = {
      Gender_data: this.clntGender,
      client_name: this.patientName,
      Client_id: this.clientRegId,
      app_id: this.apptId,
      Age_data: this.clntAge,
      Case_Clnt_dob: this.clntDob,
      spl_id: this.splId,
      hptl_clinic_id: this.hospitalId,
      mobile: this.clntContactNo.toString(),
      AppFlow: "Walkin",
      location: this.clntLocation,
      city: this.clntCity,
      state: this.clntState,
      country: this.clntCountry,
      zipcode: this.clntZipcode,
      rel_id: this.relationId,
      sub_id: this.subRelId,
      height: this.height,
      height_measure: this.hmeasure,
      weight: this.weight,
      weight_measure: this.wmeasure,
      bmi: this.bmi,
      bmr: this.bmr,
      temparature: this.temperature,
      symptoms: this.clntSymptom,
      refer_by: this.retrvReferredDoctor,
      cvs: cvs,
      rs: rs,
      kidney: kidney,
      deformities: deformities,
      arthritis_type: arthritis_type,
    };
    Diet_Helper.setWalkin(walkin_info);
  }
  checkClient() {
    if (this.clntContactNo != undefined) {
      this.getClientDetails();
    }
  }

  clearMobile() {
    this.clntContactNo = null;
  }

  save() {
    console.log("this.visitSession" + this.visitSession)
    var create_flag = true; var dob,errortext="Fill ";
    if (this.clntContNumber.length != 10) {
      create_flag = false;
      errortext+=" Mobile"
    }
    if (this.visitSession == undefined) {
      create_flag = false;
      errortext+=" Session"
    }
    if (this.typeorTime == "time") {
      if (this.visitTime == undefined) {
        create_flag = false;
        errortext+=" Appointment time"
      }
    }
    if (this.clntSal == 'select' || this.clntSal == null || this.clntSal == undefined || this.clntSal == "") {
      create_flag = false;
      //this.toastr.error(Message_data.sltSalutation);
      errortext+=" Salutation"
    }
    if (this.clientType == "New") {
      if (this.clntContNumber == undefined){
        create_flag = false;
        errortext+=" Mobile"
      } 
      if(this.clientname == undefined){
        create_flag = false;
        errortext+=" Client name"
      } 
      // if(this.clntLName == undefined){
      //   create_flag = false;
      //   errortext+=" Last name"
      // }
      if(this.clntGender == undefined){
        create_flag = false;
        errortext+=" Gender"
      } 
      if(this.clntLocation == null || this.clntLocation == ""){
        create_flag = false;
        errortext+=" Location"
      } 
      
    } else {
      if (this.clntContNumber == undefined) {
        create_flag = false;
        errortext+=" Mobile"
      }
    }
    if(create_flag == false) {
      this.toastr.error(errortext);
      
    } else {
      this.saveFlag = true;
      this.patientName = null;
      if (this.clntMName != undefined && this.clntMName != null) {
        if (this.clntMName != "") {
          this.patientName = this.clntFName.trim() + " " + this.clntMName.trim() + " " + this.clntLName.trim();
        } else {
          this.patientName = this.clntFName+ " " + this.clntLName;
        }
      } else {
        this.patientName = this.clntFName + " " + this.clntLName;
      }
      Diet_Helper.setClient_name(this.patientName);
      Diet_Helper.setClient_gender(this.clntGender);
      Diet_Helper.setClient_age(this.clntAge);
      var midname;
      if (this.clntMName != undefined) {
        midname = encrypt_decript.Encript(this.clntMName.trim()).toString();
      } else {
        midname = "";
      }
      var occupation;
      if (this.clntOccupation != undefined) {
        occupation = encrypt_decript.Encript(this.clntOccupation.trim()).toString();
      }
      var addr1;
      if (this.clntAddress1 != null && this.clntAddress1 != undefined) {
        addr1 = encrypt_decript.Encript(this.clntAddress1).toString()
      } else {
        addr1 = null;
      }
      var addr2;
      if (this.clntAddress2 != null && this.clntAddress2 != undefined) {
        addr2 = encrypt_decript.Encript(this.clntAddress2).toString()
      } else {
        addr2 = null;
      }
      if (this.clntDob != undefined && this.clntDob != "" && this.clntDob.length != 0 && this.clntDob != null) {
        dob = this.clntDob;
      }
      var curr_datetime = new Date(this.currentDate + " " + this.currentTime);
      // var curr_session;
      // if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
      //   curr_session = "Morning";
      // }
      // if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
      //   curr_session = "Afternoon";
      // }
      // if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
      //   curr_session = "Evening";
      // }
      var send_app_type = this.appType == "New" ? "1" : "2";
      var new_symptoms = [];
      if (this.clntSymptom != undefined && this.clntSymptom != "") {
        if (this.clntSymptom.indexOf(',') > -1) {
          var dataval = this.clntSymptom.trim().split(",");
          for (var i = 0; i < dataval.length; i++) {
            if (this.newsymptoms.length != 0) {
              for (var j = 0; j < this.newsymptoms.length; j++) {
                if (this.newsymptoms[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          }
        } else {
          if (this.newsymptoms.length != 0) {
            for (var j = 0; j < this.newsymptoms.length; j++) {
              if (this.newsymptoms[j] != this.clntSymptom.trim()) {
                new_symptoms.push(this.clntSymptom.trim());
              }
            }
          } else {
            new_symptoms.push(this.clntSymptom.trim());
          }
        }
      }
      var address: any = [];
      address.push({
        address1: addr1,
        address2: addr2,
        location: this.clntLocationId,
        city: this.clntCityId,
        state: this.clntStateId,
        country: this.clntCountryId,
        zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
        type: "pres"
      });
      var save_data;
      var token_flag;
      token_flag = this.typeorTime == "time" ? false : true;
      var time = this.appTypeTokenFlag == false ? this.dataCovert(this.visitTime) : this.currentTime;
      address = (addr1 != undefined && addr1 != null) ? address : undefined;
      var sub = this.tempUser == true ? "1" : "0";
      save_data = {
        prov_id: this.userId,
        client_id: this.clientRegId,
        relation_id: "1",
        salutation: this.clntSal,
        first_name: encrypt_decript.Encript(this.clntFName).toString(),
        middle_name: midname,
        last_name: encrypt_decript.Encript(this.clntLName).toString(),
        age: this.clntAge,
        dob: Date_Formate(dob),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        mem_mobile: encrypt_decript.Encript(this.clntContactNo).toString(),
        hptl_clinic_id: this.hospitalId,
        date: this.currentDatetime,
        symptoms: this.clntSymptom,
        newsymptoms: new_symptoms,
        ref_doc: this.referredDoctor,
        spl_id: this.speclization,
        // occupation: occupation,
        citizenship: encrypt_decript.Encript("Indian").toString(),
        nationality: encrypt_decript.Encript("Indian").toString(),
        address: address,
        hosp_name: this.hospName,
        location: this.hospLocation,
        address1: this.hospAddress1,
        address2: this.hospAddress2,
        city: this.hospCity,
        state: this.hospState,
        country: this.hospCountry,
        zipcode: this.hospZipcode,
        provider: "dietician",
        app_date: this.appointmentDate,
        token: token_flag,
        app_time: time,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        app_type: send_app_type,
      //  visit_purpose: this.dietType,
        package_id: this.healthPackageID,
        subscribe_app: sub,
        visit_purpose:this.visit_purpose,
        symptom: this.clntSymptom,
      };
      save_data.visit_desc = this.dietType == undefined ? this.dietTypeDesc : undefined;
      console.log("get Send Data" + JSON.stringify(save_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "appointment/dapp", JSON.stringify(save_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            console.log("check data "+JSON.stringify(obj))
            if (obj.status != 0) {
              this.toastr.success(Message_data.apptSavedSuccess);
              if (Diet_Helper.getAppFlow() == "Dietician")
                this.messageService.sendMessage("appointment");
              else
                this.frontdeskservice.sendMessage("manageappointments");
            }
          });
    }
  }

  changeCountry(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.clntCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntLocationId = undefined;
        this.clntLocation = "";
        this.clntZipcode = "";
        this.getStates(this.clntCountryId, flag);
      }
    }
  }

  selectPurposeOfVisit(pov) {
    this.dietType = pov.visit_purpose_id;
    this.dietTypeDesc = pov.visit_purpose_desc;
    this.dietTypeList = [];
  }

  selectAppointmentDate(date) {
    this.appointmentDate = date;
  }

  getRefer() {
    this.splFlag = this.referredDoctor != "" ? true : false;
  }

  async getSpecilizationType() {
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    this.specializationList = [];
    if (obj != undefined) {
      for (var i = 0; i < obj.specialization.length; i++) {
        if (obj.specialization[i].spl_name != "-") {
          this.specializationList.push({
            spl_id: obj.specialization[i].spl_id,
            spl_name: obj.specialization[i].spl_name
          });
        }
      }
    }
  }
 
  getDoctorDetails(curt_date, curt_time) {
    var sendDoctorDetailData = {
      prov_id: this.userId,
      type: this.sendproviders,
      // location: this.clntLocation,
      location: this.clntLocationId,
      country: ipaddress.country_code,
      home_care: "0",
    }
    console.log("check docotr list"+JSON.stringify(sendDoctorDetailData))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "search/npsbyid", JSON.stringify(sendDoctorDetailData), { headers: headers }).subscribe(
      data => {
        //var obj = data.json();
        var obj = JSON.parse(data["_body"]);
        console.log("check docotr list"+JSON.stringify(obj))
        if (data.json().np_id != null) {
          this.doctorID = data.json().np_id;
          if (data.json().non_avail != null) {
            this.docUnavailableData = data.json().non_avail;
          }
        }


        if (data.json().consultation != null) {// Normal Consultation
          this.doctorDetailTableData = [];
          this.hospitalListData = [];

          for (var l = 0; l < data.json().consultation.length; l++) {
            this.tempFee = data.json().consultation[l].fees != "0" ? data.json().consultation[l].fees : "NA";

            var temp_address;
            if (this.gservice.get_Array_Contains(this.hospitalListData, "hospital_id", data.json().consultation[l].hospital_id) == false) {
              if (data.json().consultation[l].address2 != null && data.json().consultation[l].address2 != "") {
                temp_address = data.json().consultation[l].address + ", " + data.json().consultation[l].address2;
              } else {
                temp_address = data.json().consultation[l].address;
              }
              this.hospitalListData.push({
                hospital_id: data.json().consultation[l].hospital_id,
                hospname: data.json().consultation[l].hospital,
                Location: data.json().consultation[l].location,
                Address_1: temp_address,
                City: data.json().consultation[l].city,
                fees: this.tempFee,
              });
            }

            this.doctorDetailTableData.push({
              hospitalid: data.json().consultation[l].hospital_id,
              hospital: data.json().consultation[l].hospital,
              location: data.json().consultation[l].location,
              day: this.gservice.get_Day(data.json().consultation[l].day),
              day_num: data.json().consultation[l].day,
              session: this.gservice.get_Session(data.json().consultation[l].session),
              available_from: this.gservice.get_Timeformate(data.json().consultation[l].available_from),
              available_to: this.gservice.get_Timeformate(data.json().consultation[l].available_to),
              fees: this.tempFee,
              TokenorTime: data.json().consultation[l].token_flag,
              Time_Duration: data.json().consultation[l].time_duration,
              Avail_from: data.json().consultation[l].available_from,
              Avail_to: data.json().consultation[l].available_to
            });
            console.log("doctorDetailTableData >>>"+JSON.stringify(this.doctorDetailTableData))
          }

          this.hospitalId = this.hospitalListData[0].hospital_id;
          this.hospitalName = this.hospitalListData[0].Hospname;
          this.hospSltLocation = this.hospitalListData[0].Location;
          this.hospSltFee = this.hospitalListData[0].fees;

          if (this.doctorDetailTableData[0].TokenorTime == "true") {
            this.typeorTime = "type";
            this.appTypeTokenFlag = true;
            this.appTypeTimeFlag = false;
            this.apptType = "Token";
            this.doctorAvailableFlag = false;
            this.sessionAdd(this.hospitalId);
            var parts = curt_date.split('-');
            this.doctorAvailable(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
          } else {
            this.typeorTime = "time";
            this.appTypeTokenFlag = false;
            this.appTypeTimeFlag = true;
            this.doctorAvailableFlag = true;
            this.sessionAdd(this.hospitalId);
            var parts = curt_date.split('-');
            this.doctorAvailable(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); // Available or Unavailable for today  
          }
          this.apptDateChange(curt_date);
        }

        if (data.json().home_consult != null) {// HomeCare Consult
          this.homecareTableData = [];
          this.homecareSessionData = [];
          for (var i = 0; i < data.json().home_consult.length; i++) {
            this.homecareTableData.push({
              Session: this.gservice.get_Session(data.json().home_consult[i].session),
              Avail_from: this.gservice.get_Timeformate(data.json().home_consult[i].available_from),
              Avail_to: this.gservice.get_Timeformate(data.json().home_consult[i].available_to),
              Fees: data.json().home_consult[i].fees,
              Time_Inter: data.json().home_consult[i].time_interval,
              avail_from: data.json().home_consult[i].available_from,
              avail_to: data.json().home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(data.json().home_consult[i].session));
            this.sessionAdd("");
            this.homecareApptDateChange(curt_date);
          }
        }
        this.getApptByDate(this.appointmentDate)
      }, error => { });
  }
  getApptByDate(data) {
    this.appointmentDate = data;
    this.visitSession = false;
    if (data != null) {
      if (this.homecare == "0")
        this.homecareAppDate = data;

      if (this.homecare == "1")
        this.sessionAdd("");
      else
        this.sessionAdd(this.hospitalId);
      this.apptDateChange(Date_Formate(data));
    }
    //this.getDoctorDetails('','')
  }
  sessionAdd(Hospital) {
    this.visitingSessionData = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    if (Hospital == "") {
     
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()) { // Today 
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }
    } else {
   
      var get_date = this.currentDate == this.appointmentDate ? Date_Formate(this.currentDate) : Date_Formate(this.appointmentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      console.log("doctorDetailTableData --"+JSON.stringify(this.doctorDetailTableData))

      for (var a = 0; a < this.doctorDetailTableData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailTableData[a].day_num;
        let dayflag = false;
        if (dayName == this.doctorDetailTableData[a].day) {
          dayflag = true;
        } else if (this.doctorDetailTableData[a].day == "All Days") {
          dayflag = true;
        } else if (sel_day_num != 1 && day_num == 8) {
          dayflag = true;
        }
        console.log("doctorDetailTableData --"+JSON.stringify(this.doctorDetailTableData))
        console.log("dayflag --"+JSON.stringify(dayflag)+get_date)
        if (this.doctorDetailTableData[a].hospitalid == Hospital && dayflag == true) {
          if (this.currentDate.trim() == Date_Formate(get_date).trim()) { // Today 
            if (this.doctorDetailTableData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailTableData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailTableData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailTableData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailTableData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailTableData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
  }
  apptDateChange(appdate) {
    this.timeList = []; // clear app time list
    if ((this.currentDate) != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = appdate.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;

      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (this.doctorDetailTableData[i].hospitalid == this.hospitalId) {
          if (this.doctorDetailTableData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailTableData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailTableData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }

      if (Available_flag_1 == false) {
        this.doctorAvailableFlag = false;
        this.doctorNonAvailableFlag = true;
      } else {
        this.doctorAvailableFlag = true;
        this.doctorNonAvailableFlag = false;
      }

      this.sessionAdd(this.hospitalId);
      if (this.typeorTime == "time") {
        this.visitSessionChangeForTime(appdate);
        this.appTypeTokenFlag = false;
      } else {
        this.visitSessionChange(this.visitSession, appdate);
        this.appTypeTokenFlag = true;
      }
      this.doctorAvailable(Date_Formate(appdate), this.currentTime);
    } else {
      this.sessionAdd(this.hospitalId);
      if (this.typeorTime == "time")
        this.visitSessionChangeForTime(appdate);
      else
        this.visitSessionChange(this.visitSession, appdate);
      this.doctorAvailable((appdate), this.currentTime);
    }
  }
  address1() {
    if (this.clntAddress1 != undefined && this.clntAddress1 != "") {
      this.tempUser = true;
    } else {
      this.tempUser = false;
    }
  }
  // Homecare
  homecareApptDateChange(appdate) {
    this.homecareTimeList = [];
    this.homecareVisitSession = null;
    this.home_Visitsession_change(appdate);
  }
  home_Visitsession_change(appdate) {
    this.homecareTimeList = [];
    var time_t = [];//
    var session;
    for (var j = 0; j < this.visitingSessionData.length; j++) {
      session = "";
      session = this.visitingSessionData[j].description;
      time_t = [];
      for (var i = 0; i < this.homecareTableData.length; i++) {
        if (session == this.homecareTableData[i].Session) {
          var From_Split = this.homecareTableData[i].avail_from.split(':');
          var To_Split = this.homecareTableData[i].avail_to.split(':');
          var parts = (appdate).split('-');
          var fromday = new Date();

          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);

          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);

          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');

          if (this.currentDate == appdate && fromday < today) {
            time_t.push(New_time1);
          } else {
            if (fromday < today) {
              if (this.currentDate != appdate) {
                time_t.push(New_time1);
              }
            }
          }

          var substr = this.homecareTableData[i].Time_Duration;

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.homecareTableData[i].Time_Inter));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);

            if (Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.homecareTableData[i].Avail_to)) {
                time_t.push(New_time);
              }
            }
          }
        }
      }

      if (time_t.length != 0) {
        this.homecareTimeList.push({
          session: session,
          time: time_t
        });
      }
    }
  }
  visitSessionChangeForTime(appdate) {


    var dateval = appdate.split("-");
    var send_data = {
      doc_reg_id: this.userId,
      app_date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
      type: "dietician",
      //session: encrypt_decript.Encript(session)
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "appointment/dbt/", JSON.stringify(send_data), { headers: headers }).subscribe(
      response => {
        var data = response.json();

        if (data != null) {
          if (data.morning != null) {
            this.booktimeMorn = data.morning;
          }
          if (data.evening != null) {
            this.booktimeEve = data.evening;
          }
          if (data.afternoon != null) {
            this.booktimeNoon = data.afternoon;
          }
          this.setTimeSlots(appdate);
        }
        else {
          this.setTimeSlots(appdate);
        }
      })
  }

  visitSessionChange(session, appdate) {
    var session1 = encrypt_decript.Encript(session).toString()
    this.timeList = [];
    if (this.typeorTime == "time") {
      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (session == this.doctorDetailTableData[i].session) {
          var From_Split = this.doctorDetailTableData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailTableData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailTableData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            this.timeList.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              this.timeList.push(New_time1);
            }
          }
          var substr = this.doctorDetailTableData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailTableData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailTableData[i].Avail_to)) {
                this.timeList.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  doctorAvailable(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.doctorDetailTableData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailTableData[i].day);

      if (this.doctorDetailTableData[i].hospital == this.hospName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);

        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailTableData[i].Avail_to.split(':');

        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);

        if (this.doctorDetailTableData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailTableData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(this.getDateMatePicker_calendar(curtdate))) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.checkAvailabilty(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }

    if (Available_flag == false) {
      this.doctorAvailableFlag = false;
      this.doctorNonAvailableFlag = true;

    } else {
      this.doctorAvailableFlag = true;
      this.doctorNonAvailableFlag = false;

    }
  }

  setTimeSlots(appdate) {
    this.timeListData = [];
    var time_t = [];//
    var session;
    for (var j = 0; j < this.visitingSessionData.length; j++) {
      session = "";
      session = this.visitingSessionData[j].description;
      time_t = [];

      for (var i = 0; i < this.doctorDetailTableData.length; i++) {
        if (session == this.doctorDetailTableData[i].session) {
          var From_Split = this.doctorDetailTableData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailTableData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailTableData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            time_t.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              time_t.push(New_time1);
            }
          }
          var substr = this.doctorDetailTableData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailTableData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailTableData[i].Avail_to)) {
                time_t.push(New_time);
              }
            }
          }
        }
      }

      if (time_t.length != 0) {
        if (this.booktimeEve.length != 0) {
          for (var k = 0; k < this.booktimeEve.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeEve[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }
        if (this.booktimeMorn.length != 0) {
          for (var k = 0; k < this.booktimeMorn.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeMorn[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }
        if (this.booktimeNoon.length != 0) {
          for (var k = 0; k < this.booktimeNoon.length; k++) {
            for (var l = 0; l < time_t.length; l++) {
              if (this.booktimeNoon[k] == time_t[l]) {
                time_t.splice(l, 1);
              }
            }
          }
        }

        this.timeListData.push({
          session: session,
          time: time_t
        });
      }
    }
    if (this.timeListData.length != 0 && this.docUnavailableData != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        for (var j = 0; j < this.timeListData.length; j++) {
          if (this.docUnavailableData[i].session == this.timeListData[j].session) {
            var tempTime = this.docUnavailableData[i].from_time.split(":")
            var tempdata = new Date();
            tempdata.setHours(tempTime[0]);
            tempdata.setMinutes(tempTime[1]);
            var getStartingTime = this.datepipe.transform(tempdata, 'hh:mm a');
            var index = this.timeListData[j].time.indexOf(getStartingTime);
            if (index !== -1) {
              this.timeListData[j].time.splice(index, 1);
            }
          }
        }
      }
    }
  }

  checkAvailabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(Date_Formate(get_sel_date));

    if (this.docUnavailableData.length != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        if (this.docUnavailableData[i].full_day != null) {
          if (check_date >= new Date(this.docUnavailableData[i].from_date)
            && check_date <= new Date(this.docUnavailableData[i].to_date)) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.docUnavailableData[i].from_time.split(':');
            var To_Split = this.docUnavailableData[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

            var get_time1 = this.ConvertTimeformat("24", get_f_time);
            var get_time2 = this.ConvertTimeformat("24", get_t_time);

            var get_f_time1 = get_time1.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));

            var get_chk_date = this.getDateMatePicker1(check_date);
            var get_f_date = this.docUnavailableData[i].from_date;
            var get_t_date = this.docUnavailableData[i].from_date;

            if (get_chk_date.toString() == get_f_date.toString()
              && sess == this.docUnavailableData[i].session
              || get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 <= t2))
                Available_flag = false;

              if ((f_time1 >= t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString()
              && get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;

              if ((f_time1 > t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  getDateMatePicker_calendar(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dataCovert(date) {
    const number = moment(date, ["h:mm A"]).format("HH:mm");
    console.log(number);
    return number;
  }

  getPackagesFun() {
    var send_data = {
      type: "dietician",
      centre_id: this.hospitalId
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/ghps", send_data, { headers: headers }).subscribe(
      data => {
        this.healthPackage = data.json().health_packages;
        console.log(" get dier " + JSON.stringify(this.healthPackage))
      },
      error => {
        this.toastr.error("unable to get package details");
      }
    )
  }

  selectSubscribe(action) {
    if (action == "yes") {
      this.subscribeFlag = true;
    } else {
      this.subscribeFlag = false;
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }

  
  getPatientList() {//get patient list
    console.log("check data"+JSON.stringify({
      
      hptl_clinic_id: this.hospitalId,
      country: ipaddress.country_code,
      imei: Helper_Class.getIPAddress(),
      limit: 200,
      filterby: "Patient Id",
      type: "Dietician",
      start_no: this.currentLimit,
      prov_id: this.userInfo.user_id,
    }))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
    JSON.stringify({
      
        hptl_clinic_id: this.hospitalId,
        country: ipaddress.country_code,
        imei: Helper_Class.getIPAddress(),
        limit: 200,
        filterby: "Patient Id",
        type: "Dietician",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("check mkljiu"+JSON.stringify(obj))         
          if (obj.clients != null) {
            this.saveFlag =true;
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });
            }
            Helper_Class.setPatientList(this.patientList);
          }
        },
        error => { });
  }

  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      // this.searchTypeFlag = true;
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by name"
    }
    else if (data == "patientid") {
      // this.searchTypeFlagmoblile = true
      this.typeflag = "patientid";
      this.patientName = "";
      // this.searchType('patient_id')
      this.searchtext = "search by id"
      this.patientid = true;
    }
    else {
      // this.searchTypeFlagid = true;
      this.typeflag = "mobile";
      this.patientName = "";
      this.searchtext = "search by mobile"
    }
  }
  public previousvisits;public basic_detailflag;public ref_source

  retrieveClient(person) {
    if (this.searchby == "patientid") {
      var get_data = {
        user_id: person[0].client_reg_id,
        relation_id: person[0].relation_id,
        sub_rel_id: person[0].sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          FrontDesk_Helper.setClientId(null);
          var obj = JSON.parse(response["_body"]);
         // var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid"+JSON.stringify(obj))

          this.previousvisits=[];

          if (obj.client_id != null) {
            this.basic_detailflag =true
            FrontDesk_Helper.setClientId(obj);
            // Editable Fields
            if (obj.salutation != null) {
              for (var i = 0; i < this.clntSalList.length; i++) {
                if (this.clntSalList[i].sal_desc == obj.salutation) {
                  this.clntSal = this.clntSalList[i].sal_desc;
                }
              }
            }
            if (obj.ref_source_desc != undefined) {
             // for (var i = 0; i < this.ref_sourcearray.length; i++) {
                //if (this.ref_sourcearray[i].lead_desc == obj.ref_source_desc) {
                  this.ref_source = obj.ref_source_desc;
              //  }
            //  }
            }
           
            this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
            if (this.searchby == "patientid") {
              this.clientRegId = person[0].client_reg_id;
              this.relationId = person[0].relation_id;
              this.subRelId = person[0].sub_rel_id;
            }
            else {
              this.clientRegId = person.client_reg_id;
              this.relationId = person.relation_id;
              this.subRelId = person.sub_rel_id;
            }
        
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            if(this.clntMiddleName != ""){
              this.clientname =this.clntSal +"."+ this.clntFirstName+" "+this.clntLastName;
            }else{
              this.clientname = this.clntSal +"."+ this.clntFirstName+" "+this.clntMiddleName +" "+this.clntLastName;
            }
            if(obj.patient_id != undefined){
              this.mrno= obj.patient_id
            }
            // if (obj.ref_source != null) {        console.log("save data = "+JSON.stringify(save_dataapp))

            //   this.ref_source = obj.ref_source;
            //  // this.selectDob(this.clntDOB)

            // } 
            
            if (obj.dob != null) {
              this.clntDOB = Date_Formate(obj.dob);
              this.selectDob(this.clntDOB)

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            if (this.appType != "New") {
              this.clntSymptom = obj.symptom;
            }
       
            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }
         

            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            //whatsapp_number
            if (obj.whatsapp_number != undefined) {
              this.whatsappNumber = encrypt_decript.Decript(obj.whatsapp_number);
            }
            if (obj.referred_by
              != undefined) {
              this.referredDoctor = (obj.referred_by
                );
            }
            // if (obj.emerg_contact1 != undefined) {
            //   this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            // }
            // if (obj.emerg_contact2 != undefined) {
            //   this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            // }
            //emerg_contact1,emerg_contact2
            if (obj.address2 != null && obj.address2 != "") {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2);
              this.address = this.clntAddress1 +""+ this.clntAddress2
            }else{
              this.address = this.clntAddress1 
            }
            this.clntLocation = this.checkData(obj.location_desc);
            this.clntLocationId = this.checkData(obj.location);
            this.clntCountry = this.checkData(obj.country_desc);
            this.clntCountryId = this.checkData(obj.country);
            this.clntState = this.checkData(obj.state_desc);
            this.clntStateId = this.checkData(obj.state);
            this.clntCity = this.checkData(obj.city_desc);
            if (obj.address2 != null && obj.address2 != "") {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
            }
            this.bookFlag = false;
          }
          this.retrvDisabledFlag = true;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getPatientListByName(name) {
    var send_data;
    if (this.searchby == "patientid" && name.length > 8) {
      this.patientList = [];
      this.filteredArr = [];
      send_data = JSON.stringify(
        {
          hptl_clinic_id: this.hospitalId,
          country: ipaddress.country_code,
          type: "patientid",
          name: this.patientName,
          prov_id: this.userInfo.user_id,
          location: "Dietician",
        }
      )
      console.log("verify send_data " + JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            console.log("verify this " + JSON.stringify(obj))
            this.patientList = [];
            this.filteredArr = [];
            if (obj.clients != null) {
              for (var i = 0; i < obj.clients.length; i++) {
                var name;
                if (obj.clients[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }

                this.patientList.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });

                this.filteredArr.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
              }
              this.retrieveClient(this.filteredArr)
            }
          },
          error => { });

    } else if (name.length > 2 && this.searchby != "patientid") {
      this.patientList = [];
      this.filteredArr = [];
      send_data = JSON.stringify({
        hptl_clinic_id: this.hospitalId,
        country: ipaddress.country_code,
        type: this.searchby,
        name: encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),
        prov_id: this.userInfo.user_id,
        location: "front-desk",
      }
)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);;
            console.log("verify this " + JSON.stringify(obj))
            this.patientList = [];
            this.filteredArr = [];
            if (obj.clients != null) {
              for (var i = 0; i < obj.clients.length; i++) {
                var name;
                if (obj.clients[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }

                this.patientList.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });

                this.filteredArr.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
              }
              //Helper_Class.setPatientList(this.patientList);
            }
          },
          error => { });

    } else {
      this.patientList = [];
      this.filteredArr = [];
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
  }
  tableFilter(e) {
    this.patientName = e;
    if (this.patientName != undefined) {
      this.filteredArr = this.patientList.filter(item => item.full_name.toLowerCase().includes(this.patientName.toLowerCase()));
    }
  }
  public visit_purpose;
  public purposeofvisit=[];
  // public clntSymptom;
  // public symptomList=[];
}
