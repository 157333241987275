
<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Cancel appointment</h5>
    <div mat-dialog-actions style="position: absolute;padding: 0;right: 8px;top: -4px;display: block;">
      <!-- <button mat-icon-button  class="btnclose"> -->
        <!-- <span (click)="ClosePopup()" id="close_icd">X</span> -->
        <img src="../../../assets/img/close_icon.png"  (click)="onNoClick()" width="20px" height="20px" id="close_icd"/>
    </div>
  </div>
<div  mat-dialog-content>
    <div class="contanier">
        <div class="row">
            <div class="col-12">
                <div style="margin-top: 10px;padding: 17px;">
                    <textarea class="ipcss" placeholder="Reason for cancellation" name="username" id="canceltxtarea" type="text" required
                    [(ngModel)]="canceltxt"></textarea>
                </div>
                <div mat-dialog-actions style="float: right;">
                    <a (click)="cancelApps()">
                        <button [mat-dialog-close]="canceltxt" cdkFocusInitial mat-button >
                            <img src="../../../assets/ui_icons/buttons/submit_button.svg" style="width: 65px;height: auto;" class="saveimgbtn_doctreg">
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
    
</div>
