import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-neurological',
  templateUrl: './physio-neurological.component.html',
  styleUrls: ['./physio-neurological.component.scss']
})
export class PhysioNeurologicalComponent implements OnInit {
  public neurologicalFlag = "min";
  subscription:Subscription;
  public bodytTypeList = [];
  public bodyType: string;
  public bodyTypeLabel : string;
  public hnLabel : string;
  public shoulderLabel : string;
  public tvLabel : string;
  public lmLabel : string;
  public ankleLabel : string;
  public hipLabel : string;
  public elbowsLabel : string;
  public kneeLabel : string;
  public afLabel : string;
  public trunkLabel : string;
  public phLabel : string;
  public antLabel : string;
  public latLabel : string;
  public csLabel : string;
  public sgLabel : string;
  public amLabel : string;
  public waLabel : string;
  public asisLabel : string;
  public lseLabel : string;
  public psisLabel : string;
  public glutealsLabel : string;
  public ptLabel : string;
  public calfSupLabel : string;
  public sensitivityLabel : string;
  public specificityLabel : string;
  public llmLabel : string;
  public twLabel : string;
  public brLabel : string;
  public strLabel : string;
  public romLabel : string;
  public rmlLabel : string;
  public headLabel: string;
  public bodyTypeFlag: boolean;
  public hnFlag: boolean;
  public shoulderFlag: boolean;
  public tvFlag: boolean;
  public lmFlag: boolean;
  public ankleFlag: boolean;
  public elbowsFlag: boolean;
  public hipFlag: boolean;
  public kneeFlag: boolean;
  public afFlag: boolean;
  public trunkFlag: boolean;
  public phFlag: boolean;
  public antFlag: boolean;
  public latFlag: boolean;
  public csFlag: boolean;
  public sgFlag: boolean;
  public amFlag: boolean;
  public waFlag: boolean;
  public asisFlag: boolean;
  public lseFlag: boolean;
  public psisFlag: boolean;
  public glutealsFlag: boolean;
  public ptFlag: boolean;
  public calfFlag: boolean;
  public sensitivityFlag: boolean;
  public specificityFlag: boolean;
  public llmFlag: boolean;
  public twFlag: boolean;
  public brFlag: boolean;
  public strFlag: boolean;
  public romFlag: boolean;
  public rmlFlag: boolean;
  public headFlag: boolean;
  public headNeckList = [];
  public shoulderList = [];
  public thoracicList = [];
  public lumbarList = [];
  public ankleList = [];
  public elbowList = [];
  public hipList = [];
  public kneeList = [];
  public anfootList = [];
  public trunkList = [];
  public pelvisList = [];
  public postKneeList = [];
  public postAnfootList = [];
  public sittingList = [];
  public headNeck: string;
  public latShoulder: string;
  public thoracic : string;
  public lumbar : string;
  public ankle : string;
  public antShoulder: string;
  public antElbow: string;
  public antHip: string;
  public antKnee: string;
  public antAnkleFoot: string;
  public postHeadNeck: string;
  public postTrunk: string;
  public postPelvis: string;
  public postKnee: string;
  public postAnkleFoot: string;
  public sitAnterior: string;
  public sitLateral: string;
  public supAnt: string;
  public supCervical: string;
  public supShoulder: string;
  public supAbdomen: string;
  public supWaist: string;
  public supAsis: string;
  public supLumbar: string;
  public proAnt: string;
  public proCervical: string;
  public proShoulder: string;
  public proPsis: string;
  public proGluteals: string;
  public proPt: string;
  public proCalf: string;
  public sensitivity: string;
  public specificity: string;
  public legLength: string;
  public tandemWalking: string;
  public boneRestrict: string;
  public softTissue: string;
  public rom: string;
  public restMuscle: string;

  constructor(public dropdown_dialog:MatDialog,public dialog:MatDialog,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService)   {
    this.bodyTypeFlag = true;
    this.hnFlag = true;
    this.shoulderFlag = true;
    this.tvFlag = true;
    this.lmFlag = true;
    this.ankleFlag = true;
    this.elbowsFlag = true;
    this.hipFlag = true;
    this.kneeFlag = true;
    this.afFlag = true;
    this.trunkFlag = true;
    this.phFlag = true;
    this.antFlag = true;
    this.latFlag = true;
    this.csFlag = true;
    this.sgFlag = true;
    this.amFlag = true;
    this.waFlag = true;
    this.asisFlag = true;
    this.lseFlag = true;
    this.psisFlag = true;
    this.glutealsFlag = true;
    this.ptFlag = true;
    this.calfFlag = true;
    this.sensitivityFlag = true;
    this.specificityFlag = true;
    this.llmFlag = true;
    this.twFlag = true;
    this.brFlag = true;
    this.strFlag = true;
    this.romFlag = true;
    this.rmlFlag = true;
    this.headFlag =true;
    this.headNeckList=[{"id":"1","name":"Strait"},{"id":"2","name":"Forward head"},{"id":"3","name":"Flattened lordotic cervical curve"},{"id":"4","name":"Excessive lordotic curve"}];
    this.headNeck = this.headNeckList[0].name;
    this.postHeadNeck= this.headNeckList[0].name;
    this.shoulderList=[{"id":"1","name":"Strait"},{"id":"2","name":"Forward"},{"id":"3","name":"Lumbar lordois"}];
    this.latShoulder = this.shoulderList[0].name;
    this.antShoulder=this.shoulderList[0].name;
    this.thoracicList=[{"id":"1","name":"Strait"},{"id":"2","name":"Kyphosis"},{"id":"3","name":"Pectus excavatum"},{"id":"4","name":"Barrel chest"},{"id":"5","name":"Pectus cavinatum"}];
    this.thoracic = this.thoracicList[0].name;
    this.lumbarList=[{"id":"1","name":"Strait"},{"id":"2","name":"Lordosis"},{"id":"3","name":"Sway back"},{"id":"4","name":"Flat back"}];
    this.lumbar = this.lumbarList[0].name;
    this.ankleList=[{"id":"1","name":"Good"},{"id":"2","name":"Forward"}];
    this.ankle= this.ankleList[0].name;
    this.elbowList=[{"id":"1","name":"Good"},{"id":"2","name":"Elbow hyperextension"},{"id":"3","name":"Distal displacement"},{"id":"4","name":"Stretched ulnar collateral ligament"},{"id":"5","name":"Cubitus varus"}];
    this.antElbow= this.elbowList[0].name;
    this.hipList=[{"id":"1","name":"Good"},{"id":"2","name":"Leteral roration"},{"id":"3","name":"Medial rotation"}];
    this.antHip= this.hipList[0].name;
    this.kneeList=[{"id":"1","name":"Strait"},{"id":"2","name":"External bibial torsion"},{"id":"3","name":"Internal tibial torsion"}];
    this.antKnee= this.kneeList[0].name;
    this.anfootList=[{"id":"1","name":"Good"},{"id":"2","name":"Hallux valgus"},{"id":"3","name":"Hammer toes"}];
    this.antAnkleFoot= this.anfootList[0].name;
    this.trunkList=[{"id":"1","name":"Strait"},{"id":"2","name":"Scoliosis"}];
    this.postTrunk=this.trunkList[0].name;
    this.pelvisList=[{"id":"1","name":"Strait"},{"id":"2","name":"Lateral pelvic tilt"},{"id":"3","name":"Pelvic rotation"},{"id":"4","name":"Abducted hip"}];
    this.postPelvis=this.pelvisList[0].name;
    this.postKneeList=[{"id":"1","name":"Good"},{"id":"2","name":"Genu varum"},{"id":"3","name":"Genu Valgum"}];
    this.postKnee=this.postKneeList[0].name;
    this.postAnfootList=[{"id":"1","name":"Good"},{"id":"2","name":"Pes planus"},{"id":"3","name":"Pes Cavus"}];
    this.postAnkleFoot=this.postAnfootList[0].name;
    this.sittingList=[{"id":"1","name":"Properly balanced"},{"id":"2","name":"Distrubing balanced"}];
    this.sitAnterior=this.sittingList[0].name;
    this.sitLateral=this.sittingList[0].name;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.neurologicalFlag = message;
      }
    });
    var objFields = Physio_Helper.getHopitalfieldsRet();
    for (var i = 0; i < objFields.length; i++) {
      if (objFields[i].page_name == "Neurological") {
        if (objFields[i].fields != undefined) {
          if (objFields[i].fields != undefined) {
            for (var j = 0; j < objFields[i].fields.length; j++) {
              if (objFields[i].fields[j].field_name == "Body type") {
                this.bodyTypeLabel = objFields[i].fields[j].field_name;
                this.bodyTypeFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Head and neck") {
                this.hnLabel = objFields[i].fields[j].field_name;
                this.hnFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Shoulder") {
                this.shoulderLabel = objFields[i].fields[j].field_name;
                this.shoulderFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Thoracic vertebrae") {
                this.tvLabel = objFields[i].fields[j].field_name;
                this.tvFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Lumbar vertebrae") {
                this.lmLabel = objFields[i].fields[j].field_name;
                this.lmFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Ankle") {
                this.ankleLabel = objFields[i].fields[j].field_name;
                this.ankleFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Elbows") {
                this.elbowsLabel = objFields[i].fields[j].field_name;
                this.elbowsFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Hip") {
                this.hipLabel = objFields[i].fields[j].field_name;
                this.hipFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Knee") {
                this.kneeLabel = objFields[i].fields[j].field_name;
                this.kneeFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Ankle and foot") {
                this.afLabel = objFields[i].fields[j].field_name;
                this.afFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Trunk") {
                this.trunkLabel = objFields[i].fields[j].field_name;
                this.trunkFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Pelvis and Hip") {
                this.phLabel = objFields[i].fields[j].field_name;
                this.phFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Anterior") {
                this.antLabel = objFields[i].fields[j].field_name;
                this.antFlag = false;
              }            
              if (objFields[i].fields[j].field_name == "Lateral") {
                this.latLabel = objFields[i].fields[j].field_name;
                this.latFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Head") {
                this.headLabel = objFields[i].fields[j].field_name;
                this.headFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Cervical spine") {
                this.csLabel = objFields[i].fields[j].field_name;
                this.csFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Shoulder girdle") {
                this.sgLabel = objFields[i].fields[j].field_name;
                this.sgFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Abdomen muscles") {
                this.amLabel = objFields[i].fields[j].field_name;
                this.amFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Waist angle") {
                this.waLabel = objFields[i].fields[j].field_name;
                this.waFlag = false;
              }
              if (objFields[i].fields[j].field_name == "ASIS level") {
                this.asisLabel = objFields[i].fields[j].field_name;
                this.asisFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Lumbar spine extension") {
                this.lseLabel = objFields[i].fields[j].field_name;
                this.lseFlag = false;
              }
              if (objFields[i].fields[j].field_name == "PSIS level") {
                this.psisLabel = objFields[i].fields[j].field_name;
                this.psisFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Gluteals") {
                this.glutealsLabel = objFields[i].fields[j].field_name;
                this.glutealsFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Posterior thigh") {
                this.ptLabel = objFields[i].fields[j].field_name;
                this.ptFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Calf") {
                this.calfSupLabel = objFields[i].fields[j].field_name;
                this.calfFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Sensitivity") {
                this.sensitivityLabel = objFields[i].fields[j].field_name;
                this.sensitivityFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Specificity") {
                this.specificityLabel = objFields[i].fields[j].field_name;
                this.specificityFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Leg length measurement") {
                this.llmLabel = objFields[i].fields[j].field_name;
                this.llmFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Tandem walking") {
                this.twLabel = objFields[i].fields[j].field_name;
                this.twFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Bone restrictions") {
                this.brLabel = objFields[i].fields[j].field_name;
                this.brFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Soft tissue restrictions") {
                this.strLabel = objFields[i].fields[j].field_name;
                this.strFlag = false;
              }
              if (objFields[i].fields[j].field_name == "ROM") {
                this.romLabel = objFields[i].fields[j].field_name;
                this.romFlag = false;
              }
              if (objFields[i].fields[j].field_name == "Resting muscle length") {
                this.rmlLabel = objFields[i].fields[j].field_name;
                this.rmlFlag = false;
              }
            }
          }
        }
      }
    }
    this.getBodyTypes();
    this.displayData();
  }

  displayData(){
    if (Physio_Helper.getRet() != undefined) {
      this.bodyType=Physio_Helper.getRet().body_type;
      this.headNeck=Physio_Helper.getRet().lat_head_neck;
      this.latShoulder=Physio_Helper.getRet().lat_shoulder;
      this.thoracic=Physio_Helper.getRet().lat_thoracic;
      this.lumbar=Physio_Helper.getRet().lat_lumbar;
      this.ankle=Physio_Helper.getRet().lat_ankle;
      this.antShoulder=Physio_Helper.getRet().ant_shoulder;
      this.antElbow=Physio_Helper.getRet().ant_elbow;
      this.antHip=Physio_Helper.getRet().ant_hip;
      this.antKnee=Physio_Helper.getRet().ant_knee;
      this.antAnkleFoot=Physio_Helper.getRet().ant_ankle_foot;
      this.postHeadNeck=Physio_Helper.getRet().post_head_neck;
      this.postTrunk=Physio_Helper.getRet().post_trunk;
      this.postPelvis=Physio_Helper.getRet().post_pelvis;
      this.postKnee=Physio_Helper.getRet().post_knee;
      this.postAnkleFoot=Physio_Helper.getRet().post_ankle_foot;
      this.sitAnterior=Physio_Helper.getRet().sit_anterior;
      this.sitLateral=Physio_Helper.getRet().sit_lateral;
      this.supAnt=Physio_Helper.getRet().sup_ant;
      this.supCervical=Physio_Helper.getRet().sup_cervical;
      this.supShoulder=Physio_Helper.getRet().sup_shoulder;
      this.supAbdomen=Physio_Helper.getRet().sup_abdomen;
      this.supWaist=Physio_Helper.getRet().sup_waist;
      this.supAsis=Physio_Helper.getRet().sup_asis;
      this.supLumbar=Physio_Helper.getRet().sup_lumbar;
      this.proAnt=Physio_Helper.getRet().pro_ant;
      this.proCervical=Physio_Helper.getRet().pro_cervical;
      this.proShoulder=Physio_Helper.getRet().pro_shoulder;
      this.proPsis=Physio_Helper.getRet().pro_psis;
      this.proGluteals=Physio_Helper.getRet().pro_gluteals,
      this.proPt=Physio_Helper.getRet().pro_pt;
      this.proCalf=Physio_Helper.getRet().pro_calf;
      this.sensitivity=Physio_Helper.getRet().sensitivity;
      this.specificity=Physio_Helper.getRet().specificity;
      this.legLength=Physio_Helper.getRet().leg_length;
      this.tandemWalking=Physio_Helper.getRet().tandem_walking;
      this.boneRestrict=Physio_Helper.getRet().bone_restrict;
      this.softTissue=Physio_Helper.getRet().soft_tissue;
      this.rom=Physio_Helper.getRet().rom;
      this.restMuscle=Physio_Helper.getRet().rest_muscle;
    }
  }

  getBodyTypes() {
    this.bodytTypeList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',
      JSON.stringify({
        gender: "Male",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.bodytTypeList = obj.body_types;
          this.bodyType = this.bodytTypeList[0].bodytype_name;
        },
        error => {
           this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  sendData(){
    var neurodata = null;
    neurodata = {
      body_type:this.bodyType,
      lat_head_neck:this.headNeck,
      lat_shoulder:this.latShoulder,
      lat_thoracic:this.thoracic,
      lat_lumbar:this.lumbar,
      lat_ankle:this.ankle,
      ant_shoulder:this.antShoulder,
      ant_elbow:this.antElbow,
      ant_hip:this.antHip,
      ant_knee:this.antKnee,
      ant_ankle_foot:this.antAnkleFoot,
      post_head_neck:this.postHeadNeck,
      post_trunk:this.postTrunk,
      post_pelvis:this.postPelvis,
      post_knee:this.postKnee,
      post_ankle_foot:this.postAnkleFoot,
      sit_anterior:this.sitAnterior,
      sit_lateral:this.sitLateral,
      sup_ant:this.supAnt,
      sup_cervical:this.supCervical,
      sup_shoulder:this.supShoulder,
      sup_abdomen:this.supAbdomen,
      sup_waist:this.supWaist,
      sup_asis:this.supAsis,
      sup_lumbar:this.supLumbar,
      pro_ant:this.proAnt,
      pro_cervical:this.proCervical,
      pro_shoulder:this.proShoulder,
      pro_psis:this.proPsis,
      pro_gluteals:this.proGluteals,
      pro_pt:this.proPt,
      pro_calf:this.proCalf,
      sensitivity: this.sensitivity,
      specificity: this.specificity,
      leg_length: this.legLength,
      tandem_walking: this.tandemWalking,
      bone_restrict: this.boneRestrict,
      soft_tissue: this.softTissue,
      rom: this.rom,
      rest_muscle: this.restMuscle,
    }
    Physio_Helper.setPhysioNeuro(neurodata);
  }
}
