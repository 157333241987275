<div class="row" style="width: 100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle"> Bill Create </h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo" style="cursor: pointer;" /> &nbsp;
            <img  *ngIf="printbutton"src="../../../assets/img/printer.svg" width="25px" height="25px" 
              style="cursor: pointer;" (click)="print_area('print')"/> &nbsp;
            <img *ngIf="saveFlag && newbutton" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="saveBill()"
              class="saveimgbtn_inpatinfo" style="cursor: pointer;" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Filter by<br>
              <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                      disableOptionCentering>                               
                <option value="Date">Date</option>
                <option value="Name">Name</option>
                <option value="Patient_ID">MR.No</option>
                <option value="Mobile_No">Mobile No</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" > 
            <mat-label class="matlabel" >Ratecard<br>
              <select required class="ipcss" [(ngModel)]="rate_card" disableOptionCentering (change)="getConcession()">
                <option *ngFor="let RateCard of RateCardArray" value={{RateCard.rate_card_id}}> {{RateCard.rate_card_desc}} </option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel" [hidden]="toFlag">Date<br>
              <input type="date" class="ipcss" style="height: 25px;" id="appt_date" (change)="OnDateChangedto(date)"
              [hidden]="fromFlag" [(ngModel)]="date" max="{{currentDate}}" #matInput style="background: #fff;">
            </mat-label>     

            <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
              <input #focusFName id="focusFName" matInput class="ipcss" style="height: 25px !important;" type="text" [hidden]="firstNameFlag" required
                [(ngModel)]="firstName" (blur)="fnameToUpper()"  />
            </mat-label>
            
            <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
              <input #focusMRNo id="focusMRNo" class="ipcss" style="height: 25px;" type="text" [hidden]="uhidFlag" required
                [(ngModel)]="patientId" (change)="getPatientListByMRNo(patientId)"/>
            </mat-label>

            <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
              <input #focusMobileNo  id="focusMobileNo" matInput class="ipcss" style="height: 25px;" type="text" [hidden]="mobileFlag" required
                [(ngModel)]="mobileNo" (change)="getPatientListByMobile_no(mobileNo)"/>
            </mat-label>                            
          </div> 

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag"> 
            <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
              <input matInput class="ipcss" style="height: 25px !important;" type="text" [hidden]="firstNameFlag" required
                [(ngModel)]="lastName" (blur)="lnameToUpper()" />
            </mat-label>
          </div>

          <div class="col-1  p-0" [hidden]="!mr_no_flag"> 
            <mat-label class="matlabel">
              <a (click)="searchPatient()" ><img src="../../../assets/ui_icons/search_icon.svg"
                  class="searchButton" /></a> 
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" *ngIf="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()" disableOptionCentering>
                <option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}> {{pay.pay_desc}} </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="insurFlag">
            <mat-label class="matlabel" [hidden]="insurFlag">Insurer<br>
              <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering [required]="insreqflag">
                <option *ngFor="let ins of insurerancListArray" value={{ins.insurer_id}}> {{ins.insurer_desc}} </option>
              </select>
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="cardreqflag">
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="transactionFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" [required]="transreqflag"/>
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="chequereqflag"/>
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" [required]="chequereqflag"/>
            </mat-label>
          </div>    
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2" >
            <mat-label class="matlabel">Patient Name<br>
              <select required class="ipcss" [(ngModel)]="appoint" disableOptionCentering (change)="changeAppID()">
                <option *ngFor="let billcreate of billListArray" value="{{billcreate.doc_app_id}}">
                  {{billcreate.diagid_name}}
                </option>
              </select>
            </mat-label>
          </div>
        </div>

        <div class="col-12" *ngIf="createpage_flag">
          <div class="cover_div">
            <div class="header_lable">Patient details</div>
              <div class="content_cover">             
                <div class="row">
                  <div class="col-2" style="text-align: center;">
                    <img src="{{this.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="profileimage">
                  </div>
                  <div class="col-10">
                    <div class="row">                                                       
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 90px; font-weight: 500 !important;">
                                MR No</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mrno}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Patient name</td>
                              <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{clnt_saldesc}}{{patient_name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Age/Gender</td>
                              <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3" *ngIf="mobile_no != undefined && mobile_no != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Mobile</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>      
                      <div class="col-3" *ngIf="doctor_name != '' && doctor_name != undefined">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Doctor</td>
                              <td class="head-td" style="width: 10px; vertical-align: top;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{doctor_name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                DOA</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{pat_reg_date}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>        
                      <div class="col-3" *ngIf="em_contact != '' && em_contact != undefined">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Emerg contact</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{em_contact}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>    
                      <div class="col-3" *ngIf="wardName != undefined && wardName != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Ward/Bed</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{wardName}} <span *ngIf="bed_no != undefined && bed_no != ''">/</span> {{bed_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                     
                      <div class="col-3" *ngIf="discharge_date != undefined && discharge_date != '' && discharge_time != undefined && discharge_time != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                Discharge Date/Time</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{discharge_date}} {{discharge_time}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                              
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                           <tbody>
                              <tr class="head-tr">
                                 <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Address</td>
                                 <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                 <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{patient_address1}} {{patient_address2}} {{Location}} {{city}} {{zipcode}} {{state}} {{country}}
                                </td>
                              </tr>                 
                           </tbody>
                        </table>
                      </div>
                    </div>      
                  </div>
                </div>               
              </div>
          </div>
        </div>

        <div class="row" style="margin-top: 10px;">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <div *ngIf="addBillArray.length" style="margin: 10px 0;">
              <div class="dig_table_overflow">
                <div class="table-responsive">
                  <table class="table table-hover table-dynamic" id="card_tbl">
                    <thead>
                      <tr style="height: 25px;">
                        <th style="width: 10%;">Sno</th>
                        <th style="width: 20%">Test</th>
                        <th *ngIf="paytypreq" style="width: 5%" >Payment type</th>
                        <th style="width: 5%">Fee</th>
                        <th [hidden]="discount_flag" style="width: 5%">Discount(%)</th>
                        <th [hidden]="bill_qty_flag && !inpatient_flag" style="width: 2%">Quantity</th>
                        <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 6%">Amount</th>                     
                        <th style="width: 10%">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let person of addBillArray" class="tdvalues">
                        <td style="text-align: center">{{person.sno}}</td> 
                        <td style="font-size: 12px !important;">{{person.test_name}}</td>                        
                        <td *ngIf="paytypreq">{{person.pay_type}}</td>
                        <td [hidden]="!discount_flag" style="text-align: right;">{{person.amount_txt}}</td>
                        <td [hidden]="discount_flag">
                          <input type="text" style="width: 75%;text-align: right !important;margin-left: 10px;"  class="ipcss order_width"
                          [(ngModel)]="person.amount_txt"
                          #matInput (keyup)="changeAmount(person,0)" /> 
                        </td>
                        <td [hidden]="discount_flag">                       
                          <input type="text" style="width: 75%;text-align: right !important;margin-left: 15px;" class="ipcss order_width"
                          [(ngModel)]="person.concession"
                          #matInput (keyup)="changeAmount(person,0)" /> 
                        </td> 
                        <td [hidden]="bill_qty_flag && !inpatient_flag">                       
                          <input type="text" style="text-align: right !important;width: 75% !important;margin-left: 10px;" class="ipcss order_width" 
                          [(ngModel)]="person.quantity"
                          #matInput (keyup)="changeAmount(person,0)" /> 
                        </td>
                        <td [hidden]="multiply_flag" style="text-align: right !important;">{{person.total}}</td>
                        <td [hidden]="discount_flag">
                          <input type="text" style="width: 75%;text-align: right;margin-left: 10px" class="ipcss order_width"
                          [(ngModel)]="person.fee" (keyup)="changeAmount(person,2)"
                          #matInput />
                        </td>
                        <td class="delete">                         
                          <a *ngIf="deletebutton" (click)="deleteBill(person)"> <img
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit"></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row " style="margin-top: 10px;">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" style="margin-top: -5px !important;" *ngIf="!paytypreq" >
              <div class="row">
                <div class="col-2">
                  <mat-label class="matlabel">Credit bill
                      <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                      </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-5" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-6">
                      <select class="ipcss " [(ngModel)]="credit_type" disableOptionCentering>
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}} </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4" [hidden]="credit_flag">
                  <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput maxlength="50" />
                </div>
              </div>
              <div class="row" [hidden]="!credit_flag">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" *ngIf="!paytypreq">
                  <mat-label class="matlabel">Payment mode<br>
                    <mat-select class="ipcss" [(ngModel)]="payType1" disableOptionCentering>
                      <mat-option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
        
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" *ngIf="!paytypreq">
                  <mat-label class="matlabel">Amount<br>
                    <input class="ipcss billing_text_align align_right" [(ngModel)]="amountval"  matInput maxlength="12"  />
                  </mat-label>
                </div>

                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2" *ngIf="!paytypreq">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align btn-outline-success rounded"
                      (click)="addPaymentDetails()" style="float: right;margin-top: 20px;cursor: pointer;"/>
                  </mat-label>
                </div>
              </div>

              <!--CashDenomination Popup Start-->                                
              <div *ngIf="showModal">
                <div class="modal fade show"  id="cashModalLabel" tabindex="-1" role="dialog" aria-labelledby="cashModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">                   
                      <div class="modal-body">                                           
                        <div *ngFor="let denomination of cashDenomination" class="denomination-container">
                          <label class="denomination-label"> {{ denomination.value }} Rupees: </label>
                          <input type="number" class="denomination-input" [(ngModel)]="denomination.count" (ngModelChange)="calculateTotalAmount()" />
                        </div>                        
                        <div>
                          <strong>Total Amount &nbsp;:</strong><span style="font-size: 14px;font-weight: 500;">&nbsp;{{ totalAmount }}</span>
                        </div>                                                 
                      </div>
                      <div class="modal-footer">                       
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" style="cursor: pointer;" (click)="saveCashDenomination()"/>                       
                        <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo" style="cursor: pointer;" (click)="showModal = false" />       
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-backdrop fade show"></div>
              </div>
              <!--CashDenomination Popup End-->
              <div class="row rowchanges" *ngIf="!paytypreq">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;" class="payment_array_style">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;text-align: right;" class="payment_array_style">{{pay.amount}}</td>&nbsp;
                    <td>
                      <img *ngIf="editbutton" src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img *ngIf="deletebutton" src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-2"></div>
          <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-3">  
            <div style="margin-top: -10px;">      
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel ">Include GST &nbsp;&nbsp; </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="includeGST(inGST)">
                  </mat-checkbox>
                </div>
                <div *ngFor="let gsttax of GSTArray">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label style="
                      border-radius: 5px;text-align: right;" class="ipcss">
                        {{gsttax.Gst_amount}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>                           
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previousBalFlag || balance == '0.00'">
                  <mat-label class="matlabel  ">Previous balance </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previousBalFlag || balance == '0.00'">
                  <input class="ipcss"  style="border-radius: 5px;text-align: right;" matInput text-right [(ngModel)]="balance" maxlength="10" disabled="true" />
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> Total </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" style="border-radius: 5px;text-align: right;" matInput text-right 
                    disabled="true" [(ngModel)]="totalBillAmount" />
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> Discount <span [hidden]="!discount_flag">({{concession}} %)</span> </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" style="border-radius: 5px;text-align: right;" matInput text-right 
                    [(ngModel)]="discount_amount" (keyup)="changeDiscount()"/>
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel ">Bill amount </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" style="border-radius: 5px;text-align: right;" matInput text-right
                    disabled="true" [(ngModel)]="finalAmount" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel "  *ngIf="advance != 0">Advance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" *ngIf="advance != 0" matInput text-right  [(ngModel)]="advance"
                    maxlength="10" style="border-radius: 5px;text-align: right;" [disabled]="true"/> 
                </div>
                
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel ">Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" matInput text-right (keyup)="changePaid()" [(ngModel)]="paidBill"
                    maxlength="10" style="border-radius: 5px;text-align: right;" />
                </div>
                
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel" [hidden]="balanceFlag">Balance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss" [disabled]="true" [hidden]="balanceFlag" matInput text-right [(ngModel)]="remaingBalance"
                    maxlength="10" style="border-radius: 5px;text-align: right;" />
                </div>
              </div>
            </div>
          </div>
        </div>  
        
        <div [hidden]="true" #printbanner id="printbanner">
          <table style="border:'0';width:100%">    
           <thead>
            <tr>
              <th style="width:100%;">                                 
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                  <div style="width: 100%; height: 205px !important;"> 
                    <div style="width: 100%;">
                      <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                    </div>                                                             
                    <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                      <tbody>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                          <td>: {{clnt_saldesc}}{{patient_name}} </td>
                          <td style="padding-left: 5px;"><strong>Address</strong></td>
                          <td>: {{patient_address1}} {{patient_address2}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                          <td>: {{clnt_age}} / {{gender}}</td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{Location}} {{city}} {{zipcode}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td>: {{doctor_name}}</td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{state}} {{country}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                          <td>: {{billId}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                          <td>: {{currentDate}}&nbsp;{{timevalue}}</td>
                        </tr>                       
                        <tr *ngIf="credit_type != undefined && credit_type != ''">
                          <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                          <td>: {{credit_person}}</td>
                        </tr>                        
                        <tr>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td>
                          </td> 
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td *ngIf="barcode_flag">
                            <img [style]="barcode_style"
                              src={{barcode_url}} /></td> 
                        </tr>                         
                      </tbody>
                    </table>
                  </div>
                </div>               
              </th>
            </tr>
           </thead>   
           <tbody>  
             <tr>  
               <td width="100%">  
                <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b>Bill/Receipt</b></p>
                <div class="bill_border">
                  <div>                                  
                    <div [hidden]="addBillArray.length === 0" style="margin-left: 20px;margin-right: 10px;">                      
                      <div class="row">
                        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <thead>
                            <tr>
                              <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 360px;border: 1px solid black;border-collapse: collapse;"><b>Test</b></th>
                              <th *ngIf="paytypreq" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Payment type</b></th>
                              <th style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Discount(%)</b></th>
                              <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                              <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>                     
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let billprinttable of addBillArray">
                              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.sno}}</td>
                              <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.test_name}}</td>
                              <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" *ngIf="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px"
                                data-th="Fee" [hidden]="!bill_qty_flag"> {{billprinttable.amount_txt}} </td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee" [hidden]="bill_qty_flag"> {{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td>  
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="discount_flag"> {{billprinttable.fee}} </td>                                         
                            </tr>
                          </tbody>                         
                        </table>
                      </div>
                    </div>             
                    <div style="width: 100%;">
                      <table style="margin-bottom: -20px;">
                        <tr>
                          <td style="vertical-align: top; width: 26%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                              </tr>
                            </table>      
                          </td>                           
                          <td style="vertical-align: top;width: 31%;">
                            <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">                             
                              <tr [hidden]="GSTArray.length==0" *ngFor="let gsttax of GSTArray">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                </td>
                              </tr> 
                              <tr [hidden]="previousBalFlag || balance == '0.00'"> 
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == finalAmount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != '0.00'">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{finalAmount}}</td>
                              </tr>                             
                              <tr  *ngIf="advance != 0" >
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{advance}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paidBill}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount || remaingBalance <= '0.00'">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaingBalance}}</td>
                              </tr> 
                            </table>
                            <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">                             
                              <tr [hidden]="GSTArray.length==0" *ngFor="let gsttax of GSTArray">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                </td>
                              </tr> 
                              <tr [hidden]="previousBalFlag || balance == '0.00'"> 
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == finalAmount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != '0.00'">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{finalAmount}}</td>
                              </tr>                             
                              <tr  *ngIf="advance != 0" >
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{advance}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paidBill}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount || remaingBalance <= '0.00'">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaingBalance}}</td>
                              </tr> 
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                      <div class="row">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paidBill != '0.00'">
                          <tr>
                            <td><strong>In Words:&nbsp;</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>           
               </td>
             </tr>
           </tbody>
           <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
           </tfoot> 
          </table>
        </div>

        <div [hidden]="true"  #printnoheader id="printnoheader">
          <table style="border:'0';width:100%">  
           <thead>
            <tr>
              <th style="width:100%;">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                  <div [style]="nobanner_style">
                    <div style="height: 5px !important;"></div>
                    <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                      <tbody>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                          <td>: {{clnt_saldesc}}{{patient_name}} </td>
                          <td style="padding-left: 5px;"><strong>Address</strong></td>
                          <td>: {{patient_address1}} {{patient_address2}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                          <td>:  {{clnt_age}} / {{gender}}</td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{Location}} {{city}} {{zipcode}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td>: {{doctor_name}}</td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{state}} {{country}} </td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                          <td>: {{billId}}</td>
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                          <td>: {{currentDate}}&nbsp;{{timevalue}}</td>                     
                        </tr>
                        <tr *ngIf="credit_type != undefined && credit_type != ''">
                          <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                          <td>: {{credit_person}}</td>                            
                        </tr>
                        <tr>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td>
                          </td> 
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td *ngIf="barcode_flag">
                            <img [style]="barcode_style"
                              src={{barcode_url}} /></td>
                        </tr>          
                      </tbody>
                    </table>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
           <tbody>  
             <tr>  
               <td width="100%">  
                <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b>Bill/Receipt</b></p>
                <div class="bill_border">
                  <div>                                     
                    <div [hidden]="addBillArray.length === 0" style="margin-left: 20px;margin-right: 10px;">                      
                      <div class="row">
                        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <thead>
                            <tr>
                              <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 360px;border: 1px solid black;border-collapse: collapse;"><b>Test</b></th>
                              <th *ngIf="paytypreq" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Payment type</b></th>
                              <th style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Discount(%)</b></th>
                              <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                              <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>                     
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let billprinttable of addBillArray">
                              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.sno}}</td>
                              <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.test_name}}</td>
                              <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" *ngIf="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px"
                                data-th="Fee" [hidden]="!bill_qty_flag"> {{billprinttable.amount_txt}} </td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee" [hidden]="bill_qty_flag"> {{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                              <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td>  
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="discount_flag"> {{billprinttable.fee}} </td>                                         
                            </tr>
                          </tbody>                       
                        </table>
                      </div>
                    </div>
                   
                    <div style="width: 100%;">
                      <table style="margin-bottom: -20px;">
                        <tr>
                          <td style="vertical-align: top; width: 26%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                              </tr>
                            </table>      
                          </td>                           
                          <td style="vertical-align: top;width: 31%;">
                            <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">                             
                              <tr [hidden]="GSTArray.length==0" *ngFor="let gsttax of GSTArray">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                </td>
                              </tr> 
                              <tr [hidden]="previousBalFlag || balance == '0.00'"> 
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == finalAmount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != '0.00'">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{finalAmount}}</td>
                              </tr>                             
                              <tr  *ngIf="advance != 0" >
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{advance}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paidBill}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount || remaingBalance <= '0.00'">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaingBalance}}</td>
                              </tr> 
                            </table>
                            <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">                             
                              <tr [hidden]="GSTArray.length==0" *ngFor="let gsttax of GSTArray">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong> % </th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                </td>
                              </tr> 
                              <tr [hidden]="previousBalFlag || balance == '0.00'"> 
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == finalAmount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != '0.00'">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="finalAmount == undefined || finalAmount == '0.00'">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{finalAmount}}</td>
                              </tr>                             
                              <tr  *ngIf="advance != 0" >
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{advance}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paidBill}}</td>
                              </tr>
                              <tr [hidden]="paidBill >= finalAmount || remaingBalance <= '0.00'">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaingBalance}}</td>
                              </tr> 
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                      <div class="row">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paidBill != '0.00'">
                          <tr>
                            <td><strong>In Words:&nbsp;</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>           
               </td>
              </tr>
           </tbody>
          </table>
          <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
          </tfoot>  
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>