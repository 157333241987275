import { ReturnStatement } from "@angular/compiler";

export class Client_Helper {


    static retrivaldata;
    static setrelationlistdata(value: any) {
        this.retrivaldata = null;
        this.retrivaldata = value;
    }
    static getrelationlistdata() {
        return this.retrivaldata;

    }

    //setLifestyleTracker
    static lifestyletracker;
    static setLifestyleTracker(value: any) {
        this.lifestyletracker = null;
        this.lifestyletracker = value;
    }
    static getLifestyleTracker() {
        return this.lifestyletracker;
    }

    static order1;
    static setdetails(value: any) {
        this.order1 = null;
        this.order1 = value;
    }
    static getdetails() {
        return this.order1;
    }

    static details;
    static setpharmacydetails(value: any) {
        this.details = null;
        this.details = value;
    }
    static getpharmacydetails() {
        return this.details;
    }

    static detailview;
    static setclientviewbilldetail(value: any) {
        this.detailview = null;
        this.detailview = value;
    }
    static getclientviewbilldetail() {
        return this.detailview;
    }

    static medpres;
    static setmedialpres(value: any) {
        this.medpres = null;
        this.medpres = value;
    }
    static getmedialpres() {
        return this.medpres;
    }
    ///check

    static pharmacy1
    static setpharmacylist(value: any) {
        this.pharmacy1 = null;
        this.pharmacy1 = value;
    }
    static getpharmacylist() {
        return this.pharmacy1;
    }

    static pharmacyvalue;
    static setquantitychangevalue(value: any) {
        this.pharmacyvalue = null;
        this.pharmacyvalue = value;
    }
    static getquantitychangevalue() {
        return this.pharmacyvalue;
    }

    static order;
    static setorderconfirm(value: any) {
        this.order = null;
        this.order = value;
    }
    static getorderconfirm() {
        return this.order;
    }
    static currentdetails;
    static setcurrentdetails(value: any) {
        this.currentdetails = null;
        this.currentdetails = value;
    }
    static getcurrentdetails() {
        return this.currentdetails;
    }
    static homedeliveryvalue;
    static sethomedelivery(value: any) {
        this.homedeliveryvalue = null;
        this.homedeliveryvalue = value;

    }
    static gethomedelivery() { return this.homedeliveryvalue; }

    static tracker1;
    static settablettracker1(value: any) {
        this.tracker1 = null;
        this.tracker1 = value;
    }
    static gettablettracker1() {
        return this.tracker1;
    }

    static storedetails;
    static setstorepickup(value: any) {
        this.storedetails = null;
        this.storedetails = value;
    }
    static getstorepickup() {
        return this.storedetails;
    }

    static relations;
    static setrelationlist(value: any) {
        this.relations = null;
        this.relations = value;
    }
    static getrelationlist() {
        return this.relations;
    }

    static pharmorder;
    static setpharmacyorderdetails(value: any) {
        this.pharmorder = null;
        this.pharmorder = value;
    }
    static getpharmacyorderdetails() {
        return this.pharmorder;
    }
    static pharmacydetila;
    static setprescriptiondetails(value: any) {
        this.pharmacydetila = null;
        this.pharmacydetila = value;
    }
    static getprescriptiondetails() {
        return this.pharmacydetila;
    }

    //record wallet
    static rwdetails;
    static setopenmedpres(value: any) {
        this.rwdetails = null;
        this.rwdetails = value;
    }
    static getopenmedpres() {
        return this.rwdetails;
    }
    static setDocSearch1;
    static setDocSearch(value: any) {
        this.setDocSearch1 = null;
        this.setDocSearch1 = value;
    }
    static getDocSearch() {
        return this.setDocSearch1
    }
    static secopndoctorlist;
    static setsplnextdoclistpage(value: any) {
        this.secopndoctorlist = null;
        this.secopndoctorlist = value;
    }
    static getsplnextdoclistpage() {
        return this.secopndoctorlist;
    }
    static secopndoctorlist1;
    static setsecondopiniondoctorlistflag(value: any) {
        this.secopndoctorlist1 = null;
        this.secopndoctorlist1 = value;
    }
    static getsecondopiniondoctorlistflag() {
        return this.secopndoctorlist1;
    }

    static secopndoclientdetails;
    static setSO_client_details(value: any) {
        this.secopndoclientdetails = null;
        this.secopndoclientdetails = value;
    }
    static getSO_client_details() {
        return this.secopndoclientdetails;
    }


    static opinionlist;
    static setopnionlistarray(value: any) {
        this.opinionlist = null;
        this.opinionlist = value;
    }
    static getopinionlistarray() {
        return this.opinionlist
    }
    static opinion;
    static setopnionlist(value: any) {
        this.opinion = null;
        this.opinion = value;
    }
    static getopinionlist() {
        return this.opinion
    }

    static opinioncheck;
    static setopinionchecked(value: any) {
        this.opinioncheck = null;
        this.opinioncheck = value;
    }
    static getopinionchecked() {
        return this.opinioncheck;
    }

    static finddonor;
    static setfinddonors(value: any) {
        this.finddonor = null;
        this.finddonor = value;
    }
    static getfinddonors() {
        return this.finddonor;
    }


    static notify;
    static setviewnotification(value: any) {
        this.notify = null;
        this.notify = value;
    }
    static getviewnotification() {
        return this.notify;
    }


    //setHomecare
    static Homecare;
    static setHomecare(value: any) {
        this.Homecare = null;
        this.Homecare = value;
    }
    static getHomecare() {
        return this.Homecare;
    }

    //setTrackerFlag
    static TrackerFlag;
    static setTrackerFlag(value: any) {
        this.TrackerFlag = null;
        this.TrackerFlag = value;
    }
    static getTrackerFlag() {
        return this.TrackerFlag;
    }

    //setDoctorApptdetail
    static DoctorApptdetail;
    static setDoctorApptdetail(value: any) {
        this.DoctorApptdetail = null;
        this.DoctorApptdetail = value;
    }
    static getDoctorApptdetail() {
        return this.DoctorApptdetail;
    }

    //setSearchByHosptial
    static SearchByHosptial;
    static setSearchByHosptial(value: any) {
        this.SearchByHosptial = null;
        this.SearchByHosptial = value;
    }
    static getSearchByHosptial() {
        return this.SearchByHosptial;
    }

    //setDiagPresList
    static DiagPresList;
    static setDiagPresList(value: any) {
        this.DiagPresList = null;
        this.DiagPresList = value;
    }
    static getDiagPresList() {
        return this.DiagPresList;
    }

    //setPharmacyList
    static PharmacyList;
    static setPharmacyList(value: any) {
        this.PharmacyList = null;
        this.PharmacyList = value;
    }
    static getPharmacyList() {
        return this.PharmacyList;
    }

    //setDiagBookNow
    static DiagBookNow;
    static setDiagBookNow(value: any) {
        this.DiagBookNow = null;
        this.DiagBookNow = value;
    }
    static getDiagBookNow() {
        return this.DiagBookNow;
    }

    //app_nurse_physio
    static app_nurse_physio;
    static setHomecare_nurse_physio(value: any) {
        this.app_nurse_physio = null;
        this.app_nurse_physio = value;
    }
    static getHomecare_nurse_physio() {
        return this.app_nurse_physio;
    }

    //app_id
    static app_id;
    static setApp_id(value: any) {
        this.app_id = null;
        this.app_id = value;
    }
    static getApp_id() {
        return this.app_id;
    }

    //setPhysioClientInfo
    static physioclient_info;
    static setPhysioClientInfo(value: any) {
        this.physioclient_info = null;
        this.physioclient_info = value;
    }
    static getPhysioClientInfo() {
        return this.physioclient_info;
    }

    //setPhysio_Doctor_List
    static physiodrlist;
    static setPhysio_Doctor_List(value: any) {
        this.physiodrlist = null;
        this.physiodrlist = value;
    }
    static getPhysio_Doctor_List() {
        return this.physiodrlist;
    }




    //setphysio_hospital_id
    static p_hosp_id;
    static setPhysio_hospital_id(value: any) {
        this.p_hosp_id = null;
        this.p_hosp_id = value;
    }
    static getPhysio_hospital_id() {
        return this.p_hosp_id;
    }

    //setAdd_Session_table
    static add_sess_tab;
    static setAdd_Session_table(value: any) {
        this.add_sess_tab = null;
        this.add_sess_tab = value;
    }
    static getAdd_Session_table() {
        return this.add_sess_tab;
    }

    //setNurse_Prescription_list
    static nurse_preslist;
    static setNurse_Prescription_list(value: any) {
        this.nurse_preslist = null;
        this.nurse_preslist = value;
    }
    static getNurse_Prescription_list() {
        return this.nurse_preslist;
    }

    static report_det;
    static setReportDetails(value: any) {
        this.report_det = null;
        this.report_det = value;
    }
    static getReportDetails() {
        return this.report_det;
    }

    static secondopinioncreatetest;
    static setsecondopinioncreate(value: any) {
        this.secondopinioncreatetest = null;
        this.secondopinioncreatetest = value;

    }
    static getsecondopinioncreate() {
        return this.secondopinioncreatetest;
    }
    static appcreatedoctorlist;
    static setspecalizationtodoctorlistpage(value: any) {
        this.appcreatedoctorlist = null;
        this.appcreatedoctorlist = value;

    }
    static getspecalizationtodoctorlistpage() {
        return this.appcreatedoctorlist;
    }

    static withnormalflowsecopn;
    static setsecondopinion(value: any) {
        this.withnormalflowsecopn = null;
        this.withnormalflowsecopn = value;

    }
    static getsecondopinion() {
        return this.withnormalflowsecopn;
    }
    static donor;
    // static setblooddonordetail()

    static setblooddonordetail(value: any) {
        this.withnormalflowsecopn = null;
        this.withnormalflowsecopn = value;

    }
    static getblooddonordetail() {
        return this.withnormalflowsecopn;
    }

    static SecOpnListSpl1;
    // static setblooddonordetail()

    static setSecOpnListSpl(value: any) {
        this.SecOpnListSpl1 = null;
        this.SecOpnListSpl1 = value;

    }
    static getSecOpnListSpl() {
        return this.SecOpnListSpl1;
    }
    static otcherbal;
    // static setblooddonordetail()

    static setherbalotcvalue(value: any) {
        this.otcherbal = null;
        this.otcherbal = value;

    }
    static getherbalotcvalue() {
        return this.otcherbal;
    }
    //setVideoLink
    static VideoLink;
    static getVideoLink() {
        return this.VideoLink;
    }
    static setVideoLink(value) {
        this.VideoLink = null;
        this.VideoLink = value;
    }

    //setExerciseTracker
    static ExerciseTracker;
    static getExerciseTracker() {
        return this.ExerciseTracker;
    }
    static setExerciseTracker(value) {
        this.ExerciseTracker = null;
        this.ExerciseTracker = value;
    }

    //setrelationsubrelationslide
    static relationsubrelationslide;
    static getrelationsubrelationslide() {
        return this.relationsubrelationslide;
    }
    static setrelationsubrelationslide(value) {
        this.relationsubrelationslide = null;
        this.relationsubrelationslide = value;
    }

    //setrelationlistDiet
    static relationlistDiet;
    static getrelationlistDiet() {
        return this.relationlistDiet;
    }
    static setrelationlistDiet(value) {
        this.relationlistDiet = null;
        this.relationlistDiet = value;
    }
    //dietFlow
    static dietFlow;
    static getdietFlow() {
        return this.dietFlow;
    }
    static setdietFlow(value) {
        this.dietFlow = null;
        this.dietFlow = value;
    }

    //setActivityActualDuration
    static ActualDuration;
    static getActivityActualDuration() {
        return this.ActualDuration;
    }
    static setActivityActualDuration(value) {
        this.ActualDuration = null;
        this.ActualDuration = value;
    }

    //setDietProgress
    static DietProgress;
    static getDietProgress() {
        return this.DietProgress;
    }
    static setDietProgress(value) {
        this.DietProgress = null;
        this.DietProgress = value;
    }

    //setViewActivity
    static ViewActivity;
    static getViewActivity() {
        return this.ViewActivity;
    }
    static setViewActivity(value) {
        this.ViewActivity = null;
        this.ViewActivity = value;
    }

    //setClntHeightMsr
    static ClntHeightMsr;
    static getClntHeightMsr() {
        return this.ClntHeightMsr;
    }
    static setClntHeightMsr(value) {
        this.ClntHeightMsr = null;
        this.ClntHeightMsr = value;
    }

    //setexerciseplanvalue
    static exerciseplanvalue;
    static getexerciseplanvalue() {
        return this.exerciseplanvalue;
    }
    static setexerciseplanvalue(value) {
        this.exerciseplanvalue = null;
        this.exerciseplanvalue = value;
    }

    //setpackage_change
    static package_change;
    static getpackage_change() {
        return this.package_change;
    }
    static setpackage_change(value) {
        this.package_change = null;
        this.package_change = value;
    }


    //ClientDietHistory
    static clientDietHistory;
    static getClientDietHistory() {
        return this.clientDietHistory;
    }
    static setClientDietHistory(value) {
        this.clientDietHistory = null;
        this.clientDietHistory = value;
    }
    //DoctDietTracking
    static doctDietTracking;
    static getDoctDietTracking() {
        return this.doctDietTracking;
    }
    static setDoctDietTracking(value) {
        this.doctDietTracking = null;
        this.doctDietTracking = value;
    }
    // diet and physio 
    static AppFlow;
    static getAppFlow() {
        return this.AppFlow;
    }
    static setAppFlow(value) {
        this.AppFlow = null;
        this.AppFlow = value;
    }
    
    // diet and physio 
    static menuDiet;
    static getMenuDiet() {
        return this.menuDiet;
    }
    static setMenuDiet(value) {
        this.menuDiet = null;
        this.menuDiet = value;
    }
    // get ganerate pysio
    static generate;
    static getGenerateDite() {
        return this.generate;
    }
    static setGenerateDite(value) {
        this.generate = null;
        this.generate = value;
    }
    
}