<div class="row" style="width:100%;margin:0 auto;" *ngIf="forCasesheet">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diet plan</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" style="width: 85px !important;"
                            src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input">
                                Caloried required
                                <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
                            </mat-label>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77"
                                    aria-valuemin="0" aria-valuemax="100"
                                    [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input">
                                Total calories(Kcal)
                                <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                            </mat-label>
                            <div id="progress"></div>
                        </div>
                    </div>
                    <div class="col-9 col-sm-11 col-md-5 col-lg-3 col-xl-3" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input" id="sga">
                                SGA score
                                <span style="float: right;padding: 0 10px 0 0;">{{sgaOoverallScore}}</span>
                            </mat-label>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23"
                                    aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':''}"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" style="text-align: center;">
                        <img src="../assets/global/images/settings-icon.png" (click)="openDietPlan()" class=""
                            style="position: relative;top: 8px;" width="22px" height="22px" />
                    </div> -->
                    
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
<div *ngIf="!forCasesheet" >
    <div class="row" style="width:100%;margin:0 auto;"  *ngIf="dietPlanFlag == 'max'">
        <a (click)="save()"><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px"
                    style="width:25px" /></span> </a>
        <div class="col-12">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="">
                        <div class=" ">
                        </div>
                        <div class="">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Caloried required
                                    <span style="float: right;padding: 0 10px 0 0;">{{cal_txt}}</span>
                                </mat-label>
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77"
                                        aria-valuemin="0" aria-valuemax="100"
                                        [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Total calories(Kcal)
                                    <span style="float: right;padding: 0 10px 0 0;" id="totalCal">{{energyvalue1}}</span>
                                </mat-label>
                                <div id="progress"></div>
                            </div>
                        </div>
                        <div class="col-9 col-sm-11 col-md-5 col-lg-3 col-xl-3" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input" id="sga">
                                    SGA score
                                    <span style="float: right;padding: 0 10px 0 0;">{{(Sga_overalltxt == "") ? 0 :
                                        Sga_overalltxt}}</span>
                                </mat-label>
                
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23" aria-valuemin="0"
                                        aria-valuemax="100" [ngStyle]="{'width':''}"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" >
                            <img src="../assets/global/images/settings-icon.png" (click)="openDietPlan()" class=""
                                style="position: relative;top: 8px;" width="22px" height="22px" />
                        </div>-->
                        <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" >
                            <img src="../../../assets/img/printer.svg" (click)="print_Viewprescription()" class=""
                                style="position: relative;top: 8px;" width="22px" height="22px" />
                        </div> 
                       
                        <div class="dite_content_cover">
                            <div class="row">
                                <div class="col-6">
                                    <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">&nbsp;</mat-label><br>
                                    <div class="switch-field" *ngIf="ynopt1 != undefined" >
                                        <input type="radio" id="radio-three" (click)="change_type(1,'yes')" [disabled]="!currentDiet" />
                                        <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                                            <div class="tab_cover">
                                                <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg"
                                                        class="iocinStyle" alt=""></div>
                                                <div class="lableStyle">Current</div>
                                            </div>
                                        </label>
                                        <input type="radio" id="radio-four" (click)="change_type(1,'no')" [disabled]="!templateDiet" />
                                        <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                                            <div class="tab_cover">
                                                <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg"
                                                        class="iocinStyle" alt=""></div>
                                                <div class="lableStyle">Suggested diet</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                                    <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Range</mat-label><br>
                                    <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                                        [(ngModel)]="appointmentType">
                                        <!-- <option value="Today">Today</option> -->
                                        <option value="Weekly">Weekly</option>
                                        <option value="Fort Night">Fort Night</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="Yearly">Yearly</option>
                                        <option value="Date">Date</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                                    <mat-label class="matlabel">From<br>
                                        <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                                            [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                                    <mat-label class="matlabel">To<br>
                                        <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                            (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                                    </mat-label>
                                </div>
                            </div>
                            <div>
                                <img *ngIf="previousFlag && displayArray.length != 0" src="../../../assets/ui_icons/diet_plan/Previous_icon.svg"
                                    (click)="copyDietPlan('previous')" style="left: 8px;z-index: 2;" class="navIcon" alt="">
                                <img *ngIf="nextFlag && displayArray.length != 0" src="../../../assets/ui_icons/diet_plan/Next_icon.svg"
                                    style="right: 8px;" class="navIcon" alt="" (click)="copyDietPlan('next')">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <ng-container *ngFor="let person of displayArray; let i = index">
                                        <li class="nav-item" role="presentation" *ngIf="person.weekNo == week" >
                                            <!-- <button  class="nav-link" id="{{person.date}}-tab" data-bs-toggle="tab" 
                                            attr.data-bs-target="#{{person.day}}" type="button" role="tab" 
                                            attr.aria-controls="{{person.day}}" aria-selected="true" 
                                            (click)="dayClick(person.day,person.date,true)">{{person.date | date:'dd-MM-yyyy'}} <br> ({{person.dayLable}}) </button> -->
                                        
                                            <button class="nav-link" [id]="person.date + '-tab'" data-bs-toggle="tab" 
                                            [attr.data-bs-target]="'#' + person.day" type="button" role="tab" 
                                            [attr.aria-controls]="person.day" [attr.aria-selected]="true" 
                                            (click)="dayClick(person.day, person.date, true)">
                                      {{ person.date | date:'dd-MM-yyyy' }} <br> ({{ person.dayLable }})
                                    </button>
                                        
                                        
                                        
                                        
                                        
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                            
                            <div class="tab-content" id="myTabContent">
                                <div *ngFor="let person1 of showArray" style="height: 485px; overflow-y: auto;  overflow-x: hidden;">
                                    <div class="row" *ngFor="let session of person1.session;" style="margin-top: 10px;">
                                        <div class="col-8 col-sm-7 col-md-12 col-lg-4 col-xl-4">
                                            <div class="card cardCover">
                                                <div class="card-header cardHeader">
                                                    {{session.lableName}}
                                                    <span style="float: right;">
                                                        {{session.energy}} Kcal
                                                    </span>
                                                </div>
                                                <div class="card-body">
                                                    <div *ngIf="session.type == 'Morning'">
                                                        <mat-label class="matlabel">Add food item <br>
                                                            <input type="text" class="ipcss mainDishInput" [(ngModel)]="morningFoods"
                                                                name="morningFoods" (keyup)="foottype(morningFoods, session.type)"
                                                                type="text" aria-label="Number" matInput
                                                                [matAutocomplete]="autoMorning" />
                                                            <div style="display: inline-block;">
                                                                <img *ngIf="session.mainDish == true"
                                                                    src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                    height="20px" alt="" class="mainDishImg">
                                                                <img *ngIf="session.mainDish == false"
                                                                    (click)="mainDish(session,person1.day)"
                                                                    src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                    height="20px" alt="" class="mainDishImg">
                                                            </div>
                                                            <mat-autocomplete #autoMorning="matAutocomplete">
                                                                <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                    *ngFor="let food of morningFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </mat-label>
                                                    </div>
                                                    <div *ngIf="session.type == 'Breakfast'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="BreakfastFoods"
                                                            name="BreakfastFoods" (keyup)="foottype(BreakfastFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoBreakfast" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoBreakfast="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of breadfastFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Mid-day'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="MidDayFoods"
                                                            name="MidDayFoods" (keyup)="foottype(MidDayFoods, session.type)" type="text"
                                                            aria-label="Number" matInput [matAutocomplete]="autoMidDay" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoMidDay="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of midDayFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Lunch'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="LunchFoods"
                                                            name="LunchFoods" (keyup)="foottype(LunchFoods, session.type)" type="text"
                                                            aria-label="Number" matInput [matAutocomplete]="autoLunchFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoLunchFoods="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of lunchFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Evening'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="EveningFoods"
                                                            name="EveningFoods" (keyup)="foottype(EveningFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoEveningFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoEveningFoods="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of eveningFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Dinner'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="DinnerFoods"
                                                            name="DinnerFoods" (keyup)="foottype(DinnerFoods, session.type)" type="text"
                                                            aria-label="Number" matInput [matAutocomplete]="autoDinnerFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoDinnerFoods="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of dinnerFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Night'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput" [(ngModel)]="NightFoods"
                                                            name="NightFoods" (keyup)="foottype(NightFoods, session.type)" type="text"
                                                            aria-label="Number" matInput [matAutocomplete]="autoNightFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png" height="20px"
                                                                alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoNightFoods="matAutocomplete">
                                                            <mat-option id="optionfont" (click)="selctedFood(person1.day,person1.date,food)"
                                                                *ngFor="let food of nightFoodItem" [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div class="foodItemCover">
                                                        <div *ngFor="let foodList of session.foodItems" class="foodItemsInnerCover">
                                                            <span class="foodItemsSpan" style="vertical-align: top;">
                                                                <img *ngIf="foodList.checked == false"
                                                                    (click)="isSelected(foodList,'add')"
                                                                    src="../../../assets/ui_icons/yellow_box.svg"
                                                                    style="cursor: pointer;" width="15px" />
                
                                                                <img *ngIf="foodList.checked == true"
                                                                    (click)="isSelected(foodList,'remove')"
                                                                    src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                            </span>
                                                            <div class="foodLable"> {{ foodList.description }} </div>
                                                            <div class="foodInputCover"> <input type="text"
                                                                    (blur)="changequantity(foodList.cals_weight,foodList.day,foodList,session.type)"
                                                                    [(ngModel)]="foodList.cals_weight" class="foodMesureInput"
                                                                    [disabled]="!foodList.checked" maxlength="7"
                                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                <div class="foodMeasure">{{foodList.wightMesure}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 ">
                                            <div class="row foodItemsCover">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0"
                                                    style="display: flex;align-items: center;justify-content: center;">
                                                    <div class="plateCover">
                                                        <div id="{{person1.day}}_{{session.type}}" class="main"
                                                            [innerHTML]="session.plateContent">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 p-0 my-auto"
                                                    style="height:fit-content">
                                                    <div class="foodValues">
                                                        <div style="width: 584px;">
                                                            <div class="foodItems"> <span> Energy (Kcal)</span> <br>
                                                                <span>{{session.energy}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Protien (gm)</span> <br>
                                                                <span>{{session.Protien}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Calcium (mg)</span> <br>
                                                                <span>{{session.Ca}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Iron (mg)</span> <br>
                                                                <span>{{session.Fe}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Fiber (mg)</span> <br>
                                                                <span>{{session.fiber}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit A (mg)</span> <br>
                                                                <span>{{session.vaitamina}}</span>
                                                            </div>
                                                            <hr />
                                                            <div class="foodItems"> <span> Fat (gm)</span> <br>
                                                                <span>{{session.Fat}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> H<sub>2</sub>O (gm)</span>
                                                                <br>
                                                                <span>{{session.h2o}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Folic (mcg)</span> <br>
                                                                <span>{{session.Folic}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Carbos (mg)</span> <br>
                                                                <span>{{session.Carbohydrates}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit C (mg)</span> <br>
                                                                <span>{{session.Vitaminc}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit B12 (mg)</span> <br>
                                                                <span>{{session.vitaminb12}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <a (click)="dietplanhistory('diettracker')"><img src="../../../assets/ui_icons/buttons/history.svg"
                                        class="saveimgbtn_inpatinfo" /></a>
                                    <a (click)="dietplanhistory('patient')"><img src="../../../assets/ui_icons/buttons/wrong.svg"
                                        class="saveimgbtn_inpatinfo" /></a> -->
                                        <div class="row">
                                            <div [hidden]="avoid_hidden" class="col-4">
                                                <mat-card id="cardcontent_avoid" style="position: relative;">
                                                    <mat-card-title id="cardheading_avoid">Avoid</mat-card-title>
                                                    <mat-card-content style="padding:5px;height: 117px;">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                                                <mat-label class="matlabel">Food type<br>
                                                                    <select class="ipcss " required [(ngModel)]="Food_type_avoid"
                                                                        disableOptionCentering >
                                                                        <option disabled value="Select">Select</option>
                                                                        <option *ngFor="let food of get_foodtype_arry"
                                                                            value={{food.description}}>
                                                                            {{food.description}}</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                                                <mat-label class="matlabel">Food item
                                                                    <input type="text" class="ipcss " required
                                                                        [(ngModel)]="Foot_Item_avoidtxt" (keyup)="avoidfilter($event)"
                                                                        aria-label="Number" matInput [matAutocomplete]="auto8" 
                                                                        [disabled]="client" />
                                                                    <mat-autocomplete #auto8="matAutocomplete">
                                                                        <mat-option id="optionfont" (click)="select_avoid_item(item)"
                                                                            *ngFor="let item of filteredList1" value="{{item}}">
                                                                            {{item}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <div [hidden]="avoid_hidden" class="col-2 my-auto" style="text-align: center;">
                                                <a (click)="DietToAvoidAdd()" [disabled]="client"><img
                                                        src="../../../assets/ui_icons/arrow_red.svg" class="saveimgbtn_inpatinfo" /></a>
                                            </div>
                                            <div class="col-6" style="margin-top: 5px; height: 160px;" *ngIf="dietavoidwadd.length != 0">
                                                <div  class="">
                                                    <div  class="table-responsive dig_table_overflow">
                                                        <table class="table table-nowrap table-sm dataTable billlisttable">
                                                            <thead>
                                                                <tr>
                                                                    <th>Category</th>
                                                                    <th>Food type</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               
                                                                <tr *ngFor="let dietavoid of dietavoidwadd">
                                                                    <td>
                                                                        <div class="dia_table_left">{{dietavoid.food_type_name}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="dia_table_left">{{dietavoid.food_item}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <a 
                                                                            (click)="DeleteDietAvoid(dietavoid.Food_type_avoid,dietavoid.food_item)"><img
                                                                                src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                                class="editDelicon" /></a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                            
                            </div>
             
                        </div>
                    </div>
                 
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
    <div *ngIf="dietPlanFlag == 'min'">
        <div class="details row">
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input">
                        Caloried required
                        <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
                    </mat-label>
                </div>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input">
                        Total calories(Kcal)
                        <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                    </mat-label>
                </div>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input" id="sga">
                        SGA score
                        <span style="float: right;padding: 0 10px 0 0;">{{sgaOoverallScore}}</span>
                    </mat-label>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div  [hidden]="true">
           
            <div >
                <div #printlogowithname id="printlogowithname"
                   style="background-image: url({{bg_image}}); background-position: center;">
                   <div >
                    <div class="bill_border">
                     <div class="row">
                      <div class="col-12">
        
                      
                      <div class="cover_div">
                          <div class="header_lable">Personal Details</div>
                          <div class="content_cover">
                           
                            <div class="row">
                                <div class="col-4" >
                                    MR no:{{mrnno}}
                                </div>
                                <div class="col-4" >
                                    Name: {{patient_name}}
                                </div>
                                <div class="col-4"  >
                                    Age : {{patient_age}}
                                </div>
                                <div class="col-4"  >
                                    Gender: {{patient_gender}}
                                </div>
                                <div class="col-4">
                                    Doctorname: {{doctor_name}}
                                </div>
                                <div class="col-4">
                                    Speciality: {{speclization_arry}}
                                </div>
                                <!-- <div class="col-4">
                                    Visit purspose: {{visitpurpose}}
                                </div>
                                <div class="col-4">
                                    Ip number: {{inpatientnuber}}
                                </div>
                                <div class="col-4">
                                    Op number: {{outpatientnumber}}
                                </div>
                               
                                <div class="col-4"  *ngIf="inpatflag">
                                    Ward : {{wardno}}
                                </div>
                                <div class="col-4"  *ngIf="inpatflag">
                                    Bed no: {{bedno}}
                                </div>
                                <div class="col-4"  *ngIf="inpatflag">
                                    Admitted date&time: {{admission_date}} {{admission_time}}
                                </div>
                                <div class="col-4" *ngIf="inpatflag" >
                                    surgery: {{surgery_inpat}}
                                </div> -->
                            
                               
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="container">
                         <p style="margin: 0px !important;"><strong>Diet to follow</strong></p>
                          <div *ngFor="let person of showArray" style="">
                              <div class="row" *ngFor="let session of person.session" style="">
                                  <div class="col-8" *ngIf="session.foodItems.length > 0">
                                      <div class="" style="">
                                          <div style="font-weight: bold;">{{ session.lableName }}</div><br>
                                          <div *ngFor="let foodList of session.foodItems" class="foodItemsInnerCover">
                                              <div class="foodLable">{{ foodList.description }}</div>
                                              <div class="foodLable1">{{ foodList.quantity }}-{{foodList.measure}}</div>
                                              <div class="foodLable1">{{ foodList.wightMesure }} {{ foodList.cals_weight }}</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-4 p-0"  *ngIf="session.foodItems.length > 0"
                                  style="display: flex;align-items: center;justify-content: center;">
                                  <div class="plateCover">
                                    
                                    
                                      <div class="main"  [ngStyle]="session.mainDish == true ? {'background-image':'url(' + session.plateContent1 + ')' } : {'background-image' : 'none'}"
                                          [innerHTML]="session.plateContent">
                                      </div>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                      <div class="container">
                        <p style="margin: 0px !important;"><strong>Diet to Avoid</strong></p>
                        <div>
                         <div *ngFor="let dietavoid of dietavoidwadd">
                         <div class="" style="display: flex;width: 100%;" >
                             <div class="dia_table_left">{{dietavoid.food_type_name}} {{dietavoid.food_item}}</div>
                         </div>
                         </div>
                            
                        </div>
                    </div>
                      </div>
                      </div>
                  
                    
                    </div>
                    <br />
                  </div>
                </div>
          
                <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
                    <div >
                        <div class="bill_border">
                         <div class="row">
                          <div class="col-12">
            
                          
                          <div class="cover_div">
                              <div class="header_lable">Personal Details</div>
                              <div class="content_cover">
                               
                                <div class="row">
                                    <div class="col-4" >                                        
                                        MR No:{{mrnno}}
                                    </div>
                                    <div class="col-4" >
                                        Name: {{patient_name}}
                                    </div>
                                    <div class="col-4"  >
                                        Age : {{patient_age}}
                                    </div>
                                    <div class="col-4"  >
                                        Gender: {{patient_gender}}
                                    </div>
                                    <div class="col-4">
                                        Doctorname: {{doctor_name}}
                                    </div>
                                    <div class="col-4">
                                        Speciality: {{speclization_arry}}
                                    </div>
                                    <!-- <div class="col-4">
                                        Visit purspose: {{visitpurpose}}
                                    </div>
                                    <div class="col-4">
                                        Ip number: {{inpatientnuber}}
                                    </div>
                                    <div class="col-4">
                                        Op number: {{outpatientnumber}}
                                    </div>
                                   
                                    <div class="col-4"  *ngIf="inpatflag">
                                        Ward : {{wardno}}
                                    </div>
                                    <div class="col-4"  *ngIf="inpatflag">
                                        Bed no: {{bedno}}
                                    </div>
                                    <div class="col-4"  *ngIf="inpatflag">
                                        Admitted date&time: {{admission_date}} {{admission_time}}
                                    </div>
                                    <div class="col-4" *ngIf="inpatflag" >
                                        surgery: {{surgery_inpat}}
                                    </div> -->
                                
                                   
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <div class="container">
                             <p style="margin: 0px !important;"><strong>Diet to follow</strong></p>
                              <div *ngFor="let person of showArray" style="">
                                  <div class="row" *ngFor="let session of person.session" style="">
                                      <div class="col-8" *ngIf="session.foodItems.length > 0">
                                          <div class="" style="">
                                              <div style="font-weight: bold;">{{ session.lableName }}</div><br>
                                              <div *ngFor="let foodList of session.foodItems" class="foodItemsInnerCover">
                                                  <div class="foodLable">{{ foodList.description }}</div>
                                                  <div class="foodLable1">{{ foodList.quantity }}-{{foodList.measure}}</div>
                                                  <div class="foodLable1">{{ foodList.wightMesure }} {{ foodList.cals_weight }}</div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-4 p-0"  *ngIf="session.foodItems.length > 0"
                                      style="display: flex;align-items: center;justify-content: center;">
                                      <div class="plateCover">
                                        
                                        
                                          <div class="main"  [ngStyle]="session.mainDish == true ? {'background-image':'url(' + session.plateContent1 + ')' } : {'background-image' : 'none'}"
                                              [innerHTML]="session.plateContent">
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                          <div class="container">
                            <p style="margin: 0px !important;"><strong>Diet to Avoid</strong></p>
                            <div>
                             <div *ngFor="let dietavoid of dietavoidwadd">
                             <div class="" style="display: flex;width: 100%;" >
                                 <div class="dia_table_left">{{dietavoid.food_type_name}} {{dietavoid.food_item}}</div>
                             </div>
                             </div>
                                
                            </div>
                        </div>
                          </div>
                          </div>
                      
                        
                        </div>
                        <br />
                      </div>
                </div>
          
                <div #printnoheader id="printnoheader" style="background-image: url({{bg_image}}); background-position: center;">
                    <div >
                       <div class="bill_border">
                        <div class="row">
                         <div class="col-12">
           
                         
                         <div class="cover_div">
                             <div class="header_lable">Personal Details</div>
                             <div class="content_cover">
                              
                               <div class="row">
                                   <div class="col-4" >
                                       MR no:{{mrnno}}
                                   </div>
                                   <div class="col-4" >
                                       Name: {{patient_name}}
                                   </div>
                                   <div class="col-4"  >
                                       Age : {{patient_age}}
                                   </div>
                                   <div class="col-4"  >
                                       Gender: {{patient_gender}}
                                   </div>
                                   <div class="col-4">
                                       Doctorname: {{doctor_name}}
                                   </div>
                                   <div class="col-4">
                                       Speciality: {{speclization_arry}}
                                   </div>
                                   <!-- <div class="col-4">
                                       Visit purspose: {{visitpurpose}}
                                   </div>
                                   <div class="col-4">
                                       Ip number: {{inpatientnuber}}
                                   </div>
                                   <div class="col-4">
                                       Op number: {{outpatientnumber}}
                                   </div>
                                  
                                   <div class="col-4"  *ngIf="inpatflag">
                                       Ward : {{wardno}}
                                   </div>
                                   <div class="col-4"  *ngIf="inpatflag">
                                       Bed no: {{bedno}}
                                   </div>
                                   <div class="col-4"  *ngIf="inpatflag">
                                       Admitted date&time: {{admission_date}} {{admission_time}}
                                   </div>
                                   <div class="col-4" *ngIf="inpatflag" >
                                       surgery: {{surgery_inpat}}
                                   </div> -->
                               
                                  
                               </div>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-12">
                         <div class="container">
                            <p style="margin: 0px !important;"><strong>Diet to follow</strong></p>
                             <div *ngFor="let person of showArray" style="">
                                 <div class="row" *ngFor="let session of person.session" style="">
                                     <div class="col-8" *ngIf="session.foodItems.length > 0">
                                         <div class="" style="">
                                             <div style="font-weight: bold;">{{ session.lableName }}</div><br>
                                             <div *ngFor="let foodList of session.foodItems" class="foodItemsInnerCover">
                                                 <div class="foodLable">{{ foodList.description }}</div>
                                                 <div class="foodLable1">{{ foodList.quantity }}-{{foodList.measure}}</div>
                                                 <div class="foodLable1">{{ foodList.wightMesure }} {{ foodList.cals_weight }}</div>
                                             </div>
                                         </div>
                                     </div>
                                     <div class="col-4 p-0"  *ngIf="session.foodItems.length > 0"
                                     style="display: flex;align-items: center;justify-content: center;">
                                     <div class="plateCover">
                                       
                                       
                                         <div class="main"  [ngStyle]="session.mainDish == true ? {'background-image':'url(' + session.plateContent1 + ')' } : {'background-image' : 'none'}"
                                             [innerHTML]="session.plateContent">
                                         </div>
                                     </div>
                                 </div>
                                 </div>
                             </div>
                         </div>
                         <div class="container">
                            <p style="margin: 0px !important;"><strong>Diet to Avoid</strong></p>
                            <div>
                             <div *ngFor="let dietavoid of dietavoidwadd">
                             <div class="" style="display: flex;width: 100%;" >
                                 <div class="dia_table_left">{{dietavoid.food_type_name}} {{dietavoid.food_item}}</div>
                             </div>
                             </div>
                                
                            </div>
                        </div>
                         </div>
                         </div>
                     
                       
                       </div>
                       <br />
                     </div>
                   </div>
                <div #printnoheadsidebar id="printnoheadsidebar"
                   style="background-image: url({{bg_image}}); background-position: center;">
                   <div >
                    <div class="bill_border">
                     <div class="row">
                      <div class="col-12">
        
                      
                      <div class="cover_div">
                          <div class="header_lable">Personal Details</div>
                          <div class="content_cover">
                           
                            <div class="row">
                                <div class="col-4" >
                                    MR no:{{mrnno}}
                                </div>
                                <div class="col-4" >
                                    Name: {{patient_name}}
                                </div>
                                <div class="col-4"  >
                                    Age : {{patient_age}}
                                </div>
                                <div class="col-4"  >
                                    Gender: {{patient_gender}}
                                </div>
                                <div class="col-4">
                                    Doctorname: {{doctor_name}}
                                </div>
                                <div class="col-4">
                                    Speciality: {{speclization_arry}}
                                </div>
                                <!-- <div class="col-4">
                                    Visit purspose: {{visitpurpose}}
                                </div>
                                <div class="col-4">
                                    Ip number: {{inpatientnuber}}
                                </div>
                                <div class="col-4">
                                    Op number: {{outpatientnumber}}
                                </div>
                               
                                <div class="col-4"  *ngIf="inpatflag">
                                    Ward : {{wardno}}
                                </div>
                                <div class="col-4"  *ngIf="inpatflag">
                                    Bed no: {{bedno}}
                                </div>
                                <div class="col-4"  *ngIf="inpatflag">
                                    Admitted date&time: {{admission_date}} {{admission_time}}
                                </div>
                                <div class="col-4" *ngIf="inpatflag" >
                                    surgery: {{surgery_inpat}}
                                </div> -->
                            
                               
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="container">
                         <p style="margin: 0px !important;"><strong>Diet to follow</strong></p>
                          <div *ngFor="let person of showArray" style="">
                              <div class="row" *ngFor="let session of person.session" style="">
                                  <div class="col-8" *ngIf="session.foodItems.length > 0">
                                      <div class="" style="">
                                          <div style="font-weight: bold;">{{ session.lableName }}</div><br>
                                          <div *ngFor="let foodList of session.foodItems" class="foodItemsInnerCover">
                                              <div class="foodLable">{{ foodList.description }}</div>
                                              <div class="foodLable1">{{ foodList.quantity }}-{{foodList.measure}}</div>
                                              <div class="foodLable1">{{ foodList.wightMesure }} {{ foodList.cals_weight }}</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-4 p-0"  *ngIf="session.foodItems.length > 0"
                                  style="display: flex;align-items: center;justify-content: center;">
                                  <div class="plateCover">
                                    
                                    
                                      <div class="main"  [ngStyle]="session.mainDish == true ? {'background-image':'url(' + session.plateContent1 + ')' } : {'background-image' : 'none'}"
                                          [innerHTML]="session.plateContent">
                                      </div>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                      <div class="container">
                        <p style="margin: 0px !important;"><strong>Diet to Avoid</strong></p>
                        <div>
                         <div *ngFor="let dietavoid of dietavoidwadd">
                         <div class="" style="display: flex;width: 100%;" >
                             <div class="dia_table_left">{{dietavoid.food_type_name}} {{dietavoid.food_item}}</div>
                         </div>
                         </div>
                            
                        </div>
                    </div>
                      </div>
                      </div>
                  
                    
                    </div>
                    <br />
                  </div>
                   <!-- <footer [hidden]="printf_flag">
                        <img src={{footer_image}}>
                      </footer> -->
                </div>
             </div>
                  </div>

    </div>
    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="titleStyle">
                    <h5 class="modal-title" id="staticBackdropLabel">Diet Conformation</h5>
                    <span class="closeicon" data-bs-dismiss="modal" aria-label="Close" style="float: right;"><img
                            src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:19px"> </span>
                </div>
                <div class="modal-body">
                    Whould you like to copy {{previous_day}} diet plan to other days?
                </div>
                <div class="modal-footer">
                    <img src="../../../assets/ui_icons/buttons/No_button.svg" alt="Card image cap"
                        style="width:85px; height: 25px;" (click)="assingData('no')">
                    <img src="../../../assets/ui_icons/buttons/yes_button.svg" alt="Card image cap"
                        style="width:85px; height: 25px;" (click)="assingData('yes')">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="pdfModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " style="width: 950px; max-width: 950px;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="margin-top: 10px;">
                    <iframe class="controls" [src]="pdfURL" width="900px" height="700px" frameborder="0"
                        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>