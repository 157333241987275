import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DiagnosisImagepopupserviceService } from '../../../app/Diagnosis_module/diagnosis-imagepopupservice.service';

import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Subject, Observable } from 'rxjs';
import { ScanDetected } from 'ngx-scanner-detection';
import { NgxBarcodeModule } from 'ngx-barcode';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';


@Component({
  selector: 'app-diag-specimen-assertion',
  templateUrl: './diag-specimen-assertion.component.html',
  styleUrls: ['./diag-specimen-assertion.component.scss']
})
export class DiagSpecimenAssertionComponent implements OnInit {

  public save_btn: boolean;
  public sampledate: string;
  public filtduration: string;
  public currentDate: string;
  public sampleList: any = [];
  public selectedList: any = [];
  public sampletype_data: any = [];
  public sampleDetails_data: any = [];
  public testtube_data: any = [];
  dtOptions: DataTables.Settings = {};
  selected_tube_colors: any[];
  public sample_id: any = [];
  public isSelected: boolean;
  public lab_samples: any = [];
  public micro_samples: any = [];
  public assertarray:any=[];

  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 2;
  barcodeheight = 50;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  specimen_Array: any = [];
  specimenPageType: any;
  userinfo: any;
  hptl_clinic_id: any;
  user_id: any;

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog, public service: DiagnosisImagepopupserviceService) { }

  ngOnInit(): void {
    this.filtduration = "select";
    this.specimenPageType = Diagnosis_Helper.getReportType();
    this.getdate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    if (this.userinfo.user_type == "Admin") {
      this.hptl_clinic_id = Helper_Class.getInfo().diag_center_id;
    }
    else {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id
    }
    this.getSampleTypes();
    this.getDiagTesttubes();
    this.getSpecimenAssertions();
  }

  getSpecimenAssertions(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "mas/gada", ({ diag_centre_id: this.hptl_clinic_id }), 
    { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        if(response != undefined) {
          for(var i=0;i<response.diag_assertions.length;i++){
            this.assertarray.push(response.diag_assertions[i].assertion_desc)
          }
        }
      });
  }
  
  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.sampledate = this.currentDate;
        this.getSampleList(this.sampledate)
      }, error => { });
  }

  getSampleList(date) {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      country: ipaddress.country_code,
      diag_center_id: this.hptl_clinic_id,
      duration: durval,
      date: date,
      type: "assertion",
      department: Diagnosis_Helper.getReportType(),
    }
    console.log("getSampleList--send_data", send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/ghscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log("response", JSON.stringify(response.sample_header));
        this.sampleList = [];
        if (response.sample_header != undefined) {
          for (var i = 0; i < response.sample_header.length; i++) {
            var cname;
            if (response.sample_header[i].middle_name != undefined) {
              cname = encrypt_decript.Decript(response.sample_header[i].first_name) + " " + encrypt_decript.Decript(response.sample_header[i].middle_name) + " " + encrypt_decript.Decript(response.sample_header[i].last_name);
            } else {
              cname = encrypt_decript.Decript(response.sample_header[i].first_name) + " " + encrypt_decript.Decript(response.sample_header[i].last_name);
            }

            var Drname;
            if (response.sample_header[i].dr_first_name != undefined || response.sample_header[i].dr_middle_name != undefined || response.sample_header[i].dr_last_name != undefined) {
              if (response.sample_header[i].dr_middle_name != undefined) {
                Drname = response.sample_header[i].dr_first_name + " " + response.sample_header[i].dr_middle_name + " " + response.sample_header[i].dr_last_name;
              } else {
                Drname = response.sample_header[i].dr_first_name + " " + response.sample_header[i].dr_last_name;
              }
            } else {
              Drname = "";
            }
            var dateset;
            if(response.sample_header[i].bill_date != undefined) {
              var date_val = response.sample_header[i].bill_date.split("-")
              dateset = date_val[2] + "-" + date_val[1] + "-" + date_val[0];
            } else {
              dateset="";
            }
            var timeset;
            if(response.sample_header[i].bill_time != undefined) {
              timeset = Time_Formate(response.sample_header[i].bill_time);
            } else {
              timeset="";
            }
            this.sampleList.push({
              mrno: response.sample_header[i].mrno,
              patient_name: cname,
              dr_name: Drname,
              bill_date: dateset,
              bill_time: timeset,
              ward_name: response.sample_header[i].ward_name,
              bed_no: response.sample_header[i].bed_no,
              client_reg_id: response.sample_header[i].client_reg_id,
              relation_id: response.sample_header[i].relation_id,
              sub_rel_id: response.sample_header[i].sub_rel_id,

            })
          }
          console.log("this.sampleList", this.sampleList);
        }

      }, error => { });
  }

  saveSamples() {
    this.selectedList = [];
    console.log("SAMPLE LIST " + JSON.stringify(this.sampleList))
    for (var i = 0; i < this.sampleList.length; i++) {
      if (this.sampleList[i].isSelected == true) {
        this.selectedList.push({
          pres_diag_id: this.sampleList[i].diagtestid,
          test_type: this.sampleList[i].test_type_name,
          pres_test_id: this.sampleList[i].prestestid,
          sample_type: this.sampleList[i].sample_type,
          mrno: this.sampleList[i].mrno,
        })
      }
    }

    console.log("this.selectedList", this.selectedList)
    var send_data = {
      user_id: this.user_id,
      country: ipaddress.country_code,
      tests: this.selectedList,
      diag_centre_id: this.hptl_clinic_id,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response)
        if (response.key == "1") {
          this.toastr.success("Successfully asserted sample");
          this.getSampleList(this.sampledate);
        }

      }, error => { })
  }

  saveSamples2() {
    this.selectedList = [];
    this.sample_id = [];

    for (var i = 0; i < this.sampleDetails_data.length; i++) {
      if (this.sampleDetails_data[i].isSelected == true) {
        var specimen_ids = [];
        var selectedtest = this.sampleDetails_data[i];
        specimen_ids = this.sampleDetails_data[i].selected_specimen;

        var assertionStatus;

        if (this.sampleDetails_data[i].selected_reason == "Asserted") {
          assertionStatus = '1';
        } else {
          assertionStatus = '0';
        }

        this.selectedList.push({
          assertion: assertionStatus,
          assertion_reason: selectedtest.selected_reason,
          diag_lab_test_id: selectedtest.prestestid,
          sample_type: selectedtest.selected_specimen[0],
          outsource: selectedtest.selected_outsource,
          test_type: selectedtest.test_type_name,
        })
      }
    }

    console.log("this.selectedList", this.selectedList)
    var send_data = {
      user_id: this.user_id,
      country: ipaddress.country_code,
      tests: this.selectedList,
      diag_centre_id: this.hptl_clinic_id,

    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/uscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        // this.sample_id = response[0].sample_id;
        console.log("saveSamples2---response", response)

        if (response.key == "1") {
          this.toastr.success("Successfully asserted sample");
          this.getSampleList(this.sampledate);
          this.sample_id = response.test_no;

          // setTimeout(() => {
          //   this.printIdCard();
          // }, 1000);
        }
      }, error => { })
  }

  getSampleTypes() {
    this.sampletype_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gstbd ', { diag_centre_id: this.hptl_clinic_id  },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj----getSampleTypes", obj)
          this.lab_samples = obj.lab_samples;
          this.micro_samples = obj.micro_samples;

          var sampleTypesData = obj.specimen_types;

          if (sampleTypesData != undefined && sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push({
                sample_type_id: sampleTypesData[i].specimen_type_id,
                sample_desc: sampleTypesData[i].specimen_desc,
                tube_type_id: sampleTypesData[i].tube_type_id,
                tube_color: sampleTypesData[i].tube_color,
              }
              )
            }
          }
        }, error => { });
  }

  getSampleDetails(data) {
    console.log("sample data " + JSON.stringify(data))
    this.sampleDetails_data = [];
    var mrval = data.mrno;
    var durval;
    this.isSelected = false;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var senddata = {
      diag_centre_id: this.hptl_clinic_id,
      client_reg_id: data.client_reg_id,
      relation_id: data.relation_id,
      sub_rel_id: data.sub_rel_id,
      date: this.sampledate,
      duration: durval,
      country: ipaddress.country_code,
      type: "assertion",
      department: Diagnosis_Helper.getReportType(),
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ghscd', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sampleDetails = obj.sample_list;
          console.log("getSampleDetails-senddata " + JSON.stringify(sampleDetails));

          if (sampleDetails.length != 0) {
            for (var i = 0; i < sampleDetails.length; i++) {
              var outsourceFlag;
              if (sampleDetails[i].outsources == undefined) {
                outsourceFlag = false;
              } else {
                outsourceFlag = true;
              }

              var selected_outsource_id = '';
              if (sampleDetails[i].outsource_id != undefined ) {
                selected_outsource_id = sampleDetails[i].outsource_id;
              }

              var specimen_type_array;
              if (sampleDetails[i].sub_department == 'Microbiology') {
                specimen_type_array = this.micro_samples;
                this.selected_tube_colors = [];

                Selected_specimen = [sampleDetails[i].dsample];


              } else {
                specimen_type_array = this.lab_samples;

                if (sampleDetails[i].sample_type != undefined) {
                  
                  this.selected_tube_colors = [];
                  var tube_array = [];
                  var sampleIds = '';
                  sampleIds = sampleDetails[i].sample_type.split(',');
                  var d_sampleId = sampleDetails[i].dsample;
                  var Selected_specimen;

                  if (this.specimenPageType == 'laboratory') {
                    // for (var l = 0; l < this.sampletype_data.length; l++) {
                    //   if (d_sampleId == this.sampletype_data[l].sample_type_id) {
                    //     this.selected_tube_colors = this.sampletype_data[l].tube_color
                    //   }
                    // }
                    Selected_specimen = [d_sampleId];
                    for (var l = 0; l < specimen_type_array.length; l++) {
                      if (d_sampleId == specimen_type_array[l].specimen_type_id) {
                        this.selected_tube_colors = specimen_type_array[l].tube_color
                      }
                    }

                  } else {
                    // for (var l = 0; l < this.sampletype_data.length; l++) {
                    //   for (var j = 0; j < sampleIds.length; j++) {
                    //     if (sampleIds[j] == this.sampletype_data[l].sample_type_id) {
                    //       tube_array = this.sampletype_data[l].tube_color;
                    //       for (var k = 0; k < tube_array.length; k++) {
                    //         this.selected_tube_colors.push(tube_array[k])
                    //       }
                    //     }
                    //   }
                    // }
                    for (var l = 0; l < specimen_type_array.length; l++) {
                      for (var j = 0; j < sampleIds.length; j++) {
                        if (sampleIds[j] == specimen_type_array[l].specimen_type_id) {
                          tube_array = specimen_type_array[l].tube_color;
                          for (var k = 0; k < tube_array.length; k++) {
                            this.selected_tube_colors.push(tube_array[k])
                          }
                        }
                      }
                    }
                    Selected_specimen = sampleDetails[i].sample_type.split(',');
                  }
                } else {
                  this.selected_tube_colors = [];
                  var tube_array = [];
                  var sampleIds = '';
                  sampleIds = sampleDetails[i].dsample.split(',');
                  var d_sampleId = sampleDetails[i].dsample;
                  var Selected_specimen;

                  if (this.specimenPageType == 'laboratory') {
                    Selected_specimen = [d_sampleId];
                    for (var l = 0; l < specimen_type_array.length; l++) {
                      if (d_sampleId == specimen_type_array[l].specimen_type_id) {
                        this.selected_tube_colors = specimen_type_array[l].tube_color
                      }
                    }
                  } 
                }
              }

              if (sampleDetails[i].tube_image != undefined) {
                var data = sampleDetails[i].tube_image
              }
              // console.log("SAMPLE TYPES " + JSON.stringify(specimen_type_array))
              this.sampleDetails_data.push({
                mrno: mrval,
                test_no: sampleDetails[i].diagtestid,
                test_type_name: sampleDetails[i].test_type,
                subtest_name: sampleDetails[i].test_name,
                test_amount: sampleDetails[i].price,
                tube_type: sampleDetails[i].tube_type,
                outsource: sampleDetails[i].outsources,
                sample_id: sampleDetails[i].sample_id,
                outsource_flag: outsourceFlag,
                prestestid: sampleDetails[i].prestestid,
                sample_type: sampleDetails[i].sample_type,
                selected_specimen: Selected_specimen,
                tube_color: this.selected_tube_colors,
                selected_reason: "Asserted",
                priority: sampleDetails[i].priority,
                image: ipaddress.Ip_with_img_address + data,
                sampletype_data: specimen_type_array,
                selected_outsource: selected_outsource_id,
              })
              console.log("this.sampleDetails_data" + JSON.stringify(this.sampleDetails_data))
            }
          }
        }, error => { });
  }

  selectAll() {
    console.log(this.isSelected)
    if (this.isSelected == true) {
      for (var i = 0; i < this.sampleDetails_data.length; i++) {
        this.sampleDetails_data[i].isSelected = true;
      }
    } else {
      for (var i = 0; i < this.sampleDetails_data.length; i++) {
        this.sampleDetails_data[i].isSelected = false;
      }
    }
  }

  unselectAll(data) {
    if (data == false) {
      this.isSelected = false;
    }

    if (this.sampleDetails_data.every(value => value.isSelected == true)) {
      this.isSelected = true;
    }
  }

  getDiagTesttubes() {
    this.testtube_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', { diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // var dept_data = obj.diag_dept_list;
          var diagtube_data = obj.tube_type_list;

          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push(
                {
                  tube_type_id: diagtube_data[i].tube_type_id,
                  tube_desc: diagtube_data[i].tube_desc,
                  status: diagtube_data[i].status,
                }
              )
            }
          }
          console.log("getDiagTesttubes", obj)
        }, error => { });
  }

  getTubetype(sampleData, data) {
    this.selected_tube_colors = [];
    var tube_array = [];

    for (var i = 0; i < this.sampletype_data.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j] == this.sampletype_data[i].sample_type_id) {
          // console.log(this.sampletype_data[i].tube_color)
          tube_array = this.sampletype_data[i].tube_color;
          for (var k = 0; k < tube_array.length; k++) {
            this.selected_tube_colors.push(tube_array[k])
          }
        }
      }
    }

    console.log("tube_array", this.selected_tube_colors)

    for (var k = 0; k < this.sampleDetails_data.length; k++) {
      if (this.sampleDetails_data[k].prestestid == sampleData.prestestid) {
        this.sampleDetails_data[k].tube_color = this.selected_tube_colors
      }
    }

    // this.specimen_Array = [];
    // for (var k = 0; k < this.sampleDetails_data.length; k++) {
    //   if (this.specimen_Array.length != 0) {
    //     for (var i = 0; i < this.sampleDetails_data[k].tube_color.length; i++) {
    //       for (var j = 0; j < this.specimen_Array.length; j++) {
    //         if (Object.values(this.specimen_Array[j]).includes(this.sampleDetails_data[k].tube_color[i].tube_type_id) == false) {
    //           this.specimen_Array.push(this.sampleDetails_data[k].tube_color[i])
    //         }
    //       }
    //     }
    //   } else {
    //     for (var i = 0; i < this.sampleDetails_data[k].tube_color.length; i++) {
    //       this.specimen_Array.push(this.sampleDetails_data[k].tube_color[i])
    //     }
    //   }
    // }

    // this.specimen_Array.push(...this.sampleDetails_data[k].tube_color) 
    console.log("specimen_Array", this.specimen_Array)
    // console.log("getTubetype", this.selected_tube_colors)
  }



  printIdCard() {
    let printContents, popupWin;
    printContents = document.getElementById('idprint').innerHTML;

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
    );
    popupWin.document.close();
  }
  openDialog(data) {
    this.service.openDialog(ipaddress.Ip_with_img_address + data);
  }

  closeDialog() {
    this.service.closeDialog();
  }

}
