import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diagnosis-create-new',
  templateUrl: './diagnosis-create-new.component.html',
  styleUrls: ['./diagnosis-create-new.component.css']
})
export class DiagnosisCreateNewComponent implements OnInit {

  public diagPresList = [];
  public relationId: string;
  public relationUrl: string;
  public sendData;
  public relationList=[];
  public relationDesc: string;
  public diagPresListFlag: boolean;
  public report: string;
  public report_show: boolean;
  public diagPresUrl: string;
  public clientRegId;
  public subRelId;

  constructor(public messageservice:ClientViewService,public gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
    this.relationUrl = ipaddress.getIp + "record/getrel";
    this.diagPresUrl = ipaddress.getIp + "record/diagprescriptions/";
    this.diagPresListFlag = true;
  }

  ngOnInit(): void {
    this.getRelationList();
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, 
      JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      }), { headers: headers }).subscribe(
      data => {
        var reldata = data.json();
        if (reldata != null && reldata.relationships.length != 0) {
          for(var i=0;i<reldata.relationships.length;i++){
            this.relationList.push({
              rel_id:reldata.relationships[i].relation_id,
              rel_desc:reldata.relationships[i].relation,
            });
          }
          this.relationId = this.relationList[0].rel_id;
          this.getSltRelationDetails(this.relationList[0].rel_id);
        } 
      });
  }

  getSltRelationDetails(data){
    for(var i=0;i<this.relationList.length;i++){
      if(data == this.relationList[i].rel_id){
        this.relationId = this.relationList[i].rel_id;
        this.relationDesc= this.relationList[i].rel_desc;
        this.diagPresListFlag=true;
         this.getDiagPresList();
      }
    }
  }

  getDiagPresList(){
    this.sendData = {};
    this.diagPresList = [];
     this.sendData.client_id = this.gservice.Client_login_detail_data.Client_Id;
    this.sendData.home_care = "0";
    this.sendData.relation = this.relationDesc; 
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(this.diagPresUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if(dataval!=null){
        var diag_pres_list_data = dataval.prescriptions;
        
        if (diag_pres_list_data != null) {
          for (var i = 0; i < diag_pres_list_data.length; i++) {
            var new_datalist = diag_pres_list_data[i];
            
            this.clientRegId = new_datalist.client_reg_id;
            this.relationId = new_datalist.relation_id;
            this.subRelId = new_datalist.sub_rel_id;

            var Docname = "";
            var res_clientname = "";
            if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
              Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
            }
            else {
              Docname = new_datalist.first_name + " " + new_datalist.last_name;
            }
            if (new_datalist.client_middle_name != undefined && new_datalist.client_middle_name != "") {
              res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_middle_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
            }
            else {
              res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
            }
            var App_date = "";
            if (new_datalist.date != null) {
              App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
            }

            
            var diag_centre_name = "";
            this.diagPresList.push({
              pres_diag_id: diag_pres_list_data[i].pres_diag_id,
              doctorname: Docname,
              clientname: res_clientname,
              date: App_date,
              diag_centre_name: diag_pres_list_data[i].diag_centre_name,
              client_reg_id: new_datalist.client_reg_id,
              relation_id: new_datalist.relation_id,
              sub_rel_id: new_datalist.sub_rel_id
            })
          }
        }
        if (this.diagPresList.length == 0) {
          this.diagPresListFlag = true;
        }
        else {
          this.diagPresListFlag = false;
        }
      }
      },
      error => {

      })
  }

  openDiagpres(pres_id){
    for(let i=0; i < this.diagPresList.length; i++ ){
      if(pres_id == this.diagPresList[i].pres_diag_id){
        var diagpres = {
          AppFlow:"diagdetail",
          pres_id: pres_id,
          flag: "diagnosis",
          relation:this.relationDesc,
          client_reg_id: this.diagPresList[i].client_reg_id,
          relation_id:this.diagPresList[i].relation_id,
          sub_rel_id:this.diagPresList[i].sub_rel_id,
        }
      }

    }
    Client_Helper.setDiagPresList(diagpres);
    this.messageservice.sendMessage("diagnosis_pres_list");
  }

  back(){
    this.messageservice.sendMessage("diagnosis");   
  }
}
