import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { Date_Formate } from '../../../assets/js/common.js';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-physio-doctor-detail',
  templateUrl: './physio-doctor-detail.component.html',
  styleUrls: ['./physio-doctor-detail.component.css']
})
export class PhysioDoctorDetailComponent implements OnInit {
  public physioDoctorList;
  public name;
  public workExperience;
  public profileImg;
  public doctorDetailData = [];
  public hospitalList = [];
  public tempFee;
  public hospitalId;
  public hospitalName;
  public hospitalLocation;
  public hospitalFee;
  public currentDate;
  public currentDateUrl;
  public currentTime;
  public appointmentDate;
  public currentYear;
  public homecareAppDate;
  public visitingSessionData = [];
  public homecareSessionData;
  public homecare;
  public timeList = [];
  public visitSession;
  public visitTime;
  public presentdate;
  public sessionList = [];
  public timeCardFlag:boolean = false;
  public addSessionFlag:boolean = false;
  public appNursePhysio;
  public homecareData = [];
  public homecareTimeList = [];
  public addressFlag:boolean;
  public availableFlag:boolean;
  public nonAvailableFlag:boolean;
  public title;

  constructor(public messageservice:ClientViewService,public datepipe:DatePipe,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
  }

  ngOnInit(): void {
    this.homecare = Client_Helper.getHomecare();
    this.appNursePhysio= Client_Helper.getHomecare_nurse_physio();
    this.addressFlag = (this.homecare == '0') ? true : false;
    this.title = (this.appNursePhysio == 'nurse') ? "Nurse" : "Doctor";

    this.physioDoctorList = Client_Helper.getPhysio_Doctor_List();
    this.name = this.physioDoctorList.name;
    this.workExperience = this.physioDoctorList.experience;
    this.profileImg = this.physioDoctorList.profile_image;
    this.getCurrentDate()
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl,{ country: "IN" }, { headers: headers }).subscribe(
      data => {
        this.currentDate = Date_Formate(data.json().current_date);
        var current_date1 = data.json().current_date;
        this.presentdate = Date_Formate(this.currentDate);
        this.currentTime = data.json().current_time;
        this.currentYear = data.json().current_date.split('-')[0];
        this.appointmentDate = data.json().current_date;
        this.homecareAppDate = this.appointmentDate;
        this.getDoctorDetails(this.currentDate, this.currentTime)
      },error => {});
  }

  getDoctorDetails(curt_date, curt_time){
    var doctor_send_data =JSON.stringify({
      prov_id: this.physioDoctorList.prov_id,
      type: this.appNursePhysio,
      location: this.physioDoctorList.location,
      country: ipaddress.country_code,
      homecare: this.homecare
    });
    var headers = new Headers;
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp + "search/npsbyid", doctor_send_data , {headers:headers}).subscribe(
      data => {
        var obj = data.json();
        if(obj.consultation != null){
          for(var i = 0; i < obj.consultation.length; i++){
            if (obj.consultation[i].fees != "0") {
              this.tempFee = obj.consultation[i].fees;
            } else {
              this.tempFee = "NA";
            }
            this.hospitalList.push({
              hospital_id: obj.consultation[i].hospital_id,
              hospname: obj.consultation[i].hospital,
              Location: obj.consultation[i].location,
              City: obj.consultation[i].city,
              fees: this.tempFee,
            });
            this.doctorDetailData.push({
              hospitalid: obj.consultation[i].hospital_id,
              hospital: obj.consultation[i].hospital,
              location: obj.consultation[i].location,
              day: this.gservice.get_Day(obj.consultation[i].day),
              day_num: obj.consultation[i].day,
              session: this.gservice.get_Session(obj.consultation[i].session),
              available_from: this.gservice.get_Timeformate(obj.consultation[i].available_from),
              available_to: this.gservice.get_Timeformate(obj.consultation[i].available_to),
              fees: this.tempFee,
              TokenorTime: obj.consultation[i].token_flag,
              Time_Duration: obj.consultation[i].time_duration,
              Avail_from: obj.consultation[i].available_from,
              Avail_to: obj.consultation[i].available_to
            });
          }
          this.hospitalId = this.hospitalList[0].hospital_id;
          this.hospitalName = this.hospitalList[0].hospname;
          this.hospitalLocation = this.hospitalList[0].Location;
          this.hospitalFee = this.hospitalList[0].fees;
          this.addSession(this.hospitalId);
          var parts = curt_date.split('-');
          this.available(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); 
          this.timeCardFlag = true;
          this.changeAppDate(curt_date);
        }
        if (obj.home_consult != null) {
          this.homecareData = [];
          this.homecareSessionData = [];
          for (var i = 0; i < obj.home_consult.length; i++) {
            this.homecareData.push({
              Session: this.gservice.get_Session(obj.home_consult[i].session),
              Avail_from: this.gservice.get_Timeformate(obj.home_consult[i].available_from),
              Avail_to: this.gservice.get_Timeformate(obj.home_consult[i].available_to),
              Fees: obj.home_consult[i].fees,
              Time_Inter: obj.home_consult[i].time_interval,
              avail_from: obj.home_consult[i].available_from,
              avail_to: obj.home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(obj.home_consult[i].session));
            this.addSession("");
            this.timeCardFlag = true;
            this.changeHomecareDate(curt_date);
          }
        }
      });
  }

  addSession(Hospital) {
    this.visitingSessionData = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    if (Hospital == "") {
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()) 
        {
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }
          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }
          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }
          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }
          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }
    } else {
      var get_date:string = Date_Formate(this.appointmentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      for (var a = 0; a < this.doctorDetailData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailData[a].day_num;
        let dayflag = false;
        if (dayName == this.doctorDetailData[a].day) {
          dayflag = true;
        } else if (this.doctorDetailData[a].day == "All Days") {
          dayflag = true;
        } else if (sel_day_num != 1 && day_num == 8) {
          dayflag = true;
        }
        if (this.doctorDetailData[a].hospitalid == Hospital
          && dayflag == true) {
          if (this.currentDate.trim() == get_date.trim()) 
          {
            if (this.doctorDetailData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }
            if (this.doctorDetailData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }
            if (this.doctorDetailData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }
            if (this.doctorDetailData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }
            if (this.doctorDetailData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
  }

  checkAvailability(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_sel_date);
    return Available_flag;
  }

  selectAppointmentDate(data,value) {
    this.timeCardFlag = true;
    if (data != null) {
            if (this.homecare == "1") {
              this.homecareAppDate = data;
              this.addSession("");
              this.changeHomecareDate(Date_Formate(data));
            } 
            if (this.homecare == "0") {
              this.appointmentDate = data;
              this.addSession(this.hospitalId);
              this.changeAppDate(Date_Formate(data));
            }
     }
  }

  changeHomecareDate(appdate) {
    this.homecareTimeList = [];
    this.changeHomecareVisitsession(appdate);
  }

  changeHomecareVisitsession(appdate) {
    this.homecareTimeList = [];
    var time_t =[];
    var session;
    for(var j=0; j<this.visitingSessionData.length; j++){
      session="";
      session = this.visitingSessionData[j].description;
      time_t=[];
      for (var i = 0; i < this.homecareData.length; i++) {
        if (session == this.homecareData[i].Session) {
          var From_Split = this.homecareData[i].avail_from.split(':');
          var To_Split = this.homecareData[i].avail_to.split(':');
          var parts = (appdate).split('-');
          var fromday = new Date();
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          if (this.currentDate == appdate && fromday < today) { 
            time_t.push(New_time1);
          } else {
            if (fromday < today) {
              if (this.currentDate != appdate) {
                time_t.push(New_time1);
              }
            }
          }
          var substr = this.homecareData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.homecareData[i].Time_Inter));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailability(appdate, session, chk_time1, end_time);
            if (Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.homecareData[i].Avail_to)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      if(time_t.length !=0){
        this.homecareTimeList.push({
          session:session,
          time:time_t
        });
      }
    } 
  }

  available(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);
    for (var i = 0; i < this.doctorDetailData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailData[i].day);
      if (this.doctorDetailData[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);
        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailData[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);
        if (this.doctorDetailData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }
    Available_flag = this.checkAvailability(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }
    if (Available_flag == false) {
      this.availableFlag = false;
      this.nonAvailableFlag = true;
    } else {
      this.availableFlag = true;
      this.nonAvailableFlag = false;
    }
  }

  changeVisitSessionForTime(appdate){
    this.timeList = [];
    var time_t =[];
    var session;
    for(var j=0; j < this.visitingSessionData.length; j++){
      session ="";
      session = this.visitingSessionData[j].description;
      time_t = [];
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (session == this.doctorDetailData[i].session) {
          var From_Split = this.doctorDetailData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailability(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            time_t.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              time_t.push(New_time1);
            }
          }
          var substr = this.doctorDetailData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailability(appdate, session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailData[i].Avail_to)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      if(time_t.length !=0){
        this.timeList.push({
          session:session,
          time:time_t
        });
      }
    } 
  }

  changeAppDate(appdate) {
    if ((this.currentDate) != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      var get_date = appdate.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (this.doctorDetailData[i].hospitalid == this.hospitalId) {
          if (this.doctorDetailData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }
    this.addSession(this.hospitalId);
    this.changeVisitSessionForTime(appdate);
    this.available(Date_Formate(appdate), this.currentTime);
    }
    else{
      this.addSession(this.hospitalId);
      this.changeVisitSessionForTime(appdate);
      this.available(Date_Formate(appdate), this.currentTime);
    }
  }

  selectAppointmentTime(e,s,index){
    this.addSessionFlag = true;
    this.visitSession = s;
    this.visitTime = e;
    var t;
    if(this.homecare == '0'){
      for(var i= 0; i<this.timeList.length; i++){
        t=[];
        t= this.timeList[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("time_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("time_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }
    }
    else{
      for(var i= 0; i<this.homecareTimeList.length; i++){
        t=[];
        t= this.homecareTimeList[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("hometime_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("hometime_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }
    }
  }

  changeHospital(Hospital) {
    this.addSession(Hospital);
    this.available(Date_Formate(this.appointmentDate), this.currentTime);
    this.changeVisitSessionForTime(this.currentDate);
    for (var r = 0; r < this.hospitalList.length; r++) {
      if (this.hospitalList[r].hospital_id == Hospital) {
        this.hospitalId = this.hospitalList[r].hospital_id;
        this.hospitalLocation = this.hospitalList[r].Location;
        this.hospitalFee = this.hospitalList[r].fees;
        break;
      }
    }
  }

  setTimeAddSession(visitTime){
    if(visitTime != undefined){
      var table_visit_time,tokenorTime;
      var app_time = visitTime.toString().split(" ");
      tokenorTime = visitTime.toString();
      if (tokenorTime != "true") {
        if (tokenorTime != undefined) {
          table_visit_time = app_time[0] + ":00";
        }
      } else {
        table_visit_time = "1";
      }
    } else {
      table_visit_time = "1";
    }
    return table_visit_time;
  }

  addApptSession(){
    var flag:boolean = false;
    if(this.sessionList.length != 0){
      for(var i =0 ; i < this.sessionList.length; i++){
        if(this.visitTime == this.sessionList[i].visit_Time){
          this.toastr.error(Message_data.timeSlotsAlrdyBooked);
          flag = true;
        }
        else{
          flag = false;
        }
      }
    }
    if(flag == false){
      var apptdate;
      if(this.homecare == '0'){
        apptdate = Date_Formate(this.appointmentDate)
      }
      else{
        apptdate = Date_Formate(this.homecareAppDate)
      }
      this.sessionList.push({
        date: apptdate,
        session : this.visitSession,
        time : this.setTimeAddSession(this.visitTime),
        visit_Time: this.visitTime
      });
      if(this.appNursePhysio == "dietician"){
        this.addSessionFlag = false;
      }
    }
  }
  
  deleteSession(i){
    for(var j= 0; j < this.sessionList.length;j++){
      if( i == j){
        this.sessionList.splice(j,1);
        if(this.appNursePhysio == "dietician"){
          this.addSessionFlag = true;
        }
      }
    }
  }

  next(){
    if(this.homecare == '0'){
      Client_Helper.setPhysio_hospital_id(this.hospitalList);
    }
    Client_Helper.setAdd_Session_table(this.sessionList);
    if(this.appNursePhysio == "nurse"){
      this.messageservice.sendMessage("physio_appcreate_new");
    }
    if(this.appNursePhysio == "physio"){
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      this.messageservice.sendMessage("physio_appointment_conform");
    }
    if(this.appNursePhysio == "dietician"){
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      this.messageservice.sendMessage("physio_appointment_conform");
    }
  }

  back() {
    if (Client_Helper.getHomecare_nurse_physio() == "nurse" ) {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_list");
    }
    if (Client_Helper.getHomecare_nurse_physio() == "physio" ) {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_list");
    }
    if (Client_Helper.getHomecare_nurse_physio() == "dietician" ) {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_list");
    }
  }
}
