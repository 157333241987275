<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Relation details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/save_button.svg" *ngIf="isNewFlowFlag"
              class="saveimgbtn_inpatinfo" (click)="save()">
            <img src="../../../assets/ui_icons/buttons/update_button.svg" *ngIf="!isNewFlowFlag"
              class="saveimgbtn_inpatinfo" (click)="update()">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div">
          <div class="header_lable">
            Personal details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2" style="text-align: center;position: relative;">
                <div style="position: relative;width: 160px;margin: 0 auto;">
                  <img id="profile_img" class="profileimg" src="{{imageFileStr}}"
                    onerror="this.src='./././assets/img/default.jpg'" />
                  <input type="file" (change)="fileChange($event)" name="profile_pic" id="profile_pic">
                  <label for="profile_pic" class="edit_profile_icon"><i class="fas fa-pencil-alt"></i></label>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                <div class="row">

                  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Relation<br>
                      <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="relationId"
                        (change)="changeRelation()">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let data of relationList" value="{{data.relation_id}}"
                          [Selected]="data.relation_id == relationId">{{data.relation_name}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                    <mat-label class="matlabel">Salutation
                      <br>
                      <select class="ipcss widthappt" [(ngModel)]="clntSal" (ngModelChange)="changeSalutation($event)">
                        <option value="select" Selected>Select</option>
                        <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                      </select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">First name<br>
                      <input type="text" class="ipcss widthappt" required [readonly]="updateFlag" value={{relFName}}
                        [(ngModel)]="relFName" maxlength="50" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Middle name(optional)<br>
                      <input type="text" class="ipcss widthappt" value={{relMName}} [(ngModel)]="relMName"
                        maxlength="50" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Last name<br>
                      <input type="text" class="ipcss widthappt" required value={{relLName}} [(ngModel)]="relLName"
                        maxlength="50" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Gender<br>
                      <mat-select id="genderid" disableOptionCentering required class="ipcss widthappt"
                        [(ngModel)]="gender">
                        <mat-option disabled>Select</mat-option>
                        <mat-option value="Male" selected [selected]="'Male'== gender">Male</mat-option>
                        <mat-option value="Female" [selected]="'Female' == gender">Female</mat-option>
                        <mat-option value="Transgender" [selected]="'Transgender' == gender">Transgender</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3" [hidden]="dobhide">
                    <mat-label class="matlabel">DOB<br>
                      <input type="text" class="ipcss widthappt" required [(ngModel)]="dob1" disabled matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3" [hidden]="dobhidecal">
                    <mat-label class="matlabel" [hidden]="dobhidecal">DOB<br>
                      <input type="date" class="ipcss noappt_width heightdob" (change)="selectDob(dob)"
                        [(ngModel)]="dob" max="{{currentMaxDate}}" required #matInput>
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Blood group<br>
                      <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="bloodId"
                        disabled={{bloodfieldDisabled}}>
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let bgrps of bgrpList" value="{{bgrps.bgrp_name}}"
                          [selected]="bgrps.bgrp_id == bloodId">{{bgrps.bgrp_name}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Mobile number
                      <input type="text" class="ipcss widthappt" [(ngModel)]="mobile" maxlength="10" type="text"
                        pattern="[0-9]*" required matInput />
                    </mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div">
          <div class="header_lable">
            Address details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <mat-label class=" matlabel">Communication address</mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel wt">
                  <mat-checkbox color=primary labelPosition="before" [(ngModel)]="sameasFlag" [checked]="sameasFlag"
                    (change)="sameaddress($event.checked)"><span style="font-weight: 500 !important;">Same as Register
                      User</span></mat-checkbox>
                </mat-label>
              </div>
            </div>
            <div class="row" *ngIf="!sameasFlag">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 1<br>
                  <input type="text" class="ipcss widthappt" required [(ngModel)]="addr1" maxlength="50" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 2<br>
                  <input type="text" class="ipcss widthappt" value={{addr2}} [(ngModel)]="addr2" maxlength="50"
                    matInput />
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Country<br>
                    <mat-select required class="ipcss " [(ngModel)]="clntCountry"
                        disableOptionCentering>
                        <mat-option *ngFor="let country of clntCountryList"
                            value={{country.description}} (click)="countryChange(clntCountry, '0')">
                            {{country.description}}
                        </mat-option>
                    </mat-select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">State<br>
                    <mat-select required class="ipcss " [(ngModel)]="clntState"
                        disableOptionCentering>
                        <mat-option *ngFor="let state of clntStateList" value={{state.state_id}}
                            (click)="stateChange(clntState, '0')">{{state.description}}
                        </mat-option>
                    </mat-select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">District / City<br>
                    <mat-select required class="ipcss " [(ngModel)]="clntCity"
                        disableOptionCentering>
                        <mat-option (click)="cityChange(clntCity, '0')"
                            *ngFor="let city of clntCityList" value="{{city.district_id}}">
                            {{city.description}}</mat-option>
                    </mat-select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Location<br>
                    <input type="text" class="ipcss " required maxlength="50"
                        [(ngModel)]="clntLocation" (keyup)="locationChange($event)" matInput
                        [matAutocomplete]="auto1" />
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option (click)="selectedLocation(location)"
                            *ngFor="let location of clntLocationList"
                            value="{{location.description}}">
                            {{location.description}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-label>
            </div>

              
              <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Search location<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="changeLocation($event)"
                    [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectLocation(location)" *ngFor="let location of locationList"
                      value={{location}}>
                      {{location}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">City<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCity"
                    (selectionChange)="changeCity($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let city of cityList" value={{city.city_id}}>{{city.city_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">State<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtState"
                    (ngModelChange)="changeState($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let state of stateList" [value]="state.state_id">{{state.state_desc}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Country<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let country of countryList" value={{country.country_desc}}>
                      {{country.country_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Zipcode<br>
                  <input disabled="true" maxlength="6" class="ipcss widthappt" matInput value={{zipcode}}
                    [(ngModel)]="zipcode">
                </mat-label>
              </div> -->
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>