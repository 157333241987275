import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-nurse-ward',
  templateUrl: './nurse-ward.component.html',
  styleUrls: ['./nurse-ward.component.scss']
})
export class NurseWardComponent implements OnInit {

  public ward;
  public wardFromdate;
  public wardTodate;
  public physioNurseList = [];
  public hospitalDetails;
  public userInfo;
  public wardMasterList;
  public currentDatetime;
  public sendPhysioNurseList = [];
  public type;
  public saveFlag:boolean = true;

  constructor(public http:HttpClient,public toastr:ToastrService) { 
    this.type = "Nurse";
  }
  
  ngOnInit(): void {
    this.saveFlag = true
    this.userInfo = Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.getWardData();
  }

  getWardData(){
    var send_data = {
      hptl_clinic_id:this.hospitalDetails,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gward',send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.wardMasterList=[];
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != undefined){
            this.wardMasterList = obj.wards;
            this.ward = this.wardMasterList[0].ward_id;
          }
          this.getDoctors();
        });
  }

  getDoctors(){
    var send_data = {
      doc_reg_id: this.userInfo.user_id,
      hptl_id: this.hospitalDetails,
      location:this.userInfo.location_desc,
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/nurbyhospid/',
    (send_data), { headers: headers }).subscribe(
      data => {   
        var obj = JSON.parse(JSON.stringify(data));
        if(obj != undefined){
          this.physioNurseList=[];
          for(var i= 0; i<obj.nurses.length;i++){
            var name;
            if(obj.nurses[i].middle_name != undefined){
              name = obj.nurses[i].first_name + " " + obj.nurses[i].middle_name + " " + obj.nurses[i].last_name;
            }else{
              name = obj.nurses[i].first_name + " " + obj.nurses[i].last_name;
            }

            this.physioNurseList.push({
              nurse_name:name,
              nurse_reg_id:obj.nurses[i].nurse_reg_id,
              checked:false,
            });
          }
        }
        this.getCurrentDate();
      },error => {});
  }

  getCurrentDate(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {country: ipaddress.country_code.toString()},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            this.wardFromdate = obj.current_date;
            this.wardTodate = obj.current_date;
          }
          this.getRetrievalData();
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  getRetrievalData(){
    var send_data = {
      ward_id:this.ward,
      hptl_clinic_id: this.hospitalDetails,
      from_date: this.wardFromdate,
      to_date: this.wardTodate,
      is_ward:true,
      country:ipaddress.country_code,
      type:this.type,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gnpw',
    (send_data), { headers: headers }).subscribe(
      data => {  
        var response = JSON.parse(JSON.stringify(data));
        if(response != undefined){
          var obj = response.np_details;
          if(obj != undefined){
            for(var i= 0; i < obj.length; i++){
              this.selectPhysioNurse(true,obj[i].user_id);
            }
          }
        }
      });
  }

  changeWard(){
    this.getRetrievalData();
  }

  fromDate(e){
    this.wardFromdate = e;
    this.getRetrievalData();
  }

  toDate(e){
    this.wardTodate = e;
    this.getRetrievalData();
  }

  save(){
    var send_data = {
      users : this.sendPhysioNurseList,
      ward_id:this.ward,
      from_date: this.wardFromdate,
      to_date: this.wardTodate,
      type:this.type,
      country:ipaddress.country_code,
      is_ward:true,
    }
    console.log("eard send_data--"+JSON.stringify(send_data))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sinpnpt',
    (send_data), { headers: headers }).subscribe(
      data => {   
        var obj = JSON.parse(JSON.stringify(data));
        console.log("eard save--"+JSON.stringify(obj))
        if(obj.key != 0){
          this.saveFlag = false;
          this.toastr.success(Message_data.saveSuccess);
          this.ngOnInit();
        }else{
          this.saveFlag = true;
          this.toastr.success(Message_data.notSavedSuccess);
        }
      },error => {this.saveFlag = true;});
  }

  selectPhysioNurse(e,id){
    if(e == true){
      this.sendPhysioNurseList.push(id);
      for (var j = 0; j < this.physioNurseList.length; j++) {
        this.physioNurseList[j].checked = false;
      }
  
      if (this.sendPhysioNurseList != undefined && this.sendPhysioNurseList.length != 0) {
        for (var i = 0; i < this.sendPhysioNurseList.length; i++) {
          for (var j = 0; j < this.physioNurseList.length; j++) {
            if (this.sendPhysioNurseList[i] == this.physioNurseList[j].nurse_reg_id) {
              this.physioNurseList[j].checked = true;
            }
          }
        }
      }  
    }else{
      if(this.sendPhysioNurseList.length != 0){
        for( var k=0; k<this.sendPhysioNurseList.length; k++){
          if(id == this.sendPhysioNurseList[k]){
            for (var j = 0; j < this.physioNurseList.length; j++) {
              if (this.sendPhysioNurseList[k] == this.physioNurseList[j].nurse_reg_id) {
                this.physioNurseList[j].checked = false;
              }
            }
            this.sendPhysioNurseList.splice(k,1);
          }
        }
      }
    }
  }
}
