<mat-accordion class="mataccordion">
    <mat-expansion-panel   class="expandpanel"(opened)="panelOpenState14 = true"
    (closed)="panelOpenState14 = false">
       <mat-expansion-panel-header class="exppanel ">
          <mat-panel-title class="title width">
            <span class="widthappt"> 
           Dental history</span>
            <!-- <mat-icon  class="iconsleft" *ngIf="!panelOpenState14">add</mat-icon>
            <mat-icon  class="iconsleft" *ngIf="panelOpenState14">remove</mat-icon>   -->
          </mat-panel-title>
       </mat-expansion-panel-header>
    
    <div  [hidden]="dental_txt">
        <div class="row" style="padding-top: 5px;">
            <div class="col-9 col-md-6 col-lg-6 col-xl-3">
                <mat-label class="matlabel widthappt">Is this child’s first visit ?</mat-label>
            </div>
            <div class="col-3 col-md-6 col-lg-6 col-xl-3">

                <mat-button-toggle id="toggid15" class="togglebtn" [disabled]="client"
                [(ngModel)]="den_hist_togg1" ngDefaultControl [checked]="den_hist_togg1"
                (change)="changechildfirstvisit($event.source.checked)" [ngStyle]="den_hist_togg1 && { 'background' : 'green' } || !den_hist_togg1 && { 'background' : 'red' }">
               <span class="familytab" *ngIf="!den_hist_togg1">No</span>
               <span class="familytab" *ngIf="den_hist_togg1">Yes</span>
            </mat-button-toggle>
                <!-- <button  id="matbtn"  *ngIf="ynopt15"    [(ngModel)]="den_hist_togg" [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}" mat-raised-button (click)="option(15)">Yes</button>
            <button id="matbtn"  *ngIf="!ynopt15"   [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="den_hist_togg" mat-raised-button (click)="option(15)">No</button>
            -->
            </div>
           
           
            
        </div>
        <div class="row" [hidden]="child_visit_fields">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"[hidden]="last_vit_hidden">
                <mat-label class="matlabel" [hidden]="last_vit_hidden"> When was the last visit?<br>
                    <input matInput  class="ipcss "  maxlength="15" [(ngModel)]="den_hist_last_vit" [required]="dental_last_vit_req"
                    (change)="Dental_change()" [disabled]="client" />
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="ple_hidden">

        <mat-label class="matlabel">Pleasant / Unpleasant experience?<br>
            <select  class="ipcss widthappt" [(ngModel)]="den_hist_ple" [required]="dental_ple_exp_req" [disabled]="client" disableOptionCentering >
                <option disabled>Select</option>
                <option value="Pleasant">Pleasant</option>
                <option value="Unpleasant">Unpleasant</option>
               
            </select>
        </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="pre_treat_hidden">
                <mat-label class="matlabel" >What was the previous treatment?<br>
                    <textarea #matInput maxlength="250" class="ipcss obsttxtareaheight widappt"[(ngModel)]="den_hist_prev" [required]="dental_prev_treat_req" [disabled]="client"
                    (change)="Dental_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="beh_last_vit_hidden">
                <mat-label class="matlabel">Behaviour during last visit?<br>
                    <textarea #matInput maxlength="250" class="ipcss obsttxtareaheight widappt" [(ngModel)]="den_hist_beh_last_vit" [required]="dental_beh_last_vit_req" [disabled]="client"
                    (change)="Dental_change()"></textarea>
                </mat-label> 
            </div>
       

        </div>
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading"  [hidden]="oral_txt_hyg">Oral hygiene</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Supervised / unsupervised<br>
                    <textarea #matInput maxlength="250" class="ipcss textarea" [required]="dental_sup_un_req" [disabled]="client"
                    (change)="Dental_change()" [(ngModel)]="den_hist_sup" ></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >No of times brush changed<br>
                    <textarea #matInput  class="ipcss " [(ngModel)]="den_hist_bru_cng" [required]="dental_prev_treat_req" [disabled]="client"
                    (change)="Dental_change()"maxlength="250"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Mode of cleaning<br>
                    <textarea #matInput maxlength="50" class="ipcss " (change)="Dental_change()" [disabled]="client"
                    [(ngModel)]="den_hist_mod_clean"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Other aids (If any)<br>
                    <textarea #matInput maxlength="50" class="ipcss "  (change)="Dental_change()" [disabled]="client"
                    [(ngModel)]="den_hist_ot_aid"></textarea>
                </mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading" [hidden]="diet_hist_hidden">Diet history</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="diet_hist_hidden">
            <mat-label class="matlabel" >Session<br>
                <select class="ipcss widthappt"[(ngModel)]="dietsession_txt"  required disableOptionCentering [disabled]="client">
                    <option disabled>Select</option>
                    <option value="Morning">Morning</option>
                    <option value="After noon">After noon</option>
                    <option value="Evening">Evening</option>
                    <option value="Night">Night</option>
                </select>
            </mat-label>

            </div> 
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Type of food<br>
                    <textarea #matInput maxlength="50" class="ipcss textarea"  [(ngModel)]="food_type" [disabled]="client"
                    required></textarea>
                </mat-label>
            </div> 
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Quantity<br>
                    <textarea #matInput maxlength="50" class="ipcss textarea " [(ngModel)]="quantity_txt" [disabled]="client"
                    required></textarea>
                </mat-label>
            </div> 
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Method of preparation   <br>
                    <textarea #matInput maxlength="50" class="ipcss textarea"  [(ngModel)]="med_prepara" [disabled]="client"
                   ></textarea>
                </mat-label>
            </div> 
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Quantity of sugar<br>
                    <textarea #matInput class="ipcss  " [(ngModel)]="qty_sugar_data" maxlength="15" required></textarea>
                </mat-label>
            </div> 
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="padding-top: 24px;" *ngIf="!client">
        
          
                <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="diet_add_click()" class="saveimgbtn_inpatinfo add" />
          
            </div> 
          
        </div>
       
       <div class="col-12" [hidden]=!diet_details_array.length>
            <table id="tbl">
                <thead>
                    <tr>
                        <th>Session</th>
                        <th>Type of food</th>
                        <th>Quantity</th>
                        <th>Method of preparation</th>
                        <th>Quantity of sugar</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let diet of diet_details_array">
                        <td style="text-align: left;">{{diet.session}}</td>
                        <td style="text-align: left;">{{diet.type_food}}</td>
                        <td>{{diet.quantity}}</td>
                        <td style="text-align: left;">{{diet.met_prepare}}</td>
                        <td>{{diet.qty_sugar}}</td>
                        <td>
                            <a (click)="diet_delete(diet.session,diet.type_food,diet.quantity)">Delete</a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>  
        <div clas="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="sweet_score">
                <mat-label class="matlabel" >Sweet scors<br>
                    <input matInput  class="ipcss widthappt" maxlength="3" 
                    [(ngModel)]="den_hist_swt_score" [disabled]="client"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="intrup_txt">
                <mat-label class="matlabel" >Interpretation<br>
                    <textarea #myInput id="myInput"  [disabled]="client"
                    class="ipcss widthappt" [(ngModel)]="den_hist_int" maxlength="25"></textarea>
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="diet_any_hidden">
                <mat-label class="matlabel">Diet analysis<br>
                    <textarea #matInput  class="ipcss " maxlength="250" [(ngModel)]="den_hist_diet_any" [disabled]="client" [required]="dental_diet_any_req"
                  ></textarea>
                </mat-label>
            </div>
           
        </div>
        <div class="row">
            <div class="col-12"> <p class="tervys_heading" [hidden]="oral_hab_div">Oral habitual history</p></div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="oral_hab_div">
                <mat-label class="matlabel" >Type of habit<br>
                    <select class="ipcss  widthappt" disableOptionCentering [disabled]="client"  (change)="habit_data_chage()" [(ngModel)]="type_of_habit" >
                        <option *ngFor="let type of type_of_habit_array" value={{type.habit_name}}>{{type.habit_name}}</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="indi_hide">
                
           
            <mat-label class="matlabel" ><br>
                <textarea #matInput maxlength="50" class="ipcss " [(ngModel)]="individual_txt" [disabled]="client"
                ></textarea>
            </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="indi_hide">
                
            <mat-label class="matlabel"><br>
                <textarea #matInput maxlength="50" class="ipcss " [(ngModel)]="individual_txt" [disabled]="client"
                ></textarea>
            </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="pre_hide">
                <mat-label class="matlabel" ><br>
                    <select class="ipcss  widthappt"[(ngModel)]="pressure_txt"  required disableOptionCentering [disabled]="client">
                        <option >Select</option>
                        <option value="Thumb sucking">Thumb sucking</option>
                        <option value="Tongue thrusting">Tongue thrusting</option>
                    
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="non_pre_hide">
              
            <mat-label class="matlabel" ><br>
                <input matInput  class="ipcss "  placeholder="Mouth breathing" [(ngModel)]="non_pre_txt" [disabled]="client"/>
            </mat-label>  
            </div>
           
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="bit_hide">
                <mat-label class="matlabel"><br>
                    <input matInput  class="ipcss "  value="Pencil biting" disabled  [(ngModel)]="biting_txt" [disabled]="client"/>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="non_pre_bit_hide">
                <mat-label class="matlabel" ><br> 
                    <textarea #matInput class="ipcss "  maxlength="250" [disabled]="client"
                    placeholder="Description" [(ngModel)]="non_pre_bit_txt"></textarea>
                </mat-label>
            </div>
       
        </div>
        <div class="row "  [hidden]="inten_pre_hide" >
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="pre_hide">
                <mat-label class="matlabel">Condition problem<br>
                    <select class="ipcss widthappt" (change)="condition_prb_change()" [(ngModel)]="con_prb"  required disableOptionCentering [disabled]="client">
                        <option >Select</option>
                        <option value="Sucking">Sucking</option>
                        <option value="Biting">Biting</option>
                        <option value="Mouth breathing">Mouth breathing</option>
                        <option value="Tongue thrusting">Tongue thrusting</option>
                    
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="suck_hide">
                <mat-label class="matlabel"><br>
                    <select  class="ipcss widthappt"[(ngModel)]="suck_data"  required disableOptionCentering [disabled]="client">
                        <option >Select</option>
                        <option value="Finger">Finger</option>
                        <option value="Thumb">Thumb</option>
                        <option value="Lip">Lip</option>
                        <option value="Cheek">Cheek</option>
                    
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="suck_hide">
                <mat-label class="matlabel"><br>
                    <select  class="ipcss widthappt"[(ngModel)]="suck_data"  required disableOptionCentering [disabled]="client">
                        <option >Select</option>
                        <option value="Finger">Finger</option>
                        <option value="Thumb">Thumb</option>
                        <option value="Lip">Lip</option>
                        <option value="Cheek">Cheek</option>
                    
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="biting_hide">
                <mat-label class="matlabel" ><br>
                    <select  class="ipcss widthappt"[(ngModel)]="bit_data"  required disableOptionCentering [disabled]="client" >
                        <option disabled>Select</option>
                        <option value="Finger">Finger</option>
                        <option value="Thumb">Thumb</option>
                        <option value="Lip">Lip</option>
                        <option value="Cheek">Cheek</option>
                    
                    </select>
                </mat-label>
            </div>
           
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inten_pre_hide">
                <mat-label class="matlabel" >Frequency<br>
                    <textarea #matInput [disabled]="client" [(ngModel)]="freq_txt" class="ipcss widthappt" maxlength="15"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inten_pre_hide">
                <mat-label class="matlabel" >Duration<br>
                    <textarea #matInput maxlength="50" class="ipcss obsttxtareaheight widappt" [(ngModel)]="dur_txt" maxlength="15" [disabled]="client"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inten_pre_hide">
                <mat-label class="matlabel" >Intensity<br>
                    <textarea #matInput  class="ipcss obsttxtareaheight widappt" [(ngModel)]="int_txt" maxlength="50"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inten_pre_hide">
                <mat-label class="matlabel" >Age at which the habit is stopped<br>
                    <textarea #matInput class="ipcss obsttxtareaheight widappt" [(ngModel)]="age_habit" maxlength="2" [disabled]="client"></textarea>
                </mat-label>
            </div>
        </div>  
      
 </div>

    </mat-expansion-panel>
</mat-accordion>
<br>
<br>
<br>