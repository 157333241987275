<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottomy">
                            <!-- (click)="location_selected(location) -->
                            <mat-label class="matlabel">Client Name<br>
                                <input type="text" class="ipcss" [(ngModel)]="clientName" name="loc_name" type="text"
                                    aria-label="Number" matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                            <mat-label class="matlabel" style="font-size: 15px;
                                      font-weight: 600;">Range</mat-label><br>
                            <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                                [(ngModel)]="appointmentType">
                                <option value="Today">Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fort Night">Fort Night</option>
                                <option value="Monthly">Monthly</option>
                                <option value="3 Months">3 Months</option>
                                <option value="6 Months">6 Months</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Date">Date</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                            <mat-label class="matlabel">From<br>
                                <input type="date" class="ipcss widthbilllist datecss"
                                    (change)="selectFromDate(fromDate)" [(ngModel)]="fromDate" #matInput
                                    max="{{currentDate}}">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                            <mat-label class="matlabel">To<br>
                                <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                    (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                        <img (click)="getClientDetails()" src='../../../assets/img/search.png' width='18px'
                            height="auto" style="width: 46px;height: 19px;position: relative;top: 24px;right: 12px;" />
                    </div>
                    <div class="row">
                        <div class="col-4 col-sm-3 col-lg-2 col-md-3 col-xl-2" *ngFor="let dataList of patientList">
                            <div (click)="selectClient(dataList)">
                                <div class="special_box_class  ">
                                    <img src="{{dataList.profileImage}}" class="specialization_image ">
                                    <p class="specialization_heading" style="text-align: center;margin: 0;">
                                        {{dataList.name}}</p>
                                    <p style="text-align: center;margin: 0;"> {{dataList.lastVisit}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>