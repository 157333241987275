import { Component, OnInit ,ElementRef,Input,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pharmacyprescriptiondetails',
  templateUrl: './pharmacyprescriptiondetails.component.html',
  styleUrls: ['./pharmacyprescriptiondetails.component.css']
})
export class PharmacyprescriptiondetailsComponent implements OnInit {
  @Input() id: string;parm: string;  
  public element: any;
  public dis_txt;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<PharmacyprescriptiondetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  
  selectClientType(e){
    this.dis_txt = e;
  }
  
  close(){
    this.dialogRef.close();
  }
}
