import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-user-list',
  templateUrl: './physio-user-list.component.html',
  styleUrls: ['./physio-user-list.component.scss']
})
export class PhysioUserListComponent implements OnInit {
  public userId;
  public userInfo;
  public userType;
  public physioLocations = [];
  public physioUserList = [];
  public location;
  public hptlClinicId;
  public getUserType;

  constructor(public http: Http, public toastr: ToastrService, public messageService: PhysioService) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.getLocationData();
          }
        },error => {});
  }

  getLocationData() {
    this.physioLocations = [];
    this.getUserType = Physio_Helper.getUserTypeId().user_type;
    this.userType = (this.getUserType != null) ? "physio_user" : "physio";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/ploc/',
      JSON.stringify({
        physio_id: this.userId,
        type: this.userType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
            for (var i = 0; i < obj.physio_loc.length; i++) {
              this.physioLocations.push({
                centre_id: obj.physio_loc[i].hptl_clinic_id,
                centre_name: obj.physio_loc[i].hptl_name,
                centre_location: obj.physio_loc[i].location,
                view_data: obj.physio_loc[i].hptl_name + " - " + obj.physio_loc[i].location
              });
            }
            this.hptlClinicId= this.physioLocations[0].centre_id;
            this.location = this.physioLocations[0].view_data;
            this.getUserList(this.physioLocations[0].centre_id);
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getUserList(centre_id) {
    this.physioUserList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gapu/',
      JSON.stringify({
        hptl_clinic__id: centre_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.users != null) {
            for(var i=0;i<obj.users.length;i++){
              var nameval;
              if(obj.users[i].middle_name != undefined){
                nameval= obj.users[i].first_name+" "+obj.users[i].middle_name+" "+obj.users[i].last_name;
              } else {
                nameval= obj.users[i].first_name+" "+obj.users[i].last_name;
              }
              this.physioUserList.push({
                users_id:obj.users[i].users_id,
                profile: ipaddress.Ip_with_img_address+obj.users[i].profile_image,
                name: nameval,
                gender: obj.users[i].gender,
                mobile: encrypt_decript.Decript(obj.users[i].mobile),
                is_active: obj.users[i].is_active
              });
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  deleteUser(userid) {
    if (userid != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'physio/dpu/',
        JSON.stringify({
          diag_users_id: userid
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj != null && obj.key == "1") {
              this.toastr.success(Message_data.userDetDeletedSuccess);
              this.getUserList(this.hptlClinicId);
            } else {
              if (obj.result != null) {
                this.toastr.error(obj.result);
              } else {
                this.toastr.error(Message_data.testDetNotDeleted);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }

  changeLocation(data) {
    if (this.physioLocations != undefined) {
      for (var i = 0; i < this.physioLocations.length; i++) {
        if (this.physioLocations[i].view_data == data) {
          this.getUserList(this.physioLocations[i].centre_id);
          this.hptlClinicId= this.physioLocations[i].centre_id
        }
      }
    }
  }

  viewPhysiotherapist(data) {
    var userDetails = {
      user_reg_id: data,
      hptl_clinic_id:this.hptlClinicId
    }
    Physio_Helper.setUserDetails(userDetails);
    this.messageService.sendMessage("userViewPage");
  }
}
