import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Time_Formate_Session } from '../../../assets/js/common';
import { Subscription } from 'rxjs';
import { Helper_Class } from '../../helper_class';
import { MatDialog } from '@angular/material/dialog';
import { PhysioService } from '../physio/physio.service';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { CommonDataService } from '../../providers/common-data.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-appointment-detailview',
  templateUrl: './physio-appointment-detailview.component.html',
  styleUrls: ['./physio-appointment-detailview.component.scss']
})
export class PhysioAppointmentDetailviewComponent implements OnInit {
  public apptFlag = 'min';
  subscription: Subscription;
  public curentDateURL: string;
  public url: string;
  public sendApptDetails;
  public currentTime;
  public apptDate;
  public apptTime;
  public apptReason;
  public clntGender;
  public clntAge;
  public clntbloodGroup;
  public clntRelation;
  public clntMobile;
  public clntName;
  public hospDetails = [];
  public symptoms;
  public retrvClientDetails;
  public appDetails: string;
  public physiotherapistName: string;
  public patientList;
  public userInfo;
  public nodapp:boolean;
  public appointmentList = [];
  public homecare;
  public currentDate;
  public showListFlag: boolean = false;

  constructor(public naviagationService:PhysioService,public messageservice1: PhysioCasesheetService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,public dialog: MatDialog,
    public gservice: CommonDataService) {
    this.curentDateURL = ipaddress.getIp + 'adm/curdate';
    this.url = ipaddress.getIp + 'appointment/pappdet/';
  }

  ngOnInit(): void {
    this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.apptFlag = message;
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.patientList = Physio_Helper.getClient_Info();
    this.homecare = Physio_Helper.getHomecare();
    this.getCurrentDate();
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentTime = obj.current_time;
            this.currentDate = obj.current_date;
          }
          this.getDocAppointmentList(this.userInfo.user_id);
        },error => {});
  }

  getDocAppointmentList(data): void {
    this.appointmentList = [];
    var data = Physio_Helper.getpatient_information();
    if(data != null && data != undefined){
      if (data.appointments != null) {
        var appolist = data.appointments;
        if (appolist.length != "0") {
          for (var i = 0; i < appolist.length; i++) {
            if (appolist[i].status == "0") {
              var status_txt = "Completed";
            }
            else if (appolist[i].status == "1") {
              if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                if (appolist[i].f_time != "1") {
                  var get_time = new Date(appolist[i].date + " " + this.currentDate);
                  var cur_time = new Date(this.currentDate + " " + this.currentDate);
                  if (get_time > cur_time) {
                    status_txt = "Not visited";
                  } else {
                    status_txt = "Open";
                  }
                } else {
                  var get_time = new Date(appolist[i].date);
                  var cur_time = new Date(this.currentDate);
                  if (get_time > cur_time || get_time == cur_time) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              } else {
                if (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) {
                  status_txt = "Open";
                } else {
                  status_txt = "Not visited";
                }
              }
            } else if (appolist[i].status == "2") { 
              if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                var get_time = new Date(appolist[i].date + " " + this.currentDate);
                var cur_time = new Date(this.currentDate + " " + this.currentDate);
                status_txt = "Confirmed";
              } else {
                if (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) {
                  status_txt = "Open";
                } else {
                  status_txt = "Not visited";
                }
              }
            }
            if (appolist[i].token != null) {
              var token_time_label = "Token no : " + appolist[i].token;
            } else {
              var Temptimesplit = appolist[i].f_time;
              var offTime = Temptimesplit.split(':');
              offTime[0] = offTime[0] % 12;
              var output = offTime.join(':');
              var get_timeq = output.split(":");
              if (get_timeq[0] == "0") {
                output = "12" + ":" + get_timeq[1];
              }
              token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
            }
            var Patient_name = null;
            if (appolist[i].middle_name != undefined && appolist[i].middle_name != null){ 
              if (encrypt_decript.Decript(appolist[i].middle_name) != ""){ 
                Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
              }else {
                Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
              }
            }else {
              Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
            }
            if (appolist[i].sub_rel_id != null) {
              var sub_rel = appolist[i].sub_rel_id;
            }
            else {
              sub_rel = "0";
            }
            if (appolist[i].age != null) {
              var get_age = appolist[i].age;
            }
            else {
              get_age = "";
            }
            if (appolist[i].address2 != null && appolist[i].address2 != "") {
              var add_data = appolist[i].address1 + " " + appolist[i].address2;
            }
            else {
              add_data = appolist[i].address1;
            }
            if (appolist[i].rel_zipcode != null) {
              var zipcode_data = encrypt_decript.Decript(appolist[i].rel_zipcode);
            }
            else {
              zipcode_data = appolist[i].zipcode;
            }
            this.appointmentList.push({
              app_data: appolist[i],
              hptl_clinic_id: appolist[i].hptl_clinic_id,
              patient_name: Patient_name,
              tot_app_count: token_time_label,
              session: encrypt_decript.Decript(appolist[i].session),
              status: status_txt,
              profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
              doc_app_id: appolist[i].app_id,
              client_reg_id: appolist[i].client_reg_id,
              relation_id: appolist[i].relation_id,
              sub_rel: sub_rel,
              rel_count: appolist[i].country_id,
              rel_stat: appolist[i].state_id,
              rel_city: appolist[i].city_id,
              rel_loc: appolist[i].location_id,
              rel_loc_desc: appolist[i].location,
              loc_age: get_age,
              gender_data: encrypt_decript.Decript(appolist[i].gender),
              dob_data: appolist[i].dob,
              address: add_data,
              state_desc: appolist[i].state_desc,
              city_desc: appolist[i].city_desc,
              country_desc: appolist[i].country_desc,
              rel_zipcode: zipcode_data,
            });
          }
          this.nodapp = true; 
        } else {
          this.nodapp = false; 
        }
      } 
    }else {
      this.toastr.error(Message_data.getNetworkMessage());
    }
  }

  isReadonly(){return true;}

  viewAppt(list) {
    var send_data:any = [];
    this.showListFlag = true;
    send_data = {
      doc_app_id:this.patientList.app_id,
      type: "doctor",
      home_care: Physio_Helper.getHomecare(),
      country: ipaddress.country_code,
    }
    this.appointmentDetails();
    this.naviagationService.filter('');
    this.naviagationService.filter('detail_view');
  }

  appointmentDetails() {
    this.sendApptDetails = {
      app_id: this.patientList.app_id,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.url, JSON.stringify(this.sendApptDetails), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = JSON.stringify(data);
        var dateSplit = data.json().date.split("-");
        this.apptDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        var get_f_time = this.gservice.get_time_form_session(data.json().f_time, this.gservice.get_seesion_ampm(data.json().session));
        this.apptTime = get_f_time;
        if (data.json().reason != null) {
          this.apptReason = data.json().reason;
        }
        this.clntGender = encrypt_decript.Decript(data.json().gender);
        this.clntAge = data.json().age;
        this.clntbloodGroup = data.json().blood_group_desc;
        this.clntRelation = data.json().relation;
        this.clntMobile = encrypt_decript.Decript(data.json().mobile);
        this.symptoms = encrypt_decript.Decript(data.json().symptoms);
        if(data.json().dr_middle_name != undefined && data.json().dr_middle_name != null){
          this.physiotherapistName = data.json().dr_first_name+" "+data.json().dr_middle_name+" "+data.json().dr_last_name;
        } else {
          this.physiotherapistName = data.json().dr_first_name+" "+data.json().dr_last_name;
        }
        function StringBuilder(this:any,value) {
          this.strings = new Array("");
          this.append(value);
        }
        StringBuilder.prototype.append = function (value) {
          if (value) {
            this.strings.push(value);
          }
        }
        StringBuilder.prototype.clear = function () {
          this.strings.length = 1;
        }
        StringBuilder.prototype.toString = function () {
          return this.strings.join("");
        }
        var hospadd = new StringBuilder("")
        if (data.json().address1 != null) {
          hospadd.append(data.json().hptl_name);
          hospadd.append("\n");
          hospadd.append(data.json().address1);
          hospadd.append("\n");
          if (data.json().address2 != null && data.json().address2 != "") {
            hospadd.append(data.json().address2);
            hospadd.append("\n");
          }
          hospadd.append(data.json().location);
          hospadd.append("\n");
          hospadd.append(data.json().city);
          hospadd.append("\n");
          hospadd.append(data.json().state+" - "+data.json().zipcode);
          hospadd.append("\n");
          hospadd.append(data.json().country);
          this.hospDetails = hospadd.toString();
        }
        var appdet = new StringBuilder("")
        if (data.json().date != null) {
          if (data.json().date != null) {
            appdet.append("Date :" + this.gservice.get_Date_Formate(data.json().date));
            appdet.append("\n");
          }
          if (data.json().token_count == "true") {
            appdet.append("Token no :" + data.json().token);
            appdet.append("\n");
          } else {
            appdet.append("Time :" + Time_Formate_Session(data.json().f_time, encrypt_decript.Decript(data.json().session)));
            appdet.append("\n");
          }
          if (data.json().session != null) {
            appdet.append("Session :" + encrypt_decript.Decript(data.json().session));
          }
          this.appDetails = appdet.toString();
        }
        this.retrvClientDetails = data.json();
        var new_datalist = this.retrvClientDetails;
        var middle = "";
        if (new_datalist.middle_name != null) {
          middle = encrypt_decript.Decript(new_datalist.first_name) + ' ' + encrypt_decript.Decript(new_datalist.middle_name) + ' ' + encrypt_decript.Decript(new_datalist.last_name);
        } else {
          middle = encrypt_decript.Decript(new_datalist.first_name) + ' ' + encrypt_decript.Decript(new_datalist.last_name);
        }
        this.clntName = middle;
      }
    )
  }

  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }
}
