<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Help Portal</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
        
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 select_bottom" >
          <mat-label class="matlabel">App help required for<br>
            <mat-select class="ipcss "  disableOptionCentering  [(ngModel)]="module" >
              <mat-option *ngFor="let module of moduledata" value={{module.module_id}}  (click)="topic_change(module.module_id)" >{{module.description}}</mat-option>
            </mat-select>
        </mat-label> 
        </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div *ngIf="helptopic.length == 0" class="nodata col-12">No data(s) found</div>
            <div *ngIf="helptopic.length != 0">
              <div style="margin-top: 15px; margin-bottom: -20px;">
                <p>Steps to follow </p>
              </div>
            </div>
            <table *ngIf="helptopic.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable helptable">
              <tbody>
                <tr *ngFor="let person of helptopic">
                  <td style="font-size: 12px ;text-align: left;" >
                    {{person.helpdesc}}

                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>