import {NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule,DatePipe} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { AdvanceCreateComponent } from './advance-create.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxBarcodeModule } from 'ngx-barcode';
import {ScannerDetectionModule} from 'ngx-scanner-detection';


@NgModule({
    imports:[MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule,MatAutocompleteModule,NgxBarcodeModule,ScannerDetectionModule],
    declarations:[AdvanceCreateComponent],
    exports:[AdvanceCreateComponent],
    // providers: [DatePipe],
    // schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class AdvanceCreateModule{   
   
}