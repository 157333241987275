import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminViewService } from 'src/app/Admin_module/admin-view/admin-view.service';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from 'src/app/ipaddress';
import { ServerApi } from 'src/app/server-api';
import { DiagnosisService } from '../../Diagnosis_module/diagnosis/diagnosis.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-list-package',
  templateUrl: './list-package.component.html',
  styleUrls: ['./list-package.component.scss']
})
export class ListPackageComponent implements OnInit {
  public userinfo;
  public diagCenterID;
  public centre_id;
  public createFlag:boolean = true;
  public packageListArray:any = [];
  public editFlag:boolean = true;
  dtOptions: DataTables.Settings = {};

  constructor(public serviceAPI: ServerApi, public toastr: ToastrService, public adminService: MenuViewService, public frontService:MenuViewService,public diagService: MenuViewService,) {}

  ngOnInit(): void {
    var userinfo = Helper_Class.getInfo();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [ 0,1,4,5 ] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by package name or package type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.diagCenterID = userinfo.diag_centre_id;
    if( Helper_Class.getLoginType() == "diagnosis" ) {
      this.centre_id = userinfo.diag_centre_id;
    }else{
      this.centre_id = userinfo.hptl_clinic_id;
    }
    
    this.getPackageList();
  }
  async getPackageList(){
    var sendData
    if(this.centre_id != undefined){
      sendData = {
        centre_id : this.centre_id
      }
    }else{
      sendData = {
        noSendID : "0"
      }
    }
    var response = await this.serviceAPI.PostData('adm/ghps',sendData).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      for( var i = 0; i < obj.health_packages.length; i++ ){
        var img = obj.health_packages[i].image != undefined ? ipaddress.Ip_with_img_address + obj.health_packages[i].image : "";
        var img_path = obj.health_packages[i].image != undefined ? obj.health_packages[i].image : "";
     //   var price = obj.health_packages[i].image != undefined ?  obj.health_packages[i].price : "";
        this.packageListArray.push({
          health_pkg_id : obj.health_packages[i].health_pkg_id,
          package_type_id : obj.health_packages[i].package_type_id,
          centre_id : obj.health_packages[i].centre_id,
          package_name : obj.health_packages[i].package_name,
          image :  img,
          package_short_desc : obj.health_packages[i].package_short_desc,
          package_long_desc : obj.health_packages[i].package_long_desc,
          price : parseFloat((obj.health_packages[i].price)).toFixed(2),
          package_type_desc : obj.health_packages[i].package_type_desc,
          image_path: img_path,
            
        });
      } 
      
    }
  }

  create(){
    var sendData = {
      provied : "admin",
      flow : "create",
    }
    Helper_Class.setPackage(null);
    Helper_Class.setPackage(sendData);
    if( Helper_Class.getLoginType() == "Admin" ) { 
      this.adminService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "front-desk" ){
      this.frontService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "diagnosis" ){
      this.diagService.sendMessage("createPackage");
    }
  }

  editPackage(array){
    var sendData = {
        provied : "admin",
        flow : "edit",
        content : array
    }
    Helper_Class.setPackage(null);
    Helper_Class.setPackage(sendData);
    if( Helper_Class.getLoginType() == "Admin" ) { 
      this.adminService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "front-desk" ){
      this.frontService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "diagnosis" ){
      this.diagService.sendMessage("createPackage");
    }
   
  }
  viewPackage(array){
    var sendData = {
      provied : "admin",
      flow : "view",
      content : array
    }

    Helper_Class.setPackage(null);
    Helper_Class.setPackage(sendData);
    if( Helper_Class.getLoginType() == "Admin" ) { 
      this.adminService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "front-desk" ){
      this.frontService.sendMessage("createPackage");
    }else if( Helper_Class.getLoginType() == "diagnosis" ){
      this.diagService.sendMessage("createPackage");
    }
  }
}
