<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">
    <div class="col-12 p-0">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 style="color: black;" class="m-0" class="mainHeadingStyle">Assignments list</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
                style="margin-right: 9px;" class="btn-outline-danger rounded pointer" />
            </div>
          </div>

        </mdb-card-header>
        <mdb-card-body>
          <div class="row" style="margin-top: 15px;">
            <div class="col-1" style="width: fit-content;">
              <mat-label class="matlabel" style="position: relative;top: 6px;">Date : </mat-label>
            </div>
            <div class="col-8" style="padding-left: 0;width: 15%;">
              <input type="date" class="ipcss_date widthappt pointer" id="appt_date"
                (change)="selectAppointmentDate(appointmentDate)" [(ngModel)]="appointmentDate" max="{{currentMaxDate}}"
                #matInput style="width: 140px;">
            </div>
            <div class="col-1" style="position: relative;top: 6px;width: 10%;" *ngIf="is_admin">
              <mat-label class="matlabel">Nurse list:</mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
              <select required [(ngModel)]="doctorid" (change)="changephysio()" class="ipcss widthappt">
                <option value="All">All</option>
                <option *ngFor="let doctors of physionameList" value={{doctors.physiodata_id}}>{{doctors.PhysioName}}
                </option>
              </select>
            </div>
          </div>
          <br>
          <div [hidden]="nodapp" class="nodata">No appointments(s) found</div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let list of appointmentList">
              <div class="card card_border">
                <div class="card-body">
                  <span class="checkbox_style">
                    <img class="checkbox_style" *ngIf="list.checked == false"
                      src="../../../assets/ui_icons/yellow_box.svg" alt=""
                      (click)="multiCancelAppointment(list.doc_app_id,false)">
                    <img class="checkbox_style" *ngIf="list.checked == true" src="list.checked == true"
                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                      (click)="multiCancelAppointment(list.doc_app_id,true)" alt=""> </span>
                  <div (click)="viewAppt(list.doc_app_id)">
                    <p style="margin:0px;">{{list.appointment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>