<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Lifestyle</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <p class="tervys_heading"><strong>Relation(s)</strong></p>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="Nodata">
              <span>No relation(s) found</span>
            </div>
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2" *ngFor="let tracker of relationlistData">
                <img src="{{tracker.prof_img}}" class="lifestye_tracker_image"
                  (click)="medi_track(tracker.rel_id,tracker.sub_rel_id)">
                <div style="width: fit-content; margin: auto;">
                  <p class="tervys_heading">{{tracker.rel_name}} </p>
                </div>
              </div>
            </div>
            <mat-grid-list cols="6">
              <mat-grid-tile>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>