import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-diagnosis-preslist-detail',
  templateUrl: './diagnosis-preslist-detail.component.html',
  styleUrls: ['./diagnosis-preslist-detail.component.css']
})
export class DiagnosisPreslistDetailComponent implements OnInit {

  public hospitalFlag: boolean;
  public sendDiagData;
  public diagDetailsUrl: string;
  public presTestId;
  public subTestName: string;
  public testName: string
  public appDate: string;
  public presId: string;
  public flag: string;
  public sendData;
  public diagPresUrl;
  public doctorAddrData = [];
  public testType = [];
  public diagId;
  public diagLocation;
  public diagListData = [];
  public seacrhFlag: boolean;
  public bookFlag: boolean;
  public hospitalName;
  public hospitalCity;
  public hospitalState;
  public hospitalLocation;
  public flagTxt;
  public splId;

  constructor(public messageservice:ClientViewService,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
    this.hospitalFlag = false;
    this.diagPresUrl = ipaddress.getIp + "prescription/dpresdet";
    this.diagDetailsUrl = ipaddress.getIp + "diagnosiscontroller/diagdetails";
    this.seacrhFlag = true;
    this.bookFlag = true;
    Client_Helper.setPharmacyList(null);
  }

  ngOnInit(): void {
    this.presId = Client_Helper.getDiagPresList().pres_id;
    this.flag = Client_Helper.getDiagPresList().flag;
    this.flagTxt = this.flag == "diagnosis" ? "booking" : undefined;

    this.sendData = {
      pres_id: this.presId,
      flag: this.flagTxt
    }
    this.getDiagPresDetail();
  }

  back() {
    if (Client_Helper.getDiagPresList().flag != undefined && Client_Helper.getDiagPresList().flag == "diagnosis") {
      this.messageservice.sendMessage("diagnosis_appcreate");
    }else if (Client_Helper.getDiagPresList().flag != undefined && Client_Helper.getDiagPresList().flag == "record") {
      this.messageservice.sendMessage("recordwallet");
    }
  }

  getDiagPresDetail() {
    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diagPresUrl, JSON.stringify(
      this.sendData
    ), { headers: headers }).subscribe(
      data => {
        this.splId = data.json().spl_id;
        var doct_mname, address, country, telephone, hosp_address, h_telephone;
        if (data.json().middle_name != null) {
          doct_mname = (data.json().first_name.toString()) + ' ' + (data.json().middle_name.toString()) + ' ' + (data.json().last_name.toString());
        }else {
          doct_mname = (data.json().first_name.toString()) + ' ' + (data.json().last_name.toString());
        }

        if (data.json().address2 != null && data.json().address2 != undefined) {
          address = (data.json().address1.toString()) + ' ' + (data.json().address2.toString() + ', ');
        }else {
          if (data.json().address1 != null && data.json().address1 != undefined) {
            address = (data.json().address1.toString()) + ', ';
          }
        }

        if (data.json().hosp_address2 != null && data.json().hosp_address2 != undefined) {
          hosp_address = (data.json().hosp_address1.toString()) + ', ' + (data.json().hosp_address2.toString() + ', ');
        }else {
          if (data.json().hosp_address1 != null && data.json().hosp_address1 != undefined) {
            hosp_address = (data.json().hosp_address1.toString()) + ', ';
          }
        }

        if (data.json().telephone != null && data.json().telephone != undefined) 
          telephone = data.json().telephone;
        
        if (data.json().hosp_telephone != null && data.json().hosp_telephone != undefined) 
          h_telephone = data.json().hosp_telephone;

        if ((data.json().pres_date) != undefined) {
          var dateSplit = (data.json().pres_date).split("-");
          this.appDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        }

        if (data.json().hosp_location != null && data.json().hosp_location != undefined) 
          data.json().hosp_location;
        
        if (data.json().diag_location != null && data.json().diag_location != undefined) 
          this.diagLocation = data.json().diag_location;
        
        if (data.json().diag_id != null && data.json().diag_id != undefined) {
          this.diagId = data.json().diag_id;
          if (this.flag == "diagnosis") {
            this.bookFlag = false;
          }
        }

        if (data.json().hosp_city != null && data.json().hosp_city != undefined) 
          data.json().hosp_city;
        
        if (data.json().hosp_zipcode != null && data.json().hosp_zipcode != undefined) 
          data.json().hosp_zipcode;
        
        if (data.json().hosp_state != null && data.json().hosp_state != undefined) 
          data.json().hosp_state;
        
        if (data.json().hosp_country != null && data.json().hosp_country != undefined) 
          data.json().hosp_country;
        
        if (data.json().diag_name != null && data.json().diag_name != undefined) 
          data.json().diag_name;
        
        if (data.json().tests != null && data.json().tests.length != 0) {
          for (var i = 0; i < data.json().tests.length; i++) {
            var t_name = data.json().tests[i].replace("_", " ");
            t_name = t_name.charAt(0).toUpperCase() + t_name.substr(1).toLowerCase();

            if (data.json()[data.json().tests[i]] != null) {
              this.subTestName = "";
              this.testName = "";
              this.presTestId = "";
              for (var j = 0; j < data.json()[data.json().tests[i]].length; j++) {
                if (j == data.json()[data.json().tests[i]].length - 1 && i == data.json().tests[i].length - 1) {
                  this.testName = this.testName + data.json()[data.json().tests[i]][j].test_name
                } else {
                  this.testName = this.testName + data.json()[data.json().tests[i]][j].test_name + ", "
                }
                if (j == data.json()[data.json().tests[i]].length - 1) {
                  this.subTestName = this.subTestName + data.json()[data.json().tests[i]][j].test_name
                  this.presTestId = this.presTestId + data.json()[data.json().tests[i]][j].pres_test_id;
                } else {
                  this.subTestName = this.subTestName + data.json()[data.json().tests[i]][j].test_name + ", "
                  this.presTestId = this.presTestId + data.json()[data.json().tests[i]][j].pres_test_id + ", ";
                }
              }

              this.testType.push({
                test: t_name,
                subtest: this.subTestName,
                pres_test_id: this.presTestId
              });
            } 
          }
        }

        if (this.testType != null && this.testType.length != 0 && this.flag == "diagnosis") 
          this.seacrhFlag = false;

        if (data.json().hosp_name != undefined) {
          this.hospitalName = data.json().hosp_name;
          this.hospitalFlag = true;
        }

        if (data.json().hosp_city != undefined) 
          this.hospitalCity = data.json().hosp_city + ' - ' + data.json().hosp_zipcode;

        if (data.json().hosp_location != undefined) 
          this.hospitalLocation = data.json().hosp_location;

        if (data.json().hosp_state != undefined) 
          this.hospitalState = data.json().hosp_state + ' - ' + data.json().hosp_country;

        this.doctorAddrData.push({
          name: doct_mname,
          address1: address,
          location: this.diagLocation,
          city: data.json().city + ' - ' + data.json().zipcode,
          state: data.json().state + ", " + data.json().country,
          telephone: telephone,
          hosp_name: this.hospitalName,
          hos_address: hosp_address,
          hos_location: this.hospitalLocation,
          hos_city: this.hospitalCity,
          hos_state: this.hospitalState,
          hos_telephone: h_telephone,
          relation: data.json().relation_name,
          date: this.appDate,
          hos_center: data.json().diag_name,
          diag_id: this.diagId,
        });
        this.getDiagDetail(this.diagLocation, this.diagId);
      }, error => {});
  }

  getDiagDetail(diag_location, diag_id) {
    this.sendDiagData = {
      location: diag_location,
      diag_id: diag_id,
    }

    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diagDetailsUrl, JSON.stringify(this.sendDiagData), { headers: headers }).subscribe(
      data => {
        this.diagListData = [];
        var address, telephone, website, workday, available, time_available;

        if (data.json().address2 != null && data.json().address2 != undefined) {
          address = (data.json().address1) + ', ' + (data.json().address2 + ', ');
        } else {
          if (data.json().address1 != null && data.json().address1 != undefined) {
            address = (data.json().address1) + ', ';
          }
        }

        if (data.json().telephone != null && data.json().telephone != undefined) 
          telephone = data.json().telephone;

        website = (data.json().website != null && data.json().website != undefined) ? data.json().website : " - ";
        
        var diag_tests_list;
        diag_tests_list = this.testType;
        this.diagListData.push({
          center_id: data.json().diag_id,
          center_name: data.json().diag_name,
          city: data.json().city + "- " + data.json().zipcode,
          location: data.json().location,
          state: data.json().state + ", " + data.json().country,
          telephone: telephone,
          profile_image: ipaddress.Ip_with_img_address + data.json().profile_image,
          website: website,
          available: available,
          diag_tests_list: diag_tests_list,
          address: address,
        });
      }, error => {});
  }

  search() {
    if (this.testType != null && this.testType.length != 0) {
      if (this.flag != undefined && this.flag == "diagnosis") {
        var pharmacylist ={
          diag_tests_list: this.testType,
          pres_id: this.presId,
          flow: this.flag,
          flag:this.flag,
          client_reg_id: Client_Helper.getDiagPresList().client_reg_id,
          relation_id:Client_Helper.getDiagPresList().relation_id,
          sub_rel_id:Client_Helper.getDiagPresList().sub_rel_id
        }
        Client_Helper.setPharmacyList(pharmacylist);
        this.messageservice.sendMessage("pharmacyorderlist");
      } else {
        var pharmacylist1 ={
          diag_tests_list: this.testType,
          pres_id: this.presId,
        }
        Client_Helper.setPharmacyList(pharmacylist1);
        this.messageservice.sendMessage("pharmacyorderlist");
      }
    } else {
      this.toastr.error(Message_data.noTest);
    }
  }

  book() {
    if (this.testType != null && this.testType.length != 0) {
      var diag_test_details = [];
      var diagbooknow = {
        diag_list_details: this.diagListData,
        pres_id: this.presId,
        flow:Client_Helper.getDiagPresList().flow,
        flag:this.flag,
        centre_id:this.diagId,
        client_reg_id: Client_Helper.getDiagPresList().client_reg_id,
        relation_id:Client_Helper.getDiagPresList().relation_id,
        sub_rel_id:Client_Helper.getDiagPresList().sub_rel_id,
        spl_id:this.splId,
      }
      Client_Helper.setDiagBookNow(diagbooknow);
      this.messageservice.sendMessage("diagnosis_booking");

    } else {
      this.toastr.error(Message_data.noTest);
    }
  }
}
